import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as StringUtil from './../../util/stringUtil';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    constructor(private http: HttpClient) { }

    async createGroup(body) {

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(body.name)) {
            body.name = body.name.trim();
        }

        const path = environment.mtntApiEndpoint + '/groups/create';
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async getGroupList(groupType, pageRecordSize, startRecord, sortField, sortAscending, filterKey, filterValue) {
        const body = {
            groupType: groupType,
            pageSize: pageRecordSize,
            startRecord: startRecord,
            sortField: sortField,
            sortAscending: sortAscending,
            filterKey: filterKey,
            filterValue: filterValue
        };
        const path = environment.mtntApiEndpoint + `/groups`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async getGroupDetails(type, id) {
        const path = environment.mtntApiEndpoint + `/groups/details`;
        const result: any = await this.http.post(path, { groupId: id, groupType: type }).toPromise();
        return result;
    }

    async updateGroupDetails(params) {

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(params.name)) {
            params.name = params.name.trim();
        }

        const path = environment.mtntApiEndpoint + '/groups';
        const result = await this.http.put(path, params).toPromise();
        return result;
    }

    async deleteGroups(params) {
        const path = environment.mtntApiEndpoint + `/groups`;
        const result: any = await this.http.delete(path, params).toPromise();
        return result;
    }

    async getModuleDisplayList() {
        const path = environment.mtntApiEndpoint + '/dashboard/modules';
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async updateModuleDisplayList(dashboardModules) {
        const path = environment.mtntApiEndpoint + '/dashboard/modules';
        const result: any = await this.http.post(path, dashboardModules).toPromise();
        return result;
    }
}
