import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from '../../../common/spinner/spinner.component';
import { VehicleSelectorComponent } from '../../vehicles/vehicle-selector/vehicle-selector.component';

// Service
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from '../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';
import { RouteService } from '../../../../_services/route/route.service';
import { PopupService } from '../../../common/popup/popup.service';

// Util
import * as DomUtil from '../../../../util/domUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';
import * as StringUtil from '../../../../util/stringUtil';

// Constant
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from '../../../../constants/responseStatusCode';

@Component({
    selector: 'app-edit-route',
    templateUrl: './edit-route.component.html',
    styleUrls: ['./edit-route.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class EditRouteComponent implements OnInit, OnDestroy {
    toggleMobileOpen: boolean = false;
    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    sortField = 'Name';
    sortAscending = true;

    constructor(
        private platform: Platform,
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private route: ActivatedRoute,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService,
        private routeService: RouteService
    ) { }

    isFirstVisit = true;

    // Route Form
    @ViewChild('vehicleAssign', { static: false }) vehicleAssign: VehicleSelectorComponent;
    @ViewChild("page_spinner", { static: true }) pageSpinner: SpinnerComponent;

    searchInput: any;
    routeId: number;

    // Route Info
    routeName: string;

    // Checkpoint
    checkpointList = [];
    // Checkpoint Selected
    isDeletingCheckpoint: boolean;
    selectedCheckpoint: any = {};
    selectedCheckpointGroups: any = {};

    //Vehicle List
    selectedVehiclesList: Array<number> = null;

    async ngOnInit() {
        this.routeId = this.route.snapshot.params.id;
        this.searchInput = document.getElementById('searchInput');
        await this.loadScriptFirst();
        DomUtil.autoFocusForm(this.el);

        await this.checkUnviewedCampaign();
    }

    async ngOnDestroy() {
    }

    onCheckpointAllSelectChange() {
        const allIsSelected = this.selectedCheckpointGroups.all;
        for (let i = 0; i < this.checkpointList.length; i++) {
            const checkpointModel = this.checkpointList[i];
            this.selectedCheckpoint[checkpointModel.id] = allIsSelected;
        }
    }

    onCheckpointSelectChange(checkpointSelected: boolean) {
        if (checkpointSelected) {
        } else {
            this.selectedCheckpoint['all'] = checkpointSelected;
        }
    }

    countSelectedCheckpoint() {
        return Object.keys(this.selectedCheckpoint).filter(result => this.selectedCheckpoint[result] === true).length;
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async getRouteDetail() {
        this.pageSpinner.show();
        try {
            const routeResult = await this.routeService.getRouteDetails(this.routeId);

            //assign values
            this.routeName = routeResult.body.result.Name;
            this.selectedVehiclesList = routeResult.body.result.VehicleIds;

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async getCheckpoints() {
        this.pageSpinner.show();
        try {
            const checkpointResult = await this.routeService.getCheckpoints(this.routeId);

            //assign values
            this.checkpointList = checkpointResult.body;

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async loadScriptFirst() {
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        if (!DomUtil.hasGoogleMapDrawingScript(GOOGLE_MAP_API_KEY)) {

            DomUtil.addGoogleMapScript({
                libraries: ['drawing', 'places'],
                onload: this.finishScriptLoad.bind(this)
            }, GOOGLE_MAP_API_KEY);

        } else {
            console.debug('EditGeofenceComponent: (Geofence)Skip adding Google Map Script Tag ...');
            if (DomUtil.isGoogleNotDefined()) {
                await AsyncUtil.wait(300); // wait for google object ready
            }
            await this.finishScriptLoad();
        }
    }

    async finishScriptLoad() {
        await this.getRouteDetail();
        await this.getCheckpoints();
    }

    async deleteCheckpoint() {
        const selectedCheckpointList = [];
        for (const key in this.selectedCheckpoint) {
            if (this.selectedCheckpoint[key] === true) {
                selectedCheckpointList.push(parseInt(key));
            }
        }

        for (const selectedCheckpoint of selectedCheckpointList) {
            this.pageSpinner.show();
            try {
                const result = await this.routeService.deleteCheckpoint(selectedCheckpoint)

                let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Checkpoint', "Checkpoint");
                if (result) {
                    if (result.statusCode == ResponseStatusCode.PARTIAL.code) {
                        msg = Message.getMessage(Message.MESSAGE.DELETE_PARTIAL.value, 'Checkpoint', "Checkpoint");
                    } else if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                        msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Checkpoint', "Checkpoint");
                        await this.getCheckpoints();
                    }

                    // reset delete selection
                    this.cancelResetDelete();
                }
                this.snackBar.openGenericSnackBar(msg);
            } catch (err) {
                this.snackBar.openStandardizedErrorSnackBar(err);
            } finally {
                this.pageSpinner.hide();
            }
        }

    }

    cancelResetDelete() {
        this.isDeletingCheckpoint = false;
        this.selectedCheckpoint = {};
        this.selectedCheckpointGroups['all'] = false;
    }

    async updateRouteInfo() {
        this.pageSpinner.show();
        try {
            this.selectedVehiclesList = this.vehicleAssign.getSelectedVehicleIds();

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.routeName)) {
                this.routeName = this.routeName.trim();
            }

            const result = await this.routeService.updateRoute(this.routeId, this.routeName, this.selectedVehiclesList);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Route', this.routeName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Route', this.routeName);
                this.navigateSuccessToRouteView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateToCreatecheckpoint() {
        this.routerProxyService.navigate(['/create-manage/routes/create-checkpoint/', this.routeId]);
    }

    navigateToEditCheckpoint(checkpointId) {
        this.routerProxyService.navigate(['/create-manage/routes/edit-checkpoint/', this.routeId, checkpointId]);
    }

    navigateSuccessToRouteView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/routes/view'], null, 'route');
    }

    navigateToRouteView() {
        this.routerProxyService.navigate(['/create-manage/routes/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
