import {
  AfterViewInit,
  Component,
  ElementRef,
  Input, OnChanges,
  OnInit, SimpleChanges,
  ViewChild
} from '@angular/core';
import {Platform} from "@ionic/angular";
import * as DomUtil from "../../../util/domUtil";
import * as AsyncUtil from "../../../util/asyncUtil";

@Component({
  selector: 'app-simple-map-popup',
  templateUrl: './simple-map-popup.component.html',
  styleUrls: ['./simple-map-popup.component.scss'],
  exportAs: 'popup',
})
export class SimpleMapPopupComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("mapContainer", {static: false}) gmap: ElementRef;
  @Input() lat;
  @Input() lng;

  private isMapInit: boolean = false;
  map: google.maps.Map;
  marker: google.maps.Marker;
  coordinates: google.maps.LatLng;
  mapOptions: google.maps.MapOptions;

  constructor(
    private platform: Platform) {}

  ngOnInit(): void {
    // None
  }

  async ngAfterViewInit() {
    await this.loadScriptFirst();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isMapInit) return;
    this.lat = changes.lat.currentValue;
    this.lng = changes.lng.currentValue;
    this.mapInitializer();
  }

  async loadScriptFirst() {
    const GOOGLE_MAP_API_KEY = DomUtil.getGoogleAPIKeyByPlatform(this.platform);
    if (!DomUtil.hasGoogleMapScript(null, GOOGLE_MAP_API_KEY)) {
      DomUtil.addGoogleMapScript({
        onload: this.mapInitializer.bind(this)
      }, GOOGLE_MAP_API_KEY);
      this.isMapInit = true;
    } else {
      if (DomUtil.isGoogleNotDefined()) {
        await AsyncUtil.wait(300); // wait for google object ready
      }
      this.isMapInit = true;
      this.mapInitializer();
    }
  }

  mapInitializer() {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.marker = new google.maps.Marker({
      position: this.coordinates,
      map: this.map,
      title: `(${this.lat}, ${this.lng})`
    });
    this.mapOptions = {
      center: this.coordinates,
      zoom: 18
    };

    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

    //Adding Click event to default marker
    this.marker.addListener("click", () => {
      const infoWindow = new google.maps.InfoWindow({
        content: this.marker.getTitle()
      });
      infoWindow.open(this.marker.getMap(), this.marker);
    });

    //Adding default marker to map
    this.marker.setMap(this.map);
  }
}
