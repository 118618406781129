<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@sm --space-top">
                    <h1 class="heading --level-1">
                        Vehicles
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <!-- <a class="heading-buttons__button" (click)="showPopup(batchImportPopup); toggleMobileOpen = false" *ngIf="currentTab === 2">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/scss-components/key-value-icons/icon-batch-import.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Bulk Assign
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Bulk Assign
                                    </div>

                                    <img src="assets/images/scss-components/key-value-icons/icon-mobile-batch-import.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a> -->
                            <a class="heading-buttons__button" (click)="showDownloadPopup(downloadsPopup)">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-export.svg" alt="" width="23" height="23" class="heading-buttons__icon">
                                    <div class="heading-buttons__text">
                                        Export As
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Export As
                                    </div>
                                    <img src="assets/images/ng-components/create-manage/icon-mobile-export.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="viewEdit()">View / Edit</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="assign()">Assign</button>
                <!--
                <button type="button" class="button" (click)="showDownloadPopup(downloadsPopup)">
                  <img src="assets/images/scss-components/buttons/icon-download.svg" width="24" height="18" alt="" class="button__icon"> Download
                </button>
                -->
            </div>
        </div>
    </div>



    <!-- Start View / Edit Tab -->
    <div class="section" *ngIf="currentTab === 1">
        <div class="wrapper">
          
            <div class="driver__filters" style="position: relative; z-index: 3;">
                <div class="block --divider">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-10of12 w-6of12@md search__group">
                                <div class="field-group --unstack@xs search__select-field">
    
                                    <div class="field-group__item search__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                                <option value="vehicles">Vehicles</option>
                                                <option value="imeis">IMEIs</option>
                                            </select>
                                        </label>
                                    </div>
    
                                    <div class="field-group__item search__type-field-group-input">
                                        <div class="field">
                                            <div class="field__elems">
    
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                                    </div>
                                                </div>
                                                <label class="field__holder">
                                                    <input id="driverSearchBox" type="text" autocomplete="off" class="field__input" [placeholder]='searchBy === "imeis" ? "Search By IMEIs" : "Search By Vehicles"' [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchVehicle()">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="button__holder">
                                    <button type="button" class="button" (click)="searchVehicle()">SEARCH</button>
                                </div>
                            </div>
    
                            <div class="grid__block w-2of12 w-6of12@sm">
                                <div class="filter-button">
                                    <button type="button" class="button --filter" (click)="sort()">
                                        <div class="filter-button__input">
                                            Vehicle Name
                                        </div>
    
                                        <img [attr.src]="sortAscending ? 'assets/images/ng-components/create-manage/icon-filter-down.svg' : 'assets/images/ng-components/create-manage/icon-filter-up.svg'" alt="" width="25" height="12" class="filter-button__icon">
    
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="block block__md-tag">
                    <ul class="md-tag">
                        <li>
                            <button class="button --outline --tag" [class.--selectedMoving]="this.currentVehicleStatus == 'moving'" (click)="getVehicleStatus('moving')">
                                Moving
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selectedStopped]="this.currentVehicleStatus == 'stopped'" (click)="getVehicleStatus('stopped')">
                                Stopped
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selectedDisconnected]="this.currentVehicleStatus == 'disconnected'" (click)="getVehicleStatus('disconnected')">
                                Disconnected
                            </button>
                        </li>

                    
                    </ul>
                </div>
                <div class="block">
                    <div class="grid --gutter">

                        <div class="grid__block w-6of12@sm w-4of12@lg" *ngFor="let vehicle of vehicleViewInfoList">
                            <div class="filter-result__box" [class.--cursor]="editable" [ngStyle]="getVehicleStatusStyles(vehicle.status)" (click)="editable && navigateToEditVehicle(vehicle.vehicleId, $event)" >
                                <div id="group-colors" class="filter-result-dots">
                                    <div class="toggle__item-groups" [class.--active]="toggle1.active">
                                        <button class="toggle__item-groups-toggle" toggle #toggle1="toggle">
                                            <span class="filter-result-dots__dot" *ngFor="let vehicleGroup of vehicle.vehicleGroupList" [ngStyle]="{'background-color': vehicleGroup.colour}"></span>
                                        </button>

                                        <div class="toggle__item-groups-floater">
                                            <button class="toggle__item-groups-close" (click)="toggle1.setInactive()"></button>

                                            <div class="toggle__item-groups-contents" *ngFor="let vehicleGroup of vehicle.vehicleGroupList">
                                                <div class="toggle__item-group"><span class="filter-result-dots__dot --dot-2" [ngStyle]="{'background-color': vehicleGroup.colour}"></span>{{ vehicleGroup.vehicleGroupName }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="filter-result-group">
                                    <div class="filter-result__image" *ngIf="vehicle.vehicleImage">
                                        <div class="filter-result__image-cropped">
                                            <img src="data:image/jpeg;base64,{{vehicle.vehicleImage}}" alt="" width="105" height="105">
                                        </div>
                                    </div>
                                    <div class="filter-result__image --placeholder" *ngIf="vehicle.vehicleName && !vehicle.vehicleImage">
                                        <div class="filter-result__image-cropped --placeholder-green">
                                            {{ vehicle.shortName }}
                                        </div>
                                    </div>
                                    <div class="filter-result__info">
                                        <div class="filter-result__title">
                                            <span class="truncate-line">
                                                {{vehicle.vehicleName}}
                                            </span>
                                        </div>

                                        <div class="filter-result__vehicle-num">
                                            <span class="truncate-line">
                                                {{vehicle.vehiclePlateNo}}
                                            </span>

                                        </div>

                                        <div class="filter-result__vehicle-brand">
                                            <span class="truncate-line">
                                                {{vehicle.vehicleBrand}} {{vehicle.vehicleModel}}
                                            </span>
                                        </div>

                                        <ul class="update-log">
                                            <li class="module-driver-tag" *ngIf="vehicle.hasDriverTagDevice"></li>
                                            <li class="module-immobilizer" *ngIf="vehicle.hasImmobilizer"></li>
                                            <li class="module-fuel-sensor" *ngIf="vehicle.hasFuelSensor"></li>
                                            <li class="module-panic-button" *ngIf="vehicle.hasPanicButton"></li>
                                        </ul>

                                        <div class="filter-result__desc">
                                            <span class="truncate-line">
                                                <span class="filter-result__desc__child__label">IMEI: </span>{{vehicle.imeiNo}}
                                            </span>
                                            <span class="truncate-line">
                                                <span class="filter-result__desc__child__label">ICCID: </span>{{vehicle.iccid}}
                                            </span>
                                            <span class="truncate-line">
                                                <span class="filter-result__desc__child__label">Installation Type: </span>{{vehicle.installationType}}
                                            </span>
                                            <!-- table cellspacing="0" cellpadding="0">
                                                <tr class="desc__imei">
                                                    <td><b>IMEI:</b></td>
                                                    <td>{{vehicle.imeiNo}}</td>
                                                </tr>
                                            </table -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>
                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination" *ngIf="vehicleViewInfoList.length > 0">
                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDisplayVehicleList(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDisplayVehicleList(page)">{{
                                    page }}</button>

                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDisplayVehicleList(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End View / Edit Tab -->




    <!-- Start Assign Tab -->
    <div class="section" *ngIf="currentTab === 2">
        <div class="wrapper">
            <div class="driver__filters" style="position: relative; z-index: 3;">
                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-10of12 w-6of12@md search__group">


                            <div class="field-group --unstack@xs search__select-field">
                                <div class="field-group__item search__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                    <label class="select --grey">
                                        <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                            <option value="vehicles">Vehicles</option>
                                            <option value="drivers">Drivers</option>
                                        </select>
                                    </label>
                                </div>

                                <div class="field-group__item search__type-field-group-input">
                                    <div class="field">
                                        <div class="field__elems">

                                            <div class="field__icon">
                                                <div class="field__icon-inner">
                                                    <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                                </div>
                                            </div>
                                            <label class="field__holder">
                                                <input type="text" autocomplete="off" class="field__input" [placeholder]='searchBy === "vehicles" ? "Search By Vehicles" : "Search By Drivers"' [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchVehicle()">
                                            </label>
                                            <div class="field__ui"></div>
                                        </div>
                                    </div>
                                </div>

                                <!-- START VEHICLE -->
                                <!-- <div class="field-group__item search__type-field-group-input" [class.--active]="vehiclesSearchDOMStates.isFocused" *ngIf="searchBy === 'vehicles'" click-outside (clickOutside)="closeVehicleSearchDropdown($event)">
                                    <div class="field" #vehiclesField>
                                        <div class="field__elems">
                                            <div class="field__icon">
                                                <div class="field__icon-inner">
                                                    <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                                </div>
                                            </div>

                                            <label class="field__holder">
                                                <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                    {{ vehiclesSelectedLabel }}
                                                </div>

                                                <input id="vehicleSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #vehiclesSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterVehiclesSelection($event.target.value)" (keyup.enter)="getVehicleDriverDetails()">
                                            </label>

                                            <div class="field__ui"></div>
                                        </div>

                                        <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                            <ul class="inline-input-list">
                                                <li *ngIf="!vehiclesModel.vehicles.length" class="inline-input-list__item">
                                                    <label class="inline-input">
                                                        No vehicles found
                                                    </label>
                                                </li>
                                                <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                                    <label class="inline-input">
                                                        <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups['all']" (ngModelChange)="onVehiclesAllSelectChange()">
                                                        <span class="inline-input__icon"></span>
                                                        All vehicles
                                                    </label>
                                                </li>

                                                <ng-container *ngFor="let group of vehiclesModel.vehicles; let groupIndex = index;">
                                                    <ng-container *ngIf="group.type === 'INDIVIDUAL'">
                                                        <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                            <label class="inline-input" [title]="item.vehicleName">
                                                                <input type="checkbox" [name]="item.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[item.vehicleId]" (ngModelChange)="onVehiclesSelectChange()">

                                                                <span class="inline-input__icon"></span>

                                                                {{ item.vehicleName }}
                                                            </label>
                                                        </li>
                                                    </ng-container>

                                                    <ng-container *ngIf="group.type === 'GROUP'">
                                                        <li class="inline-input-list__item">
                                                            <label class="inline-input">
                                                                <input type="checkbox" [name]="group.name" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups[groupIndex]" (ngModelChange)="onVehiclesGroupSelectChange(groupIndex);">

                                                                <span class="inline-input__icon"></span>

                                                                {{ group.name }}
                                                            </label>

                                                            <button class="item-group-expand" (click)="expandGroupVehicle[groupIndex] = !expandGroupVehicle[groupIndex]">

                                                                <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupVehicle[groupIndex]">

                                                            </button>

                                                            <ng-container *ngIf="expandGroupVehicle[groupIndex]">
                                                                <ul class="inline-input-list">
                                                                    <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                                        <label class="inline-input" [title]="item.vehicleName">
                                                                            <input type="checkbox" [name]="item.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[item.vehicleId]" (ngModelChange)="onVehiclesSelectChange(groupIndex, $event)">

                                                                            <span class="inline-input__icon"></span>
                                                                            {{ item.vehicleName }}
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </ng-container>
                                                        </li>
                                                    </ng-container>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- END VEHICLE -->


                                <!-- START DRIVER -->
                                <!-- <div class="field-group__item search__type-field-group-input" [class.--active]="driversSearchDOMStates.isFocused" *ngIf="searchBy === 'drivers'" click-outside (clickOutside)="closeDriverSearchDropdown($event)">
                                    <div class="field" #driversField>
                                        <div class="field__elems">
                                            <div class="field__icon">
                                                <div class="field__icon-inner">
                                                    <img src="assets/images/scss-components/forms/icon-steering-wheel.svg" width="26" height="20" alt="" class="field__icon-image">
                                                </div>
                                            </div>

                                            <label class="field__holder">
                                                <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                    {{ driversSelectedLabel }}
                                                </div>

                                                <input id="driverSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #driversSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterDriversSelection($event.target.value)" (keyup.enter)="getVehicleDriverDetails()">
                                            </label>

                                            <div class="field__ui"></div>
                                        </div>

                                        <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                            <ul class="inline-input-list">
                                                <li *ngIf="!driversModel.drivers.length" class="inline-input-list__item">
                                                    <label class="inline-input">
                                                        No drivers found
                                                    </label>
                                                </li>
                                                <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                                    <label class="inline-input">
                                                        <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverGroups['all']" (ngModelChange)="onDriversAllSelectChange()">
                                                        <span class="inline-input__icon"></span>

                                                        All Drivers
                                                    </label>
                                                </li>

                                                <ng-container *ngFor="let group of driversModel.drivers; let groupIndex = index;">
                                                    <ng-container *ngIf="group.type === 'INDIVIDUAL'">
                                                        <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                            <label class="inline-input" [title]="item.driverName">
                                                                <input type="checkbox" [name]="item.driverId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivers[item.driverId]" (ngModelChange)="onDriversSelectChange()">
                                                                <span class="inline-input__icon"></span>
                                                                {{ item.driverName }}
                                                            </label>
                                                        </li>
                                                    </ng-container>

                                                    <ng-container *ngIf="group.type === 'GROUP'">
                                                        <li class="inline-input-list__item">
                                                            <label class="inline-input">
                                                                <input type="checkbox" [name]="group.name" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverGroups[groupIndex]" (ngModelChange)="onDriversGroupSelectChange(groupIndex);">
                                                                <span class="inline-input__icon"></span>

                                                                {{ group.name }}
                                                            </label>

                                                            <button class="item-group-expand" (click)="expandGroupDriver[groupIndex] = !expandGroupDriver[groupIndex]">

                                                                <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupDriver[groupIndex]">

                                                            </button>

                                                            <ng-container *ngIf="expandGroupDriver[groupIndex]">
                                                                <ul class="inline-input-list">
                                                                    <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                                        <label class="inline-input" [title]="item.driverName">
                                                                            <input type="checkbox" [name]="item.driverId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivers[item.driverId]" (ngModelChange)="onDriversSelectChange(groupIndex, $event)">
                                                                            <span class="inline-input__icon"></span>
                                                                            {{ item.driverName }}
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </ng-container>
                                                        </li>
                                                    </ng-container>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- END DRIVER -->
                            </div>

                            <div class="button__holder">
                                <!-- <button type="button" class="button" (click)="getVehicleDriverDetails()">SEARCH</button> -->
                                <button type="button" class="button" (click)="searchVehicle()">SEARCH</button>
                            </div>
                        </div>

                        <div class="grid__block w-2of12 w-6of12@md">
                            <div class="filter-button">
                                <button type="button" class="button --filter" (click)="sort()">
                                    <div class="filter-button__input" *ngIf="searchBy === 'vehicles'">
                                        Vehicle Name
                                    </div>
                                    <div class="filter-button__input" *ngIf="searchBy === 'drivers'">
                                        Driver Name
                                    </div>

                                    <img [attr.src]="sortAscending ? 'assets/images/ng-components/create-manage/icon-filter-down.svg' : 'assets/images/ng-components/create-manage/icon-filter-up.svg'" alt="" width="25" height="12" class="filter-button__icon">

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-6of12@sm w-4of12@lg" *ngFor="let vehicle of vehicleViewInfoList">
                            <div class="filter-result__box" [class.--cursor]="editable" (click)="editable && navigateToAssignVehicle(vehicle.vehicleId, vehicle.vehiclePlateNo, $event)">
                                <div id="group-colors" class="filter-result-dots">
                                    <div class="toggle__item-groups" [class.--active]="toggle1.active">
                                        <button class="toggle__item-groups-toggle" toggle #toggle1="toggle">
                                            <span class="filter-result-dots__dot" *ngFor="let vehicleGroup of vehicle.vehicleGroupList" [ngStyle]="{'background-color': vehicleGroup.colour}"></span>
                                        </button>

                                        <div class="toggle__item-groups-floater">
                                            <button class="toggle__item-groups-close" (click)="toggle1.setInactive()"></button>

                                            <div class="toggle__item-groups-contents" *ngFor="let vehicleGroup of vehicle.vehicleGroupList">
                                                <div class="toggle__item-group"><span class="filter-result-dots__dot --dot-2" [ngStyle]="{'background-color': vehicleGroup.colour}"></span>{{ vehicleGroup.vehicleGroupName }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="filter-result-group">
                                    <div class="filter-result__image" [class.--placeholder]="!vehicle.vehicleImage">
                                        <div class="filter-result__image-cropped" [class.--placeholder-green]="!vehicle.vehicleImage">
                                            <img src="data:image/jpeg;base64,{{vehicle.vehicleImage}}" alt="" width="105" height="105" *ngIf="vehicle.vehicleImage">

                                            <div *ngIf="!vehicle.vehicleImage">{{ vehicle.shortName }}</div>

                                            <div *ngIf="vehicle.userId">
                                                <div class="filter-result__image-thumb" *ngIf="vehicle.userImage">
                                                    <img src="data:image/jpeg;base64,{{vehicle.userImage}}" alt="" width="35" height="35" *ngIf="vehicle.userImage">
                                                </div>
                                                <div class="filter-result__image-thumb --placeholder-grey" *ngIf="!vehicle.userImage">
                                                    <span *ngIf="!vehicle.userImage"> {{ vehicle.driverShortName }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="filter-result__info">
                                        <div class="filter-result__title">
                                            <span class="truncate-line">
                                                {{vehicle.vehicleName}}
                                            </span>
                                        </div>

                                        <div class="filter-result__vehicle-num">
                                            <span class="truncate-line">
                                                {{vehicle.vehiclePlateNo}}
                                            </span>
                                        </div>

                                        <div class="filter-result__vehicle-brand">
                                            <span class="truncate-line">
                                                {{vehicle.vehicleBrand}} {{vehicle.vehicleModel}}
                                            </span>
                                        </div>
                                        <ul class="update-log">
                                            <li class="module-driver-tag" *ngIf="vehicle.hasDriverTagDevice"></li>
                                            <li class="module-immobilizer" *ngIf="vehicle.hasImmobilizer"></li>
                                            <li class="module-fuel-sensor" *ngIf="vehicle.hasFuelSensor"></li>
                                            <li class="module-panic-button" *ngIf="vehicle.hasPanicButton"></li>
                                        </ul>
                                        <div class="filter-result__desc">
                                            <span class="truncate-line">
                                                <span class="filter-result__desc__child__label">Current Driver: </span>
                                                <span *ngIf="vehicle.userId" [title]="vehicle.userFirstName + ' ' + vehicle.userLastName">
                                                    {{vehicle.userFirstName}} {{vehicle.userLastName}}
                                                </span>
                                                <span *ngIf="!vehicle.userId">Unassigned</span>
                                            </span>

                                            <!--table cellspacing="0" cellpadding="0">
                                                <tbody [ngStyle]="{'display':'grid'}">
                                                    <tr class="desc__licence truncate-word">
                                                        <td [ngStyle]="{'display':'inline'}"><b>Current Driver:</b></td>
                                                        <td [ngStyle]="{'display':'inline'}" *ngIf="vehicle.userId" [title]="vehicle.userFirstName + ' ' + vehicle.userLastName">{{vehicle.userFirstName}} {{vehicle.userLastName}}
                                                        </td>
                                                        <td *ngIf="!vehicle.userId">Unassigned</td>
                                                    </tr>
                                                </tbody>
                                            </table-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>
                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination" *ngIf="vehicleViewInfoList.length > 0">
                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getVehicleDriverDetails(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getVehicleDriverDetails(page)">{{
                                    page }}</button>

                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getVehicleDriverDetails(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Assign Tab -->

</div>

<!--
<app-popup #vehicleDetailsPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="image-placeholder">
            <img src="assets/images/ng-components/create-manage/icon-vehicle-details.svg" alt="" width="114" height="90">
        </div>
    </div>

    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Vehicle details are not available.
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            Please edit vehicle before proceed to assignment.
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" (click)="navigateToEditVehicle(clickedVehicleId);hidePopup();">
                    Edit Vehicle
                </button>
                <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                    CANCEL
                </button>
            </div>
        </div>
    </div>
</app-popup>
-->

<app-popup #downloadsPopup="popup">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="exportVehicleFile('excel'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="exportVehicleFile('csv'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="exportVehicleFile('pdf'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
