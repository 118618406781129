<div class="site-main">
    <div class="error-layout">
        <div class="section">
            <div class="wrapper typography ta-c">
                <p>
                    Please reach out to our 24x7 Maxis Support team for
                    registering your complaint, reachable at
                    bizcare@maxis.com.my OR 1 800 82 1919.
                </p>

                <a href="javascript:history.back()" class="button --min-width">
                    Back
                </a>

                <!-- <div class="error-box">
                <h1>Unauthorized Access</h1>
                <span>You are accessing a page that is not granted permission to you. If this is not the case, kindly contact Maxis TNT support administrator.</span>
            </div> -->
            </div>
        </div>
    </div>
</div>
