import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class DriverTagService {

	constructor(private http: HttpClient) { }
	
	async createDriverTag(newDriverTagDetails) {
		const path = environment.mtntApiEndpoint + "/create-manage/driver-tag"
		const body = newDriverTagDetails;

		const result: any = await this.http.post(path, body).toPromise();
        return result;
	}

	async batchCreateDriverTag(newDriverTagDetails) {
		const path = environment.mtntApiEndpoint + "/create-manage/driver-tag-batch"
		const body = newDriverTagDetails;

		const result:any = await this.http.post(path, body).toPromise();
		return result;
	}

	async deleteDriverTag(driverTagDetail) {
		const path = environment.mtntApiEndpoint + "/create-manage/driver-tag";
		const body: any = {body: { driverTagIds: driverTagDetail}};

		const result: any = await this.http.delete(path, body).toPromise();

		return result;
	}
}
