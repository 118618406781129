import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { trigger, style, animate, transition } from '@angular/animations';
// import { environment } from './../../../../../environments/environment';
// import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from './../../../../_services/pager/pager.service';
import { TripsService } from '../../../../_services/trips/trips.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import * as moment from 'moment';
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
@Component({
  selector: 'app-incident-analysis',
  templateUrl: './incident-analysis.component.html',
  styleUrls: ['./incident-analysis.component.scss'],
  animations: [
      trigger('fadeToggle', [
          transition(':enter', [
              style({ opacity: 0 }),
              animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
          ]),
          transition(':leave', [
              style({ opacity: 1 }),
              animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
          ])
      ])
  ]
})
export class IncidentAnalysisComponent implements OnInit {
  toggleMobileOpen: boolean = false;
  @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
  @ViewChild("successNotification",{static:false}) successNotification;
  emailSentPopupMsg: string;
  todayStartDay = moment().startOf('day').format();

  isDeletingIncident: boolean;
  selectedIncident: any = {};
  selectedIncidentGroups: any = {};

  incidentReportList: any = [];
  sortField = 'plateNo';
  sortAscending = true;
  filterValue = '';
  currentFilterValue = '';

  pager: any = {};
  pageRecordSize = 10;

  resetPasswordUserId: any = 0;
  resetPasswordUserEmail: any = '';

  startTimeSelector: {
    hour: string[],
    fullHour: string[],
    minute: string[],
    second: string[]
} = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array
endTimeSelector: {
  hour: string[],
  fullHour: string[],
  minute: string[],
  second: string[]
} = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array
impactTimeSelector: {
  hour: string[],
  fullHour: string[],
  minute: string[],
  second: string[]
} = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array

incidentDateVar: string = '';
incidentStartTimeHh: string = '';
incidentStartTimeMm: string = '';
incidentStartTimeA: string = '';

incidentEndTimeHh: string = '';
incidentEndTimeMm: string = '';
incidentEndTimeA: string = '';

impactDateVar: string = null;  
impactTimeHh: string ='';
impactTimeMm: string ='';
impactTimeA: string ='';

currentIncidentReportObj = {
    incidentId:'',
    vehicleId: '',
    vehicleName: null,
    vehiclePlateNo: null,
    driverName: null,
    driverId: '',
    incidentStartDate: null,
    incidentEndDate: null,
    impactDate: null,
    description: null,
    remarks: null
};

  hasUnviewedCamapaign: boolean = false;
  unviewedCampaigns: Array<any> = [];

  constructor(
      private popupService: PopupService,
      private pagerService: PagerService,
      private tripsService: TripsService,
      private snackBar: SnackBarService,
      private routerProxyService: RouterProxyService,
      private popupCampaignService: PopupCampaignService

  ) { }

  async ngOnInit() {
      await this.getIncidentAnalysisReport();
      await this.checkUnviewedCampaign();

  }

  async checkUnviewedCampaign() {
      try {
          const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
          this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
          this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
      } catch (e) {
          this.snackBar.openStandardizedErrorSnackBar(e);
      }
  }

  async getIncidentAnalysisReport(page: number = 1) {
      this.pageSpinner.show();
      try {
          const startRecord = ((page - 1) * this.pageRecordSize) + 1;

          //Get Incident Analysis Records Page
          const incidentReportResult: any = await this.tripsService.getIncidentAnalysisReport(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.currentFilterValue);
          this.incidentReportList = incidentReportResult.body.result;
          this.pager = this.pagerService.getPager(incidentReportResult.body.totalRecord, page, this.pageRecordSize);

      } catch (e) {
          this.snackBar.openStandardizedErrorSnackBar(e);
      } finally {
          this.pageSpinner.hide();
      }
  }

  async deleteIncidentRecords() {
      this.pageSpinner.show();
      try {
          const selectedIncidentList = [];
          for (const key in this.selectedIncident) {
              if (this.selectedIncident[key] === true) {
                selectedIncidentList.push(parseInt(key));
              }
          }

          const result = await this.tripsService.deleteIncidentAnalysisRecord(selectedIncidentList);
          let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Incident Record');
          if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
              msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Incident Record');

              this.cancelResetDelete();
              await this.getIncidentAnalysisReport();
          }
          this.snackBar.openGenericSnackBar(msg);

      } catch (e) {
          this.snackBar.openStandardizedErrorSnackBar(e);
      } finally {
          this.pageSpinner.hide();
      }
  }


  async resetPassword() {
      this.pageSpinner.show();
      try {
          const result =null;
          if (result && result.statusCode === ResponseStatusCode.SUCCESS.code) {
              this.emailSentPopupMsg = Message.getMessage(Message.MESSAGE.EMAIL_SENT_PASSWORD_RESET_LINK.value, this.resetPasswordUserEmail);
              this.showPopup(this.successNotification);
          }
      } catch (e) {
          this.snackBar.openStandardizedErrorSnackBar(e);
      } finally {
          this.pageSpinner.hide();
      }
  }

  onIncidentAllSelectChange() {
      const allIsSelected = this.selectedIncidentGroups.all;
      // console.debug("allIsSelected:" + this.selectedIncidentGroups.all);
      for (let i = 0; i < this.incidentReportList.length; i++) {
          const incidentModel = this.incidentReportList[i];
          this.selectedIncident[incidentModel.id] = allIsSelected;
      }
  }

  onIncidentSelectChange(incidentSelected: boolean) {
      if (incidentSelected) {
          // this.selectedDrivingLimit[drivingLimitModel.vehicleId] = true;
      } else {
          this.selectedIncidentGroups['all'] = false;
      }
  }

  countSelectedIncident() {
      return Object.keys(this.selectedIncident).filter(result => this.selectedIncident[result] === true).length;
  }

  cancelResetDelete() {
      this.isDeletingIncident = false;
      this.selectedIncident = {};
      this.incidentReportList['all'] = false;
  }

  showPopup(popup) {
      this.popupService.show(popup);
  }
  hidePopup() {
      this.popupService.hide();
  }

  navigateToCreateIncidentAnalysis() {
      this.routerProxyService.navigate(['/reports/incident-analysis/create']);
  }
  viewIncidentAnalysisDetails(incidentId) {
    this.routerProxyService.navigate(['/reports/incident-analysis/details',incidentId]);
}
  async filterIncidentAnalysisReport() {
      this.currentFilterValue = this.filterValue;
      await this.getIncidentAnalysisReport();
  }

  async sort(sortName) {
      if (sortName === this.sortField) {
          this.sortAscending = !this.sortAscending;
      } else {
          this.sortField = sortName;
          this.sortAscending = true;
      }
      await this.getIncidentAnalysisReport();
  }

  getSortingState(sortName) {
      return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
  }

  onIncidentAnalysisClick(incidentId) {
    // Set Object to Empty
    for (const item in this.currentIncidentReportObj) {
        if (Object.prototype.hasOwnProperty.call(this.currentIncidentReportObj, item)) {
            this.currentIncidentReportObj[item] = null;
        }
    }
    // Set Value
    const currentIncidentAnalysis = this.incidentReportList.find(result => result.id === incidentId);
    this.currentIncidentReportObj.incidentId = currentIncidentAnalysis.id;
    this.currentIncidentReportObj.vehicleId = currentIncidentAnalysis.vehicleId;
    this.currentIncidentReportObj.vehiclePlateNo = currentIncidentAnalysis.plateNo;
    this.currentIncidentReportObj.vehicleName = currentIncidentAnalysis.vehicleName;
    this.currentIncidentReportObj.driverId = currentIncidentAnalysis.driverId;
    this.currentIncidentReportObj.driverName = currentIncidentAnalysis.driverName;
    this.currentIncidentReportObj.description = currentIncidentAnalysis.description;
    this.currentIncidentReportObj.remarks = currentIncidentAnalysis.remark;
    this.currentIncidentReportObj.incidentStartDate = currentIncidentAnalysis.incidentTimeStart;
    this.currentIncidentReportObj.incidentEndDate = currentIncidentAnalysis.incidentTimeEnd;
    this.currentIncidentReportObj.impactDate = currentIncidentAnalysis.impactDateTime;
    this.incidentDateVar = moment(currentIncidentAnalysis.incidentTimeStart).format("YYYY-MM-DD");
    this.incidentStartTimeHh = moment(currentIncidentAnalysis.incidentTimeStart).format("hh");
    this.incidentStartTimeMm = moment(currentIncidentAnalysis.incidentTimeStart).format("mm");
    this.incidentStartTimeA = moment(currentIncidentAnalysis.incidentTimeStart).format("A");
    this.incidentEndTimeHh = moment(currentIncidentAnalysis.incidentTimeEnd).format("hh");
    this.incidentEndTimeMm = moment(currentIncidentAnalysis.incidentTimeEnd).format("mm");
    this.incidentEndTimeA = moment(currentIncidentAnalysis.incidentTimeEnd).format("A");
    this.impactDateVar = currentIncidentAnalysis.impactDateTime ? moment(currentIncidentAnalysis.impactDateTime).format("YYYY-MM-DD") : null;
    this.impactTimeHh = currentIncidentAnalysis.impactDateTime ? moment(currentIncidentAnalysis.impactDateTime).format("hh") : null;
    this.impactTimeMm = currentIncidentAnalysis.impactDateTime ? moment(currentIncidentAnalysis.impactDateTime).format("mm") : null;
    this.impactTimeA = currentIncidentAnalysis.impactDateTime ? moment(currentIncidentAnalysis.impactDateTime).format("A") : null;
    
}
async updateIncidentAnalysis() {
  this.pageSpinner.show();
  try {
  
    const incidentDateOnlyMom = moment(this.incidentDateVar);
    const incidentStartDate = `${incidentDateOnlyMom.format('YYYY-MM-DD')} ${this.incidentStartTimeHh}:${this.incidentStartTimeMm} ${this.incidentStartTimeA}`;
    this.currentIncidentReportObj.incidentStartDate = incidentStartDate ? moment(incidentStartDate, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss") : null;
    const incidentEndDate = `${incidentDateOnlyMom.format('YYYY-MM-DD')} ${this.incidentEndTimeHh}:${this.incidentEndTimeMm} ${this.incidentEndTimeA}`;
    this.currentIncidentReportObj.incidentEndDate = incidentEndDate ? moment(incidentEndDate, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss") : null;

    if(this.impactDateVar){
      const impactDateOnlyMom = moment(this.impactDateVar);
      const impactDate = `${impactDateOnlyMom.format('YYYY-MM-DD')} ${this.impactTimeHh}:${this.impactTimeMm} ${this.impactTimeA}`;
      this.currentIncidentReportObj.impactDate = impactDate ? moment(impactDate, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss") : null;  
    }

    const incidentList = 
      {
          incidentId : parseInt(this.currentIncidentReportObj.incidentId),
          vehicleId: parseInt(this.currentIncidentReportObj.vehicleId),
          driverId: parseInt(this.currentIncidentReportObj.driverId),
          incidentStartDate: this.currentIncidentReportObj.incidentStartDate,
          incidentEndDate: this.currentIncidentReportObj.incidentEndDate,
          impactDate: this.impactDateVar ? this.currentIncidentReportObj.impactDate : null,
          description: this.currentIncidentReportObj.description,
          remarks: this.currentIncidentReportObj.remarks
      };
      
      const result = await this.tripsService.updateIncidentAnalysis(incidentList);

      let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Incident Analysis', this.currentIncidentReportObj.vehicleName);
      if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
          msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Incident Analysis', this.currentIncidentReportObj.vehicleName);
          // retrieve updated result
          await this.getIncidentAnalysisReport();
      }
      this.snackBar.openGenericSnackBar(msg);

  } catch (e) {
      this.snackBar.openStandardizedErrorSnackBar(e);
  } finally {
      this.pageSpinner.hide();
  }
}
}
