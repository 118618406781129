<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md --space-top">
                    <h1 class="heading --level-1">
                        Driving Limits
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top" [hidden]="currentTab !== 1">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToSetLimit(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Set Limit
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Set Limit
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingDrivingLimit = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Limit
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Limit
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top" [hidden]="currentTab !== 2">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToCreateGeofence(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Geofence
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Geofence
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingGeofence = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Geofence
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Geofence
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">

                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">

                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top" [hidden]="currentTab !== 3">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToCreateDestination(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Destination
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Destination
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingDestination = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Destination
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Destination
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">

                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">

                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="section   bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="generalDrivingLimits()">General</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="geofenceDrivingLimits()">Geofence</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 3" (click)="destinationDrivingLimits()">Destination</button>
            </div>
        </div>
    </div>

    <div class="tabs__content" [hidden]="currentTab !== 1">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input id="drivingLimitSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Vehicle" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchVehicleName()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchVehicleName()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table table__driving-limit-general">
                            <table class="table__elem" style="width: 100%; table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <ng-container *ngIf="isDeletingDrivingLimit">
                                            <th scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivingLimitGroups['all']" (ngModelChange)="onDrivingLimitAllSelectChange()">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container>

                                        <th scope="col">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Name')" (click)="sort('Name')">Vehicle</button>
                                        </th>
                                        <th scope="col" width="22%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('SpeedLimit')" (click)="sort('SpeedLimit')">Speed
                                                <br>Limit (km/h)</button>
                                        </th>
                                        <th scope="col" width="22%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('AllowedTimeFrom')" (click)="sort('AllowedTimeFrom')">Allowed
                                                <br>Driving Hour (hh:mm)</button>
                                        </th>
                                        <th scope="col" width="22%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DailyMileageLimit')" (click)="sort('DailyMileageLimit')">Daily
                                                <br>Mileage (km)</button>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let vehicle of vehicleDrivingLimitList">
                                        <ng-container *ngIf="isDeletingDrivingLimit">
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="vehicle.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivingLimit[vehicle.vehicleId]" (ngModelChange)="onDrivingLimitSelectChange($event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>
                                        </ng-container>
                                        <td class="td-truncate" *ngIf="editable">
                                            <a class="link --primary" [title]="vehicle.vehicleName || '-'" (click)="onVehicleClick(vehicle.vehicleId);showPopup(SetLimitPopup);">{{vehicle.vehicleName}}</a>
                                        </td>
                                        <td class="td-truncate" *ngIf="!editable">
                                            {{vehicle.vehicleName}}
                                        </td>
                                        <td>
                                            {{vehicle.speedLimit || '-'}}
                                        </td>
                                        <td>
                                            {{vehicle.allowedDriveTimeRange || '-'}}
                                        </td>
                                        <td>
                                            {{vehicle.dailyMileageLimit || '-'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="record-not-found" *ngIf="vehicleDrivingLimitList.length === 0">No Record Found</h2>
                        </div>
                    </div>

                    <div class="block" *ngIf="vehicleDrivingLimitList.length > 0">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination">
                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getVehicleDrivingLimit(pager.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getVehicleDrivingLimit(page)">
                                        {{ page }}
                                    </button>

                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getVehicleDrivingLimit(pager.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Geofence -->
    <div class="tabs__content" [hidden]="currentTab !== 2">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-geofence.svg" width="30" height="22" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input id="geofenceSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Geofence" [(ngModel)]="filterValueGeofence" name="filterGeofence" (keyup.enter)="searchGeofenceName()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchGeofenceName()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table table__driving-limit-geofence" [class.--isDeleting]="isDeletingGeofence">
                            <table class="table__elem">
                                <thead>
                                    <tr>
                                        <ng-container *ngIf="isDeletingGeofence">
                                            <th scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedGeofenceGroups['all']" (ngModelChange)="onGeofenceAllSelectChange()">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container>

                                        <th scope="col">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Name')" (click)="sort('Name')">Geofence Name</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('alertOnEntry')" (click)="sort('alertOnEntry')">Alert Type</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DaysOfWeek')" (click)="sort('DaysOfWeek')">Alert Day</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('timeFrom')" (click)="sort('timeFrom')">Alert Time (hh:mm)</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('affectedVehicle')" (click)="sort('affectedVehicle')">Affected Vehicles</button>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let geofence of vehicleGeofenceList">
                                        <ng-container *ngIf="isDeletingGeofence">
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="geofence.Id" [value]="true" class="inline-input__input" [(ngModel)]="selectedGeofence[geofence.Id]" (ngModelChange)="onGeofenceSelectChange($event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>
                                        </ng-container>
                                        <td *ngIf="editable">
                                            <a class="link --primary" (click)="navigateToEditGeofence(geofence.Id);">{{geofence.Name}}</a>
                                        </td>
                                        <td *ngIf="!editable">
                                            {{geofence.Name}}
                                        </td>
                                        <td>
                                            {{geofence.AlertType || '-'}}
                                        </td>
                                        <td>
                                            {{geofence.AlertDay || '-'}}
                                        </td>
                                        <td>
                                            {{formateDateLabel(geofence.AlertTimeFrom)}} - {{formateDateLabel(geofence.AlertTimeTo)}}
                                        </td>
                                        <td>
                                            {{geofence.AffectedVehicle || '-'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pagerGeofence.currentPage}} / {{pagerGeofence.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination" *ngIf="vehicleGeofenceList.length > 0">
                                    <button type="button" class="pagination__button" [class.--disabled]="pagerGeofence.currentPage === 1" [disabled]="pagerGeofence.currentPage === 1" (click)="getVehicleGeofence(pagerGeofence.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pagerGeofence.pages" class="pagination__button" [class.--active]="page === pagerGeofence.currentPage" (click)="getVehicleGeofence(page)">
                                        {{ page }}
                                    </button>

                                    <button type="button" class="pagination__button" [class.--disabled]="pagerGeofence.currentPage === pagerGeofence.totalPages" [disabled]="pagerGeofence.currentPage === pagerGeofence.totalPages" (click)="getVehicleGeofence(pagerGeofence.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Destination -->
    <div class="tabs__content" [hidden]="currentTab !== 3">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-search-geofence.svg" width="30" height="22" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input id="destinationSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Destination" [(ngModel)]="filterValueDestination" name="filterDestination" (keyup.enter)="searchDestinationName()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchDestinationName()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table">
                            <table class="table__elem">
                                <thead>
                                    <tr>
                                        <ng-container *ngIf="isDeletingDestination">
                                            <th scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDestinationGroups['all']" (ngModelChange)="onDestinationAllSelectChange()">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container>

                                        <th scope="col">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Name')" (click)="sort('Name')">Destination Name</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('affectedVehicle')" (click)="sort('affectedVehicle')">Affected Vehicles</button>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let destination of vehicleDestinationList">
                                        <ng-container *ngIf="isDeletingDestination">
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="destination.Id" [value]="true" class="inline-input__input" [(ngModel)]="selectedDestination[destination.Id]" (ngModelChange)="onDestinationSelectChange($event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>
                                        </ng-container>
                                        <td *ngIf="editable">
                                            <a class="link --primary" (click)="navigateToEditDestination(destination.Id);">{{destination.Name}}</a>
                                        </td>
                                        <td *ngIf="!editable">
                                            {{destination.Name}}
                                        </td>
                                        <td>
                                            {{destination.AffectedVehicle || '-'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pagerDestination.currentPage}} / {{pagerDestination.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination" *ngIf="vehicleDestinationList.length > 0">
                                    <button type="button" class="pagination__button" [class.--disabled]="pagerDestination.currentPage === 1" [disabled]="pagerDestination.currentPage === 1" (click)="getVehicleDestination(pagerDestination.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pagerDestination.pages" class="pagination__button" [class.--active]="page === pagerDestination.currentPage" (click)="getVehicleDestination(page)">
                                        {{ page }}
                                    </button>

                                    <button type="button" class="pagination__button" [class.--disabled]="pagerDestination.currentPage === pagerDestination.totalPages" [disabled]="pagerDestination.currentPage === pagerDestination.totalPages" (click)="getVehicleDestination(pagerDestination.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingDrivingLimit" [hidden]="currentTab !== 1">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedDrivingLimit()}} driving limit(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteLimitPopup)" [disabled]="countSelectedDrivingLimit() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteLimitPopup="popup" class="cm-popups" [hidden]="currentTab !== 1">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedDrivingLimit()}}</span> driving limit(s)
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteDrivingLimit();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingGeofence" [hidden]="currentTab !== 2">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedGeofence()}} geofence(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteGeofencePopup)" [disabled]="countSelectedGeofence() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>
                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingDestination" [hidden]="currentTab !== 3">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedDestination()}} destination(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteDestinationPopup)" [disabled]="countSelectedDestination() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>
                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteDestinationPopup="popup" class="cm-popups" [hidden]="currentTab !== 3">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedDestination()}}</span> destination(s)
        </p>

        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteVehicleDestination();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #deleteGeofencePopup="popup" class="cm-popups" [hidden]="currentTab !== 2">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedGeofence()}}</span> geofence(s)
        </p>

        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteVehicleGeofence();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #SetLimitPopup="popup" class="set-limit-popups">
    <div class="popup__block">
        <div class="grid --gutter --flex-bottom">
            <div class="grid__block w-8of12@sm form__flex">
                <h1 class="heading indicator-green">
                    Edit Driving Limits
                </h1>
            </div>
        </div>
    </div>

    <div class="popup__block">
        <h2 class="heading --level-2">

        </h2>
    </div>

    <form class="form-field" #drivingLimitForm="ngForm">
        <div class="popup__block">
            <div class="field-group__input set-limit-field">
                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isSpeedLimitOff" name="isSpeedLimitOff" #isSpeedLimitOnOff="ngModel">

                            Speed Limit (km/h):
                        </mat-slide-toggle>
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="number" class="field__input" [class.--disabled]="!isSpeedLimitOff" [disabled]="!isSpeedLimitOff" [required]="isSpeedLimitOff ? '' : null" name="speedLimit" [(ngModel)]="speedLimit" OnlyNumber OnlyInteger min="0" max="120">
                                </label>

                                <div class="field__ui"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isAllowedTimeOff" name="isAllowedTimeOff" #isAllowedTimeOnOff="ngModel">

                            Allowed Driving Hours (hh:mm):
                        </mat-slide-toggle>
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field-group__groups-inner field-group__time">
                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" [(ngModel)]="allowedTimeFromHour" name="allowedTimeFromHour" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                        <option value="" selected disabled hidden>HH</option>
                                        <option *ngFor="let hour of timeSelector.hour" [value]="hour">{{hour}}</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__time-inner --no-padding">
                                :
                            </div>

                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" [(ngModel)]="allowedTimeFromMinute" name="allowedTimeFromMinute" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                        <option value="" selected disabled hidden>MM</option>
                                        <option *ngFor="let minute of timeSelector.minute" [value]="minute">{{minute}}</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" [(ngModel)]="allowedTimeFromAMPM" name="allowedTimeFromAMPM" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div class="field-group__groups-inner">
                            -
                        </div>

                        <div class="field-group__groups-inner field-group__time">
                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" [(ngModel)]="allowedTimeToHour" name="allowedTimeToHour" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                        <option value="" selected disabled hidden>HH</option>
                                        <option *ngFor="let hour of timeSelector.hour" [value]="hour">{{hour}}</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__time-inner --no-padding">
                                :
                            </div>

                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" [(ngModel)]="allowedTimeToMinute" name="allowedTimeToMinute" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                        <option value="" selected disabled hidden>MM</option>
                                        <option *ngFor="let minute of timeSelector.minute" [value]="minute">{{minute}}</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" [(ngModel)]="allowedTimeToAMPM" name="allowedTimeToAMPM" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isDailyMileageLimitOff" name="isDailyMileageLimitOff" #isSpeedLimitOnOff="ngModel">

                            Daily Mileage (km):
                        </mat-slide-toggle>
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input" [class.--disabled]="!isDailyMileageLimitOff" [disabled]="!isDailyMileageLimitOff" name="dailyMileageLimit" [(ngModel)]="dailyMileageLimit" [required]="isDailyMileageLimitOff ? '' : null" OnlyNumber OnlyInteger>
                                </label>

                                <div class="field__ui"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup__block">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" [disabled]="drivingLimitForm.invalid" (click)="showPopup(updateDrivingLimitPopup);">
                    Save
                </button>

                <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                    Cancel
                </button>
            </div>
        </div>
    </form>
</app-popup>

<app-popup #updateDrivingLimitPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateDrivingLimit();hidePopup();">
                Confirm
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();showPopup(SetLimitPopup);">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
