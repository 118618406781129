import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import imageCompression from 'browser-image-compression';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from './../../../common/spinner/spinner.component';

// Service
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { PopupService } from '../../../../components/common/popup/popup.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';

// Util
import * as NumberUtil from './../../../../util/numberUtil';
import * as ComponentUtil from './../../../../util/componentUtil';
import * as StringUtil from './../../../../util/stringUtil';
import * as AsyncUtil from './../../../../util/asyncUtil';
import * as ImageUtil from '../../../../util/imageUtil';
import * as DomUtil from '../../../../util/domUtil';

// Constant
import * as Message from '../../../../constants/message';
// import * as FuelConstant from '../../../../../constants/fuelType.constant';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

@Component({
    selector: 'app-edit-vehicle',
    templateUrl: './edit-vehicle.component.html',
    styleUrls: ['./edit-vehicle.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class EditVehicleComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    @ViewChild('editCropImagePopup',{static:false}) editCropImagePopup;
    @ViewChild(ImageCropperComponent,{static:false}) imageCropper: ImageCropperComponent;
    @ViewChild("cropImage_spinner",{static:false}) cropImage_spinner: SpinnerComponent;
    imageChangedEvent: any = '';
    croppedImageData: any = '';

    // fuelConstant = FuelConstant;
    // fuelTypeList:any = [];
    yearList: any = [];

    message = Message;
    vehicleMakeOthers: boolean = false;
    vehicleModelOthers: boolean = false;

    vehicleId: number;
    oriVehicleDetails: any = {};
    vehicleDetails: any = {};
    vehicleMakeModelList: any = [];
    vehicleModelList: any = [];
    roadTaxDaysApplyAll: boolean = false;
    puspakomDaysApplyAll: boolean = false;    

    @ViewChild('imageAlertPopup',{static:false}) imageAlertPopup;
    hasImage: boolean = false;
    isImageChanged: boolean = false;
    maxSizeMB = environment.appConfig.createManage.maxSizeMB;
    maxWidthOrHeight = environment.appConfig.createManage.maxWidthOrHeight;
    maxSizeImageMB = environment.appConfig.createManage.maxSizeImageMB;
    imageAlertError = null;

    //date picket touch ui
    datepickerTouchUi: boolean = false;
    lastResizeTime: any = new Date(0);
    resizeTimeout: any;

    routerSubscription: Subscription;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private router: Router,
        private route: ActivatedRoute,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private vehicleService: VehicleService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        this.vehicleId = this.route.snapshot.params.id;
        await this.onInitCommonFunction();
        this.updateDatepickerTouchUi();

        // Add event listener to route events to allow same page navigations
        ComponentUtil.makeSamePageNavigationWorks({
            pageUrlConstant: 'create-manage/vehicles/edit/',
            componentSubscription: this.routerSubscription,
            componentPreInitCallback: null,
            componentInitCallback: this.ngOnInit.bind(this),
            componentDestroyCallback: null,
            componentRouter: this.router
        });
        DomUtil.autoFocusForm(this.el);
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async onInitCommonFunction() {
        this.pageSpinner.show();
        try {
            this.getYearList(40); //default get 40 years
            // this.fuelTypeList = this.fuelConstant.getFuelTypeList();
            await this.getVehicleDetails();
            await this.getVehicleMakeModel();
            this.getDefaultVehicleModel();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    // setImage() {
    //     if (this.vehicleDetails.vehicleImage) {
    //         this.vehicleDetails.vehicleImage = `data:image/jpeg;base64,${this.vehicleDetails.vehicleImage}`;
    //         this.hasImage = true;
    //     }
    // }

    async getVehicleDetails() {
        const res = await this.vehicleService.getVehicleInfoDetailsByVid(this.vehicleId);

        this.vehicleDetails = res.body.vehicleDetails;
        if (this.vehicleDetails.vehicleImage) {
            this.vehicleDetails.vehicleImage = `data:image/jpeg;base64,${this.vehicleDetails.vehicleImage}`;
            this.hasImage = true;
        }
        if (this.vehicleDetails.engineHour) {
            this.vehicleDetails.engineHour = Math.round(this.vehicleDetails.engineHour);
        }
        if (this.vehicleDetails.currentOdometer) {
            this.vehicleDetails.currentOdometer = Number.parseFloat(this.vehicleDetails.currentOdometer).toFixed(1);
        }
        this.oriVehicleDetails = Object.assign({}, this.vehicleDetails);
        this.vehicleDetails.isManualOdometer = this.vehicleDetails.isManualOdometer === 1 ? true : false;
        // this.vehicleDetails.currentOdometer = NumberUtil.formatFloat(this.vehicleDetails.currentOdometer, 1, '0.0');
        // this.vehicleDetails.engineHour = NumberUtil.formatFloat(this.vehicleDetails.engineHour, 1, '0.0');

    }

    async getVehicleMakeModel() {
        const res = await this.vehicleService.getVehicleMakeModel();
        this.vehicleMakeModelList = res.body;
        // console.debug(this.vehicleMakeModelList);
    }

    vehicleMakeChoose($event) {
        this.vehicleDetails.vehicleModelId = null;
        const vehicleMakeId = $event.srcElement.value;
        if ($event.srcElement.value !== 'others') {
            const tempVehicleModel = this.vehicleMakeModelList.filter(result => result.Id === parseInt(vehicleMakeId)).map(result => result.vehicleModel);
            this.vehicleModelList = tempVehicleModel[0];
            this.vehicleMakeOthers = false;
            this.vehicleModelOthers = false;
        } else {
            this.vehicleModelList = [];
            this.vehicleMakeOthers = true;
            this.vehicleModelOthers = true;
        }
    }

    vehicleModelChoose($event) {
        if ($event.srcElement.value === 'others') {
            this.vehicleModelOthers = true;
        } else {
            this.vehicleModelOthers = false;
        }
    }

    getYearList(yearNumber: number) {
        let currentYear = parseInt(moment().format('YYYY'));
        for (let i = 0; i < yearNumber; i++) {
            this.yearList.push(currentYear);
            currentYear = currentYear - 1;
        }
    }

    convertYearToInt() {
        this.vehicleDetails.vehicleYear = parseInt(this.vehicleDetails.vehicleYear);
    }

    getDefaultVehicleModel() {
        if (this.vehicleDetails.vehicleMakeId === null) {
            this.vehicleMakeOthers = true;
            this.vehicleModelOthers = true;
        } else if (!this.vehicleDetails.vehicleModelId && this.vehicleDetails.vehicleModelOthers) {
            // Display other for vehicle model
            this.vehicleModelOthers = true;
            const vehicleModelOthersSelectBox: any = document.getElementById('vehicleModelOthersSelectBox');
            vehicleModelOthersSelectBox.value = 'others';
        }
        const tempVehicleModel = this.vehicleMakeModelList.filter(result => result.Id === this.vehicleDetails.vehicleMakeId).map(result => result.vehicleModel);
        this.vehicleModelList = tempVehicleModel[0];
    }

    async updateVehicleInfo() {
        // console.debug("Updating Vehicle Info");
        this.pageSpinner.show();
        try {

            //Clone Object
            const tempUpdateVehicleDetails = Object.assign({}, this.vehicleDetails);

            // Check if Vehicle Make/Model is choose others
            if (tempUpdateVehicleDetails.vehicleMakeId === 'others') {
                tempUpdateVehicleDetails.vehicleMakeId = null;
            }
            if (tempUpdateVehicleDetails.vehicleModelId === 'others') {
                tempUpdateVehicleDetails.vehicleModelId = null;
            }
            //Convert DateTime from datepicker to YYYYMMDD (if not will auto minus 8 hours when pass to backend)
            if (tempUpdateVehicleDetails.roadTaxExpiry) {
                tempUpdateVehicleDetails.roadTaxExpiry = moment(tempUpdateVehicleDetails.roadTaxExpiry).format('YYYY-MM-DD');
            }
            if (tempUpdateVehicleDetails.puspakomDate) {
                tempUpdateVehicleDetails.puspakomDate = moment(tempUpdateVehicleDetails.puspakomDate).format('YYYY-MM-DD');
            }
            if (tempUpdateVehicleDetails.engineHour) {
                tempUpdateVehicleDetails.engineHour = parseInt(tempUpdateVehicleDetails.engineHour); //Convert to int
            }
            if (tempUpdateVehicleDetails.currentOdometer) {
                tempUpdateVehicleDetails.currentOdometer = parseInt(tempUpdateVehicleDetails.currentOdometer); //Convert to int
            }
            if (tempUpdateVehicleDetails.fuelEfficiency) {
                tempUpdateVehicleDetails.fuelEfficiency = parseFloat(tempUpdateVehicleDetails.fuelEfficiency);
            }
            // if (tempUpdateVehicleDetails.roadTaxReminderDays) {
            if (!StringUtil.isNotEmptyOrNull(tempUpdateVehicleDetails.roadTaxReminderDays)) {
                tempUpdateVehicleDetails.roadTaxReminderDays = 30;
            } else {
                tempUpdateVehicleDetails.roadTaxReminderDays = parseInt(tempUpdateVehicleDetails.roadTaxReminderDays);
            }
            // }
            // if (tempUpdateVehicleDetails.puspakomReminderDays) {
            if (!StringUtil.isNotEmptyOrNull(tempUpdateVehicleDetails.puspakomReminderDays)) {
                tempUpdateVehicleDetails.puspakomReminderDays = 30;
            } else {
                tempUpdateVehicleDetails.puspakomReminderDays = parseInt(tempUpdateVehicleDetails.puspakomReminderDays);
            }
            // }

            // Do not compress image before checking for unchanged flag
            let newImage;
            if (this.hasImage) {
                const img: any = <HTMLElement>document.getElementById("imageId");
                if (img) {
                    newImage = await ImageUtil.compressImageSize(img.src, this.maxSizeMB, this.maxWidthOrHeight, this.platform); // maxSizeMB, maxWidthOrHeight are optional
                    await AsyncUtil.wait(2000); // Wait for image compress
                } else {
                    newImage = null;
                }
                // Invalid image -> always update to blank
                if (!newImage) {
                    tempUpdateVehicleDetails.vehicleImage = '';
                } else if (this.isImageChanged) {
                    tempUpdateVehicleDetails.vehicleImage = newImage;
                }
            }

            // console.log('old: ' + this.oriVehicleDetails.vehicleImage);
            // console.log('new: ' + tempUpdateVehicleDetails.vehicleImage);

            //Compare diff before and after
            for (const objKey in tempUpdateVehicleDetails) {
                if (tempUpdateVehicleDetails.hasOwnProperty(objKey)) {
                    if (tempUpdateVehicleDetails[objKey] == this.oriVehicleDetails[objKey]) {
                        // console.debug('EditVehicle: ' + objKey + ' unchanged, removed from API body.');
                        delete tempUpdateVehicleDetails[objKey];
                        if (objKey == 'vehicleImage') {
                            console.debug('EditVehicle: Image unchanged');
                        }
                    }
                }
            }

            tempUpdateVehicleDetails.imeiId = this.vehicleDetails.imeiId;
            tempUpdateVehicleDetails.vehiclePlateNo = this.vehicleDetails.vehiclePlateNo;
            tempUpdateVehicleDetails.imeiNo = this.vehicleDetails.imeiNo;
            tempUpdateVehicleDetails.vehicleName = this.vehicleDetails.vehicleName;
            if (this.roadTaxDaysApplyAll) {
                tempUpdateVehicleDetails.roadTaxDaysApplyAll = this.roadTaxDaysApplyAll;
                if (!StringUtil.isNotEmptyOrNull(this.vehicleDetails.roadTaxReminderDays)) {
                    tempUpdateVehicleDetails.roadTaxReminderDays = 30;
                } else {
                    tempUpdateVehicleDetails.roadTaxReminderDays = parseInt(this.vehicleDetails.roadTaxReminderDays);
                }
            }
            if (this.puspakomDaysApplyAll) {
                tempUpdateVehicleDetails.puspakomDaysApplyAll = this.puspakomDaysApplyAll;
                if (!StringUtil.isNotEmptyOrNull(this.vehicleDetails.puspakomReminderDays)) {
                    tempUpdateVehicleDetails.puspakomReminderDays = 30;
                } else {
                    tempUpdateVehicleDetails.puspakomReminderDays = parseInt(this.vehicleDetails.puspakomReminderDays);
                }
            }
            tempUpdateVehicleDetails.channel = this.vehicleDetails.channel;
            tempUpdateVehicleDetails.isManualOdometer = this.vehicleDetails.isManualOdometer ? true : false;
            const result = await this.vehicleService.updateVehicleInfoDetails(this.vehicleId, tempUpdateVehicleDetails);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Vehicle', this.vehicleDetails.vehicleName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = this.message.getMessage(this.message.MESSAGE.UPDATE_SUCCESS.value, "Vehicle", this.vehicleDetails.vehicleName);
                this.navigateSuccessToVehicleView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async chooseImage(item: any) {
        if (item.target.files.length > 0 && item.target.files[0].type.indexOf("image") > -1) {
            const imageFile = item.target.files[0];

            if ((imageFile.size / 1024 / 1024) < this.maxSizeImageMB) { //Check Image Size
                this.isImageChanged = true;
                this.hasImage = true;
                this.imageChangedEvent = item;
                this.showPopup(this.editCropImagePopup);
            } else {
                this.imageAlertError = 'sizeError';
                this.showPopup(this.imageAlertPopup);
            }
        } else {
            this.imageAlertError = 'fileError';
            this.showPopup(this.imageAlertPopup);
        }
    }

    navigateSuccessToVehicleView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/vehicles/view'], null, 'view-edit');
    }

    navigateToVehicleView() {
        this.routerProxyService.navigate(['/create-manage/vehicles/view']);
    }

    /* ---- popup table ---- */
    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }

    updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

    confirmCropImage() {
        const img: any = <HTMLElement>document.getElementById("imageId");
        img.src = this.croppedImageData;
    }

    imageDirection(direction) {
        try {
            this.cropImage_spinner.show();
            if (direction === 'left') {
                this.imageCropper.rotateLeft();
            } else if (direction === 'right') {
                this.imageCropper.rotateRight();
            }
        } catch (e) {
            // do nothing
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImageData = event.base64;
        //const img: any = <HTMLElement>document.getElementById("imageId");
        //img.src = this.croppedImageData;
    }

    cropperReady() {
        const fileInput: any = document.getElementById('myFileInput');
        if (fileInput && fileInput.value) { fileInput.value = null; } // Clean previous file
        this.cropImage_spinner.hide();
    }

    isPlugAndPlayInstallation() {

        try {

            return this.vehicleDetails.installationType === "PLUG_PLAY";

        } catch (e) {

            // in case of unexpected errors, simply return false
            return false;

        }

    }

}
