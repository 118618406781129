import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './_services/auth/auth-guard.service';
import { CompanyGuardService as CompanyGuard } from './_services/auth/company-guard.service'
import { environment } from './../environments/environment';

//Site
import { LoginComponent } from './components/site/login/login.component';
import { SsoLoginComponent } from "./components/site/sso-login/sso-login.component";
import { EmptyComponent } from './components/site/empty/empty.component';
import { DashboardComponent } from './components/modules/dashboard/dashboard.component';
import { HomePageComponent } from './components/site/home-page/home-page.component';
import { RedirectAppDownloadComponent } from './components/site/redirect-app-download/redirect-app-download.component';
import { ErrorComponent } from './components/site/error/error.component';

//Maxis Support Panel
import { MaxisSupportPanelComponent } from './components/modules/maxis-support-panel/maxis-support-panel.component';
import { CustomerViewComponent } from './components/modules/maxis-support-panel/customer-view/customer-view.component';
import { ResetPasswordComponent } from './components/modules/maxis-support-panel/reset-password/reset-password.component';
import { IdManagementComponent } from './components/modules/maxis-support-panel/id-management/id-management.component';
import { CreateSupportComponent } from './components/modules/maxis-support-panel/id-management/create-support/create-support.component';
import { EditSupportComponent } from './components/modules/maxis-support-panel/id-management/edit-support/edit-support.component';
import { ManageCampaignsComponent } from './components/modules/maxis-support-panel/manage-campaigns/manage-campaigns.component';
import { CreateCampaignsComponent } from './components/modules/maxis-support-panel/manage-campaigns/create-campaigns/create-campaigns.component';
import { EditCampaignsComponent } from './components/modules/maxis-support-panel/manage-campaigns/edit-campaigns/edit-campaigns.component';
import { ManageDeviceComponent } from './components/modules/maxis-support-panel/manage-device/manage-device.component';
import { CompanyDetailsComponent } from './components/modules/maxis-support-panel/manage-device/add-device/company-details/company-details.component';
import { VehicleDetailsComponent } from './components/modules/maxis-support-panel/manage-device/add-device/vehicle-details/vehicle-details.component';
import { EditCompanyDetailsComponent } from './components/modules/maxis-support-panel/manage-device/edit-multiple/company-details/edit-company-details.component';
import { EditVehicleDetailsComponent } from './components/modules/maxis-support-panel/manage-device/edit-multiple/vehicle-details/edit-vehicle-details.component';
import { SwitchProviderComponent } from './components/modules/maxis-support-panel/manage-device/switch-provider/switch-provider.component';
import { VehicleTerminationComponent } from './components/modules/maxis-support-panel/vehicle-termination/vehicle-termination.component';

//Map Trips
import { MapComponent } from './components/modules/map/map.component';
import { MapListComponent } from "./components/modules/map/map-list/map-list.component";
import { VehiclePanelComponent } from "./components/modules/vehicle-panel/vehicle-panel.component";
import { TripSummaryComponent } from './components/modules/reports/trip-summary/trip-summary.component';

//Report
import { ViolationsReportComponent } from './components/modules/reports/violations/violations-reports.component';
import { MaintenanceReportComponent } from './components/modules/reports/maintenance/maintenance-report.component';
import { UtilizationReportComponent } from './components/modules/reports/utilization/utilization-report.component';
import { ScoringReportComponent } from './components/modules/reports/scoring/scoring-report.component';
import { GeofenceReportComponent } from './components/modules/reports/geofence-report/geofence-report.component';
import { RefuelLogsReportComponent } from './components/modules/reports/refuel-logs-report/refuel-logs-report.component';
import { FuelUsageReportComponent } from './components/modules/reports/fuel-usage-report/fuel-usage-report.component';
import { FuelReportComponent } from './components/modules/reports/fuel-report/fuel-report.component';
import { VehicleActivityReportComponent } from './components/modules/reports/vehicle-activity-report/vehicle-activity-report.component';
import { VehicleStatusReportComponent } from './components/modules/reports/vehicle-status-report/vehicle-status-report.component';
import { TestImageComponent } from './components/modules/test-image/test-image.component';
import { SpeedingReportComponent } from './components/modules/reports/speeding-report/speeding-report.component';
import { IdlingReportComponent } from './components/modules/reports/idling-report/idling-report.component';
import { VehicleRankingComponent } from './components/modules/reports/vehicle-ranking/vehicle-ranking.component';
import { AlertHistoryReportComponent } from './components/modules/reports/alert-history-report/alert-history-report.component';
import { DriverRankingComponent } from './components/modules/reports/driver-ranking/driver-ranking.component';
import { GeofenceMonitoringReportComponent } from './components/modules/reports/geofence-monitoring-report/geofence-monitoring-report.component';
import { VehicleTemperatureReportComponent } from './components/modules/reports/vehicle-temperature-report/vehicle-temperature-report.component';
import { IncidentAnalysisComponent } from './components/modules/reports/incident-analysis/incident-analysis.component';
import { CreateIncidentAnalysisComponent } from './components/modules/reports/incident-analysis/create-incident-analysis/create-incident-analysis.component';
import { ViewIncidentAnalysisComponent } from './components/modules/reports/incident-analysis/view-incident-analysis/view-incident-analysis.component';
import { RouteCompletionReportComponent } from './components/modules/reports/route-completion-report/route-completion-report.component';

//Customized Report
import { CustomizedUtilizationReportComponent } from './components/modules/reports/customized-utilization-report/customized-utilization-report.component'
import { CustomizedDriverAnalysisReportComponent } from './components/modules/reports/customized-driver-analysis-report/customized-driver-analysis-report.component';
import { CustomizedPanicButtonReportComponent } from './components/modules/reports/customized-panic-button-report/customized-panic-button-report.component';

//Groups
import { ViewGroupsComponent } from './components/modules/groups/view-groups/view-groups.component';
import { CreateGroupsComponent } from './components/modules/groups/create-groups/create-groups.component';
import { EditGroupComponent } from './components/modules/groups/edit-group/edit-group.component';

//Admins
import { ViewAdminsComponent } from './components/modules/admins/view-admins/view-admins.component';
import { CreateAdminComponent } from './components/modules/admins/create-admin/create-admin.component';
import { EditAdminComponent } from './components/modules/admins/edit-admin/edit-admin.component';
import { CreateRoleComponent } from './components/modules/admins/create-role/create-role.component';
import { EditRoleComponent } from './components/modules/admins/edit-role/edit-role.component';

//Drivers
import { ViewDriversComponent } from './components/modules/drivers/view-drivers/view-drivers.component';
import { EditDriverComponent } from './components/modules/drivers/edit-driver/edit-driver.component';
import { CreateDriverComponent } from './components/modules/drivers/create-driver/create-driver.component';

//Driver Tags
import { ViewDriverTagsComponent } from './components/modules/driver-tag/view-driver-tags/view-driver-tags.component';
import { CreateDriverTagComponent } from './components/modules/driver-tag/create-driver-tag/create-driver-tag.component';

//Vehicles
import { ViewVehiclesComponent } from './components/modules/vehicles/view-vehicles/view-vehicles.component';
import { EditVehicleComponent } from './components/modules/vehicles/edit-vehicle/edit-vehicle.component';
import { AssignDriverComponent } from './components/modules/vehicles/assign-driver/assign-driver.component';

//Maintenance
import { ViewMaintenanceComponent } from './components/modules/maintenances/view-maintenance/view-maintenance.component';
import { CreateMaintenanceComponent } from './components/modules/maintenances/create-maintenance/create-maintenance.component';
import { EditMaintenanceComponent } from './components/modules/maintenances/edit-maintenance/edit-maintenance.component';

//Trip tags
import { ViewTripTagsComponent } from './components/modules/trip-tags/view-trip-tags/view-trip-tags.component';
import { CreateTripTagsComponent } from './components/modules/trip-tags/create-trip-tags/create-trip-tags.component';
import { EditTripTagsComponent } from './components/modules/trip-tags/edit-trip-tags/edit-trip-tags.component';

//Driving limits
import { ViewDrivingLimitsComponent } from './components/modules/driving-limits/view-driving-limits/view-driving-limits.component';
import { SetLimitComponent } from './components/modules/driving-limits/set-limit/set-limit.component';
import { CreateGeofenceComponent } from './components/modules/driving-limits/create-geofence/create-geofence.component';
import { EditGeofenceComponent } from './components/modules/driving-limits/edit-geofence/edit-geofence.component';
import { CreateDestinationComponent } from './components/modules/driving-limits/create-destination/create-destination.component';
import { EditDestinationComponent } from './components/modules/driving-limits/edit-destination/edit-destination.component';

// Checkpoints
import { ViewRoutesComponent } from './components/modules/routes/view-routes/view-routes.component';
import { CreateRouteComponent } from './components/modules/routes/create-route/create-route.component';
import { EditRouteComponent } from './components/modules/routes/edit-route/edit-route.component';
import { CreateCheckpointComponent } from './components/modules/routes/create-checkpoint/create-checkpoint.component';
import { EditCheckpointComponent } from './components/modules/routes/edit-checkpoint/edit-checkpoint.component';

//Fuel
import { ViewFuelComponent } from './components/modules/fuel/view-fuel/view-fuel.component';
import { SetFuelLimitsComponent } from './components/modules/fuel/set-fuel-limits/set-fuel-limits.component';
import { CreateRefuelEntryComponent } from './components/modules/fuel/create-refuel-entry/create-refuel-entry.component';

//My Account
import { EditInformationComponent } from './components/modules/my-account/edit-information/edit-information.component';
import { ChangePasswordComponent } from './components/modules/my-account/change-password/change-password.component';
import { NotificationSettingsComponent } from './components/modules/my-account/notification-settings/notification-settings.component';

// User
import { ActivateResetPasswordComponent } from './components/modules/user/activate-change-password/activate-change-password.component';

// Support User Guide
import { UserGuideComponent } from './components/modules/support/user-guide/user-guide.component';

//Document Uploads
import { ViewDocumentUploadComponent } from './components/modules/upload-documents/view-document-upload/view-document-upload.component';
import { SaleDashboardViewComponent } from './components/modules/maxis-support-panel/sale-dashboard/sale-dashboard-view/sale-dashboard-view.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login/sso',
        component: SsoLoginComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user/activate-change-password',
        component: ActivateResetPasswordComponent
    },
    {
        path: 'app-download',
        component: RedirectAppDownloadComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: environment.appConfig.page.mapUrlPath, // 'map'
        component: MapComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'vehiclelist',
        outlet: 'leftbar',
        component: MapListComponent
    },
    {
        path: 'vehicle/:imei',
        outlet: 'leftbar',
        component: VehiclePanelComponent,
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'support',
        component: UserGuideComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/panel',
        component: MaxisSupportPanelComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage/companies',
        component: CustomerViewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage/companies/reset-password',
        component: ResetPasswordComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage/admins',
        component: IdManagementComponent,
        data: {
            reuseRoute: true
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage/admins/create',
        component: CreateSupportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage/admins/edit',
        component: EditSupportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage/manage-campaigns',
        component: ManageCampaignsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage-device-test',
        component: TestImageComponent,
        // canActivate: [AuthGuard]
    },
    {
        path: 'support/manage/manage-campaigns/create-campaigns',
        component: CreateCampaignsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage/manage-campaigns/edit-campaigns/:id',
        component: EditCampaignsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage-device',
        component: ManageDeviceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/terminate-vehicles',
        component: VehicleTerminationComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/sale-dashboard',
        component: SaleDashboardViewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage-device/add-device/company-details/:newOrder',
        component: CompanyDetailsComponent,
        data: {
            reuseRoute: true
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage-device/add-device/vehicle-details',
        component: VehicleDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage-device/edit-multiple/company-details/:ids',
        component: EditCompanyDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage-device/edit-multiple/vehicle-details/:ids',
        component: EditVehicleDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'support/manage-device/switch-provider/:ids',
        component: SwitchProviderComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        data: {
            breadcrumb: {
                label: 'Reports',
                allowClick: false
            }
        },
        children: [
            {
                path: 'trip-summary',
                component: TripSummaryComponent,
                data: {
                    breadcrumb: {
                        label: 'Trip Summary Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'trip-violation',
                component: ViolationsReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Violations Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'geofence',
                component: GeofenceReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Geofence Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'utilization',
                component: UtilizationReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Utilisation Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'scoring',
                component: ScoringReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Scoring Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'maintenance',
                component: MaintenanceReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Maintenance Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'fuel-report',
                component: FuelReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Fuel Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'vehicle-status',
                component: VehicleStatusReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Vehicle Status Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'vehicle-activity',
                component: VehicleActivityReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Vehicle Activity Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'speeding',
                component: SpeedingReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Overspeeding Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'idling',
                component: IdlingReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Idling Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'vehicle-ranking',
                component: VehicleRankingComponent,
                data: {
                    breadcrumb: {
                        label: 'Vehicle Ranking Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'alert-history',
                component: AlertHistoryReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Alert History Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'driver-ranking',
                component: DriverRankingComponent,
                data: {
                    breadcrumb: {
                        label: 'Driver Ranking Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'geofence-monitoring',
                component: GeofenceMonitoringReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Geofence Monitoring Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'vehicle-temperature',
                component: VehicleTemperatureReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Vehicle Temperature Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'route-completion',
                component: RouteCompletionReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Route Completion Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'incident-analysis',
                component: IncidentAnalysisComponent,
                data: {
                    breadcrumb: {
                        label: 'Incident Analysis Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            },
            {
                path: 'incident-analysis/create',
                component: CreateIncidentAnalysisComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'incident-analysis/details/:id',
                data: {
                    breadcrumb: {
                        label: 'View incident analysis details',
                        allowClick: true
                    }
                },
                component: ViewIncidentAnalysisComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'create-manage',
        data: {
            breadcrumb: {
                label: 'Create / Manage',
                allowClick: false
            }
        },
        children: [
            {
                path: 'admins',
                data: {
                    breadcrumb: {
                        label: 'Admins',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewAdminsComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create',
                        component: CreateAdminComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create Admin',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit/:id',
                        component: EditAdminComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Admins',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create-role',
                        component: CreateRoleComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create Role',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit-role/:id',
                        component: EditRoleComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Role',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'vehicles',
                data: {
                    breadcrumb: {
                        label: 'Vehicles',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewVehiclesComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit/:id',
                        component: EditVehicleComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Vehicle',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'assign/:id',
                        component: AssignDriverComponent,
                        data: {
                            breadcrumb: {
                                label: 'Assign Driver',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'drivers',
                data: {
                    breadcrumb: {
                        label: 'Drivers',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewDriversComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create',
                        component: CreateDriverComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create Driver',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit/:id',
                        component: EditDriverComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Driver',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'driver-tags',
                data: {
                    breadcrumb: {
                        label: 'Driver Tags',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewDriverTagsComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create',
                        component: CreateDriverTagComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create Driver Tag',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'groups',
                data: {
                    breadcrumb: {
                        label: 'Groups',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewGroupsComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'view/:type',
                        component: ViewGroupsComponent,
                        data: {
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create/:type',
                        component: CreateGroupsComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create New Group',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit/:type/:id',
                        component: EditGroupComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Group',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                ]
            },
            {
                path: 'maintenance',
                data: {
                    breadcrumb: {
                        label: 'Maintenance',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewMaintenanceComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create',
                        component: CreateMaintenanceComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create Maintenance',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit/:id',
                        component: EditMaintenanceComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Maintenance',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                ]
            },
            {
                path: 'fuel',
                data: {
                    breadcrumb: {
                        label: 'Fuel',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewFuelComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'set-vehicle-kpi',
                        component: SetFuelLimitsComponent,
                        data: {
                            breadcrumb: {
                                label: 'Set KPI',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create-refuel-entry',
                        component: CreateRefuelEntryComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create Refuel Entry',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'driving-limits',
                data: {
                    breadcrumb: {
                        label: 'Driving Limits',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewDrivingLimitsComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'set-vehicle-limit',
                        component: SetLimitComponent,
                        data: {
                            breadcrumb: {
                                label: 'Set Limit',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create-geofence',
                        component: CreateGeofenceComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create New Geofence',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit-geofence/:id',
                        component: EditGeofenceComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Geofence',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create-destination',
                        component: CreateDestinationComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create New Destination',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit-destination/:id',
                        component: EditDestinationComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Destination',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'routes',
                data: {
                    breadcrumb: {
                        label: 'Routes',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewRoutesComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create-route',
                        component: CreateRouteComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create New Route',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit-route/:id',
                        component: EditRouteComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Route',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create-checkpoint/:id',
                        component: CreateCheckpointComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create New Checkpoint',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit-checkpoint/:routeId/:checkpointId',
                        component: EditCheckpointComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Checkpoint',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'trip-tags',
                data: {
                    breadcrumb: {
                        label: 'Trip Tags',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewTripTagsComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'create',
                        component: CreateTripTagsComponent,
                        data: {
                            breadcrumb: {
                                label: 'Create New',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'edit/:id',
                        component: EditTripTagsComponent,
                        data: {
                            breadcrumb: {
                                label: 'Edit Trip Tags',
                                allowClick: true
                            }
                        },
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'upload-documents',
                data: {
                    breadcrumb: {
                        label: 'Upload Documents',
                        allowClick: true
                    }
                },
                children: [
                    { path: '', redirectTo: 'view', pathMatch: 'full' },
                    {
                        path: 'view',
                        component: ViewDocumentUploadComponent,
                        data: {
                            reuseRoute: true,
                            breadcrumb: null
                        },
                        canActivate: [AuthGuard]
                    },

                ]
            },
        ]
    },
    {
        path: 'customized-reports',
        data: {
            breadcrumb: {
                label: 'Customized Reports',
                allowClick: false
            }
        },
        children: [
            {
                path: 'equipment-utilization',
                component: CustomizedUtilizationReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Equipment Utilisation Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard, CompanyGuard]
            },
            {
                path: 'driver-analysis',
                component: CustomizedDriverAnalysisReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Driver Analysis Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard, CompanyGuard]
            },
            {
                path: 'panic-button',
                component: CustomizedPanicButtonReportComponent,
                data: {
                    breadcrumb: {
                        label: 'Panic Button Report',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard, CompanyGuard]
            }
        ]
    },
    {
        path: 'my-account',
        data: {
            breadcrumb: {
                label: 'My Account',
                allowClick: false
            }
        },
        children: [
            {
                path: 'edit-information',
                component: EditInformationComponent,
                data: {
                    breadcrumb: {
                        label: 'Edit Information',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            }, {
                path: 'change-password',
                component: ChangePasswordComponent,
                data: {
                    breadcrumb: {
                        label: 'Change Password',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            }, {
                path: 'notification-settings',
                component: NotificationSettingsComponent,
                data: {
                    breadcrumb: {
                        label: 'Notification Settings',
                        allowClick: true
                    }
                },
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: 'empty',
        outlet: 'leftbar',
        component: EmptyComponent,
    },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        // otherwise redirect to home page
        path: '**',
        redirectTo: '/'
    }
];

@NgModule({

    /* https://angular.io/api/router/ExtraOptions */
    imports: [RouterModule.forRoot(routes, {
        enableTracing: false,
        onSameUrlNavigation: 'reload',
        useHash: false,
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]

})

export class AppRoutingModule { }
