import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

import { environment } from './../../../../../environments/environment';

import * as StringUtil from '../../../../util/stringUtil';
import * as Message from '../../../../constants/message';
import { SpinnerComponent } from '../../../common/spinner/spinner.component';
import { GroupService } from '../../../../_services/group/group.service';
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';
import { modules } from '../../../../constants/module-access.constant';

@Component({
    selector: 'app-view-groups',
    templateUrl: './view-groups.component.html',
    styleUrls: ['./view-groups.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ViewGroupsComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    @ViewChild('page_spinner',{static:true}) pageSpinner: SpinnerComponent;

    groupType = 'vehicle';
    groupTypeText = 'Vehicle';

    pageRecordSize: number = environment.appConfig.createManage.pageRecordSize_tiles;
    sortField = 'name';
    sortAscending = true;
    filterKey = 'name'; // Filter By Name
    filterValue = '';
    currentFilterValue = '';
    pager: any = {};
    groupOriList: any = [];
    groupList: any = [];
    isDeletingGroup = false;
    selectedDeleteGroupsList: any = [];
    selectedDeleteGroups: any = {};

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;

    constructor(
        private groupService: GroupService,
        private pagerService: PagerService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private route: ActivatedRoute,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService

    ) { }

    async ngOnInit() {
        // if (this.route.snapshot.params.type) {
        //     this.groupType = this.route.snapshot.params.type;
        // }
        // await this.loadPage(this.groupType);

        // Reopen tab after navigateSuccess() (not using route reuse strategy)
        const tab = this.routerProxyService.getViewTabFromQueryParam(this.route);
        switch (tab) {
            case 'vehicle': await this.loadPage('vehicle'); break;
            case 'driver': await this.loadPage('driver'); break;
            default: await this.loadPage('vehicle'); // first tab
        }
        await this.checkUnviewedCampaign();
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_GROUP.value);
        this.creatable = permissions[modules.CRUD_GROUP.value].cAccess;
        this.editable = permissions[modules.CRUD_GROUP.value].eAccess;
        this.deletable = permissions[modules.CRUD_GROUP.value].dAccess;

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async loadPage(groupType: string) {
        this.groupType = groupType;
        this.groupTypeText = this.toUpperFirstChar(groupType);
        this.sortAscending = true;
        this.filterValue = '';

        this.cancelResetDelete();
        await this.getDisplayGroupList();
    }

    async getDisplayGroupList(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            const groupViewResponse: any = await this.groupService.getGroupList(this.groupType.toUpperCase(),
                this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey,
                this.currentFilterValue);
            this.groupOriList = groupViewResponse.body;
            this.groupList = this.groupOriList.result;
            this.pager = this.pagerService.getPager(this.groupOriList.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.groupList.forEach(group => {
                group.shortName = StringUtil.getShortName(group.name);
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    toUpperFirstChar(value = '') {
        if (value !== '') {
            return value.substr(0, 1).toUpperCase() + value.substr(1);
        } else {
            return value;
        }
    }

    async sort() {
        this.sortAscending = !this.sortAscending;
        await this.getDisplayGroupList();
    }

    async searchGroupName() {
        this.currentFilterValue = this.filterValue;
        await this.getDisplayGroupList();
    }

    navigateToEditGroup(groupId) {
        this.routerProxyService.navigate(['/create-manage/groups/edit', this.groupType, groupId]);
    }

    navigateToCreateGroups() {
        this.routerProxyService.navigate(['/create-manage/groups/create', this.groupType]);
    }

    navigateToEditGroups() {
        if (!this.isDeletingGroup) {
            this.routerProxyService.navigate(['/create-manage/groups/edit', 'drivers', 1]);
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    countSelectedGroups() {
        return Object.keys(this.selectedDeleteGroups).filter(result => this.selectedDeleteGroups[result] === true).length;
    }

    async deleteGroups() {
        this.pageSpinner.show();
        try {
            this.selectedDeleteGroupsList = [];
            for (const objKey in this.selectedDeleteGroups) {
                if (this.selectedDeleteGroups[objKey] === true) {
                    // tslint:disable-next-line:radix
                    this.selectedDeleteGroupsList.push(parseInt(objKey));
                }
            }
            const body: any = { body: { groupType: this.groupType.toUpperCase(), groupIds: this.selectedDeleteGroupsList } };
            const result = await this.groupService.deleteGroups(body);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Group');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Group');

                this.cancelResetDelete();
                await this.getDisplayGroupList();
            }
            this.snackBar.openGenericSnackBar(msg);

            // if (result.statusCode === 'FAILED') {
            //     let name = '';
            //     let count = 0;
            //     if (result.body.name) {
            //         name = result.body.name;
            //         count = 1;
            //     } else if (result.body.count) {
            //         count = result.body.count;
            //     }
            //     const params = {
            //         name: name,
            //         status: 'FAILED',
            //         action: 'DELETE',
            //         type: 'VGROUP',
            //         count: count
            //     };
            //     this.snackBar.openSnackBar(params);
            // } else {
            //     let name = '';
            //     let count = 0;
            //     if (result.body.name) {
            //         name = result.body.name;
            //         count = 1;
            //     } else if (result.body.count) {
            //         count = result.body.count;
            //     }
            //     const params = {
            //         name: name,
            //         status: 'SUCCESS',
            //         action: 'DELETE',
            //         type: this.groupType === 'vehicle' ? 'VGROUP' : 'DGROUP',
            //         count: count
            //     };
            //     this.snackBar.openSnackBar(params);
            // }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    cancelResetDelete(closeDeletePanel: boolean = true): void {
        this.isDeletingGroup = !closeDeletePanel;
        this.selectedDeleteGroups = {};
        // this.selectedMaintenance = {};
        // this.selectedMaintenanceGroups['all'] = false;
    }
}
