import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { RouterProxyService } from '../../../../../../_services/router-proxy/router-proxy.service';
import { CompanyRegistrationSharedService } from '../../../../../../shared/service/company-registration-shared.service';
import { CompanyRegistrationService } from './../../../../../../_services/company-registration/company-registration.service';
import * as moment from 'moment';
import * as Message from './../../../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../../../constants/responseStatusCode';
import { installationType } from '../../../../../../constants/installationType.constant';
import { PopupService } from '../../../../../../components/common/popup/popup.service';
import { SnackBarService } from './../../../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../../../../components/common/spinner/spinner.component';
import { ActivatedRoute } from '@angular/router';
import { UploadComponent } from '../../add-device/upload/upload.component';

@Component({
    selector: 'app-vehicle-details',
    templateUrl: './edit-vehicle-details.component.html',
    styleUrls: ['./edit-vehicle-details.component.scss'],
    animations: [
        trigger('slideToggle', [
            transition(':enter', [
                style({ height: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ height: '*' }))
            ]),
            transition(':leave', [
                style({ height: '*' }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ height: 0 }))
            ])
        ]),
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class EditVehicleDetailsComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    backupCompanyObj: any;
    companyObj: any = null;
    channelObj: any;
    region: string;
    rfDate: string;
    vehicleObjList = [];
    vehicleObj: any = {
        "deviceProviderId": null,
        "installationType": installationType.WIRED.code,
        "vehicleType": null,
        "vehicleModelId": null,
        "vehicleMakeId": null,
        "vehicleModelDetails": null,
        "vehicleYear": null,
        "numberPlate": null,
        "imei": null,
        "msisdn": null,
        "iccid": null,
        "mesId": null,
        "mob": "Y",
        "roaming": "Y",
        "region": null,
        "delivery": {
            "picName": null,
            "address": null,
            "postcode": null,
            "state": null,
            "contactNo": null,
            "email": null
        },
        "installation": {
            "picName": null,
            "address": null,
            "postcode": null,
            "state": null,
            "contactNo": null,
            "email": null,
            "proposedDate": null,
            "proposedTime": null
        },
        "installationRemarks": null
    };

    vehicleIdsString: string;
    minDate = moment(new Date()).format('YYYY-MM-DD');
    //list for dropdown
    yearList: any = [];
    deviceProviderList: any = [];
    vehicleTypeList: any = ['Car', 'Lorry', 'Truck', 'Motorbike', 'Buggy', 'Others'];
    vehicleMakeList: any = [];
    vehicleModelList: any = [];
    stateList: any = ['Johor', 'Kedah', 'Kelantan', 'Kuala Lumpur', 'Labuan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Penang', 'Perak', 'Perlis', 'Putrajaya', 'Sabah', 'Sarawak', 'Selangor', 'Terengganu'];

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild('fileUpload',{static:false}) fileUpload: UploadComponent;

    constructor(
        private routerProxyService: RouterProxyService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private companyRegistrationSharedService: CompanyRegistrationSharedService,
        private companyRegistrationService: CompanyRegistrationService,
        private route: ActivatedRoute
    ) { }

    navigateToAddDevice() {
        if (this.backupCompanyObj) {
            //set back record for previous page use
            this.companyRegistrationSharedService.setCompanyObj(this.backupCompanyObj);
        }
        this.routerProxyService.navigate(['/support/manage-device/edit-multiple/company-details', this.vehicleIdsString]);
    }

    async updateDevice() {
        try {
            this.pageSpinner.show();
            if (this.companyObj && this.vehicleObjList.length > 0) {
                const reqList = [];
                //make a clone list, so any modifying won't affect original list
                this.vehicleObjList.map(vehicle => {
                    reqList.push(JSON.parse(JSON.stringify(vehicle)));
                });
                //formed object structure that required by back end
                reqList.map(vehicle => {
                    vehicle.region = this.region;
                    if (vehicle.installationType !== installationType.PLUG_PLAY.code) { // && vehicle.installationType !== installationType.PLUG_PLAY_COURIER.code) {
                        if (moment(vehicle.installation.proposedDate).isValid()) {
                            vehicle.installation.proposedDate = moment(vehicle.installation.proposedDate).format("YYYY-MM-DD");
                        }
                        delete vehicle.delivery;
                        // keep imeiId to be used to update imei table
                        // delete vehicle.imeiId;
                        delete vehicle.imei;
                        delete vehicle.msisdn;
                        delete vehicle.iccid;
                        if (vehicle.installationType === installationType.CUSTOM.code) {
                            delete vehicle.mob;
                        }
                    } else {
                        vehicle.imeiId = Number(vehicle.imeiId);
                        delete vehicle.installation;
                    }
                    vehicle.deviceProviderId = Number(vehicle.deviceProviderId);
                    vehicle.regReqId = Number(vehicle.regReqId);
                    vehicle.vehicleId = Number(vehicle.vehicleId);
                    vehicle.scheduleId = Number(vehicle.scheduleId);
                    vehicle.vehicleMakeId = Number(vehicle.vehicleMakeId);
                    vehicle.vehicleModelId = Number(vehicle.vehicleModelId);
                    vehicle.vehicleYear = Number(vehicle.vehicleYear);

                    delete vehicle.isActive;
                    delete vehicle.contactStatus;
                    delete vehicle.assignedInstaller;
                    delete vehicle.installationDate;
                    delete vehicle.installationStatus;
                    delete vehicle.remark;
                    delete vehicle.updatedBy;
                    delete vehicle.dateUpdated;
                    // delete vehicle.status;
                });

                const fileInBytes = [];
                const fileReader = new FileReader();
                for (let i = 0; i < this.fileUpload.fileUploadList.length; i++) {
                    const file = this.fileUpload.fileUploadList[i];
                    const reader = new Promise((resolve, reject) => {
                        fileReader.readAsDataURL(file);
                        fileReader.onload = async (e) => {
                            resolve(fileReader.result);
                        };
                    });
                    const base64 = await reader;
                    const formattedFile = {
                        fileByte: base64,
                        fileName: file.name
                    };
                    fileInBytes.push(formattedFile);
                }

                const formattedReq = {
                    rfDate: this.rfDate,
                    company: this.companyObj,
                    channel: this.channelObj,
                    vehicles: reqList,
                    files: fileInBytes
                };
                const result = await this.companyRegistrationService.updateDevice(formattedReq);

                const companyName = this.companyObj.name;

                let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Company', companyName);
                if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Company', companyName);
                    this.navigateSuccessToCompanyView();
                }
                this.snackBar.openGenericSnackBar(msg);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToCompanyView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/support/manage-device']);
    }

    toggleVehicleAccordian(index) {
        if (this.vehicleObjList[index].isActive) {
            this.vehicleObjList[index].isActive = false;
        } else {
            this.vehicleObjList[index].isActive = true;
        }
    }

    ngOnInit() {
        this.vehicleIdsString = this.route.snapshot.params.ids;
        this.initCommonFunction();
    }

    async initCommonFunction() {
        try {
            //get company obj pass from company tab
            this.companyObj = JSON.parse(JSON.stringify(this.companyRegistrationSharedService.getCompanyObj()));
            this.backupCompanyObj = JSON.parse(JSON.stringify(this.companyObj));
            this.companyRegistrationSharedService.setCompanyObj(null);
            //dont have company obj then return back to company tab
            if (!this.companyObj) {
                this.navigateToAddDevice();
                return;
            }

            //separate fields from object for later use
            this.channelObj = this.companyObj.channel;
            this.channelObj.channelTypeId = Number(this.channelObj.channelTypeId);
            delete this.channelObj.vehicleId;
            this.companyObj.id = Number(this.companyObj.id);
            if (moment(this.companyObj.rfDate).isValid()) {
                this.rfDate = moment(this.companyObj.rfDate).format("YYYY-MM-DD");
            }
            delete this.companyObj.channel;
            delete this.companyObj.rfDate;
            delete this.companyObj.type;
            this.region = this.channelObj.region;
            delete this.channelObj.region;

            //initialize vehicles from db
            await this.getVehiclesByVehicleIds();

            this.getYearList(40); //default get 40 years
            //initialize drop down selection
            // const deviceProviderResponse = await this.companyRegistrationService.getDeviceProviderList();
            // this.deviceProviderList = deviceProviderResponse.body.result;
            const vehicleMakeResponse = await this.companyRegistrationService.getVehicleMakeList();
            this.vehicleMakeList = vehicleMakeResponse.body.result;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async getVehiclesByVehicleIds() {
        const response = await this.companyRegistrationService.getVehiclesByVehicleIds(this.vehicleIdsString);
        if (response && response.body.vehicles.length > 0) {
            const vehicles = response.body.vehicles;
            for (let i = 0; i < vehicles.length; i++) {
                const vehicle = vehicles[i];

                //for expand and minimize usage, first one set it true
                if (i === 0) {
                    vehicle.isActive = true;
                } else {
                    vehicle.isActive = false;
                }
                const vehicleModelResponse = await this.companyRegistrationService.getVehicleModelList(vehicle.vehicleMakeId);
                this.vehicleModelList.push(vehicleModelResponse.body.result);
            }
            this.vehicleObjList = vehicles;
        }
    }

    async getVehicleModelList(vehicleMakeId, position) {
        const vehicleModelResponse = await this.companyRegistrationService.getVehicleModelList(vehicleMakeId);
        this.vehicleModelList[position] = vehicleModelResponse.body.result;
        //vehicle make changed, empty previous selected model
        this.vehicleObjList[position].vehicleModelId = null;
    }

    getYearList(yearNumber: number) {
        let currentYear = parseInt(moment().format('YYYY'));
        for (let i = 0; i < yearNumber; i++) {
            this.yearList.push(currentYear);
            currentYear = currentYear - 1;
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async closeFileUploadPopup() {
        //clear uploaded file
        this.fileUpload.fileUploadList = [];
        this.hidePopup();
    }

}
