<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Create New Driver Tag
                    </h1>
                </div>
            </div>
        </div>
    </div>

	<form class="form-field" #driverTagForm="ngForm">
		<div class="section">
			<div class="wrapper">
				<div class="compulsory-notes">
                    *Compulsory fields
                </div>

				<div class="grid --gutter">
                    <div class="grid__block w-8of12@sm form__flex">
						<h2 class="heading indicator-green --level-2 --partial-underline">
                            Driver Tag Details
                        </h2>

						<div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
								
								<div class="field-group__holder">
                                    <div class="field-group__label">
                                        Driver Tag No:
                                        <span class="indicator-green">*</span>
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input"  [(ngModel)]="newDriverTagInfo.driverTagNo" name="driverTagNo" #driverTagNo="ngModel" name="driverTagNo" required maxlength="45">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        
										<div class="id-tag-notify">
                                            <div class="id-tag-notify__col">
                                                <i>Notify after</i>
                                            </div>

                                            <div class="id-tag-notify__col">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <label class="field__holder">
                                                            <input type="text" class="field__input" [(ngModel)]="newDriverTagInfo.remindMinute" name="remindMinute" #remindMinute="ngModel" placeholder="5" maxlength="2" required>
                                                        </label>
                                                        <div class="field__ui"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="id-tag-notify__col">
                                                <i>minutes tagged ID tag.</i>
                                            </div>                                            
                                        </div>

                                        <div *ngIf="driverTagNo.errors && (driverTagNo.dirty || driverTagNo.touched)">
                                            <div class="field-error" *ngIf="driverTagNo.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Driver Tag No')}}
                                            </div>
                                            <div class="field-error" *ngIf="driverTagNo.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Driver Tag No', '45')}}
                                            </div>
                                        </div>
                                        <div *ngIf="remindMinute.errors && (remindMinute.dirty || remindMinute.touched)">
                                            <div class="field-error" *ngIf="remindMinute.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Notification Time')}}
                                            </div>
                                            <div class="field-error" *ngIf="remindMinute.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Notification Time', '2')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

								<div class="field-group__holder">
                                    <div class="field-group__label">
                                        Driver:
                                    </div>
                                    <div class="field-group__input">
										<!-- START DRIVER TAG-->
										<div class="field-group --unstack@xs reports__type-field-group">
											<div class="field-group__item reports__type-field-group-input" [class.--active]="driverSearchDOMStates.isFocused" click-outside (clickOutside)="onOutsideClickDriverReaction($event)" (click)="toggleSubjectSelectionFloater(true)">
												<div class="field" #driverField>
													<div class="field__elems">
														<div class="field__icon">
															<div class="field__icon-inner">
																<img src="assets/images/scss-components/forms/icon-steering-wheel.svg" width="26" height="20" alt="" class="field__icon-image">
															</div>
														</div>

														<label class="field__holder">
															<div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
																{{ driverSelectedLabel }}
															</div>

                                                            <input id="driverSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #driverSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterDriverSelection($event.target.value)">
														</label>

														<div class="field__ui"></div>
													</div>

													<div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
														<ul class="inline-input-list">
															<li *ngIf="!driverModel.result.length" class="inline-input-list__item">
																<label class="inline-input">
																	No available driver found
																</label>
															</li>
															<li class="inline-input-list__item" *ngFor="let item of driverModel.result; let groupIndex = index;">

																<label class="inline-input">
																	<input type="radio" [name]="driverId" [value]="item.driverId" [selected]="item.driverId === selectedPlacement" class="inline-input__input" [(ngModel)]="newDriverTagInfo.driverId" (ngModelChange)="onDriverSelectChange()">
																	<span class="inline-input__icon"></span>
																	{{ item.driverName }}
																</label>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										<!-- END DRIVER TAG-->
                                    </div>
                                </div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="driverTagForm.invalid" (click)="showPopup(createDriverTagPopup)">
                                CREATE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToDriverTagView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
	</form>
</div>

<app-popup #createDriverTagPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="createDriverTag();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>