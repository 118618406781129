import { TokenService } from './../../../../_services/token/token.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { PopupService } from './../../../common/popup/popup.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { PagerService } from './../../../../_services/pager/pager.service';
import { SupportService } from './../../../../_services/support/support.service';
import { AuthService } from './../../../../_services/auth/auth.service';
import * as USER_ROLE from '../../../../constants/userRole';
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';

@Component({
    selector: 'app-vehicle-termination',
    templateUrl: './vehicle-termination.component.html',
    styleUrls: ['./vehicle-termination.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class VehicleTerminationComponent implements OnInit {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("successNotification",{static:false}) successNotification;
    emailSentPopupMsg: string;
    @ViewChild('resetPasswordTable',{static:false}) resetPasswordTable;
    resetPasswordTableScrolledToEnd: boolean = false;
    constructor(
        private pagerService: PagerService,
        private supportService: SupportService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private tokenService: TokenService,
        private authService: AuthService
    ) { }

    userRole = USER_ROLE;

    //Pagination
    pager: any = {}; //Pager object
    pageRecordSize: number = 10;

    searchBy = 'company';
    sortField = 'CompanyName';
    sortAscending: boolean = true;
    filterKey = 'CompanyName';
    filterValue = '';
    currentFilterValue = '';

    vehiclesOriDetails: any = {};
    vehiclesDetailsList: any = [];

    terminateVehicleId: any = {};

    isDeletingVehicles: boolean;
    selectedVehiclesGroups: any = {};
    selectedVehicles: any = {};

    toggleMobileOpen: boolean = false;

    async ngOnInit() {
        const token = await this.tokenService.removeAdministratorUser(); // remove administrator token
        if (token) {
            await this.authService.removeAdminitratorLogin(token);
        }

        await this.getDisplayVehiclesList();
    }

    async getDisplayVehiclesList(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Get Users Info Details Page
            const response: any = await this.supportService.getAllVehicles(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            this.vehiclesOriDetails = response.body;
            this.vehiclesDetailsList = response.body.result;
            this.pager = this.pagerService.getPager(this.vehiclesOriDetails.totalRecord, page, this.pageRecordSize);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async searchValue() {
        if (this.searchBy === 'company') {
            this.filterKey = 'CompanyName';
        } else if (this.searchBy === 'vehicle') {
            this.filterKey = 'VehicleName';
        } else {
            return;
        }
        this.currentFilterValue = this.filterValue;
        await this.getDisplayVehiclesList();
    }

    getSortingStatus(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }
        await this.getDisplayVehiclesList();
    }

    getPlaceHolderText() {
        let placeHolderText = 'Search By';
        if (this.searchBy === 'company') {
            placeHolderText = `${placeHolderText} Company`;
        } else if (this.searchBy === 'vehicle') {
            placeHolderText = `${placeHolderText} Vehicle`;
        }
        return placeHolderText;
    }

    terminateVehicle(vehicleId) {
        this.terminateVehicleId = vehicleId;
    }

    async vehicleTermination() {
        this.pageSpinner.show();
        try {
            const selectedVehiclesList = [];
            if(JSON.stringify(this.terminateVehicleId) !== '{}'){
                selectedVehiclesList.push(this.terminateVehicleId)
            }else{
                for (const key in this.selectedVehicles) {
                    if (this.selectedVehicles[key] === true) {
                        selectedVehiclesList.push(parseInt(key));
                    }
                }
            }

            const selectedVehiclesDetailsList = this.getVehicleDetail(selectedVehiclesList);

            const result = await this.supportService.deleteVehicles(selectedVehiclesDetailsList);
            if (result && result.statusCode === ResponseStatusCode.SUCCESS.code) {
                this.emailSentPopupMsg = Message.getMessage(Message.MESSAGE.VEHICLE_TERMINATION_SUCCESS.value);
                this.showPopup(this.successNotification);
                this.cancelResetDelete();
                await this.getDisplayVehiclesList();
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    getVehicleDetail(selectedVehicle = []) {
        const vehiclesDetail = [];

        for(let vehicle of this.vehiclesDetailsList){
            if(selectedVehicle.includes(vehicle.vehicleId)) {
                const vehicleDetail = {
                    vehicleId: vehicle.vehicleId,
                    companyName: vehicle.companyName,
                    vehiclePlateNo: vehicle.vehiclePlateNo,
                    imeiNo: vehicle.imeiNo,
                    iccid: vehicle.iccid,
                    installationType: vehicle.installationType,
                    channel: vehicle.channel
                }
                vehiclesDetail.push(vehicleDetail);                
            }
        }

        return vehiclesDetail;
    }

    onResetPasswordTableScroll() {
        if (!this.resetPasswordTable) return;

        const tableElem = this.resetPasswordTable.nativeElement;
        this.resetPasswordTableScrolledToEnd = tableElem.scrollLeft + tableElem.clientWidth >= tableElem.scrollWidth;
    }

    onVehiclesAllSelectChange() {
        const allIsSelected = this.selectedVehiclesGroups.all;
        for (let i = 0; i < this.vehiclesDetailsList.length; i++) {
            const vehiclesModel = this.vehiclesDetailsList[i];
            this.selectedVehicles[vehiclesModel.vehicleId] = allIsSelected;
        }
    }
    countSelectedVehicles() {
        return Object.keys(this.selectedVehicles).filter(result => this.selectedVehicles[result] === true).length;
    }

    cancelResetDelete() {
        this.isDeletingVehicles = false;
        this.selectedVehicles = {};
        this.vehiclesDetailsList['all'] = false;
        this.terminateVehicleId = {};
    }

    onVehiclesSelectChange(vehiclesSelected: boolean) {
        if (vehiclesSelected) {
            // this.selectedDrivingLimit[drivingLimitModel.vehicleId] = true;
        } else {
            this.selectedVehiclesGroups['all'] = false;
        }
    }


    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
