<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md --space-top">
                    <h1 class="heading --level-1">
                        Routes
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button"
                                (click)="navigateToCreateRoute(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt=""
                                        width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Route
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Route
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg"
                                        alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button"
                                (click)="isDeletingRoute = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt=""
                                        width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Route
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Route
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg"
                                        alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle"
                                (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'"
                                    alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="tabs__content">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-vehicle.svg"
                                                            width="26" height="20" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input id="routeSearchBox" type="text" autocomplete="off"
                                                        class="field__input" placeholder="Search Route"
                                                        [(ngModel)]="filterValue" name="filter"
                                                        (keyup.enter)="searchVehicleName()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button"
                                                (click)="searchVehicleName()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table table__driving-limit-general">
                            <table class="table__elem" style="width: 100%; table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <ng-container *ngIf="isDeletingRoute">
                                            <th scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true"
                                                        class="inline-input__input"
                                                        [(ngModel)]="selectedRouteGroups['all']"
                                                        (ngModelChange)="onRouteAllSelectChange()">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container>

                                        <th scope="col">
                                            <button type="button" class="table__sortable"
                                                [ngClass]="getSortingState('Name')"
                                                (click)="sort('Name')">Route</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable"
                                                [ngClass]="getSortingState('affectedVehicle')"
                                                (click)="sort('affectedVehicle')">Affected Vehicles</button>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let route of routeList">
                                        <ng-container *ngIf="isDeletingRoute">
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="route.Id" [value]="true"
                                                        class="inline-input__input"
                                                        [(ngModel)]="selectedRoute[route.Id]"
                                                        (ngModelChange)="onRouteSelectChange($event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>
                                        </ng-container>
                                        <td *ngIf="editable">
                                            <a class="link --primary"
                                                (click)="navigateToEditRoute(route.Id);">{{route.Name}}</a>
                                        </td>
                                        <td class="td-truncate" *ngIf="!editable">
                                            {{route.Name}}
                                        </td>
                                        <td class="td-truncate">
                                            {{route.AffectedVehicle}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="record-not-found" *ngIf="routeList.length === 0">No Record Found</h2>
                        </div>
                    </div>

                    <div class="block" *ngIf="routeList.length > 0">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination">
                                    <button type="button" class="pagination__button"
                                        [class.--disabled]="pager.currentPage === 1"
                                        [disabled]="pager.currentPage === 1"
                                        (click)="getRoute(pager.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pager.pages" class="pagination__button"
                                        [class.--active]="page === pager.currentPage"
                                        (click)="getRoute(page)">
                                        {{ page }}
                                    </button>

                                    <button type="button" class="pagination__button"
                                        [class.--disabled]="pager.currentPage === pager.totalPages"
                                        [disabled]="pager.currentPage === pager.totalPages"
                                        (click)="getRoute(pager.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingRoute">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedRoute()}} route(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteRoutePopup)"
                        [disabled]="countSelectedRoute() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23"
                            height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16"
                            height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteRoutePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43"
                class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedRoute()}}</span> route(s)
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteRoute();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>