import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

// import { environment } from './../../../environments/environment';

import { ErrorHandlerModule } from './../error-handler';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    config = new MatSnackBarConfig();
    configNoClose = new MatSnackBarConfig();
    // message = Message;

    constructor(
        private errorHandler: ErrorHandlerModule,
        private snackBar: MatSnackBar,
        // private emailService: EmailService
    ) {
        const defaultConfig = {
            panelClass: ['action-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
        };
        Object.assign(this.config, defaultConfig);
        Object.assign(this.configNoClose, defaultConfig);
        this.config.duration = 5000;
    }

    openGenericSnackBar(msg: any) {
        try {
            this.snackBar.open(msg, 'close', this.config);
        } catch (e) {
            //do nothing
        }
    }

    openGenericNoCloseSnackBar(msg: any) {
        try {
            this.snackBar.open(msg, 'close', this.configNoClose);
        } catch (e) {
            //do nothing
        }
    }

    /**
     * Show snackbar for error. Message automatically generated using error handler
     * @param error The error in order to generate error message
     * @param autoClose Whether to auto dismiss snackbar after a while
     * @returns true if snackbar was shown
     */
    openStandardizedErrorSnackBar(error: Error | HttpErrorResponse | any, autoClose: boolean = true): boolean {
        const msg = this.errorHandler.handleStandardizedError(error);
        if (msg) {
            if (autoClose) {
                this.openGenericSnackBar(msg);
            } else {
                this.openGenericNoCloseSnackBar(msg);
            }
            return true;
        }
        return false;
    }

    /* openSuccessSnackBar(msg) {
      let successConfig = new MatSnackBarConfig();

      successConfig.panelClass = []; // Just to prevent TypeScript error
      Object.assign(successConfig, this.config);
      successConfig.panelClass = successConfig.panelClass.slice(0);
      successConfig.panelClass.push('--success');

      this.snackBar.open(msg, '�', successConfig);
    } */

    /* openFailSnackBar(msg) {
      let failConfig = new MatSnackBarConfig();

      failConfig.panelClass = []; // Just to prevent TypeScript error
      Object.assign(failConfig, this.config);
      failConfig.panelClass = failConfig.panelClass.slice(0);
      failConfig.panelClass.push('--fail');

      this.snackBar.open(msg, '�', failConfig);
    } */

    /* openSnackBar(params) {
            let messageCode = '';
            let message = '';
            // let action = null;
            let isFail: boolean = false;
            if (params['action']) {
                switch (params['action']) {
                    case 'CREATE': {
                        let type = '';
                        if (params['status'] === 'FAILED') {
                            messageCode = this.message.MESSAGE.CREATE_FAILED.value;
                            type = this.getType(params['type']);
                            isFail = true;
                        } else if (params['status'] === 'SUCCESS') {
                            messageCode = this.message.MESSAGE.CREATE_SUCCESS.value;
                            type = this.getType(params['type']);
                        }
                        if (messageCode && type) {
                            message = this.message.getMessage(messageCode, type, params['name']);
                        }
                        break;
                    }
                    case 'UPDATE': {
                        let type = '';
                        if (params['status'] === 'FAILED') {
                            messageCode = this.message.MESSAGE.UPDATE_FAILED.value;
                            type = this.getType(params['type']);
                            isFail = true;
                        } else {
                            messageCode = this.message.MESSAGE.UPDATE_SUCCESS.value;
                            type = this.getType(params['type']);
                        }
                        if (messageCode && type) {
                            message = this.message.getMessage(messageCode, type, params['name']);
                        }
                        break;
                    }
                    case 'DELETE': {
                        let replaceMsg = '';
                        if (params['status'] === 'FAILED') {
                            messageCode = this.message.MESSAGE.DELETE_FAILED.value;
                            let type = this.getType(params['type']);
                            let count = params['count']
                            if (count > 1) {
                                type = type + 's';
                                replaceMsg = count + ' ' + type
                            } else {
                                replaceMsg = type + ` (${params['name']})`;
                            }
                            isFail = true;
                        } else if (params['status'] === 'SUCCESS') {
                            messageCode = this.message.MESSAGE.DELETE_SUCCESS.value;
                            let type = this.getType(params['type']);
                            let count = params['count']
                            if (count > 1) {
                                type = type + 's';
                                replaceMsg = count + ' ' + type
                            } else {
                                replaceMsg = type + ` (${params['name']})`;
                            }
                        }
                        if (messageCode && replaceMsg) {
                            message = this.message.getMessage(messageCode, replaceMsg);
                        }
                        break;
                    }
                }
                try {
                    // if (isFail) {
                    //     this.snackBar.open(message, null, this.configNoClose);
                    // } else {
                    this.snackBar.open(message, null, this.config);
                    // }
                } catch (e) {
                    //do nothing
                }
            }
        } */

    /* getType(param) {
        if (param === 'ADMIN') {
            return 'Admin';
        } else if (param === 'DRIVER') {
            return 'Driver';
        } else if (param === 'VGROUP') {
            return 'Vehicle Group';
        } else if (param === 'DGROUP') {
            return 'Driver Group';
        } else if (param === 'Tag') {
            return 'Tag';
        }
        return 'null';
    } */
}

