import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DriverDataService {

    constructor(public http: HttpClient) {
        // console.debug('Driver data service connected')
    }

    // getDrivers() {
    //     return this.http.get('')
    //         .pipe(map(res => res.json()));
    // }
}
