import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
// import { trigger, style, animate, transition } from '@angular/animations';
// import { RouterProxyService } from './../../../../../../_services/router-proxy/router-proxy.service';
// import { environment } from './../../../../../../../environments/environment';
// import * as StringUtil from './../../../../../../util/stringUtil';
import * as Message from './../../../../../../constants/message';
import { PopupService } from '../../../../../../components/common/popup/popup.service';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

    /* Default Setting */
    fileUploadList: any = [];
    isCreateorEdit: string = "CREATE";
    batchFileSize: number = 10; //in MB
    supportfileType: Array<string> = ["xlsx", "xls", "csv", "pdf"];
    batchErrorMessage = null;

    @Input('importErrorPopup') batchImportErrorPopup;

    /* Edit Upload Document Fields */
    @Input('isCreateOrEdit') isCreateOrEdit;
    @Input('customFileType') customFileType;
    // @Input('validateDocChange') validateDocChange;
    @Output() isDocChange = new EventEmitter<boolean>();
    supportedFileTypeStr: string;
    /* End of Edit Upload Document Fields */

    constructor(private popupService: PopupService) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.customFileType) {
            this.supportfileType = this.customFileType;
        }
        if (this.isCreateOrEdit) {
            this.isCreateorEdit = this.isCreateOrEdit;
        }
    }

    async batchImport(event) {
        const fileName = event.target.files[0].name;
        const currFileType = fileName.substring(fileName.lastIndexOf('.') + 1);
        let isCorrectFileType = false;
        const lowCaseFileName = fileName.toLowerCase();
        for (let i = 0; i < this.supportfileType.length; i++) {
            const eachType = this.supportfileType[i];
            if (lowCaseFileName.includes(eachType) == true) {
                isCorrectFileType = true;
            }
        }
        //Validate File Type
        if (event.target.files.length < 1 || isCorrectFileType == false) {
            const supportedFileTypeStr = this.requiredFileType(this.supportfileType);
            this.batchErrorMessage = Message.getMessage(Message.MESSAGE.INCORRECT_FILE_FORMAT_TYPE.value, currFileType, supportedFileTypeStr);
            this.showPopup(this.batchImportErrorPopup);
            return;
        }
        //Validate File Size
        const fileSize = event.target.files[0].size / 1024 / 1024;
        // let fileSize = event.target.files[0].size;
        // this.fileName = event.target.files[0].name;
        // this.isDownloadDone = false;
        // this.dataRecordsHasError = false;
        // this.validDataList = [];
        // this.invalidDataList = [];
        // fileSize = fileSize / 1024 / 1024;
        if (fileSize > this.batchFileSize) {
            // file size too large
            this.batchErrorMessage = Message.getMessage(Message.MESSAGE.FILE_SIZE_TOO_LARGE.value, this.batchFileSize, fileSize.toFixed(1));
            this.showPopup(this.batchImportErrorPopup);
            return;
        }
        const file = event.target.files[0];
        const fileInput: any = document.getElementById('myFileInput');
        fileInput.value = null; // Clear previous file input data
        // Check require multiple file or single file
        if (this.isCreateorEdit == "CREATE") {
            // this.convertExcelToJson();
            this.fileUploadList.push(file);
        } else if (this.isCreateorEdit == "EDIT") {
            this.fileUploadList = [file];
            this.isDocChange.emit(true);
        }
    }

    async deleteFile(index) {
        this.fileUploadList.splice(index, 1);
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    requiredFileType(supportfileType) {
        let supportTypeStr = supportfileType.join(",");
        const supportedTypeArr = [];
        const wordType = ["docx", "doc", "dot"];
        for (let i = 0; i < wordType.length; i++) {
            if (supportTypeStr.indexOf(wordType[i]) > -1) {
                supportedTypeArr.push("Word");
                break;
            }
        }
        const excelType = ["xlsx", "xls", "csv"];
        for (let i = 0; i < excelType.length; i++) {
            if (supportTypeStr.indexOf(excelType[i]) > -1) {
                supportedTypeArr.push("Excel");
                break;
            }
        }
        const ppType = ["pptx"];
        (supportTypeStr.indexOf(ppType[0]) > -1) ? supportedTypeArr.push("Powerpoint") : "";
        const textType = ["txt"];
        (supportTypeStr.indexOf(textType[0]) > -1) ? supportedTypeArr.push("TEXT") : "";
        const pdfType = ["pdf"];
        (supportTypeStr.indexOf(pdfType[0]) > -1) ? supportedTypeArr.push("PDF") : "";
        const jpgType = ["jpg", "jpeg"];
        for (let i = 0; i < jpgType.length; i++) {
            if (supportTypeStr.indexOf(jpgType[i]) > -1) {
                supportedTypeArr.push("JPG");
                break;
            }
        }
        const pngType = ["png"];
        (supportTypeStr.indexOf(pngType[0]) > -1) ? supportedTypeArr.push("PNG") : "";
        const bmpType = ["bmp"];
        (supportTypeStr.indexOf(bmpType[0]) > -1) ? supportedTypeArr.push("BMP") : "";
        const gzType = ["gz"];
        (supportTypeStr.indexOf(gzType[0]) > -1) ? supportedTypeArr.push("GZIP") : "";
        const rarType = ["rar"];
        (supportTypeStr.indexOf(rarType[0]) > -1) ? supportedTypeArr.push("RAR") : "";
        const sevenzType = ["7z"];
        (supportTypeStr.indexOf(sevenzType[0]) > -1) ? supportedTypeArr.push("7Z") : "";
        return supportedTypeArr.join(", ");
    }
}
