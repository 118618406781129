export const DashboardColors = {
    RGBA_TRANSPARENT: 'rgba(255, 255, 255, 0)',
    RGBA_BLACK: 'rgba(0, 0, 0, 1)',
    RGBA_DARK_GRAY: 'rgba(77, 77, 77, 1)',
    RGBA_GRAY: 'rgba(128, 128, 128, 1)',
    RGBA_LIGHT_GRAY: 'rgba(169, 169, 169, 0.8)',
    RGBA_LIGHT_GRAY_SHADE: 'rgba(169, 169, 169, 0.15)',
    RGBA_CHARTJS_GRAY: 'rgba(0, 0, 0, 0.25)', // chart.js default for gridlines
    RGBA_WHITE: 'rgba(255, 255, 255, 1)',
    RGBA_WHITE_SHADE: 'rgba(255, 255, 255, 0.3)',
    RGBA_BRIGHT_RED: 'rgba(255, 51, 51, 1)',
    RGBA_VIOLET: 'rgba(153, 51, 255, 1)',
    RGBA_ORANGE: 'rgba(255, 128, 0, 1)',
    RGBA_DARK_GREEN: 'rgba(0, 102, 51, 1)',
    HEX_GREY: "#aeaeae",
    HEX_GREEN: "#128958", //005c46
    HEX_YELLOWISH_ORANGE: "#ffa600",
    HEX_RED: "#bd0d0d",

    /* MAXIS PRIMARY COLORS : Refer Maxis Color Palette */
    RGBA_PRI1_SHARP_GREEN: 'rgba(140, 230, 0, 1)',      //#8CE600
    RGBA_PRI2_DULL_GREEN: 'rgba(141, 198, 63, 1)',      //#8DC63F
    RGBA_PRI3_DARK_GREEN: 'rgba(0, 75, 53, 1)',         //#004B35
    RGBA_PRI4_GRASS_GREEN: 'rgba(54, 144, 68, 1)',      //#369044

    /* MAXIS SECONDARY COLORS : Refer Maxis Color Palette */
    RGBA_SEC1_CHARCOAL_GREEN: 'rgba(42, 55, 32, 1)',    //#29371F
    RGBA_SEC2_DARK_DULL_GREEN: 'rgba(87, 118, 52, 1)',  //#577634
    RGBA_SEC3_DULL_GREEN: 'rgba(107, 146, 67, 1)',      //#6B9243
    RGBA_SEC4_LIME_GREEN: 'rgba(166, 206, 57, 1)',      //#A6CE39
    RGBA_SEC5_YELLOW_GREEN: 'rgba(212, 222, 37, 1)',    //#D4DE25

    /* MAXIS HIGHLIGHT COLORS : Refer Maxis Color Palette */
    RGBA_HL1_PINK: 'rgb(236, 0, 140, 1)',               //#EC008C
    RGBA_HL2_ROYALE_BLUE: 'rgba(41, 56, 149, 1)',       //#293895
    RGBA_HL3_SKY_BLUE: 'rgba(0, 182, 241, 1)',          //#00B6F1

    /* MAXIS CTA BUTTONS COLORS : Refer Maxis Color Palette */
    RGBA_CTA1_GRASS_GREEN: 'rgb(54, 144, 68, 1)',       //#369044
    RGBA_CTA2_DULL_GREEN: 'rgba(141, 198, 63, 1)',      //#8DC63F
    RGBA_CTA3_SHARP_GREEN: 'rgb(110, 220, 0, 1)',       //#6EDC00
    RGBA_CTA4_PINK: 'rgba(255, 0, 146, 1)',             //#FF0092
    RGBA_CTA5_SKY_BLUE: 'rgba(0, 182, 241, 1)',         //#00B6F1
    RGBA_CTA6_PURPLE: 'rgba(196, 0, 198, 1)',           //#C400C6
};
