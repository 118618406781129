// import * as MarkerClusterer from '@google/markerclustererplus';
import * as MarkerClusterer from './markerclusterer';

export function createMarkerClusters(map, markers, createFor) {
    // Default setting use for createFor == 'LIVE_TRIP'
    let markerClusterUrl = '../assets/images/ng-components/map/icon_markerCluster_light_green.svg';
    // marker clusters styling
    let styleObj = {
        url: markerClusterUrl,
        height: 100,
        width: 75,
        anchorText: [0, 0],
        fontFamily: '"Aaux Next Web"',
        fontWeight: 500,
        textColor: '#ffffff',
        textSize: 34
    };
    let maxZoom = 18; //clustering begin after this value

    let markerClusterStyles = [];
    //Create different text size
    for (let i = 0; i < 5; i++) {
        switch (i) {
            case 1: // 10 - 99 markers
                styleObj = Object.assign({}, styleObj, { textSize: 30 });
                break;
            case 2: // 100 - 999 markers
                styleObj = Object.assign({}, styleObj, { textSize: 26 });
                break;
            case 3: // 1000 - 9999 markers
                styleObj = Object.assign({}, styleObj, { textSize: 22 });
                break;
            case 4: // >10000 markers
                styleObj = Object.assign({}, styleObj, { textSize: 18 });
                break;
            default: // 1 - 9 markers
                styleObj = Object.assign({}, styleObj, { textSize: 34 });
        }
        markerClusterStyles.push(styleObj);
    }

    // For past trip detail violation markers
    if (createFor == 'DETAIL_TRIP') {
        markerClusterStyles = [];
        markerClusterUrl = '../assets/images/ng-components/past-trip/icon_markerCluster_red.svg';
        styleObj.url = markerClusterUrl;
        styleObj.height = 55;
        styleObj.width = 55;
        maxZoom = 20;

        for (let i = 0; i < 5; i++) {
            switch (i) {
                case 1: // 10 - 99 markers
                    styleObj = Object.assign({}, styleObj, { textSize: 20 });
                    break;
                case 2: // 100 - 999 markers
                    styleObj = Object.assign({}, styleObj, { textSize: 18 });
                    break;
                case 3: // 1000 - 9999 markers
                    styleObj = Object.assign({}, styleObj, { textSize: 15 });
                    break;
                case 4: // >10000 markers
                    styleObj = Object.assign({}, styleObj, { textSize: 14 });
                    break;
                default: // 1 - 9 markers
                    styleObj = Object.assign({}, styleObj, { textSize: 22 });
            }
            markerClusterStyles.push(styleObj);
        }
    }

    // generate marker clusters
    const markerCluster = new MarkerClusterer(map, markers, {
        gridSize: 40,
        styles: markerClusterStyles,
        enableRetinaIcons: true,
        maxZoom: maxZoom // determine which zoom level start perform marker cluster
    });

    return markerCluster;
}

// Clear marker clusters from map without remove marker clusters
export function clearMarkerClusters(markerClusters) {
    if (markerClusters.getMarkers().length == 0) {
        return;
    }
    markerClusters.clearMarkers();
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}

export function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

export function genLatLngByMidpoint(currLocation, nextLocation, performCount, currentIndex = null) {
    let latLngArray = [{ location: currLocation, index: currentIndex }, { location: nextLocation, index: currentIndex }];
    if (performCount < 1) {
        return latLngArray.slice(0, latLngArray.length - 1);
    }
    for (let i = 0; i < performCount; i++) {
        const tempLatLngArray = [];
        latLngArray.forEach((eachLocal, index) => {
            if (index < latLngArray.length - 1) {
                // get first record of the array
                tempLatLngArray.push({ location: eachLocal.location, index: currentIndex });
                // get midpoint of each two point
                const midLocation = Midpoint(eachLocal.location, latLngArray[index + 1].location);
                tempLatLngArray.push({ location: midLocation, index: currentIndex });
            } else if (index == latLngArray.length - 1) {
                // get last record of the array
                tempLatLngArray.push({ location: eachLocal.location, index: currentIndex });
            }
        });
        latLngArray = tempLatLngArray.slice(0);
    }
    return latLngArray.slice(0, latLngArray.length - 1);
}

/*
 * IF Applied for coordinates(Eg: google map location) x = latitude, y = longitude
 *
 */
function Midpoint(firstPoint, secondPoint) {
    const lat = (firstPoint.lat + secondPoint.lat) / 2;
    const lng = (firstPoint.lng + secondPoint.lng) / 2;
    return { lat: parseFloat(lat.toFixed(6)), lng: parseFloat(lng.toFixed(6)) };
}

export function genLatLngByTtlRecordRequired(currLocation, nextLocation, totalRecord, currentIndex = null) {
    const latLngArray = [];
    if (totalRecord <= 1) {
        return [{ location: currLocation, index: currentIndex }];
    }
    const latitudeDiff = (nextLocation.lat - currLocation.lat) / totalRecord;
    const longitudeDiff = (nextLocation.lng - currLocation.lng) / totalRecord;
    for (let i = 0; i < totalRecord; i++) {
        const latitude = currLocation.lat + (i * latitudeDiff);
        const longitude = currLocation.lng + (i * longitudeDiff);
        latLngArray.push({
            location: {
                lat: parseFloat(latitude.toFixed(6)),
                lng: parseFloat(longitude.toFixed(6))
            },
            index: currentIndex
        });
    }
    return latLngArray.slice(0, latLngArray.length - 1);
}

export function ttlNumSubLatLngReq(recSize) {
    if (recSize < 20000) {
        return 1;
    } else if (recSize >= 20000 && recSize < 30000) {
        return 0.75;
    } else if (recSize >= 30000 && recSize < 40000) {
        return 0.60;
    } else if (recSize >= 40000 && recSize < 50000) {
        return 0.40;
    } else if (recSize >= 50000 && recSize < 60000) {
        return 0.30;
    } else if (recSize >= 60000 && recSize < 70000) {
        return 0.20;
    } else if (recSize >= 70000) {
        return 0.10;
    }
}
export function numSubLatLngReq(currLocation, nextLocation, reduceNumSubLatLngReq = 1) {
    const distance = getDistanceFromLatLonInKm(currLocation.lat, currLocation.lng, nextLocation.lat, nextLocation.lng) * 1000;
    const numSubLatLng = {
        // performCount: 0,
        totalRecord: 1
    };
    if (distance < 50) {
        // numSubLatLng.performCount = 2;
        numSubLatLng.totalRecord = Math.ceil(5 * reduceNumSubLatLngReq);
    } else if (distance > 50 && distance < 70) {
        // numSubLatLng.performCount = 3;
        numSubLatLng.totalRecord = Math.ceil(10 * reduceNumSubLatLngReq);
    } else if (distance > 70 && distance < 100) {
        // numSubLatLng.performCount = 4;
        numSubLatLng.totalRecord = Math.ceil(15 * reduceNumSubLatLngReq);
    } else if (distance > 100 && distance < 150) {
        // numSubLatLng.performCount = 5;
        numSubLatLng.totalRecord = Math.ceil(20 * reduceNumSubLatLngReq);
    } else if (distance > 150 && distance < 200) {
        // numSubLatLng.performCount = 6;
        numSubLatLng.totalRecord = Math.ceil(25 * reduceNumSubLatLngReq);
    } else if (distance > 200 && distance < 300) {
        // numSubLatLng.performCount = 6;
        numSubLatLng.totalRecord = Math.ceil(30 * reduceNumSubLatLngReq);
    } else if (distance > 300) {
        // numSubLatLng.performCount = 6;
        numSubLatLng.totalRecord = Math.ceil(50 * reduceNumSubLatLngReq);
    }
    return numSubLatLng;
}

function calculateAngle(selectedLatLng, currLatLng, nextLatLng) {
    const numerator = currLatLng.lat * (selectedLatLng.lng - nextLatLng.lng) +
        selectedLatLng.lat * (nextLatLng.lng - currLatLng.lng) +
        nextLatLng.lat * (currLatLng.lng - selectedLatLng.lng);
    // let numerator = P2Y*(P1X-P3X) + P1Y*(P3X-P2X) + P3Y*(P2X-P1X);
    const denominator = (currLatLng.lng - selectedLatLng.lng) * (selectedLatLng.lng - nextLatLng.lng) +
        (currLatLng.lat - selectedLatLng.lat) * (selectedLatLng.lat - nextLatLng.lat);
    // let denominator = (P2X-P1X)*(P1X-P3X) + (P2Y-P1Y)*(P1Y-P3Y);
    const ratio = numerator / denominator;

    const angleRad = Math.atan(ratio);
    let angleDeg = (angleRad * 180) / Math.PI;

    if (angleDeg < 0) {
        angleDeg = 180 + angleDeg;
    }
    return angleDeg;
}

export function generateOverlayImg(locationArr, imgArr: string[], map: google.maps.Map) {
    let historicalOverlay;

    for (let count = 0; count < locationArr.length; count++) {
        historicalOverlay = new google.maps.GroundOverlay(
            '',
            locationArr[count]);
        historicalOverlay.set('url', imgArr[count]);
        if (count == 0) {
            historicalOverlay.setOpacity(0);
        } else {
            historicalOverlay.setOpacity(0.7);
        }
        historicalOverlay.setMap(map);
    }
}
