<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div id="performance">
    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid grid-table --gutter">
                    <div class="grid__block   w-3of12@xs">
                        <h2 class="reports__heading   heading --level-2 --partial-underline">
                            Results:
                        </h2>
                    </div>

                    <div class="grid__block inline-table-button  w-9of12@xs   ta-r@xs">
                        <button type="button" class="button download-button" (click)="downloadPdf()">
                            <div class="download-button__icon">
                                <img src="assets/images/scss-components/buttons/icon-download.svg" width="24" height="18" alt="Download">
                            </div>

                            <div class="download-button__desc">
                                Download
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="dashboard-box__highlight-container">
                    <div class="dashboard-box__highlight-inner">
                        <!-- col 1 score and hour  -->
                        <div class="dashboard-box__highlight-box">

                            <div class="dashboard-box__highlight-box-col">
                                <app-spinner #weeklyScoring_spinner></app-spinner>
                                <div class="graph-chart__panel" [ngClass]="scoringDriverLabelCssClass">
                                    <div class="graph-chart__panel-title">
                                        Weekly Driver Score
                                    </div>

                                    <div class="graph-chart__panel-count">

                                        <div class="graph-chart__panel-icon">
                                            <ul class="graph-chart__panel-rating">
                                                <li *ngFor="let i of dummyArrayThree">
                                                    <img *ngIf="i < scoringDriverLabelStars.count" src="{{ 'assets/images/ng-components/dashboard/icon-star-select-' + scoringDriverLabelStars.color + '.svg'}}">
                                                    <img *ngIf="i >= scoringDriverLabelStars.count" src="{{ 'assets/images/ng-components/dashboard/icon-star-unselect-' + scoringDriverLabelStars.color + '.svg'}}">
                                                </li>
                                            </ul>
                                        </div>
                                        <div class=" graph-chart__panel-number">
                                            {{scoringDriverLabelThisWeek}}%
                                        </div>
                                    </div>

                                    <div class="graph-chart__panel-desc">
                                        {{scoringDriverLabelDiff}}% (Previous Week: {{scoringDriverLabelLastWeek}}%)
                                    </div>
                                </div>
                            </div>

                            <div class="dashboard-box__highlight-box-col">
                                <app-spinner #weeklyWorkingHour_spinner></app-spinner>
                                <div class="graph-chart__panel" [ngClass]="driverPerformanceCssClass">
                                    <div class="graph-chart__panel-title">
                                        Weekly Working Hours
                                    </div>

                                    <div class="graph-chart__panel-count">
                                        <div class="graph-chart__panel-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-weekly-working.svg">
                                        </div>

                                        <div class="graph-chart__panel-number">
                                            {{ driverPerformanceThisWeek }}hr
                                        </div>
                                    </div>

                                    <div class="graph-chart__panel-desc">
                                        {{ driverPerformanceDiff }} (Previous Week: {{ driverPerformanceLastWeek }}hr)
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dashboard-field">
                    <div class="dashboard-field__inner">
                        <div class="dashboard-field__box">
                            <div class="dashboard-field__headings">
                                <div class="dashboard-field__headings-title --past-score">
                                    Past Score
                                </div>
                            </div>

                            <div class="dashboard-field__contents">
                                <app-spinner #pastScore_spinner></app-spinner>
                                <div *ngIf="!perfCharts.PastScore.isEmpty" class="graph-chart">
                                    <div class="graph-chart__panel" style="height: 250px;">
                                        <!-- Insert chart here -->
                                        <div style="height: 250px">
                                            <canvas id="PastScore"></canvas>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="perfCharts.PastScore.isEmpty" class="dashboard-field__content-idle">
                                    <div class="dashboard-field__content-idle-icon">
                                        <img src="assets/images/ng-components/dashboard/icon-indicator-smile.svg">
                                    </div>

                                    <div class="dashboard-field__content-idle-message">
                                        There are no Past Score this week.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard-field__inner">
                        <div class="dashboard-field__box">
                            <div class="dashboard-field__headings">
                                <div class="dashboard-field__headings-title --past-working-hour">
                                    Past Working Hours
                                </div>
                            </div>

                            <div class="dashboard-field__contents">
                                <app-spinner #pastWorkingHour_spinner></app-spinner>
                                <div *ngIf="!perfCharts.PastWorkingHours.isEmpty" class="graph-chart">
                                    <div class="graph-chart__panel" style="height: 250px;">
                                        <!-- Insert chart here -->
                                        <div style="height: 250px">
                                            <canvas id="PastWorkingHours"></canvas>
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="perfCharts.PastWorkingHours.isEmpty" class="dashboard-field__content-idle">
                                    <div class="dashboard-field__content-idle-icon">
                                        <img src="assets/images/ng-components/dashboard/icon-indicator-smile.svg">
                                    </div>

                                    <div class="dashboard-field__content-idle-message">
                                        There are no Past Working Hours this week.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard-field__inner --full-width">
                        <div class="dashboard-field__box">
                            <div class="dashboard-field__headings">
                                <div class="dashboard-field__headings-title --violation-count">
                                    Weekly Violation Count
                                </div>
                            </div>

                            <div class="dashboard-field__contents">
                                <app-spinner #weeklyViolationCount_spinner></app-spinner>
                                <div class="violation-count">
                                    <div class="violation-count__col">
                                        <div class="graph-chart__panel" [ngClass]="classViolations['HarshBrake']">
                                            <div class="graph-chart__panel-title">
                                                Harsh Braking
                                            </div>
                                            <div class="dashboard-table__highlight">
                                                <div class="graph-chart__panel-count">
                                                    <div class="graph-chart__panel-icon">
                                                        <img src="assets/images/scss-components/key-value-icons/icon-braking.svg" width="38" height="27">
                                                    </div>

                                                    <div class="graph-chart__panel-number">
                                                        {{weekVioHBLabel}}
                                                    </div>
                                                </div>

                                                <div class="graph-chart__panel-desc">
                                                    {{diffVioHBLabel}} (Previous Week: {{lastweekVioHBLabel}})
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="violation-count__col">
                                        <div class="graph-chart__panel" [ngClass]="classViolations['HarshAcceleration']">
                                            <div class="graph-chart__panel-title">
                                                Harsh Acceleration
                                            </div>
                                            <div class="dashboard-table__highlight">
                                                <div class="graph-chart__panel-count">
                                                    <div class="graph-chart__panel-icon">
                                                        <img src="assets/images/scss-components/key-value-icons/icon-acceleration.svg" width="33" height="29">
                                                    </div>

                                                    <div class="graph-chart__panel-number">
                                                        {{weekVioHALabel}}
                                                    </div>
                                                </div>

                                                <div class="graph-chart__panel-desc">
                                                    {{diffVioHALabel}} (Previous Week: {{lastweekVioHALabel}})
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="violation-count__col">
                                        <div class="graph-chart__panel" [ngClass]="classViolations['HarshTurn']">
                                            <div class="graph-chart__panel-title">
                                                Harsh Turning
                                            </div>
                                            <div class="dashboard-table__highlight">
                                                <div class="graph-chart__panel-count">
                                                    <div class="graph-chart__panel-icon">
                                                        <img src="assets/images/scss-components/key-value-icons/icon-turning.svg" width="25" height="25">
                                                    </div>

                                                    <div class="graph-chart__panel-number">
                                                        {{weekVioHCLabel}}
                                                    </div>
                                                </div>

                                                <div class="graph-chart__panel-desc">
                                                    {{diffVioHCLabel}} (Previous Week: {{lastweekVioHCLabel}})
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="violation-count__col">
                                        <div class="graph-chart__panel" [ngClass]="classViolations['Speeding']">
                                            <div class="graph-chart__panel-title">
                                                Speeding
                                            </div>
                                            <div class="dashboard-table__highlight">
                                                <div class="graph-chart__panel-count">
                                                    <div class="graph-chart__panel-icon">
                                                        <img src="assets/images/scss-components/key-value-icons/icon-speeding.svg" width="33" height="29">
                                                    </div>

                                                    <div class="graph-chart__panel-number">
                                                        {{weekVioSpeedingLabel}}
                                                    </div>
                                                </div>

                                                <div class="graph-chart__panel-desc">
                                                    {{diffVioSpeedingLabel}} (Previous Week: {{lastweekVioSpeedingLabel}})
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="violation-count__col">
                                        <div class="graph-chart__panel" [ngClass]="classViolations['RestrictedDrivingHour']">
                                            <div class="graph-chart__panel-title">
                                                Driver Time
                                            </div>
                                            <div class="dashboard-table__highlight">
                                                <div class="graph-chart__panel-count">
                                                    <div class="graph-chart__panel-icon">
                                                        <img src="assets/images/scss-components/key-value-icons/icon-time.svg" width="28" height="28">
                                                    </div>

                                                    <div class="graph-chart__panel-number">
                                                        {{weekVioDriveTimeLabel}}
                                                    </div>
                                                </div>

                                                <div class="graph-chart__panel-desc">
                                                    {{diffVioDriveTimeLabel}} (Previous Week: {{lastweekVioDriveTimeLabel}})
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="violation-count__col">
                                        <div class="graph-chart__panel" [ngClass]="classViolations['Mileage']">
                                            <div class="graph-chart__panel-title">
                                                Mileage
                                            </div>
                                            <div class="dashboard-table__highlight">
                                                <div class="graph-chart__panel-count">
                                                    <div class="graph-chart__panel-icon">
                                                        <img src="assets/images/scss-components/key-value-icons/icon-mileage.svg" width="33" height="29">
                                                    </div>

                                                    <div class="graph-chart__panel-number">
                                                        {{weekVioMileageLabel}}
                                                    </div>
                                                </div>

                                                <div class="graph-chart__panel-desc">
                                                    {{diffVioMileageLabel}} (Previous Week: {{lastweekVioMileageLabel}})
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
