import { environment } from './../environments/environment';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { FcmService } from './shared/service/fcm.service';
import { Router, NavigationEnd } from '@angular/router';

import * as LocalStorageUtil from './util/localStorageUtil';
import * as DomUtil from './util/domUtil';
import * as EnvUtil from './util/envUtil';
import * as AsyncUtil from './util/asyncUtil';
import * as ComponentUtil from './util/componentUtil';

import { MessageBusService, BusMessage } from './_services/messagebus.service';
import { SpinnerComponent } from './components/common/spinner/spinner.component';
import { AuthService } from './_services/auth';
import { AppForceUpdateComponent } from './components/site/app-force-update/app-force-update.component';
// import { EmailService } from './_services/email/email.service';

import {
    PushNotifications
} from '@capacitor/push-notifications';
import { StatusBar } from '@capacitor/status-bar';
import { Filesystem } from '@capacitor/filesystem';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    showDebugBox: boolean = false; // debug
    debugMessage: string = 'DEV'; // debug
    debugHideBtn: string = 'hide';
    hideMessage: string = "";
    isDebugBoxHide: boolean = true;
    screenWidth: number;
    screenHeight: number;

    @ViewChild("updatePopup", { static: false }) updatePopup: AppForceUpdateComponent;
    @ViewChild("site_spinner", { static: true }) siteSpinner: SpinnerComponent;

    title = 'mtnt-web';
    isLoggedIn: boolean = false;
    isShowLeftbar: boolean = false;
    sessionCheckTimer: NodeJS.Timer = null;

    // Google Analytics Key
    googleAnalyticsKey: string = environment.googleConfig.googleAnalyticsKey;

    routerSubscription: Subscription;
    msgBusSubscription: Subscription;
    notificationSubscription: Subscription;

    constructor(
        private router: Router,
        private platform: Platform,
        private fcm: FcmService,
        private msgbus: MessageBusService,
        private authService: AuthService,
        // private emailService: EmailService
    ) {
        const busesPort = ["system"];
        this.msgBusSubscription = this.msgbus.messagebus$.pipe(
            filter(message => busesPort.indexOf(message.source) != -1)
        ).subscribe(this.messageBusGetMessage.bind(this));
    }

    async ngOnInit() {

        // Indicate Debug + user device platforms
        // if (DomUtil.isMobileApp(this.platform)) {
        if (EnvUtil.isDev()) {
            this.showDebugBox = true;
            this.screenWidth = window.screen.width;
            this.screenHeight = window.screen.height;
            this.debugMessage = `${environment.systemInfo.version}_DEV - device_platform: ${DomUtil.getDevicePlatforms(this.platform)} screen: [ w: ${this.screenWidth} px, h: ${this.screenHeight} px ]`;
            // this.emailService.sendErrorEmail(new Error('testing error'));
        }


        const whiteList = ['/app-download'];
        if (!whiteList.find(url => window.location.href.endsWith(url))) {
            const _this = this;
            return AsyncUtil.asAsyncPromise(async function () {
                _this.initRouterEventListener();
                await _this.initSessionAuthentication(true);
            });
        } else {
            console.debug('AppComponent: Whitelisted URL to skip session authentication on page load');
        }

        if (DomUtil.isMobileApp(this.platform)) {
            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            PushNotifications.requestPermissions().then(result => {
                if (result.receive === 'granted') {
                    console.log("Push Notification Permission granted");
                } else {
                    // Show some error
                    console.error("Push Notification Permission denied");
                }
            });

        }
    }

    // genNewPlatform() {
    //     return DomUtil.getDevicePlatforms(new Platform());
    // }

    ngOnDestroy() {
        this.killSessionCheckTimer();
        ComponentUtil.killRxjsSubscription(this.routerSubscription);
        ComponentUtil.killRxjsSubscription(this.msgBusSubscription);
        // this.routerSubscription.unsubscribe();
        // this.msgBusSubscription.unsubscribe();
    }

    /* Message Controller*/
    messageBusGetMessage(message: BusMessage) {
        switch (message.source) {
            case "system":
                switch (message.messagetype) {
                    case "spinner": {
                        const show: boolean = message.data.show;
                        if (show) {
                            this.siteSpinner.show();
                        } else {
                            this.siteSpinner.hideAll();
                        }
                        break;
                    }
                    // case "login-page": {
                    //     this.confirmNoUserSession();
                    //     break;
                    // }
                    case "logout": {
                        this.confirmNoUserSession();
                        break;
                    }
                    case "login": {
                        this.initSessionAuthentication();
                        break;
                    }
                }
                break;
        }
    }

    initRouterEventListener(): void {
        ComponentUtil.killRxjsSubscription(this.routerSubscription);

        //Add events upon route navigations
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            distinctUntilChanged()
        ).subscribe(event => {

            // Show/Hide Left bar outlet
            this.isShowLeftbar = (this.router.url.startsWith('/' + environment.appConfig.page.mapUrlPath));

            if (this.isLoggedIn) {
                // Generate Menu if failed previously
                this.msgbus.sendMessage(new BusMessage("menu", "reload", null));
            }

            // Scroll to top of page (depreciated)
            //  instead use: RouterModule option -> scrollPositionRestoration: 'enabled'
            // try {
            //     // Smooth scrolling in modern web browsers
            //     window.scrollTo({
            //         top: 0,
            //         left: 0,
            //         behavior: 'smooth'
            //     });
            // } catch (e) {
            //     // For older browsers without smooth scrolling support
            //     window.scrollTo(0, 0);
            // }

            // Send Google Analytics
            this.initGoogleAnalytics(event);

        });

    }

    initSessionCheckTimer(): void {
        // console.debug('AppComponent: initSessionCheckTimer()');
        this.killSessionCheckTimer();
        const _this = this;
        this.sessionCheckTimer = setInterval(async function () {
            await _this.checkIsLoggedIn(true);
        }, 5000);
    }

    killSessionCheckTimer(): void {
        // console.debug('AppComponent: killSessionCheckTimer()');
        if (this.sessionCheckTimer) {
            clearInterval(this.sessionCheckTimer);
        }
    }

    confirmNoUserSession(): void {
        this.killSessionCheckTimer();
        ComponentUtil.killRxjsSubscription(this.notificationSubscription);
        this.isLoggedIn = false;
        this.isShowLeftbar = false;
    }

    async initSessionAuthentication(renewToken: boolean = false): Promise<void> {
        await this.checkIsLoggedIn();
        if (this.isLoggedIn) {
            //Refresh Token
            if (renewToken) {
                try {
                    console.debug('AppComponent: Trying to refresh token on app load.');
                    const successRenewed: boolean = await this.authService.renewLoginToken();
                    await AsyncUtil.wait(100); // let routing takes place first
                    if (!successRenewed) {
                        this.killSessionCheckTimer();
                        LocalStorageUtil.localStorageRemove(); // clear session immediately before showing snackbar (to block other HTTP calls)
                        await this.authService.noAuthorizationRedirectLogin(); // no prompt
                        return;
                    }

                } catch (err) {
                    // do nothing
                }
            }
            // Mobile App
            if (DomUtil.isMobileApp(this.platform)) {
                await this.initializeMobileApp();
            }

        }

        if (DomUtil.isMobileApp(this.platform)) {
            SplashScreen.hide({ fadeOutDuration: 300 });
        }
    }

    async checkIsLoggedIn(redirectAway: boolean = false): Promise<void> {
        // console.debug('AppComponent: checkIsLoggedIn');
        if (LocalStorageUtil.localStorageGet('currentUser')) {
            this.isLoggedIn = true;
            this.initSessionCheckTimer();
        } else {
            this.killSessionCheckTimer();
            if (redirectAway) {
                // Navigate to Login Page If Session already cleared
                const byPassUrl = ['/login', '/user/activate-change-password'];
                if (!byPassUrl.find(result => this.router.url.startsWith(result))) {
                    await this.authService.noAuthorizationRedirectLogin(); // no prompt
                }
            }
        }
    }

    async initializeMobileApp(): Promise<void> {
        await this.updatePopup.checkUpdate();
        await this.notificationSetup();
        StatusBar.setOverlaysWebView({ overlay: false });
        StatusBar.setBackgroundColor({ color: '#128958' });
        SplashScreen.hide({ fadeOutDuration: 300 });
    }

    async notificationSetup() {
        await this.fcm.initNotification();
    }

    // async presentToast(message): Promise<void> {
    //     const toast = await this.toastController.create({
    //         message,
    //         duration: 3000
    //     });
    //     toast.present();
    // }

    initGoogleAnalytics(event): void {
        (<any>window).ga('create', this.googleAnalyticsKey, 'auto');
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
    }

    showDebugInfo() {
        if (this.isDebugBoxHide) {
            this.debugHideBtn = "unhide";
            this.hideMessage = this.debugMessage;
            this.debugMessage = "";
        } else {
            this.debugHideBtn = "hide";
            this.debugMessage = this.hideMessage;
        }
        this.isDebugBoxHide = !this.isDebugBoxHide;
    }
}
