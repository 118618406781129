<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Create New Checkpoint
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #checkpointForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="compulsory-notes">
                    *Compulsory fields
                </div>
                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex geofence-field">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Step 1: Checkpoint Info *
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Checkpoint Name: <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" [(ngModel)]="checkpointName"
                                                        name="checkpointName" class="field__input" required
                                                        maxlength="255">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Step 2: Checkpoint Location <span class="indicator-green">*</span>
                    </h2>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-9of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Search Address:
                                        </div>
                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input id="searchInput" type="text" placeholder="eg: KLCC"
                                                            class="field__input">
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map__canvas" id="map">
                    <!-- map area <span class="indicator-green">*</span> -->
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button"
                                [disabled]="checkpointForm.invalid || checkpointRadius==0"
                                (click)="showPopup(createCheckpointPopup)">
                                CREATE
                            </button>

                            <button class="button inline-button__inputs --grey" submit-button type="button"
                                (click)="navigateToEditRoute()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>

<app-popup #createCheckpointPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43"
                class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="createCheckpoint();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>