import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TripsService } from '../../../../_services/trips/trips.service';
import { VehicleService } from '../../../../_services/vehicle/vehicle.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';
import { MaintenanceService } from './../../../../_services/maintenance/maintenance.service';
import { NotificationService } from './../../../../_services/notification/notification.service';
import { PagerService } from './../../../../_services/pager/pager.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';

import { SpinnerComponent } from './../../../common/spinner/spinner.component';

import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import * as moment from 'moment';
import * as Message from '../../../../constants/message';
import { modules } from '../../../../constants/module-access.constant';
import * as LocalStorageUtil from '../../../../util/localStorageUtil';
import * as NumberUtil from '../../../../util/numberUtil';
import * as USER_ROLE from './../../../../constants/userRole';

@Component({
    selector: 'app-vehicle-panel-health-status',
    templateUrl: './health-status.component.html',
    styleUrls: ['./health-status.component.scss']
})
export class VehicleHealthStatusComponent implements OnInit, OnDestroy {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("violation_spinner",{static:false}) violationSpinner: SpinnerComponent;
    @ViewChild("maintenance_spinner",{static:false}) maintenanceSpinner: SpinnerComponent;
    @Input('vehiclePanel') vehiclePanel: any;
    @Input('fuelTankSize') fuelTankSize: any;
    // @Input('hideOnOverlayClick') hideOnOverlayClick:boolean = true;

    private _init: boolean = false;

    @Input()
    set init(init: boolean) {
        if (!init) {
            return;
        }
        if (!this._init) {
            this._init = true;
            this.triggerInit();
        }
    }

    get init(): boolean {
        return this._init;
    }

    view: string = 'overview';
    vehicleId: number = 0;
    vehicleName: string = '';
    fuelPercentage: number = 0;

    //Input (requests)
    startdate: any = moment().subtract(7, "days").format("YYYY-MM-DD");
    enddate: any = moment().format("YYYY-MM-DD");
    pageSize: number = 5;
    startRecord: number = 1;
    searchViolationHistory: boolean = false;
    voltsTreshold: number = 14;

    /* vehicle health alerts */
    healthAlertsTimeRange: number = 60; //second basis
    healthAlertsPageSize: number = 0;
    healthAlertsstartRecord: number = 0;

    //Output (responses)
    /* violation history */
    violationHistory: any = {
        totalRecord: 0,
        vehicleId: 0,
        grandTotalHa: 0,
        grandTotalHb: 0,
        grandTotalHc: 0,
        grandTotalExceedTimeOfDay: 0,
        grandTotalIdle: 0,
        grandTotalGeofenceEnter: 0,
        grandTotalGeofenceExit: 0,
        grandTotalExceedMileage: 0,
        grandTotalSpeedingTime: 0,
        result: []
    };
    pager: any = {};
    /* upcoming maintenance */
    upcomingMaintenance: any = {
        result: []
    };
    upcomingPager: any = {};
    /* vehicle health alerts */
    vehicleHealthAlerts: any = {
        result: []
    };

    lastResizeTime: any = new Date(0);
    resizeTimeout: any;
    datepickerTouchUi: boolean = false;
    allowManage: boolean = true;
    moment: any = moment;
    viewable: boolean = false;
    editable: boolean = false;

    constructor(
        private vehicleService: VehicleService,
        private tripService: TripsService,
        private maintenanceService: MaintenanceService,
        private notificationService: NotificationService,
        private pagerService: PagerService,
        private snackBar: SnackBarService,
        private launchNavigator: LaunchNavigator,
        private routerProxyService: RouterProxyService,
        public el: ElementRef,
        private mpService: ModulePermissionService
    ) { }

    async ngOnInit() {
        this.verifyRole();
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_MAINTENANCE.value);
        this.viewable = permissions[modules.CRUD_MAINTENANCE.value].vAccess;
        this.editable = permissions[modules.CRUD_MAINTENANCE.value].eAccess;
    }

    async triggerInit() {
        this.updateDatepickerTouchUi();
        if (this.fuelTankSize != null) {
            this.fuelPercentage = NumberUtil.roundOff(this.vehiclePanel.trip.fuelLevel / this.fuelTankSize * 100, 1);
        } else {
            this.fuelPercentage = 0;
        }
    }

    async ngOnDestroy() {
    }

    updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }

    async getVehicleHealthStatus() {
        // console.debug("VehiclePenal -> VehicleHealthComponent: init vehicle health");
        this.pageSpinner.show();
        //console.debug("SHOWING SPINNER FROM getVehicleHealthStatus()");

        // get Violation History, get Vehicle Health Alerts
        const concurrentList = [this.getVehicleViolationHistory(), this.getVehicleHealthAlertsStatus()];
        //verify is user have view maintenance permission
        if (this.viewable) {
            // get Upcoming Maintenance
            concurrentList.push(this.getUpcomingMaintenance());
        }
        await Promise.all(concurrentList);

        this.searchViolationHistory = true;
        this.pageSpinner.hide();
        //console.debug("HIDING SPINNER FROM getVehicleHealthStatus()");
    }

    async getVehicleViolationHistory(page: number = 1) {
        //console.debug("SHOWING SPINNER FROM getVehicleViolationHistory()");
        this.violationSpinner.show();
        try {
            this.startdate = moment(this.startdate).format("YYYY-MM-DD");
            this.enddate = moment(this.enddate).format("YYYY-MM-DD");
            if (this.startdate > this.enddate) {
                const msg = Message.getMessage(Message.MESSAGE.START_DATE_LARGER.value);
                this.snackBar.openGenericSnackBar(msg);
                return;
            }
            this.startRecord = ((page - 1) * this.pageSize) + 1;
            const result = await this.tripService.getVehicleViolationHistory(this.vehicleId, this.startdate, this.enddate, this.pageSize, this.startRecord);
            this.violationHistory = result.body;
            this.pager = this.pagerService.getPager(this.violationHistory.totalRecord, page, this.pageSize);

            // parse some values to reduce function data bindings
            this.violationHistory.result.forEach(eachDay => {
                eachDay.dateLabel = '';
                const date = moment(eachDay.date);
                if (date.isSame(moment(), 'day')) {
                    eachDay.dateLabel = 'Today';
                } else if (date.isSame(moment().subtract(1, 'days'), 'day')) {
                    eachDay.dateLabel = 'Yesterday';
                } else /*if (date.isBefore(moment().subtract(1, 'days'), 'day'))*/ {
                    eachDay.dateLabel = date.format('MMM DD, YYYY');
                }
                eachDay.tripViolationDetails.forEach(eachTrip => {
                    eachTrip.gpsTimeStampStartLabel = moment(eachTrip.gpsTimeStampStart).format('hh:mm A');
                    eachTrip.gpsTimeStampEndLabel = moment(eachTrip.gpsTimeStampEnd).format('hh:mm A');
                    eachTrip.totalGeofenceLabel = eachTrip.totalGeofenceEnter + eachTrip.totalGeofenceExit;
                });
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.violationSpinner.hide();
            //console.debug("HIDING SPINNER FROM getVehicleViolationHistory()");
        }
    }

    async getUpcomingMaintenance(page: number = 1) {
        //console.debug("SHOWING SPINNER FROM getUpcomingMaintenance()");
        this.maintenanceSpinner.show();
        try {
            this.startRecord = ((page - 1) * this.pageSize) + 1;
            const result = await this.maintenanceService.getUpcomingVehicleMaintenance(this.pageSize, this.startRecord, null, true, null, this.vehicleId);
            this.upcomingMaintenance = result.body;
            this.upcomingPager = this.pagerService.getPager(this.upcomingMaintenance.totalRecord, page, this.pageSize);

            // parse some values to reduce function data bindings
            this.upcomingMaintenance.result.forEach(schedule => {

                schedule.distanceUntilLabel = schedule.distanceUntil != null ? NumberUtil.formatFloat(schedule.distanceUntil, 1) + ' km' : '-';
                schedule.nextOdometerLabel = schedule.nextOdometer != null ? NumberUtil.formatFloat(schedule.nextOdometer, 1) + ' km' : '-';

                schedule.engineHourUntilLabel = NumberUtil.formatHourMinute(schedule.engineHourUntil, '-');
                schedule.nextEngineHoursLabel = NumberUtil.formatHourMinute(schedule.nextEngineHours, '-');

                schedule.daysUntilLabel = schedule.daysUntil != null ? schedule.daysUntil + ' days' : '-';
                schedule.nextServiceDateLabel = schedule.nextServiceDate != null ? moment(schedule.nextServiceDate, "YYYY-MM-DD").format("DD/MM/YYYY") : '-';
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.maintenanceSpinner.hide();
            //console.debug("HIDING SPINNER FROM getUpcomingMaintenance()");
        }
    }

    async getVehicleHealthAlertsStatus() {
        try {
            const result = await this.vehicleService.getVehicleLatestHealthStatus(this.vehicleId);
            this.vehicleHealthAlerts = result.body;
            this.vehicleHealthAlerts.isBatteryLow = (!this.vehicleHealthAlerts.isBatteryLow) ? 0 : this.vehicleHealthAlerts.isBatteryLow;
            this.vehicleHealthAlerts.isTemperatureHigh = (!this.vehicleHealthAlerts.isTemperatureHigh) ? 0 : this.vehicleHealthAlerts.isTemperatureHigh;
            this.vehicleHealthAlerts.isDtcPoor = (!this.vehicleHealthAlerts.isDtcPoor) ? 0 : this.vehicleHealthAlerts.isDtcPoor;
            this.vehicleHealthAlerts.batteryLevel = (!this.vehicleHealthAlerts.batteryLevel) ? 0 : this.vehicleHealthAlerts.batteryLevel;
            // console.log(this.vehicleHealthAlerts);
            // this.vehicleHealthAlerts.isBatteryLow = null;
            // this.vehicleHealthAlerts.isTemperatureHigh = null;
            // this.vehicleHealthAlerts.isDtcPoor = null;
            // this.vehicleHealthAlerts.batteryLevel = 24;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    navigateToViewMaintenanceByVehicleName(vehicleName) {
        this.routerProxyService.navigate(['/create-manage/maintenance/view'], {
            queryParams: {
                autoSearch: true,
                tab: 'vehicles',
                vehicleName: vehicleName
            }
        });
    }

    async showPastTripRoute(startDate, endDate, tripId) {
        await this.vehiclePanel.toggleTriplist(startDate, endDate, tripId);
    }

    verifyRole() {
        const currentUser = LocalStorageUtil.localStorageGet('currentUser');
        if (currentUser.roleId != USER_ROLE.USER_ROLE.DRIVER.roleId) {
            this.allowManage = true;
        } else {
            this.allowManage = false;
        }
    }

    /**
     * triggered when user clicks on a violation below 'Violation History'
     * @author Sam Liew 2024-01-29
     */
    async onFilterByViolation(violation:string) {
        
        this.vehiclePanel.setViolation(violation);
        await this.showPastTripRoute(this.startdate, this.enddate, '');

    }
}
