import { TokenService } from './../../../../_services/token/token.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { PopupService } from './../../../common/popup/popup.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { PagerService } from './../../../../_services/pager/pager.service';
import { SupportService } from './../../../../_services/support/support.service';
import { AuthService } from './../../../../_services/auth/auth.service';
import * as USER_ROLE from '../../../../constants/userRole';
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ResetPasswordComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("successNotification",{static:false}) successNotification;
    emailSentPopupMsg: string;
    @ViewChild('resetPasswordTable',{static:false}) resetPasswordTable;
    resetPasswordTableScrolledToEnd: boolean = false;
    constructor(
        private pagerService: PagerService,
        private supportService: SupportService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private tokenService: TokenService,
        private authService: AuthService
    ) { }

    userRole = USER_ROLE;

    //Pagination
    pager: any = {}; //Pager object
    pageRecordSize: number = 10;

    searchBy = 'company';
    sortField = 'CompanyName';
    sortAscending: boolean = true;
    filterKey = 'companyName';
    filterValue = '';
    currentFilterValue = '';

    usersOriDetails: any = {};
    usersDetailsList: any = [];

    resetPasswordUserId;
    resetPasswordUserEmail;
    
    newUserEmail: string;
    async ngOnInit() {
        const token = await this.tokenService.removeAdministratorUser(); // remove administrator token
        if (token) {
            await this.authService.removeAdminitratorLogin(token);
        }

        await this.getDisplayUsersList();
    }

    async getDisplayUsersList(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Get Users Info Details Page
            const response: any = await this.supportService.getCompanyUsers(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            this.usersOriDetails = response.body;
            this.usersDetailsList = response.body.result;
            this.pager = this.pagerService.getPager(this.usersOriDetails.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.usersDetailsList.forEach(user => {
                user.roleLabel = USER_ROLE.getUserRoleLabel(user.role);
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async searchValue() {
        if (this.searchBy === 'company') {
            this.filterKey = 'CompanyName';
        } else if (this.searchBy === 'username') {
            this.filterKey = 'UserLoginName';
        } else if (this.searchBy === 'name') {
            this.filterKey = 'UserName';
        } else {
            return;
        }
        this.currentFilterValue = this.filterValue;
        await this.getDisplayUsersList();
    }

    getSortingStatus(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }
        await this.getDisplayUsersList();
    }

    getPlaceHolderText() {
        let placeHolderText = 'Search By';
        if (this.searchBy === 'company') {
            placeHolderText = `${placeHolderText} Company`;
        } else if (this.searchBy === 'username') {
            placeHolderText = `${placeHolderText} Username`;
        } else if (this.searchBy === 'name') {
            placeHolderText = `${placeHolderText} Name`;
        }
        return placeHolderText;
    }

    setUserIdAndEmail(userId, userEmail) {
        this.resetPasswordUserId = userId;
        this.resetPasswordUserEmail = userEmail;
        this.newUserEmail = '';
        // console.log(this.resetPasswordUserId,this.resetPasswordUserEmail);
    }

    async reactivateUserAccount() {
        this.pageSpinner.show();
        try {
            const result = await this.supportService.reactivateUserAccount(this.resetPasswordUserId, this.resetPasswordUserEmail);
            if (result && result.statusCode === ResponseStatusCode.SUCCESS.code) {
                this.emailSentPopupMsg = Message.getMessage(Message.MESSAGE.EMAIL_SENT_ACCOUNT_REACTIVATE_LINK.value, this.resetPasswordUserEmail);
                this.showPopup(this.successNotification);
                this.getDisplayUsersList();
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }
    async resetPassword() {
        this.pageSpinner.show();
        try {
            const result = await this.supportService.resetPassword(this.resetPasswordUserId, this.resetPasswordUserEmail);
            if (result && result.statusCode === ResponseStatusCode.SUCCESS.code) {
                this.emailSentPopupMsg = Message.getMessage(Message.MESSAGE.EMAIL_SENT_PASSWORD_RESET_LINK.value, this.resetPasswordUserEmail);
                this.showPopup(this.successNotification);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }
    async updateUserEmail() {
        this.pageSpinner.show();
        try {
            const result = await this.supportService.updateUserEmail(this.resetPasswordUserId, this.newUserEmail);
            if (result && result.statusCode === ResponseStatusCode.SUCCESS.code) {
                this.emailSentPopupMsg = Message.getMessage(Message.MESSAGE.EMAIL_SENT_EMAIL_UPDATE_EMAIL_PASSWORD_LINK.value, this.newUserEmail);
                this.showPopup(this.successNotification);
            }
            await this.getDisplayUsersList();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }
    
    onResetPasswordTableScroll() {
        if (!this.resetPasswordTable) return;

        const tableElem = this.resetPasswordTable.nativeElement;
        this.resetPasswordTableScrolledToEnd = tableElem.scrollLeft + tableElem.clientWidth >= tableElem.scrollWidth;
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
