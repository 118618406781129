import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

import * as StringUtil from '../../util/stringUtil';

@Injectable({
    providedIn: 'root'
})
export class RouteService {

    constructor(private http: HttpClient) { }

    // Create Route
    async createRoute(routeName, vehicleIdList = []) {
        let body = {
            vehicleIdList: vehicleIdList,
        };

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(routeName)) {
            routeName = routeName.trim();
        }

        if (StringUtil.isNotEmptyOrNull(routeName)) {
            body = Object.assign({ name: routeName }, body);
        }

        const path = environment.mtntApiEndpoint + `/route`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    // Get Route
    async getRoute(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined, routeId = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';
        param += filterValue ? `&routeId=${routeId}` : '';

        const path = environment.mtntApiEndpoint + `/route${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();

        return result;
    }

    // Get Route Detail
    async getRouteDetails(routeId) {
        let params = {
            routeId: routeId,
        };

        const path = environment.mtntApiEndpoint + `/route/details`;
        const result: any = await this.http.get(path, { params: params }).toPromise();
        return result;
    }

    // Update Route
    async updateRoute(routeId, routeName, vehicleIdList = []) {
        let body = {
            vehicleIdList: vehicleIdList,
        };

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(routeName)) {
            routeName = routeName.trim();
        }

        if (StringUtil.isNotEmptyOrNull(routeName)) {
            body = Object.assign({ name: routeName }, body);
        }

        if (StringUtil.isNotEmptyOrNull(routeId)) {
            body = Object.assign({ id: +routeId }, body);
        }

        const path = environment.mtntApiEndpoint + `/route`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    // Delete Route
    async deleteRoute(routeIdList = []) {
        const body: any = { body: { routeIdList: routeIdList } };
        const path = environment.mtntApiEndpoint + `/route`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    // Create Checkpoint
    async createCheckpoint(checkpointName, routeId, lat, lng, radius, sequenceNo) {
        let body = {
        };

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(checkpointName)) {
            checkpointName = checkpointName.trim();
        }

        if (StringUtil.isNotEmptyOrNull(checkpointName)) {
            body = Object.assign({ name: checkpointName }, body);
        }

        if (StringUtil.isNotEmptyOrNull(routeId)) {
            body = Object.assign({ routeId: +routeId }, body);
        }

        if (StringUtil.isNotEmptyOrNull(lat)) {
            body = Object.assign({ lat: +lat }, body);
        }

        if (StringUtil.isNotEmptyOrNull(lng)) {
            body = Object.assign({ lng: +lng }, body);
        }

        if (StringUtil.isNotEmptyOrNull(radius)) {
            body = Object.assign({ radius: +radius }, body);
        }

        if (StringUtil.isNotEmptyOrNull(sequenceNo)) {
            body = Object.assign({ sequenceNo: +sequenceNo }, body);
        }

        const path = environment.mtntApiEndpoint + `/checkpoint`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    // Get Checkpoints
    async getCheckpoints(routeId) {
        let params = {
            routeId: routeId,
        };

        const path = environment.mtntApiEndpoint + `/checkpoint`;
        const result: any = await this.http.get(path, { params: params }).toPromise();
        return result;
    }

    // Update Checkpoint
    async updateCheckpoint(checkpointId, checkpointName, routeId, lat, lng, radius, sequenceNo) {
        let body = {
        };

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(checkpointId)) {
            body = Object.assign({ id: +checkpointId }, body);
        }

        if (StringUtil.isNotEmptyOrNull(checkpointName)) {
            checkpointName = checkpointName.trim();
        }

        if (StringUtil.isNotEmptyOrNull(checkpointName)) {
            body = Object.assign({ name: checkpointName }, body);
        }

        if (StringUtil.isNotEmptyOrNull(routeId)) {
            body = Object.assign({ routeId: +routeId }, body);
        }

        if (StringUtil.isNotEmptyOrNull(lat)) {
            body = Object.assign({ lat: +lat }, body);
        }

        if (StringUtil.isNotEmptyOrNull(lng)) {
            body = Object.assign({ lng: +lng }, body);
        }

        if (StringUtil.isNotEmptyOrNull(radius)) {
            body = Object.assign({ radius: +radius }, body);
        }

        if (StringUtil.isNotEmptyOrNull(sequenceNo)) {
            body = Object.assign({ sequenceNo: +sequenceNo }, body);
        }

        const path = environment.mtntApiEndpoint + `/checkpoint`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    // Delete Checkpoint
    async deleteCheckpoint(checkpointId) {
        let params = {
            checkpointId: checkpointId,
        };

        const path = environment.mtntApiEndpoint + `/checkpoint`;
        const result: any = await this.http.delete(path, { params: params }).toPromise();
        return result;
    }

    //Get Route Completion Report
    async getRouteCompletionReport(idType, idList, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const body = {
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        idType === 'vehicles' ? body['vehicleId'] = idList : body['driverId'] = idList;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const path = environment.mtntApiEndpoint + "/report/trip/route-completion";
        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    // Get Completed Route Checkpoints
    async getRouteCheckpoint(routeCompletedId) {
        let params = {
            routeCompletedId: routeCompletedId,
        };

        const path = environment.mtntApiEndpoint + `/report/trip/route-completion/checkpoint`;
        const result: any = await this.http.get(path, { params: params }).toPromise();
        return result;
    }
}