import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../../../environments/environment';

import { SpinnerComponent } from '../../../common/spinner/spinner.component';
import { PopupService } from '../../../../components/common/popup/popup.service';
import { DriverService } from './../../../../_services/driver/driver.service';
import { VehicleService } from '../../../../_services/vehicle/vehicle.service';
import { PagerService } from './../../../../_services/pager/pager.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { FileExportService } from '../../../../_services/file-export/file-export.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';
import { STATUS } from '../../../../constants/status.constant';
import { MessageBusService, BusMessage } from "./../../../../_services/messagebus.service";

import { modules } from '../../../../constants/module-access.constant';
import * as StringUtil from '../../../../util/stringUtil';
import * as DomUtil from './../../../../util/domUtil';

@Component({
    selector: 'app-view-vehicles',
    templateUrl: './view-vehicles.component.html',
    styleUrls: ['./view-vehicles.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ViewVehiclesComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("vehicleDetailsPopup",{static:false}) vehicleDetailsPopup;

    // Collapse Group
    expandGroupVehicle: any = [];
    expandGroupDriver: any = [];

    // Accessible for HTML
    vehicleViewOriList: any = [];
    vehicleViewInfoList: any = [];
    // filterVehicleNameAsc:boolean = true;
    currentTab: number = 1;
    vehicleViewInfoListRec: any = {};
    //Pagination
    pager: any = {}; //Pager object
    pageRecordSize: number = environment.appConfig.createManage.pageRecordSize_tiles;

    //Sort / Filter Vehicle Name
    sortField = 'VehicleName';
    sortAscending: boolean = true;
    filterKey = 'VehicleName'; // Filter By Vehicle Name
    filterValue = '';
    currentFilterValue = '';
    excludeVehicleDeactivated: boolean = true; // filter inactive vehicle/imei
    excludeDriverDeactivated: boolean = true; // filter inactive driver/user

    //Filter Vehicle Status
    currentVehicleStatus: string = '';

    //border color of vehicle
    STATUS: any = STATUS;
    showImageColor: boolean = false;

    // Assign Vehicle
    searchBy = 'vehicles';
    clickedVehicleId;

    // Vehicles Selector/Floater
    @ViewChild('vehiclesField',{static:false}) vehiclesField;
    vehiclesModelCompleteVehiclesList: any = []; //complete vehicle list no filter
    vehiclesModel: any = {}; //store vehicles to display for selection
    selectedVehicles: any = {}; //selection state of all vehicles
    selectedVehicleGroups: any = {}; //selection state of all groups
    selectedVehiclesList: any = []; //selected vehicle list
    vehiclesSelectedLabel: string = 'All Vehicles';

    // Drivers Selector/Floater
    @ViewChild('driversField',{static:false}) driversField;
    driversModelCompleteDriversList: any = [];
    driversModel: any = {};
    selectedDrivers: any = {};
    selectedDriverGroups: any = {};
    selectedDriversList: any = [];
    driversSelectedLabel: string = 'All Drivers';

    // General Selector Filter Selection when Typing
    subjectSelectionFloaterIsShown: boolean = false;
    filterSearchString: string = ""; //store filter search string
    isShowSelectAllOption: boolean = true;

    // Export Vehicle File
    exportVehicleFileHeader = ["Imei No", "Vehicle Name", "Vehicle Brand", "Vehicle Model", "Vehicle Plate No", "First Name", "Last Name", "Vehicle Group List"];

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    editable: boolean = false;

    // Auto refresh
    refreshPageTimer: any;
    private refreshTimerIntervalInMilliSeconds: number = environment.appConfig.map.refreshInterval * 1000;

    constructor(
        private route: ActivatedRoute,
        private popupService: PopupService,
        private vehicleService: VehicleService,
        private driverService: DriverService,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private routerProxyService: RouterProxyService,
        private fileExportService: FileExportService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService,
        private msgbus: MessageBusService,

    ) { }

    async ngOnInit() {
        // const isFromAssignPage = this.route.snapshot.paramMap.get('fromAssign');
        // if (isFromAssignPage) {
        //     await this.assign(); // Go to Assign Page
        // } else {
        //     await this.viewEdit();
        // }
        // Reopen tab after navigateSuccess() (not using route reuse strategy)
        const tab = this.routerProxyService.getViewTabFromQueryParam(this.route);
        switch (tab) {
            case 'view-edit': await this.viewEdit(); break;
            case 'assign': await this.assign(); break;
            default: await this.viewEdit(); // first tab
        }
        await this.checkUnviewedCampaign();
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_VEHICLE.value);
        this.editable = permissions[modules.CRUD_VEHICLE.value].eAccess;

        // this.initRefreshTimer();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async viewEdit() {
        this.currentTab = 1;
        this.filterValue = ''; // Empty Text Box
        this.currentFilterValue = '';
        this.searchBy = 'vehicles';
        await this.getDisplayVehicleList();
    }

    async assign() {
        this.currentTab = 2;
        this.filterValue = ''; // Empty Text Box
        this.currentFilterValue = '';
        this.searchBy = 'vehicles';
        this.currentVehicleStatus = '';
        // await this.getGroupVehicleAndDriver();
        await this.getVehicleDriverDetails();
    }

    async sort() {
        this.sortField = (this.searchBy === 'vehicles' || this.searchBy === 'imeis') ? 'VehicleName' : 'UserFirstName';
        this.sortAscending = !this.sortAscending;
        if (this.currentTab === 1) {
            await this.getDisplayVehicleList();
        } else {
            await this.getVehicleDriverDetails();
        }
    }

    async searchVehicle() {
        this.filterKey = this.searchBy === 'imeis' ? 'ImeiNo' : (this.searchBy === 'drivers' ? 'UserFullName' : 'VehicleName');
        this.currentFilterValue = this.filterValue;
        await this.getDisplayVehicleList();
    }

    async getDisplayVehicleList(page: number = 1) {
        this.pageSpinner.show();

        try {
            // this.initRefreshTimer();
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            //Get Vehicle Info Details Page
            const vehicleViewResponse: any = await this.vehicleService.getAllVehicleInfoDetails(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue, this.currentVehicleStatus);
            if (vehicleViewResponse) {
                this.vehicleViewOriList = vehicleViewResponse.body;
                this.vehicleViewInfoList = this.vehicleViewOriList.vehicle_details_list;
                this.pager = this.pagerService.getPager(this.vehicleViewOriList.totalRecord, page, this.pageRecordSize);

                // parse some values to reduce function data bindings
                this.vehicleViewInfoList.forEach(vehicle => {
                    vehicle.shortName = StringUtil.getShortName(vehicle.vehicleName);
                    vehicle.driverShortName = StringUtil.getShortName(vehicle.userFirstName + ' ' + vehicle.userLastName);
                });
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateToEditVehicle(vehicleId, clickEvent?: Event) {

        const excludedHtmlIds = [
            "group-colors"
        ];

        //Avoid navigate when click on group info
        if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
            return;
        }

        this.routerProxyService.navigate(['/create-manage/vehicles/edit', vehicleId]);
    }

    /* ---- Assign Vehicle Function ---- */

    async getGroupVehicleAndDriver() {
        this.pageSpinner.show();
        try {
            // Vehicle Selector
            this.vehiclesModel = await this.vehicleService.getGroupVehicleModelByCompany(null, null, null, this.excludeVehicleDeactivated);
            this.vehiclesModelCompleteVehiclesList = this.vehiclesModel.vehicles.slice(0);
            this.selectedVehicleGroups.all = true; //default select all vehicle
            this.onVehiclesAllSelectChange();

            // Driver Selector
            this.driversModel = await this.driverService.getGroupDriverModelByCompany(null, null, true, null, this.excludeDriverDeactivated);
            this.driversModelCompleteDriversList = this.driversModel.drivers.slice(0);
            this.selectedDriverGroups.all = true; //default select all driver
            this.onDriversAllSelectChange();

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async navigateToAssignVehicle(vehicleId, vehiclePlateNo, clickEvent?: Event) {

        const excludedHtmlIds = [
            "group-colors"
        ];

        //Avoid navigate when click on group info
        if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
            return;
        }

        this.clickedVehicleId = vehicleId;
        if (vehiclePlateNo === null) {
            this.showPopup(this.vehicleDetailsPopup);
        } else {
            this.routerProxyService.navigate(['/create-manage/vehicles/assign', vehicleId]);
        }
    }

    filterTickItemAndCountLabel() {
        if (this.searchBy === 'vehicles') {
            Object.getOwnPropertyNames(this.selectedVehicles).forEach(
                value => {
                    this.selectedVehicles[value] = this.selectedVehiclesList.indexOf(parseInt(value)) !== -1 ? true : false;
                }
            );
            this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
            this.updateVehiclesSelectedLabel();
            this.focusVehicleSearchBox();

        } else {
            Object.getOwnPropertyNames(this.selectedDrivers).forEach(
                value => {
                    this.selectedDrivers[value] = this.selectedDriversList.indexOf(parseInt(value)) !== -1 ? true : false;
                }
            );
            this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
            this.updateDriversSelectedLabel();
            this.focusDriverSearchBox();

        }
    }

    async getVehicleDriverDetails(page: number = 1) {
        this.pageSpinner.show();
        try {
            // this.filterTickItemAndCountLabel();
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            // Get Vehicle Id from Driver Id
            const vehicleSearchIdList = this.searchBy === 'vehicles' ? this.selectedVehiclesList : this.getVehicleIdFromDriverId();
            const vehicleViewResponse: any = await this.vehicleService.getAllVehicleInfoDetails(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue, this.currentVehicleStatus, vehicleSearchIdList);
            this.vehicleViewOriList = vehicleViewResponse.body;
            this.vehicleViewInfoList = this.vehicleViewOriList.vehicle_details_list;
            this.pager = this.pagerService.getPager(this.vehicleViewOriList.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.vehicleViewInfoList.forEach(vehicle => {
                vehicle.shortName = StringUtil.getShortName(vehicle.vehicleName);
                vehicle.driverShortName = StringUtil.getShortName(vehicle.userFirstName + ' ' + vehicle.userLastName);
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    getVehicleIdFromDriverId() {
        const tempVehicleIdList = [];
        for (let i = 0; i < this.driversModelCompleteDriversList.length; i++) {
            for (let j = 0; j < this.selectedDriversList.length; j++) {
                const tempVehicleId = this.driversModelCompleteDriversList[i].idList.filter(result => result.driverId === this.selectedDriversList[j]).map(result => result.vehicle.vehicleId);
                if (tempVehicleId[0] !== undefined) { tempVehicleIdList.push(tempVehicleId[0]); }
            }
        }
        return tempVehicleIdList;
    }

    toggleSubjectSelectionFloater(bool: boolean) {
        this.subjectSelectionFloaterIsShown = bool;
    }

    /* ---- Outside Click Event ---- */
    // onOutsideClick(clickEvent?: Event) {
    //     if (!clickEvent || !clickEvent.target) {
    //         return;
    //     }
    //     if (Boolean(this.vehiclesField) && !this.vehiclesField.nativeElement.contains(clickEvent.target)) {
    //         var el = clickEvent.target || clickEvent.srcElement;
    //         if (!(el instanceof HTMLImageElement)) {
    //             this.toggleSubjectSelectionFloater(false);
    //             this.filterVehiclesSelection();
    //         }
    //     }
    //     if (Boolean(this.driversField) && !this.driversField.nativeElement.contains(clickEvent.target)) {
    //         var el = clickEvent.target || clickEvent.srcElement;
    //         if (!(el instanceof HTMLImageElement)) {
    //             this.toggleSubjectSelectionFloater(false);
    //             this.filterDriversSelection();
    //         }
    //     }
    // }

    closeVehicleSearchDropdown(clickEvent?: Event) {
        // var el = clickEvent.target;
        // if (!(el instanceof HTMLImageElement)) {
        this.toggleSubjectSelectionFloater(false);
        this.filterVehiclesSelection();
        // }
    }

    closeDriverSearchDropdown(clickEvent?: Event) {
        // var el = clickEvent.target;
        // if (!(el instanceof HTMLImageElement)) {
        this.toggleSubjectSelectionFloater(false);
        this.filterDriversSelection();
        // }
    }

    /* ---- Vehicle Selection Floater ---- */
    onVehiclesAllSelectChange() {

        // Note: Apply to ALL vehicles that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedVehicleGroups.all;
        for (let i = 0; i < this.vehiclesModelCompleteVehiclesList.length; i++) {
            const vehicleModel = this.vehiclesModelCompleteVehiclesList[i];

            //check group
            if (vehicleModel.type === 'GROUP') {
                this.selectedVehicleGroups[i] = allIsSelected;
            }

            //check all vehicles in group
            for (let j = 0; j < vehicleModel.idList.length; j++) {
                this.selectedVehicles[vehicleModel.idList[j].vehicleId] = allIsSelected;
            }
        }
        this.updateVehiclesSelectedLabel();
    }
    onVehiclesGroupSelectChange(groupIndex: number) {

        // Note: Apply ONLY to vehicles that are VISIBLE
        const vehicleList = this.vehiclesModel.vehicles[groupIndex].idList;
        const groupIsSelected = this.selectedVehicleGroups[groupIndex];
        for (let i = 0; i < vehicleList.length; i++) {
            this.selectedVehicles[vehicleList[i].vehicleId] = groupIsSelected;
        }

        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
        this.updateVehiclesSelectedLabel();
        this.focusVehicleSearchBox();
    }
    onVehiclesSelectChange() {
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
        this.updateVehiclesSelectedLabel();
        this.focusVehicleSearchBox();
    }
    private updateVehiclesSelectedLabel() {

        this.selectedVehiclesList = [];
        for (const key in this.selectedVehicles) {
            if (this.selectedVehicles[key] === true) {
                this.selectedVehiclesList.push(parseInt(key));
            }
        }

        const totalVehicles = this.selectedVehiclesList.length;
        if (this.selectedVehicleGroups.all) {
            this.vehiclesSelectedLabel = 'All vehicles selected (' + totalVehicles + ')';
        } else {
            if (totalVehicles === 0) {
                this.vehiclesSelectedLabel = 'Please select vehicle';
            } else if (totalVehicles === 1) {
                this.vehiclesSelectedLabel = '1 vehicle selected';
            } else {
                this.vehiclesSelectedLabel = totalVehicles + ' vehicles selected';
            }
        }
    }
    private getVehicleSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.vehiclesField.nativeElement.querySelector("#vehicleSearchBox");
        } catch (err) {
        }
        return searchBox;
    }
    filterVehiclesSelection(searchString: string = "") {

        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.isShowSelectAllOption = true;
            this.vehiclesModel.vehicles = this.vehiclesModelCompleteVehiclesList;
            this.getVehicleSearchBox().value = "";

        } else {
            this.isShowSelectAllOption = false;
            //clone deep copy
            const tempList = JSON.parse(JSON.stringify(this.vehiclesModelCompleteVehiclesList));
            const newList = [];

            //do searching
            for (const group of tempList) {
                if (Boolean(group.idList) && group.idList.length) {
                    let tempVehicleList = group.idList;
                    tempVehicleList = tempVehicleList.slice(0).filter(this.filterLogicVehicleName.bind(this));
                    group.idList = tempVehicleList;
                }
                if (Boolean(group.idList) && group.idList.length) {
                    newList.push(group);
                }
            }
            this.vehiclesModel.vehicles = newList;
            const tempVehicleIdList = [];
            newList.forEach(result => result.idList.forEach(idList => {
                tempVehicleIdList.push(idList.vehicleId);
            }));
            this.selectedVehiclesList = tempVehicleIdList;
        }
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
    }
    private filterLogicVehicleName(vehicleItem: any): boolean {
        if (this.filterSearchString) {
            const normalisedVehicleName = vehicleItem.vehicleName.toLowerCase().replace(' ', '').trim();
            const normalisedFilterName = this.filterSearchString.toLowerCase().replace(' ', '').trim();
            if (normalisedVehicleName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }
        return true;
    }
    private focusVehicleSearchBox() {
        const searchBox = this.getVehicleSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private fixVehiclesCheckBoxesStates() {
        // Note: Apply ONLY to vehicles that are VISIBLE
        let allIsSelected = true;
        for (let j = 0; j < this.vehiclesModel.vehicles.length; j++) {
            const group = this.vehiclesModel.vehicles[j];
            let groupIsSelected = true;
            for (let k = 0; k < group.idList.length; k++) {
                const vehicle = group.idList[k];
                if (!this.selectedVehicles[vehicle.vehicleId]) {
                    allIsSelected = false;
                    groupIsSelected = false;
                    break;
                }
            }
            this.selectedVehicleGroups[j] = groupIsSelected;
        }
        this.selectedVehicleGroups.all = allIsSelected;
    }

    /* ---- Driver Selection Floater ---- */
    onDriversAllSelectChange() {

        // Note: Apply to ALL drivers that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedDriverGroups.all;
        for (let i = 0; i < this.driversModelCompleteDriversList.length; i++) {
            const driverModel = this.driversModelCompleteDriversList[i];

            //check group
            if (driverModel.type === 'GROUP') {
                this.selectedDriverGroups[i] = allIsSelected;
            }

            //check all drivers in group
            for (let j = 0; j < driverModel.idList.length; j++) {
                this.selectedDrivers[driverModel.idList[j].driverId] = allIsSelected;
            }
        }
        this.updateDriversSelectedLabel();
    }
    onDriversGroupSelectChange(groupIndex: number) {

        // Note: Apply ONLY to drivers that are VISIBLE
        const driverList = this.driversModel.drivers[groupIndex].idList;
        const groupIsSelected = this.selectedDriverGroups[groupIndex];
        for (let i = 0; i < driverList.length; i++) {
            this.selectedDrivers[driverList[i].driverId] = groupIsSelected;
        }

        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
        this.updateDriversSelectedLabel();
        this.focusDriverSearchBox();
    }
    onDriversSelectChange() {
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
        this.updateDriversSelectedLabel();
        this.focusDriverSearchBox();
    }
    private updateDriversSelectedLabel() {

        this.selectedDriversList = [];
        for (const key in this.selectedDrivers) {
            if (this.selectedDrivers[key] === true) {
                this.selectedDriversList.push(parseInt(key));
            }
        }

        const totalDrivers = this.selectedDriversList.length;
        if (this.selectedDriverGroups.all) {
            this.driversSelectedLabel = 'All drivers selected (' + totalDrivers + ')';
        } else {
            if (totalDrivers === 0) {
                this.driversSelectedLabel = 'Please select driver';
            } else if (totalDrivers === 1) {
                this.driversSelectedLabel = '1 driver selected';
            } else {
                this.driversSelectedLabel = totalDrivers + ' drivers selected';
            }
        }
    }
    private getDriverSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.driversField.nativeElement.querySelector("#driverSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }
    filterDriversSelection(searchString: string = "") {

        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.isShowSelectAllOption = true;
            this.driversModel.drivers = this.driversModelCompleteDriversList;
            this.getDriverSearchBox().value = "";
        } else {
            this.isShowSelectAllOption = false;
            //clone deep copy
            const tempList = JSON.parse(JSON.stringify(this.driversModelCompleteDriversList));
            const newList = [];

            //do searching
            for (const group of tempList) {
                if (Boolean(group.idList) && group.idList.length) {
                    let tempidList = group.idList;
                    tempidList = tempidList.slice(0).filter(this.filterLogicDriverName.bind(this));
                    group.idList = tempidList;
                }
                if (Boolean(group.idList) && group.idList.length) {
                    newList.push(group);
                }
            }
            this.driversModel.drivers = newList;
            const tempDriverIdList = [];
            newList.forEach(result => result.idList.forEach(idList => {
                tempDriverIdList.push(idList.driverId);
            }));
            this.selectedDriversList = tempDriverIdList;
        }
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
    }
    private filterLogicDriverName(driverItem: any): boolean {
        if (this.filterSearchString) {
            const normalisedDriverName = driverItem.driverName.toLowerCase().replace(' ', '').trim();
            const normalisedFilterName = this.filterSearchString.toLowerCase().replace(' ', '').trim();
            if (normalisedDriverName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }
        return true;
    }
    private focusDriverSearchBox() {
        const searchBox = this.getDriverSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private fixDriversCheckBoxesStates() {
        // Note: Apply ONLY to drivers that are VISIBLE
        let allIsSelected = true;
        for (let j = 0; j < this.driversModel.drivers.length; j++) {
            const group = this.driversModel.drivers[j];
            let groupIsSelected = true;
            for (let k = 0; k < group.idList.length; k++) {
                const driver = group.idList[k];
                if (!this.selectedDrivers[driver.driverId]) {
                    allIsSelected = false;
                    groupIsSelected = false;
                    break;
                }
            }
            this.selectedDriverGroups[j] = groupIsSelected;
        }
        this.selectedDriverGroups.all = allIsSelected;
    }

    showDownloadPopup(popup) {
        this.popupService.show(popup);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    async exportVehicleFile(fileType) {
        const exportVehicleViewResponse: any = await this.vehicleService.getAllVehicleInfoDetails(0, 0, "VehicleName", this.sortAscending, "VehicleName", "", "");
        const exportVehicleViewInfoList = exportVehicleViewResponse.body.vehicle_details_list;

        const data = [];
        for (let i = 0; i < exportVehicleViewInfoList.length; i++) {
            const groupLength = exportVehicleViewInfoList[i].vehicleGroupList.length;
            let groupName = '';

            for (let k = 0; k < groupLength; k++) {
                const nameGrp = exportVehicleViewInfoList[i].vehicleGroupList[k].vehicleGroupName;
                if (k == 0) {
                    groupName = nameGrp;
                } else {
                    groupName = groupName + "," + nameGrp;
                }
            }

            const tempRow = [
                exportVehicleViewInfoList[i].imeiNo || '',
                exportVehicleViewInfoList[i].vehicleName || '',
                exportVehicleViewInfoList[i].vehicleBrand || '',
                exportVehicleViewInfoList[i].vehicleModel || '',
                exportVehicleViewInfoList[i].vehiclePlateNo || '',
                exportVehicleViewInfoList[i].userFirstName || '',
                exportVehicleViewInfoList[i].userLastName || '',
                groupName || ''];

            data.push(tempRow);
        }

        if (fileType === 'excel') {
            const excelData: any = this.fileExportService.mergeHeaderAndDataForExcel(data, this.exportVehicleFileHeader);
            this.fileExportService.exportExcelFile(excelData, "Vehicle_Report_Excel");
        } else if (fileType === 'csv') {
            this.fileExportService.exportCsvFile(data, this.exportVehicleFileHeader, "Vehicle_Report_Csv");
        } else if ('pdf') {
            this.fileExportService.exportPdfFile(data, this.exportVehicleFileHeader, "Vehicle_Report_Pdf", "Vehicle Report", null, "landscape");
        }
    }

    async getVehicleStatus(vehicleStatus) {

        switch (vehicleStatus) {
            case 'moving':
                if (this.currentVehicleStatus == vehicleStatus) {
                    this.currentVehicleStatus = '';
                } else {
                    this.currentVehicleStatus = vehicleStatus;
                }
                break;
            case 'stopped':
                if (this.currentVehicleStatus == 'stopped') {
                    this.currentVehicleStatus = '';

                } else {
                    this.currentVehicleStatus = 'stopped';
                }
                break;
            case 'disconnected':
                if (this.currentVehicleStatus == 'disconnected') {
                    this.currentVehicleStatus = '';
                } else {
                    this.currentVehicleStatus = 'disconnected';
                }
                break;

        }
        await this.getDisplayVehicleList();
    }

    getVehicleStatusStyles(status) {
        let styles;
        if (status) {
            const statusConstant = STATUS[status.toLowerCase()];
            if (statusConstant) {
                styles = { 'border': `5px solid ${statusConstant.COLOR}` };
            }
        }
        return styles;

    }
    initRefreshTimer(): void {
        // console.debug('initRefreshTimer()');
        clearInterval(this.refreshPageTimer);
        // if (this.mapModel.isOnAutoUpdate) {
        this.refreshPageTimer = setInterval(this.initiateRefreshEvent.bind(this), this.refreshTimerIntervalInMilliSeconds);
        // }
    }
    initiateRefreshEvent(): void {
        this.msgbus.sendMessage(new BusMessage("vehicle", "refresh", {
            vehicleViewInfoList: this.vehicleViewInfoList,
            currentFilterValue: this.currentFilterValue
        }));
    }

    /* ---- refresh ---- */


}
