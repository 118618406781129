<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<app-popup #updateConfirmationPopup="popup" class="cm-popups" [classes]="'--slim'" [hideOnOverlayClick]="false" [showCloseButton]="false">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                New version available
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            Looks like you have an older version of the app. Please update to get latest features and best experience.
        </p>

        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="confirmUpdate();">
                UPDATE NOW
            </button>
        </div>
    </div>
</app-popup>
