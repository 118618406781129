<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@sm --space-top">
                    <h1 class="heading --level-1">
                        Drivers
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <!--IMPORT tag commented in below is for Batch Import UI Do No Remove-->
                            <a class="heading-buttons__button" (click)="showPopup(batchImportPopup)" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-batch-import.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Batch Import
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Batch Import
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-batch-import.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="showDownloadPopup(downloadsPopup)">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-export.svg" alt="" width="23" height="23" class="heading-buttons__icon">
                                    <div class="heading-buttons__text">
                                        Export As
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Export As
                                    </div>
                                    <img src="assets/images/ng-components/create-manage/icon-mobile-export.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="navigateToCreateDriver(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Driver
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Driver
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingDrivers = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Driver
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Driver
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="driver__filters" style="position: relative; z-index: 3;">
                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-8of12 w-6of12@sm">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item search-group__item">
                                    <div class="field">
                                        <div class="field__elems">
                                            <div class="field__icon">
                                                <div class="field__icon-inner">
                                                    <img src="assets/images/scss-components/forms/icon-steering-wheel.svg" width="26" height="20" alt="" class="field__icon-image">
                                                </div>
                                            </div>

                                            <label class="field__holder">
                                                <input id="driverSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Driver" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchDriverName()">
                                            </label>

                                            <div class="field__ui"></div>
                                        </div>
                                    </div>

                                    <div class="button__holder">
                                        <button type="button" class="button" (click)="searchDriverName()">SEARCH</button>
                                    </div>

                                </div>
                                <!--
                                <button type="button" class="button" (click)="showDownloadPopup(downloadsPopup)">
                                  <img src="assets/images/scss-components/buttons/icon-download.svg" width="24" height="18" alt="" class="button__icon"> Download
                                </button>
                                -->
                            </div>
                        </div>

                        <div class="grid__block w-2of12 w-4of12@sm">
                            <div class="filter-button">
                                <button type="button" class="button --filter" (click)="changeView()">
                                    <div class="filter-button__input">
                                        {{ viewText }}
                                    </div>

                                    <img [attr.src]="listView ? 'assets/images/ng-components/create-manage/Icon-Grid.svg' : 'assets/images/ng-components/create-manage/Icon-List.svg'" alt="" width="25" height="17" class="filter-button__icon">

                                </button>
                            </div>
                        </div>

                        <div class="grid__block w-2of12 w-2of12@sm">
                            <div class="filter-button">
                                <button type="button" class="button --filter" (click)="sort()">
                                    <div class="filter-button__input">
                                        Driver Name
                                    </div>

                                    <img [attr.src]="sortAscending ? 'assets/images/ng-components/create-manage/icon-filter-down.svg' : 'assets/images/ng-components/create-manage/icon-filter-up.svg'" alt="" width="25" height="12" class="filter-button__icon">

                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <ng-container  *ngIf="!listView">
                            <div class="grid__block w-6of12@sm w-4of12@lg" *ngFor="let driver of driverViewInfoList">

                                <a class="filter-result__box" [class.--cursor]="editable" *ngIf="!isDeletingDrivers" (click)="editable && navigateToEditDriver(driver.Id, $event)">
                                    <ng-container *ngTemplateOutlet="result1"></ng-container>
                                </a>

                                <label class="result-checkbox" *ngIf="isDeletingDrivers">
                                    <div class="inline-input">
                                        <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDeleteDrivers[driver.Id]">

                                        <span class="inline-input__icon"></span>

                                        <div class="filter-result__box --cursor">
                                            <ng-container *ngTemplateOutlet="result1"></ng-container>
                                        </div>
                                    </div>
                                </label>

                                <ng-template #result1>
                                    <div id="group-colors" class="filter-result-dots">
                                        <div class="toggle__item-groups" [class.--active]="toggle1.active">
                                            <button class="toggle__item-groups-toggle" toggle #toggle1="toggle">
                                                <span class="filter-result-dots__dot" *ngFor="let driverGroup of driver.GroupList" [ngStyle]="{'background-color': driverGroup.groupColor}"></span>
                                            </button>

                                            <div class="toggle__item-groups-floater">
                                                <button class="toggle__item-groups-close" (click)="toggle1.setInactive()"></button>

                                                <div class="toggle__item-groups-contents" *ngFor="let driverGroup of driver.GroupList">
                                                    <div class="toggle__item-group">
                                                        <span class="filter-result-dots__dot --dot-2" [ngStyle]="{'background-color': driverGroup.groupColor}"></span>{{ driverGroup.groupName }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="filter-result-group">
                                        <div class="filter-result__image">
                                            <div class="filter-result__image-cropped" *ngIf="driver.Photo">
                                                <img src="data:image/jpeg;base64,{{driver.Photo}}" alt="" width="105" height="105">
                                            </div>
                                            <div class="filter-result__image --placeholder" *ngIf="driver.hasUserNameBool && !driver.Photo">
                                                <div class="filter-result__image-cropped --placeholder-green">
                                                    {{ driver.shortName }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="filter-result__info">
                                            <div class="filter-result__title">
                                                <span class="truncate-line">
                                                    {{ driver.Username }}
                                                </span>
                                            </div>

                                            <div class="filter-result__email">
                                                <span class="truncate-line">
                                                    {{ driver.Email || '-'}}
                                                </span>
                                            </div>

                                            <div class="filter-result__contact">
                                                <span class="truncate-line">
                                                    {{ driver.TelNo || '-' }}
                                                </span>
                                            </div>

                                            <div class="filter-result__desc">
                                                <span class="truncate-line">
                                                    <span class="filter-result__desc__child__label">ID Tag No: </span>{{ driver.driverTagNo || '-'}}
                                                    <br>
                                                    <span class="filter-result__desc__child__label">Licence No: </span>{{ driver.DrivingLicenseNo || '-' }}
                                                    <br>
                                                    <span class="filter-result__desc__child__label">Exp: </span>{{ driver.DrivingLicenseExpiryDate|| '-'}}
                                                </span>

                                                <!--table cellspacing="0" cellpadding="0">
                                                    <tr class="desc__licence">
                                                        <td style="width: 65px">
                                                            <b>Licence No:</b>
                                                        </td>
                                                        <td>{{ driver.DrivingLicenseNo || '-' }}</td>
                                                    </tr>
                                                    <tr class="desc__exp">
                                                        <td>
                                                            <b>Exp:</b>
                                                        </td>
                                                        <td>
                                                            {{ driver.DrivingLicenseExpiryDate|| '-'}}
                                                        </td>
                                                    </tr>
                                                </table-->
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>                                
                            </div>
                        </ng-container>
                        <div class="grid__block as-c@sm" *ngIf="listView">
                            <ng-container *ngTemplateOutlet="result2"></ng-container>


                            <ng-template #result2>
                                <div class="table table__view-drivers" (scroll)="onOverviewTableScroll()" [class.--scrolled-to-end]="overviewTableScrolledToEnd" #overviewTable>
                                    <table class="table__elem">
                                        <thead>
                                            <tr>
                                                <ng-container *ngIf="isDeletingDrivers">
                                                    <th scope="col" class="delete-table-data">
                                                        Delete
                                                    </th>
                                                </ng-container>
                                                <th scope="col" width="15%">
                                                    Name
                                                </th>

                                                <th scope="col" width="15%">
                                                    Driver Tag No.
                                                </th>

                                                <th scope="col" width="15%">
                                                    Email
                                                </th>

                                                <th scope="col" width="15%">
                                                    Tel No.
                                                </th>                                                

                                                <th scope="col" width="15%">
                                                    Driving License No.
                                                </th>

                                                <th scope="col" width="15%">
                                                    Driving License Expiry Date
                                                </th>
                                            </tr>
                                        </thead>
                
                                        <tbody>
                                            <tr *ngFor="let driver of driverViewInfoList" (click)="editable && navigateToEditDriver(driver.Id, $event)">
                                                <ng-container *ngIf="isDeletingDrivers">
                                                    <td scope="col" class="delete-table-data">
                                                        <label class="inline-input">
                                                            <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDeleteDrivers[driver.Id]" *ngIf='isDeletingDrivers'>
            
                                                            <span class="inline-input__icon"></span>
                                                        </label>
                                                    </td>
                                                </ng-container>
                                                <td>{{ driver.Username }}</td>
                                                <td>{{ driver.driverTagNo || '-'}}</td>
                                                <td>{{ driver.Email || '-'}}</td>
                                                <td>{{ driver.TelNo || '-' }}</td>
                                                <td>{{ driver.DrivingLicenseNo || '-' }}</td>
                                                <td>{{ driver.DrivingLicenseExpiryDate|| '-'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>
                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination" *ngIf="driverViewInfoList.length > 0">
                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDisplayDriverList(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDisplayDriverList(page)">{{
                                    page }}</button>

                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDisplayDriverList(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="delete-dialog" @fadeToggle *ngIf="isDeletingDrivers">
        <div class="wrapper">
            <div class="delete-dialog__field">
                <div class="delete-dialog__total">
                    You have selected {{countSelectedDrivers()}} driver(s)
                </div>

                <div class="delete-dialog__button">
                    <div class="inline-button__field">
                        <button class="button inline-button__inputs" type="button" [disabled]="countSelectedDrivers()==0" (click)="showPopup(deleteDriverPopup)">
                            <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                            <div class="inline-button__input">
                                Delete
                            </div>
                        </button>

                        <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                            <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                            <div class="inline-button__input">
                                CANCEL
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteDriverPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedDrivers()}}</span> drivers
        </p>

        <p class="notes">
            *All drivers' access will be revoked and any assigned vehicles will be removed
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteDriver();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #downloadsPopup="popup">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="exportDriverFile('excel'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="exportDriverFile('csv'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="exportDriverFile('pdf'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>

<app-popup #batchImportPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Batch Import
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="image-placeholder">
            <img src="assets/images/ng-components/create-manage/icon-batch-import-grey.svg" alt="" width="170" height="170">
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <label>
                <span class="button inline-button__inputs">
                    Browse
                </span>
                <input type="file" id="myFileInput" (change)="batchImportDriver($event)" accept="*.xlsx, *.xls, *.csv" />
            </label>
        </div>

        <span class="notes-input">( Max File Size: {{batchFileSize}}MB )</span>
    </div>

    <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">

            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div>
</app-popup>

<app-popup #batchAlertPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert">{{batchErrorMessage}}</span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(batchImportPopup);">
                OK
            </button>
        </div>
    </div>
    <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div>
</app-popup>

<app-popup #batchCreateDriverPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            Total {{batchDriverCount}} Driver(s) will be created.
        </p>

        <p class="notes"></p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="batchCreateDriver();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

