import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';

// Service
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { UserService } from './../../../../_services/user/user.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as DomUtil from '../../../../util/domUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';
import * as StringUtil from './../../../../util/stringUtil';
import * as LocalStorageUtil from '../../../../util/localStorageUtil';
import * as MapUtil from '../../../../util/mapUtil';

// Constant
import * as Message from './../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { ERROR_MESSAGE as ErrorMessage } from './../../../../constants/errorMessage';
import { overlayConstant } from './../../../../constants/overlay-image-map.constant';

declare var google: any;

@Component({
    selector: 'app-create-geofence',
    templateUrl: './create-geofence.component.html',
    styleUrls: ['./create-geofence.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CreateGeofenceComponent implements OnInit, OnDestroy {

    constructor(
        private platform: Platform,
        private vehicleService: VehicleService,
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private userService: UserService,
        private popupCampaignService: PopupCampaignService

    ) { }

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    // Geofence Form
    @ViewChild('vehicleAssign',{static:false}) vehicleAssign: VehicleSelectorComponent;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    // private map: any;
    // private drawingManager: any;
    // private loadAPI: Promise<any>;

    message = Message;

    MY_LOCATION_LAT: number = 3.158; //Menara Maxis
    MY_LOCATION_LNG: number = 101.713; //Menara Maxis
    // mapScriptLoaded = false;
    mapIsReady = false;
    mapContent: any;
    searchInput: any;

    //Geofence Info
    geofenceName: string;

    alertTimeFrom: string;
    alertTimeTo: string;
    allowedTimeFromHour = '';
    allowedTimeFromMinute = '';
    allowedTimeFromAMPM = 'AM';
    allowedTimeToHour = '';
    allowedTimeToMinute = '';
    allowedTimeToAMPM = 'AM';

    alertTypes = [
        { name: 'Entry', value: 'Entry', checked: false },
        { name: 'Exit', value: 'Exit', checked: false }
    ];

    timeSelector: {
        hour: string[],
        fullHour: string[],
        minute: string[],
        second: string[]
    } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array

    alertDays = [
        { name: 'Sun', value: '0', checked: false },
        { name: 'Mon', value: '1', checked: false },
        { name: 'Tue', value: '2', checked: false },
        { name: 'Wed', value: '3', checked: false },
        { name: 'Thu', value: '4', checked: false },
        { name: 'Fri', value: '5', checked: false },
        { name: 'Sat', value: '6', checked: false }
    ];

    //Geofence Area
    geofencePath: string;
    geofenceRadius: number;

    //Vehicle List
    selectedVehiclesList: Array<number> = null;

    // Geofence Type
    polygon: string = 'polygon';
    circle: string = 'circle';
    selectedGeofenceType: string = this.polygon;

    alertTypeSelectedOptions() {
        return this.alertTypes
            .filter(opt => opt.checked)
            .map(opt => opt.value);
    }

    alertDaySelectedOptions() {
        return this.alertDays
            .filter(opt => opt.checked)
            .map(opt => opt.value);
    }

    async ngOnInit() {
        this.mapContent = document.getElementById('map');
        this.searchInput = document.getElementById('searchInput');
        await this.loadScriptFirst();
        DomUtil.autoFocusForm(this.el);

        await this.checkUnviewedCampaign();

    }

    async ngOnDestroy() {
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async loadScriptFirst() {
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        if (!DomUtil.hasGoogleMapDrawingScript(GOOGLE_MAP_API_KEY)) {
            DomUtil.addGoogleMapScript({
                libraries: ['drawing', 'places'],
                onload: this.finishScriptLoad.bind(this)
            }, GOOGLE_MAP_API_KEY);

        } else {
            console.debug('CreateGeofenceComponent: (Geofence)Skip adding Google Map Script Tag ...');
            if (DomUtil.isGoogleNotDefined()) {
                await AsyncUtil.wait(300); // wait for google object ready
            }
            this.finishScriptLoad();
        }
    }

    finishScriptLoad(): void {
        this.initMap();
    }

    chooseGeofenceType(geofenceType) {
        if (this.selectedGeofenceType !== geofenceType) {
            this.selectedGeofenceType = geofenceType;
            this.initMap();
        }
    }

    async initMap() {
        if (!DomUtil.isGoogleNotDefined()) {
            const self = this;
            let currentOverlay;
            const map = new google.maps.Map(this.mapContent, {
                center: { lat: this.MY_LOCATION_LAT, lng: this.MY_LOCATION_LNG },
                gestureHandling: 'greedy',
                zoom: 17
            });

            self.geofencePath = '';
            //var icon = {
            //  url: "../assets/images/ng-components/past-trip/start.svg", // url
            //  scaledSize: new google.maps.Size(28.5, 38) // scaled size
            //};

            const drawingManager = new google.maps.drawing.DrawingManager({
                //drawingMode: google.maps.drawing.OverlayType.MARKER,
                drawingMode: self.selectedGeofenceType,
                drawingControl: false,
                // drawingControlOptions: {
                //     position: google.maps.ControlPosition.TOP_CENTER,
                //     drawingModes: [self.polygon, self.circle]
                // },
                [`${this.selectedGeofenceType}Options`]: {
                    fillColor: '#FFC0CB',
                    fillOpacity: 0.5,
                    strokeWeight: 3,
                    strokeColor: '#DC143C',
                    clickable: true,
                    draggable: true,
                    editable: true
                }
            });
            drawingManager.setMap(map);

            google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {

                // Disable Drawing
                drawingManager.setDrawingMode(null);
                currentOverlay = event.overlay;
                self.geofencePath = '';

                self.editGeofenceArea(currentOverlay, event.type);

                // remove the overlay
                currentOverlay.addListener('click', function (e) {
                    e.stop();
                    if (typeof event.path === 'undefined') { // ignore clicks on polygon paths
                        self.geofencePath = '';
                        drawingManager.setDrawingMode(self.selectedGeofenceType);
                        currentOverlay.setMap(null);
                        google.maps.event.clearInstanceListeners(currentOverlay);
                        currentOverlay = null;
                    }
                });
            });

            // Create the search box and link it to the UI element.
            const searchBox = new google.maps.places.SearchBox(this.searchInput);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', function () {
                searchBox.setBounds(map.getBounds());
            });

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', function () {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                //// Clear out the old markers.
                //markers.forEach(function (marker) {
                //  marker.setMap(null);
                //});
                //markers = [];

                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();
                places.forEach(function (place) {
                    if (!place.geometry) {
                        console.debug("Returned place contains no geometry");
                        return;
                    }

                    //// Create a marker for each place.
                    //markers.push(new google.maps.Marker({
                    //  map: map,
                    //  icon: icon,
                    //  title: place.name,
                    //  position: place.geometry.location
                    //}));

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
                drawingManager.setMap(map);

                if (currentOverlay) {
                    currentOverlay.setMap(null);
                    currentOverlay = null;
                }
                self.geofencePath = '';
                drawingManager.setDrawingMode(self.selectedGeofenceType);
            });

            const result = await this.userService.getCompanyIdByName('Gamuda Cove');
            if (result && result.body.length > 0) {
                const currentUserCompanyId = LocalStorageUtil.localStorageGet('currentUser').companyId;
                if (result.body[0].id === currentUserCompanyId) {
                    MapUtil.generateOverlayImg(overlayConstant.imageLoc, overlayConstant.imagePath, map);
                }
            }

        } else {
            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.MAP_LOAD_FAILED);
            this.snackBar.openGenericSnackBar(msg);
        }
    }

    editGeofenceArea(event, type) {
        const _this = this;
        if (type === this.polygon) {
            // (Polygon) For Edit / Drag Area
            google.maps.event.addListener(event.getPath(), 'insert_at', function () {
                _this.geofencePath = '';
                event.getPath().getArray().forEach(function (latlng) {
                    if (_this.geofencePath) {
                        _this.geofencePath += ';';
                    }
                    _this.geofencePath += latlng.lat() + ',' + latlng.lng();
                });
            });
            google.maps.event.addListener(event.getPath(), 'set_at', function () {
                _this.geofencePath = '';
                event.getPath().getArray().forEach(function (latlng) {
                    if (_this.geofencePath) {
                        _this.geofencePath += ';';
                    }
                    _this.geofencePath += latlng.lat() + ',' + latlng.lng();
                });
            });
            // Run One Time After Finish Drawing
            event.getPath().getArray().forEach(function (latlng) {
                if (_this.geofencePath) {
                    _this.geofencePath += ';';
                }
                _this.geofencePath += latlng.lat() + ',' + latlng.lng();
            });
        } else {
            // (Circle) For Edit / Drag Area
            google.maps.event.addListener(event, 'dragend', function () {
                _this.geofencePath = event.center.lat() + ',' + event.center.lng();
            });
            google.maps.event.addListener(event, 'radius_changed', function () {
                _this.geofenceRadius = Math.round(event.radius);
            });
            // Run One Time After Finish Drawing
            _this.geofencePath = event.center.lat() + ',' + event.center.lng();
            _this.geofenceRadius = Math.round(event.radius);
        }
    }

    async createGeofence() {
        this.pageSpinner.show();

        try {
            let alertDays = '';
            this.alertDaySelectedOptions().forEach(function (day) {

                switch (day) {
                    case '0': alertDays += '0,'; break;
                    case '1': alertDays += '1,'; break;
                    case '2': alertDays += '2,'; break;
                    case '3': alertDays += '3,'; break;
                    case '4': alertDays += '4,'; break;
                    case '5': alertDays += '5,'; break;
                    case '6': alertDays += '6,'; break;
                    default: break;
                }
            });
            alertDays = alertDays.replace(/.$/, "");

            let alertTimeFromT = `${this.allowedTimeFromHour}:${this.allowedTimeFromMinute} ${this.allowedTimeFromAMPM}`;
            let alertTimeToT = `${this.allowedTimeToHour}:${this.allowedTimeToMinute} ${this.allowedTimeToAMPM}`;
            if (this.allowedTimeFromHour == "HH") {
                alertTimeFromT = '';
            } else if (this.allowedTimeFromMinute == "MM") {
                alertTimeFromT = '';
            }
            if (this.allowedTimeToHour == "HH") {
                alertTimeToT = '';
            } else if (this.allowedTimeToMinute == "MM") {
                alertTimeToT = '';
            }

            this.alertTimeFrom = alertTimeFromT.length != 0 ? moment(alertTimeFromT, "HH:mm A").format("HH:mm") : "";
            this.alertTimeTo = alertTimeToT.length != 0 ? moment(alertTimeToT, "HH:mm A").format("HH:mm") : "";

            this.selectedVehiclesList = this.vehicleAssign.getSelectedVehicleIds()/* [] */;

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.geofenceName)) {
                this.geofenceName = this.geofenceName.trim();
            }

            const result = await this.vehicleService.createGeofence(this.geofenceName, this.alertTypes[0].checked, this.alertTypes[1].checked, alertDays, this.alertTimeFrom, this.alertTimeTo, this.geofencePath, this.selectedGeofenceType === this.polygon ? 'P' : 'C', this.selectedGeofenceType === this.polygon ? null : this.geofenceRadius, this.selectedVehiclesList);

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Geofence', this.geofenceName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Geofence', this.geofenceName);
                this.navigateSuccessToDrivingLimitView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToDrivingLimitView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/driving-limits/view'], null, 'geofence');
    }

    navigateToDrivingLimitView() {
        this.routerProxyService.navigate(['/create-manage/driving-limits/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
