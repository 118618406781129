export const overlayConstant = {
    imageLoc: [{
        north: 2.908993,
        south: 2.862144,
        east: 101.638156,
        west: 101.585760
    }, {
        north: 2.887493,
        south: 2.884944,
        east: 101.619056,
        west: 101.614960
    }],
    imagePath: ['assets/images/map_overlay_image/discoverypark_map_old.png', 'assets/images/map_overlay_image/discoverypark_map_new.png']
};
