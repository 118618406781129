import { Platform } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileExportService } from './../../../_services/file-export/file-export.service';

export class DownloadableArrayItem {

    private fileName: string;
    private label: string;
    private layout: string;
    private headerName: Array<string>;
    private headerType: Array<any>;
    private excelWidthConfig: Array<any>;
    private data: Array<any>;
    private fileExportService: FileExportService;

    constructor(fileName: string, label: string, layout: string, headerName: Array<string>, headerType: Array<any>, excelWidthConfig: Array<any>, data: Array<any>, platform: Platform) {
        this.fileName = fileName;
        this.label = label;
        this.headerName = headerName;
        this.headerType = headerType;
        this.excelWidthConfig = excelWidthConfig;
        this.data = data;
        this.layout = layout;
        this.fileExportService = new FileExportService(platform);
    }

    public getFileName(): string {
        return this.fileName;
    }

    public getHeaders(): Array<string> {
        return this.headerName;
    }

    public getHeaderType(): Array<any> {
        return this.headerType;
    }

    public getExcelWidthConfig(): Array<any> {
        return this.excelWidthConfig;
    }

    public getData(): Array<any> {
        return this.data;
    }

    public async downloadExcelFile() {
        const excelData: any = this.fileExportService.mergeHeaderAndDataForExcel(this.data, this.headerName);
        await this.fileExportService.exportExcelFile(excelData, this.fileName, this.headerType, this.excelWidthConfig);
    }

    public async downloadCsvFile() {
        await this.fileExportService.exportCsvFile(this.data, this.headerName, this.fileName);
    }

    public async downloadPdfFile(dateRange: string) {
        await this.fileExportService.exportPdfFile(this.data, this.headerName, this.fileName, this.label, dateRange, this.layout);
    }

}
