export const COLOR_PICKER = {
  ONE: '#513B8A',
  TWO: '#4C39AE',
  THREE: '#6741C8',
  FOUR: '#8739AE',
  FIVE: '#AE398A',
  SIX: '#C346BF',
  SEVEN: '#AE3939',
  EIGHT: '#C3466C',
  NINE: '#C36046',
  TEN: '#C38546',
  ELEVEN: '#C3AE46',
  TWELVE: '#A7C346',
  THIRTEEN: '#128958',
  FOURTEEN: '#16A84A',
  FIFTEEN: '#1BBC2B',
  SIXTEEN: '#3B8B83',
  SEVENTEEN: '#009193',
  EIGHTEEN: '#18B897',
  NINETEEN: '#2382CA',
  TWENTY: '#4F91C4'
};
