<div class="vehicle__main" [class.--trip-day-item-single-view-mode]="tripDayItemSingleViewMode">

    <app-spinner #panel_spinner [keepParentUntouched]="true"></app-spinner>

    <div class="vehicle__main-inner">
        <div class="vehicle__main-header">
            <!--a [routerLink]="['',{outlets:{leftbar:['vehiclelist']}}]">
          &lt; Driver List
            </a-->
            <a (click)="navigateToMapPage()"> &lt; Vehicle List </a>
        </div>

        <div class="vehicle__main-body">
            <div class="vehicle__main-inforegion">
                <div class="vehicle__main-groups" [class.--active]="toggle.active" *ngIf="vehicle?.vehicleGroupList && vehicle?.vehicleGroupList.length">
                    <button class="vehicle__main-groups-toggle" toggle #toggle="toggle">
                        <span *ngFor="let group of vehicle?.vehicleGroupList" class="vehicle__main-group-dot" [ngStyle]="{ 'color': group?.colour }"></span>
                    </button>

                    <!-- Insert Group list here-->
                    <div class="vehicle__main-groups-floater">
                        <button class="vehicle__main-groups-close" (click)="toggle.setInactive()"></button>

                        <div class="vehicle__main-group" *ngFor="let group of vehicle.vehicleGroupList">
                            <span class="vehicle__main-group-dot" [ngStyle]="{ 'color': group.colour }"></span> {{ group.vehicleGroupName }}
                        </div>
                    </div>
                </div>

                <div class="vehicle__main-infoimage" [ngStyle]="itemImageStyles">
                    <div *ngIf="showImageAlert" class="vehicle__main-infoimage-alert"></div>
                </div>

                <div class="vehicle__main-infopanel"  [ngStyle]="{ 'background-color': vehicle.isPanic ? 'pink' : 'white' }">
                    <div class="vehicle__main-info-heading">
                        {{ vehicleName | uppercase }}
                    </div>

                    <div class="vehicle__main-info-toggle" [class.active]="expandInfoDetails" (click)="showInfoDetails()">
                        View Details
                    </div>

                    <ul class="update-log">
                        <li class="module-driver-tag" *ngIf="vehicle.hasDriverTagDevice"></li>
                        <li class="module-immobilizer" *ngIf="vehicle.hasImmobilizer"></li>
                        <li class="module-fuel-sensor" *ngIf="vehicle.hasFuelSensor"></li>
                        <li class="module-panic-button" *ngIf="vehicle.hasPanicButton"></li>
                    </ul>

                    <br />
                    <!--  -->
                    <div class="button__holder" *ngIf="vehicle.isPanic" >
                        <!-- <button type="button" class="button" (click)="searchVehicle()">SEARCH</button> -->
                        <button type="button" class="button_panic_ack" (click)="acknowledgeAlarm()">Acknowledge Alarm</button>
                    </div>
                </div>
                <div class="vehicle__main-infodetails" [class.active]="expandInfoDetails">
                    <div class="vehicle__main-info-wrapper">
                        <div class="vehicle__main-info-carinfo">
                            <span class="vehicle__main-info-title">
                                Vehicle Info:
                            </span>
                            <span class="vehicle__main-info-content">
                                {{vehicle.vehicleBrand}} {{vehicle.vehicleModel}}, {{vehicle.vehiclePlateNo}}
                            </span>
                        </div>
                        <div class="vehicle__main-info-driverinfo">
                            <span class="vehicle__main-info-title">
                                Current Driver:
                            </span>
                            <span class="vehicle__main-info-content">
                                {{vehicle.fullUserName || '-' }}
                            </span>
                        </div>
                        <div class="vehicle__main-info-driverid">
                            <span class="vehicle__main-info-title">
                                Driver Tag:
                            </span>
                            <span class="vehicle__main-info-content">
                                {{vehicle.driverTagNo || '-'}}
                            </span>
                        </div>
                        <div class="vehicle__main-info-driverid" *ngIf="!vehicle.driverTagNo && vehicle.hasDriverTagDevice && vehicle.userId">
                            <a class="link --primary --u" (click)="showPopup(driverTagPopup)">Add ID Tag</a>
                        </div>
                        <div class="vehicle__main-info-walktocar" *ngIf="showWalkToCar">
                            <button class="button inline-button__walktocar" (click)="walkToCar()" type="button" [disabled]="vehicle.imeiNo == null" [class.--disabled]="vehicle.imeiNo == null">
                                <img src="assets/images/scss-components/forms/icon-walk-to-car.svg" alt="" width="20" height="24" class="inline-button__icon">

                                <div class="inline-button__input">
                                    Walk to car
                                </div>
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <ng-container *ngIf="trip.status">
                <!-- [ngStyle]="checkVehicleStatus()" -->
                <div class="vehicle__main-currtripregion" [ngClass]="'--' + trip.statusLabel.toLowerCase()">
                    <div class="vehicle__main-currtripitems" [class.is-fuel-sensor--]="vehicle.hasFuelSensor" *ngIf="trip.statusLoweredCase !== STATUS.DISCONNECTED && trip.statusLoweredCase !== STATUS.STOPPED && trip.statusLoweredCase !== STATUS.PARKANDALERT">
                        <div class="vehicle__main-currtripitem">
                            <div class="header">Current Speed</div>
                            <div class="itemvalue">
                                {{trip.activity.speed}} km/h
                            </div>
                        </div>
                        <div class="vehicle__main-currtripitem" *ngIf="vehicle.hasFuelSensor">
                            <div class="header">Current Fuel Level</div>
                            <div class="itemvalue">
                                {{trip.fuelLevel || '-'}} L
                            </div>
                        </div>
                        <div class="vehicle__main-currtripitem">
                            <div class="header">Current Heading</div>
                            <div class="itemvalue">
                                {{trip.activity.direction}}
                            </div>
                        </div>
                        <div class="vehicle__main-currtripitem">
                            <div class="header">Driving Duration</div>
                            <div class="itemvalue">
                                {{ trip.activityDurationStr }}
                            </div>
                        </div>
                    </div>

                    <div class="vehicle__mobile-control" *ngIf="trip.statusLoweredCase !== STATUS.MOVING && vehicle.hasImmobilizer">
                        You are allowed to:

                        <button class="button --outline" *ngIf="!vehicle.vehicleImmobilised" (click)="showPopup(confirmationPopupForImmobilise)">
                            Immobilise Vehicle
                        </button>
                        <button class="button --outline" *ngIf="vehicle.vehicleImmobilised" (click)="startVehicle()">
                            Start Vehicle
                        </button>
                    </div>

                    <!-- <div class="vehicle__main-currtripitems" *ngIf="trip.fuelLevel || trip.batteryLevel">
                        <div class="vehicle__main-currtripitem" *ngIf="trip.fuelLevel">
                            <div class="header">Fuel Level</div>
                            <div class="itemvalue">
                                {{ trip.fuelLevel }}L
                            </div>
                        </div>
                        <div class="vehicle__main-currtripitem" *ngIf="trip.batteryLevel">
                            <div class="header">Battery Voltage</div>
                            <div class="itemvalue">
                                {{ trip.batteryLevel }}
                            </div>
                        </div>
                    </div> -->

                    <div class="vehicle__item-activity" [ngClass]="'--' + trip.statusLoweredCase" [ngStyle]="activtyStyles" *ngIf="trip.statusLoweredCase == STATUS.STOPPED || trip.statusLoweredCase == STATUS.PARKANDALERT || trip.statusLoweredCase == STATUS.DISCONNECTED || trip.statusLoweredCase == STATUS.IDLING">
                        <b>{{ trip.statusLabel }}</b> &ndash; {{ trip.lastStopDateLabel }}<span *ngIf="trip.activity.lastStopAddress != null && trip.activity.lastStopAddress != ''"> &ndash; <br> {{ trip.activity.lastStopAddress }} </span>
                    </div>

                    <div *ngIf="hasViolation" class="vehicle__item-violations" [ngClass]="'--' + trip.violations.type">
                        <!-- <b>{{ trip.report.desc }}</b> &ndash; {{ trip.report.date | date: 'MMM dd, y' }} -->
                        <ng-container>
                            <!-- *ngIf="item.violations.type == 'EXCEED_TIME_OF_DAY'" -->
                            <b>{{ trip.violationLabel }}</b> &ndash; {{ trip.violationDateLabel }}
                        </ng-container>

                        <!-- <ng-container *ngIf="trip.report.type == 'drive'">
                            <b>{{ trip.report.desc }}</b> &ndash; {{ trip.report.date | date: 'MMM dd, y' }} 8.00 AM
                        </ng-container>
                        <ng-container *ngIf="trip.report.type == 'mileage'">
                            <b>{{ trip.report.desc }}</b> &ndash; 210 km
                        </ng-container>
                        <ng-container *ngIf="trip.report.type == 'issue'">
                            <b>{{ trip.report.desc }}</b> &ndash; {{ trip.report.date | date: 'MMM dd, y' }} 5:00 PM
                        </ng-container> -->
                    </div>

                    <div *ngIf="trip.violations === undefined" class="vehicle__item-no-violations">
                        <ng-container>
                            No Alerts Found
                        </ng-container>
                    </div>

                    <div class="vehicle__item-mobile" *ngIf="vehicle.lastImmobilisedTime && vehicle.lastImmobilisedAddress">
                        <div class="vehicle__item-mobile-status">
                            Immobilized - {{vehicle.lastImmobilisedTime}}
                        </div>

                        <div class="vehicle__item-mobile-address">
                            {{vehicle.lastImmobilisedAddress}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="vehicle__main-footer">
        <button class="footerbutton --overview" (click)="backToOverview()" [class.--active]="view === 'overview'" [disabled]="vehicle.imeiNo == null" [class.--disabled]="vehicle.imeiNo == null">
            <span class="button-icon"></span> Overview
        </button>
        <button class="footerbutton --trips" (click)="toggleTriplist()" [class.--active]="view === 'trips'" [disabled]="vehicle.imeiNo == null" [class.--disabled]="vehicle.imeiNo == null">
            <span class="button-icon"></span> Trips
        </button>
        <button class="footerbutton --status" (click)="toggleVehicleStatus()" [class.--active]="view === 'status'" [disabled]="vehicle.imeiNo == null" [class.--disabled]="vehicle.imeiNo == null">
            <span class="button-icon"></span> Vehicle Status
        </button>
        <button class="footerbutton --limits" (click)="toggleDrivingLimits()" [class.--active]="view === 'limits'" [disabled]="vehicle.imeiNo == null" [class.--disabled]="vehicle.imeiNo == null" *ngIf="viewable">
            <span class="button-icon"></span> Driving Limits
        </button>
    </div>
</div>

<div *ngIf="vehicle && vehicle.imeiNo" class="vehicle__main-triphost" [class.--active]="view === 'trips'" [class.--trip-day-item-single-view-mode]="tripDayItemSingleViewMode" id="divVehicleTripHost">
    <button id="btnCloseTripList" class="close" (click)='toggleTriplist()'></button>
    <vehicle-tripslist [init]="childTripListInit" [imei]="vehicle.imeiNo" [hasFuelSensor]="vehicle.hasFuelSensor" #tripList></vehicle-tripslist>
</div>

<app-vehicle-panel-health-status *ngIf="vehicle && vehicle.imeiNo" class="vehicle__main-status" [init]="childHealthStatusInit" [vehiclePanel]="vehiclePanel" [fuelTankSize]="vehicle.fuelTankSize" [class.--active]="view === 'status'" #vehicleHealthStatus></app-vehicle-panel-health-status>

<app-vehicle-panel-driving-limits *ngIf="vehicle && vehicle.imeiNo" class="vehicle__main-status" [init]="childDrivingLimitInit" [vehiclePanel]="vehiclePanel" [class.--active]="view === 'limits'" #vehicleDrivingLimits></app-vehicle-panel-driving-limits>

<app-popup #driverTagPopup="popup" classes="--wide-more">
    <app-driver-tag-selector #driverTagSelector title="Add ID Tag" [inPopup]="true" [singleSelect]="true"></app-driver-tag-selector>
    <div class="ta-c">
        <div class="wrapper">
            <div class="inline-group">
                <div class="inline-group__item">
                    <button type="button" class="button --min-width" (click)="showPopup(confirmationPopupForDriverTag)" [disabled]="!driverTagSelector.getSelectedIds().length">
                        Save
                    </button>
                </div>

                <div class="inline-group__item">
                    <a class="button --min-width --grey" (click)="hidePopup();">
                        Cancel
                    </a>
                </div>
            </div>
        </div>
    </div>
</app-popup>

<app-popup #confirmationPopupForDriverTag="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p class="desc">
            Driver Would Be Overwrite If Selected Driver Tag Already Tied To Driver.
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="tieDriverTagToDriver(); hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(driverTagPopup);">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #confirmationPopupForImmobilise="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirmation
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p class="desc">
            You are going to immobilise the vehicle. <br />
            The assigned driver will not able to start the vehicle later. Confirm?
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="immobiliseVehicle(); hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
