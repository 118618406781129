import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class HttpXSRFInterceptor implements HttpInterceptor {
    _xsrf_token_header_name: string = 'X-XSRF-TOKEN';

    constructor(private tokenExtractor: HttpXsrfTokenExtractor) {
        //Empty
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.tokenExtractor.getToken();
        if (token !== null && !req.headers.has(this._xsrf_token_header_name)) {
            req = req.clone({ headers: req.headers.set(this._xsrf_token_header_name, token) });
        }
        return next.handle(req);
    }
}
