<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>
            <h1 class="reports__heading   heading --level-1">
                Alert History Report
            </h1>

            <div class="reports__filters" style="position: relative; z-index: 3;">
                <div class="grid --gutter   reports__filters-grid">
                    <div class="grid__block   reports__filters-grid-block --type">
                        <div class="field-group --unstack@xs   reports__type-field-group">



                            <!-- <div class="field-group__item   reports__type-field-group-input" >
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                            </div>
                                        </div>

                                        <label class="select" style="width: 100%">
                                            <select (change)="onVehicleChoose($event.target.value);" name="vehicleId" #vehicleId="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.vehicleId" required>
                                                <option value="" selected disabled hidden>Please Select</option>
                                                <option *ngFor="let item of vehicleInfoList" [value]="item.vehicleId">{{item.vehiclePlateNo}}</option>
                                            </select>
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>

                                </div>
                            </div> -->
                            <div class="field-group__input reports__type-field-group-input">
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                            </div>
                                        </div>
                                        <label class="select" style="width: 100%">
                                            <select class="select__input"
                                                (change)="onVehicleChoose($event.target.value);" name="vehicleId"
                                                #vehicleId="ngModel"
                                                [(ngModel)]="currentVehicleObj.vehicleId" required>
                                                <option value="" selected disabled hidden>Please Select</option>
                                                <option *ngFor="let item of vehicleInfoList" [value]="item.vehicleId">
                                                    {{item.vehiclePlateNo}}</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div *ngIf="vehicleId.errors && (vehicleId.dirty || vehicleId.touched)">
                                    <div class="field-error" *ngIf="vehicleId.errors?.required">
                                        {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Vehicle Plate Number')}}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="grid__block   reports__filters-grid-block --period">
                        <div class="field-group --unstack@xs   reports__period-field-group">
                            <div class="field-group__item   reports__period-field-group-input"
                                [class.--active]="fromDateDOMStates.isFocused">
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <mat-datepicker-toggle [for]="FromDatepicker">
                                                    <img matDatepickerToggleIcon
                                                        src="assets/images/scss-components/forms/icon-calendar.svg"
                                                        width="24" height="20" alt="" class="field__icon-image">
                                                </mat-datepicker-toggle>
                                            </div>
                                        </div>

                                        <input [matDatepicker]="FromDatepicker" (focus)="FromDatepicker.open()"
                                            (click)="FromDatepicker.open()" type="text" [(ngModel)]="fromDate"
                                            [max]="toDate" placeholder="From Date" class="field__input" readonly
                                            dom-states #fromDateDOMStates="DOMStates">

                                        <div class="field__ui"></div>
                                    </div>

                                    <mat-datepicker #FromDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                                </div>
                            </div>

                            <div class="field-group__item   reports__period-field-group-input"
                                [class.--active]="toDateDOMStates.isFocused">
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <mat-datepicker-toggle [for]="ToDatepicker">
                                                    <img matDatepickerToggleIcon
                                                        src="assets/images/scss-components/forms/icon-calendar.svg"
                                                        width="24" height="20" alt="" class="field__icon-image">
                                                </mat-datepicker-toggle>
                                            </div>
                                        </div>
                                        <input [matDatepicker]="ToDatepicker" type="text" [(ngModel)]="toDate"
                                            [min]="fromDate" [max]="moment().startOf('day').format()" readonly
                                            (focus)="ToDatepicker.open()" (click)="ToDatepicker.open()"
                                            placeholder="To Date" class="field__input" dom-states
                                            #toDateDOMStates="DOMStates">

                                        <div class="field__ui"></div>
                                    </div>

                                    <mat-datepicker #ToDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid__block   reports__filters-grid-block --cta">
                        <button type="button" class="button" [disabled]="!currentVehicleObj.vehicleId" (click)="generateReport()">
                            Generate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block   w-3of12@xs">
                        <h2 class="reports__heading   heading --level-2 --partial-underline">
                            Results:
                        </h2>
                    </div>

                    <div class="grid__block   w-9of12@xs   ta-r@xs" *ngIf="isReportShown">
                        <button type="button" class="button" (click)="showDownloadPopup(downloadsPopup)">
                            <img src="assets/images/scss-components/buttons/icon-download.svg" width="24" height="18"
                                alt="" class="button__icon"> Download
                        </button>
                    </div>
                </div>
            </div>

            <div class="report-box">
                <app-spinner #reportResult_spinner></app-spinner>
                <div class="block">
                    <div class="table">
                        <table class="table__elem">
                            <thead>
                                <tr>
                                    <th scope="col" width="30px">
                                        Vehicle Name
                                    </th>
                                    <th scope="col" width="30px">
                                        <button type="button" class="table__sortable"
                                            [ngClass]="getSortingState('gpsTimeStamp')"
                                            (click)="doSorting('gpsTimeStamp')">Date/Time</button>
                                    </th>
                                    <th scope="col" width="50px">
                                        Alert Type
                                    </th>
                                    <th scope="col" width="20%">
                                        Location
                                    </th>
                                    <th scope="col" width="10%">
                                        Lat,Lon(Altitude)
                                    </th>
                                    <th scope="col" width="30px">
                                        Driver Name
                                    </th>
                                    

                                    <!-- <th scope="col" style="width:50px; min-width: 50px"></th> -->
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let record of resultList">
                                    <td class="td-truncate" [title]="record.vehicleName || '-'">{{record.vehicleName ||'-'}}</td>
                                    <td>{{record.gpsTimeStamp}}</td>
                                    <td class="td-truncate" style="white-space:pre" [title]="record.alertType || '-'">{{record.alertType ||'-'}}</td>
                                    <td class="td-truncate" [title]="record.address || '-'">{{record.address ||'-'}}</td>
                                    <td class="td-truncate" [title]="record.latlng || '-'">{{record.latlng ||'-'}}</td>
                                    <td class="td-truncate" [title]="record.driverName || '-'">{{record.driverName ||'-'}}</td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h2 class="reports__record-not-found --level-1" *ngIf="!isReportShown">{{ isReportSearched ?
                        message.getMessage(message.MESSAGE.REPORT_NO_RECORD_FOUND.value) :
                        message.getMessage(message.MESSAGE.REPORT_PLEASE_GENERATE.value) }}</h2>
                </div>

                <div class="block" *ngIf="isReportShown">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>

                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination">
                                <button type="button" class="pagination__button"
                                    [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1"
                                    (click)="fetchPage(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button"
                                    [class.--active]="page === pager.currentPage" (click)="fetchPage(page)">{{ page
                                    }}</button>

                                <button type="button" class="pagination__button"
                                    [class.--disabled]="pager.currentPage === pager.totalPages"
                                    [disabled]="pager.currentPage === pager.totalPages"
                                    (click)="fetchPage(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #downloadsPopup="popup">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="btnExcel()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43"
                        class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnCsv()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43"
                        class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnPdf()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35"
                        class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>