// Reference from https://codepen.io/Merri/pen/Dsuim
import { Directive, HostListener, OnInit, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[line-clamp]',
    exportAs: 'line-clamp',
})
export class LineClampDirective implements OnInit {
    @Input('line-clamp') options:any = {};
    timer:any;
    last:any = new Date(0);
    throttleDuration:number = 150;
    result:string = '';

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
        this.throttledClamp();
    }

    @HostListener('window:resize') onResize() {
        this.throttledClamp();
    }

    throttledClamp() {
        let now:any = new Date();
        let diff:any = now - this.last;

        clearTimeout(this.timer);

        if(diff >= this.throttleDuration) {
            this.clamp();
            this.last = now;
        } else {
            this.timer = setTimeout(() => {
                this.clamp();
                this.last = new Date();
            }, diff);
        }
    }

    emptyElem(elem) {
        while(elem.firstChild) {
            elem.removeChild(elem.firstChild);
        }
    }

    clamp() {
        if(!this.options.lines) { // Must be clamping to at least 1 line and above
            return;
        }

        let elem = this.elementRef.nativeElement;
        let text = this.options.text;
        let lineWidth = elem.clientWidth;
        let lineCount = 1;
        let lineStart = 0;
        let wordStart = 0;
        let dce = document.createElement.bind(document);
        let dctn = document.createTextNode.bind(document);
        let measure = dce('div');
        measure.style.position = 'absolute';
        measure.style.whiteSpace = 'pre';
        measure.style.visibility = 'hidden';

        this.result = '';
        elem.appendChild(measure);

        text.replace(/ /g, (z, index) => {
            if(lineCount >= this.options.lines) {
                return;
            } else {
                measure.appendChild(dctn(text.substring(wordStart, index)));

                if(measure.clientWidth > lineWidth) {
                    this.result += text.substring(lineStart, wordStart);
                    // this.emptyElem(measure);
                    // elem.appendChild(dctn(text.substring(lineStart, wordStart)));
                    lineStart = wordStart + 1;
                    lineCount++;
                }

                wordStart = index;
            }
        });

        this.result += `<span class="truncate-line">${text.substring(lineStart)}</span>`;
        elem.removeChild(measure);
    }
}
