import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as NumberUtil from './../../util/numberUtil';

@Directive({
    selector: '[OnlyNumber]'
    //support 'OnlyInteger' attribute
})
export class OnlyNumberDirective {

    // Allow decimal numbers. The \. is only allowed once to occur
    private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);

    // Allow key codes for special events.
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        //Check if OnlyInteger
        let onlyIntAttr = this.el.nativeElement.getAttribute('OnlyInteger');
        if (onlyIntAttr != null && onlyIntAttr != undefined) {
            this.regex = new RegExp(/^[0-9]*$/g);
        }

        //Check if MaxNumber
        let maxNumber = this.el.nativeElement.getAttribute('MaxNumber');
        if (maxNumber != null && maxNumber != undefined) {
            if (NumberUtil.isInteger(maxNumber)) {
                maxNumber = parseInt(maxNumber);
            }
        } else {
            maxNumber = null;
        }

        //Check if MinNumber
        let minNumber = this.el.nativeElement.getAttribute('MinNumber');
        if (minNumber != null && minNumber != undefined) {
            if (NumberUtil.isInteger(minNumber)) {
                minNumber = parseInt(minNumber);
            }
        } else {
            minNumber = null;
        }

        // Allow special keys
        // console.debug(event.key);
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = this.el.nativeElement.value;
        // We need this because the current value on the DOM element
        // is not yet updated with the value from this event
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        } else {
            //extra validations
            if (maxNumber) {
                if (parseFloat(next) > maxNumber) {
                    event.preventDefault();
                }
            }
            if (minNumber) {
                if (parseFloat(next) < minNumber) {
                    event.preventDefault();
                }
            }
        }
    }
}