<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <div class="block">
                <h1 class="heading --level-1">
                    Reset Password
                </h1>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block w-10of12 w-6of12@md search__group">
                        <div class="field-group --unstack@xs search__select-field">
                            <div class="field-group__item search__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                <label class="select --grey">
                                    <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                        <option value="company">Company</option>
                                        <option value="username">Username</option>
                                        <option value="name">Name</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__item search__type-field-group-input">
                                <div class="field">
                                    <div class="field__elems">
                                        <label class="field__holder">
                                            <input id="searchBox" type="text" class="field__input" [placeholder]="getPlaceHolderText()" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchValue()">
                                        </label>
                                        <div class="field__ui"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="button__holder">
                            <button type="button" class="button" (click)="searchValue()">SEARCH</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="table table__reset-password" (scroll)="onResetPasswordTableScroll()" [class.--scrolled-to-end]="resetPasswordTableScrolledToEnd" #resetPasswordTable>
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('CompanyName')" (click)="sort('CompanyName')">Company</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('UserLoginName')" (click)="sort('UserLoginName')">Username</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('UserName')" (click)="sort('UserName')">Name</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('Role')" (click)="sort('Role')">Role</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('userStatus')" (click)="sort('userStatus')">Account Status</button>
                                </th>

                                <th scope="col" class="sticky-col --first-col --fill-data">
                                    Reset Password
                                </th>

                                <th scope="col" class="sticky-col --second-col --fill-data">
                                    Re-activate Account
                                </th>
                                <th scope="col" class="sticky-col --third-col --fill-data">
                                    Reset Email
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let user of usersDetailsList">
                                <td>{{user.companyName}}</td>
                                <td>{{user.userLoginName}}</td>
                                <td>{{user.userName}}</td>
                                <td>{{user.roleLabel}}</td>
                                <td>{{user.userStatus}}</td>
                                <td class="sticky-col --first-col --fill-data">
                                    <a class="link" [ngClass]="{
                                        '--adisabled': user.userStatus == 'SUSPENDED',
                                        '--primary': user.userStatus != 'SUSPENDED'
                                    }" (click)="setUserIdAndEmail(user.userId, user.userLoginName); showPopup(resetPasswordPopup);">Reset
                                        Password</a>
                                </td>

                                <td class="sticky-col --second-col --fill-data">
                                    <a class="link" [ngClass]="{
                                        '--adisabled': user.userStatus == 'SUSPENDED',
                                        '--primary': user.userStatus != 'SUSPENDED'
                                    }" (click)="setUserIdAndEmail(user.userId, user.userLoginName); showPopup(reactivateButtonPopup);">
                                        Re-Activate</a>
                                </td>
                                <td class="sticky-col --third-col --fill-data">
                                    <a class="link" [ngClass]="{
                                        '--adisabled': user.userStatus == 'SUSPENDED',
                                        '--primary': user.userStatus != 'SUSPENDED'
                                    }" (click)="setUserIdAndEmail(user.userId, user.userLoginName); showPopup(SetEmailPopup);">
                                        Reset Email</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="block" *ngIf="usersDetailsList.length > 0">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                        </div>
                    </div>
                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination">
                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDisplayUsersList(pager.currentPage - 1)">
                                <span class="angle-arrow --left">
                                </span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDisplayUsersList(page)">{{
                                page }}</button>

                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDisplayUsersList(pager.currentPage + 1)">
                                <span class="angle-arrow --right">
                                </span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #resetPasswordPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Reset?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to reset current account password.
        </p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="resetPassword();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #successNotification="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Notification
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p [innerHtml]="emailSentPopupMsg"></p>

        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                OK
            </button>
        </div>
    </div>
</app-popup>

<app-popup #reactivateButtonPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to save vehicle changes
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="reactivateUserAccount();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #SetEmailPopup="popup" class="set-limit-popups">
    <div class="popup__block">
        <div class="grid --gutter --flex-bottom">
            <div class="grid__block w-8of12@sm form__flex">
                <h1 class="heading indicator-green">
                    Edit User Email
                </h1>
            </div>
        </div>
    </div>

    <div class="popup__block">
        <h2 class="heading --level-2">

        </h2>
    </div>

    <form class="form-field" #newUserEmailForm="ngForm">
        <div class="popup__block">
            <div class="field-group__input set-limit-field">
                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Current Email:
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input"  disabled name="resetPasswordUserEmail" [(ngModel)]="resetPasswordUserEmail" >
                                </label>

                                <div class="field__ui"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field-group__groups">
                    
                    <div class="field-group__groups-col">
                        New User Email:
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input" required name="newUserEmail" [(ngModel)]="newUserEmail">
                                </label>

                                <div class="field__ui"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>

		<div class="popup__block">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" [disabled]="newUserEmailForm.invalid" (click)="showPopup(updateUserEmailPopup);">
                    Save
                </button>

                <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                    Cancel
                </button>
            </div>
        </div>

    </form>
</app-popup>


<app-popup #updateUserEmailPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateUserEmail();hidePopup();">
                Confirm
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();showPopup(SetEmailPopup);">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
