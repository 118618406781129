export const USER_ROLE = {

    SUPER_ADMIN: {
        name: 'Super Admin',
        value: 'SUPER_ADMIN',
        label: 'Super Admin',
        roleId: 1
    },
    ADMIN: {
        name: 'Admin',
        value: 'ADMIN',
        label: 'Admin',
        roleId: 2
    },
    DRIVER: {
        name: 'Driver',
        value: 'DRIVER',
        label: 'Driver',
        roleId: 3
    },
    MAXIS_SUPPORT_A: {
        name: 'Maxis support type A',
        value: 'MAXIS_ADMIN_LEVEL_A',
        label: 'Customer Service',
        roleId: 7,
        supportId: 2
    },
    MAXIS_SUPPORT_B: {
        name: 'Maxis support type B',
        value: 'MAXIS_ADMIN_LEVEL_B',
        label: 'Maxpert',
        roleId: 6,
        supportId: 1
    },
    MAXIS_SUPPORT_C: {//View only role
        name: 'Maxis support type C',
        value: 'MAXIS_ADMIN_LEVEL_C',
        label: 'Maxpert Field Support',
        roleId: 41,
        supportId: 5
    },
    MAXIS_SUPPORT_ID_MGMT: {
        name: 'Maxis support ID Management',
        value: 'MAXIS_ADMIN_ID_MGMT',
        label: 'Account Support',
        roleId: 5,
        supportId: 3
    },
    SUPER_SUPER_ADMIN: {
        name: 'Super Super Admin',
        value: 'SUPER_SUPER_ADMIN',
        label: 'Super Super Admin',
        roleId: 39
    },
    MAXIS_SALES_DEMO: {
        name: 'Sales Demo Admin',
        value: 'MAXIS_SALES_DEMO',
        label: "Sales Demo Admin",
        roleId: 18,
        supportId: 4
    }
};

export function getUserRoleLabel(role) {
    for (const roles in USER_ROLE) {
        if (role === USER_ROLE[roles].value) {
            return USER_ROLE[roles].label;
        }
    }
    return '-';
}

export function getUserRoleValueFromLabel(role) {
    for (const roles in USER_ROLE) {
        if (role === USER_ROLE[roles].label) {
            return USER_ROLE[roles].value;
        }
    }
    return '-';
}

export function getUserRoleFromLabel(role) {
    for (const roles in USER_ROLE) {
        if (role === USER_ROLE[roles].label) {
            return roles;
        }
    }
}

export function searchUserRole(role) {
    for (const roles in USER_ROLE) {
        const label = USER_ROLE[roles].label;
        const search = label.search(role);
        if (search > 0) {
            return USER_ROLE[roles].value;
        }
    }
    return '';
}
