/**
 * Wait for N milli seconds before continue
 * @param ms (number) Duration to wait in ms
 * @return (promise)
 */
export async function wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Run function passed in as promise
 * @param asyncFunction (function) callback
 * @return (promise)
 */
export async function asAsyncPromise(asyncFunction: () => any) {
    return new Promise((resolve, reject) => {
        (async function () {
            await asyncFunction();
        })().then(function () {
            resolve(null);
        }).catch(err => {
            reject(err);
        });
    });
}
