import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    // Input Parameters
    @Input('aboveSiteHeader') aboveSiteHeader = false;
    @Input('disableScroll') disableScroll = false;
    @Input('transparent') transparent = false;
    @Input('fullscreen') fullscreen = false;
    @Input('keepParentUntouched') keepParentUntouched = false;
    @Input('keepParentSize') keepParentSize = false;

    // Child Elements
    @ViewChild('overlay',{static:false}) overlay;
    @ViewChild('skSpinner',{static:false}) skSpinner;

    // Global variable
    noOfLoadingitems: number = 0; //keep track number of invokers
    parentNode: any = null;

    constructor(private el: ElementRef) {
        this.parentNode = this.el.nativeElement.parentNode;
    }

    async ngOnInit() {
        
    }

    async ngAfterViewInit() {
        if (this.transparent) {
            this.overlay.nativeElement.style.opacity = '0';
        }
        if (this.aboveSiteHeader) {
            // Modify z-index to make spinner on top of everything
            this.overlay.nativeElement.style['z-index'] = '100';
        }
        if (this.fullscreen) {
            this.overlay.nativeElement.style.position = 'fixed';

            //move position of spinner lower 5% to cater for site-header height
            // refer: spinkit-spinner.scss for the normal percentage + 5
            this.skSpinner.nativeElement.style['top'] = `45%`;

        } else if (!this.keepParentUntouched) {
            this.makeParentSupportSpinner();
        }
    }

    show(): void {
        this.noOfLoadingitems++; //add invoker
        if (this.noOfLoadingitems > 0) {
            try {
                // show spinner
                this.overlay.nativeElement.style.display = 'block';
                if (this.disableScroll) {
                    this.disableParentScrolling();
                }
            } catch (err) {
                // do nothing
            }
        }
    }

    showOne(): void {
        if (!this.isShowing()) {
            this.show();
        }
    }

    isShowing(): boolean {
        if (this.overlay.nativeElement.style.display == 'block') {
            return true;
        }
        return false;
    }

    hide(): void {
        if (this.noOfLoadingitems > 0) {
            this.noOfLoadingitems--; //revoke invoker
            try {
                // hide spinner (when no more invokers are using the spinner)
                if (this.noOfLoadingitems == 0) {
                    this.overlay.nativeElement.style.display = 'none';
                    if (this.disableScroll) {
                        this.enableParentScrolling();
                    }
                }
            } catch (err) {
                // do nothing
            }
        }
    }

    hideAll(): void {
        this.noOfLoadingitems = 1;
        this.hide();
    }

    makeParentSupportSpinner() {
        if (this.parentNode) {
            this.parentNode.style['display'] = 'block';
            this.parentNode.style['position'] = 'relative';
            if (!this.keepParentSize) {
                this.parentNode.style['min-height'] = '200px';
            }
            this.parentNode.style['overflow-anchor'] = 'none'; // fix auto scroll to bottom
        }
    }

    /* Only works if ancestors does not have "overflow = auto" */
    disableParentScrolling() {
        if (this.parentNode) {
            this.parentNode.style['overflow'] = 'hidden';
            const scrollTop = this.parentNode.scrollTop;
            this.overlay.nativeElement.style['top'] = scrollTop + 'px';
        }
    }

    enableParentScrolling() {
        if (this.parentNode) {
            this.parentNode.style['overflow'] = 'auto';
        }
    }

    // @HostListener('window:scroll') onScroll() {
    //     //Fix position of loading overlay after scrolling
    //     if (this.disableScroll && this.parentNode) {
    //         let scrollTop = this.parentNode.scrollTop;
    //         this.overlay.nativeElement.style['top'] = scrollTop + 'px';
    //     }
    // }

}
