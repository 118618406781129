import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { Chart } from 'chart.js';
// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from '../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { TripsService } from '../../../../_services/trips/trips.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
// import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
// Util
import * as NumberUtil from './../../../../util/numberUtil';
import * as StringUtil from './../../../../util/stringUtil';
import * as DomUtil from './../../../../util/domUtil';
// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';
import { ERROR_MESSAGE as ErrorMessage } from './../../../../constants/errorMessage';
import { DashboardColors as Colors } from './../../dashboard/DashboardColors';
import { ContentObserver } from '@angular/cdk/observers';

const CHART_TICK_LABEL_MAX_LENGTH: number = 5; // chartJS truncate: axis tick label
const CHART_TOOLTIP_MAX_LENGTH: number = 22; // chartJS truncate: tooltip title

@Component({
  selector: 'app-speeding-report',
  templateUrl: './speeding-report.component.html',
  styleUrls: ['./speeding-report.component.scss'],
  animations: [
    trigger('fadeToggle', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SpeedingReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

  expandGroupVehicle: any = [];
  expandGroupDriver: any = [];

  // Set Export File Name from environments
  exportPdfSpeedingPageLayout = environment.appConfig.reporting.speeding.layout;
  exportFileNameSpeeding = environment.appConfig.reporting.speeding.fileName;
  exportFileNameSpeedingPdfTitle = environment.appConfig.reporting.speeding.label;
  pageLayout = this.exportPdfSpeedingPageLayout;
  exportPdfDetailedSpeedingPageLayout = environment.appConfig.reporting.detailedSpeeding.layout;
  exportFileNameDetailedSpeeding = environment.appConfig.reporting.detailedSpeeding.fileName;
  exportFileNameDetailedSpeedingPdfTitle = environment.appConfig.reporting.detailedSpeeding.label;
  moment = moment;

  @ViewChild("page_spinner") page_spinner: SpinnerComponent;
  @ViewChild("reportResult_spinner") reportResult_spinner: SpinnerComponent;

  // Message Properties
  message = Message;

  //Vehicle select list
  vehicleInfoList = [];
  currentVehicleObj = {
    vehicleId: '',
    vehicleName: null,
    vehiclePlateNo: null,
  };
  hasUnviewedCamapaign: boolean = false;
  unviewedCampaigns: Array<any> = [];
  currentTab: number = 1;
  valueBy = '';
  isGreater: boolean = true;
  isLesser: boolean = false;
  speeds: number;

  //graph
  chartJsBarHoverInteraction: any;
  chartJsTooltipStyles: any;
  chartJsHoverProps: any;
  chartJsTicksFontStyles: any;
  chartJsTicksStringEllipsisCallbackFn: any;
  chartJsTicksNumberTruncateCallbackFn: any;
  chartJsTooltipTruncateCallbackTitleFn: any;
  chartJsAnimation: any;
  //overrideChartJsOptions
  chartJsScaleLabelFontStyles: any;
  speedingGraph;
  isChart = 1;

  fetchPage: any;
  generateDownloadables: any;

  simpleMapMarkerCoordinates = {lat: 0, lng: 0};

  constructor(
    private platform: Platform,
    private http_parent: HttpClient,
    private snackBar: SnackBarService,
    private pagerService: PagerService,
    private tripsService: TripsService,
    private popupService_parent: PopupService,
    private popupCampaignService: PopupCampaignService,
    private el: ElementRef,
    // private vehicleService: VehicleService,
  ) {

    //pass services to parent
    super(http_parent, popupService_parent, snackBar);
  }

  /**
   * To work properly with GeneralReportClass
   * Please call this method at first line of ngOnInit()
   */
  async initGeneralReportClass() {
    this.page_spinner.show();
    this.fetchPage = this.fetchSpeedingPage;
    this.generateDownloadables = this.generateSpeedingReportDownloadables;
    this.handShakeImplementations(
      this.fetchPage.bind(this),
      this.fetchDataForDownload.bind(this),
      this.generateDownloadables.bind(this),
      this.page_spinner
    );
    await this.initialise({
      usingDateRangePicker: true,
      usingVehicleSelector: true
    });
    this.page_spinner.hide();
  }

  async ngOnInit() {

    //Default value by
    this.valueBy = "isGreater";

    //Default search range
    // this.fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    // this.toDate = moment().format("YYYY-MM-DD");
    await this.getVehiclesInfo();
    // Init page components & dropdown options
    await this.initGeneralReportClass();

    //Page load search report
    // await this.generateReport();
    this.initChartJsDefaults();
    await this.checkUnviewedCampaign();

  }

  async checkUnviewedCampaign() {
    try {
      const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
      this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
      this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
    } catch (e) {
      this.snackBar.openStandardizedErrorSnackBar(e);
    }
  }

  async overview() {
    this.currentTab = 1;
    this.valueBy = "isGreater";
    this.isGreater = true;
    this.isLesser = false;
    this.speeds = null;
    this.currentVehicleObj.vehicleId = '';
    this.pager = {};
    this.isReportShown = false;
    this.resultList = [];
    this.currentFromDate = "";
    this.currentToDate = "";
    this.isChart = 1;
    // await this.getDisplayVehicleList();
  }

  async speeding() {
    this.currentTab = 2;
    this.valueBy = "isGreater";
    this.isGreater = true;
    this.isLesser = false;
    this.speeds = null;
    this.currentVehicleObj.vehicleId = '';
    this.pager = {};
    this.isReportShown = false;
    this.resultList = [];
    this.currentFromDate = "";
    this.currentToDate = "";
    this.isChart = 0;
    if (this.speedingGraph !== undefined) {
      this.speedingGraph.destroy();
    }
    this.fetchPage = this.fetchSpeedingPage;
    this.generateDownloadables = this.generateSpeedingReportDownloadables;
    this.handShakeImplementations(
      this.fetchPage.bind(this),
      this.fetchDataForDownload.bind(this),
      this.generateDownloadables.bind(this),
      this.page_spinner
    );
  }

  speedReport() {
    this.currentTab = 3;
    this.valueBy = "isGreater";
    this.isGreater = true;
    this.isLesser = false;
    this.speeds = null;
    this.currentVehicleObj.vehicleId = '';
    this.pager = {};
    this.isReportShown = false;
    this.resultList = [];
    this.currentFromDate = "";
    this.currentToDate = "";
    this.isChart = 0;
    if (this.speedingGraph !== undefined) {
      this.speedingGraph.destroy();
    }
    this.fetchPage = this.fetchDetailedSpeedingPage;
    this.generateDownloadables = this.generateDetailedSpeedingReportDownloadables;
    this.handShakeImplementations(
      this.fetchPage.bind(this),
      this.fetchDataForDownload.bind(this),
      this.generateDownloadables.bind(this),
      this.page_spinner
    );
  }

  onVehicleChoose(vehicleId: number) {
    const foundVehicle = this.vehicleInfoList.find(result => result.vehicleId === parseInt(vehicleId.toString()));
    if (foundVehicle) {
      this.currentVehicleObj.vehicleName = foundVehicle.vehicleName;
    } else {
      this.currentVehicleObj.vehicleName = null;
    }
  }
  /**
   * @Implementing Methods of GeneralReportInterface
   */

  // Call API to get data with pagination
  async fetchDetailedSpeedingPage(page) {
    if (!this.page_spinner.isShowing()) {
      this.reportResult_spinner.show();
    }
    try {
      // toggle
      this.isReportSearched = true;

      const startRecord = ((page - 1) * this.pageRecordSize) + 1;

      //call api to get report page
      const apiControllerResponse: any = await this.getApiControllerResponse(startRecord);
      if (apiControllerResponse.response != null) {
        this.apiResponse = apiControllerResponse.response;

        //update sorting classes
        this.updateSortingState(this.apiResponse.sort);

        this.resultList = apiControllerResponse.resultList;
        if (this.resultList.length) {

          // parse some values to reduce function data bindings
          this.resultList.forEach(record => {
            record.duration = moment.utc(record.durationInMs).format('HH:mm:ss');
          });

          //update isReportShown boolean
          this.isReportShown = true;

          //get Pager data from service
          this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
        }
      }

    } catch (err) {
      this.snackBar.openStandardizedErrorSnackBar(err);
    } finally {
      this.reportResult_spinner.hide();
      this.page_spinner.hide();
    }
  }
  async fetchSpeedingPage(page: number = 1) {
    if (!this.page_spinner.isShowing()) {
      this.reportResult_spinner.show();
    }
    try {
      // toggle
      this.isReportSearched = true;

      const startRecord = ((page - 1) * this.pageRecordSize) + 1;

      //call api to get report page
      const apiControllerResponse: any = await this.getApiControllerResponse(startRecord);
      if (apiControllerResponse.response != null) {
        this.apiResponse = apiControllerResponse.response;

        //update sorting classes
        this.updateSortingState(this.apiResponse.sort);

        this.resultList = apiControllerResponse.resultList;
        if (this.resultList.length) {

          // parse some values to reduce function data bindings
          this.resultList.forEach(record => {
            record.gpsTimeStamp = moment(record.gpsTimeStamp, 'DD/MM/YYYY hh:mm:ss A').format('DD/MM/YYYY hh:mm:ss A');
            record.speed = record.speed;
          });

          //update isReportShown boolean
          this.isReportShown = true;

          //get Pager data from service
          this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
        }
      }
      if (this.isChart) {
        await this.getSpeedingGraph(this.resultList);
      }

    } catch (err) {
      this.snackBar.openStandardizedErrorSnackBar(err);
    } finally {
      this.reportResult_spinner.hide();
      this.page_spinner.hide();
    }
  }
  // Call API to get all data for download
  async fetchDataForDownload() {

    //call download api
    const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

    return apiControllerResponse.resultList;
  }
  // All API calls go through here, logics put inside here
  async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

    let apiResponse: any = null;
    let apiResultList: Array<any> = [];

    if (this.currentTab === 1) {
      apiResponse = await this.getSpeedingRecords(startRecord, isDownload);
    } else if (this.currentTab === 3) {
      apiResponse = await this.getDetailedSpeedingRecords(startRecord, isDownload);
    } else {
      apiResponse = await this.getSpeedingRecords(startRecord, isDownload);
    }

    if (apiResponse != null) {
      apiResultList = apiResponse.speedingReport;
    }

    return {
      response: apiResponse,
      resultList: apiResultList
    };
  }
  // For download speeding report
  generateDetailedSpeedingReportDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
    if (!recordList.length) {
      return null;
    }

    let headerName: any = [];
    const headerType: any = [];
    let filename: string = "";
    let label: string = "";
    const data: any = [];
    const excelWidthConfig = [
      { wch: 17.5 }, { wch: 17.5 }, { wch: 17.5 }, { wch: 17.5 },
      { wch: 17.5 }, { wch: 17.5 }, { wch: 17.5 }, { wch: 17.5 }, { wch: 17.5 }];

    filename = this.exportFileNameDetailedSpeeding;
    label = this.exportFileNameDetailedSpeedingPdfTitle;

    headerName = [
      "Plate No", "Start Time", "End Time", "Start Lat,Lon", "End Lat,Lon",
      "Start Speed", "End Speed", "Max Speed", "Speeding Duration (HH:mm:ss)"
    ];

    for (const r of recordList) {
      const tempRow = [
        r.plateNo,
        r.startGpsTimeStamp,
        r.endGpsTimeStamp,
        r.startLat + ',' + r.startLng,
        r.endLat + ',' + r.endLng,
        r.startSpeed,
        r.endSpeed,
        r.maxSpeed,
        moment.utc(r.durationInMs).format('HH:mm:ss')
      ];
      data.push(tempRow);
    }

    return new DownloadableArrayItem(filename, label, this.exportPdfDetailedSpeedingPageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
  }
  generateSpeedingReportDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
    if (!recordList.length) {
      return null;
    }

    let headerName: any = [];
    const headerType: any = [];
    let filename: string = "";
    let label: string = "";
    const data: any = [];
    const excelWidthConfig = [{ wch: 15 }, { wch: 17.5 }, { wch: 17.5 }];

    filename = this.exportFileNameSpeeding;
    label = this.exportFileNameSpeedingPdfTitle;

    headerName = [
      "Date/Time", "Lat,Lon(Altitude)", "Speed(km/h)"
    ];

    /**
     * Change the header type sequence, when the header sequnce is changed
     * Change the header type, when the header content data type is changed
     */
    // // excel file cell data type & format(if applicable)
    // headerType = [
    //     { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" },
    //     { type: "string" }, { type: "number" }, { type: "number" }, { type: "number" },
    //     { type: "number" }, { type: "number" }, { type: "number" }, { type: "string" }, { type: "string" }
    // ];

    for (let i = 0; i < recordList.length; i++) {
      const tempRow = [
        // When output excel xlsx.js library will add an addition 46 seconds into the date time
        moment(recordList[i].gpsTimeStamp, 'DD/MM/YYYY hh:mm:ss A').format('YYYY/MM/DD HH:mm:ss'),
        // recordList[i].address || '',
        recordList[i].latlng || '',
        recordList[i].speed || ''
      ];
      data.push(tempRow);
    }
    return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
  }

  /**
   * Report Specific Methods
   */

  // 1. Call API Speeding Report
  async getSpeedingRecords(startRecord: number = 1, isDownload: boolean = false) {

    let result: any = null;
    if (this.valueBy === 'isGreater') {
      this.isGreater = true;
      this.isLesser = false;
    } else {
      this.isGreater = false;
      this.isLesser = true;
    }

    if (!isDownload) {
      result = await this.tripsService.getSpeedingReport(this.currentVehicleObj.vehicleId, this.currentFromDate, this.currentToDate, this.speeds, this.isGreater, this.isLesser, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
    } else {
      result = await this.tripsService.getSpeedingReport(this.currentVehicleObj.vehicleId, this.currentFromDate, this.currentToDate, this.speeds, this.isGreater, this.isLesser, null, null, this.currentSortField, this.currentSortAscending);
    }

    return result || null;
  }
  async getDetailedSpeedingRecords(startRecord: number = 1, isDownload: boolean = false) {

    let result: any = null;

    if (!isDownload) {
      result = await this.tripsService.getDetailedSpeedReport(this.selectedVehiclesList, this.currentFromDate, this.currentToDate, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
    } else {
      result = await this.tripsService.getDetailedSpeedReport(this.selectedVehiclesList, this.currentFromDate, this.currentToDate, null, null, this.currentSortField, this.currentSortAscending);
    }

    return result || null;
  }
  initChartJsDefaults() {
    Chart.defaults.global.legend.display = false;
    // this.randomColourGenerator();
    // this.colourList = [
    //     Colors.RGBA_PRI1_SHARP_GREEN,
    //     Colors.RGBA_PRI3_DARK_GREEN,
    //     Colors.RGBA_PRI4_GRASS_GREEN,
    //     Colors.RGBA_HL3_SKY_BLUE,
    //     Colors.RGBA_HL2_ROYALE_BLUE,
    //     Colors.RGBA_VIOLET,
    //     Colors.RGBA_ORANGE,
    //     Colors.RGBA_BRIGHT_RED,
    //     Colors.RGBA_GRAY,
    //     Colors.RGBA_DARK_GREEN
    // ];
    // this.violationPieColourList = [
    //     Colors.RGBA_PRI4_GRASS_GREEN,
    //     Colors.RGBA_HL3_SKY_BLUE,
    //     Colors.RGBA_HL2_ROYALE_BLUE,
    //     Colors.RGBA_PRI1_SHARP_GREEN,
    //     Colors.RGBA_PRI3_DARK_GREEN,
    //     Colors.RGBA_CTA6_PURPLE
    // ];
    this.chartJsHoverProps = {
      animationDuration: 300,
      onHover: function (e: MouseEvent) {
        const point = this.getElementAtEvent(e);
        if (point.length) {
          (<HTMLElement>e.target).style.cursor = 'pointer';
        } else {
          (<HTMLElement>e.target).style.cursor = 'default';
        }
      }
    };
    this.chartJsAnimation = {
      easing: "easeInOutCubic"
    };
    this.chartJsTooltipStyles = {
      titleAlign: 'center',
      bodyAlign: 'center',
      yPadding: 8,
      xPadding: 10
    };
    this.chartJsBarHoverInteraction = {
      hoverBorderColor: Colors.RGBA_WHITE_SHADE,
      hoverBorderWidth: 2
    };
    this.chartJsScaleLabelFontStyles = {
      fontStyle: "bold",
      fontColor: Colors.RGBA_BLACK,
      fontSize: 13
    };
    this.chartJsTicksFontStyles = {
      fontColor: Colors.RGBA_BLACK,
      fontSize: 12
    };
    this.chartJsTicksNumberTruncateCallbackFn = function (value) {
      if (value >= 1000000) {
        return NumberUtil.roundOff(value / 1000000, 1) + 'mil';
      } else if (value >= 10000) {
        return NumberUtil.roundOff(value / 1000, 1) + 'k';
      }
      return value;
    };
    this.chartJsTicksStringEllipsisCallbackFn = function (value, index, values) {
      // truncate name of ticks in axis
      return StringUtil.truncateText(value, CHART_TICK_LABEL_MAX_LENGTH, 'N/A');
    };
    this.chartJsTooltipTruncateCallbackTitleFn = function (tooltipItem, cdata) {
      // truncate for tooltip
      const label = cdata.labels[tooltipItem[0].index];
      return StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
    };
  }
  async getSpeedingGraph(resultList) {

    const linearGradient = this.makeLinearCanvasGradient('speedingChart', Colors.RGBA_PRI1_SHARP_GREEN);

    const label = resultList.map(({ gpsTimeStamp }) => gpsTimeStamp);
    const data = resultList.map(({ speed }) => speed);

    const cOptions = {
      type: 'line',
      data: {
        labels: label,
        datasets: [{
          label: 'Speed',
          backgroundColor: linearGradient,
          borderColor: Colors.RGBA_PRI1_SHARP_GREEN,
          data: data,
          lineTension: 0,
          borderWidth: 1.2,
          pointBorderWidth: 1.2
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 70,
            bottom: -5
          }
        },
        tooltips: {
          // displayColors: false,
          ...this.chartJsTooltipStyles,
          // enabled: false,
          // mode: 'average'
          // position: 'nearest',
          // custom: customTooltips
          yAlign: 'bottom'
        },
        hover: this.chartJsHoverProps,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              padding: 5,
              ...this.chartJsTicksFontStyles,
              maxTicksLimit: 6,
              autoSkip: false,
              callback: this.chartJsTicksNumberTruncateCallbackFn
            },
            gridLines: {
              z: 1,
              display: true,
              lineWidth: 1,
              zeroLineWidth: 0,
              borderDash: [5, 4],
              color: Colors.RGBA_LIGHT_GRAY,
              tickMarkLength: 5
            },
            scaleLabel: {
              display: true,
              labelString: 'Speed (km/h)',
              ...this.chartJsScaleLabelFontStyles
            }
          }],
          xAxes: [{
            ticks: {
              padding: 5,
              ...this.chartJsTicksFontStyles,
              fontSize: 11,
              maxRotation: 20,
              maxTicksLimit: 5,
              autoSkip: false
            },
            gridLines: {
              z: 1,
              display: true,
              lineWidth: [3, 1, 1, 1, 1],
              zeroLineWidth: 3,
              color: [Colors.RGBA_LIGHT_GRAY, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE],
              zeroLineColor: Colors.RGBA_LIGHT_GRAY,
              tickMarkLength: 5
            },
            scaleLabel: {
              display: true,
              labelString: 'Date',
              ...this.chartJsScaleLabelFontStyles
            }
          }]
        },
        animation: this.chartJsAnimation
      }
    };

    const ctx = document.getElementById('speedingChart');
    this.speedingGraph = new Chart(ctx, cOptions);
    // const chart = new Chart(ctx, {
    //     // The type of chart we want to create
    //     type: 'line',

    //     // The data for our dataset
    //     data: {
    //         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //         datasets: [{
    //             label: 'My First dataset',
    //             backgroundColor: linearGradient,
    //             borderColor: Colors.RGBA_PRI1_SHARP_GREEN,
    //             data: [0, 10, 5, 2, 20, 30, 45],
    //             lineTension: 0,
    //             borderWidth: 1.2,
    //             pointBorderWidth: 1.2
    //         }]
    //     },

    //     // Configuration options go here
    //     options: {}
    // });
  }
  // ChartJS Helper: Create linear canvas gradient
  makeLinearCanvasGradient(canvasId: string, colorRgba: string, height: number = 200): CanvasGradient {
    // Render Gradient
    const ctx: CanvasRenderingContext2D = (<HTMLCanvasElement>this.el.nativeElement.querySelector(`#${canvasId}`)).getContext('2d');
    const cg: CanvasGradient = ctx.createLinearGradient(0, 0, 0, height);
    const rgb = DomUtil.getRgbaValues(colorRgba, false);
    cg.addColorStop(0, DomUtil.makeRgbaString(rgb.concat([0.9]))); //opacity 0.9
    cg.addColorStop(0.3, DomUtil.makeRgbaString(rgb.concat([0.9]))); //opacity 0.9
    cg.addColorStop(0.7, DomUtil.makeRgbaString(rgb.concat([0.5]))); //opacity 0.5
    cg.addColorStop(1, DomUtil.makeRgbaString(rgb.concat([0.2]))); //opacity 0.2
    return cg;
  }

  showSimpleMapPopup(popup, lat, lng) {
    this.simpleMapMarkerCoordinates.lat = lat;
    this.simpleMapMarkerCoordinates.lng = lng;
    this.popupService_parent.show(popup);
  }

  get detailedSpeedingReportMaxDate() {
    const maxDate = moment(this.fromDate).startOf('day').add(31, 'day');
    return this.fromDate && maxDate.isBefore(moment()) ?
      maxDate.format() :
      moment().startOf('day').format();
  }
}
