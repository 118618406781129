import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';

import { EmailService } from './../_services/email/email.service';

// Util
import * as moment from 'moment';
import * as LocalStorageUtil from './../util/localStorageUtil';
import * as ObjectUtil from './../util/objectUtil';
import * as StringUtil from './../util/stringUtil';
import * as NumberUtil from './../util/numberUtil';
import * as DomUtil from './../util/domUtil';
import * as ErrorUtil from './../util/errorUtil';
import * as EnvUtil from './../util/envUtil';
// import * as LocalStorageUtil from './../util/localStorageUtil';

import { ERROR_MESSAGE as ErrorMessage } from './../constants/errorMessage';

@Injectable()
export class ErrorHandlerModule {

    constructor(
        private emailService: EmailService,
        private platform: Platform,
        private device: Device,
        private appVersion: AppVersion
    ) { }

    /**
     * Returns error message string if prompt required, else returns null
     *
     * Send email for certain errors
     * @param error The error
     * @returns string | null
     */
    public handleStandardizedError(error: Error | HttpErrorResponse | any, alwaysReturnMessage: boolean = false): string {

        let message = null; //null = no need prompt
        let shouldSendEmail: boolean = false;
        let shouldPrompt: boolean = true;

        // swallow HTTPErrorAlreadyHandled error
        if (ErrorUtil.isHttpErrorAlreadyHandled(error)) {
            return message;
        }

        if (error.hasDisplayableError) {
            // Backend API Error: Show backend error code / message (always prompt)
            const errCode = error.error.errorCode;
            const errMsg = error.error.errorMessage || 'Error';
            message = ErrorMessage.getPromptErrorMessage(ErrorMessage.BACKEND_API_ERROR, [errCode, errMsg]);

        } else if (NumberUtil.isNumeric(error.status)) {
            // HttpErrorResponse: HTTP/Connection Error, Did not receive Backend error
            const errCode = error.status;
            const errMsg = (error.error ? error.error.message : null) || error.statusText || error.message;
            message = ErrorMessage.getPromptErrorMessage(ErrorMessage.WEB_HTTP_ERROR, [errCode, errMsg]);

            // Special: CORS Error or Unknown HTTP Error (captured by Angular)
            // - Could be backend server not running
            // - Could be CORS issue
            if (error.status == 0 || StringUtil.toCapsUnderscore(error.statusText) == 'UNKNOWN_ERROR') {
                message = ErrorMessage.getPromptErrorMessage(ErrorMessage.UNKNOWN_HTTP_ERROR);
            }

            // Assign Error Code (depreciated)
            // let errCode: string = 'WEB_HTTP_ERROR';
            // if (error.name) {
            //     // Append HTTP Status Text to Error Code
            //     if (error.name == 'HttpErrorResponse') {
            //         if (error.statusText) {
            //             errCode = 'WEB_HTTP_' + StringUtil.toCapsUnderscore(error.statusText);
            //         }
            //     } else {
            //         errCode = 'WEB_HTTP_' + StringUtil.toCapsUnderscore(error.name);
            //     }
            // }

            // controls whether to send email
            //     if (error.name == 'TimeoutError') {
            //         shouldSendEmail = true;
            //     }

            // prompt only if non production
            shouldPrompt = !environment.production;

        } else {
            // Other errors:
            // - Could be a front-end exception
            // - Could be a HTTP request exception
            // - Could be a non Error-instance object thrown from requestInterceptor
            shouldSendEmail = true;

            if (error instanceof Error) {

                // Custom Error Handling
                // 1. TimeOutError: Add Request URL & timeout duration to stacktrace
                if (StringUtil.toCapsUnderscore(error.name) == 'TIMEOUTERROR' || error.message.toUpperCase().includes('TIMEOUTERROR')) {
                    console.debug('TimeoutError: ' + error['request'].url);
                    error.stack = error.name + ': ' + error.message +
                        '\n    to ' + error['request'].url +
                        '\n    after ' + error['timeoutLimit'] + ' milliseconds';
                    message = ErrorMessage.getPromptErrorMessage(ErrorMessage.WEB_HTTP_TIMEOUT);
                    shouldSendEmail = false; //ignore timeout error
                } else {
                    console.debug('GenericError: Instance of Error: ' + error.stack);
                    message = ErrorMessage.getPromptErrorMessage(ErrorMessage.WEB_APP_ERROR_CAUGHT);
                }
            } else {
                console.debug('GenericError: Not an Instance of Error: ', error);
                // console.debug(JSON.stringify(error, null, 2));
                message = ErrorMessage.getPromptErrorMessage(ErrorMessage.WEB_APP_NONERROR_CAUGHT);
            }

            // Attach snackbar message info in error email
            // error.remarks = {
            //     errorCode: errCode,
            //     errorMessage: errMsg
            // };

            // prompt only if non production
            shouldPrompt = !environment.production;

        }

        // last gate
        if (!StringUtil.isNotEmptyOrNull(message)) {
            message = ErrorMessage.getDefaultErrorMessage();
        }

        // send email
        if (shouldSendEmail) {
            this.sendErrorEmail(error); // no need await
        }

        // debugger in DEV environment
        // if (EnvUtil.isDev()) {
        //     debugger;
        // }

        return (shouldPrompt || alwaysReturnMessage ? message : null);
    }

    public async sendErrorEmail(error: Error | HttpErrorResponse | any) {
        const isSendEmail: boolean = environment.appConfig.errorHandling.sendEmail;
        if (!isSendEmail) {
            return;
        }

        try {

            console.debug('ErrorHandlerModule: sendErrorEmail()');
            // alert(`EmailService Platforms: ${DomUtil.getDevicePlatforms(this.platform)}`);
            // console.debug(`Device Platforms: ${DomUtil.getDevicePlatforms(this.platform)}`);

            const subject = 'mtnt-web exception';
            let message = '';

            // Decide to send email base on Error type
            if (ErrorUtil.isError(error) && error.message.includes('cordova_not_available')) {
                console.debug('Email: Skip cordova_not_available exception');
                return; //skip cordova_not_available
            }

            // Decide to send email base on Config Settings
            let env: string = EnvUtil.getEnv();
            const urlHost: string = window.location.host;
            if (!DomUtil.isMobileApp(this.platform) && urlHost.toLowerCase().includes('localhost')) {
                env = 'LOCALHOST'; //localhost & dev sharing same config file
                if (!environment.appConfig.errorHandling.sendEmailForLocalhostErrors) {
                    console.debug('Email: Skip localhost exception');
                    return; //skip localhost exceptions
                }
            }

            /*** Basic information ***/
            message = 'Environment: ' + env + '<BR>' +
                // 'OS Host Name: ' + os.hostname() + '<BR>' +
                // 'Host IP: ' + ip.address() + '<BR>' +
                'Host Name: ' + window.location.hostname + '<BR>' +
                'Host URL: ' + window.location.host + '<BR>';

            /*** Error information ***/
            message += '<HR><BR>' +
                '<div style="margin-bottom:8px;"><span style="font-size: 1.5em;">' +
                '<U><B>Error information</B></U>' +
                '</span></div>' +
                'Date/Time: ' + moment().format('YYYY-MM-DD HH:mm:ss.SSS') + ' (GMT+8.00)<BR>';

            // Error info -> Add some user info
            const currentUser = LocalStorageUtil.localStorageGet('currentUser');
            if (currentUser) {
                message += 'Company ID: ' + currentUser.companyId + '<BR>' +
                    'User ID: ' + currentUser.userId + '<BR>' +
                    'Username: ' + currentUser.username + '<BR>' +
                    'Role ID: ' + currentUser.roleId + '<BR>';
            }

            // Error info -> Add snackbar message set by ErrorHandlerModule.handleError() & ErrorHandlerModule.handleStandardizedError()
            if (ObjectUtil.isObject(error) && error.remarks) {
                message += 'Snackbar Err Code: ' + error.remarks.errorCode + '<BR>' +
                    'Snackbar Err Message: ' + error.remarks.errorMessage + '<BR>';
            }

            // Error info -> Add error details
            let errorDetails = '';
            if (ErrorUtil.isError(error)) {
                if (error.stack) {
                    // use error stack trace
                    errorDetails = StringUtil.makeHtmlFriendly(error.stack);
                } else {
                    // use error name + error message
                    errorDetails = StringUtil.makeHtmlFriendly(error.name + ': ' + error.message);
                }
            } else {
                // use stringified error object
                errorDetails = StringUtil.makeHtmlFriendly(error);
            }
            message += 'Error Details: <BR>'
                + '<BR>'
                + '<pre>' + errorDetails + '</pre><BR>';

            // Error info -> Add Request info set by RequestInterceptor ErrorHandler
            if (ObjectUtil.isObject(error) && error.request) {
                const httpRequest = {
                    method: error.request.method,
                    urlWithParams: error.request.urlWithParams,
                    // headers: error.request.headers,
                    // params: error.request.params,
                    body: error.request.body
                };
                message += 'Intercepted HTTP Request: <BR>'
                    + '<pre>' + StringUtil.makeHtmlFriendly(httpRequest) + '</pre><BR>';
            }

            /*** Client information ***/
            message += '<HR><BR>' +
                '<div style="margin-bottom:8px;"><span style="font-size: 1.5em;">' +
                '<U><B>Client information</B></U>' +
                '</span></div>' +
                'Device Platforms: ' + DomUtil.getDevicePlatforms(this.platform) + '<BR>';

            const _this = this;
            const emailResp = await new Promise((resolve, reject) => {
                (async function () {
                    if (DomUtil.isMobileApp(_this.platform)) {
                        const appInfo = await DomUtil.getMobileAppInfo(_this.platform, _this.appVersion);
                        const deviceInfo = await DomUtil.getMobileDeviceInfo(_this.platform, _this.device);

                        // Error info -> Add mobile details
                        message += '<BR>' +
                            '<div style="margin-bottom:5px;"><span style="font-size: 1.2em;">' +
                            '<B>Mobile App details:</B>' +
                            '</span></div>' +
                            'Mobile App URL Address: ' + window.location.href + '<BR>' +
                            '<pre>' + StringUtil.makeHtmlFriendly(appInfo) + '</pre><BR>' +
                            '<div style="margin-bottom:5px;"><span style="font-size: 1.2em;">' +
                            '<B>Mobile Device details:</B>' +
                            '</span></div>' +
                            '<pre>' + StringUtil.makeHtmlFriendly(deviceInfo) + '</pre><BR>';
                        return message;
                    } else {
                        // Error info -> Add browser details
                        message += '<BR>' +
                            '<span style="font-size: 1.2em;"><B>Browser details:</B></span><BR>' +
                            'mDrive Web Version: ' + environment.systemInfo.version + '<BR>' +
                            'Browser URL Address: ' + window.location.href + '<BR>' +
                            'Browser CodeName: ' + navigator.appCodeName + '<BR>' +
                            'Browser Name: ' + navigator.appName + '<BR>' +
                            'Browser Version: ' + navigator.appVersion + '<BR>' +
                            'Cookies Enabled: ' + navigator.cookieEnabled + '<BR>' +
                            'Browser Language: ' + navigator.language + '<BR>' +
                            'Browser Online: ' + navigator.onLine + '<BR>' +
                            'Platform: ' + navigator.platform + '<BR>' +
                            'User-agent header: ' + navigator.userAgent + '<BR>';
                        return message;
                    }
                })().then(function (finalMsg: string) {
                    finalMsg += '<BR><HR>';
                    // finalMsg += '<BR>----------END----------<BR>';
                    // _this.sendEmailViaPostfix(recipient, ccRecipient, subject, finalMsg);
                    _this.emailService.sendEmailViaPostfix(subject, finalMsg);
                    resolve(null);
                }).catch(err => {
                    reject(err);
                });

                // finaliseExceptionEmail(message: string, errorDetails: string, recipient, ccRecipient, subject) {
                //     message += 'Error Details: <BR>'
                //         + '<BR>'
                //         + '<pre>' + errorDetails + '</pre><BR>';
                //     this.sendEmailViaPostfix(recipient, ccRecipient, subject, message);
                //     console.debug('Email: Exception details sent');
                // }

                // getSourceMappedStackTrace(error: Error, callback) {
                //     mapStackTrace(error.stack, { isChromeOrEdge: true })
                //         .then(function (newTrace) {
                //             callback(error.name + ': ' + error.message + '\n' + newTrace);
                //         })
                //         .catch((err) => {
                //             // do nothing
                //             callback(null);
                //         });
                // }

            });
        } catch (err) {
            // do nothing
        }

    }

}
