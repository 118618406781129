<app-spinner #page_spinner_nav [fullscreen]="true"></app-spinner>
<!--
    <app-spinner #page_spinner [fullscreen]="true"></app-spinner>
    <span class="debugMsg">{{mapModel.visibleComponent + ' blocked: ' + blockedMsgBusByMessageSource.join(',') + (_momLastBlockedTime ? _momLastBlockedTime.format('HH:mm:ss:SSS') : '')}}</span>
-->

<div *ngIf="searchFiltersIsActive" class="map__utils">
    <div class="map__utils-inner">
        <div id="searchBox" class="map__search">
            <input type="search" placeholder="Search by name" class="map__search-field" [(ngModel)]="filtersModel.vehicleName" (ngModelChange)="applyFilters()">

            <button type="button" title="Search" class="map__search-submit"></button>
        </div>

        <div id="filters" class="map__filters" [class.--active]="filtersModel.isShown" [class.--animate-out]="filtersModel.isAnimatingOut">
            <button type="button" title="Filter" class="map__filters-toggle" [class.--active]="filtersModel.isShown" (click)="toggleFilters()"></button>
            <div class="map__filters-menu">
                <div class="map__filters-menu-inner">
                    <button type="button" title="Close filter" class="map__filters-menu-close" (click)="hideFilters()"></button>

                    <div class="map__filters-menu-heading">
                        Status
                    </div>

                    <div class="map__filters-menu-options">
                        <div class="map__filters-menu-option" *ngFor="let status of STATUSES; let i = index;">
                            <label class="map__filters-inline-input">
                                <input type="checkbox" name="status" value="{{ i }}" class="ha   map__filters-inline-input-elem" [(ngModel)]="filtersModel.statuses[status]" (ngModelChange)="applyFilters()">

                                <span class="map__filters-inline-input-icon" [ngStyle]="{ 'color': STATUS[status.toLowerCase()].COLOR }"></span>

                                {{ STATUS[status].LABEL }}
                            </label>
                        </div>
                    </div>

                    <div class="map__filters-menu-heading">
                        Groups
                    </div>

                    <div class="map__filters-menu-options">
                        <div class="map__filters-menu-option" *ngFor="let group of filtersModel.groups; let i = index;">
                            <label class="map__filters-inline-input">
                                <input type="checkbox" name="group.name" value="{{ i }}" class="ha   map__filters-inline-input-elem" [(ngModel)]="group.status" (ngModelChange)="applyFilters()">

                                <span class="map__filters-inline-input-icon" [ngStyle]="{ 'color': group.colour }"></span>

                                {{ group.name }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map" class="map__canvas" [class.--triplist-is-shown]="triplistIsShown" [class.--search-filters-is-inactive]="!searchFiltersIsActive" [class.--vehiclemain-is-active]="vehiclemainIsActive" [class.--trip-day-item-single-view-mode]="tripDayItemSingleViewMode"></div>

<!-- For FAB button -->
<button *ngIf="fabIsRequired" type="button" class="map__fab" [class.--active]="listIsShown" (click)="toggleList()" @fadeToggle></button>

<!-- For Auto update button -->
<button style="display: none;" type="button" class="map__auto-update" (click)="toggleAutoUpdate()" id="btnAutoUpdate">
    Auto Update

    <ng-container *ngIf="mapModel.isOnAutoUpdate">
        ON
    </ng-container>

    <ng-container *ngIf="!mapModel.isOnAutoUpdate">
        OFF
    </ng-container>
</button>

<!-- For Toggle traffic layer button -->
<button style="display: none;" type="button" (click)="toggleTraffic()" id="btnTrafficToggler" [class.--active]="mapModel.isOnTrafficLayer"></button>

<!-- For Map legend -->
<div style="display: none;" id="legend">
    <div class="legend-list">
        <div class="legend-item">
            <img class="legend-item-icon" src="assets/images/ng-components/map/nogps_new.png">
            <span class="legend-item-alt">Poor GPS</span>
        </div>
        <div class="legend-item">
            <img class="legend-item-icon" src="assets/images/ng-components/map/speeding_new.png">
            <span class="legend-item-alt">Speeding</span>
        </div>
        <div class="legend-item">
            <img class="legend-item-icon" src="assets/images/ng-components/map/oriroute_new.png">
            <span class="legend-item-alt">Original Route</span>
        </div>
    </div>
</div>

<!-- For Vehicle Marker -->
<div style="display: none;" id="mapItemMarkerTemplate">

    <div class="map__item-marker" style="color: [[ color ]]; z-index: [[ zindex ]]">
        <div class="map__item-marker-image" style="background-image: [[ imageStyle ]]">
            <div class="map__item-marker-alert" style="background: [[ alertImageStyle ]]"></div>
        </div>
        <div title="[[ heading ]]" class="map__item-marker-label">
            <div class="map__item-marker-label-inner">
                [[ heading ]]
            </div>
        </div>
    </div>
</div>

<!-- For Alert Marker -->
<div style="display: none; position: relative">
    <div class="map-alert-floater floater --active" id="alertMarkerTemplate">
        <button type="button" class="floater__closer" (click)="closePopUp()"></button>

        <div class="map-alert-floater-header">
            [[ title ]]
            <!--Harsh Braking-->
        </div>

        <div class="map-alert-floater-main">
            <div class="map-alert-floater-block">
                <div class="map-alert-floater-label">
                    Speed
                </div>

                <div class="map-alert-floater-value">
                    [[ speed ]]
                    <!--120km/h-->
                </div>
            </div>

            <div class="map-alert-floater-block">
                <div class="map-alert-floater-label">
                    Time
                </div>

                <div class="map-alert-floater-value">
                    [[ time ]]
                    <!--08:30PM-->
                </div>
            </div>
        </div>

        <div class="map-alert-floater-footer">
            [[ address ]]
            <!--18, Jalan Dutamas Raya, Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur 51200-->
        </div>
    </div>
</div>
<div id="map__mq"></div>