<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main manage-device">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@lg --space-top">
                    <h1 class="heading --level-1">
                        Manage Order and Devices
                    </h1>
                </div>

                <div class="grid__block w-8of12@lg heading-buttons-field --space-top" [hidden]="currentTab !== 1">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="showPopup(batchImportPopup); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/scss-components/key-value-icons/icon-batch-import.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Bulk Import
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Bulk Import
                                    </div>

                                    <img src="assets/images/scss-components/key-value-icons/icon-mobile-batch-import.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isEditingMultipleDevices = true; toggleMobileOpen = false" *ngIf="editable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/scss-components/key-value-icons/icon-edit-multiple.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Edit Multiple
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Edit Multiple
                                    </div>

                                    <img src="assets/images/scss-components/key-value-icons/icon-mobile-edit-multiple.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="navigateToAddDevice(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Add Order
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Add Order
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingDevice = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Device
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Device
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section   bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="overview()">Overview</button>
                <!-- <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="todo()">To-do</button> -->
            </div>
        </div>
    </div>

    <div class="tabs__content" [hidden]="currentTab !== 1">
        <div class="section">
            <div class="wrapper">
                <div class="block --divider">
                    <div class="grid --gutter">
                        <div class="grid__block w-6of12@md search__group">
                            <div class="field-group --unstack@xs search__select-field">
                                <div class="field-group__item search__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                    <label class="select --grey">
                                        <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                            <option value="company">Company</option>
                                            <option value="vehicle">Vehicle</option>
                                            <option value="imei">IMEI No</option>
                                        </select>
                                    </label>
                                </div>

                                <div class="field-group__item search__type-field-group-input">
                                    <div class="field">
                                        <div class="field__elems">
                                            <div class="field__icon">
                                                <div class="field__icon-inner">
                                                    <img src="assets/images/scss-components/key-value-icons/icon-search-company.svg" alt="" class="field__icon-image">
                                                </div>
                                            </div>

                                            <label class="field__holder">
                                                <input id="searchBox" type="text" autocomplete="off" class="field__input" [placeholder]="getPlaceHolderText()" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchCompany()">
                                            </label>

                                            <div class="field__ui"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="button__holder">
                                    <button type="button" class="button" (click)="searchCompany()">SEARCH</button>
                                </div>

                                <button class="button download-button download-mobile" (click)="showPopup(downloadsPopup)">
                                    <img src="assets/images/scss-components/key-value-icons/icon-download.svg" alt="" class="download-button__icon">

                                    <div class="download-button__desc">
                                        Download
                                    </div>
                                </button>
                            </div>

                        </div>

                        <div class="grid__block w-6of12@sm download-desktop">
                            <button class="button download-button" (click)="showPopup(downloadsPopup)">
                                <img src="assets/images/scss-components/key-value-icons/icon-download.svg" alt="" class="download-button__icon">

                                <div class="download-button__desc">
                                    Download
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="mobile-table-scroll-notice">
                        <div class="mobile-table-scroll-notice__icon"></div>

                        <div class="mobile-table-scroll-notice__main">
                            Please scroll left/right to browse the table.
                        </div>
                    </div>
                </div>

                <div class="block block__md-tag">
                    <ul class="md-tag">
                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.filterProgress == 'inProgress'" (click)="getRegStatus('inProgress')">
                                In progress
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.filterProgress == 'installed'" (click)="getRegStatus('installed')">
                                Installed
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.filterProgress == 'completed'" (click)="getRegStatus('completed')">
                                Completed
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.filterProgress == 'deactivated'" (click)="getRegStatus('deactivated')">
                                Deactivated
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.filterProgress == 'suspended'" (click)="getRegStatus('suspended')">
                                Suspended
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.filterProgress == 'terminated'" (click)="getRegStatus('terminated')">
                                Terminated
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.currentInstallationType == 'WIRED'" (click)="getInstallationType('WIRED')">
                                Wired
                            </button>
                        </li>

                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.currentInstallationType == 'PLUG_PLAY'" (click)="getInstallationType('PLUG_PLAY')">
                                Plug &amp; Play
                            </button>
                        </li>

                        <!-- <li>
                            <button class="button --outline --tag" [class.--selected]="this.currentInstallationType == 'PLUG_PLAY_COURIER'" (click)="getInstallationType('PLUG_PLAY_COURIER')">
                                Plug &amp; Play Courier
                            </button>
                        </li> -->


                        <li>
                            <button class="button --outline --tag" [class.--selected]="this.currentInstallationType == 'CUSTOM'" (click)="getInstallationType('CUSTOM')">
                                Customised
                            </button>
                        </li>
                    </ul>
                </div>

                <div class="block">
                    <div class="table table__manage-device" (scroll)="onOverviewTableScroll()" [class.--scrolled-to-end]="overviewTableScrolledToEnd" [class.--isEditing]="isEditingMultipleDevices || isDeletingDevice" #overviewTable>
                        <table class="table__elem">
                            <thead>
                                <tr>
                                    <ng-container *ngIf="isEditingMultipleDevices || isDeletingDevice">
                                        <th scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedMaxisDeviceGroups['all']" (ngModelChange)="onMaxisDeviceAllSelectChange()" (click)="checkDeviceAllSelect($event)">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </th>
                                    </ng-container>
                                    <th scope="col" *ngIf="filterProgress === 'terminated'">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('dateUpdated')" (click)="sort('dateUpdated')">Termination Date</button>
                                    </th>
                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('submitDate')" (click)="sort('submitDate')">Submit Date</button>
                                    </th>
                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('provider')" (click)="sort('provider')">Provider</button>
                                    </th>
                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('plateNo')" (click)="sort('plateNo')">Plate No</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('companyName')" (click)="sort('companyName')">Company Name</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('brn')" (click)="sort('brn')">BRN</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('rfDate')" (click)="sort('rfDate')">RF Date</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('salesChannelType')" (click)="sort('salesChannelType')">Channel</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('salesChannelName')" (click)="sort('salesChannelName')">Channel Name</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('region')" (click)="sort('region')">Region</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('imeiNo')" (click)="sort('imeiNo')">IMEI</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('iccid')" (click)="sort('iccid')">ICCID</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('cmssId')" (click)="sort('cmssId')">Opportunity ID</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('mes')" (click)="sort('mes')">MES</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('billingDate')" (click)="sort('billingDate')">Billing Date</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('replacementCaseNo')" (click)="sort('replacementCaseNo')">Replacement Case</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('activationDate')" (click)="sort('activationDate')">Activation Date</button>
                                    </th>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('installationDate')" (click)="sort('installationDate')">Installation Date</button>
                                    </th>

                                    <th scope="col" class="sticky-col --first-col">
                                        Progress
                                    </th>

                                    <th scope="col" class="sticky-col --second-col">
                                        Action
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let mdOverview of devicesList; let i = index;">
                                    <ng-container *ngIf="isEditingMultipleDevices || isDeletingDevice">
                                        <td scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" [name]="mdOverview.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedMaxisDevice[mdOverview.vehicleId]" (ngModelChange)="onMaxisDeviceSelectChange($event)" (click)="checkDeviceSelect($event, mdOverview.vehicleId)">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>
                                    </ng-container>
                                    <td *ngIf="filterProgress === 'terminated'">
                                        {{ mdOverview.dateUpdated || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.submitDate || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.provider || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.plateNo || '-' }}
                                    </td>
                                    <td>
                                        {{ mdOverview.companyName || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.brn || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.rfDate || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.salesChannelType || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.salesChannelName || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.region || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.imeiNo || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.iccid || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.cmssId || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.mes || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.billingDate || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.replacementCaseNo || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.activationDate || '-'}}
                                    </td>
                                    <td>
                                        {{ mdOverview.installationDate || '-'}}
                                    </td>
                                    <td class="sticky-col --first-col">
                                        <div class="progress-status" [ngClass]="{
                                                    '--in-progress': mdOverview.regStatus == 'SCHEDULED' || mdOverview.regStatus == 'PENDING',
                                                    '--activated': mdOverview.regStatus == 'ACTIVATED',
                                                    '--completed': mdOverview.regStatus == 'COMPLETED' || !mdOverview.regStatus || mdOverview.regStatus == 'DEACTIVATED',
                                                    '--pending': mdOverview.regStatus=='RESCHEDULE' || mdOverview.regStatus == 'REQ_NEW_IMEI'
                                                                                    }"></div>

                                    </td>
                                    <td class="sticky-col --second-col" [ngStyle]="{ 'zIndex': toggleMdList[i].isActive ? '1' : '' }">
                                        <div class="md-action__box">
                                            <button class="btn-md-action" (click)="toggleFunction(i)"></button>

                                            <div *ngIf="toggleMdList[i].isActive">
                                                <ng-container *ngTemplateOutlet="list; context: { $implicit: toggleMdList[i].children, idx: i }"></ng-container>
                                            </div>
                                        </div>

                                        <ng-template #list let-allItems let-idx="idx">
                                            <ul class="md-action-list">
                                                <li *ngFor="let toggle of allItems">
                                                    <button class="btn-md-action__list" (click)="actionTriggered(toggle.value, i)">{{toggle.data}}</button>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="block" *ngIf="devicesList.length > 0">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>
                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination">
                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getAllDevices(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getAllDevices(page)">
                                    {{ page }}
                                </button>

                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getAllDevices(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="edit-dialog" @fadeToggle *ngIf="isEditingMultipleDevices">
    <div class="wrapper">
        <div class="edit-dialog__field">
            <div class="edit-dialog__total">
                You have selected {{countSelectedMaxisDevice()}} vehicle(s)
            </div>

            <div class="edit-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="navigateToEditDevice()" [disabled]="countSelectedMaxisDevice()==0">
                        <img src="assets/images/ng-components/create-manage/icon-edit-multiple-white.svg" alt="" width="18" height="18" class="inline-button__icon">

                        <div class="inline-button__input">
                            Edit Info
                        </div>
                    </button>

                    <!-- <button class="button inline-button__inputs" type="button" (click)="showPopup(raiseIssuePopup)" [disabled]="countSelectedMaxisDevice()==0">
                        <img src="assets/images/ng-components/create-manage/icon-raise-issue-white.svg" alt="" width="20" height="20" class="inline-button__icon">

                        <div class="inline-button__input">
                            Raise Issue
                        </div>
                    </button> -->

                    <button class="button inline-button__inputs --dark-grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingDevice">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedMaxisDevice()}} vehicle(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="checkIsAbleDelete()" [disabled]="countSelectedMaxisDevice() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteDevicePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedMaxisDevice()}}</span> vehicle(s)
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteDevice();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #singleDeleteDevicePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteDevice();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #raiseIssuePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Raise Issue
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p class="desc">
            A ticket will be sent to the relevant department
        </p>

        <p class="notes">Request:
            <!-- *All maxis supports' access will be revoked. -->
            <label class="select" style="width: 300px">
                <select class="select__input" name="requestCategory" #requestCategory="ngModel" [(ngModel)]="selectedRequest" required>
                    <option *ngFor="let item of requestList; let position = index;" [value]="position">{{item}}</option>
                </select>
            </label>
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="raiseIssue();hidePopup();">
                SUBMIT
            </button>

            <button class="button inline-button__inputs --dark-grey" type="button" (click)="hidePopup();">
                BACK
            </button>
        </div>
    </div>
</app-popup>

<app-popup #unableEditPopup="popup" class="cm-popups" id="unableEditPopup">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Unable to Edit
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p class="desc">
            You can only edit multiple vehicles if they belong to the <strong>same company</strong>
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                RESELECT VEHICLES
            </button>
        </div>
    </div>
</app-popup>

<app-popup #unableEditDeletePopup="popup" class="cm-popups" id="unableEditPopup">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Unable to Edit/Delete
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p class="desc">
            You can only edit/delete multiple vehicles if they haven't been activated.
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                RESELECT VEHICLES
            </button>
        </div>
    </div>
</app-popup>


<!-- Import pop up-->
<app-popup #batchImportPopup="popup" class="cm-popups --batch-import">
    <div class="popup__block">
        <div class="popup__block-field">
            <div class="cm-heading__main">
                <div class="cm-heading__text">
                    Bulk Import
                </div>
            </div>
        </div>

        <div class="popup__block-field">
            <div class="inline-button__field">
                <label>
                    <span class="button inline-button__inputs" [ngClass]="'--' + browseBtnColor">
                        Browse
                    </span>
                    <input type="file" id="myFileInput" (change)="batchImport($event)" accept=".xlsx, .xls, .csv" />
                </label>
            </div>

            <span class="notes-input">( Max File Size: {{batchFileSize}}MB )</span>
        </div>
        <div class="popup__block">
            <p class="notes">
                <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
                <span class="notes__text">
                    Bulk Import template (.xls) can be downloaded
                    <a class="link" (click)="generateExcelTemplate()">here</a>
                </span>
            </p>
        </div>
    </div>
</app-popup>

<!-- Failed Read File pop up-->
<app-popup #batchImportErrorPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert" [innerHTML]="batchErrorMessage"></span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(batchImportPopup);">
                OK
            </button>
        </div>
    </div>
    <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
            <span class="notes__text">
                Bulk Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div>
</app-popup>

<!-- Sucess Read File pop up-->
<app-popup #batchImportPreviewPopup="popup" class="cm-popups --batch-import">
    <div class="popup__block ta-l preview">
        <div class="popup__block-field">
            <h1 class="heading indicator-green">Bulk Import Preview</h1>
        </div>

        <div class="popup__block-field">
            <div class="grid --gutter-xspace">
                <div class="grid__block-xspace w-6of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Document uploaded
                        </div>

                        <div class="preview__result">
                            {{ fileName }}
                        </div>
                    </div>
                </div>

                <div class="grid__block-xspace w-3of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Entries verified
                        </div>

                        <div class="preview__result --text-big   indicator-green ">
                            {{ validDataList.length }}/{{ totalImportRecords }}
                        </div>
                    </div>
                </div>

                <div class="grid__block-xspace w-3of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Entries needing attention
                        </div>

                        <div class="preview__result --text-big   indicator-red">
                            {{ invalidDataList.length}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup__block-field" *ngIf="!dataRecordsHasError">
            <div class="preview__notes --if-valid">
                <div class="preview__notes-icon">
                    <i class="gfx-icons --valid"></i>
                </div>

                <div class="preview__notes-desc">
                    <p class="preview__notes-desc-notification">
                        All data entries are <span class="fw-600">valid!</span>
                    </p>

                    <p>
                        Please click Submit to upload.
                    </p>
                </div>
            </div>
        </div>

        <div class="popup__block-field" *ngIf="dataRecordsHasError">
            <div class="preview__notes --if-invalid">
                <div class="preview__notes-icon">
                    <i class="gfx-icons --invalid"></i>
                </div>

                <div class="preview__notes-desc">
                    <p class="preview__notes-desc-notification">
                        Some data entries are <span class="fw-600">invalid!</span>
                    </p>

                    <p>
                        Please download and review the invalid entries. You may still click Submit to upload ONLY the valid data.
                    </p>

                    <p class="fs-i">
                        * Please download the invalid data before you proceed with submit.
                    </p>
                </div>

                <div class="preview__notes-download">
                    <button class="button --red --min-width" type="button" (click)="generateExcelTemplate(false);isDownloadDone = true">
                        Download
                    </button>
                </div>
            </div>
        </div>

        <div class="popup__block-field">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" [disabled]="!isDownloadDone && invalidDataList.length > 0" (click)="showPopup(batchImportConfirmPopup)">
                    Submit
                </button>

                <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</app-popup>

<!-- Submit Confirmation pop up-->
<app-popup #batchImportConfirmPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            {{ validDataList.length }} Data(s) Entry will be created.
        </p>

        <p class="notes"></p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="batchCreateDataEntry();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #downloadsPopup="popup">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="btnExcel()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnCsv()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnPdf()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>

<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
