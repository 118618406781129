import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';
import * as Message from './../../../../constants/message';
import * as Moment from 'moment';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { DriverService } from './../../../../_services/driver/driver.service';
import { PopupService } from '../../../../components/common/popup/popup.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as DomUtil from '../../../../util/domUtil';
import * as ImageUtil from '../../../../util/imageUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';
import * as StringUtil from '../../../../util/stringUtil';
import * as NumberUtil from '../../../../util/numberUtil';

// Constant
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';import * as PasswordUtil from './../../../../util/passwordUtil';
import { UserService } from './../../../../_services/user/user.service';
@Component({
    selector: 'app-create-driver',
    templateUrl: './create-driver.component.html',
    styleUrls: ['./create-driver.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CreateDriverComponent implements OnInit {
    newDriverDetails = {
        firstName: '',
        lastName: '',
        telNo: '',
        drivingLicenseNo: '',
        // drivingLicenseExpiryDate: moment().format("YYYY-MM-DD"),
        drivingLicenseExpiryDate: null,
        licenseExpReminderDays: 30,
        licenseDaysApplyAll: false,
        email: '',
        photo: null,
        driverTagId: null,
        remindMinute: 5,
        driverTagRemindApplyAll: false
    };
    minDate: any = Moment().format();
    message = Message;

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    @ViewChild('imageAlertPopup',{static:false}) imageAlertPopup;
    isImage: boolean = false;
    maxSizeMB = environment.appConfig.createManage.maxSizeMB;
    maxWidthOrHeight = environment.appConfig.createManage.maxWidthOrHeight;
    maxSizeImageMB = environment.appConfig.createManage.maxSizeImageMB;
    imageAlertError = null;

    @ViewChild('createCropImagePopup',{static:false}) createCropImagePopup;
    @ViewChild(ImageCropperComponent,{static:false}) imageCropper: ImageCropperComponent;
    @ViewChild("cropImage_spinner",{static:false}) cropImage_spinner: SpinnerComponent;
    imageChangedEvent: any = '';
    croppedImageData: any = '';

    //date picket touch ui
    datepickerTouchUi: boolean = false;
    lastResizeTime: any = new Date(0);
    resizeTimeout: any;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    formModel: any = {
        oldPassword: '',
        password: '',
        passwordStrength: '',
        confirmPassword: ''
    };

    passwordValidation: any = {
        hasError: false,
        isMatch: true,
        isEnoughLength: true,
        isOneUpperChar: true,
        isOneLowerChar: true,
        isOneNumber: true
    };

    @ViewChild('passwordPolicyPopup',{static:false}) passwordPolicyPopup;
    @ViewChild('passwordChangePopup',{static:false}) passwordChangePopup;
    @ViewChild('driverForm',{static:false}) driverForm;

    error = '';

    // Drivers Tag Selector/Floater
    @ViewChild('driverTagField', { static: false }) driverTagField;
    driverTagList: Array<any> = [];
    driverTagModel: any = {};
    selectedDriverTag: any = {};
    selectedDriverTagGroups: any = {};
    selectedDriverTagList: any = [];
    driverTagSelectedLabel: string;

    // General Selector Filter Selection when Typing
    subjectSelectionFloaterIsShown: boolean = false;
    filterSearchString: string = ""; //store filter search string
    
    constructor(
        private platform: Platform,
        private userService: UserService,
        private popupService: PopupService,
        private driverService: DriverService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        this.updateDatepickerTouchUi();
        DomUtil.autoFocusForm(this.el);
        this.checkUnviewedCampaign();
        await this.getAllDriverTags();

    }

    async getAllDriverTags() {
        this.pageSpinner.show();
        try {
            const driverTagResult = await this.driverService.getDriverTagListByCompanyIdAndUserId(0);
            this.driverTagModel = driverTagResult.body || {};
            this.driverTagList = driverTagResult.body.driverTags.slice(0);
            this.toggleSubjectSelectionFloater(false);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    navigateSuccessToDriverView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/drivers/view']);
    }

    navigateToDriverView() {
        this.routerProxyService.navigate(['/create-manage/drivers/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async createDriver() {
        this.pageSpinner.show();
        try {
            // Set Image
            let newImage;
            if (this.isImage) {
                const img: any = <HTMLElement>document.getElementById("imageId");
                if (img) {
                    newImage = await ImageUtil.compressImageSize(img.src, this.maxSizeMB, this.maxWidthOrHeight, this.platform); // maxSizeMB, maxWidthOrHeight are optional
                    await AsyncUtil.wait(2000); // Wait for image compress
                } else {
                    newImage = null;
                }
                // Invalid image -> always update to blank
                if (!newImage) {
                    this.newDriverDetails.photo = '';
                } else {
                    this.newDriverDetails.photo = newImage;
                }
            }

            if (Moment(this.newDriverDetails.drivingLicenseExpiryDate).isValid()) {
                this.newDriverDetails.drivingLicenseExpiryDate = Moment(this.newDriverDetails.drivingLicenseExpiryDate).format("YYYY-MM-DD");
            }
            if (!StringUtil.isNotEmptyOrNull(this.newDriverDetails.licenseExpReminderDays)) {
                this.newDriverDetails.licenseExpReminderDays = 30;
            } else {
                this.newDriverDetails.licenseExpReminderDays = Number(this.newDriverDetails.licenseExpReminderDays);
            }
            if (this.newDriverDetails.licenseDaysApplyAll) {
                if (!StringUtil.isNotEmptyOrNull(this.newDriverDetails.licenseExpReminderDays)) {
                    this.newDriverDetails.licenseExpReminderDays = 30;
                } else {
                    this.newDriverDetails.licenseExpReminderDays = Number(this.newDriverDetails.licenseExpReminderDays);
                }
            }

            for (const objKey in this.newDriverDetails) {
                if (this.newDriverDetails[objKey] == '' || this.newDriverDetails[objKey] == null || this.newDriverDetails[objKey] == undefined) {
                    delete this.newDriverDetails[objKey];
                }
            }

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.newDriverDetails.firstName)) {
                this.newDriverDetails.firstName = this.newDriverDetails.firstName.trim();
            }
            if (StringUtil.isNotEmptyOrNull(this.newDriverDetails.lastName)) {
                this.newDriverDetails.lastName = this.newDriverDetails.lastName.trim();
            }

            if (this.newDriverDetails.driverTagId) {
                this.newDriverDetails.driverTagId = Number(this.newDriverDetails.driverTagId);
            }

            if (this.newDriverDetails.remindMinute) {
                this.newDriverDetails.remindMinute = Number(this.newDriverDetails.remindMinute);
            } else {
                //default 5 minutes
                this.newDriverDetails.remindMinute = 5;
            }
            const driverDetails = [];
            driverDetails.push(this.newDriverDetails)
            const result = await this.driverService.createDriver(driverDetails);

            const driverName = this.newDriverDetails.firstName + ' ' + this.newDriverDetails.lastName;

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Driver', driverName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Driver', driverName);
                this.navigateSuccessToDriverView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }

    updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

    async chooseImage(item: any) {
        if (item.target.files.length > 0 && item.target.files[0].type.indexOf("image") > -1) {
            const imageFile = item.target.files[0];

            if ((imageFile.size / 1024 / 1024) < this.maxSizeImageMB) { //Check Image Size

                this.isImage = true;
                this.imageChangedEvent = item;
                this.showPopup(this.createCropImagePopup);

            } else {
                this.imageAlertError = 'sizeError';
                this.showPopup(this.imageAlertPopup);
            }
        } else {
            this.imageAlertError = 'fileError';
            this.showPopup(this.imageAlertPopup);
        }
    }

    confirmCropImage() {
        const img: any = <HTMLElement>document.getElementById("imageId");
        img.src = this.croppedImageData;
    }

    imageDirection(direction) {
        try {
            this.cropImage_spinner.show();
            if (direction === 'left') {
                this.imageCropper.rotateLeft();
            } else if (direction === 'right') {
                this.imageCropper.rotateRight();
            }
        } catch (e) {
            // do nothing
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImageData = event.base64;
        const img: any = <HTMLElement>document.getElementById("imageId");
        img.src = this.croppedImageData;
    }

    cropperReady() {
        const fileInput: any = document.getElementById('myFileInput');
        if (fileInput && fileInput.value) { fileInput.value = null; } // Clean previous file
        this.cropImage_spinner.hide();
    }

    populateRemindMinute() {
        for (let i = 0; i < this.driverTagList.length; i++ ) {
            if (this.driverTagList[i].driverTagId === Number(this.newDriverDetails.driverTagId)) {
                this.newDriverDetails.remindMinute = this.driverTagList[i].remindMinute;
                break;
            }
        }
    }

    validatePassword() {
        this.passwordValidation = PasswordUtil.passwordValidator(this.formModel, this.passwordValidation);
    }

    onStrengthChanged($event) {
        if ($event === 20) {
            this.formModel.passwordStrength = 'WEAK';
        } else if ($event === 40) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 60) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 80) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 100) {
            this.formModel.passwordStrength = 'STRONG';
        }
    }

    async changePassword() {
        if (this.formModel.password.length !== 0 && this.formModel.confirmPassword.length !== 0) {
            this.validatePassword();
            if (this.passwordValidation.hasError) {
                this.showPopup(this.passwordPolicyPopup);
            } else {
                try {
                    const result = await this.userService.checkAndChangePassword(this.formModel.oldPassword, this.formModel.password);

                    this.message = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Password');
                    if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                        this.message = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Password');
                    }
                    this.snackBar.openGenericSnackBar(this.message);
                    // this.showPopup(this.passwordChangePopup);
                } catch (e) {
                    this.snackBar.openStandardizedErrorSnackBar(e);
                    // this.error = error.error.errorMessage;
                }
            }
        }
    }

    reload() {
        this.formModel = {
            oldPassword: '',
            password: '',
            passwordStrength: '',
            confirmPassword: ''
        };
        this.driverForm.reset();
    }

    /* ---- Driver Tag Selection Floater ---- */
    onDriverTagAllSelectChange() {

        // Note: Apply to ALL driver tag that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedDriverTagGroups.all;
        for (let i = 0; i < this.driverTagList.length; i++) {
            this.selectedDriverTag[this.driverTagList[i].driverTagId] = allIsSelected;
        }
        this.updateDriverTagSelectedLabel();
        this.focusDriverTagSearchBox();
    }
    onDriverTagSelectChange() {
        this.updateDriverTagSelectedLabel();
        this.focusDriverTagSearchBox();
        this.populateRemindMinute();
        this.toggleSubjectSelectionFloater(false);
    }
    private focusDriverTagSearchBox() {
        const searchBox = this.getDriverTagSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private getDriverTagSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.driverTagField.nativeElement.querySelector("#driverTagSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }
    private updateDriverTagSelectedLabel() {
        const driverTagSelected = this.driverTagList.find(tag => {
            return this.newDriverDetails.driverTagId === tag.driverTagId;
        });
        this.driverTagSelectedLabel = driverTagSelected.driverTagNo ? driverTagSelected.driverTagNo : "";
    }
    filterDriverTagSelection(searchString: string = "") {
        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.driverTagModel.driverTags = this.driverTagList;
        } else {
            this.driverTagModel.driverTags = this.driverTagList.filter(result => result.driverTagNo.toLowerCase().trim().indexOf(searchString) > -1);
        }
    }

    toggleSubjectSelectionFloater(bool: boolean) {
        this.subjectSelectionFloaterIsShown = bool;
    }

    onOutsideClickDriverTagReaction(clickEvent: Event): void {
        this.toggleSubjectSelectionFloater(false);
        this.filterDriverTagSelection();
    }

}
