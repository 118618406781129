import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccessControlService } from './../access-control/access-control.service';
import { TokenService } from '../token/token.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyGuardService implements CanActivate {
    constructor(
        private tokenService: TokenService,
        private accessControlService: AccessControlService,
        private authService: AuthService
    ) {}
    
    async canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        let currentUser = this.tokenService.getAdministratorUserDetails();
        if(!currentUser) {
            currentUser = this.authService.getCurrentUserDetails();
        }

        const result = await this.accessControlService.getPageAccessCompany(state.url, currentUser.companyId);         

        if(result.body.length > 0) {
            return true;
        } else {
            return false;
        }
    }
  
}
