<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md --space-top">
                    <h1 class="heading --level-1">
                        <!-- {{ currentTitle[currentTab - 1]}} -->
                        Fuel
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top" *ngIf="currentTab === 1">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <!--IMPORT tag commented in below is for Batch Import UI Do No Remove-->
                            <a class="heading-buttons__button" (click)="showPopup(batchImportPopup);cleanFuelImportData()">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-batch-import.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Batch Import
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Batch Import
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-batch-import.svg" alt="" width="60" height="60">

                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="navigateToCreateRefuelEntry(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Entry
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Entry
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingRefuelEntry = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Entry
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Entry
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top" *ngIf="currentTab === 2">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToSetFuelLimit(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Set KPI
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Set KPI
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingFuelLimit = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete KPI
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete KPI
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="section   bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="generalRefuelEntry()">Refuel Entry</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="generalFuelLimits()">Fuel KPI</button>
            </div>
        </div>
    </div>

    <!-- Start Refuel Entry -->
    <div class="tabs__content" *ngIf="currentTab === 1">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                                    </div>
                                                </div>
                                                <label class="field__holder">
                                                    <input type="text" autocomplete="off" class="field__input" placeholder="Search Vehicle" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchVehicleName()">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchVehicleName()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table table__refuel-entry-general" [class.--isDeleting]="isDeletingRefuelEntry">
                            <table class="table__elem" style="width: 100%; table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <ng-container *ngIf="isDeletingRefuelEntry">
                                            <th scope="col" width="5%">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedRefuelEntryGroups['all']" (ngModelChange)="onRefuelEntryAllSelectChange()">
                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container>

                                        <th scope="col" width="20%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('VehicleName')" (click)="sort('VehicleName')">Vehicle Name</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('VehiclePlateNo')" (click)="sort('VehiclePlateNo')">Plate Number</button>
                                        </th>
                                        <th scope="col" width="10%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('RefuelDate')" (click)="sort('RefuelDate')">Date/Time</button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Location')" (click)="sort('Location')">Location</button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelQuantity')" (click)="sort('FuelQuantity')">Refuel<br>Amount (L)</button>
                                        </th>
                                        <th scope="col" width="11%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelType')" (click)="sort('FuelType')">Fuel Type</button>
                                        </th>
                                        <th scope="col" width="10%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelCost')" (click)="sort('FuelCost')">Fuel<br>Cost (RM)</button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Odometer')" (click)="sort('Odometer')">Odometer (km)</button>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let refuelEntry of vehiclesRefuelEntryList">
                                        <ng-container *ngIf="isDeletingRefuelEntry">
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="refuelEntry.FuelId" [value]="true" class="inline-input__input" [(ngModel)]="selectedRefuelEntry[refuelEntry.FuelId]" (ngModelChange)="onRefuelEntrySelectChange($event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>
                                        </ng-container>
                                        <td class="td-truncate" *ngIf="editable">
                                            <a class="link --primary" [title]="refuelEntry.VehicleName || '-'" (click)="onVehicleRefuelClick(refuelEntry.FuelId);showPopup(editRefuelEntryPopup);">{{refuelEntry.VehicleName}}</a>
                                        </td>
                                        <td class="td-truncate" *ngIf="!editable">
                                            {{refuelEntry.VehicleName}}
                                        </td>
                                        <td>
                                            {{refuelEntry.VehiclePlateNo || '-'}}
                                        </td>
                                        <td>
                                            {{refuelEntry.FuelDateLabel || '-'}}
                                        </td>
                                        <td>
                                            {{refuelEntry.Location || '-'}}
                                        </td>
                                        <td>
                                            {{refuelEntry.FuelQuantityLabel || '-'}}
                                        </td>
                                        <td>
                                            {{refuelEntry.FuelType || '-'}}
                                        </td>
                                        <td>
                                            {{refuelEntry.FuelCostLabel || '-'}}
                                        </td>
                                        <td>
                                            {{refuelEntry.Odometer || '-'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="record-not-found" *ngIf="vehiclesRefuelEntryList.length === 0">No Record Found</h2>
                        </div>
                    </div>

                    <div class="block" *ngIf="vehiclesRefuelEntryList.length > 0">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination">
                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getVehiclesRefuelEntry(pager.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getVehiclesRefuelEntry(page)">
                                        {{ page }}
                                    </button>

                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getVehiclesRefuelEntry(pager.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Refuel Entry -->

    <!-- Start Fuel KPI -->
    <div class="tabs__content" *ngIf="currentTab === 2">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input type="text" autocomplete="off" class="field__input" placeholder="Search Vehicle" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchVehicleName()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchVehicleName()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table table__fuel-limit" [class.--isDeleting]="isDeletingFuelLimit">
                            <table class="table__elem" style="width: 100%; table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <ng-container *ngIf="isDeletingFuelLimit">
                                            <th scope="col" width="5%">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedFuelLimitGroups['all']" (ngModelChange)="onFuelLimitAllSelectChange()">
                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container>

                                        <th scope="col" width="25%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Name')" (click)="sort('Name')">Vehicle Name</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('PlateNo')" (click)="sort('PlateNo')">Plate Number</button>
                                        </th>
                                        <!--
                                        <th scope="col" width="10%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('NumOfRefills')" (click)="sort('NumOfRefills')">Number of Refills</button>
                                        </th>
                                        -->
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelUsedLimit')" (click)="sort('FuelUsedLimit')">Refuel Amount
                                                <br>(L / month)</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelCostLimit')" (click)="sort('FuelCostLimit')">Fuel Cost
                                                <br>(RM / month)</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelEfficiencyLimit')" (click)="sort('FuelEfficiencyLimit')">Fuel Efficiency
                                                <br>(km / L)</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelRefuelThreshold')" (click)="sort('FuelRefuelThreshold')">Sensor Refuel Threshold
                                                <br>(%)</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelUnexpectDropThreshold')" (click)="sort('FuelUnexpectDropThreshold')">Sensor Fuel Drop Threshold
                                                <br>(%)</button>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let vehicle of vehiclesFuelLimitList">
                                        <ng-container *ngIf="isDeletingFuelLimit">
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="vehicle.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedFuelLimit[vehicle.vehicleId]" (ngModelChange)="onFuelLimitSelectChange($event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>
                                        </ng-container>
                                        <td class="td-truncate" *ngIf="editable">
                                            <a class="link --primary" [title]="vehicle.vehicleName || '-'" (click)="onVehicleClick(vehicle.vehicleId, vehicle.vehicleName);showPopup(SetLimitPopup);">{{vehicle.vehicleName}}</a>
                                        </td>
                                        <td class="td-truncate" *ngIf="!editable">
                                            {{vehicle.vehicleName}}
                                        </td>
                                        <td>
                                            {{vehicle.plateNo || '-'}}
                                        </td>
                                        <!--
                                        <td>
                                            {{vehicle.numOfRefills || '-'}}
                                        </td>
                                        -->
                                        <td>
                                            {{vehicle.fuelUsedLimit || '-'}}
                                            <!--(L/month)-->
                                        </td>
                                        <td>
                                            {{vehicle.fuelCostLimit || '-'}}
                                            <!--(RM/month)-->
                                        </td>
                                        <td>
                                            {{vehicle.fuelEfficiencyLimit || '-'}}
                                            <!--(L/100km)-->
                                        </td>
                                        <td>
                                            {{vehicle.fuelSensorRefuelThreshold || '-'}}
                                            <!--(%)-->
                                        </td>
                                        <td>
                                            {{vehicle.fuelSensorUnexpectDropThreshold || '-'}}
                                            <!--(%)-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="record-not-found" *ngIf="vehiclesFuelLimitList.length === 0">No Record Found</h2>
                        </div>
                    </div>

                    <div class="block" *ngIf="vehiclesFuelLimitList.length > 0">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination">
                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getVehiclesFuelLimit(pager.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getVehiclesFuelLimit(page)">
                                        {{ page }}
                                    </button>

                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getVehiclesFuelLimit(pager.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Fuel KPI -->
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingRefuelEntry && currentTab === 1">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedRefuelEntry()}} fuel vehicle(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteRefuelEntryPopup)" [disabled]="countSelectedRefuelEntry() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingFuelLimit && currentTab === 2">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedFuelLimit()}} Fuel KPI(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteLimitPopup)" [disabled]="countSelectedFuelLimit() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<app-popup #deleteLimitPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedFuelLimit()}}</span> Fuel KPI(s)
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteFuelLimit();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #deleteRefuelEntryPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedRefuelEntry()}}</span> vehicle(s)
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteRefuelEntry();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #SetLimitPopup="popup" class="set-limit-popups">
    <div class="popup__block">
        <div class="grid --gutter --flex-bottom">
            <div class="grid__block w-8of12@sm form__flex">
                <h1 class="heading indicator-green">
                    Edit KPI
                </h1>
            </div>
        </div>
    </div>

    <div class="popup__block">
        <h2 class="heading --level-2">

        </h2>
    </div>

    <form class="form-field" #fuelLimitForm="ngForm">
        <div class="popup__block">
            <!-- <div class="field-group__input set-limit-field"> -->
            <div class="field-group__holder">
                <div class="field-group__label">
                    <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelUsedLimitOff" name="isFuelUsedLimitOff" #isFuelUsedLimitOnOff="ngModel">
                                                                Fuel Used (L/month):
                                                            </mat-slide-toggle> -->
                    Refuel Amount (L/month):
                </div>

                <div class="field-group__input">
                    <div class="field">
                        <div class="field__elems">
                            <label class="field__holder">
                                <!-- <input id="FuelUsedLimit" type="text" class="field__input" [class.--disabled]="!isFuelUsedLimitOff" [disabled]="!isFuelUsedLimitOff" [required]="isFuelUsedLimitOff ? '' : null" name="fuelUsedLimit" [(ngModel)]="fuelUsedLimit" OnlyNumber OnlyInteger> -->
                                <input id="FuelUsedLimit" type="text" class="field__input" name="fuelUsedLimit" [(ngModel)]="fuelUsedLimit" placeholder="125" OnlyNumber OnlyInteger>
                            </label>

                            <div class="field__ui"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field-group__holder">
                <div class="field-group__label">
                    <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelCostLimitOff" name="isFuelCostLimitOff" #isFuelCostLimitOnOff="ngModel">
                                                                Fuel Cost (RM/month):
                                                            </mat-slide-toggle> -->
                    Fuel Cost (RM/month):
                </div>

                <div class="field-group__input">
                    <div class="field">
                        <div class="field__elems">
                            <label class="field__holder">
                                <!-- <input id="FuelCostLimit" type="text" class="field__input" [class.--disabled]="!isFuelCostLimitOff" [disabled]="!isFuelCostLimitOff" [required]="isFuelCostLimitOff ? '' : null" name="fuelCostLimit" [(ngModel)]="fuelCostLimit" OnlyNumber OnlyInteger> -->
                                <input id="FuelCostLimit" type="text" class="field__input" name="fuelCostLimit" [(ngModel)]="fuelCostLimit" placeholder="237" OnlyNumber OnlyInteger>
                            </label>

                            <div class="field__ui"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field-group__holder">
                <div class="field-group__label">
                    <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelEfficiencyLimitOff" name="isFuelEfficiencyLimitOff" #isFuelEfficiencyLimitOnOff="ngModel">
                                                                    Fuel Efficiency (L/100km):
                                                            </mat-slide-toggle> -->
                    Fuel Efficiency (km/L):
                </div>

                <div class="field-group__input">
                    <div class="field">
                        <div class="field__elems">
                            <label class="field__holder">
                                <!-- <input type="text" class="field__input" [class.--disabled]="!isFuelEfficiencyLimitOff" [disabled]="!isFuelEfficiencyLimitOff" name="fuelEfficiencyLimit" [(ngModel)]="fuelEfficiencyLimit" [required]="isFuelEfficiencyLimitOff ? '' : null" OnlyNumber OnlyInteger> -->
                                <input type="text" class="field__input" name="fuelEfficiencyLimit" [(ngModel)]="fuelEfficiencyLimit" placeholder="32" OnlyNumber OnlyInteger maxlength="3">
                            </label>
                            <div class="field__ui"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field-group__holder">
                <div class="field-group__label">
                    <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelEfficiencyLimitOff" name="isFuelEfficiencyLimitOff" #isFuelEfficiencyLimitOnOff="ngModel">
                                                                                                        Fuel Efficiency (L/100km):
                                                                                                </mat-slide-toggle> -->
                    Fuel Refuel Threshold (%):
                </div>

                <div class="field-group__input">
                    <div class="field">
                        <div class="field__elems">
                            <label class="field__holder">
                                <!-- <input type="text" class="field__input" [class.--disabled]="!isFuelEfficiencyLimitOff" [disabled]="!isFuelEfficiencyLimitOff" name="fuelEfficiencyLimit" [(ngModel)]="fuelEfficiencyLimit" [required]="isFuelEfficiencyLimitOff ? '' : null" OnlyNumber OnlyInteger> -->
                                <input type="text" class="field__input" name="RefuelThreshold" [(ngModel)]="fuelRefuelThreshold" #refuelThreshold="ngModel" placeholder="0-100" OnlyNumber OnlyInteger maxlength="3">
                            </label>
                            <div class="field__ui"></div>
                        </div>
                    </div>
                    <div class="field-notes">
                        (Vehicle with fuel sensor module will take effect)
                    </div>
                    <div class="field-error" *ngIf="fuelRefuelThreshold < 0 || fuelRefuelThreshold > 100">
                        {{message.getMessage(message.MESSAGE.OUT_OF_RANGE.value, 'Fuel Refuel Threshold', '0', '100')}}
                    </div>
                </div>
            </div>

            <div class="field-group__holder">
                <div class="field-group__label">
                    <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelEfficiencyLimitOff" name="isFuelEfficiencyLimitOff" #isFuelEfficiencyLimitOnOff="ngModel">
                                                                                                        Fuel Efficiency (L/100km):
                                                                                                </mat-slide-toggle> -->
                    Fuel Drop Alert Threshold (%):
                </div>

                <div class="field-group__input">
                    <div class="field">
                        <div class="field__elems">
                            <label class="field__holder">
                                <!-- <input type="text" class="field__input" [class.--disabled]="!isFuelEfficiencyLimitOff" [disabled]="!isFuelEfficiencyLimitOff" name="fuelEfficiencyLimit" [(ngModel)]="fuelEfficiencyLimit" [required]="isFuelEfficiencyLimitOff ? '' : null" OnlyNumber OnlyInteger> -->
                                <input type="text" class="field__input" name="FuelDropThreshold" [(ngModel)]="fuelDropThreshold" placeholder="0-100" #dropTreshold="ngModel" OnlyNumber OnlyInteger maxlength="3">
                            </label>
                            <div class="field__ui"></div>
                        </div>
                    </div>
                    <div class="field-notes">
                        (Vehicle with fuel sensor module will take effect)
                    </div>
                    <div class="field-error" *ngIf="fuelDropThreshold < 0 || fuelDropThreshold > 100">
                        {{message.getMessage(message.MESSAGE.OUT_OF_RANGE.value, 'Fuel Drop Threshold', '0', '100')}}
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>

        <div class="popup__block">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" [disabled]="fuelLimitForm.invalid || (fuelRefuelThreshold < 0 || fuelRefuelThreshold > 100) || (fuelDropThreshold < 0 || fuelDropThreshold > 100)" (click)="showPopup(updateInfoConfirmPopup);">
                    SAVE
                </button>

                <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                    CANCEL
                </button>
            </div>
        </div>
    </form>
</app-popup>

<app-popup #updateInfoConfirmPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>
    <div class="popup__block">
        <div class="inline-button__field" *ngIf="currentTab === 1">
            <button class="button inline-button__inputs" type="button" (click)="updateVehicleRefuelEntry();hidePopup();">CONFIRM</button>
            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();showPopup(refuelEntryForm);">CANCEL</button>
        </div>

        <div class="inline-button__field" *ngIf="currentTab === 2">
            <button class="button inline-button__inputs" type="button" (click)="updateFuelLimit();hidePopup();">CONFIRM</button>
            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();showPopup(SetLimitPopup);">CANCEL</button>
        </div>
    </div>
</app-popup>

<app-popup #editRefuelEntryPopup="popup" class="set-limit-popups">
    <div class="popup__block">
        <div class="grid --gutter --flex-bottom">
            <div class="grid__block w-8of12@sm form__flex">
                <h1 class="heading indicator-green">
                    Edit Refuel Entry
                </h1>
            </div>
        </div>
    </div>

    <div class="popup__block">
        <h2 class="heading --level-2">

        </h2>
    </div>

    <form class="form-field" #refuelEntryForm="ngForm">
        <div class="popup__block">
            <div class="field-group__input set-limit-field">
                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Vehicle Plate Number:
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input" name="vehiclePlateNo" [(ngModel)]="currentVehicleRefuelEntryObj.vehiclePlateNo" disabled>
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Vehicle Name:
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input" name="vehicleName" [(ngModel)]="currentVehicleRefuelEntryObj.vehicleName" disabled>
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Refuel Date:
                        <span class="indicator-green">*</span>
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <div class="field__icon">
                                    <div class="field__icon-inner">
                                        <mat-datepicker-toggle [for]="FuelDatepicker">
                                            <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                        </mat-datepicker-toggle>
                                    </div>
                                </div>
                                <input [matDatepicker]="FuelDatepicker" (focus)="FuelDatepicker.open()" (click)="FuelDatepicker.open()" type="text" #fuelDate="ngModel" [(ngModel)]="fuelDateVar" name="FuelDatepicker" [max]="moment().startOf('day').format()" class="field__input" readonly dom-states #FuelDateDOMStates="DOMStates" required>
                                <div class="field__ui"></div>
                            </div>
                            <mat-datepicker #FuelDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                        </div>
                    </div>
                </div>

                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Refuel Time:
                        <span class="indicator-green">*</span>
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" #fuelTimeHour="ngModel" [(ngModel)]="fuelTimeHh" name="fuelTimeHour" required>
                                        <option value="" selected disabled hidden>HH</option>
                                        <option *ngFor="let hour of timeSelector.hour" [value]="hour">{{hour}}</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__time-inner --no-padding">
                                :
                            </div>

                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" #fuelTimeMin="ngModel" [(ngModel)]="fuelTimeMm" name="fuelTimeMin" required>
                                        <option value="" selected disabled hidden>MM</option>
                                        <option *ngFor="let minute of timeSelector.minute" [value]="minute">{{minute}}</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__time-inner">
                                <label class="select select-time">
                                    <select class="select__input" #fuelTimeAmPm="ngModel" [(ngModel)]="fuelTimeA" name="fuelTimeAmPm" required>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div *ngIf="(fuelTimeHour.errors && (fuelTimeHour.dirty || fuelTimeHour.touched)) || (fuelTimeMin.errors && (fuelTimeMin.dirty || fuelTimeMin.touched)) || (fuelTimeAmPm.errors && (fuelTimeAmPm.dirty || fuelTimeAmPm.touched))">
                            <div class="field-error">
                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Refuel Time')}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Location:
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input" name="location" #location="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.location" placeholder="eg: KLCC" maxlength="300">
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>
                        <div *ngIf="location.errors && (location.dirty || location.touched)">
                            <div class="field-error" *ngIf="location.errors?.maxlength">
                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Location', '300')}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Refuel Amount (L):
                        <span class="indicator-green">*</span>
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input" name="fuelQuantity" #fuelQuantity="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.fuelQuantity" placeholder="eg: 25" required maxlength="10" OnlyNumber>
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>
                        <div *ngIf="fuelQuantity.errors && (fuelQuantity.dirty || fuelQuantity.touched)">
                            <div class="field-error" *ngIf="fuelQuantity.errors?.required">
                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Fuel Amount')}}
                            </div>
                            <div class="field-error" *ngIf="fuelQuantity.errors?.maxlength">
                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Fuel Amount', '10')}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Fuel Type:
                        <span class="indicator-green">*</span>
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="select" style="width: 100%">
                                    <select class="select__input" name="fuelType" #fuelType="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.fuelType" required>
                                        <option value="" selected disabled hidden>Please Select</option>
                                        <option *ngFor="let item of fuelTypeList;" [value]="item" [selected]="item === currentVehicleRefuelEntryObj.fuelType">{{item}}</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="fuelType.errors && (fuelType.dirty || fuelType.touched)">
                            <div class="field-error" *ngIf="fuelType.errors?.required">
                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Fuel Type')}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Fuel Cost (RM):
                        <span class="indicator-green">*</span>
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input" name="fuelCost" #fuelCost="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.fuelCost" placeholder="eg: 28.50" required maxlength="10" OnlyNumber>
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>
                        <div *ngIf="fuelCost.errors && (fuelCost.dirty || fuelCost.touched)">
                            <div class="field-error" *ngIf="fuelCost.errors?.required">
                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Fuel Cost')}}
                            </div>
                            <div class="field-error" *ngIf="fuelCost.errors?.maxlength">
                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Fuel Cost', '10')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field-group__groups">
                    <div class="field-group__groups-col">
                        Odometer (km):
                    </div>

                    <div class="field-group__groups-col">
                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input type="text" class="field__input" name="odometer" #odometer="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.odometer" placeholder="eg: 34561.7" maxlength="14" OnlyNumber>
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>
                        <div *ngIf="odometer.errors && (odometer.dirty || odometer.touched)">
                            <div class="field-error" *ngIf="odometer.errors?.maxlength">
                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Odometer', '14')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup__block">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" [disabled]="refuelEntryForm.invalid" (click)="showPopup(updateInfoConfirmPopup);">
                    SAVE
                </button>

                <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                    CANCEL
                </button>
            </div>
        </div>
    </form>
</app-popup>

<!-- Import pop up-->
<app-popup #batchImportPopup="popup" class="cm-popups --batch-import">
    <div class="popup__block">
        <div class="popup__block-field">
            <div class="cm-heading__main">
                <div class="cm-heading__text">
                    Batch Import
                </div>
            </div>
        </div>

        <!-- <div class="popup__block-field">
            <div class="image-placeholder">
                <img src="assets/images/ng-components/create-manage/icon-batch-import-grey.svg" alt="" width="170" height="170">
            </div>
        </div> -->

        <!-- Fuel Template Type Listing -->
        <div class="popup__block-field">
            <div class="cm-heading__main">
                <div class="cm-subheading__text">
                    Please select your fuel type.
                </div>
            </div>

            <div class="fuel-selections">
                <div class="fuel-selections__block" [class.--active]="fuelType.id === selectFuelTypeId" *ngFor="let fuelType of fuelTemplateType; let i = index;" (click)="selectFuelType(fuelType.id)">
                    <div class="fuel-selections__block-inner">
                        <div class="fuel-selections__selected" *ngIf="fuelType.id === selectFuelTypeId">
                            <img src="assets/images/scss-components/key-value-icons/icon-if-select.svg" width="12" height="12">
                        </div>

                        <img class="fuel-selections__icon" src="assets/images/scss-components/key-value-icons/icon-fuel.svg">

                        <div class="fuel-selections__label">
                            {{ fuelType.label }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup__block-field">
            <div class="inline-button__field">
                <label>
                    <span class="button inline-button__inputs" [ngClass]="'--' + browseBtnColor">
                        Browse
                    </span>
                    <input type="file" id="myFileInput" (change)="batchImportRefuelEntry($event)" accept="*.xlsx, *.xls, *.csv" [disabled]="!enableUpload" />
                </label>
            </div>

            <span class="notes-input">( Max File Size: {{batchFileSize}}MB )</span>
        </div>

        <div class="popup__block-field">
            <div class="notes">
                <label class="notes__content">
                    <div class="notes__header">
                        <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
                        <p class="notes__header-text">Note:</p>
                    </div>

                    <div class="notes__text">
                        <div>
                            1) Batch Import template (.xls) can be downloaded
                            <a class="link" (click)="generateExcelTemplate()">here</a>.
                        </div>
                        <div>
                            2) Please kindly convert to Batch Import template format if there is no options available.
                        </div>
                        <div *ngIf="isCollectTemplateRequired">
                            3) Upload your import file to allow system provide your listing selection in future(Optional).
                        </div>
                    </div>
                </label>
            </div>
        </div>
        <div class="popup__block-field">
            <div class="inline-button__field">
                <label class="notes__uploadBtn" *ngIf="isCollectTemplateRequired">
                    <span class="button inline-button__inputs">
                        Share Listing
                    </span>
                    <input type="file" id="templateFileInput" (change)="tmpStoreUploadedTemplate($event);showPopup(templateUploadConfirmPopup);" accept="*.xlsx, *.xls, *.csv" style="display: none;" />
                    <div class="notes__important">*Please remove any sensitive data</div>
                </label>
            </div>
        </div>
    </div>
</app-popup>

<!-- Sucess Read File pop up-->
<app-popup #batchImportPreviewPopup="popup" class="cm-popups --batch-import">
    <div class="popup__block ta-l preview">
        <div class="popup__block-field">
            <h1 class="heading indicator-green">Batch Import Preview</h1>
        </div>

        <div class="popup__block-field">
            <div class="grid --gutter-xspace">
                <div class="grid__block-xspace w-6of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Document uploaded
                        </div>

                        <div class="preview__result">
                            {{ fileName }}
                        </div>
                    </div>
                </div>

                <div class="grid__block-xspace w-3of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Entries verified
                        </div>

                        <div class="preview__result --text-big   indicator-green ">
                            {{ validRefuelList.length }}/{{ totalRefuelRecords }}
                        </div>
                    </div>
                </div>

                <div class="grid__block-xspace w-3of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Entries needing attention
                        </div>

                        <div class="preview__result --text-big   indicator-red">
                            {{ invalidRefuelList.length}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup__block-field" *ngIf="!refuelRecordsHasError">
            <div class="preview__notes --if-valid">
                <div class="preview__notes-icon">
                    <i class="gfx-icons --valid"></i>
                </div>

                <div class="preview__notes-desc">
                    <p class="preview__notes-desc-notification">
                        All data entries are <span class="fw-600">valid!</span>
                    </p>

                    <p>
                        Please click Submit to upload.
                    </p>
                </div>
            </div>
        </div>

        <div class="popup__block-field" *ngIf="refuelRecordsHasError">
            <div class="preview__notes --if-invalid">
                <div class="preview__notes-icon">
                    <i class="gfx-icons --invalid"></i>
                </div>

                <div class="preview__notes-desc">
                    <p class="preview__notes-desc-notification">
                        Some data entries are <span class="fw-600">invalid!</span>
                    </p>

                    <p>
                        Please download and review the invalid data before you proceed with the submission.
                    </p>

                    <p class="fs-i">
                        * Please download the invalid data before you proceed with submit.
                    </p>
                </div>

                <div class="preview__notes-download">
                    <button class="button --red --min-width" type="button" (click)="generateExcelTemplate(false);isDownloadDone = true">
                        Download
                    </button>
                </div>
            </div>
        </div>

        <div class="popup__block-field">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" [disabled]="!isDownloadDone && invalidRefuelList.length > 0" (click)="showPopup(batchRefuelConfirmPopup)">
                    Submit
                </button>

                <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</app-popup>

<!-- Failed Read File pop up-->
<app-popup #batchRefuelErrorPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert" [innerHTML]="batchErrorMessage"></span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(batchImportPopup);">
                OK
            </button>
        </div>
    </div>
    <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div>
</app-popup>

<!-- Submit Confirmation pop up-->
<app-popup #batchRefuelConfirmPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            {{ validRefuelList.length }} Refuel(s) Entry will be created.
        </p>

        <p class="notes"></p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="batchCreateRefuelEntry();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
<app-popup #templateUploadConfirmPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Upload?
            </div>
        </div>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="uploadBatchTemplate();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="removeTmpUploadedTemplate();hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
