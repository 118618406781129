import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GeofenceService {

    constructor(private http: HttpClient) { }

    //Get Vehicle Model Dropdown List
    async getGeofenceModelByCompany() {
        const path = environment.mtntApiEndpoint + `/report/company/geofence`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }
}
