export const zIndex: number = 10;
// For arrow icon
// const POLYLINE_ICON_PROPERTIES: any = {
//     path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
//     scale: 1,
//     strokeColor: 'white',
//     fillColor: 'white',
//     fillOpacity: 1
// };

// For Dotted line
const POOR_GPS_LINE_PROPERTIES: any = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    strokeWeight: 10,
    scale: 0.1 // 0.1 = circle dot, 2 above = cylinder shape
};

const POOR_GPS_BORDER_PROPERTIES: any = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    strokeWeight: 13,
    scale: 0.1 // 0.1 = circle dot, 2 above = cylinder shape
};

export const NORMAL_BORDER_PATH = {
    path: [],
    strokeColor: '#23669b',
    strokeOpacity: 1.0,
    strokeWeight: 13,
    zIndex: zIndex / 2,
    type: "BORDER"
};
export const NORMAL_PATH = {
    path: [],
    strokeColor: '#2f8ad3',
    strokeOpacity: 1.0,
    strokeWeight: 10,
    zIndex: zIndex,
    type: "PATH"
};

export const SPD_BORDER_PATH = {
    path: [],
    strokeColor: '#d00000',
    strokeOpacity: 1.0,
    strokeWeight: 13,
    zIndex: zIndex / 2 + 2,
    type: "BORDER"
};
export const SPD_PATH = {
    path: [],
    strokeColor: '#f60000',
    strokeOpacity: 1.0,
    strokeWeight: 10,
    zIndex: zIndex + 2,
    type: "PATH"
};

export const ARROW_ICON = {
    path: [],
    strokeColor: '#2f8ad3',
    strokeOpacity: 0,
    strokeWeight: 10,
    /* Require to let component created google after onInit only can use google.maps.SymbolPath
        * Please set the icon on component side if using google.maps.SymbolPath
        */
    // icons: [{
    //     icon: POLYLINE_ICON_PROPERTIES,
    //     repeat: "20px" //this repeat use to defined how far the distance between 2 arrow head Either: X px | X %
    // }],
    zIndex: zIndex + 2,
    type: "ICON"
};

export const POOR_GPS_BORDER_PATH = {
    path: [],
    strokeOpacity: 0,
    strokeColor: '#23669b',
    icons: [{
        icon: POOR_GPS_BORDER_PROPERTIES,
        // offset: '0',
        repeat: '20px' // more space between dotted line
    }],
    zIndex: zIndex / 2 - 2,
    type: "BORDER"
};
export const POOR_GPS_PATH = {
    path: [],
    strokeOpacity: 0,
    // strokeWeight: 10,// useless when using icons to draw the line
    strokeColor: '#2f8ad3',
    icons: [{
        icon: POOR_GPS_LINE_PROPERTIES,
        // offset: '0',
        repeat: '20px' // more space between dotted line
    }],
    zIndex: zIndex - 2,
    type: "PATH"
};

export const POOR_GPS_AND_SPD_BORDER_PATH = {
    path: [],
    strokeOpacity: 0,
    strokeColor: '#d00000',
    icons: [{
        icon: POOR_GPS_BORDER_PROPERTIES,
        offset: '0',
        repeat: '20px' // more space between dotted line
    }],
    zIndex: zIndex / 2 - 1,
    type: "BORDER"
};
export const POOR_GPS_AND_SPD_PATH = {
    path: [],
    strokeOpacity: 0,
    strokeColor: '#f60000',
    // strokeWeight: 10,// useless when using icons to draw the line
    icons: [{
        icon: POOR_GPS_LINE_PROPERTIES,
        offset: '0',
        repeat: '20px' // more space between dotted line
    }],
    zIndex: zIndex - 1,
    type: "PATH"
};

export function setPath(map: any, style: any, path: any = [], icons: any = null, iconScale = null) {
    if (path.length <= 0) {
        return null;
    }
    const selectedStyle = style;
    selectedStyle.path = path;
    selectedStyle.map = map;
    if (iconScale != null) {
        // console.log(selectedStyle);
        // console.log(icons[0]);
        selectedStyle.icons[0].icon.scale = iconScale;
    }
    if (icons != null) {
        selectedStyle.icons = icons;
    }
    return selectedStyle;
}
