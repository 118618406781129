import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../../../../../environments/environment';
import * as moment from 'moment';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { ActivatedRoute } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from './../../../../../components/common/spinner/spinner.component';

// Service
import { PopupService } from '../../../../../components/common/popup/popup.service';
import { SnackBarService } from '../../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../../_services/router-proxy/router-proxy.service';
import { CampaignServiceService } from './../../../../../_services/campaign/campaign-service.service';

// Util
import * as ImageUtil from '../../../../../util/imageUtil';
import * as AsyncUtil from '../../../../../util/asyncUtil';
import * as StringUtil from '../../../../../util/stringUtil';

// Constant
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../../constants/responseStatusCode';
import * as Message from './../../../../../constants/message';

@Component({
    selector: 'app-edit-campaigns',
    templateUrl: './edit-campaigns.component.html',
    styleUrls: ['./edit-campaigns.component.scss']
})
export class EditCampaignsComponent implements OnInit {
    moment = moment;
    campaignObj = {
        name: null,
        pageAccessId: null,
        status: 'ACTIVE',
        durationStart: null,
        durationEnd: null
    };

    campaignItemObj = {
        title: 'New item',
        description: null,
        desktopImage: null,
        mobileImage: null,
        haveDesktopImage: false,
        haveMobileImage: false
    };
    message = Message;
    placementList = [];
    campaignItemList = [];
    campaignItemOldList = [];
    campaignId: number;
    deleteCampaignItemPosition: number;

    @ViewChild('imageAlertPopup',{static:false}) imageAlertPopup;
    maxSizeMB = 0.5; //in MB
    maxWidthOrHeightDesktop = 650;
    maxWidthOrHeightMobile = 580;
    maxSizeImageMB = environment.appConfig.createManage.maxSizeImageMB;
    imageAlertError = null;

    @ViewChild('createCropImagePopup',{static:false}) createCropImagePopup;
    @ViewChild(ImageCropperComponent,{static:false}) imageCropper: ImageCropperComponent;
    @ViewChild("cropImage_spinner",{static:false}) cropImage_spinner: SpinnerComponent;
    imageChangedEvent: any = '';
    croppedImageData: any = '';
    imagePosition: number;
    fromDesktopImage: boolean = true;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    datepickerTouchUi: boolean = false;

    constructor(
        private platform: Platform,
        private campaignService: CampaignServiceService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private route: ActivatedRoute,
        private dragulaService: DragulaService) {
        if (!dragulaService.find("HANDLES")) {
            dragulaService.createGroup("HANDLES", {
                moves: (el, container, handle) => {
                    return handle.id === 'handle';
                }
            });
        }
    }

    async ngOnInit() {
        this.campaignId = this.route.snapshot.params.id;
        await this.onInitCommonFunction();
        this.getPlacementDropDownList();
        // this.campaignItemList.push(JSON.parse(JSON.stringify(this.campaignItemObj)));
    }

    async onInitCommonFunction() {
        this.pageSpinner.show();
        try {
            await this.getCampaignDetails(this.campaignId);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async getCampaignDetails(campaignId) {
        const res: any = await this.campaignService.getCampaignDetails(campaignId);
        this.campaignObj = res.body.campaign[0];
        this.campaignItemList = res.body.campaignItem;
        for (const campaignItem of this.campaignItemList) {
            if (campaignItem.desktopImage) {
                // campaignItem.desktopImage = `data:image/jpeg;base64,${campaignItem.desktopImage}`;
                campaignItem["haveDesktopImage"] = true;
            }

            if (campaignItem.mobileImage) {
                // campaignItem.mobileImage = `data:image/jpeg;base64,${campaignItem.mobileImage}`;
                campaignItem["haveMobileImage"] = true;
            }
        }
        //for later check image have changes or not
        this.campaignItemOldList = Array.from(this.campaignItemList);
    }

    async getPlacementDropDownList() {
        const response: any = await this.campaignService.getPlacementDropDownList();
        this.placementList = response.body.result;
    }

    addNewCampaignItem() {
        const newCampaignItem = JSON.parse(JSON.stringify(this.campaignItemObj));
        this.campaignItemList.push(newCampaignItem);
    }

    selectedDeleteCampaignItemPosition(position: number) {
        this.deleteCampaignItemPosition = position;
    }

    deleteCampaignItem() {
        this.campaignItemList.splice(this.deleteCampaignItemPosition, 1);
    }

    async updateCampaign() {
        this.pageSpinner.show();
        try {
            let count = 0;
            for (const campaignItem of this.campaignItemList) {
                let needUpdateDesktopImg = true;
                let needUpdateMobileImg = true;
                if ("id" in campaignItem) {
                    //id property existed, mean existing item, not new
                    const campaignItemOld = this.campaignItemOldList.find(oldItem => oldItem.id === campaignItem.id);
                    const desktopImg: any = <HTMLElement>document.getElementById("imageId" + count);
                    const mobileImg: any = <HTMLElement>document.getElementById("mobileImageId" + count);
                    //check is image have changes, if no changes no need compress image again
                    if (desktopImg && desktopImg.src === campaignItemOld.desktopImage) {
                        needUpdateDesktopImg = false;
                    }

                    if (mobileImg && mobileImg.src === campaignItemOld.mobileImage) {
                        needUpdateMobileImg = false;
                    }
                }

                // Set Image
                if (needUpdateDesktopImg) {
                    if (campaignItem.haveDesktopImage) {
                        let newDesktopImage;
                        const img: any = <HTMLElement>document.getElementById("imageId" + count);
                        if (img) {
                            newDesktopImage = await ImageUtil.compressImageSize(img.src, this.maxSizeMB, this.maxWidthOrHeightDesktop, this.platform); // maxSizeMB, maxWidthOrHeight are optional
                            await AsyncUtil.wait(2000); // Wait for image compress
                        } else {
                            newDesktopImage = null;
                        }
                        // Invalid image -> always update to blank
                        if (!newDesktopImage) {
                            campaignItem.desktopImage = '';
                        } else {
                            campaignItem.desktopImage = newDesktopImage;
                        }
                    }
                }

                if (needUpdateMobileImg) {
                    if (campaignItem.haveMobileImage) {
                        let newMobileImage;
                        const img: any = <HTMLElement>document.getElementById("mobileImageId" + count);
                        if (img) {
                            newMobileImage = await ImageUtil.compressImageSize(img.src, this.maxSizeMB, this.maxWidthOrHeightMobile, this.platform); // maxSizeMB, maxWidthOrHeight are optional
                            await AsyncUtil.wait(2000); // Wait for image compress
                        } else {
                            newMobileImage = null;
                        }
                        // Invalid image -> always update to blank
                        if (!newMobileImage) {
                            campaignItem.mobileImage = '';
                        } else {
                            campaignItem.mobileImage = newMobileImage;
                        }
                    }
                }

                campaignItem["sequence"] = count;

                delete campaignItem["haveDesktopImage"];
                delete campaignItem["haveMobileImage"];
                count++;
            }

            if (this.campaignObj.pageAccessId) {
                this.campaignObj.pageAccessId = Number(this.campaignObj.pageAccessId);
            }

            if (moment(this.campaignObj.durationStart).isValid()) {
                this.campaignObj.durationStart = moment(this.campaignObj.durationStart).format("YYYY-MM-DD");
            }

            if (moment(this.campaignObj.durationEnd).isValid()) {
                this.campaignObj.durationEnd = moment(this.campaignObj.durationEnd).format("YYYY-MM-DD");
            }

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.campaignObj.name)) {
                this.campaignObj.name = this.campaignObj.name.trim();
            }

            if (this.campaignItemList.length > 0) {
                this.campaignObj["items"] = this.campaignItemList;
            }

            const result = await this.campaignService.updateCampaign(this.campaignObj);

            const campaignName = this.campaignObj.name;

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Campaign', campaignName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = this.message.getMessage(this.message.MESSAGE.UPDATE_SUCCESS.value, "Campaign", campaignName);
                this.navigateSuccessToCampaignView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToCampaignView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['support/manage/manage-campaigns']);
    }

    navigateToCampaignView() {
        this.routerProxyService.navigate(['support/manage/manage-campaigns']);
    }

    currentImageSelectedIndexAndSource(position: number, fromDesktopImage: boolean) {
        //to indicate which index of campaignitem image we dealing
        this.imagePosition = position;
        //to indicate the following image process is calling from desktopImage or mobileImage
        this.fromDesktopImage = fromDesktopImage;
    }

    async chooseImage(item: any) {
        if (item.target.files.length > 0 && item.target.files[0].type.indexOf("image") > -1) {
            const imageFile = item.target.files[0];

            if ((imageFile.size / 1024 / 1024) < this.maxSizeImageMB) { //Check Image Size
                if (this.fromDesktopImage) {
                    this.campaignItemList[this.imagePosition].haveDesktopImage = true;
                } else {
                    this.campaignItemList[this.imagePosition].haveMobileImage = true;
                }

                this.imageChangedEvent = item;
                this.showPopup(this.createCropImagePopup);

            } else {
                this.imageAlertError = 'sizeError';
                this.showPopup(this.imageAlertPopup);
            }
        } else {
            this.imageAlertError = 'fileError';
            this.showPopup(this.imageAlertPopup);
        }
    }

    confirmCropImage() {
        let img: any = null;
        if (this.fromDesktopImage) {
            img = <HTMLElement>document.getElementById("imageId" + this.imagePosition);
            img.src = this.croppedImageData;
        } else {
            img = <HTMLElement>document.getElementById("mobileImageId" + this.imagePosition);
            img.src = this.croppedImageData;
        }
    }

    clearImage() {
        let img: any = null;
        if (this.fromDesktopImage) {
            img = <HTMLElement>document.getElementById("imageId" + this.imagePosition);
        } else {
            img = <HTMLElement>document.getElementById("mobileImageId" + this.imagePosition);
        }
        //clear image when hit cancel and is 1st time select image
        if (!img.src || img.src === '' || !img.src.startsWith("data:image")) {
            if (this.fromDesktopImage) {
                this.campaignItemList[this.imagePosition].haveDesktopImage = false;
            } else {
                this.campaignItemList[this.imagePosition].haveMobileImage = false;
            }
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImageData = event.base64;
        let img: any = null;
        if (this.fromDesktopImage) {
            img = <HTMLElement>document.getElementById("imageId" + this.imagePosition);
        } else {
            img = <HTMLElement>document.getElementById("mobileImageId" + this.imagePosition);
        }
        // img.src = this.croppedImageData;
    }

    cropperReady() {
        const fileInput: any = document.getElementById('myFileInput');
        if (fileInput && fileInput.value) { fileInput.value = null; } // Clean previous file
        this.cropImage_spinner.hide();
    }

    imageDirection(direction) {
        try {
            this.cropImage_spinner.show();
            if (direction === 'left') {
                this.imageCropper.rotateLeft();
            } else if (direction === 'right') {
                this.imageCropper.rotateRight();
            }
        } catch (e) {
            // do nothing
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
