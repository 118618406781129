import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PopupService {
    currentPopup;

    constructor() { }

    show(popup, popupType = null) {
        this.hide();
        popup.isActive = true;
        this.currentPopup = popup;

        // Check if is survey popup
        if (popupType !== 'survey') this.disablePageScroll();
    }

    hide() {
        if (this.currentPopup) {
            this.currentPopup.isActive = false;
            this.currentPopup = undefined;
            this.enablePageScroll();
        }
    }

    enablePageScroll() {
        document.documentElement.classList.remove('--popup-is-shown');
    }

    disablePageScroll() {
        document.documentElement.classList.add('--popup-is-shown');
    }
}