<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main manage-device">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@lg --space-top">
                    <h1 class="heading --level-1">
                        Switch Device Provider
                    </h1>
                </div>

                <!-- <div class="grid__block w-8of12@lg heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="showPopup(fileUploadPopup);">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/scss-components/key-value-icons/icon-upload.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Upload
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Upload
                                    </div>

                                    <img src="assets/images/scss-components/key-value-icons/icon-mobile-upload.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <form class="form-field" #vehicleForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <div class="add-device-tabs">
                        <div class="add-device-tabs__tab">
                            <button class="tab__details" (click)="viewDetails(1)">
                                <div class="add-device-tabs__button --company-details" [ngClass]="{'--active': currentTab == 1}">
                                    Company Details
                                </div>
                            </button>
                        </div>

                        <div class="add-device-tabs__tab">
                            <button class="tab__details" (click)="viewDetails(2)">
                                <div class="add-device-tabs__button --vehicle-details" [ngClass]="{'--active': currentTab == 2}">
                                    Vehicle Details
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="compulsory-notes">
                        *Compulsory fields
                    </div>
                </div>

                <div class="block" [hidden]="currentTab !== 1">
                    <div class="block">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            01. Registration Type
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__input">
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Type:

                                            <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="inline-input" style="margin: 10px;">
                                                                    <input type="radio" #new name="type" class="inline-input__input" value="NEW" checked>
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>New</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 10px;">
                                                                    <input type="radio" #existing name="type" class="inline-input__input" value="EXISTING">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Existing</span>
                                                                </label> -->
                                                    EXISTING
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            02. Company Info
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item --2-col">
                                <div class="field-group__input">
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Company Name:

                                            <!-- <span class="indicator-green">*</span> -->
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                                        <input type="text" class="field__input" name="companyName" [(ngModel)]="companyObj.name" required>
                                                                    </label>

                                                                    <div class="field__ui"></div> -->
                                                    {{companyObj.name || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="field-group__groups" *ngIf="existing.checked">
                                                    <div class="field-group__groups-col">
                                                        Company Name:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                    		                                        <select class="select__input">
                    		                                        	<option value="" selected>Please Select</option>
                    		                                            <option value="Option 1">Option 1</option>
                    									                <option value="Option 2">Option 2</option>
                    									                <option value="Option 3">Option 3</option>
                    		                                        </select>
                    		                                    </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            BRN:

                                            <!-- <span class="indicator-green">*</span> -->
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                                        <input type="text" class="field__input" name="brn" [(ngModel)]="companyObj.brn" required>
                                                                    </label>

                                                                    <div class="field__ui"></div> -->
                                                    {{companyObj.brn || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Email Address:

                                            <!-- <span class="indicator-green">*</span> -->
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                                        <input type="text" class="field__input" name="email" [(ngModel)]="companyObj.email" required>
                                                                    </label>

                                                                    <div class="field__ui"></div> -->
                                                    {{companyObj.email || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Address 1:

                                            <!-- <span class="indicator-green">*</span> -->
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                                        <input type="text" class="field__input" name="address1" [(ngModel)]="companyObj.address.address1" required>
                                                                    </label>

                                                                    <div class="field__ui"></div> -->
                                                    {{companyObj.address.address1 || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Address 2:
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                                        <input type="text" class="field__input" name="address2" [(ngModel)]="companyObj.address.address2">
                                                                    </label>

                                                                    <div class="field__ui"></div> -->
                                                    {{companyObj.address.address2 || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__input">
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            City:

                                            <!-- <span class="indicator-green">*</span> -->
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                                        <input type="text" class="field__input" name="city" [(ngModel)]="companyObj.address.city" required>
                                                                    </label>

                                                                    <div class="field__ui"></div> -->
                                                    {{companyObj.address.city || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            State:

                                            <!-- <span class="indicator-green">*</span> -->
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                                        <input type="text" class="field__input" name="state" [(ngModel)]="companyObj.address.state" required>
                                                                    </label>

                                                                    <div class="field__ui"></div> -->
                                                    {{companyObj.address.state || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Zip Code:

                                            <!-- <span class="indicator-green">*</span> -->
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                                        <input type="text" class="field__input" name="zipCode" [(ngModel)]="companyObj.address.zipCode" required>
                                                                    </label>

                                                                    <div class="field__ui"></div> -->
                                                    {{companyObj.address.zipCode || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Country:


                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="select">
                                                                        <select class="select__input" name="countryCode" [(ngModel)]="companyObj.address.countryCode">
                                                                            <option value="">Please Select</option>
                                                                            <option value="Afghanistan">Afghanistan</option>
                                                                            <option value="Åland Islands">Åland Islands</option>
                                                                            <option value="Albania">Albania</option>
                                                                            <option value="Algeria">Algeria</option>
                                                                            <option value="American Samoa">American Samoa</option>
                                                                            <option value="Andorra">Andorra</option>
                                                                            <option value="Angola">Angola</option>
                                                                            <option value="Anguilla">Anguilla</option>
                                                                            <option value="Antarctica">Antarctica</option>
                                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                                            <option value="Argentina">Argentina</option>
                                                                            <option value="Armenia">Armenia</option>
                                                                            <option value="Aruba">Aruba</option>
                                                                            <option value="Australia">Australia</option>
                                                                            <option value="Austria">Austria</option>
                                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                                            <option value="Bahamas">Bahamas</option>
                                                                            <option value="Bahrain">Bahrain</option>
                                                                            <option value="Bangladesh">Bangladesh</option>
                                                                            <option value="Barbados">Barbados</option>
                                                                            <option value="Belarus">Belarus</option>
                                                                            <option value="Belgium">Belgium</option>
                                                                            <option value="Belize">Belize</option>
                                                                            <option value="Benin">Benin</option>
                                                                            <option value="Bermuda">Bermuda</option>
                                                                            <option value="Bhutan">Bhutan</option>
                                                                            <option value="Bolivia">Bolivia</option>
                                                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                                            <option value="Botswana">Botswana</option>
                                                                            <option value="Bouvet Island">Bouvet Island</option>
                                                                            <option value="Brazil">Brazil</option>
                                                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                                            <option value="Bulgaria">Bulgaria</option>
                                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                                            <option value="Burundi">Burundi</option>
                                                                            <option value="Cambodia">Cambodia</option>
                                                                            <option value="Cameroon">Cameroon</option>
                                                                            <option value="Canada">Canada</option>
                                                                            <option value="Cape Verde">Cape Verde</option>
                                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                                            <option value="Central African Republic">Central African Republic</option>
                                                                            <option value="Chad">Chad</option>
                                                                            <option value="Chile">Chile</option>
                                                                            <option value="China">China</option>
                                                                            <option value="Christmas Island">Christmas Island</option>
                                                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                                            <option value="Colombia">Colombia</option>
                                                                            <option value="Comoros">Comoros</option>
                                                                            <option value="Congo">Congo</option>
                                                                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                                                            <option value="Cook Islands">Cook Islands</option>
                                                                            <option value="Costa Rica">Costa Rica</option>
                                                                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                                                                            <option value="Croatia">Croatia</option>
                                                                            <option value="Cuba">Cuba</option>
                                                                            <option value="Cyprus">Cyprus</option>
                                                                            <option value="Czech Republic">Czech Republic</option>
                                                                            <option value="Denmark">Denmark</option>
                                                                            <option value="Djibouti">Djibouti</option>
                                                                            <option value="Dominica">Dominica</option>
                                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                                            <option value="Ecuador">Ecuador</option>
                                                                            <option value="Egypt">Egypt</option>
                                                                            <option value="El Salvador">El Salvador</option>
                                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                                            <option value="Eritrea">Eritrea</option>
                                                                            <option value="Estonia">Estonia</option>
                                                                            <option value="Ethiopia">Ethiopia</option>
                                                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                                            <option value="Fiji">Fiji</option>
                                                                            <option value="Finland">Finland</option>
                                                                            <option value="France">France</option>
                                                                            <option value="French Guiana">French Guiana</option>
                                                                            <option value="French Polynesia">French Polynesia</option>
                                                                            <option value="French Southern Territories">French Southern Territories</option>
                                                                            <option value="Gabon">Gabon</option>
                                                                            <option value="Gambia">Gambia</option>
                                                                            <option value="Georgia">Georgia</option>
                                                                            <option value="Germany">Germany</option>
                                                                            <option value="Ghana">Ghana</option>
                                                                            <option value="Gibraltar">Gibraltar</option>
                                                                            <option value="Greece">Greece</option>
                                                                            <option value="Greenland">Greenland</option>
                                                                            <option value="Grenada">Grenada</option>
                                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                                            <option value="Guam">Guam</option>
                                                                            <option value="Guatemala">Guatemala</option>
                                                                            <option value="Guernsey">Guernsey</option>
                                                                            <option value="Guinea">Guinea</option>
                                                                            <option value="Guinea-bissau">Guinea-bissau</option>
                                                                            <option value="Guyana">Guyana</option>
                                                                            <option value="Haiti">Haiti</option>
                                                                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                                            <option value="Honduras">Honduras</option>
                                                                            <option value="Hong Kong">Hong Kong</option>
                                                                            <option value="Hungary">Hungary</option>
                                                                            <option value="Iceland">Iceland</option>
                                                                            <option value="India">India</option>
                                                                            <option value="Indonesia">Indonesia</option>
                                                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                                            <option value="Iraq">Iraq</option>
                                                                            <option value="Ireland">Ireland</option>
                                                                            <option value="Isle of Man">Isle of Man</option>
                                                                            <option value="Israel">Israel</option>
                                                                            <option value="Italy">Italy</option>
                                                                            <option value="Jamaica">Jamaica</option>
                                                                            <option value="Japan">Japan</option>
                                                                            <option value="Jersey">Jersey</option>
                                                                            <option value="Jordan">Jordan</option>
                                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                                            <option value="Kenya">Kenya</option>
                                                                            <option value="Kiribati">Kiribati</option>
                                                                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                                                            <option value="Kuwait">Kuwait</option>
                                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                                            <option value="Latvia">Latvia</option>
                                                                            <option value="Lebanon">Lebanon</option>
                                                                            <option value="Lesotho">Lesotho</option>
                                                                            <option value="Liberia">Liberia</option>
                                                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                                            <option value="Lithuania">Lithuania</option>
                                                                            <option value="Luxembourg">Luxembourg</option>
                                                                            <option value="Macao">Macao</option>
                                                                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                                            <option value="Madagascar">Madagascar</option>
                                                                            <option value="Malawi">Malawi</option>
                                                                            <option value="Malaysia" selected>Malaysia</option>
                                                                            <option value="Maldives">Maldives</option>
                                                                            <option value="Mali">Mali</option>
                                                                            <option value="Malta">Malta</option>
                                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                                            <option value="Martinique">Martinique</option>
                                                                            <option value="Mauritania">Mauritania</option>
                                                                            <option value="Mauritius">Mauritius</option>
                                                                            <option value="Mayotte">Mayotte</option>
                                                                            <option value="Mexico">Mexico</option>
                                                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                                            <option value="Monaco">Monaco</option>
                                                                            <option value="Mongolia">Mongolia</option>
                                                                            <option value="Montenegro">Montenegro</option>
                                                                            <option value="Montserrat">Montserrat</option>
                                                                            <option value="Morocco">Morocco</option>
                                                                            <option value="Mozambique">Mozambique</option>
                                                                            <option value="Myanmar">Myanmar</option>
                                                                            <option value="Namibia">Namibia</option>
                                                                            <option value="Nauru">Nauru</option>
                                                                            <option value="Nepal">Nepal</option>
                                                                            <option value="Netherlands">Netherlands</option>
                                                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                                            <option value="New Caledonia">New Caledonia</option>
                                                                            <option value="New Zealand">New Zealand</option>
                                                                            <option value="Nicaragua">Nicaragua</option>
                                                                            <option value="Niger">Niger</option>
                                                                            <option value="Nigeria">Nigeria</option>
                                                                            <option value="Niue">Niue</option>
                                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                                            <option value="Norway">Norway</option>
                                                                            <option value="Oman">Oman</option>
                                                                            <option value="Pakistan">Pakistan</option>
                                                                            <option value="Palau">Palau</option>
                                                                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                                            <option value="Panama">Panama</option>
                                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                                            <option value="Paraguay">Paraguay</option>
                                                                            <option value="Peru">Peru</option>
                                                                            <option value="Philippines">Philippines</option>
                                                                            <option value="Pitcairn">Pitcairn</option>
                                                                            <option value="Poland">Poland</option>
                                                                            <option value="Portugal">Portugal</option>
                                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                                            <option value="Qatar">Qatar</option>
                                                                            <option value="Reunion">Reunion</option>
                                                                            <option value="Romania">Romania</option>
                                                                            <option value="Russian Federation">Russian Federation</option>
                                                                            <option value="Rwanda">Rwanda</option>
                                                                            <option value="Saint Helena">Saint Helena</option>
                                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                                            <option value="Saint Lucia">Saint Lucia</option>
                                                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                                            <option value="Samoa">Samoa</option>
                                                                            <option value="San Marino">San Marino</option>
                                                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                                            <option value="Senegal">Senegal</option>
                                                                            <option value="Serbia">Serbia</option>
                                                                            <option value="Seychelles">Seychelles</option>
                                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                                            <option value="Singapore">Singapore</option>
                                                                            <option value="Slovakia">Slovakia</option>
                                                                            <option value="Slovenia">Slovenia</option>
                                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                                            <option value="Somalia">Somalia</option>
                                                                            <option value="South Africa">South Africa</option>
                                                                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                                            <option value="Spain">Spain</option>
                                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                                            <option value="Sudan">Sudan</option>
                                                                            <option value="Suriname">Suriname</option>
                                                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                                            <option value="Swaziland">Swaziland</option>
                                                                            <option value="Sweden">Sweden</option>
                                                                            <option value="Switzerland">Switzerland</option>
                                                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                                                            <option value="Tajikistan">Tajikistan</option>
                                                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                                            <option value="Thailand">Thailand</option>
                                                                            <option value="Timor-leste">Timor-leste</option>
                                                                            <option value="Togo">Togo</option>
                                                                            <option value="Tokelau">Tokelau</option>
                                                                            <option value="Tonga">Tonga</option>
                                                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                                            <option value="Tunisia">Tunisia</option>
                                                                            <option value="Turkey">Turkey</option>
                                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                                            <option value="Tuvalu">Tuvalu</option>
                                                                            <option value="Uganda">Uganda</option>
                                                                            <option value="Ukraine">Ukraine</option>
                                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                                            <option value="United Kingdom">United Kingdom</option>
                                                                            <option value="United States">United States</option>
                                                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                                            <option value="Uruguay">Uruguay</option>
                                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                                            <option value="Vanuatu">Vanuatu</option>
                                                                            <option value="Venezuela">Venezuela</option>
                                                                            <option value="Viet Nam">Viet Nam</option>
                                                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                                            <option value="Western Sahara">Western Sahara</option>
                                                                            <option value="Yemen">Yemen</option>
                                                                            <option value="Zambia">Zambia</option>
                                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                                        </select>
                                                                    </label> -->
                                                    {{companyObj.address.countryCode || 'None'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="field-group__groups">
                                                        <div class="field-group__groups-col">
                                                            RF Date:
                                                        </div>

                                                        <div class="field-group__groups-col">
                                                            <div class="field">
                                                                <div class="field__elems">
                                                                    <!- <div class="field__icon">
                                                                        <div class="field__icon-inner">
                                                                            <mat-datepicker-toggle [for]="DurationStartDatepicker">
                                                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                                            </mat-datepicker-toggle>
                                                                        </div>
                                                                    </div>

                                                                    <input [matDatepicker]="DurationStartDatepicker" (focus)="DurationStartDatepicker.open()" (click)="DurationStartDatepicker.open()" type="text" name="durationStart" class="field__input" [(ngModel)]="companyObj.rfDate" readonly dom-states required>

                                                                    <div class="field__ui"></div> ->
                                                                    {{companyObj.rfDate}}
                                                                </div>

                                                                <!- <mat-datepicker #DurationStartDatepicker [touchUi]="datepickerTouchUi">
                                                                </mat-datepicker> ->
                                                            </div>
                                                        </div>
                                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            03. Channel Details
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item --2-col">
                                <div class="field-group__input">
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Channel Name:

                                            <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="field__holder">
                                                        <input type="text" class="field__input" name="channelName" [(ngModel)]="companyObj.channel.channelName" required>
                                                    </label>

                                                    <div class="field__ui"></div> -->
                                                    {{ channelObj[0].channelName || '-'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Channel Type

                                            <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="select">
                                                        <select class="select__input" [(ngModel)]="companyObj.channel.channelTypeId" name="channelTypeId" required>
                                                            <option *ngFor="let item of salesChannelList;" [value]="item.id" [selected]="item.id === selectedPlacement">{{item.name}}</option>
                                                        </select>
                                                    </label> -->
                                                    {{ channelObj[0].channelType || '-' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__input">
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Region

                                            <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <!-- <label class="select">
                                                        <select class="select__input" [(ngModel)]="companyObj.channel.region" name="region" required>
                                                            <option *ngFor="let item of regionList;" [value]="item" [selected]="item === selectedPlacement">{{item}}</option>
                                                        </select>
                                                    </label> -->
                                                    {{ channelObj[0].region || '-' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block" [hidden]="currentTab !== 2">
                    <div class="block">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Editing Vehicle Details
                        </h2>
                    </div>

                    <div class="block">
                        <div class="md-box-details-table table__edit-vehicle-details">
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Vehicles
                                        </th>

                                        <th scope="col">
                                            Installation
                                        </th>

                                        <th scope="col">
                                            Last Modified By
                                        </th>

                                        <th scope="col">
                                            Last Modified
                                        </th>

                                        <th scope="col">
                                            Progress
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>

                    <div class="block block-mt-1" *ngFor="let vehicleObj of vehicleObjList; let position = index;">
                        <div class="md-box" [class.--active]="vehicleObj.isActive">
                            <div class="md-box__head" (click)="toggleVehicleAccordian(position)">
                                <div class="md-box-details-table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td scope="col">
                                                    Vehicle {{ vehicleObj.vehicleId }}
                                                </td>

                                                <td scope="col">
                                                    {{ vehicleObj.installationType === 'WIRED' ? 'Wired' : (vehicleObj.installationType === 'PLUG_PLAY' ? 'Plug & Play' : (vehicleObj.installationType === 'CUSTOM' ? 'Customised' : 'None')) }}
                                                </td>

                                                <td scope="col">
                                                    {{ vehicleObj.updatedBy }}
                                                </td>

                                                <td scope="col">
                                                    {{ vehicleObj.dateUpdated }}
                                                </td>

                                                <td scope="col">
                                                    <div class="progress-status" [ngClass]="{
                                                '--in-progress': vehicleObj.status == 'SCHEDULED' || vehicleObj.status == 'PENDING',
                                                '--activated': vehicleObj.status == 'ACTIVATED',
                                                '--completed': vehicleObj.status == 'COMPLETED' || !vehicleObj.status || vehicleObj.status == 'DEACTIVATED',
                                                '--pending': vehicleObj.status=='RESCHEDULE' || vehicleObj.status == 'REQ_NEW_IMEI'
                                            }"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="md-box__contents-container" [hidden]="!vehicleObj.isActive" *ngIf="vehicleObj.installationType == 'WIRED'" @slideToggle>
                                <div class="md-box__contents">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Installation Type:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #wired type="radio" name="{{'type'+position}}" class="inline-input__input" value="WIRED" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'wired', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Wired</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #plugnplay type="radio" name="{{'type'+position}}" class="inline-input__input" value="PLUG_PLAY" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'plugnplay', position)">
                                                                    <span class=" inline-input__icon"></span>
                                                                    <span>Plug &amp; Play</span>
                                                                </label>

                                                                <!-- <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #plugnplaycourier type="radio" name="{{'type'+position}}" class="inline-input__input" value="PLUG_PLAY_COURIER" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'plugnplaycourier', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Plug &amp; Play Courier</span>
                                                                </label> -->

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #customised type="radio" name="{{'type'+position}}" class="inline-input__input" value="CUSTOM" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'customised', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Customised</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="md-box__contents --bg-white">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item --2-col">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Current Provider:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ vehicleObj.thirdPartyName }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Device Provider:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.deviceProviderId" required name="{{'wiredDeviceProviderId'+position}}" (change)="validateProvider(position)">
                                                                        <option *ngFor="let provider of deviceProviderList;" [value]="provider.id" [selected]="provider.id === vehicleObj.deviceProviderId">{{provider.thirdPartyName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Type:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleType" required name="{{'wiredVehicleType'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleType of vehicleTypeList;" [value]="vehicleType" [selected]="vehicleType === vehicleObj.vehicleType">{{vehicleType}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Make:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleMakeId" required name="{{'wiredVehicleMakeId'+position}}" (change)="getVehicleModelList(vehicleObj.vehicleMakeId, position)" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleMake of vehicleMakeList;" [value]="vehicleMake.id" [selected]="vehicleMake.id === vehicleObj.vehicleMakeId">{{vehicleMake.brandName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Model:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleModelId" name="{{'wiredVehicleModelId'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleModel of vehicleModelList[position];" [value]="vehicleModel.id" [selected]="vehicleModel.id === vehicleObj.vehicleModelId">{{vehicleModel.modelName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Model Details:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'wiredVehicleModelDetails'+position}}" [(ngModel)]="vehicleObj.vehicleModelDetails" [disabled]="isEditVehicleInfo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Year:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleYear" name="{{'wiredVehicleYear'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let year of yearList;" [value]="year" [selected]="year === vehicleObj.vehicleYear">{{year}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Number Plate:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'wiredNumberPlate'+position}}" [(ngModel)]="vehicleObj.numberPlate" required [disabled]="isEditVehicleInfo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        MOB:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'wiredMob'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.mob" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Yes</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'wiredMob'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.mob" [disabled]="isEditVehicleInfo==true">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ formatYesOrNo(vehicleObj.mob) }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Roaming:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'wiredRoaming'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.roaming" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Yes</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'wiredRoaming'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.roaming" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ formatYesOrNo(vehicleObj.roaming) }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. PIC Name:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'wiredPicName'+position}}" required [(ngModel)]="vehicleObj.installation.picName">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. Address:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'wiredAddress'+position}}" required [(ngModel)]="vehicleObj.installation.address">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Postcode:
    
                                                        <span class="indicator-green">*</span>
                                                    </div>
    
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="number" class="field__input" name="{{'wiredPostcode'+position}}" required [(ngModel)]="vehicleObj.installation.postcode">
                                                                </label>
    
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        State:
                                                        <span class="indicator-green">*</span>
                                                    </div>
    
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.installation.state" name="{{'wiredState'+position}}" required>
                                                                        <option *ngFor="let state of stateList;" [value]="state" [selected]="state === selectedPlacement">{{state}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. PIC Contact No:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'wiredContactNo'+position}}" required [(ngModel)]="vehicleObj.installation.contactNo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. PIC Email Address:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'wiredEmail'+position}}" required [(ngModel)]="vehicleObj.installation.email">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Proposal Instl. Date:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <div class="field__icon">
                                                                    <div class="field__icon-inner">
                                                                        <mat-datepicker-toggle [for]="ProposalDatepicker">
                                                                            <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                                        </mat-datepicker-toggle>
                                                                    </div>
                                                                </div>

                                                                <input [matDatepicker]="ProposalDatepicker" (focus)="ProposalDatepicker.open()" (click)="ProposalDatepicker.open()" type="text" name="{{'wiredProposedDate'+position}}" [min]="minDate" class="field__input" readonly dom-states required [(ngModel)]="vehicleObj.installation.proposedDate">

                                                                <div class="field__ui"></div>
                                                            </div>

                                                            <mat-datepicker #ProposalDatepicker [touchUi]="datepickerTouchUi">
                                                            </mat-datepicker>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Propose Instl.Time:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <div class="field__icon">
                                                                    <div class="field__icon-inner">
                                                                        <img src="assets/images/scss-components/key-value-icons/icon-time-green.svg" width="24" height="24" alt="" class="field__icon-image">
                                                                    </div>
                                                                </div>

                                                                <input type="time" class="field__input" name="{{'wiredProposedTime'+position}}" required [(ngModel)]="vehicleObj.installation.proposedTime">

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Installation Remarks:
                                                    </div>
                
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" maxlength="150" class="field__input" name="{{'wiredInstallationRemarks'+position}}" [(ngModel)]="vehicleObj.installationRemarks">
                                                                </label>
                
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__item">
                                            <hr class="divider" />
                                        </div>

                                        <div class="field-group__item --2-col">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Contact Status:


                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.contactStatus || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Assigned installer:

                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.assignedInstaller || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. Date:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.installationDate || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. Status:

                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.installationStatus || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Remark:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.remark || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="md-box__contents-container" [hidden]="!vehicleObj.isActive" *ngIf="vehicleObj.installationType == 'PLUG_PLAY'" @slideToggle>
                                <div class="md-box__contents">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Installation Type:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #wired type="radio" name="{{'type'+position}}" class="inline-input__input" value="WIRED" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'wired', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Wired</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #plugnplay type="radio" name="{{'type'+position}}" class="inline-input__input" value="PLUG_PLAY" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'plugnplay', position)">
                                                                    <span class=" inline-input__icon"></span>
                                                                    <span>Plug &amp; Play</span>
                                                                </label>

                                                                <!-- <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #plugnplaycourier type="radio" name="{{'type'+position}}" class="inline-input__input" value="PLUG_PLAY_COURIER" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'plugnplaycourier', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Plug &amp; Play Courier</span>
                                                                </label> -->
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #customised type="radio" name="{{'type'+position}}" class="inline-input__input" value="CUSTOM" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'customised', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Customised</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="md-box__contents --bg-white">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item --2-col">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Current Provider:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ vehicleObj.thirdPartyName }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Device Provider:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.deviceProviderId" required name="{{'plugnplayDeviceProviderId'+position}}" (change)="validateProvider(position)">
                                                                        <option *ngFor="let provider of deviceProviderList;" [value]="provider.id" [selected]="provider.id === vehicleObj.deviceProviderId">{{provider.thirdPartyName}}</option>
                                                                    </select>
                                                                </label>
                                                                <!-- {{vehicleObj.thirdPartyName}} -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Type:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" required [(ngModel)]="vehicleObj.vehicleType" name="{{'plugnplayVehicleType'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleType of vehicleTypeList;" [value]="vehicleType" [selected]="vehicleType === vehicleObj.vehicleType">{{vehicleType}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Make:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleMakeId" required name="{{'plugnplayVehicleMakeId'+position}}" (change)="getVehicleModelList(vehicleObj.vehicleMakeId, position)" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleMake of vehicleMakeList;" [value]="vehicleMake.id" [selected]="vehicleMake.id === vehicleObj.vehicleMakeId">{{vehicleMake.brandName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Model:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleModelId" name="{{'plugnplayVehicleModelId'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleModel of vehicleModelList[position];" [value]="vehicleModel.id" [selected]="vehicleModel.id === vehicleObj.vehicleModelId">{{vehicleModel.modelName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Model Details:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayVehicleModelDetails'+position}}" [(ngModel)]="vehicleObj.vehicleModelDetails" [disabled]="isEditVehicleInfo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Year:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleYear" name="{{'plugnplayVehicleYear'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let year of yearList;" [value]="year" [selected]="year === vehicleObj.vehicleYear">{{year}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Number Plate:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayNumberPlate'+position}}" required [(ngModel)]="vehicleObj.numberPlate" [disabled]="isEditVehicleInfo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        MOB:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'plugnplayMob'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.mob" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Yes</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'plugnplayMob'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.mob" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ formatYesOrNo(vehicleObj.mob) }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Roaming:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'plugnplayRoaming'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.roaming" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Yes</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'plugnplayRoaming'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.roaming" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ formatYesOrNo(vehicleObj.roaming) }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Delivery PIC Name:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayPicName'+position}}" required [(ngModel)]="vehicleObj.delivery.picName">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Delivery Address:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayAddress'+position}}" required [(ngModel)]="vehicleObj.delivery.address">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Postcode:
    
                                                        <span class="indicator-green">*</span>
                                                    </div>
    
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="number" class="field__input" name="{{'plugnplayPostcode'+position}}" required [(ngModel)]="vehicleObj.delivery.postcode">
                                                                </label>
    
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        State:
                                                        <span class="indicator-green">*</span>
                                                    </div>                                                
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.delivery.state" name="{{'plugnplayState'+position}}" required>
                                                                        <option *ngFor="let state of stateList;" [value]="state" [selected]="state === selectedPlacement">{{state}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        PIC Contact No:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayContactNo'+position}}" required [(ngModel)]="vehicleObj.delivery.contactNo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        PIC Email Address:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayEmail'+position}}" required [(ngModel)]="vehicleObj.delivery.email">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Installation Remarks:
                                                    </div>
                
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" maxlength="150" class="field__input" name="{{'plugnplayInstallationRemarks'+position}}" [(ngModel)]="vehicleObj.installationRemarks">
                                                                </label>
                
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__item">
                                            <hr class="divider" />
                                        </div>

                                        <div class="field-group__item --2-col">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Device IMEI:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" minlength="15" maxlength="15" name="{{'plugnplayimei'+position}}" [(ngModel)]="vehicleObj.imei">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    {{ vehicleObj.imei }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        ICCID:

                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    <input type="text" minlength="19" maxlength="19" class="field__input" name="{{'plugnplayiccid'+position}}" [(ngModel)]="vehicleObj.iccid">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    {{ vehicleObj.iccid }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        MSISDN:

                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplaymsisdn'+position}}" [(ngModel)]="vehicleObj.msisdn">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    {{ vehicleObj.msisdn }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="md-box__contents-container" [hidden]="!vehicleObj.isActive" *ngIf="vehicleObj.installationType == 'PLUG_PLAY_COURIER'" @slideToggle>
                                <div class="md-box__contents">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Installation Type:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #wired type="radio" name="{{'type'+position}}" class="inline-input__input" value="WIRED" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'wired', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Wired</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #plugnplay type="radio" name="{{'type'+position}}" class="inline-input__input" value="PLUG_PLAY" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'plugnplay', position)">
                                                                    <span class=" inline-input__icon"></span>
                                                                    <span>Plug &amp; Play</span>
                                                                </label>

                                                                <!-- <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #plugnplaycourier type="radio" name="{{'type'+position}}" class="inline-input__input" value="PLUG_PLAY_COURIER" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'plugnplaycourier', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Plug &amp; Play Courier</span>
                                                                </label> 

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #customised type="radio" name="{{'type'+position}}" class="inline-input__input" value="CUSTOM" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'customised', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Customised</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="md-box__contents --bg-white">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item --2-col">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Current Provider:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ vehicleObj.thirdPartyName }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Device Provider:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.deviceProviderId" required name="{{'plugnplayDeviceProviderId'+position}}" (change)="validateProvider(position)">
                                                                        <option *ngFor="let provider of deviceProviderList;" [value]="provider.id" [selected]="provider.id === vehicleObj.deviceProviderId">{{provider.thirdPartyName}}</option>
                                                                    </select>
                                                                </label>
                                                                <!-- {{vehicleObj.thirdPartyName}} -
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Type:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" required [(ngModel)]="vehicleObj.vehicleType" name="{{'plugnplayVehicleType'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleType of vehicleTypeList;" [value]="vehicleType" [selected]="vehicleType === vehicleObj.vehicleType">{{vehicleType}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Make:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleMakeId" required name="{{'plugnplayVehicleMakeId'+position}}" (change)="getVehicleModelList(vehicleObj.vehicleMakeId, position)" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleMake of vehicleMakeList;" [value]="vehicleMake.id" [selected]="vehicleMake.id === vehicleObj.vehicleMakeId">{{vehicleMake.brandName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Model:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleModelId" name="{{'plugnplayVehicleModelId'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleModel of vehicleModelList[position];" [value]="vehicleModel.id" [selected]="vehicleModel.id === vehicleObj.vehicleModelId">{{vehicleModel.modelName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Model Details:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayVehicleModelDetails'+position}}" [(ngModel)]="vehicleObj.vehicleModelDetails" [disabled]="isEditVehicleInfo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Year:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleYear" name="{{'plugnplayVehicleYear'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let year of yearList;" [value]="year" [selected]="year === vehicleObj.vehicleYear">{{year}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Number Plate:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayNumberPlate'+position}}" required [(ngModel)]="vehicleObj.numberPlate" [disabled]="isEditVehicleInfo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        MOB:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'plugnplayMob'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.mob" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Yes</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'plugnplayMob'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.mob" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ formatYesOrNo(vehicleObj.mob) }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Roaming:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'plugnplayRoaming'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.roaming" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Yes</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'plugnplayRoaming'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.roaming" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ formatYesOrNo(vehicleObj.roaming) }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Delivery PIC Name:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayPicName'+position}}" required [(ngModel)]="vehicleObj.delivery.picName">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Delivery Address:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayAddress'+position}}" required [(ngModel)]="vehicleObj.delivery.address">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Postcode:
    
                                                        <span class="indicator-green">*</span>
                                                    </div>
    
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="number" class="field__input" name="{{'plugnplayPostcode'+position}}" required [(ngModel)]="vehicleObj.delivery.postcode">
                                                                </label>
    
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        State:
                                                        <span class="indicator-green">*</span>
                                                    </div>                                                
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.delivery.state" name="{{'plugnplayState'+position}}" required>
                                                                        <option *ngFor="let state of stateList;" [value]="state" [selected]="state === selectedPlacement">{{state}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        PIC Contact No:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayContactNo'+position}}" required [(ngModel)]="vehicleObj.delivery.contactNo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        PIC Email Address:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplayEmail'+position}}" required [(ngModel)]="vehicleObj.delivery.email">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Installation Remarks:
                                                    </div>
                
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" maxlength="150" class="field__input" name="{{'plugnplayInstallationRemarks'+position}}" [(ngModel)]="vehicleObj.installationRemarks">
                                                                </label>
                
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__item">
                                            <hr class="divider" />
                                        </div>

                                        <div class="field-group__item --2-col">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Device IMEI:

                                                        <span class="indicator-green" *ngIf="!isEditVehicleInfo">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" minlength="15" maxlength="15" name="{{'plugnplayimei'+position}}" [(ngModel)]="vehicleObj.imei">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    {{ vehicleObj.imei }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        ICCID:

                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    <input type="text" minlength="19" maxlength="19" class="field__input" name="{{'plugnplayiccid'+position}}" [(ngModel)]="vehicleObj.iccid">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    {{ vehicleObj.iccid }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        MSISDN:

                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'plugnplaymsisdn'+position}}" [(ngModel)]="vehicleObj.msisdn">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="field__holder">
                                                                    {{ vehicleObj.msisdn }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                             -->
                            <div class="md-box__contents-container" [hidden]="!vehicleObj.isActive" *ngIf="vehicleObj.installationType == 'CUSTOM'" @slideToggle>
                                <div class="md-box__contents">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Installation Type:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #wired type="radio" name="{{'type'+position}}" class="inline-input__input" value="WIRED" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'wired', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Wired</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #plugnplay type="radio" name="{{'type'+position}}" class="inline-input__input" value="PLUG_PLAY" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'plugnplay', position)">
                                                                    <span class=" inline-input__icon"></span>
                                                                    <span>Plug &amp; Play</span>
                                                                </label>

                                                                <!-- <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #plugnplaycourier type="radio" name="{{'type'+position}}" class="inline-input__input" value="PLUG_PLAY_COURIER" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'plugnplaycourier', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Plug &amp; Play Courier</span>
                                                                </label> -->

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input #customised type="radio" name="{{'type'+position}}" class="inline-input__input" value="CUSTOM" [(ngModel)]="vehicleObj.installationType" (change)="installationTypeChange($event, 'customised', position)">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Customised</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="md-box__contents --bg-white">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item --2-col">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Current Provider:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ vehicleObj.thirdPartyName }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Device Provider:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" required [(ngModel)]="vehicleObj.deviceProviderId" name="{{'customisedDeviceProviderId'+position}}" (change)="validateProvider(position)">
                                                                        <option *ngFor="let provider of deviceProviderList;" [value]="provider.id" [selected]="provider.id === vehicleObj.deviceProviderId">{{provider.thirdPartyName}}</option>
                                                                    </select>
                                                                </label>
                                                                <!-- {{vehicleObj.thirdPartyName}} -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Type:

                                                        <!-- <span class="indicator-green">*</span> -->
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" required [(ngModel)]="vehicleObj.vehicleType" name="{{'customisedVehicleType'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleType of vehicleTypeList;" [value]="vehicleType" [selected]="vehicleType === vehicleObj.vehicleType">{{vehicleType}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Make:

                                                        <!-- <span class="indicator-green">*</span> -->
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" required [(ngModel)]="vehicleObj.vehicleMakeId" name="{{'customisedVehicleMakeId'+position}}" (change)="getVehicleModelList(vehicleObj.vehicleMakeId, position)" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleMake of vehicleMakeList;" [value]="vehicleMake.id" [selected]="vehicleMake.id === vehicleObj.vehicleMakeId">{{vehicleMake.brandName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Model:

                                                        <!-- <span class="indicator-green">*</span> -->
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleModelId" name="{{'customisedVehicleModelId'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let vehicleModel of vehicleModelList[position];" [value]="vehicleModel.id" [selected]="vehicleModel.id === vehicleObj.vehicleModelId">{{vehicleModel.modelName}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Model Details:

                                                        <!-- <span class="indicator-green">*</span> -->
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'customisedVehicleModelDetails'+position}}" [(ngModel)]="vehicleObj.vehicleModelDetails" [disabled]="isEditVehicleInfo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Vehicle Year:

                                                        <!-- <span class="indicator-green">*</span> -->
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.vehicleYear" name="{{'customisedVehicleYear'+position}}" [disabled]="isEditVehicleInfo">
                                                                        <option *ngFor="let year of yearList;" [value]="year" [selected]="year === vehicleObj.vehicleYear">{{year}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Number Plate:

                                                        <!-- <span class="indicator-green">*</span> -->
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'customisedNumberPlate'+position}}" required [(ngModel)]="vehicleObj.numberPlate" [disabled]="isEditVehicleInfo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Selected Devices:
    
                                                        <span class="indicator-green">*</span>
                                                    </div>
    
                                                    <div class="field-group__groups-col" [class.--active]="devicesSearchDOMStates.isFocused" *ngIf="searchBy === 'devices'" click-outside (clickOutside)="onOutsideClickDeviceReaction($event)">
                                                            <div class="field" #devicesField>
                                                                <div class="field__elems">
                                                                    <label class="field__holder">
                                                                        <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                                            {{ deviceSelectedLabel }}
                                                                        </div>
                            
                                                                        <input id="deviceSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #devicesSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterDevicesSelection($event.target.value)">
                                                                    </label>
                            
                                                                    <div class="field__ui"></div>
                                                                </div>
                            
                                                                <div class="field__floater" [class.--bottomEdge]="isBtmBorderEdge" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                                                    <ul class="inline-input-list">
                                                                        <li *ngIf="!customizeDevicesModel.devices.length" class="inline-input-list__item">
                                                                            <label class="inline-input">
                                                                                No devices found
                                                                            </label>
                                                                        </li>
                            
                                                                        <ng-container *ngFor="let group of customizeDevicesModel.devices;">
                                                                            <ng-container>
                                                                                <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                                                    <label class="inline-input">
                                                                                        <input type="checkbox" [name]="item.modelId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDevices[item.modelId]" (ngModelChange)="onDevicesSelectChange(item.modelId, group.deviceId)">
                            
                                                                                        <span class="inline-input__icon"></span>
                            
                                                                                        {{ group.name }}<br>
                                                                                        {{ item.modelName }}
    
                                                                                    </label>
                                                                                </li>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Roaming:

                                                        <!-- <span class="indicator-green">*</span> -->
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems" *ngIf="!isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'customisedRoaming'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.roaming" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>Yes</span>
                                                                </label>

                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    <input type="radio" name="{{'customisedRoaming'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.roaming" [disabled]="isEditVehicleInfo">
                                                                    <span class="inline-input__icon"></span>
                                                                    <span>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="field__elems" *ngIf="isEditVehicleInfo">
                                                                <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                    {{ formatYesOrNo(vehicleObj.roaming) }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. PIC Name:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'customisedPicName'+position}}" required [(ngModel)]="vehicleObj.installation.picName">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. Address:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'customisedAddress'+position}}" required [(ngModel)]="vehicleObj.installation.address">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Postcode:
    
                                                        <span class="indicator-green">*</span>
                                                    </div>
    
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="number" class="field__input" name="{{'customisedPostcode'+position}}" required [(ngModel)]="vehicleObj.installation.postcode">
                                                                </label>
    
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        State:
                                                        <span class="indicator-green">*</span>
                                                    </div>
    
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="select">
                                                                    <select class="select__input" [(ngModel)]="vehicleObj.installation.state" name="{{'customisedState'+position}}" required>
                                                                        <option *ngFor="let state of stateList;" [value]="state" [selected]="state === selectedPlacement">{{state}}</option>
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. Contact No:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'customisedContactNo'+position}}" required [(ngModel)]="vehicleObj.installation.contactNo">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. Email Address:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'customisedEmail'+position}}" required [(ngModel)]="vehicleObj.installation.email">
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Proposal Instl. Date:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <div class="field__icon">
                                                                    <div class="field__icon-inner">
                                                                        <mat-datepicker-toggle [for]="ProposalDatepicker">
                                                                            <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                                        </mat-datepicker-toggle>
                                                                    </div>
                                                                </div>

                                                                <input [matDatepicker]="ProposalDatepicker" (focus)="ProposalDatepicker.open()" (click)="ProposalDatepicker.open()" type="text" name="{{'customisedProposedDate'+position}}" [min]="minDate" class="field__input" readonly dom-states required [(ngModel)]="vehicleObj.installation.proposedDate">

                                                                <div class="field__ui"></div>
                                                            </div>

                                                            <mat-datepicker #ProposalDatepicker [touchUi]="datepickerTouchUi">
                                                            </mat-datepicker>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Propose Instl.Time:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <div class="field__icon">
                                                                    <div class="field__icon-inner">
                                                                        <img src="assets/images/scss-components/key-value-icons/icon-time-green.svg" width="24" height="24" alt="" class="field__icon-image">
                                                                    </div>
                                                                </div>

                                                                <input type="time" class="field__input" name="{{'customisedProposedTime'+position}}" required [(ngModel)]="vehicleObj.installation.proposedTime">

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Installation Remarks:
                                                    </div>
                
                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" maxlength="150" class="field__input" name="{{'customisedInstallationRemarks'+position}}" [(ngModel)]="vehicleObj.installationRemarks">
                                                                </label>
                
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__item">
                                            <hr class="divider" />
                                        </div>

                                        <div class="field-group__item --2-col">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Contact Status:


                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.contactStatus || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Assigned installer:

                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.assignedInstaller || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. Date:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.installationDate || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Instl. Status:

                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.installationStatus || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Remark:
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                {{vehicleObj.remark || 'None'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="inline-button__field">
                        <button class=" button inline-button__inputs --dark-grey" type="button" (click)="navigateManageDeviceView()">
                            Back
                        </button>

                        <button class="button inline-button__inputs" type="button" (click)="showPopup(updatePopup)" [disabled]="vehicleForm.invalid || hasSameProvider == true  || (selectInstallationType === 'customised' && selectedDevicesList.length === 0)">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #updatePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Update?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="switchProvider();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #fileUploadPopup="popup" classes="--wide-more" [disableClickOutside]="true" (onClickClosed)="closeFileUploadPopup()">
    <app-upload #fileUpload [importErrorPopup]="batchImportErrorPopup"></app-upload>
    <div class="block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" *ngIf="fileUpload.fileUploadList?.length > 0" (click)="hidePopup();">
                Submit
            </button>

            <button class=" button inline-button__inputs --dark-grey" type="button" (click)="closeFileUploadPopup()">
                Cancel
            </button>
        </div>
    </div>
</app-popup>

<app-popup #batchImportErrorPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert" [innerHTML]="fileUpload.batchErrorMessage"></span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(fileUploadPopup);">
                OK
            </button>
        </div>
    </div>
    <!-- <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div> -->
</app-popup>
