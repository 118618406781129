import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
// import imageCompression from 'browser-image-compression';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from './../../../common/spinner/spinner.component';

// Service
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { DriverService } from './../../../../_services/driver/driver.service';
import { PopupService } from '../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as passwordUtil from '../../../../util/passwordUtil';
import * as AsyncUtil from './../../../../util/asyncUtil';
import * as ComponentUtil from './../../../../util/componentUtil';
import * as DomUtil from './../../../../util/domUtil';
import * as StringUtil from './../../../../util/stringUtil';
import * as imageUtil from '../../../../util/imageUtil';

// Constant
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

import * as PasswordUtil from './../../../../util/passwordUtil';

import { UserService } from './../../../../_services/user/user.service';

@Component({
    selector: 'app-edit-driver',
    templateUrl: './edit-driver.component.html',
    styleUrls: ['./edit-driver.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class EditDriverComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild('editCropImagePopup',{static:false}) editCropImagePopup;
    @ViewChild(ImageCropperComponent,{static:false}) imageCropper: ImageCropperComponent;
    @ViewChild("cropImage_spinner",{static:false}) cropImage_spinner: SpinnerComponent;

    // Drivers Tag Selector/Floater
    @ViewChild('driverTagField', { static: false }) driverTagField;
    driverTagList: Array<any> = [];
    driverTagModel: any = {};
    selectedDriverTag: any = {};
    selectedDriverTagGroups: any = {};
    selectedDriverTagList: any = [];
    driverTagSelectedLabel: string;

    // General Selector Filter Selection when Typing
    subjectSelectionFloaterIsShown: boolean = false;
    filterSearchString: string = ""; //store filter search string

    imageChangedEvent: any = '';
    croppedImageData: any = '';

    driverId: number;
    currentTab: number = 1;
    newPassword: any = {
        password: '',
        passwordStrength: '',
        confirmPassword: '',
    };
    actionMessage: string;
    driverDBDetails: any = {};
    driverUpdateDetails: any = {};
    message = Message;
    passwordValidation: any = {
        hasError: false,
        isMatch: true,
        isEnoughLength: true,
        isOneUpperChar: true,
        isOneLowerChar: true,
        isOneNumber: true
    };

    minDate: any = moment().format();
    isEditFail: boolean = false;

    @ViewChild('imageAlertPopup',{static:false}) imageAlertPopup;
    hasImage: boolean = false;
    isImageChanged: boolean = false;
    maxSizeMB = environment.appConfig.createManage.maxSizeMB;
    maxWidthOrHeight = environment.appConfig.createManage.maxWidthOrHeight;
    maxSizeImageMB = environment.appConfig.createManage.maxSizeImageMB;
    imageAlertError = null;

    //date picket touch ui
    datepickerTouchUi: boolean = false;
    lastResizeTime: any = new Date(0);
    resizeTimeout: any;

    routerSubscription: Subscription;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    formModel: any = {
        oldPassword: '',
        password: '',
        passwordStrength: '',
        confirmPassword: ''
    };

    // passwordValidation: any = {
    //     hasError: false,
    //     isMatch: true,
    //     isEnoughLength: true,
    //     isOneUpperChar: true,
    //     isOneLowerChar: true,
    //     isOneNumber: true
    // };

    @ViewChild('passwordPolicyPopup',{static:false}) passwordPolicyPopup;
    @ViewChild('passwordChangePopup',{static:false}) passwordChangePopup;
    @ViewChild('driverForm',{static:false}) driverForm;

    error = '';

    constructor(
        private platform: Platform,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private popupService: PopupService,
        private driverService: DriverService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        this.driverId = this.route.snapshot.params.id;
        await this.onInitCommonFunction();
        this.updateDatepickerTouchUi();

        await this.checkUnviewedCampaign();

        // Add event listener to route events to allow same page navigations
        ComponentUtil.makeSamePageNavigationWorks({
            pageUrlConstant: 'create-manage/drivers/edit/',
            componentSubscription: this.routerSubscription,
            componentPreInitCallback: null,
            componentInitCallback: this.ngOnInit.bind(this),
            componentDestroyCallback: null,
            componentRouter: this.router
        });
        DomUtil.autoFocusForm(this.el);

        this.currentTab = 1;
    }

    async getAllDriverTags() {
        this.pageSpinner.show();
        try {
            const driverTagResult = await this.driverService.getDriverTagListByCompanyIdAndUserId(this.driverId);
            this.driverTagModel = driverTagResult.body || {};
            this.driverTagList = driverTagResult.body.driverTags.slice(0);
            this.onDriverTagAllSelectChange();
            this.toggleSubjectSelectionFloater(false);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async onInitCommonFunction() {
        this.pageSpinner.show();
        try {
            await this.getDriverDetails(this.driverId);
            await this.getAllDriverTags();
            this.populateRemindMinute();
            // this.setImage();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    // setImage() {
    //     if (this.driverUpdateDetails.photo) {
    //         this.driverUpdateDetails.photo = `data:image/jpeg;base64,${this.driverUpdateDetails.photo}`;
    //         this.hasImage = true;
    //     }
    // }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    onStrengthChanged($event) {
        if ($event === 30) {
            this.newPassword.passwordStrength = 'WEAK';
        } else if ($event === 60) {
            this.newPassword.passwordStrength = 'MODERATE';
        } else if ($event === 100) {
            this.newPassword.passwordStrength = 'STRONG';
        }
    }

    async getDriverDetails(driverId) {
        const res: any = await this.driverService.getDriverDetails(driverId);
        this.driverUpdateDetails = res.body.result;
        this.driverUpdateDetails.licenseDaysApplyAll = false;
        this.driverUpdateDetails.driverTagRemindApplyAll = false;
        if (this.driverUpdateDetails.photo) {
            this.driverUpdateDetails.photo = `data:image/jpeg;base64,${this.driverUpdateDetails.photo}`;
            this.hasImage = true;
        }
        this.driverDBDetails = Object.assign({}, this.driverUpdateDetails);
    }

    async updateDriverInfo() {
        this.pageSpinner.show();
        try {

            const updatedFields = {};
            for (const objKey in this.driverUpdateDetails) {
                if (objKey == 'password') {
                    if (this.newPassword.password.length == 0) {
                        delete this.driverUpdateDetails[objKey];
                    } else {
                        Object.assign(updatedFields, { [objKey]: this.newPassword.password });
                    }
                } else if (objKey == 'telNo') {
                    if (this.driverUpdateDetails[objKey] !== this.driverDBDetails[objKey]) {
                        Object.assign(updatedFields, { [objKey]: this.driverUpdateDetails[objKey] });
                    }
                } else if (objKey == 'drivingLicenseExpiryDate') {
                    if ((this.driverUpdateDetails[objKey]) !== null) {
                        Object.assign(updatedFields, { [objKey]: moment(this.driverUpdateDetails[objKey]).format("YYYY-MM-DD") });
                    } else {
                        Object.assign(updatedFields, { [objKey]: null });
                    }
                } else if (objKey == 'licenseExpReminderDays') {
                    if (this.driverUpdateDetails[objKey] !== this.driverDBDetails[objKey]) {
                        if (!StringUtil.isNotEmptyOrNull(this.driverUpdateDetails[objKey])) {
                            Object.assign(updatedFields, { [objKey]: 30 });
                        } else {
                            Object.assign(updatedFields, { [objKey]: parseInt(this.driverUpdateDetails[objKey]) });
                        }
                    }
                } else if (objKey == 'licenseDaysApplyAll') {
                    Object.assign(updatedFields, { [objKey]: this.driverUpdateDetails[objKey] });
                    // only true required reminder days
                    if (this.driverUpdateDetails[objKey]) {
                        if (!StringUtil.isNotEmptyOrNull(this.driverUpdateDetails['licenseExpReminderDays'])) {
                            Object.assign(updatedFields, { licenseExpReminderDays: 30 });
                        } else {
                            Object.assign(updatedFields, { licenseExpReminderDays: parseInt(this.driverUpdateDetails['licenseExpReminderDays']) });
                        }
                    }
                } else if (objKey == 'userId') {
                    Object.assign(updatedFields, { [objKey]: this.driverId });
                } else if (objKey !== 'userId') {

                    // trim leading & trailing whitespace for names
                    if (objKey == 'firstName' || objKey == 'lastName') {
                        if (StringUtil.isNotEmptyOrNull(this.driverUpdateDetails[objKey])) {
                            this.driverUpdateDetails[objKey] = this.driverUpdateDetails[objKey].trim();
                        }
                    }

                    if (objKey == 'photo') {

                        // Do not compress image before checking for unchanged flag
                        let newImage;
                        if (this.hasImage) {
                            const img: any = <HTMLElement>document.getElementById("imageId");
                            if (img) {
                                newImage = await imageUtil.compressImageSize(img.src, this.maxSizeMB, this.maxWidthOrHeight, this.platform); // maxSizeMB, maxWidthOrHeight are optional
                                await AsyncUtil.wait(2000); // Wait for image compress
                            } else {
                                newImage = null;
                            }
                            // Invalid image -> always update to blank
                            if (!newImage) {
                                this.driverUpdateDetails[objKey] = '';
                            } else if (this.isImageChanged) {
                                this.driverUpdateDetails[objKey] = newImage;
                            }
                        }
                        // console.log('old: ' + this.driverDBDetails.photo);
                        // console.log('new: ' + this.driverUpdateDetails.photo);
                    }

                    //Compare diff before and after
                    if (this.driverUpdateDetails[objKey] !== this.driverDBDetails[objKey]) {
                        Object.assign(updatedFields, { [objKey]: this.driverUpdateDetails[objKey] });
                    } else {
                        if (objKey == 'photo') {
                            console.debug('EditDrive: Image unchanged');
                        }
                    }

                }
            }

            if (this.driverUpdateDetails.driverTagId) {
                Object.assign(updatedFields, { driverTagId: parseInt(this.driverUpdateDetails.driverTagId) });
            }

            if (this.driverUpdateDetails.remindMinute) {
                Object.assign(updatedFields, { remindMinute: parseInt(this.driverUpdateDetails.remindMinute) });
            } else {
                //default 5 minutes
                Object.assign(updatedFields, { remindMinute: 5 });
            }

            if (this.driverUpdateDetails.driverTagRemindApplyAll) {
                Object.assign(updatedFields, { driverTagRemindApplyAll: this.driverUpdateDetails.driverTagRemindApplyAll });
            }

            const result = await this.driverService.updateDriverDetails(updatedFields);

            // window.scroll(0, 0); // Scroll to top
            const driverName = this.driverUpdateDetails.firstName + ' ' + this.driverUpdateDetails.lastName;

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Driver', driverName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = this.message.getMessage(this.message.MESSAGE.UPDATE_SUCCESS.value, "Driver", driverName);
                this.navigateSuccessToDriverView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    validatePassword() {
        this.passwordValidation = passwordUtil.passwordValidator(this.newPassword, this.passwordValidation);

        this.passwordValidation = PasswordUtil.passwordValidator(this.formModel, this.passwordValidation);
    }

    onPasswordStrengthChanged($event) {
        if ($event === 20) {
            this.formModel.passwordStrength = 'WEAK';
        } else if ($event === 40) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 60) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 80) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 100) {
            this.formModel.passwordStrength = 'STRONG';
        }
    }

    async changePassword() {
        if (this.formModel.password.length !== 0 && this.formModel.confirmPassword.length !== 0) {
            this.validatePassword();
            if (this.passwordValidation.hasError) {
                this.showPopup(this.passwordPolicyPopup);
            } else {
                try {
                    const result = await this.userService.checkAndChangePassword(this.formModel.oldPassword, this.formModel.password);

                    this.message = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Password');
                    if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                        this.message = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Password');
                    }
                    this.snackBar.openGenericSnackBar(this.message);
                    // this.showPopup(this.passwordChangePopup);
                } catch (e) {
                    this.snackBar.openStandardizedErrorSnackBar(e);
                    // this.error = error.error.errorMessage;
                }
            }
        }
    }

    reload() {
        this.formModel = {
            oldPassword: '',
            password: '',
            passwordStrength: '',
            confirmPassword: ''
        };
        this.driverForm.reset();
    }

    async chooseImage(item: any) {
        if (item.target.files.length > 0 && item.target.files[0].type.indexOf("image") > -1) {
            const imageFile = item.target.files[0];

            if ((imageFile.size / 1024 / 1024) < this.maxSizeImageMB) { //Check Image Size
                this.isImageChanged = true;
                this.hasImage = true;
                this.imageChangedEvent = item;
                this.showPopup(this.editCropImagePopup);
            } else {
                this.imageAlertError = 'sizeError';
                this.showPopup(this.imageAlertPopup);
            }
        } else {
            this.imageAlertError = 'fileError';
            this.showPopup(this.imageAlertPopup);
        }
    }

    navigateSuccessToDriverView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/drivers/view']);
    }

    navigateToDriverView() {
        this.routerProxyService.navigate(['/create-manage/drivers/view']);
    }

    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }

    updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

    confirmCropImage() {
        const img: any = <HTMLElement>document.getElementById("imageId");
        img.src = this.croppedImageData;
    }

    imageDirection(direction) {
        try {
            this.cropImage_spinner.show();
            if (direction === 'left') {
                this.imageCropper.rotateLeft();
            } else if (direction === 'right') {
                this.imageCropper.rotateRight();
            }
        } catch (e) {
            // do nothing
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImageData = event.base64;
        //const img: any = <HTMLElement>document.getElementById("imageId");
        //img.src = this.croppedImageData;
    }

    cropperReady() {
        const fileInput: any = document.getElementById('myFileInput');
        if (fileInput && fileInput.value) { fileInput.value = null; } // Clean previous file
        this.cropImage_spinner.hide();
    }

    async overview() {
        this.currentTab = 1;
    }

    async performance() {
        this.currentTab = 2;
    }

    populateRemindMinute() {
        for (let i = 0; i < this.driverTagList.length; i++ ) {
            if (this.driverTagList[i].driverTagId === Number(this.driverUpdateDetails.driverTagId)) {
                this.driverUpdateDetails.remindMinute = this.driverTagList[i].remindMinute;
                break;
            }
        }
    }

    /* ---- Driver Tag Selection Floater ---- */
    onDriverTagAllSelectChange() {

        // Note: Apply to ALL driver tag that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedDriverTagGroups.all;
        for (let i = 0; i < this.driverTagList.length; i++) {
            this.selectedDriverTag[this.driverTagList[i].driverTagId] = allIsSelected;
        }
        this.updateDriverTagSelectedLabel();
        this.focusDriverTagSearchBox();
    }
    onDriverTagSelectChange() {
        this.updateDriverTagSelectedLabel();
        this.focusDriverTagSearchBox();
        this.populateRemindMinute();
        this.toggleSubjectSelectionFloater(false);
    }
    private focusDriverTagSearchBox() {
        const searchBox = this.getDriverTagSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private getDriverTagSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.driverTagField.nativeElement.querySelector("#driverTagSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }
    private updateDriverTagSelectedLabel() {
        const driverTagSelected = this.driverTagList.find(tag => {
            return this.driverUpdateDetails.driverTagId === tag.driverTagId;
        });
        this.driverTagSelectedLabel = driverTagSelected && driverTagSelected.driverTagNo ? driverTagSelected.driverTagNo : "";
    }
    filterDriverTagSelection(searchString: string = "") {
        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.driverTagModel.driverTags = this.driverTagList;
        } else {
            this.driverTagModel.driverTags = this.driverTagList.filter(result => result.driverTagNo.toLowerCase().trim().indexOf(searchString) > -1);
        }
    }

    toggleSubjectSelectionFloater(bool: boolean) {
        this.subjectSelectionFloaterIsShown = bool;
    }

    onOutsideClickDriverTagReaction(clickEvent: Event): void {
        this.toggleSubjectSelectionFloater(false);
        this.filterDriverTagSelection();
    }
}
