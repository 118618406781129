import { Component } from '@angular/core';

import { RouterProxyService } from '../../../_services/router-proxy/router-proxy.service';

@Component({
    template: ''
})
export class HomePageComponent {

    constructor(
        private routerProxyService: RouterProxyService) {
        this.routerProxyService.navigateToHomePage();
    }

}
