export const modules = {
    DASHBOARD: {
        value: "DASHBOARD",
        label: "Dashboard"
    },
    MAP_VEHICLE: {
        value: "MAP_VEHICLE",
        label: "Live Trip Map"
    },
    MAP_VEHICLE_HISTORY: {
        value: "MAP_VEHICLE_HISTORY",
        label: "Past Trip Map"
    },
    MY_ACCOUNT: {
        value: "MY_ACCOUNT",
        label: "My Account"
    },
    SUPPORT_HELP: {
        value: "SUPPORT_HELP",
        label: "Support"
    },
    REPORTS: {
        value: "REPORTS",
        label: "Reports"
    },
    REPORT_TRIP_SUMMARY: {
        value: "REPORT_TRIP_SUMMARY",
        label: "Trip Summary Report"
    },
    REPORT_VIOLATION: {
        value: "REPORT_TRIP_VIOLATION",
        label: "Violation Report"
    },
    REPORT_GEOFENCE: {
        value: "REPORT_GEOFENCE",
        label: "Geofence Report"
    },
    REPORT_UTILISATION: {
        value: "REPORT_UTILISATION",
        label: "Utilasation Report"
    },
    REPORT_SCORING: {
        value: "REPORT_SCORING",
        label: "Scoring Report"
    },
    REPORT_MAINTENANCE: {
        value: "REPORT_MAINTENANCE",
        label: "Maintenance Report"
    },
    REPORT_FUEL: {
        value: "REPORT_FUEL",
        label: "Fuel Report"
    },
    CREATE_MANGE: {
        value: "CREATE_MANAGE",
        label: "Create Manage"
    },
    CRUD_VEHICLE: {
        value: "CRUD_VEHICLE",
        label: "Manage Vehicle"
    },
    CRUD_ADMIN: {
        value: "CRUD_ADMIN",
        label: "Manage Admin"
    },
    CRUD_DRIVER_TAG: {
        value: "CRUD_DRIVER_TAG",
        label: "Manage Driver Tag"
    },
    CRUD_DRIVER: {
        value: "CRUD_DRIVER",
        label: "Manage Driver"
    },
    CRUD_GROUP: {
        value: "CRUD_GROUP",
        label: "Manage Group"
    },
    CRUD_MAINTENANCE: {
        value: "CRUD_MAINTENANCE",
        label: "Manage Maintenance"
    },
    CRUD_DRIVING_LIMIT: {
        value: "CRUD_DRIVING_LIMIT",
        label: "Manage Driving Limit"
    },
    CRUD_TRIP_TAG: {
        value: "CRUD_TRIP_TAG",
        label: "Manage Trip Tag"
    },
    CRUD_FUEL: {
        value: "CRUD_FUEL",
        label: "Manage Fuel"
    },
    LOGOUT: {
        value: "LOGOUT",
        label: "Logout"
    },
    SALE_DASHBOARD: {
        value: "SALE_DASHBOARD",
        label: "Sale Dashboard"
    },
    CRUD_ROUTE: {
        value: "CRUD_ROUTE",
        label: "Manage Route"
    },
    BO_SUPPORT_CRUD_DEVICE: {
        value: "BO_SUPPORT_CRUD_DEVICE",
        label: "Support Manage Device"
    },
};
