<div class="section">
    <app-spinner #page_spinner [fullscreen]="true"></app-spinner>
    <div class="wrapper">
        <div class="block">
            <h1 class="heading --level-2 --partial-underline   c-green">
                Health Status
            </h1>
        </div>

        <div class="block">
            <div class="key-value-icons --1-col@xs-dn">
                <div class="key-value-icon">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-health-fuel.svg" alt="" width="37" height="25">
                        </div>

                        <div class="key-value-icon__value c-green">
                            <span class="health-fuel__amount">{{vehiclePanel.trip.fuelLevel || '-'}}%</span>
                            <!-- <span class="health-fuel__percentage">({{ fuelPercentage || '-'}}%)</span> -->
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Current Fuel Level
                    </div>
                </div>

                <div class="key-value-icon">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-battery.svg" alt="" width="31" height="23">
                        </div>

                        <ng-container>
                            <div class="key-value-icon__value" [class.c-green]="vehicleHealthAlerts.isBatteryLow < 1 || vehicleHealthAlerts.isBatteryLow == null" [class.c-red]="vehicleHealthAlerts.isBatteryLow > 0">
                                <span *ngIf="vehicleHealthAlerts.isBatteryLow > 0">Bad</span>
                                <span *ngIf="vehicleHealthAlerts.isBatteryLow < 1">Good</span>
                            </div>
                        </ng-container>
                    </div>

                    <div class="key-value-icon__key">
                        Current Battery Status
                    </div>
                </div>

                <div class="key-value-icon">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-temperature.svg" alt="" width="19" height="35">
                        </div>

                        <ng-container>
                            <div class="key-value-icon__value" [class.c-red]="vehicleHealthAlerts.batteryLevel < voltsTreshold && vehicleHealthAlerts.isTemperatureHigh > 0" [class.c-grey]="vehicleHealthAlerts.batteryLevel >= voltsTreshold && vehicleHealthAlerts.isTemperatureHigh < 1 || vehicleHealthAlerts.isBatteryLow == null">
                                <span *ngIf="vehicleHealthAlerts.batteryLevel >= voltsTreshold">N/A</span>
                                <span *ngIf="vehicleHealthAlerts.batteryLevel < voltsTreshold && vehicleHealthAlerts.isTemperatureHigh > 0">High</span>
                                <span *ngIf="vehicleHealthAlerts.batteryLevel < voltsTreshold && vehicleHealthAlerts.isTemperatureHigh < 1">Normal</span>
                            </div>
                        </ng-container>
                    </div>

                    <div class="key-value-icon__key">
                        Engine Temperature
                    </div>
                </div>

                <div class="key-value-icon">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-engine.svg" alt="" width="38" height="30">
                        </div>

                        <ng-container>
                            <div class="key-value-icon__value" [class.c-green]="vehicleHealthAlerts.batteryLevel < voltsTreshold && vehicleHealthAlerts.isDtcPoor < 1 || vehicleHealthAlerts.isBatteryLow == null" [class.c-red]="vehicleHealthAlerts.batteryLevel < voltsTreshold && vehicleHealthAlerts.isDtcPoor > 0" [class.c-grey]="vehicleHealthAlerts.batteryLevel >= voltsTreshold">
                                <span *ngIf="vehicleHealthAlerts.batteryLevel >= voltsTreshold">N/A</span>
                                <span *ngIf="vehicleHealthAlerts.batteryLevel < voltsTreshold && vehicleHealthAlerts.isDtcPoor > 0">Poor</span>
                                <span *ngIf="vehicleHealthAlerts.batteryLevel < voltsTreshold && vehicleHealthAlerts.isDtcPoor < 1">Good</span>
                            </div>
                        </ng-container>
                    </div>

                    <div class="key-value-icon__key">
                        DTC (Engine Status)
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section --border-top" *ngIf="viewable">
    <app-spinner #maintenance_spinner [fullscreen]="false"></app-spinner>
    <div class="wrapper">
        <div class="block">
            <div class="grid --gutter">
                <div class="grid__block   w-8of12@xs">
                    <h1 class="heading --level-2 --partial-underline   c-green">
                        Upcoming Maintenance
                    </h1>
                </div>

                <div class="grid__block   w-4of12@xs   ta-r@sm" *ngIf="editable">
                    <a class="button --min-width" (click)="navigateToViewMaintenanceByVehicleName(vehicleName)" *ngIf="allowManage">
                        Manage
                    </a>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="mobile-table-scroll-notice">
                <div class="mobile-table-scroll-notice__icon"></div>

                <div class="mobile-table-scroll-notice__main">
                    Please scroll left/right to browse the table.
                </div>
            </div>

            <div class="maintenance-table">
                <table class="maintenance-table__elem">
                    <thead>
                        <tr>
                            <th></th>

                            <th scope="col">
                                <div class="maintenance-table__column-heading --distance">
                                    Distance
                                    <br> To Next Service
                                </div>
                            </th>

                            <th scope="col">
                                <div class="maintenance-table__column-heading --engine">
                                    Engine Hours
                                    <br> To Next Service
                                </div>
                            </th>

                            <th scope="col">
                                <div class="maintenance-table__column-heading --days">
                                    Days
                                    <br> To Next Service
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody *ngIf="upcomingMaintenance.result.length > 0">
                        <tr *ngFor="let schedule of upcomingMaintenance.result">
                            <th scope="row" class="maintenance-table__row-header">
                                {{ schedule.maintenanceName }}
                            </th>

                            <td>
                                <div class="maintenance-table__emphasis" [class.c-red]="schedule.distanceUntil < 0">
                                    {{ schedule.distanceUntilLabel }}
                                </div>

                                <div class="maintenance-table__notes" *ngIf="schedule.nextOdometer != null">
                                    (Scheduled {{ schedule.nextOdometerLabel }})
                                </div>
                            </td>

                            <td>
                                <div class="maintenance-table__emphasis" [class.c-red]="schedule.engineHourUntil < 0">
                                    {{schedule.engineHourUntilLabel}}
                                </div>

                                <div class="maintenance-table__notes" *ngIf="schedule.nextEngineHours != null">
                                    (Scheduled {{schedule.nextEngineHoursLabel}})
                                </div>
                            </td>

                            <td>
                                <div class="maintenance-table__emphasis" [class.c-red]="schedule.daysUntil < 0">
                                    {{ schedule.daysUntilLabel }}
                                </div>

                                <div class="maintenance-table__notes" *ngIf="schedule.nextServiceDate != null">
                                    (Scheduled {{ schedule.nextServiceDateLabel }})
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="no-record-found" *ngIf="upcomingMaintenance.result.length <= 0">
                        <tr>
                            <th scope="row" class="maintenance-table__row-header"> - </th>
                            <td> - </td>
                            <td> - </td>
                            <td> - </td>
                        </tr>
                    </tbody>
                </table>
                <div class="no-record-found" *ngIf="upcomingMaintenance.result.length <= 0">
                    <span>There is currently no upcoming maintenance</span>
                </div>
            </div>
        </div>

        <div class="block" *ngIf="upcomingMaintenance.totalRecord > 0">
            <div class="grid --gutter">
                <div class="grid__block   w-4of12@sm   as-c@sm">
                    <div class="notes">
                        Showing {{ upcomingPager.currentPage }} out of {{ upcomingPager.totalPages }}
                    </div>
                </div>

                <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                    <div class="pagination">
                        <button type="button" class="pagination__button" [class.--disabled]="upcomingPager.currentPage === 1" [disabled]="upcomingPager.currentPage === 1" (click)="getUpcomingMaintenance(upcomingPager.currentPage - 1)">
                            <span class="angle-arrow --left"></span>
                            <span class="ha">Previous Page</span>
                        </button>

                        <button type="button" *ngFor="let page of upcomingPager.pages" class="pagination__button" [class.--active]="page === upcomingPager.currentPage" (click)="getUpcomingMaintenance(page)">
                            {{ page }}
                        </button>

                        <button type="button" class="pagination__button" [class.--disabled]="upcomingPager.currentPage === upcomingPager.totalPages" [disabled]="upcomingPager.currentPage === upcomingPager.totalPages" (click)="getUpcomingMaintenance(upcomingPager.currentPage + 1)">
                            <span class="angle-arrow --right">
                            </span>
                            <span class="ha">Next Page</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Violation History-->
<div class="section --border-top">
    <app-spinner #violation_spinner [fullscreen]="false"></app-spinner>
    <div class="wrapper">
        <div class="block">
            <div class="grid --gutter">
                <div class="grid__block   w-5of12@sm">
                    <h1 class="heading --level-2 --partial-underline   c-green">
                        Violation History
                    </h1>
                </div>

                <div class="grid__block   w-7of12@sm">
                    <div class="date-range">
                        <div class="date-range__block --from">
                            <div class="field">
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <mat-datepicker-toggle [for]="FromDatepicker">
                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                            </mat-datepicker-toggle>
                                        </div>
                                    </div>

                                    <input [matDatepicker]="FromDatepicker" placeholder="From Date" readonly (focus)="FromDatepicker.open()" (click)="FromDatepicker.open()" [(ngModel)]="startdate" type="text" class="field__input" [max]="enddate">

                                    <div class="field__ui"></div>
                                </div>

                                <mat-datepicker #FromDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                            </div>
                        </div>

                        <div class="date-range__block --to">
                            <div class="field">
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <mat-datepicker-toggle [for]="ToDatepicker">
                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                            </mat-datepicker-toggle>
                                        </div>
                                    </div>

                                    <input [matDatepicker]="ToDatepicker" placeholder="To Date" readonly (focus)="ToDatepicker.open()" (click)="ToDatepicker.open()" [(ngModel)]="enddate" type="text" class="field__input" [min]="startdate" [max]="moment().startOf('day').format()">

                                    <div class="field__ui"></div>
                                </div>

                                <mat-datepicker #ToDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                            </div>
                        </div>

                        <div class="date-range__block --cta">
                            <button type="button" class="button" (click)="getVehicleViolationHistory()">
                                Go
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="key-value-icons">
                <button class="key-value-icon" (click)="onFilterByViolation('speeding')">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-speeding.svg" alt="" width="33" height="29">
                        </div>

                        <div class="key-value-icon__value" [class.c-grey]="violationHistory.grandTotalSpeedingTime == 0" [class.c-red]="violationHistory.grandTotalSpeedingTime > 0">
                            {{ violationHistory.grandTotalSpeedingTime || 0}}
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Overspeeding
                    </div>
                </button>

                <button class="key-value-icon" (click)="onFilterByViolation('harsh-acceleration')">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-acceleration.svg" alt="" width="33" height="29">
                        </div>

                        <div class="key-value-icon__value" [class.c-grey]="violationHistory.grandTotalHa == 0" [class.c-red]="violationHistory.grandTotalHa > 0">
                            {{ violationHistory.grandTotalHa || 0}}
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Harsh Acceleration
                    </div>
                </button>

                <button class="key-value-icon" (click)="onFilterByViolation('harsh-braking')">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-braking.svg" alt="" width="38" height="27">
                        </div>

                        <div class="key-value-icon__value" [class.c-grey]="violationHistory.grandTotalHb == 0" [class.c-red]="violationHistory.grandTotalHb > 0">
                            {{ violationHistory.grandTotalHb || 0}}
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Harsh Braking
                    </div>
                </button>

                <button class="key-value-icon" (click)="onFilterByViolation('harsh-turning')">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-turning.svg" alt="" width="25" height="25">
                        </div>

                        <div class="key-value-icon__value" [class.c-grey]="violationHistory.grandTotalHc == 0" [class.c-red]="violationHistory.grandTotalHc > 0">
                            {{ violationHistory.grandTotalHc || 0}}
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Harsh Turning
                    </div>
                </button>

                <button class="key-value-icon" (click)="onFilterByViolation('time-of-the-day')">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-time.svg" alt="" width="28" height="28">
                        </div>

                        <div class="key-value-icon__value" [class.c-grey]="violationHistory.grandTotalExceedTimeOfDay == 0" [class.c-red]="violationHistory.grandTotalExceedTimeOfDay > 0">
                            {{ violationHistory.grandTotalExceedTimeOfDay || 0}}
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Time of the Day
                    </div>
                </button>

                <button class="key-value-icon" (click)="onFilterByViolation('idling')">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-idling.svg" alt="" width="23" height="29">
                        </div>

                        <div class="key-value-icon__value" [class.c-grey]="violationHistory.grandTotalIdle == 0" [class.c-red]="violationHistory.grandTotalIdle > 0">
                            {{ violationHistory.grandTotalIdle || 0}}
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Idling
                    </div>
                </button>

                <button class="key-value-icon" (click)="onFilterByViolation('geofence-exit')">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-geofence.svg" alt="" width="37" height="28">
                        </div>

                        <div class="key-value-icon__value" [class.c-grey]="violationHistory.grandTotalGeofence == 0" [class.c-red]="violationHistory.grandTotalGeofence > 0">
                            {{ violationHistory.grandTotalGeofence || 0 }}
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Geofence
                    </div>
                </button>

                <button class="key-value-icon" (click)="onFilterByViolation('mileage')">
                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-mileage.svg" alt="" width="33" height="29">
                        </div>

                        <div class="key-value-icon__value" [class.c-grey]="violationHistory.grandTotalExceedMileage == 0" [class.c-red]="violationHistory.grandTotalExceedMileage > 0">
                            {{ violationHistory.grandTotalExceedMileage || 0}}
                        </div>
                    </div>

                    <div class="key-value-icon__key">
                        Mileage
                    </div>
                </button>
            </div>
        </div>

        <div class="block">
            <div class="tripdays">
                <ng-container *ngIf="violationHistory.result.length > 0">
                    <div class="tripday" [class.--active]="tripdayToggle1.active" *ngFor="let eachDay of violationHistory.result">
                        <button type="button" class="tripday__header" toggle #tripdayToggle1="toggle">
                            <div class="tripday__header-label">
                                {{ eachDay.dateLabel }}
                            </div>

                            <div class="tripday__header-sublabels">
                                <span class="c-dark-red">
                                    {{ eachDay.totalViolation }} Alerts
                                </span>
                            </div>
                        </button>

                        <div class="tripday__trips">
                            <div class="tripday__trip" *ngFor="let eachTrip of eachDay.tripViolationDetails">
                                <div class="tripday__trip-time">
                                    <div class="tripday__trip-time-stamp --green-dot">
                                        {{ eachTrip.gpsTimeStampStartLabel }}
                                    </div>

                                    <div class="tripday__trip-time-stamp --red-dot">
                                        {{ eachTrip.gpsTimeStampEndLabel }}
                                    </div>
                                </div>

                                <div class="tripday__trip-descs">
                                    <div class="tripday__trip-desc" *ngIf="eachTrip.totalSpeedingTime > 0">
                                        <div class="tripday__trip-desc-icon --IS_SPEEDING"></div>

                                        <div class="tripday__trip-desc-main">
                                            Speeding
                                            <span class="tripday__trip-desc-main-count" *ngIf="eachTrip.totalSpeedingTime > 1">({{ eachTrip.totalSpeedingTime }})</span>
                                        </div>
                                    </div>
                                    <div class="tripday__trip-desc" *ngIf="eachTrip.totalHA > 0">
                                        <div class="tripday__trip-desc-icon --HARSH_ACCELERATION"></div>

                                        <div class="tripday__trip-desc-main">
                                            Harsh Acceleration
                                            <span class="tripday__trip-desc-main-count" *ngIf="eachTrip.totalHA > 1">({{ eachTrip.totalHA }})</span>
                                        </div>
                                    </div>
                                    <div class="tripday__trip-desc" *ngIf="eachTrip.totalHB > 0">
                                        <div class="tripday__trip-desc-icon --HARSH_BRAKE"></div>
                                        <div class="tripday__trip-desc-main">
                                            Harsh Braking
                                            <span class="tripday__trip-desc-main-count" *ngIf="eachTrip.totalHB > 1">({{ eachTrip.totalHB }})</span>
                                        </div>
                                    </div>
                                    <div class="tripday__trip-desc" *ngIf="eachTrip.totalHC > 0">
                                        <div class="tripday__trip-desc-icon --HARSH_CORNERING"></div>

                                        <div class="tripday__trip-desc-main">
                                            Harsh Turn
                                            <span class="tripday__trip-desc-main-count" *ngIf="eachTrip.totalHC > 1">({{ eachTrip.totalHC }})</span>
                                        </div>
                                    </div>
                                    <div class="tripday__trip-desc" *ngIf="eachTrip.totalExceedTimeOfDay > 0">
                                        <div class="tripday__trip-desc-icon --EXCEED_TIME_OF_DAY"></div>

                                        <div class="tripday__trip-desc-main">
                                            Exceed Time of the Day
                                            <span class="tripday__trip-desc-main-count" *ngIf="eachTrip.totalExceedTimeOfDay > 1">({{ eachTrip.totalExceedTimeOfDay }})</span>
                                        </div>
                                    </div>
                                    <div class="tripday__trip-desc" *ngIf="eachTrip.totalIdle > 0">
                                        <div class="tripday__trip-desc-icon --IS_IDLING"></div>

                                        <div class="tripday__trip-desc-main">
                                            Idle
                                            <span class="tripday__trip-desc-main-count" *ngIf="eachTrip.totalIdle > 1">({{ eachTrip.totalIdle }})</span>
                                        </div>
                                    </div>
                                    <div class="tripday__trip-desc" *ngIf="(eachTrip.totalGeofenceEnter + eachTrip.totalGeofenceExit) > 0">
                                        <div class="tripday__trip-desc-icon --GEOFENCE"></div>

                                        <div class="tripday__trip-desc-main">
                                            Geofence
                                            <span class="tripday__trip-desc-main-count" *ngIf="(eachTrip.totalGeofenceEnter + eachTrip.totalGeofenceExit) > 1">({{ eachTrip.totalGeofenceLabel }})</span>
                                        </div>
                                    </div>
                                    <div class="tripday__trip-desc" *ngIf="eachTrip.totalExceedMileage > 0">
                                        <div class="tripday__trip-desc-icon --EXCEED_MILEAGE"></div>

                                        <div class="tripday__trip-desc-main">
                                            Mileage
                                            <span class="tripday__trip-desc-main-count" *ngIf="eachTrip.totalExceedMileage > 1">({{ eachTrip.totalExceedMileage }})</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="tripday__trip-actions">
                                    <button type="button" class="link --primary --u" (click)="showPastTripRoute(eachTrip.gpsTimeStampStart, eachTrip.gpsTimeStampEnd, eachTrip.tripId)">
                                        Show Trip
                                    </button>
                                </div>
                            </div>

                            <!-- <div class="tripday__trip">
                                    <div class="tripday__trip-time">
                                        <div class="tripday__trip-time-stamp --green-dot">
                                            06:00 AM
                                        </div>

                                        <div class="tripday__trip-time-stamp --red-dot">
                                            18:30 PM
                                        </div>
                                    </div>

                                    <div class="tripday__trip-descs">
                                        <div class="tripday__trip-desc">
                                            <div class="tripday__trip-desc-icon --HARSH_CORNERING"></div>

                                            <div class="tripday__trip-desc-main">
                                                Harsh Turn
                                                <span class="tripday__trip-desc-main-count">(2)</span>
                                            </div>
                                        </div>

                                        <div class="tripday__trip-desc">
                                            <div class="tripday__trip-desc-icon --IS_IDLING"></div>

                                            <div class="tripday__trip-desc-main">
                                                Idle
                                                <span class="tripday__trip-desc-main-count">(99)</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tripday__trip-actions">
                                        <button type="button" class="link --primary --u">
                                            Show Trip
                                        </button>
                                    </div>
                                </div> -->
                        </div>
                    </div>
                </ng-container>
                <div class="no-record-found" *ngIf="violationHistory.result.length <= 0">
                    <span>No Violation History Found</span>
                </div>
            </div>

            <div class="block" *ngIf="violationHistory.totalRecord > 0">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing {{ pager.currentPage }} out of {{ pager.totalPages }}
                        </div>
                    </div>

                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination">
                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getVehicleViolationHistory(pager.currentPage - 1)">
                                <span class="angle-arrow --left"></span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getVehicleViolationHistory(page)">
                                {{ page }}
                            </button>

                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getVehicleViolationHistory(pager.currentPage + 1)">
                                <span class="angle-arrow --right">
                                </span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
