<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        {{groupDetails.name}}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #vehiclesForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="compulsory-notes">
                    *Compulsory fields
                </div>

                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Group Info
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item hasPopup">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Group Name:
                                        <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" [(ngModel)]="groupDetails.name" name="name" #name="ngModel" required maxlength="45">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div class="field-error" *ngIf="name.errors?.required && (name.dirty || name.touched)">
                                            {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Group Name')}}
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Group Colour:
                                    </div>

                                    <div class="field-group__input">
                                        <div class="color-picker">
                                            <div class="color-picker__choice" [ngStyle]="{'background': colorChoose.code}"></div>

                                            <div id="colorpicker-floater-wrapper" class="color-picker__links toggle__item-groups" [class.--active]="colorPickerToggle.active">
                                                <button id="colorpicker-floater-toggler" class="toggle__item-groups-toggle link" (click)="colorPickerToggle.toggleActive()">
                                                    Pick a colour
                                                </button>

                                                <div id="colorpicker-floater-content" class="toggle__item-groups-floater --color-picker__floater" click-outside [click-outside-excludedHtmlIds]="['colorpicker-floater-toggler']" (clickOutside)="closeColorPicker($event)">
                                                    <button class="toggle__item-groups-close" (click)="colorPickerToggle.setInactive()"></button>

                                                    <div class="toggle__item-groups-contents">
                                                        <button *ngFor="let i of array20" [ngClass]="'color-picker__choice --color-picker__' + (i+1)" [class.--active]="colorChoose.no == (i+1)" (click)="colorPickerToggle.setInactive();toggleColor(i+1);"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-driver-selector *ngIf="groupType==='driver' && preselectedIds" #driverAssign [title]="'Drivers'" [withGroup]="false" [selectedIds]="preselectedIds"></app-driver-selector>

        <app-vehicle-selector *ngIf="groupType==='vehicle' && preselectedIds" #vehicleAssign [title]="'Vehicles'" [withGroup]="false" [selectedIds]="preselectedIds"></app-vehicle-selector>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">

                            <button *ngIf="groupType==='vehicle' && preselectedIds" class="button inline-button__inputs" type="button" (click)="showPopup(confirmCreatePopup)" [disabled]="vehiclesForm.invalid || (vehicleAssign && vehicleAssign.getSelectedCount() == 0)">
                                SAVE
                            </button>

                            <button *ngIf="groupType==='driver' && preselectedIds" class="button inline-button__inputs" type="button" (click)="showPopup(confirmCreatePopup)" [disabled]="vehiclesForm.invalid || (driverAssign && driverAssign.getSelectedCount() == 0)">
                                SAVE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToGroupView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #confirmCreatePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to save vehicle group changes
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateGroupDetails();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
