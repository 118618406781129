import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { MaintenanceService } from './../../../../_services/maintenance/maintenance.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as NumberUtil from './../../../../util/numberUtil';

// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';

@Component({
    providers: [GeneralReport],
    templateUrl: './maintenance-report.component.html',
    styleUrls: ['./maintenance-report.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s ease-out', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class MaintenanceReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

    expandGroupVehicle: any = [];

    // Set Export File Name from environments
    exportPdfMaintenancePageLayout = environment.appConfig.reporting.maintenance.layout;
    exportFileNamePastMaintenance: string = environment.appConfig.reporting.maintenance.past.filename;
    exportFileNameUpcomingMaintenance: string = environment.appConfig.reporting.maintenance.upcoming.filename;
    exportFileNamePastMaintenancePdfTitle: string = environment.appConfig.reporting.maintenance.past.label;
    exportFileNameUpcomingMaintenancePdfTitle: string = environment.appConfig.reporting.maintenance.upcoming.label;
    pageLayout = this.exportPdfMaintenancePageLayout;
    moment = moment;

    @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private maintenanceService: MaintenanceService,
        private el: ElementRef,
        private popupService_parent: PopupService,
        private popupCampaignService: PopupCampaignService
    ) {

        //pass services to parent
        super(http_parent, popupService_parent, snackBar);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingSearchType: true,
            usingDateRangePicker: true,
            usingVehicleSelector: true
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {

        //Default search by
        this.searchBy = "vehicles";

        //Default search type
        this.searchType = "past";
        this.currentSearchType = "past";

        //Default search range
        // this.fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
        // this.toDate = moment().format("YYYY-MM-DD");

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //call api to get report page
            const apiControllerResponse: any = await this.getApiControllerResponse(startRecord);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;

                //update sorting classes
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = apiControllerResponse.resultList;
                if (this.resultList.length) {

                    // parse some values to reduce function data bindings
                    this.resultList.forEach(record => {
                        if (this.currentSearchType === 'upcoming') {
                            record.nextOdometerLabel = NumberUtil.formatFloat(record.nextOdometer, 1);
                            record.nextEngineHoursLabel = NumberUtil.formatHourMinute(record.nextEngineHours, '-');
                        } else if (this.currentSearchType === 'past') {
                            record.OdometerLabel = NumberUtil.formatFloat(record.Odometer, 1);
                            record.EngineHoursLabel = NumberUtil.formatHourMinute(record.EngineHours, '-');
                            record.NotesLabel = record.Notes || '-';
                        }
                    });

                    //update isReportShown boolean
                    this.isReportShown = true;

                    //get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }
    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }
    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        if (this.currentSearchType == "past") {
            apiResponse = await this.getPastMaintenanceRecords(startRecord, isDownload);
        } else if (this.currentSearchType == "upcoming") {
            apiResponse = await this.getUpcomingMaintenanceRecords(startRecord, isDownload);
        }

        if (apiResponse != null) {
            apiResultList = apiResponse.result;
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }
    // For download report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 18 }, { wch: 15 }, { wch: 13 }, { wch: 18 }, { wch: 25 }];

        if (this.currentSearchType == "past") {
            filename = this.exportFileNamePastMaintenance;
            label = this.exportFileNamePastMaintenancePdfTitle;
            headerName = [
                "Vehicle", "Maintenance Name", "Service Date",
                "Odometer (km)", "Engine Hours (hours)", "Notes"
            ];
            for (let i = 0; i < recordList.length; i++) {
                const tempRow = [
                    recordList[i].VehicleName || '',
                    recordList[i].MaintenanceName || '',
                    recordList[i].ServiceDate ? moment(recordList[i].ServiceDate, "YYYY-MM-DD").format("YYYY/MM/DD") : '',
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Odometer, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].EngineHours, 0, '')),
                    recordList[i].Notes || ''
                ];
                data.push(tempRow);
            }
        } else if (this.currentSearchType == "upcoming") {
            filename = this.exportFileNameUpcomingMaintenance;
            label = this.exportFileNameUpcomingMaintenancePdfTitle;
            headerName = [
                "Vehicle", "Maintenance Name", "Service Due Date",
                "Odometer (km)", "Engine Hours (hours)"
            ];
            for (let i = 0; i < recordList.length; i++) {
                const tempRow = [
                    recordList[i].vehicleName || '',
                    recordList[i].maintenanceName || '',
                    recordList[i].nextServiceDate ? moment(recordList[i].nextServiceDate, "YYYY-MM-DD").format("YYYY/MM/DD") : '',
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].nextOdometer, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].nextEngineHours, 1, ''))
                ];
                data.push(tempRow);
            }
        }
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    /**
     * Report Specific Methods
     */

    // 1. Call API Past Report
    async getPastMaintenanceRecords(startRecord: number = 1, isDownload: boolean = false) {

        let result: any = null;

        if (!isDownload) {
            result = await this.maintenanceService.getPastMaintenance(this.selectedVehiclesList, this.currentFromDate, this.currentToDate, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
        } else {
            result = await this.maintenanceService.getPastMaintenance(this.selectedVehiclesList, this.currentFromDate, this.currentToDate, null, null, this.currentSortField, this.currentSortAscending);
        }

        return result || null;
    }

    // 2. Call API Upcoming Report
    async getUpcomingMaintenanceRecords(startRecord: number = 1, isDownload: boolean = false) {

        let result: any = null;

        if (!isDownload) {
            result = await this.maintenanceService.getUpcomingMaintenance(this.selectedVehiclesList, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
        } else {
            result = await this.maintenanceService.getUpcomingMaintenance(this.selectedVehiclesList, null, null, this.currentSortField, this.currentSortAscending);
        }

        return result || null;
    }

    // 3. Upon Switching Search Type (Past/Upcoming)
    changeSearchType(): void {

        //fix position of GENERATE button
        const div = this.el.nativeElement.querySelector("#divSearchType");
        div.classList.remove("--period");
        if (this.searchType == "past") {
            div.classList.add("--period");
        }

    }

}
