import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

// import * as StringUtil from '../../util/stringUtil';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient) { }

    async getUserNotificationInfo() {
        const path = environment.mtntApiEndpoint + `/notification`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async updateUserNotification(notificationList) {
        const body = { notification: notificationList };

        const path = environment.mtntApiEndpoint + `/notification`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    async updateUserVehicleNotification(type, notificationName, vehicleIdsList = [], driverTagIdList = []) {
        const body = {
            type: type,
            notificationName: notificationName,
            vehicleIdList: vehicleIdsList,
            driverTagIdList: driverTagIdList
        };

        const path = environment.mtntApiEndpoint + `/notification/vehicles`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    async getVehicleHealthStatusNotification(vehicleId = null, startTime, endTime, sortField = null, sortAscending = null, pageSize = null, startRecord = null, latestStatus = false, groupId = null) {
        const path = environment.mtntApiEndpoint + `/notification/vehicles/vehicle-health-alerts`;
        const params: any = {};
        if (vehicleId != null) {
            params.vehicleId = vehicleId;
        }
        if (startTime != null) {
            params.startTime = startTime;
        }
        if (endTime != null) {
            params.endTime = endTime;
        }
        if (sortField != null) {
            params.sortField = sortField;
        }
        if (sortAscending != null) {
            params.sortAscending = sortAscending;
        }
        if (pageSize != null) {
            params.pageSize = pageSize;
        }
        if (startRecord != null) {
            params.startRecord = startRecord;
        }
        if (groupId !== null) {
            params.groupId = groupId;
        }
        params.latestStatus = latestStatus;

        const result: any = await this.http.get(path, {
            params: params
        }).toPromise();
        return result;
    }

    async getUserAlertNotification(startRecord = null, pageSize = null) {
        const path = environment.mtntApiEndpoint + `/notification/alert`;
        const params: any = {};
        if (startRecord != null) {
            params.startRecord = startRecord;
        }
        if (pageSize != null) {
            params.pageSize = pageSize;
        }
        const result: any = await this.http.get(path, { params: params }).toPromise();
        return result;
    }

    async getUserTotalAlertNotification() {
        const path = environment.mtntApiEndpoint + `/notification/alert/total`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }
}
