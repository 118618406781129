import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';

import { ToggleDirective } from '../../../../directives/toggle/toggle.directive';
import { PopupService } from '../../../common/popup/popup.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from '../../../../_services/router-proxy/router-proxy.service';
import { SpinnerComponent } from '../../../common/spinner/spinner.component';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { FuelService } from './../../../../_services/fuel/fuel.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import * as moment from 'moment';
import * as DomUtil from '../../../../util/domUtil';
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as ObjectUtil from '../../../../util/objectUtil';
import * as StringUtil from '../../../../util/stringUtil';
import * as NumberUtil from '../../../../util/numberUtil';

import * as Message from '../../../../constants/message';
import * as FuelType from './../../../../constants/fuelType.constant';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from '../../../../constants/responseStatusCode';

@Component({
    selector: 'app-create-refuel-entry',
    templateUrl: './create-refuel-entry.component.html',
    styleUrls: ['./create-refuel-entry.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CreateRefuelEntryComponent implements OnInit {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    message = Message;
    fuelType = FuelType;
    datePickerMaxValue = moment().startOf('day').format();

    timeSelector: {
        hour: string[],
        fullHour: string[],
        minute: string[],
        second: string[]
    } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array
    fuelTypeList = this.fuelType.getFuelTypeList();

    fuelDateVar: string = moment().format("YYYY-MM-DD");
    fuelTimeHh: string = moment().format("hh");
    fuelTimeMm: string = moment().format("mm");
    fuelTimeA: string = moment().format("A");
    currentVehicleRefuelEntryObj = {
        vehicleId: '',
        vehicleName: null,
        vehiclePlateNo: null,
        fuelType: '',
        fuelQuantity: null,
        fuelCost: null,
        location: null,
        refuelDate: null,
        odometer: null
    };
    updateOdometer: boolean = false;
    vehicleInfoList = [];

    //date picket touch ui
    datepickerTouchUi: boolean = false;
    lastResizeTime: any = new Date(0);
    resizeTimeout: any;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private vehicleService: VehicleService,
        private fuelService: FuelService,
        private popupService: PopupService,
        private el: ElementRef,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        DomUtil.autoFocusForm(this.el);
        await this.getVehiclesInfo();

        await this.checkUnviewedCampaign();

    }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async getVehiclesInfo() {
        // Pull for dropdown box
        const vehicleList = await this.vehicleService.getAllVehicleInfoDetails();
        if (vehicleList) {
            this.vehicleInfoList = vehicleList.body.vehicle_details_list;
            this.vehicleInfoList = this.vehicleInfoList.filter(result => StringUtil.isNotEmptyOrNull(result.vehiclePlateNo));
        }
    }

    onVehicleChoose(vehicleId: number) {
        const foundVehicle = this.vehicleInfoList.find(result => result.vehicleId === parseInt(vehicleId.toString()));
        if (foundVehicle) {
            this.currentVehicleRefuelEntryObj.vehicleName = foundVehicle.vehicleName;
        } else {
            this.currentVehicleRefuelEntryObj.vehicleName = null;
        }
    }

    async createVehicleRefuelEntry() {
        this.pageSpinner.show();

        try {
            this.currentVehicleRefuelEntryObj.fuelCost = NumberUtil.numberOrNull(NumberUtil.formatFloat(this.currentVehicleRefuelEntryObj.fuelCost, 2, '0'));
            this.currentVehicleRefuelEntryObj.fuelQuantity = NumberUtil.numberOrNull(NumberUtil.formatFloat(this.currentVehicleRefuelEntryObj.fuelQuantity, 2, '0'));
            this.currentVehicleRefuelEntryObj.odometer = NumberUtil.numberOrNull(NumberUtil.formatFloat(this.currentVehicleRefuelEntryObj.odometer, 1, '0'));

            const fuelDateOnlyMom = moment(this.fuelDateVar);
            const fuelDate = `${fuelDateOnlyMom.format('YYYY-MM-DD')} ${this.fuelTimeHh}:${this.fuelTimeMm} ${this.fuelTimeA}`;
            this.currentVehicleRefuelEntryObj.refuelDate = fuelDate ? moment(fuelDate, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss") : null;

            const updateCurrOdometer = this.updateOdometer;
            const fuelList = [
                {
                    vehicleId: parseInt(this.currentVehicleRefuelEntryObj.vehicleId),
                    fuelType: this.currentVehicleRefuelEntryObj.fuelType,
                    fuelCost: this.currentVehicleRefuelEntryObj.fuelCost,
                    fuelQuantity: this.currentVehicleRefuelEntryObj.fuelQuantity,
                    location: this.currentVehicleRefuelEntryObj.location,
                    fuelDate: this.currentVehicleRefuelEntryObj.refuelDate,
                    odometer: this.currentVehicleRefuelEntryObj.odometer,
                    updateOdometer: updateCurrOdometer
                }
            ];
            const result = await this.fuelService.createVehicleFuel(fuelList);

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Refuel Entry', this.currentVehicleRefuelEntryObj.vehicleName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Refuel Entry', this.currentVehicleRefuelEntryObj.vehicleName);
                this.navigateSuccessToFuelView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToFuelView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/fuel/view'], null, 'refuel-entry');
    }

    navigateToFuelView() {
        this.routerProxyService.navigate(['/create-manage/fuel/view']);
        // this.routerProxyService.navigate(['/create-manage/fuel/view', { 'fromSetFuelLimit': true }]);
    }

    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }

    updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

}
