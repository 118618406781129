import * as ObjectUtil from './objectUtil';

export const Characters = {
    FULL_STOP: '.',
    COMMA: ',',
    EXCLAIMATION_MARK: '!',
    QUESTION_MARK: '?'
};

/**
 * Check if string is null or empty
 * @param value (any) value to be checked
 * @return (boolean) true = pass validation
 */
export function isNotEmptyOrNull(value: any): boolean {
    if (value != null && value != undefined && value.toString().trim().length) {
        return true;
    }
    return false;
}

export function equalsIgnoreCase(str1 = '', str2 = '') {
    if (str1 && str2) {
        if (str1.toLowerCase() == str2.toLowerCase()) {
            return true;
        }
    }
}

export function getShortName(name) {
    if (!isNotEmptyOrNull(name)) {
        return '';
    }
    let shortName = "";
    const nameArr: string[] = name.split(/\s+/).filter(item => (typeof item == 'string' && item.trim().length > 0));
    for (let i = 0; i < nameArr.length; i++) {
        if (i === 3) {
            break;
        }
        shortName += nameArr[i].charAt(0).toUpperCase();
    }
    return shortName;
}

export function toTitleCase(strMessage) {
    if (!isNotEmptyOrNull(strMessage)) {
        return '';
    }
    return strMessage.split(/\s+/).filter(item => (typeof item == 'string' && item.trim().length > 0))
        .map((firstChar: string) => firstChar.charAt(0).toUpperCase() + firstChar.substr(1).toLowerCase())
        .join(' ');
}

export function headToLowerCase(strMessage) {
    if (!isNotEmptyOrNull(strMessage)) {
        return '';
    }
    return strMessage.split(/\s+/).filter(item => (typeof item == 'string' && item.trim().length > 0))
        .map((firstChar: string) => firstChar.charAt(0).toLowerCase() + firstChar.substr(1))
        .join(' ');
}

export function sortAscending(obj1, obj2, key) {
    if (typeof obj1[key] === 'string' && typeof obj2[key] === 'string') {
        if (obj1[key].toLowerCase() < obj2[key].toLowerCase()) {
            return -1;
        }
        if (obj1[key].toLowerCase() > obj2[key].toLowerCase()) {
            return 1;
        }
    } else {
        if (obj1[key] < obj2[key]) {
            return -1;
        }
        if (obj1[key] > obj2[key]) {
            return 1;
        }
    }
    return 0;
}

export function sortDescending(obj1, obj2, key) {
    return sortAscending(obj1, obj2, key) * -1;
}

export function toCapsUnderscore(text: string = '') {
    if (text == null) {
        text = 'null';
    }
    return text.toUpperCase().replace(' ', '_');
}

export function getListFromDelimitedText(text: string = '', delimiter: string = ',') {
    if (text) {
        return text.split(delimiter).filter(item => isNotEmptyOrNull(item)).map(item => item.trim());
    }
    return [];
}

// HTML friendly - @author zhuhang.jasper
export function makeHtmlFriendly(content: any): string {
    if (ObjectUtil.isObject(content)) {
        // try {
        //     content.objectType = content.constructor.name;
        // } catch (ex) { }
        content = JSON.stringify(content, null, 2);
    }
    if (content) {
        return '&emsp;' + content.replace(/\n/g, '<BR>&emsp;');
    } else {
        return '';
    }
}

/**
 * Replace variables in text with actual value
 * Variable format: "{n}"
 * @param msg original message
 * @param params variables
 * @param keyOpen character before variable index (default: '{')
 * @param keyClose character after variable index (default: '}')
 */
export function replaceVarsInText(msg: string, params: any[], keyOpen: string = '{', keyClose: string = '}'): string {
    if (!isNotEmptyOrNull(msg)) {
        return null;
    }
    for (let i = 0; i < params.length; i++) {
        msg = msg.replace(`${keyOpen}${i}${keyClose}`, params[i]);
    }
    //remove any unreplaced variables
    msg = replaceUnusedVarsInText(msg);
    return msg;
}

export function replaceUnusedVarsInText(msg: string, keyOpen: string = '{', keyClose: string = '}'): string {
    if (!isNotEmptyOrNull(msg)) {
        return null;
    }
    const rgx = new RegExp(`${keyOpen}\\d*${keyClose}`, "g");
    msg = msg.replace(rgx, ''); // remove unused {n}
    msg = msg.replace(/\(\)/g, ''); // remove empty brackets
    msg = msg.replace(/ {2,}/g, ' '); // remove consecutive spaces
    return msg;
}

/**
 * Make text always end with a specified character
 * @param text original text
 * @param char the character to add to the last position of `text`
 */
export function alwaysEndWith(text: string, char: string): string {
    if (text.endsWith(char)) {
        return text;
    } else {
        return `${text}${char}`;
    }
}

/**
 * To Get Next character based on the increment of charCode number
 * @param char the begin character
 */
export function getNextChar(char: string) {
    return String.fromCharCode(char.charCodeAt(0) + 1);
}

/**
 * To Truncate text to a specified number of characters, followed by ellipses
 * @param text the text to be truncated
 * @param newLength the new length to be truncated to
 * @param textIfNull the text to return if input is empty
 */
export function truncateText(text: string, newLength: number, textIfNull: string = '') {
    return isNotEmptyOrNull(text) ? (text.length > newLength ? text.substring(0, newLength).trim() + '...' : text) : textIfNull;
}
