import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../common/spinner/spinner.component';
import { RoleService } from '../../../../_services/role/role.service';
import { PopupService } from '../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import * as Message from './../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-edit-role',
    templateUrl: './edit-role.component.html',
    styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {

    role = {
        name: null,
        desc: 'Custom Role',
        roleType: 'ADMIN',
        homePageId: null,
        status: 'ACTIVE'
    };

    pageModuleList = [];
    homePageList = [];
    adminList = [];
    filterValue = '';
    roleId: number;

    isShowPermissionSelectAllOption: boolean = true;
    selectedPermissionGroups: any = {}; //keep track which parent ticked by their index
    selectedPermissions: any = {}; //keep track which module(parent moduleId also included in here) ticked by their moduleId
    selectedPermissionsView: any = {}; //the final list(parent moduleId also included in here) to keep track which moduleId have ticked view
    selectedPermissionsEdit: any = {}; //the final list(parent moduleId also included in here) to keep track which moduleId have ticked edit
    expandGroupPermission: any = [];
    selectedAdminGroups: any = {};
    selectedAdmins: any = {};

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    constructor(private roleService: RoleService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private snackBar: SnackBarService,
        private route: ActivatedRoute) { }

    async ngOnInit() {
        this.roleId = this.route.snapshot.params.id;
        await this.getPageModuleList();
        await this.getHomePageList();
        await this.getAdminList();
        await this.onInitCommonFunction();
    }

    async onInitCommonFunction() {
        this.pageSpinner.show();
        try {
            await this.getRoleDetails(this.roleId);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async getRoleDetails(roleId) {
        const res: any = await this.roleService.getRoleDetails(roleId);
        const role = res.body.role;
        const moduleIdList = res.body.moduleIdList;
        const userIdList = res.body.userIdList;

        //form back the structure FE needed to display
        this.role.name = role.name;
        this.role.homePageId = role.homePageId;

        for (let i = 0; i < moduleIdList.length; i++) {
            const moduleFromBE = moduleIdList[i];
            this.selectedPermissions[moduleFromBE.moduleId] = true;
            if (moduleFromBE.haveView === 'Y') {
                this.selectedPermissionsView[moduleFromBE.moduleId] = true;
            }

            if (moduleFromBE.haveEdit === 'Y') {
                this.selectedPermissionsEdit[moduleFromBE.moduleId] = true;
            }
        }

        //find which group radio button need ticked
        for (let i = 0; i < this.pageModuleList.length; i++) {
            let allTicked = true;
            const module = this.pageModuleList[i];
            //only module with child need check, because module without child is not using selectedPermissionGroups to tick checkbox
            if (module.subModuleList.length > 0) {
                for (let j = 0; j < module.subModuleList.length; j++) {
                    const subPermissionModel = module.subModuleList[j];
                    if (subPermissionModel.viewable === 1 || subPermissionModel.editable === 1) {
                        //child ticked, need find out is it all child ticked, if yes need set parent to ticked as well
                        if (typeof (this.selectedPermissions[subPermissionModel.moduleId]) === "undefined" || !this.selectedPermissions[subPermissionModel.moduleId]) {
                            allTicked = false;
                            break;
                        }
                    }
                }
                if (allTicked) {
                    //selectedPermissionGroups store index, so need get the original position in pageModuleList
                    this.selectedPermissionGroups[i] = true;
                }
            } else {
                //dont have child, use parentId to check, set this is use for select all button to do checking
                if (this.selectedPermissions[module.moduleId]) {
                    this.selectedPermissionGroups[i] = true;
                }
            }
        }

        for (let k = 0; k < userIdList.length; k++) {
            this.selectedAdmins[userIdList[k].userId] = true;
        }

        //verify whether need ticked select all button
        this.checkNeedTickSelectAllButton();
        this.checkNeedTickAdminSelectAllButton();
    }

    async getPageModuleList() {
        const response: any = await this.roleService.getPageModuleList();
        this.pageModuleList = response.body;
    }

    async getHomePageList() {
        const response: any = await this.roleService.getHomePageList();
        this.homePageList = response.body;
    }

    async getAdminList() {
        const response: any = await this.roleService.getAdminList(this.filterValue);
        this.adminList = response.body;
        this.checkNeedTickAdminSelectAllButton();
    }

    navigateToRoleView() {
        this.routerProxyService.navigate(['create-manage/admins/view'], {
            queryParams: {
                roleTab: true
            }
        });
    }

    navigateSuccessToRoleView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['create-manage/admins/view'], {
            queryParams: {
                roleTab: true
            }
        });
    }

    onPermissionsAllSelectChange() {
        const allIsSelected = this.selectedPermissionGroups.all;
        for (let i = 0; i < this.pageModuleList.length; i++) {
            const permissionModel = this.pageModuleList[i];
            //at least 1 need to be true to be able to select
            if (permissionModel.viewable === 1 || permissionModel.editable === 1) {
                this.selectedPermissionGroups[i] = allIsSelected;
                this.selectedPermissions[permissionModel.moduleId] = allIsSelected;
                //parent moduleId keep in selectedPermissionsView for BE usage
                this.selectedPermissionsView[permissionModel.moduleId] = allIsSelected;
                if (permissionModel.subModuleList) {
                    if (permissionModel.subModuleList.length > 0) {
                        for (let j = 0; j < permissionModel.subModuleList.length; j++) {
                            const subPermissionModel = permissionModel.subModuleList[j];
                            //either one true mean it can be select
                            if (subPermissionModel.viewable === 1 || subPermissionModel.editable === 1) {
                                this.selectedPermissions[subPermissionModel.moduleId] = allIsSelected;
                            }
                            //only viewable can be selected
                            if (subPermissionModel.viewable === 1) {
                                this.selectedPermissionsView[subPermissionModel.moduleId] = allIsSelected;
                            }
                            //only editable can be selected
                            if (subPermissionModel.editable === 1) {
                                this.selectedPermissionsEdit[subPermissionModel.moduleId] = allIsSelected;
                            }
                        }
                    } else {
                        //no sub module mean it self can select view and edit
                        if (permissionModel.editable === 1) {
                            this.selectedPermissionsEdit[permissionModel.moduleId] = allIsSelected;
                        }
                    }
                }
            }

        }
    }

    onPermissionsGroupSelectChange(index: number, selected: boolean) {
        const selectedGroup = this.pageModuleList[index];
        this.selectedPermissions[selectedGroup.moduleId] = selected;
        //parent moduleId keep in selectedPermissionsView for BE usage
        this.selectedPermissionsView[selectedGroup.moduleId] = selected;
        if (selectedGroup.subModuleList) {
            for (let j = 0; j < selectedGroup.subModuleList.length; j++) {
                const subPermissionModel = selectedGroup.subModuleList[j];
                if (subPermissionModel.viewable === 1 || subPermissionModel.editable === 1) {
                    this.selectedPermissions[subPermissionModel.moduleId] = selected;
                }
                //only viewable can be selected
                if (subPermissionModel.viewable === 1) {
                    this.selectedPermissionsView[subPermissionModel.moduleId] = selected;
                }
                //only editable can be selected
                if (subPermissionModel.editable === 1) {
                    this.selectedPermissionsEdit[subPermissionModel.moduleId] = selected;
                }
            }
        }

        if (!selected) {
            this.selectedPermissionGroups['all'] = selected;
        } else {
            //user selected group, verify is all group check
            this.checkNeedTickSelectAllButton();
        }
    }

    onPermissionsSelectChange(parentIndex: number, moduleId: number, selected: boolean) {
        if (!selected) {
            //user unticked, so doesn't matter viewable or editable, it won't save to db anyway
            this.selectedPermissionsView[moduleId] = selected;
            this.selectedPermissionsEdit[moduleId] = selected;
            //check view edit both is it unticked
            this.checkIsViewEditBothUnticked(parentIndex, moduleId);
        } else {
            let allTicked = true;
            const parentGroup = this.pageModuleList[parentIndex];
            if (parentGroup.subModuleList.length > 0) {
                //find current selected permission is viewable nor editable
                for (let j = 0; j < parentGroup.subModuleList.length; j++) {
                    const subPermissionModel = parentGroup.subModuleList[j];
                    //find current selected permission
                    if (subPermissionModel.moduleId === moduleId) {
                        //only viewable can be selected
                        if (subPermissionModel.viewable === 1) {
                            this.selectedPermissionsView[moduleId] = selected;
                        }
                        //only editable can be selected
                        if (subPermissionModel.editable === 1) {
                            this.selectedPermissionsEdit[moduleId] = selected;
                        }
                        break;
                    }
                }
                //check whether all child ticked
                for (let j = 0; j < parentGroup.subModuleList.length; j++) {
                    const subPermissionModel = parentGroup.subModuleList[j];
                    if (subPermissionModel.viewable === 1 || subPermissionModel.editable === 1) {
                        //child ticked, need find out is it all child ticked, if yes need set parent to ticked as well
                        if (typeof (this.selectedPermissions[subPermissionModel.moduleId]) === "undefined" || !this.selectedPermissions[subPermissionModel.moduleId]) {
                            allTicked = false;
                            break;
                        }
                    }
                }
            } else {
                //subModuleList length 0 mean parent without child, so use parent one to check
                //only viewable can be selected
                if (parentGroup.viewable === 1) {
                    this.selectedPermissionsView[moduleId] = selected;
                }
                //only editable can be selected
                if (parentGroup.editable === 1) {
                    this.selectedPermissionsEdit[moduleId] = selected;
                }
            }

            if (allTicked) {
                //all child ticked, parent checkbox need tick
                this.selectedPermissionGroups[parentIndex] = selected;
            }
            //set the parent permission to true
            this.selectedPermissions[parentGroup.moduleId] = selected;
            this.selectedPermissionsView[parentGroup.moduleId] = selected;

            //user selected group, verify is all group check
            this.checkNeedTickSelectAllButton();
        }
    }

    onPermissionsViewSelectChange(parentIndex: number, moduleId: number, selected: boolean) {
        if (!selected) {
            //check view edit both is it unticked
            this.checkIsViewEditBothUnticked(parentIndex, moduleId);
        } else {
            this.selectedPermissions[moduleId] = selected;
            let allTicked = true;
            const parentGroup = this.pageModuleList[parentIndex];
            //set the parent permission to true
            this.selectedPermissions[parentGroup.moduleId] = selected;
            if (parentGroup.subModuleList.length > 0) {
                //have child, set parent moduleId in view
                this.selectedPermissionsView[parentGroup.moduleId] = selected;
            }
            for (let j = 0; j < parentGroup.subModuleList.length; j++) {
                const subPermissionModel = parentGroup.subModuleList[j];

                if (subPermissionModel.viewable === 1 || subPermissionModel.editable === 1) {
                    //child ticked, need find out is it all child ticked, if yes need set parent to ticked as well
                    if (typeof (this.selectedPermissions[subPermissionModel.moduleId]) === "undefined" || !this.selectedPermissions[subPermissionModel.moduleId]) {
                        allTicked = false;
                        break;
                    }
                }
            }

            if (allTicked) {
                //all child ticked, parent checkbox need tick
                this.selectedPermissionGroups[parentIndex] = selected;
            }

            //user selected group, verify is all group check
            this.checkNeedTickSelectAllButton();
        }
    }

    onPermissionsEditSelectChange(parentIndex: number, moduleId: number, selected: boolean) {
        if (!selected) {
            //check view edit both is it unticked
            this.checkIsViewEditBothUnticked(parentIndex, moduleId);
        } else {
            this.selectedPermissions[moduleId] = selected;
            let allTicked = true;
            const parentGroup = this.pageModuleList[parentIndex];
            //set the parent permission to true
            this.selectedPermissions[parentGroup.moduleId] = selected;
            if (parentGroup.subModuleList.length > 0) {
                //have child, set parent moduleId in view
                this.selectedPermissionsView[parentGroup.moduleId] = selected;
            }

            for (let j = 0; j < parentGroup.subModuleList.length; j++) {
                const subPermissionModel = parentGroup.subModuleList[j];

                if (subPermissionModel.viewable === 1 || subPermissionModel.editable === 1) {
                    //child ticked, need find out is it all child ticked, if yes need set parent to ticked as well
                    if (typeof (this.selectedPermissions[subPermissionModel.moduleId]) === "undefined" || !this.selectedPermissions[subPermissionModel.moduleId]) {
                        allTicked = false;
                        break;
                    }
                }
            }

            if (allTicked) {
                //all child ticked, parent checkbox need tick
                this.selectedPermissionGroups[parentIndex] = selected;
            }

            //user selected group, verify is all group check
            this.checkNeedTickSelectAllButton();
        }
    }

    checkNeedTickSelectAllButton() {
        let allGroupTicked = true;
        for (let i = 0; i < this.pageModuleList.length; i++) {
            const permissionModel = this.pageModuleList[i];
            if (typeof (this.selectedPermissionGroups[i]) === "undefined" || !this.selectedPermissionGroups[i]) {
                allGroupTicked = false;
                break;
            }
        }

        if (allGroupTicked) {
            this.selectedPermissionGroups['all'] = true;
        }
    }

    checkIsViewEditBothUnticked(parentIndex: number, moduleId: number) {
        const parentGroup = this.pageModuleList[parentIndex];
        let viewEditUnticked = false;
        if (parentGroup.subModuleList.length > 0) {
            for (let j = 0; j < parentGroup.subModuleList.length; j++) {
                const subPermissionModel = parentGroup.subModuleList[j];
                //find current selected permission
                if (subPermissionModel.moduleId === moduleId) {
                    if (subPermissionModel.viewable === 1 && subPermissionModel.editable === 1) {
                        //check is both unticked
                        if ((typeof (this.selectedPermissionsView[moduleId]) === "undefined" || !this.selectedPermissionsView[moduleId]) && (typeof (this.selectedPermissionsEdit[moduleId]) === "undefined" || !this.selectedPermissionsEdit[moduleId])) {
                            viewEditUnticked = true;
                        }
                    } else if (subPermissionModel.viewable === 1) {
                        //module with viewable option only, so check view list enough
                        if (typeof (this.selectedPermissionsView[moduleId]) === "undefined" || !this.selectedPermissionsView[moduleId]) {
                            viewEditUnticked = true;
                        }
                    } else if (subPermissionModel.editable === 1) {
                        //module with editable option only, so check edit list enough
                        if (typeof (this.selectedPermissionsEdit[moduleId]) === "undefined" || !this.selectedPermissionsEdit[moduleId]) {
                            viewEditUnticked = true;
                        }
                    }
                }
            }
        } else {
            //subModuleList length 0 mean parent without child, so use parent one to check
            if (parentGroup.viewable === 1 && parentGroup.editable === 1) {
                //check is both unticked
                if ((typeof (this.selectedPermissionsView[moduleId]) === "undefined" || !this.selectedPermissionsView[moduleId]) && (typeof (this.selectedPermissionsEdit[moduleId]) === "undefined" || !this.selectedPermissionsEdit[moduleId])) {
                    viewEditUnticked = true;
                }
            } else if (parentGroup.viewable === 1) {
                //module with viewable option only, so check view list enough
                if (typeof (this.selectedPermissionsView[moduleId]) === "undefined" || !this.selectedPermissionsView[moduleId]) {
                    viewEditUnticked = true;
                }
            } else if (parentGroup.editable === 1) {
                //module with editable option only, so check edit list enough
                if (typeof (this.selectedPermissionsEdit[moduleId]) === "undefined" || !this.selectedPermissionsEdit[moduleId]) {
                    viewEditUnticked = true;
                }
            }
        }

        if (viewEditUnticked) {
            let allUnticked = true;

            this.selectedPermissions[moduleId] = false;
            this.selectedPermissionGroups[parentIndex] = false;
            this.selectedPermissionGroups['all'] = false;

            for (let j = 0; j < parentGroup.subModuleList.length; j++) {
                const subPermissionModel = parentGroup.subModuleList[j];
                if (subPermissionModel.viewable === 1 || subPermissionModel.editable === 1) {
                    //only those viewable or editable need to check
                    //need find out is it all child unticked, if yes need set parent to unticked as well
                    if (this.selectedPermissions[subPermissionModel.moduleId]) {
                        allUnticked = false;
                        break;
                    }
                }
            }
            //both unticked, set parent to unticked
            if (allUnticked) {
                this.selectedPermissions[parentGroup.moduleId] = false;
                this.selectedPermissionsView[parentGroup.moduleId] = false;
                this.selectedPermissionsEdit[parentGroup.moduleId] = false;
            }
        }
    }

    onAdminAllSelectChange() {
        const allIsSelected = this.selectedAdminGroups.all;
        for (let i = 0; i < this.adminList.length; i++) {
            const adminModel = this.adminList[i];
            this.selectedAdmins[adminModel.id] = allIsSelected;
        }
    }

    onAdminSelectChange(selected: boolean) {
        if (selected) {
            this.checkNeedTickAdminSelectAllButton();
        } else {
            this.selectedAdminGroups['all'] = false;
        }
    }

    checkNeedTickAdminSelectAllButton() {
        let allTicked = true;
        for (let i = 0; i < this.adminList.length; i++) {
            const adminModel = this.adminList[i];
            if (typeof (this.selectedAdmins[adminModel.id]) === "undefined" || !this.selectedAdmins[adminModel.id]) {
                allTicked = false;
                break;
            }
        }

        if (allTicked && this.adminList && this.adminList.length > 0) {
            this.selectedAdminGroups['all'] = true;
        } else {
            this.selectedAdminGroups['all'] = false;
        }
    }

    async updateRole() {
        const finalObj = {};
        const moduleIdList = [];
        const adminIdList = [];

        this.pageSpinner.show();
        try {
            this.role.homePageId = Number(this.role.homePageId);
            finalObj['id'] = this.roleId;
            finalObj['role'] = this.role;
            for (const id in this.selectedPermissions) {
                if (this.selectedPermissions[id]) {
                    const permissionTmp = {
                        moduleId: Number(id),
                        permission: {
                            view: false,
                            edit: false
                        }
                    };

                    if (this.selectedPermissionsView[id]) {
                        permissionTmp.permission.view = true;
                    }

                    if (this.selectedPermissionsEdit[id]) {
                        permissionTmp.permission.edit = true;
                    }

                    moduleIdList.push(permissionTmp);
                }
            }
            finalObj['moduleIdList'] = moduleIdList;

            for (const userId in this.selectedAdmins) {
                if (this.selectedAdmins[userId]) {
                    adminIdList.push(Number(userId));
                }
            }

            finalObj['userIdList'] = adminIdList;
            const result = await this.roleService.updateRole(finalObj);

            const roleName = this.role.name;

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Role', roleName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Role', roleName);
                this.navigateSuccessToRoleView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }

    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

}
