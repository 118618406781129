import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";

import { SnackBarService } from "../../../../_services/snackBar/snack-bar.service";
import { PopupService } from "./../../../common/popup/popup.service";
import { ActivatedRoute } from "@angular/router";
import { MaintenanceService } from "./../../../../_services/maintenance/maintenance.service";
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import * as Message from "../../../../constants/message";
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import * as DomUtil from "../../../../util/domUtil";
import * as StringUtil from './../../../../util/stringUtil';

import { VehicleSelectorComponent } from "./../../vehicles/vehicle-selector/vehicle-selector.component";
import { SpinnerComponent } from "./../../../../components/common/spinner/spinner.component";

@Component({
    templateUrl: "./edit-maintenance.component.html",
    styleUrls: ["./edit-maintenance.component.scss"]
})
export class EditMaintenanceComponent implements OnInit, AfterViewInit {

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private route: ActivatedRoute,
        private popupService: PopupService,
        private maintenanceService: MaintenanceService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }
    //HTML use
    message = Message;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    color = "primary";
    disabled = false;

    // Maintenance Form
    @ViewChild('maintenanceForm',{static:false}) maintenanceForm: NgForm;
    maintenanceId: number;
    preselectedIds: Array<number> = null;
    oldMaintenanceDetails: any = {};
    newMaintenanceDetails: any = {
        name: "",
        isRecurring: true,
        isToggleMileage: false, //mileage
        mileage: null,
        thresholdMileage: null,
        isToggleEngineHours: false, //engineHours
        engineHours: null,
        thresholdEngineHours: null,
        isToggleDurationMonth: false, //durationMonth
        durationMonth: null,
        thresholdDurationDays: null,
        thresholdDurationFrequencyDays: null,
        selectedToggleCount: ""
    };
    affectedVehicleResult = {
        insert: 0,
        update: 0,
        delete: 0
    };

    // Schedule Form
    @ViewChild("vehicleAssign",{static:false}) vehicleAssign: VehicleSelectorComponent;

    markedDirty: boolean = false;

    async ngOnInit() {
        this.maintenanceId = parseInt(this.route.snapshot.params.id);
        DomUtil.autoFocusForm(this.el);
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async ngAfterViewInit() {
        await this.getMaintenanceDetails();
    }

    async getMaintenanceDetails() {
        this.pageSpinner.show();
        try {
            const res: any = await this.maintenanceService.getMaintenanceProfileDetails(
                this.maintenanceId
            );

            const details: any = res.body;
            details.isToggleMileage = false;
            details.isToggleEngineHours = false;
            details.isToggleDurationMonth = false;
            if (details.mileage || details.thresholdMileage) {
                details.isToggleMileage = true;
            }
            if (details.engineHours || details.thresholdEngineHours) {
                details.isToggleEngineHours = true;
            }
            if (details.durationMonth || details.thresholdDurationDays || details.thresholdDurationFrequencyDays) {
                details.isToggleDurationMonth = true;
            }
            this.oldMaintenanceDetails = details;
            this.newMaintenanceDetails = {
                name: details.name,
                isRecurring: details.isRecurring,
                isToggleMileage: details.isToggleMileage, //mileage
                mileage: details.mileage,
                thresholdMileage: details.thresholdMileage,
                isToggleEngineHours: details.isToggleEngineHours, //engineHours
                engineHours: details.engineHours,
                thresholdEngineHours: details.thresholdEngineHours,
                isToggleDurationMonth: details.isToggleDurationMonth, //durationMonth
                durationMonth: details.durationMonth,
                thresholdDurationDays: details.thresholdDurationDays,
                thresholdDurationFrequencyDays: details.thresholdDurationFrequencyDays,
                selectedToggleCount: ""
            };
            this.toggle(); //update selectedToggleCount

            //Set selected vehicles
            this.preselectedIds = details.vehicleIds || [];
            // this.vehicleAssign.setSelectedVehicleIds(details.vehicleIds);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    getAffectedSchedulesCount() {

        const affectedList = this.vehicleAssign.getAffectedCount();
        this.affectedVehicleResult.insert = affectedList.insert.length;
        this.affectedVehicleResult.update = affectedList.update.length;
        this.affectedVehicleResult.delete = affectedList.delete.length;
        return;
    }

    async updateMaintenanceAndSchedule() {
        this.pageSpinner.show();
        try {
            //Remove empty keys
            const cloneForm = JSON.parse(JSON.stringify(this.newMaintenanceDetails));
            cloneForm.id = this.maintenanceId;

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(cloneForm.name)) {
                cloneForm.name = cloneForm.name.trim();
            }

            const integerFields = [
                "mileage",
                "thresholdMileage",
                "engineHours",
                "thresholdEngineHours",
                "durationMonth",
                "thresholdDurationDays",
                "thresholdDurationFrequencyDays"
            ];
            for (const objKey in cloneForm) {
                if (cloneForm.hasOwnProperty(objKey)) {
                    const value = cloneForm[objKey];
                    if (value === null || value === undefined) {
                        delete cloneForm[objKey];
                    } else if (integerFields.indexOf(objKey) != -1) {
                        cloneForm[objKey] = parseInt(cloneForm[objKey]);
                    }
                }
            }
            let selectedRecurring: number = 3;
            if (!cloneForm.isToggleMileage) {
                delete cloneForm.mileage;
                delete cloneForm.thresholdMileage;
                selectedRecurring--;
            }
            if (!cloneForm.isToggleEngineHours) {
                delete cloneForm.engineHours;
                delete cloneForm.thresholdEngineHours;
                selectedRecurring--;
            }
            if (!cloneForm.isToggleDurationMonth) {
                delete cloneForm.durationMonth;
                delete cloneForm.thresholdDurationDays;
                delete cloneForm.thresholdDurationFrequencyDays;
                selectedRecurring--;
            }

            if (!selectedRecurring) {
                this.pageSpinner.hide();
                this.snackBar.openGenericSnackBar(
                    "Please choose at least ONE(1) recurring type."
                );
                return;
            }

            delete cloneForm.isToggleMileage;
            delete cloneForm.isToggleDurationMonth;
            delete cloneForm.isToggleEngineHours;
            delete cloneForm.selectedToggleCount;
            cloneForm.vehicleIds = this.vehicleAssign.getSelectedVehicleIds();

            // Call service
            let result = null;
            result = await this.maintenanceService.updateMaintenanceAndSchedule(
                cloneForm
            );
            // console.debug(cloneForm);

            //Show Snackbar
            const mName = cloneForm.name;
            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Maintenance', mName);
            if (result) {
                if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Maintenance', mName);
                } else if (result.statusCode == ResponseStatusCode.PARTIAL.code) {
                    msg = Message.getMessage(Message.MESSAGE.UPDATE_PARTIAL.value, 'Maintenance', mName);
                }
                this.navigateSuccessToMaintenanceView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToMaintenanceView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/maintenance/view'], null, 'schedule');
    }

    navigateToMaintenanceView() {
        this.routerProxyService.navigate(['/create-manage/maintenance/view']);
    }

    markNumericFieldsDirty() {
        try {
            if (!this.markedDirty) {
                //Make some fields dirty on first load
                this.maintenanceForm.form.get('mileage').markAsDirty();
                this.maintenanceForm.form.get('thresholdMileage').markAsDirty();
                this.maintenanceForm.form.get('engineHours').markAsDirty();
                this.maintenanceForm.form.get('thresholdEngineHours').markAsDirty();
                this.maintenanceForm.form.get('durationMonth').markAsDirty();
                this.maintenanceForm.form.get('thresholdDurationDays').markAsDirty();
                this.maintenanceForm.form.get('thresholdDurationFrequencyDays').markAsDirty();
                this.markedDirty = true;
            }
        } catch (err) {
            //do nothing
        }
    }

    toggle() {
        this.markNumericFieldsDirty();
        if (
            this.newMaintenanceDetails.isToggleMileage ||
            this.newMaintenanceDetails.isToggleEngineHours ||
            this.newMaintenanceDetails.isToggleDurationMonth
        ) {
            this.newMaintenanceDetails.selectedToggleCount = "selected";
        } else {
            this.newMaintenanceDetails.selectedToggleCount = "";
        }
    }

    /* ---- popup table ---- */
    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
