<ng-container *ngIf="item">
    <div class="map__item-groups" [class.--active]="toggle.active">
        <button class="map__item-groups-toggle" toggle #toggle="toggle">
            <span *ngFor="let group of item.groups" class="map__item-group-dot" [ngStyle]="{ 'color': group.colour }"></span>
            <!-- <ng-container >
                 <span class="map__item-group-dot" [ngStyle]="{ 'color': GROUP[group].COLOR }"></span>
            </ng-container>-->
        </button>

        <div class="map__item-groups-floater">
            <button class="map__item-groups-close" (click)="toggle.setInactive()"></button>

            <div class="map__item-group" *ngFor="let group of item.groups">
                <!-- <span class="map__item-group-dot" [ngStyle]="{ 'color': GROUP[group].COLOR }"></span> {{ GROUP[group].LABEL }} -->
                <span class="map__item-group-dot" [ngStyle]="{ 'color': group.colour }"></span> {{ group.vehicleGroupName }}
            </div>
        </div>
    </div>

    <button type="button" class="map__item-favourite" [class.--active]="item.isFavourite" (click)="item.isFavourite ? unpinFromTop() : pinToTop()"></button>

  <a class="map__item-inner" (click)="isPastTripViewable && routeToVehicleMain(item.imei, item.vehicleName)" [class.--cursor-pointer]="isPastTripViewable" [ngStyle]="{ 'background-color': item.isPanic ? 'pink' : 'white' }">
  <!--a [routerLink]="['',{outlets:{leftbar:['vehicle',item.imei]}}]" class="map__item-inner" -->
        <div class="map__item-image" [ngStyle]="imageStyle">
            <div *ngIf="showImageAlert" class="map__item-image-alert"></div>
        </div>

        <div class="map__item-heading">
            <div class="map__item-heading-inner" [line-clamp]="{
                    'text': item.vehicleName,
                    'lines': 2
                }" #clampedHeading="line-clamp" [innerHTML]="clampedHeading.result"></div>
        </div>

        <div class="map__item-activity" [ngClass]="'--' + item.statusLoweredCase" [ngStyle]="activityStyle">
            <ng-container *ngIf="item.statusLoweredCase == STATUS.MOVING">
                {{ item.activity.direction || "-"}}
                <b>|</b> {{ item.activity.speedStr || "0"}}
                <b>|</b> {{ item.activity.durationStr || "0"}}
                <!-- <span *ngIf="item.fuelLevel"><br> Fuel Level: {{item.fuelLevel}}L</span>
                <span *ngIf="item.batteryLevel"><br> Battey Voltage: {{item.batteryLevel}}</span> -->
            </ng-container>

            <ng-container *ngIf="item.statusLoweredCase == STATUS.STOPPED || item.statusLoweredCase == STATUS.PARKANDALERT || item.statusLoweredCase == STATUS.DISCONNECTED || item.statusLoweredCase == STATUS.IDLING">
                <b>{{ item.statusLabel }}</b> &ndash; {{ item.activity.lastStopDateStr }} <span *ngIf="item.activity.lastStopAddress != null && item.activity.lastStopAddress != ''"> &ndash; <br> {{ item.activity.lastStopAddress }} </span>
                <!-- <span *ngIf="item.fuelLevel"><br> Fuel Level: {{item.fuelLevel}}L</span> <span *ngIf="item.batteryLevel"><br> Battey Voltage: {{item.batteryLevel}}</span> -->
            </ng-container>
        </div>

        <div *ngIf="item.hasViolation" class="map__item-violations" [ngClass]="'--' + item.violations.type">
            <!-- {{ item.report.date | date: 'MMMM dd, y' }} -->
            <ng-container>
                <!-- *ngIf="item.violations.type == 'EXCEED_TIME_OF_DAY'" -->
                <b>{{ item.violations.typeLabel }}</b> &ndash; {{ item.violations.dateLabel }}
            </ng-container>

            <!-- <ng-container *ngIf="item.violations.type == 'EXCEED_MILEAGE'">
                <b>{{ item.violations.typeLabel }}</b> &ndash; 210 km <b>&gt;</b>
            </ng-container>

            <ng-container *ngIf="item.violations.type == 'HARSH_BREAK'">
                <b>{{ item.violations.typeLabel }}</b> &ndash; {{ item.violations.dateLabel }}<b>&gt;</b>
            </ng-container>

            <ng-container *ngIf="item.violations.type == 'HARSH_TURN'">
                    <b>{{ item.violations.typeLabel }}</b> &ndash; {{ item.violations.dateLabel }}<b>&gt;</b>
            </ng-container>

            <ng-container *ngIf="item.violations.type == 'HARSH_ACCELERATION'">
                    <b>{{ item.violations.typeLabel }}</b> &ndash; {{ item.violations.dateLabel }}<b>&gt;</b>
            </ng-container>

            <ng-container *ngIf="item.violations.type == 'IS_IDLING'">
                    <b>{{ item.violations.typeLabel }}</b> &ndash; {{ item.activity.totalIdling}} Mins<b>&gt;</b>
            </ng-container>

            <ng-container *ngIf="item.violations.type == 'IS_SPEEDING'">
                    <b>{{ item.violations.typeLabel }}</b> &ndash; {{ item.violations.dateLabel }}<b>&gt;</b>
            </ng-container>

            <ng-container *ngIf="item.violations.type == 'GEOFENCE_ENTER'">
                    <b>{{ item.violations.typeLabel }}</b> &ndash; {{ item.violations.dateLabel }}<b>&gt;</b>
            </ng-container>

            <ng-container *ngIf="item.violations.type == 'GEOFENCE_EXIT'">
                    <b>{{ item.violations.typeLabel }}</b> &ndash; {{ item.violations.dateLabel }}<b>&gt;</b>
            </ng-container> -->
        </div>

        <div *ngIf="item.violations === undefined" class="map__item-no-violations">
            <ng-container>
                No Alerts Found
            </ng-container>
        </div>

        <ul class="update-log">
            <li class="module-driver-tag" *ngIf="item.hasDriverTagDevice"></li>
            <li class="module-immobilizer" *ngIf="item.hasImmobilizer"></li>
            <li class="module-fuel-sensor" *ngIf="item.hasFuelSensor"></li>
            <li class="module-panic-button" *ngIf="item.hasPanicButton"></li>
        </ul>

        <!--/a-->
    </a>
</ng-container>
