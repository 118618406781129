import { environment } from './../../environments/environment';
import { Platform } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { ElementRef } from '@angular/core';
import { Device as device } from '@capacitor/device';

// const GOOGLE_MAP_SCRIPT_KEYWORDS: Array<string> = [GOOGLE_MAP_API_KEY, 'maps.googleapis'];
const GOOGLE_MAP_LIBRARY_KEYWORD: string = '&libraries=';

declare var window: any;

export function isBelongToElementIds(element: Element, ids: Array<String>, level: number = 0): boolean {

    if (level > 10) {
        return false; //reached maximum ancestor level to check
    }
    if (ids.indexOf(element.id) >= 0) {
        return true;
    } else if (Boolean(element.parentElement)) {
        return isBelongToElementIds(element.parentElement, ids, level + 1);
    }
    return false;
}

export function isDescendantOrExcluded(parent, child, excludedIds) {
    let node = child;
    while (node) {
        if (node === parent || excludedIds.indexOf(node.id) >= 0) {
            return true;
        } else {
            // console.debug(node.parentNode);
            node = node.parentNode;
        }
    }
    return false;
}

export function checkMouseClickOnMatchingElement(clickEvent: Event, matchingHtmlIds: Array<String>): boolean {

    if (Boolean(clickEvent)) {
        let target = null;
        if (Boolean(clickEvent.srcElement)) {
            target = clickEvent.srcElement;
        } else if (Boolean(clickEvent.target)) {
            target = clickEvent.target;
        }
        if (target != null && isBelongToElementIds(target, matchingHtmlIds)) {
            return true;
        }
    }
    return false;
}

export function hasGoogleMapDrawingScript(GOOGLE_MAP_API_KEY: string): boolean {
    const libs = ['drawing', 'places'];
    return hasGoogleMapScript(GOOGLE_MAP_API_KEY + GOOGLE_MAP_LIBRARY_KEYWORD + libs.join(','), GOOGLE_MAP_API_KEY);
}

export function hasGoogleMapScript(newkeyword: string = null, GOOGLE_MAP_API_KEY: string): boolean {

    const keyword = newkeyword || GOOGLE_MAP_API_KEY;
    const qsKeyword = `script[src*="${keyword}"]`;

    // check DOM & BOM for google map
    const gmScript: Element = document.head.querySelector(qsKeyword);
    if (gmScript && window.google) {
        return true;
    }
    return false;
}

export function hasJsScript(keywords: Array<string>): boolean {
    // find google map script from DOM
    // let keywords = keywords || GOOGLE_MAP_SCRIPT_KEYWORDS;
    const scripts = document.head.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; ++i) {
        const scriptSource = scripts[i].getAttribute('src');
        if (scriptSource != null) {
            if (keywords.filter(item => scriptSource.includes(item)).length) {
                return true;
            }
        }
    }
    return false;
}

export function isGoogleNotDefined(): boolean {
    try {
        if (window.google && typeof window.google === 'object') {
            return false;
        }
    } catch (err) {
        // console.warn('MapComponent: Intercepted Google not defined within loadScriptFirst()');
    }
    return true;
}

export function addGoogleMapScript(options: any = {}, GOOGLE_MAP_API_KEY): void {
    removeGoogleMapScripts(GOOGLE_MAP_API_KEY);

    const defaultOption: any = {
        key: GOOGLE_MAP_API_KEY,
        libraries: [],
        onload: function () {
            //do nothing
        }
    };
    options = Object.assign(defaultOption, options);

    // console.debug('##################');
    // console.debug('##################');
    console.debug('### GMap: Adding google map script...');
    if (options.libraries.length) {
        console.debug('### GMap: + extra gm-libs: ' + options.libraries.join(','));
    }
    // console.debug('##################');
    // console.debug('##################');

    let gmUrl = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${options.key}`;
    if (options.libraries.length) {
        gmUrl += `${GOOGLE_MAP_LIBRARY_KEYWORD}${options.libraries.join(',')}`;
    }

    const node = document.createElement('script');
    node.src = gmUrl;
    node.type = 'text/javascript';
    node.async = true;
    node.defer = true;
    node.charset = 'utf-8';
    node.onload = options.onload;
    node.nonce = '8c13aaf58a8d41e1a6d01e4af8c386a1';
    document.head.appendChild(node);
}

export function getGoogleAPIKeyByPlatform(platform): string {
    let googleAPIKey;
    console.groupCollapsed("Platform Info:");
    console.group("List:");
    console.debug(getDevicePlatforms(platform));
    console.groupEnd();
    console.debug(`Mobile?: [${isMobile(platform)}]`);
    console.debug(`Mobile Native App?: [${isMobileApp(platform)}]`);
    console.debug(`Desktop/Laptop?: [${!isMobile(platform)}]`);

    if (isMobile(platform)) {
        //Prepare to retrieve mobile google map api key
        const deviceInfo = device.getInfo();
        const devicePlatformInfo = getDevicePlatforms(platform);
        if (devicePlatformInfo.includes('mobileweb')) {
            console.debug("***Mobile Browser Detected***");
            googleAPIKey = environment.googleConfig.web.mapKey;
        } else if (devicePlatformInfo.includes('android')) {
            console.debug("***Android Detected***");
            googleAPIKey = environment.googleConfig.mobile.android.mapKey;
        } else if (devicePlatformInfo.includes('iphone')||devicePlatformInfo.includes('ipad')) {
            console.debug("***IOS Detected***");
            googleAPIKey = environment.googleConfig.mobile.ios.mapKey;
        }
    } else {
        console.debug("***Desktop/Laptop Browser Detected***");
        googleAPIKey = environment.googleConfig.web.mapKey;
    }
    console.groupEnd();
    return googleAPIKey;
}
// export function addLibraryToGoogleMap(newLibs: Array<string> = []) {

//     if (newLibs.length == 0) {
//         return;
//     }

//     let gmScript: Element = document.head.querySelector(`script[src~="maps.googleapis.com/maps/api/js"]`);
//     let scriptSource = gmScript.getAttribute('src');
//     let srcUrl = '';
//     let oldlibs = [];
//     let libsToAppend = [];
//     if (scriptSource != null) {
//         if (scriptSource.includes(GOOGLE_MAP_LIBRARY_KEYWORD)) {
//             let chunks = scriptSource.split(GOOGLE_MAP_LIBRARY_KEYWORD);
//             srcUrl = chunks[0];
//             oldlibs = chunks[1].split(',');

//             //identify libs that requires to be appended
//             for (let lib of newLibs) {
//                 if (oldlibs.indexOf(lib) == -1) {
//                     libsToAppend.push(lib);
//                 }
//             }

//             let newSrcUrl = srcUrl + GOOGLE_MAP_LIBRARY_KEYWORD + oldlibs.join(',') + libsToAppend.join(',');
//             gmScript.setAttribute('src', newSrcUrl);
//         }
//     }
// }

export function removeGoogleMapScripts(GOOGLE_MAP_API_KEY: string): void {
    console.debug('### GMap: Removing google map script...');
    const GOOGLE_MAP_SCRIPT_KEYWORDS = [GOOGLE_MAP_API_KEY, 'maps.googleapis']
    const keywords = GOOGLE_MAP_SCRIPT_KEYWORDS;

    //Remove google from BOM (window object)
    window.google = undefined;

    //Remove google map scripts from DOM
    const scripts = document.head.getElementsByTagName("script");
    for (let i = scripts.length - 1; i >= 0; i--) {
        const scriptSource = scripts[i].getAttribute('src');
        if (scriptSource != null) {
            // scriptSource = scriptSource.toString();
            if (keywords.filter(item => scriptSource.includes(item)).length) {
                scripts[i].remove();
                // scripts[i].parentNode.removeChild(scripts[i]);
            }
        }
    }

    //Remove all scripts
    // let i = scripts.length;
    // while (i--) {
    //     scripts[i].parentNode.removeChild(scripts[i]);
    // }

    // let scripts = document.querySelectorAll("script[src*='maps.googleapis.com/maps-api-v3']");
    // for (let i = 0; i < scripts.length; i++) {
    //     console.debug('script: ' + scripts[i]);
    //     scripts[i].parentNode.removeChild(scripts[i]);
    // }
}

const MEDIA_QUERY_SCREEN_WIDTHS = {
    // Refer `_variables.scss` for latest Media Query Values
    "xs": 480,
    "sm": 640,
    "md": 800,
    "lg": 1024, // <--- Maximum before reaching desktop view
    "xl": 1280,
    "ul": 1540
};

/* Check whether user screen size is specific category */
/** Media Query: `sm-dn`; <= 640px */
export function isMiniMobileView(): boolean {
    return isScreenWidthMq('sm', 'down');
}
/** Media Query: `md-dn` exclusive; < 800px */
export function isMobileView(): boolean {
    return isScreenWidthMq('md', 'down', false);
}
/** Media Query: Between `md-up` inclusive & `lg-down`; >= 800px and <= 1024px*/
export function isTabletView(): boolean {
    return isScreenWidthMq('md', 'up', true) && isScreenWidthMq('lg', 'down');
}
/** Media Query: `lg-up`; > 1024px */
export function isDesktopView(): boolean {
    return isScreenWidthMq('lg', 'up');
}
/** Media Query: `lg-dn`; <= 1024px */
export function isMobileOrTabletView(): boolean {
    return isScreenWidthMq('lg', 'down');
}
/** Media Query: `md-up`; >= 800px */
export function isTabletOrDesktopView(): boolean {
    return isScreenWidthMq('md', 'up', true);
}

/**
 * You should consider using CSS Class instead.
 */
export function isScreenWidthMq(profile: "xs" | "sm" | "md" | "lg" | "xl" | "ul", upOrDown: "up" | "down", inclusive: boolean = null): boolean {
    if (upOrDown === 'up') {
        // up is exclusive by default
        inclusive = (inclusive != null) ? inclusive : false;
        if (inclusive) {
            return (window.innerWidth >= MEDIA_QUERY_SCREEN_WIDTHS[profile]);
        } else {
            return (window.innerWidth > MEDIA_QUERY_SCREEN_WIDTHS[profile]);
        }
    } else if (upOrDown === 'down') {
        // down is inclusive by default
        inclusive = (inclusive != null) ? inclusive : true;
        if (inclusive) {
            return (window.innerWidth <= MEDIA_QUERY_SCREEN_WIDTHS[profile]);
        } else {
            return (window.innerWidth < MEDIA_QUERY_SCREEN_WIDTHS[profile]);
        }
    }
}

/**
 * Check whether user is on mobile platform (ios/android)
 * @param platform ionic platform instance
 * @param os "android" | "ios"
 */
export function isMobile(platform: Platform, os: "android" | "ios" = null): boolean {
    // platforms: "ipad" | "iphone" | "ios" | "android" | "phablet" | "tablet" | "cordova" | "capacitor" | "electron" | "pwa" | "mobile" | "desktop" | "hybrid"

    if (os) {
        return (platform.is(os));
    } else {
        return (platform.is('ios')
            || platform.is('android'));
    }
}

/**
 * Check whether user is on native mobile application (ios/android)
 * @param platform ionic platform instance
 * @param os "android" | "ios"
 */
export function isMobileApp(platform: Platform, os: "android" | "ios" = null): boolean {
    // return isIonic(platform) && isMobile(platform, os);
    return platform.is("cordova") && isMobile(platform, os);
}

// private:
function isIonic(platform: Platform): boolean {
    return platform.is('cordova') || (
        document.URL.indexOf('http://localhost') === 0 ||
        document.URL.indexOf('ionic') === 0 ||
        document.URL.indexOf('https://localhost') === 0
    );
}

/**
 * Returns a string of users' device platforms using Platform.platforms()
 *
 * With additional custom-derived platform: `ionic`
 * @param platform ionic platform instance
 */
export function getDevicePlatforms(platform: Platform): string {
    const arr: string[] = platform.platforms().slice(0);
    if (isIonic(platform) && isMobile(platform)) {
        arr.push('ionic(derived)');
    }
    return arr.join(',');
}

export async function getMobileAppInfo(platform: Platform, appVersion: AppVersion): Promise<{
    appName: string,
    packageName: string,
    versionCode: string,
    versionNumber: string
}> {
    if (isMobileApp(platform)) {

        const appInfo: any = {
            appName: '',
            packageName: '',
            versionCode: '',
            versionNumber: ''
        };

        platform.ready().then(async () => {
            await Promise.all([
                appVersion.getAppName().then((val) => {
                    appInfo.appName = val;
                }),
                appVersion.getPackageName().then((val) => {
                    appInfo.packageName = val;
                }),
                appVersion.getVersionCode().then((val) => {
                    appInfo.versionCode = val.toString();
                }),
                appVersion.getVersionNumber().then((val) => {
                    appInfo.versionNumber = val;
                })
            ]);
        });

        return appInfo;
    }
    return null;
}

export async function getMobileDeviceInfo(platform: Platform, device: Device): Promise<{
    manufacturer: string,
    model: string,
    platform: string,
    version: string
}> {
    if (isMobileApp(platform)) {

        const deviceInfo: any = {
            manufacturer: '',
            model: '',
            platform: '',
            version: ''
        };
        await Promise.all([
            platform.ready().then(() => {
                deviceInfo.manufacturer = device.manufacturer;
                deviceInfo.model = device.model;
                deviceInfo.platform = device.platform;
                deviceInfo.version = device.version;
            })
        ]);
        return deviceInfo;
    }
    return null;
}

export function downloadInNewTab(uri: string) {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
        link.setAttribute('href', uri);
        link.setAttribute('target', '_blank'); // new tab
        link.setAttribute('download', ''); // trigger download (requires HTML5)

        // Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.dispatchEvent(new MouseEvent("click"));
        // link.dispatchEvent(new MouseEvent("click", { ctrlKey: true }));

        //remove the link when done
        document.body.removeChild(link);
    } else {
        window.open(uri, "_blank");
    }
}

export function autoFocusForm(elementRef: ElementRef<any>) {
    // console.log(elementRef);
    const domSelection = ['input', 'select', 'button', 'a'];
    if (elementRef) {
        // console.log('inside if');
        // console.log(elementRef.nativeElement.querySelector('input'));
        for (let i = 0; i < domSelection.length; i++) {
            const eachSelection = domSelection[i];
            if (elementRef.nativeElement.querySelector(eachSelection)) {
                elementRef.nativeElement.querySelector(eachSelection).focus();
                break;
            }
        }
        // elementRef.nativeElement.querySelector('input,select,button,a').focus();
    }
}

/**
 * Returns an array of RED, GREEN, BLUE, ALPHA values based on the provided RGBA string
 * @param rgba The RGBA string.
 * @param getAlpha Whether to include the ALPHA (4th value) in the array.
 * (Default = true)
 */
export function getRgbaValues(rgba: string, getAlpha: boolean = true): number[] {
    //Get values
    let values: string[] = rgba.split(',');
    if (values.length < 3) {
        throw new Error('Invalid RGB/RGBA value!');
    }
    values[0] = values[0].trim().split('(')[1];
    values[1] = values[1].trim();
    values[2] = values[2].trim();
    //Correct missing alpha
    if (getAlpha && values.length == 3) {
        values[3] = '1'; //add 4th value to become RGBA
    } else if (!getAlpha) {
        values = values.slice(0, 3); //only return first 3 RGB values
    }
    return values.map(item => parseFloat(item));
}

/**
 * Returns a RGBA string using the provided RED, GREEN, BLUE, ALPHA values
 * @param rgbaValues Array of RED, GREEN, BLUE, ALPHA values.
 * (Min length = 3, Max length = 4)
 */
export function makeRgbaString(rgbaValues: number[]) {
    if (Array.isArray(rgbaValues) && rgbaValues.length >= 3 && rgbaValues.length <= 4) {
        if (rgbaValues.length == 3) {
            rgbaValues[3] = 1;
        }
        return `rgba(${rgbaValues.join(',')})`;
        // return (rgbaValues.length == 3 ? 'rgb' : 'rgba') + `(${rgbaValues.join(',')})`;
    } else {
        return null;
    }
}

/**
 * Returns an array of randomly generated RGBA Colours
 * @param noOfColors Number of colors to generate
 */
export function getRandomRgbaColour(noOfColors: number) {
    const colorList = [];
    for (let i = 0; i < noOfColors; i++) {
        const randomR = Math.floor((Math.random() * 130) + 100);
        const randomG = Math.floor((Math.random() * 130) + 100);
        const randomB = Math.floor((Math.random() * 130) + 100);
        const colour = `rgba(${randomR}, ${randomG}, ${randomB}, 1)`;
        colorList.push(colour);
    }
    return colorList;
}
