<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        {{ driverUpdateDetails.firstName}} {{ driverUpdateDetails.lastName}}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #driverForm="ngForm">
        <div class="section bgc-dark-white tabs__section">
            <div class="wrapper">
                <div class="tabs__button">
                    <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="overview()">Overview</button>
                    <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="performance()">Performance</button>
                </div>
            </div>
        </div>
        <div class="tabs__content" *ngIf="currentTab == 1">
            <div class="section">
                <div class="wrapper">
                    <div [ngClass]="{'compulsory-notes': !isEditFail, 'alert': isEditFail}">
                        {{actionMessage}}
                    </div>
                    <div class="compulsory-notes">
                        *Compulsory fields
                    </div>

                    <div class="grid --gutter">
                        <div class="grid__block w-8of12@sm form__flex">
                            <h2 class="heading indicator-green --level-2 --partial-underline">
                                Driver Details
                            </h2>

                            <div class="field-group --unstack@xs --space-top">
                                <div class="field-group__item">
                                    <!-- First Name -->
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            First Name:
                                            <span class="indicator-green">*</span>
                                        </div>
                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="driverUpdateDetails.firstName" name="firstName" #firstName="ngModel" required maxlength="45">
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                            <div class="field-error" *ngIf="firstName.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'First Name')}}
                                            </div>
                                            <div class="field-error" *ngIf="firstName.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'First Name', '45')}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- First Name End -->

                                    <!-- Last Name -->
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Last Name:
                                            <span class="indicator-green">*</span>
                                        </div>
                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="driverUpdateDetails.lastName" name="lastName" #lastName="ngModel" required maxlength="45">
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                            <div class="field-error" *ngIf="lastName.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Last Name')}}
                                            </div>
                                            <div class="field-error" *ngIf="lastName.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Last Name', '45')}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Last Name End -->

                                    <!-- Phone Number -->
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Phone Number:
                                        </div>
                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="driverUpdateDetails.telNo" name="telNo" placeholder="eg: 0123456789" maxlength="20">
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Phone Number End -->

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Email:
                                            <span class="indicator-green">*</span>
                                        </div>
                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input class="field__input" [(ngModel)]="driverUpdateDetails.email" name="email" #email="ngModel" required maxlength="45" email>
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                            <div class="field-notes">
                                                (This email will be used as login username for driver)
                                            </div>
                                            <div class="field-error" *ngIf="email.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Email')}}
                                            </div>
                                            <div class="field-error" *ngIf="email.errors?.email">
                                                {{message.MESSAGE.INVALID_EMAIL.label}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            ID Tag Number:
                                        </div>
                                        <div class="field-group__input">

                                            <!-- START DRIVER TAG-->
                                            <div class="field-group --unstack@xs reports__type-field-group">
                                                <div class="field-group__item reports__type-field-group-input" [class.--active]="driverTagSearchDOMStates.isFocused" click-outside (clickOutside)="onOutsideClickDriverTagReaction($event)" (click)="toggleSubjectSelectionFloater(true)">
                                                    <div class="field" #driverTagField>
                                                        <div class="field__elems">
                                                            <div class="field__icon">
                                                                <div class="field__icon-inner">
                                                                    <img src="assets/images/ng-components/vehicle-info/icon-id.svg" width="26" height="20" alt="" class="field__icon-image">
                                                                </div>
                                                            </div>

                                                            <label class="field__holder">
                                                                <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                                    {{ driverTagSelectedLabel }}
                                                                </div>

                                                                <input id="driverTagSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #driverTagSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterDriverTagSelection($event.target.value)">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>

                                                        <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                                            <ul class="inline-input-list">
                                                                <li *ngIf="!driverTagModel.driverTags.length" class="inline-input-list__item">
                                                                    <label class="inline-input">
                                                                        No driver tag found
                                                                    </label>
                                                                </li>
                                                                <li class="inline-input-list__item" *ngFor="let item of driverTagModel.driverTags; let groupIndex = index;">

                                                                    <label class="inline-input">
                                                                        <input type="radio" [name]="driverTagId" [value]="item.driverTagId" [selected]="item.driverTagId === driverUpdateDetails.driverTagId" class="inline-input__input" [(ngModel)]="driverUpdateDetails.driverTagId" (ngModelChange)="onDriverTagSelectChange()">
                                                                        <span class="inline-input__icon"></span>
                                                                        {{ item.driverTagNo }}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- END DRIVER TAG-->
                                            <div class="id-tag-notify">
                                                <div class="id-tag-notify__col">
                                                    <i>Notify after</i>
                                                </div>

                                                <div class="id-tag-notify__col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" [(ngModel)]="driverUpdateDetails.remindMinute" name="remindMinute" placeholder="5" maxlength="2">
                                                            </label>
                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="id-tag-notify__col">
                                                    <i>minutes tagged ID tag.</i>
                                                </div>

                                                <div class="field-group__input apply_all-checkbox">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="inline-input">
                                                                <input type="checkbox" [(ngModel)]="driverUpdateDetails.driverTagRemindApplyAll" name="driverTagRemindApplyAll" class="inline-input__input">
                                                                <span class="inline-input__icon"></span>
                                                                <span>Applied to all driver tag</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid__block w-4of12@sm profile__image-field form__flex">
                            <div class="profile__image-cropped --placeholder-grey" *ngIf="!hasImage">
                                <img id="imageId" src="assets/images/ng-components/create-manage/icon-driver-placeholder.svg" alt="" width="94" height="102">
                                <button type="button" class="button-circle-thumb" (click)="showPopup(imageUploadPopup)">
                                    <img src="assets/images/ng-components/create-manage/icon-add-photo.svg" alt="" width="23" height="23">
                                </button>
                            </div>
                            <div class="profile__image-cropped --placeholder-grey --placeholder-photo" *ngIf="hasImage">
                                <img id="imageId" src="{{driverUpdateDetails.photo}}" alt="" width="206" height="206">
                                <button type="button" class="button-circle-thumb" (click)="showPopup(imageUploadPopup)">
                                    <img src="assets/images/ng-components/create-manage/icon-edit-photo.svg" alt="" width="22" height="22">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section --border-top">
                <div class="wrapper">
                    <div class="grid --gutter">
                        <div class="grid__block w-8of12@sm form__flex">
                            <h2 class="heading indicator-green --level-2 --partial-underline">
                                Licence Information
                            </h2>

                            <div class="field-group --unstack@xs --space-top">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Licence No:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="driverUpdateDetails.drivingLicenseNo" name="DrivingLicenseNo" maxlength="45">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Expiry Date:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <div class="field__icon">
                                                        <div class="field__icon-inner">
                                                            <mat-datepicker-toggle [for]="ExpiryDatepicker">
                                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                            </mat-datepicker-toggle>
                                                        </div>
                                                    </div>

                                                    <input [matDatepicker]="ExpiryDatepicker" (focus)="ExpiryDatepicker.open()" (click)="ExpiryDatepicker.open()" type="text" [(ngModel)]="driverUpdateDetails.drivingLicenseExpiryDate" name="DrivingLicenseExpiryDate" class="field__input" readonly dom-states #ExpiryDateDOMStates="DOMStates">

                                                    <div class="field__ui"></div>
                                                </div>

                                                <mat-datepicker #ExpiryDatepicker [touchUi]="datepickerTouchUi">
                                                </mat-datepicker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Expiry Reminder Day(s):
                                        </div>
                                        <div class="field-group__input apply_all-checkbox">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="driverUpdateDetails.licenseExpReminderDays" name="licenseExpReminderDays" placeholder="eg: 30" maxlength="10" OnlyNumber>
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                            <div class="field-notes">
                                                (Leave empty will restore to default 30 days)
                                            </div>
                                        </div>

                                        <div class="field-group__input apply_all-checkbox">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="inline-input">
                                                        <input type="checkbox" [(ngModel)]="driverUpdateDetails.licenseDaysApplyAll" name="licenseDaysApplyAll" class="inline-input__input">
                                                        <span class="inline-input__icon"></span>
                                                        <span>Applied to all drivers</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="section --border-top">
                <div class="wrapper">
                    <div class="block">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Account Security
                        </h2>
                    </div>

                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-8of12@sm form__flex">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item">
                                        <div class="field-group__holder">
                                            <div class="field-group__label">
                                                Email:
                                                <span class="indicator-green">*</span>
                                            </div>
                                            <div class="field-group__input">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <label class="field__holder">
                                                            <input class="field__input" [(ngModel)]="driverUpdateDetails.email" name="email" #email="ngModel" required maxlength="45" email>
                                                        </label>
                                                        <div class="field__ui"></div>
                                                    </div>
                                                </div>
                                                <div class="field-notes">
                                                    (This email will be used as login username for driver)
                                                </div>
                                                <div class="field-error" *ngIf="email.errors?.required">
                                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Email')}}
                                                </div>
                                                <div class="field-error" *ngIf="email.errors?.email">
                                                    {{message.MESSAGE.INVALID_EMAIL.label}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__holder">
                                            <div class="field-group__label">
                                                Password: <span class="indicator-green">*</span>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field" style="z-index: 5;">
                                                    <div class="field__elems">
                                                        <label class="field__holder">
                                                            <input type="password" class="field__input" #password="ngModel" [(ngModel)]="formModel.password" name="password" autocomplete="new-password" required tabindex="2">
                                                        </label>

                                                        <div class="field__ui"></div>
                                                    </div>
                                                </div>

                                                <div class="field-error" *ngIf="!!password.errors?.required && (!!password.dirty || !!password.touched)">
                                                    New Password is required.
                                                </div>

                                                <div class="password-indicator">
                                                    <div class="password-strength">
                                                        Password strength: <b>{{ formModel.passwordStrength }}</b>
                                                    </div>

                                                    <mat-password-strength #passwordComponent (onPasswordStrengthChanged)="onPasswordStrengthChanged($event)" [password]="password.value">
                                                    </mat-password-strength>
                                                </div>
                                            </div>

                                            <div class="field__icon">
                                                <div class="field__icon-inner">
                                                    <div class="toggle__item-groups" [class.--active]="toggle.active">
                                                        <button class="toggle__item-groups-toggle" toggle #toggle="toggle">
                                                            <img src="assets/images/scss-components/forms/icon-question.svg" width="26" height="26" alt="" class="field__icon-image">
                                                        </button>

                                                        <div class="toggle__item-groups-floater">
                                                            <button class="toggle__item-groups-close" (click)="toggle.setInactive()"></button>

                                                            <div class="toggle__item-groups-contents">
                                                                Password must be a minimum of 8 characters and contain the following:<br><br>

                                                                <ul>
                                                                    <li> At least 1 upper case letter (A, B, C...Z)
                                                                    </li>
                                                                    <li>At least 1 lower case letter (a, b, c...z)
                                                                    </li>
                                                                    <li>At least 1 number (1, 2, 3...9)</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__holder">
                                            <div class="field-group__label">
                                                Confirm Password: <span class="indicator-green">*</span>
                                            </div>

                                            <div class="field-group__input">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <input type="password" class="field__input" #confirmPassword="ngModel" [(ngModel)]="formModel.confirmPassword" name="confirmPassword" autocomplete="new-password" required [class.--error]="!confirmPassword.errors?.required && formModel.password !== formModel.confirmPassword && (!!confirmPassword.dirty || !!confirmPassword.touched)" tabindex="3">
                                                        <div class="field__ui"></div>
                                                    </div>
                                                </div>
                                                <div class="field-error" *ngIf="!!confirmPassword.errors?.required && (!!confirmPassword.dirty || !!confirmPassword.touched)">
                                                    Confirm Password is required.
                                                </div>
                                                <div class="field-error" *ngIf="!confirmPassword.errors?.required && formModel.password !== formModel.confirmPassword && (!!confirmPassword.dirty || !!confirmPassword.touched)">
                                                    Passwords do not match.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="section">
                <div class="wrapper">
                    <div class="grid --gutter">
                        <div class="grid__block">
                            <div class="inline-button__field">
                                <button class="button inline-button__inputs" type="button" [disabled]="driverForm.invalid" (click)="showPopup(editDriverPopup)">
                                    SAVE
                                </button>

                                <button class="button inline-button__inputs --grey" type="button" (click)="navigateToDriverView()">
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-popup #imageUploadPopup="popup" class="cm-popups">
                <div class="popup__block">
                    <div class="cm-heading__main">
                        <div class="cm-heading__text">
                            Image Upload
                        </div>
                    </div>
                </div>

                <div class="popup__block">
                    <div class="image-placeholder">
                        <img src="assets/images/ng-components/create-manage/icon-image-upload.svg" alt="" width="152" height="133">
                    </div>
                </div>

                <div class="popup__block">
                    <div class="inline-button__field">
                        <label>
                            <span class="button inline-button__inputs">
                                Browse
                            </span>
                            <input type="file" id="myFileInput" accept="image/*" (change)="chooseImage($event)" />
                        </label>
                    </div>
                    <span class="notes-input">( Max File Size: {{maxSizeImageMB}}MB )</span>
                </div>
            </app-popup>

            <app-popup #imageAlertPopup="popup" class="cm-popups">
                <div class="popup__block">
                    <div class="cm-heading__main">
                        <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
                        <div class="cm-heading__text">
                            Warning
                        </div>
                    </div>
                </div>
                <div class="popup__block">
                    <span class="notes-input --alert" *ngIf="imageAlertError === 'sizeError'">Image Size Cannot Larger Than
                        {{maxSizeImageMB}}MB</span>
                    <span class="notes-input --alert" *ngIf="imageAlertError === 'fileError'">Invalid Image File</span>
                </div>
                <div class="popup__block">
                    <div class="inline-button__field">
                        <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                            OK
                        </button>
                    </div>
                </div>
            </app-popup>

            <app-popup #editDriverPopup="popup" class="cm-popups">
                <div class="popup__block">
                    <div class="cm-heading__main">
                        <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

                        <div class="cm-heading__text">
                            Confirm Changes?
                        </div>
                    </div>
                </div>

                <!-- <div class="popup__block">
                    <p>
                        You are going to save driver changes
                    </p>

                    <p class="notes">
                    </p>
                </div> -->

                <div class="popup__block">
                    <div class="inline-button__field">
                        <button class="button inline-button__inputs" type="button" (click)="updateDriverInfo();hidePopup();">
                            CONFIRM
                        </button>

                        <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                            CANCEL
                        </button>
                    </div>
                </div>
            </app-popup>
            <app-popup #editCropImagePopup="popup" class="cm-popups">
                <div class="popup__block">
                    <div class="cm-heading__main">
                        <div class="cm-heading__text">
                            Crop Image
                        </div>
                    </div>
                </div>
                <app-spinner #cropImage_spinner></app-spinner>
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [resizeToWidth]="maxWidthOrHeight" [onlyScaleDown]="true" [roundCropper]="true" outputType="base64" (cropperReady)="cropperReady()" (imageCropped)="imageCropped($event)"></image-cropper>
                <div class="popup__block">
                    <div class="inline-button__field">
                        <div class="inline-button__inputs">
                            <button type="button" class="button-crop" (click)="imageDirection('left')">
                                <img src="assets/images/ng-components/create-manage/icon-rotate-left.svg" alt="" width="23" height="23">
                            </button>

                            <button class="button-crop" type="button" (click)="imageDirection('right')">
                                <img src="assets/images/ng-components/create-manage/icon-rotate-right.svg" alt="" width="23" height="23">
                            </button>
                        </div>
                    </div>
                </div>

                <div class="popup__block">
                    <div class="inline-button__field">
                        <button class="button inline-button__inputs" type="button" (click)="confirmCropImage();hidePopup();">
                            CONFIRM
                        </button>

                        <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                            CANCEL
                        </button>
                    </div>
                </div>
            </app-popup>
        </div>

        <div class="tabs__content" *ngIf="currentTab == 2">
            <app-performance></app-performance>
        </div>
    </form>
</div>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
