import * as StringUtil from './stringUtil';
import * as Message from './../constants/message';
import * as FuelTempConst from './../constants/fuelTemplateType.constant';
import * as XLSX from 'xlsx';

/**
 * To Get next column Alphabet(s) from excel file
 * @param string current column Alphabet(s)
 * When reach Z, AZ, ZZ, AAZZ kind of column,
 * next column will be AA, BA, AAA, ABAA
 */
export function getNextExcelColumnLabelAlphabets(string: string) {
    const charArr: any = string.split('').reverse();
    // Increment for next column
    charArr[0] = StringUtil.getNextChar(charArr[0]);

    // Ensure all Character within A-Z
    for (let i = 0; i < charArr.length; i++) {
        let eachChar = charArr[i];
        if (eachChar.charCodeAt(0) > 90) {
            // Increase next char value
            const nextIndex = i + 1;
            if (nextIndex < charArr.length) {
                let currChar = charArr[nextIndex];
                currChar = StringUtil.getNextChar(currChar);
                charArr[nextIndex] = currChar;
            } else {
                // If reach 'Z' columns next column will be AA onward
                charArr.push('A');
            }
            // Reset current value within A-Z
            const increment = eachChar.charCodeAt(0) - 90;
            if (increment > 0) {
                eachChar = '@';
                for (let j = 0; j < increment; j++) {
                    eachChar = StringUtil.getNextChar(eachChar);
                }
            }

            charArr[i] = eachChar;
        } else {
            continue;
        }
    }
    return charArr.reverse().join('');
}

/**
 * Use To Verify uploaded excel file and template format is match
 * @param worksheetHaederObj The header of the uploaded excel File
 * @param worksheetObj Original Contents of the uploaded excel file that converted to JSON format
 * @param headerRowNum Header Row number
 * @param headerList List of Header must have for the template
 * @param repHeaderList List of Db column name is using
 */
export async function verifyFuelBrandTemplate(worksheetHeaderObj, worksheetObj, headerRowNum, headerList, repHeaderList) {
    // console.log("Verifying Header Match Current Template Version[excelUtil]");
    const fieldValidateResult: any = {
        worksheet: worksheetObj,
        // errorMessage: null,
        status: true,
    };

    // Get headers row
    const hdrNotFoundRowObj = [];
    let hdrListIndex = 0;
    let prevKey = null;
    const columnInfo = [];
    let dateHeaderIndex = 0;
    for (const eachKey in worksheetHeaderObj) {
        if (eachKey) {
            if (eachKey.includes('!') || XLSX.utils.decode_cell(eachKey).r != headerRowNum) {
                // skip excel configuration Eg: Margin, Merge, etc...
                // console.log("Skip Excel Configuration JSON obj[excelUtil]");
                continue;
            } else {
                // reach header row's columns
                if (XLSX.utils.decode_cell(eachKey).r == headerRowNum) {
                    // console.log("On Header Row[excelUtil]");
                    const currHdr = worksheetHeaderObj[eachKey].v.replace(/\s/g, '');
                    if (StringUtil.equalsIgnoreCase(currHdr, "Invalid Reason".replace(/\s/g, ''))) {
                        // console.log("Skip Re-Uploaded excel file with Remark[excelUtil]");
                        continue;
                    }
                    const tempHdr = headerList[hdrListIndex].replace(/\s/g, '');
                    if (StringUtil.equalsIgnoreCase(currHdr, tempHdr)) {
                        // console.log("Found Header, Begin Replace Header with Db column Name[excelUtil]");
                        if (StringUtil.equalsIgnoreCase(repHeaderList[hdrListIndex], 'fuelDate')) {
                            columnInfo.push({ cellName: eachKey.replace(new RegExp("[0-9]", "g"), ''), type: "date", format: "DD/MM/YYYY" });
                            dateHeaderIndex = columnInfo.length - 1;
                        }
                        if (StringUtil.equalsIgnoreCase(repHeaderList[hdrListIndex], 'fuelTime')) {
                            columnInfo.push({ cellName: eachKey.replace(new RegExp("[0-9]", "g"), ''), type: "time", format: "HH:mm:ss" });
                        } else {
                            if (hdrListIndex == repHeaderList.length) {
                                columnInfo[dateHeaderIndex].hasTime = false;
                            }
                        }

                        // If Matched replace db using column name and continue next loop
                        const newColName = repHeaderList[hdrListIndex];
                        worksheetObj[`${eachKey}`] = Object.assign(worksheetObj[`${eachKey}`], { v: newColName/* , h: newColName, w: newColName  */ });
                        hdrListIndex++;
                    } else {
                        // console.log("Header Not Match, Keeping Result");
                        // Return with error on column not found or being shifted
                        hdrNotFoundRowObj.push({ prevColName: worksheetHeaderObj[prevKey] || null, currColName: worksheetHeaderObj[eachKey] });
                        break;
                    }
                }
            }
            prevKey = eachKey;
        }
    }
    // Certain Template Header not found/ Shifted location
    if (hdrNotFoundRowObj.length > 0) {
        fieldValidateResult.status = false;
        fieldValidateResult.errorMessage = `Column Not Found [${hdrNotFoundRowObj[0].currColName}], After Column [${hdrNotFoundRowObj[0].prevColName}] Please Check the Excel File`;
    }
    // Certain Column is Missing and Yet the verification reach the end of the header
    if (hdrListIndex !== headerList.length) {
        fieldValidateResult.status = false;
        fieldValidateResult.errorMessage = `Certain Column is Missing Please Check the Column`;
    }

    // for (let i = hdrListIndex; i < headerList.length; i++) {
    //     if (StringUtil.equalsIgnoreCase(headerRowObj[i].value.v, headerList[i])) {
    //         // console.log("found" + headerList[i]);
    //         hdrListIndex++;
    //     } else if (i == headerList.length - 1) {
    //         fieldValidateResult.status = false;
    //         fieldValidateResult.errorMessage = Message.getMessage(Message.MESSAGE.COLUMN_NOT_FOUND.value, headerList[hdrListIndex]);
    //         return fieldValidateResult;
    //     }
    // }

    // all validated pass
    fieldValidateResult.worksheet = worksheetObj;
    fieldValidateResult.columnInfo = columnInfo;
    return fieldValidateResult;
}

/**
 * Find Excel's Header location
 * @param worksheet object, excel sheet inside workbook
 * @param headerName string, first column header value
 *
 */
export async function findHeaderRowNum(worksheetChk: any, headerName: string, brandLabel: string): Promise<any> {
    const result = {
        status: 'FAILED',
        errorMessage: Message.getMessage(Message.MESSAGE.TEMPLATE_FILE_MISMATCH.value, brandLabel),
        rowNum: 0,
        new_excla_ref: null
    };

    for (const eachKey in worksheetChk) {
        // console.log(worksheetChk[eachKey].v, headerName);
        const currCell = (StringUtil.isNotEmptyOrNull(worksheetChk[eachKey].v)) ? worksheetChk[eachKey].v.toString() : '';
        if (StringUtil.equalsIgnoreCase(currCell, headerName)) {
            // console.log(worksheetChk[eachKey].v, headerName);
            // decode value into 0-indexed form
            const zero_idx_obj = XLSX.utils.decode_cell(eachKey);
            // set value
            worksheetChk['!ref'] = delFirstXRows(worksheetChk['!ref'], zero_idx_obj.r);
            // console.log(worksheetChk);
            // return new worksheet
            result.status = 'SUCCESS';
            delete result.errorMessage;
            result.rowNum = zero_idx_obj.r;
            result.new_excla_ref = worksheetChk['!ref'];
            break;
        }
    }
    return result;
}
/**
 * toDo: Refine it to become paramValidator(refer tin project)
 *       paramValidator good for reusable, but nested looping (x checking columns) * (x columns provided in the uploaded excel file)
 *       current approach long code for (x check columns) with single looping (x columns provided in the uploaded excel file)
 */

export async function verifyExcelFuelEntryList(worksheetHaederObj, worksheetObj, requiredField, templateFuelBrand) {
    // validation configs
    let isPlateNo: boolean = false;
    let plateNoColumn: String = null;
    const vehiclePlateNoParams = {
        keyWordArr: [
            { wordSearching: 'vehicle', required: true, searchKey: ["vehicle", "car"] },
            { wordSearching: 'plate', required: true, searchKey: ["plate", "registration"] },
            { wordSearching: 'number', required: true, searchKey: ["no", "no.", "number", "nombor"] }
        ]
    };

    let isRefuelQuantity: boolean = false;
    let refuelQuantityColumn: String = null;
    const fuelQuantityParams = {
        keyWordArr: [
            { wordSearching: 'fuel', required: true, searchKey: ["fuel", "petrol"] },
            { wordSearching: 'quantity', required: true, searchKey: ["quantity"] }
        ]
    };

    let isRefuelCost: boolean = false;
    let refuelCostColumn: String = null;
    const fuelCostParams = {
        keyWordArr: [
            { wordSearching: 'fuel', required: false, searchKey: ["fuel", "petrol"] },
            { wordSearching: 'cost', required: true, searchKey: ["charges", "cost", "amount"] }
        ]
    };

    let isRefuelDate: boolean = false;
    let refuelDateColumn: String = null;
    const fuelDateParams = {
        keyWordArr: [
            { wordSearching: 'fuel', required: false, searchKey: ["fuel", "petrol"] },
            { wordSearching: 'datetime', required: true, searchKey: ["date", "datetime", "date/time", "time"] }
        ]
    };

    let isLocation: boolean = false;
    let locationColumn: String = null;
    const locationParams = {
        keyWordArr: [
            { wordSearching: 'location', required: true, searchKey: ["location", "petrol station", "place"] },
        ]
    };

    let isFuelType: boolean = false;
    let fuelTypeColumn: String = null;
    const fuelTypeParams = {
        keyWordArr: [
            { wordSearching: 'fuel', required: false, searchKey: ["fuel", "petrol"] },
            { wordSearching: 'type', required: true, searchKey: ["type"] },
        ]
    };

    let isOdometer: boolean = false;
    let odometerColumn: String = null;
    const odometerParams = {
        keyWordArr: [
            { wordSearching: 'odometer', required: true, searchKey: ["odometer", "meter"] },
            { wordSearching: 'reading', required: false, searchKey: ["reading"] },
        ]
    };

    // validations
    for (const eachHeader in worksheetHaederObj) {
        // skip those !ref, !cols, !margin(xlsx Fields Config)...
        if (!eachHeader.includes('!')) {
            // skip it when found in previous column header
            if (!isPlateNo) {
                const searchResult = await findRequiredField(worksheetHaederObj[eachHeader].v, vehiclePlateNoParams);
                if (searchResult.status) {
                    isPlateNo = searchResult.status;
                    plateNoColumn = eachHeader;
                }
            }
            if (!isRefuelQuantity) {
                const searchResult = await findRequiredField(worksheetHaederObj[eachHeader].v, fuelQuantityParams);
                if (searchResult.status) {
                    isRefuelQuantity = searchResult.status;
                    refuelQuantityColumn = eachHeader;
                }
            }
            if (!isRefuelCost) {
                const searchResult = await findRequiredField(worksheetHaederObj[eachHeader].v, fuelCostParams);
                if (searchResult.status) {
                    isRefuelCost = searchResult.status;
                    refuelCostColumn = eachHeader;
                }
            }
            if (!isRefuelDate) {
                const searchResult = await findRequiredField(worksheetHaederObj[eachHeader].v, fuelDateParams);
                if (searchResult.status) {
                    isRefuelDate = searchResult.status;
                    refuelDateColumn = eachHeader;
                }
            }
            if (!isLocation) {
                const searchResult = await findRequiredField(worksheetHaederObj[eachHeader].v, locationParams);
                if (searchResult.status) {
                    isLocation = searchResult.status;
                    locationColumn = eachHeader;
                }
            }
            if (!isFuelType) {
                const searchResult = await findRequiredField(worksheetHaederObj[eachHeader].v, fuelTypeParams);
                if (searchResult.status) {
                    isFuelType = searchResult.status;
                    fuelTypeColumn = eachHeader;
                }
            }
            if (!isOdometer) {
                const searchResult = await findRequiredField(worksheetHaederObj[eachHeader].v, odometerParams);
                if (searchResult.status) {
                    isOdometer = searchResult.status;
                    odometerColumn = eachHeader;
                }
            }

            if (isPlateNo && isRefuelQuantity && isRefuelCost && isRefuelDate && isLocation && isFuelType) {
                break; // all fields found skip the rest
            }
        }
    }

    // rename field name(Optional)
    const requiredFieldResult: any = {
        worksheet: worksheetObj,
        errorMessage: null,
        status: true,
    };
    if (!isPlateNo) {
        if (requiredField.vehiclePlateNo) {
            requiredFieldResult.status = false;
            requiredFieldResult.errorMessage = Message.getMessage(Message.MESSAGE.COLUMN_NOT_FOUND.value, 'Vehicle Plate Number');
            return requiredFieldResult;
        }
    } else {
        worksheetObj[`${plateNoColumn}`] = Object.assign(worksheetObj[`${plateNoColumn}`], { v: 'plateNo', h: 'plateNo', w: 'plateNo' });
    }
    if (!isRefuelQuantity) {
        if (requiredField.fuelQuantity) {
            requiredFieldResult.status = false;
            requiredFieldResult.errorMessage = Message.getMessage(Message.MESSAGE.COLUMN_NOT_FOUND.value, 'Fuel Quantity (L)');
            return requiredFieldResult;
        }
    } else {
        worksheetObj[`${refuelQuantityColumn}`] = Object.assign(worksheetObj[`${refuelQuantityColumn}`], { v: 'fuelQuantity', h: 'fuelQuantity', w: 'fuelQuantity' });
    }
    if (!isRefuelCost) {
        if (requiredField.fuelCost) {
            requiredFieldResult.status = false;
            requiredFieldResult.errorMessage = Message.getMessage(Message.MESSAGE.COLUMN_NOT_FOUND.value, 'Cost (RM)');
            return requiredFieldResult;
        }
    } else {
        worksheetObj[`${refuelCostColumn}`] = Object.assign(worksheetObj[`${refuelCostColumn}`], { v: 'fuelCost', h: 'fuelCost', w: 'fuelCost' });
    }
    if (!isRefuelDate) {
        if (requiredField.fuelDate) {
            requiredFieldResult.status = false;
            requiredFieldResult.errorMessage = Message.getMessage(Message.MESSAGE.COLUMN_NOT_FOUND.value, 'Date/Time');
            return requiredFieldResult;
        }
    } else {
        worksheetObj[`${refuelDateColumn}`] = Object.assign(worksheetObj[`${refuelDateColumn}`], { v: 'fuelDate', h: 'fuelDate', w: 'fuelDate' });
    }
    if (!isLocation) {
        if (requiredField.location) {
            requiredFieldResult.status = false;
            requiredFieldResult.errorMessage = Message.getMessage(Message.MESSAGE.COLUMN_NOT_FOUND.value, 'Location');
            return requiredFieldResult;
        }
    } else {
        worksheetObj[`${locationColumn}`] = Object.assign(worksheetObj[`${locationColumn}`], { v: 'location', h: 'location', w: 'location' });
    }
    if (!isFuelType) {
        if (requiredField.fuelType) {
            requiredFieldResult.status = false;
            requiredFieldResult.errorMessage = Message.getMessage(Message.MESSAGE.COLUMN_NOT_FOUND.value, 'Fuel Type');
            return requiredFieldResult;
        }
    } else {
        worksheetObj[`${fuelTypeColumn}`] = Object.assign(worksheetObj[`${fuelTypeColumn}`], { v: 'fuelType', h: 'fuelType', w: 'fuelType' });
    }
    if (!isOdometer) {
        if (requiredField.odometer) {
            requiredFieldResult.status = false;
            requiredFieldResult.errorMessage = Message.getMessage(Message.MESSAGE.COLUMN_NOT_FOUND.value, 'odometer');
            return requiredFieldResult;
        }
    } else {
        worksheetObj[`${odometerColumn}`] = Object.assign(worksheetObj[`${odometerColumn}`], { v: 'odometer', h: 'odometer', w: 'odometer' });
    }

    // all validated pass
    requiredFieldResult.worksheet = worksheetObj;
    return requiredFieldResult;
}

/**
 * This method use to verify the column type
 * @param cellHeader The checking column's header title
 * @param cellParams The validate required string to identify the column type
 * sample of cellParams = {
        keyWordArr: [
            {
                required: true,
                searchKey: ["date", "datetime", "date/time", "time"]
            },
            {
                required: true,
                searchKey: ["fuel", "petrol", "refuel"]
            }
        ]
    }
  */
export async function findRequiredField(cellHeader: string, cellParams: any) {
    cellHeader = cellHeader.toLowerCase();
    const foundKeyWordArr = [cellParams.keyWordArr.length];
    const actualResult = [cellParams.keyWordArr.length];

    for (let i = 0; i < cellParams.keyWordArr.length; i++) {
        const keyWordInfo = cellParams.keyWordArr[i];
        foundKeyWordArr[i] = {
            searchWord: keyWordInfo.wordSearching,
            status: false
        };
        // foundKeyWordArr[i].searchWord = keyWordInfo.wordSearching;
        // foundKeyWordArr[i].status = false;
        actualResult[i] = false;

        const foundKeyWord = keyWordInfo.searchKey.some(eachKey => {
            return cellHeader.includes(eachKey);
        });
        if (foundKeyWord) {
            foundKeyWordArr[i].status = true;
            actualResult[i] = true;
        }
        // ensure not required is true after search
        if (!keyWordInfo.required) {
            actualResult[i] = true;
        }
    }

    // false if 1 required keyword not found
    const result = {
        status: false,
        keyWordResult: [],
    };

    result.status = actualResult.every(eachResult => {
        return eachResult == true;
    });
    result.keyWordResult = foundKeyWordArr;

    return result;
}

/**
 * Generate new !ref for worksheet to remove uneccesary beginning rows
 * @param excla_ref string !ref value Eg: "A1:AB8234"
 * @param n number of row required to remove
 */
export function delFirstXRows(excla_ref, n): string {
    const range = XLSX.utils.decode_range(excla_ref);
    // exclude rows
    range.s.r = n;
    if (range.s.r >= range.e.r) {
        range.s.r = range.e.r;
    }
    // update !ref
    // console.log(range);
    return XLSX.utils.encode_range(range);
}

export function delLastXRows(excla_ref, n): string {
    const range = XLSX.utils.decode_range(excla_ref);
    // exclude rows
    range.e.r = n;
    if (range.e.r <= range.s.r) {
        range.e.r = range.s.r;
    }
    // update !ref
    return XLSX.utils.encode_range(range);
}

export function formatExcelSheetDateTime(worksheet, contentRange, headerInfo, headerRowNum) {
    let index = contentRange.s.r + 2;
    do {
        headerInfo.map(eachHeader => {
            let dateFormat = eachHeader.format;
            if (eachHeader.hasTime && eachHeader.hasTime == false) {
                dateFormat = "DD/MM/YYYY HH:mm:ss";
            }
            const cellName = eachHeader.cellName + index;
            XLSX.utils.format_cell(worksheet[cellName], null, { dateNF: dateFormat });
            if (index != contentRange.s.r) {
                if (worksheet[cellName]) {
                    worksheet[cellName].v = worksheet[cellName].w;
                    delete worksheet[cellName].w;
                }
            }
        });

        index++;
    } while (index <= contentRange.e.r);
    // this.worksheet[`H${contentRange.e.r}`].v = this.worksheet[`H${contentRange.e.r}`].w;
    // delete this.worksheet[`H${contentRange.e.r}`].w;
    return worksheet;
}
