<div class="login">
    <div class="login__layout">
        <div class="login__header">
            <button type="button" (click)="currentView = 'login'">
                <img
                    [src]="imageFolder + 'logo-mDrive.svg'"
                    alt="MDrive"
                    class="login__logo"
                />
            </button>
        </div>

        <div class="login__body">
            <ng-container *ngIf="currentView === 'login'">
                <!-- <div class="notes-box typography">
                    <h2 class="notes-box__title">
                        Welcome to the new mDrive!
                    </h2>

                    <p *ngIf="showClassicMdriveLink">
                        If you have yet to be upgraded, you can login using the classic mDrive<a class="link" (click)="switchToClassic()"> here</a>.
                    </p>
                </div> -->

                <div class="login__body-top">
                    <h1 class="heading login__heading">Sign In</h1>
                </div>

                <form
                    class="form login__form"
                    [formGroup]="loginForm"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="form__row">
                        <div class="field-label">Username:</div>

                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input
                                        type="text"
                                        formControlName="username"
                                        class="field__input"
                                        [class.--error]="
                                            submitted && f.username.errors
                                        "
                                        autocomplete="username"
                                        (input)="
                                            onUsernameInput($event.target.value)
                                        "
                                    />
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>

                        <div
                            class="field-error"
                            *ngIf="submitted && f.username.errors"
                        >
                            <ng-container *ngIf="f.username.errors?.required">
                                Username is required.
                            </ng-container>
                        </div>
                    </div>

                    <div class="form__row" *ngIf="!isSSOLogin">
                        <div class="field-label">Password:</div>

                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input
                                        type="password"
                                        formControlName="password"
                                        class="field__input"
                                        [class.--error]="
                                            submitted && f.password.errors
                                        "
                                        autocomplete="current-password"
                                    />
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>

                        <div
                            class="field-error"
                            *ngIf="submitted && f.password.errors"
                        >
                            <ng-container *ngIf="f.password.errors?.required">
                                Password is required.
                            </ng-container>
                        </div>
                    </div>

                    <div class="form__row fs-sm">
                        <div
                            class="grid"
                            [ngStyle]="{
                                'padding-top': '5px',
                                'padding-bottom': '5px'
                            }"
                        >
                            <div class="w-6of12 as-c">
                                <div class="field-error-2" *ngIf="error">
                                    {{ error }}
                                </div>
                            </div>

                            <div class="w-6of12 as-c ta-r" *ngIf="!isSSOLogin">
                                <button
                                    type="button"
                                    class="link"
                                    (click)="
                                        currentView = 'forgot-password';
                                        initForgot()
                                    "
                                >
                                    Forgot Password
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form__row --space-more --button-switch">
                        <button
                            type="submit"
                            [disabled]="loading"
                            class="button --min-width --normal"
                            *ngIf="!isSSOLogin"
                        >
                            <mat-spinner
                                *ngIf="loading"
                                diameter="18"
                                class="--light --center"
                            ></mat-spinner>
                            <span *ngIf="!loading"> Log in </span></button
                        >&nbsp;&nbsp;
                        <button
                            type="submit"
                            [disabled]="loading"
                            class="button --min-width --normal"
                            *ngIf="isSSOLogin"
                        >
                            <mat-spinner
                                *ngIf="loading"
                                diameter="18"
                                class="--light --center"
                            ></mat-spinner>
                            <span *ngIf="!loading">
                                Sign in via SSO
                            </span></button
                        >&nbsp;&nbsp;
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="currentView === 'forgot-password'">
                <div class="login__body-top">
                    <h1 class="heading login__heading">Forgot Password</h1>

                    <p>Enter your email to recover your password.</p>
                </div>

                <form class="form login__form" [formGroup]="emailForm">
                    <div class="form__row">
                        <div class="field-label">Email:</div>

                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <input
                                        type="email"
                                        formControlName="email"
                                        class="field__input"
                                        required
                                        maxlength="45"
                                        email
                                    />
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>
                        <div
                            class="field-error"
                            *ngIf="
                                f2.email.errors &&
                                (f2.email.dirty || f2.email.touched)
                            "
                        >
                            <ng-container *ngIf="f2.email.errors?.required">
                                {{
                                    message.getMessage(
                                        message.MESSAGE.IS_EMPTY.value,
                                        "Email"
                                    )
                                }}
                            </ng-container>
                            <ng-container *ngIf="f2.email.errors?.email">
                                {{ message.MESSAGE.INVALID_EMAIL.label }}
                            </ng-container>
                        </div>
                    </div>

                    <div class="form__row --space-more">
                        <button
                            type="button"
                            [disabled]="sent || f2.email.errors"
                            class="button --min-width"
                            (click)="forgotpassword()"
                        >
                            <mat-spinner
                                *ngIf="sent"
                                diameter="18"
                                class="--light --center"
                            ></mat-spinner>
                            <span *ngIf="!sent"> Reset </span>
                        </button>
                        &nbsp;&nbsp;
                        <button
                            type="button"
                            class="button --min-width --grey"
                            (click)="closeForgotPassword()"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</div>

<!-- TO BE HIDDEN AFTER RDS UPGRADE, COMMENT OUT METHOD IN TS-->
<app-popup #disruptionAlertPopup="popup" class="cm-popups">
    <div style="font-family: Arial, sans-serif; padding: 20px">
        <h1 style="color: #0072c6; font-size: 24px">
            Dear mDrive valued customer,
        </h1>
        <p>
            We have scheduled a system maintenance for Maxis mDrive platform (<a
                href="https://mdrive.my/login"
                style="color: #0072c6; text-decoration: none"
                >https://mdrive.my/login</a
            >) on <b>31st July 2024</b> from <b>12:00am till 5:00am</b> and <b>1st August 2024</b> from <b>12:00am till 5:00am</b>.
        </p>
        <p>
            The following functions will not available during the maintenance
            period:
        </p>
        <div>
            <ul>
                <li>Login to mDrive portal and its mobile apps</li>
            </ul>
        </div>
        <br>
        <h1 style="font-weight: bold">Thank you for your patience.</h1>
    </div>
</app-popup>

<app-popup #emailSentPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img
                src="assets/images/ng-components/create-manage/icon-cm-alert.svg"
                alt=""
                width="43"
                height="43"
                class="cm-heading__icon"
            />

            <div class="cm-heading__text">Reset Password</div>
        </div>
    </div>

    <div class="popup__block">
        <p [innerHtml]="emailSentPopupMsg"></p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button
                class="button inline-button__inputs"
                type="button"
                (click)="hidePopup(); currentView = 'login'"
            >
                Ok
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign
    *ngIf="hasUnviewedCamapaign"
    [unviewedCampaigns]="unviewedCampaigns"
>
</popup-campaign>
