export const MESSAGE_BUS = {
    MAP: {
        source: "map",
        name: "MapComponent"
    },
    MAP_MARKER: {
        source: 'mapMarker',
        name: null
    },
    MAP_LIST: {
        source: "mapList",
        name: "MapListComponent"
    },
    VEHICLE_PANEL: {
        source: "vehiclePanel",
        name: "VehiclePanelComponent"
    },
    TRIP_DAY_ITEM: {
        source: "tripDayItem",
        name: "TripDayItemComponent"
    }
};

