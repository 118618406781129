export const environment = {
    /**
     * production: (boolean) - Refer `main.ts` file.
     *
     *      true:
     *          - Disable Console outputs
     *      false:
     *          - Enable Console outputs
     */
    production: false,
    environmentIdentifier: 'STG',
    // endpoints and keys
    mtntApiEndpoint: "https://stg.mdrive.my/mtnt/v1",
    // mtntApiEndpoint: "http://localhost:10010/mtnt/v1", //leave it for easy local testing

    azureAD: {
        authority: "https://login.microsoftonline.com/5a5d28b7-2b31-4397-b7b5-a3328b8f8d18",
        clientId: "58d0ab86-38c9-4e38-a6db-d5fb2d35ad7c"
    },
    
    googleConfig: {
        web: {
            mapKey: "AIzaSyAnw5AA0pZE1lumpz8ORmL4udmVuQQUYNI",
        },
        mobile: {
            android: {
                mapKey: "AIzaSyAnw5AA0pZE1lumpz8ORmL4udmVuQQUYNI",
            },
            ios: {
                mapKey: "AIzaSyAnw5AA0pZE1lumpz8ORmL4udmVuQQUYNI",
            }
        },
        googleAnalyticsKey: "UA-144294884-1"
    },

    firebaseConfig: {
        apiKey: "AIzaSyA9eucyP6YpJRtXxfHnLmqgxtnXuSUiMoE",
        authDomain: "mdrive-production.firebaseapp.com",
        databaseURL: "https://mdrive-production.firebaseio.com",
        projectId: "mdrive-production",
        storageBucket: "mdrive-production.appspot.com",
        messagingSenderId: "313823934271",
        appId: "1:313823934271:web:ffb54f6cefa54d9c26458a"
    },

    // application configuration
    appConfig: {
        // error handling
        errorHandling: {
            sendEmail: true,
            sendEmailForLocalhostErrors: false,
            errorRecipientList: [
                'wingfaic@maxis.com.my',
                // 'zloo@maxis.com.my',
                // 'choonshyan.chong@uberfusion.com',
                'waibin.chia@uberfusion.com',
                'tkong@maxis.com.my'
            ],
            errorCcList: []
        },

        // auth: {
        //     tokenExpiryCheck: true
        // },

        // page
        page: {
            loginUrlPath: 'login',
            mapUrlPath: 'map',
            defaultHomePageUrl: 'map'
        },

        // create-manage module
        createManage: {
            pageRecordSize: 10,
            pageRecordSize_tiles: 9,
            pageLimitNo: 5,
            maxSizeMB: 0.02, // in Size MB
            maxWidthOrHeight: 200,  // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight
            maxSizeImageMB: 5, //Limit Upload Image File Size
            batchFileSize: 5 // in MB
        },

        // manage device
        manageDevice: {
            deviceOverview: {
                fileName: "STG-DEVICE_OVERVIEW",
                label: "Device Overview Report",
                layout: "landscape"
            }
        },

        // reporting related
        reporting: {
            pageRecordSize: 20,
            pageLimitNo: 5,
            tripSummary: {
                fileName: "STG-TRIP_SUMMARY_REPORT",
                label: "Trip Summary Report",
                layout: "landscape"
            },
            tripDetail: {
                fileName: "STG-TRIP_DETAIL_REPORT",
                label: "Trip Detail Report",
                layout: "portrait"
            },
            geofence: {
                fileName: "STG-GEOFENCE_REPORT",
                label: "Geofence Report",
                layout: "portrait"
            },
            violation: {
                fileName: "STG-VIOLATION_REPORT",
                label: "Violation Report",
                layout: "portrait"
            },
            utilization: {
                fileName: "STG-UTILIZATION_REPORT",
                label: "Utilization Report",
                layout: "portrait"
            },
            customUtilization: {
                fileName: "STG-CUSTOM-UTILIZATION_REPORT",
                label: "Customized Utilization Report",
                layout: "portrait"
            },
            driverAnalysisReport: {
                fileName: "DEV-DRIVER_ANALYSIS_REPORT",
                label: "Driver Analysis Report",
                layout: "portrait"
            },
            scoring: {
                weekly: { filename: "STG-WEEKLY_SCORING_REPORT", label: "Weekly Scoring Report" },
                lifetime: { filename: "STG-LIFETIME_SCORING_REPORT", label: "Life Time Scoring Report" },
                layout: "portrait"
            },
            maintenance: {
                past: { filename: "STG-MAINTENANCE_REPORT (Past)", label: "Past Maintenance Report" },
                upcoming: { filename: "STG-MAINTENANCE_REPORT (Upcoming)", label: "Upcoming Maintenance Report" },
                layout: "portrait"
            },
            fuelUsage: {
                fileName: "STG-FUEL-USAGE_REPORT",
                label: "Fuel Usage Report",
                layout: "portrait"
            },
            refuelLogs: {
                fileName: "STG-REFUEL-ENTRY_REPORT",
                label: "Refuel Entry Report",
                layout: "portrait"
            },
            vehicleStatus: {
                fileName: "STG-VEHICLE_STATUS_REPORT",
                label: "Vehicle Status Report",
                layout: "landscape"
            },
            vehicleActivity: {
                fileName: "STG-VEHICLE_ACTIVITY_REPORT",
                label: "Vehicle Activity Report",
                layout: "landscape"
            },
            speeding: {
                fileName: "STG-SPEEDING_REPORT",
                label: "Speeding Report",
                layout: "landscape"
            },
            detailedSpeeding: {
                fileName: "DEV-DETAILED_SPEEDING_REPORT",
                label: "Detailed Speeding Report",
                layout: "landscape"
            },
            idling: {
                fileName: "STG-IDLING_REPORT",
                label: "Idling Report",
                layout: "landscape"
            },
            vehicleRanking: {
                fileName: "STG-VEHICLE_RANKING_REPORT",
                label: "Vehicle Ranking Report",
                layout: "landscape"
            },
            alertHistory: {
                fileName: "STG-ALERT_HISTORY_REPORT",
                label: "Alert History Report",
                layout: "landscape"
            },
            driverRanking: {
                fileName: "STG-DRIVER_RANKING_REPORT",
                label: "Driver Ranking  Report",
                layout: "landscape"
            },
            geofenceMonitoring: {
                fileName: "STG-GEOFENCE_MONITORING_REPORT",
                label: "Geofence Monitoring Report",
                layout: "landscape"
            },
            vehicleTemperature: {
                fileName: "STG-VEHICLE_TEMPERATURE_REPORT",
                label: "Vehicle Temperature Report",
                layout: "landscape"
            },
            panicButton: {
                fileName: "STG-CUSTOM-PANIC_BUTTON_REPORT",
                label: "Panic Button Report",
                layout: "portrait"
            },
        },

        // google map related
        map: {
            //Refresh interval in second
            refreshInterval: 15
        },

        // dashboard related
        dashboard: {
            //Refresh interval in second
            refreshInterval: 600,
            pdfFileName: "Dashboard"
        },

        // driver performance
        performance: {
            //Refresh interval in second
            refreshInterval: 600,
            pdfFileName: "Driver Performance"
        },

        // user support
        support: {
            // userGuidePdfFileUrlPath: "https://s3-ap-southeast-1.amazonaws.com/mdrivetnt/user_guide/user-guide.pdf", //AWS guide path
            userGuidePdfFileUrlPath: "https://maxisupload.s3.ap-southeast-1.amazonaws.com/assets/user-guide.pdf", //Azure guide path
            userGuidePdfFileName: "mDrive User Guide 2022.pdf",
            supportHotline: "1800-82-1919",
            supportEmail: "bizcare@maxis.com.my"
        },

        // user push notification
        notification: {
            pageRecordSize: 10,
            triggerTotalRecentAlertInterval: 300 // in second
        },

        // classic mdrive
        classicMdrive: {
            url: "http://mdrive.modusgo.com"
        },

        // mobile app download links
        appDownload: {
            // format: "https://play.google.com/store/apps/details?id=***"
            android: "https://play.google.com/store/apps/details?id=my.com.maxis.mdrive",
            // format: "https://itunes.apple.com/us/app/***/id**********?mt=8"
            ios: "https://itunes.apple.com/us/app/mdrive-2-0/id1441512226?mt=8"
        }//,

        //surveySettings: {
        //    surveyUrl: "https://www.surveymonkey.com/r/HSJ5L6G",
        //    surveyDate: ['13/06/2019', '14/06/2019', '15/06/2019', '16/06/2019', '17/06/2019', '18/06/2019', '19/06/2019', '20/06/2019', '21/06/2019', '22/07/2019', '29/07/2019', '01/08/2019'],

        //}
    },

    systemInfo: {
        version: "2.2.9",
        copyrightYear: 2022
    }
};
