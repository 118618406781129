<div [class.trip-day-item-single-view-mode]="tripDayItemSingleViewMode">
    <button type="button" class="dayheader" [class.--active]="dateExpandToggle.active" [attr.id]="htmlId" (click)="dateExpandCollapse()">
        <div class="label">
            {{ _tripday.dateLabel }}
        </div>
        <div class="sublabels">
            <b>Idle</b>: {{ _tripday.gtIdleDurLabel }} |
            <span class="travelled">
                <b>Travel</b>: {{ _tripday.gtDistLabel }} </span> |
            <span class="alert">{{ tripday.gtVio }} Alerts</span>
        </div>
    </button>

    <div class="triplist" [class.--active]="dateExpandToggle.active">
        <button type="button" class="trip" (click)="getTripItemDetails(trip, i, $event)" *ngFor="let trip of tripday.inf; let i = index;" [class.--active]="currentTrip == i" [class.--isTrip]="trip.statusUpperedCase == 'END' || trip.statusUpperedCase =='ALERTS'" [attr.id]="trip.tripDet ? 'tripId_' + trip.tripDet.tId : ''">
            <!-- all status checking that is === 'INSTALLED' or === 'UNPLUGGED' required to remove as it only used in old staing environment dummy data-->
            <div class="item timeind" *ngIf="trip.statusUpperedCase === 'END' || trip.statusUpperedCase === 'ALERTS'">
                <div>
                    <span class="dot green"></span>
                    <span>{{ trip.tripGpsTimeStartLabel }}</span>
                </div>
                <div>
                    <span class="line"></span>
                </div>
                <div>
                    <span class="dot red"></span>
                    <span>{{ trip.tripGpsTimeEndLabel }}</span>
                </div>
            </div>

            <div class="item timeind" *ngIf="trip.statusUpperedCase === 'INSTALLED' || trip.statusUpperedCase === 'INSTALL_DEVICE' || trip.statusUpperedCase === 'UNPLUGGED' || trip.statusUpperedCase === 'UNPLUG_DEVICE'">
                <div>
                    <span class="dot" [ngClass]="trip.statusUpperedCase"></span>
                    <span>{{ trip.eventGpsTimeStartLabel }}</span>
                </div>
            </div>

            <div class="item tripinfo">
                <div class="tripinfo-toggle" [ngClass]="'--' + trip.statusUpperedCase" *ngIf="trip.statusUpperedCase === 'INSTALLED' || trip.statusUpperedCase === 'INSTALL_DEVICE' || trip.statusUpperedCase === 'UNPLUGGED' || trip.statusUpperedCase === 'UNPLUG_DEVICE' || trip.statusUpperedCase === 'END'">
                    <div class="tripinfo-toggle-inner">
                        <ng-container *ngIf="trip.statusUpperedCase !== 'END'">
                            {{ trip.statusLabel }}
                        </ng-container>

                        <ng-container *ngIf="trip.statusUpperedCase === 'END'">
                            <div class="mileage">{{ trip.tripTotalDistLabel }}

                                <span class="mileage__tag" *ngIf="trip.tripDet.note || trip.tripDet.tag !== undefined && trip.tripDet.tag.tagId != -1">
                                    <img src="assets/images/scss-components/key-value-icons/icon-route-tag.svg">
                                </span>
                            </div>
                            <div class="drivername">Driver: {{ trip.tripDet.assignedDriverName || '-' }}</div>
                            <div class="fuelusage" *ngIf="_hasFuelSensor == 1">Fuel Usage: {{ trip.tripDet.tFU }}</div>
                        </ng-container>
                    </div>
                </div>

                <div class="floater-anchor" *ngIf="trip.statusUpperedCase === 'ALERTS'">
                    <button [attr.id]="trip.tripDet ? 'tripinfo-floater-toggler-' + trip.tripDet.tId : 'tripinfo-floater-toggler'" type="button" class="tripinfo-toggle" [ngClass]="'--' + trip.statusUpperedCase" (click)="toggleAlertTagAndNote(i, 'Alert')">
                    </button>
                    <div class="tripinfo-toggle-inner">
                        <div class="mileage">
                            {{ trip.tripTotalDistLabel }}

                            <span class="mileage__tag" *ngIf="trip.tripDet.note || trip.tripDet.tag !== undefined && trip.tripDet.tag.tagId != -1">
                                <img src="assets/images/scss-components/key-value-icons/icon-route-tag.svg">
                            </span>
                        </div>
                        <div class="drivername">Driver: {{ trip.tripDet.assignedDriverName || '-' }}</div>
                        <div class="fuelusage" *ngIf="_hasFuelSensor == 1">Fuel Usage: {{ trip.tripDet.tFU }}</div>
                    </div>
                    <div [attr.id]="trip.tripDet ? 'tripinfo-floater-content-' + trip.tripDet.tId : 'tripinfo-floater-content'" class="tripinfo-floater   floater --position-bottom-out-left-in --reveal-from-top-left" [class.--active]="tripInfoToggle[i].active">
                        <!-- click-outside [click-outside-excludedHtmlIds]="['tripinfo-floater-toggler']" (clickOutside)="closeTripInfoFloater(trip, i, $event)" -->

                        <!--Close Button-->
                        <button type="button" class="floater__closer" (click)="tripInfoToggle[i].setInactive()"></button>

                        <div class="floater__header">
                            Driver Alert:
                        </div>
                        <div class="tripinfo-floater-item --EXCEED_TIME_OF_DAY" *ngIf="trip.tripDet && trip.tripDet.tExToD != undefined && trip.tripDet.tExToD > 0">
                            Exceed Time of Day: {{trip.tripDet.tExToD || 0}}
                        </div>
                        <div class="tripinfo-floater-item --GEOFENCE" *ngIf="(trip.tripDet && trip.tripDet.tGeoIn != undefined && trip.tripDet.tGeoIn > 0) ||
                            (trip.tripDet && trip.tripDet.tGeoOut != undefined && trip.tripDet.tGeoOut > 0)">
                            Geofence: {{trip.tripDet.tGeoIn + trip.tripDet.tGeoOut || 0}}
                        </div>
                        <div class="tripinfo-floater-item --HARSH_ACCELERATION" *ngIf="trip.tripDet && trip.tripDet.tHA != undefined && trip.tripDet.tHA > 0">
                            Harsh Acceleration: {{trip.tripDet.tHA || 0}}
                        </div>
                        <div class="tripinfo-floater-item --HARSH_BRAKE" *ngIf="trip.tripDet && trip.tripDet.tHB != undefined && trip.tripDet.tHB > 0">
                            Harsh Braking: {{trip.tripDet.tHB || 0}}
                        </div>
                        <div class="tripinfo-floater-item --HARSH_CORNERING" *ngIf="trip.tripDet && trip.tripDet.tHC != undefined && trip.tripDet.tHC > 0">
                            Harsh Turn: {{trip.tripDet.tHC || 0}}
                        </div>
                        <div class="tripinfo-floater-item --IS_IDLING" *ngIf="trip.tripDet && trip.tripDet.tIdle != undefined && trip.tripDet.tIdle > 0">
                            Idle: {{trip.tripDet.tIdle || 0}} ({{ trip.tripTotalIdlingDur }})
                        </div>
                        <div class="tripinfo-floater-item --EXCEED_MILEAGE" *ngIf="trip.tripDet && trip.tripDet.tExMil != undefined && trip.tripDet.tExMil > 0">
                            Mileage: {{trip.tripDet.tExMil || 0}}
                        </div>
                        <div class="tripinfo-floater-item --IS_SPEEDING" *ngIf="trip.tripDet && trip.tripDet.tSpd != undefined && trip.tripDet.tSpd > 0">
                            Speeding: {{trip.tripDet.tSpd || 0}}
                        </div>
                        <div class="tripinfo-floater-item --PANIC_BUTTON" *ngIf="trip.tripDet.tPanicTri != undefined && trip.tripDet.tPanicTri > 0">
                            Panic Triggered: {{trip.tripDet.tPanicTri || 0}}
                        </div>
                        <div class="tripinfo-floater-item --FUEL_DROP" *ngIf="trip.tripDet.tFuelDrp != undefined && trip.tripDet.tFuelDrp > 0">
                            Fuel Drop: {{trip.tripDet.tFuelDrp || 0}}
                        </div>
                        <div class="tripinfo-floater-item --DRIVER_TAG" *ngIf="trip.tripDet.tDTTri != undefined && trip.tripDet.tDTTri > 0">
                            Driver Tag Not Tied: {{trip.tripDet.tDTTri || 0}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="item expand-toggle" *ngIf="trip.statusUpperedCase !== 'INSTALLED' && trip.statusUpperedCase !== 'UNPLUGGED' && trip.statusUpperedCase !== 'INSTALL_DEVICE' && trip.statusUpperedCase !== 'UNPLUG_DEVICE'">
                <div class="expand-toggle__toggle" [attr.id]="'expand-floater-toggler'" [class.active]="i === expandUtilities" (click)="showUtilities(i)"></div>
            </div>

            <div class="item utilities" [class.active]="i === expandUtilities" *ngIf="trip.statusUpperedCase !== 'INSTALLED' && trip.statusUpperedCase !== 'UNPLUGGED' && trip.statusUpperedCase !== 'INSTALL_DEVICE' && trip.statusUpperedCase !== 'UNPLUG_DEVICE'">
                <div class="floater-anchor" *ngIf="trip.tripDet.driverTagNo">
                    <button type="button" class="tags-toggle  tags-triptag" [ngClass]="{'--select': true}">{{trip.tripDet.driverTagNo}}</button>
                </div>
                <div class="floater-anchor" *ngIf="trip.tripDet.driverTagDriverName">
                    <button type="button" class="tags-toggle  tags-triptag" [ngClass]="{'--select': true}">{{trip.tripDet.driverTagDriverName}}</button>
                </div>
                <div class="floater-anchor">
                    <button [attr.id]="trip.tripDet ? 'tags-floater-toggler-' + trip.tripDet.tId : 'tags-floater-toggler'" type="button" class="tags-toggle" [ngStyle]="{ 'background-color': trip.tripDet.tag?.colorCode }" (click)="toggleAlertTagAndNote(i, 'Tag')" [appOverrideCssVar]="{ name: '--dotColor', value: trip.tripDet.tag?.fontColor}" [class.--has-tags]="trip.tripDet.tag !== undefined && trip.tripDet.tag.tagId != -1 ">
                        <u class="tags-toggle-label" *ngIf="trip.tripDet.tag && trip.tripDet.tag.tagId == -1">Trip Tags</u>
                        <u class="tags-toggle-label" [ngStyle]="{ color: trip.tripDet.tag?.fontColor }" *ngIf="trip.tripDet.tag && trip.tripDet.tag.tagId != -1">
                            <span>{{ trip.tripDet.tag.tagName }}</span>
                        </u>
                    </button>

                    <div [attr.id]="trip.tripDet ? 'tags-floater-content-' + trip.tripDet.tId : 'tags-floater-content'" class="tags-floater   floater --position-bottom-out-right-in --reveal-from-top-right" [class.--active]="tagsToggle[i].active">
                        <!-- click-outside [click-outside-excludedHtmlIds]="['tags-floater-toggler']" (clickOutside)="closeTagsFloater(trip, i, $event)" -->

                        <!--Close Button-->
                        <button type="button" class="floater__closer" (click)="tagsToggle[i].setInactive()"></button>

                        <div class="tags-floater-header   floater__header">
                            Trip Tags:
                        </div>

                        <ng-container *ngFor="let tag of tagsList">
                            <ng-container *ngIf="trip.tripDet.tag !== undefined && trip.tripDet.tag.tagId !== undefined && trip.tripDet.tag?.tagId == tag?.tagId">
                                <button type="button" class="tags-floater-item" [appOverrideCssVar]="{ name: '--circleColor', value: trip.tripDet.tag?.colorCode }" [class.--active]="trip.tripDet.tag.tagId == tag.tagId" (click)="setTripTag(trip, tag.tagId); tagsToggle[i].setInactive()">
                                    {{ tag.tagName }}
                                </button>
                            </ng-container>
                            <ng-container *ngIf="trip.tripDet.tag == undefined || (trip.tripDet.tag.tagId !== undefined && trip.tripDet.tag?.tagId !== tag?.tagId)">
                                <button type="button" class="tags-floater-item" (click)="setTripTag(trip, tag.tagId); tagsToggle[i].setInactive()">
                                    {{ tag.tagName }}
                                </button>
                            </ng-container>
                        </ng-container>

                        <div class="tags-floater-footer">
                            <button type="button" class="tags-floater-remove" (click)="setTripTag(trip, -1); tagsToggle[i].setInactive()">
                                Remove
                            </button>
                        </div>
                    </div>
                </div>

                <div class="floater-anchor">
                    <button [attr.id]="trip.tripDet ? 'notes-floater-toggler-' + trip.tripDet.tId : 'notes-floater-toggler'" type="button" class="notes-toggle" [class.--has-notes]="trip.tripDet.note" (click)="toggleAlertTagAndNote(i, 'Note')">
                        <u>Note</u>
                    </button>

                    <div [attr.id]="trip.tripDet ? 'notes-floater-content-' + trip.tripDet.tId : 'notes-floater-content'" class="notes-floater   floater --position-bottom-out-right-in --reveal-from-top-right" [class.--active]="notesToggle[i].active">
                        <!-- click-outside [click-outside-excludedHtmlIds]="['notes-floater-toggler']" (clickOutside)="closeNotesFloater(trip, i, $event)" -->

                        <!--Close Button-->
                        <button type="button" class="floater__closer" (click)="notesToggle[i].setInactive();resetTripNote(trip)"></button>

                        <div class="floater__header">
                            Trip Notes
                        </div>

                        <textarea [(ngModel)]="trip.tripDet.note" class="floater__field" (keyup)="$event.preventDefault()" maxlength="45"></textarea>

                        <div class="notes-floater-footer">
                            <div class="inline-group">
                                <div class="inline-group__item">
                                    <button type="button" class="button" (click)="setTripNote(trip); notesToggle[i].setInactive()">
                                        Update
                                    </button>
                                </div>

                                <div class="inline-group__item">
                                    <button type="button" class="button" (click)="setTripNote(trip, true); notesToggle[i].setInactive()">
                                        <img src="assets/images/ng-components/past-trip/icon-trash.svg" alt="" class="button__icon">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </button>
    </div>
</div>
