<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="block --space-top">
                <h1 class="heading --level-1">
                    Edit Information
                </h1>
            </div>
        </div>
    </div>

    <form class="form-field" #vehiclesForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Personal Details
                    </h2>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-9of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            First Name:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="accountInfo.firstName" name="firstName" #name="ngModel">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Last Name:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="accountInfo.lastName" name="lastName" #name="ngModel">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Phone Number:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="accountInfo.telNo" name="telNo" #name="ngModel">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Email:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="accountInfo.email" name="email" #name="ngModel" (change)="onChangeEmail($event)">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>

                                            <div class="field-notes">
                                                (This email will be used as the login username)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" (click)="showPopup(confirmationPopup)">
                                SAVE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToHomePage()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #confirmationPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>
    <div class="popup__block">
        <p>
            You are going to update account information
        </p>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateAccountInfo(); hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
