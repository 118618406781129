import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CampaignServiceService {

    constructor(private http: HttpClient) { }

    async listCampaign(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/support/campaign${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getPlacementDropDownList() {
        const path = environment.mtntApiEndpoint + `/support/campaign/get-placement-dropdown`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async createCampaign(campaignObj) {
        const path = environment.mtntApiEndpoint + `/support/campaign`;
        const body = campaignObj;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async updateCampaign(campaignObj) {
        const path = environment.mtntApiEndpoint + `/support/campaign/${campaignObj.id}`;
        const body = campaignObj;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async getCampaignDetails(campaignId) {
        const path = environment.mtntApiEndpoint + `/support/campaign/${campaignId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async deleteCampaign(campaignIdList = []) {
        const body: any = { body: { campaignIdList: campaignIdList } };
        const path = environment.mtntApiEndpoint + `/support/campaign`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async getUnviewedCampaign(todayDate = moment().format("YYYY-MM-DD"), currentPageAccessId) {
        const paramObj = {
            todayDate: todayDate,
            currentPageAccessId: currentPageAccessId
        };

        const path = environment.mtntApiEndpoint + `/user/campaigns`;
        const result: any = await this.http.get(path, { params: paramObj }).toPromise();
        return result;
    }

    async insertViewedCampaigns(campaignIdList = []) {
        const body = {
            campaignIdList: campaignIdList
        };

        const path = environment.mtntApiEndpoint + `/user/campaigns`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }
}
