import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompanyRegistrationService {

    constructor(private http: HttpClient) { }

    async getSalesChannelTypeList() {
        const path = environment.mtntApiEndpoint + `/support/sales-channel-type`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getDeviceProviderList() {
        const path = environment.mtntApiEndpoint + `/support/device-provider`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getVehicleMakeList() {
        const path = environment.mtntApiEndpoint + `/support/vehicle-make`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getVehicleModelList(vehicleMakeId: number) {
        const path = environment.mtntApiEndpoint + `/support/vehicle-model/${vehicleMakeId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getCompanyList() {
        const path = environment.mtntApiEndpoint + `/support/getCompanies`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getCompanyByVehicleId(vehicleId) {
        const path = environment.mtntApiEndpoint + `/support/company/vehicle/${vehicleId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getVehiclesByVehicleIds(vehicleIds) {
        const path = environment.mtntApiEndpoint + `/support/vehicle/vehicle/${vehicleIds}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getDeviceForFillIn(vehicleId) {
        const path = environment.mtntApiEndpoint + `/support/companies/devices/${vehicleId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }
    
    async addDevice(reqObj) {
        const path = environment.mtntApiEndpoint + `/support/companies/devices`;
        const body = reqObj;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async updateDevice(reqObj) {
        const path = environment.mtntApiEndpoint + `/support/companies/devices`;
        const body = reqObj;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    async deleteDevice(reqObj) {
        const path = environment.mtntApiEndpoint + `/support/companies/devices`;
        const body: any = { body: reqObj };
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async rescheduleDevice(reqObj) {
        const path = environment.mtntApiEndpoint + `/support/companies/devices/reschedule`;
        const body = reqObj;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async requestNewImei(reqObj) {
        const path = environment.mtntApiEndpoint + `/support/companies/devices/imei/replace`;
        const body = reqObj;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async switchProvider(reqObj) {
        const path = environment.mtntApiEndpoint + `/support/companies/devices/switchProvider`;
        const body = reqObj;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }
}
