import { ChangeDetectorRef } from "@angular/core";
import { Chart } from 'chart.js';

// Setting "parentWidthForPdf" (fixed PDF width size + mobile-friendly)
// dashboard section width 1400 (.wrapper.\--full-width)
//      chart container: 325 (.dashboard-field__inner.--col-4)
//      chart area: 293 (.graph-chart__panel)
//      bigger chart container: 670 (.dashboard-field__inner)
//      bigger chart content: 638 (.graph-chart__panel-inner)
//      bigger chart area: 319 (.graph-chart__panel-inner-block)
export const ChartOptions = {
    TopFiveMileage: {
        canvasId: 'TopFiveMileage',
        parentWidthForPdf: '293px'
    },
    TopFiveSpeeding: {
        canvasId: 'TopFiveSpeeding',
        parentWidthForPdf: '293px'
    },
    TopFiveIdling: {
        canvasId: 'TopFiveIdling',
        parentWidthForPdf: '293px'
    },
    WeeklyFleetUtilisation: {
        canvasId: 'WeeklyFleetUtilisation',
        parentWidthForPdf: '293px'
    },
    HourlyFleetUtilisation: {
        canvasId: 'HourlyFleetUtilisation',
        parentWidthForPdf: '293px'
    },
    TopFiveFuelPerformance: {
        canvasId: 'TopFiveFuelPerformance',
        parentWidthForPdf: '293px'
    },
    MonthlyFuelPerformance: {
        canvasId: 'MonthlyFuelPerformance',
        parentWidthForPdf: '293px'
    },
    WeeklyViolationCount: {
        canvasId: 'WeeklyViolationCount',
        parentWidthForPdf: '319px'
    }
};
// below calculation is for old dashboard:
//      dashboard section width 1200
//      dashboard content width = 1200 - 40 = 1160
//      3-in-1 width = 1160/3 - 60 = 326
//      2-column width = 575 - 60 = 515
//      2-in-1 width = 579 - 60 = 519

export class DashboardCharts {
    changeRef: ChangeDetectorRef;
    instances: DashboardChartChildObj[];

    // Chart Declarations
    TopFiveMileage: DashboardChartChildObj;
    TopFiveSpeeding: DashboardChartChildObj;
    TopFiveIdling: DashboardChartChildObj;
    WeeklyFleetUtilisation: DashboardChartChildObj;
    HourlyFleetUtilisation: DashboardChartChildObj;
    TopFiveFuelPerformance: DashboardChartChildObj;
    MonthlyFuelPerformance: DashboardChartChildObj;
    WeeklyViolationCount: DashboardChartChildObj;

    constructor(ref: ChangeDetectorRef) {
        this.changeRef = ref;
        this.instances = [];
        for (const chartName in ChartOptions) {
            if (ChartOptions.hasOwnProperty(chartName)) {
                const instance: any = new DashboardChartChildObj(ref, ChartOptions[chartName]);
                this[chartName] = instance;
                this.instances.push(instance);
            }
        }
    }

    /**
     * Resize all charts programatically for PDF render purpose
     */
    resizeForPdfDownload() {
        this.instances.forEach(instance => {
            if (!instance.isEmpty && instance.chart) {
                instance.chart.canvas.parentElement.style.width = instance.parentWidthForPdf;
                instance.chart.resize();
            }
        });
        // if (!this.isIdlingTimeBarChartDataEmpty) {
        //     this.IdlingTimeBarChart.canvas.parentNode.style.width = '326px';
        // }
        // this.FleetUtilisationLineChart.canvas.parentNode.style.width = '515px';
        // if (!this.isFuelPerformanceLineChartDataEmpty) {
        //     this.fuelPerformanceLineChart.canvas.parentNode.style.width = '519px';
        // }
        // if (!this.isFuelTopFiveBarChartDataEmpty) {
        //     this.fuelTopFiveBarChart.canvas.parentNode.style.width = '519px';
        // }
    }

    /**
     * Restore default sizes of all charts programatically
     */
    restoreDefaultSizes() {
        this.instances.forEach(instance => {
            if (!instance.isEmpty && instance.chart) {
                instance.chart.canvas.parentElement.style.width = '';
                instance.chart.resize();
            }
        });
        // for (const chartName in this.dashCharts) {
        //     if (this.dashCharts.hasOwnProperty(chartName)) {
        //         this.dashCharts[chartName].chart.canvas.parentElement.style.width = '';
        //         this.dashCharts[chartName].chart.resize();
        //     }
        // }
        // this.dashCharts.TopFiveIdling.chart.canvas.parentElement.style.width = '';
        // this.dashCharts.FleetUtilisationWeekly.chart.canvas.parentElement.style.width = '';
        // this.dashCharts.FuelPerformanceMonthly.chart.canvas.parentElement.style.width = '';
        // this.dashCharts.TopFiveFuelUsage.chart.canvas.parentElement.style.width = '';
    }

}

export class DashboardChartChildObj {
    changeRef: ChangeDetectorRef;
    canvasId: string; // HTML canvas id
    parentWidthForPdf: string; // Width(px) of canvas parent DIV for html2canvas
    isEmpty: boolean; // Flag to show chart / placeholder smiley image
    chart: Chart; // Chart.js instance

    constructor(changeRef: ChangeDetectorRef, opts: any = null) {
        this.changeRef = changeRef;
        this.chart = null;
        this.isEmpty = true;
        if (opts) {
            this.canvasId = opts.canvasId || null;
            this.parentWidthForPdf = opts.parentWidthForPdf || null;
        }
    }

    /**
     * Destroy the chart if it is not null.
     */
    destroy() {
        if (this.chart !== null) {
            this.chart.destroy(); //invoke ChartJS API
            this.isEmpty = true;
        }
    }

    /**
     * Changes chart's `isEmpty` state and forces Angular detectChanges()
     */
    setIsEmpty(isEmpty: boolean) {
        this.isEmpty = isEmpty;

        // Forces Angular to render UI (show/hide canvas)
        // to avoid ViewChild to be undefined
        this.changeRef.detectChanges();
    }
}
