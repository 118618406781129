<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@sm --space-top">
                    <h1 class="heading --level-1">
                        Driver Tags
                    </h1>
                </div>

				<div class="grid__block w-8of12@md heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
							<a class="heading-buttons__button" (click)="showPopup(batchImportPopup)" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-batch-import.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Batch Import
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Batch Import
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-batch-import.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

							<a class="heading-buttons__button" (click)="showDownloadPopup(downloadsPopup)">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-export.svg" alt="" width="23" height="23" class="heading-buttons__icon">
                                    <div class="heading-buttons__text">
                                        Export As
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Export As
                                    </div>
                                    <img src="assets/images/ng-components/create-manage/icon-mobile-export.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="navigateToCreateDriverTag(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Driver Tag
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Driver Tag
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingDriverTags = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Driver Tag
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Driver Tag
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section">
        <div class="wrapper">
			<div class="driver__filters" style="position: relative; z-index: 3;">
                <div class="block">
					<div class="table table__driving-limit-general">
						<table class="table__elem" style="width: 100%; table-layout: fixed;">
							<thead>
								<tr>
                                    <ng-container *ngIf="isDeletingDriverTags">
                                        <th scope="col" style="width: 10%;">
                                            <!-- <label class="inline-input">
                                                <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivingLimitGroups['all']" (ngModelChange)="onDrivingLimitAllSelectChange()">
                                                <span class="inline-input__icon"></span>
                                            </label> -->
                                        </th>
                                    </ng-container>
									<th scope="col">
										<button type="button" class="table__sortable" [ngClass]="getSortingState('driverTagNo')" (click)="sort('driverTagNo')">Driver Tag No</button>
									</th>
									<th scope="col">
										<button type="button" class="table__sortable" [ngClass]="getSortingState('driverName')" (click)="sort('driverName')">Driver Name</button>
									</th>
								</tr>
							</thead>

							<tbody>
								<tr *ngFor="let driverTag of driverTagCurrentPageList">
                                    <ng-container *ngIf="isDeletingDriverTags">
                                        <td scope="col">
                                            <label class="inline-input" *ngIf="driverTag.driverName == null">
                                                <input type="checkbox" [name]="driverTag.driverTagId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDeleteDriverTags[driverTag.driverTagId]">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>
                                    </ng-container>
									<td>{{ driverTag.driverTagNo || '-' }}</td>
									<td>{{ driverTag.driverName || '-' }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>
                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination" *ngIf="driverTagList.length > 0">
                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDisplayDriverTagList(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDisplayDriverTagList(page)">{{
                                    page }}</button>

                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDisplayDriverTagList(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>
</div>
<div class="delete-dialog" @fadeToggle *ngIf="isDeletingDriverTags">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedDeleteDriverTags()}} driver tag(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteTagPopup)" [disabled]="countSelectedDeleteDriverTags() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteTagPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedDeleteDriverTags()}}</span> driver tag(s)

            <!-- some warning about driver tag in use -->
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteDriverTag();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #downloadsPopup="popup">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="exportDriverTagFile('excel'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="exportDriverTagFile('csv'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="exportDriverTagFile('pdf'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>

<app-popup #batchImportPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Batch Import
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="image-placeholder">
            <img src="assets/images/ng-components/create-manage/icon-batch-import-grey.svg" alt="" width="170" height="170">
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <label>
                <span class="button inline-button__inputs">
                    Browse
                </span>
                <input type="file" id="myFileInput" (change)="batchImportDriverTag($event)" accept="*.xlsx, *.xls, *.csv" />
            </label>
        </div>

        <span class="notes-input">( Max File Size: {{batchFileSize}}MB )</span>
    </div>

    <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">

            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div>
</app-popup>

<!-- file cannot read -->
<app-popup #batchAlertPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert">{{batchErrorMessage}}</span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(batchImportPopup);">
                OK
            </button>
        </div>
    </div>
    <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div>
</app-popup>

<app-popup #batchCreatePreview="popup" class="cm-popups">
    <div class="popup__block ta-l preview">
        <div class="popup__block-field">
            <h1 class="heading indicator-green">Bulk Import Preview</h1>
        </div>

        <div class="popup__block-field">
            <div class="grid --gutter-xspace">
                <div class="grid__block-xspace w-6of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Document uploaded
                        </div>

                        <div class="preview__result">
                            {{ fileName }}
                        </div>
                    </div>
                </div>

                <div class="grid__block-xspace w-3of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Entries verified
                        </div>

                        <div class="preview__result --text-big   indicator-green ">
                            {{ validDataList.length }}/{{ batchDriverTagCount }}
                        </div>
                    </div>
                </div>

                <div class="grid__block-xspace w-3of12@md">
                    <div class="grid__block-inner">
                        <div class="preview__title">
                            Entries needing attention
                        </div>

                        <div class="preview__result --text-big   indicator-red">
                            {{ invalidDataList.length}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup__block-field" *ngIf="!dataRecordsHasError">
            <div class="preview__notes --if-valid">
                <div class="preview__notes-icon">
                    <i class="gfx-icons --valid"></i>
                </div>

                <div class="preview__notes-desc">
                    <p class="preview__notes-desc-notification">
                        All data entries are <span class="fw-600">valid!</span>
                    </p>

                    <p>
                        Please click Submit to upload.
                    </p>
                </div>
            </div>
        </div>

        <div class="popup__block-field" *ngIf="dataRecordsHasError">
            <div class="preview__notes --if-invalid">
                <div class="preview__notes-icon">
                    <i class="gfx-icons --invalid"></i>
                </div>

                <div class="preview__notes-desc">
                    <p class="preview__notes-desc-notification">
                        Some data entries are <span class="fw-600">invalid!</span>
                    </p>

                    <p>
                        Please download and review the invalid entries. You may still click Submit to upload ONLY the valid data.
                    </p>

                    <p class="fs-i">
                        * Please download the invalid data before you proceed with submit.
                    </p>
                </div>

                <div class="preview__notes-download">
                    <button class="button --red --min-width" type="button" (click)="generateExcelTemplate(false);isDownloadDone = true">
                        Download
                    </button>
                </div>
            </div>
        </div>

        <div class="popup__block-field">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" [disabled]="!isDownloadDone && invalidDataList.length > 0" (click)="showPopup(batchCreateDriverTagPopup)">
                    Submit
                </button>

                <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</app-popup>


<app-popup #batchCreateDriverTagPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            Total {{validDataList.length}} Driver Tag(s) will be created.
        </p>

        <p class="notes"></p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="batchCreateDriverTag();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>