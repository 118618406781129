import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as StringUtil from './../../../../util/stringUtil';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

@Component({
    selector: 'app-vehicle-selector',
    templateUrl: './vehicle-selector.component.html',
    styleUrls: ['./vehicle-selector.component.scss']
})
export class VehicleSelectorComponent implements OnInit {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    @Input('title') title: string = "Assign Vehicles";
    @Input('adminId') adminId: number;
    @Input('selectedIds') selectedIds: Array<number>;
    @Input('withGroup') withGroup: boolean = true;
    @Input('inPopup') inPopup: boolean = false;
    @Input('excludeDeactivated') excludeDeactivated: boolean = true;

    sectionClass: string;
    wrapperClass: string;
    blockClass: string;

    //Assign Resource Variables
    vehicleService: VehicleService;
    vehicleResult: any = {};

    vehicleSortField: any = 'vehicleName';
    vehicleSortAscending: boolean = true;
    currVehicleSortField: any = 'vehicleName';
    currVehicleSortAscending: boolean = true;
    vehicleFilterValue: any = '';
    currVehicleFilterValue: any = '';

    vehicleUniqueList: Array<any> = [];
    vehicleIdUniqueList: Array<number> = [];
    vehicleIdUniqueCompleteList: Array<any> = [];
    unmodifiedSelectedVehicleIdList: Array<number> = [];
    selectedVehicleIdList: Array<number> = [];
    selectedVehicles: any = {};
    selectedVehicleGroups: any = {};

    @ViewChild('vehiclesField',{static:false}) vehiclesField;
    vehiclesModelCompleteVehiclesList: any = [];
    vehicleFilterSearchString: string = ""; //store filter search string
    isShowVehicleSelectAllOption: boolean = true;

    ready: boolean = false;

    expandGroupVehicle: any = [];

    constructor(private el: ElementRef,
        private snackBar: SnackBarService,
        private http: HttpClient) { }

    async ngOnInit() {
        this.sectionClass = (this.inPopup) ? '' : 'section --border-top';
        this.wrapperClass = (this.inPopup) ? '' : 'wrapper';
        this.blockClass = (this.inPopup) ? 'popup__block' : 'block';

        this.pageSpinner.show();
        try {

            //Get vehicle list
            this.vehicleService = new VehicleService(this.http);
            if (this.adminId) {
                this.vehicleResult = await this.vehicleService.getGroupVehicleModelByCompany(this.vehicleSortField, this.vehicleSortAscending, this.adminId, this.excludeDeactivated);
                this.getAssignedVehicles();
            } else {
                // userIdToUse = LocalStorageUtil.localStorageGet('currentUser').userId;
                this.vehicleResult = await this.vehicleService.getGroupVehicleModelByCompany(this.vehicleSortField, this.vehicleSortAscending, null, this.excludeDeactivated);
            }
            this.vehiclesModelCompleteVehiclesList = this.vehicleResult.vehicles.slice(0);
            this.getVehicleListWithoutGroup();
            this.sort(this.currVehicleSortField, true);
            this.ready = true;

            //Check vehicles
            if (this.selectedIds && this.selectedIds.length) {
                this.setSelectedVehicleIds(this.selectedIds);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    warnIfNotReady(): boolean {
        if (!this.ready) {
            console.warn('Trying to select/deselect vehicles before selector component is ready. Instead, you should pass vehicle ids through component tag attribute.');
            return true;
        }
        return false;
    }

    private getVehicleListWithoutGroup(): void {
        const list = this.vehiclesModelCompleteVehiclesList;
        const uniqueVehicleIds = [];
        const uniqueVehicleList = [];
        for (const group of list) {
            if (Boolean(group.idList) && group.idList.length) {
                const tempVehicleList = group.idList;
                for (const vehicle of tempVehicleList) {
                    if (uniqueVehicleIds.indexOf(vehicle.vehicleId) == -1) {
                        uniqueVehicleIds.push(vehicle.vehicleId);
                        uniqueVehicleList.push(vehicle);
                    }
                }
            }
        }
        this.vehicleUniqueList = uniqueVehicleList;
        this.vehicleIdUniqueList = uniqueVehicleIds;
        this.vehicleIdUniqueCompleteList = uniqueVehicleIds;
    }

    private updateVehicleListWithoutGroup(): void {
        const list = this.vehicleResult.vehicles;
        let uniqueVehicleIds = [];
        const uniqueVehicleList = [];
        for (const group of list) {
            if (Boolean(group.idList) && group.idList.length) {
                const tempVehicleList = group.idList;
                for (const vehicle of tempVehicleList) {
                    if (uniqueVehicleIds.indexOf(vehicle.vehicleId) == -1) {
                        uniqueVehicleIds.push(vehicle.vehicleId);
                        uniqueVehicleList.push(vehicle);
                    }
                }
            }
        }

        // Apply sorting
        let sortField = this.currVehicleSortField;
        if (StringUtil.equalsIgnoreCase(sortField, 'makeModel')) {
            sortField = 'vehicleMake';
        }
        this.sortList(uniqueVehicleList, sortField, this.currVehicleSortAscending);
        uniqueVehicleIds = [];
        uniqueVehicleList.forEach(item => {
            uniqueVehicleIds.push(item.vehicleId);
        });

        this.vehicleUniqueList = uniqueVehicleList;
        this.vehicleIdUniqueList = uniqueVehicleIds;
    }

    public hasModified(): boolean {
        return this.getAffectedCount().modified;
    }

    public getNewlySelectedVehicleIds() {
        return this.getAffectedCount().insert;
    }

    public getUnmodifiedVehicleIds() {
        return this.getAffectedCount().update;
    }

    public getRemovedVehicleIds() {
        return this.getAffectedCount().delete;
    }

    public getAffectedCount() {
        const result = {
            insert: [],
            update: [],
            delete: [],
            modified: false
        };
        const oldIds = this.unmodifiedSelectedVehicleIdList.slice(0);
        const newIds = this.selectedVehicleIdList.slice(0);
        if (!oldIds) {
            return result;
        }
        for (const oldId of oldIds) {
            const f = newIds.indexOf(oldId);
            if (f == -1) {
                result.delete.push(oldId);
            } else {
                result.update.push(oldId);
                newIds.splice(f, 1);
            }
        }
        result.insert = [].concat(newIds);
        if (result.insert.length || result.delete.length) {
            result.modified = true;
        }
        return result;
    }

    public getSelectedCount(): number {
        // console.debug(this.selectedVehicleIdList);
        // console.debug(this.selectedVehicles);
        // console.debug(this.vehicleIdUniqueList);
        // console.debug(this.vehicleUniqueList);
        return this.selectedVehicleIdList.length;
    }

    public getSelectedVisibleCount(): number {
        let count = 0;
        for (const vId of this.selectedVehicleIdList) {
            if (this.vehicleIdUniqueList.indexOf(vId) != -1) {
                count++;
            }
        }
        return count;
        // return this.selectedVehicleIdList.length;
    }

    getSelectedDisplayText(): string {

        const selectedCount = this.getSelectedCount();
        const selectedVisibleCount = this.getSelectedVisibleCount();
        const totalCount = this.getTotalCount();
        const totalVisibleCount = this.getTotalVisibleCount();
        // let selectedHiddenCount = selectedCount - selectedVisibleCount;
        // let totalHiddenCount = totalCount - totalVisibleCount;

        let text: string = '';
        // text += `${selectedCount} / ${totalCount} vehicle${selectedCount > 1 ? 's' : ''} selected `;
        // if (totalCount > totalVisibleCount && totalVisibleCount > 0) {
        //     text += `(${selectedVisibleCount} / ${totalVisibleCount} visible) `;
        // }
        text += `${selectedVisibleCount} / ${totalVisibleCount} vehicle${selectedVisibleCount > 1 ? 's' : ''} selected `;
        if (totalCount > totalVisibleCount && totalVisibleCount > 0) {
            text += `(${selectedCount} / ${totalCount} total selected)`;
        }
        return text;
    }

    public getTotalCount(): number {
        return this.vehicleIdUniqueCompleteList.length;
    }

    public getTotalVisibleCount(): number {
        return this.vehicleIdUniqueList.length;
    }

    public getSelectedVehicleIds(): Array<number> {
        return this.selectedVehicleIdList.slice(0); //clone copy
    }

    public selectVehicleIds(ids: Array<number>): void {
        ids = ids.filter(item => item != null);
        if (this.warnIfNotReady()) {
            return;
        }
        for (const id of ids) {
            if (this.vehicleIdUniqueCompleteList.indexOf(id) != -1) {
                this.selectedVehicles[id.toString()] = true;
            } else {
                delete this.selectedVehicles[id.toString()];
            }
        }
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
    }

    public setSelectedVehicleIds(ids: Array<number>): void {
        ids = ids.filter(item => item != null);
        if (this.warnIfNotReady()) {
            return;
        }
        this.selectedVehicles = {};
        for (const id of ids) {
            if (this.vehicleIdUniqueCompleteList.indexOf(id) != -1) {
                this.selectedVehicles[id.toString()] = true;
            } else {
                delete this.selectedVehicles[id.toString()];
            }
        }
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
        this.unmodifiedSelectedVehicleIdList = this.selectedVehicleIdList.slice(0);
    }

    private syncSelectedVehicleIdList(): void {
        const assignedVehicleId: Array<number> = [];
        for (const objKey in this.selectedVehicles) {
            if (this.selectedVehicles[objKey] === true) {
                assignedVehicleId.push(parseInt(objKey));
            }
        }
        this.selectedVehicleIdList = assignedVehicleId;
    }

    public selectAll() {
        if (this.warnIfNotReady()) {
            return;
        }
        this.selectedVehicleGroups.all = true;
        this.onVehiclesAllSelectChange();
    }

    public deselectAll() {
        if (this.warnIfNotReady()) {
            return;
        }
        this.selectedVehicleGroups.all = false;
        this.onVehiclesAllSelectChange();
    }

    onVehiclesAllSelectChange() {

        // Note: Apply to ALL vehicles that are VISIBLE+HIDDEN
        const allIsSelected: boolean = this.selectedVehicleGroups.all;
        if (!this.vehicleResult.vehicles) {
            return;
        }
        //update array
        if (allIsSelected) {
            // this.selectedVehicleIdList = this.vehicleIdUniqueCompleteList.slice(0);
            for (const vId of this.vehicleIdUniqueList) {
                if (this.selectedVehicleIdList.indexOf(vId) == -1) {
                    this.selectedVehicleIdList.push(vId);
                }
            }
        } else {
            // this.selectedVehicleIdList = [];
            const newSelectedList = [];
            for (const vId of this.selectedVehicleIdList) {
                if (this.vehicleIdUniqueList.indexOf(vId) == -1) {
                    newSelectedList.push(vId);
                }
            }
            this.selectedVehicleIdList = newSelectedList;
        }
        //update object
        for (let i = 0; i < this.vehicleResult.vehicles.length; i++) {
            const vehicleModel = this.vehicleResult.vehicles[i];

            //check group
            if (vehicleModel.type === 'GROUP') {
                this.selectedVehicleGroups[i] = allIsSelected;
            }

            //check all vehicles in group
            for (let j = 0; j < vehicleModel.idList.length; j++) {
                this.selectedVehicles[vehicleModel.idList[j].vehicleId.toString()] = allIsSelected;
            }
        }
    }

    onVehiclesGroupSelectChange(groupIndex: number) {

        // Note: Apply ONLY to vehicles that are VISIBLE
        const idList = this.vehicleResult.vehicles[groupIndex].idList;
        const groupIsSelected = this.selectedVehicleGroups[groupIndex];
        for (let i = 0; i < idList.length; i++) {
            this.selectedVehicles[idList[i].vehicleId.toString()] = groupIsSelected;
        }
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
    }

    onVehiclesSelectChange() {
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
    }

    private getAssignedVehicles() {
        if (!this.vehicleResult.vehicles) {
            return;
        }
        for (let i = 0; i < this.vehicleResult.vehicles.length; i++) {
            let allIsSelected = true;
            let groupIsSelected = true;
            const vehicleModel = this.vehicleResult.vehicles[i];
            for (let j = 0; j < vehicleModel.idList.length; j++) {
                const eachIdList = vehicleModel.idList[j];
                if (eachIdList.assignedVehicle === 1) {
                    this.selectedVehicles[eachIdList.vehicleId.toString()] = true;
                } else {
                    allIsSelected = false;
                    groupIsSelected = false;
                    this.selectedVehicles[eachIdList.vehicleId.toString()] = false;
                }
            }
            this.selectedVehicleGroups[i] = groupIsSelected;
            this.selectedVehicleGroups.all = allIsSelected;
        }
        this.fixVehiclesCheckBoxesStates();
    }

    filterVehiclesSelection(searchString: string = "") {

        this.vehicleFilterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.vehicleResult.vehicles = this.vehiclesModelCompleteVehiclesList;
            this.getVehicleSearchBox().value = "";

            //Update withoutGroup listing
            this.updateVehicleListWithoutGroup();
        } else {
            //clone deep copy
            const tempList = JSON.parse(JSON.stringify(this.vehiclesModelCompleteVehiclesList));
            const newList = [];

            //do searching
            for (const group of tempList) {
                if (Boolean(group.idList) && group.idList.length) {
                    let tempVehicleList = group.idList;
                    tempVehicleList = tempVehicleList.slice(0).filter(this.filterLogicVehicleName.bind(this));
                    group.idList = tempVehicleList;
                }
                if (Boolean(group.idList) && group.idList.length) {
                    newList.push(group);
                }
            }

            //Show/Hide Select all checkbox
            if (newList.length) {
                this.isShowVehicleSelectAllOption = true;
            } else {
                this.isShowVehicleSelectAllOption = false;
            }
            this.vehicleResult.vehicles = newList;

            //Update withoutGroup listing
            this.updateVehicleListWithoutGroup();
        }
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
    }

    private getVehicleSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.vehiclesField.nativeElement.querySelector("#vehicleSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }

    private filterLogicVehicleName(vehicleItem: any): boolean {
        if (this.vehicleFilterSearchString) {
            const normalisedVehicleName = vehicleItem.vehicleName.toLowerCase().replace(' ', '').trim();
            const normalisedFilterName = this.vehicleFilterSearchString.toLowerCase().replace(' ', '').trim();
            if (normalisedVehicleName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }
        return true;
    }

    private fixVehiclesCheckBoxesStates() {

        // Note: Apply ONLY to vehicles that are VISIBLE
        if (!this.vehicleResult.vehicles) {
            return;
        }
        let allIsSelected = true;
        for (let j = 0; j < this.vehicleResult.vehicles.length; j++) {
            const group = this.vehicleResult.vehicles[j];
            let groupIsSelected = true;
            for (let k = 0; k < group.idList.length; k++) {
                const vehicle = group.idList[k];
                if (!this.selectedVehicles[vehicle.vehicleId.toString()]) {
                    allIsSelected = false;
                    groupIsSelected = false;
                    break;
                }
            }
            this.selectedVehicleGroups[j] = groupIsSelected;
        }
        this.selectedVehicleGroups.all = allIsSelected;
        if (this.vehicleResult.vehicles.length == 0) {
            this.selectedVehicleGroups.all = false;
        }
        this.syncSelectedVehicleIdList();
    }

    async sort(sortField: string = "", skipStateChange: boolean = false) {
        sortField = sortField.trim();
        if (sortField == "") {
            return;
        }

        this.pageSpinner.show();
        try {
            if (!skipStateChange) {
                //toggle ascending/descending
                if (StringUtil.equalsIgnoreCase(sortField, this.currVehicleSortField)) {
                    this.currVehicleSortAscending = !this.currVehicleSortAscending;
                } else {
                    this.currVehicleSortAscending = true;
                }
                this.currVehicleSortField = StringUtil.headToLowerCase(sortField);
            }

            // server side sorting
            // this.vehicleResult = await this.vehicleService.getGroupVehicleModelByCompany(this.currVehicleSortField, this.currVehicleSortAscending);

            // client side sorting
            sortField = this.currVehicleSortField;
            if (StringUtil.equalsIgnoreCase(sortField, 'makeModel')) {
                sortField = 'vehicleMake';
            }
            const groups = this.vehicleResult.vehicles;
            if (groups && groups.length) {
                for (const group of groups) {
                    const vehicleList: Array<any> = group.idList;
                    this.sortList(vehicleList, sortField, this.currVehicleSortAscending);
                    // if (this.currVehicleSortAscending) {
                    //     vehicleList.sort(function (m1, m2) {
                    //         return StringUtil.sortAscending(m1, m2, sortField);
                    //     })
                    // } else {
                    //     vehicleList.sort(function (m1, m2) {
                    //         return StringUtil.sortDescending(m1, m2, sortField);
                    //     })
                    // }
                }
            }
            this.updateVehicleListWithoutGroup();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    getSortingState(sortField: string = ""): string {
        let sortingClass = "";
        sortField = sortField.trim();
        if (sortField == "") {
            return;
        } else if (StringUtil.equalsIgnoreCase(sortField, this.currVehicleSortField)) {
            sortingClass = this.currVehicleSortAscending ? '--ascending' : '--descending';
        }
        return sortingClass;
    }

    sortList(list: any[], sortField: string, ascending: boolean) {
        if (ascending) {
            list.sort(function (m1, m2) {
                return StringUtil.sortAscending(m1, m2, sortField);
            });
        } else {
            list.sort(function (m1, m2) {
                return StringUtil.sortDescending(m1, m2, sortField);
            });
        }
    }

}
