<div [ngClass]="sectionClass">
    <div [ngClass]="wrapperClass">
        <div [ngClass]="blockClass">
            <h2 class="heading indicator-green --level-2 --partial-underline">
                {{ title }}
            </h2>
        </div>

        <div [ngClass]="blockClass">
            <div class="grid --gutter">
                <div class="grid__block w-6of12@sm">
                    <div class="field-group --unstack@xs">
                        <div class="field-group__item search-group__item">
                            <div class="field" #driversField>
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <img src="assets/images/scss-components/forms/icon-steering-wheel.svg" width="26" height="20" alt="" class="field__icon-image">
                                        </div>
                                    </div>

                                    <label class="field__holder">
                                        <input id="driverSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Drivers" dom-states #driversSearchDOMStates="DOMStates" (keyup)="filterDriversSelection($event.target.value)" maxlength="200">
                                    </label>

                                    <div class="field__ui"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass]="blockClass">
            <div class="table__wrapper">
                <table class="table__elem">
                    <thead>
                        <tr>
                            <th scope="col" class="thead__col">
                                <label class="inline-input" [class.--transparent]="!isShowDriverSelectAllOption">
                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverGroups['all']" (ngModelChange)="onDriversAllSelectChange()">

                                    <span class="inline-input__icon"></span>
                                </label>
                            </th>

                            <th scope="col" class="thead__col">
                                <button type="button" class="table__sortable" [ngClass]="getSortingState('FirstName')" (click)="sort('FirstName')">Driver Name</button>
                            </th>

                            <th scope="col" class="thead__col">
                                <button type="button" class="table__sortable" [ngClass]="getSortingState('Email')" (click)="sort('Email')">Email</button>
                            </th>

                            <th scope="col" class="thead__col">
                                <button type="button" class="table__sortable" [ngClass]="getSortingState('TelNo')" (click)="sort('TelNo')">Phone Number</button>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <div *ngIf="driverResult.drivers && !driverResult.drivers.length" class="no-record">
                            <label>
                                No driver found
                            </label>
                        </div>
                        <ng-container *ngIf="!withGroup">
                            <tr *ngFor="let driver of driverUniqueList">
                                <td scope="col">
                                    <label class="inline-input">
                                        <input type="checkbox" [name]="driver.driverId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivers[driver.driverId]" (ngModelChange)="onDriversSelectChange()">

                                        <span class="inline-input__icon"></span>
                                    </label>
                                </td>

                                <td scope="col">
                                    {{driver.driverName}}
                                </td>

                                <td scope="col">
                                    {{driver.email}}
                                </td>

                                <td scope="col">
                                    {{driver.telNo}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="withGroup">
                            <ng-container *ngFor="let driverGroup of driverResult.drivers; let groupIndex = index;">
                                <ng-container *ngIf="driverGroup.type === 'GROUP'">
                                    <tr class="table-row-group">
                                        <td scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" [name]="driverGroup.name" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverGroups[groupIndex]" (ngModelChange)="onDriversGroupSelectChange(groupIndex);">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>

                                        <td scope="col">
                                            {{driverGroup.name}}
                                        </td>

                                        <td scope="col">

                                        </td>

                                        <td scope="col">

                                        </td>

                                        <button class="table-group-expand" (click)="expandGroupDriver[groupIndex] = !expandGroupDriver[groupIndex]">

                                            <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupDriver[groupIndex]">

                                        </button>
                                    </tr>

                                    <ng-container *ngIf="expandGroupDriver[groupIndex]">
                                        <tr class="tr__elem-sub" *ngFor="let driver of driverGroup.idList">
                                            <td scope="col">

                                            </td>

                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="driver.driverId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivers[driver.driverId]" (ngModelChange)="onDriversSelectChange()">

                                                    <span class="inline-input__icon"></span>

                                                    <span class="inline-input__text">{{driver.driverName}}</span>
                                                </label>
                                            </td>

                                            <td scope="col">
                                                {{driver.email}}
                                            </td>

                                            <td scope="col">
                                                {{driver.telNo}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="driverGroup.type === 'INDIVIDUAL'">
                                    <tr *ngFor="let driver of driverGroup.idList">
                                        <td scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" [name]="driver.driverId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivers[driver.driverId]" (ngModelChange)="onDriversSelectChange()">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>

                                        <td scope="col">
                                            {{driver.driverName}}
                                        </td>

                                        <td scope="col">
                                            {{driver.email}}
                                        </td>

                                        <td scope="col">
                                            {{driver.telNo}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <app-spinner #page_spinner [disableScroll]="true"></app-spinner>
                    </tbody>
                </table>
            </div>
        </div>

        <div [ngClass]="blockClass">
            <div class="grid --gutter">
                <div class="grid__block   w-4of12@sm   as-c@sm">
                    <div class="notes">
                        {{getSelectedDisplayText()}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
