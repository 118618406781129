import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

import * as StringUtil from '../../../../util/stringUtil';
import { PopupService } from '../../../common/popup/popup.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { TripsService } from '../../../../_services/trips/trips.service';
import { DriverService } from '../../../../_services/driver/driver.service';
import { VehicleService } from '../../../../_services/vehicle/vehicle.service';
import { SpinnerComponent } from '../../../common/spinner/spinner.component';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';

@Component({
    templateUrl: './assign-driver.component.html',
    styleUrls: ['./assign-driver.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AssignDriverComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    message = Message;
    vehicleId;
    vehicleDetails: any = {};

    driverDetailsList: any = [];
    selectedDriverDetails: any = {};
    isShowSelectMessage: boolean = true;
    isSaveButtonOff: boolean = true;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    hasDriverTagDevice: boolean;

    ibuttonDriverList: any = [];
    driverDropdownList: any = [];
    driverSelectedLabel: string = "Select Driver(s)";
    selectedDrivers: any = {};
    selectedDriversList: any = [];
    selectedDriverGroups: any = {};
    subjectSelectionFloaterIsShown: boolean = false;
    filterSearchString: string = '';

    constructor(
        private route: ActivatedRoute,
        private popupService: PopupService,
        private vehicleService: VehicleService,
        private driverService: DriverService,
        private snackBar: SnackBarService,
        private tripService: TripsService,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        this.vehicleId = this.route.snapshot.params.id;
        await this.onInitCommonFunction();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async onInitCommonFunction() {
        this.pageSpinner.show();
        try {
            await this.getVehicleDetails();
            if(this.hasDriverTagDevice) {
                await this.getDriversAssignedToVehicle();
            } else {
                await this.getDriverDetails();
            }            
            await this.getVehicleLiveStatusDetails();
            await this.getDriverStatus();
            await this.onSelectedDriver(this.vehicleDetails.userId);
            this.isSaveButtonOff = true; // Default Off Save Button
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async getVehicleDetails() {
        const res = await this.vehicleService.getVehicleInfoDetailsByVid(this.vehicleId);
        this.vehicleDetails = res.body.vehicleDetails;
        this.hasDriverTagDevice = this.vehicleDetails.hasDriverTagDevice;;

        // Set Vehicle Make Model
        this.vehicleDetails.vehicleMakeModel = this.setVehicleMakeModel(this.vehicleDetails.vehicleMakeName, this.vehicleDetails.vehicleModelName, this.vehicleDetails.vehicleMakeOthers, this.vehicleDetails.vehicleModelOthers);

        // Parse some values to reduce double binding
        this.vehicleDetails.vehicleShortName = StringUtil.getShortName(this.vehicleDetails.vehicleName);
    }

    async getDriverDetails() {
        const res = await this.driverService.getDrivers(); // Get All Drivers
        this.driverDetailsList = res.body.result;
    }

    async getDriversAssignedToVehicle() {
        const res = await this.driverService.getDriversAndAssignmentToVehicle(this.vehicleDetails.vehicleId, true);
        this.ibuttonDriverList = res.body.result;
        await this.initializeDropdown();
    }

    async getVehicleLiveStatusDetails() {
        const res: any = await this.tripService.getLiveSummaryTrips([this.vehicleDetails.imeiNo]);
        if (res) {
            const vehicleLiveStatusDetails = res.body.live_summary_trip.length ? res.body.live_summary_trip[0] : null;
            // Set Vehicle Status
            if (vehicleLiveStatusDetails) {
                this.vehicleDetails.status = vehicleLiveStatusDetails.status;
                this.vehicleDetails.statusColourIndicator = this.vehicleDetails.status.toUpperCase() === 'MOVING' ? 'green' : 'red';
            } else {
                this.vehicleDetails.status = null;
                this.vehicleDetails.statusColourIndicator = 'red';
            }
        }
    }

    async onSelectedDriver(driverId) {
        if (driverId) {
            this.isSaveButtonOff = false;
            this.isShowSelectMessage = false;
            const tempSelectedDriverDetails = this.driverDetailsList.filter(result => result.Id === parseInt(driverId)); // Get Selected Driver Details

            if (tempSelectedDriverDetails.length > 0) {
                this.selectedDriverDetails = tempSelectedDriverDetails[0];
                this.selectedDriverDetails.VehicleMakeModel = this.setVehicleMakeModel(this.selectedDriverDetails.VehicleMakeName, this.selectedDriverDetails.VehicleModelName, this.selectedDriverDetails.VehicleMakeOthers, this.selectedDriverDetails.VehicleModelOthers);
            } else {
                // For Non Visible Driver
                const statusInfo = this.checkDriverStatus(this.vehicleDetails.status);
                this.selectedDriverDetails.NonVisible = true;
                this.selectedDriverDetails.Username = this.vehicleDetails.userName;
                this.selectedDriverDetails.Photo = this.vehicleDetails.userImage;
                this.selectedDriverDetails.VehicleName = this.vehicleDetails.vehicleName;
                this.selectedDriverDetails.Status = statusInfo.status;
                this.selectedDriverDetails.StatusColourIndicator = statusInfo.statusColourIndicator;
                this.selectedDriverDetails.VehicleMakeModel = this.setVehicleMakeModel(this.vehicleDetails.vehicleMakeName, this.vehicleDetails.vehicleModelName, this.vehicleDetails.vehicleMakeOthers, this.vehicleDetails.vehicleModelOthers);
            }
            // console.debug(this.selectedDriverDetails)

            // Parse some values to reduce data binding
            this.selectedDriverDetails.userShortName = StringUtil.getShortName(this.selectedDriverDetails.Username);

        }
    }

    async getDriverStatus() {
        const allDriverImeiNoList = this.driverDetailsList.filter(result => result.VehicleImeiNo !== null).map(result => result.VehicleImeiNo);

        const res: any = await this.tripService.getLiveSummaryTrips(allDriverImeiNoList);
        const vehicleLiveTrip = res.body.live_summary_trip;

        for (let i = 0; i < this.driverDetailsList.length; i++) {
            this.driverDetailsList[i].Status = 'N/A';
            this.driverDetailsList[i].StatusColourIndicator = 'black';
            for (let j = 0; j < vehicleLiveTrip.length; j++) {
                if (this.driverDetailsList[i].VehicleId === vehicleLiveTrip[j].vehicleId) {
                    const statusInfo = await this.checkDriverStatus(vehicleLiveTrip[j].status);
                    this.driverDetailsList[i].Status = statusInfo.status;
                    this.driverDetailsList[i].StatusColourIndicator = statusInfo.statusColourIndicator;
                }
            }
        }
    }

    checkDriverStatus(vehicleStatus) {
        if (vehicleStatus !== null) {
            const status = vehicleStatus.toUpperCase() === 'MOVING' ? 'DRIVING' : 'IDLE';
            const statusColourIndicator = status === 'DRIVING' ? 'green' : 'red';
            return { status: status, statusColourIndicator: statusColourIndicator };
        } else {
            return { status: 'N/A', statusColourIndicator: 'black' };
        }
    }

    setVehicleMakeModel(vehicleMake, vehicleModel, vehicleMakeOthers, vehicleModelOthers) {
        let vehicleMakeModel = 'N/A';
        const newVehicleMake = vehicleMake || vehicleMakeOthers;
        const newVehicleModel = vehicleModel || vehicleModelOthers;
        if (newVehicleMake !== null && newVehicleModel !== null) {
            vehicleMakeModel = `${newVehicleMake} ${newVehicleModel}`;
        }
        return vehicleMakeModel;
    }

    async assignVehicleToDriver() {
        this.pageSpinner.show();
        try {
            let result: any;
            if (!this.hasDriverTagDevice) {
                result = await this.vehicleService.updateAssignVehicleToDriver(this.vehicleDetails.vehicleId, this.selectedDriverDetails.Id, this.vehicleDetails.vehiclePlateNo, this.vehicleDetails.imeiId, this.vehicleDetails.imeiNo);
            } else {
                result = await this.vehicleService.updateAssignVehicleToDriverIbutton(this.vehicleDetails.vehicleId, this.selectedDriversList);
            }
            //Show Snackbar
            let msg = this.message.getMessage(this.message.MESSAGE.ASSIGN_DRIVER_FAILED.value, this.selectedDriverDetails.Username, this.vehicleDetails.vehicleName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = this.message.getMessage(this.message.MESSAGE.ASSIGN_DRIVER_SUCCESS.value, this.selectedDriverDetails.Username, this.vehicleDetails.vehicleName);
                this.navigateSuccessToVehicleView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToVehicleView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/vehicles/view'], null, 'assign');
    }

    navigateToVehicleView() {
        this.routerProxyService.navigate(['/create-manage/vehicles/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }


    /* ---- Driver Selector ---- */
    async initializeDropdown() {
        for(let x = 0; x < this.ibuttonDriverList.length; x++) {
            if(this.ibuttonDriverList[x].Status === 'ACTIVE' || this.ibuttonDriverList[x].Status === 'VALID') {
                this.selectedDrivers[this.ibuttonDriverList[x].Id] = true;
            }
        }
    }
    filterDriverSelection(searchString: string = "") {
        // this.filterSearchString = searchString;
        // if (searchString.trim().length == 0) {
        //     // this.driverModel.result = this.driverList;
        // } else {
        //     // this.driverModel.result = this.driverList.filter(result => result.driverName.toLowerCase().trim().indexOf(searchString) > -1);
        // }
    }
    toggleSubjectSelectionFloater(bool: boolean) {
        this.subjectSelectionFloaterIsShown = bool;
    }
    onOutsideClickDriverReaction(clickEvent: Event): void {
        this.toggleSubjectSelectionFloater(false);
        // this.filterDriverSelection();
    }

    onDriversSelectChange() {
        this.isSaveButtonOff = true;
        for(const key in this.selectedDrivers) {
            if (this.selectedDrivers[key] === true) {
                this.isSaveButtonOff = false;
                break;
            }
        }
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
        this.updateDriversSelectedLabel();
    }
    updateDriversSelectedLabel() {
        this.selectedDriversList = [];

        for (const key in this.selectedDrivers) {
            if (this.selectedDrivers[key] === true) {
                this.selectedDriversList.push(parseInt(key));
            }
        }

        const totalDrivers = this.selectedDriversList.length;

        if (totalDrivers === 0) {
            this.driverSelectedLabel = 'Select Driver(s)';
        } else if (totalDrivers === 1) {
            this.driverSelectedLabel = '1 driver selected';
        } else if (totalDrivers > 1) {
            this.driverSelectedLabel = totalDrivers + ' drivers selected';   
        }
    }
    onDriversAllSelectChange() {
        const allIsSelected: boolean = this.selectedDriverGroups.all;

        if(!this.ibuttonDriverList) {
            return;
        }

        if(allIsSelected) {
            this.selectedDrivers = {};
            for(let x = 0; x < this.ibuttonDriverList.length; x++) {
                this.selectedDrivers[this.ibuttonDriverList[x].Id] = true;
            }
        } else {
            this.selectedDrivers = {};
        }
    }
    fixDriversCheckBoxesStates() {
        let status = true;
        const selectedId = [];

        for(const key in this.selectedDrivers) {
            if(this.selectedDrivers[key] !== true ) {
                status = false;
                break;
            }
            selectedId.push(key);
        }

        if(selectedId.length != this.ibuttonDriverList.length) {
            status= false;
        }

        if(status) {
            this.selectedDriverGroups['all'] = true;
        } else {
            this.selectedDriverGroups['all'] = false;
        }
    }
}