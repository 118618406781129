<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div id="dashboard">
        <div class="section">
            <div class="wrapper --full-width">
                <!-- Title, Dropdown & Download -->
                <div class="block">
                    <div class="dashboard-box__inner grid">
                        <div class="grid__block w-5of12">
                            <div class="block-heading__col">
                                <h1 class="heading">
                                    Dashboard
                                </h1>
                            </div>

                            <div class="block-heading__col">
                                <div class="date">
                                    {{ todayDateLabel }}
                                </div>
                            </div>
                        </div>

                        <div class="grid__block w-7of12">
                            <div class="select-download__container">
                                <div class="select-download__select">
                                    <label class="select --grey">
                                        <select class="select__input" [(ngModel)]="selectedGroupId" (change)="getDashboardInfo()">
                                            <option value="-1" selected>All</option>
                                            <option *ngFor="let group of groupList" value="{{group.id}}">{{ group.name }}</option>
                                        </select>
                                    </label>
                                </div>

                                <div *ngIf="!isDownloadPdfClick" class="select-download__download">
                                    <button class="button download-button" (click)="downloadPdf()" [disabled]="refreshOngoing">
                                        <img src="assets/images/scss-components/key-value-icons/icon-download.svg" alt="Download" class="download-button__icon">

                                        <div class="download-button__desc">
                                            Download
                                        </div>
                                    </button>
                                </div>

                                <div *ngIf="!isDownloadPdfClick" class="select-download__download">
                                    <button #btnRefresh class="button refresh-button" (click)="getDashboardInfo()">
                                        <img style="width:18px;" src="assets/images/scss-components/key-value-icons/icon-refresh.svg" alt="Refresh">
                                        <!--
                                        <div class="download-button__desc">
                                            Refresh
                                        </div>
                                        -->
                                    </button>
                                </div>
                                <!--
                                <div *ngIf="!isDownloadPdfClick" class="select-download__download">
                                    <button class="button refresh-button" (click)="swapSvgPng()" style="background-color: red">
                                        <img style="width:18px;" src="assets/images/scss-components/key-value-icons/icon-refresh.svg" alt="Refresh">
                                    </button>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Dashboard Contents -->
                <div class="block">
                    <!-- Sub block for top row cards  -->
                    <div>
                        <app-spinner #liveStats_spinner [keepParentSize]="true"></app-spinner>
                        <!-- Live Trip Status -->
                        <div class="dashboard-box__highlight-container">
                            <div class="dashboard-box__highlight-inner">
                                <button class="dashboard-box__highlight-button" (click)="showPopup(movingPopup)">
                                    <div style="background-image: url('src/assets/images/ng-components/dashboard/icon-moving.svg')" class="dashboard-box__icon --icon-moving"></div>

                                    <div class="dashboard-box__desc">
                                        Moving
                                    </div>

                                    <div class="dashboard-box__number --alert-red">
                                        {{ movingCount }}
                                    </div>
                                </button>
                            </div>

                            <!-- <div class="dashboard-box__highlight-inner">
                            <button class="dashboard-box__highlight-button" (click)="showPopup(idlingPopup)">
                                <div class="dashboard-box__count">
                                    <div style="background-image: url('src/assets/images/ng-components/dashboard/icon-idle.svg')" class="dashboard-box__icon --icon-idle"></div>

                                    <div class="dashboard-box__number --alert-red">
                                        {{ idlingCount }}
                                    </div>
                                </div>

                                <div class="dashboard-box__desc">
                                    Idling
                                </div>
                            </button>
                        </div> -->

                            <div class="dashboard-box__highlight-inner">
                                <button class="dashboard-box__highlight-button" (click)="showPopup(stoppedPopup)">
                                    <div style="background-image: url('src/assets/images/ng-components/dashboard/icon-stopped.svg')" class="dashboard-box__icon --icon-stopped"></div>

                                    <div class="dashboard-box__desc">
                                        Stopped
                                    </div>

                                    <div class="dashboard-box__number --alert-red">
                                        {{ stoppedCount }}
                                    </div>
                                </button>
                            </div>

                            <div class="dashboard-box__highlight-inner">
                                <button class="dashboard-box__highlight-button" (click)="showPopup(disconnectedPopup)">
                                    <div style="background-image: url('src/assets/images/ng-components/dashboard/icon-disconnected.svg')" class="dashboard-box__icon --icon-disconnected"></div>

                                    <div class="dashboard-box__desc">
                                        Disconnected
                                    </div>

                                    <div class="dashboard-box__number --alert-red">
                                        {{ disconnectedCount }}
                                    </div>
                                </button>
                            </div>

                            <!-- Vehicle Health -->
                            <div class="dashboard-box__highlight-inner">
                                <button class="dashboard-box__highlight-button" (click)="showPopup(healthPopup)">
                                    <div style="background-image: url('src/assets/images/ng-components/dashboard/icon-vehicle-health.svg')" class="dashboard-box__icon --icon-vehicle-health"></div>

                                    <div class="dashboard-box__desc">
                                        Vehicle Health Issues
                                    </div>

                                    <div class="dashboard-box__number --alert-red">
                                        {{ healthAlertsCounts }}
                                    </div>
                                </button>
                            </div>

                            <!-- Maintenance -->
                            <div class="dashboard-box__highlight-inner" *ngIf="isMaintenanceViewable">
                                <button class="dashboard-box__highlight-button" (click)="showPopup(maintenancePopup)">
                                    <div style="background-image: url('src/assets/images/ng-components/dashboard/icon-maintenance.svg')" class="dashboard-box__icon --icon-maintenance"></div>

                                    <div class="dashboard-box__desc">
                                        Maintenance Due Soon
                                    </div>

                                    <div class="dashboard-box__number --alert-red">
                                        {{alertCountOfUpcomingMaintenance}}
                                    </div>
                                </button>
                            </div>

                            <!-- License -->
                            <div class="dashboard-box__highlight-inner">
                                <button class="dashboard-box__highlight-button" (click)="showPopup(expiredPopup)">
                                    <div style="background-image: url('src/assets/images/ng-components/dashboard/icon-carlicense.svg')" class="dashboard-box__icon --icon-carlicense"></div>

                                    <div class="dashboard-box__desc">
                                        General Expiry
                                    </div>

                                    <div class="dashboard-box__number --alert-red">
                                        {{alertCountOfExpiringLicense}}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Sub block for charts  -->
                    <div class="dashboard-field">
                        <!-- Advisory -->
                        <div class="dashboard-field__inner --col-4">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Recommendation
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #recommendation_spinner></app-spinner>
                                    <div *ngIf="recommendations.length" class="recommendation-notification">
                                        <div class="recommendation-notification__list" [class.--active]="recommendation.display" *ngFor="let recommendation of recommendations; let i = index;" (click)="selectRecommendation(i, recommendation.id)">

                                            <div style="text-align: right;padding-bottom: 5px;">{{recommendation.dateCreated}}</div>

                                            <div class="recommendation-notification__list-header">
                                                <div class="recommendation-notification__list-icon">
                                                    <img src="assets/images/ng-components/dashboard/icon-recommendation-list.svg">
                                                </div>

                                                <div class="recommendation-notification__list-title" *ngIf="recommendation.advisoryTypeId == 1 && recommendation.type == 'ADVISORY'">
                                                    <b *ngIf="isViolationReportViewable"><a class="link --primary --u" (click)="navigateToViolationReportByVehicleId(recommendation.vehicleIds, 'SpeedingDistance', true);">{{recommendation.name}}</a></b><b *ngIf="!isViolationReportViewable">{{recommendation.name}}</b> have many speeding events over the past week
                                                </div>

                                                <div class="recommendation-notification__list-title" *ngIf="recommendation.advisoryTypeId == 2 && recommendation.type == 'ADVISORY'">
                                                    Excessive idling detected on <b *ngIf="isUtilisationReportViewable"><a class="link --primary --u" (click)="navigateToUtilizationReportByVehicleId(recommendation.vehicleIds, 'TotalIdlingTime', true);">{{recommendation.name}}</a></b><b *ngIf="!isUtilisationReportViewable">{{recommendation.name}}</b>, resulting in {{recommendation.fuelWastage}} litres of fuel wastage.
                                                </div>

                                                <div class="recommendation-notification__list-title" *ngIf="recommendation.advisoryTypeId == 3 && recommendation.type == 'ADVISORY'">
                                                    <b *ngIf="isViolationReportViewable"><a class="link --primary --u" (click)="navigateToViolationReportByVehicleId(recommendation.vehicleIds, 'VehicleName', true);">{{recommendation.name}}</a></b><b *ngIf="!isViolationReportViewable">{{recommendation.name}}</b> are being driven dangerously
                                                </div>

                                                <div class="recommendation-notification__list-title" *ngIf="recommendation.advisoryTypeId == 4 && recommendation.type == 'ADVISORY'">
                                                    <b *ngIf="isUtilisationReportViewable"><a class="link --primary --u" (click)="navigateToUtilizationReportByVehicleId(recommendation.vehicleIds, 'TotalDrivingTime', true);">{{recommendation.name}}</a></b><b *ngIf="!isUtilisationReportViewable">{{recommendation.name}}</b> are not driven much this week
                                                </div>

                                                <div class="recommendation-notification__list-title" *ngIf="recommendation.advisoryTypeId == 5 && recommendation.type == 'ADVISORY'">
                                                    Fuel efficiency for <b *ngIf="isFuelReportViewable"><a class="link --primary --u" (click)="navigateToFuelReportByVehicleId(recommendation.vehicleIds, 'FuelEfficiency', true);">{{recommendation.name}}</a></b><b *ngIf="!isFuelReportViewable">{{recommendation.name}}</b> are significantly higher than the average
                                                </div>

                                                <div class="recommendation-notification__list-title" *ngIf="recommendation.type == 'TREND'">
                                                    {{recommendation.message}}
                                                </div>

                                                <div class="recommendation-notification__list-arrow">
                                                    <img src="assets/images/ng-components/dashboard/icon-arrow.svg">
                                                </div>
                                            </div>

                                            <div class="recommendation-notification__list-expand" *ngIf="recommendation.display === true">
                                                <ul>
                                                    <li *ngFor="let message of recommendation.variation;">
                                                        {{message.msg}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- --- Enable this for idle --- -->

                                    <div *ngIf="!recommendations.length" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-good.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            All Good!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Top 5 Mileage -->
                        <div class="dashboard-field__inner --col-4">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Top 5 Mileage
                                    </div>

                                    <div class="dashboard-field__headings-button">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="mileageSearchType" (change)="getTopMileageStats()">
                                                <option value="vehicle">Vehicles</option>
                                                <option value="driver" *ngIf="selectedGroupId < 0">Drivers</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #topMileage_spinner></app-spinner>

                                    <div *ngIf="!dashCharts.TopFiveMileage.isEmpty" class="graph-chart">
                                        <div class="graph-chart__panel" style="height: 250px;">
                                            <!-- Insert chart here -->
                                            <div style="height: 250px">
                                                <canvas id="TopFiveMileage"></canvas>
                                            </div>
                                        </div>

                                        <div class="graph-chart__panel" [ngClass]="weekMileageCssClass">
                                            <div class="graph-chart__panel-title">
                                                Mileage (km)
                                            </div>

                                            <div class="graph-chart__panel-count">
                                                <div class="graph-chart__panel-icon">
                                                    <span class="helper"></span>
                                                    <img style="height:28px" src="assets/images/ng-components/dashboard/icon-driving-distance-new.svg">
                                                </div>

                                                <div class="graph-chart__panel-number">
                                                    {{weekMileageLabel}}
                                                </div>
                                            </div>

                                            <div class="graph-chart__panel-desc">
                                                {{weekMileageDesc}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="dashCharts.TopFiveMileage.isEmpty" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-sad.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            No cars were driven this week.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Top 5 Speeding -->
                        <div class="dashboard-field__inner --col-4">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Top 5 Speeding
                                    </div>

                                    <div class="dashboard-field__headings-button">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="speedingSearchType" (change)="getTopSpeedingStats()">
                                                <option value="vehicle">Vehicles</option>
                                                <option value="driver" *ngIf="selectedGroupId < 0">Drivers</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #topSpeeding_spinner></app-spinner>

                                    <div *ngIf="!dashCharts.TopFiveSpeeding.isEmpty" class="graph-chart">
                                        <div class="graph-chart__panel" style="height: 250px;">
                                            <!-- Insert chart here -->
                                            <div style="height: 250px">
                                                <canvas id="TopFiveSpeeding"></canvas>
                                            </div>
                                        </div>

                                        <div class="graph-chart__panel" [ngClass]="weekSpeedingDistanceCssClass">
                                            <div class="graph-chart__panel-title">
                                                Speeding Distance (km)
                                            </div>

                                            <div class="graph-chart__panel-count">
                                                <div class="graph-chart__panel-icon">
                                                    <span class="helper"></span>
                                                    <img style="height:28px" src="assets/images/ng-components/dashboard/icon-speeding-distance-new.svg">
                                                </div>

                                                <div class="graph-chart__panel-number">
                                                    {{weekSpeedingDistanceLabel}}
                                                </div>
                                            </div>

                                            <div class="graph-chart__panel-desc">
                                                {{weekSpeedingDistanceDesc}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="dashCharts.TopFiveSpeeding.isEmpty" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-smile.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            There are no speeding drivers/vehicles this week.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Top 5 Idling -->
                        <div class="dashboard-field__inner --col-4">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Top 5 Idling Time
                                    </div>

                                    <div class="dashboard-field__headings-button">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="idlingSearchType" (change)="getTopIdlingTimeStats()">
                                                <option value="vehicle">Vehicles</option>
                                                <option value="driver" *ngIf="selectedGroupId < 0">Drivers</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #topIdling_spinner></app-spinner>

                                    <div *ngIf="!dashCharts.TopFiveIdling.isEmpty" class="graph-chart">
                                        <div class="graph-chart__panel" style="height: 250px;">
                                            <!-- Insert chart here -->
                                            <div style="height: 250px">
                                                <canvas id="TopFiveIdling"></canvas>
                                            </div>
                                        </div>

                                        <div class="graph-chart__panel" [ngClass]="weekIdlingDurationCssClass">
                                            <div class="graph-chart__panel-title">
                                                Idling Time (hh:mm)
                                            </div>

                                            <div class="graph-chart__panel-count">
                                                <div class="graph-chart__panel-icon">
                                                    <span class="helper"></span>
                                                    <img style="height:28px" src="assets/images/ng-components/dashboard/icon-idle-single-new.svg">
                                                </div>

                                                <div class="graph-chart__panel-number">
                                                    {{weekIdlingDurationLabel}}
                                                </div>
                                            </div>

                                            <div class="graph-chart__panel-desc">
                                                {{weekIdlingDurationDesc}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="dashCharts.TopFiveIdling.isEmpty" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-smile.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            There are no idle drivers/vehicles this week.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Fleet Utilisation Weekly -->
                        <div class="dashboard-field__inner --col-4">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Fleet Utilisation
                                    </div>

                                    <div class="dashboard-field__headings-button">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="utilisationWeeklySearchType" (change)="getWeeklyFleetUtilisationStats(true)">
                                                <option value="distance">Distance</option>
                                                <option value="hour">Duration</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #weeklyFleetUtilisation_spinner></app-spinner>

                                    <div *ngIf="!dashCharts.WeeklyFleetUtilisation.isEmpty" class="graph-chart">
                                        <div class="graph-chart__panel" style="height: 250px;">
                                            <!-- Insert chart here -->
                                            <canvas id="WeeklyFleetUtilisation"></canvas>
                                        </div>

                                        <div class="graph-chart__panel" [ngClass]="weeklyFleetUtilisationCssClass">
                                            <div class="graph-chart__panel-title">
                                                {{weeklyFleetUtilisationTitle}}
                                            </div>

                                            <div class="graph-chart__panel-count">
                                                <div class="graph-chart__panel-icon">
                                                    <span class="helper"></span>
                                                    <img style="height:33px" [src]="'assets/images/ng-components/dashboard/' + weeklyFleetUtilisationIcon">
                                                </div>

                                                <div class="graph-chart__panel-number">
                                                    {{weeklyFleetUtilisationLabel}}
                                                </div>
                                            </div>

                                            <div class="graph-chart__panel-desc">
                                                {{weeklyFleetUtilisationDesc}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="dashCharts.WeeklyFleetUtilisation.isEmpty" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-sad.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            No data for past 5 weeks. Data will be shown after 1 week of driving.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Fleet Utilisation Hourly -->
                        <div class="dashboard-field__inner --col-4">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Weekly Fleet Utilisation (By Hour)
                                    </div>
                                    <!--
                                    <div class="dashboard-field__headings-button">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="utilisationHourlySearchType" (change)="getHourlyFleetUtilisationStats()">
                                                <option value="percentage">Percentage</option>
                                                <option value="distance">Distance</option>
                                            </select>
                                        </label>
                                    </div>
                                    -->
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #hourlyFleetUtilisation_spinner></app-spinner>

                                    <div *ngIf="!dashCharts.HourlyFleetUtilisation.isEmpty" class="graph-chart">
                                        <div class="graph-chart__panel" style="height: 250px;">
                                            <!-- Insert chart here -->
                                            <div style="height: 250px">
                                                <canvas id="HourlyFleetUtilisation"></canvas>
                                            </div>
                                        </div>

                                        <div class="graph-chart__panel" [ngClass]="hourlyFleetUtilisationCssClass">
                                            <div class="graph-chart__panel-title">
                                                Average Hourly Utilisation (%)
                                            </div>

                                            <div class="graph-chart__panel-count">
                                                <div class="graph-chart__panel-icon">
                                                    <span class="helper"></span>
                                                    <img style="height:33px" src="assets/images/ng-components/dashboard/icon-fleet-utilization-percent.svg">
                                                </div>

                                                <div class="graph-chart__panel-number">
                                                    {{hourlyFleetUtilisationLabel}}
                                                </div>
                                            </div>

                                            <div class="graph-chart__panel-desc">
                                                {{hourlyFleetUtilisationDesc}}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div *ngIf="dashCharts.HourlyFleetUtilisation.isEmpty" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-smile.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            Chart coming soon.
                                        </div>
                                    </div> -->


                                    <div *ngIf="dashCharts.HourlyFleetUtilisation.isEmpty" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-sad.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            No cars were driven this week.
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!-- Top 5 Fuel Performance -->
                        <div class="dashboard-field__inner --col-4">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Top 5 Fuel Performance
                                        <!-- {{ fuelStatsMonth }} -->
                                    </div>

                                    <div class="dashboard-field__headings-button">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="topFuelPerformanceSearchType" (change)="getTopFiveFuelPerformance()">
                                                <option value="FuelCost">Fuel Cost</option>
                                                <option value="FuelEfficiency">Fuel Efficiency</option>
                                                <option value="FuelUsed">Fuel Used</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #topFuelPerformance_spinner></app-spinner>

                                    <div *ngIf="!dashCharts.TopFiveFuelPerformance.isEmpty" class="graph-chart">
                                        <div class="graph-chart__panel" style="height: 250px;">
                                            <!-- Insert chart here -->
                                            <div style="height: 250px">
                                                <canvas id="TopFiveFuelPerformance"></canvas>
                                            </div>
                                        </div>

                                        <div class="graph-chart__panel" [ngClass]="topFuelPerformanceCssClass">
                                            <div class="graph-chart__panel-title">
                                                {{ topFuelPerformanceTitle }}
                                            </div>

                                            <div class="graph-chart__panel-count">
                                                <div class="graph-chart__panel-icon">
                                                    <span class="helper"></span>
                                                    <img style="height:30px" [src]="'assets/images/ng-components/dashboard/' + topFuelPerformanceIcon">
                                                </div>

                                                <div class="graph-chart__panel-number">
                                                    {{topFuelPerformanceLabel}}
                                                </div>
                                            </div>

                                            <div class="graph-chart__panel-desc">
                                                {{topFuelPerformanceDesc}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="dashCharts.TopFiveFuelPerformance.isEmpty" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-sad.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            No data this month. Enter fuel data or integrate your petrol card.
                                        </div>

                                        <div class="dashboard-field__content-idle-button">
                                            <a class="button --outline" (change)="navigateToViewRefuelEntry(true)">
                                                Go to Data Entry
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Fuel Performance Montly -->
                        <div class="dashboard-field__inner --col-4">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Fuel Performance
                                    </div>

                                    <div class="dashboard-field__headings-button">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="monthlyFuelPerformanceSearchType" (change)="getMonthlyFuelPerformance(true)">
                                                <option value="FuelCost">Fuel Cost</option>
                                                <option value="FuelEfficiency">Fuel Efficiency</option>
                                                <option value="FuelUsed">Fuel Used</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #monthlyFuelPerformance_spinner></app-spinner>

                                    <div *ngIf="!dashCharts.MonthlyFuelPerformance.isEmpty" class="graph-chart">
                                        <div class="graph-chart__panel" style="height: 250px;">
                                            <!-- Insert chart here -->
                                            <div style="height: 250px">
                                                <canvas id="MonthlyFuelPerformance"></canvas>
                                            </div>
                                        </div>

                                        <div class="graph-chart__panel" [ngClass]="monthlyFuelPerformanceCssClass">
                                            <div class="graph-chart__panel-title">
                                                {{ monthlyFuelPerformanceTitle }}
                                            </div>

                                            <div class="graph-chart__panel-count">
                                                <div class="graph-chart__panel-icon">
                                                    <span class="helper"></span>
                                                    <img style="height:30px" [src]="'assets/images/ng-components/dashboard/' + monthlyFuelPerformanceIcon">
                                                </div>

                                                <div class="graph-chart__panel-number">
                                                    {{monthlyFuelPerformanceLabel}}
                                                </div>
                                            </div>

                                            <div class="graph-chart__panel-desc">
                                                {{monthlyFuelPerformanceDesc}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="dashCharts.MonthlyFuelPerformance.isEmpty" class="dashboard-field__content-idle">
                                        <div class="dashboard-field__content-idle-icon">
                                            <img src="assets/images/ng-components/dashboard/icon-indicator-sad.svg">
                                        </div>

                                        <div class="dashboard-field__content-idle-message">
                                            No data for past 5 months. Enter fuel data or integrate your petrol card.
                                        </div>

                                        <div class="dashboard-field__content-idle-button">
                                            <a class="button --outline" (change)="navigateToViewRefuelEntry(true)">
                                                Go to Data Entry
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Scoring -->
                        <div class="dashboard-field__inner">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Score {{ scoringStatsDateRange }}
                                    </div>
                                    <div class="dashboard-field__headings-button">
                                        <label class="select --grey">
                                            <select class="select__input" [(ngModel)]="scoringSearchSortAscending" (change)="getScoringStats()">
                                                <option value="true">Lowest</option>
                                                <option value="false">Highest</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #weeklyScoring_spinner></app-spinner>

                                    <div class="graph-chart --score">
                                        <div class="graph-chart__panel">
                                            <h2 class="heading --level-2 --partial-underline dashboard-box__heading">
                                                Vehicles
                                            </h2>

                                            <div class="graph-chart__panel-inner">
                                                <div class="graph-chart__panel-inner-field --flex-box">
                                                    <div class="graph-chart__panel-inner-block">
                                                        <div *ngIf="scoringVehicleTop" class="data-percent__circle" id="vehicleScore">
                                                            <circle-progress *ngIf="!isDownloadPdfClick" [percent]="scoringVehicleTop.Score" title="{{scoringVehicleTop.scoreTitle}}" [titleColor]="scoringTopVehicleHexColor" [unitsColor]="scoringTopVehicleHexColor" [outerStrokeColor]="scoringTopVehicleHexColor"></circle-progress>
                                                            <canvas id="circleCanvas1" height="0px" width="0px"></canvas>
                                                            <div class="data-percent__desc" [ngStyle]="{ 'color' : scoringTopVehicleHexColor}">
                                                                {{ scoringVehicleTop.VehicleName }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="graph-chart__panel-inner-block">
                                                        <ul *ngIf="scoringVehicleOthers && scoringVehicleOthers.length" class="graph-chart__panel-list">
                                                            <li *ngFor="let vehicle of scoringVehicleOthers">
                                                                <div class="graph-chart__panel-list-inner">
                                                                    <span class="graph-chart__panel-list-value-percentage" [ngStyle]="{ 'color' : vehicle.scoreHexColor}">
                                                                        {{ vehicle.ScoreLabel }}
                                                                    </span>
                                                                    <span class="graph-chart__panel-list-value-desc truncate-line">
                                                                        {{ vehicle.VehicleName }}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div class="graph-chart__panel-inner-field">
                                                    <div class="graph-chart__panel" [ngClass]="scoringVehicleLabelCssClass">
                                                        <div class="graph-chart__panel-title">
                                                            Average Vehicle Score
                                                        </div>

                                                        <div class="graph-chart__panel-count">
                                                            <div class="graph-chart__panel-icon">
                                                                <ul class="graph-chart__panel-rating">
                                                                    <li *ngFor="let i of dummyArrayThree">
                                                                        <img *ngIf="i < scoringVehicleLabelStars.count" src="{{ 'assets/images/ng-components/dashboard/icon-star-select-' + scoringVehicleLabelStars.color + '.svg'}}">
                                                                        <img *ngIf="i >= scoringVehicleLabelStars.count" src="{{ 'assets/images/ng-components/dashboard/icon-star-unselect-' + scoringVehicleLabelStars.color + '.svg'}}">
                                                                    </li>
                                                                    <!--
                                                                    <li><img src="assets/images/ng-components/dashboard/icon-star-select-green.svg"></li>
                                                                    <li><img src="assets/images/ng-components/dashboard/icon-star-select-green.svg"></li>
                                                                    <li><img src="assets/images/ng-components/dashboard/icon-star-unselect-green.svg"></li>
                                                                    -->
                                                                </ul>
                                                            </div>

                                                            <div class="graph-chart__panel-number">
                                                                {{scoringVehicleLabelThisWeek}}%
                                                            </div>
                                                        </div>

                                                        <div class="graph-chart__panel-desc">
                                                            {{scoringVehicleLabelDiff}}% (Previous Week: {{scoringVehicleLabelLastWeek}}%)
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="graph-chart__panel">
                                            <h2 class="heading --level-2 --partial-underline dashboard-box__heading">
                                                Drivers
                                            </h2>

                                            <div class="graph-chart__panel-inner">
                                                <div class="graph-chart__panel-inner-field --flex-box">
                                                    <div class="graph-chart__panel-inner-block">
                                                        <div *ngIf="scoringDriverTop" class="data-percent__circle" id="driverScore">
                                                            <circle-progress *ngIf="!isDownloadPdfClick" [percent]="scoringDriverTop.Score" title="{{scoringDriverTop.scoreTitle}}" [titleColor]="scoringTopDriverHexColor" [unitsColor]="scoringTopDriverHexColor" [outerStrokeColor]="scoringTopDriverHexColor"></circle-progress>
                                                            <canvas id="circleCanvas2" height="0px" width="0px"></canvas>
                                                            <div class="data-percent__desc" [ngStyle]="{ 'color' : scoringTopDriverHexColor}">
                                                                {{ scoringDriverTop.DriverName }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="graph-chart__panel-inner-block">
                                                        <ul *ngIf="scoringDriverOthers && scoringDriverOthers.length" class="graph-chart__panel-list">
                                                            <li *ngFor="let driver of scoringDriverOthers">
                                                                <div class="graph-chart__panel-list-inner">
                                                                    <span class="graph-chart__panel-list-value-percentage" [ngStyle]="{ 'color' : driver.scoreHexColor}">
                                                                        {{ driver.ScoreLabel }}
                                                                    </span>
                                                                    <span class="graph-chart__panel-list-value-desc truncate-line">
                                                                        {{ driver.DriverName }}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div class="graph-chart__panel-inner-field">
                                                    <div class="graph-chart__panel" [ngClass]="scoringDriverLabelCssClass">
                                                        <div class="graph-chart__panel-title">
                                                            Average Driver Score
                                                        </div>

                                                        <div class="graph-chart__panel-count">
                                                            <div class="graph-chart__panel-icon">
                                                                <ul class="graph-chart__panel-rating">
                                                                    <li *ngFor="let i of dummyArrayThree">
                                                                        <img *ngIf="i < scoringDriverLabelStars.count" src="{{ 'assets/images/ng-components/dashboard/icon-star-select-' + scoringDriverLabelStars.color + '.svg'}}">
                                                                        <img *ngIf="i >= scoringDriverLabelStars.count" src="{{ 'assets/images/ng-components/dashboard/icon-star-unselect-' + scoringDriverLabelStars.color + '.svg'}}">
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div class="graph-chart__panel-number">
                                                                {{scoringDriverLabelThisWeek}}%
                                                            </div>
                                                        </div>

                                                        <div class="graph-chart__panel-desc">
                                                            {{scoringDriverLabelDiff}}% (Previous Week: {{scoringDriverLabelLastWeek}}%)
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Weekly Violation Count -->
                        <div class="dashboard-field__inner">
                            <div class="dashboard-field__box">
                                <div class="dashboard-field__headings">
                                    <div class="dashboard-field__headings-title">
                                        Weekly Violation Count
                                    </div>
                                </div>

                                <div class="dashboard-field__contents">
                                    <app-spinner #weeklyViolationCount_spinner></app-spinner>

                                    <div class="graph-chart --violation">
                                        <div class="graph-chart__panel">
                                            <div class="graph-chart__panel-inner">
                                                <div class="graph-chart__panel-inner-field">
                                                    <table class="dashboard-table" cellspacing="0" cellpadding="0">
                                                        <tr>
                                                            <th>Violation</th>
                                                            <th>Last Week</th>
                                                            <th>This Week</th>
                                                        </tr>

                                                        <tr>
                                                            <td>Harsh Brake</td>
                                                            <td>{{lastweekVioHBLabel}}</td>
                                                            <td>
                                                                <div class="dashboard-table__highlight" [ngClass]="classViolations['HarshBrake']">
                                                                    {{weekVioHBLabel}}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Harsh Acceleration</td>
                                                            <td>{{lastweekVioHALabel}}</td>
                                                            <td>
                                                                <div class="dashboard-table__highlight" [ngClass]="classViolations['HarshAcceleration']">
                                                                    {{weekVioHALabel}}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Harsh Turn</td>
                                                            <td>{{lastweekVioHCLabel}}</td>
                                                            <td>
                                                                <div class="dashboard-table__highlight" [ngClass]="classViolations['HarshTurn']">
                                                                    {{weekVioHCLabel}}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Speeding</td>
                                                            <td>{{lastweekVioSpeedingLabel}}</td>
                                                            <td>
                                                                <div class="dashboard-table__highlight" [ngClass]="classViolations['Speeding']">
                                                                    {{weekVioSpeedingLabel}}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Drive Time</td>
                                                            <td>{{lastweekVioDriveTimeLabel}}</td>
                                                            <td>
                                                                <div class="dashboard-table__highlight" [ngClass]="classViolations['RestrictedDrivingHour']">
                                                                    {{weekVioDriveTimeLabel}}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Mileage</td>
                                                            <td>{{lastweekVioMileageLabel}}</td>
                                                            <td>
                                                                <div class="dashboard-table__highlight" [ngClass]="classViolations['Mileage']">
                                                                    {{weekVioMileageLabel}}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                <div *ngIf="!dashCharts.WeeklyViolationCount.isEmpty" class="graph-chart__panel-inner-field">
                                                    <div class="graph-chart__panel-inner-block" style="height: 280px;">
                                                        <!-- Insert chart here -->
                                                        <div style="height: 280px">
                                                            <canvas id="WeeklyViolationCount"></canvas>
                                                        </div>
                                                    </div>

                                                    <div class="graph-chart__panel-inner-block">
                                                        <div class="graph-chart__panel" [ngClass]="violationStatsTotalCssClass">
                                                            <div class="graph-chart__panel-title">
                                                                Total Violation Count
                                                            </div>

                                                            <div class="graph-chart__panel-count">
                                                                <div class="graph-chart__panel-icon">
                                                                    <ul class="graph-chart__panel-rating" title="{{violationStatsTotalStars.desc}}">

                                                                        <li *ngFor="let i of dummyArrayThree">
                                                                            <img *ngIf="i < violationStatsTotalStars.count" src="{{ 'assets/images/ng-components/dashboard/icon-violation-select-' + violationStatsTotalStars.color + '.svg'}}">
                                                                            <img *ngIf="i >= violationStatsTotalStars.count" src="{{ 'assets/images/ng-components/dashboard/icon-violation-unselect-' + violationStatsTotalStars.color + '.svg'}}">
                                                                        </li>
                                                                        <!--
                                                                        <li><img src="assets/images/ng-components/dashboard/icon-violation-select-red.svg"></li>
                                                                        <li><img src="assets/images/ng-components/dashboard/icon-violation-select-red.svg"></li>
                                                                        <li><img src="assets/images/ng-components/dashboard/icon-violation-unselect-red.svg"></li>
                                                                        -->
                                                                    </ul>
                                                                </div>

                                                                <div class="graph-chart__panel-number">
                                                                    {{violationStatsTotalThisWeek}}
                                                                </div>
                                                            </div>

                                                            <div class="graph-chart__panel-desc">
                                                                {{violationStatsTotalDiff}} (Last Week: {{violationStatsTotalLastWeek}})
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="dashCharts.WeeklyViolationCount.isEmpty" class="dashboard-field__content-idle">
                                                    <div class="dashboard-field__content-idle-icon">
                                                        <img src="assets/images/ng-components/dashboard/icon-indicator-smile.svg">
                                                    </div>

                                                    <div class="dashboard-field__content-idle-message">
                                                        No violations this week!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #maintenancePopup="popup">
    <div class="popup__block">
        <div class="popup__heading">
            <img src="assets/images/ng-components/dashboard/icon-maintenance-single.svg" alt="" class="popup__icon">

            <div class="popup__title">
                <span class="--alert-red">{{alertCountOfUpcomingMaintenance}}</span> Maintenance Due Soon
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="popup__table popup__scrollbar" id="style-green">
            <table class="table__elem">
                <thead>
                    <tr>
                        <th scope="col" width="55%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('vehicleName','maintenance')" (click)="sort('vehicleName', 'maintenance')">Vehicle</button>
                        </th>

                        <th scope="col" width="45%" colspan="2"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngIf="!upcomingMaintenanceList.length">
                        <td colspan="100%" class="--center">No Records Found</td>
                    </tr>
                    <ng-container *ngIf="upcomingMaintenanceList.length">
                        <ng-container *ngFor="let vehicle of upcomingMaintenanceList">
                            <tr class="popup__table-accordion-toggle" [class.--active]="tableToggle1.active" toggle #tableToggle1="toggle">
                                <td class="td-break-word">
                                    {{vehicle.vehicleName}}
                                </td>
                                <td class="c-dark-red">
                                    <div class="ta-r">
                                        {{vehicle.maintenances.length + ' '}}Maintenance(s)
                                    </div>
                                </td>
                                <td class="popup__table-accordion-toggle-icon"></td>
                            </tr>
                            <tr class="popup__table-accordion-contents">
                                <td>
                                    <ul class="list">
                                        <li *ngFor="let maintenance of vehicle.maintenances">{{maintenance.maintenanceName}}</li>
                                    </ul>
                                </td>

                                <td colspan="2">
                                    <!-- <div class="ta-r">
                                        <a class="link --primary --u" (click)="hidePopup();navigateToViewMaintenanceByVehicleName(vehicle.vehicleName, true);">
                                            View All
                                        </a>
                                    </div> -->
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</app-popup>


<app-popup #healthPopup="popup">
    <div class="popup__block">
        <div class="popup__heading">
            <img src="assets/images/ng-components/dashboard/icon-vehicle-health-single.svg" alt="" class="popup__icon">

            <div class="popup__title">
                <span class="--alert-red"> {{ healthAlertsCounts }}</span> Vehicle Health Issues
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="popup__table popup__scrollbar" id="style-green">
            <table class="table__elem">
                <thead>
                    <tr>
                        <th scope="col" width="45%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('vehicleName','vehicleHealth')" (click)="sort('vehicleName', 'vehicleHealth')">Vehicle</button>
                        </th>

                        <th scope="col" width="25%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('type','vehicleHealth')" (click)="sort('type', 'vehicleHealth')">Issue</button>
                        </th>

                        <th scope="col" width="30%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('notifyDate','vehicleHealth')" (click)="sort('notifyDate', 'vehicleHealth')">Notify Time</button>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngIf="!vehicleHealthAlerts.length">
                        <td colspan="100%" class="--center">No Records Found</td>
                    </tr>
                    <ng-container *ngFor="let healthIssue of vehicleHealthAlerts">
                        <tr *ngIf="(healthIssue.isBatteryLow != null && healthIssue.isBatteryLow > 0)
                                || (healthIssue.batteryLevel < 14 && healthIssue.isTemperatureHigh != null && healthIssue.isTemperatureHigh > 0)
                                || (healthIssue.batteryLevel < 14 && healthIssue.isDtcPoor != null && healthIssue.isDtcPoor > 0)">

                            <td class="td-break-word">{{ healthIssue.vehicleName }}</td>
                            <td>
                                <span *ngIf="healthIssue.isBatteryLow != null && healthIssue.isBatteryLow > 0">Battery</span>
                                <span *ngIf="healthIssue.batteryLevel < 14 && healthIssue.isTemperatureHigh != null && healthIssue.isTemperatureHigh > 0">High Temperature</span>
                                <span *ngIf="healthIssue.batteryLevel < 14 && healthIssue.isDtcPoor != null && healthIssue.isDtcPoor > 0">Poor DTC</span>
                            </td>
                            <td>
                                <span class="--alert-red">{{Moment(healthIssue.notifyDate,"YYYY-MM-DD HH:mm:ss.SSS").format("hh:mm:ss A")}}</span>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</app-popup>

<app-popup #expiredPopup="popup">
    <div class="popup__block">
        <div class="popup__heading">
            <img src="assets/images/ng-components/dashboard/icon-carlicense-single.svg" alt="" class="popup__icon">

            <div class="popup__title">
                <span class="--alert-red">{{alertCountOfExpiringLicense}}</span> General Expiry
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="popup__table popup__scrollbar" id="style-green">
            <table class="table__elem">
                <thead>
                    <tr>
                        <th scope="col" width="40%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('name','license')" (click)="sort('name', 'license')">Vehicle / Driver</button>
                        </th>

                        <th scope="col" width="30%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('type','license')" (click)="sort('type', 'license')">Expiring Type</button>
                        </th>

                        <th scope="col" width="30%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('expiryDate','license')" (click)="sort('expiryDate', 'license')">Expiring Date</button>

                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngIf="!expiringLicenseList.length">
                        <td colspan="100%" class="--center">No Records Found</td>
                    </tr>
                    <ng-container *ngIf="expiringLicenseList.length">
                        <ng-container *ngFor="let license of expiringLicenseList">
                            <tr class="popup-links" (click)="hidePopup();navigateToResource(license, true);" *ngIf="license.driverId && isDriverEditable">
                                <td class="td-break-word">{{license.name}}</td>
                                <td>{{license.type}}</td>
                                <td>
                                    <span class="--alert-red">{{license.expiryDate}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="license.driverId && !isDriverEditable">
                                <td class="td-break-word">{{license.name}}</td>
                                <td>{{license.type}}</td>
                                <td>
                                    <span class="--alert-red">{{license.expiryDate}}</span>
                                </td>
                            </tr>
                            <tr class="popup-links" (click)="hidePopup();navigateToResource(license, true);" *ngIf="license.vehicleId && isVehicleEditable">
                                <td class="td-break-word">{{license.name}}</td>
                                <td>{{license.type}}</td>
                                <td>
                                    <span class="--alert-red">{{license.expiryDate}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="license.vehicleId && !isVehicleEditable">
                                <td class="td-break-word">{{license.name}}</td>
                                <td>{{license.type}}</td>
                                <td>
                                    <span class="--alert-red">{{license.expiryDate}}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</app-popup>

<app-popup #movingPopup="popup">
    <div class="popup__block">
        <div class="popup__heading">
            <img src="assets/images/ng-components/dashboard/icon-moving-single.svg" alt="" class="popup__icon">

            <div class="popup__title">
                <span class="--alert-red">{{ movingCount }}</span> Moving
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="popup__table popup__scrollbar" id="style-green">
            <table class="table__elem">
                <thead>
                    <tr>
                        <th scope="col" width="80%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('vehicleName','movingVehicle')" (click)="sort('vehicleName','movingVehicle')">Vehicle</button>
                        </th>

                        <!-- <th scope="col" width="20%">
                            Action
                        </th> -->
                    </tr>
                </thead>

                <tbody>
                    <tr *ngIf="!movingVehicleList.length">
                        <td colspan="100%" class="--center">No Records Found</td>
                    </tr>
                    <ng-container *ngIf="movingVehicleList.length">
                        <ng-container *ngFor="let vehicle of movingVehicleList">
                            <tr class="popup-links" (click)="hidePopup();navigateToVehicle(vehicle, true);" *ngIf="isPastTripViewable">
                                <td class="td-break-word">{{vehicle.vehicleName}}</td>
                                <!-- <td>
                                    <a class="link --primary --u" (click)="hidePopup();navigateToVehicle(vehicle, true);">
                                        View</a>
                                </td> -->
                            </tr>
                            <tr *ngIf="!isPastTripViewable">
                                <td class="td-break-word">{{vehicle.vehicleName}}</td>
                                <td>
                                    -
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>

            </table>
        </div>
    </div>
</app-popup>

<app-popup #idlingPopup="popup">
    <div class="popup__block">
        <div class="popup__heading">
            <img src="assets/images/ng-components/dashboard/icon-idle-single.svg" alt="" class="popup__icon">

            <div class="popup__title">
                <span class="--alert-red">{{ idlingCount }}</span> Vehicles Idling
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="popup__table popup__scrollbar" id="style-green">
            <table class="table__elem">
                <thead>
                    <tr>
                        <th scope="col" width="80%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('vehicleName','idlingVehicle')" (click)="sort('vehicleName','idlingVehicle')">Vehicle</button>
                        </th>

                        <!-- <th scope="col" width="20%">
                            Action
                        </th> -->
                    </tr>
                </thead>

                <tbody>
                    <tr *ngIf="!idlingVehicleList.length">
                        <td colspan="100%" class="--center">No Records Found</td>
                    </tr>
                    <ng-container *ngIf="idlingVehicleList.length">
                        <ng-container *ngFor="let vehicle of idlingVehicleList">
                            <tr class="popup-links" (click)="hidePopup();navigateToVehicle(vehicle, true);">
                                <td class="td-break-word">{{vehicle.vehicleName}}</td>
                                <!-- <td>
                                    <a class="link --primary --u" (click)="hidePopup();navigateToVehicle(vehicle, true);">
                                        View</a>
                                </td> -->
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</app-popup>

<app-popup #stoppedPopup="popup">
    <div class="popup__block">
        <div class="popup__heading">
            <img src="assets/images/ng-components/dashboard/icon-stopped-single.svg" alt="" class="popup__icon">

            <div class="popup__title">
                <span class="--alert-red">{{ stoppedCount }}</span> Vehicles Stopped
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="popup__table popup__scrollbar" id="style-green">
            <table class="table__elem">
                <thead>
                    <tr>
                        <th scope="col" width="80%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('vehicleName','stoppedVehicle')" (click)="sort('vehicleName','stoppedVehicle')">Vehicle</button>
                        </th>

                        <!-- <th scope="col" width="20%">
                            Action
                        </th> -->
                    </tr>
                </thead>

                <tbody>
                    <tr *ngIf="!stoppedVehicleList.length">
                        <td colspan="100%" class="--center">No Records Found</td>
                    </tr>
                    <ng-container *ngIf="stoppedVehicleList.length">
                        <ng-container *ngFor="let vehicle of stoppedVehicleList">
                            <tr class="popup-links" (click)="hidePopup();navigateToVehicle(vehicle, true);" *ngIf="isPastTripViewable">
                                <td class="td-break-word">{{vehicle.vehicleName}}</td>
                                <!-- <td>
                                    <a class="link --primary --u" (click)="hidePopup();navigateToVehicle(vehicle, true);">
                                        View</a>
                                </td> -->
                            </tr>
                            <tr *ngIf="!isPastTripViewable">
                                <td class="td-break-word">{{vehicle.vehicleName}}</td>
                                <td>
                                    -
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</app-popup>

<app-popup #disconnectedPopup="popup">
    <div class="popup__block">
        <div class="popup__heading">
            <img src="assets/images/ng-components/dashboard/icon-disconnected-single.svg" alt="" class="popup__icon">

            <div class="popup__title">
                <span class="--alert-red">{{ disconnectedCount }}</span> Vehicles Disconnected
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="popup__table popup__scrollbar" id="style-green">
            <table class="table__elem">
                <thead>
                    <tr>
                        <th scope="col" width="80%">
                            <button type="button" class="table__sortable" [ngClass]="getSortingState('vehicleName','disconnectedVehicle')" (click)="sort('vehicleName','disconnectedVehicle')">Vehicle</button>
                        </th>

                        <!-- <th scope="col" width="20%">
                            Action
                        </th> -->
                    </tr>
                </thead>

                <tbody>
                    <tr *ngIf="!disconnectedVehicleList.length">
                        <td colspan="100%" class="--center">No Records Found</td>
                    </tr>
                    <ng-container *ngIf="disconnectedVehicleList.length">
                        <ng-container *ngFor="let vehicle of disconnectedVehicleList">
                            <tr class="popup-links" (click)="hidePopup();navigateToVehicle(vehicle, true);" *ngIf="isPastTripViewable">
                                <td class="td-break-word">{{vehicle.vehicleName}}</td>
                                <!-- <td>
                                    <a class="link --primary --u" (click)="hidePopup();navigateToVehicle(vehicle, true);">
                                        View</a>
                                </td> -->
                            </tr>
                            <tr *ngIf="!isPastTripViewable">
                                <td class="td-break-word">{{vehicle.vehicleName}}</td>
                                <td>
                                    -
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
