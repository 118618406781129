import { Component, OnInit, HostListener, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { PopupService } from './popup.service';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    exportAs: 'popup',
    animations: [
        trigger('fadeAndScaleToggle', [
            transition(':enter', [
                style({ opacity: 0, transform: '{{ scaleOut }}' }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1, transform: '{{ scaleIn }}' }))
            ], {
                params: {
                    scaleIn: 'scale(1)',
                    scaleOut: 'scale(1.2)'
                }
            }),
            transition(':leave', [
                style({ opacity: 1, transform: '{{ scaleIn }}' }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0, transform: '{{ scaleOut }}' }))
            ], {
                params: {
                    scaleIn: 'scale(1)',
                    scaleOut: 'scale(1.2)'
                }
            }),
        ])
    ],
})
export class PopupComponent implements OnInit {

    @Input('classes') classes: string = '';
    @ViewChild('popup',{static:false}) popup;
    @ViewChild('popupCloser',{static:false}) popupCloser;
    @Input('hideOnOverlayClick') hideOnOverlayClick: boolean = true;
    @Input('showCloseButton') showCloseButton: boolean = true;
    @Input('scaleOut') scaleOut: string = 'scale(1.2)';
    @Input('scaleIn') scaleIn: string = 'scale(1)';
    @Input('disableClickOutside') disableClickOutside: boolean;

    isActive: boolean = false;

    @Output() onClickClosed: EventEmitter<any> = new EventEmitter();

    constructor(private popupService: PopupService) {
    }

    ngOnInit() {
    }

    show(popupType = null): void {
        this.popupService.show(this, popupType);
    }

    onClickClose(): void {
        this.hide();
        this.onClickClosed.emit();
    }

    hide(): void {
        this.popupService.hide();
    }

    @HostListener('click', ['$event.target']) onClick(clickTarget: HTMLElement) {
        const currPopup = document.getElementById('supportPopups');
        if (!this.hideOnOverlayClick || currPopup || this.disableClickOutside) {
            return;
        }

        if (this.popup !== undefined && clickTarget.contains(this.popup.nativeElement)) {
            this.hide();
        }
    }
}
