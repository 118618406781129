export const VIOLATION_TYPE = {
  EXCEED_TIME_OF_DAY: {value: 'EXCEED_TIME_OF_DAY', label: 'Drive Time'},
  IS_IDLING: {value: 'IS_IDLING', label: 'Idling'},
  GEOFENCE_ENTER: {value: 'GEOFENCE_ENTER', label: 'Geofence Enter'}, 
  GEOFENCE_EXIT: {value: 'GEOFENCE_EXIT', label: 'Geofence Exit'},
  EXCEED_MILEAGE: {value: 'EXCEED_MILEAGE', label: 'Daily Mileage'},
  HARSH_CORNERING: {value: 'HARSH_CORNERING', label: 'Harsh Turn'},
  HARSH_BRAKE: {value: 'HARSH_BRAKE', label: 'Harsh Braking'},
  HARSH_ACCELERATE: {value: 'HARSH_ACCELERATION', label: 'Harsh Acceleration'},
  IS_SPEEDING: {value: 'IS_SPEEDING', label: 'Speeding'}
};
export function getViolationLabelFromValue(value:string) {
  let violation = {value: "", label: ""};
    switch (value) {
      case VIOLATION_TYPE.EXCEED_TIME_OF_DAY.value: violation = VIOLATION_TYPE.EXCEED_TIME_OF_DAY; break;
      case VIOLATION_TYPE.IS_IDLING.value: violation = VIOLATION_TYPE.IS_IDLING; break;
      case VIOLATION_TYPE.GEOFENCE_ENTER.value: violation = VIOLATION_TYPE.GEOFENCE_ENTER; break;
      case VIOLATION_TYPE.GEOFENCE_EXIT.value: violation = VIOLATION_TYPE.GEOFENCE_EXIT; break;
      case VIOLATION_TYPE.EXCEED_MILEAGE.value: violation = VIOLATION_TYPE.EXCEED_MILEAGE; break;
      case VIOLATION_TYPE.HARSH_CORNERING.value: violation = VIOLATION_TYPE.HARSH_CORNERING; break;
      case VIOLATION_TYPE.HARSH_BRAKE.value: violation = VIOLATION_TYPE.HARSH_BRAKE; break;
      case VIOLATION_TYPE.HARSH_ACCELERATE.value: violation = VIOLATION_TYPE.HARSH_ACCELERATE; break;
      case VIOLATION_TYPE.IS_SPEEDING.value: violation = VIOLATION_TYPE.IS_SPEEDING; break;
    }
  return violation;
}