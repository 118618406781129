<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md">
                    <h1 class="heading --level-1">
                        Incident Analysis Report
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToCreateIncidentAnalysis(); toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create New Incident Analysis
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create New Incident Analysis
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingIncident = true; toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Incident Analysis
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Incident Analysis
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">

                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">

                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block w-10of12 w-6of12@md search__group">
                        <div class="field-group --unstack@xs search__select-field">
                            <!-- <div class="field-group__item search__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                <label class="select --grey">
                                    <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                        <option value="username">Vehicle</option>
                                        <option value="name">Name</option>
                                    </select>
                                </label>
                            </div> -->

                            <div class="field-group__item search__type-field-group-input">
                                <div class="field">
                                    <div class="field__elems">

                                        <label class="field__holder">
                                            <input id="searchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Vehicle" [(ngModel)]="filterValue" name="filter" (keyup.enter)="filterIncidentAnalysisReport()">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="button__holder">
                            <button type="button" class="button" (click)="filterIncidentAnalysisReport()">SEARCH</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="table table__support-id-mgmt" [class.--isDeleting]="isDeletingIncident">
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <ng-container *ngIf="isDeletingIncident">
                                    <th scope="col">
                                        <label class="inline-input">
                                            <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedIncidentGroups['all']" (ngModelChange)="onIncidentAllSelectChange()">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </th>
                                </ng-container>
                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('plateNo')" (click)="sort('plateNo')">Plate No</button>
                                </th>
                                <th scope="col" width="25%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('driverName')" (click)="sort('driverName')">Driver Name</button>
                                </th>
                                <th scope="col" width="18%">
                                   Created
                                </th>
                                <th scope="col" width="18%">
                                  Last Update
                                </th>
                                <th scope="col" width="10%">
                                    Action
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-container>
                                <tr *ngFor="let incidentList of incidentReportList">
                                    <ng-container *ngIf="isDeletingIncident">
                                        <td scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" [name]="incidentList.id" [value]="true" class="inline-input__input" [(ngModel)]="selectedIncident[incidentList.id]" (ngModelChange)="onIncidentSelectChange($event)">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>
                                    </ng-container>
                                    <td scope="col">
                                        <a class="link --primary" (click)="onIncidentAnalysisClick(incidentList.id);showPopup(editIncidentAnalysisPopup);">
                                            {{incidentList.plateNo}}
                                        </a>
                                    </td>

                                    <td scope="col">
                                        {{incidentList.driverName}}
                                    </td>

                                    <td scope="col">
                                        {{incidentList.dateCreated}}
                                    </td>

                                    <td scope="col">
                                        {{incidentList.lastUpdate}}
                                    </td>

                                    <td scope="col">
                                        <a class="link --primary" (click)="viewIncidentAnalysisDetails(incidentList.id)">
                                            View
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                        </div>
                    </div>
                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination" *ngIf="incidentReportList.length > 0">
                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getIncidentAnalysisReport(pager.currentPage - 1)">
                                <span class="angle-arrow --left">
                                </span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getIncidentAnalysisReport(page)">{{
                                page }}</button>

                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getIncidentAnalysisReport(pager.currentPage + 1)">
                                <span class="angle-arrow --right">
                                </span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingIncident">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedIncident()}} Incident Record(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteSupportPopup)">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteSupportPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedIncident()}}</span> Incident Record
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteIncidentRecords();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #resetPasswordPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Reset?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to reset current account password
        </p>

        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="resetPassword();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #successNotification="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Notification
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p [innerHtml]="emailSentPopupMsg"></p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                OK
            </button>
        </div>
    </div>
</app-popup>

<app-popup #editIncidentAnalysisPopup="popup" class="set-limit-popups">
  <div class="popup__block">
      <div class="grid --gutter --flex-bottom">
          <div class="grid__block w-8of12@sm form__flex">
              <h1 class="heading indicator-green">
                  Edit Incident Analysis
              </h1>
          </div>
      </div>
  </div>

  <div class="popup__block">
      <h2 class="heading --level-2">

      </h2>
  </div>

  <form class="form-field" #incidentAnalysisForm="ngForm">
      <div class="popup__block">
          <div class="field-group__input set-limit-field">
              <div class="field-group__groups">
                  <div class="field-group__groups-col">
                      Vehicle Plate Number:
                  </div>

                  <div class="field-group__groups-col">
                      <div class="field">
                          <div class="field__elems">
                              <label class="field__holder">
                                  <input type="text" class="field__input" name="vehiclePlateNo" [(ngModel)]="currentIncidentReportObj.vehiclePlateNo" disabled>
                              </label>
                              <div class="field__ui"></div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="field-group__groups">
                  <div class="field-group__groups-col">
                      Vehicle Name:
                  </div>

                  <div class="field-group__groups-col">
                      <div class="field">
                          <div class="field__elems">
                              <label class="field__holder">
                                  <input type="text" class="field__input" name="vehicleName" [(ngModel)]="currentIncidentReportObj.vehicleName" disabled>
                              </label>
                              <div class="field__ui"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="field-group__groups">
                <div class="field-group__groups-col">
                    Driver Name:
                </div>

                <div class="field-group__groups-col">
                    <div class="field">
                        <div class="field__elems">
                            <label class="field__holder">
                                <input type="text" class="field__input" name="driverName" [(ngModel)]="currentIncidentReportObj.driverName" disabled>
                            </label>
                            <div class="field__ui"></div>
                        </div>
                    </div>
                </div>
            </div>
              <div class="field-group__groups">
                  <div class="field-group__groups-col">
                      Incident Date:
                      <span class="indicator-green">*</span>
                  </div>

                  <div class="field-group__groups-col">
                      <div class="field">
                          <div class="field__elems">
                              <div class="field__icon">
                                  <div class="field__icon-inner">
                                      <mat-datepicker-toggle [for]="IncidentDatepicker">
                                          <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                      </mat-datepicker-toggle>
                                  </div>
                              </div>
                              <input [matDatepicker]="IncidentDatepicker" (focus)="IncidentDatepicker.open()" (click)="IncidentDatepicker.open()" type="text" #incidentDate="ngModel" [(ngModel)]="incidentDateVar" name="IncidentDatepicker" [max]="todayStartDay" class="field__input" readonly dom-states #IncidentDateDOMStates="DOMStates" required>
                              <div class="field__ui"></div>
                          </div>
                          <mat-datepicker #IncidentDatepicker></mat-datepicker>
                      </div>
                  </div>
              </div>

              <div class="field-group__groups">
                  <div class="field-group__groups-col">
                      Incident Start Time:
                      <span class="indicator-green">*</span>
                  </div>

                  <div class="field-group__groups-col">
                      <div class="field">
                          <div class="field-group__time-inner">
                              <label class="select select-time">
                                  <select class="select__input" #incidentStartTimeHour="ngModel" [(ngModel)]="incidentStartTimeHh" name="incidentStartTimeHour" required>
                                      <option value="" selected disabled hidden>HH</option>
                                      <option *ngFor="let hour of startTimeSelector.hour" [value]="hour">{{hour}}</option>
                                  </select>
                              </label>
                          </div>

                          <div class="field-group__time-inner --no-padding">
                              :
                          </div>

                          <div class="field-group__time-inner">
                              <label class="select select-time">
                                  <select class="select__input" #incidentStartTimeMin="ngModel" [(ngModel)]="incidentStartTimeMm" name="incidentStartTimeMin" required>
                                      <option value="" selected disabled hidden>MM</option>
                                      <option *ngFor="let minute of startTimeSelector.minute" [value]="minute">{{minute}}</option>
                                  </select>
                              </label>
                          </div>

                          <div class="field-group__time-inner">
                              <label class="select select-time">
                                  <select class="select__input" #incidentStartTimeAmPm="ngModel" [(ngModel)]="incidentStartTimeA" name="incidentStartTimeAmPm" required>
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                  </select>
                              </label>
                          </div>
                      </div>

                      <div *ngIf="(incidentStartTimeHour.errors && (incidentStartTimeHour.dirty || incidentStartTimeHour.touched)) || (incidentStartTimeMin.errors && (incidentStartTimeMin.dirty || incidentStartTimeMin.touched)) || (incidentStartTimeAmPm.errors && (incidentStartTimeAmPm.dirty || incidentStartTimeAmPm.touched))">
                          <div class="field-error">
                              {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Incident Start Time')}}
                          </div>
                      </div>
                  </div>
              </div>

              <div class="field-group__groups">
                <div class="field-group__groups-col">
                    Incident End Time:
                    <span class="indicator-green">*</span>
                </div>

                <div class="field-group__groups-col">
                    <div class="field">
                        <div class="field-group__time-inner">
                            <label class="select select-time">
                                <select class="select__input" #incidentEndTimeHour="ngModel" [(ngModel)]="incidentEndTimeHh" name="incidentEndTimeHour" required>
                                    <option value="" selected disabled hidden>HH</option>
                                    <option *ngFor="let hour of endTimeSelector.hour" [value]="hour">{{hour}}</option>
                                </select>
                            </label>
                        </div>

                        <div class="field-group__time-inner --no-padding">
                            :
                        </div>

                        <div class="field-group__time-inner">
                            <label class="select select-time">
                                <select class="select__input" #incidentEndTimeMin="ngModel" [(ngModel)]="incidentEndTimeMm" name="incidentEndTimeMin" required>
                                    <option value="" selected disabled hidden>MM</option>
                                    <option *ngFor="let minute of endTimeSelector.minute" [value]="minute">{{minute}}</option>
                                </select>
                            </label>
                        </div>

                        <div class="field-group__time-inner">
                            <label class="select select-time">
                                <select class="select__input" #incidentEndTimeAmPm="ngModel" [(ngModel)]="incidentEndTimeA" name="incidentEndTimeAmPm" required>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </label>
                        </div>
                    </div>

                    <div *ngIf="(incidentEndTimeHour.errors && (incidentEndTimeHour.dirty || incidentEndTimeHour.touched)) || (incidentEndTimeMin.errors && (incidentEndTimeMin.dirty || incidentEndTimeMin.touched)) || (incidentEndTimeAmPm.errors && (incidentEndTimeAmPm.dirty || incidentStartTimeAmPm.touched))">
                        <div class="field-error">
                            {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Incident End Time')}}
                        </div>
                    </div>
                  </div>
                </div>

                <div class="field-group__groups">
                  <div class="field-group__groups-col">
                      Impact Date:
                  </div>

                  <div class="field-group__groups-col">
                      <div class="field">
                          <div class="field__elems">
                              <div class="field__icon">
                                  <div class="field__icon-inner">
                                      <mat-datepicker-toggle [for]="ImpactDatepicker">
                                          <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                      </mat-datepicker-toggle>
                                  </div>
                              </div>
                              <input [matDatepicker]="ImpactDatepicker" (focus)="ImpactDatepicker.open()" (click)="ImpactDatepicker.open()" type="text" #impactDate="ngModel" [(ngModel)]="impactDateVar" name="ImpactDatepicker" [max]="todayStartDay" class="field__input" readonly dom-states #ImpactDateDOMStates="DOMStates">
                              <div class="field__ui"></div>
                          </div>
                          <mat-datepicker #ImpactDatepicker></mat-datepicker>
                      </div>
                  </div>
              </div>

              <div class="field-group__groups">
                  <div class="field-group__groups-col">
                      Impact Time:
                  </div>

                  <div class="field-group__groups-col">
                      <div class="field">
                          <div class="field-group__time-inner">
                              <label class="select select-time">
                                  <select class="select__input" #impactTimeHour="ngModel" [(ngModel)]="impactTimeHh" name="impactTimeHour"  [required]="impactDateVar!==null" [disabled]="impactDateVar === null">
                                      <option value="" selected disabled hidden>HH</option>
                                      <option *ngFor="let hour of impactTimeSelector.hour" [value]="hour">{{hour}}</option>
                                  </select>
                              </label>
                          </div>

                          <div class="field-group__time-inner --no-padding">
                              :
                          </div>

                          <div class="field-group__time-inner">
                              <label class="select select-time">
                                  <select class="select__input" #impactTimeMin="ngModel" [(ngModel)]="impactTimeMm" name="impactTimeMin" [required]="impactDateVar!==null" [disabled]="impactDateVar === null">
                                      <option value="" selected disabled hidden>MM</option>
                                      <option *ngFor="let minute of impactTimeSelector.minute" [value]="minute">{{minute}}</option>
                                  </select>
                              </label>
                          </div>

                          <div class="field-group__time-inner">
                              <label class="select select-time">
                                  <select class="select__input" #impactTimeAmPm="ngModel" [(ngModel)]="impactTimeA" name="impactTimeAmPm" [required]="impactDateVar!==null" [disabled]="impactDateVar === null">
                                      <option value="AM">AM</option>
                                      <option value="PM">PM</option>
                                  </select>
                              </label>
                          </div>
                      </div>

                      <div *ngIf="(impactTimeHour.errors && (impactTimeHour.dirty || impactTimeHour.touched)) || (impactTimeMin.errors && (impactTimeMin.dirty || impactTimeMin.touched)) || (impactTimeAmPm.errors && (impactTimeAmPm.dirty || impactTimeAmPm.touched))">
                          <div class="field-error">
                              {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Impact Time')}}
                          </div>
                      </div>
                  </div>
              </div>
              <div class="field-group__groups">
                <div class="field-group__groups-col">
                    Description:
                </div>

                <div class="field-group__groups-col">
                    <div class="field">
                        <div class="field__elems">
                            <label class="field__holder">
                                <input type="text" class="field__input" name="description" #description="ngModel" [(ngModel)]="currentIncidentReportObj.description" placeholder="Description" maxlength="255">
                            </label>
                            <div class="field__ui"></div>
                        </div>
                    </div>
                    <div *ngIf="description.errors && (description.dirty || description.touched)">
                        <div class="field-error" *ngIf="description.errors?.maxlength">
                            {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Description', '255')}}
                        </div>
                    </div>
                </div>
            </div>

              <div class="field-group__groups">
                <div class="field-group__groups-col">
                    Remarks:
                </div>

                <div class="field-group__groups-col">
                    <div class="field">
                        <div class="field__elems">
                            <label class="field__holder">
                                <input type="text" class="field__input" name="remarks" #remarks="ngModel" [(ngModel)]="currentIncidentReportObj.remarks" placeholder="Remarks" maxlength="255">
                            </label>
                            <div class="field__ui"></div>
                        </div>
                    </div>
                    <div *ngIf="remarks.errors && (remarks.dirty || remarks.touched)">
                        <div class="field-error" *ngIf="remarks.errors?.maxlength">
                            {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Remarks', '255')}}
                        </div>
                    </div>
                </div>
            </div>
              
          </div>
      </div>

      <div class="popup__block">
          <div class="inline-button__field">
              <button class="button inline-button__inputs" type="button" [disabled]="incidentAnalysisForm.invalid" (click)="showPopup(updateInfoConfirmPopup);">
                  SAVE
              </button>

              <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                  CANCEL
              </button>
          </div>
      </div>
  </form>
</app-popup>

<app-popup #updateInfoConfirmPopup="popup" class="cm-popups">
  <div class="popup__block">
      <div class="cm-heading__main">
          <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

          <div class="cm-heading__text">
              Confirm Changes?
          </div>
      </div>
  </div>
  <div class="popup__block">
      <div class="inline-button__field">
          <button class="button inline-button__inputs" type="button" (click)="updateIncidentAnalysis();hidePopup();">CONFIRM</button>
          <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();showPopup(incidentAnalysisForm);">CANCEL</button>
      </div>
  </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
