<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        {{ vehicleDetails.vehicleName}}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #vehicleForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="compulsory-notes">
                    *Compulsory fields
                </div>
                <div class="grid --gutter">
                    <div class="grid__block w-8of12@sm form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Vehicle Details
                        </h2>
                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Vehicle Name: <span class="indicator-green">*</span>
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" [(ngModel)]="vehicleDetails.vehicleName" name="VehicleName" #vehicleName="ngModel" required maxlength="45">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="field-error" *ngIf="vehicleName.errors?.required">
                                            {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Vehicle Name')}}
                                        </div>
                                        <div class="field-error" *ngIf="vehicleName.errors?.maxlength">
                                            {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Vehicle Name', '45')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Number Plate:
                                        <span class="indicator-green">*</span>
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" [(ngModel)]="vehicleDetails.vehiclePlateNo" name="NumberPlate" placeholder="eg: BDP 5678" #vehiclePlateNo="ngModel" required maxlength="20">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div class="field-error" *ngIf="vehiclePlateNo.errors?.required">
                                            {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Number Plate')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Vehicle Make:
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="select">
                                                    <select class="select__input" (change)="vehicleMakeChoose($event)" [(ngModel)]="vehicleDetails.vehicleMakeId" [ngModelOptions]="{standalone: true}" placeholder="Please Select Vehicle Make">
                                                        <option *ngFor="let item of vehicleMakeModelList" [selected]="vehicleDetails.vehicleMakeId === item.Id" [value]="item.Id">{{item.vehicleMake}}</option>
                                                        <option value="others" [selected]="vehicleMakeOthers === true">
                                                            Others</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="vehicleMakeOthers">
                                            <div class="field field__space-top">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" placeholder="Please specify" [(ngModel)]="vehicleDetails.vehicleMakeOthers" [ngModelOptions]="{standalone: true}" maxlength="45">
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>


                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Vehicle Model:
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="select">
                                                    <select id="vehicleModelOthersSelectBox" class="select__input" (change)="vehicleModelChoose($event)" [(ngModel)]="vehicleDetails.vehicleModelId" [ngModelOptions]="{standalone: true}">
                                                        <option *ngFor="let item of vehicleModelList" [selected]="vehicleDetails.vehicleModelId === item.Id" [value]="item.Id">{{item.Model}}</option>
                                                        <option value="others" [selected]="vehicleModelOthers === true">
                                                            Others</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="vehicleModelOthers">
                                            <div class="field field__space-top">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" placeholder="Please specify" [(ngModel)]="vehicleDetails.vehicleModelOthers" [ngModelOptions]="{standalone: true}" maxlength="45">
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Vehicle Year:
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="select">
                                                    <select class="select__input" [(ngModel)]="vehicleDetails.vehicleYear" [ngModelOptions]="{standalone: true}" (change)="convertYearToInt()">
                                                        <option *ngFor="let year of yearList" [selected]="vehicleDetails.vehicleYear === year">{{year}}
                                                        </option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid__block w-4of12@sm profile__image-field form__flex">

                        <div class="profile__image-cropped --placeholder-grey" *ngIf="!hasImage">
                            <img id="imageId" src="assets/images/ng-components/create-manage/icon-car-placeholder.svg" alt="" width="94" height="102">
                            <button type="button" class="button-circle-thumb" (click)="showPopup(imageUploadPopup)">
                                <img src="assets/images/ng-components/create-manage/icon-add-photo.svg" alt="" width="23" height="23">
                            </button>
                        </div>
                        <div class="profile__image-cropped --placeholder-grey --placeholder-photo" *ngIf="hasImage">
                            <img id="imageId" src="{{vehicleDetails.vehicleImage}}" alt="" width="206" height="206">
                            <button type="button" class="button-circle-thumb" (click)="showPopup(imageUploadPopup)">
                                <img src="assets/images/ng-components/create-manage/icon-edit-photo.svg" alt="" width="22" height="22">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block w-8of12@sm form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Vehicle Status
                        </h2>
                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Current Odometer (km):<span class="indicator-green">*</span>
                                    </div>
                                    <div class="field-group__input apply_all-checkbox">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input  type="text" 
                                                            class="field__input" 
                                                            [(ngModel)]="vehicleDetails.currentOdometer" 
                                                            name="CurrentOdometer" 
                                                            placeholder="eg: 230030" 
                                                            required 
                                                            maxlength="10" 
                                                            #currentOdometer="ngModel" 
                                                            OnlyNumber
                                                            [disabled]="!isPlugAndPlayInstallation()"
                                                            >

                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div class="field-error" *ngIf="currentOdometer.errors?.required">
                                            {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Current Odometer')}}
                                        </div>
                                    </div>
                                    <div class="field-group__input apply_all-checkbox">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label *ngIf="isPlugAndPlayInstallation()" class="inline-input">
                                                    <input 
                                                        type="checkbox" 
                                                        [(ngModel)]="vehicleDetails.isManualOdometer" 
                                                        name="isManualOdometer" 
                                                        class="inline-input__input"
                                                        >
                                                    <span class="inline-input__icon"></span>
                                                    <span>Apply manual odometer</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Engine Hour:
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" [(ngModel)]="vehicleDetails.engineHour" name="EngineHour" placeholder="eg: 15" maxlength="10" OnlyNumber>
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Fuel Efficiency (km/L):
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" [(ngModel)]="vehicleDetails.fuelEfficiency" name="FuelEfficiency" placeholder="eg: 2" maxlength="10" OnlyNumber>
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block w-8of12@sm form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Licence Information
                        </h2>
                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Road Tax Expiry:
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <mat-datepicker-toggle [for]="RoadTaxExpiryDatepicker">
                                                            <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                        </mat-datepicker-toggle>
                                                    </div>
                                                </div>
                                                <input [matDatepicker]="RoadTaxExpiryDatepicker" datepicker-popup="dd-MM-yyyy" (focus)="RoadTaxExpiryDatepicker.open()" (click)="RoadTaxExpiryDatepicker.open()" type="text" [(ngModel)]="vehicleDetails.roadTaxExpiry" name="RoadTaxExpiryDatepicker" class="field__input" readonly dom-states #ExpiryDateDOMStates="DOMStates">

                                                <div class="field__ui"></div>
                                            </div>
                                            <mat-datepicker #RoadTaxExpiryDatepicker [touchUi]="datepickerTouchUi">
                                            </mat-datepicker>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Road Tax Reminder Day(s):
                                    </div>
                                    <div class="field-group__input apply_all-checkbox">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" [(ngModel)]="vehicleDetails.roadTaxReminderDays" name="roadTaxReminderDays" placeholder="eg: 30" maxlength="10" OnlyNumber>
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div class="field-notes">
                                            (Leave empty will restore to default 30 days)
                                        </div>
                                    </div>

                                    <div class="field-group__input apply_all-checkbox">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="inline-input">
                                                    <input type="checkbox" [(ngModel)]="roadTaxDaysApplyAll" name="roadTaxDaysApplyAll" class="inline-input__input">
                                                    <span class="inline-input__icon"></span>
                                                    <span>Applied to all vehicles</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Puspakom Inspection:
                                    </div>
                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <mat-datepicker-toggle [for]="PuspakomDatepicker">
                                                            <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                        </mat-datepicker-toggle>
                                                    </div>
                                                </div>
                                                <input [matDatepicker]="PuspakomDatepicker" datepicker-popup="dd-MM-yyyy" (focus)="PuspakomDatepicker.open()" (click)="PuspakomDatepicker.open()" type="text" [(ngModel)]="vehicleDetails.puspakomDate" name="PuspakomDatepicker" class="field__input" readonly dom-states #ExpiryDateDOMStates="DOMStates">

                                                <div class="field__ui"></div>
                                            </div>

                                            <mat-datepicker #PuspakomDatepicker [touchUi]="datepickerTouchUi">
                                            </mat-datepicker>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Inspection Reminder Day(s):
                                    </div>
                                    <div class="field-group__input apply_all-checkbox">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" [(ngModel)]="vehicleDetails.puspakomReminderDays" name="puspakomReminderDays" placeholder="eg: 30" maxlength="10" OnlyNumber>
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div class="field-notes">
                                            (Leave empty will restore to default 30 days)
                                        </div>
                                    </div>
                                    <div class="field-group__input apply_all-checkbox">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="inline-input">
                                                    <input type="checkbox" [(ngModel)]="puspakomDaysApplyAll" name="puspakomDaysApplyAll" class="inline-input__input">
                                                    <span class="inline-input__icon"></span>
                                                    <span>Applied to all vehicles</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block w-8of12@sm form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Device
                        </h2>

                        <div class="field-group__holder">
                            <div class="field-group__label">
                                IMEI:
                            </div>

                            <div class="field-group__input">
                                <div class="field">
                                    <div class="field__elems">
                                        <label class="field__holder">
                                            <input type="text" class="field__input" [(ngModel)]="vehicleDetails.imeiNo" name="ImeiNo" maxlength="30" disabled="true">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="vehicleForm.invalid" (click)="showPopup(editVehiclePopup)">
                                SAVE
                            </button>
                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToVehicleView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #imageUploadPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Image Upload
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="image-placeholder">
            <img src="assets/images/ng-components/create-manage/icon-image-upload.svg" alt="" width="152" height="133">
        </div>
    </div>

    <!-- <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="chooseImageButtonclick()">
       Browse
      </button>
            <input id="imageUrl" type="file" accept="image/*" (change)="chooseImage($event)" style="position: absolute; opacity: 0;" data-max-size="1" ngf-max-size='1' />
        </div>

        <span class="notes-input">( Max File Size: 2MB )</span>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <label>
                <span class="button inline-button__inputs">
                    Browse
                </span>
                <input type="file" id="myFileInput" accept="image/*" (change)="chooseImage($event)" />
            </label>
        </div>

        <span class="notes-input">( Max File Size: {{maxSizeImageMB}}MB )</span>
    </div>
</app-popup>

<app-popup #imageAlertPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert" *ngIf="imageAlertError === 'sizeError'">Image Size Cannot Larger Than
            {{maxSizeImageMB}}MB</span>
        <span class="notes-input --alert" *ngIf="imageAlertError === 'fileError'">Invalid Image File</span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                OK
            </button>
        </div>
    </div>
</app-popup>


<app-popup #editVehiclePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to save vehicle changes
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateVehicleInfo();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<app-popup #editCropImagePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Crop Image
            </div>
        </div>
    </div>
    <app-spinner #cropImage_spinner></app-spinner>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [resizeToWidth]="maxWidthOrHeight" [onlyScaleDown]="true" [roundCropper]="true" outputType="base64" (cropperReady)="cropperReady()" (imageCropped)="imageCropped($event)"></image-cropper>
    <div class="popup__block">
        <div class="inline-button__field">
            <div class="inline-button__inputs">
                <button type="button" class="button-crop" (click)="imageDirection('left')">
                    <img src="assets/images/ng-components/create-manage/icon-rotate-left.svg" alt="" width="23" height="23">
                </button>

                <button class="button-crop" type="button" (click)="imageDirection('right')">
                    <img src="assets/images/ng-components/create-manage/icon-rotate-right.svg" alt="" width="23" height="23">
                </button>
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="confirmCropImage();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
