import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { RouterProxyService } from '../../../../../_services/router-proxy/router-proxy.service';
import { CompanyRegistrationSharedService } from '../../../../../shared/service/company-registration-shared.service';
import { CompanyRegistrationService } from './../../../../../_services/company-registration/company-registration.service';
import * as moment from 'moment';
import * as Message from './../../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../../constants/responseStatusCode';
import { installationType } from '../../../../../constants/installationType.constant';
import { PopupService } from '../../../../../components/common/popup/popup.service';
import { SnackBarService } from './../../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../../../components/common/spinner/spinner.component';
import { ActivatedRoute } from '@angular/router';
import { UploadComponent } from '../add-device/upload/upload.component';
import { CustomizeDeviceService } from 'src/app/_services/customize-device/customize-device.service';

@Component({
    selector: 'app-switch-provider',
    templateUrl: './switch-provider.component.html',
    styleUrls: ['./switch-provider.component.scss'],
    animations: [
        trigger('slideToggle', [
            transition(':enter', [
                style({ height: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ height: '*' }))
            ]),
            transition(':leave', [
                style({ height: '*' }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ height: 0 }))
            ])
        ]),
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class SwitchProviderComponent implements OnInit {
    searchBy: string = "devices";
    deviceSelectedLabel: string = `All Devices`;
    excludeVehicleDeactivated: boolean = true; // filter inactive vehicle/imei
    customizeDevicesModelComplateDeviceList: any = [];
    customizeDevicesModel: any = {};
    selectedDevices: any = {};
    selectedDeviceGroups: any = {};
    selectedDevicesList: any = [];
    isBtmBorderEdge = true;
    @ViewChild('devicesField', { static: false }) devicesField;
    //list of device installation config
    subjectSelectionFloaterIsShown: boolean = false;
    filterSearchString: string = ""; //store filter search string
    isShowSelectAllOption: boolean = true;

    currentTab: number = 2;
    toggleMobileOpen: boolean = false;
    backupCompanyObj: any;
    companyObj: any = {
        "id": null,
        "name": null,
        "brn": null,
        "email": null,
        "rfDate": null,
        "address": {
            "address1": null,
            "address2": null,
            "city": null,
            "state": null,
            "zipCode": null,
            "countryCode": null
        }
    };
    channelObj: any = [{
        "channelName": null,
        "channelTypeId": null,
        "region": null
    }];
    region: string;
    rfDate: string;
    isEditVehicleInfo: boolean = true;
    oriVehicleObjList: Array<any> = [];
    hasSameProvider: Boolean = true;
    vehicleObjList: Array<any> = [];
    vehicleObj: any = {
        "deviceProviderId": null,
        "installationType": installationType.WIRED.code,
        "vehicleType": null,
        "vehicleModelId": null,
        "vehicleMakeId": null,
        "vehicleModelDetails": null,
        "vehicleYear": null,
        "numberPlate": null,
        "imei": null,
        "msisdn": null,
        "iccid": null,
        "mob": "Y",
        "roaming": "Y",
        "region": null,
        "delivery": {
            "picName": null,
            "address": null,
            "postcode": null,
            "state": null,
            "contactNo": null,
            "email": null
        },
        "installation": {
            "picName": null,
            "address": null,
            "postcode": null,
            "state": null,
            "contactNo": null,
            "email": null,
            "proposedDate": null,
            "proposedTime": null
        },
        "installationRemarks": null
    };

    vehicleIdsString: string;
    minDate = moment(new Date()).format('YYYY-MM-DD');
    //list for dropdown
    yearList: any = [];
    deviceProviderList: any = [];
    vehicleTypeList: any = ['Car', 'Lorry', 'Truck', 'Motorbike', 'Buggy', 'Others'];
    vehicleMakeList: any = [];
    vehicleModelList: any = [];
    salesChannelList: any = [];
    stateList: any = ['Johor', 'Kedah', 'Kelantan', 'Kuala Lumpur', 'Labuan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Penang', 'Perak', 'Perlis', 'Putrajaya', 'Sabah', 'Sarawak', 'Selangor', 'Terengganu'];

    selectInstallationType: string = installationType.WIRED.code; // Default WIRED

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild('fileUpload',{static:false}) fileUpload: UploadComponent;

    constructor(
        private routerProxyService: RouterProxyService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private companyRegistrationSharedService: CompanyRegistrationSharedService,
        private companyRegistrationService: CompanyRegistrationService,
        private route: ActivatedRoute,
        private custDeviceSrv: CustomizeDeviceService,
    ) { }

    navigateToAddDevice() {
        if (this.backupCompanyObj) {
            //set back record for previous page use
            this.companyRegistrationSharedService.setCompanyObj(this.backupCompanyObj);
        }
        this.routerProxyService.navigate(['/support/manage-device/edit-multiple/company-details', this.vehicleIdsString]);
    }

    validateProvider(index) {
        const currVehicle = this.oriVehicleObjList[index];
        const editVehicle = this.vehicleObjList[index];
        if (currVehicle.deviceProviderId == editVehicle.deviceProviderId) {
            this.vehicleObjList[index].deviceProviderId = null;// switch provider not allow to select same provider
            this.hasSameProvider = true;
        }
        this.hasSameProvider = false;
    }

    async switchProvider() {
        try {
            this.pageSpinner.show();

            if (this.companyObj && this.vehicleObjList.length > 0) {
                const reqList = [];
                //make a clone list, so any modifying won't affect original list
                this.vehicleObjList.map(vehicle => {
                    reqList.push(JSON.parse(JSON.stringify(vehicle)));
                });

                this.oriVehicleObjList.map((eachOriCar, index) => {
                    if (eachOriCar.deviceProviderId == this.vehicleObjList[index].deviceProviderId) {
                        throw "found same provider";
                    }
                });
                //formed object structure that required by back end
                reqList.map(vehicle => {
                    // vehicle.region = this.region;
                    if (vehicle.installationType !== installationType.PLUG_PLAY.code) { // && vehicle.installationType !== installationType.PLUG_PLAY_COURIER.code) {
                        if (moment(vehicle.installation.proposedDate).isValid()) {
                            vehicle.installation.proposedDate = moment(vehicle.installation.proposedDate).format("YYYY-MM-DD");
                        }
                        delete vehicle.delivery;
                        // delete vehicle.imeiId;
                        vehicle.imeiId = Number(vehicle.imeiId);
                        vehicle.imei = vehicle.imei;
                        // delete vehicle.imei;
                        delete vehicle.msisdn;
                        delete vehicle.iccid;
                        if (vehicle.installationType === installationType.CUSTOM.code) {
                            delete vehicle.mob;
                            vehicle.customDevicesList = this.selectedDevicesList;
                        }
                    } else {
                        vehicle.imeiId = Number(vehicle.imeiId);
                        delete vehicle.installation;
                    }
                    vehicle.deviceProviderId = Number(vehicle.deviceProviderId);
                    vehicle.regReqId = Number(vehicle.regReqId);
                    vehicle.vehicleId = Number(vehicle.vehicleId);
                    vehicle.scheduleId = Number(vehicle.scheduleId);
                    vehicle.vehicleMakeId = Number(vehicle.vehicleMakeId);
                    vehicle.vehicleModelId = Number(vehicle.vehicleModelId);
                    vehicle.vehicleYear = Number(vehicle.vehicleYear);

                    delete vehicle.isActive;
                    delete vehicle.contactStatus;
                    delete vehicle.assignedInstaller;
                    delete vehicle.installationDate;
                    delete vehicle.installationStatus;
                    delete vehicle.remark;
                    delete vehicle.updatedBy;
                    delete vehicle.dateUpdated;
                    delete vehicle.status;

                    for (let i = 0; i < this.channelObj.length; i++) {
                        if (vehicle.vehicleId == this.channelObj[i].vehicleId) {
                            vehicle.region = this.channelObj[i].region;
                            delete this.channelObj[i].region;
                        }
                    }
                });

                const fileInBytes = [];
                const fileReader = new FileReader();
                for (let i = 0; i < this.fileUpload.fileUploadList.length; i++) {
                    const file = this.fileUpload.fileUploadList[i];
                    const reader = new Promise((resolve, reject) => {
                        fileReader.readAsDataURL(file);
                        fileReader.onload = async (e) => {
                            resolve(fileReader.result);
                        };
                    });
                    const base64 = await reader;
                    const formattedFile = {
                        fileByte: base64,
                        fileName: file.name
                    };
                    fileInBytes.push(formattedFile);
                }
                // switch provider consider another request so update it as the new rfDate
                this.rfDate = moment().format("YYYY-MM-DD");
                const formattedReq = {
                    rfDate: this.rfDate,
                    company: this.companyObj,
                    channels: this.channelObj,
                    vehicles: reqList,
                    files: fileInBytes
                };

                const result = await this.companyRegistrationService.switchProvider(formattedReq);
                let msg: string;
                if (result) {
                    let vehicleLength = result.body.vehicles.length;
                    let vehicleStr = (vehicleLength > 1) ? `${vehicleLength} devices` : `device (${result.body.vehicles[0]})`;
                    msg = Message.getMessage(Message.MESSAGE.VEHICLE_SWITCH_FAILED.value, vehicleStr);
                    if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                        msg = Message.getMessage(Message.MESSAGE.VEHICLE_SWITCH_SUCCESS.value, vehicleStr);
                        this.navigateManageDeviceView();
                    }
                }

                this.snackBar.openGenericSnackBar(msg);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateManageDeviceView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/support/manage-device']);
    }

    toggleVehicleAccordian(index) {
        if (this.vehicleObjList[index].isActive) {
            this.vehicleObjList[index].isActive = false;
        } else {
            this.vehicleObjList[index].isActive = true;
        }
    }

    ngOnInit() {
        this.vehicleIdsString = this.route.snapshot.params.ids;
        this.initCommonFunction();
    }

    async initCommonFunction() {
        const vehicleIds = this.route.snapshot.params.ids.split(',');
        try {
            const sortField = "DeviceId";
            const sortAscending = true;

            let custDeviceRes = await this.custDeviceSrv.getAllCustomizeDevice(sortField, sortAscending, this.excludeVehicleDeactivated);
            this.customizeDevicesModel = custDeviceRes.body;
            this.customizeDevicesModelComplateDeviceList = this.customizeDevicesModel.devices.slice(0);
            this.selectedDeviceGroups.all = false;
            this.onDevicesAllSelectChange();

            const companyInfoRes = await this.companyRegistrationService.getCompanyByVehicleId(vehicleIds);
            this.companyObj = companyInfoRes.body.result;
            this.channelObj = companyInfoRes.body.result.channels;
            delete this.companyObj.channels;

            if (moment(this.companyObj.rfDate).isValid()) {
                this.rfDate = moment(this.companyObj.rfDate).format("YYYY-MM-DD");
            }
            delete this.companyObj.rfDate;

            //initialize vehicles from db
            await this.getVehiclesByVehicleIds();

            this.getYearList(40); //default get 40 years
            //initialize drop down selection
            const deviceProviderResponse = await this.companyRegistrationService.getDeviceProviderList();
            this.deviceProviderList = deviceProviderResponse.body.result;
            const vehicleMakeResponse = await this.companyRegistrationService.getVehicleMakeList();
            this.vehicleMakeList = vehicleMakeResponse.body.result;
            const channelTypeResponse = await this.companyRegistrationService.getSalesChannelTypeList();
            this.salesChannelList = channelTypeResponse.body.result;

            this.channelObj = this.channelObj.map(currChannel => {
                if(currChannel.channelTypeId !== null){
                    let channel = this.salesChannelList.find(eachChannel => { return currChannel.channelTypeId == eachChannel.id });
                    return { ...currChannel, channelType: channel.name };
                }else{
                    return { ...currChannel, channelType: null };
                }
            })
            

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async getVehiclesByVehicleIds() {
        const response = await this.companyRegistrationService.getVehiclesByVehicleIds(this.vehicleIdsString);
        if (response && response.body.vehicles.length > 0) {
            const vehicles = response.body.vehicles;
            this.oriVehicleObjList = vehicles.map(eachVehicle => { return Object.assign({}, eachVehicle) });
            for (let i = 0; i < vehicles.length; i++) {
                const vehicle = vehicles[i];

                //for expand and minimize usage, first one set it true
                if (i === 0) {
                    vehicle.isActive = true;
                } else {
                    vehicle.isActive = false;
                }
                const vehicleModelResponse = await this.companyRegistrationService.getVehicleModelList(vehicle.vehicleMakeId);
                this.vehicleModelList.push(vehicleModelResponse.body.result);

                // vehicle.deviceProviderId = null;
                vehicle.installation.proposedDate = null;
                vehicle.installation.proposedTime = null;
            }
            this.vehicleObjList = vehicles;
        }
    }

    async getVehicleModelList(vehicleMakeId, position) {
        const vehicleModelResponse = await this.companyRegistrationService.getVehicleModelList(vehicleMakeId);
        this.vehicleModelList[position] = vehicleModelResponse.body.result;
        //vehicle make changed, empty previous selected model
        this.vehicleObjList[position].vehicleModelId = null;
    }

    installationTypeChange(event, type, position) {
        this.selectInstallationType = type;
        const target = event.target;
        const vehicleTriggered = this.vehicleObjList[position];
        if (target.checked) {
            if (type === 'wired') {
                vehicleTriggered.delivery = {};
                vehicleTriggered.delivery.picName = null;
                vehicleTriggered.delivery.address = null;
                vehicleTriggered.delivery.contactNo = null;
                vehicleTriggered.delivery.email = null;
                vehicleTriggered.imei = null;
                vehicleTriggered.msisdn = null;
                vehicleTriggered.iccid = null;
                vehicleTriggered.mob = 'Y'; //default value
            } else if (type === 'plugnplay') { // || type === 'plugnplaycourier' ) {
                vehicleTriggered.installation = {};
                vehicleTriggered.installation.picName = null;
                vehicleTriggered.installation.address = null;
                vehicleTriggered.installation.contactNo = null;
                vehicleTriggered.installation.email = null;
                vehicleTriggered.installation.proposedDate = null;
                vehicleTriggered.installation.proposedTime = null;
                vehicleTriggered.mob = 'Y'; //default value
            } else if (type === 'customised') {
                vehicleTriggered.delivery = {};
                vehicleTriggered.delivery.picName = null;
                vehicleTriggered.delivery.address = null;
                vehicleTriggered.delivery.contactNo = null;
                vehicleTriggered.delivery.email = null;
                vehicleTriggered.imei = null;
                vehicleTriggered.msisdn = null;
                vehicleTriggered.iccid = null;
                vehicleTriggered.mob = null;
            }
        }
    }

    getYearList(yearNumber: number) {
        let currentYear = parseInt(moment().format('YYYY'));
        for (let i = 0; i < yearNumber; i++) {
            this.yearList.push(currentYear);
            currentYear = currentYear - 1;
        }
    }

    // cutomised device
    private updateDevicesSelectedLabel() {
        this.selectedDevicesList = [];
        for (let j = 0; j < this.customizeDevicesModelComplateDeviceList.length; j++) {
            const group = this.customizeDevicesModelComplateDeviceList[j];

            for (let k = 0; k < group.idList.length; k++) {
                let selectedModel: any = {};
                const device = group.idList[k];
                if (this.selectedDevices[device.modelId]) {
                    selectedModel.deviceId = group.deviceId;
                    selectedModel.modelId = device.modelId;
                    this.selectedDevicesList.push(selectedModel);
                }
            }
        }
        const totalDevices = this.selectedDevicesList.length;

        if (this.selectedDeviceGroups.all) {
            this.deviceSelectedLabel = `All devices selected (${totalDevices})`;
        } else {
            if (totalDevices === 0) {
                this.deviceSelectedLabel = 'Please select device';
            } else if (totalDevices === 1) {
                this.deviceSelectedLabel = '1 device selected';
            } else {
                this.deviceSelectedLabel = totalDevices + ' devices selected';
            }
        }
    }

    /* ---- Device Selection Floater ---- */
    onDevicesAllSelectChange() {
        // Note: Apply to ALL devices that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedDeviceGroups.all;
        for (let i = 0; i < this.customizeDevicesModelComplateDeviceList.length; i++) {
            const deviceModel = this.customizeDevicesModelComplateDeviceList[i];
            for (let j = 0; j < deviceModel.idList.length; j ++) {
                this.selectedDevices[deviceModel.idList[j].modelId] = allIsSelected;
            }
        }
        this.updateDevicesSelectedLabel();
    }

    private getDeviceSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.devicesField.nativeElement.querySelector("#deviceSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }

    private focusDeviceSearchBox() {
        const searchBox = this.getDeviceSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }

    filterDevicesSelection(searchString: string = "") {
        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.isShowSelectAllOption = true;
            this.customizeDevicesModel.devices = this.customizeDevicesModelComplateDeviceList;
            this.getDeviceSearchBox().value = "";
        } else {
            this.isShowSelectAllOption = false;
            //clon deep copy
            const tempList = JSON.parse(JSON.stringify(this.customizeDevicesModelComplateDeviceList));
            const newList = [];
            //do searching
            for (const group of tempList) {
                
                // Filter by device name
                const normalisedDeviceName = group.name.toLowerCase().replace(' ', '').trim();
                const normalisedFilterName = this.filterSearchString.toLowerCase().replace(' ', '').trim();
                if (normalisedDeviceName.includes(normalisedFilterName) > 0) {
                    newList.push(group);
                } else {
                    // Filter by model name
                    if (Boolean(group.idList) && group.idList.length) {
                        let tempDeviceList = group.idList;
                        tempDeviceList = tempDeviceList.slice(0).filter(this.filterLogicModelName.bind(this));
                        if (tempDeviceList.length) {
                            group.idList = tempDeviceList;
                            newList.push(group);
                        }
                    }
                }
            }
            this.customizeDevicesModel.devices = newList;
        }
    }

    onDevicesSelectChange(modelId: number, deviceId: number) {
        this.fixDevicesCheckBoxesStates(modelId, deviceId); // Tick correct checkboxes
        this.updateDevicesSelectedLabel();
        this.focusDeviceSearchBox();
    }

    private fixDevicesCheckBoxesStates(modelId: number, deviceId: number) {
        const devices = this.customizeDevicesModelComplateDeviceList.find(result => result.deviceId === deviceId);
        for (let i = 0; i < devices.idList.length; i++) {
            // Every device only can select one model, other model under same deviceId set to false
            if (devices.idList[i].modelId !== modelId) {
                this.selectedDevices[devices.idList[i].modelId] = false;
            }
        } 
    }

    private filterLogicModelName(deviceItem: any): boolean {
        if (this.filterSearchString) {
            const normalisedModelName = deviceItem.modelName.toLowerCase().replace(' ', '').trim();
            const normalisedFilterName = this.filterSearchString.toLowerCase().replace(' ', '').trim();
            if (normalisedModelName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }
        return true;
    }

    /* ---- Device installtion method ---- */
    toggleSubjectSelectionFloater(bool: boolean) {
        this.subjectSelectionFloaterIsShown = bool;
    }

    onOutsideClickDeviceReaction(clickEvent: Event): void {
        this.toggleSubjectSelectionFloater(false);
        this.filterDevicesSelection();
    }
    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async closeFileUploadPopup() {
        //clear uploaded file
        this.fileUpload.fileUploadList = [];
        this.hidePopup();
    }

    formatYesOrNo(value) {
        if (value == 'Y') {
            return 'Yes';
        }
        return 'No';
    }

    viewDetails(tabNumbers) {
        this.currentTab = tabNumbers;
    }
}
