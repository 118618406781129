<div class="login">
    <div class="login__layout">
        <div class="login__header">
            <button type="button">
                <img [src]="imageFolder + 'logo-mDrive.svg'" alt="MDrive" class="login__logo">
            </button>
        </div>

        <div class="login__body">
            <div class="login__body-top">
                <h1 class="heading   login__heading">
                    Sign In via SSO
                </h1>
            </div>

            <div class="form   login__form">
                <div class="form__row   fs-sm">
                    <div class="grid" [ngStyle]="{ 'padding-top': '5px', 'padding-bottom': '5px' }">
                        <div class="   w-6of12   as-c">
                            <div class="field-error-2" *ngIf="error">
                                {{ error }}
                                <br>
                                <br>
                                Will redirect to login page in {{ countdownInSec }} seconds.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
