import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


import * as Message from '../../constants/message';
import * as StringUtil from './../../util/stringUtil';

@Injectable({
    providedIn: 'root'
})
export class TripsService {
    constructor(private http: HttpClient) { }

    // async getCompanyVehicleImeiList(vehicleGroup = undefined) {
    //     let path = environment.mtntApiEndpoint + '/imei';
    //     if (vehicleGroup) path += `?vehicleGroup=${vehicleGroup}`;
    //     let result: any = await this.http.get(path).toPromise();
    //     return result;
    // }

    async getLiveSummaryTrips(imeiArr = null, groupId = null) {
        const path = environment.mtntApiEndpoint + "/trip/live";
        let body = {
            // "companyId": companyId,
            pinFavouritesToTop: true
        };
        if (imeiArr !== null) {
            body = Object.assign(body, { imeis: imeiArr });
        }
        if (groupId !== null) {
            body = Object.assign(body, { groupId: groupId });
        }
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async getLiveDashboardTrips(imeiArr = null, groupId = null) {
        const path = environment.mtntApiEndpoint + "/trip/dashboard";
        let body = {};

        if (imeiArr !== null) {
            body = Object.assign(body, { imeis: imeiArr });
        }
        if (groupId !== null) {
            body = Object.assign(body, { groupId: groupId });
        }
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    /*
     * Calling Dynamo DB 
     * Sam 2024-01-26 - ^ not true at all. there are sql queries executed.
     * 
     * violations - ['speeding', 'harsh-braknig]
     */
    async getTrips(imeis, vehicleId, fromDate, toDate, pageSize, violations?:string[]) {
        const path = environment.mtntApiEndpoint + "/trip/post";
        const body = {
            imeis: imeis.length ? imeis : [],
            // companyId: companyId,
            vehicleId: vehicleId,
            fromDate: fromDate == null ? '' : fromDate,
            toDate: toDate == null ? '' : toDate,
            pageSize: pageSize == null ? 10 : pageSize,
            violations
        };
        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getTripDetails(tripId, vehicleId, startTime, endTime, staStpAdd) {
        const path = environment.mtntApiEndpoint + '/trip/details';
        const body: any = {
            "tripId": tripId,
            "vehicleId": vehicleId,
            "startTime": startTime,
            "endTime": endTime,
        };
        if (staStpAdd) {
            body.staStpAdd = staStpAdd;
        }
        let retryIndex: number = 0;
        let result;
        while (retryIndex < 2) {
            try {
                result = await this.http.post(path, body).toPromise();

                if (result.trip_details) {
                    // Success Retrieve Data and Stop the Loop
                    break;
                }

                retryIndex = 2; // Incase fall into infinite loop
                if (retryIndex == 2) {
                    const message = Message.getMessage(Message.MESSAGE.POTENTIAL_DEVELOPER_MISTAKE.value, "Infinite Loop Appear.");
                    console.debug(message);
                }
            } catch (err) {
                retryIndex++;
                if (retryIndex == 2) {
                    throw (err);
                }
            }
        }

        return result;
    }

    //Get Summary Trip Report for Vehicle only
    async getTripSummaryReport(idType, idList, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/trip/summary";
        const body = {
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        idType === 'vehicles' ? body['vehicleId'] = idList : body['driverId'] = idList;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Geofence Report for Vehicle only
    async getGeofenceReport(idType, idList, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/geofence";
        const body = {
            // "includeEnterExitTime": true,
            //include violations that do not have enter and exit
            "includeEnterExitTime": false,
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        idType === 'vehicles' ? body['vehicleId'] = idList : (idType === 'drivers' ? body['driverId'] = idList : body['geofenceName'] = idList);

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Utilization Report
    async getUtilizationReport(idType, idList, startDate, endDate, pageSize, startRecord, sortField, sortAscending, breakdown = false) {
        const path = environment.mtntApiEndpoint + "/report/utilization";
        const body = {
            "startDate": startDate,
            "endDate": endDate,
            "breakdown": breakdown
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        idType === 'vehicles' ? body['vehicleId'] = idList : body['driverId'] = idList;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getCustomUtilizationReport(vehicleTypeList, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/customized-utilization";

        const body = {
            "vehicleType": vehicleTypeList,
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getCustomDriverAnalysisReport(driverIds, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/customized-driver-analysis";

        const body = {
            "driverIds": driverIds,
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Summary Trip Report for Vehicle only
    async getCustomPanicButtonReport(idType, idList, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/customized-panic-button";
        // const path = environment.mtntApiEndpoint + "/report/trip/summary";
        const body = {
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        idType === 'vehicles' ? body['vehicleId'] = idList : body['driverId'] = idList;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }
    
    //Call Violation Report API
    async getTripSummaryViolationReport(idListObj, startDate, endDate, pageSize, startRecord, currentSortField, currentSortAscending) {
        const path = environment.mtntApiEndpoint + "/report/trip/summary-violation";
        const body = Object.assign(idListObj, {
            "startDate": startDate,
            "endDate": endDate,
            "sortField": currentSortField,
            "sortAscending": currentSortAscending
        });

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Details Trip Report
    async getTripDetailsReport(tripId) {
        const path = environment.mtntApiEndpoint + `/report/trip/details?tripId=${tripId}`;
        const result = await this.http.get(path).toPromise();
        return result;
    }

    // set trip tag
    async setTripTag(tripId, vehicleId, tagId) {
        let path = environment.mtntApiEndpoint + `/trip/tags`;
        if (tripId) {
            path += `/${tripId}`;
        }
        const body = {
            tagId: tagId,
            vehicleId: vehicleId
        };
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    // get Trip's tag based on Trip Id
    async getTripTagByTripId(tripId) {
        let path = environment.mtntApiEndpoint + `/trip/tags`;
        if (tripId) {
            path += `/${tripId}`;
        }
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    // Get company tags for trip
    async getTripTags() {
        const path = environment.mtntApiEndpoint + `/trip/tags`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    // Update Trip Notes
    async setTripNote(tripId, vehicleId, tripNote) {
        let path = environment.mtntApiEndpoint + `/trip/notes`;
        if (tripId) {
            path += `/${tripId}`;
        }
        const body = {
            tripNote: tripNote,
            vehicleId: vehicleId
        };
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    //   Get Trip Notes
    async getTripNote(tripId) {
        let path = environment.mtntApiEndpoint + `/trip/notes`;
        if (tripId) {
            path += `/${tripId}`;
        }
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getVehicleViolationHistory(vehicleId = null, startDate = null, endDate = null, pageSize = null, startRecord = null) {
        const path = environment.mtntApiEndpoint + `/trip/violations`;

        // let paramArr = [];
        // if (vehicleId != null) {
        //     paramArr.push(`vehicleId=${vehicleId}`);
        // }
        // if (startDate != null) {
        //     paramArr.push(`startDate=${startDate}`);
        // }
        // if (endDate != null) {
        //     paramArr.push(`endDate=${endDate}`);
        // }
        // if (pageSize != null) {
        //     paramArr.push(`pageSize=${pageSize}`);
        // }
        // if (startRecord != null) {
        //     paramArr.push(`startRecord=${startRecord}`);
        // }
        // let param = '';
        // for (let i = 0; i < paramArr.length; i++) {
        //     if (i == 0) {
        //         param += '?' + paramArr[i];
        //     } else {
        //         param += '&' + paramArr[i];
        //     }
        // }
        // path += param;

        const paramObj: any = {};
        if (vehicleId != null) {
            paramObj.vehicleId = vehicleId;
        }
        if (startDate != null) {
            paramObj.startDate = startDate;
        }
        if (endDate != null) {
            paramObj.endDate = endDate;
        }
        if (pageSize != null) {
            paramObj.pageSize = pageSize;
        }
        if (startRecord != null) {
            paramObj.startRecord = startRecord;
        }

        const result: any = await this.http.get(path, {
            params: paramObj
        }).toPromise();
        return result;
    }

    async getTripsTagsAndNotes(tripIds = [], vehicleId, companyId) {
        const path = environment.mtntApiEndpoint + `/trips/tagsAndNotes`;
        const body = {
            tripIds: tripIds,
            vehicleId: vehicleId,
            companyId: companyId
        };
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Vehicle Activity Report for Vehicle only
    async getVehicleActivityReport(vehicleId, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/vehicle-activity";
        const body = {
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        body['vehicleId'] = vehicleId;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Vehicle Status Report for Vehicle only
    async getVehicleStatusReport(idList, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/vehicle-status";
        const body = {
        };
        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        body['vehicleId'] = idList;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Speeding Report for Vehicle only
    async getSpeedingReport(vehicleId, startDate, endDate, speed, isGreater, isLesser, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/speeding";
        const body = {
            "startDate": startDate,
            "endDate": endDate,
            "speed": speed,
            "isGreater": isGreater,
            "isLesser": isLesser

        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        body['vehicleId'] = vehicleId;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getDetailedSpeedReport(vehicleIds, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/detailed-speeding";
        const body = {
            "startDate": startDate,
            "endDate": endDate,
            "vehicleIds": vehicleIds,
            "sortAscending": sortAscending
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Idling Report for Vehicle only
    async getIdlingReport(vehicleId, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/idling";
        const body = {
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        body['vehicleId'] = vehicleId;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Alert History Report for Vehicle only
    async getAlertHistoryReport(vehicleId, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/alert-history";
        const body = {
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        body['vehicleId'] = vehicleId;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Geofence Monitoring Report for Geofence only
    async getGeofenceMonitoringReport(idType, idList, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/geofence-monitoring";
        const body = {

        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        idType === 'vehicles' ? body['vehicleId'] = idList : body['geofenceId'] = idList;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Vehicle Activity Report for Vehicle only
    async getVehicleTemperatureReport(vehicleId, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/vehicle-temperature";
        const body = {
            "startDate": startDate,
            "endDate": endDate
        };

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        body['vehicleId'] = vehicleId;

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }
    async getIncidentAnalysisReport(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, currentFilterValue = undefined) {
        const path = environment.mtntApiEndpoint + `/report/incident-analysis`;
        const paramObj: any = {};
        if (StringUtil.isNotEmptyOrNull(sortField)) {
            paramObj.sortField = sortField;
        }
        if (typeof (sortAscending) == typeof (true)) {
            paramObj.sortAscending = sortAscending;
        }
        if (pageRecordSize != undefined) {
            paramObj.pageSize = pageRecordSize;
        }
        if (startRecord != undefined) {
            paramObj.startRecord = startRecord;
        }
        if (currentFilterValue != undefined) {
            paramObj.filterValue = currentFilterValue;
        }

        const result: any = await this.http.get(path, {
            params: paramObj
        }).toPromise();
        return result;
    }

    async deleteIncidentAnalysisRecord(incidentAnalysisIdList = []) {
        const body: any = { body: { idList: incidentAnalysisIdList } };

        const path = environment.mtntApiEndpoint + `/report/incident-analysis`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async createIncidentAnalysisReport(incidentList = []) {
        const body = {
            incidentList: incidentList
        };
        const path = environment.mtntApiEndpoint + `/report/incident-analysis`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async updateIncidentAnalysis(incidentList = {}) {
        const body = {
            incidentList: incidentList
        };
        const path = environment.mtntApiEndpoint + `/report/incident-analysis`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    async getIncidentDetails(incidentId :number) {
        const path = environment.mtntApiEndpoint + `/report/incident-analysis/details/${incidentId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

}



