import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { trigger, style, animate, transition } from '@angular/animations';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { CompanyRegistrationService } from './../../../../_services/company-registration/company-registration.service';
import { SupportService } from './../../../../_services/support/support.service';
import { PagerService } from './../../../../_services/pager/pager.service';
import { FileExportService } from '../../../../_services/file-export/file-export.service';
import { RESPONSE_STATUS_CODE as StatusCode } from '../../../../constants/responseStatusCode';
import { installationType as installationTypeConstant } from '../../../../constants/installationType.constant';
import * as Message from './../../../../constants/message';
import * as moment from 'moment';
import { environment } from './../../../../../environments/environment';
import * as XLSX from 'xlsx';
import * as StringUtil from './../../../../util/stringUtil';
import * as NumberUtil from './../../../../util/numberUtil';
import * as DateTimeUtil from './../../../../util/dateTimeUtil';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import { DownloadableArrayItem } from './../../reports/DownloadableArrayItemClass';
import * as _ from 'lodash';
import { ModulePermissionService } from 'src/app/_services/access-control/module-permission.service';
import { modules } from 'src/app/constants/module-access.constant';

@Component({
    selector: 'app-manage-device',
    templateUrl: './manage-device.component.html',
    styleUrls: ['./manage-device.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ManageDeviceComponent implements OnInit {
    @ViewChild('overviewTable',{static:false}) overviewTable;
    overviewTableScrolledToEnd: boolean = false;
    @ViewChild('unableEditPopup',{static:false}) unableEditPopup;
    currentTab: number = 1;
    toggleMdList: any = {};

    pager: any = {};
    pageRecordSize: number = 10;
    sortField: string = 'submitDate';
    sortAscending: boolean = false;
    searchBy: string = 'company';
    devicesList: any = [];
    filterValue: string = '';
    installationType: string = '';
    regStatus: string = '';
    currentFilterValue: string = '';
    currentRegStatus: string = '';
    currentInstallationType: string = '';
    filter: boolean = false;
    filterProgress: string = '';
    tab: string = 'overview';
    filterKey = 'CompanyName';

    toggleMobileOpen: boolean = false;
    isEditingMultipleDevices: boolean;
    isDeletingDevice: boolean;
    checkboxCounter: any = 0;
    selectedMaxisDevice: any = {};
    selectedMaxisDeviceGroups: any = {};

    newOrder = true;

    maxisDeviceList: any = [];
    requestList: any = ['Reschedule new installation date', 'Request new IMEI, etc', 'Troubleshoot device'];
    selectedRequest: number = 0;
    @ViewChild('raiseIssuePopup',{static:false}) raiseIssuePopup;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild('singleDeleteDevicePopup',{static:false}) singleDeleteDevicePopup;
    @ViewChild('deleteDevicePopup',{static:false}) deleteDevicePopup;
    @ViewChild('unableEditDeletePopup',{static:false}) unableEditDeletePopup;
    selectedTask: any;
    //Batch Import
    @ViewChild('batchImportErrorPopup',{static:false}) batchImportErrorPopup;
    @ViewChild('batchImportConfirmPopup',{static:false}) batchImportConfirmPopup;
    @ViewChild('batchImportPreviewPopup',{static:false}) batchImportPreviewPopup;
    batchFileSize: number = environment.appConfig.createManage.batchFileSize;
    supportfileType: Array<string> = ["xlsx", "xls", "csv"];
    file: File; // uploaded file
    fileName: String = ''; // Uploaded file name
    validDataList: any = []; // All valid data entries
    invalidDataList: any = []; // All invalid data entries
    isDownloadDone: boolean = false; // confirm client has download invalid file
    dataRecordsHasError: boolean = false;
    batchErrorMessage = null; // Show any error while excel file validation is running
    totalImportRecords: number = 0;

    // Downloads
    downloadableItem: DownloadableArrayItem;
    exportPdfDeviceOverviewPageLayout = environment.appConfig.manageDevice.deviceOverview.layout;
    exportFileNamePdfTitle = environment.appConfig.manageDevice.deviceOverview.label;
    exportFileNameDeviceOverviewPdfTitle = environment.appConfig.manageDevice.deviceOverview.label;
    pageLayout = this.exportPdfDeviceOverviewPageLayout;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    datepickerTouchUi: boolean = false;
    browseBtnColor: String = '--green';

    // access right control
    creatable = false;
    editable = false;
    deletable = false;

    overview(): void {
        this.currentTab = 1;
        this.tab = 'overview';
        this.resetFilterSearch();
        this.getAllDevices();
    }

    getPlaceHolderText() {
        let placeHolderText = 'Search By';
        if (this.searchBy === 'company') {
            placeHolderText = `${placeHolderText} Company`;
        } else if (this.searchBy === 'vehicle') {
            placeHolderText = `${placeHolderText} Vehicle`;
        } else if (this.searchBy === 'imei') {
            placeHolderText = `${placeHolderText} IMEI No`;
        }
        return placeHolderText;
    }

    
    async raiseIssue() {
        this.pageSpinner.show();
        try {
            let selectedIds;
            let result;
            if (this.getSelectedIds().length === 0) {
                //single raise issue
                selectedIds = [Number(this.selectedTask.vehicleId)];
            } else {
                //multi raise issue
                selectedIds = this.getSelectedIds().map(id => Number(id));
            }
            //according to their position in the requestList
            if (this.selectedRequest == 0) {
                //reschedule
                const reqObj = {
                    vehicleIds: selectedIds
                };
                result = await this.companyRegistrationService.rescheduleDevice(reqObj);

            } else if (this.selectedRequest == 1) {
                const reqObj = {
                    vehicleIds: selectedIds
                };
                result = await this.companyRegistrationService.requestNewImei(reqObj);
            }

            let msg = Message.getMessage(Message.MESSAGE.RAISED_FAILED.value);
            if (result && result.statusCode == StatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.RAISED_SUCCESS.value);

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getAllDevices();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async deleteDevice() {
        this.pageSpinner.show();
        try {
            let selectedIds;
            if (this.getSelectedIds().length === 0) {
                //single delete from 3 dots
                selectedIds = [Number(this.selectedTask.vehicleId)];
            } else {
                //multi delete
                selectedIds = this.getSelectedIds().map(id => Number(id));
            }

            const reqObj = {
                vehicleIds: selectedIds
            };

            const result = await this.companyRegistrationService.deleteDevice(reqObj);
            const vehicleName = this.selectedTask.vehicleName;

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Vehicle', vehicleName);
            if (result && result.statusCode == StatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Vehicle', vehicleName);
                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getAllDevices();
            }
            this.snackBar.openGenericSnackBar(msg);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    goToFillData(vehicleId) {
        this.routerProxyService.navigate(['/support/manage-device/fill-data', vehicleId]);
    }

    navigateToAddDevice() {
        this.routerProxyService.navigate(['/support/manage-device/add-device/company-details', this.newOrder]);
    }

    navigateToEditDevice() {
        const selectedObjHaveActivated = this.isAbleToEditDelete();

        if (selectedObjHaveActivated) {
            //activated cannot edit
            this.showPopup(this.unableEditDeletePopup);
        } else {
            const selectedIdString = this.getSelectedIds().join();
            this.routerProxyService.navigate(['/support/manage-device/edit-multiple/company-details', selectedIdString]);
        }
    }

    navigateToSwitchProvider(vehicleId) {
        // const selectedObjHaveActivated = this.isAbleToEditDelete();

        // if (selectedObjHaveActivated) {
        //     //activated cannot edit
        //     this.showPopup(this.unableEditDeletePopup);
        // } else {
        let selectedIdString = this.getSelectedIds().join();

        if (this.getSelectedIds().length < 1) {
            selectedIdString = vehicleId;
        }
        this.routerProxyService.navigate(['/support/manage-device/switch-provider', selectedIdString]);
        // }
    }

    onMaxisDeviceAllSelectChange() {
        const allIsSelected = this.selectedMaxisDeviceGroups.all;

        for (let i = 0; i < this.devicesList.length; i++) {
            const maxisDeviceModel = this.devicesList[i];
            this.selectedMaxisDevice[maxisDeviceModel.vehicleId] = allIsSelected;
        }
    }

    checkDeviceAllSelect(event) {
        if (this.thereAreMultipleSelectedCompanies(this.getAllIds())) {
            event.preventDefault();
            this.showPopup(this.unableEditPopup);
        }
    }

    onMaxisDeviceSelectChange(maxisDeviceSelected: boolean) {
        if (maxisDeviceSelected) {
        } else {
            this.selectedMaxisDeviceGroups['all'] = false;
        }
    }

    checkDeviceSelect(event, triggerId: string) {
        if (this.thereAreMultipleSelectedCompanies(this.getSelectedIds(triggerId))) {
            event.preventDefault();
            this.showPopup(this.unableEditPopup);
        }
    }

    getAllIds() {
        return this.devicesList.map((model) => {
            return model.vehicleId;
        });
    }

    getSelectedIds(additionalId?) {
        const selectedIds = [];

        if (additionalId !== undefined) {
            selectedIds.push(additionalId);
        }

        for (const id in this.selectedMaxisDevice) {
            if (this.selectedMaxisDevice[id]) {
                selectedIds.push(Number(id));
            }
        }

        return selectedIds;
    }

    thereAreMultipleSelectedCompanies(selectedIds): boolean {
        let selectedDevices = [];
        const selectedCompanies = [];

        // Get all the selected devices' details
        selectedDevices = this.devicesList.filter((model) => {
            return selectedIds.indexOf(model.vehicleId) > -1;
        });

        // Loop through and gather all unique company names
        for (let i = 0; i < selectedDevices.length; i++) {
            if (selectedCompanies.indexOf(selectedDevices[i].companyName) < 0) {
                selectedCompanies.push(selectedDevices[i].companyName);
            }
        }

        // Return the result. `true` if there are more than 1,
        // false otherwise.
        return selectedCompanies.length > 1;
    }

    countSelectedMaxisDevice() {
        return Object.keys(this.selectedMaxisDevice).filter(result => this.selectedMaxisDevice[result] === true).length;
    }

    cancelResetDelete() {
        this.isEditingMultipleDevices = false;
        this.isDeletingDevice = false;
        this.selectedMaxisDevice = {};
        this.selectedMaxisDeviceGroups['all'] = false;
        this.selectedRequest = 0;
    }

    /* ---- Download File ---- */
    private async initDownload() {
        this.hidePopup();
        if (!this.pageSpinner.isShowing()) {
            this.pageSpinner.show();
        }
        try {
            const downloadedRecords: Array<any> = await this.fetchDataForDownload();
            this.downloadableItem = this.generateDownloadables(downloadedRecords);
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.pageSpinner.hide();
        }
    }
    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }
    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        apiResponse = await this.getAllDevices(startRecord, isDownload);
        if (apiResponse != null) {
            apiResultList = apiResponse.result;
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }
    // For download summary report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 15 }, { wch: 17.5 }, { wch: 17.5 }, { wch: 12.5 }, { wch: 12.5 },
        { wch: 10 }, { wch: 10 }, { wch: 15.5 }, { wch: 16 }, { wch: 20 }, { wch: 13 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }];

        filename = this.exportFileNamePdfTitle;
        label = this.exportFileNameDeviceOverviewPdfTitle;
        
        if (this.filterProgress === 'terminated') {
            headerName = [
                "Termination Date", "Submit Date", "Provider", "Plate No", "Company Name", "BRN", "RF Date",
                "Channel", "Channel Name", "Region", "IMEI", "ICCID", "Opportunity ID",
                "MES", "Billing Date", "Replacement Case", "Activation Date",
                "Installation Date"
            ];
        } else {
            headerName = [
                "Submit Date", "Provider", "Plate No", "Company Name", "BRN", "RF Date",
                "Channel", "Channel Name", "Region", "IMEI", "ICCID", "Opportunity ID",
                "MES", "Billing Date", "Replacement Case", "Activation Date",
                "Installation Date"
            ];
        }        

        /**
         * Change the header type sequence, when the header sequnce is changed
         * Change the header type, when the header content data type is changed
         */
        // // excel file cell data type & format(if applicable)
        // headerType = [
        //     { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" },
        //     { type: "string" }, { type: "number" }, { type: "number" }, { type: "number" },
        //     { type: "number" }, { type: "number" }, { type: "number" }, { type: "string" }, { type: "string" }
        // ];
        // (this.filterProgress === 'terminated')? recordList[i].dateUpdated: '',

        for (let i = 0; i < recordList.length; i++) {
            const tempRow = [
                recordList[i].submitDate || '',
                recordList[i].provider || '',
                recordList[i].plateNo || '',
                recordList[i].companyName || '',
                recordList[i].brn || '',
                recordList[i].rfDate || '',
                recordList[i].salesChannelType || '',
                recordList[i].salesChannelName || '',
                recordList[i].region || '',
                recordList[i].imeiNo || '',
                recordList[i].iccid || '',
                recordList[i].cmssId || '',
                recordList[i].mes || '',
                recordList[i].billingDate || '',
                recordList[i].replacementCaseNo || '',
                recordList[i].activationDate || '',
                recordList[i].installationDate || ''
            ];
            if(this.filterProgress === 'terminated') {
                tempRow.unshift(recordList[i].dateUpdated || '')
            }
            data.push(tempRow);
        }
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    async btnExcel() {
        await this.initDownload();
        await this.downloadableItem.downloadExcelFile();
    }
    async btnPdf() {
        await this.initDownload();
        await this.downloadableItem.downloadPdfFile(null);
    }
    async btnCsv() {
        await this.initDownload();
        await this.downloadableItem.downloadCsvFile();
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    onOverviewTableScroll() {
        if (!this.overviewTable) return;

        const tableElem = this.overviewTable.nativeElement;
        this.overviewTableScrolledToEnd = tableElem.scrollLeft + tableElem.clientWidth >= tableElem.scrollWidth;
    }

    constructor(
        private platform: Platform,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private companyRegistrationService: CompanyRegistrationService,
        private supportService: SupportService,
        private pagerService: PagerService,
        private fileExportService: FileExportService,
        private popupCampaignService: PopupCampaignService,
		private mpService: ModulePermissionService,

    ) {

    }

    

    /**
     * Added access right loading for 3 vars (`creatable`, `editable` & `deletable`)
     * @author Sam Liew 2024-02-05
     */
    async ngOnInit() {
        this.checkIsAllowAccess();
        this.getAllDevices();
        this.checkUnviewedCampaign();
    }

    /**
     * load the access right for this module.  
     * @author Sam Liew 2024-02-05
     */
    private async checkIsAllowAccess(){

        const permissions = await this.mpService.hasPermission(modules.BO_SUPPORT_CRUD_DEVICE.value);

        if (permissions?.BO_SUPPORT_CRUD_DEVICE){
            const {cAccess, eAccess, dAccess} = permissions.BO_SUPPORT_CRUD_DEVICE;
            
            this.creatable = cAccess;
            this.editable = eAccess;
            this.deletable = dAccess;

        }

    }

    /**
     * added access right control using `this.editable`.  
     * action button will no longer respond if the user does not have `editable` permission.  
     * @author Sam 2024-02-05
     */
    toggleFunction(indexTriggered) {

        if (!this.editable){
            return;
        }

        this.toggleMdList.map((toggle, index) => {
            //not selected index all set to false
            if (indexTriggered !== index) {
                toggle.isActive = false;
            } else {
                //only selected will show
                toggle.isActive = !toggle.isActive;
            }
        });
    }

    actionTriggered(action, selectedIndex) {

        //hide toggle small popup after pressed
        this.toggleMdList[selectedIndex].isActive = !this.toggleMdList[selectedIndex].isActive;
        //reset raise issue selection to 1st one
        this.selectedRequest = 0;
        this.selectedTask = this.devicesList[selectedIndex];
        if (action === 'EDIT') {
            //Edit
            this.routerProxyService.navigate(['/support/manage-device/edit-multiple/company-details', this.selectedTask.vehicleId]);
        } else if (action === 'ISSUE') {
            //Raise issue
            this.showPopup(this.raiseIssuePopup);
        } else if (action === 'DELETE') {
            //Delete
            this.showPopup(this.singleDeleteDevicePopup);
        } else if (action === 'SWITCH') {
            //Switch Provider
            this.navigateToSwitchProvider(this.selectedTask.vehicleId);
        }

        
    }

    isAbleToEditDelete() {
        const selectedIds = this.getSelectedIds();
        let selectedObjHaveActivated = false;
        for (let i = 0; i < selectedIds.length; i++) {
            const selectObj = this.devicesList.find(tmp => Number(tmp.vehicleId) === Number(selectedIds[i]));
            if (!(selectObj.regStatus === 'PENDING' || selectObj.regStatus === 'SCHEDULED')) {
                selectedObjHaveActivated = true;
                break;
            }
        }

        return selectedObjHaveActivated;
    }

    checkIsAbleDelete() {
        const selectedObjHaveActivated = this.isAbleToEditDelete();

        if (selectedObjHaveActivated) {
            //activated cannot delete
            this.showPopup(this.unableEditDeletePopup);
        } else {
            this.showPopup(this.deleteDevicePopup);
        }
    }

    async formatResultForContextMenu(resultList) {
        this.toggleMdList = resultList.map(i => {
            const toggle = {
                isActive: false,
                children: []
            };

            if (i.regStatus === 'PENDING' || i.regStatus === 'SCHEDULED') {
                //actions can be performed before activated
                toggle.children = [
                    {
                        value: 'EDIT',
                        data: 'Edit Info'
                    }, {
                        value: 'ISSUE',
                        data: 'Raise Issue'
                    }, {
                        value: 'SUSPEND',
                        data: 'Suspend Device'
                    }, {
                        value: 'DELETE',
                        data: 'Delete'
                    }
                ];
            } else if (i.regStatus && !(i.regStatus === 'PENDING' || i.regStatus === 'SCHEDULED')) {
                //actions can be performed after activated
                toggle.children = [
                    {
                        value: 'ISSUE',
                        data: 'Raise Issue'
                    }, {
                        value: 'SUSPEND',
                        data: 'Suspend Device'
                    }, {
                        value: 'SWITCH',
                        data: 'Switch Provider'
                    }
                ];
            } else {
                //actions for dont have data in vehicle_reg_details
                toggle.children = [
                    {
                        value: 'SUSPEND',
                        data: 'Suspend Device'
                    }
                ];
            }
            return toggle;
        });
    }

    //overview tab
    async getAllDevices(page: number = 1, isDownload: boolean = false) {
        this.pageSpinner.show();
        try {
            //reset sorting if sorted using termination date in Terminated filter
            if(this.sortField == 'dateUpdated' && this.filterProgress != 'terminated') {
                this.sortField = 'submitDate';
                this.sortAscending = false;
            }

            if(isDownload){
                const devicesResult: any = await this.supportService.getAllDevices(null, null, this.sortField, this.sortAscending, this.filterKey,  this.currentFilterValue, this.currentInstallationType, this.currentRegStatus, this.filterProgress);
                return devicesResult.body;
            }
            else{
                const startRecord = ((page - 1) * this.pageRecordSize) + 1;

                // const devicesResult: any = await this.supportService.getAllDevices(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.companyName, this.installationType, this.regStatus);
                const devicesResult: any = await this.supportService.getAllDevices(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue, this.currentInstallationType, this.currentRegStatus, this.filterProgress);
                devicesResult.body.result = devicesResult.body.result.map((eachRes) => {
                    const formattedRes = eachRes;
                    formattedRes.submitDate = DateTimeUtil.getDateLabel(formattedRes.submitDate, "YYYY-MM-DD HH:mm:ss.SSS", "DD/MM/YYYY");
                    formattedRes.rfDate = DateTimeUtil.getDateLabel(formattedRes.rfDate, "YYYY-MM-DD HH:mm:ss", "DD/MM/YYYY");
                    formattedRes.billingDate = DateTimeUtil.getDateLabel(formattedRes.billingDate, "YYYY-MM-DD", "DD/MM/YYYY");
                    formattedRes.installationDate = DateTimeUtil.getDateLabel(formattedRes.installationDate, "YYYY-MM-DD HH:mm:ss", "DD/MM/YYYY");
                    formattedRes.activationDate = DateTimeUtil.getDateLabel(formattedRes.activationDate, "YYYY-MM-DD HH:mm:ss.SSS", "DD/MM/YYYY");
                    if(formattedRes.dateUpdated) {
                        formattedRes.dateUpdated = DateTimeUtil.getDateLabel(formattedRes.dateUpdated, "YYYY-MM-DD HH:mm:ss.SSS", "DD/MM/YYYY");
                    }
                    return eachRes;
                });
                this.devicesList = devicesResult.body.result;
                this.formatResultForContextMenu(this.devicesList);
                this.pager = this.pagerService.getPager(devicesResult.body.totalRecord, page, this.pageRecordSize);
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async searchCompany() {
        if (this.searchBy === 'company') {
                this.filterKey = 'CompanyName';
            } else if (this.searchBy === 'vehicle') {
                this.filterKey = 'VehicleName';
            } else if (this.searchBy === 'imei'){
                this.filterKey = 'IMEI'
            } else {
                return;
            }
        
            this.currentFilterValue = this.filterValue;
        await this.getAllDevices();
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }
        await this.getAllDevices();
    }

    getSortingStatus(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    async getRegStatus(regStatus) {

        switch (regStatus) {
            case 'inProgress':
                if (this.filterProgress == regStatus) {
                    this.filterProgress = '';
                    this.currentRegStatus = '';
                } else {
                    this.filterProgress = regStatus;
                    this.currentRegStatus = '';
                }
                break;
            case 'installed':
                if (this.filterProgress == 'installed') {
                    this.currentRegStatus = '';
                    this.filterProgress = '';
                } else {
                    this.filterProgress = 'installed';
                }
                break;
            case 'completed':
                if (this.currentRegStatus == 'COMPLETED') {
                    this.currentRegStatus = '';
                    this.filterProgress = '';
                } else {
                    this.currentRegStatus = 'COMPLETED';
                    this.filterProgress = 'completed';
                }
                break;
            case 'deactivated':
                if (this.currentRegStatus == 'DEACTIVATED') {
                    this.currentRegStatus = '';
                    this.filterProgress = '';
                } else {
                    this.currentRegStatus = 'DEACTIVATED';
                    this.filterProgress = 'deactivated';
                }

                break;
            case 'suspended':
                if (this.filterProgress == 'suspended') {
                    this.filterProgress = '';
                    this.currentRegStatus = '';
                } else {
                    this.filterProgress = 'suspended';
                }
                break;
            case 'terminated':
                if (this.filterProgress == 'terminated') {
                    this.filterProgress = '';
                    this.currentRegStatus = '';
                } else {
                    this.currentRegStatus = 'TERMINATED';
                    this.filterProgress = 'terminated';
                }
                break;
        }
        // this.currentRegStatus = regStatus;
        await this.getAllDevices();
    }

    async getInstallationType(installationType) {
        if (this.currentInstallationType == installationType) {
            this.currentInstallationType = '';
        } else {
            this.currentInstallationType = installationType;
        }

        await this.getAllDevices();
    }

    async resetFilterSearch() {
        this.filterValue = '';
        this.currentFilterValue = '';
        this.currentRegStatus = '';
        this.currentInstallationType = '';
        this.filterProgress = '';
    }

    // onBatchFileUpload
    async batchImport(event) {
        this.fileName = event.target.files[0].name;
        const currFileType = this.fileName.substring(this.fileName.lastIndexOf('.') + 1);

        // Check File Type
        if (event.target.files.length > 0 && this.supportfileType.find(result => this.fileName.includes(result))) {
            let fileSize = event.target.files[0].size;
            // this.fileName = event.target.files[0].name;
            this.isDownloadDone = false;
            this.dataRecordsHasError = false;
            this.validDataList = [];
            this.invalidDataList = [];
            fileSize = fileSize / 1024 / 1024;
            if (fileSize > this.batchFileSize) {
                // file size too large
                this.batchErrorMessage = Message.getMessage(Message.MESSAGE.FILE_SIZE_TOO_LARGE.value, this.batchFileSize, fileSize.toFixed(1));
                this.showPopup(this.batchImportErrorPopup);
            } else {
                this.file = event.target.files[0];
                const fileInput: any = document.getElementById('myFileInput');
                fileInput.value = null; // Clear previous file input data
                this.convertExcelToJson();
            }
        } else {
            const supportedFileType = this.supportfileType.join(',');
            this.batchErrorMessage = Message.getMessage(Message.MESSAGE.INCORRECT_FILE_FORMAT_TYPE.value, supportedFileType, currFileType);
            this.showPopup(this.batchImportErrorPopup);
        }
    }

    /**
     * Convert Excel File to Json Object
     */
    async convertExcelToJson() {
        // convert excel file into bstr format
        const bstr = await this.getBstrValue();
        // Get Excel Content(Include Header);
        const oriWorkbook = XLSX.read(bstr, { type: "binary", cellDates: true, cellNF: false, cellText: false });
        //1st work sheet
        const worksheet = oriWorkbook.Sheets[oriWorkbook.SheetNames[0]];
        const headerList = ["Company Name", "BRN", "Email", "Address 1", "Address 2", "City", "State", "ZipCode", "Country", "RF Date", "Channel Name", "Channel Type", "Region", "Installation Type", "Device Provider", "Vehicle Type", "Vehicle Make", "Vehicle Model", "Model Details", "Vehicle Year", "Number Plate", "MOB", "Roaming", "Manual Odometer", "PIC Name", "PIC Address", "PIC Postcode", "PIC State", "PIC Contact No", "PIC Email", "Propose Installation Date", "Propose Installation Time", "IMEI", "ICCID", "MSISDN", "MES ID", "Opportunity-ID"];
        const header = this.getHeaderRow(worksheet);

        let allColumnMatched = true;
        //if the file uploaded have invalid reason in last column, remove it, that column would be ignore
        if (header[header.length - 1] === "Invalid Reason") {
            header.pop();
        }
        //check column matched or not and it's sequence

        for (let i = 0; i < headerList.length; i++) {
            if (headerList[i] !== header[i]) {
                allColumnMatched = false;
                break;
            }
        }

        if (headerList.length !== header.length || !allColumnMatched) {
            this.batchErrorMessage = "Template Column Not Matched";
            this.showPopup(this.batchImportErrorPopup);
            return;
        }

        const batchImportList = XLSX.utils.sheet_to_json(worksheet, { dateNF: "YYYY-MM-DD HH:mm:ss", raw: false, defval: null }/* , { raw: true } */);

        // Check File Content
        const checkContentResult = await this.checkIsValidFileContent(batchImportList);
        if (!checkContentResult.status) {
            this.batchErrorMessage = checkContentResult.errorMessage;
            this.showPopup(this.batchImportErrorPopup);
        } else {
            this.showPopup(this.batchImportPreviewPopup);
        }
    }

    // format checking
    async checkIsValidFileContent(jsonSheet) {
        if (jsonSheet.length) {
            const {
                salesChannelTypeList,
                deviceProviderList,
                vehicleMakeList,
                regionList,
                vehicleTypeList
            } = await this.getCanonicalValues(false);
            const typeDef = {
                "Company Name": { type: "string", required: true },
                "BRN": { type: "string", required: true },
                "Email": { type: "email", required: true },
                "Address 1": { type: "string", required: true, maxLength:50 },
                "Address 2": { type: "string", required: false, maxLength:50 },
                "City": { type: "string", required: true },
                "State": { type: "string", required: true },
                "ZipCode": { type: "number", required: true },
                "Country": { type: "string", required: true },
                "RF Date": { type: "date", format: "YYYY-MM-DD", required: true },
                "Channel Name": { type: "string", required: false },
                "Channel Type": { type: "string", required: false },
                "Region": { type: "string", required: false },
                "Installation Type": { type: "string", required: true },
                "Device Provider": { type: "string", required: true },
                "Vehicle Type": { type: "string", required: true },
                "Vehicle Make": { type: "string", required: true },
                "Vehicle Model": { type: "string", required: false },
                "Model Details": { type: "string", required: false },
                "Vehicle Year": { type: "number", required: true },
                "Number Plate": { type: "string", required: true },
                "MOB": { type: "string", required: true },
                "Roaming": { type: "string", required: true },
                "Manual Odometer": { type: "string", required: true },
                "PIC Name": { type: "string", required: true },
                "PIC Address": { type: "string", required: true },
                "PIC Postcode": { type: "string", required: true },
                "PIC State": { type: "string", required: true },
                "PIC Contact No": { type: "string", required: true },
                "PIC Email": { type: "email", required: true },
                "Propose Installation Date": { type: "date", format: "YYYY-MM-DD", required: true },
                "Propose Installation Time": { type: "date", format: "HH:mm", required: true },
                "IMEI": { type: "string", required: false },
                "ICCID": { type: "string", required: false },
                "MSISDN": { type: "string", required: false },
                "MES ID": { type: "string", required: true },
                "Opportunity-ID": { type: "string", required: true },
            };
            this.totalImportRecords = jsonSheet.length;
            //loop all data rows
            for (let i = 0; i < jsonSheet.length; i++) {
                const eachRecord = jsonSheet[i];
                const tempRow: any = {};
                const columnsStatus: any = {};
                //customized the required rule based on installation type
                const currentRowInstallationType = eachRecord['Installation Type'].toLowerCase();
                if (currentRowInstallationType === installationTypeConstant.CUSTOM.desc.toLowerCase()) {
                    typeDef["MOB"].required = false;
                } else if (currentRowInstallationType === installationTypeConstant.PLUG_PLAY.desc.toLowerCase()) { // || currentRowInstallationType === installationTypeConstant.PLUG_PLAY_COURIER.desc.toLowerCase()) {
                    typeDef["Propose Installation Date"].required = false;
                    typeDef["Propose Installation Time"].required = false;
                }

                let vehicleMakeId; //for vehicle model listing checking use
                //loop each column of the row for validation
                for (const fieldName in typeDef) {                    
                    if (!Object.prototype.hasOwnProperty.call(typeDef, fieldName)) {
                        continue;
                    }
                    let fieldValue = eachRecord[fieldName];
                    const fieldRule = typeDef[fieldName];

                    //format date and time before validation due to some of the date passed in have time some don't have, standardized all before validate
                    if ((fieldName === "RF Date" || fieldName === "Propose Installation Date") && fieldValue) {
                        //valid then format to standardized, else leave it as original value
                        if (moment(fieldValue).isValid()) {
                            fieldValue = moment(fieldValue).format("YYYY-MM-DD");
                        }
                    } else if (fieldName === "Propose Installation Time" && fieldValue) {
                        const dateTime = fieldValue.split(" ");
                        if (dateTime.length > 1) {
                            const time = dateTime[1];
                            //get time only (till minute level)
                            fieldValue = time.substring(0, time.lastIndexOf(":"));
                        }
                    }

                    //normal validation
                    const response = this.validateFields(fieldValue, fieldRule, fieldName);
                    columnsStatus[fieldName + ' Valid'] = !response.hasViolation;
                    columnsStatus[fieldName + ' ErrMsg'] = response.errorMsg;

                    //below are those fields have specific value defined, check is it correct
                    if (!response.hasViolation) {
                        //channel type need special validation to check the value is it exist in db
                        
                        if(eachRecord[fieldName]!== undefined && StringUtil.isNotEmptyOrNull(fieldValue)){
                            if (fieldName === "Channel Type") {
                                    const channelType = salesChannelTypeList.find(eachType => {
                                        return eachRecord[fieldName].toLowerCase() === eachType.name.toLowerCase();
                                    });

                                    if (channelType) {
                                        //exist, store it as id, since db is use id to link
                                        fieldValue = channelType.id;
                                    } else {
                                        columnsStatus[fieldName + ' Valid'] = false;
                                        columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, fieldName);
                                    }


                            } else if (fieldName === "Region") {
                                const regionFound = regionList.find(region => {
                                    return eachRecord[fieldName].toLowerCase() === region.toLowerCase();
                                });

                                if (!regionFound) {
                                    columnsStatus[fieldName + ' Valid'] = false;
                                    columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, fieldName);
                                }
                            } else if (fieldName === "Installation Type") {
                                let found = false;
                                for (const type in installationTypeConstant) {
                                    if (installationTypeConstant[type].desc.toLowerCase() === eachRecord[fieldName].toLowerCase()) {
                                        fieldValue = installationTypeConstant[type].code;
                                        found = true;
                                        break;
                                    }
                                }

                                if (!found) {
                                    columnsStatus[fieldName + ' Valid'] = false;
                                    columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, fieldName);
                                }
                            } else if (fieldName === "Device Provider") {
                                const provider = deviceProviderList.find(eachProvider => {
                                    return eachRecord[fieldName].toLowerCase() === eachProvider.thirdPartyName.toLowerCase();
                                });

                                if (provider) {
                                    //exist, store it as id, since db is use id to link
                                    fieldValue = provider.id;
                                } else {
                                    columnsStatus[fieldName + ' Valid'] = false;
                                    columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, fieldName);
                                }
                            } else if (fieldName === "Vehicle Type") {
                                const typeFound = vehicleTypeList.find(type => {
                                    return eachRecord[fieldName].toLowerCase() === type.toLowerCase();
                                });

                                if (!typeFound) {
                                    columnsStatus[fieldName + ' Valid'] = false;
                                    columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, fieldName);
                                }
                            } else if (fieldName === "Vehicle Make") {
                                const vehicleMake = vehicleMakeList.find(make => {
                                    return eachRecord[fieldName].toLowerCase() === make.brandName.toLowerCase();
                                });

                                if (vehicleMake) {
                                    //exist, store it as id, since db is use id to link
                                    fieldValue = vehicleMake.id;
                                    vehicleMakeId = vehicleMake.id;
                                } else {
                                    columnsStatus[fieldName + ' Valid'] = false;
                                    columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, fieldName);
                                }
                            } else if (fieldName === "Vehicle Model") {
                                if (vehicleMakeId) {
                                    const vehicleModelResponse = await this.companyRegistrationService.getVehicleModelList(vehicleMakeId);
                                    const vehicleModelList = vehicleModelResponse.body.result;
                                    const vehicleModel = vehicleModelList.find(model => {
                                        return eachRecord[fieldName].toLowerCase() === model.modelName.toLowerCase();
                                    });

                                    if (vehicleModel) {
                                        //exist, store it as id, since db is use id to link
                                        fieldValue = vehicleModel.id;
                                    } else {
                                        columnsStatus[fieldName + ' Valid'] = false;
                                        columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, fieldName);
                                    }
                                }
                            }
                        }
                    }

                    tempRow[fieldName] = fieldValue;
                }
                //form error msg and either push to valid or invalid list
                this.batchRecordValidation(columnsStatus, tempRow, eachRecord);
            }
            if (this.invalidDataList.length > 0) {
                this.dataRecordsHasError = true;
            }
            return { status: true };
        }
        return { status: false, errorMessage: `${'Invalid File Contents'}` };
    }

    validateFields(fieldValue, fieldRule, fieldName) {
        const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        const response = {
            hasViolation: false,
            errorMsg: ''
        };
        // Check Required
        if (fieldRule.required) {
            if (!Array.isArray(fieldValue) && !StringUtil.isNotEmptyOrNull(fieldValue)) {
                response.hasViolation = true;
                response.errorMsg = Message.getMessage(Message.MESSAGE.IS_EMPTY.value, fieldName);
                return response;
            }
        }

        // Check Value Data Type and Format
        if (StringUtil.isNotEmptyOrNull(fieldValue)) {
            switch (fieldRule.type.toLowerCase()) {
                case "string": {
                    if(fieldRule.hasOwnProperty("maxLength") && fieldValue.length > fieldRule.maxLength){
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.MAXLENGTH.value, fieldName, fieldRule.maxLength);
                    }
                    break;
                }
                case "email": {
                    if(!emailPattern.test(String(fieldValue).toLowerCase())){
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.INVALID_EMAIL.value, fieldName)
                    }
                    break;
                }
                case "number": {
                    if (!NumberUtil.isNumeric(fieldValue)) {
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.NOT_NUMERIC.value, fieldName);
                    }
                    break;
                }
                case "date":
                case "datetime": {
                    const dateFormat = fieldRule.format || "";
                    if (!this.checkDateFormat(fieldValue, dateFormat)) {
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.INVALID_DATE.value, fieldName, fieldRule.format);
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }

        return response;
    }

    checkDateFormat(dateStr = "", format = "YYYY-MM-DD") {
        if (dateStr !== null && typeof dateStr != 'string') {
            dateStr = moment(dateStr).format(format);
        } else if (dateStr == null || dateStr.trim().length == 0) {
            return false;
        }
        const momDate = moment(dateStr, format, true);

        return momDate.isValid();
    }

    async batchRecordValidation(columnsStatus = null, tempRecordJSON = null, originalRecordJSON = null) {
        if (!columnsStatus || !tempRecordJSON || !originalRecordJSON) {
            return;
        }
        let isError: boolean = false;
        let errMsg: string = '';
        for (const eachStat in columnsStatus) {
            if (eachStat.includes('ErrMsg')) {
                continue;
            } else if (!columnsStatus[eachStat]) {
                const tempErrMsg = eachStat.replace('Valid', 'ErrMsg');
                isError = true;
                errMsg += columnsStatus[tempErrMsg] + ", ";
            }
        }
        errMsg = errMsg.substring(0, errMsg.lastIndexOf(", "));
        if (isError) {
            // Push original record with remarks to invalid data list (because new one replaced some string to ID)
            originalRecordJSON.remark = errMsg;
            this.invalidDataList.push(originalRecordJSON);
            return;
        }
        // Push new record to valid data list.
        this.validDataList.push(tempRecordJSON);
        return;
    }

    // batch import submit after validation
    async batchCreateDataEntry() {
        try {
            const formattedReqList = [];
            const companiesResponse = await this.companyRegistrationService.getCompanyList();
            const companiesList = companiesResponse.body.result.companyFormatResult;
            //loop every row and form request and group in 1 request if same company
            this.validDataList.map(eachEntry => {
                //form vehicle object of this current row
                const vehicleObj = {
                    "deviceProviderId": Number(eachEntry['Device Provider']),
                    "installationType": eachEntry['Installation Type'],
                    "vehicleType": eachEntry['Vehicle Type'],
                    "vehicleModelId": Number(eachEntry['Vehicle Model']),
                    "vehicleMakeId": Number(eachEntry['Vehicle Make']),
                    "vehicleModelDetails": eachEntry['Model Details'],
                    "vehicleYear": Number(eachEntry['Vehicle Year']),
                    "numberPlate": eachEntry['Number Plate'],
                    "imei": eachEntry['IMEI'],
                    "msisdn": eachEntry['MSISDN'],
                    "iccid": eachEntry['ICCID'],
                    "mesId": eachEntry['MES ID'],
                    "mob": eachEntry['MOB'] === 'Yes' ? 'Y' : 'N',
                    "roaming": eachEntry['Roaming'] === 'Yes' ? 'Y' : 'N',
                    "isManualOdometer": eachEntry['Manual Odometer'] === 'Yes' ? 'Y' : 'N',
                    "region": eachEntry['Region'],
                    "delivery": {
                        "picName": eachEntry['PIC Name'],
                        "address": eachEntry['PIC Address'],
                        "postcode": eachEntry['PIC Postcode'],
                        "state": eachEntry['PIC State'],
                        "contactNo": eachEntry['PIC Contact No'],
                        "email": eachEntry['PIC Email']
                    },
                    "installation": {
                        "picName": eachEntry['PIC Name'],
                        "address": eachEntry['PIC Address'],
                        "postcode": eachEntry['PIC Postcode'],
                        "state": eachEntry['PIC State'],
                        "contactNo": eachEntry['PIC Contact No'],
                        "email": eachEntry['PIC Email'],
                        "proposedDate": moment(eachEntry['Propose Installation Date']).format('YYYY-MM-DD'),
                        "proposedTime": eachEntry['Propose Installation Time']
                    }
                };

                //delete unnecessary property based on installation type
                const installType = eachEntry['Installation Type'];
                if (installType === installationTypeConstant.PLUG_PLAY.code) { // || installType === installationTypeConstant.PLUG_PLAY_COURIER.code) {
                    delete vehicleObj.installation;
                } else if (installType === installationTypeConstant.WIRED.code) {
                    delete vehicleObj.delivery;
                    delete vehicleObj.imei;
                    delete vehicleObj.msisdn;
                    delete vehicleObj.iccid;

                } else if (installType === installationTypeConstant.CUSTOM.code) {
                    delete vehicleObj.delivery;
                    delete vehicleObj.imei;
                    delete vehicleObj.msisdn;
                    delete vehicleObj.iccid;
                    delete vehicleObj.mob;
                }

                //find is the current company inserted previously, if have then just add vehicle into it else create a new company request
                let companyInsertedPreviously = false;
                for (let i = 0; i < formattedReqList.length; i++) {
                    const formattedReq = formattedReqList[i];
                    if (formattedReq.company.name === eachEntry['Company Name'] && formattedReq.company.email === eachEntry['Email']) {
                        companyInsertedPreviously = true;
                        formattedReq.vehicles.push(vehicleObj);
                        break;
                    }

                }

                //current company havent insert previously, create a new company request
                if (!companyInsertedPreviously) {
                    //form new formatted request
                    const formattedReq = {
                        cmssId:eachEntry['Opportunity-ID'],
                        rfDate: moment(eachEntry['RF Date']).format('YYYY-MM-DD'),
                        company: {},
                        channel: {
                            channelName: eachEntry['Channel Name'],
                            channelTypeId: eachEntry['Channel Type'],
                        },
                        vehicles: [vehicleObj]
                    };
                    //find is current company existed in db (existing company)
                    const companyFoundInDB = companiesList.find(company => {
                        return (company.name === eachEntry['Company Name'] && company.email === eachEntry['Email']);
                    });

                    if (companyFoundInDB) {
                        //existing company, assign it into company request
                        Object.assign(formattedReq.company, companyFoundInDB);
                    } else {
                        //new company, form company request
                        formattedReq.company = {
                            name: eachEntry['Company Name'],
                            brn: eachEntry['BRN'],
                            email: eachEntry['Email'],
                            address: {
                                address1: eachEntry['Address 1'],
                                address2: eachEntry['Address 2'],
                                city: eachEntry['City'],
                                state: eachEntry['State'],
                                zipCode: String(eachEntry['ZipCode']),
                                countryCode: eachEntry['Country']
                            }
                        };
                    }

                    formattedReqList.push(formattedReq);
                }
            });

            let errMessage = '';
            let allSuccess = true;
            for (let j = 0; j < formattedReqList.length; j++) {
                const result = await this.companyRegistrationService.addDevice(formattedReqList[j]);
                if (result && result.statusCode !== StatusCode.SUCCESS.code) {
                    allSuccess = false;
                    const companyName = formattedReqList[j].company.name;
                    errMessage += Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Company', companyName) + ", ";
                }
            }

            let msg = Message.getMessage(Message.MESSAGE.IMPORT_SUCCESS.value, 'Batch Data');
            if (!allSuccess) {
                errMessage = errMessage.substring(0, errMessage.lastIndexOf(", "));
                msg = errMessage;
            }

            await this.getAllDevices();
            this.cleanBatchImportData();
            this.snackBar.openGenericSnackBar(msg);
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        }
    }

    cleanBatchImportData() {
        this.file = null; // uploaded file
        this.batchErrorMessage = null; // Show any error while excel file validation is running
        this.fileName = ''; // Uploaded file name
        this.totalImportRecords = 0;
        this.isDownloadDone = false; // confirm client has download invalid file
        this.dataRecordsHasError = false;
        this.validDataList = []; // All valid data entries
        this.invalidDataList = []; // All invalid data entries
    }

    // generate template/invalid data entry records for download
    async generateExcelTemplate(isTemplate = true) {
        const now = moment();
        const header = [
            "Company Name", "BRN", "Email", "Address 1", "Address 2",
            "City", "State", "ZipCode", "Country", "RF Date",
            "Channel Name", "Channel Type", "Region", "Installation Type", "Device Provider",
            "Vehicle Type", "Vehicle Make", "Vehicle Model", "Model Details", "Vehicle Year",
            "Number Plate", "MOB", "Roaming", "Manual Odometer", "PIC Name",
            "PIC Address", "PIC Postcode", "PIC State", "PIC Contact No", "PIC Email",
            "Propose Installation Date", "Propose Installation Time", "IMEI", "ICCID", "MSISDN", "MES ID", "Opportunity-ID"
        ];
        const headerType = [];
        const excelWidthConfig = [
            { wch: 20.3}, { wch: 16.5 }, { wch: 18.2 }, { wch: 22 }, { wch: 21 },
            { wch: 11.9 }, { wch: 13.5 }, { wch: 8 }, { wch: 8.6 }, { wch: 10.4 },
            { wch: 20.3 }, { wch: 20.3 }, { wch: 20.3 }, { wch: 15.3 }, { wch: 20.3 },
            { wch: 20.3 }, { wch: 20.3 }, { wch: 20.3 }, { wch: 20.3 }, { wch: 11.4 },
            { wch: 12.7 }, { wch: 5.3 }, { wch: 8.5 }, { wch: 16.7 }, { wch: 15.9 },
            { wch: 17.8 }, { wch: 18.7 }, { wch: 15.3 }, { wch: 20.5 }, { wch: 15.6 },
            { wch: 22.7 }, { wch: 22.9 }, { wch: 11.5 }, { wch: 12.2 }, { wch: 14.4 }, { wch: 14.4 }, { wch: 13.9 }
        ];
        // const noteMsg = [
        //     ['Note: ', '1.', 'If continue using this document for submission please select mDrive(Default Template).'],
        //     ['', '2.', '*Columns is Required Fields'],
        //     ['', '3.', 'Please Convert Date/Time to string date by using =TEXT(<cellName>, "DD/MM/YYYY HH:mm:ss") in a new column.'],
        //     ['', '', 'After that copy new column value to the existring Date/Time column using paste Value(V) method. Then Remove New Column.'],
        //     ['', '', 'Paste Value(V) method: Right click cell > Paste Options > "Value(V)" or Icon come with 123.']
        // ];
        let data = [];
        // template data
        if (isTemplate) {
            data = [
                [
                    "Sample Company Name", "A123456", "sample@sample.com", "Sample Address 1",
                    "Sample Address 2", "Petaling Jaya", "Selangor", "65100", "Malaysia",
                    now.format("YYYY-MM-DD"), "Sample Channel Name",
                    "[[Refer 'canonical values' sheet (column name = 'Channel Type') for allowed values]]",
                    "[[Refer 'canonical values' sheet (column name = 'Region') for allowed values]]",
                    "Wired", "[[Refer 'canonical values' sheet (column name = 'Device Provider') for allowed values]]",
                    "[[Refer 'canonical values' sheet (column name = 'Vehicle Type') for allowed values]]",
                    "[[Refer 'canonical values' sheet (column name = 'Vehicle Make') for allowed values]]",
                    "[[Refer 'canonical values' sheet (column name = 'Vehicle Model', brand = vehicle make) for allowed values]]",
                    "Sample Model Details", "2012", "ABC123", "Yes", "No", "No", "Sample PIC Name",
                    "Sample PIC Address", "Sample PIC Postcode", "Sample PIC State", "Sample PIC Contact No", "Sample PIC Email",
                    now.format("YYYY-MM-DD"), now.format("HH:mm"), "Sample IMEI", "Sample ICCID",
                    "Sample MSISDN", "Sample MES ID", "Sample Opportunity ID"
                ]
            ];
            // data.push(noteMsg);
            const {
                salesChannelTypeList,
                deviceProviderList,
                vehicleMakeList,
                regionList,
                vehicleTypeList,
                vehicleModelMap
            } = await this.getCanonicalValues(true);
            this.fileExportService.exportExcelFile2(
                "Batch_Entry_Template", {
                    "data": {
                        data: this.fileExportService.mergeHeaderAndDataForExcel(data, header),
                        widthConfig: excelWidthConfig
                    },
                    "canonical values": {
                        data: this.fileExportService.mergeHeaderAndDataForExcel(
                            [
                                [],
                                ...salesChannelTypeList.map(x => ["Channel Type", x?.name]),
                                [],
                                ...deviceProviderList.map(x => ["Device Provider", x?.thirdPartyName]),
                                [],
                                ...vehicleMakeList.map(x => ["Vehicle Make", x?.brandName]),
                                [],
                                ...regionList.map(x => ["Region", x]),
                                [],
                                ...vehicleTypeList.map(x => ["Vehicle Type", x]),
                                [],
                                ..._.flatten(Object.entries(vehicleModelMap).map((x) => {
                                    const brandName = x[0], models: any[] = x[1];
                                    return models.map(model => [`Vehicle Model (make: ${brandName})`, `${model?.modelName}`]).concat([[]]);
                                }))
                            ], [
                                "Column Name", "Canonical Value"
                            ]
                        ),
                        widthConfig: [{ wch: 40 }, { wch: 40 }]
                    }
                });
        } else {
            const actualData = this.invalidDataList.slice(0);
            if (actualData && actualData.length >= 1) {
                data = [];
                //Add remark columns for invalid records
                header.push('Invalid Reason');
                excelWidthConfig.push({ wch: 90.5 });

                // noteMsg.map(eachArray => {
                //     // eachArray.push('');
                //     actualData.push(eachArray);
                // });
                // actualData.push(noteMsg);

                actualData.map(eachData => {
                    const temp: any = [];
                    for (const eachFieldValues in eachData) {
                        if (eachFieldValues != 'vehicleId') {
                            temp.push(eachData[eachFieldValues]);
                        }
                    }
                    data.push(temp);
                });
            }
            const excel: any = this.fileExportService.mergeHeaderAndDataForExcel(data, header);
            this.fileExportService.exportExcelFile(excel, "Batch_Entry_Template", headerType, excelWidthConfig);
        }
    }

    getHeaderRow(sheet) {
        const headers = [];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        let C, R = range.s.r; /* start in the first row */
        /* walk every column in the range */
        for (C = range.s.c; C <= range.e.c; ++C) {
            const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */

            let hdr = "UNKNOWN " + C; // <-- replace with your desired default
            if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

            headers.push(hdr);
        }
        return headers;
    }

    /**
     * Used to convert File -> bstr format
     */
    async getBstrValue() {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(this.file);
            let bstr: any = null;
            fileReader.onload = async (e) => {
                const arrayBuffer: any = fileReader.result;
                const data = new Uint8Array(arrayBuffer);
                const arr = new Array();
                for (let i = 0; i != data.length; i++) {
                    arr[i] = String.fromCharCode(data[i]);
                }
                bstr = arr.join("");
                resolve(bstr);
            };
        });
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async downloadUserGuideMenu() {
        console.log("toDo: download user guide");
    }

    private async getCanonicalValues(loadVehicleModel?: boolean) {
        const salesChannelTypeList = (await this.companyRegistrationService.getSalesChannelTypeList())?.body?.result || [];
        const deviceProviderList = (await this.companyRegistrationService.getDeviceProviderList())?.body?.result || [];
        const vehicleMakeList = (await this.companyRegistrationService.getVehicleMakeList())?.body?.result || [];
        const regionList = ["Northern", "Southern", "Eastern", "Central", "Sabah", "Sarawak"];
        const vehicleTypeList = ['Car', 'Lorry', 'Truck', 'Motorbike', 'Buggy', 'Others'];
        const vehicleModelMap: {[brandName: string]: any[]} = {};

        if (loadVehicleModel) {
            for (const vmk of vehicleMakeList) {
                const vehicleModel = (await this.companyRegistrationService.getVehicleModelList(vmk.id))?.body?.result;
                vehicleModelMap[vmk.brandName] = vehicleModel;
            }
        }

        return {salesChannelTypeList, deviceProviderList, vehicleMakeList, regionList, vehicleTypeList, vehicleModelMap};
    }
}
