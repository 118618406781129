/**
 * @param newPassword password object 
 * newPassword = { password: string, passwordStrength: string, confirmPassword: string}
 * @param passwordValidation password validation object 
 * passwordValidation = {
 *  hasError: Boolean,
 *  isMatch: Boolean,
 *  isEnoughLength: Boolean,
 *  isOneUpperChar: Boolean,
 *  isOneLowerChar: Boolean,
 *  isOneNumber: Boolean
 * }
 */
export function passwordValidator(newPassword, passwordValidation){
    if(newPassword.password.length > 7) {
      passwordValidation.isEnoughLength = true;
    } else {
      passwordValidation.isEnoughLength = false;
    }

    let isLowerChar = new RegExp(/[a-z]+/);
    if(isLowerChar.test(newPassword.password)) {
      passwordValidation.isOneLowerChar = true;
    } else {
      passwordValidation.isOneLowerChar = false;
    }

    let isUpperChar = new RegExp(/[A-Z]+/);
    if(isUpperChar.test(newPassword.password)) {
      passwordValidation.isOneUpperChar = true;
    } else {
      passwordValidation.isOneUpperChar = false;
    }

    let isNumber = new RegExp(/[0-9]+/);
    if(isNumber.test(newPassword.password)) {
      passwordValidation.isOneNumber = true;
    } else {
      passwordValidation.isOneNumber = false;
    }

    if(newPassword.confirmPassword === newPassword.password) {
      passwordValidation.isMatch = true;
    } else {
      passwordValidation.isMatch = false;
    }

    if(!passwordValidation.isMatch || !passwordValidation.isEnoughLength || !passwordValidation.isOneUpperChar || 
      !passwordValidation.isOneLowerChar || !passwordValidation.isOneNumber) {
        passwordValidation.hasError = true;
    } else {
        passwordValidation.hasError = false;
    }
    //when password is not set
    if(newPassword.password.length == 0) {
      passwordValidation.hasError= false,
      passwordValidation.isMatch= true,
      passwordValidation.isEnoughLength= true,
      passwordValidation.isOneUpperChar= true,
      passwordValidation.isOneLowerChar= true,
      passwordValidation.isOneNumber= true
    }

    return passwordValidation;
  }