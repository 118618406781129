import { MessageBusService, BusMessage } from './../../../../../_services/messagebus.service';
import { Component, OnInit, Input, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ToggleDirective } from './../../../../../directives/toggle/toggle.directive';
import { ClickOutsideManualDirective } from '../../../../../directives/click-outside/click-outside-manual.directive';

import * as moment from 'moment';
import * as NumberUtil from './../../../../../util/numberUtil';
import * as DateTimeUtil from "../../../../../util/dateTimeUtil";
import * as DomUtil from './../../../../../util/domUtil';
import * as StringUtil from './../../../../../util/stringUtil';
import * as DeviceStatus from '../../../../../constants/deviceStatus.constant';

import { TripsService } from "../../../../../_services/trips/trips.service";
import { SnackBarService } from '../../../../../_services/snackBar/snack-bar.service';

import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../../constants/responseStatusCode';

@Component({
    selector: 'tripday-item',
    templateUrl: './tripdayitem.component.html',
    styleUrls: ['./tripdayitem.component.scss']
})
export class TripdayitemComponent implements OnInit, OnDestroy, AfterViewInit {

    _tripday: any = null;
    _momdate = null; //parsed moment date
    deviceStatus: any = DeviceStatus;
    _hasFuelSensor: boolean = false;

    htmlId: string = "";
    notesFloaterText: string = "";
    previousTripNote: string = "";
    currentTrip: number = -1;

    expandUtilities = -1;

    //Declare toggle directives in component (to allow access from component)
    dateExpandToggle: ToggleDirective;
    tripInfoToggle: ToggleDirective[] = [];
    tagsToggle: ToggleDirective[] = [];
    notesToggle: ToggleDirective[] = [];
    clickOutsideEvent: { toggle: ToggleDirective, subscription: Subscription, directive: ClickOutsideManualDirective } = {
        toggle: null,
        subscription: null,
        directive: null
    };

    @Input('loadHighlightedTrip') onInitLoadHighlightedTrip: boolean = false;
    @Input('showViolatedTrip') onInitLoadViolatedTrip: boolean = false;
    @Input('singleViewMode') tripDayItemSingleViewMode;
    @Input('tagsList') tagsList;

    @Input()
    set tripday(tripday: any) {
        if (tripday == null) { return; }
        this.initTripDayItem(tripday);
    }

    get tripday() { return this._tripday; }

    @Input()
    set hasFuelSensor(hasFuelSensor: boolean) {
        if (hasFuelSensor == null) { return; }
        this._hasFuelSensor = hasFuelSensor;
    }

    msgBusSubscription: Subscription;
    constructor(private msgbus: MessageBusService,
        private el: ElementRef,
        private snackBar: SnackBarService,
        private tripsvc: TripsService) {

        //Use NULL as constructor parameter, no ChangeDetectorRef required for manual toggling
        this.dateExpandToggle = new ToggleDirective(null);
    }

    /* Message Controller*/
    async messageBusGetMessage(message: BusMessage) {
        switch (message.source) {
            case "triplist":
                await this.handleTripListMessage(message);
                break;
            case "tripRoute":
                await this.handleTripRouteMessage(message);
                break;
        }
    }

    /* Message Handler: Trip List */
    async handleTripListMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "loadHighlightedTrip": {
                // console.debug('(MsgBus)TripDayItemComponent: triplist -> loadHighlightedTrip (TripListComponent)');
                await this.loadHighlightedTrip();
                break;
            }
        }
    }

    /* Message Handler: Trip Route */
    async handleTripRouteMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "highlightItem":
                if (this.htmlId == message.data.selectedDate) {
                    // console.debug('(MsgBus)TripDayItemComponent: tripRoute -> highlightItem (TripDayItemComponent)');
                    this.currentTrip = message.data.selectedIndex;
                } else {
                    this.currentTrip = -1; // defocus trip
                    // this.closeAllInToggleList(); // close all floaters in this component
                }
                break;
            // case "closeFloaters":
            //     if (this.htmlId != message.data.selectedDate) {
            //         // console.debug('(MsgBus)TripDayItemComponent: tripRoute -> closeFloaters (TripDayItemComponent)');
            //         this.closeAllInToggleList(); // close all floaters in this component
            //     }
            //     break;
        }
    }

    async ngOnInit() {
        // let startTimeCounter = moment();

        // let endTimeCounter = moment();
        // let duration = moment.duration(endTimeCounter.diff(startTimeCounter)).asSeconds();
        // console.debug("Total Time Complete Display Single Day Trips' Tag & Note Result: " + duration + 's');
    }

    async ngAfterViewInit() {

        const busesPort = ["triplist", "tripRoute"];
        this.msgBusSubscription = this.msgbus.messagebus$.pipe(
            filter(message => busesPort.indexOf(message.source) != -1)
        ).subscribe(this.messageBusGetMessage.bind(this));
    }

    async ngOnDestroy() {
        this.destroyClickOutsideEvent();
        if (this.msgBusSubscription) {
            this.msgBusSubscription.unsubscribe();
        }
    }

    async initTripDayItem(tripday: any) {

        this._tripday = tripday;
        this._momdate = moment(tripday.date);

        this.htmlId = "tripDate_" + tripday.date;

        // parse some values first time to reduce load on double bindings
        this._tripday.dateLabel = DateTimeUtil.getDateLabel(this._tripday.date, "YYYY-MM-DD", "MMM DD, YYYY");
        this._tripday.gtIdleDurLabel = DateTimeUtil.genTimeDuration(Number.isInteger(tripday.gtIdleDur) ? tripday.gtIdleDur : tripday.gtIdleDur * 60, 'minute', 'hms') || '00m 00s';
        this._tripday.gtDistLabel = NumberUtil.formatFloat(tripday.gtDist, 1, '0') + 'km';

        this._tripday.inf.forEach((trip: any, index: number) => {

            // parse some values to reduce function data bindings
            trip.statusUpperedCase = trip.st.toUpperCase();
            trip.statusLabel = DeviceStatus.getDeviceStatusFromValue(trip.statusUpperedCase);

            if (trip.tripDet) {
                trip.tripTotalDistLabel = NumberUtil.formatFloat(trip.tripDet.tDist, 1, '0') + 'km';
                trip.tripDet.tFU = NumberUtil.formatFloat(trip.tripDet.tFU, 2, '0') + 'L';
                trip.tripGpsTimeStartLabel = DateTimeUtil.getDateLabel(trip.tripDet.gtsStart, "YYYY-MM-DD HH:mm:ss.SSS", "hh:mm A");
                trip.tripGpsTimeEndLabel = DateTimeUtil.getDateLabel(trip.tripDet.gtsEnd, "YYYY-MM-DD HH:mm:ss.SSS", "hh:mm A");
                trip.tripTotalIdlingDur = DateTimeUtil.genTimeDuration(Number.isInteger(trip.tripDet.tIdleDur) ? trip.tripDet.tIdleDur : trip.tripDet.tIdleDur * 60, 'minute', 'hms') || 0;
            }

            if (trip.eventDet) {
                trip.eventGpsTimeStartLabel = DateTimeUtil.getDateLabel(trip.eventDet.gtsStart, "YYYY-MM-DD HH:mm:ss.SSS", "hh:mm A");
            }

            // Generate Toggle Directives
            this.tripInfoToggle[index] = new ToggleDirective(null)
                .withData({ type: 'tripInfo', trip: trip });
            this.notesToggle[index] = new ToggleDirective(null)
                .withData({ type: 'notes', trip: trip });
            this.tagsToggle[index] = new ToggleDirective(null)
                .withData({ type: 'tags', trip: trip });
        });

        // auto expand highlighted date
        if (tripday.highlighted) {
            await this.dateExpandCollapse(true); //expand date
            if (this.onInitLoadHighlightedTrip || this.onInitLoadViolatedTrip) {
                this.currentTrip = parseInt(tripday.highlightedIndex);
                await this.loadHighlightedTrip();
            }
        }
    }

    async loadHighlightedTrip() {
        if (this.currentTrip != -1) {
            // console.debug('TripDayItemComponent: Loading highlighted trip route...');
            await this.dateExpandCollapse(true); //make sure date is expanded
            const index = this.currentTrip;
            this.getTripItemDetails(this._tripday.inf[index], index, null);
        }
    }

    toggleAlertTagAndNote(i: number, toggleType: string = null) {

        // toggleType = toggleType.toLowerCase();

        // let toggleListMap = {
        //     alert: this.tripInfoToggle,
        //     tag: this.tagsToggle,
        //     note: this.notesToggle
        // }
        // let toggleListToActive: ToggleDirective[] = [];
        // let toggleListToInactive: ToggleDirective[] = [];

        // toggleListToActive = toggleListMap[toggleType];
        // delete toggleListMap[toggleType];

        // for (let remainingToggleListName in toggleListMap) {
        //     toggleListToInactive = toggleListToInactive.concat(toggleListMap[remainingToggleListName]);
        // }

        // this.toggleOneInToggleListByIndex(toggleListToActive, i);
        // this.closeAllInToggleList(toggleListToInactive);

        if (toggleType === "Alert") {
            // console.debug("Toggle Violation");
            this.toggleOneInToggleListByIndex(this.tripInfoToggle, i);
            this.closeAllInToggleList(this.tagsToggle);
            this.closeAllInToggleList(this.notesToggle);
        } else if (toggleType === "Tag") {
            // console.debug("Toggle Tag");
            this.toggleOneInToggleListByIndex(this.tagsToggle, i);
            this.closeAllInToggleList(this.tripInfoToggle);
            this.closeAllInToggleList(this.notesToggle);
        } else if (toggleType === "Note") {
            // console.debug("Toggle Note");
            this.toggleOneInToggleListByIndex(this.notesToggle, i);
            this.closeAllInToggleList(this.tripInfoToggle);
            this.closeAllInToggleList(this.tagsToggle);
        }
    }

    toggleOneInToggleListByIndex(toggleList: ToggleDirective[], i: number) {
        // console.debug('toggleOneInToggleListByIndex: ');

        let skipRemaining: boolean = false;
        toggleList.forEach((eachToggle, index) => {
            if (skipRemaining || i !== index) {
                this.closeToggler(eachToggle);
            } else {
                skipRemaining = true;
                if (eachToggle.active) {
                    this.closeToggler(eachToggle);
                } else {
                    this.openToggler(eachToggle, i);
                }
            }
        });
    }

    createClickOutsideEventForToggler(toggler: ToggleDirective, index: number) {
        // console.debug('createClickOutsideEventForToggler: ');
        // console.debug(toggler);
        // console.debug('index: ' + index);

        this.destroyClickOutsideEvent();

        const tripId = toggler.data.trip.tripDet.tId;
        let floaterContentHtmlId = '-floater-content-' + tripId;
        let floaterTogglerHtmlId = '-floater-toggler-' + tripId;
        let floaterCloserFunction: Function = null;

        switch (toggler.data.type) {
            case 'tripInfo': {
                floaterContentHtmlId = 'tripinfo' + floaterContentHtmlId;
                floaterTogglerHtmlId = 'tripinfo' + floaterTogglerHtmlId;
                floaterCloserFunction = this.closeTripInfoFloater.bind(this);
                break;
            }
            case 'tags': {
                floaterContentHtmlId = 'tags' + floaterContentHtmlId;
                floaterTogglerHtmlId = 'tags' + floaterTogglerHtmlId;
                floaterCloserFunction = this.closeTagsFloater.bind(this);
                break;
            }
            case 'notes': {
                floaterContentHtmlId = 'notes' + floaterContentHtmlId;
                floaterTogglerHtmlId = 'notes' + floaterTogglerHtmlId;
                floaterCloserFunction = this.closeNotesFloater.bind(this);
                break;
            }
        }

        // Keep the toggleDirective as reference
        this.clickOutsideEvent.toggle = toggler;

        // Bind the close outside directive
        const trip = JSON.parse(JSON.stringify(toggler.data.trip)); // deep clone
        const togglerElement = this.el.nativeElement.querySelector('#' + floaterContentHtmlId);

        const newOutsideClickDirective: ClickOutsideManualDirective = new ClickOutsideManualDirective(new ElementRef(togglerElement));
        newOutsideClickDirective.excludedOutsideHtmlIds = [floaterTogglerHtmlId];
        newOutsideClickDirective.init();
        this.clickOutsideEvent.subscription = newOutsideClickDirective.clickOutside.subscribe($event => {
            floaterCloserFunction(trip, index, $event);
        });

        this.clickOutsideEvent.directive = newOutsideClickDirective;
    }

    destroyClickOutsideEvent(toggler: ToggleDirective = null) {
        try {
            // Unsubscribe and remove previous clickOutside event
            if (!toggler || toggler == this.clickOutsideEvent.toggle) {

                // Destroy events
                if (this.clickOutsideEvent.subscription) {
                    this.clickOutsideEvent.subscription.unsubscribe();
                }
                this.clickOutsideEvent.directive.destroy();
                delete this.clickOutsideEvent.directive;

                this.clickOutsideEvent.toggle = null;
                this.clickOutsideEvent.subscription = null;
                this.clickOutsideEvent.directive = null;
            }
        } catch (err) {
            // ignore
        }
    }

    closeAllInToggleList(toggleList: ToggleDirective[] = null) {
        if (toggleList == null) {
            toggleList = this.tripInfoToggle.concat(this.tagsToggle, this.notesToggle);
        }
        toggleList.forEach(eachToggle => {
            this.closeToggler(eachToggle);
        });
    }

    openToggler(toggler: ToggleDirective, index: number) {
        if (!toggler.active) {
            // console.debug('openToggler: ');
            toggler.setActive();
            this.createClickOutsideEventForToggler(toggler, index); // add click outside event to floater
            // this.closeAllFloatersInOtherDates(); // close floaters in sibling tripDayItemComponents
        }
    }

    closeToggler(toggler: ToggleDirective) {
        if (toggler.active) {
            // console.debug('closeToggler: ');
            toggler.setInactive();
            this.destroyClickOutsideEvent(toggler);
        }
    }

    closeTripInfoFloater(trip, i, clickEvent?: Event): void {
        // let tripId = trip.tripDet.tId;
        // let floaterContentTagId = "#tripinfo-floater-content-" + tripId;
        // let excludedHtmlIds = [
        //     "tripinfo-floater-toggler",
        //     "tripinfo-floater-content-" + tripId
        // ];

        //Avoid floater auto-closed when clicked on toggler
        // if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
        //     return;
        // }

        // let tripInfoDom = this.el.nativeElement.querySelector(floaterContentTagId);
        // if (Boolean(tripInfoDom) && tripInfoDom.className.indexOf("--active") >= 0) {
        //     // console.debug('TripDayItemComponent: Closing tripinfo');
        //     this.tripInfoToggle[i].setInactive();
        // }

        // if (this.tripInfoToggle[i].active) {
        //     this.tripInfoToggle[i].setInactive();
        // }
        // console.debug('TripDayItemComponent: Closing tripinfo');
        this.closeToggler(this.tripInfoToggle[i]);
    }

    closeTagsFloater(trip, i, clickEvent?: Event): void {
        // let tripId = trip.tripDet.tId;
        // let floaterContentTagId = "#tags-floater-content-" + tripId;
        // let excludedHtmlIds = [
        //     "tags-floater-toggler",
        //     "tags-floater-content-" + tripId
        // ];

        //Avoid floater auto-closed when clicked on toggler
        // if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
        //     return;
        // }

        // let tagsDom = this.el.nativeElement.querySelector(floaterContentTagId);
        // if (Boolean(tagsDom) && tagsDom.className.indexOf("--active") >= 0) {
        //     // console.debug('TripDayItemComponent: Closing tags');
        //     this.tagsToggle[i].setInactive();
        // }

        // if (this.tagsToggle[i].active) {
        //     this.tagsToggle[i].setInactive();
        // }
        // console.debug('TripDayItemComponent: Closing tags');
        this.closeToggler(this.tagsToggle[i]);
    }

    closeNotesFloater(trip, i, clickEvent?: Event): void {
        // let tripId = trip.tripDet.tId;
        // let floaterContentTagId = "#notes-floater-content-" + tripId;
        // let excludedHtmlIds = [
        //     "notes-floater-toggler",
        //     "notes-floater-content-" + tripId
        // ];

        // //Avoid floater auto-closed when clicked on toggler
        // if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
        //     return;
        // }

        // let notesDom = this.el.nativeElement.querySelector(floaterContentTagId);
        // if (Boolean(notesDom) && notesDom.className.indexOf("--active") >= 0) {
        //     // console.debug('TripDayItemComponent: Closing notes');
        //     this.notesToggle[i].setInactive();
        //     this.resetTripNote(trip);
        // }

        // if (this.notesToggle[i].active) {
        // this.notesToggle[i].setInactive();
        // this.resetTripNote(trip);
        // }
        // console.debug('TripDayItemComponent: Closing notes');
        this.closeToggler(this.tagsToggle[i]);
        this.resetTripNote(trip);
    }

    getTripItemDetails(trip, index, clickEvent?: Event) {
        if (!trip || !trip.tripDet) {
            return;
        }

        const tripId = trip.tripDet.tId;
        const excludedHtmlIds = [
            "tripinfo-floater-toggler-" + tripId,
            "tripinfo-floater-content-" + tripId,
            "tags-floater-toggler-" + tripId,
            "tags-floater-content-" + tripId,
            "notes-floater-toggler-" + tripId,
            "notes-floater-content-" + tripId,
            "expand-floater-toggler"
        ];

        //Prevent loading trip on certain clicking areas
        if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
            // console.debug('Mouseclick: skip reloading trip');
            return;
        }
        if (clickEvent) {
            (clickEvent.target as HTMLElement).blur();
            const element = clickEvent.target as Element
            const parent = element.closest('button.trip');
            if (!parent.classList.contains('--isTrip')) {
                return;
            }
        }

        // if (clickEvent) {
        this.msgbus.sendMessage(new BusMessage(
            "tripDayItem",
            "singleViewModeToggle",
            {
                active: true
            }
        ));
        // }

        // if (trip !== undefined && trip !== null && trip.tripDet) {
        // let tripId = String(trip.tripDet.tId);
        const startTime = trip.tripDet.gtsStart;
        const endTime = trip.tripDet.gtsEnd;
        const vehicleId = trip.tripDet.vId;
        const driverId = trip.tripDet.dId;
        //Convert Date Time to Millisecond
        // let startDateTimeMs = String(moment(startTime, "YYYY-MM-DD HH:mm:ss.SSS").valueOf());
        // let endDateTimeMs = String(moment(endTime, "YYYY-MM-DD HH:mm:ss.SSS").valueOf());

        this.msgbus.sendMessage(new BusMessage(
            "tripDayItem",
            "tripRoute",
            {
                tripId: String(tripId),
                startTime: /* startDateTimeMs, */startTime,
                endTime: /* endDateTimeMs, */endTime,
                vehicleId: vehicleId,
                driverId: driverId,
                // duration: trip.tripDet.tIdle > 0 ? this.dateTimeUtil.genTimeDuration(trip.tripDet.tIdle, 'minute', 'hms') : '0s',
                sender: "TripDayItemComponent"
            }
        ));

        this.highlightCorrectTrip(index);
        // }
    }

    // closeAllFloatersInOtherDates() {
    //     this.msgbus.sendMessage(new BusMessage(
    //         "tripRoute",
    //         "closeFloaters",
    //         {
    //             selectedDate: this.htmlId
    //         }
    //     ));
    // }

    highlightCorrectTrip(index: number) {
        this.currentTrip = index;
        this.msgbus.sendMessage(new BusMessage(
            "tripRoute",
            "highlightItem",
            {
                selectedDate: this.htmlId,
                selectedIndex: index
            }
        ));
    }

    async setTripTag(trip, tagId) {
        const tripId = trip.tripDet.tId;
        const vehicleId = trip.tripDet.vId;
        try {
            const setTripTagResult = await this.tripsvc.setTripTag(tripId, vehicleId, tagId);
            if (setTripTagResult.statusCode === ResponseStatusCode.SUCCESS.code) {
                if (tagId !== -1) {
                    trip.tripDet.tag = this.tagsList.find(eachTag => {
                        if (tagId === eachTag.tagId) {
                            return eachTag;
                        }
                    });
                    trip.tripDet.tag.fontColor = this.brightnessByColor(trip.tripDet.tag.colorCode);
                } else {
                    trip.tripDet.tag = {
                        tagId: -1,
                        tagName: "",
                        desc: "",
                        colorCode: ""
                    };
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        }
    }

    async getTripTagByTripId(tripId) {
        let tripTagResult: any = {};
        try {
            tripTagResult = await this.tripsvc.getTripTagByTripId(tripId);
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        }

        return tripTagResult;
    }

    async setTripNote(trip, remove = false) {
        const tripId = trip.tripDet.tId;
        const vehicleId = trip.tripDet.vId;
        let tripNote = trip.tripDet.note;
        if (remove) {
            tripNote = '';
        }

        try {
            const setTripNoteResult = await this.tripsvc.setTripNote(tripId, vehicleId, tripNote);
            if (setTripNoteResult && setTripNoteResult.statusCode == ResponseStatusCode.SUCCESS.code) {
                trip.tripDet.note = tripNote; // in case delete
                trip.tripDet.previousNote = tripNote;
            } else {
                trip.tripDet.note = trip.tripDet.previousNote;
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        }
    }

    resetTripNote(trip) {
        trip.tripDet.note = trip.tripDet.previousNote;
    }

    // async getTripNote(tripId) {
    //     let tripNoteResult: any = {};
    //     try {
    //         tripNoteResult = await this.tripsvc.getTripNote(tripId);
    //     } catch (err) {
    //         this.snackBar.openStandardizedErrorSnackBar(err);
    //     }

    //     return tripNoteResult
    // }

    async getTagAndNote() {
        const tripIds = [];
        const tripInfoList = [];
        const vehicleId = this.tripday.vId;
        const companyId = this.tripday.cId;
        for (let i = 0; i < this.tripday.inf.length; i++) {
            const tripInfo = this.tripday.inf[i];

            if (tripInfo.tripDet) {
                tripInfoList.push(tripInfo);
                tripIds.push(tripInfo.tripDet.tId);

            }
        }

        if (tripIds.length == 0) {
            return;
        }

        try {
            const tripsTagsAndNotesResult = await this.tripsvc.getTripsTagsAndNotes(tripIds, vehicleId, companyId);
            if (tripsTagsAndNotesResult.statusCode === ResponseStatusCode.SUCCESS.code) {
                const body = tripsTagsAndNotesResult.body;
                for (let i = 0; i < tripInfoList.length; i++) {
                    const tripId = tripInfoList[i].tripDet.tId;
                    for (let j = 0; j < body.length; j++) {
                        if (tripId == body[i].tripId) {
                            tripInfoList[i].tripDet.tag = body[i].tripTag;
                            tripInfoList[i].tripDet.tag.tagName = tripInfoList[i].tripDet.tag.tagName == "" ? "" : StringUtil.toTitleCase(tripInfoList[i].tripDet.tag.tagName);
                            tripInfoList[i].tripDet.tag.fontColor = this.brightnessByColor(tripInfoList[i].tripDet.tag.colorCode);
                            tripInfoList[i].tripDet.note = body[i].tripNote;
                            tripInfoList[i].tripDet.previousNote = body[i].tripNote;
                        }
                    }
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        }
    }

    brightnessByColor(color) {
        color = "" + color;
        const isHEX = color.indexOf("#") == 0;
        const isRGB = color.indexOf("rgb") == 0;
        let red = 0;
        let green = 0;
        let blue = 0;
        if (isHEX) {
            const m = color.substr(1).match(color.length == 7 ? /(\S{2})/g : /(\S{1})/g);
            if (m) {
                red = parseInt(m[0], 16);
                green = parseInt(m[1], 16);
                blue = parseInt(m[2], 16);
            }
        }
        if (isRGB) {
            const m = color.match(/(\d+){3}/g);
            if (m) {
                red = m[0];
                green = m[1];
                blue = m[2];
            }
        }
        if (typeof red != "undefined") {
            const brightness = ((red * 299) + (green * 587) + (blue * 114)) / 1000;
            if (brightness / 255 * 100 < 50) {
                return "#ffffff";
            } else {
                return "#000000";
            }
        }
    }

    async dateExpandCollapse(setActiveBool = null) {
        if (setActiveBool == null) {
            this.dateExpandToggle.toggleActive();
        } else if (setActiveBool) {
            if (this.dateExpandToggle.active) {
                return; // already active, ignore
            }
            this.dateExpandToggle.setActive();
        } else {
            this.dateExpandToggle.setInactive();
        }

        if (this.dateExpandToggle.active) {
            await this.getTagAndNote();
        }
    }

    showUtilities(index) {
        if (this.expandUtilities === index) {
            this.expandUtilities = -1;
        } else {
            this.expandUtilities = index;
        }
    }
}
