import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
// import { Router } from '@angular/router';
import { UserService } from './../../../../_services/user/user.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { PopupService } from '../../../common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';

import * as DomUtil from './../../../../util/domUtil';

import * as Message from './../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

@Component({
    selector: 'app-edit-information',
    templateUrl: './edit-information.component.html',
    styleUrls: ['./edit-information.component.scss']
})
export class EditInformationComponent implements OnInit {
    @ViewChild('page_spinner',{static:true}) pageSpinner: SpinnerComponent;
    accountInfo: any = {};
    message = Message;
    emailChange = false;
    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private userService: UserService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private snackBar: SnackBarService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        this.pageSpinner.show();
        try {
            const res = await this.userService.getAccountInfo();
            this.accountInfo = res.body;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
        DomUtil.autoFocusForm(this.el);
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    onChangeEmail($event) {
        this.emailChange = true;
    }

    async updateAccountInfo() {
        this.pageSpinner.show();
        try {
            let updatedFields = {};
            if (this.emailChange) {
                updatedFields = {
                    userId: this.accountInfo.id,
                    firstName: this.accountInfo.firstName,
                    lastName: this.accountInfo.lastName,
                    telNo: this.accountInfo.telNo,
                    email: this.accountInfo.email
                };
            } else {
                updatedFields = {
                    userId: this.accountInfo.id,
                    firstName: this.accountInfo.firstName,
                    lastName: this.accountInfo.lastName,
                    telNo: this.accountInfo.telNo
                };
            }

            const result = await this.userService.updateAdminDetails(updatedFields);
            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Account Information', this.accountInfo.firstName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Account Information', this.accountInfo.firstName);
            }
            this.snackBar.openGenericSnackBar(msg);
            this.emailChange = false;

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateToHomePage() {
        this.routerProxyService.navigateToHomePage();
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

}
