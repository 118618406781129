import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import * as StringUtil from './../../util/stringUtil';
import * as DomUtil from './../../util/domUtil';
// import * as LocalStorageUtil from './../../util/localStorageUtil';
// import * as ObjectUtil from './../../util/objectUtil';
// import * as ErrorUtil from './../../util/errorUtil';

// import * as os from 'os';
// import * as ip from 'ip';
// import * as moment from 'moment';

// const mapStackTrace = require('sourcemapped-stacktrace-node').default;

const operationSupportEmail = environment.appConfig.errorHandling.errorRecipientList.join(',');
const operationSupportEmailCc = environment.appConfig.errorHandling.errorCcList.join(',');

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private http: HttpClient,
        private platform: Platform
    ) { }

    // async sendPlainEmailSES(recipient, ccRecipient, subject, message) {
    //     const path = environment.mtntApiEndpoint + '/email/sendPlainEmailSES';
    //     const body = { 'recipient': recipient, 'ccRecipient': ccRecipient, 'subject': subject, 'message': message };
    //     // let result: any;
    //     try {
    //         await this.http.post(path, body).toPromise();
    //     } catch (ex) {
    //         // console.debug(ex);
    //     }
    //     return;
    // }

    async sendEmailViaPostfix(subject: string, message: string) {
        const path = environment.mtntApiEndpoint + '/email/sendEmailPostfix';

        try {
            const body = {
                recipient: operationSupportEmail,
                ccRecipient: operationSupportEmailCc,
                subject: subject + (DomUtil.isMobileApp(this.platform) ? ' (mobile app)' : ''),
                message: message
            };

            // DO NOT SEND MOBILE APP ERRORS TO TNT-DEVOPS
            if (DomUtil.isMobileApp(this.platform)) {
                const newRecipients: string[] = StringUtil.getListFromDelimitedText(body.recipient).filter(item => item != 'TNT-DEVOPS@maxis.com.my');
                body.recipient = newRecipients.join();
            }

            console.debug('Email details:');
            console.debug(body);

            await this.http.post(path, body).toPromise();
            console.debug('Email sent. Subject: ' + body.subject);
        } catch (ex) {
            // console.debug(ex);
        }
        return;
    }

    async sendEmailViaPostfixWithAttachment(subject: string, message: string, fileName: string, attachment: any) {
        const path = environment.mtntApiEndpoint + '/email/sendEmailPostfix';

        try {
            const body = {
                recipient: operationSupportEmail,
                ccRecipient: operationSupportEmailCc,
                subject: subject + (DomUtil.isMobileApp(this.platform) ? ' (mobile app)' : ''),
                message: message
            };

            if (fileName && attachment) {
                body['fileName'] = fileName;
                body['attachment'] = attachment;
            }

            // DO NOT SEND MOBILE APP ERRORS TO TNT-DEVOPS
            if (DomUtil.isMobileApp(this.platform)) {
                const newRecipients: string[] = StringUtil.getListFromDelimitedText(body.recipient).filter(item => item != 'TNT-DEVOPS@maxis.com.my');
                body.recipient = newRecipients.join();
            }

            console.debug('Email details:');
            console.debug(body);

            await this.http.post(path, body).toPromise();
            console.debug('Email sent. Subject: ' + body.subject);
        } catch (ex) {
            // console.debug(ex);
        }
        return;
    }

}
