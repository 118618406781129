<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<app-popup #logoutConfirmationPopup="popup" class="cm-popups" [classes]="'--slim'">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Log Out
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            Confirm proceed to log out?
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="confirmLogout();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
