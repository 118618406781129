import { Directive, ElementRef, OnInit, Input, Renderer2 } from '@angular/core';

import { AuthService } from './../../_services/auth/auth.service';
import { AccessControlService } from './../../_services/access-control/access-control.service';

@Directive({ selector: '[isVisibleElement]' })

export class RoleAccessDirective implements OnInit {
    constructor(
        private authService: AuthService,
        private accessControlService: AccessControlService,
        private renderer: Renderer2,
        private elRef: ElementRef
    ) { }

    path: string;

    @Input() set isVisibleElement(path: string) {
        this.path = path;
    }

    async ngOnInit() {
        // Check Page Authentication
        if (this.authService.isAuthenticated()) {
            let hasRequiredPermission: boolean = await this.accessControlService.hasPermission(this.path);

            if (hasRequiredPermission) {
                this.renderer.setStyle(this.elRef.nativeElement, 'visibility', 'visible');
            }
            else {
                this.renderer.setStyle(this.elRef.nativeElement, 'visibility', 'hidden');
            }
        } else {
            this.renderer.setStyle(this.elRef.nativeElement, 'visibility', 'hidden');
        }
    }

}