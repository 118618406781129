import { Injectable } from '@angular/core';
import { RouterProxyService } from '../router-proxy/router-proxy.service';
import { AccessControlService } from '../access-control/access-control.service';
import { CampaignServiceService } from '../campaign/campaign-service.service';
import * as moment from 'moment';
import { AuthService } from '../auth/auth.service';
import { TokenService } from '../token/token.service';
import { USER_ROLE } from '../../constants/userRole';

@Injectable({
    providedIn: 'root'
})
export class PopupCampaignService {

    constructor(
        private routerProxyService: RouterProxyService,
        private accessControlService: AccessControlService,
        private campaignService: CampaignServiceService,
        private authService: AuthService,
        private tokenService: TokenService
    ) {

    }

    async getUnviewedCampaigns() {
        let hasUnviewedCamapaign = false;
        const pageUrl = await this.routerProxyService.getCurrentPath();
        const pageAccessId = await this.accessControlService.getPageAccessId(pageUrl);
        let unviewedCampaigns = [];
        const currentUser = this.authService.getCurrentUserDetails();
        const administratorUserExist = this.tokenService.isAdministratorUserTokenExist();
        //if super super admin managing account, dont show campaign popup, else original super admin user logged in would not see
        if (pageAccessId && !(administratorUserExist && currentUser && currentUser.role === USER_ROLE.SUPER_SUPER_ADMIN.value)) {
            const result: any = await this.campaignService.getUnviewedCampaign(moment().format('YYYY-MM-DD'), pageAccessId);
            unviewedCampaigns = result.body;
            if (unviewedCampaigns.length > 0) {
                hasUnviewedCamapaign = true;
            }
        }
        return { hasUnviewedCamapaign: hasUnviewedCamapaign, unviewedCampaigns: unviewedCampaigns };
    }
}
