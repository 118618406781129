/**
 * Left pad numeric value with leading characters
 * @param number (number) number to be padded
 * @param length (number) length of final output
 * @param paddingChar (string) character to left padding
 * @return (number) concatination of year and week
 */
export function lpad(number: number = 0, length: number = 0, paddingChar: string): string {
    let s: string = number.toString();
    while (s.length < length) {
        s = paddingChar + s;
    }
    return s;
}

/**
 * Check input is integer
 * @param x (string) input to be checked
 * @return (boolean) true = pass validation
 */
export function isInteger(x) {
    const y = parseInt(x, 10);
    return !isNaN(y) && x == y && x.toString() == y.toString();
}

/**
 * Check input is float
 * @param x (string) input to be checked
 * @return (boolean) true = pass validation
 */
export function isFloat(x) {
    const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(x)) {
        return false;
    }
    return !isNaN(parseFloat(x));
}

/**
 * Check input is Number
 * @param n (any) input to be checked
 * @return (boolean) true = pass validation
 */
export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Round off float value to specified number of decimal places
 * @param n number to be round off
 * @param p precision (number of decimal places)
 * @return (number) value after rounding off
 */
export function roundOff(n: number, p: number): number {
    try {
        if (isNumeric(n)) {
            const n1 = n * Math.pow(10, p + 1);
            const n2 = Math.floor(n1 / 10);
            if (n1 >= (n2 * 10 + 5)) {
                return (n2 + 1) / Math.pow(10, p);
            }
            return n2 / Math.pow(10, p);
        } else {
            return n;
        }
    } catch (err) {
        return 0;
    }
}

/**
 * Converts float value into string, rounded off to specified number of decimal places
 * @param n number to be round off
 * @param p precision (number of decimal places)
 * @param textIfNullOrZero string to display if input value is null or 0. Default '-' for null & unset for zeroes
 * @return (string) value after rounding off
 */
export function formatFloat(n: number, p: number, textIfNullOrZero: string = null): string {
    if (isNumeric(n)) {
        const formatted = roundOff(n, p).toFixed(p);
        if (!textIfNullOrZero) {
            return formatted;
        } else {
            return Number(formatted) == 0 ? textIfNullOrZero : formatted;
        }
    } else {
        return textIfNullOrZero || '-';
    }
}

/**
 * Converts hours with fraction into hour:minute format
 * @param duration fractional number value
 * @param textIfNull string to display if empty duration
 * @return (string) formatted text in HH:mm, or specified textIfNull
 */
export function formatHourMinute(duration: any = 0, textIfNull: string = "00:00", format: string = 'HH:MM'): string {

    if (!isNumeric(duration)) {
        return textIfNull;
    }

    const fractionalHour = duration % 1;
    let h: any = parseInt(duration).toString();
    let m = formatFloat(Math.abs(fractionalHour) * 60, 0);
    if (m == "60") {
        m = "00";
        h++;
    }
    const hh = h.length < 2 ? `0${h}` : h;
    const mm = m.length < 2 ? `0${m}` : m;

    const result = format
        .replace('HH', hh)
        .replace('MM', mm)
        .replace('H', h)
        .replace('M', m);

    return result;
}

export function nullIfUndefined(val: any) {
    return val === undefined ? null : val;
}

export function numberOrZero(val: any) {
    return (isNumeric(val) ? parseFloat(val) : 0);
}

export function numberOrNull(val: any) {
    return (isNumeric(val) ? parseFloat(val) : null);
}

// return undefined if input is not a number (zero is number)
export function numberOrUndefined(val: any) {
    return (isNumeric(val) ? parseFloat(val) : undefined);
}

export function numberOrDash(val: any) {
    return (isNumeric(val) ? parseFloat(val) : '-');
}

export function int2float(x: any) {
    if (isInteger(x)) {
        return Number(x);
    } else if (isFloat(x)) {
        return x;
    }
    return null;
}

export function getDurationInHhMmSs(milliseconds: number) {
    return {
        second: milliseconds / 1000 % 60,
        minute: milliseconds / (60 * 1000) % 60,
        hour: milliseconds / (60 * 60 * 1000)
    };
}

// Convert numbers to words for South Asian numbering system
// https://github.com/salmanm/num-words

const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/;

export function numWords(num) {
    if (!isInteger(num)) {
        return '';
    }
    if ((num = num.toString()).length > 9) {
        throw new Error('overflow'); // Does not support converting more than 9 digits yet
    }

    const n = ('000000000' + num).substr(-9).match(regex);
    if (!n) { return; }

    let str = '';
    str += (parseInt(n[1]) != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (parseInt(n[2]) != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (parseInt(n[3]) != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (parseInt(n[4]) != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (parseInt(n[5]) != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';

    return str.trim();
}
