<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Set Driving Limit
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #drivingLimitForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Step 1: Driving Limit Type
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__input set-limit-field">
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isSpeedLimitOff" name="isSpeedLimitOff" #isSpeedLimitOnOff="ngModel">

                                                Speed Limit (km/h):
                                            </mat-slide-toggle>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input id="SpeedLimit" type="text" class="field__input" [class.--disabled]="!isSpeedLimitOff" [disabled]="!isSpeedLimitOff" [required]="isSpeedLimitOff ? '' : null" name="speedLimit" [(ngModel)]="speedLimit" OnlyNumber OnlyInteger>
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isAllowedTimeOff" name="isAllowedTimeOff" #isAllowedTimeOnOff="ngModel">

                                                Allowed Driving Hours (hh:mm):
                                            </mat-slide-toggle>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field-group__groups-inner field-group__time">
                                                <div class="field-group__time-inner">
                                                    <label class="select select-time">
                                                        <select class="select__input" [(ngModel)]="allowedTimeFromHour" name="allowedTimeFromHour" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                                            <option value="" selected disabled hidden>HH</option>
                                                            <option *ngFor="let hour of timeSelector.hour" [value]="hour">{{hour}}</option>
                                                        </select>
                                                    </label>
                                                </div>

                                                <div class="field-group__time-inner --no-padding">
                                                    :
                                                </div>

                                                <div class="field-group__time-inner">
                                                    <label class="select select-time">
                                                        <select class="select__input" [(ngModel)]="allowedTimeFromMinute" name="allowedTimeFromMinute" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                                            <option value="" selected disabled hidden>MM</option>
                                                            <option *ngFor="let minute of timeSelector.minute" [value]="minute">{{minute}}</option>
                                                        </select>
                                                    </label>
                                                </div>

                                                <div class="field-group__time-inner">
                                                    <label class="select select-time">
                                                        <select class="select__input" [(ngModel)]="allowedTimeFromAMPM" name="allowedTimeFromAMPM" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="field-group__groups-inner">
                                                -
                                            </div>

                                            <div class="field-group__groups-inner field-group__time">
                                                <div class="field-group__time-inner">
                                                    <label class="select select-time">
                                                        <select class="select__input" [(ngModel)]="allowedTimeToHour" name="allowedTimeToHour" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                                            <option value="" selected disabled hidden>HH</option>
                                                            <option *ngFor="let hour of timeSelector.hour" [value]="hour">{{hour}}</option>
                                                        </select>
                                                    </label>
                                                </div>

                                                <div class="field-group__time-inner --no-padding">
                                                    :
                                                </div>

                                                <div class="field-group__time-inner">
                                                    <label class="select select-time">
                                                        <select class="select__input" [(ngModel)]="allowedTimeToMinute" name="allowedTimeToMinute" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                                            <option value="" selected disabled hidden>MM</option>
                                                            <option *ngFor="let minute of timeSelector.minute" [value]="minute">{{minute}}</option>
                                                        </select>
                                                    </label>
                                                </div>

                                                <div class="field-group__time-inner">
                                                    <label class="select select-time">
                                                        <select class="select__input" [(ngModel)]="allowedTimeToAMPM" name="allowedTimeToAMPM" [class.--disabled]="!isAllowedTimeOff" [disabled]="!isAllowedTimeOff" [required]="isAllowedTimeOff ? '' : null">
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isDailyMileageLimitOff" name="isDailyMileageLimitOff" #isSpeedLimitOnOff="ngModel">

                                                Daily Mileage (km):
                                            </mat-slide-toggle>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [class.--disabled]="!isDailyMileageLimitOff" [disabled]="!isDailyMileageLimitOff" name="dailyMileageLimit" [(ngModel)]="dailyMileageLimit" [required]="isDailyMileageLimitOff ? '' : null" OnlyNumber OnlyInteger>
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-vehicle-selector #vehicleAssign [title]="'Step 2: Assign Vehicles'"></app-vehicle-selector>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="drivingLimitForm.invalid || vehicleAssign.getSelectedCount() == 0" (click)="showPopup(saveDrivingLimitPopup)">
                                CREATE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToDrivingLimitView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #saveDrivingLimitPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to set new driving limit
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="setDrivingLimit();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
