import { Component, OnInit, ViewChild } from '@angular/core';

//service
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { PopupService } from '../../../common/popup/popup.service';
import { NotificationService } from './../../../../_services/notification/notification.service';

//selector
import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';
import { DriverTagSelectorComponent } from './../../driver-tag-selector/driver-tag-selector.component';

//util
import * as LocalStorageUtil from './../../../../util/localStorageUtil';

//constants
import * as USER_ROLE from './../../../../constants/userRole';
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

@Component({
    selector: 'app-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild('notificationSettingsPopup',{static:false}) notificationSettingsPopup;
    @ViewChild('vehicleNotification',{static:false}) vehicleNotification: VehicleSelectorComponent;
    @ViewChild('driverTagPopup',{static:false}) driverTagPopup;
    @ViewChild('driverTagSelector',{static:false}) driverTagSelector: DriverTagSelectorComponent;

    currentTab: number = 1;
    color: string = 'primary';

    notification: any = {};
    oriNotification: any = {};
    updateNotificationList: any = [];
    selectedVehicleIdList: any = [];
    selectedDriverTagIdList: any = [];
    selectedTypeNotification = "";
    selectedNotificationName = "";

    isDriverRole: boolean = false;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private notificationService: NotificationService,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        const session = LocalStorageUtil.localStorageGet('currentUser');
        if (session) {
            // Check is it Driver Role
            this.isDriverRole = session.roleId === USER_ROLE.USER_ROLE.DRIVER.roleId ? true : false;
            await this.getNotificationAllInfo();
        }
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async getNotificationAllInfo() {
        this.pageSpinner.show();
        try {
            const result = await this.notificationService.getUserNotificationInfo();
            this.notification = result.body.notification;

            this.oriNotification = JSON.parse(JSON.stringify(result.body.notification)); // Deep Clone Object Value
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    setVehicleIdsList(type, notificationName) {
        // Set Type and Notification Name
        this.selectedTypeNotification = type;
        this.selectedNotificationName = notificationName;

        if (notificationName === 'DRIVER_TAG') {
            this.selectedDriverTagIdList = this.notification[type].filter(result => result.name === notificationName)[0].driverTagList;
            //show the ticked driver tag
            this.driverTagSelector.setSelectedDriverTagIds(this.selectedDriverTagIdList);
            this.showPopup(this.driverTagPopup);
        } else {
            // Set Selected Vehicle Ids
            this.selectedVehicleIdList = this.notification[type].filter(result => result.name === notificationName)[0].vehicleIdList;
            this.vehicleNotification.setSelectedVehicleIds(this.selectedVehicleIdList);
            this.showPopup(this.notificationSettingsPopup);
        }

    }
    async updateDriverTagNotification() {

        this.pageSpinner.show();
        try {
            // Get Selected Driver Tag Ids
            this.selectedDriverTagIdList = this.driverTagSelector.getSelectedDriverTagIds();
            const emptyVehicleList: any[] = [];
            //
            const result = await this.notificationService.updateUserVehicleNotification(this.selectedTypeNotification, this.selectedNotificationName, emptyVehicleList, this.selectedDriverTagIdList);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Driver Tag Notification Setting', this.selectedNotificationName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Driver Tag Notification Setting', this.selectedNotificationName);

                await this.getNotificationAllInfo(); // Get latest data notification
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async updateVehicleNotification() {

        this.pageSpinner.show();
        try {
            // Get Selected Vehicle Ids
            this.selectedVehicleIdList = this.vehicleNotification.getSelectedVehicleIds();
            const emptyDriverTagList: any[] = [];
            const result = await this.notificationService.updateUserVehicleNotification(this.selectedTypeNotification, this.selectedNotificationName, this.selectedVehicleIdList, emptyDriverTagList);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Vehicle Notification Setting', this.selectedNotificationName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Vehicle Notification Setting', this.selectedNotificationName);

                await this.getNotificationAllInfo(); // Get latest data notification
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async updateUserNotification() {
        // Update Only Different with Previous Record (Reason Not Use: for db side easy to see the record accordingly)
        // try {
        //     this.pageSpinner.show();
        //     for (let objKey in this.notification) {
        //         for (let i = 0; i < this.notification[objKey].length; i++) {
        //             //Compare diff before and after
        //             if (this.notification[objKey][i].isOn !== this.oriNotification[objKey][i].isOn) {
        //                 let obj = {
        //                     type: objKey,
        //                     notificationName: this.notification[objKey][i].name,
        //                     isOn: this.notification[objKey][i].isOn
        //                 };
        //                 this.updateNotificationList.push(obj);
        //             }
        //         }
        //     }
        //     console.debug(this.updateNotificationList);

        this.pageSpinner.show();
        try {

            this.updateNotificationList = []; // Empty the list

            for (let i = 0; i < this.notification.email.length; i++) {
                const emailObj = {
                    type: 'email',
                    notificationName: this.notification.email[i].name,
                    isOn: this.notification.email[i].isOn
                };
                this.updateNotificationList.push(emailObj);
            }

            for (let i = 0; i < this.notification.push.length; i++) {
                const pushObj = {
                    type: 'push',
                    notificationName: this.notification.push[i].name,
                    isOn: this.notification.push[i].isOn
                };
                this.updateNotificationList.push(pushObj);
            }

            const result = await this.notificationService.updateUserNotification(this.updateNotificationList);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Notification Setting');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Notification Setting');
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {

            // Get latest data notification regardless success/fail
            await this.getNotificationAllInfo();

            this.pageSpinner.hide();
        }
    }

    byNotification() {
        this.currentTab = 1;
    }

    byVehicle() {
        this.currentTab = 2;
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }
}
