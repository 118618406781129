import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import * as Message from './../../../../constants/message';

//Components
import { SpinnerComponent } from 'src/app/components/common/spinner/spinner.component';

//Services
import { DriverService } from 'src/app/_services/driver/driver.service';
import { DriverTagService } from 'src/app/_services/driver-tag/driver-tag.service';
import { RouterProxyService } from 'src/app/_services/router-proxy/router-proxy.service';
import { PopupService } from 'src/app/components/common/popup/popup.service';
import { SnackBarService } from 'src/app/_services/snackBar/snack-bar.service';
import { PopupCampaignService } from 'src/app/_services/campaign/popup-campaign.service';

// Constant
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

@Component({
  selector: 'app-create-driver-tag',
  templateUrl: './create-driver-tag.component.html',
  styleUrls: ['./create-driver-tag.component.scss'],
  animations: [
    trigger('fadeToggle', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
        ])
    ])
]
})
export class CreateDriverTagComponent implements OnInit {
    newDriverTagInfo = {
        driverTagNo: null,
        remindMinute: null,
        driverId: ''
    }

    message = Message;

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;


    @ViewChild('driverTagForm',{static:false}) driverTagForm;

    error = '';

    // Drivers Selector/Floater
    driverModel: any = {};
    driverList: any = [];
    driverSelectedLabel: string;

    // General Selector Filter Selection when Typing
    @ViewChild('driverField', { static: false }) driverField;
    subjectSelectionFloaterIsShown: boolean = false;
    filterSearchString: string = ""; //store filter search string

    constructor(
        private driverService: DriverService,
        private driverTagService: DriverTagService,
        private routerProxyService: RouterProxyService,
        private popupService: PopupService,
        private snackBar: SnackBarService
    ) { }

    async ngOnInit() {
        await this.getAllDriversWithoutDriverTag();
    }

    async getAllDriversWithoutDriverTag() {
        let driverList = await this.driverService.getDriverWithoutDriverTag();
        this.driverModel = driverList.body || {};
        this.driverList = driverList.body.result.slice(0);
    }

    navigateSuccessToDriverTagView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/driver-tags/view']);
    }

    navigateToDriverTagView() {
        this.routerProxyService.navigate(['/create-manage/driver-tags/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async createDriverTag() {
        this.pageSpinner.show();
        try {
            const driverTagDetail = [{
                driverTagNo: this.newDriverTagInfo.driverTagNo,
                remindMinute: parseInt(this.newDriverTagInfo.remindMinute),
                driverId: String(this.newDriverTagInfo.driverId)
            }];

            const driverTagRes = await this.driverTagService.createDriverTag(driverTagDetail);
            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Driver Tag', this.newDriverTagInfo.driverTagNo);
            if (driverTagRes && driverTagRes.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Driver Tag', this.newDriverTagInfo.driverTagNo);
                this.navigateSuccessToDriverTagView();
            }
            this.snackBar.openGenericSnackBar(msg);
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.pageSpinner.hide();
        }
    }

    // Driver Selection Floater

    onDriverSelectChange() {
        this.updateDriverSelectedLabel();
        this.focusDriverSearchBox();
        this.toggleSubjectSelectionFloater(false);
    }
    private focusDriverSearchBox() {
        const searchBox = this.getDriverSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private getDriverSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.driverField.nativeElement.querySelector("#driverSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }
    private updateDriverSelectedLabel() {
        const driverSelected = this.driverList.find(tag => {
            return this.newDriverTagInfo.driverId === tag.driverId;
        });
        this.driverSelectedLabel = driverSelected.driverName ? driverSelected.driverName : "";
    }
    filterDriverSelection(searchString: string = "") {
        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.driverModel.result = this.driverList;
        } else {
            this.driverModel.result = this.driverList.filter(result => result.driverName.toLowerCase().trim().indexOf(searchString) > -1);
        }
    }
    toggleSubjectSelectionFloater(bool: boolean) {
        this.subjectSelectionFloaterIsShown = bool;
    }
    onOutsideClickDriverReaction(clickEvent: Event): void {
        this.toggleSubjectSelectionFloater(false);
        this.filterDriverSelection();
    }

}
