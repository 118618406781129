import * as StringUtil from './../util/stringUtil';

// class ErrorConstant {
// code: string;
// desc: string;
// message: string;
// constructor(code: string, msg: string) {
//     this.code = code;
//     this.message = msg;
// }
// fillUpCode(...params: any[]) {
//     replaceAnchorText(this.code, params);
// }
// fillUpMessage(...params: any[]) {
//     replaceAnchorText(this.message, params);
// }
// }

const GENERIC_MESSAGE = `mDrive has encountered an error.`;
const GENERIC_MESSAGE_WITH_CODE = `mDrive has encountered an error (#{0}).`;
const SHARED_MESSAGE_ENDING = `Please try again or contact support for help.`;
const OLD_ERROR_MESSAGE = `{1} ({0})`;
const API_ERROR_MESSAGE_ONLY = `{1}`;

export const ERROR_MESSAGE /*: { [name: string]: ErrorConstant }*/ = {

    FILE_DOWNLOAD_FAILED: {
        message: 'Failed to download file.'
    },
    INFO_LOAD_FAILED: {
        message: 'Failed to load information.'
    },
    INFO_UNDER_PROCESSING: {
        message: 'Trip is being processed and will be updated once done.'
    },
    MAP_LOAD_FAILED: {
        message: 'Failed to load map.'
    },
    WEB_APP_NONERROR_CAUGHT: {
        code: '100',
        message: 'Exception (Non-Error instance) caught in front-end application.'
    },
    WEB_APP_ERROR_CAUGHT: {
        code: '101',
        message: 'Exception caught in front-end application.'
    },
    WEB_HTTP_TIMEOUT: {
        code: '102',
        message: 'Timeout occured during HTTP request.'
    },
    WEB_HTTP_ERROR: {
        code: '{0}',    // Use HTTP Status Code
        message: '{1}'  // Use HTTP Status Text
    },
    UNKNOWN_HTTP_ERROR: {
        code: '900',
        message: 'Unknown HTTP Error'
    },
    BACKEND_API_ERROR: {
        code: '{0}',    // Use API Backend Error Code
        message: '{1}'  // Use API Backend Error Message
    },

    getDefaultErrorMessage: function (): string {
        return `${GENERIC_MESSAGE} \n${SHARED_MESSAGE_ENDING}`;
    },

    getPromptErrorMessage: function (errorConstant: any, params: string[] = []): string {
        if (!StringUtil.isNotEmptyOrNull(errorConstant.code) && !StringUtil.isNotEmptyOrNull(errorConstant.message)) {
            return null;
        }

        let appendEnding: boolean = true;
        let messageFormat: string = '';
        let messageParams: string[] = [];
        if (errorConstant.code) {
            // messageFormat = OLD_ERROR_MESSAGE; // old

            // Show backend error message if code = 500100
            if (params.length && params[0] == '500100') {
                messageFormat = API_ERROR_MESSAGE_ONLY;
                appendEnding = false;
            } else {
                messageFormat = GENERIC_MESSAGE_WITH_CODE;
            }

            const errorCode = StringUtil.replaceVarsInText(errorConstant.code, params);
            const errorMessage = StringUtil.alwaysEndWith(StringUtil.replaceVarsInText(errorConstant.message, params), StringUtil.Characters.FULL_STOP); // always ends with full stop

            // console.log({
            //     errorCode, errorMessage
            // });
            messageParams = [errorCode, errorMessage];

        } else {
            messageFormat = errorConstant.message;
        }

        // Append Ending
        if (appendEnding) {
            messageFormat += ` \n${SHARED_MESSAGE_ENDING}`;
        }

        return StringUtil.replaceVarsInText(messageFormat, messageParams);
    }

};
