<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <div class="block">
                <h1 class="heading --level-1">
                    Customer View
                </h1>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block w-10of12 w-6of12@md search__group">
                        <div class="field-group --unstack@xs search__select-field">
                            <div class="field-group__item search__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                <label class="select --grey">
                                    <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                        <option value="company">Company</option>
                                        <option value="username">Username</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__item search__type-field-group-input">
                                <div class="field">
                                    <div class="field__elems">
                                        <label class="field__holder">
                                            <input id="searchBox" type="text" autocomplete="off" class="field__input" [placeholder]="getPlaceHolderText()" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchValue()">
                                        </label>
                                        <div class="field__ui"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="button__holder">
                            <button type="button" class="button" (click)="searchValue()">SEARCH</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="table table__support-customer-view">
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <th scope="col" width="33%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('CompanyName')" (click)="sort('CompanyName')">Company</button>
                                </th>

                                <th scope="col" width="33%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('UserName')" (click)="sort('UserName')">Username</button>
                                </th>

                                <th scope="col" width="23%">
                                    Suspended : {{companyOriDetails.totalSuspendedRecord}}
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('SuspendLabel')" (click)="sort('SuspendLabel')">Suspend/Unsuspend</button>
                                </th>

                                <th scope="col" width="23%">
                                    Deactivated
                                </th>

                                <!-- <th scope="col" width="33%">
                                    Action
                                </th> -->
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let company of companyDetailsList">
                                <td><a class="link --primary" (click)="manageAccount(company.companyId, company.companyName)">{{company.companyName}}</a></td>
                                <td>{{company.userName}}</td>
                                <td><a class="link --primary" (click)="showPopup(suspendButtonPopup);tempStoreInfoForSuspendUnsuspend(company.companyId, company.status);">{{company.suspendLabel}}</a>
                                </td>
                                <td><a class="link --primary" (click)="showPopup(deactivatedButtonPopup);tempStoreInfoForDeactivation(company.companyId);">Deactivate</a>
                                </td>
                                <!-- <td><a class="link --primary" (click)="manageAccount(company.companyId, company.companyName)">Manage
                                        Account</a></td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h2 class=" record-not-found" *ngIf="companyDetailsList.length === 0">No Records Found</h2>
            </div>

            <div class="block" *ngIf="companyDetailsList.length > 0">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                        </div>
                    </div>
                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination">
                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDisplayCompanyList(pager.currentPage - 1)">
                                <span class="angle-arrow --left">
                                </span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDisplayCompanyList(page)">{{
                                page
                                }}</button>

                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDisplayCompanyList(pager.currentPage + 1)">
                                <span class="angle-arrow --right">
                                </span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<app-popup #suspendButtonPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to save vehicle changes
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="suspendUnsuspendAccount();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>


<app-popup #deactivatedButtonPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Deactivation?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to save vehicle changes
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deactivateAccount();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
