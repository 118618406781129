import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { trigger, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Platform } from '@ionic/angular';

import * as moment from 'moment';
import * as LocalStorageUtil from './../../../util/localStorageUtil';
import * as AsyncUtil from './../../../util/asyncUtil';
import * as DomUtil from './../../../util/domUtil';
import * as DateTimeUtil from "../../../util/dateTimeUtil";
import * as MapUtil from '../../../util/mapUtil';
import * as ComponentUtil from '../../../util/componentUtil';
import * as NumberUtil from '../../../util/numberUtil';
import * as StringUtil from '../../../util/stringUtil';

import { TripsService } from './../../../_services/trips/trips.service';
import { MessageBusService, BusMessage } from "./../../../_services/messagebus.service";
import { SnackBarService } from './../../../_services/snackBar/snack-bar.service';
import { VehicleService } from './../../../_services/vehicle/vehicle.service';
import { RouterProxyService } from './../../../_services/router-proxy/router-proxy.service';
import { SpinnerComponent } from './../../common/spinner/spinner.component';
import { ModulePermissionService } from './../../../_services/access-control/module-permission.service';

import * as Message from './../../../constants/message';
import { STATUSES } from './../../../constants/statuses.constant';
import { STATUS } from './../../../constants/status.constant';
import { MESSAGE_BUS } from './../../../constants/messagebus-subscribers.constant';
import { ERROR_MESSAGE as ErrorMessage } from './../../../constants/errorMessage';
import * as PolylineStyle from './../../../constants/polyline-properties';
import { UserService } from './../../../_services/user/user.service';
import { overlayConstant } from './../../../constants/overlay-image-map.constant';
// import { MarkerManager } from '@googlemaps/markermanager';

import { modules } from './../../../constants/module-access.constant';
// import { TIME } from './../../../constants/time.constant';
// import { VIOLATION_TYPE, getViolationLabelFromValue } from './../../../constants/violationTypes.constant';
// import * as LiveSummaryTripStatusConstant from '../constants/liveSummaryTripStatus.constant';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
    // _tripId: number = 0;
    private map: google.maps.Map;
    private refreshTimerIntervalInMilliSeconds: number = environment.appConfig.map.refreshInterval * 1000;
    private CustomMarker: any;
    private mapItemMarkerTemplate: string = '';
    private alertMarkerTemplate: any = '';

    // private alertMarkerTemplateInnerHTML:string = '';
    // private resizeTimer: any;
    // private loadAPI: Promise<any>;

    // @ViewChild("page_spinner") pageSpinner: SpinnerComponent;
    @ViewChild("page_spinner_nav",{static:true}) pageSpinnerNav: SpinnerComponent;
    Popup: any;
    isPopUpShown: boolean = false;
    visiblePopUp: any;

    STATUSES: any = STATUSES;
    STATUS: any = STATUS;

    MY_LOCATION_LAT: number = 3.158; //Menara Maxis
    MY_LOCATION_LNG: number = 101.713; //Menara Maxis
    defaultPolyLineIconProps: any;

    listIsShown: boolean = true;
    searchFiltersIsActive: boolean = true;
    fabIsRequired: boolean = true;
    vehiclemainIsActive: boolean = false;
    refreshPageTimer: any;
    // mapMqIsDesktop:boolean = false;
    mapIsReady: boolean = false;
    triplistIsShown: boolean = false;
    useSnap: boolean = false;
    modulusValue = 5;

    tripDayItemSingleViewMode: boolean = false;

    filtersModel: any = {
        isShown: false,
        isAnimatingOut: false,
        timer: null,
        vehicleName: '',
        statuses: {},
        groups: []
    };

    searchVehicleName: string;
    companyImeiList: any = [];

    mapTrafficLayer: any;
    mapContent: any;
    legendContent: any; //store html content of legend DIV
    autoUpdateButton: any;
    trafficLayerToggler: any;

    cachedMapList: {
        loaded: boolean,
        mapModel: any,
        vehiclesList: Array<any>
    } = {
            loaded: false, // indicate whether cached
            mapModel: null, // for caching map list data
            vehiclesList: [] // for caching map list data
        };

    mapModel: {
        coordinates: Array<any>,
        mapCoordinates: Array<any>,
        useSnapData: any,
        eachSnapDataViolationIndex: Array<any>,
        tripRoute: Array<any>,
        currentItems: Array<any>,
        itemMarkers: Array<any>,
        currentItem: any,
        // currentItemIsActive: boolean,
        showMapLegend: boolean,
        isOnTrafficLayer: boolean,
        showTrafficLayerIcon: boolean,
        isOnAutoUpdate: boolean,
        showAutoUpdateIcon: boolean,
        visibleComponent: any,
        focusedMarker: any,
        isRefresh: boolean
    };

    //animation of vehicle moving between coordinate
    animationWait = 55;
    animationIndex = 0;
    animationSpd = 3;

    animationMarker: any = [];
    // perviousMapModel: {
    //     coordinates: Array<any>,
    //     mapCoordinates: Array<any>,
    //     useSnapData: any,
    //     eachSnapDataViolationIndex: Array<any>,
    //     tripRoute: Array<any>,
    //     currentItems: Array<any>,
    //     itemMarkers: Array<any>,
    //     currentItem: any,
    //     // currentItemIsActive: boolean,
    //     showMapLegend: boolean,
    //     isOnTrafficLayer: boolean,
    //     showTrafficLayerIcon: boolean,
    //     isOnAutoUpdate: boolean,
    //     showAutoUpdateIcon: boolean,
    //     visibleComponent: any,
    //     focusedMarker: any,
    //     isRefresh: boolean
    // };
    perviousMapModel: any = {};
    frameAnimation: Array<any>;
    liveTripTimeout: any;

    defaultMapModel: any = {
        coordinates: [], //for route raw coordinates when user clicks on certain trip/vehicle detail
        mapCoordinates: [], //for route coordinates that return from snap to road (google road API)
        useSnapData: [], //details retrieved from dynamodb when user click tripDayItem, but the location is updated by Snap to Road
        eachSnapDataViolationIndex: [], //keep track which snap to road records consist violations
        tripRoute: [], //for trip polylines
        currentItems: [], //for raw marker items
        itemMarkers: [], //for map markers: vehicle location, trip origin/destination, trip alerts
        currentItem: null,
        // currentItemIsActive: false,
        showMapLegend: false,
        isOnTrafficLayer: false,
        showTrafficLayerIcon: true,
        isOnAutoUpdate: true,
        showAutoUpdateIcon: true,
        visibleComponent: null, //Component that currently interacting/projecting to Map (VehicleMain/MapList/...)
        focusedMarker: null, //IMEI of vehicle marker in focus
        isRefresh: false
    };

    markerClusters = null;
    tripDuration: any = '';
    tripDetail: any = {
        data: {
            tId: null,
            tripDet: []
        }, // details retrieved from dynamodb when user click tripDayItem
        add: {
            sAdd: null,
            sTime: null,
            eAdd: null,
            eTime: null
        }
    };
    tripDetailsInterpolated: any = [];
    // tripDetailsInterpolated1: any = [];

    msgBusSubscription: Subscription;
    blockedMsgBusByMessageType: Array<string> = [];
    blockedMsgBusByMessageSource: Array<string> = [];
    lastBlockedTrigger: string = '';
    useSVG: boolean = false;

    stopCurrentLiveMarkerRefreshing: boolean = false;
    currentRunning: boolean = false;

    // All available/required permission in this page
    permission: any;

    constructor(
        // private ref: ChangeDetectorRef,
        private platform: Platform,
        private http: HttpClient,
        private tripsService: TripsService,
        private vehicleService: VehicleService,
        private msgbus: MessageBusService,
        private el: ElementRef,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private userService: UserService,
        private mpService: ModulePermissionService
    ) {
        // console.debug('MapComponent: constructor');

        this.mapModel = Object.create(this.defaultMapModel);
        // this.mapModel.currentItems = this.mapModel.items.slice(0);
        // this.perviousMapModel = Object.create(this.defaultMapModel);

        // for(let i in Object.getOwnPropertyNames(STATUS)) {
        for (const i of STATUSES) {
            this.filtersModel.statuses[i] = false;
        }
    }

    async ngOnInit() {
        // console.debug('MapComponent: ngOnInit');

        //Init HTML Contents
        this.mapContent = document.getElementById('map');
        this.legendContent = document.getElementById('legend');
        this.autoUpdateButton = document.getElementById('btnAutoUpdate');
        this.trafficLayerToggler = document.getElementById('btnTrafficToggler');
        this.mapItemMarkerTemplate = document.getElementById('mapItemMarkerTemplate').innerHTML;
        this.alertMarkerTemplate = document.getElementById('alertMarkerTemplate');

        //Init Module Permission
        this.permission = await this.mpService.hasPermission(modules.MAP_VEHICLE_HISTORY.value, modules.CRUD_DRIVER.value);
        // this.permission[modules.MAP_VEHICLE_HISTORY.value].vAccess = false;

        // Get All Vehicle Groups for Filter Use
        this.getCompanyAllUserGroups(); // (async) but executed in-sync on purpose

        await this.loadScriptFirst(); // (sync)

        this.initRefreshTimer();
    }

    initMessageBus() {
        ComponentUtil.killRxjsSubscription(this.msgBusSubscription);
        const busesPort = ["map", "mapMarker", "mapList", "vehiclePanel", "tripDayItem", "fab"];
        this.msgBusSubscription = this.msgbus.messagebus$.pipe(
            filter(message => busesPort.indexOf(message.source) != -1)
        ).subscribe(this.messageBusGetMessage.bind(this));
    }

    ngAfterViewInit() {
        // console.debug('MapComponent: ngAfterViewInit');
    }

    ngOnDestroy() {
        // console.debug('MapComponent: ngOnDestroy');
        clearInterval(this.refreshPageTimer);
        ComponentUtil.killRxjsSubscription(this.msgBusSubscription);
    }

    /* Message Controller*/
    async messageBusGetMessage(message: BusMessage) {

        //Block message bus
        if (this.isMessageBlocked(message)) {
            return;
        }
        // this.blockedMsgBusByMessageSource = [];
        switch (message.source) {
            case "map":
                await this.handleMapMessage(message);
                break;
            case "mapMarker":
                await this.handleMapMarkerMessage(message);
                break;
            case "mapList":
                await this.handleMapListMessage(message);
                break;
            case "vehiclePanel":
                await this.handleVehiclePanelMessage(message);
                break;
            case "tripDayItem":
                await this.handleTripDayItemMessage(message);
                break;
            // case "fab":
            //     await this.handleFabMessage(message);
            //     break;
        }
    }

    /* Message Handler: General Map related */
    async handleMapMessage(message: BusMessage) {

        switch (message.messagetype) {
            case "isReady":
                if (this.mapIsReady) {
                    this.notifyOtherComponentsThatMapIsReady(message.data.sender);
                }
                break;
        }
    }

    /* Message Handler: Map List */
    async handleMapListMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "markerlistinit": {
                console.debug('(MsgBus)MapComponent: mapMarker -> markerlistinit (mapComponent) + called by: ' + message.data.sender);

                //Block map item marker msg bus
                // this.blockMsgBusByMessageSource('tripDayItem');

                this.triplistIsShown = false;
                // this.initEmptyMap();

                const prevVisibleComponent: string = this.mapModel.visibleComponent;
                this.updateCurrentVisibleComponent(message.data.sender);
                this.setCorrectMapControlStates(prevVisibleComponent, true);

                // this.initMapForMarkerList();
                break;
            }
            case "markerlistchange": {
                await this.handleMapMarkerMessage(message);
                break;
            }
        }
    }

    async handleMapMarkerMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "markerlistchange": {
                console.debug('(MsgBus)MapComponent: mapMarker -> markerlistchange (mapComponent) + called by: ' + message.data.sender);

                //Block map item marker msg bus
                // this.blockMsgBusByMessageSource('tripDayItem');

                const prevVisibleComponent: string = this.mapModel.visibleComponent;
                this.updateCurrentVisibleComponent(message.data.sender);
                this.setCorrectMapControlStates(prevVisibleComponent);

                this.mapModel.currentItems = message.data.currentItems;
                if (message.data.sender == 'MapListComponent') {
                    this.cachedMapList.mapModel = message.data.mapListMapModel; // caching
                    this.cachedMapList.vehiclesList = message.data.mapListVehiclesList; // caching
                    this.cachedMapList.loaded = true;
                }
                this.mapModel.isRefresh = message.data.isRefresh;

                // show/hide 2nd panel
                this.triplistIsShown = message.data.isPanelOpened;

                // update markers
                this.initMapMarkers();

                // if (!this.mapModel.isRefresh) {
                //     // show/hide 2nd panel when not auto-refresh
                //     this.triplistIsShown = message.data.isPanelOpened;
                //     if (message.data.isFiltering != true) {
                //         // this.initMapElements(); // init map elements
                //         this.initMapMarkers(); //refresh markers only
                //     } else {
                //         this.initMapMarkers(); //refresh markers only
                //     }
                // } else {
                //     this.initMapMarkers(); // do not init map elements
                // }

                //shop empty map when no marker shown
                if (!Array.isArray(this.mapModel.currentItems) || this.mapModel.currentItems.length == 0) {
                    this.initEmptyMap();

                    // Hide auto refresh button
                    // this.mapModel.showAutoUpdateIcon = false;
                    // this.initMapAutoUpdateButton();

                    this.mapModel.isOnAutoUpdate = false;
                    // this.initRefreshTimer();
                }

                // show/hide search filter when viewing single vehicle
                const focusedMarker = message.data.focusedMarker; // imei received from sender
                if (focusedMarker != undefined && focusedMarker != null) {
                    this.mapModel.focusedMarker = focusedMarker;
                    this.hideSearchFilter();
                } else {
                    this.showSearchFilter();
                    if (message.data.firstLoad == true) {
                        this.applyFilters();
                    }
                }

                // re-init auto refresh timer
                this.initRefreshTimer();
                break;
            }
        }
    }
    /* Message Handler: Vehicle Markers */
    async handleLiveTripMapMarkerMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "markerlistchange": {
                console.debug('(MsgBus)MapComponent: mapMarker -> markerlistchange (mapComponent) + called by: ' + message.data.sender);

                const prevVisibleComponent: string = this.mapModel.visibleComponent;
                this.updateCurrentVisibleComponent(message.data.sender);
                this.setCorrectMapControlStates(prevVisibleComponent);

                //save the current and previous state
                this.perviousMapModel = {};
                this.perviousMapModel.currentItems = this.mapModel.currentItems;
                //get the latest data
                this.mapModel.currentItems = message.data.currentItems;

                if (message.data.sender == 'MapListComponent') {
                    this.cachedMapList.mapModel = message.data.mapListMapModel; // caching
                    this.cachedMapList.vehiclesList = message.data.mapListVehiclesList; // caching
                    this.cachedMapList.loaded = true;
                }
                this.perviousMapModel.isRefresh = this.mapModel.isRefresh;
                this.mapModel.isRefresh = message.data.isRefresh;

                // show/hide 2nd panel
                this.triplistIsShown = message.data.isPanelOpened;

                // update markers
                this.initLiveTripMapMarkers();

                //shop empty map when no marker shown
                if (!Array.isArray(this.mapModel.currentItems) || this.mapModel.currentItems.length == 0) {
                    this.initEmptyMap();

                    // Hide auto refresh button
                    // this.mapModel.showAutoUpdateIcon = false;
                    // this.initMapAutoUpdateButton();

                    this.mapModel.isOnAutoUpdate = false;
                    // this.initRefreshTimer();
                }

                // show/hide search filter when viewing single vehicle
                const focusedMarker = message.data.focusedMarker; // imei received from sender
                if (focusedMarker != undefined && focusedMarker != null) {
                    this.mapModel.focusedMarker = focusedMarker;
                    this.hideSearchFilter();
                } else {
                    this.showSearchFilter();
                    if (message.data.firstLoad == true) {
                        this.applyFilters();
                    }
                }

                // re-init auto refresh timer
                this.initRefreshTimer();
                break;
            }
        }
    }

    /* Message Handler: Vehicle Panel */
    async handleVehiclePanelMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "unblock":
                this.unblockMsgBusByMessageSource(MESSAGE_BUS.VEHICLE_PANEL.source);
                break;
            case "singleViewModeToggle": {
                this.tripDayItemSingleViewMode = message.data.active;
                break;
            }
            case "triplisttoggle": {
                // console.debug('(MsgBus)MapComponent: triplist -> triplisttoggle (VehiclePanelComponent)');
                if (this.triplistIsShown !== message.data.isActive) {
                    this.triplistIsShown = message.data.isActive;
                    if (this.triplistIsShown) {
                        this.updateMsgBusBlockersByComponent(MESSAGE_BUS.TRIP_DAY_ITEM.name);
                    } else {
                        this.updateMsgBusBlockersByComponent(MESSAGE_BUS.VEHICLE_PANEL.name);
                    }
                }
                break;
            }
            case "markerlistchange": {
                await this.handleLiveTripMapMarkerMessage(message);
                // await this.handleMapMarkerMessage(message);
                break;
            }
            // case "vehiclemaintoggle": {
            //     this.vehiclemainIsActive = message.data.isActive;
            //     break;
            // }
        }
    }

    /* Message Handler: Trip day item */
    async handleTripDayItemMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "singleViewModeToggle":
                this.tripDayItemSingleViewMode = message.data.active;
                break;
            case "tripRoute":
                // console.debug('(MsgBus)MapComponent: tripRoute -> tripDayItem (mapComponent)');

                //Block map item marker msg bus
                // this.blockMsgBusByMessageSource('mapMarker');

                this.updateCurrentVisibleComponent(message.data.sender);

                //Get value from message bus service
                const tripId = message.data.tripId;
                const startTime = message.data.startTime;
                const endTime = message.data.endTime;
                const vehicleId = message.data.vehicleId;
                const driverId = message.data.driverId;
                // let duration = message.data.duration;

                //Call trip details API to generate route for this post trip
                const _this = this;
                this.initTripRoute(tripId, vehicleId, driverId, startTime, endTime)
                    .then(function () {
                        return _this.initTripMarker();
                    })
                    .then(function () {
                        //disable auto refresh for post trip
                        _this.mapModel.isOnAutoUpdate = false;
                        _this.initRefreshTimer();
                    }).catch((err) => {
                        throw err;
                    });

                break;
        }
    }

    // async handleFabMessage(message: BusMessage) {
    //     switch (message.messagetype) {
    //         case "fabtoggle":
    //             this.fabIsRequired = message.data.isRequired;
    //             break;
    //     }
    // }

    async loadScriptFirst() {
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        // console.debug('MapComponent: hasGoogleMapScript()');
        if (!DomUtil.hasGoogleMapScript(null, GOOGLE_MAP_API_KEY)) {

            DomUtil.addGoogleMapScript({
                onload: this.finishScriptLoad.bind(this)
            }, GOOGLE_MAP_API_KEY);

        } else {
            console.debug('MapComponent: Skip adding Google Map Script Tag...');
            if (DomUtil.isGoogleNotDefined()) {
                // console.time("duration");
                await AsyncUtil.wait(300); // wait for google object ready
                // console.timeEnd("duration");
            }
            this.finishScriptLoad();
        }
    }

    finishScriptLoad() {
        this.initMap();
    }

    /* MAP INITIALIZATION */
    async initMap() {
        // console.debug('MapComponent: Initialise new Google Map...');
        this.mapIsReady = false;

        if (!DomUtil.isGoogleNotDefined()) {

            const defaultMapOptions = {
                center: {
                    lat: this.MY_LOCATION_LAT,
                    lng: this.MY_LOCATION_LNG
                },
                clickableIcons: false,
                gestureHandling: 'greedy',
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                },
                // mapTypeControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP,
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    // style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    // mapTypeIds: ['roadmap', 'custom-hybrid']
                    mapTypeIds: ['roadmap', 'hybrid', 'satellite']
                },
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.TOP_LEFT
                },
                zoom: 18,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                }
            };

            //Traffic Layer
            this.mapTrafficLayer = new google.maps.TrafficLayer();

            const mapOptions = Object.create(defaultMapOptions);
            if (this.checkHasCachedMapView()) {
                mapOptions.center = new google.maps.LatLng(LocalStorageUtil.localStorageGet('mapLat'), LocalStorageUtil.localStorageGet('mapLng'));
                mapOptions.zoom = parseInt(LocalStorageUtil.localStorageGet('mapZoom'));
            }

            // console.debug('##################');
            // console.debug('##################');
            // console.debug('init map...');
            // console.debug('##################');
            // console.debug('##################');

            this.map = new google.maps.Map(this.mapContent, mapOptions);
            this.definePopupClass();

            const mapp: google.maps.Map = this.map;

            //Set local storage variables.
            if (mapp != null && mapp != undefined) {
                const mapCentre = mapp.getCenter();
                this.cacheMapView(mapCentre.lat(), mapCentre.lng(), mapp.getZoom());
            }

            const _this = this;
            google.maps.event.addListener(mapp, "center_changed", function () {
                //Set local storage variables.
                if (mapp != null && mapp != undefined) {
                    const mapCentre = mapp.getCenter();
                    _this.cacheMapView(mapCentre.lat(), mapCentre.lng(), mapp.getZoom());
                }
            });

            const polylines = this.mapModel.tripRoute;
            google.maps.event.addListener(mapp, "zoom_changed", function () {
                //Set local storage variables.
                if (mapp != null && mapp != undefined) {
                    const mapCentre = mapp.getCenter();
                    _this.cacheMapView(mapCentre.lat(), mapCentre.lng(), mapp.getZoom());
                    // console.log("zoom: " + mapp.getZoom());
                    _this.adjustTripRouteStroke(mapp.getZoom(), polylines);
                }
            });

            const result = await this.userService.getCompanyIdByName('Gamuda Cove');
            if (result && result.body.length > 0) {
                const currentUser = LocalStorageUtil.localStorageGet('currentUser');
                let currUserCompanyId = currentUser.companyId;
                const currUserRoleId = currentUser.roleId;
                // if Any Maxpert or Super Super Admin Viewing Gamuda Cove use this companyId to check instead of it own companyId
                if (currUserRoleId == 6 || currUserRoleId == 11) {
                    currUserCompanyId = LocalStorageUtil.localStorageGet('administratorUser').companyId;
                }
                if (result.body[0].id === currUserCompanyId) {
                    MapUtil.generateOverlayImg(overlayConstant.imageLoc, overlayConstant.imagePath, this.map);
                }
            }

            // mapp.mapTypes.set('custom-hybrid', new google.maps.StyledMapType([], {
            //     name: 'Satellite'
            // }));
            // google.maps.event.addListener(mapp, 'maptypeid_changed', function () {
            //     if (mapp.getMapTypeId() == 'custom-hybrid') {
            //         mapp.setMapTypeId('hybrid');
            //     }
            //     _this.setCorrectMapTypeLabel();
            // });
            // google.maps.event.addListener(mapp, 'idle', function () {
            //     _this.setCorrectMapTypeLabel();
            //     // this.initMapElements.bind(this);
            // });
        } else {
            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.MAP_LOAD_FAILED);
            this.snackBar.openGenericSnackBar(msg);

            //Close Map Message Bus
            if (this.msgBusSubscription) {
                this.msgBusSubscription.unsubscribe();
            }

            //Switch OFF Auto Refresh
            this.mapModel.isOnAutoUpdate = false;
            this.initRefreshTimer();

            // this.smartCloseSpinner();
        }

        this.mapIsReady = true;
        this.notifyOtherComponentsThatMapIsReady();

    }

    notifyOtherComponentsThatMapIsReady(sender: string = null): void {
        this.stopLiveTripTimeout();
        // Start receiving from message bus
        this.initMessageBus();

        // Notify map-dependent components to start initialising
        this.msgbus.sendMessage(new BusMessage("map", "ready", {
            target: sender, //null = All
            cachedMapList: this.cachedMapList || null
        }));
    }

    /* MESSAGE BUS BLOCKERS */
    isMessageBlocked(message: BusMessage): boolean {

        if (message.data.bypassBlock === true) {
            return false;
        } else if (this.blockedMsgBusByMessageSource.indexOf(message.source) != -1) {
            console.debug('MapComponent: @@ Blocked Message Bus -> ' + message.source);
            console.debug('Blocked by: ' + this.lastBlockedTrigger);
            console.debug(message);

            return true;
        }
        return false;
    }
    updateCurrentVisibleComponent(componentName: string = null): void {
        this.mapModel.visibleComponent = componentName;
        if (componentName == MESSAGE_BUS.MAP_LIST.name) {
            this.tripDayItemSingleViewMode = false;
            this.vehiclemainIsActive = false;
            this.fabIsRequired = true;
            this.listIsShown = true;
        } else if (componentName == MESSAGE_BUS.VEHICLE_PANEL.name) {
            this.tripDayItemSingleViewMode = false;
            this.vehiclemainIsActive = true;
            this.fabIsRequired = false;
        } else if (componentName == MESSAGE_BUS.TRIP_DAY_ITEM.name) {
            this.vehiclemainIsActive = false;
            this.fabIsRequired = false;
        }
        this.updateMsgBusBlockersByComponent(componentName);
    }
    updateMsgBusBlockersByComponent(componentName: string): void {
        // let visibleComponent = this.mapModel.visibleComponent;

        if (componentName == this.lastBlockedTrigger) {
            return;
        }

        // console.debug('updateMsgBusBlockersByComponent -> ' + componentName);

        this.lastBlockedTrigger = componentName;

        this.unblockMsgBusByMessageSource(); // unblock all
        switch (componentName) {
            case MESSAGE_BUS.MAP_LIST.name: {
                this.blockMsgBusByMessageSource(MESSAGE_BUS.TRIP_DAY_ITEM.source);
                this.blockMsgBusByMessageSource(MESSAGE_BUS.VEHICLE_PANEL.source);
                break;
            }
            case MESSAGE_BUS.VEHICLE_PANEL.name: {
                this.blockMsgBusByMessageSource(MESSAGE_BUS.TRIP_DAY_ITEM.source);
                break;
            }
            case MESSAGE_BUS.TRIP_DAY_ITEM.name: {
                this.blockMsgBusByMessageSource(MESSAGE_BUS.MAP_MARKER.source);
                this.blockMsgBusByMessageSource(MESSAGE_BUS.VEHICLE_PANEL.source);
                break;
            }
        }
    }
    blockMsgBusByMessageSource(source: string, block: boolean = true): void {
        this.blockedMsgBusByMessageSource = this.blockedMsgBusByMessageSource.filter(item => item != source);
        if (block) {
            this.blockedMsgBusByMessageSource = this.blockedMsgBusByMessageSource.concat([source]);
        }
    }
    unblockMsgBusByMessageSource(source: string = 'all'): void {
        if (source == 'all') {
            this.blockedMsgBusByMessageSource = [];
        } else {
            this.blockMsgBusByMessageSource(source, false);
        }
    }

    /* MAP HELPER FUNCTIONS */
    // initMapForMarkerList(): void {
    //     // console.debug('MapComponent: initMapForMarkerList()');

    //     this.triplistIsShown = false;
    //     this.initEmptyMap();
    //     // this.showDefaultMapControls();
    //     this.cleanMap();

    //     // if (!this.pageSpinner.isShowing()) {
    //     //     this.pageSpinner.show();
    //     //     console.debug('SHOW SPINNER FROM initMapForMarkerList');
    //     // }
    // }

    setCorrectMapControlStates(prevVisibleComponent: string, showSpinnerEvenIfNotCleaningMap: boolean = false) {

        // clean map if navigated from trip route
        // else, carry forward map control settings (refresh/traffic)
        const acceptedComponents = ["MapListComponent", "VehiclePanelComponent"];
        if (acceptedComponents.indexOf(prevVisibleComponent) == -1) {
            //reset map to show correct controls & states
            this.cleanMap();
        } else {
            //only show correct controls but retain ON/OFF states (carry forward settings when zooming)
            if (showSpinnerEvenIfNotCleaningMap) {
                this.pageSpinnerNav.show();
                console.debug('SHOW SPINNER FROM setCorrectMapControlStates()');
            }
            this.showDefaultMapControls();
        }
    }

    fixMapView(): boolean {
        if (this.checkHasCachedMapView()) {
            if (!this.map) {
                return;
            }
            this.map.setCenter(new google.maps.LatLng(LocalStorageUtil.localStorageGet('mapLat'), LocalStorageUtil.localStorageGet('mapLng')));
            this.map.setZoom(parseInt(LocalStorageUtil.localStorageGet('mapZoom')));
            return true;
        }
        return false;
    }

    cacheMapView(lat, lng, zoom): void {
        LocalStorageUtil.localStorageSet('mapLat', lat);
        LocalStorageUtil.localStorageSet('mapLng', lng);
        LocalStorageUtil.localStorageSet('mapZoom', zoom);
    }

    checkHasCachedMapView(): boolean {
        if (LocalStorageUtil.localStorageGet('mapLat')
            && LocalStorageUtil.localStorageGet('mapLng')
            && LocalStorageUtil.localStorageGet('mapZoom')) {
            return true;
        }
        return false;
    }

    fixMapZoom(zoomLevel): boolean {
        if (zoomLevel != null) {
            this.map.setZoom(parseInt(zoomLevel));
            return true;
        }
        return false;
    }

    initEmptyMap(): void {

        //remove all markers
        // this.initMapMarkers();

        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initEmptyMap()');

        this.map.setCenter(new google.maps.LatLng(this.MY_LOCATION_LAT, this.MY_LOCATION_LNG));
        this.map.setZoom(18);

        // console.debug('MapComponent: Empty Google Map ready!');

        console.debug('HIDING SPINNER FROM initEmptyMap()');
        this.smartCloseSpinner();
    }

    initMapMarkers(): void {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initMapMarkers()');
        //refresh all markers
        this.initMapItemMarker();
        this.setMapItemMarkers();
    }

    initLiveTripMapMarkers(): void {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initMapMarkers()');

        //refresh all markers
        //set stopCurrentLiveMarkerRefreshing to true at this level, so previous running singleVehicleAnimationMoves function will stop
        this.stopCurrentLiveMarkerRefreshing = true;
        //reset indicator
        this.currentRunning = false;
        this.initMapItemMarker();
        this.setMapItemMarkersWithAnimation();
    }
    initMapElements(): void {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initMapElements()');

        try {

            /** init something on map **/
            this.initMapLegend();
            this.initMapAutoUpdateButton();
            this.initMapTrafficLayerButton();
            this.initTrafficLayer();
            this.initMapMarkers();

            // console.debug('MapComponent: Google Map ready!');
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    showDefaultMapControls(): void {
        this.mapModel.showMapLegend = this.defaultMapModel.showMapLegend;
        this.mapModel.showTrafficLayerIcon = this.defaultMapModel.showTrafficLayerIcon;
        this.mapModel.showAutoUpdateIcon = this.defaultMapModel.showAutoUpdateIcon;
    }

    initMapLegend(): void {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initMapLegend()');

        if (this.map.controls) {

            const legend = this.legendContent;
            if (!Boolean(legend)) {
                alert('missing HTML content - legend');
                return;
            }

            const index = this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].getArray().indexOf(legend);
            const controlArray = Array(this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM]);
            controlArray.splice(index, 1);

            if (this.mapModel.showMapLegend) {
                legend.style.display = "block";
            } else {
                legend.style.display = "none";
            }
            controlArray.push(legend);

            this.pushMapControls('LEFT_BOTTOM', controlArray);
            // this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
        }
    }

    initMapAutoUpdateButton(): void {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initMapAutoUpdateButton()');

        const autoUpdateButton = this.autoUpdateButton;
        if (!Boolean(autoUpdateButton)) {
            alert('missing HTML content - auto update button');
            return;
        }

        if (this.mapModel.showAutoUpdateIcon) {
            autoUpdateButton.style.display = "block";
        } else {
            autoUpdateButton.style.display = "none";
        }
    }

    initMapTrafficLayerButton(): void {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initMapTrafficLayerButton()');

        if (this.map.controls) {

            const trafficLayerToggler = this.trafficLayerToggler;
            if (!Boolean(trafficLayerToggler)) {
                alert('missing HTML content - traffic button');
                return;
            }

            const index = this.map.controls[google.maps.ControlPosition.LEFT_TOP].getArray().indexOf(trafficLayerToggler);
            const controlArray = Array(this.map.controls[google.maps.ControlPosition.LEFT_TOP]);
            controlArray.splice(index, 1);

            //get map control icon size based on street view icon
            const gmControl = this.el.nativeElement.querySelector('.gm-svpc');
            if (gmControl) {
                trafficLayerToggler.style.width = gmControl.getAttribute('controlwidth') + 'px';
                trafficLayerToggler.style.height = gmControl.getAttribute('controlheight') + 'px';
            }

            if (this.mapModel.showTrafficLayerIcon) {
                trafficLayerToggler.style.display = "block";
            } else {
                trafficLayerToggler.style.display = "none";
            }
            controlArray.push(trafficLayerToggler);

            this.pushMapControls('LEFT_TOP', controlArray);
            // this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(trafficLayerToggler);
        }

    }

    pushMapControls(position, controls): void {
        if (!this.map) {
            return;
        }
        const controlSpace = this.map.controls[google.maps.ControlPosition[position]];
        while (controlSpace.length > 0) {
            controlSpace.pop();
        }
        for (const c of controls) {
            controlSpace.push(c);
        }
    }

    // setCorrectMapTypeLabel() {
    //     let asd: Element = this.el.nativeElement.querySelector('div[title="Change map style"]');
    //     if (asd) {
    //         let caret = asd.querySelector('img');
    //         caret.src = 'https://maps.gstatic.com/mapfiles/arrow-down.png';
    //         if (this.map.getMapTypeId() == 'hybrid') {
    //             asd.innerHTML = 'Satellite' + caret.outerHTML;
    //         } else if (this.map.getMapTypeId() == 'roadmap') {
    //             asd.innerHTML = 'Map' + caret.outerHTML;
    //         }
    //     }
    // }

    initMapItemMarker(): void {
        // refer to global object, in certain statment the "this" would refer to custom Marker object
        const _this = this;
        if (!this.map) {
            return;
        }

        try {
            // console.debug('MapComponent: initMapItemMarker()');

            this.CustomMarker = function (latlng, map, templateVariables, mapItem) {
                this.latlng = latlng;
                this.templateVariables = templateVariables;
                this.mapItem = mapItem;
                this.setMap(map);
            };

            this.CustomMarker.prototype = new google.maps.OverlayView();

            this.CustomMarker.prototype.markerTemplateString = this.mapItemMarkerTemplate;

            this.CustomMarker.prototype.makeMarkerTemplateString = function () {
                let markerTemplateString = this.markerTemplateString;

                for (const i in this.templateVariables) {
                    if (i) {
                        let value = this.templateVariables[i];
                        // upper case for heading (vehicle name)
                        if (i == "heading") {
                            value = value.toUpperCase();
                        }
                        markerTemplateString = markerTemplateString.replace(
                            new RegExp(`\\[\\[\\s ?${i}\\s ?\\]\\]`, 'g'),
                            value
                        );
                    }
                }

                return markerTemplateString;
            };

            //added by wai bin for markerclusterer.js to resolve the getDraggable not found issue
            this.CustomMarker.prototype.getDraggable = function () { return false; };

            this.CustomMarker.prototype.draw = function () {
                let contents = this.contents;

                if (!contents) {
                    contents = this.contents = document.createElement('div');
                    contents.style.position = 'absolute';
                    contents.innerHTML = this.makeMarkerTemplateString();

                    const panes = this.getPanes();
                    panes.overlayImage.appendChild(contents);

                    if (_this.permission[modules.MAP_VEHICLE_HISTORY.value].vAccess) {
                        google.maps.event.addDomListener(contents, 'click', (event) => {
                            this.mapMarkerRouteForImeiNo(this.mapItem.imei);
                            // if (this.toggleCurrentMapItem(this.mapItem) /*|| this.isMapMqDesktop()*/) {
                            //     this.map.panTo(this.latlng);
                            //     this.mapMarkerRouteForImeiNo(this.mapItem.imei)
                            // }
                        });
                    }
                }

                const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

                if (point) {
                    contents.style.left = point.x + 'px';
                    contents.style.top = point.y + 'px';
                }
            };

            this.CustomMarker.prototype.remove = function () {
                if (this.contents) {
                    this.setMap(null);
                    this.contents.parentNode.removeChild(this.contents);
                    this.contents = null;
                }
            };

            this.CustomMarker.prototype.getPosition = function () {
                return this.latlng;
            };

            this.CustomMarker.prototype.setPosition = function (position) {

                this.latlng = position;
                if (this.latlng) {
                    // Position the overlay
                    if (this.getProjection()) {
                        const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
                        if (point && this.contents) {
                            this.contents.style.left = point.x + 'px';
                            this.contents.style.top = point.y + 'px';
                        }
                    }
                }
            };
            // this.CustomMarker.prototype.toggleCurrentMapItem = this.toggleCurrentMapItem.bind(this);
            // this.CustomMarker.prototype.isMapMqDesktop = this.isMapMqDesktop.bind(this);
            this.CustomMarker.prototype.mapMarkerRouteForImeiNo = this.mapMarkerRouteForImeiNo.bind(this);

        } catch (err) {
            if (err != 'return') {
                this.snackBar.openStandardizedErrorSnackBar(err);
            }
        }
    }

    stopLiveTripTimeout(): void {
        //clear the timeout from vehicle panel live trip
        clearTimeout(this.liveTripTimeout);
        this.liveTripTimeout = null;
        while (this.animationMarker.length) {
            this.animationMarker.pop().setMap(null);
        }

    }

    singleVehicleAnimationMoves(marker, latlngs, index, stopCurrentRefresh = false): void {

        //latlng iis undefined at first
        if (marker) {
            marker.setPosition(latlngs[index]);
        }

        // console.log("outside" + index);
        //stopCurrentRefresh as a indicator to stop previous running function, only latest triggered function need to run
        if (index != latlngs.length - 1 && !stopCurrentRefresh) {
            this.liveTripTimeout = setTimeout(() => {
                // console.log(index);
                this.singleVehicleAnimationMoves(marker, latlngs, index + 1, this.stopCurrentLiveMarkerRefreshing);

            }, this.animationWait);

        } else {
            //done running or get stopped by stopCurrentLiveMarkerRefreshing
            this.currentRunning = false;
        }

    }

    setMapItemMarkers(): void {
        if (!this.map) {
            return;
        }

        try {
            // console.debug('MapComponent: setMapItemMarkers()');

            if (!this.CustomMarker) {
                this.initMapItemMarker();
            }

            const mapItems = this.mapModel.currentItems;
            const mapBounds = new google.maps.LatLngBounds();

            //remove all popups
            this.closePopUp();

            //remove all previous markers
            while (this.mapModel.itemMarkers.length || this.animationMarker.length) {
                if (this.mapModel.itemMarkers.length) {
                    this.mapModel.itemMarkers.pop().setMap(null);
                }

                if (this.animationMarker.length) {
                    this.animationMarker.pop().setMap(null);
                }
            }

            if (this.markerClusters) {
                MapUtil.clearMarkerClusters(this.markerClusters);
            }

            if (!mapItems || !mapItems.length) {
                return;
            }
            for (let i = 0; i < mapItems.length; i++) {
                const templateVariables = {
                    color: '',
                    heading: mapItems[i].vehicleName,
                    imageStyle: '',
                    zindex: '',
                    alertImageStyle: ''
                };

                // console.log('vehicle: ' + mapItems[i].vehicleName + ', status: ' + mapItems[i].status);

                const statusConstant = STATUS[mapItems[i].status.toLowerCase()];
                if (statusConstant) {
                    templateVariables['color'] = statusConstant.COLOR;
                    templateVariables['zindex'] = statusConstant.ZINDEX;
                }

                // Parked with alert icon at vehicle marker
                if (mapItems[i].status.toLowerCase() == STATUS.PARKANDALERT) {
                    templateVariables.alertImageStyle = `center url(/assets/images/ng-components/past-trip/icon-has-alert-red.svg); background-size: cover`;
                }

                // For some reason, this needs to be explicitly converted into a boolean,
                // the truthy/falsey outcome doesn't work as intended.
                if (Boolean(mapItems[i].image)) {
                    templateVariables.imageStyle = `url(data:image/jpeg;base64,${mapItems[i].image}); background-size: cover`;
                }

                if (mapItems[i].coordinates.lat != 0 && mapItems[i].coordinates.lng != 0) {
                    this.mapModel.itemMarkers.push(new this.CustomMarker(
                        new google.maps.LatLng(
                            mapItems[i].coordinates.lat,
                            mapItems[i].coordinates.lng
                        ),
                        this.map,
                        templateVariables,
                        mapItems[i]
                    ));
                    // Default Google Map Marker setup
                    // const option = {
                    //     position: new google.maps.LatLng(mapItems[i].coordinates.lat, mapItems[i].coordinates.lng),
                    //     map: this.map,
                    //     optimized: true
                    // }
                    // this.mapModel.itemMarkers.push(new google.maps.Marker(
                    //     option
                    // ));

                    mapBounds.extend({
                        lat: mapItems[i].coordinates.lat,
                        lng: mapItems[i].coordinates.lng
                    });
                }
            }

            // create marker clusters for vehicle markers (only if mobile app or mobile view)
            if (DomUtil.isMobileApp(this.platform) || DomUtil.isMobileOrTabletView() || mapItems.length > 150) {
                this.markerClusters = MapUtil.createMarkerClusters(this.map, this.mapModel.itemMarkers, 'LIVE_TRIP');
            }

            /*|| !this.fixMapView()*/
            if (!this.mapModel.isRefresh) {
                this.map.fitBounds(mapBounds);
                if (this.mapModel.itemMarkers.length == 1) {
                    //default for single marker tracking - zoom out a little bit
                    const zoom = LocalStorageUtil.localStorageGet('mapZoom');
                    this.fixMapZoom(parseInt(zoom) - 4);
                }
            } else if (this.mapModel.itemMarkers.length == 1) {
                // console.debug('FIX MAP VIEW - FIT BOUND');
                const zoom = LocalStorageUtil.localStorageGet('mapZoom');
                this.map.fitBounds(mapBounds);
                this.fixMapZoom(zoom);
            }
            // else {
            //   console.debug('FIX MAP VIEW - FIT ZOOM ONLY');
            //   let zoom = LocalStorageUtil.localStorageGet('mapZoom');
            //   this.fixMapZoom(zoom);
            // }

            console.debug('HIDING SPINNER FROM setMapItemMarkers()');
            this.smartCloseSpinner();
        } catch (err) {
            if (err != 'return') {
                this.snackBar.openStandardizedErrorSnackBar(err);
            }
        }
    }
    setMapItemMarkersWithAnimation(): void {
        if (!this.map) {
            return;
        }

        try {

            // console.debug('MapComponent: setMapItemMarkers()');

            if (!this.CustomMarker) {
                this.initMapItemMarker();
            }

            const mapItems = this.mapModel.currentItems;
            const mapBounds = new google.maps.LatLngBounds();
            const prevMapItems = this.perviousMapModel.currentItems;

            // get the coordinates between two points (frame)
            if (prevMapItems.length != 0) {
                const prevFoundItem = prevMapItems.find(prev => prev.vehicleId === mapItems[0].vehicleId);
                const fromLat = prevFoundItem.coordinates.lat;
                const fromLng = prevFoundItem.coordinates.lng;
                const toLat = mapItems[0].coordinates.lat;
                const toLng = mapItems[0].coordinates.lng;

                this.frameAnimation = [];
                let curLat = 0;
                let curLng = 0;
                const spd = 1.5;
                // FORK // Increase percent * speed
                for (let percent = 0; percent < 1; percent += (0.0025 * spd)) {
                    curLat = fromLat + percent * (toLat - fromLat);
                    curLng = fromLng + percent * (toLng - fromLng);
                    this.frameAnimation.push(new google.maps.LatLng(curLat, curLng));
                }
            }

            //remove all popups
            this.closePopUp();

            //remove all previous markers
            while (this.mapModel.itemMarkers.length || this.animationMarker.length) {
                // Clean Vehicle Listing Marker
                if (this.mapModel.itemMarkers.length) {
                    this.mapModel.itemMarkers.pop().setMap(null);
                }
                // Clean Animation Marker
                if (this.animationMarker.length) {
                    this.animationMarker.pop().setMap(null);
                }
            }

            if (this.markerClusters) {
                MapUtil.clearMarkerClusters(this.markerClusters);
            }

            if (!mapItems || !mapItems.length) {
                return;
            }

            const templateVariables = {
                color: '',
                heading: mapItems[0].vehicleName,
                imageStyle: '',
                zindex: '',
                alertImageStyle: ''
            };

            // console.log('vehicle: ' + mapItems[i].vehicleName + ', status: ' + mapItems[i].status);

            const statusConstant = STATUS[mapItems[0].status.toLowerCase()];
            if (statusConstant) {
                templateVariables['color'] = statusConstant.COLOR;
                templateVariables['zindex'] = statusConstant.ZINDEX;
            }

            // Parked with alert icon at vehicle marker
            if (mapItems[0].status.toLowerCase() == STATUS.PARKANDALERT) {
                templateVariables.alertImageStyle = `center url(/assets/images/ng-components/past-trip/icon-has-alert-red.svg); background-size: cover`;
            }

            // For some reason, this needs to be explicitly converted into a boolean,
            // the truthy/falsey outcome doesn't work as intended.
            if (Boolean(mapItems[0].image)) {
                templateVariables.imageStyle = `url(data:image/jpeg;base64,${mapItems[0].image}); background-size: cover`;
            }
            // console.log("marker");
            if (mapItems[0].coordinates.lat != 0 && mapItems[0].coordinates.lng != 0) {

                this.mapModel.itemMarkers.push(new this.CustomMarker(
                    new google.maps.LatLng(
                        mapItems[0].coordinates.lat,
                        mapItems[0].coordinates.lng
                    ),
                    null,
                    templateVariables,
                    mapItems[0]
                ));
                mapBounds.extend({
                    lat: mapItems[0].coordinates.lat,
                    lng: mapItems[0].coordinates.lng
                });

                if (this.animationMarker.length === 0) {
                    // animation marker
                    this.animationMarker.push(new this.CustomMarker(
                        new google.maps.LatLng(
                            mapItems[0].coordinates.lat,
                            mapItems[0].coordinates.lng
                        ),
                        null,
                        templateVariables,
                        mapItems[0]
                    ));
                }

            }

            //animation
            if (this.frameAnimation !== undefined) {
                if (this.animationMarker[0] !== undefined) {
                    // console.log(this.animationMarker[0]);
                    this.animationMarker[0].setMap(this.map);
                    //set 1 sec delay, so that stopCurrentLiveMarkerRefreshing can stop previous running singleVehicleAnimationMoves function
                    setTimeout(() => {
                        //set to false else singleVehicleAnimationMoves cannot run for current trigger
                        this.stopCurrentLiveMarkerRefreshing = false;
                        //currentRunning use to prevent concurrent run. Ex. 2 triggering come to same line of code at above
                        if (!this.currentRunning) {
                            this.currentRunning = true;
                            this.singleVehicleAnimationMoves(this.animationMarker[0], this.frameAnimation, this.animationIndex);
                        }
                    }, 1000);
                }
            }

            // this.mapModel.itemMarkers[0].CustomMarker
            // create marker clusters for vehicle markers (only if mobile app or mobile view)
            if (DomUtil.isMobileApp(this.platform) || DomUtil.isMobileOrTabletView()) {
                this.markerClusters = MapUtil.createMarkerClusters(this.map, this.mapModel.itemMarkers, 'LIVE_TRIP');
            }

            /*|| !this.fixMapView()*/
            if (!this.mapModel.isRefresh) {
                this.map.fitBounds(mapBounds);
                if (this.mapModel.itemMarkers.length == 1) {
                    //default for single marker tracking - zoom out a little bit
                    const zoom = LocalStorageUtil.localStorageGet('mapZoom');
                    this.fixMapZoom(parseInt(zoom) - 4);
                }
            } else if (this.mapModel.itemMarkers.length == 1) {
                // console.debug('FIX MAP VIEW - FIT BOUND');
                const zoom = LocalStorageUtil.localStorageGet('mapZoom');
                this.map.fitBounds(mapBounds);
                this.fixMapZoom(zoom);
            }
            // else {
            //   console.debug('FIX MAP VIEW - FIT ZOOM ONLY');
            //   let zoom = LocalStorageUtil.localStorageGet('mapZoom');
            //   this.fixMapZoom(zoom);
            // }

            console.debug('HIDING SPINNER FROM setMapItemMarkersWithAnimation()');
            this.smartCloseSpinner();
        } catch (err) {
            if (err != 'return') {
                this.snackBar.openStandardizedErrorSnackBar(err);
            }
        }
    }

    mapMarkerRouteForImeiNo(imeiNo): void {
        this.routerProxyService.navigate(['', { outlets: { leftbar: ['vehicle', imeiNo] } }]);
    }

    async initTripRoute(tripId, vehicleId, driverId, startTime, endTime): Promise<void> {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initTripRoute()');
        try {
            const startTimeCounter = moment();
            this.mapModel.eachSnapDataViolationIndex = [];

            //create new mapModel object for post trip & rerender clean map
            const newMapModel = this.createMapModelForPostTrip();
            this.cleanMap(newMapModel);
            this.initMapElements();

            let tripDetailData = null;
            const getFullTripBySplitCounter = moment();
            const dateStartTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss.SSS');
            const dateEndTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss.SSS');
            const chkDate = dateStartTime.isAfter(dateEndTime);
            let temp;
            const chkDuration = moment.duration(dateEndTime.diff(dateStartTime)).asSeconds();

            let tripDetailResult: any = null;
            const staStpAdd = "BOTH";
            let staStpAddResult = {};

            if (chkDate) {
                temp = startTime;
                startTime = endTime;
                endTime = temp;

            }

            // split API call when the duration > 3 hours
            // if (chkDuration > 10800) {
            //     const ttlSplitCall = Math.ceil(chkDuration / 3600);
            //     let newStartTime = dateStartTime;
            //     const promiseDetailTripArr = [];
            //     for (let i = 0; i < ttlSplitCall; i++) {
            //         if (i > 0) {
            //             newStartTime = newStartTime.add(1, 'seconds');
            //         }
            //         if (i == 0) {
            //             staStpAdd = "START";
            //         } else if (i == (ttlSplitCall - 1)) {
            //             staStpAdd = "END";
            //         } else {
            //             staStpAdd = null;
            //         }
            //         let newEndTime = moment(dateStartTime, 'YYYY-MM-DD HH:mm:ss.SSS').add(3600 * (i + 1), 'seconds');
            //         if (moment.duration(newEndTime.diff(dateEndTime)).asSeconds() > 0) {
            //             newEndTime = dateEndTime;
            //         }
            //         promiseDetailTripArr.push(this.tripsService.getTripDetails(tripId, newStartTime.format('YYYY-MM-DD HH:mm:ss.SSS'),
            //             newEndTime.format('YYYY-MM-DD HH:mm:ss.SSS'), staStpAdd));
            //         newStartTime = newEndTime;
            //     }

            //     let trip_det: any = {};

            //     await Promise.all(promiseDetailTripArr)
            //         .then((result) => {
            //             trip_det = result[0].trip_det;
            //             let maxTimeElapsed: number = 0;
            //             let totalTimeElapsed: number = 0;
            //             result.forEach((eachResult, index) => {
            //                 if (index != 0) {
            //                     trip_det.resultSize = trip_det.resultSize + eachResult.trip_det.resSize;
            //                     trip_det.totalAPICalls = ttlSplitCall + " Times";
            //                     // trip_det.dynamoCallsMade = trip_det.dynamoCallsMade + eachResult.trip_det.dynamoCallsMade;
            //                     if (maxTimeElapsed < parseFloat(eachResult.trip_det.tElapsed)) {
            //                         maxTimeElapsed = parseFloat(eachResult.trip_det.tElapsed);
            //                     }
            //                     trip_det.maxTimeElapsed = maxTimeElapsed.toFixed(3) + 's';
            //                     totalTimeElapsed += parseFloat(eachResult.trip_det.tElapsed);
            //                     trip_det.totalTimeElapsed = totalTimeElapsed.toFixed(3) + 's';
            //                     trip_det.avgTimeElapsed = (totalTimeElapsed / ttlSplitCall).toFixed(3) + 's/API Calls';
            //                     trip_det.tripDetails = trip_det.tripDet.concat(eachResult.trip_det.tripDet);
            //                 }
            //                 if (index == 0) {
            //                     staStpAddResult = Object.assign(staStpAddResult, result[index].trip_det.add);
            //                 }
            //                 if (index == (result.length - 1)) {
            //                     staStpAddResult = Object.assign(staStpAddResult, result[index].trip_det.add);
            //                 }
            //             });
            //             delete trip_det.tElapsed;
            //         })/* .catch((err) => {
            //             console.log(err);
            //             throw ('return');
            //         }) */;
            //     tripDetailResult = {
            //         trip_details: trip_det
            //     };
            // } else {
            tripDetailResult = await this.tripsService.getTripDetails(tripId, vehicleId, startTime, endTime, staStpAdd);
            staStpAddResult = tripDetailResult.trip_det.add;
            // }

            const getFullTripBySplitCounterEnd = moment();
            const getFullTripBySplitCounterTimeElapsed = moment.duration(getFullTripBySplitCounterEnd.diff(getFullTripBySplitCounter)).asSeconds();
            console.debug("Time Complete Get Past Trip: " + getFullTripBySplitCounterTimeElapsed + 's');

            if (tripDetailResult) {
                tripDetailData = tripDetailResult.trip_det;
            } else {
                // console.debug("Error occured when getting Trip's Details");
                // this.initEmptyMap();
                throw 'return';
            }

            if (tripDetailData.tripDet.length <= 0) {
                // console.debug("Trip's Details Not Found");
                // this.initEmptyMap();
                const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.INFO_UNDER_PROCESSING);
                this.snackBar.openGenericSnackBar(msg);
                throw 'return';
            }

            this.tripDetail.data = tripDetailData;
            this.tripDetail.address = staStpAddResult;

            //Remove those coordinate is 0 for lat & lng
            this.tripDetail.data.tripDet = this.tripDetail.data.tripDet.filter(detail => {
                if (detail.coor.lat != 0 && detail.coor.lng != 0) {
                    return detail;
                }
            });

            // Log idling info (for sync up with dynamoDb use)
            // const idleObj = {
            //     tripId: null,
            //     fakeIdlingGpsList: null
            // };
            // idleObj.fakeIdlingGpsList = [];
            // this.tripDetail.data.tripDet.slice(0).forEach(item => {
            //     if (item.vio && item.vio.isIdling) {
            //         idleObj.tripId = this.tripDetail.data.tripId;
            //         idleObj.fakeIdlingGpsList.push(item.gpsTimestamp);
            //     }
            // });
            // idleObj.fakeIdlingGpsList = idleObj.fakeIdlingGpsList.join(',');
            // if (idleObj.tripId) {
            //     console.log('[IDLING] ' + JSON.stringify(idleObj));
            // }

            //Group up detail info with identical coordinates
            this.tripDetail.data.tripDet = this.joinTripDetailInfoForSameCoordinates(this.tripDetail.data.tripDet.slice(0));
            // let newArrayFromMidPoint = [];
            let newArrayFromTtlRecord = [];
            // console.time('midPoint');
            // this.tripDetail.data.tripDet.map((eachDetail, index) => {
            //     if(index < this.tripDetail.data.tripDet.length - 1) {
            //         let nextDetail = this.tripDetail.data.tripDet[index + 1];
            //         let numLatLngReq = MapUtil.numSubLatLngReq(eachDetail.coor, nextDetail.coor);
            //         newArrayFromMidPoint = newArrayFromMidPoint.concat(MapUtil.genLatLngByMidpoint(eachDetail.coor, nextDetail.coor, numLatLngReq.performCount, index));
            //     }
            // });
            // this.tripDetailsInterpolated1 = newArrayFromMidPoint;
            // console.timeEnd('midPoint');
            // console.time('ttlRecord')

            const checkGenLatLngByTtlRecord = moment();
            // console.log("Detail Trip Size: " + this.tripDetail.data.tripDet.length);
            // if(this.tripDetail.data.tripDet.length < 70000) {
            this.tripDetail.data.tripDet.map((eachDetail, index) => {
                if (index < this.tripDetail.data.tripDet.length - 1) {
                    const nextDetail = this.tripDetail.data.tripDet[index + 1];
                    // reduce number of sub latlng for polyline onClick popUp show speed
                    const reduceNumSubLatLngReq = MapUtil.ttlNumSubLatLngReq(this.tripDetail.data.tripDet.length);
                    // define number of sub latlng require between 2 point
                    const numLatLngReq = MapUtil.numSubLatLngReq(eachDetail.coor, nextDetail.coor, reduceNumSubLatLngReq);
                    // gen sub latlng point between 2 differnt point
                    newArrayFromTtlRecord = newArrayFromTtlRecord.concat(MapUtil.genLatLngByTtlRecordRequired(eachDetail.coor, nextDetail.coor, numLatLngReq.totalRecord, index));
                }
            });
            this.tripDetailsInterpolated = newArrayFromTtlRecord;
            // } else {
            // this.tripDetailsInterpolated = this.tripDetail.data.tripDet;
            // }

            const checkGenLatLngByTtlRecordEnd = moment();
            const checkGenLatLngByTtlRecordTimeElapsed = moment.duration(checkGenLatLngByTtlRecordEnd.diff(checkGenLatLngByTtlRecord)).asSeconds();
            console.debug("Time Complete Gen Popup Sub LatLng Point: " + checkGenLatLngByTtlRecordTimeElapsed + 's');

            /* console.timeEnd('ttlRecord');
            Testing for midpoint & totalRecord
            let location1 = {
                lat: 3.0799765,
                lng: 101.58458929999999
            }
            let location2 = {
                lat: 3.080098,
                lng: 101.584695
            }
            let test = MapUtil.genLatLngByMidpoint(location1, location2, 5, -1);
            console.log(test);
            let test2 = MapUtil.genLatLngByTtlRecordRequired(location1, location2, 30, -1);
            console.log(test2);
            let test3 = MapUtil.getDistanceFromLatLonInKm(location1.lat, location1.lng, location2.lat, location2.lng);
            console.log(test3); */

            // coordinates - store coordinates from selected triptions: coord.violations, speed: coord.speed, isPoorGps: coord.isPoorGps };
            this.mapModel.coordinates = this.tripDetail.data.tripDet.map(coord => {
                if (coord.vio) {
                    return { coordinates: coord.coor, violations: coord.vio, speed: coord.spd, isPoorGps: coord.isPoorGps };
                } else {
                    return { coordinates: coord.coor, speed: coord.spd, isPoorGps: coord.isPoorGps };
                }
            });
            const tempCoordinates = this.mapModel.coordinates;
            /* DRAW ROUTE ON MAP */
            // Full route for Past Trip
            const bounds = new google.maps.LatLngBounds();

            const location = [];
            // Poor Gps + Speeding Routes for Past Trip
            const prGpsSpd: any = [[]];
            let prGpsSpdRouteIndex: number = 0;
            prGpsSpd[prGpsSpdRouteIndex] = [];
            let prGpsSpdCount: number = 0;
            let foundPrGpsSpd: boolean = false;

            // Poor Gps routes for Past Trip
            const prGps: any = [[]];
            let prGpsRouteIndex: number = 0;
            prGps[prGpsRouteIndex] = [];
            let prGpsCount: number = 0;
            let foundPrGps: boolean = false;

            // Speeding routes for Past Trip
            const speedingViolation: any = [[]];
            let spdRouteIndex: number = 0;
            speedingViolation[spdRouteIndex] = [];
            let speedingCount: number = 0;
            let foundSpeeding: boolean = false;

            // Normal route for Past Trip
            const normalRoute: any = [[]];
            let normalRouteIndex: number = 0;
            normalRoute[normalRouteIndex] = [];
            let normalRouteCount: number = 0;
            let isNormalRoute: boolean = false;

            /*
             * NOTE: snap to feature had been turn down since 2019-Jan-10
             *       so the code below might not support the latest version of the system
             */
            if (this.useSnap) {
                // console.debug("MapComponent: Snap to Road is On");
                this.mapModel.useSnapData = this.tripDetail.data;
                let coordinatesBuckets = [[]];
                const snapToRoadViolation = [];
                if (tempCoordinates.length <= 100) {
                    // console.debug("MapComponent: less than 100 coordinates.");
                    for (let i = 0; i < tempCoordinates.length; i++) {
                        coordinatesBuckets[0].push({ coordinate: tempCoordinates[i].coordinates, index: i });
                    }
                } else {
                    // console.debug("MapComponent: more than 100 coordinates.");
                    //first record is mandatory record, is push before the for loop
                    coordinatesBuckets = [[{ coordinate: tempCoordinates[0].coordinates, index: 0 }]];

                    let bucketIndex = 0;
                    let previousArrayLastLocation = {};
                    for (let i = 1; i < tempCoordinates.length; i++) {
                        if (coordinatesBuckets[bucketIndex].length == 0) {//append the previous array last location
                            coordinatesBuckets[bucketIndex].push(previousArrayLastLocation);
                        } else if (tempCoordinates[i].violations) {
                            snapToRoadViolation.push(tempCoordinates[i]);
                            coordinatesBuckets[bucketIndex].push({ coordinate: tempCoordinates[i].coordinates, index: i });
                        } else if (i % this.modulusValue == 0) {
                            coordinatesBuckets[bucketIndex].push({ coordinate: tempCoordinates[i].coordinates, index: i });
                        } else if (i == tempCoordinates.length - 1) {//last record is mandatory record
                            coordinatesBuckets[bucketIndex].push({ coordinate: tempCoordinates[i].coordinates, index: i });
                        }
                        if (coordinatesBuckets[bucketIndex].length == 100) {
                            previousArrayLastLocation = { coordinate: tempCoordinates[i].coordinates, index: i }; //Use to get last location of each 100 record array
                            bucketIndex++;
                            coordinatesBuckets[bucketIndex] = [];
                        }
                    }
                }

                this.mapModel.mapCoordinates = await this.getAccurateRoute(coordinatesBuckets);
                let startIndex = 0;
                let endIndex = 0;
                for (let i = 0; i < this.mapModel.eachSnapDataViolationIndex.length; i++) {
                    const violations = this.mapModel.eachSnapDataViolationIndex[i].violations;
                    let snapIndex = this.mapModel.eachSnapDataViolationIndex[i].index;
                    const coordinateList = this.mapModel.mapCoordinates;
                    if (violations && violations.isSpeeding != undefined && (violations.isSpeeding > 0 || violations.isSpeeding === "isRepeated")) {
                        if (!foundSpeeding) {
                            startIndex = snapIndex;
                        } else {
                            if (i < this.mapModel.eachSnapDataViolationIndex.length //if not last record
                                && this.mapModel.eachSnapDataViolationIndex[i + 1].violations //if next record have violation
                                && this.mapModel.eachSnapDataViolationIndex[i + 1].violations.isSpeeding // if next record has isSpeeding
                                && (this.mapModel.eachSnapDataViolationIndex[i].oriIndex + 1) != this.mapModel.eachSnapDataViolationIndex[i + 1].oriIndex) { // if next record oriIndex is the index after this record
                                //mean current speeding record not end yet
                                endIndex = 0;
                            } else {
                                endIndex = snapIndex;
                            }
                        }
                        foundSpeeding = true;
                        //only happen when next records has isSpeeding with the oriIndex maybe far away from here
                        if (endIndex != 0) {
                            const speedingCoordinates = this.mapModel.mapCoordinates.slice(startIndex, endIndex);
                            speedingViolation[spdRouteIndex] = speedingCoordinates.map(coordinate => {
                                return { lat: coordinate.latitude, lng: coordinate.longitude };
                            });
                            speedingCount = 0;
                            startIndex = 0;
                            endIndex = 0;
                            spdRouteIndex++;
                            speedingViolation[spdRouteIndex] = [];
                            foundSpeeding = false;
                        }
                        speedingCount++;
                    } else if (foundSpeeding) {

                        if (speedingCount === 1) {
                            endIndex = snapIndex++;
                        }
                        const speedingCoordinates = this.mapModel.mapCoordinates.slice(startIndex, endIndex);
                        speedingViolation[spdRouteIndex] = speedingCoordinates.map(coordinate => {
                            return { lat: coordinate.latitude, lng: coordinate.longitude };
                        });
                        speedingCount = 0;
                        startIndex = 0;
                        endIndex = 0;
                        spdRouteIndex++;
                        speedingViolation[spdRouteIndex] = [];
                        foundSpeeding = false;
                    }
                }
                for (let i = 0; i < this.mapModel.mapCoordinates.length; i++) {
                    location.push({ lat: this.mapModel.mapCoordinates[i].latitude, lng: this.mapModel.mapCoordinates[i].longitude });
                    bounds.extend({ lat: this.mapModel.mapCoordinates[i].latitude, lng: this.mapModel.mapCoordinates[i].longitude });
                }
            } else {
                // console.debug("MapComponent: Snap to Road is Off");
                // This will be use when Snap to Road is not in used
                // Split route to plot different type of polyline
                for (let i = 0; i < this.mapModel.coordinates.length; i++) {
                    // let eachCoordinate = this.mapModel.coordinates[i];
                    const violations = this.mapModel.coordinates[i].violations;
                    const coordinates = this.mapModel.coordinates[i].coordinates;
                    const isPoorGps = this.mapModel.coordinates[i].isPoorGps;
                    bounds.extend({ lat: coordinates.lat, lng: coordinates.lng });
                    if (isPoorGps >= 1 && violations && (violations.isSpd >= 1)) {
                        if (foundPrGps) {
                            // Poor Gps
                            prGps[prGpsRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            prGpsCount = 0;
                            prGpsRouteIndex++;
                            prGps[prGpsRouteIndex] = [];
                            foundPrGps = false;
                        } else if (foundSpeeding) {
                            // Speeding
                            speedingViolation[spdRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            speedingCount = 0;
                            spdRouteIndex++;
                            speedingViolation[spdRouteIndex] = [];
                            foundSpeeding = false;
                        } else if (isNormalRoute) {
                            // Normal Route
                            normalRoute[normalRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            normalRouteCount = 0;
                            normalRouteIndex++;
                            normalRoute[normalRouteIndex] = [];
                            isNormalRoute = false;
                        }
                        foundPrGpsSpd = true;
                        prGpsSpd[prGpsSpdRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                        prGpsSpdCount++;
                    } else if (isPoorGps >= 1) {
                        if (foundPrGpsSpd) {
                            // Poor Gps + Speeding
                            prGpsSpd[prGpsSpdRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            prGpsSpdCount = 0;
                            prGpsSpdRouteIndex++;
                            prGpsSpd[prGpsSpdRouteIndex] = [];
                            foundPrGpsSpd = false;
                        } else if (foundSpeeding) {
                            // Speeding
                            speedingViolation[spdRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            speedingCount = 0;
                            spdRouteIndex++;
                            speedingViolation[spdRouteIndex] = [];
                            foundSpeeding = false;
                        } else if (isNormalRoute) {
                            // Normal Route
                            normalRoute[normalRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            normalRouteCount = 0;
                            normalRouteIndex++;
                            normalRoute[normalRouteIndex] = [];
                            isNormalRoute = false;
                        }
                        foundPrGps = true;
                        prGps[prGpsRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                        prGpsCount++;
                    } else if (violations && (violations.isSpd >= 1)) {
                        if (foundPrGpsSpd) {
                            // Poor Gps + Speeding
                            prGpsSpd[prGpsSpdRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            prGpsSpdCount = 0;
                            prGpsSpdRouteIndex++;
                            prGpsSpd[prGpsSpdRouteIndex] = [];
                            foundPrGpsSpd = false;
                        } else if (foundPrGps) {
                            // Poor Gps
                            prGps[prGpsRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            prGpsCount = 0;
                            prGpsRouteIndex++;
                            prGps[prGpsRouteIndex] = [];
                            foundPrGps = false;
                        } else if (isNormalRoute) {
                            // Normal Route
                            normalRoute[normalRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            normalRouteCount = 0;
                            normalRouteIndex++;
                            normalRoute[normalRouteIndex] = [];
                            isNormalRoute = false;
                        }
                        foundSpeeding = true;
                        speedingViolation[spdRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                        speedingCount++;
                    } else {
                        if (foundPrGpsSpd) {
                            // Poor GPS + Speeding
                            prGpsSpd[prGpsSpdRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            prGpsSpdCount = 0;
                            prGpsSpdRouteIndex++;
                            prGpsSpd[prGpsSpdRouteIndex] = [];
                            foundPrGpsSpd = false;
                        } else if (foundPrGps) {
                            // Poor GPS
                            prGps[prGpsRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            prGpsCount = 0;
                            prGpsRouteIndex++;
                            prGps[prGpsRouteIndex] = [];
                            foundPrGps = false;
                        } else if (foundSpeeding) {
                            // Speeding
                            speedingViolation[spdRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                            speedingCount = 0;
                            spdRouteIndex++;
                            speedingViolation[spdRouteIndex] = [];
                            foundSpeeding = false;
                        }
                        isNormalRoute = true;
                        normalRoute[normalRouteIndex].push({ lat: coordinates.lat, lng: coordinates.lng });
                        normalRouteCount++;
                    }
                }
            }

            // find the longest array length
            let maxArrayIndex = normalRoute.length;
            if (maxArrayIndex <= speedingViolation.length) {
                maxArrayIndex = speedingViolation.length;
            }
            if (maxArrayIndex <= prGps.length) {
                maxArrayIndex = prGps.length;
            }
            if (maxArrayIndex <= prGpsSpd.length) {
                maxArrayIndex = prGpsSpd.length;
            }

            // For arrow icon
            const POLYLINE_ICON_PROPERTIES = {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 1,
                strokeColor: 'white',
                fillColor: 'white',
                fillOpacity: 1
            };
            this.defaultPolyLineIconProps = POLYLINE_ICON_PROPERTIES;
            const iconScale = 5;
            // testing for poorGps using dash/dot polyline
            // if (tripId == 3602) {
            //     this._tripId = tripId;
            //     iconScale = 5;
            // } else if (tripId = 3605) {
            //     this._tripId = tripId;
            //     iconScale = 0.1;
            // }

            for (let i = 0; i < maxArrayIndex; i++) {
                // normal routes
                if (i < normalRoute.length && normalRoute[i].length > 0) {
                    const normalRouteObj = {
                        routeList: normalRoute[i],
                        polyLineStyleArr: [
                            // border
                            { style: PolylineStyle.NORMAL_BORDER_PATH, icon: null, iconScale: null },
                            // actual path
                            { style: PolylineStyle.NORMAL_PATH, icon: null, iconScale: null },
                            // arrow icon
                            { style: PolylineStyle.ARROW_ICON, icon: [{ icon: POLYLINE_ICON_PROPERTIES, repeat: "20px" }], iconScale: null }
                        ]
                    };
                    this.setPolyline(normalRouteObj);
                }
                // poor gps routes
                if (i < prGps.length && prGps[i].length > 0) {
                    const poorGPSRouteObj = {
                        routeList: prGps[i],
                        polyLineStyleArr: [
                            // border
                            { style: PolylineStyle.POOR_GPS_BORDER_PATH, icon: null, iconScale: iconScale },
                            // actual path
                            { style: PolylineStyle.POOR_GPS_PATH, icon: null, iconScale: iconScale },
                        ]
                    };
                    this.setPolyline(poorGPSRouteObj);
                }
                // poor gps + speeding routes
                if (i < prGpsSpd.length && prGpsSpd[i].length > 0) {
                    const poorGPSAndSpdRouteObj = {
                        routeList: prGpsSpd[i],
                        polyLineStyleArr: [
                            // border
                            { style: PolylineStyle.POOR_GPS_AND_SPD_BORDER_PATH, icon: null, iconScale: iconScale },
                            // actual path
                            { style: PolylineStyle.POOR_GPS_AND_SPD_PATH, icon: null, iconScale: iconScale },
                        ]
                    };
                    this.setPolyline(poorGPSAndSpdRouteObj);
                }
                // speeding routes
                if (i < speedingViolation.length && speedingViolation[i].length > 0) {
                    const normalRouteObj = {
                        routeList: speedingViolation[i],
                        polyLineStyleArr: [
                            // border
                            { style: PolylineStyle.SPD_BORDER_PATH, icon: null, iconScale: null },
                            // actual path
                            { style: PolylineStyle.SPD_PATH, icon: null, iconScale: null },
                            // arrow icon
                            { style: PolylineStyle.ARROW_ICON, icon: [{ icon: POLYLINE_ICON_PROPERTIES, repeat: "20px" }], iconScale: null }
                        ]
                    };
                    this.setPolyline(normalRouteObj);
                }
            }
            this.map.fitBounds(bounds);

            const endTimeCounter = moment();
            const timeElapsed = moment.duration(endTimeCounter.diff(startTimeCounter)).asSeconds();
            console.debug("Time Complete Get & Process & Show Past Trip: " + timeElapsed + 's');
        } catch (err) {
            if (err != 'return') {
                this.snackBar.openStandardizedErrorSnackBar(err);
            }
        }
    }

    setPolyline(routeObj) {
        routeObj.polyLineStyleArr.map((eachStyle, index) => {
            const temp = PolylineStyle.setPath(this.map, eachStyle.style, routeObj.routeList, eachStyle.icon);
            const polylinePath = new google.maps.Polyline(temp);
            this.mapModel.tripRoute.push(polylinePath);

            if (index < routeObj.polyLineStyleArr.length) {
                this.addPolylineEventListener(polylinePath);
            }
        });
    }

    joinTripDetailInfoForSameCoordinates(tripDetailList) {
        let startDetail = null;
        let stoppedDetail = null;
        const joinedTripDetailList = [];
        if (tripDetailList.length > 0) {
            let joinDetail = null;
            // Incase more than 1 trip detail's status = START
            let startFlag = false;
            tripDetailList.filter((detail, index) => {
                if (detail.tripSt == 'START' && !startFlag) {
                    startDetail = detail;
                    startFlag = true;
                }
                if (detail.tripSt == 'STOPPED') {
                    stoppedDetail = detail;
                }
                //remove violations type if value = 0 & violations is empty object
                detail.violations = this.violationVerification(detail.vio, index);
                if (joinDetail === null) {
                    // 1st detail
                    joinDetail = Object.assign({}, { trip: detail, index: index });

                } else {
                    const lastDetailCoordinates = joinDetail.trip.coor;
                    const currDetailCoordinates = detail.coor;

                    if (lastDetailCoordinates.lat == currDetailCoordinates.lat && lastDetailCoordinates.lng == currDetailCoordinates.lng) {
                        // lat & lng same as previous
                        const tempViolations = Object.assign({}, joinDetail.trip.vio, detail.vio);
                        joinDetail.trip.vio = Object.assign({}, tempViolations);

                    } else {
                        // lat & lng not same as previous
                        joinedTripDetailList.push(joinDetail.trip);
                        joinDetail = Object.assign({}, { trip: detail, index: index });
                    }
                }
                //to ensure last verification record always pushed
                if (index == tripDetailList.length - 1) {
                    joinedTripDetailList.push(joinDetail.trip);
                }
            });
        }
        // Incase tripStatus = start/stopped could not be found
        startDetail = startDetail === null ? tripDetailList[0] : startDetail;
        stoppedDetail = stoppedDetail === null ? tripDetailList[tripDetailList.length - 1] : stoppedDetail;

        const firstJoinedTripDetail = joinedTripDetailList[0];
        if (startDetail.coor.lat == firstJoinedTripDetail.coor.lat && startDetail.coor.lng == firstJoinedTripDetail.coor.lng) {
            startDetail.vio = Object.assign({}, firstJoinedTripDetail.vio);
            joinedTripDetailList[0] = startDetail;
        } else {
            const temp = [startDetail];
            joinedTripDetailList[0] = temp[0];
        }

        const lastJoinedTripDetail = joinedTripDetailList[joinedTripDetailList.length - 1];
        if (stoppedDetail.coor.lat == lastJoinedTripDetail.coor.lat && stoppedDetail.coor.lng == lastJoinedTripDetail.coor.lng) {
            stoppedDetail.vio = Object.assign({}, lastJoinedTripDetail.vio);
            joinedTripDetailList[joinedTripDetailList.length - 1] = stoppedDetail;
        } else {
            joinedTripDetailList.push(stoppedDetail);
        }
        return joinedTripDetailList;
    }

    adjustTripRouteStroke(zoomLevel: any, polylines: Array<google.maps.Polyline>): void {

        //adjust route stroke weight
        if (polylines.length >= 3) {
            let zoomed_stroke = zoomLevel - 4;

            if (zoomLevel > 21) {
                zoomed_stroke += 7;
            } else if (zoomLevel > 19) {
                zoomed_stroke += 6;
            } else if (zoomLevel > 18) {
                zoomed_stroke -= 2;
            } else {
                zoomed_stroke -= 5;
            }

            let repeat_px = zoomLevel;
            /* // this set of setting is for poorGps dotted line(iconScale = 0.1);
            if (zoomLevel > 21) {
                repeat_px = zoomLevel + (repeat_px - 10) * 3;
            } else if (zoomLevel > 19) {
                repeat_px = zoomLevel + (repeat_px - 10) * 3;
            } else if (zoomLevel > 18) {
                repeat_px = zoomLevel + (repeat_px - 10) * 3;
            } else if (zoomLevel > 16) {
                repeat_px = (repeat_px * 2) - 2;
            } else if (zoomLevel > 12) {
                repeat_px = (repeat_px * 2) - 9;
            } else {
                repeat_px -= 3;
            } */

            let iconScale = 5;
            // if (this._tripId == 3602) {
            if (zoomLevel > 21) {
                iconScale = 17;
                repeat_px = zoomLevel + 10 + (repeat_px - 10) * 3;
            } else if (zoomLevel > 19) {
                iconScale = 15;
                repeat_px = zoomLevel + 10 + (repeat_px - 10) * 3;
            } else if (zoomLevel > 18) {
                iconScale = 13;
                repeat_px = zoomLevel + 5 + (repeat_px - 10) * 3;
            } else if (zoomLevel > 15) { // 16 17 18
                iconScale = 11;
                repeat_px = (repeat_px * 2) + 5;
            } else if (zoomLevel > 13) { //14 15
                iconScale = 7;
                repeat_px = (repeat_px * 2) + 3;
            } else if (zoomLevel > 12) { //13
                iconScale = zoomLevel - 10;
                repeat_px = (repeat_px * 2) - 10;
            } else {
                iconScale = 2;
                repeat_px += 6;
            }
            // }

            // min stroke = 1
            if (zoomed_stroke <= 0) {
                zoomed_stroke = 1;
            }

            for (let i = 0; i < polylines.length; i++) {
                const eachPolyline: any = polylines[i];
                // actual path's border
                if (eachPolyline.type == "BORDER") {
                    eachPolyline.setOptions({ strokeWeight: zoomed_stroke + 3 });
                    if (eachPolyline.icons) {
                        const icons = eachPolyline.icons[0];
                        icons.icon.strokeWeight = zoomed_stroke + 3;
                        icons.icon.scale = iconScale;
                        icons.repeat = repeat_px + "px";
                        eachPolyline.setOptions({ icons: [icons] });
                    }
                }
                // actual path
                if (eachPolyline.type == "PATH") {
                    eachPolyline.setOptions({ strokeWeight: zoomed_stroke });
                    if (eachPolyline.icons) {
                        const icons = eachPolyline.icons[0];
                        icons.icon.scale = iconScale;
                        icons.icon.strokeWeight = zoomed_stroke;
                        icons.repeat = repeat_px + "px";
                        eachPolyline.setOptions({ icons: [icons] });
                    }
                }
                // arrow icon
                if (eachPolyline.type == "ICON") {
                    eachPolyline.setOptions({ strokeWeight: zoomed_stroke });
                }
            }
            // //WARNING: Push sequence of polylines are crucial
            // //border path
            // polylines[0].setOptions({ strokeWeight: zoomed_stroke + 3 });
            // //path
            // polylines[1].setOptions({ strokeWeight: zoomed_stroke });
            // //alert highlights
            // for (let i = 2; i < polylines.length - 1; i++) {
            //     polylines[i].setOptions({ strokeWeight: zoomed_stroke });
            // }
            // //arrow
            // polylines[polylines.length - 1].setOptions({ strokeWeight: zoomed_stroke });

            //scale icon
            // let iconScale = 1;
            if (Boolean(this.defaultPolyLineIconProps)) {
                const iconProps = this.defaultPolyLineIconProps;
                iconProps.scale = zoomLevel / 16; //use zoom level = 16 to calibrate
                if (zoomLevel <= 13) {
                    iconProps.scale = 0; //hide arrow icon if zoom out too far
                } else if (zoomLevel <= 13) {
                    iconProps.scale -= 0.2;
                } else if (zoomLevel >= 19) {
                    iconProps.scale += (zoomLevel - 16) * 0.1;
                }
                // iconScale = iconProps.scale;
                const iconSetting = [{ icon: iconProps, repeat: "18px" }];
                polylines[polylines.length - 1].setOptions({ icons: iconSetting });
            }

            // console.debug('MapComponent: map zoom level = ' + zoomLevel);
            // console.debug('MapComponent: map route stroke weight = ' + zoomed_stroke);
            // console.debug('MapComponent: icon scale = ' + iconScale);
        }
    }

    addPolylineEventListener(googleMapsPolyline) {
        const makePopUp = this.Popup;
        const showPopUp = this.showPopUp.bind(this);
        const closePopUp = this.closePopUp.bind(this);
        const popUpContent = this.alertMarkerTemplate.cloneNode(true);
        const self = this;

        google.maps.event.addListener(googleMapsPolyline, 'click', function (e) {

            e.stop();
            let time = "---";
            let speed = "---";

            // let tripDetailPopup = self.tripDetailsInterpolated1;
            const tripDetailPopup = self.tripDetailsInterpolated;
            if (tripDetailPopup.length) {
                //get closest point
                let minDist = null;
                let closestIndex = -1;
                let tmpClosestIndex = 0;
                let tmpMinDist = null;
                tripDetailPopup.forEach((element, i) => {
                    /*
                     * For Acceptance Level
                     * x.x      => 10km unit
                     * x.xx     => 1km unit,
                     * x.xxx    => 0.1km unit,
                     * x.xxxx   => 0.01km unit,
                     * x.xxxxx  => 0.001km unit;
                     */
                    const acceptanceLevel = 0.0001;
                    const elementLat = element.location.lat;
                    const elementLng = element.location.lng;
                    const selectedLat = e.latLng.lat();
                    const selectedLng = e.latLng.lng();

                    const dist = MapUtil.getDistanceFromLatLonInKm(
                        element.location.lat.toFixed(6),
                        element.location.lng.toFixed(6),
                        e.latLng.lat().toFixed(6),
                        e.latLng.lng().toFixed(6)
                    );

                    if (selectedLat >= elementLat - acceptanceLevel && selectedLat <= elementLat + acceptanceLevel
                        && selectedLng >= elementLng - acceptanceLevel && selectedLng <= elementLng + acceptanceLevel) {
                        // get a distance upto 1 meter scale

                        if (!minDist || dist < minDist) {
                            minDist = dist;
                            closestIndex = i;
                        }
                    }

                    //get alternate closest point if none matched for above function
                    if (!tmpMinDist || dist < tmpMinDist) {
                        tmpMinDist = dist;
                        tmpClosestIndex = i;
                    }
                });
                //use alternate closest point if closestIndex still remained -1
                if (closestIndex === -1) {
                    closestIndex = tmpClosestIndex;
                }

                //Populate info for popup
                const closestDetail = self.tripDetail.data.tripDet[tripDetailPopup[closestIndex].index];
                time = Boolean(closestDetail.gts) ? moment(closestDetail.gts).format("hh:mm A") : "---";
                speed = Boolean(closestDetail.spd) ? parseInt(closestDetail.spd) + "km/h" : "---";
            } else {
                closePopUp(); //do not show popup
                return;
            }
            //Re-attach close button event
            popUpContent.childNodes[0].addEventListener("click", self.closePopUp.bind(self));
            //Pop-up Title
            popUpContent.childNodes[1].innerHTML = 'Route Info';
            //Speed
            popUpContent.childNodes[2].childNodes[0].lastChild.innerHTML = speed;
            //Time
            popUpContent.childNodes[2].childNodes[1].lastChild.innerHTML = time;
            //Address
            popUpContent.childNodes[3].style.display = 'none';

            const popped = new makePopUp(e.latLng, popUpContent);
            popped.setMap(self.map);
            showPopUp(popped);
        });
    }

    hideTripRoute(): void {

        // console.debug('MapComponent: Removing previous trip from map...');

        //remove previous routes on map
        while (this.mapModel.tripRoute.length) {
            this.mapModel.tripRoute.pop().setMap(null);
        }

        // single polyline method : depreciated
        // let existingRoute = this.mapModel.tripRoute;
        // if (existingRoute != null) {
        //   existingRoute.setMap(null);
        // }

    }

    cleanMap(newMapModel: any = null): void {
        // console.debug('MapComponent: Cleaning Google Map...');

        // if (!this.pageSpinner.isShowing()) {
        this.pageSpinnerNav.show();
        console.debug('SHOW SPINNER FROM cleanMap()');
        // }

        this.hideTripRoute(); //remove polyline route
        if (newMapModel == null) {
            const tempVisible = this.mapModel.visibleComponent;
            this.mapModel = Object.create(this.defaultMapModel);
            this.mapModel.visibleComponent = tempVisible;
        } else {
            this.mapModel = newMapModel;
        }
        this.initMapElements();
    }

    createMapModelForPostTrip(): any {

        //create new mapModel object
        const tempVisible = this.mapModel.visibleComponent;
        const newMapModel = Object.create(this.defaultMapModel);
        newMapModel.visibleComponent = tempVisible;

        //disable auto refresh on/off button for post trip
        newMapModel.isOnTrafficLayer = false;
        //disable traffic info for post trip
        newMapModel.showAutoUpdateIcon = false;
        //disable traffic button on/off for post trip
        newMapModel.showTrafficLayerIcon = false;
        //show legend on map
        newMapModel.showMapLegend = true;

        return newMapModel;
    }

    async initTripMarker(): Promise<void> {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initTripMarker()');
        try {
            if (this.tripDetail.data.tripDet && this.tripDetail.data.tripDet.length <= 0) {
                const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.INFO_LOAD_FAILED);
                this.snackBar.openGenericSnackBar(msg);
                throw 'return';
            }

            let violatedTrips = [];
            let isSpeedingFlag = false;

            /*
            * NOTE: snap to feature had been turn down since 1/10/2019
            *       so the code below might not support the latest version of the system
            */
            if (this.useSnap) {//if snap to road is use
                const tripDetails: any = this.mapModel.useSnapData.tripDet.slice(0);
                if (tripDetails[0].vio == undefined || tripDetails[0].vio != undefined) {
                    violatedTrips.push(tripDetails[0]);
                }
                //Only the detail trip that had violation will be taken
                const middleViolatedTrips = tripDetails.filter(detail => {
                    if (detail.vio) {
                        if (detail.vio.isSpd != undefined && detail.vio.isSpd === 1 && !isSpeedingFlag) {
                            isSpeedingFlag = true;
                            return detail;
                        } else {
                            if (detail.vio.isSpd === undefined) {
                                isSpeedingFlag = false;
                                return detail;
                            } else {
                                detail.vio.isSpd = "isRepeated";
                                return detail;
                            }

                        }

                    }
                });
                violatedTrips = violatedTrips.concat(middleViolatedTrips);
                //Always grab the last record for ending marker
                if (tripDetails[tripDetails.length - 1].vio == undefined
                    || tripDetails[tripDetails.length - 1].vio != undefined) {
                    violatedTrips.push(tripDetails[tripDetails.length - 1]);
                }
            } else {
                //snap to road is not use
                const tripDetails: any = this.tripDetail.data.tripDet.slice(0);
                //Always grab the 1st record for starting marker
                if (tripDetails.length > 0) {
                    if (tripDetails[0].vio != undefined || tripDetails[0].vio == undefined) {
                        violatedTrips.push(tripDetails[0]);
                    }
                    //Only the detail trip that had violation will be taken
                    let exceedTimeOfDayFound = false;
                    let exceedMileageFound = false;
                    let exceedMileageDD = null;
                    const middleViolatedTrips = [];
                    tripDetails.filter((detail, index) => {
                        //Verify violations and remove those all type value = 0 & if violations is empty object
                        detail.vio = this.violationVerification(detail.vio, index);
                        if (detail.vio == null) {
                            delete detail['vio'];
                        }

                        if (detail.vio) {
                            //To ensure exceed mileage only occur min: 0 max: 1 marker on whole day trip (Remove those repeated)
                            const exceedMileage = detail.vio.exMil;
                            if (exceedMileage && exceedMileage === 1) {
                                if (!exceedMileageFound) {
                                    exceedMileageDD = moment(detail.gts, "YYYY-MM-DD HH:mm:ss.SSS").format('DD');
                                    exceedMileageFound = true;
                                } else {
                                    delete detail.vio.exMil;
                                }
                            } else {
                                const currentViolationsTimeDD = moment(detail.gts, "YYYY-MM-DD HH:mm:ss.SSS").format('DD');
                                //Exceed mileage will only reset to false when the timestamp is the next day of current trip date
                                if (currentViolationsTimeDD > exceedMileageDD) {
                                    exceedMileageFound = false;
                                }
                            }

                            //To ensure exceed time of day only occur min: 0 max: 2 marker on detail trip (Remove those repeated)
                            const exceedTimeOfDay = detail.vio.exToD;
                            if (exceedTimeOfDay != undefined && exceedTimeOfDay === 1) {
                                if (!exceedTimeOfDayFound) {
                                    exceedTimeOfDayFound = true;
                                } else {
                                    const findOtherViolation = (
                                        detail.vio.isSpeeding == 1 ||
                                        detail.vio.isIdle == 1 ||
                                        detail.vio.geoIn == 1 ||
                                        detail.vio.geoOut == 1 ||
                                        detail.vio.exMil == 1 ||
                                        detail.vio.hA == 1 ||
                                        detail.vio.hB == 1 ||
                                        detail.vio.hC == 1);
                                    delete detail.vio['exToD'];
                                    if (!findOtherViolation) {
                                        delete detail.vio['add'];
                                    }
                                }
                            } else {
                                exceedTimeOfDayFound = false;
                            }

                            //To ensure speeding marker occur only on the 1st coordinate for every speeding paths on detail trip
                            if (detail.vio.isSpd != undefined && detail.vio.isSpd === 1 && !isSpeedingFlag) {
                                isSpeedingFlag = true;
                                middleViolatedTrips.push(detail);
                            } else {
                                if (detail.vio.isSpd === undefined) {
                                    isSpeedingFlag = false;
                                    middleViolatedTrips.push(detail);
                                } else {
                                    detail.vio.isSpd = "isRepeated";
                                    const findOtherViolation = (
                                        detail.vio.exToD == 1 ||
                                        detail.vio.isIdle == 1 ||
                                        detail.vio.geoIn == 1 ||
                                        detail.vio.geoOut == 1 ||
                                        detail.vio.exMil == 1 ||
                                        detail.vio.hA == 1 ||
                                        detail.vio.hB == 1 ||
                                        detail.vio.hC == 1);
                                    if (!findOtherViolation) {
                                        delete detail.vio["add"];
                                    }
                                    middleViolatedTrips.push(detail);
                                }
                            }

                        } else {
                            // When current detail trip dont have violation
                            if (isSpeedingFlag) {
                                isSpeedingFlag = false;
                            }

                        }
                    });
                    violatedTrips = violatedTrips.concat(middleViolatedTrips);
                    //Always grab the last record for ending marker
                    if (tripDetails[tripDetails.length - 1].vio == undefined
                        || tripDetails[tripDetails.length - 1].vio != undefined) {
                        violatedTrips.push(tripDetails[tripDetails.length - 1]);
                    }
                }
            }
            let formatType = '.png';
            let width = 27;
            let height = 38;
            if (this.useSVG) {
                formatType = '.svg';
                width = 28.5;
                height = 38;
            }

            for (let i = 0; i < violatedTrips.length; i++) {
                if (i == 0 || i == violatedTrips.length - 1) {
                    //process start/end markers
                    let title: string;
                    let markerImage: { url: string, scaledSize: google.maps.Size };
                    if (i === 0) {
                        title = 'Trip Start';
                        markerImage = {
                            url: '../assets/images/ng-components/past-trip/start' + formatType,
                            scaledSize: new google.maps.Size(width * 1.35, height * 1.35)
                        };
                    } else {
                        title = 'Trip End';
                        markerImage = {
                            url: '../assets/images/ng-components/past-trip/stop' + formatType,
                            scaledSize: new google.maps.Size(width * 1.35, height * 1.35)
                        };
                    }

                    //add marker to map
                    const markerPopup = new google.maps.Marker({//define marker for start of the trip and end of the trip
                        position: new google.maps.LatLng(violatedTrips[i].coor.lat, violatedTrips[i].coor.lng),
                        map: this.map,
                        icon: markerImage,
                        // zIndex: violatedTrips.length + 1 //let start and stop always on top
                        zIndex: 0 //let start and stop awalys at behind of violation
                    });
                    this.mapModel.itemMarkers.push(markerPopup);

                    //prepare marker popup from template
                    const makePopUp = this.Popup;
                    const showPopUp = this.showPopUp.bind(this);
                    const popUpContent = this.alertMarkerTemplate.cloneNode(true);

                    const time = Boolean(violatedTrips[i].gts) ? moment(violatedTrips[i].gts).format("hh:mm A") : "---";
                    const speed = Boolean(violatedTrips[i].spd) ? parseInt(violatedTrips[i].spd) + "km/h" : "---";

                    let address = '';
                    if (i == 0) {
                        if (this.tripDetail.address) {
                            address = Boolean(this.tripDetail.address.sAdd) ? this.tripDetail.address.sAdd : "";
                        }/*  else {
                            address = Boolean(violatedTrips[i].coordinates.address) ? violatedTrips[i].coordinates.address : "";
                        } */
                    } else {
                        if (this.tripDetail.address) {
                            address = Boolean(this.tripDetail.address.eAdd) ? this.tripDetail.address.eAdd : "";
                        }/*  else {
                            address = Boolean(violatedTrips[i].coordinates.address) ? violatedTrips[i].coordinates.address : "";
                        } */
                    }
                    //Re-attach close button event
                    popUpContent.childNodes[0].addEventListener("click", this.closePopUp.bind(this));
                    //Pop-up Title
                    popUpContent.childNodes[1].innerHTML = title;
                    //Speed
                    popUpContent.childNodes[2].childNodes[0].lastChild.innerHTML = speed;
                    //Time
                    popUpContent.childNodes[2].childNodes[1].lastChild.innerHTML = time;
                    //Address
                    if (address != '') {
                        popUpContent.childNodes[3].innerHTML = address;
                    } else {
                        popUpContent.childNodes[3].style.display = 'none';
                    }

                    google.maps.event.addListener(markerPopup, 'click', (function (markerPopup, i) {
                        return function () {
                            const popped = new makePopUp(markerPopup.getPosition(), popUpContent);
                            popped.setMap(this.map);
                            showPopUp(popped);
                        };
                    })(markerPopup, i));
                } else if (violatedTrips[i].vio) {
                    //process alert markers
                    if ((violatedTrips[i].vio.isSpd && violatedTrips[i].vio.isSpd == "isRepeated")) {
                        const temp = violatedTrips[i].vio;
                        delete temp['isSpd'];
                        violatedTrips[i].vio = temp;
                    }
                    if ((Object.getOwnPropertyNames(violatedTrips[i].vio).length !== 0)) { // maximum 250 marker for svg icons

                        const getViolationsTitle = this.getViolationTitle(violatedTrips[i].vio);

                        if (getViolationsTitle.violationImage) {
                            if (getViolationsTitle.violationImage.url && getViolationsTitle.violationImage.scaledSize) {
                                const markerPopup = new google.maps.Marker({
                                    position: new google.maps.LatLng(violatedTrips[i].coor.lat, violatedTrips[i].coor.lng),
                                    map: this.map,
                                    icon: getViolationsTitle.violationImage,
                                    zIndex: i
                                });
                                this.mapModel.itemMarkers.push(markerPopup);

                                //prepare marker popup from template
                                const makePopUp = this.Popup;
                                const showPopUp = this.showPopUp.bind(this);
                                const popUpContent = this.alertMarkerTemplate.cloneNode(true);

                                const time = Boolean(violatedTrips[i].gts) ? moment(violatedTrips[i].gts).format("hh:mm A") : "---";
                                const speed = violatedTrips[i].spd && NumberUtil.roundOff(violatedTrips[i].spd, 0) ? NumberUtil.roundOff(violatedTrips[i].spd, 0) + "km/h" : "---";
                                const title = Boolean(getViolationsTitle.title) ? getViolationsTitle.title : "Alert Event";
                                const address = Boolean(violatedTrips[i].vio.add) ? violatedTrips[i].vio.add : "";
                                // let address = "---";
                                // "99, Jalan Raya, Wilayah Persekutuan Kuala Lumpur 51200";

                                /* STRUCTURE OF DOM
                                popUpContent: {
                                    (4)childNodes: [
                                    0: { CLOSE BUTTON DOM ELEMENT},
                                    1: { TITLE DOM ELEMENT },
                                    2: { MAIN INFO DOM ELEMENT
                                        (2)childNodes: [
                                        0: { SPEED PANEL DOM
                                            firstChild: LABEL,
                                            lastChild: VALUE
                                        }
                                        1: { TIME PANEL DOM
                                            firstChild: LABEL,
                                            lastChild: VALUE
                                        }
                                        ]
                                    },
                                    3: { ADDRESS DOM ELEMENT }
                                    ]
                                }
                                */

                                //Re-attach close button event
                                popUpContent.childNodes[0].addEventListener("click", this.closePopUp.bind(this));
                                //Pop-up Title
                                popUpContent.childNodes[1].innerHTML = title;
                                //Speed
                                popUpContent.childNodes[2].childNodes[0].lastChild.innerHTML = speed;
                                if (violatedTrips[i].vio.isIdle && violatedTrips[i].vio.isIdle == 1) {
                                    const idlingDuration = DateTimeUtil.genTimeDuration(Number.isInteger(violatedTrips[i].vio.idleDur) ? violatedTrips[i].vio.idleDur : violatedTrips[i].vio.idleDur * 60, 'minute', 'hms');
                                    popUpContent.childNodes[2].childNodes[0].lastChild.innerHTML = idlingDuration;
                                    popUpContent.childNodes[2].childNodes[0].firstChild.innerHTML = "Duration";
                                }
                                //Time
                                popUpContent.childNodes[2].childNodes[1].lastChild.innerHTML = time;
                                //Address
                                if (address != '') {
                                    popUpContent.childNodes[3].innerHTML = address;
                                } else {
                                    popUpContent.childNodes[3].style.display = 'none';
                                }
                                // let contentHtml = this.alertMarkerTemplateInnerHTML;
                                // contentHtml = contentHtml.replace("[[ title ]]", getViolationsTitle.title);
                                // contentHtml = contentHtml.replace("[[ speed ]]", violatedTrips[i].speed);
                                // contentHtml = contentHtml.replace("[[ time ]]", time.format("hh:mm A"));
                                // contentHtml = contentHtml.replace("[[ address ]]", "18, Jalan Dutamas Raya, Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur 51200");
                                // popUpContent.innerHTML = contentHtml;

                                google.maps.event.addListener(markerPopup, 'click', (function (markerPopup, i) {
                                    return function () {
                                        const popped = new makePopUp(markerPopup.getPosition(), popUpContent);
                                        popped.setMap(this.map);
                                        showPopUp(popped);
                                    };
                                })(markerPopup, i));
                            }
                        }
                    }
                }
            }

            // Create marker clusters for violation markers in past trip's trip details (only if mobile app or mobile view)
            if (DomUtil.isMobileApp(this.platform) || DomUtil.isMobileOrTabletView()) {
                const violationMarkerList = this.mapModel.itemMarkers.slice(1, this.mapModel.itemMarkers.length - 1);
                this.markerClusters = MapUtil.createMarkerClusters(this.map, violationMarkerList, 'DETAIL_TRIP');
            }

        } catch (err) {
            // this.snackBar.openStandardizedErrorSnackBar(err);
            if (err != 'return') {
                this.snackBar.openStandardizedErrorSnackBar(err);
            }
        } finally {
            console.debug('HIDING SPINNER FROM initTripMarker()');
            this.smartCloseSpinner();
        }
    }

    violationVerification(violations, i): any {
        if (violations === undefined || violations === null) {
            return false;
        }
        let address = null;
        if (violations["add"] != null) {
            address = violations["add"];
        }
        let idlingDuration = null;
        if (violations["idleDur"] != null) {
            idlingDuration = violations["idleDur"];
        }

        let hasViolation = false;
        for (const key in violations) {
            if (key) {
                if (StringUtil.equalsIgnoreCase(key, "isSpd")) {
                    if (violations[key] == 0 || violations[key] == "isRepeated") {
                        continue;
                    } else {
                        hasViolation = true;
                    }
                }
                if (violations[key] == 0) {
                    continue;
                } else {
                    hasViolation = true;
                    if (StringUtil.equalsIgnoreCase(key, "isIdle")) {
                        violations["idleDur"] = idlingDuration;
                    }
                }
            }
        }

        if (hasViolation) {
            violations["add"] = address;
            return violations;
        } else {
            return null;
        }
    }

    smartCloseSpinner(): void {
        // if (this.pageSpinner.isShowing()) {
        //     this.pageSpinner.hide();
        // }
        if (this.pageSpinnerNav.isShowing()) {
            this.pageSpinnerNav.hide();
        }
    }

    showPopUp(popped: any): void {
        this.closePopUp();
        this.visiblePopUp = popped;
    }

    closePopUp(): void {
        if (Boolean(this.visiblePopUp)) {
            this.visiblePopUp.setMap(null);
        }
    }

    getViolationTitle(violations: any): { title: string, violationImage: { url: string, scaledSize: google.maps.Size } } {
        let formatType = '.png';
        let width: number = 26;
        let height: number = 38;
        if (this.useSVG) {
            formatType = '.svg';
            width = 28.5;
            height = 38;
        }
        let title = "";
        let count = 0;
        let image: { url: string, scaledSize: google.maps.Size };
        if (violations) {
            if (violations.isSpd && (violations.isSpd == 1 || violations.isSpd == true)) {
                title += "Speeding, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_Speeding' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.hA && (violations.hA == 1 || violations.hA == true)) {
                title += "Harsh Acceleration, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_HarshAcceleration' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.exToD && (violations.exToD == 1 || violations.exToD == true)) {
                title += "Exceed Time Of Day, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_TimeOftheDay' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.isIdle && (violations.isIdling == 1 || violations.isIdle == true)) {
                title += "Idle, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_Idle' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.geoIn && (violations.geoIn == 1 || violations.geoIn == true)) {
                title += "Geofence Enter, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_Geofence' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.geoOut && (violations.geoOut == 1 || violations.geoOut == true)) {
                title += "Geofence Exit, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_Geofence' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.exMil && (violations.exMil == 1 || violations.exMil == true)) {
                title += "Exceed Daily Mileage, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_DailyMileage' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.hB && (violations.hB == 1 || violations.hB == true)) {
                title += "Harsh Braking, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_HarshBrake' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.hC && (violations.hC == 1 || violations.hC == true)) {
                title += "Harsh Turn, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_HarshTurn' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.triPanic && (violations.triPanic == 1 || violations.triPanic == true)) {
                title += "Panic Alert, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_PanicButton' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.triDT && (violations.triDT == 1 || violations.triDT == true)) {
                title += "Driver Tag Alert, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_DriverTag' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
            if (violations.fuelDrp && (violations.fuelDrp == 1 || violations.fuelDrp == true)) {
                title += "Unexpected Fuel Drop, ";
                count++;
                image = {
                    url: '../assets/images/ng-components/past-trip/icon_FuelDrop' + formatType,
                    scaledSize: new google.maps.Size(width, height)
                };
            }
        }

        title = title.slice(0, -2);
        if (count > 1) {
            title += " Events";
            image = {
                url: '../assets/images/ng-components/past-trip/icon_Multiple_Alerts' + formatType,
                scaledSize: new google.maps.Size(width, height)
            };
        } else {
            title += " Event";
        }
        return { title: title, violationImage: image };
    }

    async getAccurateRoute(coordinateBuckets: any): Promise<Array<any>> {
        const startTime = moment();
        const accurateRoute = [];
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        for (const bucket of coordinateBuckets) {
            const eachBucket = [];

            for (const eachLocation of bucket) {
                eachBucket.push(`${eachLocation.coordinate.lat},${eachLocation.coordinate.lng}`);
            }
            const googlePath = `https://roads.googleapis.com/v1/snapToRoads?path=${eachBucket.join('|')}&interpolate=true&key=${GOOGLE_MAP_API_KEY}`;
            const tempRoute = await this.http.get(googlePath).toPromise();
            accurateRoute.push(tempRoute);
        }
        const finalRoute = [];
        for (let i = 0; i < accurateRoute.length; i++) {
            if (accurateRoute[i].snappedPoints !== undefined) {
                accurateRoute[i].snappedPoints.forEach((snappedPoint, index) => {
                    finalRoute.push(snappedPoint.location);
                    if (snappedPoint.originalIndex != undefined) {
                        const oriIndex = coordinateBuckets[i][snappedPoint.originalIndex].index;
                        this.mapModel.useSnapData.tripDetails[oriIndex].coordinates.lat = snappedPoint.location.latitude;
                        this.mapModel.useSnapData.tripDetails[oriIndex].coordinates.lng = snappedPoint.location.longitude;
                        if (this.mapModel.useSnapData.tripDetails[oriIndex].violations) {
                            this.mapModel.eachSnapDataViolationIndex.push({ index: index, violations: this.mapModel.useSnapData.tripDetails[oriIndex].violations, oriIndex: oriIndex });
                        }
                    }
                });
                if (i == accurateRoute.length - 1) {
                    this.mapModel.useSnapData.tripDetails[this.mapModel.useSnapData.tripDetails.length - 1].coordinates.lat = accurateRoute[i].snappedPoints[accurateRoute[i].snappedPoints.length - 1].location.latitude;
                    this.mapModel.useSnapData.tripDetails[this.mapModel.useSnapData.tripDetails.length - 1].coordinates.lng = accurateRoute[i].snappedPoints[accurateRoute[i].snappedPoints.length - 1].location.longitude;
                }
            }
        }
        // console.debug("MapComponent: Snap to Road Calculate Done");
        const endTime = moment();
        const timeUsage = moment.duration(endTime.diff(startTime)).asSeconds();
        console.debug("Total Time Complete Snap to Road: " + timeUsage + 's');
        return finalRoute;
    }

    // toggleCurrentMapItem(mapItem: any): boolean {
    //     let hasChange: boolean = false;

    //     if (this.mapModel.currentItem === mapItem
    //         && this.mapModel.currentItemIsActive) {
    //         this.mapModel.currentItemIsActive = false;
    //     } else {
    //         this.mapModel.currentItem = mapItem;
    //         this.mapModel.currentItemIsActive = true;
    //         hasChange = true;
    //     }

    //     this.ref.detectChanges();

    //     return hasChange;
    // }

    toggleTraffic(): void {
        this.mapModel.isOnTrafficLayer = !this.mapModel.isOnTrafficLayer;
        this.initTrafficLayer();
    }

    initTrafficLayer(): void {
        if (!this.map || !this.mapTrafficLayer) {
            return;
        }
        // console.debug('MapComponent: initTrafficLayer()');

        if (this.mapModel.isOnTrafficLayer) {
            this.mapTrafficLayer.setMap(this.map);
        } else {
            this.mapTrafficLayer.setMap(null);
        }
    }

    showSearchFilter(): void {
        this.searchFiltersIsActive = true;
    }

    hideSearchFilter(): void {
        this.searchFiltersIsActive = false;
    }

    toggleFilters(): void {
        if (this.filtersModel.isShown) {
            this.hideFilters();
        } else {
            this.showFilters();
        }
    }

    showFilters(): void {
        this.filtersModel.isShown = true;
    }

    hideFilters(): void {

        const animationend = [
            'animationend',
            'webkitAnimationEnd',
            'MSAnimationEnd',
            'oAnimationEnd'
        ];

        const filterElement = this.el.nativeElement.querySelector('#filters');
        const animationendCallback = () => {
            for (let i = 0; i < animationend.length; i++) {
                filterElement.removeEventListener(animationend[i], animationendCallback, false);
            }
            this.filtersModel.isShown = false;
            this.filtersModel.isAnimatingOut = false;
        };

        for (let i = 0; i < animationend.length; i++) {
            filterElement.addEventListener(animationend[i], animationendCallback, false);
        }

        this.filtersModel.isAnimatingOut = true;
    }

    applyFilters(): void {

        //Propagate filter change event throughout the app
        this.msgbus.sendMessage(new BusMessage("map", "filterchange", {
            filters: this.filtersModel
        }));
    }

    async getCompanyAllUserGroups(): Promise<void> {
        const result = await this.vehicleService.getGroupVehicleModelByCompany();
        if (result && result.vehicles) {
            this.filtersModel.groups = result.vehicles.filter(eachRes => eachRes.type === 'GROUP');
            this.filtersModel.groups.map(eachRes => delete eachRes.idList);
        }
    }

    toggleList(): void {
        this.listIsShown = !this.listIsShown;
        // if (this.listIsShown) {
        //     this.listIsShown = false;
        // } else {
        //     this.listIsShown = true;
        //     this.mapModel.currentItemIsActive = false;
        // }

        if (this.filtersModel.isShown) {
            this.hideFilters();
        }
        this.msgbus.sendMessage(new BusMessage(
            "map",
            "togglelist",
            { listIsShown: this.listIsShown }
        ));
    }

    toggleAutoUpdate(): void {
        this.mapModel.isOnAutoUpdate = !this.mapModel.isOnAutoUpdate;
        this.initRefreshTimer();
    }

    initRefreshTimer(): void {
        // console.debug('initRefreshTimer()');
        clearInterval(this.refreshPageTimer);
        if (this.mapModel.isOnAutoUpdate) {
            this.refreshPageTimer = setInterval(this.initiateRefreshEvent.bind(this), this.refreshTimerIntervalInMilliSeconds);
        }
    }

    initiateRefreshEvent(): void {
        this.msgbus.sendMessage(new BusMessage("map", "refresh", {
            filters: this.filtersModel,
            target: this.mapModel.visibleComponent,
            focusedMarker: this.mapModel.focusedMarker
        }));
    }

    // updateMapMq():void {
    //   this.mapMqIsDesktop = getComputedStyle(document.getElementById('map__mq')).fontFamily.indexOf('desktop') > -1;
    // }

    // isMapMqDesktop():boolean {
    //   return this.mapMqIsDesktop;
    // }

    // @HostListener('window:resize') onResize():void {
    //   clearTimeout(this.resizeTimer);

    //   this.resizeTimer = setTimeout(() => {
    //     this.updateMapMq();
    //   }, 100);
    // }

    /** Defines the Popup class. */
    definePopupClass(): void {
        if (!this.map) {
            return;
        }
        /**
         * A customized popup on the map.
         * @param {!google.maps.LatLng} position
         * @param {!Element} content
         * @constructor
         * @extends {google.maps.OverlayView}
         */
        this.Popup = function (position, content) {
            this.position = position;

            content.classList.add('popup-bubble-content');

            const pixelOffset = document.createElement('div');
            pixelOffset.classList.add('popup-bubble-anchor');
            pixelOffset.appendChild(content);

            this.anchor = document.createElement('div');
            this.anchor.classList.add('popup-tip-anchor');
            this.anchor.appendChild(pixelOffset);

            // Optionally stop clicks, etc., from bubbling up to the map.
            // this.stopEventPropagation();
        };

        // NOTE: google.maps.OverlayView is only defined once the Maps API has
        // loaded. That is why Popup is defined inside initMap().
        this.Popup.prototype = Object.create(google.maps.OverlayView.prototype);

        /** Called when the popup is added to the map. */
        this.Popup.prototype.onAdd = function () {
            this.getPanes().floatPane.appendChild(this.anchor);
        };

        /** Called when the popup is removed from the map. */
        this.Popup.prototype.onRemove = function () {
            if (this.anchor.parentElement) {
                this.anchor.parentElement.removeChild(this.anchor);
            }
        };

        /** Called when the popup needs to draw itself. */
        this.Popup.prototype.draw = function () {
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            // Hide the popup when it is far out of view.
            const display =
                Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
                    'block' :
                    'none';

            if (display === 'block') {
                this.anchor.style.left = divPosition.x + 'px';
                this.anchor.style.top = divPosition.y + 'px';
            }
            if (this.anchor.style.display !== display) {
                this.anchor.style.display = display;
            }
        };

        /** Stops clicks/drags from bubbling up to the map. */
        // this.Popup.prototype.stopEventPropagation = function () {
        //     var anchor = this.anchor;
        //     anchor.style.cursor = 'auto';

        //     ['click', 'dblclick', 'contextmenu', 'wheel', 'mousedown', 'touchstart',
        //         'pointerdown']
        //         .forEach(function (event) {
        //             anchor.addEventListener(event, function (e) {
        //                 e.stopPropagation();
        //             });
        //         });
        // };
    }

}