import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';

import * as Message from '../../../../constants/message';
import { SpinnerComponent } from 'src/app/components/common/spinner/spinner.component';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { DriverService } from './../../../../_services/driver/driver.service';
import { PagerService } from './../../../../_services/pager/pager.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from 'src/app/_services/router-proxy/router-proxy.service';
import { FileExportService } from 'src/app/_services/file-export/file-export.service';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { PopupCampaignService } from 'src/app/_services/campaign/popup-campaign.service';
import { ModulePermissionService } from 'src/app/_services/access-control/module-permission.service';
import { modules } from '../../../../constants/module-access.constant';
import { RESPONSE_STATUS_CODE as StatusCode } from '../../../../constants/responseStatusCode';
import { DriverTagService } from 'src/app/_services/driver-tag/driver-tag.service';

import * as StringUtil from '../../../../util/stringUtil';
import * as XLSX from 'xlsx';
import * as NumberUtil from './../../../../util/numberUtil';

@Component({
  selector: 'app-view-driver-tags',
  templateUrl: './view-driver-tags.component.html',
  styleUrls: ['./view-driver-tags.component.scss'],
  animations: [
	trigger('fadeToggle', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
		]),
		transition(':leave', [
			style({ opacity: 1 }),
			animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
		])
	])
]
})
export class ViewDriverTagsComponent implements OnInit {
	toggleMobileOpen: boolean = false;
	@ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

	isDeletingDriverTags: boolean = false;
	selectedDeleteDriverTagsList: any = [];
	selectedDeleteDriverTags: any = {};

	driverTagResultList: any = [];
	driverTagList: any = [];
	driverTagCurrentPageList: any = [];

	driverList: any = {};

	//Sort Filter
	sortField = 'DriverTagNo';
	sortAscending: boolean = true;
	

	//Pagination
	pager: any = {}; //Pager object
	pageRecordSize: number = environment.appConfig.createManage.pageRecordSize;

	@ViewChild('batchAlertPopup',{static:false}) batchAlertPopup;
    @ViewChild('batchCreateDriverTagPopup',{static:false}) batchCreateDriverTagPopup;
    @ViewChild('batchCreatePreview',{static:false}) batchCreatePreview;
    file: File;
	fileName: String = ''; // Uploaded file name
    arrayBuffer: any;
    batchCreateDriverTagFileSize = environment.appConfig.createManage.batchFileSize;
    batchDriverTagList = [];
    batchDriverTagCount: number = 0;
    batchErrorMessage = null;
	batchFileSize = 2;

	validDataList: any = []; // All valid data entries
    invalidDataList: any = []; // All invalid data entries
	dataRecordsHasError: boolean = false;
	isDownloadDone: boolean = false; // confirm client has download invalid file


	// Export Driver File
	exportDriverTagFileHeader = ['Driver Tag No', "Driver Name"];

	hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
	creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;

	constructor(
		private popupService: PopupService,
		private driverService: DriverService,
		private snackBar: SnackBarService,
        private pagerService: PagerService,
		private routerProxyService: RouterProxyService,
		private fileExportService: FileExportService,
		private popupCampaignService: PopupCampaignService,
		private mpService: ModulePermissionService,
		private driverTagService: DriverTagService
	) { }

	async ngOnInit() {
		await this.getDisplayDriverTagList();
		await this.getAllDriversWithoutDriverTag();
		await this.checkUnviewedCampaign();
		//initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_DRIVER_TAG.value);
        this.creatable = permissions[modules.CRUD_DRIVER_TAG.value].cAccess;
        this.editable = permissions[modules.CRUD_DRIVER_TAG.value].eAccess;
        this.deletable = permissions[modules.CRUD_DRIVER_TAG.value].dAccess;

	}

	async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

	async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }

        await this.getDisplayDriverTagList();
    }

	getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

	async getAllDriversWithoutDriverTag() {
        let driverList = await this.driverService.getDriverWithoutDriverTag();
        this.driverList = driverList.body.result || {};
    }

	async getDisplayDriverTagList(page: number = 1) {
		this.pageSpinner.show();
		try {
			const startRecord = ((page - 1) * this.pageRecordSize) + 1;
			this.driverTagResultList = await this.driverService.getDriverTagListByCompanyId(this.sortField, this.sortAscending);
			this.driverTagList = this.driverTagResultList.body.driverTags;

			//limit the displayed result
			let listTotalLength = this.driverTagList.length;
			this.driverTagCurrentPageList = [];

			for (let x = 0 + this.pageRecordSize*(page - 1); x < this.pageRecordSize * page && x < listTotalLength ; x++) {
				this.driverTagCurrentPageList.push(this.driverTagList[x])
			}

			this.pager = this.pagerService.getPager(this.driverTagList.length, page, this.pageRecordSize);
		} catch (err) {
			this.snackBar.openStandardizedErrorSnackBar(err);
		} finally {
			this.pageSpinner.hide();
		}
	}

	navigateToCreateDriverTag() {
        this.routerProxyService.navigate(['/create-manage/driver-tags/create']);
    }

	/* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

	cancelResetDelete(closeDeletePanel: boolean = true): void {
        this.isDeletingDriverTags = !closeDeletePanel;
        this.selectedDeleteDriverTags = {};
    }

    showDownloadPopup(popup) {
        this.popupService.show(popup);
    }
    hideDownloadPopup() {
        this.popupService.hide();
    }

	async exportDriverTagFile(fileType: string) {
		const exportDriverTagResult = await this.driverService.getDriverTagListByCompanyId();
		const exportDriverTagList = exportDriverTagResult.body.driverTags;

		const data = [];

		for (let x = 0; x < exportDriverTagList.length; x++) {
			const tempRow = [
				exportDriverTagList[x].driverTagNo || '',
				exportDriverTagList[x].driverName || '',
			]
			data.push(tempRow);
		}
		

		if (fileType === 'excel') {
			const excelData: any = this.fileExportService.mergeHeaderAndDataForExcel(data, this.exportDriverTagFileHeader);
            this.fileExportService.exportExcelFile(excelData, "Driver_Tag_Report_Excel");
		} else if (fileType === 'csv') {
			this.fileExportService.exportCsvFile(data, this.exportDriverTagFileHeader, "Driver_Tag_Report_Csv");
		} else if (fileType === 'pdf') {
			this.fileExportService.exportPdfFile(data, this.exportDriverTagFileHeader, "Driver_Tag_Report_Pdf", "Driver Tag Report", null, "landscape");
		}
	}

	// onBatchFileUpload
    batchImportDriverTag(event) {
		this.fileName = event.target.files[0].name;
		const fileType = ["xlsx", "xls", "csv"];

        // Check File Type
        if (event.target.files.length > 0 && fileType.find(result => event.target.files[0].name.includes(result))) {
			this.isDownloadDone = false;
			this.dataRecordsHasError = false;
			this.validDataList = [];
            this.invalidDataList = [];
            const fileSize = event.target.files[0].size;
            if (fileSize > this.batchCreateDriverTagFileSize * 1024 * 1024) {
                this.batchErrorMessage = `${`File Size Cannot More Than ` + this.batchCreateDriverTagFileSize + 'MB'}`;
                this.showPopup(this.batchAlertPopup);
            } else {
                this.file = event.target.files[0];
                const fileInput: any = document.getElementById('myFileInput');
                fileInput.value = null; // Clear previous file input data
                this.convertExcelToJson();
            }
        } else {
            this.batchErrorMessage = 'File Type must be in (xlsx / xls / csv)';
            this.showPopup(this.batchAlertPopup);
        }
	}

	async convertExcelToJson() {
		const fileReader = new FileReader();
		fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = async (e) => {
            this.arrayBuffer = fileReader.result;
            const data = new Uint8Array(this.arrayBuffer);
            const arr = new Array();
            for (let i = 0; i != data.length; i++) { arr[i] = String.fromCharCode(data[i]); }
            const bstr = arr.join("");
            const workbook = XLSX.read(bstr, { type: "binary", cellDates: true, cellNF: false, cellText: false });
            const first_sheet_name = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[first_sheet_name];
            // this.batchDriverTagList = XLSX.utils.sheet_to_json(worksheet, { dateNF: "DD/MM/YYYY", raw: false, defval: null });
            const batchDriverTagList = XLSX.utils.sheet_to_json(worksheet, { dateNF: "DD/MM/YYYY", raw: false, defval: null });
            this.batchDriverTagCount = batchDriverTagList.length;
            // const batchDriverTagCount = batchDriverTagList.length;

            // Check File Content
            const checkContentResult = await this.checkIsValidFileContent(batchDriverTagList);
            if (!checkContentResult.status) {
                this.batchErrorMessage = checkContentResult.errorMessage;
                this.showPopup(this.batchAlertPopup);
            } else {
                // this.showPopup(this.batchCreateDriverTagPopup);
                this.showPopup(this.batchCreatePreview);
            }
        };
	}

	async checkIsValidFileContent(batchDriverTagList) {
		const typeDef = {
            "Driver Tag No": { type: "string", required: true, maxLength:50 },
            "Driver Name": { type: "string", required: false, maxLength: 50 },
        }

		if(batchDriverTagList.length > 0) {
			const driverTagNoList: any = [];
			const driverNameList: any = [];

			for(let x = 0; x < this.batchDriverTagCount; x++) {
				const eachRecord = batchDriverTagList[x];
				const tempRow: any = {};
                const columnsStatus: any = {};				

				// Loop each column of the row for validation
                for (const fieldName in typeDef) { 

					let fieldValue = eachRecord[fieldName];
                    const fieldRule = typeDef[fieldName];

					// Normal validation
                    const response = this.validateFields(fieldValue, fieldRule, fieldName);
					columnsStatus[fieldName + ' Valid'] = !response.hasViolation;
                    columnsStatus[fieldName + ' ErrMsg'] = response.errorMsg;


					// Driver Name validation
					if(!response.hasViolation) {
						if(eachRecord[fieldName]!== undefined && StringUtil.isNotEmptyOrNull(fieldValue)){
                            if (fieldName === "Driver Name") {
								let isErr: boolean = false;
								const driverName = this.driverList.find( eachType => {
									return eachRecord[fieldName] === eachType.driverName;
								});
								if(driverNameList.includes(eachRecord[fieldName])) {
									isErr = true;
								}

								if(driverName && !isErr) {
									fieldValue = driverName.driverId;
									driverNameList.push(driverName.driverName);
								} else {
									columnsStatus[fieldName + ' Valid'] = false;
									if(isErr) {
										columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.IS_DUPLICATE.value, fieldName);
									} else {
										columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, fieldName);
									}
								}
							}

							if(fieldName === 'Driver Tag No') {																
								if(driverTagNoList.includes(eachRecord[fieldName])) {
									columnsStatus[fieldName + ' Valid'] = false;
									columnsStatus[fieldName + ' ErrMsg'] = Message.getMessage(Message.MESSAGE.IS_DUPLICATE.value, fieldName);
								} else {
									driverTagNoList.push(eachRecord[fieldName]);
								}
							}
						}
					}
                    // if (response.hasViolation) {
                    //     return { status: false, errorMessage: response.errorMsg };
                    // }
					tempRow[fieldName] = fieldValue;
				}

				//form error msg and either push to valid or invalid list
                this.batchRecordValidation(columnsStatus, tempRow, eachRecord);
			}
			if (this.invalidDataList.length > 0) {
                this.dataRecordsHasError = true;
            }
			return { status: true };
		}

		return { status: false, errorMessage: `${`Invalid File Contents`}` };
	}

	validateFields(fieldValue, fieldRule, fieldName) {
		const response = {
            hasViolation: false,
            errorMsg: ''
        };
		
		// Check Required
        if (fieldRule.required) {
            if (!Array.isArray(fieldValue) && !StringUtil.isNotEmptyOrNull(fieldValue)) {
                response.hasViolation = true;
                response.errorMsg = Message.getMessage(Message.MESSAGE.IS_EMPTY.value, fieldName);
                return response;
            }
        }

		//Check value and data format
		if(StringUtil.isNotEmptyOrNull(fieldValue)) {
			switch(fieldRule.type.toLowerCase()) {
				case 'string': {
					if(fieldRule.hasOwnProperty("maxLength") && fieldValue.length > fieldRule.maxLength){
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.MAXLENGTH.value, fieldName, fieldRule.maxLength);
                    }
                    break;
				}
				case 'number': {
					if(!NumberUtil.isNumeric(fieldValue)) {
						response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.NOT_NUMERIC.value, fieldName);
					} else if(Boolean(fieldRule.maxLength) && fieldValue.length > fieldRule.maxLength) {
						response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.OUT_OF_RANGE.value, fieldName, 1, 99);
					}
					break;
				}
				default: {
					break;
				}
			}
		}

		return response;
	}

	async batchRecordValidation(columnsStatus = null, tempRecordJSON = null, originalRecordJSON = null) {
		if (!columnsStatus || !tempRecordJSON || !originalRecordJSON) {
            return;
        }

		let isError: boolean = false;
        let errMsg: string = '';

		for (const eachStat in columnsStatus) {
            if (eachStat.includes('ErrMsg')) {
                continue;
            } else if (!columnsStatus[eachStat]) {
                const tempErrMsg = eachStat.replace('Valid', 'ErrMsg');
                isError = true;
                errMsg += columnsStatus[tempErrMsg] + ", ";
            }
        }
		errMsg = errMsg.substring(0, errMsg.lastIndexOf(", "));

		if (isError) {
            // Push original record with remarks to invalid data list (because new one replaced some string to ID)
            originalRecordJSON.remark = errMsg;
            this.invalidDataList.push(originalRecordJSON);
            return;
        }
        // Push new record to valid data list.
        this.validDataList.push(tempRecordJSON);
        return;
	}

	cleanBatchImportData() {
		this.file = null; // uploaded file
        this.batchErrorMessage = null; // Show any error while excel file validation is running
        this.fileName = ''; // Uploaded file name
        // this.totalImportRecords = 0;
        this.isDownloadDone = false; // confirm client has download invalid file
        this.dataRecordsHasError = false;
        this.validDataList = []; // All valid data entries
        this.invalidDataList = []; // All invalid data entries
	}

	generateExcelTemplate(isTemplate = true) {
		const header = [
			'Driver Tag No',
			'Driver Name'
		];

		const excelWidthConfig = [
			{ wch: 20.3}, { wch: 20.3}
		];

		let data = [];

		if(isTemplate) {
			data = [
				['SampleTagId1', 'Driver A'],
				['SampleTagId2', 'Driver B']
			];

			this.fileExportService.exportExcelFile2(
				'Batch_Drive_Tag_Template', {
					"data": {
						data: this.fileExportService.mergeHeaderAndDataForExcel(data, header),
                        widthConfig: excelWidthConfig
					},
					"available drivers": {
						data: this.fileExportService.mergeHeaderAndDataForExcel([
							...this.driverList.map(row => [row.driverName])
						], ['DriverName']),
						widthConfig: [{ wch: 40 }]
					}
				}
			);
		} else {
			const actualData = this.invalidDataList.slice(0);
			
			if (actualData && actualData.length >= 1) {
				data = [];
				header.push('Invalid Reason');

				actualData.map(eachData => {
                    const temp: any = [];
                    for (const eachFieldValues in eachData) {
                        if (eachFieldValues != 'vehicleId') {
                            temp.push(eachData[eachFieldValues]);
                        }
                    }
                    data.push(temp);
                });
			}

			const excel: any = this.fileExportService.mergeHeaderAndDataForExcel(data, header);
        	this.fileExportService.exportExcelFile(excel, "Batch_Drive_Tag_Template");
		}        
	}

	async batchCreateDriverTag() {
		const formattedReqList = [];
		this.validDataList.map( row => {
			const driverTagObj = {
				driverTagNo: row['Driver Tag No'],
				driverId: row['Driver Name'] === null ? "" : String(row['Driver Name']),
				remindMinutes: 5
			};

			formattedReqList.push(driverTagObj);
		})

		try {
			let errMessage = '';
			let allSuccess = true;
			const batchCreateResult = await this.driverTagService.batchCreateDriverTag(formattedReqList);

			if (batchCreateResult && batchCreateResult.statusCode !== StatusCode.SUCCESS.code) {
				allSuccess = false;
				errMessage += Message.getMessage(Message.MESSAGE.CREATE_FAILED.value) + ", ";
			}
			let msg = Message.getMessage(Message.MESSAGE.IMPORT_SUCCESS.value, 'Batch Data');
			if (!allSuccess) {
				errMessage = errMessage.substring(0, errMessage.lastIndexOf(", "));
				msg = errMessage;
			}
			await this.getDisplayDriverTagList();
			this.cleanBatchImportData();
			this.snackBar.openGenericSnackBar(msg);
		} catch(err) {
			this.snackBar.openStandardizedErrorSnackBar(err);
		}
	}

	async deleteDriverTag() {
		this.pageSpinner.show();
		try {
			this.selectedDeleteDriverTagsList = [];
            for (const objKey in this.selectedDeleteDriverTags) {
                if (this.selectedDeleteDriverTags[objKey] === true) {
                    this.selectedDeleteDriverTagsList.push(parseInt(objKey));
                }
            }

			const deleteRespsonse = await this.driverTagService.deleteDriverTag(this.selectedDeleteDriverTagsList);
			let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, ' Tag');
            if (deleteRespsonse && deleteRespsonse.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Driver Tag');

                this.cancelResetDelete();
                await this.getDisplayDriverTagList();
            }
            this.snackBar.openGenericSnackBar(msg);

		} catch(err) {
			this.snackBar.openStandardizedErrorSnackBar(err);
		} finally {
			this.pageSpinner.hide();
		}
	}

	countSelectedDeleteDriverTags() {
		return Object.keys(this.selectedDeleteDriverTags).filter(result => this.selectedDeleteDriverTags[result] === true).length;
    }
}
