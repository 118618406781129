import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Chart } from 'chart.js';
import * as html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Platform } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { environment } from './../../../../../../environments/environment';

// Utils
import * as moment from 'moment';
import * as EnvUtil from './../../../../../util/envUtil';
import * as DateTimeUtil from './../../../../../util/dateTimeUtil';
import * as NumberUtil from './../../../../../util/numberUtil';
import * as StringUtil from './../../../../../util/stringUtil';
import * as DomUtil from './../../../../../util/domUtil';
import * as AsyncUtil from './../../../../../util/asyncUtil';
import * as ObjectUtil from './../../../../../util/objectUtil';

// Services
import { SpinnerComponent } from './../../../../common/spinner/spinner.component';
import { PopupService } from './../../../../common/popup/popup.service';
import { RouterProxyService } from './../../../../../_services/router-proxy/router-proxy.service';
import { SnackBarService } from './../../../../../_services/snackBar/snack-bar.service';
import { TripsService } from './../../../../../_services/trips/trips.service';
import { StatsService } from './../../../../../_services/stats/stats.service';
import { GroupService } from './../../../../../_services/group/group.service';
import { NotificationService } from './../../../../../_services/notification/notification.service';
import { MaintenanceService } from './../../../../../_services/maintenance/maintenance.service';
import { ModulePermissionService } from './../../../../../_services/access-control/module-permission.service';
import { PopupCampaignService } from './../../../../../_services/campaign/popup-campaign.service';

// Constants
import { ERROR_MESSAGE as ErrorMessage } from './../../../../../constants/errorMessage';
import { DashboardCharts, DashboardChartChildObj } from './../../../dashboard/DashboardCharts';
import { DashboardColors as Colors } from './../../../dashboard/DashboardColors';
import { modules } from './../../../../../constants/module-access.constant';


const DASHBOARD_REFRESH_INTERVAL_MS: number = environment.appConfig.dashboard.refreshInterval * 1000;
const PDF_FILE_NAME_PREFIX: string = environment.appConfig.dashboard.pdfFileName;
const CHART_TICK_LABEL_MAX_LENGTH: number = 5; // chartJS truncate: axis tick label
const CHART_TOOLTIP_MAX_LENGTH: number = 22; // chartJS truncate: tooltip title

@Component({
    // selector: 'app-sale-dashboard-view',
    templateUrl: './sale-dashboard-view.component.html',
    styleUrls: ['./sale-dashboard-view.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class SaleDashboardViewComponent implements OnInit, OnDestroy, AfterViewInit {

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    isPastTripViewable: boolean = false;
    isMaintenanceViewable: boolean = false;
    isDriverEditable: boolean = false;
    isVehicleEditable: boolean = false;
    isUtilisationReportViewable: boolean = false;
    isViolationReportViewable: boolean = false;
    isFuelReportViewable: boolean = false;
    isFuelViewable: boolean = false;

    constructor(
        private el: ElementRef,
        private ref: ChangeDetectorRef,
        private statsService: StatsService,
        private tripsService: TripsService,
        private notificationService: NotificationService,
        private maintenanceService: MaintenanceService,
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private groupService: GroupService,
        private routerProxyService: RouterProxyService,
        private platform: Platform,
        private file: File,
        private fileOpener: FileOpener,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService

    ) {
        this.dashCharts = new DashboardCharts(this.ref);
    }

    recommendations = [];
    // recommendations = [
    //     {
    //         "vehicleRecommendationName": "Vincent",
    //         "otherRecommendationName": "Steven",
    //         "id": "001",
    //         "messages": [
    //             {
    //                 "msg": "Lorem Ipsum ? un testo segnaposto utilizzato nel settore della tipografia e della stampa.",
    //             }, {
    //                 "msg": "Vis iracundia evertitur et, modus veritus ea duo.",
    //             }
    //         ],
    //         "display": false
    //     },
    //     {
    //         "vehicleRecommendationName": "Ramli",
    //         "otherRecommendationName": "Najib",
    //         "id": "002",
    //         "messages": [
    //             {
    //                 "msg": "Probo erroribus interpretaris eum at.",
    //             }, {
    //                 "msg": "Has an epicuri volutpat, at quo dicit conceptam",
    //             }
    //         ],
    //         "display": false
    //     },
    //     // {
    //     //     "vehicleRecommendationName": "William",
    //     //     "otherRecommendationName": "Jesper",
    //     //     "id": "003",
    //     //     "messages": [
    //     //         {
    //     //             "msg": "Yt ridens recusabo mel. Nostrum insolens sed te, altera vocibus eum te, no mollis deterruisset nec.",
    //     //         }, {
    //     //             "msg": "Vix mandamus concludaturque an.",
    //     //         }
    //     //     ],
    //     //     "display": false
    //     // },
    //     // {
    //     //     "vehicleRecommendationName": "Jackson",
    //     //     "otherRecommendationName": "Sajiv",
    //     //     "id": "004",
    //     //     "messages": [
    //     //         {
    //     //             "msg": "Appetere accusamus definitiones cum ad.",
    //     //         }, {
    //     //             "msg": "Ea quo summo laudem, ex augue oportere sit. Ne possim deserunt has.",
    //     //         }
    //     //     ],
    //     //     "display": false
    //     // },
    //     // {
    //     //     "vehicleRecommendationName": "Amresh",
    //     //     "otherRecommendationName": "Amirul",
    //     //     "id": "005",
    //     //     "messages": [
    //     //         {
    //     //             "msg": "No eos aeterno ornatus, usu te percipit persecuti.",
    //     //         }, {
    //     //             "msg": "Vis iracundia evertitur et, modus veritus ea duo.",
    //     //         }
    //     //     ],
    //     //     "display": false
    //     // }
    // ];

    Moment = moment;

    // Page States
    todayDateMoment: moment.Moment = moment();
    // todayDate1 = "2020-02-11";
    // todayDateMoment: moment.Moment = moment(this.todayDate1); // test
    todayDate: string = this.todayDateMoment.format('YYYY-MM-DD');
    todayDateLabel: string = `(${this.todayDateMoment.format("DD/MM/YYYY")})`;
    currentWeekDayStart: string;
    currentWeekDayEnd: string;
    currentWeekRange: string;
    groupList: Array<any> = [];
    selectedGroupId: any = -1;
    isDownloadPdfClick: Boolean = false;    // true while downloading in progress
    refreshPageTimer: any;                  // keep timer object

    // STATS: Live Vehicle Tracking
    movingVehicleList: Array<any> = [];
    movingCount: number = 0;
    idlingVehicleList: Array<any> = [];
    idlingCount: number = 0;
    stoppedVehicleList: Array<any> = [];
    stoppedCount: number = 0;
    disconnectedVehicleList: Array<any> = [];
    disconnectedCount: number = 0;
    sortSessions = { // popup sorting columns
        maintenance: {
            currentSortField: '',
            currentSortAscending: true
        },
        license: {
            currentSortField: '',
            currentSortAscending: true
        },
        vehicleHealth: {
            currentSortField: '',
            currentSortAscending: true
        },
        movingVehicle: {
            currentSortField: '',
            currentSortAscending: true
        },
        idlingVehicle: {
            currentSortField: '',
            currentSortAscending: true
        },
        stoppedVehicle: {
            currentSortField: '',
            currentSortAscending: true
        },
        disconnectedVehicle: {
            currentSortField: '',
            currentSortAscending: true
        },
    };

    // STATS: Today Notifications
    vehicleHealthAlerts: any = [];
    healthAlertsCounts: number = 0;
    upcomingMaintenanceList: Array<any> = [];
    alertCountOfUpcomingMaintenance: number = 0;
    expiringLicenseList: Array<any> = [];
    alertCountOfExpiringLicense: number = 0;
    // healthAlertsTimeRange: number = 60; //second basis

    // CHARTS states
    chartJsHoverProps: any;
    chartJsAnimation: any;
    chartJsTooltipStyles: any;
    chartJsBarHoverInteraction: any;
    chartJsScaleLabelFontStyles: any;
    chartJsTicksFontStyles: any;
    chartJsTicksNumberTruncateCallbackFn: any;
    chartJsTicksStringEllipsisCallbackFn: any;
    chartJsTooltipTruncateCallbackTitleFn: any;
    dashCharts: DashboardCharts; //chart main class
    colourList: string[];
    violationDoughnutColourList: string[];
    // isDisplayFuelModule: Boolean = false;
    // planIncluded: boolean = false; // prepare for business plan

    // Charts Dropdown States
    mileageSearchType: string = 'vehicle';
    speedingSearchType: string = 'vehicle';
    idlingSearchType: string = 'vehicle';
    utilisationWeeklySearchType: string = 'distance';
    utilisationHourlySearchType: string = 'percentage';
    topFuelPerformanceSearchType: string = "FuelEfficiency";
    monthlyFuelPerformanceSearchType: string = "FuelCost";
    scoringSearchSortAscending: boolean = true;
    weeklyViolationStatSearchType: string = "vehicle";

    // CHART: (old) Vehicles stats mileage, speeding, idling
    // vehicleStatsTypes = ['IdleTime', 'DrivingDistance', 'SpeedingDistance'];
    // diffVehicleStats: any = {};
    // classVehicleStats: any = {};
    // descVehicleStats: any = {};

    // CHART: Top Mileage
    weekMileageLabel: string = '0';
    weekMileageCssClass: string = '';
    weekMileageDesc: string = '';
    // vehicleTopMileageStats: any;

    // CHART: Top Speeding
    weekSpeedingDistanceLabel: string = '0';
    weekSpeedingDistanceCssClass: string = '';
    weekSpeedingDistanceDesc: string = '';
    // vehicleTopSpeedingStats: any;

    // CHART: Top Idling
    weekIdlingDurationLabel: string = '00:00';
    weekIdlingDurationCssClass: string = '';
    weekIdlingDurationDesc: string = '';
    // vehicleTopIdlingStats: any;

    // CHART: Fleet Utilisation (weekly)
    weeklyFleetUtilisationStatsNoOfWeeks: number = 5;
    weeklyFleetUtilisationTitle: string = 'Average Daily Utilisation (km)';
    weeklyFleetUtilisationIcon: string = "icon-fleet-utilization-km.svg";
    weeklyFleetUtilisationLabel: string = '0';
    weeklyFleetUtilisationCssClass: string = '';
    weeklyFleetUtilisationDesc: string = '';
    weeklyFleetUtilisationStatsCache: any = null;

    // CHART: Fleet Utilisation (hourly)
    hourlyFleetUtilisationStatsNoOfWeeks: number = 1;
    hourlyFleetUtilisationLabel: string = '0';
    hourlyFleetUtilisationCssClass: string = '';
    hourlyFleetUtilisationDesc: string = '';

    // CHART: Top Fuel Performance
    topFuelPerformanceTitle: string = "Average Fuel Cost (RM)";
    topFuelPerformanceIcon: string = 'icon-fuel-performance-rm.svg';
    topFuelPerformanceLabel: string = '0';
    topFuelPerformanceCssClass: string = '';
    topFuelPerformanceDesc: string = '';
    topFuelPerformanceStatsCache: any = null;
    // fuelStatsMonth: string = `(${moment(this.todayDateMoment).format("MMM YYYY")})`;

    // CHART: Monthly Fuel Performance
    monthlyFuelPerformanceNoOfMonths: number = 5;
    monthlyFuelPerformanceTitle: string = "Total Fuel Cost (RM)";
    monthlyFuelPerformanceIcon: string = 'icon-fuel-performance-rm.svg';
    monthlyFuelPerformanceLabel: string = '0';
    monthlyFuelPerformanceCssClass: string = '';
    monthlyFuelPerformanceDesc: string = '';
    monthlyFuelPerformanceStatsCache: any = null;

    // CHART: Scoring
    scoringStatsNoOfWeeks: number = 2;
    scoringTopVehicleHexColor: string = Colors.HEX_GREY;
    scoringTopDriverHexColor: string = Colors.HEX_GREY;
    scoringStatsDateRange: string = ""; // "(Last week: 30/09 - 6/10)";
    scoringVehicleStats: any;
    scoringVehicleTop: any;
    scoringVehicleTopBlank = {
        CompanyId: 0,
        DateTime: "-",
        VehicleId: 0,
        VehicleName: "-",
        Id: 0,
        Imei: "-",
        ImpactOfHA: 0,
        ImpactOfHB: 0,
        ImpactOfHC: 0,
        ImpactOfSpeeding: 0,
        Score: 0,
        ScoreLabel: '-',
        TotalHA: 0,
        TotalHB: 0,
        TotalHC: 0,
        TotalRecord: 0,
        TotalSpeedingDistance: 0,
        WeekNumber: 0,
        Year: 0
    };
    scoringVehicleOthers: Array<any>;
    scoringVehicleLabelCssClass: string = '';
    scoringVehicleLabelThisWeek: number = 0;
    scoringVehicleLabelLastWeek: number = 0;
    scoringVehicleLabelDiff: number = 0;
    scoringVehicleLabelStars: { count: number, color: string, desc: string } = {
        count: 0,
        color: 'black',
        desc: null
    };
    scoringDriverStats: any;
    scoringDriverTop: any;
    scoringDriverTopBlank = {
        CompanyId: 0,
        DateTime: "-",
        DriverId: 0,
        DriverName: "-",
        Id: 0,
        Imei: "-",
        ImpactOfHA: 0,
        ImpactOfHB: 0,
        ImpactOfHC: 0,
        ImpactOfSpeeding: 0,
        Score: 0,
        ScoreLabel: '-',
        TotalHA: 0,
        TotalHB: 0,
        TotalHC: 0,
        TotalRecord: 0,
        TotalSpeedingDistance: 0,
        WeekNumber: 0,
        Year: 0
    };
    scoringDriverOthers: Array<any>;
    scoringDriverLabelCssClass: string = '';
    scoringDriverLabelThisWeek: number = 0;
    scoringDriverLabelLastWeek: number = 0;
    scoringDriverLabelDiff: number = 0;
    scoringDriverLabelStars: { count: number, color: string, desc: string } = {
        count: 0,
        color: 'black',
        desc: null
    };

    // CHART: Weekly Violation Stats
    weeklyViolationStatsNoOfWeeks: number = 2;
    violationTypes = ['HarshBrake', 'HarshAcceleration', 'HarshTurn', 'Speeding', 'RestrictedDrivingHour', 'Mileage'];
    classViolations: any = {};
    lastweekVioHBLabel: number = 0;
    lastweekVioHALabel: number = 0;
    lastweekVioHCLabel: number = 0;
    lastweekVioSpeedingLabel: number = 0;
    lastweekVioDriveTimeLabel: number = 0;
    lastweekVioMileageLabel: number = 0;
    weekVioHBLabel: number = 0;
    weekVioHALabel: number = 0;
    weekVioHCLabel: number = 0;
    weekVioSpeedingLabel: number = 0;
    weekVioDriveTimeLabel: number = 0;
    weekVioMileageLabel: number = 0;
    violationStatsTotalCssClass: string = '';
    violationStatsTotalLastWeek: number = 0;
    violationStatsTotalDiff: number = 0;
    violationStatsTotalThisWeek: number = 0;
    violationStatsTotalStars: { count: number, color: string, desc: string } = {
        count: 0,
        color: 'black',
        desc: null
    };
    dummyArrayThree: number[] = ObjectUtil.fillRange(3);
    refreshOngoing: boolean = false;

    // Angular ElementRefs
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("btnRefresh",{static:false}) refreshButton: ElementRef;
    @ViewChild("liveStats_spinner",{static:true}) liveStats_spinner: SpinnerComponent;
    @ViewChild("topMileage_spinner",{static:true}) topMileage_spinner: SpinnerComponent;
    @ViewChild("topSpeeding_spinner",{static:true}) topSpeeding_spinner: SpinnerComponent;
    @ViewChild("topIdling_spinner",{static:true}) topIdling_spinner: SpinnerComponent;
    @ViewChild("weeklyFleetUtilisation_spinner",{static:true}) weeklyFleetUtilisation_spinner: SpinnerComponent;
    @ViewChild("hourlyFleetUtilisation_spinner",{static:true}) hourlyFleetUtilisation_spinner: SpinnerComponent;
    @ViewChild("topFuelPerformance_spinner",{static:true}) topFuelPerformance_spinner: SpinnerComponent;
    @ViewChild("monthlyFuelPerformance_spinner",{static:true}) monthlyFuelPerformance_spinner: SpinnerComponent;
    @ViewChild("weeklyScoring_spinner",{static:true}) weeklyScoring_spinner: SpinnerComponent;
    @ViewChild("weeklyViolationCount_spinner",{static:true}) weeklyViolationCount_spinner: SpinnerComponent;
    @ViewChild("topVehicleCircleBar",{static:false}) topVehicleCircleBar;
    @ViewChild("topDriverCircleBar",{static:false}) topDriverCircleBar;
    @ViewChild("recommendation_spinner",{static:true}) recommendation_spinner: SpinnerComponent;

    // Test PNG SVG comparison
    // imageSvgMode: boolean = true;
    // swapSvgPng() {
    //     this.imageSvgMode = !this.imageSvgMode;
    //     if (this.imageSvgMode) {
    //         this.convertDocumentPngsToSvgs();
    //     } else {
    //         this.convertDocumentSvgsToPngs(); // convert SVG to PNG
    //     }
    // }

    ngOnInit() {
        this.checkUnviewedCampaign();
        this.checkUserPermission();
        // Check if fuel module to be shown
        // this.isDisplayFuelModule = EnvUtil.isDev();
        // this.planIncluded = true; // prepare for business plan

        // Init Dashboard Labels
        // this.todayDateMoment = moment(this.todayDate1);
        this.currentWeekDayStart = moment(this.todayDateMoment).startOf('isoWeek').format("DD/MM");
        this.currentWeekDayEnd = moment(this.todayDateMoment).endOf('isoWeek').format("DD/MM");
        this.currentWeekRange = `(${this.currentWeekDayStart} - ${this.currentWeekDayEnd})`;

        this.initChartJsDefaults();
    }

    async checkUserPermission() {
        const permissions = await this.mpService.hasPermission(modules.MAP_VEHICLE_HISTORY.value);
        this.isPastTripViewable = permissions[modules.MAP_VEHICLE_HISTORY.value].vAccess;
        const permissionsMaintenance = await this.mpService.hasPermission(modules.CRUD_MAINTENANCE.value);
        this.isMaintenanceViewable = permissionsMaintenance[modules.CRUD_MAINTENANCE.value].vAccess;
        const permissionsDriver = await this.mpService.hasPermission(modules.CRUD_DRIVER.value);
        this.isDriverEditable = permissionsDriver[modules.CRUD_DRIVER.value].eAccess;
        const permissionsVehicle = await this.mpService.hasPermission(modules.CRUD_VEHICLE.value);
        this.isVehicleEditable = permissionsVehicle[modules.CRUD_VEHICLE.value].eAccess;
        const permissionsUtilisationReport = await this.mpService.hasPermission(modules.REPORT_UTILISATION.value);
        this.isUtilisationReportViewable = permissionsUtilisationReport[modules.REPORT_UTILISATION.value].vAccess;
        const permissionsViolationReport = await this.mpService.hasPermission(modules.REPORT_VIOLATION.value);
        this.isViolationReportViewable = permissionsViolationReport[modules.REPORT_VIOLATION.value].vAccess;
        const permissionsFuelReport = await this.mpService.hasPermission(modules.REPORT_FUEL.value);
        this.isFuelReportViewable = permissionsFuelReport[modules.REPORT_FUEL.value].vAccess;
        const permissionsFuel = await this.mpService.hasPermission(modules.CRUD_FUEL.value);
        this.isFuelViewable = permissionsFuel[modules.CRUD_FUEL.value].vAccess;
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async ngAfterViewInit() {
        await this.getDashboardInfo();
    }

    ngOnDestroy() {
        clearInterval(this.refreshPageTimer);
    }

    async getDashboardInfo() {
        this.refreshButton.nativeElement.classList.add('--block-click');
        //disable download button when refreshing
        this.refreshOngoing = true;

        if (!NumberUtil.isInteger(this.selectedGroupId)) {
            this.selectedGroupId = -1; // restore to ALL
        }
        this.selectedGroupId = parseInt(this.selectedGroupId);

        // When a group is selected, restore all dropdowns to "vehicle"
        // Because only vehicle group is supported
        if (this.selectedGroupId >= 0) {
            this.mileageSearchType = 'vehicle';
            this.speedingSearchType = 'vehicle';
            this.idlingSearchType = 'vehicle';
            this.weeklyViolationStatSearchType = "vehicle";
        }

        await Promise.all([
            this.getGroupList(),
            // STATS
            this.getTodayLiveVehiclesStats(),
            this.getTodayAlertNotifications(),
            // CHARTS row 1
            this.getAdvisory(),
            this.getTopMileageStats(),
            this.getTopSpeedingStats(),
            this.getTopIdlingTimeStats(),
            // CHARTS row 2
            this.getWeeklyFleetUtilisationStats(),
            this.getHourlyFleetUtilisationStats(),
            /* (this.isDisplayFuelModule ?  */this.getTopFiveFuelPerformance()/*  : null) */,
            /* (this.isDisplayFuelModule ?  */this.getMonthlyFuelPerformance()/*  : null) */,
            // CHARTS row 3
            this.getScoringStats(),
            this.getVehicleViolationStats()
        ]);

        this.initRefreshTimer(); // Refresh Timer

        this.refreshButton.nativeElement.classList.remove('--block-click');
        //reenable download button after refreshed
        this.refreshOngoing = false;
    }

    /* ---- refresh ---- */

    initRefreshTimer(): void {
        clearInterval(this.refreshPageTimer);
        this.refreshPageTimer = setInterval(
            this.refreshDashboardActions.bind(this), DASHBOARD_REFRESH_INTERVAL_MS);
    }

    async refreshDashboardActions() {
        console.debug("DashboardComponent: Refresh is triggered");
        await Promise.all([
            this.getTodayLiveVehiclesStats()
            // this.getTodayAlertNotifications()
        ]);
    }

    /* ---- Dashboard Stats ---- */

    // General: Populate Vehicle Group Dropdown Options
    async getGroupList() {
        try {
            const startRecord = 0;
            const pageRecordSize = 0;
            const sortField = 'name';
            const sortAscending = true;
            const filterKey = 'name'; // Filter By Name
            const filterValue = '';
            const groupViewResponse: any = await this.groupService.getGroupList(
                'VEHICLE',
                pageRecordSize,
                startRecord,
                sortField,
                sortAscending,
                filterKey,
                filterValue);
            if (groupViewResponse) {
                this.groupList = groupViewResponse.body.result;
            }
            // this.groupList = this.groupOriList.result;
            // this.pager = this.pagerService.getPager(this.groupOriList.totalRecord, page, this.pageRecordSize);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    // Left Half: Live Vehicles Stats (Moving, Idling, Stopped, DC)
    async getTodayLiveVehiclesStats() {
        try {
            this.liveStats_spinner.show();
            let newMovingCount = 0;
            let newStoppedCount = 0;
            let newDisconnectedCount = 0;
            let newIdlingCount = 0;

            this.movingVehicleList = [];
            this.idlingVehicleList = [];
            this.stoppedVehicleList = [];
            this.disconnectedVehicleList = [];

            const liveTrips = await this.tripsService.getLiveDashboardTrips(null, this.selectedGroupId);

            //sorting info
            this.sortSessions['movingVehicle'].currentSortField = 'vehicleName';
            this.sortSessions['movingVehicle'].currentSortAscending = true;

            this.sortSessions['idlingVehicle'].currentSortField = 'vehicleName';
            this.sortSessions['idlingVehicle'].currentSortAscending = true;

            this.sortSessions['stoppedVehicle'].currentSortField = 'vehicleName';
            this.sortSessions['stoppedVehicle'].currentSortAscending = true;

            this.sortSessions['disconnectedVehicle'].currentSortField = 'vehicleName';
            this.sortSessions['disconnectedVehicle'].currentSortAscending = true;

            if (liveTrips && liveTrips.body) {
                liveTrips.body.live_summary_trip.forEach(vehicle => {
                    switch (vehicle.status) {
                        case "MOVING":
                            vehicle.vehicleName = "vehicle " + (newMovingCount + 1);
                            this.movingVehicleList.push(vehicle);
                            newMovingCount++;
                            break;
                        case "STOPPED":
                            vehicle.vehicleName = "vehicle " + (newStoppedCount + 1);
                            this.stoppedVehicleList.push(vehicle);
                            newStoppedCount++;
                            break;
                        case "DISCONNECTED":
                            vehicle.vehicleName = "vehicle " + (newDisconnectedCount + 1);
                            this.disconnectedVehicleList.push(vehicle);
                            newDisconnectedCount++;
                            break;
                        case "IDLING":
                            vehicle.vehicleName = "vehicle " + (newIdlingCount + 1);
                            this.idlingVehicleList.push(vehicle);
                            newIdlingCount++;
                            break;
                    }
                });
            }

            this.movingCount = newMovingCount;
            this.stoppedCount = newStoppedCount;
            this.disconnectedCount = newDisconnectedCount;
            this.idlingCount = newIdlingCount;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.liveStats_spinner.hide();
        }
    }

    // Right Half: Today Alert Notifs (Health, Maintenance, License)
    async getTodayAlertNotifications() {
        await Promise.all([
            // Vehicle Health Status
            this.getVehiclesHealthAlertsStatus(),
            // Upcoming Maintenance
            this.getUpcomingMaintenance(),
            // Expiring License
            this.getExpiringLicense()
        ]);
    }

    // DashStat: Upcoming Maintenance
    async getUpcomingMaintenance() {
        try {
            this.liveStats_spinner.show();
            const result = await this.maintenanceService.getUpcomingVehicleMaintenanceNoPaging('', this.selectedGroupId);
            if (result) {
                this.alertCountOfUpcomingMaintenance = parseInt(result.body.totalRecord);

                //sorting info
                this.sortSessions['maintenance'].currentSortField = result.body.sort.sortField;
                this.sortSessions['maintenance'].currentSortAscending = result.body.sort.sortAscending;

                //group by vehicle
                const rawResultList = result.body.result;
                const groupedMaintenanceList = [];

                let tempGroup = {
                    vehicleId: null,
                    vehicleName: null,
                    maintenances: []
                };

                for (let maintenance of rawResultList) {
                    let index = 0;
                    maintenance.vehicleName = `vehicle ${index} `;
                    if (tempGroup.vehicleId == maintenance.vehicleId) {
                        tempGroup.maintenances.push(maintenance);
                        continue;
                    } else {
                        if (tempGroup.vehicleId != null && tempGroup.vehicleName != null && tempGroup.maintenances.length > 0) {
                            groupedMaintenanceList.push(tempGroup);
                        }
                        index++;
                        maintenance.vehicleName = `vehicle ${index} `;
                        tempGroup = Object.assign({}, {
                            vehicleId: maintenance.vehicleId,
                            vehicleName: maintenance.vehicleName,
                            maintenances: [maintenance]
                        });
                    }
                }
                // Push last record
                groupedMaintenanceList.push(tempGroup);
                this.upcomingMaintenanceList = groupedMaintenanceList;
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.liveStats_spinner.hide();
        }
    }

    // DashStat: Expiring License
    async getExpiringLicense() {
        try {
            this.liveStats_spinner.show();
            const result = await this.statsService.getExpiringLicense(this.selectedGroupId);
            if (result) {
                this.alertCountOfExpiringLicense = parseInt(result.body.totalRecord);

                //sorting info
                this.sortSessions['license'].currentSortField = result.body.sort.sortField;
                this.sortSessions['license'].currentSortAscending = result.body.sort.sortAscending;

                //group by vehicle
                this.expiringLicenseList = result.body.result;
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.liveStats_spinner.hide();
        }
    }

    // DashStat: Vehicles Health Status Alerts
    async getVehiclesHealthAlertsStatus() {
        try {
            this.liveStats_spinner.show();
            this.healthAlertsCounts = 0;
            // this.todayDateMoment = moment(this.todayDate1);
            let endTime: any = moment(this.todayDateMoment);
            let startTime: any = moment(this.todayDateMoment);
            endTime = moment(endTime).endOf("day").format("YYYY-MM-DD HH:mm:ss");
            // endTime = '2019-07-15 23:59:59';
            startTime = moment(startTime).startOf("day").format("YYYY-MM-DD HH:mm:ss");
            // startTime = '2019-07-15 00:00:00';

            const result = await this.notificationService.getVehicleHealthStatusNotification(null, startTime, endTime, "vehicleName", true, 0, 0, false, this.selectedGroupId);
            if (result) {
                this.vehicleHealthAlerts = result.body.result;
                this.sortSessions['vehicleHealth'].currentSortField = result.body.sort.sortField;
                this.sortSessions['vehicleHealth'].currentSortAscending = result.body.sort.sortAscending;
                this.vehicleHealthAlerts.map(eachStatus => {
                    if (eachStatus.isBatteryLow != null && eachStatus.isBatteryLow > 0) {
                        this.healthAlertsCounts++;
                    } else if (eachStatus.isTemperatureHigh != null && eachStatus.isTemperatureHigh > 0) {
                        if (eachStatus.batteryLevel < 14) {
                            this.healthAlertsCounts++;
                        }
                    } else if (eachStatus.isDtcPoor != null && eachStatus.isDtcPoor > 0) {
                        if (eachStatus.batteryLevel < 14) {
                            this.healthAlertsCounts++;
                        }
                    }
                });
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.liveStats_spinner.hide();
        }
    }

    // ChartStat: Top 5 Mileage
    async getTopMileageStats() {
        try {
            this.topMileage_spinner.show();

            // await AsyncUtil.wait(1000);
            const apiStats = await this.statsService.getTopMileageStats(this.mileageSearchType, this.todayDate, this.selectedGroupId);

            // mockup data
            // const test = {
            //     body: {
            //         vehicles: {
            //             weeks: [
            //                 {
            //                     "CompanyId": 38,
            //                     "Year": 2019,
            //                     "Week": 28,
            //                     "IdleTime": 8017.78,
            //                     "DrivingDistance": 3500.2,
            //                     "SpeedingDistance": 32.36,
            //                     "TotalVehicleCount": 7
            //                 },
            //                 {
            //                     "CompanyId": 38,
            //                     "Year": 2019,
            //                     "Week": 27,
            //                     "IdleTime": 25521.21,
            //                     "DrivingDistance": 2500.1,
            //                     "SpeedingDistance": 40.25,
            //                     "TotalVehicleCount": 7
            //                 }
            //             ],
            //             topFive: [
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 350.7,
            //                     vehicleId: 200008,
            //                     vehicleName: "TEST 3G",
            //                     driverName: "Kleist"
            //                 },
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 235.9,
            //                     vehicleId: 200011,
            //                     vehicleName: "Sheng Car",
            //                     driverName: "Yi Sheng Ooi"
            //                 },
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 120.1,
            //                     vehicleId: 200012,
            //                     vehicleName: "Maxis Van",
            //                     driverName: "Maxis Boss"
            //                 },
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 80.2,
            //                     vehicleId: 200009,
            //                     vehicleName: "White Hilux",
            //                     driverName: "Mohd Amin"
            //                 },
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 15.0,
            //                     vehicleId: 200010,
            //                     vehicleName: "Alvin Range Rover",
            //                     driverName: "Alvin Soh"
            //                 }
            //             ]
            //         },
            //         drivers: {
            //             weeks: [
            //                 {
            //                     "CompanyId": 38,
            //                     "Year": 2019,
            //                     "Week": 28,
            //                     "IdleTime": 8017.78,
            //                     "DrivingDistance": 3500.2,
            //                     "SpeedingDistance": 32.36,
            //                     "TotalDriverCount": 7
            //                 },
            //                 {
            //                     "CompanyId": 38,
            //                     "Year": 2019,
            //                     "Week": 27,
            //                     "IdleTime": 25521.21,
            //                     "DrivingDistance": 2500.1,
            //                     "SpeedingDistance": 40.25,
            //                     "TotalDriverCount": 7
            //                 }
            //             ],
            //             topFive: [
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 322.8,
            //                     driverId: 200012,
            //                     driverName: "Maxis Boss"
            //                 },
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 218.4,
            //                     driverId: 200009,
            //                     driverName: "Mohd Amin"
            //                 },
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 110.7,
            //                     driverId: 200008,
            //                     driverName: "Kleist"
            //                 },
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 55.2,
            //                     driverId: 200011,
            //                     driverName: "Yi Sheng Ooi"
            //                 },
            //                 {
            //                     imeiNo: "357042069863091",
            //                     totalDistance: 20.3,
            //                     driverId: 200010,
            //                     driverName: "Alvin Soh"
            //                 }
            //             ]
            //         }
            //     }
            // };
            // const vehicleTopMileageStats = test;

            if (apiStats && apiStats.body[this.mileageSearchType + 's']) {
                const stats = apiStats.body[this.mileageSearchType + 's'];

                // Stats
                const curVal = stats.weeks[0].DrivingDistance;
                const prevVal = stats.weeks[1].DrivingDistance;
                const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                this.weekMileageLabel = NumberUtil.formatFloat(curVal, 1, '0');
                this.weekMileageCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, false);
                this.weekMileageDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Week: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;

                // Chart
                await this.generateTopMileageBarChart(this.mileageSearchType, stats.topFive);
            } else {
                // Remove old chart & stats
                await this.generateTopMileageBarChart(this.mileageSearchType, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.topMileage_spinner.hide();
        }
    }

    // ChartStat: Top 5 Speeding
    async getTopSpeedingStats() {
        try {
            this.topSpeeding_spinner.show();

            // await AsyncUtil.wait(1000);
            const apiStats = await this.statsService.getTopSpeedingStats(this.speedingSearchType, this.todayDate, this.selectedGroupId);

            // mockup data
            // const test = {
            //     TopSpeeding: {
            //         vehicles: [
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 22.8,
            //                 vehicleId: 200012,
            //                 vehicleName: "Maxis Van",
            //                 driverName: "Maxis Boss"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 18.4,
            //                 vehicleId: 200009,
            //                 vehicleName: "White Hilux",
            //                 driverName: "Mohd Amin"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 10.7,
            //                 vehicleId: 200008,
            //                 vehicleName: "TEST 3G",
            //                 driverName: "Kleist"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 5.2,
            //                 vehicleId: 200011,
            //                 vehicleName: "Sheng Car",
            //                 driverName: "Yi Sheng Ooi"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 2.3,
            //                 vehicleId: 200010,
            //                 vehicleName: "Alvin Range Rover",
            //                 driverName: "Alvin Soh"
            //             }
            //         ],
            //         drivers: [
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 22.8,
            //                 driverId: 200012,
            //                 driverName: "Maxis Boss"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 18.4,
            //                 driverId: 200009,
            //                 driverName: "Mohd Amin"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 10.7,
            //                 driverId: 200008,
            //                 driverName: "Kleist"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 5.2,
            //                 driverId: 200011,
            //                 driverName: "Yi Sheng Ooi"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalSpeedingDistance: 2.3,
            //                 driverId: 200010,
            //                 driverName: "Alvin Soh"
            //             }
            //         ]
            //     }
            // };
            // const vehicleTopSpeedingStats = test;

            if (apiStats && apiStats.body[this.speedingSearchType + 's']) {
                const stats = apiStats.body[this.speedingSearchType + 's'];

                // Stats
                const curVal = stats.weeks[0].SpeedingDistance;
                const prevVal = stats.weeks[1].SpeedingDistance;
                const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                this.weekSpeedingDistanceLabel = NumberUtil.formatFloat(curVal, 1, '0');
                this.weekSpeedingDistanceCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true, false);
                this.weekSpeedingDistanceDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Week: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;

                // Chart
                await this.generateTopSpeedingBarChart(this.speedingSearchType, stats.topFive);
            } else {
                // Remove old chart & stats
                await this.generateTopSpeedingBarChart(this.speedingSearchType, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.topSpeeding_spinner.hide();
        }
    }

    // ChartStat: Top 5 Idling Time
    async getTopIdlingTimeStats() {
        try {
            this.topIdling_spinner.show();
            // this.todayDateMoment = moment(this.todayDate1);
            this.todayDate = this.todayDateMoment.format('YYYY-MM-DD');
            // await AsyncUtil.wait(1000);
            const apiStats = await this.statsService.getTopIdlingStats(this.idlingSearchType, this.todayDate, this.selectedGroupId);
            // mockup data
            // const test = {
            //     TopIdlingTime: {
            //         vehicles: [
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 10.1,
            //                 vehicleId: 200008,
            //                 vehicleName: "TEST 3G",
            //                 driverName: "Kleist"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 5.25,
            //                 vehicleId: 200009,
            //                 vehicleName: "White Hilux",
            //                 driverName: "Mohd Amin"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 1.5,
            //                 vehicleId: 200010,
            //                 vehicleName: "Alvin Range Rover",
            //                 driverName: "Alvin Soh"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 1.2,
            //                 vehicleId: 200011,
            //                 vehicleName: "Sheng Car",
            //                 driverName: "Yi Sheng Ooi"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 0.5,
            //                 vehicleId: 200012,
            //                 vehicleName: "Maxis Van",
            //                 driverName: "Maxis Boss"
            //             }
            //         ],
            //         drivers: [
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 10.8,
            //                 driverId: 200012,
            //                 driverName: "Maxis Boss"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 5.4,
            //                 driverId: 200009,
            //                 driverName: "Mohd Amin"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 3.7,
            //                 driverId: 200008,
            //                 driverName: "Kleist"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 1.2,
            //                 driverId: 200011,
            //                 driverName: "Yi Sheng Ooi"
            //             },
            //             {
            //                 imeiNo: "357042069863091",
            //                 totalIdlingTime: 0.5,
            //                 driverId: 200010,
            //                 driverName: "Alvin Soh"
            //             }
            //         ]
            //     }
            // };
            // const vehicleTopIdlingStats = test;

            if (apiStats && apiStats.body[this.idlingSearchType + 's']) {
                const stats = apiStats.body[this.idlingSearchType + 's'];

                // Stats
                const curVal = stats.weeks[0].IdleTime;
                const prevVal = stats.weeks[1].IdleTime;
                const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                this.weekIdlingDurationLabel = NumberUtil.formatHourMinute(curVal / 60) || '00:00';
                this.weekIdlingDurationCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true, false);
                this.weekIdlingDurationDesc = `${NumberUtil.formatHourMinute(diffVal / 60)} (Last Week: ${NumberUtil.formatHourMinute(prevVal / 60)})`;

                // Chart
                await this.generateTopIdlingBarChart(this.idlingSearchType, stats.topFive);
            } else {
                // Remove old chart & stats
                await this.generateTopIdlingBarChart(this.idlingSearchType, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.topIdling_spinner.hide();
        }
    }

    // ChartStat: Recent 5 weeks Fleet Utilisation
    async getWeeklyFleetUtilisationStats(useCache: boolean = false) {
        try {
            this.weeklyFleetUtilisation_spinner.show();

            if (StringUtil.equalsIgnoreCase(this.utilisationWeeklySearchType, 'distance')) {
                this.weeklyFleetUtilisationTitle = "Average Daily Utilisation (km)";
                this.weeklyFleetUtilisationIcon = "icon-fleet-utilization-km.svg";
            } else if (StringUtil.equalsIgnoreCase(this.utilisationWeeklySearchType, 'hour')) {
                this.weeklyFleetUtilisationTitle = "Average Daily Utilisation (hh:mm)";
                this.weeklyFleetUtilisationIcon = "icon-fleet-utilization-l.svg";
            }

            // Caching
            let apiStats: any = null;
            if (useCache && this.weeklyFleetUtilisationStatsCache && this.weeklyFleetUtilisationStatsCache.body) {
                await AsyncUtil.wait(50);
                apiStats = this.weeklyFleetUtilisationStatsCache;
            } else {
                apiStats = await this.statsService.getWeeklyFleetUtilisationStats(this.weeklyFleetUtilisationStatsNoOfWeeks, this.todayDate, this.selectedGroupId);
                // await AsyncUtil.wait(1000);
                // const test = {
                //     UtilisationStats: [
                //         {
                //             "CompanyId": 38,
                //             "TotalDuration": 1767.49,
                //             "TotalDistance": 521.9,
                //             "TotalVehicles": 5,
                //             "TotalDrivers": 5,
                //             "TotalTrips": 65,
                //             "Year": 2019,
                //             "Week": 28
                //         },
                //         {
                //             "CompanyId": 38,
                //             "TotalDuration": 1962.35,
                //             "TotalDistance": 1341.7,
                //             "TotalVehicles": 5,
                //             "TotalDrivers": 5,
                //             "TotalTrips": 133,
                //             "Year": 2019,
                //             "Week": 27
                //         },
                //         {
                //             "CompanyId": 38,
                //             "TotalDuration": 3057.08,
                //             "TotalDistance": 838.5,
                //             "TotalVehicles": 6,
                //             "TotalDrivers": 5,
                //             "TotalTrips": 123,
                //             "Year": 2019,
                //             "Week": 26
                //         },
                //         {
                //             "CompanyId": 38,
                //             "TotalDuration": 2976.99,
                //             "TotalDistance": 1781.5,
                //             "TotalVehicles": 5,
                //             "TotalDrivers": 5,
                //             "TotalTrips": 124,
                //             "Year": 2019,
                //             "Week": 25
                //         },
                //         {
                //             "CompanyId": 38,
                //             "TotalDuration": 1363.44,
                //             "TotalDistance": 2069.5,
                //             "TotalVehicles": 5,
                //             "TotalDrivers": 4,
                //             "TotalTrips": 96,
                //             "Year": 2019,
                //             "Week": 24
                //         }
                //     ]
                // };
                // const weeklyUtilisationStats = test;
                this.weeklyFleetUtilisationStatsCache = apiStats;
            }

            // console.log(JSON.stringify(weeklyUtilisationStats, null, 2));
            if (apiStats && apiStats.body['weeks']) {
                // const stats = {
                //     weeks: apiStats.UtilisationStats
                // };
                const stats = apiStats.body;

                // Stats
                if (this.utilisationWeeklySearchType == 'distance') {
                    const curVal = NumberUtil.roundOff(stats.weeks[0].TotalDistance / stats.weeks[0].TotalDays, 2);
                    const prevVal = NumberUtil.roundOff(stats.weeks[1].TotalDistance / stats.weeks[1].TotalDays, 2);
                    const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                    this.weeklyFleetUtilisationLabel = NumberUtil.formatFloat(curVal, 1, '0');
                    this.weeklyFleetUtilisationCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true);
                    this.weeklyFleetUtilisationDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Week: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;
                } else {
                    const curVal = NumberUtil.roundOff(stats.weeks[0].TotalDuration / stats.weeks[0].TotalDays, 2);
                    const prevVal = NumberUtil.roundOff(stats.weeks[1].TotalDuration / stats.weeks[1].TotalDays, 2);
                    const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                    this.weeklyFleetUtilisationLabel = NumberUtil.formatHourMinute(curVal / 60) || '00:00';
                    this.weeklyFleetUtilisationCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true);
                    this.weeklyFleetUtilisationDesc = `${NumberUtil.formatHourMinute(diffVal / 60)} (Last Week: ${NumberUtil.formatHourMinute(prevVal / 60)})`;
                }

                // Chart
                await this.generateWeeklyFleetUtilisationLineChart(this.utilisationWeeklySearchType, stats.weeks);
            } else {
                // Remove old chart & stats
                await this.generateWeeklyFleetUtilisationLineChart(this.utilisationWeeklySearchType, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.weeklyFleetUtilisation_spinner.hide();
        }
    }

    // ChartStat: Hourly breakdown Fleet Utilisation
    async getHourlyFleetUtilisationStats() {
        try {
            this.hourlyFleetUtilisation_spinner.show();
            await AsyncUtil.wait(100);
            const hourlyUtilisationStats = await this.statsService.getHourlyFleetUtilisationStats(this.hourlyFleetUtilisationStatsNoOfWeeks, this.selectedGroupId);

            // mockup data
            // const test = {
            //     body: {
            //         "CompanyId": 38,
            //         "Year": 2019,
            //         "Week": 28,
            //         data: [
            //             {
            //                 "TotalDistance": 0.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.5,
            //                 "Time": '00-01'
            //             }, {
            //                 "TotalDistance": 0.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.0,
            //                 "Time": '01-02'
            //             }, {
            //                 "TotalDistance": 0.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.0,
            //                 "Time": '02-03'
            //             }, {
            //                 "TotalDistance": 0.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.0,
            //                 "Time": '03-04'
            //             }, {
            //                 "TotalDistance": 521.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.0,
            //                 "Time": '04-05'
            //             }, {
            //                 "TotalDistance": 0.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.0,
            //                 "Time": '05-06'
            //             }, {
            //                 "TotalDistance": 10.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.1,
            //                 "Time": '06-07'
            //             }, {
            //                 "TotalDistance": 20.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.2,
            //                 "Time": '07-08'
            //             }, {
            //                 "TotalDistance": 30.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.3,
            //                 "Time": '08-09'
            //             }, {
            //                 "TotalDistance": 380.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.4,
            //                 "Time": '09-10'
            //             }, {
            //                 "TotalDistance": 400.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.5,
            //                 "Time": '10-11'
            //             }, {
            //                 "TotalDistance": 550.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.5,
            //                 "Time": '11-12'
            //             }, {
            //                 "TotalDistance": 600.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.7,
            //                 "Time": '12-13'
            //             }, {
            //                 "TotalDistance": 521.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.6,
            //                 "Time": '13-14'
            //             }, {
            //                 "TotalDistance": 521.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.4,
            //                 "Time": '14-15'
            //             }, {
            //                 "TotalDistance": 521.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.5,
            //                 "Time": '15-16'
            //             }, {
            //                 "TotalDistance": 521.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.2,
            //                 "Time": '16-17'
            //             }, {
            //                 "TotalDistance": 321.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.3,
            //                 "Time": '17-18'
            //             }, {
            //                 "TotalDistance": 221.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.6,
            //                 "Time": '18-19'
            //             }, {
            //                 "TotalDistance": 201.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.7,
            //                 "Time": '19-20'
            //             }, {
            //                 "TotalDistance": 121.9,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.4,
            //                 "Time": '20-21'
            //             }, {
            //                 "TotalDistance": 70.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.2,
            //                 "Time": '21-22'
            //             }, {
            //                 "TotalDistance": 50.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.1,
            //                 "Time": '22-23'
            //             }, {
            //                 "TotalDistance": 0.0,
            //                 "TotalVehicles": 5,
            //                 "PercentageVehicles": 0.0,
            //                 "Time": '23-24'
            //             },
            //         ],
            //         AverageHourly: 5,
            //         PrevAverageHourly: 3
            //     }
            // };
            // const hourlyUtilisationStats = test;
            // const hourlyUtilisationStats = null;
            // console.log(hourlyUtilisationStats);

            const curVal = NumberUtil.roundOff(hourlyUtilisationStats.body.AverageHourly, 2);
            const prevVal = NumberUtil.roundOff(hourlyUtilisationStats.body.PrevAverageHourly, 2);
            const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

            this.hourlyFleetUtilisationLabel = NumberUtil.formatFloat(curVal, 1, '0');
            this.hourlyFleetUtilisationCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true);
            this.hourlyFleetUtilisationDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Week: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;

            if (hourlyUtilisationStats && hourlyUtilisationStats.body.data) {
                await this.generateHourlyFleetUtilisationLineChart(this.utilisationHourlySearchType, hourlyUtilisationStats.body.data);
            } else {
                // Remove old chart
                await this.generateHourlyFleetUtilisationLineChart(this.utilisationHourlySearchType, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.hourlyFleetUtilisation_spinner.hide();
        }
    }

    async getAdvisory() {
        try {
            this.recommendation_spinner.show();
            await AsyncUtil.wait(100);
            const advisory = await this.statsService.getAdvisory(this.selectedGroupId);
            if (advisory && Array.isArray(advisory.body)) {
                advisory.body.forEach((element, index) => {
                    let content;
                    if (element.type == "ADVISORY") {
                        let formattedName;
                        if (element.vehicleCount > 1) {
                            formattedName = `vehicle ${index} `;
                            if (formattedName.length > 11) {
                                formattedName = formattedName.substring(0,11) + "... &" + (element.vehicleCount - 1) + " others";
                            }
                        } else {
                            formattedName = `vehicle ${index} `;
                            if (formattedName.length > 11) {
                                formattedName = formattedName.substring(0,11) + "...";
                            }
                        }
                        if (element.advisoryTypeId == 1) {
                            //speeding
                            content = {
                                dateCreated: element.dateCreated,
                                type: element.type,
                                vehicleIds: element.vehicleIds,
                                advisoryTypeId: element.advisoryTypeId,
                                name: formattedName,
                                variation: [
                                    {
                                        msg: "You should coach your drivers to drive within limits to reduce speeding fines"
                                    }
                                ],
                                display: false
                            };
                        } else if (element.advisoryTypeId == 2) {
                            //idling
                            content = {
                                dateCreated: element.dateCreated,
                                type: element.type,
                                fuelWastage: NumberUtil.roundOff(element.totalIdlingTime / 60 * 0.7, 2),
                                vehicleIds: element.vehicleIds,
                                advisoryTypeId: element.advisoryTypeId,
                                name: formattedName,
                                variation: [
                                    {
                                        msg: "Educating drivers to turn off engines while waiting will help reduce this"
                                    }
                                ],
                                display: false
                            };
                        } else if (element.advisoryTypeId == 3) {
                            //violation
                            const variationMsg = [
                                {
                                    msg: "You should coach your drivers to drive safely to reduce chances of accidents"
                                },
                                {
                                    msg: "You should advise your drivers to brake earlier and slow down during corners"
                                }
                            ];

                            content = {
                                dateCreated: element.dateCreated,
                                type: element.type,
                                vehicleIds: element.vehicleIds,
                                advisoryTypeId: element.advisoryTypeId,
                                name: formattedName,
                                variation: [
                                    variationMsg[Math.round(Math.random() * 1)]
                                ],
                                display: false
                            };
                        } else if (element.advisoryTypeId == 4) {
                            //utilization
                            const variationMsg = [
                                {
                                    msg: "You may want to consider using these vehicles more"
                                },
                                {
                                    msg: "You may want to review your vehicle roster"
                                }
                            ];

                            content = {
                                dateCreated: element.dateCreated,
                                type: element.type,
                                vehicleIds: element.vehicleIds,
                                advisoryTypeId: element.advisoryTypeId,
                                name: formattedName,
                                variation: [
                                    variationMsg[Math.round(Math.random() * 1)]
                                ],
                                display: false
                            };
                        } else if (element.advisoryTypeId == 5) {
                            //fuel efficiency
                            const variationMsg = [
                                {
                                    msg: "You may want to review the fuel usage for these vehicles"
                                },
                                {
                                    msg: "Reducing idling, speeding and dangerous driving can help improve fuel efficiency"
                                }
                            ];

                            content = {
                                dateCreated: element.dateCreated,
                                type: element.type,
                                vehicleIds: element.vehicleIds,
                                advisoryTypeId: element.advisoryTypeId,
                                name: formattedName,
                                variation: [
                                    variationMsg[Math.round(Math.random() * 1)]
                                ],
                                display: false
                            };
                        }
                    } else {
                        //trend
                        if (element.trendDirection == 'UP') {
                            if (element.advisoryTypeId == 1) {
                                //speeding distance
                                content = {
                                    dateCreated: element.dateCreated,
                                    type: element.type,
                                    message: `Your fleet's speeding distance is trending upwards`,
                                    variation: [
                                        {
                                            msg: "You may want to review the top speeding vehicles"
                                        }
                                    ],
                                    display: false
                                };
                            } else if (element.advisoryTypeId == 2) {
                                //idling
                                content = {
                                    dateCreated: element.dateCreated,
                                    type: element.type,
                                    message: `Your fleet's idling duration is trending upwards`,
                                    variation: [
                                        {
                                            msg: "You may want to review the top idling vehicles"
                                        }
                                    ],
                                    display: false
                                };
                            } else if (element.advisoryTypeId == 3) {
                                //violation
                                content = {
                                    dateCreated: element.dateCreated,
                                    type: element.type,
                                    message: `Your fleet's dangerous driving behaviour is trending upwards`,
                                    variation: [
                                        {
                                            msg: "You may want to review the vehicles with the lowest driving scores"
                                        }
                                    ],
                                    display: false
                                };
                            }
                        }
                    }
                    if (content) {
                        this.recommendations.push(content);
                    }
                });
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.recommendation_spinner.hide();
        }
    }

    // ChartStat: Top 5 Fuel Performance
    async getTopFiveFuelPerformance(/*useCache: boolean = false*/) {
        try {
            this.topFuelPerformance_spinner.show();

            if (StringUtil.equalsIgnoreCase(this.topFuelPerformanceSearchType, 'FuelCost')) {
                this.topFuelPerformanceTitle = "Average Fuel Cost (RM)";
                this.topFuelPerformanceIcon = "icon-fuel-performance-rm.svg";
            } else if (StringUtil.equalsIgnoreCase(this.topFuelPerformanceSearchType, 'FuelEfficiency')) {
                this.topFuelPerformanceTitle = "Average Fuel Efficiency (km/L)";
                this.topFuelPerformanceIcon = "icon-fuel-performance-percent.svg";
            } else if (StringUtil.equalsIgnoreCase(this.topFuelPerformanceSearchType, 'FuelUsed')) {
                this.topFuelPerformanceTitle = "Average Fuel Used (L)";
                this.topFuelPerformanceIcon = "icon-fuel-performance-l-cursive.svg";
            }

            // Caching
            let apiStats: any = null;
            // if (useCache && this.topFuelPerformanceStatsCache && this.topFuelPerformanceStatsCache.body) {
            //     await AsyncUtil.wait(50);
            //     apiStats = this.topFuelPerformanceStatsCache;
            // } else {
            // this.todayDateMoment = moment(this.todayDate1);
            const now = moment(this.todayDateMoment);
            apiStats = await this.statsService.getTopFuelPerformance(this.selectedGroupId, this.topFuelPerformanceSearchType, parseInt(now.format("MM")), parseInt(now.format("YYYY")), 5, 1);

            // await AsyncUtil.wait(1000);
            // const test = {
            //     "type": "FuelCost",
            //     "result": [
            //         {
            //             "vehicleId": 200004,
            //             "vehicleName": "BMF",
            //             "plateNo": "BMF 88",
            //             "totalRecord": 27,
            //             "value": 50000
            //         },
            //         {
            //             "vehicleId": 29,
            //             "vehicleName": "White Hilux",
            //             "plateNo": "WD 8023 C",
            //             "totalRecord": 27,
            //             "value": 40000
            //         },
            //         {
            //             "vehicleId": 200011,
            //             "vehicleName": "Sheng Staging Test",
            //             "plateNo": "SST 123",
            //             "totalRecord": 27,
            //             "value": 30000
            //         },
            //         {
            //             "vehicleId": 52,
            //             "vehicleName": "VEHICLE TEMP",
            //             "plateNo": null,
            //             "totalRecord": 27,
            //             "value": 10000
            //         },
            //         {
            //             "vehicleId": 8,
            //             "vehicleName": "VEHICLE TEMP11ssssssssssssssssssssssssssss",
            //             "plateNo": "Temp 112",
            //             "totalRecord": 27,
            //             "value": 5000
            //         }
            //     ]
            // };
            // const topFuelPerformanceStats = test;
            this.topFuelPerformanceStatsCache = apiStats;
            // }

            // console.log(JSON.stringify(this.topFuelPerformanceStats, null, 2));
            if (apiStats && apiStats.body['type']) {
                const stats = apiStats.body;

                // Stats
                if (this.topFuelPerformanceSearchType == 'FuelCost') {
                    const curVal = NumberUtil.roundOff(stats.months[0].AverageFuelCost, 2);
                    const prevVal = NumberUtil.roundOff(stats.months[1].AverageFuelCost, 2);
                    const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                    this.topFuelPerformanceLabel = NumberUtil.formatFloat(curVal, 1, '0');
                    this.topFuelPerformanceCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true, false);
                    this.topFuelPerformanceDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Month: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;
                } else if (this.topFuelPerformanceSearchType == 'FuelEfficiency') {
                    const curVal = NumberUtil.roundOff(stats.months[0].AverageFuelEfficiency, 2);
                    const prevVal = NumberUtil.roundOff(stats.months[1].AverageFuelEfficiency, 2);
                    const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                    this.topFuelPerformanceLabel = NumberUtil.formatFloat(curVal, 1, '0');
                    //healthyNature increase mean good, so need be true (due to changed from L/100km to km/L)
                    this.topFuelPerformanceCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true, true);
                    this.topFuelPerformanceDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Month: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;
                } else {
                    const curVal = NumberUtil.roundOff(stats.months[0].AverageFuelUsed, 2);
                    const prevVal = NumberUtil.roundOff(stats.months[1].AverageFuelUsed, 2);
                    const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                    this.topFuelPerformanceLabel = NumberUtil.formatFloat(curVal, 1, '0');
                    this.topFuelPerformanceCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true, false);
                    this.topFuelPerformanceDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Month: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;
                }

                // Chart
                await this.generateTopFuelPerformanceBarChart(this.topFuelPerformanceSearchType, stats.topFive);
            } else {
                // Remove old chart & stats
                await this.generateTopFuelPerformanceBarChart(this.topFuelPerformanceSearchType, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.topFuelPerformance_spinner.hide();
        }
    }

    // ChartStat: Recent 5 months Fuel Performance
    async getMonthlyFuelPerformance(useCache: boolean = false) {
        try {
            this.monthlyFuelPerformance_spinner.show();

            if (StringUtil.equalsIgnoreCase(this.monthlyFuelPerformanceSearchType, 'FuelCost')) {
                this.monthlyFuelPerformanceTitle = "Total Fuel Cost (RM)";
                this.monthlyFuelPerformanceIcon = "icon-fuel-performance-rm.svg";
            } else if (StringUtil.equalsIgnoreCase(this.monthlyFuelPerformanceSearchType, 'FuelEfficiency')) {
                this.monthlyFuelPerformanceTitle = "Overall Fuel Efficiency (km/L)";
                this.monthlyFuelPerformanceIcon = "icon-fuel-performance-percent.svg";
            } else if (StringUtil.equalsIgnoreCase(this.monthlyFuelPerformanceSearchType, 'FuelUsed')) {
                this.monthlyFuelPerformanceTitle = "Total Fuel Used (L)";
                this.monthlyFuelPerformanceIcon = "icon-fuel-performance-l-cursive.svg";
            }

            // Caching
            let apiStats: any = null;
            if (useCache && this.monthlyFuelPerformanceStatsCache && this.monthlyFuelPerformanceStatsCache.body) {
                await AsyncUtil.wait(50);
                apiStats = this.monthlyFuelPerformanceStatsCache;
            } else {
                // this.todayDateMoment = moment(this.todayDate1);
                const now = moment(this.todayDateMoment);
                apiStats = await this.statsService.getMonthlyFuelPerformance(this.selectedGroupId, this.monthlyFuelPerformanceSearchType, this.monthlyFuelPerformanceNoOfMonths, now.format('YYYY-MM-DD'));
                // await AsyncUtil.wait(1000);
                // const test = {
                //     "type": "FuelCost",
                //     "result": [
                //         {
                //             "year": 2019,
                //             "month": 7,
                //             "value": 987
                //         },
                //         {
                //             "year": 2019,
                //             "month": 6,
                //             "value": 525
                //         },
                //         {
                //             "year": 2019,
                //             "month": 5,
                //             "value": 771
                //         },
                //         {
                //             "year": 2019,
                //             "month": 4,
                //             "value": 333
                //         },
                //         {
                //             "year": 2019,
                //             "month": 3,
                //             "value": 1024
                //         }
                //     ]
                // };
                // fuelPerformanceStats = test;
                this.monthlyFuelPerformanceStatsCache = apiStats;
            }

            // console.log(JSON.stringify(fuelPerformanceStats, null, 2));
            if (apiStats && apiStats.body['type']) {
                // const stats = {
                //     months: apiStats.body.result
                // };
                const stats = apiStats.body;

                // Stats
                if (this.monthlyFuelPerformanceSearchType == 'FuelCost') {
                    const curVal = NumberUtil.roundOff(stats.months[0].TotalFuelCost, 2);
                    const prevVal = NumberUtil.roundOff(stats.months[1].TotalFuelCost, 2);
                    const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                    this.monthlyFuelPerformanceLabel = NumberUtil.formatFloat(curVal, 1, '0');
                    this.monthlyFuelPerformanceCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true, false);
                    this.monthlyFuelPerformanceDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Month: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;
                } else if (this.monthlyFuelPerformanceSearchType == 'FuelEfficiency') {
                    const curVal = NumberUtil.roundOff(stats.months[0].OverallFuelEfficiency, 2);
                    const prevVal = NumberUtil.roundOff(stats.months[1].OverallFuelEfficiency, 2);
                    const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                    this.monthlyFuelPerformanceLabel = NumberUtil.formatFloat(curVal, 1, '0');
                    //healthyNature increase mean good, so need be true (due to changed from L/100km to km/L)
                    this.monthlyFuelPerformanceCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true, true);
                    this.monthlyFuelPerformanceDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Month: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;
                } else {
                    const curVal = NumberUtil.roundOff(stats.months[0].TotalFuelUsed, 2);
                    const prevVal = NumberUtil.roundOff(stats.months[1].TotalFuelUsed, 2);
                    const diffVal = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));

                    this.monthlyFuelPerformanceLabel = NumberUtil.formatFloat(curVal, 1, '0');
                    this.monthlyFuelPerformanceCssClass = this.getCssClassUpDownIndicator(prevVal, curVal, true, false);
                    this.monthlyFuelPerformanceDesc = `${NumberUtil.formatFloat(diffVal, 1, '0')} (Last Month: ${NumberUtil.formatFloat(prevVal, 1, '0')})`;
                }

                // Chart
                await this.generateMonthlyFuelPerformanceLineChart(this.monthlyFuelPerformanceSearchType, stats.months);
            } else {
                // Remove old chart & stats
                await this.generateMonthlyFuelPerformanceLineChart(this.monthlyFuelPerformanceSearchType, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.monthlyFuelPerformance_spinner.hide();
        }
    }

    // ChartStat: Top Vehicle/Driver Scoring Stats
    async getScoringStats() {

        //update label last week date range
        // this.todayDateMoment = moment(this.todayDate1);
        const lastWeekDate: moment.Moment = moment(this.todayDateMoment).subtract(1, "weeks");
        const yearAndWeekNo: any = DateTimeUtil.fromDateToYearAndWeekNo(lastWeekDate);
        const dateRange: any = DateTimeUtil.fromWeekYearToDateRange(yearAndWeekNo.weekNo, yearAndWeekNo.year);
        const newDateRange = "(Last Week: " + dateRange.startDate.format("DD/MM") + " - " + dateRange.endDate.format("DD/MM") + ")";
        this.scoringStatsDateRange = newDateRange;

        await Promise.all([
            this.getVehicleScoringStats(),
            this.getDriverScoringStats()
        ]);
    }

    private async getVehicleScoringStats() {
        try {
            this.weeklyScoring_spinner.show();

            const pageSize: number = 5;
            // this.todayDateMoment = moment(this.todayDate1);
            const lastWeekDate: moment.Moment = moment(this.todayDateMoment).subtract(1, "weeks");
            const yearAndWeekNo: any = DateTimeUtil.fromDateToYearAndWeekNo(lastWeekDate);
            const weekNo: number = yearAndWeekNo.weekNo;
            const year: number = yearAndWeekNo.year;

            const apiStats: any = await this.statsService.getScoringStats("vehicle", pageSize, weekNo, year, this.scoringSearchSortAscending, this.selectedGroupId, this.scoringStatsNoOfWeeks);
            if (apiStats && apiStats.body) {
                const stats = apiStats.body;

                // Data for circle progress bar
                this.scoringVehicleStats = stats.weeks[0].TopFive;
                if (this.scoringVehicleStats.length) {
                    this.scoringVehicleTop = this.scoringVehicleStats[0];
                    this.scoringTopVehicleHexColor = this.getColorHexFromScore(this.scoringVehicleTop.Score);
                    // this.topVehicleCircleBarDegree = this.getDegreeFromScore(this.scoringTopVehicle.Score);
                    this.scoringVehicleOthers = this.scoringVehicleStats.slice(1);
                    this.scoringVehicleOthers.forEach(vehicle => {
                        vehicle.scoreHexColor = this.getColorHexFromScore(vehicle.Score);
                        vehicle.ScoreLabel = NumberUtil.formatFloat(vehicle.Score, 1, '0.0') + '%';
                    });
                } else {
                    this.scoringVehicleTop = this.scoringVehicleTopBlank;
                    this.scoringVehicleOthers = [this.scoringVehicleTopBlank, this.scoringVehicleTopBlank, this.scoringVehicleTopBlank, this.scoringVehicleTopBlank, this.scoringVehicleTopBlank];
                }
                this.scoringVehicleTop.scoreTitle = this.scoringVehicleTop.Score ? NumberUtil.formatFloat(this.scoringVehicleTop.Score, 1) + '%' : '-';

                // Weekly Average Score
                this.scoringVehicleLabelThisWeek = NumberUtil.roundOff(stats.weeks[0].AverageScore, 1) || 0;
                this.scoringVehicleLabelLastWeek = NumberUtil.roundOff(stats.weeks[1].AverageScore, 1) || 0;
                this.scoringVehicleLabelDiff = NumberUtil.roundOff(Math.abs(this.scoringVehicleLabelLastWeek - this.scoringVehicleLabelThisWeek), 1);
                this.scoringVehicleLabelCssClass = this.getCssClassUpDownIndicator(this.scoringVehicleLabelLastWeek, this.scoringVehicleLabelThisWeek, true);

                // stars rating
                this.scoringVehicleLabelStars = {
                    ...this.countRatingScores(this.scoringVehicleLabelThisWeek),
                    desc: null
                };
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.weeklyScoring_spinner.hide();
        }
    }

    private async getDriverScoringStats() {
        try {
            this.weeklyScoring_spinner.show();

            const pageSize: number = 5;
            // this.todayDateMoment = moment(this.todayDate1);
            const lastWeekDate: moment.Moment = moment(this.todayDateMoment).subtract(1, "weeks");
            const yearAndWeekNo: any = DateTimeUtil.fromDateToYearAndWeekNo(lastWeekDate);
            const weekNo: number = yearAndWeekNo.weekNo;
            const year: number = yearAndWeekNo.year;

            let apiStats: any = {};
            if (this.selectedGroupId < 0) {
                apiStats = await this.statsService.getScoringStats("driver", pageSize, weekNo, year, this.scoringSearchSortAscending, this.selectedGroupId, this.scoringStatsNoOfWeeks);
                if (apiStats && apiStats.body) {
                    const stats = apiStats.body;

                    // Data for circle progress bar
                    this.scoringDriverStats = stats.weeks[0].TopFive;
                    if (this.scoringDriverStats.length) {
                        this.scoringDriverTop = this.scoringDriverStats[0];
                        this.scoringTopDriverHexColor = this.getColorHexFromScore(this.scoringDriverTop.Score);
                        // this.topDriverCircleBarDegree = this.getDegreeFromScore(this.scoringTopDriver.Score);
                        this.scoringDriverOthers = this.scoringDriverStats.slice(1);
                        this.scoringDriverOthers.forEach(driver => {
                            driver.scoreHexColor = this.getColorHexFromScore(driver.Score);
                            driver.ScoreLabel = NumberUtil.formatFloat(driver.Score, 1, '0.0') + '%';
                        });
                    } else {
                        this.scoringDriverTop = this.scoringDriverTopBlank;
                        this.scoringDriverOthers = [this.scoringDriverTopBlank, this.scoringDriverTopBlank, this.scoringDriverTopBlank, this.scoringDriverTopBlank, this.scoringDriverTopBlank];
                    }
                    this.scoringDriverTop.scoreTitle = this.scoringDriverTop.Score ? NumberUtil.formatFloat(this.scoringDriverTop.Score, 1) + '%' : '-';

                    // Weekly Average Score
                    this.scoringDriverLabelThisWeek = NumberUtil.roundOff(stats.weeks[0].AverageScore, 1) || 0;
                    this.scoringDriverLabelLastWeek = NumberUtil.roundOff(stats.weeks[1].AverageScore, 1) || 0;
                    this.scoringDriverLabelDiff = NumberUtil.roundOff(Math.abs(this.scoringDriverLabelLastWeek - this.scoringDriverLabelThisWeek), 1);
                    this.scoringDriverLabelCssClass = this.getCssClassUpDownIndicator(this.scoringDriverLabelLastWeek, this.scoringDriverLabelThisWeek, true);

                    // stars rating
                    this.scoringDriverLabelStars = {
                        ...this.countRatingScores(this.scoringDriverLabelThisWeek),
                        desc: null
                    };
                }
            } else {
                this.scoringDriverTop = this.scoringDriverTopBlank;
                this.scoringDriverOthers = [this.scoringDriverTop];
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.weeklyScoring_spinner.hide();
        }
    }

    // ChartStat: Weekly Violation Stats
    async getVehicleViolationStats() {
        try {
            this.weeklyViolationCount_spinner.show();

            // const vehicleStats = await this.statsService.getVehicleStats(2, this.todayDate, this.selectedGroupId);
            const apiStats = await this.statsService.getVehicleViolationStats(this.weeklyViolationStatsNoOfWeeks, this.todayDate, this.selectedGroupId);

            // await AsyncUtil.wait(1000);
            const test = {
                body: {
                    "VehicleStats": [
                        {
                            "CompanyId": 38,
                            "Year": 2019,
                            "Week": 28,
                            "IdleTime": 8017.78,
                            "DrivingDistance": 521.89,
                            "SpeedingDistance": 32.36
                        },
                        {
                            "CompanyId": 38,
                            "Year": 2019,
                            "Week": 27,
                            "IdleTime": 25521.21,
                            "DrivingDistance": 1341.74,
                            "SpeedingDistance": 40.25
                        }
                    ],
                    "ViolationCount": [
                        {
                            "CompanyId": 38,
                            "Year": 2019,
                            "Week": 28,
                            "HarshAcceleration": 0,
                            "HarshBrake": 0,
                            "HarshTurn": 0,
                            "Speeding": 0,
                            "RestrictedDrivingHour": 0,
                            "Mileage": 0,
                            "TotalVehicleCount": 7,
                            TotalViolationCount: 0
                        },
                        {
                            "CompanyId": 38,
                            "Year": 2019,
                            "Week": 27,
                            "HarshAcceleration": 0,
                            "HarshBrake": 13,
                            "HarshTurn": 18,
                            "Speeding": 67,
                            "RestrictedDrivingHour": 3,
                            "Mileage": 15,
                            "TotalVehicleCount": 7,
                            TotalViolationCount: 90
                        }
                    ]
                }
            };
            // const apiStats = test;

            // console.log(JSON.stringify(vehicleStats, null, 2));
            // if (vehicleStats && vehicleStats.VehicleStats && vehicleStats.ViolationCount) {
            if (apiStats && apiStats.body) {

                // Vehicle stats (To be moved to individual chart APIs)
                // const weekVehicleStats = apiResponse.body.VehicleStats;

                // parse some values to reduce function data bindings
                // this.weekMileageLabel = weekVehicleStats[0].DrivingDistance + 'km';
                // this.weekSpeedingDistanceLabel = weekVehicleStats[0].SpeedingDistance + 'km';
                // this.weekIdlingDurationLabel = weekVehicleStats.length ? NumberUtil.formatHourMinute(weekVehicleStats[0].IdleTime / 60) : '00:00';
                // this.vehicleStatsTypes.forEach(statType => {
                //     const curVal = weekVehicleStats[0][statType];
                //     const prevVal = weekVehicleStats[1][statType];
                //     this.diffVehicleStats[statType] = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));
                //     if (statType != 'DrivingDistance') {
                //         this.classVehicleStats[statType] = this.getCssClassUpDownIndicator(prevVal, curVal, true, false);
                //     } else {
                //         this.classVehicleStats[statType] = this.getCssClassUpDownIndicator(prevVal, curVal, false);
                //     }
                // });
                // this.descVehicleStats['DrivingDistance'] = `${this.diffVehicleStats['DrivingDistance']}km (Last Week: ${
                //     weekVehicleStats[1]['DrivingDistance']}km)`;
                // this.descVehicleStats['SpeedingDistance'] = `${this.diffVehicleStats['SpeedingDistance']}km (Last Week: ${
                //     weekVehicleStats[1]['SpeedingDistance']}km)`;
                // this.descVehicleStats['IdleTime'] = `${NumberUtil.formatHourMinute(this.diffVehicleStats['IdleTime'] / 60)} (Last Week: ${NumberUtil.formatHourMinute(weekVehicleStats[1]['IdleTime'] / 60)})`;

                // Violation counts
                const weekViolationStats = apiStats.body.ViolationCount;

                // parse some values to reduce function data bindings
                this.weekVioHBLabel = weekViolationStats[0].HarshBrake || 0;
                this.weekVioHALabel = weekViolationStats[0].HarshAcceleration || 0;
                this.weekVioHCLabel = weekViolationStats[0].HarshTurn || 0;
                this.weekVioSpeedingLabel = weekViolationStats[0].Speeding || 0;
                this.weekVioDriveTimeLabel = weekViolationStats[0].RestrictedDrivingHour || 0;
                this.weekVioMileageLabel = weekViolationStats[0].Mileage || 0;

                this.lastweekVioHBLabel = weekViolationStats[1].HarshBrake || 0;
                this.lastweekVioHALabel = weekViolationStats[1].HarshAcceleration || 0;
                this.lastweekVioHCLabel = weekViolationStats[1].HarshTurn || 0;
                this.lastweekVioSpeedingLabel = weekViolationStats[1].Speeding || 0;
                this.lastweekVioDriveTimeLabel = weekViolationStats[1].RestrictedDrivingHour || 0;
                this.lastweekVioMileageLabel = weekViolationStats[1].Mileage || 0;

                // Total weekly violation stats
                this.violationStatsTotalThisWeek = weekViolationStats[0].TotalViolationCount || 0;
                this.violationStatsTotalLastWeek = weekViolationStats[1].TotalViolationCount || 0;
                this.violationStatsTotalDiff = Math.abs(this.violationStatsTotalLastWeek - this.violationStatsTotalThisWeek);
                this.violationStatsTotalCssClass = this.getCssClassUpDownIndicator(this.violationStatsTotalLastWeek, this.violationStatsTotalThisWeek, true, false);
                this.violationTypes.forEach(violation => {
                    const thisWkVal = weekViolationStats[0][violation];
                    const lasyWkVal = weekViolationStats[1][violation];
                    this.classViolations[violation] = this.getCssClassUpDownIndicator(lasyWkVal, thisWkVal, true, false);
                });

                // stars rating
                const averageViolationCount = NumberUtil.roundOff(this.violationStatsTotalThisWeek / weekViolationStats[0].TotalVehicleCount, 2);
                this.violationStatsTotalStars = {
                    ...this.countRatingWeekViolation(averageViolationCount),
                    desc: `Average Vehicle Violation Count: ${averageViolationCount}`
                };

                // Chart
                if (this.violationStatsTotalThisWeek > 0) {
                    const chartStats = {
                        'Harsh Brake': this.weekVioHBLabel,
                        'Harsh Acceleration': this.weekVioHALabel,
                        'Harsh Turn': this.weekVioHCLabel,
                        'Speeding': this.weekVioSpeedingLabel,
                        'Drive Time': this.weekVioDriveTimeLabel,
                        'Mileage': this.weekVioMileageLabel
                    };
                    await this.generateWeeklyViolationStatsDoughnutChart(this.weeklyViolationStatSearchType, chartStats);
                } else {
                    await this.generateWeeklyViolationStatsDoughnutChart(this.weeklyViolationStatSearchType, null);
                }
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.weeklyViolationCount_spinner.hide();
        }
    }

    /* ---- Dashboard Helper functions ---- */

    /**
     * Returns a string of CSS classes after comparing `val1` and `val2`
     * @param val1 First value
     * @param val2 Second value
     * @param color Whether return add color class (Default = true)
     * @param healthyNature Whether increases means good (Default = true)
     * @param indicator Whether to return indicator class (Default = false)
     */
    getCssClassUpDownIndicator(val1: number = 0, val2: number = 0, color: boolean = true, healthyNature: boolean = true, indicator: boolean = false): string {
        val1 = NumberUtil.isNumeric(val1) ? val1 : 0;
        val2 = NumberUtil.isNumeric(val2) ? val2 : 0;

        const classes: string[] = [];
        const CLASS_INDICATOR = '--indicator';
        const CLASS_DASH = '--dash';
        const CLASS_ARROW_UP = '--up';
        const CLASS_ARROW_DOWN = '--down';
        const CLASS_RED = '--alert-red';
        const CLASS_GREEN = '--alert-green';

        if (val1 == val2) {
            classes.push(CLASS_DASH);
        } else {
            if (val1 < val2) {
                classes.push(CLASS_ARROW_UP);
                if (color) {
                    classes.push(healthyNature ? CLASS_GREEN : CLASS_RED);
                }
            } else {
                classes.push(CLASS_ARROW_DOWN);
                if (color) {
                    classes.push(healthyNature ? CLASS_RED : CLASS_GREEN);
                }
            }
        }
        if (indicator) {
            classes.push(CLASS_INDICATOR);
        }
        return classes.join(' ');
    }

    // JS: Get CSS color code for scoring
    private getColorHexFromScore(score: number): string {
        let colorHex = Colors.HEX_GREY;
        if (score >= 80) {
            colorHex = Colors.HEX_GREEN;
        } else if (score >= 50) {
            colorHex = Colors.HEX_YELLOWISH_ORANGE;
        } else {
            colorHex = Colors.HEX_RED;
        }
        return colorHex;
    }

    // JS: Calculate number of crosses for average violation
    private countRatingWeekViolation(avgViolation: number) {
        let count = 0;
        let color = '';
        if (avgViolation < 11) {
            count = 0;
            color = 'green';
        } else if (avgViolation < 21) {
            count = 1;
            color = 'amber';
        } else if (avgViolation < 36) {
            count = 2;
            color = 'amber';
        } else {
            count = 3;
            color = 'red';
        }
        return {
            count,
            color
        };
    }

    // JS: Calculate number of stars for average scores
    private countRatingScores(averageScore: number) {
        let count = 0;
        let color = '';
        if (averageScore == 0) {
            count = 0;
            color = 'black';
        } else if (averageScore < 25) {
            count = 0;
            color = 'red';
        } else if (averageScore < 50) {
            count = 1;
            color = 'red';
        } else if (averageScore < 80) {
            count = 2;
            color = 'amber';
        } else {
            count = 3;
            color = 'green';
        }
        return {
            count,
            color
        };
    }

    /* ---- ChartJS ---- // ---- Circle Progress Bar is done within HTML ---- */

    initChartJsDefaults() {
        Chart.defaults.global.legend.display = false;
        // this.randomColourGenerator();
        this.colourList = [
            Colors.RGBA_PRI1_SHARP_GREEN,
            Colors.RGBA_PRI3_DARK_GREEN,
            Colors.RGBA_PRI4_GRASS_GREEN,
            Colors.RGBA_HL3_SKY_BLUE,
            Colors.RGBA_HL2_ROYALE_BLUE,
            Colors.RGBA_VIOLET,
            Colors.RGBA_ORANGE,
            Colors.RGBA_BRIGHT_RED,
            Colors.RGBA_GRAY,
            Colors.RGBA_DARK_GREEN
        ];
        this.violationDoughnutColourList = [
            Colors.RGBA_PRI3_DARK_GREEN,
            Colors.RGBA_PRI4_GRASS_GREEN,
            Colors.RGBA_HL3_SKY_BLUE,
            Colors.RGBA_HL2_ROYALE_BLUE,
            Colors.RGBA_CTA6_PURPLE,
            Colors.RGBA_PRI1_SHARP_GREEN
        ];
        this.chartJsHoverProps = {
            animationDuration: 300,
            onHover: function (e: MouseEvent) {
                const point = this.getElementAtEvent(e);
                if (point.length) {
                    (<HTMLElement>e.target).style.cursor = 'pointer';
                } else {
                    (<HTMLElement>e.target).style.cursor = 'default';
                }
            }
        };
        this.chartJsAnimation = {
            easing: "easeInOutCubic"
        };
        this.chartJsTooltipStyles = {
            titleAlign: 'center',
            bodyAlign: 'center',
            yPadding: 8,
            xPadding: 10
        };
        this.chartJsBarHoverInteraction = {
            hoverBorderColor: Colors.RGBA_WHITE_SHADE,
            hoverBorderWidth: 2
        };
        this.chartJsScaleLabelFontStyles = {
            fontStyle: "bold",
            fontColor: Colors.RGBA_BLACK,
            fontSize: 13
        };
        this.chartJsTicksFontStyles = {
            fontColor: Colors.RGBA_BLACK,
            fontSize: 12
        };
        this.chartJsTicksNumberTruncateCallbackFn = function (value) {
            if (value >= 1000000) {
                return NumberUtil.roundOff(value / 1000000, 1) + 'mil';
            } else if (value >= 10000) {
                return NumberUtil.roundOff(value / 1000, 1) + 'k';
            }
            return value;
        };
        this.chartJsTicksStringEllipsisCallbackFn = function (value, index, values) {
            // truncate name of ticks in axis
            return StringUtil.truncateText(value, CHART_TICK_LABEL_MAX_LENGTH, 'N/A');
        };
        this.chartJsTooltipTruncateCallbackTitleFn = function (tooltipItem, cdata) {
            // console.log('title:', tooltipItem);
            // console.log('title:', cdata);
            // truncate for tooltip
            const label = cdata.labels[tooltipItem[0].index];
            return StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
        };
    }

    // ChartJS Helper: Create linear canvas gradient
    makeLinearCanvasGradient(canvasId: string, colorRgba: string, height: number = 200): CanvasGradient {
        // Render Gradient
        const ctx: CanvasRenderingContext2D = (<HTMLCanvasElement>this.el.nativeElement.querySelector(`#${canvasId}`)).getContext('2d');
        const cg: CanvasGradient = ctx.createLinearGradient(0, 0, 0, height);
        const rgb = DomUtil.getRgbaValues(colorRgba, false);
        cg.addColorStop(0, DomUtil.makeRgbaString(rgb.concat([0.9]))); //opacity 0.9
        cg.addColorStop(0.3, DomUtil.makeRgbaString(rgb.concat([0.9]))); //opacity 0.9
        cg.addColorStop(0.7, DomUtil.makeRgbaString(rgb.concat([0.5]))); //opacity 0.5
        cg.addColorStop(1, DomUtil.makeRgbaString(rgb.concat([0.2]))); //opacity 0.2
        return cg;
    }

    // ChartJS Helper: Template Generator Configuration Override
    overrideChartJsOptions(cOptions: any, options: any) {
        if (options.onClickFn) {
            cOptions.options['onClick'] = options.onClickFn;
        }
        if (options.tooltipCb) {
            cOptions.options.tooltips['callbacks'] = options.tooltipCb;
        }
        if (options.xLabel) {
            cOptions.options.scales.xAxes[0]['scaleLabel'] = {
                display: true,
                labelString: options.xLabel,
                ...this.chartJsScaleLabelFontStyles
            };
        }
        if (options.yLabel) {
            cOptions.options.scales.yAxes[0]['scaleLabel'] = {
                display: true,
                labelString: options.yLabel,
                ...this.chartJsScaleLabelFontStyles
            };
        }
        // Apply custom configurations
        if (options.custom) {
            cOptions = options.custom(cOptions);
        }
        return cOptions;
    }

    // ChartJS Helper: Helper for Custom Legend -> generateLabels()
    getValueAtIndexOrDefault(value, index, defaultValue) {
        if (value === undefined || value === null) {
            return defaultValue;
        }
        if (Array.isArray(value)) {
            return index < value.length ? value[index] : defaultValue;
        }
        return value;
    }

    /* ---- ChartJS Template Generator ----  */

    genColorfulHorizontalBarChart(chartObj: DashboardChartChildObj, options: any) {
        const {
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        let cOptions = {
            type: 'horizontalBar',
            data: {
                labels: tickLabels,
                datasets: [{
                    data: tickValues,
                    backgroundColor: this.colourList,
                    ...this.chartJsBarHoverInteraction
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    ...this.chartJsTooltipStyles
                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        barThickness: "flex",
                        maxBarThickness: 20,
                        ticks: {
                            ...this.chartJsTicksFontStyles,
                            callback: this.chartJsTicksStringEllipsisCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: false,
                            lineWidth: 3,
                            zeroLineWidth: 3,
                            color: Colors.RGBA_LIGHT_GRAY
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxRotation: 20,
                            maxTicksLimit: 7,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 1, 1, 1, 1, 1, 1],
                            zeroLineWidth: 3,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    genPlainBarChart(chartObj: DashboardChartChildObj, options: any) {
        const {
            priColor, tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        let cOptions = {
            type: 'bar',
            data: {
                labels: tickLabels,
                datasets: [{
                    data: tickValues,
                    backgroundColor: priColor,
                    ...this.chartJsBarHoverInteraction
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 10,
                        bottom: -5
                    }
                },
                tooltips: {
                    displayColors: false,
                    ...this.chartJsTooltipStyles
                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxTicksLimit: 6,
                            autoSkip: false,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: 1,
                            zeroLineWidth: 0,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }],
                    xAxes: [{
                        barThickness: "flex",
                        maxBarThickness: 25,
                        ticks: {
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxRotation: 20,
                            maxTicksLimit: 5,
                            autoSkip: false,
                            callback: this.chartJsTicksStringEllipsisCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 0, 0, 0, 0],
                            zeroLineWidth: 3,
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    genGradientLineChart(chartObj: DashboardChartChildObj, options: any) {
        const {
            priColor = Colors.RGBA_PRI3_DARK_GREEN,
            secColor = Colors.RGBA_WHITE,
            areaColor = Colors.RGBA_PRI4_GRASS_GREEN,
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        // Make Gradient
        const linearGradient = this.makeLinearCanvasGradient(chartObj.canvasId, areaColor);
        let cOptions = {
            type: 'line',
            data: {
                labels: tickLabels,
                datasets: [{
                    data: tickValues,
                    fill: true,
                    lineTension: 0.35,
                    borderWidth: 1.2,
                    pointBorderWidth: 1.2,
                    borderColor: priColor,
                    backgroundColor: linearGradient,
                    pointBackgroundColor: secColor,
                    pointHoverBackgroundColor: priColor,
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    pointHitRadius: 10
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 10,
                        bottom: -5
                    }
                },
                tooltips: {
                    displayColors: false,
                    ...this.chartJsTooltipStyles
                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxTicksLimit: 6,
                            autoSkip: false,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: 1,
                            zeroLineWidth: 0,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxRotation: 20,
                            maxTicksLimit: 5,
                            autoSkip: false
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 1, 1, 1, 1],
                            zeroLineWidth: 3,
                            color: [Colors.RGBA_LIGHT_GRAY, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE],
                            zeroLineColor: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    genGradientLineChartSmallerDots(chartObj: DashboardChartChildObj, options: any) {
        const {
            priColor = Colors.RGBA_PRI3_DARK_GREEN,
            secColor = Colors.RGBA_WHITE,
            areaColor = Colors.RGBA_PRI4_GRASS_GREEN,
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        // Make Gradient
        const linearGradient = this.makeLinearCanvasGradient(chartObj.canvasId, areaColor);
        let cOptions = {
            type: 'line',
            data: {
                labels: tickLabels,
                datasets: [{
                    data: tickValues,
                    fill: true,
                    lineTension: 0,
                    borderWidth: 1.2,
                    pointBorderWidth: 1.2,
                    borderColor: priColor,
                    backgroundColor: linearGradient,
                    pointBackgroundColor: priColor,
                    pointHoverBackgroundColor: secColor,
                    pointRadius: 2,
                    pointHoverRadius: 4,
                    pointHitRadius: 5
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 10,
                        bottom: -5
                    }
                },
                tooltips: {
                    displayColors: false,
                    ...this.chartJsTooltipStyles
                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxTicksLimit: 6,
                            autoSkip: false,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: 1,
                            zeroLineWidth: 0,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            fontSize: 11,
                            maxRotation: 20,
                            maxTicksLimit: 5,
                            autoSkip: false
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 1, 1, 1, 1],
                            zeroLineWidth: 3,
                            color: [Colors.RGBA_LIGHT_GRAY, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE],
                            zeroLineColor: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    genColorfulDoughnutChart(chartObj: DashboardChartChildObj, options: any) {
        const {
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        let cOptions = {
            type: 'doughnut',
            data: {
                labels: tickLabels,
                datasets: [{
                    data: tickValues,
                    backgroundColor: this.violationDoughnutColourList,
                    borderWidth: 4,
                    hoverBorderColor: Colors.RGBA_WHITE_SHADE,
                    hoverBorderWidth: 5
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 10
                    }
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        fontSize: 14,
                        fontColor: Colors.RGBA_DARK_GRAY,
                        padding: 12,
                        usePointStyle: true
                        // generateLabels: (chart) => {
                        //     chart.legend.afterFit = function () {
                        //         const width = this.width;
                        //         this.lineWidths = this.lineWidths.map(() => this.width - width * 0.3);
                        //     };
                        //     const data = chart.data;
                        //     if (data.labels.length && data.datasets.length) {
                        //         return data.labels.map((label, i) => {
                        //             const meta = chart.getDatasetMeta(0);
                        //             const ds = data.datasets[0];
                        //             const arc = meta.data[i];
                        //             const custom = arc && arc.custom || {};
                        //             const getValueAtIndexOrDefault = this.getValueAtIndexOrDefault;
                        //             const arcOpts = chart.options.elements.arc;
                        //             const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                        //             const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                        //             const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                        //             return {
                        //                 text: label,
                        //                 fillStyle: fill,
                        //                 strokeStyle: stroke,
                        //                 lineWidth: bw,
                        //                 hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                        //                 index: i // for toggling the correct item
                        //             };
                        //         });
                        //     }
                        //     return [];
                        // }
                    }
                },
                tooltips: {
                    ...this.chartJsTooltipStyles
                },
                hover: this.chartJsHoverProps,
                cutoutPercentage: 48,
                animation: {
                    animateScale: true,
                    ...this.chartJsAnimation
                }
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    genPlainFloatingLineChart(chartObj: DashboardChartChildObj, options: any) {
        const {
            priColor = Colors.RGBA_HL1_PINK,
            secColor = Colors.RGBA_HL1_PINK,
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        let cOptions = {
            type: 'bar',
            data: {
                labels: tickLabels,
                datasets: [{
                    type: 'line',
                    data: tickValues,
                    fill: false,
                    lineTension: 0,
                    borderWidth: 2,
                    pointBorderWidth: 2,
                    borderColor: priColor, //Colors.RGBA_HL1_PINK
                    pointBackgroundColor: secColor, //Colors.RGBA_HL1_PINK
                    pointHoverBackgroundColor: priColor,
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    pointHitRadius: 10
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 10,
                        bottom: -5
                    }
                },
                tooltips: {
                    displayColors: false,
                    ...this.chartJsTooltipStyles
                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxTicksLimit: 6,
                            autoSkip: false,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: 1,
                            zeroLineWidth: 0,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxRotation: 0,
                            maxTicksLimit: 5,
                            autoSkip: false
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 0, 0, 0, 0],
                            zeroLineWidth: 3,
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    /* ---- Charts Implementation ----  */

    // ChartJS: Bar Chart For Top 5 Mileage
    async generateTopMileageBarChart(searchType, data) {
        // Clear Previous Chart Data
        this.dashCharts.TopFiveMileage.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        const tickLabels = data.map((result, index) => searchType + " " + (index + 1));
        const tickValues = data.map(result => result.totalMileage);

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        const _this = this;
        // const onClickFn = function (e) {
        //     const element = this.getElementAtEvent(e);
        //     if (element.length) {
        //         //if user have view past trip permission then only can redirect
        //         if (_this.isPastTripViewable) {
        //             const index = element[0]._index;
        //             const imeiNo = data[index].imeiNo;
        //             _this.routerProxyService.navigateByUrl('/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/' + imeiNo + ')?view=trips');
        //         }
        //     }
        // };

        // Construct Bar Chart
        const options = {
            xLabel: 'Distance (km)', // X-Axis Title
            tickLabels, //Y-Axis Labels
            tickValues, //Y-Axis Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    return ' ' + tooltipItem.value + ' km';
                }
            },
            // onClickFn
        };
        this.dashCharts.TopFiveMileage.chart = this.genColorfulHorizontalBarChart(this.dashCharts.TopFiveMileage, options);
    }

    // ChartJS: Bar Chart For Top 5 Speeding
    async generateTopSpeedingBarChart(searchType, data) {
        // Clear Previous Chart Data
        this.dashCharts.TopFiveSpeeding.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        const tickLabels = data.map((result,index) => searchType + " " + (index + 1));
        const tickValues = data.map(result => result.totalSpeedingDistance);

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        const _this = this;
        // const onClickFn = function (e) {
        //     const element = this.getElementAtEvent(e);
        //     if (element.length) {
        //         //if user have view past trip permission then only can redirect
        //         if (_this.isPastTripViewable) {
        //             const index = element[0]._index;
        //             const imeiNo = data[index].imeiNo;
        //             _this.routerProxyService.navigateByUrl('/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/' + imeiNo + ')?view=trips');
        //         }
        //     }
        // };

        // Construct Bar Chart
        const options = {
            xLabel: 'Distance (km)', // X-Axis Title
            tickLabels, //Y-Axis Labels
            tickValues, //Y-Axis Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    return ' ' + tooltipItem.value + ' km';
                }
            },
            // onClickFn
        };
        this.dashCharts.TopFiveSpeeding.chart = this.genColorfulHorizontalBarChart(this.dashCharts.TopFiveSpeeding, options);
    }

    // ChartJS: Bar Chart For Top 5 Idling
    async generateTopIdlingBarChart(searchType, data) {
        // Clear Previous Chart Data
        this.dashCharts.TopFiveIdling.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        const tickLabels = data.map((result,index) => searchType + " " + (index + 1));
        const tickValues = data.map(result => NumberUtil.roundOff(result.totalIdlingTime / 60, 2));

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        const _this = this;
        // const onClickFn = function (e) {
        //     const element = this.getElementAtEvent(e);
        //     if (element.length) {
        //         //if user have view past trip permission then only can redirect
        //         if (_this.isPastTripViewable) {
        //             const index = element[0]._index;
        //             const imeiNo = data[index].imeiNo;
        //             _this.routerProxyService.navigateByUrl('/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/' + imeiNo + ')?view=trips');
        //         }
        //     }
        // };

        // Construct Bar Chart
        const options = {
            xLabel: 'Duration (hours)', //X-Axis Title
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Format hh:mm
                    return ' ' + NumberUtil.formatHourMinute(tooltipItem.value);
                }
            },
            // onClickFn
        };
        this.dashCharts.TopFiveIdling.chart = this.genColorfulHorizontalBarChart(this.dashCharts.TopFiveIdling, options);
    }

    // ChartJS: Line Chart For Fleet Utilisation Weekly
    async generateWeeklyFleetUtilisationLineChart(searchType, data) {
        // Clear Previous Chart Data
        this.dashCharts.WeeklyFleetUtilisation.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        data = data.slice(0).reverse(); // descending order
        const tickLabels = [];
        for (let i = 0; i < data.length; i++) {
            const tempLabel = []; // expect length of 2 for multi line labels
            const { startDate, endDate } = DateTimeUtil.fromWeekYearToDateRange(data[i].Week, data[i].Year);
            const weekNoLabel = (i == data.length - 1) ? 'This Week' : `Week ${data[i].Week}`;
            const dateRangeStart = startDate.format("DD/MM");
            const dateRangeEnd = endDate.format("DD/MM");
            const dateRangeLabel = `(${dateRangeStart} - ` + (i == data.length - 1 ? `Today` : `${dateRangeEnd}`) + `)`;
            tempLabel.push(weekNoLabel, dateRangeLabel);
            tickLabels.push(tempLabel);
        }
        const tickValues = searchType == 'distance' ? data.map(result => result.TotalDistance) : data.map(result => result.TotalDuration);

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        // Y-Axis Title
        let yLabel = "Distance (km)";
        let tooltipPrefix = "";
        let tooltipSuffix = " km";
        if (StringUtil.equalsIgnoreCase(searchType, 'hour')) {
            yLabel = "Duration (hours)";
            tooltipPrefix = "";
            tooltipSuffix = " hrs";
        }

        // Construct Chart
        const options = {
            priColor: Colors.RGBA_PRI3_DARK_GREEN,
            secColor: Colors.RGBA_WHITE,
            areaColor: Colors.RGBA_PRI4_GRASS_GREEN,
            xLabel: null, //X-Axis Title
            yLabel, //Y-Axis Title
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values,
            tooltipCb: { //Tooltip Custom
                title: function (tooltipItem, cdata) {
                    // Add week date range
                    return cdata.labels[tooltipItem[0].index][0] + ' ' + cdata.labels[tooltipItem[0].index][1];
                },
                label: function (tooltipItem, cdata) {
                    if (StringUtil.equalsIgnoreCase(searchType, 'hour')) {
                        // Format hh:mm
                        return NumberUtil.formatHourMinute(tooltipItem.value);
                    } else {
                        // Add prefix & suffix
                        return tooltipPrefix + tooltipItem.value + tooltipSuffix;
                    }
                }
            },
            custom: function (cOptions) {
                if (cOptions.options.layout.padding) {
                    cOptions.options.layout.padding['bottom'] = -7;
                }
                if (cOptions.options.scales.xAxes[0].scaleLabel) {
                    cOptions.options.scales.xAxes[0].scaleLabel['padding'] = -5;
                }
                if (cOptions.options.scales.xAxes[0].ticks) {
                    cOptions.options.scales.xAxes[0].ticks['padding'] = 10;
                    cOptions.options.scales.xAxes[0].ticks['callback'] = function (value) {
                        if (Array.isArray(value) && value.length > 1) {
                            // Show only 1 line (week number)
                            return value[0];
                        } else {
                            return value;
                        }
                    };
                }
                return cOptions;
            }
        };
        this.dashCharts.WeeklyFleetUtilisation.chart = this.genGradientLineChart(this.dashCharts.WeeklyFleetUtilisation, options);
    }

    // ChartJS: Line Chart For Fleet Utilisation Hourly
    async generateHourlyFleetUtilisationLineChart(searchType, data) {
        // Clear Previous Chart Data
        this.dashCharts.HourlyFleetUtilisation.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        const tickLabels = data.map(result => result.Time);
        const tickValues = searchType == 'percentage' ? data.map(result => NumberUtil.roundOff(result.PercentageVehicles * 100, 2)) : data.map(result => result.TotalDistance);

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        // Construct Chart
        const options = {
            priColor: Colors.RGBA_PRI3_DARK_GREEN,
            secColor: Colors.RGBA_WHITE,
            areaColor: Colors.RGBA_PRI4_GRASS_GREEN,
            xLabel: 'Hour (hh)', //X-Axis Title
            yLabel: (searchType === 'distance') ? 'Distance (km)' : 'Percentage (%)', //Y-Axis Title
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values,
            tooltipCb: { //Tooltip Custom
                title: function (tooltipItem, cdata) {
                    // Show full label
                    const hh2hh = cdata.labels[tooltipItem[0].index];
                    const startHh = hh2hh.slice(0, 2) + '00';
                    const endHh = hh2hh.slice(-2) + '00';
                    return startHh + '-' + endHh;
                },
                label: function (tooltipItem, cdata) {
                    // Add suffix
                    return tooltipItem.value + '% (' + data[tooltipItem.index].VehicleNum + ')';
                }
            },
            custom: function (cOptions) {
                if (cOptions.options.layout.padding) {
                    cOptions.options.layout.padding['bottom'] = 0; //because x-axis ticks not rotated, so no need -5 padding
                }
                if (cOptions.options.scales.yAxes[0].ticks) {
                    cOptions.options.scales.yAxes[0].ticks['min'] = 0;
                    cOptions.options.scales.yAxes[0].ticks['stepSize'] = 20;
                    cOptions.options.scales.yAxes[0].ticks['max'] = 100;
                }
                if (cOptions.options.scales.xAxes[0].ticks) {
                    cOptions.options.scales.xAxes[0].ticks['fontSize'] = 11;
                    cOptions.options.scales.xAxes[0].ticks['autoSkip'] = true;
                    cOptions.options.scales.xAxes[0].ticks['minRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxTicksLimit'] = 25;
                    cOptions.options.scales.xAxes[0].ticks['callback'] = function (value) {
                        // Show hour in HH format
                        return value.slice(0, 2);
                    };
                }
                if (cOptions.options.scales.xAxes[0].scaleLabel) {
                    cOptions.options.scales.xAxes[0].scaleLabel['padding'] = 4;
                }
                if (cOptions.options.scales.xAxes[0].gridLines) {
                    const lineWidth24hr = [3];
                    const color24hr = [Colors.RGBA_LIGHT_GRAY];
                    for (let i = 0; i < 24; i++) {
                        lineWidth24hr.push(1);
                        color24hr.push(Colors.RGBA_LIGHT_GRAY_SHADE);
                    }
                    cOptions.options.scales.xAxes[0].gridLines['lineWidth'] = lineWidth24hr;
                    cOptions.options.scales.xAxes[0].gridLines['color'] = color24hr;
                }
                return cOptions;
            }
        };
        this.dashCharts.HourlyFleetUtilisation.chart = this.genGradientLineChartSmallerDots(this.dashCharts.HourlyFleetUtilisation, options);
    }

    // ChartJS: Bar Chart For Top 5 Fuel Performance
    async generateTopFuelPerformanceBarChart(searchType, data) {
        // Clear Previous Chart Data
        this.dashCharts.TopFiveFuelPerformance.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        const tickLabels = data.map((result,index) => searchType + " " + (index + 1));
        let tickValues = [];
        if (StringUtil.equalsIgnoreCase(searchType, 'FuelCost')) {
            tickValues = data.map(result => result.fuelCost);
        } else if (StringUtil.equalsIgnoreCase(searchType, 'FuelEfficiency')) {
            tickValues = data.map(result => result.fuelEfficiency);
        } else if (StringUtil.equalsIgnoreCase(searchType, 'FuelUsed')) {
            tickValues = data.map(result => result.fuelUsed);
        }

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        // Y-Axis Title
        let yLabel = "Fuel Cost (RM)";
        let tooltipPrefix = "RM ";
        let tooltipSuffix = "";
        if (StringUtil.equalsIgnoreCase(searchType, 'FuelUsed')) {
            yLabel = "Fuel Used (L)";
            tooltipPrefix = "";
            tooltipSuffix = " L";
        } else if (StringUtil.equalsIgnoreCase(searchType, 'FuelEfficiency')) {
            yLabel = "Fuel Efficiency (km/L)";
            tooltipPrefix = "";
            tooltipSuffix = " km/L";
        }

        const _this = this;
        // const onClickFn = function (e) {
        //     const element = this.getElementAtEvent(e);
        //     // console.log(element);
        //     if (element.length) {
        //         //if user have view fuel permission then only can redirect
        //         if (_this.isFuelViewable) {
        //             const index = element[0]._index;
        //             const vehicleName = data[index].vehicleName;
        //             _this.navigateToViewRefuelEntryByVehicleName(vehicleName);
        //         }
        //     }
        // };

        // Construct Bar Chart
        const options = {
            priColor: Colors.RGBA_HL2_ROYALE_BLUE,
            // xLabel: 'Vehicle', //X-Axis Title
            yLabel, //Y-Axis Title
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    return tooltipPrefix + tooltipItem.value + tooltipSuffix;
                }
            },
            // onClickFn,
            custom: function (cOptions) {
                if (cOptions.options.scales.xAxes[0].scaleLabel) {
                    cOptions.options.scales.xAxes[0].scaleLabel['padding'] = -5;
                }
                if (cOptions.options.scales.xAxes[0].ticks) {
                    cOptions.options.scales.xAxes[0].ticks['padding'] = 10;
                }
                return cOptions;
            }
        };
        this.dashCharts.TopFiveFuelPerformance.chart = this.genPlainBarChart(this.dashCharts.TopFiveFuelPerformance, options);
    }

    // ChartJS: Line Chart For Monthly Fuel Performance
    async generateMonthlyFuelPerformanceLineChart(searchType, data) {
        // Clear Previous Chart Data
        this.dashCharts.MonthlyFuelPerformance.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        data = data.slice(0).reverse(); // descending order
        const tickLabels = [];
        for (let i = 0; i < data.length; i++) {
            const tempLabel = [];
            const month = data[i].Month.toString().length == 1 ? '0' + data[i].Month : data[i].Month;
            const year = data[i].Year;
            const tempMonth = moment(month, "MM");
            tempLabel.push(tempMonth.format("MMM"), year);
            tickLabels.push(tempLabel);
        }
        let tickValues = [];
        if (StringUtil.equalsIgnoreCase(searchType, 'FuelCost')) {
            tickValues = data.map(result => result.TotalFuelCost);
        } else if (StringUtil.equalsIgnoreCase(searchType, 'FuelEfficiency')) {
            tickValues = data.map(result => result.OverallFuelEfficiency);
        } else if (StringUtil.equalsIgnoreCase(searchType, 'FuelUsed')) {
            tickValues = data.map(result => result.TotalFuelUsed);
        }

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        // Y-Axis Title
        let yLabel = "Fuel Cost (RM)";
        let tooltipPrefix = "RM ";
        let tooltipSuffix = "";
        if (StringUtil.equalsIgnoreCase(searchType, 'FuelUsed')) {
            yLabel = "Fuel Used (L)";
            tooltipPrefix = "";
            tooltipSuffix = " L";
        } else if (StringUtil.equalsIgnoreCase(searchType, 'FuelEfficiency')) {
            yLabel = "Fuel Efficiency (km/L)";
            tooltipPrefix = "";
            tooltipSuffix = " km/L";
        }

        // Construct Chart
        const options = {
            priColor: Colors.RGBA_HL2_ROYALE_BLUE, //Colors.RGBA_HL1_PINK
            secColor: Colors.RGBA_WHITE, //Colors.RGBA_HL1_PINK
            areaColor: Colors.RGBA_HL3_SKY_BLUE,
            xLabel: null, //X-Axis Title
            yLabel, //Y-Axis Title
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values,
            tooltipCb: { //Tooltip Custom
                title: function (tooltipItem, cdata) {
                    // Format text
                    return cdata.labels[tooltipItem[0].index][0] + ' ' + cdata.labels[tooltipItem[0].index][1];
                },
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    return tooltipPrefix + tooltipItem.value + tooltipSuffix;
                }
            }
        };
        this.dashCharts.MonthlyFuelPerformance.chart = this.genGradientLineChart(this.dashCharts.MonthlyFuelPerformance, options);
        // this.dashCharts.MonthlyFuelPerformance.chart = this.genPlainFloatingLineChart(this.dashCharts.MonthlyFuelPerformance, options);
    }

    // ChartJS: Doughnut Chart for Weekly Violation Stats
    async generateWeeklyViolationStatsDoughnutChart(searchType, data) {
        // Clear Previous Chart Data
        this.dashCharts.WeeklyViolationCount.destroy();

        if (!ObjectUtil.isObject(data) || ObjectUtil.isEmpty(data)) {
            return;
        }

        // Prepare Chart Values
        // const tickLabels = ['Harsh Brake', 'Speeding', 'Harsh Acceleration', 'Drive Time', 'Harsh Turn', 'Mileage'];
        const tickLabels = [];
        const tickValues = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                tickLabels.push(key);
                tickValues.push(data[key]);
            }
        }

        // Construct Chart
        const options = {
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values
            tooltipCb: { //Tooltip Custom
                // title: function (tooltipItem, cdata) {
                //     // console.log(tooltipItem, cdata);
                //     return ' ' + cdata.labels[tooltipItem[0].index];
                // },
                label: function (tooltipItem, cdata) {
                    // console.log(tooltipItem, cdata);
                    return ' ' + cdata.labels[tooltipItem.index] + ': ' + cdata.datasets[0].data[tooltipItem.index];
                }
            },
        };
        this.dashCharts.WeeklyViolationCount.chart = this.genColorfulDoughnutChart(this.dashCharts.WeeklyViolationCount, options);
    }

    /* ---- popup & sorting columns inside popup ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    sort(sortField: string = "", sortSession: string = ""): void {
        sortField = sortField.trim();
        sortSession = sortSession.trim();
        if (sortField == "" || sortSession == "") {
            return;
        }

        //toggle ascending/descending
        if (StringUtil.equalsIgnoreCase(sortField, this.sortSessions[sortSession].currentSortField)) {
            this.sortSessions[sortSession].currentSortAscending = !this.sortSessions[sortSession].currentSortAscending;
        } else {
            this.sortSessions[sortSession].currentSortAscending = true;
        }
        this.sortSessions[sortSession].currentSortField = StringUtil.headToLowerCase(sortField);

        sortField = this.sortSessions[sortSession].currentSortField;
        if (StringUtil.equalsIgnoreCase(sortSession, 'maintenance')) {
            //do sorting
            if (this.sortSessions[sortSession].currentSortAscending) {
                this.upcomingMaintenanceList.sort(function (m1, m2) {
                    return StringUtil.sortAscending(m1, m2, sortField);
                });
            } else {
                this.upcomingMaintenanceList.sort(function (m1, m2) {
                    return StringUtil.sortDescending(m1, m2, sortField);
                });
            }
        } else if (StringUtil.equalsIgnoreCase(sortSession, 'license')) {
            //do sorting
            if (this.sortSessions[sortSession].currentSortAscending) {
                this.expiringLicenseList.sort(function (m1, m2) {
                    if (StringUtil.equalsIgnoreCase(sortField, 'expiryDate')) {
                        return DateTimeUtil.sortAscending(m1, m2, sortField);
                    } else {
                        return StringUtil.sortAscending(m1, m2, sortField);
                    }
                });
            } else {
                this.expiringLicenseList.sort(function (m1, m2) {
                    if (StringUtil.equalsIgnoreCase(sortField, 'expiryDate')) {
                        return DateTimeUtil.sortDescending(m1, m2, sortField);
                    } else {
                        return StringUtil.sortDescending(m1, m2, sortField);
                    }
                });
            }
        } else if (StringUtil.equalsIgnoreCase(sortSession, 'vehicleHealth')) {
            //do sorting
            if (this.sortSessions[sortSession].currentSortAscending) {
                this.vehicleHealthAlerts.sort(function (m1, m2) {
                    return StringUtil.sortAscending(m1, m2, sortField);
                });
            } else {
                this.vehicleHealthAlerts.sort(function (m1, m2) {
                    return StringUtil.sortDescending(m1, m2, sortField);
                });
            }
        } else if (StringUtil.equalsIgnoreCase(sortSession, 'movingVehicle')) {
            //do sorting
            if (this.sortSessions[sortSession].currentSortAscending) {
                this.movingVehicleList.sort(function (m1, m2) {
                    return StringUtil.sortAscending(m1, m2, sortField);
                });
            } else {
                this.movingVehicleList.sort(function (m1, m2) {
                    return StringUtil.sortDescending(m1, m2, sortField);
                });
            }
        } else if (StringUtil.equalsIgnoreCase(sortSession, 'idlingVehicle')) {
            //do sorting
            if (this.sortSessions[sortSession].currentSortAscending) {
                this.idlingVehicleList.sort(function (m1, m2) {
                    return StringUtil.sortAscending(m1, m2, sortField);
                });
            } else {
                this.idlingVehicleList.sort(function (m1, m2) {
                    return StringUtil.sortDescending(m1, m2, sortField);
                });
            }
        } else if (StringUtil.equalsIgnoreCase(sortSession, 'stoppedVehicle')) {
            //do sorting
            if (this.sortSessions[sortSession].currentSortAscending) {
                this.stoppedVehicleList.sort(function (m1, m2) {
                    return StringUtil.sortAscending(m1, m2, sortField);
                });
            } else {
                this.stoppedVehicleList.sort(function (m1, m2) {
                    return StringUtil.sortDescending(m1, m2, sortField);
                });
            }
        } else if (StringUtil.equalsIgnoreCase(sortSession, 'disconnectedVehicle')) {
            //do sorting
            if (this.sortSessions[sortSession].currentSortAscending) {
                this.disconnectedVehicleList.sort(function (m1, m2) {
                    return StringUtil.sortAscending(m1, m2, sortField);
                });
            } else {
                this.disconnectedVehicleList.sort(function (m1, m2) {
                    return StringUtil.sortDescending(m1, m2, sortField);
                });
            }
        }
    }

    getSortingState(sortField: string = "", sortSession: string = ""): string {
        let sortingClass = "";
        sortField = sortField.trim();
        sortSession = sortSession.trim();
        if (sortField == "" || sortSession == "") {
            return;
        } else if (StringUtil.equalsIgnoreCase(sortField, this.sortSessions[sortSession].currentSortField)) {
            sortingClass = this.sortSessions[sortSession].currentSortAscending ? '--ascending' : '--descending';
        }
        return sortingClass;
    }

    /* ---- navigations ---- */

    // Navigate: Maintenance View page
    navigateToViewMaintenanceByVehicleName(vehicleName, isBlock) {
        if (isBlock) {
            return
        } else {
            this.routerProxyService.navigate(['/create-manage/maintenance/view'], {
                queryParams: {
                    autoSearch: true,
                    tab: 'vehicles',
                    vehicleName: vehicleName
                }
            });
        }
    }

    // Navigate: Driver/Vehicle Edit page
    navigateToResource(licenseObject: any, isBlock) {
        if (isBlock) {
            return
        } else {
            //check if driver or vehicle
            if (licenseObject.driverId) {
                this.routerProxyService.navigate(['/create-manage/drivers/edit', licenseObject.driverId]);
            } else if (licenseObject.vehicleId) {
                this.routerProxyService.navigate(['/create-manage/vehicles/edit', licenseObject.vehicleId]);
            }
        }
    }

    // Navigate: Vehicle Panel page
    navigateToVehicle(vehicleObject: any, isBlock) {
        if (isBlock) {
            return
        } else {
            this.routerProxyService.navigateByUrl('/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/' + vehicleObject.imei + ')');
        }
    }

    // Navigate: View Reuel Entry page
    navigateToViewRefuelEntryByVehicleName(vehicleName, isBlock) {
        if (isBlock) {
            return
        } else {
            this.routerProxyService.navigate(["/create-manage/fuel/view"], {
                queryParams: {
                    autoSearch: true,
                    tab: 'refuel',
                    vehicleName: vehicleName
                }
            });
        }
    }

    navigateToViewRefuelEntry(isBlock) {
        if (isBlock) {
            return
        } else {
            this.routerProxyService.navigate(["/create-manage/fuel/view"]);
        }
    }

    // Navigate: Utilization Report page
    navigateToUtilizationReportByVehicleId(vehicleIds, sortColumnName, isBlock) {
        if (isBlock) {
            return
        } else {
            this.routerProxyService.navigate(['/reports/utilization'], {
                queryParams: {
                    autoSearch: true,
                    vehicleIds: vehicleIds,
                    autoSortColumn: sortColumnName
                }
            });
        }
    }

    // Navigate: Violation Report page
    navigateToViolationReportByVehicleId(vehicleIds, sortColumnName, isBlock) {
        if (isBlock) {
            return
        } else {
            this.routerProxyService.navigate(['/reports/trip-violation'], {
                queryParams: {
                    autoSearch: true,
                    vehicleIds: vehicleIds,
                    autoSortColumn: sortColumnName
                }
            });
        }
    }

    // Navigate: Fuel Report page
    navigateToFuelReportByVehicleId(vehicleIds, sortColumnName, isBlock) {
        if (isBlock) {
            return
        } else {
            this.routerProxyService.navigate(['/reports/fuel-report'], {
                queryParams: {
                    autoSearch: true,
                    vehicleIds: vehicleIds,
                    autoSortColumn: sortColumnName
                }
            });
        }
    }

    /* ---- PDF Downloads ---- */

    // PDF: Main Download Engine
    async downloadPdf() {
        try {
            this.pageSpinner.show();
            this.isDownloadPdfClick = true;

            // 1. Prepare temporary changes for PDF rendering
            const circleCanvasList: HTMLCanvasElement[] = [];
            const circleBar: any = document.getElementsByTagName('circle-progress');
            for (let j = 0; j < circleBar.length; j++) {

                // Convert Circle Bar to readable Image (SVG to normal image)
                const svg = new Blob([circleBar[j].children[0].outerHTML], { type: "image/svg+xml;charset=utf-8" });
                const url = window.URL.createObjectURL(svg);

                const img = new Image();
                img.src = url;
                img.width = 500;
                img.height = 500;

                const circleCanvas = <HTMLCanvasElement>document.getElementById('circleCanvas' + (j + 1));
                circleCanvasList.push(circleCanvas);
                circleCanvas.width = 130;
                circleCanvas.height = 130;
                const context = circleCanvas.getContext("2d");
                img.onload = function () {
                    context.drawImage(img, 0, 0, 130, 130);
                };
            } // create canvas for circle charts
            await AsyncUtil.wait(1000); // Wait for canvas rendering to complete
            this.convertDocumentSvgsToPngs(); // convert SVG to PNG
            this.dashCharts.resizeForPdfDownload(); // resize charts to support desktop & mobile

            // 2. Start Main Rendering
            const canvas = document.createElement("canvas");
            const content = document.getElementById("dashboard");
            const scale = DomUtil.isMobile(this.platform) ? 1 : 2; // Reduce load on mobile
            const pdfPaperWidth = 1400; // old: 1200
            canvas.width = pdfPaperWidth * scale;
            // const pdfPaperHeight = 2100; // no height = get all contents
            // canvas.height = pdfPaperHeight * scale;
            canvas.getContext("2d").scale(scale, scale); // make image clearer / HD

            // 3. Generate PDF
            const opts = {
                // allowTaint: true, //allow cross-origin images
                tainttest: true, // perform test before render image
                // foreignObjectRendering: true, // ForeignObject rendering
                // useCORS: true,
                scale: scale,
                canvas: canvas,
                logging: true,
                width: pdfPaperWidth,
                // height: pdfPaperHeight,
                windowWidth: pdfPaperWidth,
                // windowHeight: pdfPaperHeight,
            };
            const htmlCanvas = await html2canvas(content, opts); // Convert HTML to Canvas
            const pdfTimeStamp = moment(this.todayDate).format("YYYYMMDD") + '-' + moment().format('HHmmss');
            const pdf = new jsPDF('p', 'pt', [htmlCanvas.width, htmlCanvas.height]);
            const imgData = htmlCanvas.toDataURL("image/jpeg", 1);
            pdf.addImage(imgData, 0, 0, htmlCanvas.width, htmlCanvas.height);
            // pdf.addImage(imgData, 'PNG', 20, 30, 0, 130);

            // 4. Reverting temporary changes
            this.isDownloadPdfClick = false;
            this.dashCharts.restoreDefaultSizes(); // revert chart sizes
            this.convertDocumentPngsToSvgs(); // revert PNG to SVG
            circleCanvasList.forEach(result => {
                // Set Canvas small size to hide
                result.height = 0;
                result.width = 0;
                if (StringUtil.equalsIgnoreCase(result.id, "circleCanvas1")) {
                    const childNodes = document.getElementById("vehicleScore").childNodes;
                    const description = childNodes[childNodes.length - 1].cloneNode(true);
                    document.getElementById("vehicleScore").childNodes[2].remove();
                    document.getElementById('vehicleScore').appendChild(result);
                    document.getElementById('vehicleScore').appendChild(description);
                } else if (StringUtil.equalsIgnoreCase(result.id, "circleCanvas2")) {
                    const childNodes = document.getElementById("driverScore").childNodes;
                    const description = childNodes[childNodes.length - 1].cloneNode(true);
                    document.getElementById("driverScore").childNodes[2].remove();
                    document.getElementById('driverScore').appendChild(result);
                    document.getElementById('driverScore').appendChild(description);
                }
                // NOTE****: do not remove circle canvas else it will failed to download on second time

            });

            // 5. Download to user
            const fileName = PDF_FILE_NAME_PREFIX + "_" + pdfTimeStamp + ".pdf";
            if (DomUtil.isMobileApp(this.platform)) {
                const pdfOutput = pdf.output();
                // using ArrayBuffer will allow you to put image inside PDF
                const buffer = new ArrayBuffer(pdfOutput.length);
                const array = new Uint8Array(buffer);
                for (let i = 0; i < pdfOutput.length; i++) {
                    array[i] = pdfOutput.charCodeAt(i);
                }

                if (DomUtil.isMobileApp(this.platform, 'ios')) {
                    const directory = this.file.documentsDirectory;
                    const fullPath = directory + fileName;
                    //Writing File to Device for iOS
                    this.file.writeFile(directory, fileName, buffer)
                        .then((success) => this.fileOpener.open(fullPath, 'application/pdf'))
                        .catch((error) => {
                            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.FILE_DOWNLOAD_FAILED);
                            this.snackBar.openGenericSnackBar(msg);
                        });
                } else if (DomUtil.isMobileApp(this.platform, 'android')) {
                    const directory = this.file.externalDataDirectory;
                    //Writing File to Device for android
                    this.file.writeFile(directory, fileName, buffer)
                        //.then((success)=> this.snackBar.openGenericSnackBar("File has been downloaded successfully to " + fullPath)                        )
                        .then((success) => this.fileOpener.open(directory + fileName + "", 'application/pdf'))
                        //.catch((error)=> this.snackBar.openGenericSnackBar("Cannot Create File " +JSON.stringify(error)));
                        .catch((error) => {
                            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.FILE_DOWNLOAD_FAILED);
                            this.snackBar.openGenericSnackBar(msg);
                        });
                }

            } else {
                pdf.save(fileName);
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hideAll();
        }
    }

    convertDocumentSvgsToPngs() {
        // Convert SVG to PNG for download PDF
        const smileyFaceContainers = document.getElementsByClassName('no-idle');
        // console.log('smileyFaceContainers', smileyFaceContainers);
        for (let i = 0; i < smileyFaceContainers.length; i++) {
            const prevSrc = smileyFaceContainers[i].children[0]['src'];
            if (prevSrc) {
                smileyFaceContainers[i].children[0]['src'] = prevSrc.replace(".svg", ".png");
            }
        }
        const dashboardIconElements: any = document.getElementsByClassName('dashboard-box__icon');
        for (let i = 0; i < dashboardIconElements.length; i++) {
            if (dashboardIconElements[i].nodeName === 'DIV') {
                dashboardIconElements[i].style.backgroundImage = dashboardIconElements[i].style.backgroundImage.replace(".svg", ".png");
            } else if (dashboardIconElements[i].nodeName === 'IMG') {
                dashboardIconElements[i].src = dashboardIconElements[i].src.replace(".svg", ".png");
            }
        }
    }

    convertDocumentPngsToSvgs() {
        // Convert PNG back to SVG
        const smileyFaceContainers = document.getElementsByClassName('no-idle');
        for (let i = 0; i < smileyFaceContainers.length; i++) {
            const prevSrc = smileyFaceContainers[i].children[0]['src'];
            if (prevSrc) {
                smileyFaceContainers[i].children[0]['src'] = prevSrc.replace(".png", ".svg");
            }
        }
        const dashboardIconElements: any = document.getElementsByClassName('dashboard-box__icon');
        for (let i = 0; i < dashboardIconElements.length; i++) {
            if (dashboardIconElements[i].nodeName === 'DIV') {
                dashboardIconElements[i].style.backgroundImage = dashboardIconElements[i].style.backgroundImage.replace(".png", ".svg");
            } else if (dashboardIconElements[i].nodeName === 'IMG') {
                dashboardIconElements[i].src = dashboardIconElements[i].src.replace(".png", ".svg");
            }
        }
    }

    /* ---- Other interactions ---- */

    selectRecommendation(index, id) {
        this.recommendations.filter((item, i) => i != index).forEach(result => {
            result.display = false;
        });
        this.recommendations[index].display = !this.recommendations[index].display;
    }

    /* ---- Obsolete Codes (to be removed) ---- */

    // (obsolete) JS: Indicator Checking for Vehicle Stats
    // private getVehicleStatsIndicatorStatus(vehicleStatsType: string = null) {
    //     // const indicator = '--indicator';
    //     const indicator = '';
    //     let alertIndicate: string = '';
    //     if (this.vehicleStats && vehicleStatsType) {
    //         const curVal = this.vehicleStats.VehicleStats[0][vehicleStatsType];
    //         const prevVal = this.vehicleStats.VehicleStats[1][vehicleStatsType];
    //         if (prevVal == curVal) {
    //             alertIndicate = '--dash';
    //         } else if (prevVal < curVal) {
    //             alertIndicate = vehicleStatsType == 'DrivingDistance' ? '--up' : '--up --alert-red';
    //         } else {
    //             // alertIndicate = '--down';
    //             alertIndicate = vehicleStatsType == 'DrivingDistance' ? '--down' : '--down --alert-green';
    //         }
    //         alertIndicate = `${indicator} ${alertIndicate}`;
    //         this.diffVehicleStats[vehicleStatsType] = Math.abs(NumberUtil.roundOff(curVal - prevVal, 2));
    //     }
    //     return alertIndicate;
    // }

    // (obsolete) JS: Indicator Checking for Violations
    // private getViolationIndicatorStatus(violationType: string, isIndicator: boolean = true) {
    //     let alertIndicate: string = '';
    //     if (this.vehicleStats && violationType) {
    //         const curVal = this.vehicleStats.ViolationCount[0][violationType];
    //         const prevVal = this.vehicleStats.ViolationCount[1][violationType];
    //         if (isIndicator) {
    //             alertIndicate += '--indicator ';
    //         }
    //         if (prevVal == curVal) {
    //             alertIndicate += '--dash';
    //         } else {
    //             alertIndicate += prevVal < curVal ? '--up --alert-red' : '--down --alert-green';
    //         }
    //     }
    //     return alertIndicate;
    // }

    // (obsolete) JS: Get Vehicle Stats Data
    // private getVehicleStatsData(typeHeader: string, typeName: string, recordNum: number) {
    //     let data: any;
    //     if (this.vehicleStats !== undefined) {
    //         if (typeHeader === 'VehicleStats') {
    //             data = this.vehicleStats.VehicleStats[recordNum][typeName];
    //         } else {
    //             data = this.vehicleStats.ViolationCount[recordNum][typeName];
    //         }
    //         return data;
    //     }
    // }

    // (obsolete) JS: Get degree or circle percentage for scoring
    // getDegreeFromScore(score: number): string {
    //     const degree = NumberUtil.formatFloat(score / 100 * 360, 2);
    //     return degree + 'deg';
    // }

    // (obsolete) HTML: Set Correct Color for Scoring Stats (HTML)
    // getColorClassFromScore(score:number): string {
    //   let colorClass = "";
    //   if (score >= 80) {
    //     colorClass = "good";
    //   } else if (score >= 50) {
    //     colorClass = "medium";
    //   } else {
    //     colorClass = "bad";
    //   }
    //   return colorClass;
    // }

}
