import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(private http: HttpClient) { }

    async getDocumentList(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/upload-documents${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async downloadDocument(documentId) {
        let param = '';
        param += documentId ? `documentId=${documentId}` : '';
        const path = environment.mtntApiEndpoint + `/upload-documents/download-document${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async deleteDocument(documentIds) {
        const body: any = {
            body: {
                documentIds: documentIds
            }
        };
        const path = environment.mtntApiEndpoint + `/upload-documents`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async uploadDocument(documentFileList) {
        const body = documentFileList;
        const path = environment.mtntApiEndpoint + `/upload-documents`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async editUploadedDocument(documentObj) {
        const path = environment.mtntApiEndpoint + `/upload-documents`;
        let body = {
            documentObj
        };
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }
}
