<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@sm --space-top">
                    <h1 class="heading --level-1">
                        Vehicles Termination
                    </h1>
                </div>
            
                <div class="grid__block w-8of12@md heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">


                            <a class="heading-buttons__button" (click)="isDeletingVehicles  = true; toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23"   class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Terminate Multiple Vehicles
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Terminate Multiple Vehicles
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/    icon-mobile-delete-driver.svg" alt="" width="60"    height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)   ="toggleMobileOpen = !toggleMobileOpen">

                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ ng-components/create-manage/icon-mobile-close.svg' :     'assets/images/ng-components/create-manage/ icon-mobile-open.svg'" alt="" width="60" height="60">

                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block w-10of12 w-6of12@md search__group">
                        <div class="field-group --unstack@xs search__select-field">
                            <div class="field-group__item search__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                <label class="select --grey">
                                    <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                        <option value="company">Company</option>
                                        <option value="vehicle">Vehicle</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__item search__type-field-group-input">
                                <div class="field">
                                    <div class="field__elems">
                                        <label class="field__holder">
                                            <input id="searchBox" type="text" class="field__input" [placeholder]="getPlaceHolderText()" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchValue()">
                                        </label>
                                        <div class="field__ui"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="button__holder">
                            <button type="button" class="button" (click)="searchValue()">SEARCH</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="table table__reset-password" (scroll)="onResetPasswordTableScroll()" [class.--scrolled-to-end]="resetPasswordTableScrolledToEnd" #resetPasswordTable>
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <ng-container *ngIf="isDeletingVehicles">
                                    <th scope="col">
                                        <label class="inline-input">
                                            <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehiclesGroups['all']" (ngModelChange)="onVehiclesAllSelectChange()">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </th>
                                </ng-container>
                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('CompanyName')" (click)="sort('CompanyName')">Company</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('vehicleName')" (click)="sort('vehicleName')">Vehicle Name</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('vehiclePlateNo')" (click)="sort('vehiclePlateNo')">Plate No</button>
                                </th>

                                <th scope="col">
                                    Brand
                                </th>

                                <th scope="col">
                                    Model
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('imeiNo')" (click)="sort('imeiNo')">Imei</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('iccid')" (click)="sort('iccid')">ICCID</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('vehicleChannel')" (click)="sort('vehicleChannel')">Channel</button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('InstallationType')" (click)="sort('InstallationType')">Installtion Type</button>
                                </th>

                                <th scope="col" class="sticky-col --third-col --fill-data">
                                    Termination Vehicle
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let vehicle of vehiclesDetailsList">
                                <ng-container *ngIf="isDeletingVehicles">
                                    <td scope="col">
                                        <label class="inline-input">
                                            <input type="checkbox" [name]="vehicle.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[vehicle.vehicleId]" (ngModelChange)="onVehiclesSelectChange($event)">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </td>
                                </ng-container>
                                <td>{{vehicle.companyName}}</td>
                                <td>{{vehicle.vehicleName}}</td>
                                <td>{{vehicle.vehiclePlateNo || '-'}}</td>
                                <td>{{vehicle.vehicleBrand || '-'}}</td>
                                <td>{{vehicle.vehicleModel || '-'}}</td>
                                <td>{{vehicle.imeiNo}}</td>
                                <td>{{vehicle.iccid}}</td>
                                <td>{{vehicle.channel}}</td>
                                <td>{{vehicle.installationType}}</td>
                                <td class="sticky-col --third-col --fill-data">
                                    <a class="link" (click)="terminateVehicle(vehicle.vehicleId); showPopup(terminateVehiclePopup);">Termination</a>
                                </td>

                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="block" *ngIf="vehiclesDetailsList.length > 0">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                        </div>
                    </div>
                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination">
                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDisplayVehiclesList(pager.currentPage - 1)">
                                <span class="angle-arrow --left">
                                </span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDisplayVehiclesList(page)">{{
                                page }}</button>

                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDisplayVehiclesList(pager.currentPage + 1)">
                                <span class="angle-arrow --right">
                                </span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #terminateVehiclePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Terminate?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to terminate current vehicle.
        </p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="vehicleTermination();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #successNotification="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Notification
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p [innerHtml]="emailSentPopupMsg"></p>

        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                OK
            </button>
        </div>
    </div>
</app-popup>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingVehicles">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedVehicles()}} vehicle(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteSupportPopup)">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteSupportPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Terminate?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to terminate
            <span class="total-count">{{countSelectedVehicles()}}</span> vehicle(s).
        </p>

        <p class="notes">
            
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="vehicleTermination();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>