<div class="region-search" [class.--trip-day-item-single-view-mode]="tripDayItemSingleViewMode">
    <div class="label-heading">
        Search
    </div>

    <div class="search-host">
        <div class="item-field">
            <div class="field">
                <div class="field__elems">
                    <div class="field__icon">
                        <div class="field__icon-inner">
                            <mat-datepicker-toggle [for]="FromDatepicker">
                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                            </mat-datepicker-toggle>
                        </div>
                    </div>
                    <input [matDatepicker]="FromDatepicker" placeholder="From Date" [max]="enddate" readonly (focus)="FromDatepicker.open()" (click)="FromDatepicker.open()" [(ngModel)]="startdate" type="text" class="field__input">
                    <div class="field__ui"></div>
                </div>
                <mat-datepicker #FromDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
            </div>
        </div>

        <div class="item-field">
            <div class="field">
                <div class="field__elems">
                    <div class="field__icon">
                        <div class="field__icon-inner">
                            <mat-datepicker-toggle [for]="ToDatepicker">
                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                            </mat-datepicker-toggle>
                        </div>
                    </div>
                    <input [matDatepicker]="ToDatepicker" placeholder="To Date" [min]="startdate" [max]="moment().startOf('day').format()" readonly (focus)="ToDatepicker.open()" (click)="ToDatepicker.open()" [(ngModel)]="enddate" type="text" class="field__input">
                    <div class="field__ui"></div>
                </div>

                <mat-datepicker #ToDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
            </div>
        </div>

        <div class="item-cta">
            <button class="button" type="button" (click)="getTripInfo()">GO</button>
        </div>

    </div>

    <div class="region-violations">
        <div class="violations-text-wrap">
            <p class="violations-text">Violation: </p>
        </div>
        <div class="violations-dropdown">
            <label class="select --grey">
                <select [(ngModel)]="selectedViolation" class="select__input" (change)="setViolation($event.target.value)">
                    <option value=""></option>
                    <option value="speeding">Overspeeding</option>
                    <option value="harsh-acceleration">Harsh Acceleration</option>
                    <option value="harsh-braking">Harsh Braking</option>
                    <option value="harsh-turning">Harsh Turning</option>
                    <option value="time-of-the-day">Time of the Day</option>
                    <option value="idling">Idling</option>
                    <option value="geofence-exit">Geofence</option>
                    <option value="mileage">Mileage</option>
                </select>
            </label>
        </div>
    </div>
</div>

<div class="region-tripslist" [class.--trip-day-item-single-view-mode]="tripDayItemSingleViewMode">
    <app-spinner #panel_spinner [disableScroll]="true" [keepParentUntouched]="true"></app-spinner>
    <div class="tripscontainer" (scroll)="onTripslistScroll(tripslist)" #tripslist>
        <ng-container *ngFor="let item of currtrips; let i = index;">
            <tripday-item [singleViewMode]="tripDayItemSingleViewMode" [tripday]="item" [tagsList]="tagsList" [loadHighlightedTrip]="tripDayItemOnInitLoadHighlightedTrip" [showViolatedTrip]="tripDayItemOnInitLoadViolatedTrip" [hasFuelSensor]="_hasFuelSensor"></tripday-item>
            <!-- [ngStyle]="{ 'z-index': currtrips.length - i }" -->
        </ng-container>
        <div class="no-record-msg" *ngIf="currtrips === undefined || currtrips.length == 0"><span>No Records Found</span></div>
        <div class="no-more-record-msg" *ngIf="currtrips !== undefined && currtrips.length > 0 && !hasMoreRecord"><span>-End of Records-</span></div>
    </div>
</div>
