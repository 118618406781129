import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AlertTypeDataService {

    constructor(public http: HttpClient) {
        // console.debug('Alert type data service connected');
    }

    getAlertTypes() {
        return this.http.get('http://stg-web-lb-66482329.ap-southeast-1.elb.amazonaws.com/mtnt/v1/alert-types')
            .pipe(map(res => res));
    }
}
