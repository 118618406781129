<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="section">
    <div class="wrapper">
        <app-breadcrumb></app-breadcrumb>
        <div class="reports__filters" style="position: relative; z-index: 3;">
            <div class="grid --gutter   reports__filters-grid">
                <div class="grid__block   reports__filters-grid-block --type">
                    <div class="field-group --unstack@xs   reports__type-field-group">
                        <div class="field-group__item   reports__type-field-group-input" [class.--active]="vehiclesSearchDOMStates.isFocused" click-outside (clickOutside)="onOutsideClickVehicleReaction($event)">
                            <div class="field" #vehiclesField>
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                        </div>
                                    </div>

                                    <label class="field__holder">
                                        <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                            {{ vehiclesSelectedLabel }}
                                        </div>

                                        <input id="vehicleSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #vehiclesSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterVehiclesSelection($event.target.value)">
                                    </label>

                                    <div class="field__ui"></div>
                                </div>

                                <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                    <ul class="inline-input-list">
                                        <li *ngIf="!vehiclesModel.vehicles.length" class="inline-input-list__item">
                                            <label class="inline-input">
                                                No vehicles found
                                            </label>
                                        </li>
                                        <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                            <label class="inline-input">
                                                <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups['all']" (ngModelChange)="onVehiclesAllSelectChange()">

                                                <span class="inline-input__icon"></span>

                                                All vehicles
                                            </label>
                                        </li>

                                        <ng-container *ngFor="let group of vehiclesModel.vehicles; let groupIndex = index;">
                                            <ng-container *ngIf="group.type === 'INDIVIDUAL'">
                                                <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                    <label class="inline-input">
                                                        <input type="checkbox" [name]="item.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[item.vehicleId]" (ngModelChange)="onVehiclesSelectChange()">

                                                        <span class="inline-input__icon"></span>

                                                        {{ item.vehicleName }}
                                                    </label>
                                                </li>
                                            </ng-container>

                                            <ng-container *ngIf="group.type === 'GROUP'">
                                                <li class="inline-input-list__item">
                                                    <label class="inline-input">
                                                        <input type="checkbox" [name]="group.name" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups[groupIndex]" (ngModelChange)="onVehiclesGroupSelectChange(groupIndex);">

                                                        <span class="inline-input__icon"></span>

                                                        {{ group.name }}
                                                    </label>

                                                    <button class="item-group-expand" (click)="expandGroupVehicle[groupIndex] = !expandGroupVehicle[groupIndex]">

                                                        <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupVehicle[groupIndex]">

                                                    </button>

                                                    <ng-container *ngIf="expandGroupVehicle[groupIndex]">
                                                        <ul class="inline-input-list">
                                                            <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                                <label class="inline-input">
                                                                    <input type="checkbox" [name]="item.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[item.vehicleId]" (ngModelChange)="onVehiclesSelectChange(groupIndex, $event)">

                                                                    <span class="inline-input__icon"></span>

                                                                    {{ item.vehicleName }}
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </ng-container>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid__block   reports__filters-grid-block --period">
                    <div class="field-group --unstack@xs   reports__period-field-group">
                        <div class="field-group__item   reports__period-field-group-input" [class.--active]="fromDateDOMStates.isFocused">
                            <div class="field">
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <mat-datepicker-toggle [for]="FromDatepicker">
                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                            </mat-datepicker-toggle>
                                        </div>
                                    </div>

                                    <input [matDatepicker]="FromDatepicker" type="text" [(ngModel)]="fromDate" [max]="toDate" readonly (focus)="FromDatepicker.open()" (click)="FromDatepicker.open()" placeholder="From Date" class="field__input" dom-states #fromDateDOMStates="DOMStates">

                                    <div class="field__ui"></div>
                                </div>

                                <mat-datepicker #FromDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                            </div>
                        </div>

                        <div class="field-group__item   reports__period-field-group-input" [class.--active]="toDateDOMStates.isFocused">
                            <div class="field">
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <mat-datepicker-toggle [for]="ToDatepicker">
                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                            </mat-datepicker-toggle>
                                        </div>
                                    </div>

                                    <input [matDatepicker]="ToDatepicker" type="text" [(ngModel)]="toDate" [min]="fromDate" [max]="moment().startOf('day').format()" readonly (focus)="ToDatepicker.open()" (click)="ToDatepicker.open()" placeholder="To Date" class="field__input" dom-states #toDateDOMStates="DOMStates">

                                    <div class="field__ui"></div>
                                </div>

                                <mat-datepicker #ToDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid__block   reports__filters-grid-block --cta">
                    <button type="button" class="button" (click)="generateReport()">
                        Generate
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section">
    <div class="wrapper">
        <div class="block">
            <div class="grid --gutter">
                <div class="grid__block   w-3of12@xs">
                    <h2 class="reports__heading   heading --level-2 --partial-underline">
                        Results:
                    </h2>
                </div>

                <div class="grid__block   w-9of12@xs   ta-r@xs" *ngIf="isReportShown">
                    <button type="button" class="button" (click)="showDownloadPopup(downloadsPopup)">
                        <img src="assets/images/scss-components/buttons/icon-download.svg" width="24" height="18" alt="" class="button__icon"> Download
                    </button>
                </div>
            </div>
        </div>

        <div class="report-box">
            <app-spinner #reportResult_spinner></app-spinner>
            <div class="block">
                <div class="table">
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <th scope="col" width="23%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('VehicleName')" (click)="doSorting('VehicleName')">Vehicle Name</button>
                                </th>
                                <th scope="col" width="15%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('PlateNo')" (click)="doSorting('PlateNo')">Plate number</button>
                                </th>
                                <th scope="col" width="13%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('RefuelDate')" (click)="doSorting('RefuelDate')">Date/Time</button>
                                </th>
                                <th scope="col" width="11%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('Location')" (click)="doSorting('Location')">Location</button>
                                </th>
                                <th scope="col" width="9%">
                                    Previous Refuel Amount(L)
                                </th>
                                <th scope="col" width="9%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('RefuelAmount')" (click)="doSorting('RefuelAmount')">Refuel Amount(L)</button>
                                </th>
                                <th scope="col" width="9%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelType')" (click)="doSorting('FuelType')">Fuel Type</button>
                                </th>
                                <th scope="col" width="9%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('FuelCost')" (click)="doSorting('FuelCost')">Fuel Cost(RM)</button>
                                </th>
                                <th scope="col" width="13%">
                                    Previous Odometer(KM)
                                </th>
                                <th scope="col" width="13%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('Odometer')" (click)="doSorting('Odometer')">Odometer(KM)</button>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let record of resultList">
                                <td class="td-break-word td-truncate" [title]="record.vehicleName || '-'">{{record.vehicleName || '-'}}</td>
                                <td>{{record.plateNo || '-'}}</td>
                                <td>{{record.refuelDateLabel || '-'}}</td>
                                <td>{{record.location || '-'}}</td>
                                <td>{{record.prevFuelAmount || '-'}}</td>
                                <td>{{record.refuelAmountLabel || '-'}}</td>
                                <td>{{record.fuelType || '-'}}</td>
                                <td>{{record.fuelCostLabel || '-'}}</td>
                                <td>{{record.prevOdometer || '-'}}</td>
                                <td>{{record.odometer || '-'}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <h2 class="reports__record-not-found --level-1" *ngIf="!isReportShown">{{ isReportSearched ? message.getMessage(message.MESSAGE.REPORT_NO_RECORD_FOUND.value) : message.getMessage(message.MESSAGE.REPORT_PLEASE_GENERATE.value) }}</h2>
            </div>

            <div class="block" *ngIf="isReportShown">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                        </div>
                    </div>

                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination">
                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="fetchPage(pager.currentPage - 1)">
                                <span class="angle-arrow --left">
                                </span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="fetchPage(page)">{{ page }}</button>

                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="fetchPage(pager.currentPage + 1)">
                                <span class="angle-arrow --right">
                                </span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #downloadsPopup="popup">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="btnExcel()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnCsv()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnPdf()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
