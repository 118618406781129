import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/** Main Typescript File **/
enableProdMode(); // Optimizes Angular Change Detection

if (environment.production === true) {
    disableConsoleOutput(); // Block some console methods
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

function disableConsoleOutput() {
    if (!window.console) {
        (<any>window).console = {};
    }
    const methods = ["log", "debug", "warn", "info", "time", "timeEnd"];
    for (let i = 0; i < methods.length; i++) {
        console[methods[i]] = function () { };
    }
}
