import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FuelService {

    constructor(private http: HttpClient) { }

    async getVehicleRefuelEntry(startDate = undefined, endDate = undefined, pageSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined) {
        let param = '';
        param += startDate ? `startDate=${startDate}` : '';
        param += endDate ? `&endDate=${endDate}` : '';
        param += pageSize ? `&pageSize=${pageSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/fuel${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async createVehicleFuel(fuelList = []) {
        const body = {
            fuelList: fuelList
        };
        const path = environment.mtntApiEndpoint + `/fuel`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async updateVehicleFuel(fuelId, fuelType, fuelCost, fuelQuantity, location, fuelDate, odometer) {
        const body = {
            fuelId: fuelId,
            fuelType: fuelType,
            fuelCost: fuelCost,
            fuelQuantity: fuelQuantity,
            location: location,
            fuelDate: fuelDate,
            odometer: odometer
        };
        const path = environment.mtntApiEndpoint + `/fuel`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    async deleteVehicleFuel(fuelIdList) {
        const body: any = { body: { fuelId: fuelIdList } };
        const path = environment.mtntApiEndpoint + `/fuel`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async getFuelUsageReport(vehicleIds, startMonth, startYear, endMonth, endYear, pageSize, startRecord, currentSortField, currentSortAscending) {
        const path = environment.mtntApiEndpoint + "/report/fuel-usage";
        const body = {
            vehicleIds: vehicleIds,
            startMonth: startMonth,
            startYear: startYear,
            endMonth: endMonth,
            endYear: endYear,
            // month: month,
            // year: year,
            sortField: currentSortField,
            sortAscending: currentSortAscending
        };

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }
    async getOverviewFuelUsageReport(vehicleIds, startDate, endDate) {
        const path = environment.mtntApiEndpoint + "/report/fuel-usage/overview";
        const body = {
            vehicleIds: vehicleIds,
            startDate: startDate,
            endDate: endDate,
        };

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getRefuelLogsReport(vehicleIds, startDate, endDate, pageSize, startRecord, currentSortField, currentSortAscending) {
        const path = environment.mtntApiEndpoint + "/report/refuel";
        const body = {
            vehicleIds: vehicleIds,
            startDate: startDate,
            endDate: endDate,
            sortField: currentSortField,
            sortAscending: currentSortAscending
        };

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getRefuelLogsBySensorReport(vehicleIds, startDate, endDate, pageSize, startRecord, currentSortField, currentSortAscending) {
        const path = environment.mtntApiEndpoint + "/report/refuel-by-sensor";
        const body = {
            vehicleIds: vehicleIds,
            startDate: startDate,
            endDate: endDate,
            sortField: currentSortField,
            sortAscending: currentSortAscending
        };

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }
}
