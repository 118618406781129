import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CompanyRegistrationSharedService {
    private sharedCompanyObj: any = null;
    constructor() { }

    public setCompanyObj(companyObj: any) {
        this.sharedCompanyObj = companyObj;
    }

    public getCompanyObj() {
        return this.sharedCompanyObj;
    }

}
