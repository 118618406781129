import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as StringUtil from './../../util/stringUtil';

@Injectable({
    providedIn: 'root'
})
export class AccessControlService {
    permissions: Array<any> = []; // Store the actions for which this user has permission
    pagesThatRequireRefToken: Array<any> = []; // Store the URLs which require ref token by maxis support

    constructor(private http: HttpClient) { }

    async hasPermission(pageUrl: string) {
        // If user permissions already retrieved from the API
        // Otherwise, must request permissions from the API first
        if (!this.permissions.length) {
            await this.initializePermissions();
        }

        if (this.permissions.length) {
            if (this.permissions.find((permission, i) => {
                // console.debug(i + '. ' + permission);
                // return pageUrl.startsWith(permission);
                return StringUtil.equalsIgnoreCase(pageUrl, permission.url);
            })) {
                return true;
            }
        }
        return false;
    }

    async isRequiredRefToken(pageUrl: string) {
        // If user permissions already retrieved from the API
        // Otherwise, must request permissions from the API first
        if (!this.permissions.length) {
            await this.initializePermissions();
        }

        if (this.pagesThatRequireRefToken.length) {
            if (this.pagesThatRequireRefToken.find((refPermission, i) => {
                return StringUtil.equalsIgnoreCase(pageUrl, refPermission.url);
            })) {
                return true;
            }
        }
        return false;
    }

    clearPermissions(): void {
        this.permissions = [];
    }

    /**
     * Generate Page Accessibility from Page Access API
     */
    async initializePermissions() {
        try {
            // console.debug('initializePermissions');
            const pageAccess = await this.getPageAccess(null);
            if (pageAccess && pageAccess.body) {
                this.permissions = [];
                pageAccess.body.map(pa => {
                    if (pa.url) {
                        this.permissions.push({ url: pa.url, pageAccessId: pa.pId });
                    }
                    if (pa.isAccessibleByRefToken) {
                        this.pagesThatRequireRefToken.push({ url: pa.url, pageAccessId: pa.pId });
                    }
                });
            }
            // console.debug('permission:'+JSON.stringify(this.permissions));
            return true;
        } catch (err) {
            //do nothing
        }
    }

    /**
     * To get a list of page accessible by login user
     * @param isActivePage
     * @param isDisplayInMenu (optional) - If not provided, retrieve both menu and non-menu url.
     */
    async getPageAccess(isDisplayInMenu: boolean, useRefToken: boolean = false) {
        const params = {};
        if (isDisplayInMenu !== null) {
            Object.assign(params, { isDisplayInMenu: isDisplayInMenu });
        }

        const path = environment.mtntApiEndpoint + '/authorization/page-access';
        try {
            const result: any = await this.http.get(path, { params: params, headers: {_usereftoken: useRefToken.toString()} }).toPromise();
            return result;
        } catch (err) {
            // do nothing
            return null;
        }
    }

    async getPageAccessId(pageUrl) {
        let pageAccessId = null;
        if (!this.permissions.length) {
            await this.initializePermissions();
        }

        if (this.permissions.length) {
            this.permissions.find(permission => {
                if (StringUtil.equalsIgnoreCase(pageUrl, permission.url)) {
                    pageAccessId = permission.pageAccessId;
                    // pageAccessId =1;
                }
                return StringUtil.equalsIgnoreCase(pageUrl, permission.url);
            });
        }
        return pageAccessId;
    }

    async getLandingPagePathByRoleId(roleId: number) {
        const path = environment.mtntApiEndpoint + `/authorization/landing-page/${roleId}`;
        try {
            const result: any = await this.http.get(path).toPromise();
            return result;
        } catch (err) {
            // do nothing
            return null;
        }
    }

    async getPageAccessCompany(url, companyId) {
        const params = {};
        const path = environment.mtntApiEndpoint + '/authorization/page-access-company';
        const body = {
            path: url,
            companyId: companyId
        };

        try {
            const result: any = await this.http.post(path, body).toPromise();
            return result;
        } catch (err) {
            // do nothing
            return null;
        }
    }
}
