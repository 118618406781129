import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';



import * as DomUtil from './../../util/domUtil';

@Directive({
    selector: '[click-outside]'
})

export class ClickOutsideDirective {

    @Input('click-outside-excludedHtmlIds') excludedOutsideHtmlIds: string[] = [];
    @Output('clickOutside') clickOutside: EventEmitter<Object> = new EventEmitter();

    constructor(private _elRef: ElementRef) {
    }

    // @HostListener('document:click', ['$event'])     // debug click event
    // public onClick($event) {                        // debug click event
    //     console.debug($event);                      // debug click event
    //     let targetElement = $event.target;          // debug click event

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        if (targetElement) {
            const clickedInside = DomUtil.isDescendantOrExcluded(this._elRef.nativeElement, targetElement, this.excludedOutsideHtmlIds);
            if (!clickedInside) {
                this.clickOutside.emit({
                    target: targetElement
                });
            }
        }
    }

}
