import { Component, OnInit, ViewChild } from '@angular/core';

// import { MessageBusService, BusMessage } from './../../../../_services/messagebus.service';
import { TokenService } from './../../../../_services/token/token.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PagerService } from './../../../../_services/pager/pager.service';
import { SupportService } from './../../../../_services/support/support.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';

// import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { CustomReuseStrategy } from '../../../../app-routing-custom-reuse-strategy';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { BusMessage, MessageBusService } from './../../../../_services/messagebus.service';
import { AccessControlService } from './../../../../_services/access-control/access-control.service';
import { AuthService } from './../../../../_services/auth/auth.service';
import { USER_ROLE } from './../../../../../app/constants/userRole';

@Component({
    selector: 'app-customer-view',
    templateUrl: './customer-view.component.html',
    styleUrls: ['./customer-view.component.scss']
})
export class CustomerViewComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    toggleMobileOpen: boolean = false;
    isDeletingRefuelEntry: boolean;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private pagerService: PagerService,
        private supportService: SupportService,
        private snackBar: SnackBarService,
        private tokenService: TokenService,
        private routerProxyService: RouterProxyService,
        private popupService: PopupService,
        private popupCampaignService: PopupCampaignService,
        private msgbus: MessageBusService,
        private accessControlService: AccessControlService,
        private authService: AuthService

    ) { }

    //Pagination
    pager: any = {}; //Pager object
    pageRecordSize: number = 10;

    searchBy = 'company';
    sortField = 'CompanyName';
    sortAscending: boolean = true;
    filterKey = 'companyName';
    filterValue = '';
    currentFilterValue = '';
    oriStatus: String = '';
    companyId: Number;

    companyOriDetails: any = {};
    companyDetailsList: any = [];

    async ngOnInit() {
        const token = await this.tokenService.removeAdministratorUser(); // remove administrator token
        if (token) {
            await this.authService.removeAdminitratorLogin(token);
        }
        await this.getDisplayCompanyList();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async getDisplayCompanyList(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Get Company Info Details Page
            const response: any = await this.supportService.getCompanyList(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            if (response) {
                this.companyOriDetails = response.body;
                this.companyDetailsList = response.body.result;
                this.pager = this.pagerService.getPager(this.companyOriDetails.totalRecord, page, this.pageRecordSize);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async searchValue() {
        this.filterKey = this.searchBy === 'company' ? 'CompanyName' : 'UserName';
        this.currentFilterValue = this.filterValue;
        await this.getDisplayCompanyList();
    }

    getSortingStatus(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }
        await this.getDisplayCompanyList();
    }

    getPlaceHolderText() {
        const defaultText = 'Search By';
        return this.searchBy === 'company' ? `${defaultText} Company` : `${defaultText} Username`;
    }

    async manageAccount(companyId, companyName) {
        const result = await this.supportService.administatorLogin(companyId);
        if (this.tokenService.isAdministratorUserTokenExist()) {
            // if (result.statusCode === ResponseStatusCode.SUCCESS.code) {
            // this.msgbus.sendMessage(new BusMessage("support", "companyName", { companyName: companyName }));

            //turn on reuse route path
            CustomReuseStrategy.turnOnReuseRoutePath();

            const currentUser = this.authService.getCurrentUserDetails();
            if (currentUser && currentUser.role === USER_ROLE.SUPER_SUPER_ADMIN.value) {
                //only do these action when is super super admin manage account
                //reload side menu
                this.msgbus.sendMessage(new BusMessage("menu", "reload", USER_ROLE.SUPER_SUPER_ADMIN.value));
                //reinitialize permission
                await this.accessControlService.initializePermissions();
            } else {
                this.msgbus.sendMessage(new BusMessage("menu", "reload", {useRefToken: true}));
            }

            //Navigate to Map of Selected Company
            this.routerProxyService.navigateToMapPage();
        }
    }

    async suspendUnsuspendAccount() {
        let status: String;
        //only 2 status (ACTIVE, SUSPEND)
        if (this.oriStatus === "ACTIVE") {
            status = "SUSPEND";
        } else {
            status = "ACTIVE";
        }
        const result = await this.supportService.suspendUnsuspendAccount(this.companyId, status);
        // if (result.body && result.body.success === true) {
        //     this.companyDetailsList.forEach(element => {
        //         if (element.companyId === this.companyId) {
        //             element.status = status;
        //             element.suspendLabel = this.oriStatus === "ACTIVE" ? "Unsuspend" : "Suspend";
        //         }
        //     });
        // }
        await this.getDisplayCompanyList();
    }
    async deactivateAccount() {
        // let status: String;
        // //only 2 status (ACTIVE, SUSPEND)
        // if (this.oriStatus === "ACTIVE") {
        //     status = "SUSPEND";
        // } else {
        //     status = "ACTIVE";
        // }
        const result = await this.supportService.deactivateAccount(this.companyId);
        // if (result.body && result.body.success === true) {
        //     this.companyDetailsList.forEach(element => {
        //         if (element.companyId === this.companyId) {
        //             element.status = status;
        //             element.suspendLabel = this.oriStatus === "ACTIVE" ? "Unsuspend" : "Suspend";
        //         }
        //     });
        // }
        await this.getDisplayCompanyList();
    }

    tempStoreInfoForSuspendUnsuspend(companyId: Number, oriStatus: String) {
        this.companyId = companyId;
        this.oriStatus = oriStatus;
    }

    tempStoreInfoForDeactivation(companyId: Number) {
        this.companyId = companyId;
    }

    /* ---- popup table ---- */
    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
