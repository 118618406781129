<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
  <div class="section   bgc-dark-white">
    <div class="wrapper">
      <app-breadcrumb></app-breadcrumb>

      <div class="grid --gutter --flex-bottom">
        <div class="grid__block --space-top">
          <h1 class="heading --level-1">
            Create Incident Analysis
          </h1>
        </div>
      </div>
    </div>
  </div>

  <form class="form-field" #incidentAnalysisForm="ngForm">
    <div class="section">
      <div class="wrapper">
        <div class="compulsory-notes">
          *Compulsory fields
        </div>

        <div class="grid --gutter">
          <div class="grid__block w-9of12@md form__flex">
            <h2 class="heading indicator-green --level-2 --partial-underline">
              Report Info
            </h2>

            <div class="field-group --unstack@xs --space-top">
              <div class="field-group__item">
                <div class="field-group__holder">
                  <div class="field-group__label">
                    Vehicle Plate Number:
                    <span class="indicator-green">*</span>
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field__elems">
                        <label class="select" style="width: 100%">
                          <select class="select__input" (change)="onVehicleChoose($event.target.value);"
                            name="vehicleId" #vehicleId="ngModel" [(ngModel)]="currentIncidentReportObj.vehicleId"
                            required>
                            <option value="" selected disabled hidden>Please Select</option>
                            <option *ngFor="let item of vehicleInfoList" [value]="item.vehicleId">
                              {{item.vehiclePlateNo}}</option>
                          </select>
                        </label>
                      </div>
                    </div>

                    <div *ngIf="vehicleId.errors && (vehicleId.dirty || vehicleId.touched)">
                      <div class="field-error" *ngIf="vehicleId.errors?.required">
                        {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Vehicle Plate Number')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-group__holder">
                  <div class="field-group__label">
                    Vehicle Name:
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field__elems">
                        <label class="field__holder">
                          <input type="text" class="field__input" name="vehicleName"
                            [(ngModel)]="currentIncidentReportObj.vehicleName" disabled>
                        </label>
                        <div class="field__ui"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-group__holder">
                  <div class="field-group__label">
                    Driver Name:
                    <span class="indicator-green">*</span>
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field__elems">
                        <label class="select" style="width: 100%">
                          <select class="select__input" (change)="onDriverChoose($event.target.value);"
                            name="driverId" #driverId="ngModel" [(ngModel)]="currentIncidentReportObj.driverId"
                            required>
                            <option value="" selected disabled hidden>Please Select</option>
                            <option *ngFor="let item of driverDetailsList" [value]="item.Id">
                              {{item.Username}}</option>
                          </select>
                        </label>
                      </div>
                    </div>

                    <div *ngIf="driverId.errors && (driverId.dirty || driverId.touched)">
                      <div class="field-error" *ngIf="driverId.errors?.required">
                        {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Vehicle Plate Number')}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field-group__holder">
                  <div class="field-group__label">
                    Incident Date:
                    <span class="indicator-green">*</span>
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field__elems">
                        <div class="field__icon">
                          <div class="field__icon-inner">
                            <mat-datepicker-toggle [for]="IncidentDatepicker">
                              <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg"
                                width="24" height="20" alt="" class="field__icon-image">
                            </mat-datepicker-toggle>
                          </div>
                        </div>
                        <input [matDatepicker]="IncidentDatepicker" (focus)="IncidentDatepicker.open()"
                          (click)="IncidentDatepicker.open()" type="text" #incidentDate="ngModel"
                          [(ngModel)]="incidentDateVar" name="IncidentDatepicker" [max]="datePickerMaxValue"
                          class="field__input" readonly dom-states #IncidentDateDOMStates="DOMStates" required>
                        <div class="field__ui"></div>
                      </div>
                      <mat-datepicker #IncidentDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                    </div>
                    <div *ngIf="incidentDate.errors && (incidentDate.dirty || incidentDate.touched)">
                      <div class="field-error" *ngIf="incidentDate.errors?.required">
                        {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Incident Date')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-group__holder">
                  <div class="field-group__label">
                    Incident Start Time:
                    <span class="indicator-green">*</span>
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #incidentStartTimeHour="ngModel"
                            [(ngModel)]="incidentStartTimeHh" name="incidentStartTimeHour" required>
                            <option value="" selected disabled hidden>HH</option>
                            <option *ngFor="let hour of startTimeSelector.hour" [value]="hour">{{hour}}</option>
                          </select>
                        </label>
                      </div>

                      <div class="field-group__time-inner --no-padding">
                        :
                      </div>

                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #incidentStartTimeMin="ngModel"
                            [(ngModel)]="incidentStartTimeMm" name="incidentStartTimeMin" required>
                            <option value="" selected disabled hidden>MM</option>
                            <option *ngFor="let minute of startTimeSelector.minute" [value]="minute">{{minute}}</option>
                          </select>
                        </label>
                      </div>

                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #incidentStartTimeAmPm="ngModel"
                            [(ngModel)]="incidentStartTimeA" name="incidentStartTimeAmPm" required>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </label>
                      </div>
                    </div>

                    <div
                      *ngIf="(incidentStartTimeHour.errors && (incidentStartTimeHour.dirty || incidentStartTimeHour.touched)) || (incidentStartTimeMin.errors && (incidentStartTimeMin.dirty || incidentStartTimeMin.touched)) || (incidentStartTimeAmPm.errors && (incidentStartTimeAmPm.dirty || incidentStartTimeAmPm.touched))">
                      <div class="field-error">
                        {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Incident Start Time')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-group__holder">
                  <div class="field-group__label">
                    Incident End Time:
                    <span class="indicator-green">*</span>
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #incidentEndTimeHour="ngModel" [(ngModel)]="incidentEndTimeHh"
                            name="incidentEndTimeHour" required>
                            <option value="" selected disabled hidden>HH</option>
                            <option *ngFor="let hour of endTimeSelector.hour" [value]="hour">{{hour}}</option>
                          </select>
                        </label>
                      </div>

                      <div class="field-group__time-inner --no-padding">
                        :
                      </div>

                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #incidentEndTimeMin="ngModel" [(ngModel)]="incidentEndTimeMm"
                            name="incidentEndTimeMin" required>
                            <option value="" selected disabled hidden>MM</option>
                            <option *ngFor="let minute of endTimeSelector.minute" [value]="minute">{{minute}}</option>
                          </select>
                        </label>
                      </div>

                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #incidentEndTimeAmPm="ngModel" [(ngModel)]="incidentEndTimeA"
                            name="incidentEndTimeAmPm" required>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </label>
                      </div>
                    </div>

                    <div
                      *ngIf="(incidentEndTimeHour.errors && (incidentEndTimeHour.dirty || incidentEndTimeHour.touched)) || (incidentEndTimeMin.errors && (incidentEndTimeMin.dirty || incidentEndTimeMin.touched)) || (incidentEndTimeAmPm.errors && (incidentEndTimeAmPm.dirty || incidentEndTimeAmPm.touched))">
                      <div class="field-error">
                        {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Incident End Time')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-group__holder">
                  <div class="field-group__label">
                    Impact Date:
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field__elems">
                        <div class="field__icon">
                          <div class="field__icon-inner">
                            <mat-datepicker-toggle [for]="ImpactDatepicker">
                              <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg"
                                width="24" height="20" alt="" class="field__icon-image">
                            </mat-datepicker-toggle>
                          </div>
                        </div>
                        <input [matDatepicker]="ImpactDatepicker" (focus)="ImpactDatepicker.open()"
                          (click)="ImpactDatepicker.open()" type="text" #impactDate="ngModel"
                          [(ngModel)]="impactDateVar" name="ImpactDatepicker" [max]="datePickerMaxValue"
                          class="field__input" readonly dom-states #ImpactDateDOMStates="DOMStates">
                        <div class="field__ui"></div>
                      </div>
                      <mat-datepicker #ImpactDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                    </div>
                    <div *ngIf="impactDate.errors && (impactDate.dirty || impactDate.touched)">
                      <div class="field-error" *ngIf="impactDate.errors?.required">
                        {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Impact Date')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-group__holder">
                  <div class="field-group__label">
                    Impact Time:
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #impactTimeHour="ngModel"
                            [(ngModel)]="impactTimeHh" name="impactTimeHour" [required]="impactDateVar!==null" [disabled]="impactDateVar === null">
                            <option value="" selected disabled hidden>HH</option>
                            <option *ngFor="let hour of impactTimeSelector.hour" [value]="hour">{{hour}}</option>
                          </select>
                        </label>
                      </div>

                      <div class="field-group__time-inner --no-padding">
                        :
                      </div>

                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #impactTimeMin="ngModel"
                            [(ngModel)]="impactTimeMm" name="impactTimeMin" [required]="impactDateVar!==null" [disabled]="impactDateVar === null">
                            <option value="" selected disabled hidden>MM</option>
                            <option *ngFor="let minute of impactTimeSelector.minute" [value]="minute">{{minute}}</option>
                          </select>
                        </label>
                      </div>

                      <div class="field-group__time-inner">
                        <label class="select select-time">
                          <select class="select__input" #impactTimeAmPm="ngModel"
                            [(ngModel)]="impactTimeA" name="impactTimeAmPm" [required]="impactDateVar!==null"  [disabled]="impactDateVar === null">
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </label>
                      </div>
                    </div>

                    <div
                      *ngIf="(impactTimeHour.errors && (impactTimeHour.dirty || impactTimeHour.touched)) || (impactTimeMin.errors && (impactTimeMin.dirty || impactTimeMin.touched)) || (impactTimeAmPm.errors && (impactTimeAmPm.dirty || impactTimeAmPm.touched))">
                      <div class="field-error">
                        {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Impact Time')}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field-group__holder">
                  <div class="field-group__label">
                    Description:
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field__elems">
                        <label class="field__holder">
                          <input type="text" class="field__input" name="description" #description="ngModel"
                            [(ngModel)]="currentIncidentReportObj.description" maxlength="255">
                        </label>
                        <div class="field__ui"></div>
                      </div>
                    </div>
                    <div *ngIf="description.errors && (description.dirty || description.touched)">
                      <div class="field-error" *ngIf="description.errors?.maxlength">
                        {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Description', '255')}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field-group__holder">
                  <div class="field-group__label">
                    Remarks:
                  </div>

                  <div class="field-group__input">
                    <div class="field">
                      <div class="field__elems">
                        <label class="field__holder">
                          <input type="text" class="field__input" name="remarks" #remarks="ngModel"
                            [(ngModel)]="currentIncidentReportObj.remarks" maxlength="255">
                        </label>
                        <div class="field__ui"></div>
                      </div>
                    </div>
                    <div *ngIf="remarks.errors && (remarks.dirty || remarks.touched)">
                      <div class="field-error" *ngIf="remarks.errors?.maxlength">
                        {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Remarks', '255')}}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="wrapper">
        <div class="grid --gutter">
          <div class="grid__block">
            <div class="inline-button__field">
              <button class="button inline-button__inputs" type="button" [disabled]="incidentAnalysisForm.invalid"
                (click)="showPopup(createConfirmPopup);">
                CREATE
              </button>

              <button class="button inline-button__inputs --grey" type="button" (click)="navigateToIncidentAnalysisView()">
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<app-popup #createConfirmPopup="popup" class="cm-popups">
  <div class="popup__block">
    <div class="cm-heading__main">
      <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43"
        class="cm-heading__icon">

      <div class="cm-heading__text">
        Confirm Create?
      </div>
    </div>
  </div>

  <div class="popup__block">
    <div class="inline-button__field">
      <button class="button inline-button__inputs" type="button" (click)="createIncidentAnalysisReport();hidePopup();">
        CONFIRM
      </button>

      <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
        CANCEL
      </button>
    </div>
  </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>