<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

        </div>
    </div>

    <div class="section   bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="overview()">Overview</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="fuelUsage()">Fuel Usage</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 3" (click)="refuelEntry()">Refuel Log<br> (By Import)</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 4" (click)="refuelSensor()">Refuel Log<br> (By Sensor)</button>
            </div>
        </div>
    </div>
    <div class="tabs__content" *ngIf="currentTab == 1">
        <app-overview></app-overview>
    </div>
    <div class="tabs__content" *ngIf="currentTab == 2">
        <app-fuel-usage-report [vehicleIds]="vehicleIds" [autoSortColumn]="autoSortColumn"></app-fuel-usage-report>
    </div>
    <div class="tabs__content" *ngIf="currentTab == 3">
        <app-refuel-logs-report></app-refuel-logs-report>
    </div>
    <div class="tabs__content" *ngIf="currentTab == 4">
        <app-refuel-logs-sensor></app-refuel-logs-sensor>
    </div>
    <popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
    </popup-campaign>
</div>
