import { environment } from '../../../../environments/environment';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as DomUtil from '../../../util/domUtil';
import { RouterProxyService } from '../../../_services/router-proxy/router-proxy.service';

@Component({
    template: '<p>Redirecting you based on your device platform...</p>'
})
export class RedirectAppDownloadComponent {

    constructor(
        private platform: Platform,
        private routerProxyService: RouterProxyService
    ) {
        if (DomUtil.isMobile(this.platform, 'android')) {
            this.openInPlayStore();
        } else if (DomUtil.isMobile(this.platform, 'ios')) {
            this.openInAppleStore();
        } else {
            this.routerProxyService.redirectExternalUrl('https://mdrive.my');
        }
    }

    openInPlayStore() {
        const actualLink = environment.appConfig.appDownload.android;
        const packageLink = actualLink.replace(/http(s)?:\/\/play\.google\.com\/store\/apps\//, ''); // details?id=my.com.maxis.mdrive

        this.routerProxyService.redirectExternalUrl(
            // 1. Use "market://"
            `market://${packageLink}`,

            // 2. Google Play App shorthand
            `https://play.app.goo.gl/?link=https://play.google.com/store/apps/${packageLink}`,

            // 3. Normal URL
            actualLink
        );
    }

    openInAppleStore() {
        const actualLink = environment.appConfig.appDownload.ios;

        this.routerProxyService.redirectExternalUrl(
            // 1. Use "itms-appss://"
            actualLink.replace(/http(s)?:\/\//, 'itms-appss://'),

            // 2. Normal URL
            actualLink
        );
    }

}
