import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import * as moment from 'moment';
import { modules } from '../../../../constants/module-access.constant';

// Component
import { SpinnerComponent } from '../../../common/spinner/spinner.component';

// Service
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { PagerService } from '../../../../_services/pager/pager.service';
import { PopupService } from '../../../common/popup/popup.service';
import { RouterProxyService } from '../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';
import { RouteService } from '../../../../_services/route/route.service';
import { ModulePermissionService } from '../../../../_services/access-control/module-permission.service';

// Util
import * as DateTimeUtil from '../../../../util/dateTimeUtil';

// Constant
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from '../../../../constants/responseStatusCode';

@Component({
    selector: 'app-view-routes',
    templateUrl: './view-routes.component.html',
    styleUrls: ['./view-routes.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ViewRoutesComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner", { static: true }) pageSpinner: SpinnerComponent;

    color = 'primary';
    disabled = false;

    currentTab: number = 1;
    deletingDrivers: boolean = false;

    routeList: any = [];
    sortField = 'Name';
    sortAscending = true;
    filterKey = 'Name';
    filterValue = '';
    currentFilterValue = '';

    pager: any = {};
    pageRecordSize = environment.appConfig.createManage.pageRecordSize;

    /* ----- Route ----- */
    // Route Selected
    isDeletingRoute: boolean;
    selectedRoute: any = {};
    selectedRouteGroups: any = {};
    /* ----- Route ----- */

    message = Message;
    moment = moment;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;

    constructor(
        private pagerService: PagerService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService,
        private routeService: RouteService

    ) { }

    async ngOnInit() {
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_ROUTE.value);
        this.creatable = permissions[modules.CRUD_ROUTE.value].cAccess;
        this.editable = permissions[modules.CRUD_ROUTE.value].eAccess;
        this.deletable = permissions[modules.CRUD_ROUTE.value].dAccess;
        await this.getRoute();
        await this.checkUnviewedCampaign();
    }

    async routes(filterValue = '') {
        this.currentTab = 1;
        this.sortField = 'Name';
        this.sortAscending = true;
        this.filterValue = filterValue || ''; // Empty Text Box
        this.currentFilterValue = filterValue || '';
        await this.getRoute();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    navigateToCreateRoute() {
        this.routerProxyService.navigate(['/create-manage/routes/create-route']);
    }

    navigateToEditRoute(routeId) {
        this.routerProxyService.navigate(['/create-manage/routes/edit-route/', routeId]);
    }

    async getRoute(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            //Get Company Route
            const routeResult = await this.routeService.getRoute(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            this.routeList = routeResult.body.result;
            this.pager = this.pagerService.getPager(routeResult.body.totalRecord, page, this.pageRecordSize);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }


    async searchVehicleName() {
        this.currentFilterValue = this.filterValue;
        await this.getRoute();
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }

        await this.getRoute();
    }

    getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    onChangeSlideBar(status, slideBarName) {
        this[slideBarName] = !status;
    }

    onRouteAllSelectChange() {
        const allIsSelected = this.selectedRouteGroups.all;
        for (let i = 0; i < this.routeList.length; i++) {
            const routeModel = this.routeList[i];
            this.selectedRoute[routeModel.Id] = allIsSelected;
        }
    }

    onRouteSelectChange(routeSelected: boolean) {
        if (routeSelected) {
        } else {
            this.selectedRouteGroups['all'] = false;
        }
    }

    countSelectedRoute() {
        return Object.keys(this.selectedRoute).filter(result => this.selectedRoute[result] === true).length;
    }

    cancelResetDelete() {
        this.isDeletingRoute = false;
        this.selectedRoute = {};
        this.selectedRouteGroups['all'] = false;
    }

    async deleteRoute() {
        this.pageSpinner.show();
        try {
            const selectedRouteList = [];
            for (const key in this.selectedRoute) {
                if (this.selectedRoute[key] === true) {
                    selectedRouteList.push(parseInt(key));
                }
            }

            const result = await this.routeService.deleteRoute(selectedRouteList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Route');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Route');

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getRoute();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    formateDateLabel(dateTime) {
        const currFormat = "hh:mm:ss";
        const desireFormat = "hh:mm A";
        if (dateTime == null) {
            return '';
        } else {
            return DateTimeUtil.getDateLabel(dateTime, currFormat, desireFormat);
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

}
