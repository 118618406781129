import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { NgZone, Injectable } from "@angular/core";
import { environment } from './../../../environments/environment';
import * as LocalStorageUtil from './../../util/localStorageUtil';
import * as AsyncUtil from './../../util/asyncUtil';
import * as USER_ROLE from "../../constants/userRole";
import { MessageBusService, BusMessage } from "../messagebus.service";
import { AccessControlService } from "../access-control/access-control.service";

//Constants
const URL_LOGIN: string = environment.appConfig.page.loginUrlPath;
const URL_MAP: string = environment.appConfig.page.mapUrlPath;
const URL_ERROR: string = '/error';
const URL_SUPPORT_PANEL_HOME: string = '/support/panel';
const URL_SUPPORT_RESET_PASSWORD: string = '/support/manage/companies/reset-password';
const URL_SUPPORT_ID_MGMT: string = '/support/manage/admins';
const DEFAULT_HOME_PAGE_URL: string = environment.appConfig.page.defaultHomePageUrl;

@Injectable()
export class RouterProxyService {

    userHomePage: string = URL_LOGIN;

    /*
        CAUTION!
        DO NOT SIMPLY REFERENCE OTHER SERVICES HERE,
        CIRCULAR DEPENDENCY MIGHT HAPPEN.
     */
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private zone: NgZone,
        private msgbus: MessageBusService,
        private accessControlService: AccessControlService
    ) {

        //Init Home Page from Session
        this.initHomePageCached();
    }

    // public navigateToRoot(): void {
    //     this.navigateByUrl();
    // }

    public navigateToHomePage(): void {
        this.navigateByUrl(this.userHomePage);
    }

    public navigateToLoginPage(showSpinner: boolean = false, timeout: number = 0): void {
        this.navigateByUrl(URL_LOGIN, new SpinnerWaitOption(showSpinner, timeout));
    }

    public navigateToMapPage(): void {
        this.navigateByUrl('/' + URL_MAP + '(leftbar:vehiclelist)');
        // this.navigate([MAP_URL, { outlets: { leftbar: ['vehiclelist'] } }]);
    }

    public navigateToSupportPanelPage(): void {
        this.navigateByUrl(URL_SUPPORT_PANEL_HOME);
    }

    public navigateToErrorPage(): void {
        this.navigateByUrl(URL_ERROR);
    }

    public setUserHomePage(urlPath: string): void {
        this.userHomePage = urlPath;
        LocalStorageUtil.setSessionDetails('userHomePage', urlPath);
    }

    public getUserHomePage(): string {
        return this.userHomePage;
    }

    public initHomePageCached() {
        const userSession = LocalStorageUtil.localStorageGet('currentUser');
        if (!userSession) {
            return;
        }

        //Lookup from local storage
        const sessionHomePage = LocalStorageUtil.getSessionDetails('userHomePage');
        if (sessionHomePage) {
            this.userHomePage = sessionHomePage;
            // console.debug('RouterProxyService: Cached Homepage = ' + this.userHomePage);
        } else {
            this.initHomePage();
        }
    }

    public async initHomePage() {
        const userSession = LocalStorageUtil.localStorageGet('currentUser');
        if (!userSession) {
            return;
        }

        //Init Home Page by User Role
        this.userHomePage = DEFAULT_HOME_PAGE_URL;
        if (userSession.roleId) {
            const result = await this.accessControlService.getLandingPagePathByRoleId(userSession.roleId);
            if (result.body) {
                this.userHomePage = result.body[0].Url;
            }
        }
        this.setUserHomePage(this.userHomePage); //set to session

        // console.debug('RouterProxyService: Set Homepage = ' + this.userHomePage);
    }

    /**
     * Proxy for: Router.navigateByUrl()
     * @param url (string) path to be navigated
     */
    public async navigateByUrl(url: string = '//', options: SpinnerWaitOption = new SpinnerWaitOption()): Promise<void> {
        if (url != null && url.toString().trim().length) {

            // Remove leading forward slash for router.navigateByUrl()
            url = url.toString();
            if (url.indexOf('/') == 0) {
                url = url.substring(1, url.length);
            }

            await this.spinnerWait(options);

            // Navigate using NgZone
            this.zone.run(() => {
                this.router.navigateByUrl(url);
            });
        }
    }

    /**
     * Custom Proxy for: Router.navigate(). Respects CustomRouteReuseStrategy.
     * @param commands Refer Router.navigate() docs
     * @param extras Refer Router.navigate() docs
     */
    public navigate(commands: any[], extras?: NavigationExtras): void {

        // Navigate using NgZone
        this.zone.run(() => {
            this.router.navigate(commands, extras);
        });
    }

    /**
     * A special navigate() proxy to bypass CustomRouteReuseStrategy
     *  using Custom Router Navigation Proxy
     * @param commands Refer Router.navigate() docs
     * @param extras Refer Router.navigate() docs
     * @param tabToReOpen The tab to reopen after navigation, handled by landing page component's ngOnInit(). Append query param.
     */
    public navigateSuccess(commands: any[], extras?: NavigationExtras, tabToReOpen: string = null): void {

        // Navigate using NgZone
        commands.push({ success: true }); // append param

        // Hide these from URL address
        extras = Object.assign(extras ? extras : {}, { skipLocationChange: true });
        if (tabToReOpen) {
            Object.assign(extras ? extras : {}, {
                queryParams: {
                    tab: tabToReOpen
                },
                queryParamsHandling: 'merge'
            });
        }

        // Navigate
        this.navigate(commands, extras);

        // Update URL address
        window.history.pushState('', '', commands ? commands[0] : '');
    }

    public getViewTabFromQueryParam(route: ActivatedRoute) {
        const tab = route.snapshot.queryParams.tab;
        return tab || null;
    }

    public addParamsToCurrentUrl(params: any): void {
        this.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            // queryParamsHandling: 'merge', // options: 'preserve', 'merge'.
            skipLocationChange: false // reflect changes in URL
        });
    }

    private async spinnerWait(options: SpinnerWaitOption) {

        // send message bus to app to show site spinner
        if (options.hasSpinner()) {
            this.msgbus.sendMessage(new BusMessage("system", "spinner", { show: true }));
        }

        // delay N seconds
        if (options.hasWait()) {
            await AsyncUtil.wait(options.waitTimeout);
        }

        // send message bus to app to hide site spinner
        if (options.hasSpinner()) {
            this.msgbus.sendMessage(new BusMessage("system", "spinner", { show: false }));
        }
    }

    /**
     * Redirect to external URLs
     * @param url The URL to redirect to
     * @param altUrls Alternate URLs if `url` fails to redirect
     */
    public redirectExternalUrl(url: string, ...altUrls: string[]) {
        // await AsyncUtil.wait(0);
        altUrls.forEach((altUrl, i) => {
            setTimeout(function () {
                window.location.replace(altUrl);
            }, (i + 1) * 1000);
        });
        window.location.replace(url);
    }

    public getCurrentPath() {
        const currentPathUrl = this.router.url.includes('leftbar') ? this.router.url.substring(0, this.router.url.indexOf('(leftbar')) : this.router.url;
        return currentPathUrl;
    }
}

class SpinnerWaitOption {
    private _showSpinner: boolean;
    private _waitTimeout: number; // milli seconds
    constructor(showSpinner: boolean = false, waitTimeout: number = 0) {
        this._showSpinner = showSpinner;
        this._waitTimeout = waitTimeout;
    }
    hasSpinner() {
        return (this._showSpinner);
    }
    hasWait() {
        return (this._waitTimeout > 0);
    }
    get waitTimeout() {
        return this._waitTimeout;
    }
}
