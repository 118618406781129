import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { MatDatepicker } from '@angular/material/datepicker';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { TripsService } from '../../../../_services/trips/trips.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { TokenService } from 'src/app/_services/token/token.service';
import { AuthService } from 'src/app/_services/auth';

// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';
import { ActivatedRoute } from '@angular/router';

@Component({
	providers: [GeneralReport],
	templateUrl: './customized-utilization-report.component.html',
	styleUrls: ['./customized-utilization-report.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CustomizedUtilizationReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

    monthName: Array<string> = ['January', 'February', 'March', 'April' ,'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Set Export File Name from environments
    exportPdfUtilizationPageLayou = environment.appConfig.reporting.customUtilization.layout;
    exportFileNameUtilization = environment.appConfig.reporting.customUtilization.fileName;
    exportFileNameUtilizationPdfTitle = environment.appConfig.reporting.customUtilization.label;
    pageLayout = this.exportPdfUtilizationPageLayou;
    moment = moment;

	@ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
	@ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

	constructor(
	    private platform: Platform,
		private http_parent: HttpClient,
		private snackBar: SnackBarService,
		private pagerService: PagerService,
		private tripsService: TripsService,
		private popupService_parent: PopupService,
		private route: ActivatedRoute,
		private popupCampaignService: PopupCampaignService,
        private tokenService: TokenService,
        private authService: AuthService
	) { 
		//pass services to parent
		super(http_parent, popupService_parent, snackBar);
	}

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() { 
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            // usingMonthYearPicker: true,
            usingDateRangePicker: true,
            usingVehicleTypeSelector: true
        });
        this.page_spinner.hide();
    }
  
    async ngOnInit() {
        //Default search by
        this.searchBy = "Utilization";

        let currentUser = this.tokenService.getAdministratorUserDetails();
        if(!currentUser) {
            currentUser = this.authService.getCurrentUserDetails();
        }
        this.companyId = currentUser.companyId;

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        await this.checkUnviewedCampaign();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            
            //call api to get report page
            const apiControllerResponse: any = await this.getApiControllerResponse(startRecord, null);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;

                //update sorting classes
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = this.apiResponse.customUtilizationReport;

                if (this.resultList.length) {
                    //convert numbered nmonth to text format
                    this.resultList.forEach(record => {
                        record.Month = this.monthName[record.Month-1];
                    });

                    //update isReportShown boolean
                    this.isReportShown = true;
                    
                    //get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }

    }

    // Call API to get all data for download
    async fetchDataForDownload() {
        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);
        return apiControllerResponse.resultList;
    }

    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        apiResponse = await this.getCustomUtilizationRecords(startRecord, isDownload);
        if (apiResponse != null) {
            apiResultList = apiResponse.customUtilizationReport;
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }
    // For download summary report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem { 
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 9 }, { wch: 21 }, { wch: 23 }, { wch: 30 }];

        filename = this.exportFileNameUtilization;
        label = this.exportFileNameUtilizationPdfTitle;
        headerName = ["Equipment Type", "Total Unit", "Date", "Total Utilization", "Monthly Average Total Utilization (%)"];

        for (let i = 0; i < recordList.length; i++) {
            const tempRow = [
                recordList[i].VehicleType,
                recordList[i].TypeCount,
                recordList[i].Date,
                recordList[i].Utilization,
                recordList[i].Avg
            ];

            data.push(tempRow);
        }
        
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    // chosenStartMonthHandler(normalizedMonth, datepicker: MatDatepicker<Date>) {
    //     const selectedYearMonth = moment(normalizedMonth).format("YYYY-MM-01");
    //     this.startMonthYear = selectedYearMonth;
    //     // Must have to prevent datepicker those date selection tab
    //     datepicker.close();
    // }

    // chosenEndMonthHandler(normalizedMonth, datepicker: MatDatepicker<Date>) {
    //     const selectedYearMonth = moment(normalizedMonth).format("YYYY-MM-01");
    //     this.endMonthYear = selectedYearMonth;
    //     // Must have to prevent datepicker those date selection tab
    //     datepicker.close();
    // }

    /**
     * Report Specific Methods
     */

    // 1. Call API Utilization Report
    async getCustomUtilizationRecords(startRecord: number = 1, isDownload: boolean = false) {
        let result: any = null;
        let vehicleTypeList = [];
        
        vehicleTypeList = this.selectedVehiclesList;
        
        // let startMonth = parseInt(this.startMonth);
        // let startYear = parseInt(this.startYear);
        // let endMonth = parseInt(this.endMonth);
        // let endYear = parseInt(this.endYear);
        let fromDate = this.currentFromDate;
        let toDate = this.currentToDate;
        let sortField = this.currentSortField;
        let sortAscending = this.currentSortAscending;

        if (!isDownload) {
            result = await this.tripsService.getCustomUtilizationReport(vehicleTypeList, fromDate, toDate, this.pageRecordSize, startRecord, sortField, sortAscending);
        } else {
            result = await this.tripsService.getCustomUtilizationReport(vehicleTypeList,fromDate, toDate, null, null, sortField, sortAscending);
        }

        return result || null;
    }
}
