import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[dom-states]',
    exportAs: 'DOMStates'
})
export class DOMStatesDirective {
    isFocused:boolean = false;

    constructor(
        private elementRef: ElementRef
    ) { }

    @HostListener('focus') onFocus():void {
        this.isFocused = true;
    }

    @HostListener('blur') onBlur():void {
        this.isFocused = false;
    }
}
