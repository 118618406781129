<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <div class="block">
                <h1 class="heading --level-1">
                    {{ maxisSupportUserUpdatedDetails.name }}
                </h1>
            </div>
        </div>
    </div>

    <form class="form-field" #maxisSupportUpdateForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex geofence-field">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Maxis Support Type
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder geofence-alert">
                                    <div class="field-group__label">
                                        Specify the user's role:
                                    </div>

                                    <div class="field-group__input">
                                        <label class="inline-input">
                                            <input type="radio" name="role" class="inline-input__input" [checked]="selectedRoleLabel == userRoleALabel" (click)="getSelectedRole(userRoleALabel)">

                                            <span class="inline-input__icon"></span>

                                            {{userRoleALabel}}
                                        </label>

                                        <label class="inline-input">
                                            <input type="radio" name="role" class="inline-input__input" [checked]="selectedRoleLabel == userRoleBLabel" (click)="getSelectedRole(userRoleBLabel)">

                                            <span class="inline-input__icon"></span>

                                            {{userRoleBLabel}}
                                        </label>
                                        
                                        <label class="inline-input">
                                            <input type="radio" name="role" class="inline-input__input" [checked]="selectedRoleLabel == userRoleCLabel" (click)="getSelectedRole(userRoleCLabel)">

                                            <span class="inline-input__icon"></span>

                                            {{userRoleCLabel}}
                                        </label>

                                        <label class="inline-input">
                                            <input type="radio" name="role" class="inline-input__input" [checked]="selectedRoleLabel == userRoleSalesDemoLabel" (click)="getSelectedRole(userRoleSalesDemoLabel)">

                                            <span class="inline-input__icon"></span>

                                            {{userRoleSalesDemoLabel}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Maxis Support Basic Info
                    </h2>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-9of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Name:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="maxisSupportUserUpdatedDetails.name" name="FirstName" #name="ngModel" required maxlength="100" placeholder="Full Name">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="name.errors && (name.dirty || name.touched)">
                                                <div class="field-error" *ngIf="name.errors?.required">
                                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Name')}}
                                                </div>
                                                <div class="field-error" *ngIf="name.errors?.maxlength">
                                                    {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Name',
                                                    '100')}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Email Address:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" [(ngModel)]="maxisSupportUserUpdatedDetails.email" name="Email" #email="ngModel" required maxlength="45" placeholder="example@gmail.com" email>
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>

                                            <div class="field-notes">
                                                (This email will be used as the login username)
                                            </div>
                                            <div *ngIf="email.errors && (email.dirty || email.touched)">
                                                <div class="field-error" *ngIf="email.errors?.required">
                                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Email')}}
                                                </div>
                                                <div class="field-error" *ngIf="email.errors?.email">
                                                    {{message.MESSAGE.INVALID_EMAIL.label}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="maxisSupportUpdateForm.invalid" (click)="showPopup(updateMaxisSupportPopup)">
                                SAVE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToMaxisSupportView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #updateMaxisSupportPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to save maxis support changes
        </p>

        <p class="notes">
            Additional notes here
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateMaxisSupport();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
