import { Component, OnInit, ViewChild } from '@angular/core';
import { TokenService } from './../../../../_services/token/token.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { SupportService } from './../../../../_services/support/support.service';
// import { CustomReuseStrategy } from './../../../../app-routing-custom-reuse-strategy';
import { PagerService } from './../../../../_services/pager/pager.service';
import { CampaignServiceService } from './../../../../_services/campaign/campaign-service.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { AuthService } from './../../../../_services/auth/auth.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';

import { trigger, style, animate, transition } from '@angular/animations';
import { PopupService } from './../../../../components/common/popup/popup.service';
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';


@Component({
    selector: 'app-manage-campaigns',
    templateUrl: './manage-campaigns.component.html',
    styleUrls: ['./manage-campaigns.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ManageCampaignsComponent implements OnInit {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    toggleMobileOpen: boolean = false;

    //Pagination
    pager: any = {}; //Pager object
    pageRecordSize: number = 10;

    sortField = 'name';
    sortAscending: boolean = true;
    filterValue = '';
    currentFilterValue = '';
    campaignList: any = [];
    campaignOriDetails: any = {};

    // Campaign Selected
    isDeletingCampaign: boolean;
    selectedCampaign: any = {};
    selectedCampaignGroups: any = {};

    constructor(
        private tokenService: TokenService,
        private supportService: SupportService,
        private routerProxyService: RouterProxyService,
        private popupService: PopupService,
        private pagerService: PagerService,
        private snackBar: SnackBarService,
        private campaignService: CampaignServiceService,
        private authService: AuthService
    ) { }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async ngOnInit() {
        const token = await this.tokenService.removeAdministratorUser(); // remove administrator token
        if (token) {
            await this.authService.removeAdminitratorLogin(token);
        }
        await this.listCampaign();
    }

    navigateToCreateCampaigns() {
        this.routerProxyService.navigateByUrl('/support/manage/manage-campaigns/create-campaigns');
    }

    getSortingStatus(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }
        await this.listCampaign();
    }

    navigateToEditCampaign(campaignId) {
        this.routerProxyService.navigate(['support/manage/manage-campaigns/edit-campaigns', campaignId]);
    }

    async searchCampaign() {
        this.currentFilterValue = this.filterValue;
        await this.listCampaign();
    }

    async listCampaign(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Get Company Info Details Page
            const response: any = await this.campaignService.listCampaign(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.currentFilterValue);
            if (response) {
                this.campaignOriDetails = response.body;
                this.campaignList = response.body.result;
                this.pager = this.pagerService.getPager(this.campaignOriDetails.totalRecord, page, this.pageRecordSize);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    onCampaignAllSelectChange() {
        const allIsSelected = this.selectedCampaignGroups.all;
        for (let i = 0; i < this.campaignList.length; i++) {
            const campaignModel = this.campaignList[i];
            this.selectedCampaign[campaignModel.id] = allIsSelected;
        }
    }

    onCampaignSelectChange(campaignSelected: boolean) {
        if (campaignSelected) {
            // this.selectedDrivingLimit[drivingLimitModel.vehicleId] = true;
        } else {
            this.selectedCampaignGroups['all'] = false;
        }
    }

    countSelectedCampaign() {
        return Object.keys(this.selectedCampaign).filter(result => this.selectedCampaign[result] === true).length;
    }

    async deleteCampaigns() {
        this.pageSpinner.show();
        try {
            const selectedCampaignList = [];
            for (const key in this.selectedCampaign) {
                if (this.selectedCampaign[key] === true) {
                    selectedCampaignList.push(parseInt(key));
                }
            }

            const result = await this.campaignService.deleteCampaign(selectedCampaignList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Campaign');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Campaign');

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.listCampaign();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    cancelResetDelete() {
        this.isDeletingCampaign = false;
        this.selectedCampaign = {};
        this.selectedCampaignGroups['all'] = false;
    }
}
