import { Injectable, Output, EventEmitter } from '@angular/core';
import { AccessControlService } from '../access-control/access-control.service';
import { Platform } from '@ionic/angular';
import * as DomUtil from './../../util/domUtil';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    // isShowManageAccount = false;

    // @Output() change: EventEmitter<boolean> = new EventEmitter();

    constructor(private accessControlService: AccessControlService, private platform: Platform) { }

    // showManageAccountMenu() {
    //     this.isShowManageAccount = true;
    //     this.change.emit(this.isShowManageAccount);
    // }

    // hideManageAccountMenu() {
    //     this.isShowManageAccount = false;
    //     this.change.emit(this.isShowManageAccount);
    // }

    /**
     * Generate Menu Listing from Page Access API
     */
    async getNavigationMenu(useRefToken: boolean = false) {
        // console.debug('get navigation menu')
        const response = {
            navMenu: [],
            success: false
        };

        const pageAccess = await this.accessControlService.getPageAccess(true, useRefToken);
        if (pageAccess && pageAccess.body) {
            // console.debug('pageAccess.body:'+JSON.stringify(pageAccess.body));
            pageAccess.body.map(pa => {
                // set parent module as menu header
                if (pa.isParentMenu) {
                    const menu = {
                        id: pa.mId,
                        navId: pa.navId,
                        href: pa.url,
                        label: pa.pName,
                        disable: false,
                        isRequiredRefToken: pa.isAccessibleByRefToken,
                        subnav: []
                    };
                    if (pa.navId == 'reports' || pa.navId == 'customized-reports') {
                        if (!DomUtil.isMobileApp(this.platform) && DomUtil.isTabletOrDesktopView()) {
                            response.navMenu.push(menu);
                        }
                    } else {
                        response.navMenu.push(menu);
                    }
                } else {
                    // set sub nav menu to parent module
                    const subnav = {
                        label: pa.pName,
                        href: pa.url,
                        isRequiredRefToken: pa.isAccessibleByRefToken
                    };
                    // check the child and parent relationship by navMenu's moduleId(mId) & subModule's parentModuleId(pMId)
                    const nm = response.navMenu.find(eachNM => eachNM.id == pa.pMId);
                    if (nm) {
                        nm.subnav.push(subnav);
                    }
                }
            });
            response.success = true;
        }
        // console.debug('navMenu:'+ JSON.stringify(navMenu));
        return response;
    }
}

export interface NavMenu {
    id: number;
    navId: string;
    href: string;
    label: string;
    disable: boolean;
    isRequiredRefToken: boolean;
    subnav: SubNav[];
}

interface SubNav {
    label: string;
    href: string;
    isRequiredRefToken: boolean;
}
