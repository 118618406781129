<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Set Fuel KPI
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #fuelLimitForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block  w-8of12@sm form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Step 1: KPI Type
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <!-- <div class="field-group__input set-limit-field"> -->
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelUsedLimitOff" name="isFuelUsedLimitOff" #isFuelUsedLimitOnOff="ngModel">
                                                Fuel Used (L/month):
                                            </mat-slide-toggle> -->
                                        Refuel Amount (L/month):
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <!-- <input id="FuelUsedLimit" type="text" class="field__input" [class.--disabled]="!isFuelUsedLimitOff" [disabled]="!isFuelUsedLimitOff" [required]="isFuelUsedLimitOff ? '' : null" name="fuelUsedLimit" [(ngModel)]="fuelUsedLimit" OnlyNumber OnlyInteger> -->
                                                    <input id="FuelUsedLimit" type="text" class="field__input" name="fuelUsedLimit" [(ngModel)]="fuelUsedLimit" placeholder="125" OnlyNumber OnlyInteger>
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelCostLimitOff" name="isFuelCostLimitOff" #isFuelCostLimitOnOff="ngModel">
                                                Fuel Cost (RM/month):
                                            </mat-slide-toggle> -->
                                        Fuel Cost (RM/month):
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <!-- <input id="FuelCostLimit" type="text" class="field__input" [class.--disabled]="!isFuelCostLimitOff" [disabled]="!isFuelCostLimitOff" [required]="isFuelCostLimitOff ? '' : null" name="fuelCostLimit" [(ngModel)]="fuelCostLimit" OnlyNumber OnlyInteger> -->
                                                    <input id="FuelCostLimit" type="text" class="field__input" name="fuelCostLimit" [(ngModel)]="fuelCostLimit" placeholder="237" OnlyNumber OnlyInteger>
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelEfficiencyLimitOff" name="isFuelEfficiencyLimitOff" #isFuelEfficiencyLimitOnOff="ngModel">
                                                    Fuel Efficiency (L/100km):
                                            </mat-slide-toggle> -->
                                        Fuel Efficiency (km/L):
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <!-- <input type="text" class="field__input" [class.--disabled]="!isFuelEfficiencyLimitOff" [disabled]="!isFuelEfficiencyLimitOff" name="fuelEfficiencyLimit" [(ngModel)]="fuelEfficiencyLimit" [required]="isFuelEfficiencyLimitOff ? '' : null" OnlyNumber OnlyInteger> -->
                                                    <input type="text" class="field__input" name="fuelEfficiencyLimit" [(ngModel)]="fuelEfficiencyLimit" placeholder="32" OnlyNumber OnlyInteger maxlength="3">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelEfficiencyLimitOff" name="isFuelEfficiencyLimitOff" #isFuelEfficiencyLimitOnOff="ngModel">
                                                                                        Fuel Efficiency (L/100km):
                                                                                </mat-slide-toggle> -->
                                        Fuel Refuel Threshold (%):
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <!-- <input type="text" class="field__input" [class.--disabled]="!isFuelEfficiencyLimitOff" [disabled]="!isFuelEfficiencyLimitOff" name="fuelEfficiencyLimit" [(ngModel)]="fuelEfficiencyLimit" [required]="isFuelEfficiencyLimitOff ? '' : null" OnlyNumber OnlyInteger> -->
                                                    <input type="text" class="field__input" name="RefuelThreshold" [(ngModel)]="fuelRefuelThreshold" #refuelThreshold="ngModel" placeholder="0-100" OnlyNumber OnlyInteger maxlength="3">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div class="field-notes">
                                            (Vehicle with fuel sensor module will take effect)
                                        </div>
                                        <div class="field-error" *ngIf="fuelRefuelThreshold < 0 || fuelRefuelThreshold > 100">
                                            {{message.getMessage(message.MESSAGE.OUT_OF_RANGE.value, 'Fuel Refuel Threshold', '0', '100')}}
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        <!-- <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="isFuelEfficiencyLimitOff" name="isFuelEfficiencyLimitOff" #isFuelEfficiencyLimitOnOff="ngModel">
                                                                                        Fuel Efficiency (L/100km):
                                                                                </mat-slide-toggle> -->
                                        Fuel Drop Alert Threshold (%):
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <!-- <input type="text" class="field__input" [class.--disabled]="!isFuelEfficiencyLimitOff" [disabled]="!isFuelEfficiencyLimitOff" name="fuelEfficiencyLimit" [(ngModel)]="fuelEfficiencyLimit" [required]="isFuelEfficiencyLimitOff ? '' : null" OnlyNumber OnlyInteger> -->
                                                    <input type="text" class="field__input" name="FuelDropThreshold" [(ngModel)]="fuelDropThreshold" placeholder="0-100" #dropTreshold="ngModel" OnlyNumber OnlyInteger maxlength="3">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div class="field-notes">
                                            (Vehicle with fuel sensor module will take effect)
                                        </div>
                                        <div class="field-error" *ngIf="fuelDropThreshold < 0 || fuelDropThreshold > 100">
                                            {{message.getMessage(message.MESSAGE.OUT_OF_RANGE.value, 'Fuel Drop Threshold', '0', '100')}}
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-vehicle-selector #vehicleAssign [title]="'Step 2: Assign Vehicles'"></app-vehicle-selector>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="fuelLimitForm.invalid || vehicleAssign.getSelectedCount() == 0 || (fuelRefuelThreshold < 0 || fuelRefuelThreshold > 100) || (fuelDropThreshold < 0 || fuelDropThreshold > 100)" (click)="showPopup(saveFuelLimitPopup)">
                                CREATE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToFuelView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #saveFuelLimitPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to set new fuel KPI
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="createFuelLimit();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
