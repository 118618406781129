<div class="site-main">
    <div class="section msp-section">
        <div class="wrapper msp-wrapper">
            <div class="block msp-block-heading">
                <h1 class="heading">
                    Customer Support
                </h1>

                <p class="space-top">
                    Select an option to proceed
                </p>
            </div>

            <div class="block msp-block-links">
                <div class="msp-block-links__box" *ngIf="allowAccessManageCompany">
                    <button class="msp-button" (click)="navigateToCustomerView()">
                        <img src="assets/images/ng-components/maxis-support-panel/customer-view.svg" alt="">

                        <p>
                            Customer View
                        </p>
                    </button>
                </div>

                <div class="msp-block-links__box" *ngIf="allowAccessCampaign">
                    <button class="msp-button" (click)="navigateToManageCampaigns()">
                        <img src="assets/images/ng-components/maxis-support-panel/create-tutorials.svg" alt="">

                        <p>
                            Manage Campaigns
                        </p>
                    </button>
                </div>

                <div class="msp-block-links__box" *ngIf="allowAccessResetPass">
                    <button class="msp-button" (click)="navigateToResetPassword()">
                        <img src="assets/images/ng-components/maxis-support-panel/reset-password.svg" alt="">

                        <p>
                            Reset User Password
                        </p>
                    </button>
                </div>

                <div class="msp-block-links__box" *ngIf="allowAccessIdManagement">
                    <button class="msp-button" (click)="navigateToIdManagement()">
                        <img src="assets/images/ng-components/maxis-support-panel/manage-maxis-users.svg" alt="">

                        <p>
                            Manage Maxis Users
                        </p>
                    </button>
                </div>

                <div class="msp-block-links__box" *ngIf="allowAccessManageDevice">
                    <button class="msp-button" (click)="navigateToManageDevices()">
                        <img src="assets/images/ng-components/maxis-support-panel/manage-devices.svg" alt="">

                        <p>
                            Manage Order and Device
                        </p>
                    </button>
                </div>

                <div class="msp-block-links__box" *ngIf="allowAccessTerminateVehicle">
                    <button class="msp-button" (click)="navigateToVehicleTermination()">
                        <img src="assets/images/ng-components/maxis-support-panel/manage-devices.svg" alt="">

                        <p>
                            Vehicle Termination
                        </p>
                    </button>
                </div>

                <div class="msp-block-links__box" *ngIf="allowAccessSaleDashboard">
                    <button class="msp-button" (click)="navigateToSalesDashboard()">
                        <img src="assets/images/ng-components/maxis-support-panel/sale_dashboard.svg" alt="">

                        <p>
                            Sales Dashboard
                        </p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
