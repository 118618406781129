<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@sm --space-top">
                    <h1 class="heading --level-1">
                        Admins
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top" [hidden]="currentTab !== 1">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <!--IMPORT tag commented in below is for Batch Import UI Do No Remove-->
                            <!-- <a class="heading-buttons__button" (click)="showPopup(batchImportPopup)" style="visibility: hidden">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-batch-import.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Batch Import
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Batch Import
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-batch-import.svg" alt="" width="60" height="60">

                                </ng-container>
                            </a> -->


                            <a class="heading-buttons__button" (click)="showDownloadPopup(downloadsPopup)">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-export.svg" alt="" width="23" height="23" class="heading-buttons__icon">
                                    <div class="heading-buttons__text">
                                        Export As
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Export As
                                    </div>
                                    <img src="assets/images/ng-components/create-manage/icon-mobile-export.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" *ngIf="creatable" (click)="navigateToCreateAdmin(); toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Admin
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Admin
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" *ngIf="deletable" (click)="isDeletingAdmins = true; toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Admin
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Admin
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
                <div class="grid__block w-8of12@md heading-buttons-field --space-top" [hidden]="currentTab !== 2">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToCreateRole(); toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Role
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Role
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingRole = true; toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Role
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Role
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="viewAdmin()">Admin</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="viewRoles()">Roles</button>
            </div>
        </div>
    </div>
    <div class="tabs__content" [hidden]="currentTab !== 1">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-10of12 w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-steering-wheel.svg" width="26" height="20" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input id="adminSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Admin" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchAdminName()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchAdminName()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="grid__block w-2of12 w-6of12@sm">
                                <div class="filter-button">
                                    <button type="button" class="button --filter" (click)="sort()">
                                        <div class="filter-button__input">
                                            Admin Name
                                        </div>

                                        <img [attr.src]="sortAscending ? 'assets/images/ng-components/create-manage/icon-filter-down.svg' : 'assets/images/ng-components/create-manage/icon-filter-up.svg'" alt="" width="25" height="12" class="filter-button__icon">

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm w-4of12@lg" *ngFor="let admin of adminViewInfoList">

                                <a class="filter-result__box --cursor" *ngIf="!isDeletingAdmins" (click)="navigateToEditAdmin(admin.Id)">
                                    <ng-container *ngTemplateOutlet="result1"></ng-container>
                                </a>

                                <label class="result-checkbox" *ngIf="isDeletingAdmins">
                                    <div class="inline-input">
                                        <input type="checkbox" name="all" class="inline-input__input" [(ngModel)]="selectedDeleteAdmins[admin.Id]">

                                        <span class="inline-input__icon"></span>

                                        <div class="filter-result__box --cursor">
                                            <ng-container *ngTemplateOutlet="result1"></ng-container>
                                        </div>
                                    </div>
                                </label>

                                <ng-template #result1>
                                    <div class="filter-result-group">
                                        <div class="filter-result__image">
                                            <div class="filter-result__image-cropped" *ngIf="admin.Photo">
                                                <img src="data:image/jpeg;base64,{{admin.Photo}}" alt="" width="105" height="105">
                                            </div>
                                            <div class="filter-result__image --placeholder" *ngIf="admin.hasUserNameBool && !admin.Photo">
                                                <div class="filter-result__image-cropped --placeholder-green">
                                                    {{admin.shortName}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="filter-result__info">
                                            <div class="filter-result__title">
                                                <span class="truncate-line">
                                                    {{admin.Username}}
                                                </span>
                                            </div>

                                            <div class="filter-result__email">
                                                <span class="truncate-line">
                                                    {{admin.Email || '-'}}
                                                </span>
                                            </div>

                                            <div class="filter-result__contact">
                                                <span class="truncate-line">
                                                    {{admin.TelNo || '-'}}
                                                </span>
                                            </div>

                                            <div class="filter-result__desc">
                                                <div class="filter-result__desc__child__row">
                                                    <div class="filter-result__desc__child__col">
                                                        <span class="truncate-line">
                                                            <span class="filter-result__desc__child__label">Vehicles: </span>
                                                            <span>
                                                                {{!!admin.VisibleVehicleCount ? admin.VisibleVehicleCount : 0}}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div class="filter-result__desc__child__col">
                                                        <span class="truncate-line">
                                                            <span class="filter-result__desc__child__label">Drivers: </span>
                                                            <span>
                                                                {{!!admin.VisibleDriverCount ? admin.VisibleDriverCount : 0}}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <!--table cellspacing="0" cellpadding="0">
                                                <tr class="desc__licence">
                                                    <td>
                                                        <b>Vehicles:</b> {{!!admin.VisibleVehicleCount ? admin.VisibleVehicleCount : 0}}</td>
                                                    <td>
                                                        <b>Drivers:</b> {{!!admin.VisibleDriverCount ? admin.VisibleDriverCount : 0}}</td>
                                                </tr>
                                            </table-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination" *ngIf="adminViewInfoList.length > 0">
                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDisplayAdminList(pager.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDisplayAdminList(page)">{{
                                        page }}</button>

                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDisplayAdminList(pager.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="tabs__content" [hidden]="currentTab !== 2">
        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <div class="table">
                        <table class="table__elem" style="width: 100%; table-layout: fixed;">
                            <thead>
                                <tr>
                                    <ng-container *ngIf="isDeletingRole">
                                        <th scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedRoleGroups['all']" (ngModelChange)="onRoleAllSelectChange()">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </th>
                                    </ng-container>

                                    <th scope="col">
                                        <button type="button" class="table__sortable" [ngClass]="getSortingStatus('name')" (click)="roleSort('name')">Role</button>
                                    </th>
                                    <th scope="col">
                                        Users
                                    </th>
                                    <th scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let role of roleInfoList">
                                    <ng-container *ngIf="isDeletingRole">
                                        <td scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" [name]="role.id" [value]="true" class="inline-input__input" [(ngModel)]="selectedRole[role.id]" (ngModelChange)="onRoleSelectChange($event)" [disabled]="role.defaultAdmin === 'Y'">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>
                                    </ng-container>
                                    <td class="td-truncate">
                                        {{role.name}}
                                    </td>
                                    <td>
                                        {{role.userCount}}
                                    </td>
                                    <td *ngIf="role.defaultAdmin === 'N'">
                                        <a class="link --primary" (click)="navigateToEditRole(role.id)">Edit Role</a>
                                    </td>
                                    <td *ngIf="role.defaultAdmin === 'Y'">
                                        -
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <h2 class="record-not-found" *ngIf="roleInfoList.length === 0">No Record Found</h2>
                    </div>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{rolePager.currentPage}} / {{rolePager.totalPages}} Page
                            </div>
                        </div>
                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination">
                                <button type="button" class="pagination__button" [class.--disabled]="rolePager.currentPage === 1" [disabled]="rolePager.currentPage === 1" (click)="listRole(rolePager.currentPage - 1)">
                                    <span class="angle-arrow --left"></span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of rolePager.pages" class="pagination__button" [class.--active]="page === rolePager.currentPage" (click)="listRole(page)">{{
                                    page
                                    }}</button>

                                <button type="button" class="pagination__button" [class.--disabled]="rolePager.currentPage === rolePager.totalPages" [disabled]="rolePager.currentPage === rolePager.totalPages" (click)="listRole(rolePager.currentPage + 1)">
                                    <span class="angle-arrow --right"></span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingAdmins" [hidden]="currentTab !== 1">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedAdmins()}} admin(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" [disabled]="countSelectedAdmins()==0" (click)="showPopup(deleteAdminPopup)">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteAdminPopup="popup" class="cm-popups" [hidden]="currentTab !== 1">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedAdmins()}}</span> admin(s)
        </p>

        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteAdmin();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #downloadsPopup="popup" [hidden]="currentTab !== 1">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="exportAdminFile('excel'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="exportAdminFile('csv'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="exportAdminFile('pdf'); hidePopup();">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
<!--
<app-popup #batchImportPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Batch Import
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="image-placeholder">
            <img src="assets/images/ng-components/create-manage/icon-batch-import-grey.svg" alt="" width="170" height="170">
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button">
                Browse
            </button>
        </div>

        <span class="notes-input">( Max File Size: 2MB )</span>
    </div>

    <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">

            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a href="#/">here</a>
            </span>
        </p>
    </div>
</app-popup>
-->

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingRole" [hidden]="currentTab !== 2">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedRole()}} Role(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteRolePopup)" [disabled]="countSelectedRole() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteRolePopup="popup" class="cm-popups" [hidden]="currentTab !== 2">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedRole()}} Role(s)</span>
        </p>

        <p class="notes"></p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteRole();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
