<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md">
                    <h1 class="heading --level-1">
                        Create Campaigns
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #campaignForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Campaign Name
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__input">
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Name:

                                            <span class="indicator-green">*</span>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" name="campaignName" [(ngModel)]="campaignObj.name" #campaignName="ngModel" required maxlength="250">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="campaignName.errors && (campaignName.dirty || campaignName.touched)">
                                                <div class="field-error" *ngIf="campaignName.errors?.required">
                                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Name')}}
                                                </div>
                                                <div class="field-error" *ngIf="campaignName.errors?.maxlength">
                                                    {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Name', '250')}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Duration:

                                            <span class="indicator-green">*</span>
                                        </div>

                                        <div class="field-group__groups-col field-duration">
                                            <div class="field-duration__block">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <div class="field__icon">
                                                            <div class="field__icon-inner">
                                                                <mat-datepicker-toggle [for]="DurationStartDatepicker">
                                                                    <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                                </mat-datepicker-toggle>
                                                            </div>
                                                        </div>

                                                        <input [matDatepicker]="DurationStartDatepicker" (focus)="DurationStartDatepicker.open()" (click)="DurationStartDatepicker.open()" type="text" [(ngModel)]="campaignObj.durationStart" name="durationStart" class="field__input" readonly dom-states #DurationStartDateDOMStates="DOMStates" [max]="campaignObj.durationEnd" required>

                                                        <div class="field__ui"></div>
                                                    </div>

                                                    <mat-datepicker #DurationStartDatepicker [touchUi]="datepickerTouchUi">
                                                    </mat-datepicker>
                                                </div>
                                            </div>

                                            <div class="field-duration__block">
                                                -
                                            </div>

                                            <div class="field-duration__block">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <div class="field__icon">
                                                            <div class="field__icon-inner">
                                                                <mat-datepicker-toggle [for]="DurationEndDatepicker">
                                                                    <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                                </mat-datepicker-toggle>
                                                            </div>
                                                        </div>

                                                        <input [matDatepicker]="DurationEndDatepicker" (focus)="DurationEndDatepicker.open()" (click)="DurationEndDatepicker.open()" type="text" [(ngModel)]="campaignObj.durationEnd" name="durationEnd" class="field__input" readonly dom-states #DurationEndDateDOMStates="DOMStates" [min]="campaignObj.durationStart" required>

                                                        <div class="field__ui"></div>
                                                    </div>

                                                    <mat-datepicker #DurationEndDatepicker [touchUi]="datepickerTouchUi">
                                                    </mat-datepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Placement:

                                            <span class="indicator-green">*</span>
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="select" style="width: 100%">
                                                        <select class="select__input" name="category" #category="ngModel" [(ngModel)]="campaignObj.pageAccessId" required>
                                                            <option *ngFor="let item of placementList;" [value]="item.id" [selected]="item.id === selectedPlacement">{{item.name}}</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Status:
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="inline-input" style="margin: 10px;">
                                                        <input type="radio" name="enable" class="inline-input__input" value="ACTIVE" [(ngModel)]="campaignObj.status">
                                                        <span class="inline-input__icon"></span>
                                                        <span>Active</span>
                                                    </label>

                                                    <label class="inline-input" style="margin: 10px;">
                                                        <input type="radio" name="disable" class="inline-input__input" value="DEACTIVATED" [(ngModel)]="campaignObj.status">
                                                        <span class="inline-input__icon"></span>
                                                        <span>Deactivated</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Campaign Setup
                        </h2>

                        <div class="campaign-setup" dragula="HANDLES" [(dragulaModel)]='campaignItemList'>
                            <div class="campaign-setup__items" *ngFor="let campaignItem of campaignItemList; let position = index;">
                                <div class="campaign-setup__items-header">
                                    <div class="campaign-setup__items-header-title">
                                        {{campaignItem.title}}
                                    </div>

                                    <div class="campaign-setup__items-header-buttons">
                                        <!--<button class="button --button-circle --edit"></button>-->
                                        <button class="button --button-circle --delete" (click)="showPopup(deleteCampaignItemPopup);selectedDeleteCampaignItemPosition(position);"></button>
                                        <button id="handle" class="button --button-circle --drag"></button>
                                    </div>
                                </div>

                                <div class="campaign-setup__items-contents">
                                    <div class="field-group --unstack@xs">
                                        <div class="field-group__item">
                                            <div class="field-group__input">
                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Title:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'title'+position}}" [(ngModel)]="campaignItem.title" required>
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Description:

                                                        <span class="indicator-green">*</span>
                                                    </div>

                                                    <div class="field-group__groups-col">
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" name="{{'description'+position}}" [(ngModel)]="campaignItem.description" required>
                                                                </label>

                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Desktop Image:<br>

                                                        <span class="field-notes">(650 X 350px)</span>
                                                    </div>

                                                    <div class="field-group__groups-col" *ngIf="!campaignItem.haveDesktopImage">
                                                        <button class="campaign-setup__upload-button --desktop" (click)="showPopup(imageUploadPopup);currentImageSelectedIndexAndSource(position, true);">
                                                            <div class="campaign-setup__upload-button-inner"></div>
                                                        </button>
                                                    </div>
                                                    <div class="field-group__groups-col" *ngIf="campaignItem.haveDesktopImage">
                                                        <a (click)="showPopup(imageUploadPopup);currentImageSelectedIndexAndSource(position, true);"><img id="{{'imageId'+position}}" alt="" width="206" height="116">
                                                        </a>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups">
                                                    <div class="field-group__groups-col">
                                                        Mobile Image:<br>
                                                        <span class="field-notes">(580 X 430px)</span>
                                                    </div>

                                                    <div class="field-group__groups-col" *ngIf="!campaignItem.haveMobileImage">
                                                        <button class="campaign-setup__upload-button --desktop" (click)="showPopup(imageUploadPopup);currentImageSelectedIndexAndSource(position, false);">
                                                            <div class="campaign-setup__upload-button-inner"></div>
                                                        </button>
                                                    </div>
                                                    <div class="field-group__groups-col" *ngIf="campaignItem.haveMobileImage">
                                                        <a (click)="showPopup(imageUploadPopup);currentImageSelectedIndexAndSource(position, false);"><img id="{{'mobileImageId'+position}}" alt="" width="206" height="116">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button class="campaign-setup__add" (click)="addNewCampaignItem()">
                                <div class="campaign-setup__add-inner">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="campaign-setup__add-icon">

                                    <div class="campaign-setup__add-text">
                                        Create Campaigns
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="campaignForm.invalid" (click)="showPopup(createCampaignPopup)">
                                SAVE
                            </button>

                            <button class=" button inline-button__inputs --grey" type="button" (click)="navigateToCampaignView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #imageUploadPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Image Upload
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="image-placeholder">
            <img src="assets/images/ng-components/create-manage/icon-image-upload.svg" alt="" width="152" height="133">
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <label>
                <span class="button inline-button__inputs">
                    Browse
                </span>
                <input type="file" id="myFileInput" accept="image/*" (change)="chooseImage($event)" />
            </label>
        </div>
        <span class="notes-input">( Max File Size: {{maxSizeImageMB}}MB )</span>
    </div>
</app-popup>

<app-popup #imageAlertPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert" *ngIf="imageAlertError === 'sizeError'">Image Size Cannot Larger Than
            {{maxSizeImageMB}}MB</span>
        <span class="notes-input --alert" *ngIf="imageAlertError === 'fileError'">Invalid Image File</span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                OK
            </button>
        </div>
    </div>
</app-popup>

<app-popup #createCropImagePopup="popup" class="cm-popups" [disableClickOutside]=true (onClickClosed)="clearImage()">
    <div class="popup__block">
        <div class="cm-heading__main">

            <div class="cm-heading__text">
                Crop Image
            </div>
        </div>
    </div>
    <app-spinner #cropImage_spinner></app-spinner>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [onlyScaleDown]="true" outputType="base64" (cropperReady)="cropperReady()" (imageCropped)="imageCropped($event)" [aspectRatio]="16/9"></image-cropper>
    <div class="popup__block">
        <div class="inline-button__field">
            <div class="inline-button__inputs">
                <button type="button" class="button-crop" (click)="imageDirection('left')">
                    <img src="assets/images/ng-components/create-manage/icon-rotate-left.svg" alt="" width="23" height="23">
                </button>

                <button class="button-crop" type="button" (click)="imageDirection('right')">
                    <img src="assets/images/ng-components/create-manage/icon-rotate-right.svg" alt="" width="23" height="23">
                </button>
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="confirmCropImage();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();clearImage();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #createCampaignPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to create new driver
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="createCampaign();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #deleteCampaignItemPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to create new driver
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteCampaignItem();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
