<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="section">
    <div class="wrapper">
		<div class="reports__filters" style="position: relative; z-index: 3;">
            <div class="grid --gutter   reports__filters-grid">
                <div class="grid__block   reports__filters-grid-block --type">
                    <div class="field-group --unstack@xs   reports__type-field-group">
                        <div class="field-group__item   reports__type-field-group-input" [class.--active]="vehiclesSearchDOMStates.isFocused" click-outside (clickOutside)="onOutsideClickVehicleReaction($event)">
                            <div class="field" #vehiclesField>
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                        </div>
                                    </div>

                                    <label class="field__holder">
                                        <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                            {{ vehiclesSelectedLabel }}
                                        </div>

                                        <input id="vehicleSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #vehiclesSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterVehiclesSelection($event.target.value)">
                                    </label>

                                    <div class="field__ui"></div>
                                </div>

                                <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                    <ul class="inline-input-list">
                                        <li *ngIf="!vehiclesModel.vehicles.length" class="inline-input-list__item">
                                            <label class="inline-input">
                                                No vehicles found
                                            </label>
                                        </li>
                                        <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                            <label class="inline-input">
                                                <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups['all']" (ngModelChange)="onVehiclesAllSelectChange()">

                                                <span class="inline-input__icon"></span>

                                                All vehicles
                                            </label>
                                        </li>

                                        <ng-container *ngFor="let group of vehiclesModel.vehicles; let groupIndex = index;">
                                            <ng-container *ngIf="group.type === 'INDIVIDUAL'">
                                                <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                    <label class="inline-input">
                                                        <input type="checkbox" [name]="item.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[item.vehicleId]" (ngModelChange)="onVehiclesSelectChange()">

                                                        <span class="inline-input__icon"></span>

                                                        {{ item.vehicleName }}
                                                    </label>
                                                </li>
                                            </ng-container>

                                            <ng-container *ngIf="group.type === 'GROUP'">
                                                <li class="inline-input-list__item">
                                                    <label class="inline-input">
                                                        <input type="checkbox" [name]="group.name" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups[groupIndex]" (ngModelChange)="onVehiclesGroupSelectChange(groupIndex);">

                                                        <span class="inline-input__icon"></span>

                                                        {{ group.name }}
                                                    </label>

                                                    <button class="item-group-expand" (click)="expandGroupVehicle[groupIndex] = !expandGroupVehicle[groupIndex]">

                                                        <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupVehicle[groupIndex]">

                                                    </button>

                                                    <ng-container *ngIf="expandGroupVehicle[groupIndex]">
                                                        <ul class="inline-input-list">
                                                            <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                                <label class="inline-input">
                                                                    <input type="checkbox" [name]="item.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[item.vehicleId]" (ngModelChange)="onVehiclesSelectChange(groupIndex, $event)">

                                                                    <span class="inline-input__icon"></span>

                                                                    {{ item.vehicleName }}
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </ng-container>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid__block   reports__filters-grid-block --period">
                    <div class="field-group --unstack@xs   reports__period-field-group">
                        <div class="field-group__item   reports__period-field-group-input" [class.--active]="fromDateDOMStates.isFocused">
                            <div class="field">
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <mat-datepicker-toggle [for]="FromDatepicker">
                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                            </mat-datepicker-toggle>
                                        </div>
                                    </div>

                                    <input [matDatepicker]="FromDatepicker" type="text" [(ngModel)]="fromDate" [max]="toDate" readonly (focus)="FromDatepicker.open()" (click)="FromDatepicker.open()" placeholder="From Date" class="field__input" dom-states #fromDateDOMStates="DOMStates">

                                    <div class="field__ui"></div>
                                </div>

                                <mat-datepicker #FromDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                            </div>
                        </div>

                        <div class="field-group__item   reports__period-field-group-input" [class.--active]="toDateDOMStates.isFocused">
                            <div class="field">
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <mat-datepicker-toggle [for]="ToDatepicker">
                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                            </mat-datepicker-toggle>
                                        </div>
                                    </div>

                                    <input [matDatepicker]="ToDatepicker" type="text" [(ngModel)]="toDate" [min]="fromDate" [max]="moment().startOf('day').format()" readonly (focus)="ToDatepicker.open()" (click)="ToDatepicker.open()" placeholder="To Date" class="field__input" dom-states #toDateDOMStates="DOMStates">

                                    <div class="field__ui"></div>
                                </div>

                                <mat-datepicker #ToDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid__block   reports__filters-grid-block --cta">
                    <button type="button" class="button" (click)="generateReport()">
                        Generate
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section">
    <div class="wrapper">
        <div class="block">
            <div class="grid --gutter">
                <div class="grid__block   w-3of12@xs">
                    <h2 class="reports__heading   heading --level-2 --partial-underline">
                        Results: 
                    </h2>
                </div>
            </div>
        </div>

        <div class="report-box">
            <app-spinner #reportResult_spinner></app-spinner>
            <div style="margin-bottom: 30px;">
                <app-spinner #fuelUsageGraph_spinner></app-spinner>
                <div [hidden]="!fuelUsageGraph" class="graph-chart">
                    <div class="graph-title">
                        Fuel Usage
                    </div>
                    <div class="graph-chart__panel" style="height: 450px;">
                        <!-- Insert chart here -->
                        <div style="height: 450px">
                            <canvas id="fuelUsageChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <app-spinner #fuelEfficiencyGraph_spinner></app-spinner>
                <div [hidden]="!fuelEfficiencyGraph" class="graph-chart">
                    <div class="graph-title">
                        Fuel Efficiency
                    </div>
                    <div class="graph-chart__panel" style="height: 450px;">
                        <!-- Insert chart here -->
                        <div style="height: 450px">
                            <canvas id="fuelEfficiencyChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>