import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {

    constructor(
        private toastController: ToastController
    ) { }

    async presentToast(message: string, duration: number = 3000) {
        const toast = await this.toastController.create({
            message,
            duration
        });
        return await toast.present();
    }

    async presentErrorToast(message: string, duration: number = 3000) {
        const toast = await this.toastController.create({
            message,
            position: 'top',
            duration,
            cssClass: 'toast-error'
        });
        return await toast.present();
    }

}