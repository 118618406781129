import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import * as StringUtil from './../../util/stringUtil';
@Injectable({
    providedIn: 'root'
})
export class ScoringService {

    constructor(private http: HttpClient) { }

    // Get Past Maintenance Report
    async getDriverScoring(driverIds, freqType, startWeekNo, endWeekNo, startYear, endYear, pageSize, startRecord, sortField, sortAscending, currentSearchBy, driverTagIds) {
        const path = environment.mtntApiEndpoint + "/report/scoring/driver";
        const body = {
            "driverIds": driverIds, //[1,2,3],
            "freqType": freqType, //"weekly",
            "onlyDriverTagScore": currentSearchBy == "driverTag" ? true : false,
            "driverTagIds": driverTagIds
        };
        if (Boolean(startWeekNo)) {
            body['startWeekNo'] = startWeekNo;
        }

        if (Boolean(endWeekNo)) {
            body['endWeekNo'] = endWeekNo;
        }

        if (Boolean(startYear)) {
            body['startYear'] = startYear;
        }

        if (Boolean(endYear)) {
            body['endYear'] = endYear;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    // Get Past Maintenance Report
    async getVehicleScoring(vehicleIds, freqType, startWeekNo, endWeekNo, startYear, endYear, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/scoring/vehicle";
        const body = {
            "vehicleIds": vehicleIds, //[1,2,3],
            "freqType": freqType, //"weekly",
        };
        if (Boolean(startWeekNo)) {
            body['startWeekNo'] = startWeekNo;
        }

        if (Boolean(endWeekNo)) {
            body['endWeekNo'] = endWeekNo;
        }

        if (Boolean(startYear)) {
            body['startYear'] = startYear;
        }

        if (Boolean(endYear)) {
            body['endYear'] = endYear;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    // Get Vehicle Ranking Report
    async getVehicleRankingReport(vehicleIds, startWeekNo, endWeekNo, startYear, endYear, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/vehicle-ranking";
        const body = {
            "vehicleIds": vehicleIds, //[1,2,3],
            "startDate": startDate,
            "endDate": endDate,
        };
        if (Boolean(startWeekNo)) {
            body['startWeekNo'] = startWeekNo;
        }

        if (Boolean(endWeekNo)) {
            body['endWeekNo'] = endWeekNo;
        }

        if (Boolean(startYear)) {
            body['startYear'] = startYear;
        }

        if (Boolean(endYear)) {
            body['endYear'] = endYear;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getDriverRankingReport(driverIds, startWeekNo, endWeekNo, startYear, endYear, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/driver-ranking";
        const body = {
            "driverIds": driverIds, //[1,2,3],
            "startDate": startDate,
            "endDate": endDate,
        };
        if (Boolean(startWeekNo)) {
            body['startWeekNo'] = startWeekNo;
        }

        if (Boolean(endWeekNo)) {
            body['endWeekNo'] = endWeekNo;
        }

        if (Boolean(startYear)) {
            body['startYear'] = startYear;
        }

        if (Boolean(endYear)) {
            body['endYear'] = endYear;
        }

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getScoringFormula() {
        const path = environment.mtntApiEndpoint + "/report/driver-ranking/formula";  
        const body = {};
        const result: any = await this.http.post(path, body).toPromise();
        return result;      
    }

}
