const sensitiveKeys = ['currentUser', 'administratorUser', 'sessionDetails'];

const LOCAL_STORAGE_NAME = 'mtntStorage';

export function localStorageSet(key: string, val: any) {
    let mtntStorage: any = getMtntStorage() || {};
    mtntStorage[key] = val;
    setMtntStorage(mtntStorage);
    // localStorage.setItem(key, JSON.stringify(val));
}

export function localStorageGet(key: string) {
    var result: any = null;
    let mtntStorage: any = getMtntStorage();
    if (mtntStorage && mtntStorage[key]) {
        // var result:any = JSON.parse(localStorage.getItem(key));
        result = mtntStorage[key];
        if (sensitiveKeys.indexOf(key) == -1) {
            delete mtntStorage[key];
            setMtntStorage(mtntStorage);
            // localStorage.removeItem(key);
        }
    }
    return result;
}

export function localStorageRemove(key: string = null) {
    let mtntStorage: any = getMtntStorage();
    if (!key) {
        //Delete all
        deleteMtntStorage();
        // localStorage.clear();
    } else if (key && mtntStorage && mtntStorage[key]) {
        //Delete specified key
        delete mtntStorage[key];
        setMtntStorage(mtntStorage);
        // localStorage.removeItem(key);
        return true;
    }
    return false;
}

function deleteMtntStorage() {
    localStorage.removeItem(LOCAL_STORAGE_NAME);
}

function setMtntStorage(mtntStorage) {
    localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(mtntStorage));
}

function getMtntStorage() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME));
}

export function getSessionDetails(key: string = null) {
    let sessionDetails = localStorageGet('sessionDetails');
    if (sessionDetails) {
        if (key) {
            return sessionDetails[key];
        }
        return sessionDetails;
    } else {
        localStorageSet('sessionDetails', {});
        return {};
    }
}

export function setSessionDetails(key: string, val: any) {
    let sessionDetails = getSessionDetails();
    if (key) {
        if (val == undefined) {
            delete sessionDetails[key];
        } else {
            sessionDetails[key] = val;
        }
        localStorageSet('sessionDetails', sessionDetails);
    }
}