/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Simple object check if empty.
 * @param obj
 * @returns {boolean}
 */
export function isEmpty(obj = null) {
    return (obj == null || (Object.keys(obj).length === 0 && obj.constructor === Object));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

/**
 * Fill integers into an array
 * @param start The starting integer to insert
 * @param end The ending integer to stop inserting
 */
export function fillRange(start: number, end: number = null): Array<number> {
    if (!end) {
        end = start - 1;
        start = 0;
    }
    return Array(end - start + 1).fill(0).map((item, index) => start + index);
}

/**
 * Returns true if all elements in the array is `false` after boolean type casting
 * @param array The array to check
 */
export function isArrayAllEmpty(array: any[]): boolean {
    return (!array.find((item: any) => item));
    // return true;
}
