import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import * as StringUtil from '../../util/stringUtil';
// import * as LocalStorageUtil from './../../util/localStorageUtil';

@Injectable({
    providedIn: 'root'
})
export class DriverService {

    constructor(private http: HttpClient) { }

    async getGroupDriverModelByCompany(sortField = null, sortAscending = null, hasVehicle = false, adminId = null, excludeDeactivated = true) {
        const param = "/drivers/group";

        const paramObj: any = {};
        if (StringUtil.isNotEmptyOrNull(sortField)) {
            paramObj.sortField = sortField;
        }
        if (typeof (sortAscending) == typeof (true)) {
            paramObj.sortAscending = sortAscending;
        }
        if (typeof (hasVehicle) == typeof (true)) {
            paramObj.hasVehicle = hasVehicle;
        }
        if (StringUtil.isNotEmptyOrNull(adminId)) {
            paramObj.adminId = adminId;
        }
        paramObj.excluDeact = excludeDeactivated;

        const path = environment.mtntApiEndpoint + param /* ?companyId=${companyId} */;
        const result: any = await this.http.get(path, {
            params: paramObj
        }).toPromise();
        return result;
    }

    async getDriverTagListByCompanyId(sortField = null, sortAscending = null, filterValue = "") {
        const param = "/driver-tag";

        const paramObj: any = {};
        if (StringUtil.isNotEmptyOrNull(sortField)) {
            paramObj.sortField = sortField;
        }
        if (typeof (sortAscending) == typeof (true)) {
            paramObj.sortAscending = sortAscending;
        }
        if (StringUtil.isNotEmptyOrNull(filterValue)) {
            paramObj.filterValue = filterValue;
        }

        const path = environment.mtntApiEndpoint + param /* ?companyId=${companyId} */;
        const result: any = await this.http.get(path, {
            params: paramObj
        }).toPromise();
        return result;
    }

    async getDriverTagListByCompanyIdAndUserId(driverId) {
        let param = "/drivers/driver-tag";
        if (driverId !== 0) {
            param += "/" + driverId;
        }

        const path = environment.mtntApiEndpoint + param /* ?companyId=${companyId} */;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getDrivers(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterValue = undefined) {

        // let param = `pageSize=${pageRecordSize}&startRecord=${startRecord}&sortField=${sortField}&sortAscending=${sortAscending}`;
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/users/drivers${StringUtil.isNotEmptyOrNull(param) ? `?${param}` : ''}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getDriverDetails(userId) {
        const path = environment.mtntApiEndpoint + `/users/drivers/${userId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async deleteDrivers(driverIdList) {
        const body: any = { body: { userIds: driverIdList } };
        const path = environment.mtntApiEndpoint + `/users/drivers`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async updateDriverDetails(driverObj) {

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(driverObj.firstName)) {
            driverObj.firstName = driverObj.firstName.trim();
        }
        if (StringUtil.isNotEmptyOrNull(driverObj.lastName)) {
            driverObj.lastName = driverObj.lastName.trim();
        }

        if ((driverObj.drivingLicenseExpiryDate) == null) {
            driverObj.drivingLicenseExpiryDate = "";
        }

        const path = environment.mtntApiEndpoint + `/users/drivers/${driverObj.userId}`;
        const body = driverObj;

        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    async createDriver(driverObj) {
        // trim leading & trailing whitespace for names
        driverObj.map(eachEntry => {
            if (StringUtil.isNotEmptyOrNull(eachEntry.firstName)) {
                eachEntry.firstName = eachEntry.firstName.trim();
            }
            if (StringUtil.isNotEmptyOrNull(eachEntry.lastName)) {
                eachEntry.lastName = eachEntry.lastName.trim();
            }
        })

        const path = environment.mtntApiEndpoint + `/users/drivers/create`;
        const body = driverObj;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async getDriverScoring(userId = null, weekNo = null, year = null, noOfWeek = 2, ) {
        const weekNoStr = Boolean(weekNo) ? weekNo : "";
        const yearStr = Boolean(year) ? year : "";

        let path = environment.mtntApiEndpoint + `/users/drivers/performance/scoring/${userId}/?weekNo=${weekNoStr}`;
        path += `&year=${yearStr}`;
        path += `&noOfWeek=${noOfWeek}`;
        const result: any = await this.http.get(path).toPromise();
        return result;

    }

    async getDriverPerformance(userId = null, noOfWeek = 2, endDate = "") {

        let path = environment.mtntApiEndpoint + `/users/drivers/performance/${userId}/?noOfWeek=${noOfWeek}`;
        path += `&endDate=${endDate}`;

        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async tieDriverTagToDriver(driverId, driverTagId) {
        const path = environment.mtntApiEndpoint + `/driver-tag/tie`;
        const body = {
            driverId: driverId,
            driverTagId: driverTagId
        };

        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async immobiliseOrStartVehicle(reqObj) {
        const path = environment.mtntApiEndpoint + `/vehicles/immobilise`;
        const body = reqObj;

        const result: any = await this.http.post(path, body).toPromise();
        return result;

    }

    async getDriverWithoutDriverTag() {
		const path = environment.mtntApiEndpoint + "/users/drivers-no-tag";

		const result: any = await this.http.get(path).toPromise();
        return result;
	}

    async getDriversAndAssignmentToVehicle(vehicleId: number, getAll: boolean) {
        let param = '';
        param += vehicleId ? `vehicleId=${vehicleId}` : '';
        param += getAll ? `&getAll=${getAll}` : '';

        const path = environment.mtntApiEndpoint + `/users/drivers/ibutton${StringUtil.isNotEmptyOrNull(param) ? `?${param}` : ''}`;
        console.log("patj", path)
        const result: any = await this.http.get(path).toPromise();
        return result;
    }
}
