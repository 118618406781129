import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { environment } from './../../../../../environments/environment';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from '../../../common/spinner/spinner.component';

// Service
import { VehicleService } from '../../../../_services/vehicle/vehicle.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { UserService } from '../../../../_services/user/user.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';

// Util
import * as AsyncUtil from './../../../../util/asyncUtil';
import * as DomUtil from './../../../../util/domUtil';
import * as LocalStorageUtil from '../../../../util/localStorageUtil';
import * as MapUtil from '../../../../util/mapUtil';

// Constant
import { ERROR_MESSAGE as ErrorMessage } from './../../../../constants/errorMessage';
import { modules } from '../../../../constants/module-access.constant';
import { overlayConstant } from './../../../../constants/overlay-image-map.constant';

declare var google: any;

@Component({
    selector: 'app-vehicle-panel-driving-limits',
    templateUrl: './driving-limits.component.html',
    styleUrls: ['./driving-limits.component.scss']
})
export class VehicleDrivingLimitsComponent implements OnInit, OnDestroy {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("general_spinner",{static:false}) generalSpinner: SpinnerComponent;
    @ViewChild("geofence_spinner",{static:false}) geofenceSpinner: SpinnerComponent;
    @Input('vehiclePanel') vehiclePanel: any;

    private _init: boolean = false;

    @Input()
    set init(init: boolean) {
        if (!init) {
            return;
        }
        if (!this._init) {
            this._init = true;
            this.triggerInit();
        }
    }

    get init(): boolean {
        return this._init;
    }

    MY_LOCATION_LAT: number = 3.158; //Menara Maxis
    MY_LOCATION_LNG: number = 101.713; //Menara Maxis
    map: google.maps.Map;
    mapContent: any;
    view: string = 'overview';
    vehicleId: number = 0;
    vehicleName: string = '';
    geofenceId: number = 0;
    geofenceName: string = '';
    path: string = '';
    geofenceRadius: number;
    geofenceType: string;

    // Geofence Type
    polygon: string = 'polygon';
    circle: string = 'circle';

    sortField = 'Name';
    sortAscending = true;
    filterKey = 'Name';
    filterValue = '';
    currentFilterValue = '';

    vehicleGeofence: any = 0;
    createManageDrivingLimitsPath = '/create-manage/driving-limits/view';

    //Input (requests)
    pageRecordSize = environment.appConfig.createManage.pageRecordSize;
    startRecord: number = 1;
    // searchViolationHistory: boolean = false;

    /* vehicle general driving limit */
    allowedDriveTimeRange: string = '';
    vehicleDrivingLimit: any = {
        vehicleId: null,
        vehicleName: null,
        speedLimit: null,
        dailyMileageLimit: null,
        allowedTimeFrom: null,
        allowedTimeTo: null
    };

    /* vehicle geofence */
    vehicleGeofenceList: any = [];

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    editable: boolean = false;

    constructor(
        private platform: Platform,
        private vehicleService: VehicleService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        public el: ElementRef,
        private userService: UserService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService

    ) { }

    async ngOnInit() {
        //move to triggerInit()
    }

    async triggerInit() {

        this.mapContent = document.getElementById('map_drivingLimit');
        await this.loadScriptFirst();
        await this.checkUnviewedCampaign();
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_DRIVING_LIMIT.value);
        this.editable = permissions[modules.CRUD_DRIVING_LIMIT.value].eAccess;
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async ngOnDestroy() {
    }

    async loadScriptFirst() {
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        // console.debug('DrivingLimitsComponent: hasGoogleMapScript()');
        if (!DomUtil.hasGoogleMapScript(null, GOOGLE_MAP_API_KEY)) {

            DomUtil.addGoogleMapScript({
                onload: this.finishScriptLoad.bind(this)
            }, GOOGLE_MAP_API_KEY);

        } else {
            console.debug('DrivingLimitsComponent: (Geofence)Skip adding Google Map Script Tag ...');
            if (DomUtil.isGoogleNotDefined()) {
                await AsyncUtil.wait(300); // wait for google object ready
            }
            this.finishScriptLoad();
        }
    }

    finishScriptLoad() {
        this.initMap();
    }

    /* MAP INITIALIZATION */
    async initMap() {
        // console.debug('DrivingLimitsComponent: (Geofence)Initialise new Google Map...');

        if (!DomUtil.isGoogleNotDefined()) {

            const mapZoom = 17;
            const pathCoords = [];

            google.maps.Polygon.prototype.getBounds = function () {
                const bounds = new google.maps.LatLngBounds();
                this.getPath().forEach(function (element, index) { bounds.extend(element); });
                return bounds;
            };

            const map = new google.maps.Map(this.mapContent, {
                center: { lat: + this.MY_LOCATION_LAT, lng: + this.MY_LOCATION_LNG },
                zoom: mapZoom
            });

            // Setting for Polygon / Circle
            const defaultSetting = {
                clickable: false,
                editable: false,
                draggable: false,
                strokeColor: '#DC143C',
                strokeWeight: 3,
                fillColor: '#FFC0CB',
                fillOpacity: 0.5
            };
            const geoType = this.geofenceType === 'P' ? this.polygon : this.circle;
            let areaType;

            if (geoType === this.polygon) {
                const dbGeoFencePathList = this.path.split(';');
                dbGeoFencePathList.forEach(function (dbGeoFencePath) {
                    if (dbGeoFencePath) {
                        const latLng = dbGeoFencePath.split(',');
                        pathCoords.push(new google.maps.LatLng(Number(latLng[0]), Number(latLng[1])));
                    }
                });
                areaType = new google.maps.Polygon(Object.assign(defaultSetting, { paths: pathCoords }));

            } else {
                const latLng = this.path.split(',');
                pathCoords.push(new google.maps.LatLng(Number(latLng[0]), Number(latLng[1])));
                areaType = new google.maps.Circle(Object.assign(defaultSetting, {
                    center: pathCoords[0],
                    radius: this.geofenceRadius
                }));
            }

            areaType.setMap(map);
            if (areaType.getBounds()) {
                const centerPoints = areaType.getBounds().getCenter();
                map.setCenter(new google.maps.LatLng(centerPoints.lat(), centerPoints.lng()));
                map.fitBounds(areaType.getBounds());
            }

            const result = await this.userService.getCompanyIdByName('Gamuda Cove');
            if (result && result.body.length > 0) {
                const currentUserCompanyId = LocalStorageUtil.localStorageGet('currentUser').companyId;
                if (result.body[0].id === currentUserCompanyId) {
                    MapUtil.generateOverlayImg(overlayConstant.imageLoc, overlayConstant.imagePath, map);
                }
            }

        } else {
            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.MAP_LOAD_FAILED);
            this.snackBar.openGenericSnackBar(msg);
        }
    }

    async getVehicleDrivingLimits() {
        //console.debug("VehiclePanel -> DrivingLimitComponent: init drivingLimit & geofence");
        //get General Limits
        // await this.getVehicleGeneralLimits();
        await this.getVehicleDrivingLimitById();
        //get Geofence
        await this.getVehicleGeofence();
    }

    async getVehicleDrivingLimitById() {
        this.generalSpinner.show();
        try {
            const vehicleResult = await this.vehicleService.getVehicleDrivingLimitById(this.vehicleId);
            this.vehicleDrivingLimit = vehicleResult.body;
            if (this.vehicleDrivingLimit) {
                this.allowedDriveTimeRange = `${moment(this.vehicleDrivingLimit.allowedTimeFrom, "HH:mm").format("hh:mm A")} - ${moment(this.vehicleDrivingLimit.allowedTimeTo, "HH:mm").format("hh:mm A")}`;
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.generalSpinner.hide();
        }
    }

    async getVehicleGeofence(page: number = 1) {
        //console.debug("SHOWING SPINNER FROM getVehicleGeofence()")
        this.geofenceSpinner.show();
        try {
            //Get Vehicle Geofence
            const geofenceResult = await this.vehicleService.getVehicleGeofenceDetailsByVehicleId(this.vehicleId);
            this.vehicleGeofenceList = geofenceResult.body.result;
            if (this.vehicleGeofenceList.length > 0) {
                this.geofenceId = this.vehicleGeofenceList[0].Id;
                this.geofenceName = this.vehicleGeofenceList[0].Name;
                this.geofenceType = this.vehicleGeofenceList[0].Type;
                this.path = this.vehicleGeofenceList[0].Path;
                this.geofenceRadius = this.vehicleGeofenceList[0].GeofenceRadius;
                this.vehicleGeofence = this.geofenceId;
                this.initMap();
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.geofenceSpinner.hide();
            //console.debug("HIDING SPINNER FROM getVehicleGeofence()");
        }
    }

    vehicleGeofenceChange() {
        const geofenceDetails = this.vehicleGeofenceList.filter(result => result.Id === parseInt(this.vehicleGeofence));
        this.geofenceId = geofenceDetails[0].Id;
        this.geofenceName = geofenceDetails[0].Name;
        this.path = geofenceDetails[0].Path;
        this.geofenceType = geofenceDetails[0].Type;
        this.geofenceRadius = geofenceDetails[0].GeofenceRadius;
        this.vehicleGeofence = this.geofenceId;
        this.initMap();
    }

    navigateToViewGeneralDrivingLimitsByVehicleName() {
        this.routerProxyService.navigate([this.createManageDrivingLimitsPath], {
            queryParams: {
                autoSearch: true,
                tab: 'general',
                geofenceName: this.geofenceName,
                vehicleName: this.vehicleName
            }
        });
    }

    navigateToViewGeofenceDrivingLimitsByGeofenceName() {
        this.routerProxyService.navigate([this.createManageDrivingLimitsPath], {
            queryParams: {
                autoSearch: true,
                tab: 'geofence',
                geofenceName: this.geofenceName,
                vehicleName: this.vehicleName
            }
        });
    }
}
