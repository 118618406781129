import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Platform } from '@ionic/angular';
import * as PolylineStyle from './../../../../../constants/polyline-properties';
import { Chart } from 'chart.js';

// Service
import { SnackBarService } from '../../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from '../../../../common/spinner/spinner.component';
import { PopupService } from '../../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../../_services/campaign/popup-campaign.service';

// Util
import * as AsyncUtil from './../../../../../util/asyncUtil';
import * as DomUtil from './../../../../../util/domUtil';
import * as MapUtil from '../../../../../util/mapUtil';
import * as LocalStorageUtil from '../../../../../util/localStorageUtil';
import * as NumberUtil from './../../../../../util/numberUtil';
import * as StringUtil from './../../../../../util/stringUtil';

import { ERROR_MESSAGE as ErrorMessage } from './../../../../../constants/errorMessage';
import { overlayConstant } from './../../../../../constants/overlay-image-map.constant';

import { UserService } from './../../../../../_services/user/user.service';
import { TripsService } from 'src/app/_services/trips/trips.service';

import { DashboardColors as Colors } from './../../../dashboard/DashboardColors';
const CHART_TICK_LABEL_MAX_LENGTH: number = 5; // chartJS truncate: axis tick label
const CHART_TOOLTIP_MAX_LENGTH: number = 22; // chartJS truncate: tooltip title
@Component({
  selector: 'app-view-incident-analysis',
  templateUrl: './view-incident-analysis.component.html',
  styleUrls: ['./view-incident-analysis.component.scss'],
  animations: [
      trigger('fadeToggle', [
          transition(':enter', [
              style({ opacity: 0 }),
              animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
          ]),
          transition(':leave', [
              style({ opacity: 1 }),
              animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
          ])
      ])
  ]
})
export class ViewIncidentAnalysisComponent implements OnInit {
  @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

 

  incidentDetails: any = {};
  startIncidentDetails: any ={};
  endIncidentDetails: any ={};
  incidentId: number;
  tripDetailResult: any ={};

  hasUnviewedCamapaign: boolean = false;
  unviewedCampaigns: Array<any> = [];

  error = '';

  //map
  private map: google.maps.Map;
  private alertMarkerTemplate: any = '';
  mapContent: any;
  legendContent: any;
  mapModel: {
    coordinates: Array<any>,
    mapCoordinates: Array<any>,
    useSnapData: any,
    eachSnapDataViolationIndex: Array<any>,
    tripRoute: Array<any>,
    currentItems: Array<any>,
    itemMarkers: Array<any>,
    currentItem: any,
    // currentItemIsActive: boolean,
    showMapLegend: boolean,
    isOnTrafficLayer: boolean,
    showTrafficLayerIcon: boolean,
    isOnAutoUpdate: boolean,
    showAutoUpdateIcon: boolean,
    visibleComponent: any,
    focusedMarker: any,
    isRefresh: boolean
};
defaultMapModel: any = {
    coordinates: [], //for route raw coordinates when user clicks on certain trip/vehicle detail
    mapCoordinates: [], //for route coordinates that return from snap to road (google road API)
    useSnapData: [], //details retrieved from dynamodb when user click tripDayItem, but the location is updated by Snap to Road
    eachSnapDataViolationIndex: [], //keep track which snap to road records consist violations
    tripRoute: [], //for trip polylines
    currentItems: [], //for raw marker items
    itemMarkers: [], //for map markers: vehicle location, trip origin/destination, trip alerts
    currentItem: null,
    // currentItemIsActive: false,
    showMapLegend: false,
    isOnTrafficLayer: false,
    showTrafficLayerIcon: true,
    isOnAutoUpdate: true,
    showAutoUpdateIcon: true,
    visibleComponent: null, //Component that currently interacting/projecting to Map (VehicleMain/MapList/...)
    focusedMarker: null, //IMEI of vehicle marker in focus
    isRefresh: false
};
tripDetailsInterpolated: any = [];
tripDetail: any = {
    data: {
        tId: null,
        tripDet: []
    }, // details retrieved from dynamodb when user click tripDayItem
    add: {
        sAdd: null,
        sTime: null,
        eAdd: null,
        eTime: null
    }
};
defaultPolyLineIconProps: any;
  MY_LOCATION_LAT: number = 3.158; //Menara Maxis
  MY_LOCATION_LNG: number = 101.713; //Menara Maxis
 //map polyline
 Popup: any;
 visiblePopUp: any;


    //chart
    //graph
    chartJsBarHoverInteraction: any;
    chartJsTooltipStyles: any;
    chartJsHoverProps: any;
    chartJsTicksFontStyles: any;
    chartJsTicksStringEllipsisCallbackFn: any;
    chartJsTicksNumberTruncateCallbackFn: any;
    chartJsTooltipTruncateCallbackTitleFn: any;
    chartJsAnimation: any;
    //overrideChartJsOptions
    chartJsScaleLabelFontStyles: any;
    speedingGraph;
    isChart = 1;

    
  constructor(
      private platform: Platform,
      private userService: UserService,
      private tripsService: TripsService,
      private route: ActivatedRoute,
      private popupService: PopupService,
      private snackBar: SnackBarService,
      private routerProxyService: RouterProxyService,
      private popupCampaignService: PopupCampaignService,
      private el: ElementRef,

  ) {
    
    this.mapModel = Object.create(this.defaultMapModel);
   }

  async ngOnInit() {
    this.pageSpinner.show();
    try {
        this.incidentId = this.route.snapshot.params.id;
        await this.onInitCommonFunction();
        this.alertMarkerTemplate = document.getElementById('alertMarkerTemplate');
        this.mapContent = document.getElementById('map');
        this.legendContent = document.getElementById('legend');
        await this.loadScriptFirst();
        this.initChartJsDefaults();
    } catch (e) {
        this.snackBar.openStandardizedErrorSnackBar(e);
    } finally {
        this.pageSpinner.hide();
    }

      await this.checkUnviewedCampaign();
  }


  async checkUnviewedCampaign() {
      try {
          const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
          this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
          this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
      } catch (e) {
          this.snackBar.openStandardizedErrorSnackBar(e);
      }
  }

  async onInitCommonFunction() {
    await this.getIncidentDetails(this.incidentId);
  }
      async loadScriptFirst() {
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        if (!DomUtil.hasGoogleMapScript(null, GOOGLE_MAP_API_KEY)) {

            DomUtil.addGoogleMapScript({
                onload: this.finishScriptLoad.bind(this)
            }, GOOGLE_MAP_API_KEY);

        }else {
            console.debug('CreateGeofenceComponent: (Geofence)Skip adding Google Map    Script Tag ...');
            if (DomUtil.isGoogleNotDefined()) {
                await AsyncUtil.wait(300); // wait for google object ready
            }
            this.finishScriptLoad();
        }
    }

    finishScriptLoad(): void {
        this.initMap();
    }
  async initMap() {
    if (!DomUtil.isGoogleNotDefined()) {
        this.map = new google.maps.Map(this.mapContent, {
            center: { lat: this.MY_LOCATION_LAT, lng: this.MY_LOCATION_LNG },
            gestureHandling: 'greedy',
            zoom: 17
        });
         this.definePopupClass();
         const mapp: google.maps.Map = this.map;

         //Set local storage variables.
         if (mapp != null && mapp != undefined) {
             const mapCentre = mapp.getCenter();
             this.cacheMapView(mapCentre.lat(), mapCentre.lng(), mapp.getZoom());
         }

         const _this = this;
         google.maps.event.addListener(mapp, "center_changed", function () {
             //Set local storage variables.
             if (mapp != null && mapp != undefined) {
                 const mapCentre = mapp.getCenter();
                 _this.cacheMapView(mapCentre.lat(), mapCentre.lng(), mapp.getZoom());
             }
         });

         const polylines = this.mapModel.tripRoute;
         google.maps.event.addListener(mapp, "zoom_changed", function () {
             //Set local storage variables.
             if (mapp != null && mapp != undefined) {
                 const mapCentre = mapp.getCenter();
                 _this.cacheMapView(mapCentre.lat(), mapCentre.lng(), mapp.getZoom());
                 // console.log("zoom: " + mapp.getZoom());
                 _this.adjustTripRouteStroke(mapp.getZoom(), polylines);
             }
         });

        const result = await this.userService.getCompanyIdByName('Gamuda Cove');
        if (result && result.body.length > 0) {
            const currentUserCompanyId = LocalStorageUtil.localStorageGet('currentUser').companyId;
            if (result.body[0].id === currentUserCompanyId) {
                MapUtil.generateOverlayImg(overlayConstant.imageLoc, overlayConstant.imagePath, this.map);
            }
        } 



        if(this.tripDetailResult.trip_det.tripDet.length > 0){

            //init legend on map
            this.initMapLegend();
            //plot line
            this.mapModel.eachSnapDataViolationIndex = [];
            const startTimeCounter = moment();
            const getFullTripBySplitCounter = moment();
            let tripDetailData = null;
            let tripDetailResult: any = null;
            tripDetailResult = this.tripDetailResult;
            const getFullTripBySplitCounterEnd = moment();
            const getFullTripBySplitCounterTimeElapsed = moment.duration    (getFullTripBySplitCounterEnd.diff(getFullTripBySplitCounter)).asSeconds();
            console.debug("Time Complete Get Past Trip: " +     getFullTripBySplitCounterTimeElapsed + 's');
            if (tripDetailResult) {
                tripDetailData = tripDetailResult.trip_det;
            } else {
                throw 'return';
            }

            if (tripDetailData.tripDet.length <= 0) {
                const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.    INFO_UNDER_PROCESSING);
                this.snackBar.openGenericSnackBar(msg);
                throw 'return';
            }

            this.tripDetail.data = tripDetailData;
            this.tripDetail.address = null;

            //Remove those coordinate is 0 for lat & lng
            this.tripDetail.data.tripDet = this.tripDetail.data.tripDet.filter(detail => {
                if (detail.coor.lat != 0 && detail.coor.lng != 0) {
                    return detail;
                }
            });

            //Group up detail info with identical coordinates
            this.tripDetail.data.tripDet = this.joinTripDetailInfoForSameCoordinates(this.  tripDetail.data.tripDet.slice(0));

            let newArrayFromTtlRecord = [];


            const checkGenLatLngByTtlRecord = moment();
            this.tripDetail.data.tripDet.map((eachDetail, index) => {
                if (index < this.tripDetail.data.tripDet.length - 1) {
                    const nextDetail = this.tripDetail.data.tripDet[index + 1];
                    // reduce number of sub latlng for polyline onClick popUp show speed
                    const reduceNumSubLatLngReq = MapUtil.ttlNumSubLatLngReq(this.  tripDetail.data.tripDet.length);
                    // define number of sub latlng require between 2 point
                    const numLatLngReq = MapUtil.numSubLatLngReq(eachDetail.coor,   nextDetail.coor, reduceNumSubLatLngReq);
                    // gen sub latlng point between 2 differnt point
                    newArrayFromTtlRecord = newArrayFromTtlRecord.concat(MapUtil.   genLatLngByTtlRecordRequired(eachDetail.coor, nextDetail.coor,     numLatLngReq.totalRecord, index));
                }
            });
            this.tripDetailsInterpolated = newArrayFromTtlRecord;


            const checkGenLatLngByTtlRecordEnd = moment();
            const checkGenLatLngByTtlRecordTimeElapsed = moment.duration    (checkGenLatLngByTtlRecordEnd.diff(checkGenLatLngByTtlRecord)).asSeconds();
            console.debug("Time Complete Gen Popup Sub LatLng Point: " +    checkGenLatLngByTtlRecordTimeElapsed + 's');
            this.mapModel.coordinates = this.tripDetail.data.tripDet.map(coord => {if (coord.vio) {
                return { coordinates: coord.coor, violations: coord.vio, speed: coord.spd};
            } else {
                return { coordinates: coord.coor, speed: coord.spd };
            }
            });
            /* DRAW ROUTE ON MAP */
            // Full route for Past Trip
            const bounds = new google.maps.LatLngBounds();


            // Speeding routes for Past Trip
            const speedingViolation: any = [[]];
            let spdRouteIndex: number = 0;
            speedingViolation[spdRouteIndex] = [];
            let speedingCount: number = 0;
            let foundSpeeding: boolean = false;

            // Normal route for Past Trip
            const normalRoute: any = [[]];
            let normalRouteIndex: number = 0;
            normalRoute[normalRouteIndex] = [];
            let normalRouteCount: number = 0;
            let isNormalRoute: boolean = false;

            /*
             * NOTE: snap to feature had been turn down since 2019-Jan-10
             *       so the code below might not support the latest version of the system
             */

                // This will be use when Snap to Road is not in used
                // Split route to plot different type of polyline
                for (const element of this.mapModel.coordinates) {
                    const violations = element.violations;
                    const coordinates = element.coordinates;
                    bounds.extend({ lat: coordinates.lat, lng: coordinates.lng });
                     if (violations && (violations.isSpd >= 1)) {
                         if (isNormalRoute) {
                            // Normal Route
                            normalRoute[normalRouteIndex].push({ lat: coordinates.lat,  lng: coordinates.lng });
                            normalRouteCount = 0;
                            normalRouteIndex++;
                            normalRoute[normalRouteIndex] = [];
                            isNormalRoute = false;
                        }
                        foundSpeeding = true;
                        speedingViolation[spdRouteIndex].push({ lat: coordinates.lat, lng:  coordinates.lng });
                        speedingCount++;
                    } else {
                        if (foundSpeeding) {
                            // Speeding
                            speedingViolation[spdRouteIndex].push({ lat: coordinates.lat,   lng: coordinates.lng });
                            speedingCount = 0;
                            spdRouteIndex++;
                            speedingViolation[spdRouteIndex] = [];
                            foundSpeeding = false;
                        }
                        isNormalRoute = true;
                        normalRoute[normalRouteIndex].push({ lat: coordinates.lat, lng:     coordinates.lng });
                        normalRouteCount++;
                    }
                }
            

            // find the longest array length
            let maxArrayIndex = normalRoute.length;
            if (maxArrayIndex <= speedingViolation.length) {
                maxArrayIndex = speedingViolation.length;
            }

            // For arrow icon
            const POLYLINE_ICON_PROPERTIES = {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 1,
                strokeColor: 'white',
                fillColor: 'white',
                fillOpacity: 1
            };
            this.defaultPolyLineIconProps = POLYLINE_ICON_PROPERTIES;

            for (let i = 0; i < maxArrayIndex; i++) {
                // normal routes
                if (i < normalRoute.length && normalRoute[i].length > 0) {
                    const normalRouteObj = {
                        routeList: normalRoute[i],
                        polyLineStyleArr: [
                            // border
                            { style: PolylineStyle.NORMAL_BORDER_PATH, icon: null,  iconScale: null },
                            // actual path
                            { style: PolylineStyle.NORMAL_PATH, icon: null, iconScale:  null },
                            // arrow icon
                            { style: PolylineStyle.ARROW_ICON, icon: [{ icon:   POLYLINE_ICON_PROPERTIES, repeat: "20px" }], iconScale: null }
                        ]
                    };
                    this.setPolyline(normalRouteObj);
                }
                // speeding routes
                if (i < speedingViolation.length && speedingViolation[i].length > 0) {
                    const normalRouteObj = {
                        routeList: speedingViolation[i],
                        polyLineStyleArr: [
                            // border
                            { style: PolylineStyle.SPD_BORDER_PATH, icon: null, iconScale:  null },
                            // actual path
                            { style: PolylineStyle.SPD_PATH, icon: null, iconScale: null },
                            // arrow icon
                            { style: PolylineStyle.ARROW_ICON, icon: [{ icon:   POLYLINE_ICON_PROPERTIES, repeat: "20px" }], iconScale: null }
                        ]
                    };
                    this.setPolyline(normalRouteObj);
                }
            }
            this.map.fitBounds(bounds);

            const endTimeCounter = moment();
            const timeElapsed = moment.duration(endTimeCounter.diff(startTimeCounter)). asSeconds();
            console.debug("Time Complete Get & Process & Show Past Trip: " + timeElapsed +  's');
            this.initTripMarker();
        }

        
    } else {
        const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.MAP_LOAD_FAILED);
        this.snackBar.openGenericSnackBar(msg);
    }

}

  /* ---- map component ---- */
  setPolyline(routeObj) {
    routeObj.polyLineStyleArr.map((eachStyle, index) => {
        const temp = PolylineStyle.setPath(this.map, eachStyle.style, routeObj.routeList, eachStyle.icon);
        const polylinePath = new google.maps.Polyline(temp);
        this.mapModel.tripRoute.push(polylinePath);

        if (index < routeObj.polyLineStyleArr.length) {
            this.addPolylineEventListener(polylinePath);
        }
    });
  }

  
    showPopUp(popped: any): void {
      this.closePopUp();
      this.visiblePopUp = popped;
    }

    closePopUp(): void {
      if (Boolean(this.visiblePopUp)) {
          this.visiblePopUp.setMap(null);
      }
    }

    addPolylineEventListener(googleMapsPolyline) {
    const makePopUp = this.Popup;
    const showPopUp = this.showPopUp.bind(this);
    const closePopUp = this.closePopUp.bind(this);
    const popUpContent = this.alertMarkerTemplate.cloneNode(true);
    const self = this;

    google.maps.event.addListener(googleMapsPolyline, 'click', function (e) {

        e.stop();
        let time = "---";
        let speed = "---";

        const tripDetailPopup = self.tripDetailsInterpolated;
        if (tripDetailPopup.length) {
            //get closest point
            let minDist = null;
            let closestIndex = -1;
            let tmpClosestIndex = 0;
            let tmpMinDist = null;
            tripDetailPopup.forEach((element, i) => {
                /*
                 * For Acceptance Level
                 * x.x      => 10km unit
                 * x.xx     => 1km unit,
                 * x.xxx    => 0.1km unit,
                 * x.xxxx   => 0.01km unit,
                 * x.xxxxx  => 0.001km unit;
                 */
                const acceptanceLevel = 0.0001;
                const elementLat = element.location.lat;
                const elementLng = element.location.lng;
                const selectedLat = e.latLng.lat();
                const selectedLng = e.latLng.lng();

                const dist = MapUtil.getDistanceFromLatLonInKm(
                    element.location.lat.toFixed(6),
                    element.location.lng.toFixed(6),
                    e.latLng.lat().toFixed(6),
                    e.latLng.lng().toFixed(6)
                );

                if (selectedLat >= elementLat - acceptanceLevel && selectedLat <= elementLat + acceptanceLevel
                    && selectedLng >= elementLng - acceptanceLevel && selectedLng <= elementLng + acceptanceLevel) {
                    // get a distance upto 1 meter scale

                    if (!minDist || dist < minDist) {
                        minDist = dist;
                        closestIndex = i;
                    }
                }

                //get alternate closest point if none matched for above function
                if (!tmpMinDist || dist < tmpMinDist) {
                    tmpMinDist = dist;
                    tmpClosestIndex = i;
                }
            });
            //use alternate closest point if closestIndex still remained -1
            if (closestIndex === -1) {
                closestIndex = tmpClosestIndex;
            }

            //Populate info for popup
            const closestDetail = self.tripDetail.data.tripDet[tripDetailPopup[closestIndex].index];
            time = Boolean(closestDetail.gts) ? moment(closestDetail.gts).format("hh:mm A") : "---";
            speed = Boolean(closestDetail.spd) ? parseInt(closestDetail.spd) + "km/h" : "---";
        } else {
            closePopUp(); //do not show popup
            return;
        }
        // Re-attach close button event
        popUpContent.childNodes[0].addEventListener("click", self.closePopUp.bind(self));
        //Pop-up Title
        popUpContent.childNodes[1].innerHTML = 'Route Info';
        //Speed
        popUpContent.childNodes[2].childNodes[0].lastChild.innerHTML = speed;
        //Time
        popUpContent.childNodes[2].childNodes[1].lastChild.innerHTML = time;
        //Address
        popUpContent.childNodes[3].style.display = 'none';

        const popped = new makePopUp(e.latLng, popUpContent);
        // const popped = new makePopUp(e.latLng, null);
        popped.setMap(self.map);
        showPopUp(popped);
    });
    }
      /** Defines the Popup class. */
    definePopupClass(): void {
        if (!this.map) {
            return;
        }
        /**
         * A customized popup on the map.
         * @param {!google.maps.LatLng} position
         * @param {!Element} content
         * @constructor
         * @extends {google.maps.OverlayView}
         */
        this.Popup = function (position, content) {
            this.position = position;
            content.classList.add('popup-bubble-content');

            const pixelOffset = document.createElement('div');
            pixelOffset.classList.add('popup-bubble-anchor');
            pixelOffset.appendChild(content);

            this.anchor = document.createElement('div');
            this.anchor.classList.add('popup-tip-anchor');
            this.anchor.appendChild(pixelOffset);

            // Optionally stop clicks, etc., from bubbling up to the map.
            // this.stopEventPropagation();
        };

        // NOTE: google.maps.OverlayView is only defined once the Maps API has
        // loaded. That is why Popup is defined inside initMap().
        this.Popup.prototype = Object.create(google.maps.OverlayView.prototype);

        /** Called when the popup is added to the map. */
        this.Popup.prototype.onAdd = function () {
            this.getPanes().floatPane.appendChild(this.anchor);
        };

        /** Called when the popup is removed from the map. */
        this.Popup.prototype.onRemove = function () {
            if (this.anchor.parentElement) {
                this.anchor.parentElement.removeChild(this.anchor);
            }
        };

        /** Called when the popup needs to draw itself. */
        this.Popup.prototype.draw = function () {
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            // Hide the popup when it is far out of view.
            const display =
                Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
                    'block' :
                    'none';

            if (display === 'block') {
                this.anchor.style.left = divPosition.x + 'px';
                this.anchor.style.top = divPosition.y + 'px';
            }
            if (this.anchor.style.display !== display) {
                this.anchor.style.display = display;
            }
        };

    }

    joinTripDetailInfoForSameCoordinates(tripDetailList) {
        let startDetail = null;
        let stoppedDetail = null;
        const joinedTripDetailList = [];
        if (tripDetailList.length > 0) {
            let joinDetail = null;
            // Incase more than 1 trip detail's status = START
            let startFlag = false;
            tripDetailList.filter((detail, index) => {
                if (detail.tripSt == 'START' && !startFlag) {
                    startDetail = detail;
                    startFlag = true;
                }
                if (detail.tripSt == 'STOPPED') {
                    stoppedDetail = detail;
                }

                if (joinDetail === null) {
                    // 1st detail
                    joinDetail = Object.assign({}, { trip: detail, index: index });

                } else {
                    const lastDetailCoordinates = joinDetail.trip.coor;
                    const currDetailCoordinates = detail.coor;

                    if (lastDetailCoordinates.lat == currDetailCoordinates.lat && lastDetailCoordinates.lng == currDetailCoordinates.lng) {
                        // lat & lng same as previous
                        const tempViolations = Object.assign({}, joinDetail.trip.vio, detail.vio);
                        joinDetail.trip.vio = Object.assign({}, tempViolations);

                    } else {
                        // lat & lng not same as previous
                        joinedTripDetailList.push(joinDetail.trip);
                        joinDetail = Object.assign({}, { trip: detail, index: index });
                    }
                }
                //to ensure last verification record always pushed
                if (index == tripDetailList.length - 1) {
                    joinedTripDetailList.push(joinDetail.trip);
                }
            });
        }
        // Incase tripStatus = start/stopped could not be found
        startDetail = startDetail === null ? tripDetailList[0] : startDetail;
        stoppedDetail = stoppedDetail === null ? tripDetailList[tripDetailList.length - 1] : stoppedDetail;

        const firstJoinedTripDetail = joinedTripDetailList[0];
        if (startDetail.coor.lat == firstJoinedTripDetail.coor.lat && startDetail.coor.lng == firstJoinedTripDetail.coor.lng) {
            startDetail.vio = Object.assign({}, firstJoinedTripDetail.vio);
            joinedTripDetailList[0] = startDetail;
        } else {
            const temp = [startDetail];
            joinedTripDetailList[0] = temp[0];
        }

        const lastJoinedTripDetail = joinedTripDetailList[joinedTripDetailList.length - 1];
        if (stoppedDetail.coor.lat == lastJoinedTripDetail.coor.lat && stoppedDetail.coor.lng == lastJoinedTripDetail.coor.lng) {
            stoppedDetail.vio = Object.assign({}, lastJoinedTripDetail.vio);
            joinedTripDetailList[joinedTripDetailList.length - 1] = stoppedDetail;
        } else {
            joinedTripDetailList.push(stoppedDetail);
        }
        return joinedTripDetailList;
    }

    initMapLegend(): void {
        if (!this.map) {
            return;
        }
        if (this.map.controls) {

            const legend = this.legendContent;
            if (!Boolean(legend)) {
                alert('missing HTML content - legend');
                return;
            }

            const index = this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].getArray().indexOf(legend);
            const controlArray = Array(this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM]);
            controlArray.splice(index, 1);

                legend.style.display = "block";
            controlArray.push(legend);

            this.pushMapControls('LEFT_BOTTOM', controlArray);
        }
    }

    pushMapControls(position, controls): void {
        if (!this.map) {
            return;
        }
        const controlSpace = this.map.controls[google.maps.ControlPosition[position]];
        while (controlSpace.length > 0) {
            controlSpace.pop();
        }
        for (const c of controls) {
            controlSpace.push(c);
        }
    }

    //when zoom in/out the stroke width/weight 
    adjustTripRouteStroke(zoomLevel: any, polylines: Array<google.maps.Polyline>): void {

        //adjust route stroke weight
        if (polylines.length >= 3) {
            let zoomed_stroke = zoomLevel - 4;

            if (zoomLevel > 21) {
                zoomed_stroke += 7;
            } else if (zoomLevel > 19) {
                zoomed_stroke += 6;
            } else if (zoomLevel > 18) {
                zoomed_stroke -= 2;
            } else {
                zoomed_stroke -= 5;
            }

            let repeat_px = zoomLevel;


            let iconScale = 5;
            if (zoomLevel > 21) {
                iconScale = 17;
                repeat_px = zoomLevel + 10 + (repeat_px - 10) * 3;
            } else if (zoomLevel > 19) {
                iconScale = 15;
                repeat_px = zoomLevel + 10 + (repeat_px - 10) * 3;
            } else if (zoomLevel > 18) {
                iconScale = 13;
                repeat_px = zoomLevel + 5 + (repeat_px - 10) * 3;
            } else if (zoomLevel > 15) { // 16 17 18
                iconScale = 11;
                repeat_px = (repeat_px * 2) + 5;
            } else if (zoomLevel > 13) { //14 15
                iconScale = 7;
                repeat_px = (repeat_px * 2) + 3;
            } else if (zoomLevel > 12) { //13
                iconScale = zoomLevel - 10;
                repeat_px = (repeat_px * 2) - 10;
            } else {
                iconScale = 2;
                repeat_px += 6;
            }
            // }

            // min stroke = 1
            if (zoomed_stroke <= 0) {
                zoomed_stroke = 1;
            }

            for (const element of polylines) {
                const eachPolyline: any = element;
                // actual path's border
                if (eachPolyline.type == "BORDER") {
                    eachPolyline.setOptions({ strokeWeight: zoomed_stroke + 3 });
                    if (eachPolyline.icons) {
                        const icons = eachPolyline.icons[0];
                        icons.icon.strokeWeight = zoomed_stroke + 3;
                        icons.icon.scale = iconScale;
                        icons.repeat = repeat_px + "px";
                        eachPolyline.setOptions({ icons: [icons] });
                    }
                }
                // actual path
                if (eachPolyline.type == "PATH") {
                    eachPolyline.setOptions({ strokeWeight: zoomed_stroke });
                    if (eachPolyline.icons) {
                        const icons = eachPolyline.icons[0];
                        icons.icon.scale = iconScale;
                        icons.icon.strokeWeight = zoomed_stroke;
                        icons.repeat = repeat_px + "px";
                        eachPolyline.setOptions({ icons: [icons] });
                    }
                }
                // arrow icon
                if (eachPolyline.type == "ICON") {
                    eachPolyline.setOptions({ strokeWeight: zoomed_stroke });
                }
            }

            if (Boolean(this.defaultPolyLineIconProps)) {
                const iconProps = this.defaultPolyLineIconProps;
                iconProps.scale = zoomLevel / 16; //use zoom level = 16 to calibrate
                if (zoomLevel <= 13) {
                    iconProps.scale = 0; //hide arrow icon if zoom out too far
                } else if (zoomLevel <= 13) {
                    iconProps.scale -= 0.2;
                } else if (zoomLevel >= 19) {
                    iconProps.scale += (zoomLevel - 16) * 0.1;
                }
                const iconSetting = [{ icon: iconProps, repeat: "18px" }];
                polylines[polylines.length - 1].setOptions({ icons: iconSetting });
            }

        }
    }
    
    cacheMapView(lat, lng, zoom): void {
        LocalStorageUtil.localStorageSet('mapLat', lat);
        LocalStorageUtil.localStorageSet('mapLng', lng);
        LocalStorageUtil.localStorageSet('mapZoom', zoom);
    }
    async initTripMarker(): Promise<void> {
        if (!this.map) {
            return;
        }
        // console.debug('MapComponent: initTripMarker()');
        try {
            if (this.tripDetail.data.tripDet && this.tripDetail.data.tripDet.length <= 0) {
                const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.INFO_LOAD_FAILED);
                this.snackBar.openGenericSnackBar(msg);
                throw 'return';
            }

            let violatedTrips = [];
            let isSpeedingFlag = false;

            /*
            * NOTE: snap to feature had been turn down since 1/10/2019
            *       so the code below might not support the latest version of the system
            */
            
                //snap to road is not use
                const tripDetails: any = this.tripDetail.data.tripDet.slice(0);
                //Always grab the 1st record for starting marker
                if (tripDetails.length > 0) {
                    if (tripDetails[0].vio != undefined || tripDetails[0].vio == undefined) {
                        violatedTrips.push(tripDetails[0]);
                    }
                    
                    //Always grab the last record for ending marker
                    if (tripDetails[tripDetails.length - 1].vio == undefined
                        || tripDetails[tripDetails.length - 1].vio != undefined) {
                        violatedTrips.push(tripDetails[tripDetails.length - 1]);
                    }
                }
            
            let formatType = '.png';
            let width = 27;
            let height = 38;
            

            for (let i = 0; i < violatedTrips.length; i++) {
                if (i == 0 || i == violatedTrips.length - 1) {
                    //process start/end markers
                    let title: string;
                    let markerImage: { url: string, scaledSize: google.maps.Size };
                    if (i === 0) {
                        title = 'Trip Start';
                        markerImage = {
                            url: '../assets/images/ng-components/past-trip/start' + formatType,
                            scaledSize: new google.maps.Size(width * 1.35, height * 1.35)
                        };
                    } else {
                        title = 'Trip End';
                        markerImage = {
                            url: '../assets/images/ng-components/past-trip/stop' + formatType,
                            scaledSize: new google.maps.Size(width * 1.35, height * 1.35)
                        };
                    }

                    //add marker to map
                    const markerPopup = new google.maps.Marker({//define marker for start of the trip and end of the trip
                        position: new google.maps.LatLng(violatedTrips[i].coor.lat, violatedTrips[i].coor.lng),
                        map: this.map,
                        icon: markerImage,
                        // zIndex: violatedTrips.length + 1 //let start and stop always on top
                        zIndex: 0 //let start and stop awalys at behind of violation
                    });
                    this.mapModel.itemMarkers.push(markerPopup);

                    //prepare marker popup from template
                    const makePopUp = this.Popup;
                    const showPopUp = this.showPopUp.bind(this);
                    const popUpContent = this.alertMarkerTemplate.cloneNode(true);

                    const time = Boolean(violatedTrips[i].gts) ? moment(violatedTrips[i].gts).format("hh:mm A") : "---";
                    const speed = Boolean(violatedTrips[i].spd) ? parseInt(violatedTrips[i].spd) + "km/h" : "---";

                    let address = '';
                    if (i == 0) {
                        if (this.tripDetail.address) {
                            address = Boolean(this.tripDetail.address.sAdd) ? this.tripDetail.address.sAdd : "";
                        }/*  else {
                            address = Boolean(violatedTrips[i].coordinates.address) ? violatedTrips[i].coordinates.address : "";
                        } */
                    } else {
                        if (this.tripDetail.address) {
                            address = Boolean(this.tripDetail.address.eAdd) ? this.tripDetail.address.eAdd : "";
                        }/*  else {
                            address = Boolean(violatedTrips[i].coordinates.address) ? violatedTrips[i].coordinates.address : "";
                        } */
                    }
                    //Re-attach close button event
                    popUpContent.childNodes[0].addEventListener("click", this.closePopUp.bind(this));
                    //Pop-up Title
                    popUpContent.childNodes[1].innerHTML = title;
                    //Speed
                    popUpContent.childNodes[2].childNodes[0].lastChild.innerHTML = speed;
                    //Time
                    popUpContent.childNodes[2].childNodes[1].lastChild.innerHTML = time;
                    //Address
                    if (address != '') {
                        popUpContent.childNodes[3].innerHTML = address;
                    } else {
                        popUpContent.childNodes[3].style.display = 'none';
                    }

                    google.maps.event.addListener(markerPopup, 'click', (function (markerPopup, i) {
                        return function () {
                            const popped = new makePopUp(markerPopup.getPosition(), popUpContent);
                            popped.setMap(this.map);
                            showPopUp(popped);
                        };
                    })(markerPopup, i));
                } else if (violatedTrips[i].vio) {
                    //process alert markers
                    if ((violatedTrips[i].vio.isSpd && violatedTrips[i].vio.isSpd == "isRepeated")) {
                        const temp = violatedTrips[i].vio;
                        delete temp['isSpd'];
                        violatedTrips[i].vio = temp;
                    }
                    
                }
            }



        } catch (err) {
            // this.snackBar.openStandardizedErrorSnackBar(err);
            if (err != 'return') {
                this.snackBar.openStandardizedErrorSnackBar(err);
            }
        } finally {
            console.debug('HIDING SPINNER FROM initTripMarker()');
        }
    }

  /* ---- speed chart ---- */
  initChartJsDefaults() {
    Chart.defaults.global.legend.display = false;
    this.chartJsHoverProps = {
      animationDuration: 300,
      onHover: function (e: MouseEvent) {
        const point = this.getElementAtEvent(e);
        if (point.length) {
          (<HTMLElement>e.target).style.cursor = 'pointer';
        } else {
          (<HTMLElement>e.target).style.cursor = 'default';
        }
      }
    };
    this.chartJsAnimation = {
      easing: "easeInOutCubic"
    };
    this.chartJsTooltipStyles = {
      titleAlign: 'center',
      bodyAlign: 'center',
      yPadding: 8,
      xPadding: 10
    };
    this.chartJsBarHoverInteraction = {
      hoverBorderColor: Colors.RGBA_WHITE_SHADE,
      hoverBorderWidth: 2
    };
    this.chartJsScaleLabelFontStyles = {
      fontStyle: "bold",
      fontColor: Colors.RGBA_BLACK,
      fontSize: 13
    };
    this.chartJsTicksFontStyles = {
      fontColor: Colors.RGBA_BLACK,
      fontSize: 12
    };
    this.chartJsTicksNumberTruncateCallbackFn = function (value) {
      if (value >= 1000000) {
        return NumberUtil.roundOff(value / 1000000, 1) + 'mil';
      } else if (value >= 10000) {
        return NumberUtil.roundOff(value / 1000, 1) + 'k';
      }
      return value;
    };
    this.chartJsTicksStringEllipsisCallbackFn = function (value, index, values) {
      // truncate name of ticks in axis
      return StringUtil.truncateText(value, CHART_TICK_LABEL_MAX_LENGTH, 'N/A');
    };
    this.chartJsTooltipTruncateCallbackTitleFn = function (tooltipItem, cdata) {
      // truncate for tooltip
      const label = cdata.labels[tooltipItem[0].index];
      return StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
    };
  }
  async getSpeedingGraph(resultList) {
    const linearGradient = this.makeLinearCanvasGradient('speedingChart', Colors.RGBA_PRI1_SHARP_GREEN);

    const label = resultList.map(({ gts }) => gts);
    const data = resultList.map(({ spd }) => spd);
    const cOptions = {
      type: 'line',
      data: {
        labels: label,
        datasets: [{
          label: 'Speed',
          backgroundColor: linearGradient,
          borderColor: Colors.RGBA_PRI1_SHARP_GREEN,
          data: data,
          lineTension: 0,
          borderWidth: 1.2,
          pointBorderWidth: 1.2
        }]
      },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 70,
            bottom: -5
          }
        },
        tooltips: {
          // displayColors: false,
          ...this.chartJsTooltipStyles,
          // enabled: false,
          // mode: 'average'
          // position: 'nearest',
          // custom: customTooltips
          yAlign: 'bottom'
        },
    //     hover: this.chartJsHoverProps,
        scales: {
          yAxes: [{
        //     ticks: {
        //       beginAtZero: true,
        //       padding: 5,
        //       ...this.chartJsTicksFontStyles,
        //       maxTicksLimit: 6,
        //       autoSkip: false,
        //       callback: this.chartJsTicksNumberTruncateCallbackFn
        //     },
            gridLines: {
              z: 1,
              display: true,
              lineWidth: 1,
              zeroLineWidth: 0,
              borderDash: [5, 4],
              color: Colors.RGBA_LIGHT_GRAY,
              tickMarkLength: 5
            },
            scaleLabel: {
              display: true,
              labelString: 'Speed (km/h)',
              ...this.chartJsScaleLabelFontStyles
            }
          }],
          xAxes: [{
            ticks: {
              display:false
            },
            gridLines: {
              z: 1,
              display: false,
              lineWidth: [3, 1, 1, 1, 1],
              zeroLineWidth: 3,
              color: [Colors.RGBA_LIGHT_GRAY, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE],
              zeroLineColor: Colors.RGBA_LIGHT_GRAY,
              tickMarkLength: 5
            },
            scaleLabel: {
              display: false,
            }
          }]
        },
    //     animation: this.chartJsAnimation
      }
    };

    const ctx = document.getElementById('speedingChart');
    this.speedingGraph = new Chart(ctx, cOptions);

  }
  // ChartJS Helper: Create linear canvas gradient
  makeLinearCanvasGradient(canvasId: string, colorRgba: string, height: number = 200): CanvasGradient {
    // Render Gradient
    const ctx: CanvasRenderingContext2D = (<HTMLCanvasElement>this.el.nativeElement.querySelector(`#${canvasId}`)).getContext('2d');
    const cg: CanvasGradient = ctx.createLinearGradient(0, 0, 0, height);
    const rgb = DomUtil.getRgbaValues(colorRgba, false);
    cg.addColorStop(0, DomUtil.makeRgbaString(rgb.concat([0.9]))); //opacity 0.9
    cg.addColorStop(0.3, DomUtil.makeRgbaString(rgb.concat([0.9]))); //opacity 0.9
    cg.addColorStop(0.7, DomUtil.makeRgbaString(rgb.concat([0.5]))); //opacity 0.5
    cg.addColorStop(1, DomUtil.makeRgbaString(rgb.concat([0.2]))); //opacity 0.2
    return cg;
  }
  /* ---- popup table ---- */

  showPopup(popup) {
      this.popupService.show(popup);
  }
  hidePopup() {
      this.popupService.hide();
  }

  // get data
  async getIncidentDetails(incidentId) {
    this.startIncidentDetails = {
        gpsTimeStamp: null,
        location:null,
        latlng:null,
        altitude:null,
        direction:null,
        speed: null
    }
    this.endIncidentDetails = {
        gpsTimeStamp: null,
        location:null,
        latlng:null,
        altitude:null,
        direction:null,
        speed: null
    }
    const res: any = await this.tripsService.getIncidentDetails(incidentId);
    this.incidentDetails = res.body.detailsResult[0];
    if(res.body.tripResult.length > 0){
        this.startIncidentDetails = res.body.tripResult[0];
        this.endIncidentDetails =res.body.tripResult[1];
    }


    //parse date format
    this.startIncidentDetails.gpsTimeStamp = this.startIncidentDetails.gpsTimeStamp ?moment(this.startIncidentDetails.gpsTimeStamp).format('DD/MM/YYYY hh:mm:ss A') : null;
    this.endIncidentDetails.gpsTimeStamp = this.endIncidentDetails.gpsTimeStamp? moment(this.endIncidentDetails.gpsTimeStamp).format('DD/MM/YYYY hh:mm:ss A'):null;
    
    this.tripDetailResult = res.body.tripDetailResult;
    await this.getSpeedingGraph(this.tripDetailResult.trip_det.tripDet);
    

      
  }


  navigateSuccessToDriverView() {
      // Force page refresh
      this.routerProxyService.navigateSuccess(['/create-manage/drivers/view']);
  }

  navigateToDriverView() {
      this.routerProxyService.navigate(['/create-manage/drivers/view']);
  }
  navigateToIncidentAnalysisList() {
    this.routerProxyService.navigate(['/reports/incident-analysis']);
}

}
