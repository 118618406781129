/**
 * toDo:
 * 1) group columnList, repColumnList as a version
 * Note: columnList should have same length as repColumnList
 */
export const FuelTemplateType: any = {
    // PETRONAS: {
    //     value: "PETRONAS",
    //     label: "Petronas",
    //     verList: ["v2", "v1"],
    //     v1: { excludedRowNum, columnList, repColumnList, supported},
    //     v2: { excludedRowNum, columnList, repColumnList, supported}
    //     columnList: ["plateNo", "fuelQuantity", "fuelCost", "fuelDate", "location", "fuelType", "odometer"]
    //     repColumnList: ["plateNo", "fuelQuantity", "fuelCost", "fuelDate", "location", "fuelType", "odometer"]
    // },
    // CALTEX: {
    //     value: "CALTEX",
    //     label: "Caltex",
    //     verList: ["v2", "v1"],
    //     v1: { excludedRowNum, columnList, repColumnList, supported},
    //     v2: { excludedRowNum, columnList, repColumnList, supported}
    //     columnList: ["plateNo", "fuelQuantity", "fuelCost", "fuelDate", "location", "fuelType", "odometer"]
    //     repColumnList: ["plateNo", "fuelQuantity", "fuelCost", "fuelDate", "location", "fuelType", "odometer"]
    // },
    // PETRON: {
    //     value: "PERTRON",
    //     label: "Petron",
    //     verList: ["v2", "v1"],
    //     v1: { excludedRowNum, columnList, repColumnList, supported},
    //     v2: { excludedRowNum, columnList, repColumnList, supported}
    //     columnList: ["plateNo", "fuelQuantity", "fuelCost", "fuelDate", "location", "fuelType", "odometer"]
    //     repColumnList: ["plateNo", "fuelQuantity", "fuelCost", "fuelDate", "location", "fuelType", "odometer"]
    // },
    // BHP: {
    //     value: "BHP",
    //     label: "BHP",
    //     verList: ["v2", "v1"],
    //     v1: { excludedRowNum, columnList, repColumnList, supported},
    //     v2: { excludedRowNum, columnList, repColumnList, supported}
    //     columnList: ["plateNo", "fuelQuantity", "fuelCost", "fuelDate", "location", "fuelType", "odometer"]
    //     repColumnList: ["plateNo", "fuelQuantity", "fuelCost", "fuelDate", "location", "fuelType", "odometer"]
    // },
    SHELL: {
        id: 1,
        value: "SHELL",
        label: "Shell Helix",
        // verList: ["v2", "v1"],
        // v1: { excludedRowNum, columnList, repColumnList, supported},
        // v2: { excludedRowNum, columnList, repColumnList, supported}
        columnList: [
            "Account Name", "Licence Number", "Transaction Date", "Transaction Time", "Odometer Reading",
            "Product Name", "Volume", "Transaction Currency", "Unit Price", "Delivery Discount(local currency)",
            "Gross Amount(local currency)", "Tax(local currency)", "Net Amount(local currency)", "Net Amount(Invoice currency)"
        ],
        repColumnList: [
            "accName", "plateNo", "fuelDate", "fuelTime", "odometer",
            "fuelType", "fuelQuantity", "currencyType", "UnitPrice", "DeliveryDiscount",
            "GrossAmount", "Tax", "NewAmount", "fuelCost"
        ]
    },
    DEFAULT: {
        id: 0,
        value: "DEFAULT",
        label: "mDrive",
        // verList: ["v2", "v1"],
        // v1: { excludedRowNum, columnList, repColumnList, supported},
        // v2: { excludedRowNum, columnList, repColumnList, supported}
        columnList: [
            "*Vehicle Plate Number", "*Fuel Quantity(L)", "*Cost(RM)", "*Date/Time", "Location", "Fuel Type", "odometer"
        ],
        repColumnList: [
            "plateNo", "fuelQuantity", "fuelCost", "fuelDate", "localtion", "fuelType", "odometer"
        ]
    }
};

export function getFuelTemplateTypeValueById(templateTypeId: number): string {
    let selectedTypeValue = '';
    for (const eachTemplate in FuelTemplateType) {
        if (FuelTemplateType[eachTemplate].id == templateTypeId) {
            selectedTypeValue = FuelTemplateType[eachTemplate].value;
            break;
        }
    }
    return selectedTypeValue;
}

export function getFuelTemplateTypeValue(templateType: string): string {
    return FuelTemplateType[templateType.toUpperCase()].value;
}

export function getFuelTemplateTypeLabel(templateType: string): string {
    return FuelTemplateType[templateType.toUpperCase()].label;
}

export function getFuelTypeList(): Array<Object> {
    const fuelTypeList = [];
    for (const eachType in FuelTemplateType) {
        if (eachType) {
            const temp = { label: FuelTemplateType[eachType].label, id: FuelTemplateType[eachType].id };
            fuelTypeList.push(temp);
        }
    }
    return fuelTypeList;
}

export function getFuelTemplateTypeColumns(templateType: string): Array<string> {
    return FuelTemplateType[templateType.toUpperCase()].columnList;
}

export function getFuelTemplateTypeRepColumnList(templateType: string): Array<string> {
    return FuelTemplateType[templateType.toUpperCase()].repColumnList;
}

export function getFuelTemplateTypeList(): Array<string> {
    return Object.keys(FuelTemplateType).map(result => FuelTemplateType[result].label);
}

export function isValidFuelType(value: string): boolean {
    const labelList: string[] = Object.keys(FuelTemplateType).map(result => FuelTemplateType[result].label);
    return labelList.includes(value);
}
