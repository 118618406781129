<!-- <div class="site-main manage-device"> -->
<!-- <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@lg --space-top">
                    <h1 class="heading --level-1">
                        Upload
                    </h1>
                </div>
            </div>
        </div>
    </div> -->

<div class="section">
    <div class="wrapper">
        <div class="block">
            <div class="upload-header d-flex --aligned-center --justify-space-between">
                <h2 class="heading indicator-green --level-2 --partial-underline">
                    Information
                </h2>

                <!-- <div class="notes">
                        <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">

                        <span class="notes__text">
                            Batch Import template (.xls) can be downloaded
                            <a class="link" (click)="generateExcelTemplate()">here</a>
                        </span>
                    </div> -->
            </div>
        </div>

        <div class="block">
            <div class="upload-batch">
                <div class="image-placeholder">
                    <img src="assets/images/ng-components/create-manage/icon-batch-import-grey.svg" width="72">
                </div>

                <div class="inline-button__field">
                    <label>
                        <span class="button inline-button__inputs">
                            Browse
                        </span>

                        <input type="file" id="myFileInput" (change)="batchImport($event)" />
                    </label>
                </div>

                <span class="notes-input">( Max File Size: 10MB )</span>
                <span class="notes-input">Note*: Please remove white space in file name before upload</span>
            </div>
        </div>

        <div class="block" *ngIf="fileUploadList.length > 0">
            <div class="table">
                <table class="table__elem">
                    <thead>
                        <tr>
                            <th scope="col">
                                <button type="button">Uploaded File</button>
                            </th>

                            <!-- <th scope="col">
                                    <button type="button" class="table__sortable">Date / Time</button>
                                </th> -->

                            <th scope="col">
                                Action
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let file of fileUploadList; let i = index;">
                            <td>
                                {{file.name}}
                            </td>
                            <!-- <td>
                                    10/06/2020    08:00AM
                                </td> -->
                            <td>
                                <a class="link" (click)="deleteFile(i)">
                                    Delete
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing 1 / 1 Page
                        </div>
                    </div>
                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination">
                            <button type="button" class="pagination__button">
                                <span class="angle-arrow --left">
                                </span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" class="pagination__button">
                                1
                            </button>

                            <button type="button" class="pagination__button">
                                <span class="angle-arrow --right">
                                </span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->

    </div>
</div>
<!-- </div> -->
