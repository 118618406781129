import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../../environments/environment';
import { TripsService } from '../../../../../_services/trips/trips.service';
import { ToggleDirective } from '../../../../../directives/toggle/toggle.directive';
import { PopupService } from '../../../../common/popup/popup.service';
import { SnackBarService } from '../../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from '../../../../../_services/router-proxy/router-proxy.service';
import { SpinnerComponent } from '../../../../common/spinner/spinner.component';
import { DriverService } from './../../../../../_services/driver/driver.service';
import { VehicleService } from './../../../../../_services/vehicle/vehicle.service';
import { PopupCampaignService } from './../../../../../_services/campaign/popup-campaign.service';

import * as moment from 'moment';
import * as DomUtil from '../../../../../util/domUtil';
import * as DateTimeUtil from '../../../../../util/dateTimeUtil';
import * as ObjectUtil from '../../../../../util/objectUtil';
import * as StringUtil from '../../../../../util/stringUtil';
import * as NumberUtil from '../../../../../util/numberUtil';

import * as Message from '../../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from '../../../../../constants/responseStatusCode';
@Component({
  selector: 'app-create-incident-analysis',
  templateUrl: './create-incident-analysis.component.html',
  styleUrls: ['./create-incident-analysis.component.scss'],
  animations: [
    trigger('fadeToggle', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
        ])
    ])
]
})
export class CreateIncidentAnalysisComponent implements OnInit {
  @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

  message = Message;
  datePickerMaxValue = moment().startOf('day').format();

  startTimeSelector: {
      hour: string[],
      fullHour: string[],
      minute: string[],
      second: string[]
  } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array
  endTimeSelector: {
    hour: string[],
    fullHour: string[],
    minute: string[],
    second: string[]
  } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array
  impactTimeSelector: {
    hour: string[],
    fullHour: string[],
    minute: string[],
    second: string[]
  } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array

  incidentDateVar: string = moment().format("YYYY-MM-DD");
  incidentStartTimeHh: string = moment().format("hh");
  incidentStartTimeMm: string = moment().format("mm");
  incidentStartTimeA: string = moment().format("A");
  
  incidentEndTimeHh: string = moment().format("hh");
  incidentEndTimeMm: string = moment().format("mm");
  incidentEndTimeA: string = moment().format("A");

  impactDateVar: string = null;  
  impactTimeHh: string ='';
  impactTimeMm: string ='';
  impactTimeA: string ='AM';

  currentIncidentReportObj = {
      vehicleId: '',
      vehicleName: null,
      vehiclePlateNo: null,
      driverName: null,
      driverId: '',
      incidentStartDate: null,
      incidentEndDate: null,
      impactDate: null,
      description: null,
      remarks: null
  };

  vehicleInfoList = [];

  driverDetailsList: any = [];
  //date picket touch ui
  datepickerTouchUi: boolean = false;
  lastResizeTime: any = new Date(0);
  resizeTimeout: any;

  hasUnviewedCamapaign: boolean = false;
  unviewedCampaigns: Array<any> = [];

  constructor(
    private driverService: DriverService,
      private vehicleService: VehicleService,
      private popupService: PopupService,
      private el: ElementRef,
      private tripsService: TripsService,
      private snackBar: SnackBarService,
      private routerProxyService: RouterProxyService,
      private popupCampaignService: PopupCampaignService

  ) { }

  async ngOnInit() {
      DomUtil.autoFocusForm(this.el);
      await this.getVehiclesInfo();
      await this.getDriverDetails();
      await this.checkUnviewedCampaign();

  }

  showPopup(popup) {
      this.popupService.show(popup);
  }
  hidePopup() {
      this.popupService.hide();
  }

  async checkUnviewedCampaign() {
      try {
          const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
          this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
          this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
      } catch (e) {
          this.snackBar.openStandardizedErrorSnackBar(e);
      }
  }

  async getVehiclesInfo() {
      // Pull for dropdown box
      const vehicleList = await this.vehicleService.getAllVehicleInfoDetails();
      if (vehicleList) {
          this.vehicleInfoList = vehicleList.body.vehicle_details_list;
          this.vehicleInfoList = this.vehicleInfoList.filter(result => StringUtil.isNotEmptyOrNull(result.vehiclePlateNo));
      }
  }

  async getDriverDetails() {
    const res = await this.driverService.getDrivers(); // Get All Drivers
    this.driverDetailsList = res.body.result;
}

  onVehicleChoose(vehicleId: number) {
      const foundVehicle = this.vehicleInfoList.find(result => result.vehicleId === parseInt(vehicleId.toString()));
      if (foundVehicle) {
          this.currentIncidentReportObj.vehicleName = foundVehicle.vehicleName;
      } else {
          this.currentIncidentReportObj.vehicleName = null;
      }
  }

  onDriverChoose(driverId: number) {
    const foundDriver = this.driverDetailsList.find(result => result.Id === parseInt(driverId.toString()));
    if (foundDriver) {
        this.currentIncidentReportObj.driverName = foundDriver.Username;
    } else {
        this.currentIncidentReportObj.driverName = null;
    }
}

  async createIncidentAnalysisReport() {
      this.pageSpinner.show();

      try {

          const incidentDateOnlyMom = moment(this.incidentDateVar);
          const incidentStartDate = `${incidentDateOnlyMom.format('YYYY-MM-DD')} ${this.incidentStartTimeHh}:${this.incidentStartTimeMm} ${this.incidentStartTimeA}`;
          this.currentIncidentReportObj.incidentStartDate = incidentStartDate ? moment(incidentStartDate, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss") : null;
          const incidentEndDate = `${incidentDateOnlyMom.format('YYYY-MM-DD')} ${this.incidentEndTimeHh}:${this.incidentEndTimeMm} ${this.incidentEndTimeA}`;
          this.currentIncidentReportObj.incidentEndDate = incidentEndDate ? moment(incidentEndDate, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss") : null;

          if(this.impactDateVar){
            const impactDateOnlyMom = moment(this.impactDateVar);
            const impactDate = `${impactDateOnlyMom.format('YYYY-MM-DD')} ${this.impactTimeHh}:${this.impactTimeMm} ${this.impactTimeA}`;
            this.currentIncidentReportObj.impactDate = impactDate ? moment(impactDate, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss") : null;  
          }
         
          const incidentList = [
              {
                  vehicleId: parseInt(this.currentIncidentReportObj.vehicleId),
                  driverId: parseInt(this.currentIncidentReportObj.driverId),
                  incidentStartDate: this.currentIncidentReportObj.incidentStartDate,
                  incidentEndDate: this.currentIncidentReportObj.incidentEndDate,
                  impactDate: this.impactDateVar ? this.currentIncidentReportObj.impactDate : null,
                  description: this.currentIncidentReportObj.description,
                  remarks: this.currentIncidentReportObj.remarks
              }
          ];
          const result = await this.tripsService.createIncidentAnalysisReport(incidentList);
          let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Incident Analysis', this.currentIncidentReportObj.vehicleName);
          if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
              msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Incident Analysis', this.currentIncidentReportObj.vehicleName);
              this.navigateSuccessToIncidentAnalysisView();
          }
          this.snackBar.openGenericSnackBar(msg);

      } catch (e) {
          this.snackBar.openStandardizedErrorSnackBar(e);
      } finally {
          this.pageSpinner.hide();
      }
  }

  navigateSuccessToIncidentAnalysisView() {
      // Force page refresh
      this.routerProxyService.navigateSuccess(['/reports/incident-analysis']);
  }

  navigateToIncidentAnalysisView() {
      this.routerProxyService.navigate(['/reports/incident-analysis']);
  }

  @HostListener('window:resize') onResize() {
      const now: any = new Date();
      const diff: any = now - this.lastResizeTime;

      clearTimeout(this.resizeTimeout);

      if (diff >= 100) {
          this.updateDatepickerTouchUi();
          this.lastResizeTime = now;
      } else {
          this.resizeTimeout = setTimeout(() => {
              this.updateDatepickerTouchUi();
              this.lastResizeTime = new Date();
          }, diff);
      }
  }

  updateDatepickerTouchUi() {
      this.datepickerTouchUi = (window.innerWidth <= 480);
  }
}
