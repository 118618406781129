<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Create New Destination
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #destinationForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="compulsory-notes">
                    *Compulsory fields
                </div>
                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex geofence-field">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Step 1: Destination Info *
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Destination Name:
                                        <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" [(ngModel)]="destinationName" name="destinationName" class="field__input" required maxlength="255">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Step 2: Destination Area
                        <span class="indicator-green">*</span>
                    </h2>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-9of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Search Address:
                                        </div>
                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input id="searchInput" type="text" placeholder="eg: KLCC" class="field__input">
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="field-group__holder" style="text-align: center">
                    <label class="inline-input" style="margin: 10px">
                        <input type="radio" name="role" class="inline-input__input" [checked]="selectedDestinationType === polygon" (click)="chooseDestinationType(polygon)">

                        <span class="inline-input__icon"></span>
                        Polygon
                    </label>

                    <label class="inline-input" style="margin: 10px">
                        <input type="radio" name="role" class="inline-input__input" [checked]="selectedDestinationType === circle" (click)="chooseDestinationType(circle)">

                        <span class="inline-input__icon"></span>
                        Circle
                    </label>
                </div> -->
                <div class="map__canvas" id="map">
                </div>
            </div>
        </div>

        <app-vehicle-selector #vehicleAssign [title]="'Step 3: Assign Vehicles *'"></app-vehicle-selector>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="destinationForm.invalid
                            || vehicleAssign.getSelectedVehicleIds().length == 0
                            || destinationPath === ''" (click)="showPopup(createDestinationPopup)">
                                CREATE
                            </button>

                            <button class="button inline-button__inputs --grey" submit-button type="button" (click)="navigateToDrivingLimitView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>

<app-popup #createDestinationPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="createDestination();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
