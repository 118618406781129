import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Platform } from '@ionic/angular';

// Component
import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';

// Service
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { UserService } from './../../../../_services/user/user.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as DomUtil from '../../../../util/domUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';
import * as StringUtil from '../../../../util/stringUtil';
import * as LocalStorageUtil from '../../../../util/localStorageUtil';
import * as MapUtil from '../../../../util/mapUtil';

// Constant
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { ERROR_MESSAGE as ErrorMessage } from './../../../../constants/errorMessage';
import { overlayConstant } from '../../../../constants/overlay-image-map.constant';

declare var google: any;

@Component({
    selector: 'app-create-destination',
    templateUrl: './create-destination.component.html',
    styleUrls: ['./create-destination.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CreateDestinationComponent implements OnInit, OnDestroy {

    // Destination Form
    @ViewChild('vehicleAssign',{static:false}) vehicleAssign: VehicleSelectorComponent;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    message = Message;

    MY_LOCATION_LAT: number = 3.158; //Menara Maxis
    MY_LOCATION_LNG: number = 101.713; //Menara Maxis
    mapIsReady = false;
    mapContent: any;
    searchInput: any;

    //Destination Info
    destinationName: string;

    //Destination Area
    destinationPath: string;
    destinationRadius: number;

    //Vehicle List
    selectedVehiclesList: Array<number> = null;

    // Destination Type
    polygon: string = 'polygon';
    circle: string = 'circle';
    selectedDestinationType: string = this.circle;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private vehicleService: VehicleService,
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private userService: UserService,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        this.mapContent = document.getElementById('map');
        this.searchInput = document.getElementById('searchInput');
        await this.loadScriptFirst();
        DomUtil.autoFocusForm(this.el);

        await this.checkUnviewedCampaign();

    }

    async ngOnDestroy() {
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async loadScriptFirst() {
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        if (!DomUtil.hasGoogleMapDrawingScript(GOOGLE_MAP_API_KEY)) {
            DomUtil.addGoogleMapScript({
                libraries: ['drawing', 'places'],
                onload: this.finishScriptLoad.bind(this)
            }, GOOGLE_MAP_API_KEY);

        } else {
            if (DomUtil.isGoogleNotDefined()) {
                await AsyncUtil.wait(300); // wait for google object ready
            }
            this.finishScriptLoad();
        }
    }

    finishScriptLoad(): void {
        this.initMap();
    }

    chooseDestinationType(destinationType) {
        if (this.selectedDestinationType !== destinationType) {
            this.selectedDestinationType = destinationType;
            this.initMap();
        }
    }

    async initMap() {
        if (!DomUtil.isGoogleNotDefined()) {
            const self = this;
            let currentOverlay;
            const map = new google.maps.Map(this.mapContent, {
                center: { lat: this.MY_LOCATION_LAT, lng: this.MY_LOCATION_LNG },
                gestureHandling: 'greedy',
                zoom: 17
            });

            self.destinationPath = '';
            const drawingManager = new google.maps.drawing.DrawingManager({
                //drawingMode: google.maps.drawing.OverlayType.MARKER,
                drawingMode: self.selectedDestinationType,
                drawingControl: false,
                [`${this.selectedDestinationType}Options`]: {
                    fillColor: '#FFC0CB',
                    fillOpacity: 0.5,
                    strokeWeight: 3,
                    strokeColor: '#DC143C',
                    clickable: true,
                    draggable: true,
                    editable: true
                }
            });
            drawingManager.setMap(map);

            google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {

                // Disable Drawing
                drawingManager.setDrawingMode(null);
                currentOverlay = event.overlay;
                self.destinationPath = '';

                self.editDestinationArea(currentOverlay, event.type);

                // remove the overlay
                currentOverlay.addListener('click', function (e) {
                    e.stop();
                    if (typeof event.path === 'undefined') { // ignore clicks on polygon paths
                        self.destinationPath = '';
                        drawingManager.setDrawingMode(self.selectedDestinationType);
                        currentOverlay.setMap(null);
                        google.maps.event.clearInstanceListeners(currentOverlay);
                        currentOverlay = null;
                    }
                });
            });

            // Create the search box and link it to the UI element.
            const searchBox = new google.maps.places.SearchBox(this.searchInput);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', function () {
                searchBox.setBounds(map.getBounds());
            });

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', function () {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();
                places.forEach(function (place) {
                    if (!place.geometry) {
                        console.debug("Returned place contains no geometry");
                        return;
                    }
                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
                drawingManager.setMap(map);

                if (currentOverlay) {
                    currentOverlay.setMap(null);
                    currentOverlay = null;
                }
                self.destinationPath = '';
                drawingManager.setDrawingMode(self.selectedDestinationType);
            });

            const result = await this.userService.getCompanyIdByName('Gamuda Cove');
            if (result && result.body.length > 0) {
                const currentUserCompanyId = LocalStorageUtil.localStorageGet('currentUser').companyId;
                if (result.body[0].id === currentUserCompanyId) {
                    MapUtil.generateOverlayImg(overlayConstant.imageLoc, overlayConstant.imagePath, map);
                }
            }

        } else {
            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.MAP_LOAD_FAILED);
            this.snackBar.openGenericSnackBar(msg);
        }
    }

    editDestinationArea(event, type) {
        const _this = this;
        if (type === this.polygon) {
            // (Polygon) For Edit / Drag Area
            google.maps.event.addListener(event.getPath(), 'insert_at', function () {
                _this.destinationPath = '';
                event.getPath().getArray().forEach(function (latlng) {
                    if (_this.destinationPath) {
                        _this.destinationPath += ';';
                    }
                    _this.destinationPath += latlng.lat() + ',' + latlng.lng();
                });
            });
            google.maps.event.addListener(event.getPath(), 'set_at', function () {
                _this.destinationPath = '';
                event.getPath().getArray().forEach(function (latlng) {
                    if (_this.destinationPath) {
                        _this.destinationPath += ';';
                    }
                    _this.destinationPath += latlng.lat() + ',' + latlng.lng();
                });
            });
            // Run One Time After Finish Drawing
            event.getPath().getArray().forEach(function (latlng) {
                if (_this.destinationPath) {
                    _this.destinationPath += ';';
                }
                _this.destinationPath += latlng.lat() + ',' + latlng.lng();
            });
        } else {
            // (Circle) For Edit / Drag Area
            google.maps.event.addListener(event, 'dragend', function () {
                _this.destinationPath = event.center.lat() + ',' + event.center.lng();
            });
            google.maps.event.addListener(event, 'radius_changed', function () {
                _this.destinationRadius = Math.round(event.radius);
            });
            // Run One Time After Finish Drawing
            _this.destinationPath = event.center.lat() + ',' + event.center.lng();
            _this.destinationRadius = Math.round(event.radius);
        }
    }

    async createDestination() {
        this.pageSpinner.show();
        try {
            this.selectedVehiclesList = this.vehicleAssign.getSelectedVehicleIds();

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.destinationName)) {
                this.destinationName = this.destinationName.trim();
            }

            const result = await this.vehicleService.createDestination(this.destinationName, this.destinationPath, this.selectedDestinationType === this.polygon ? 'P' : 'C', this.selectedDestinationType === this.polygon ? null : this.destinationRadius, this.selectedVehiclesList);

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Destination', this.destinationName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Destination', this.destinationName);
                this.navigateSuccessToDrivingLimitView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToDrivingLimitView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/driving-limits/view'], null, 'destination');
    }

    navigateToDrivingLimitView() {
        this.routerProxyService.navigate(['/create-manage/driving-limits/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
