export const STATUS = {
    IDLING: 'idling',
    MOVING: 'moving',
    STOPPED: 'stopped',
    PARKANDALERT: 'parkandalert',
    DISCONNECTED: 'disconnected',

    moving: {
        LABEL: 'Moving',
        COLOR: '#128958', // new proposed color: '#00ab64'
        ZINDEX: '4' // higher will overlap above
    },
    idling: {
        LABEL: 'Idling',
        COLOR: '#f0c800',
        ZINDEX: '3'
    },
    parkandalert: {
        LABEL: 'Parked with Alert',
        COLOR: '#bc0909',
        ZINDEX: '2'
    },
    stopped: {
        LABEL: 'Stopped',
        COLOR: '#bc0909', // new proposed color: '#ef0000'
        ZINDEX: '2'
    },
    disconnected: {
        LABEL: 'Disconnected',
        COLOR: '#aeaeae',
        ZINDEX: '1'
    }
};
