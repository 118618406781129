import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';
import { DatePickerCustomFormatter, FORMATS } from './../../../../shared/adapter/datepicker-custom-formatter';

// Service
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { FuelService } from './../../../../_services/fuel/fuel.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as NumberUtil from './../../../../util/numberUtil';

// Class
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';

// Constant
import * as Message from '../../../../constants/message';

@Component({
    selector: 'app-fuel-usage-report',
    templateUrl: './fuel-usage-report.component.html',
    styleUrls: ['./fuel-usage-report.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s ease-out', style({ opacity: 0 }))
            ])
        ])
    ],
    providers: [
        {
            provide: DateAdapter, useClass: DatePickerCustomFormatter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: FORMATS.SPACE_MMMYYYY
        }
    ]
})
export class FuelUsageReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

    expandGroupVehicle: any = [];

    // Set Export File Name from environments
    exportPdfFuelUsagePageLayout = environment.appConfig.reporting.fuelUsage.layout;
    exportFileNameFuelUsage = environment.appConfig.reporting.fuelUsage.fileName;
    exportFileNameFuelUsagePdfTitle = environment.appConfig.reporting.fuelUsage.label;
    pageLayout = this.exportPdfFuelUsagePageLayout;
    moment = moment;

    @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

    @Input()
    vehicleIds: string;

    @Input()
    autoSortColumn: string;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private fuelService: FuelService,
        private el: ElementRef,
        private popupService_parent: PopupService,
        private popupCampaignService: PopupCampaignService
    ) {

        super(http_parent, popupService_parent, snackBar);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingMonthYearPicker: true,
            usingVehicleSelector: true
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {

        //Default search by
        this.searchBy = "vehicles";

        //Default search range
        // this.monthYear = moment().format("YYYY-MM-DD");

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        //when redirect from advisory
        if (this.vehicleIds && this.vehicleIds != '') {
            //clear default selected all vehicles
            this.selectedVehicles = {};
            const idArray = this.vehicleIds.split(',');
            idArray.forEach(vehicleId => {
                this.selectedVehicles[vehicleId] = true;
            });
            const current = moment();
            const pastMonth = current.subtract(1, 'months');
            const pastMonthStart = pastMonth.startOf('month').format('YYYY-MM-DD');
            const pastMonthEnd = pastMonth.endOf('month').format('YYYY-MM-DD');
            // this.monthYear = pastMonth.format("YYYY-MM-DD");
            this.startMonthYear = pastMonthStart;
            this.endMonthYear = pastMonthEnd;
            //update ui changes
            this.onRedirectUpdateVehiclesSelectChange();
            this.currentSortField = this.autoSortColumn;
            this.currentSortAscending = false;
            await this.generateReport();
        }

        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {

            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Call api to get report page
            const apiControllerResponse = await this.getApiControllerResponse(startRecord);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = apiControllerResponse.resultList;
                if (this.resultList.length) {

                    // parse some values to reduce function data bindings
                    this.resultList.forEach(record => {
                        record.refuelAmountLimitColor = this.fontColorByTwoNumber(record.fuelUsed, record.fuelUsedLimit);
                        record.fuelCostLimitColor = this.fontColorByTwoNumber(record.fuelCost, record.fuelCostLimit);
                        //pass in param with reverse order, because fuel efficiency above limit need to be green and below be red
                        record.fuelEfficiencyColor = this.fontColorByTwoNumber(record.fuelEfficiencyLimit, record.fuelEfficiency);
                        record.numOfRefills = NumberUtil.formatFloat(record.numOfRefills, 0, '0');
                        record.fuelUsed = NumberUtil.formatFloat(record.fuelUsed, 0, '0');
                        record.fuelCost = NumberUtil.formatFloat(record.fuelCost, 2, '0.00');
                        record.fuelEfficiency = NumberUtil.formatFloat(record.fuelEfficiency, 1, '0.0');
                    });

                    this.isReportShown = true;

                    // Get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }

    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }

    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {
        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        apiResponse = await this.getFuelUsageRecord(startRecord, isDownload);
        if (apiResponse != null) {
            apiResultList = apiResponse.result;
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }

    // For download report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 19 }, { wch: 17.5 }, { wch: 21 }];

        filename = this.exportFileNameFuelUsage + "_VEHICLE";
        label = this.exportFileNameFuelUsagePdfTitle + " (Vehicle)";
        headerName = [
            "Vehicle Name", "Plate Number", "Number of Refills", "Total Refuel Amount(L)", "Total Fuel Cost(RM)",
            "Fuel Efficiency(L/100km)"
        ];

        for (let i = 0; i < recordList.length; i++) {
            const tempRow = [
                recordList[i].vehicleName,
                recordList[i].plateNo,
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].numOfRefills, 0, '0')),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].fuelUsed, 0, '0')),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].fuelCost, 0, '0')),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].fuelEfficiency, 0, '0'))
            ];
            data.push(tempRow);
        }
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    /**
     * Report Specific Methods
     */

    // 1. Call API Post Trip Summary Violation Report
    async getFuelUsageRecord(startRecord, isDownload: boolean = false) {
        let result: any = null;
        //Get Data
        if (!isDownload) {
            result = await this.fuelService.getFuelUsageReport(this.selectedVehiclesList, parseInt(this.startMonth), parseInt(this.startYear), parseInt(this.endMonth), parseInt(this.endYear), this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
        } else {
            result = await this.fuelService.getFuelUsageReport(this.selectedVehiclesList, parseInt(this.startMonth), parseInt(this.startYear), parseInt(this.endMonth), parseInt(this.endYear), null, null, this.currentSortField, this.currentSortAscending);
        }
        return result || null;
    }

    fontColorByTwoNumber(exactNumber, compareNumber) {
        if (exactNumber == null || compareNumber == null || exactNumber == compareNumber) {
            return "--black";
        }
        if (exactNumber > compareNumber) {
            return "--red";
        }
        if (exactNumber < compareNumber) {
            return "--green";
        }
    }

    /**
     * Mat Datepicker Info:
     * startview="multi-year"(show select year tab)
     * startview="year"(show select month tab)
     * startview="month"(show select date tab)
     * each tab selection is done will emit an event that show a normalized date
     */

    // // Get Year from Mat Datepicker after year is selected
    // chosenYearHandler(normalizedYear) {
    //     this.chosenYear = '';
    //     const selectedYear = moment(normalizedYear).format("YYYY");
    //     // this.choosingDate = selectedYear;
    //     this.chosenYear = selectedYear;
    // }

    // Get Month from Mat Datepicker after month is selected
    // chosenMonthHandler(normalizedMonth, datepicker: MatDatepicker<Date>) {
    //     const selectedYearMonth = moment(normalizedMonth).format("YYYY-MM-01");
    //     this.monthYear = selectedYearMonth;

    //     // Must have to prevent datepicker those date selection tab
    //     datepicker.close();
    // }

    chosenStartMonthHandler(normalizedMonth, datepicker: MatDatepicker<Date>) {
        const selectedYearMonth = moment(normalizedMonth).format("YYYY-MM-01");
        this.startMonthYear = selectedYearMonth;
        // Must have to prevent datepicker those date selection tab
        datepicker.close();
    }

    chosenEndMonthHandler(normalizedMonth, datepicker: MatDatepicker<Date>) {
        const selectedYearMonth = moment(normalizedMonth).format("YYYY-MM-01");
        this.endMonthYear = selectedYearMonth;
        // Must have to prevent datepicker those date selection tab
        datepicker.close();
    }

    preBtnPdf() {
        const startDate = moment(this.startMonthYear).format("YYYY-MM");
        const endDate = moment(this.endMonthYear).format("YYYY-MM");
        this.btnPdf(startDate, endDate);
    }
}
