import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
// import { Router } from '@angular/router';
// import { first } from 'rxjs/operators';

import { UserService } from './../../../../_services/user/user.service';
import { PopupService } from '../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import * as Message from './../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

import * as PasswordUtil from './../../../../util/passwordUtil';
import * as DomUtil from './../../../../util/domUtil';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    formModel: any = {
        oldPassword: '',
        password: '',
        passwordStrength: '',
        confirmPassword: ''
    };

    passwordValidation: any = {
        hasError: false,
        isMatch: true,
        isEnoughLength: true,
        isOneUpperChar: true,
        isOneLowerChar: true,
        isOneNumber: true
    };

    @ViewChild('passwordPolicyPopup',{static:false}) passwordPolicyPopup;
    @ViewChild('passwordChangePopup',{static:false}) passwordChangePopup;
    @ViewChild('passwordForm',{static:false}) passwordForm;
    message = '';
    error = '';

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private userService: UserService,
        private routerProxyService: RouterProxyService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    ngOnInit() {
        DomUtil.autoFocusForm(this.el);
        this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    validatePassword() {
        this.passwordValidation = PasswordUtil.passwordValidator(this.formModel, this.passwordValidation);
    }

    onStrengthChanged($event) {
        if ($event === 20) {
            this.formModel.passwordStrength = 'WEAK';
        } else if ($event === 40) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 60) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 80) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 100) {
            this.formModel.passwordStrength = 'STRONG';
        }
    }

    async changePassword() {
        if (this.formModel.password.length !== 0 && this.formModel.confirmPassword.length !== 0) {
            this.validatePassword();
            if (this.passwordValidation.hasError) {
                this.showPopup(this.passwordPolicyPopup);
            } else {
                try {
                    const result = await this.userService.checkAndChangePassword(this.formModel.oldPassword, this.formModel.password);

                    this.message = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Password');
                    if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                        this.message = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Password');
                    }
                    this.snackBar.openGenericSnackBar(this.message);
                    // this.showPopup(this.passwordChangePopup);
                } catch (e) {
                    this.snackBar.openStandardizedErrorSnackBar(e);
                    // this.error = error.error.errorMessage;
                }
            }
        }
    }

    navigateToHomePage() {
        this.routerProxyService.navigateToHomePage();
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
    reload() {
        this.formModel = {
            oldPassword: '',
            password: '',
            passwordStrength: '',
            confirmPassword: ''
        };
        this.passwordForm.reset();
    }

}
