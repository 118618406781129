import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from '../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { ScoringService } from '../../../../_services/scoring/scoring.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as NumberUtil from './../../../../util/numberUtil';

// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';
@Component({
  selector: 'app-driver-ranking',
  templateUrl: './driver-ranking.component.html',
  styleUrls: ['./driver-ranking.component.scss'],
  animations: [
    trigger('fadeToggle', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class DriverRankingComponent extends GeneralReport implements GeneralReportInterface, OnInit {


  expandGroupVehicle: any = [];
  expandGroupDriver: any = [];

  scoreFormula: number;

  // Set Export File Name from environments
  exportPdfVehicleRankingPageLayout = environment.appConfig.reporting.vehicleRanking.layout;
  exportFileNameVehicleRanking = environment.appConfig.reporting.vehicleRanking.fileName;
  exportFileNameVehicleRankingPdfTitle = environment.appConfig.reporting.vehicleRanking.label;
  pageLayout = this.exportPdfVehicleRankingPageLayout;
  moment = moment;

  @ViewChild("page_spinner", {static: true}) page_spinner: SpinnerComponent;
  @ViewChild("reportResult_spinner", {static: false}) reportResult_spinner: SpinnerComponent;

  // Message Properties
  message = Message;

  hasUnviewedCamapaign: boolean = false;
  unviewedCampaigns: Array<any> = [];

  constructor(
    private platform: Platform,
    private http_parent: HttpClient,
    private snackBar: SnackBarService,
    private pagerService: PagerService,
    private scoringService: ScoringService,
    private popupService_parent: PopupService,
    private popupCampaignService: PopupCampaignService
  ) {

    //pass services to parent
    super(http_parent, popupService_parent, snackBar);
  }

  /**
   * To work properly with GeneralReportClass
   * Please call this method at first line of ngOnInit()
   */
  async initGeneralReportClass() {
    this.page_spinner.show();
    this.handShakeImplementations(
      this.fetchPage.bind(this),
      this.fetchDataForDownload.bind(this),
      this.generateDownloadables.bind(this),
      this.page_spinner
    );
    await this.initialise({
      usingDateRangePicker: true,
      usingDriverSelector: true
    });
    this.page_spinner.hide();
  }

  async ngOnInit() {
    await this.getVehiclesInfo();
    const scoreFormulaResponse = await this.scoringService.getScoringFormula();
    this.scoreFormula = scoreFormulaResponse.ScoringFormula[0];
    //Default search range
    // this.fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    // this.toDate = moment().format("YYYY-MM-DD");

    // Init page components & dropdown options
    await this.initGeneralReportClass();

    //Page load search report
    // await this.generateReport();
    await this.checkUnviewedCampaign();

  }

  async checkUnviewedCampaign() {
    try {
      const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
      this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
      this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
    } catch (e) {
      this.snackBar.openStandardizedErrorSnackBar(e);
    }
  }

  /**
   * @Implementing Methods of GeneralReportInterface
   */

  // Call API to get data with pagination
  async fetchPage(page: number = 1) {
    if (!this.page_spinner.isShowing()) {
      this.reportResult_spinner.show();
    }
    try {
      // toggle
      this.isReportSearched = true;

      const startRecord = ((page - 1) * this.pageRecordSize) + 1;

      //call api to get report page
      const apiControllerResponse: any = await this.getApiControllerResponse(startRecord);
      if (apiControllerResponse.response != null) {
        this.apiResponse = apiControllerResponse.response;

        //update sorting classes
        this.updateSortingState(this.apiResponse.sort);

        this.resultList = apiControllerResponse.resultList;

        if (this.resultList.length) {

          // parse some values to reduce function data bindings
          this.resultList.forEach(record => {
            record.Score = NumberUtil.formatFloat(record.Score, 2);
            record.totalDistance = NumberUtil.formatFloat(record.totalDistance, 2);
            record.totalDuration = NumberUtil.formatFloat(record.totalDuration, 2);
          });

          //update isReportShown boolean
          this.isReportShown = true;

          //get Pager data from service
          this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
        }
      }
    } catch (err) {
      this.snackBar.openStandardizedErrorSnackBar(err);
    } finally {
      this.reportResult_spinner.hide();
      this.page_spinner.hide();
    }
  }
  // Call API to get all data for download
  async fetchDataForDownload() {

    //call download api
    const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

    return apiControllerResponse.resultList;
  }
  // All API calls go through here, logics put inside here
  async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

    let apiResponse: any = null;
    let apiResultList: Array<any> = [];

    apiResponse = await this.getDriverRankingRecords(startRecord, isDownload);
    if (apiResponse != null) {
      apiResultList = apiResponse.driver_ranking_list;
    }

    return {
      response: apiResponse,
      resultList: apiResultList
    };
  }
  // For download driver ranking report
  generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
    if (!recordList.length) {
      return null;
    }

    let headerName: any = [];
    const headerType: any = [];
    let filename: string = "";
    let label: string = "";
    const data: any = [];

    const excelWidthConfig = [];

    filename = this.exportFileNameVehicleRanking;
    label = this.exportFileNameVehicleRankingPdfTitle;
    
    if(this.scoreFormula == 2 ) {
      headerName = [
        "Driver Name", "Driver Tag ID", "Distance Travelled (KM)", "Journey Duration (minutes)",
        "Total HA", "Total HB", "Total HC", "Total SV", "Total Idle", "Total Duration",
        "Score(%)", "Rank"
      ];

      excelWidthConfig.push({ wch: 15 }, { wch: 12 }, { wch: 17.5 }, { wch: 17.5 }, { wch: 12.5 }, { wch: 12.5 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15.5 }, { wch: 16 });
    } else {      
      headerName = [
        "Driver Name", "Driver Tag ID", "Distance Travelled (KM)", "Journey Duration (minutes)",
        "Total HA", "Total HB", "Total HC", "Total SV",
        "Score(%)", "Rank"
      ];

      excelWidthConfig.push({ wch: 15 }, { wch: 12 }, { wch: 17.5 }, { wch: 17.5 }, { wch: 12.5 }, { wch: 12.5 }, { wch: 10 }, { wch: 10 }, { wch: 15.5 }, { wch: 16 });
    }

    /**
     * Change the header type sequence, when the header sequnce is changed
     * Change the header type, when the header content data type is changed
     */
    // // excel file cell data type & format(if applicable)
    // headerType = [
    //     { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" },
    //     { type: "string" }, { type: "number" }, { type: "number" }, { type: "number" },
    //     { type: "number" }, { type: "number" }, { type: "number" }, { type: "string" }, { type: "string" }
    // ];

    for (let i = 0; i < recordList.length; i++) {
      const tempRow = [
        recordList[i].DriverName || '',
        recordList[i].DriverTagId || '',
        recordList[i].totalDistance || 0,
        recordList[i].totalDuration || 0,
        recordList[i].ImpactOfHA || 0,
        recordList[i].ImpactOfHB || 0,
        recordList[i].ImpactOfHC || 0,
        recordList[i].ImpactOfSpeeding || 0,
        ...this.scoreFormula == 2 ? [recordList[i].TotalIdle] : [],
        ...this.scoreFormula == 2 ? [recordList[i].TotalIdlingTime] : [],
        recordList[i].Score || '',
        recordList[i].Ranking || ''
      ];
      data.push(tempRow);
    }
    return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
  }


  /**
   * Report Specific Methods
   */

  // 1. Call API Driver Ranking Report
  async getDriverRankingRecords(startRecord: number = 1, isDownload: boolean = false) {

    let result: any = null;
    //Default value = null is for lifetime
    let startWeekNo = null;
    let endWeekNo = null;
    let startYear = null;
    let endYear = null;
    //get weekNo and year of from date
    const yearAndWeekNoFromDate = this.dateTimeUtil.fromDateToYearAndWeekNo(this.momFromDate);
    startYear = yearAndWeekNoFromDate.year;
    startWeekNo = yearAndWeekNoFromDate.weekNo;

    //get weekNo and year of to date
    const yearAndWeekNoToDate = this.dateTimeUtil.fromDateToYearAndWeekNo(this.momToDate);
    endYear = yearAndWeekNoToDate.year;
    endWeekNo = yearAndWeekNoToDate.weekNo;

    const idList = this.selectedDriversList;

    if (!isDownload) {
      result = await this.scoringService.getDriverRankingReport(idList, startWeekNo, endWeekNo, startYear, endYear, this.fromDate, this.toDate, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
    } else {
      result = await this.scoringService.getDriverRankingReport(idList, startWeekNo, endWeekNo, startYear, endYear, this.fromDate, this.toDate, null, null, this.currentSortField, this.currentSortAscending);
    }

    return result || null;
  }

}
