import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { BreadCrumb } from './breadcrumb';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

    breadcrumbs$: any[];
    myBreadcrumb = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        map(event => this.buildBreadCrumb(this.activatedRoute.root))
    ).subscribe(rs => this.breadcrumbs$ = rs);

    // Build your breadcrumb starting with the root route of your current activated route
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.myBreadcrumb) {
            this.myBreadcrumb.unsubscribe();
        }
    }

    buildBreadCrumb(route: ActivatedRoute,
        url: string = '',
        breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {

        // If no breadcrumbs, we are on the root path
        if (!breadcrumbs.length) {
            url = '/';
            const root: BreadCrumb = {
                label: 'Home',
                url: url,
                allowClick: true
            };
            breadcrumbs.push(root);
        }

        // Build breadcrumb for current path
        const bcRouter = route.routeConfig ? route.routeConfig : null;
        const bcData = bcRouter && bcRouter.data ? bcRouter.data['breadcrumb'] : null;
        let nextUrl = url;
        const newBreadcrumbs = [...breadcrumbs];
        if (bcData) {
            const label = bcData.label || '';
            let path = bcRouter.path || '';
            const allowClick = bcData.allowClick || false;

            if (path) {
                path = path + '/';
            }
            nextUrl = `${url}${path}`;
            const breadcrumb: BreadCrumb = {
                label: label,
                url: nextUrl,
                allowClick: allowClick
            };
            // console.debug(breadcrumb);
            newBreadcrumbs.push(breadcrumb);
        }

        if (route.firstChild) {
            // Recursive: Build breadcrumb for children paths
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        } else {
            // Reaches leaf node: Disable click
            const lastBreadcrumb: BreadCrumb = newBreadcrumbs.pop();
            lastBreadcrumb.allowClick = false;
            newBreadcrumbs.push(lastBreadcrumb);
        }

        return newBreadcrumbs;
    }
}
