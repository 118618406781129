import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';
// import { Browser } from 'selenium-webdriver';
// import { detectChangesInternal } from '@angular/core/src/render3/instructions';
// import { first } from 'rxjs/operators';
import * as DomUtil from './../../../util/domUtil';
// import * as LocalStorageUtil from './../../../util/localStorageUtil';
import { AuthService } from './../../../_services/auth';
import { FcmService } from './../../../shared/service/fcm.service';
import { PopupService } from './../../../components/common/popup/popup.service';
import { SnackBarService } from './../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../_services/router-proxy/router-proxy.service';
import { ErrorHandlerModule } from './../../../_services/error-handler';
import { BusMessage, MessageBusService } from './../../../_services/messagebus.service';
import { PopupCampaignService } from './../../../_services/campaign/popup-campaign.service';

import * as Message from './../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../constants/responseStatusCode';
import { CustomReuseStrategy } from './../../../app-routing-custom-reuse-strategy';

// azure ad
import { MsalService } from '@azure/msal-angular';
import { 
    AuthenticationResult, 
    EventMessage, 
    EventType 
} from '@azure/msal-browser';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    isSSOLogin: boolean = false;
    imageFolder = 'assets/images/ng-components/login/';
    currentView = 'login';
    loginForm: UntypedFormGroup;
    // username: string;
    // password: string;
    submitted: boolean = false;
    loading: boolean = false;
    returnUrl: string;
    stayLoggedIn: boolean = true; //future: checkbox at login page
    error: string = '';
    emailForm: UntypedFormGroup;
    // email: string
    sent: boolean = false;
    error2: string = '';
    submitted2: boolean = false;
    @ViewChild('emailSentPopup', { static: false }) emailSentPopup;
    emailSentPopupMsg: string;

    @ViewChild('disruptionAlertPopup', { static: false }) disruptionAlertPopup;

    message = Message;
    showClassicMdriveLink: boolean;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private errorHandler: ErrorHandlerModule,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private authService: AuthService,
        private platform: Platform,
        private fcm: FcmService,
        private routerProxyService: RouterProxyService,
        private msgbus: MessageBusService,
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private popupCampaignService: PopupCampaignService,
        private msalService: MsalService
    ) {
        // link to classic mdrive
        this.showClassicMdriveLink = !DomUtil.isMobileApp(this.platform);
        //Set Padding Top to 0 for body
        document.body.style.paddingTop = '0px';
    }

    get f() { return this.loginForm.controls; }

    get f2() { return this.emailForm.controls; }

    async ngOnInit() {
        // this.msgbus.sendMessage(new BusMessage("system", "login-page", null));
        
        this.authService.initLoginPage();

        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.emailForm = this.formBuilder.group({
            email: ['', Validators.required]
        });

        if (this.detectIE()) {
            this.snackBar.openGenericNoCloseSnackBar("Please use other browsers such as Chrome, Firefox or Microsoft Edge to access this application");
            return;
        }
        await this.checkUnviewedCampaign();
        await this.authService.preLogin();

        // COMMENT OUT AFTER DOWNTIME
        // this.showDisruptionPopUp();

    }

    async showDisruptionPopUp(){
        this.showPopup(this.disruptionAlertPopup);

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async onSubmit() {
        this.submitted = true;
        this.loading = true;
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
        try {
            if (this.isSSOLogin && !this.f.username.errors) {
                // 
                // this.msalService.loginRedirect();
                this.msalService.loginPopup()
                    .subscribe(async (response: AuthenticationResult) => {
                        this.msalService.instance.setActiveAccount(response.account);
                        // window.location.href = await this.authService.getSsoUrl(this.f.username.value);
                        this.routerProxyService.navigate(['/login/sso']);
                    });
            } else if (!this.isSSOLogin && !this.f.username.errors && !this.f.password.errors) {
                const result: any = await this.authService.login(this.f.username.value, this.f.password.value);
                if (result) {
                    //turn on reuse route path
                    CustomReuseStrategy.turnOnReuseRoutePath();
                    //notify AppComponent to do something
                    this.msgbus.sendMessage(new BusMessage("system", "login", null));

                    //stay logged in
                    this.authService.setStayLoggedIn(this.stayLoggedIn);
                    // if (this.stayLoggedIn) {
                    //     this.msgbus.sendMessage(new BusMessage("system", "userSession", { stayLoggedIn: true }));
                    // }

                    //init home page
                    await this.routerProxyService.initHomePage();
                    this.returnUrl = this.returnUrl || this.routerProxyService.getUserHomePage();
                    //Set Padding Top to come below notch/status bar (safe area)
                    document.body.style.paddingTop = 'max(0vmin, env(safe-area-inset-top))';
                    this.routerProxyService.navigateByUrl(this.returnUrl);
                }
            }
        } catch (error) {
            this.error = this.errorHandler.handleStandardizedError(error, true);
        }
        this.loading = false;
    }

    initForgot() {
        this.sent = false;
        this.submitted2 = false;
    }

    async forgotpassword() {
        this.sent = true;
        this.submitted2 = true;
        const email = this.f2.email.value;
        try {
            if (!this.f2.email.errors) {
                const result = await this.authService.forgotpassword(email);
                if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    this.emailSentPopupMsg = Message.getMessage(Message.MESSAGE.EMAIL_SENT_PASSWORD_RESET_LINK.value, this.f2.email.value);
                    this.showPopup(this.emailSentPopup);
                    // this.f2.email.setValue('');
                }
                this.sent = false;
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
            this.sent = false;
        }
    }

    onUsernameInput(inputValue: string) {
        if (inputValue.includes("@maxis.com.my")) {
            this.isSSOLogin = true;
        } else {
            this.isSSOLogin = false;
        }
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    closeForgotPassword() {
        this.currentView = 'login';
        this.f2.email.setValue('');
    }

    // switchToClassic() {
    //     console.log('switching to classic...');
    //     window.location.href = environment.appConfig.classicMdrive.url;
    // }

    detectIE() {

        const ua = window.navigator.userAgent;

        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return true;
        }

        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            return true;
        }

        // other browser
        return false;
    }

}
