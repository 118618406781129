import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { RouterProxyService } from '../../../../../../_services/router-proxy/router-proxy.service';
import { CompanyRegistrationSharedService } from '../../../../../../shared/service/company-registration-shared.service';
import { CompanyRegistrationService } from './../../../../../../_services/company-registration/company-registration.service';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from './../../../../../../_services/snackBar/snack-bar.service';
import * as moment from 'moment';

@Component({
    selector: 'app-company-details',
    templateUrl: './edit-company-details.component.html',
    styleUrls: ['./edit-company-details.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class EditCompanyDetailsComponent implements OnInit {

    showNew: boolean;
    showExisting: boolean;
    companyObj: any = {
        "id": null,
        "name": null,
        "brn": null,
        "email": null,
        "rfDate": null,
        "address": {
            "address1": null,
            "address2": null,
            "city": null,
            "state": null,
            "zipCode": null,
            "countryCode": null
        },
        "channel": {
            "channelName": null,
            "channelTypeId": null,
            "region": null
        }
    };

    vehicleIdsString: string;
    vehicleIds: Array<string> = [];
    regionList: any = ["Northern", "Southern", "Eastern", "Central", "Sabah", "Sarawak"];
    salesChannelList: any = [];

    constructor(
        private routerProxyService: RouterProxyService,
        private companyRegistrationSharedService: CompanyRegistrationSharedService,
        private companyRegistrationService: CompanyRegistrationService,
        private snackBar: SnackBarService,
        private route: ActivatedRoute
    ) { }

    goToVehicleDetails() {
        this.companyRegistrationSharedService.setCompanyObj(this.companyObj);
        this.routerProxyService.navigate(['/support/manage-device/edit-multiple/vehicle-details', this.vehicleIdsString]);
    }

    goToOverview() {
        this.routerProxyService.navigateByUrl('/support/manage-device');
    }

    ngOnInit() {
        this.vehicleIdsString = this.route.snapshot.params.ids;
        this.vehicleIds = this.vehicleIdsString.split(",");
        this.initCommonFunction();

    }

    async initCommonFunction() {
        try {
            //retrieve if have old data
            const object = JSON.parse(JSON.stringify(this.companyRegistrationSharedService.getCompanyObj()));
            if (object) {
                this.companyRegistrationSharedService.setCompanyObj(null);
                this.companyObj = object;
            } else {
                if (this.vehicleIds && this.vehicleIds.length > 0) {
                    //since all vehicle same company, any one of it will do
                    const results = await this.companyRegistrationService.getCompanyByVehicleId(this.vehicleIds[0]);
                    //check is the object returned have any properties
                    if (Object.keys(results.body.result).length > 0) {
                        this.companyObj = results.body.result;
                        this.companyObj.channel = this.companyObj.channels[0];
                        delete this.companyObj.channels;
                        this.companyObj.rfDate = moment(this.companyObj.rfDate).format('YYYY-MM-DD');
                    }
                }
            }

            const channelTypeResponse = await this.companyRegistrationService.getSalesChannelTypeList();
            this.salesChannelList = channelTypeResponse.body.result;

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

}
