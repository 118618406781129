import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from '../../../common/spinner/spinner.component';

// Service
import { UserService } from '../../../../_services/user/user.service';
import { PopupService } from '../../../common/popup/popup.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from '../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';
import { RoleService } from '../../../../_services/role/role.service';

// Util
import * as ImageUtil from '../../../../util/imageUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';
import * as DomUtil from '../../../../util/domUtil';
import * as StringUtil from '../../../../util/stringUtil';

// Constant
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from '../../../../constants/responseStatusCode';

@Component({
    selector: 'app-create-admin',
    templateUrl: './create-admin.component.html',
    styleUrls: ['./create-admin.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CreateAdminComponent implements OnInit {
    newAdminDetails = {
        firstName: '',
        lastName: '',
        telNo: '',
        email: '',
        photo: null
    };
    message = Message;

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    @ViewChild('imageAlertPopup',{static:false}) imageAlertPopup;
    isImage: boolean = false;
    maxSizeMB = environment.appConfig.createManage.maxSizeMB;
    maxWidthOrHeight = environment.appConfig.createManage.maxWidthOrHeight;
    maxSizeImageMB = environment.appConfig.createManage.maxSizeImageMB;
    imageAlertError = null;

    @ViewChild('createCropImagePopup',{static:false}) createCropImagePopup;
    @ViewChild(ImageCropperComponent,{static:false}) imageCropper: ImageCropperComponent;
    imageChangedEvent: any = '';
    croppedImageData: any = '';

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    roleId: number;
    roleList = [];

    constructor(
        private platform: Platform,
        private popupService: PopupService,
        private userService: UserService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService,
        private roleService: RoleService

    ) { }

    async ngOnInit() {
        DomUtil.autoFocusForm(this.el);
        await this.checkUnviewedCampaign();
        this.getRoleList();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    navigateSuccessToAdminView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/admins/view']);
    }

    navigateToAdminView() {
        this.routerProxyService.navigate(['/create-manage/admins/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async createAdmin() {
        this.pageSpinner.show();
        try {
            // Set Image
            let newImage;
            if (this.isImage) {
                const img: any = <HTMLElement>document.getElementById("imageId");
                if (img) {
                    //Image Compression
                    // this.newAdminDetails.photo = this.croppedImageData
                    newImage = await ImageUtil.compressImageSize(img.src, this.maxSizeMB, this.maxWidthOrHeight, this.platform); // maxSizeMB, maxWidthOrHeight are optional
                    await AsyncUtil.wait(2000); // Wait for image compress
                } else {
                    newImage = null;
                }
                // Invalid image -> always update to blank
                if (!newImage) {
                    this.newAdminDetails.photo = '';
                } else {
                    this.newAdminDetails.photo = newImage;
                }
            }

            for (const objKey in this.newAdminDetails) {
                if (this.newAdminDetails[objKey] == '' || this.newAdminDetails[objKey] == null || this.newAdminDetails[objKey] == undefined) {
                    delete this.newAdminDetails[objKey];
                }
            }

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.newAdminDetails.firstName)) {
                this.newAdminDetails.firstName = this.newAdminDetails.firstName.trim();
            }
            if (StringUtil.isNotEmptyOrNull(this.newAdminDetails.lastName)) {
                this.newAdminDetails.lastName = this.newAdminDetails.lastName.trim();
            }

            if (this.roleId) {
                this.newAdminDetails['roleId'] = this.roleId;
            }

            const result = await this.userService.createAdmin(this.newAdminDetails);

            // scroll back to the top for error (if any)
            // window.scroll(0, 0);

            const adminName = this.newAdminDetails.firstName + ' ' + this.newAdminDetails.lastName;

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Admin', adminName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Admin', adminName);
                // redirect to view
                this.navigateSuccessToAdminView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async chooseImage(item: any) {
        if (item.target.files.length > 0 && item.target.files[0].type.indexOf("image") > -1) {
            const imageFile = item.target.files[0];

            if ((imageFile.size / 1024 / 1024) < this.maxSizeImageMB) { //Check Image Size

                this.isImage = true;
                this.imageChangedEvent = item;
                // let fileInput: any = document.getElementById('myFileInput');
                // fileInput.value = null; // Clean previous file
                this.showPopup(this.createCropImagePopup);

            } else {
                this.imageAlertError = 'sizeError';
                this.showPopup(this.imageAlertPopup);
            }
        } else {
            this.imageAlertError = 'fileError';
            this.showPopup(this.imageAlertPopup);
        }
    }

    confirmCropImage() {
        const img: any = <HTMLElement>document.getElementById("imageId");
        img.src = this.croppedImageData;
    }

    imageDirection(direction) {
        if (direction === 'left') {
            this.imageCropper.rotateLeft();
        } else if (direction === 'right') {
            this.imageCropper.rotateRight();
        } else if (direction === 'horizontal') {
            this.imageCropper.flipHorizontal();
        } else if (direction === 'vertical') {
            this.imageCropper.flipVertical();
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImageData = event.base64;
        const img: any = <HTMLElement>document.getElementById("imageId");
        img.src = this.croppedImageData;
    }

    imageLoaded() {
        const fileInput: any = document.getElementById('myFileInput');
        fileInput.value = null; // Clean previous file
    }

    async getRoleList() {
        const response: any = await this.roleService.listRole();
        this.roleList = response.body.result;
    }
}
