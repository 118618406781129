
export const installationType = {
    WIRED: {
        code: "WIRED",
        desc: "Wired"
    },
    PLUG_PLAY: {
        code: "PLUG_PLAY",
        desc: "Plug and Play"
    },
    // PLUG_PLAY_COURIER: {
    //     code: "PLUG_PLAY_COURIER",
    //     desc: "Plug and Play Courier"
    // },
    CUSTOM: {
        code: "CUSTOM",
        desc: "Customized"
    }
}
