import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

import * as StringUtil from '../../util/stringUtil';
// import * as LocalStorageUtil from './../../util/localStorageUtil';

@Injectable({
    providedIn: 'root'
})
export class SupportService {

    constructor(private http: HttpClient) { }

    async administatorLogin(companyId) {
        const path = environment.mtntApiEndpoint + `/support/administrator-login`;
        const result: any = await this.http.post(path, { companyId }).toPromise();
        return result;
    }

    async getCompanyList(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/support/companies${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getCompanyUsers(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/support/companies/users${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async resetPassword(userId, userEmail) {
        const body = {
            userId: userId,
            email: userEmail
        };

        const path = environment.mtntApiEndpoint + `/support/reset-password`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async reactivateUserAccount(userId, userEmail) {
        const body = {
            userId: userId,
            email: userEmail
        };

        const path = environment.mtntApiEndpoint + `/support/re-activate`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async updateUserEmail(userId, userEmail) {
        const body = {
            userId: userId,
            email: userEmail
        };

        const path = environment.mtntApiEndpoint + `/support/updateEmail`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async getMaxisSupportUser(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, currentFilterValue = undefined) {

        const path = environment.mtntApiEndpoint + `/support/users`;

        // let paramArr = [];
        // if (StringUtil.isNotEmptyOrNull(sortField)) {
        //     paramArr.push(`sortField=${sortField}`);
        // }
        // if (typeof (sortAscending) == typeof (true)) {
        //     paramArr.push(`sortAscending=${sortAscending}`);
        // }
        // if (pageRecordSize != undefined) {
        //     paramArr.push(`pageSize=${pageRecordSize}`);
        // }
        // if (startRecord != undefined) {
        //     paramArr.push(`startRecord=${startRecord}`);
        // }
        // if (filterKey != undefined) {
        //     paramArr.push(`filterKey=${filterKey}`);
        // }
        // if (currentFilterValue != undefined) {
        //     paramArr.push(`filterValue=${currentFilterValue}`);
        // }
        // let param = '';
        // for (let i = 0; i < paramArr.length; i++) {
        //     if (i == 0) {
        //         param += '?' + paramArr[i];
        //     } else
        //         param += '&' + paramArr[i];
        // }
        // path += param;

        const paramObj: any = {};
        if (StringUtil.isNotEmptyOrNull(sortField)) {
            paramObj.sortField = sortField;
        }
        if (typeof (sortAscending) == typeof (true)) {
            paramObj.sortAscending = sortAscending;
        }
        if (pageRecordSize != undefined) {
            paramObj.pageSize = pageRecordSize;
        }
        if (startRecord != undefined) {
            paramObj.startRecord = startRecord;
        }
        if (filterKey != undefined) {
            paramObj.filterKey = filterKey;
        }
        if (currentFilterValue != undefined) {
            paramObj.filterValue = currentFilterValue;
        }

        const result: any = await this.http.get(path, {
            params: paramObj
        }).toPromise();
        return result;
    }

    async deleteMaxisSupportUser(maxisSupportUserIdList = []) {
        const body: any = { body: { userIdList: maxisSupportUserIdList } };
        const path = environment.mtntApiEndpoint + `/support/users`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async createMaxisSupportUser(newMaxisSupportUser) {
        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(newMaxisSupportUser.name)) {
            newMaxisSupportUser.name = newMaxisSupportUser.name.trim();
        }
        let firstName = '';
        let lastName = '';

        const nameSplit = newMaxisSupportUser.name.split(/\s+/);
        if (nameSplit.length) {
            firstName = nameSplit[0];
            for (let i = 1; i < nameSplit.length; i++) {
                lastName += nameSplit[i] + ' ';
            }
            if (nameSplit.length == 1) {
                lastName = ' ';
            }
        }

        const body = {
            role: newMaxisSupportUser.selectedRole,
            firstName: firstName,
            lastName: lastName,
            email: newMaxisSupportUser.email
        };

        const path = environment.mtntApiEndpoint + `/support/users`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async updateMaxisSupportUser(updateMaxisSupportUser) {
        const path = environment.mtntApiEndpoint + `/support/users`;
        let body = {
            userId: parseInt(updateMaxisSupportUser.userId),
            role: updateMaxisSupportUser.userRole
        };
        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(updateMaxisSupportUser.name)) {
            updateMaxisSupportUser.name = updateMaxisSupportUser.name.trim();
            let firstName = '';
            let lastName = '';
            const nameSplit = updateMaxisSupportUser.name.split(/\s+/);
            firstName = nameSplit[0];
            for (let i = 1; i < nameSplit.length; i++) {
                lastName += nameSplit[i] + ' ';
            }
            if (nameSplit.length == 1) {
                lastName = ' ';
            }
            body = Object.assign(body, { firstName: firstName, lastName: lastName });
        }
        if (StringUtil.isNotEmptyOrNull(updateMaxisSupportUser.email)) {
            body = Object.assign(body, { email: updateMaxisSupportUser.email });
        }

        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    async suspendUnsuspendAccount(companyId, status) {
        const body = {
            status: status,
            companyIds: [companyId]
        };

        const path = environment.mtntApiEndpoint + `/users/updateCompanyAndUserStatus`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }
    async deactivateAccount(companyId) {

        const path = environment.mtntApiEndpoint + `/support/companies/${companyId}`;
        const result: any = await this.http.delete(path).toPromise();
        return result;
    }

    async getAllDevices(pageRecordSize, startRecord, sortField, sortAscending, filterKey, filterValue, installationType, regStatus, filterProgress) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += installationType ? `&installationType=${installationType}` : '';
        param += regStatus ? `&regStatus=${regStatus}` : '';
        param += filterProgress ? `&filterProgress=${filterProgress}` : '';

        const path = environment.mtntApiEndpoint + `/support/companies/devices${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getAllVehicles(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, currentFilterValue = undefined) {

        const path = environment.mtntApiEndpoint + `/support/vehicles`;

        const paramObj: any = {};
        if (StringUtil.isNotEmptyOrNull(sortField)) {
            paramObj.sortField = sortField;
        }
        if (typeof (sortAscending) == typeof (true)) {
            paramObj.sortAscending = sortAscending;
        }
        if (pageRecordSize != undefined) {
            paramObj.pageSize = pageRecordSize;
        }
        if (startRecord != undefined) {
            paramObj.startRecord = startRecord;
        }
        if (filterKey != undefined) {
            paramObj.filterKey = filterKey;
        }
        if (currentFilterValue != undefined) {
            paramObj.filterValue = currentFilterValue;
        }

        const result: any = await this.http.get(path, {
            params: paramObj
        }).toPromise();
        return result;
    }

    async deleteVehicles(vehiclesDetailList = []) {
        const body: any = { body: { vehiclesDetailList: vehiclesDetailList } };
        const path = environment.mtntApiEndpoint + `/support/vehicles`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }
}
