<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        {{ adminUpdateDetails.firstName}} {{ adminUpdateDetails.lastName}}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="section   bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="generalInfo()">General
                    Info</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="assignResources()">Assign
                    Resources</button>
            </div>
        </div>
    </div>

    <!-- Edit Admin Tabs-->
    <div class="section tabs__content" [hidden]="currentTab !== 1">
        <div class="wrapper">
            <form class="form-field" #adminForm="ngForm">
                <div class="section">
                    <div class="wrapper">
                        <div [ngClass]="{'compulsory-notes': !isEditFail, 'alert': isEditFail}">
                            {{actionMessage}}
                        </div>
                        <div class="compulsory-notes">
                            *Compulsory fields
                        </div>
                        <div class="grid --gutter">
                            <div class="grid__block w-8of12@sm form__flex">
                                <h2 class="heading indicator-green --level-2 --partial-underline">
                                    Admin Details
                                </h2>

                                <div class="field-group --unstack@xs --space-top">
                                    <div class="field-group__item">
                                        <!-- First Name -->
                                        <div class="field-group__holder">
                                            <div class="field-group__label">
                                                First Name:
                                                <span class="indicator-green">*</span>
                                            </div>
                                            <div class="field-group__input">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <label class="field__holder">
                                                            <input type="text" class="field__input" [(ngModel)]="adminUpdateDetails.firstName" name="firstName" #firstName="ngModel" required maxlength="45">
                                                        </label>
                                                        <div class="field__ui"></div>
                                                    </div>
                                                </div>
                                                <div class="field-error" *ngIf="firstName.errors?.required">
                                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'First Name')}}
                                                </div>
                                                <div class="field-error" *ngIf="firstName.errors?.maxlength">
                                                    {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'First Name', '45')}}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- First Name End -->

                                        <!-- Last Name -->
                                        <div class="field-group__holder">
                                            <div class="field-group__label">
                                                Last Name:
                                                <span class="indicator-green">*</span>
                                            </div>
                                            <div class="field-group__input">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <label class="field__holder">
                                                            <input type="text" class="field__input" [(ngModel)]="adminUpdateDetails.lastName" name="lastName" #lastName="ngModel" required maxlength="45">
                                                        </label>
                                                        <div class="field__ui"></div>
                                                    </div>
                                                </div>
                                                <div class="field-error" *ngIf="lastName.errors?.required">
                                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Last Name')}}
                                                </div>
                                                <div class="field-error" *ngIf="lastName.errors?.maxlength">
                                                    {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Last Name', '45')}}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Last Name End -->

                                        <!-- Phone Number -->
                                        <div class="field-group__holder">
                                            <div class="field-group__label">
                                                Phone Number:
                                            </div>
                                            <div class="field-group__input">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <label class="field__holder">
                                                            <input type="text" class="field__input" [(ngModel)]="adminUpdateDetails.telNo" name="telNo" placeholder="eg: 0123456789" maxlength="20">
                                                        </label>
                                                        <div class="field__ui"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Phone Number End -->
                                        <div class="field-group__holder">
                                            <div class="field-group__label">
                                                Email:
                                                <span class="indicator-green">*</span>
                                            </div>
                                            <div class="field-group__input">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <label class="field__holder">
                                                            <input class="field__input" [(ngModel)]="adminUpdateDetails.email" name="email" #email="ngModel" required maxlength="45" email>
                                                        </label>
                                                        <div class="field__ui"></div>
                                                    </div>
                                                </div>
                                                <div class="field-notes">
                                                    (This email will be used as login username for admin)
                                                </div>
                                                <div class="field-error" *ngIf="email.errors?.required">
                                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Email')}}
                                                </div>
                                                <div class="field-error" *ngIf="email.errors?.email">
                                                    {{message.MESSAGE.INVALID_EMAIL.label}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="field-group__holder">
                                            <div class="field-group__label">
                                                Role: <span class="indicator-green">*</span>
                                            </div>
                                            <div class="field-group__input">
                                                <div class="field">
                                                    <div class="field__elems">
                                                        <label class="select">
                                                            <select class="select__input" name="role" [(ngModel)]="roleId" required>
                                                                <option *ngFor="let item of roleList;" [value]="item.id" [selected]="item.id === roleId">{{item.name}}</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="grid__block w-4of12@sm profile__image-field form__flex">
                                <div class="profile__image-cropped --placeholder-grey" *ngIf="!hasImage">
                                    <img id="imageId" src="assets/images/ng-components/create-manage/icon-admin-placeholder.svg" alt="" width="94" height="102">
                                    <button type="button" class="button-circle-thumb" (click)="showPopup(imageUploadPopup)">
                                        <img src="assets/images/ng-components/create-manage/icon-add-photo.svg" alt="" width="23" height="23">
                                    </button>
                                </div>

                                <div class="profile__image-cropped --placeholder-grey --placeholder-photo --has_image" *ngIf="hasImage">
                                    <img id="imageId" src="{{adminUpdateDetails.photo}}" alt="" width="206" height="206">
                                    <button type="button" class="button-circle-thumb" (click)="showPopup(imageUploadPopup)">
                                        <img src="assets/images/ng-components/create-manage/icon-edit-photo.svg" alt="" width="22" height="22">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section">
                    <div class="wrapper">
                        <div class="grid --gutter">
                            <div class="grid__block">
                                <div class="inline-button__field">
                                    <button class="button inline-button__inputs" type="button" [disabled]="adminForm.invalid" (click)="showPopup(editAdminPopup)">
                                        SAVE
                                    </button>
                                    <button class="button inline-button__inputs --grey" type="button" (click)="navigateToAdminView()">
                                        CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- End of Edit Admin Tabs-->

    <!-- Assign Resources-->
    <div class="tabs__content" [hidden]="currentTab !== 2">

        <app-vehicle-selector #vehicleAssign [title]="'Vehicles'" [adminId]="adminId"></app-vehicle-selector>

        <app-driver-selector #driverAssign [title]="'Drivers'" [adminId]="adminId"></app-driver-selector>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" (click)="showPopup(editAdminPopup)">
                                SAVE
                            </button>
                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToAdminView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End of Assign Resource-->
</div>

<app-popup #imageUploadPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Image Upload
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="image-placeholder">
            <img src="assets/images/ng-components/create-manage/icon-image-upload.svg" alt="" width="152" height="133">
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <label>
                <span class="button inline-button__inputs">
                    Browse
                </span>
                <input type="file" id="myFileInput" accept="image/*" (change)="chooseImage($event)" />
            </label>
        </div>
        <span class="notes-input">( Max File Size: {{maxSizeImageMB}}MB )</span>
    </div>
</app-popup>

<app-popup #imageAlertPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert" *ngIf="imageAlertError === 'sizeError'">Image Size Cannot Larger Than
            {{maxSizeImageMB}}MB</span>
        <span class="notes-input --alert" *ngIf="imageAlertError === 'fileError'">Invalid Image File</span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                OK
            </button>
        </div>
    </div>
</app-popup>

<app-popup #editAdminPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p *ngIf="currentTab === 1">
            You are going to save admin changes
        </p>

        <p *ngIf="currentTab === 2">
            You are going to save this resource assignment
        </p>
        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateAdminInfo();hidePopup();" *ngIf="currentTab === 1">
                CONFIRM
            </button>

            <button class="button inline-button__inputs" type="button" (click)="submitAssignDriversAndVehiclesResources();hidePopup();" *ngIf="currentTab === 2">
                CONFIRM
            </button>
            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #editCropImagePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <div class="cm-heading__text">
                Crop Image
            </div>
        </div>
    </div>
    <app-spinner #cropImage_spinner></app-spinner>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [resizeToWidth]="maxWidthOrHeight" [onlyScaleDown]="true" [roundCropper]="true" outputType="base64" (cropperReady)="cropperReady()" (imageCropped)="imageCropped($event)"></image-cropper>
    <div class="popup__block">
        <div class="inline-button__field">
            <div class="inline-button__inputs">
                <button type="button" class="button-crop" (click)="imageDirection('left')">
                    <img src="assets/images/ng-components/create-manage/icon-rotate-left.svg" alt="" width="23" height="23">
                </button>

                <button class="button-crop" type="button" (click)="imageDirection('right')">
                    <img src="assets/images/ng-components/create-manage/icon-rotate-right.svg" alt="" width="23" height="23">
                </button>
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="confirmCropImage();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
