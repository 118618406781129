import { environment } from './../../../../environments/environment';
import { Component, OnInit, ElementRef, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as LocalStorageUtil from './../../../util/localStorageUtil';
// import * as DomUtil from './../../../util/domUtil';

import * as moment from 'moment';
import * as AsyncUtil from "../../../util/asyncUtil";

import { MessageBusService, BusMessage } from './../../../_services/messagebus.service';
import { MenuService, NavMenu } from './../../../_services/menu/menu.service';
import { RouterProxyService } from './../../../_services/router-proxy/router-proxy.service';
import { TokenService } from './../../../_services/token/token.service';
import { NotificationService } from './../../../_services/notification/notification.service';
import { PagerService } from './../../../_services/pager/pager.service';
import { UserService } from './../../../_services/user/user.service';
import { AuthService } from './../../../_services/auth/auth.service';
import { SnackBarService } from './../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../common/spinner/spinner.component';
import { LogoutComponent } from '../logout/logout.component';

import * as USER_ROLE from './../../../constants/userRole';
import * as ImageConstant from './../../../constants/notification-image.constant';
import { CustomReuseStrategy } from './../../../app-routing-custom-reuse-strategy';
import { AccessControlService } from './../../../_services/access-control/access-control.service';

@Component({
    selector: 'site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.scss'],
    animations: [
        trigger('slideToggle', [
            transition(':enter', [
                style({ height: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ height: '*' }))
            ]),
            transition(':leave', [
                style({ height: '*' }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ height: 0 }))
            ])
        ]),
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ],
})
export class SiteHeaderComponent implements OnInit, OnDestroy {

    // @ViewChild("notification_spinner") notification_spinner: SpinnerComponent;
    // testHTML = `<span class="notification__user">Stackoverflow</span>`;

    navIsShown: boolean = false;
    // isLoggedIn: boolean = false;
    currentSubnav: string = '';

    username: string = null;
    companyName = null;
    // isAdminSupportLogin: boolean = false;

    //Push Notification
    @ViewChild('notificationField',{static:false}) notificationField;
    pager: any = {}; //Pager object
    pageRecordSize: number = environment.appConfig.notification.pageRecordSize;
    notificationList = [];
    notificationTotalRecord: number;
    notificationRecentAlertCount: number = 0;
    isShowNotification: Boolean = false;
    isViewMore: Boolean = false;
    isNotificationMenuFinishDisplay: Boolean = false;
    defaultMessage: String = "No Notification Found";

    // Set Default load page number
    @ViewChild("notification_spinner",{static:true}) notification_spinner: SpinnerComponent;
    page: number = 1;
    notificationDisplayList = [];
    imageConstant = ImageConstant;
    retrieveAlertCountTimer: any;
    retrieveAlertCountTimerIntervalInMilliSeconds = environment.appConfig.notification.triggerTotalRecentAlertInterval * 1000;
    roleId = LocalStorageUtil.localStorageGet('currentUser').roleId;
    //super super admin when haven't manage account will same as normal support role
    isSupportManagementRole: Boolean = LocalStorageUtil.localStorageGet('currentUser').supportId || LocalStorageUtil.localStorageGet('currentUser').role === USER_ROLE.USER_ROLE.SUPER_SUPER_ADMIN.value ? true : false;

    @ViewChild("logoutPopup",{static:false}) logoutPopup: LogoutComponent;

    // Survey
    @ViewChild('surveyPopup',{static:false}) surveyPopup;
    // surveyStatus = LocalStorageUtil.localStorageGet('currentUser').surveyStatus || null;
    // surveyUrl = environment.appConfig.surveySettings.surveyUrl;
    // surveyDate = environment.appConfig.surveySettings.surveyDate;
    isSurveyDisplayed: boolean = false;
    userSurveyStatus: any = [];
    survey = {
        surveyTitle: "",
        surveyContent: "",
        surveyUrl: "",
        surveyId: 0
    };

    isMenuLoaded: boolean = false;
    navModel: any;
    /* navModel: any = [
      {
        id: 'supportPanel',
        href: 'maxis-support-panel',
        label: 'Maxis Support Panel',
        disable: false,
        subnav: [],
      },
      {
        id: 'map',
        href: '#/home',
        label: 'Map',
        disable: false,
        subnav: [],
      },
      {
        id: 'dashboard',
        href: 'dashboard',
        label: 'Dashboard',
        disable: false,
        subnav: [],
      }, {
        id: 'reports',
        href: '#/reports',
        label: 'Reports',
        disable: false,
        subnav: [
          {
            label: 'Trip Summary',
            href: 'reports/trip/summary'
          }, {
            label: 'Violations',
            href: 'reports/trip/summary/violation'
          }, {
            label: 'Geofence',
            href: 'reports/geofence'
          }, {
            label: 'Utilisation',
            href: 'reports/utilization'
          }, {
            label: 'Scoring',
            href: 'reports/scoring'
          }, {
            label: 'Maintenance',
            href: 'reports/maintenance'
          }
        ]
      }, {
        id: 'manage',
        href: '#/manage',
        label: 'Create / Manage',
        disable: false,
        subnav: [
          {
            label: 'Vehicles',
            href: 'create-manage/vehicles'
          },
          {
            label: 'Drivers',
            href: 'create-manage/drivers'
          },
          {
            label: 'Admins',
            href: 'create-manage/admins'
          },
          {
            label: 'Groups',
            href: 'create-manage/groups'
          },
          {
            label: 'Maintenance',
            href: 'create-manage/maintenance'
          },
          {
            label: 'Driving Limits',
            href: 'create-manage/driving-limits'
          },
          {
            label: 'Trip Tags',
            href: 'create-manage/trip-tags'
          }
          // ,
          // {
          //   label: 'Assets(Ref)',
          //   href: 'create-manage/assets-ref'
          // },
          // {
          //   label: 'Drivers(Ref)',
          //   href: 'create-manage/drivers-ref'
          // }
          // ,
          // {
          //   label: 'Vehicles(Ref)',
          //   href: 'create-manage/vehicles-ref'
          // }
        ],
      }, {
        id: 'support',
        href: '#/support',
        label: 'Support',
        disable: true,
        subnav: [],
      }, {
        id: 'my-account',
        href: '#/my-account',
        label: 'My Account',
        disable: false,
        subnav: [
          {
            label: 'Edit Information',
            href: 'my-account/edit-information'
          }, {
            label: 'Change Password',
            href: 'my-account/change-password'
          }
        ]
      }, {
        id: 'log-out',
        href: '/login',
        label: 'Log Out',
        disable: false,
        subnav: [],
      }
    ] */

    msgBusSubscription: Subscription;
    constructor(
        private el: ElementRef,
        private tokenService: TokenService,
        private routerProxyService: RouterProxyService,
        private menuService: MenuService,
        private notificationService: NotificationService,
        private pagerService: PagerService,
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
        private msgbus: MessageBusService,
        private snackBar: SnackBarService,
        private changeDetector: ChangeDetectorRef,
        private accessControlService: AccessControlService
    ) {

        const busesPort = ["menu"];
        this.msgBusSubscription = this.msgbus.messagebus$.pipe(
            filter(message => busesPort.indexOf(message.source) != -1)
        ).subscribe(this.messageBusGetMessage.bind(this));
    }

    /* Message Controller*/
    async messageBusGetMessage(message: BusMessage) {
        switch (message.source) {
            case "menu":
                switch (message.messagetype) {
                    case "reload":
                        if (!this.isMenuLoaded || message.data === USER_ROLE.USER_ROLE.SUPER_SUPER_ADMIN.value) {
                            //reload the side menu when page refreshed or super super admin trigger manage account
                            await this.setNavigationMenu();
                        } else if (message.data && message.data.useRefToken === true) {
                            await this.setNavigationMenu(true);
                        }
                        break;
                }
                break;
            // case "system":
            //     switch (message.messagetype) {
            //         case "login":
            //             this.setLoginAndMenuInfo();
            //             break;
            //         // case "logout":
            //         //   this.isLoggedIn = false;
            //         //   this.hideNav();
            //         //   break;
            //     }
            //     break;
            // case "support":
            //     switch (message.messagetype) {
            //         case "companyName":
            //             this.companyName = message.data.companyName;
            //             this.isAdminSupportLogin = this.companyName !== null ? true : false;
            //             break;
            //     }
            //     break;
        }
    }

    async ngOnInit() {
        await this.setLoginAndMenuInfo();

        // Skip Notification Alert for Maxpert Support Role
        if (!this.isSupportManagementRole) {
            await this.getUserTotalAlertNotification();
            this.initTotalAlertTimer();
            this.checkSurveyPopup();
        }
    }

    async ngOnDestroy() {
        clearInterval(this.retrieveAlertCountTimer);
        // this.msgBusSubscription.unsubscribe();
    }

    toggleNav(): void {
        if (this.navIsShown) {
            this.hideNav();
        } else {
            this.showNav();
        }
    }

    showNav(): void {
        this.navIsShown = true;
        this.el.nativeElement.classList.add('--nav-is-shown');
        document.body.classList.add('--nav-is-shown');
    }

    hideNav(): void {
        this.navIsShown = false;
        this.el.nativeElement.classList.remove('--nav-is-shown');
        document.body.classList.remove('--nav-is-shown');
    }

    async sideMenuClickFunction(clickId, disabled, subnav, navId) {
        if (disabled) {
            return;
        }

        if (subnav.length) {
            if (this.currentSubnav === clickId) {
                this.currentSubnav = '';
            } else {
                this.currentSubnav = clickId;
            }
        } else {
            this.hideNav();
            const urlPath = this.navModel.filter(result => result.id === clickId).map(result => result.href);

            if (navId === 'log-out') {
                this.logoutPopup.show();
                // this.showLogoutPopup(urlPath);
            } else {
                if (navId === 'supportPanel') {
                    //clear reuse route path to avoid cache issue
                    CustomReuseStrategy.clearReuseRoutePath();

                    //if supportPanel menu triggered from account managed by super super admin
                    if (this.isSuperSuperAdminManagingAccount()) {
                        // remove administrator token so that jwt interceptor would use original user token
                        const token = await this.tokenService.removeAdministratorUser();
                        if (token) {
                            await this.authService.removeAdminitratorLogin(token);
                        }
                        //reinitialize permission
                        await this.accessControlService.initializePermissions();
                        //make it reload side menu
                        this.isMenuLoaded = false;
                    }
                }
                // console.debug('menu trigger -> ' + urlPath);
                this.routerProxyService.navigateByUrl(urlPath);
            }
        }
    }

    subNavMenuItemClickFunction(href: string) {
        this.hideNav();
        this.routerProxyService.navigateByUrl(href);
    }

    isVisible(nav: NavMenu) {
        const currentUser = LocalStorageUtil.localStorageGet('currentUser');
        const administratorUser = LocalStorageUtil.localStorageGet('administratorUser');

        if (currentUser && (currentUser.supportId === USER_ROLE.USER_ROLE.MAXIS_SUPPORT_B.supportId || currentUser.supportId === USER_ROLE.USER_ROLE.MAXIS_SALES_DEMO.supportId)) { // Maxis Support Type B
            if (administratorUser) {
                if (nav.navId === "my-account") {
                    return false;
                }
            }
            if (nav.isRequiredRefToken) {
                if (!administratorUser) {
                    return false;
                }
            }
        } else if (currentUser && currentUser.role === USER_ROLE.USER_ROLE.SUPER_SUPER_ADMIN.value && !administratorUser) {
            //only super super admin side menu come here, when super super admin manage other account, do nothing and show as normal admin menu
            if (nav.isRequiredRefToken) {
                return false;
            }
        }
        return true;
    }

    closeMenu(clickEvent?: Event): void {
        //Exclude the DOM that should not trigger this close menu by element Id
        // let excludedHtmlIds = [
        //     "menu", "menu-sub", "notification"
        // ];

        //Avoid floater auto-closed when clicked on toggler
        // if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
        //     return;
        // }

        //Hide the menu
        this.hideNav();
    }

    async setLoginAndMenuInfo() {
        const currentUser = LocalStorageUtil.localStorageGet('currentUser');
        if (currentUser) {
            // this.isLoggedIn = true;
            this.username = currentUser.firstName;
            await this.setNavigationMenu();
        }
    }

    isAdminSupportLogin() {
        if (this.tokenService.isAdministratorUserTokenExist()) {
            this.companyName = this.tokenService.getAdministratorUserDetails().companyName;
            return true;
        }
        return false;
    }

    async setNavigationMenu(useRefToken: boolean = false) {
        try {
            const menuResponse = await this.menuService.getNavigationMenu(useRefToken);
            //if is super super admin manage account, add support panel to side menu
            if (this.isSuperSuperAdminManagingAccount()) {
                const supportMenu = {
                    // id: pa.mId,
                    navId: 'supportPanel',
                    href: '/support/panel',
                    label: 'Maxis Support Panel',
                    disable: false,
                    isRequiredRefToken: false,
                    subnav: []
                };
                menuResponse.navMenu.unshift(supportMenu);
            }
            this.navModel = menuResponse.navMenu;
            this.isMenuLoaded = menuResponse.success;
        } catch (err) {
            // do nothing
        }
    }

    // showLogoutPopup(redirectUrl: string) {
    //     //show logout popup
    //     this.logoutPopup.setRedirectUrlAfterLogout(redirectUrl);
    //     this.logoutPopup.show();
    // }

    navigateToHomePage() {
        this.routerProxyService.navigateToHomePage();
    }

    // Notification Menu

    closeNotificationMenu(clickEvent?: Event) {
        if (this.isNotificationMenuFinishDisplay && Boolean(this.notificationField)) {
            this.closeNotification();
        }
    }

    closeNotification() {
        this.page = 1;
        this.notificationDisplayList = [];
        this.isViewMore = false;
        this.isShowNotification = false;
    }

    initTotalAlertTimer(): void {
        clearInterval(this.retrieveAlertCountTimer);
        this.retrieveAlertCountTimer = setInterval(
            this.timerTrigger.bind(this), this.retrieveAlertCountTimerIntervalInMilliSeconds);
    }

    timerTrigger() {
        this.getUserTotalAlertNotification();
        // this.checkSurveyPopup();
    }

    async loadMore() {
        if (this.pager.currentPage !== this.pager.totalPages) {
            this.page++;
            await this.getUserAlertNotification();
        }
    }

    async getUserAlertNotification() {
        try {
            this.isNotificationMenuFinishDisplay = false;
            if (this.isShowNotification) {
                this.changeDetector.detectChanges(); // Refresh to get View Child Element

                if (this.notification_spinner) {
                    this.notification_spinner.parentNode.style['min-height'] = ''; // Remove spinner min height
                    this.notification_spinner.show(); // Show Spinner
                }

                this.notificationRecentAlertCount = 0;
                const startRecord = ((this.page - 1) * this.pageRecordSize) + 1;

                const notificationResult: any = await this.notificationService.getUserAlertNotification(startRecord, this.pageRecordSize);
                if (notificationResult) {
                    this.notificationList = notificationResult.body.notificationList;
                    this.notificationTotalRecord = notificationResult.body.totalRecord;
                    this.pager = this.pagerService.getPager(this.notificationTotalRecord, this.page, this.pageRecordSize);

                    this.isViewMore = false; // Set False for default
                    if (this.pager.currentPage !== this.pager.totalPages) {
                        this.isViewMore = true;
                    }

                    if (this.notificationList.length) {
                        this.notificationList.map(async result => this.notificationDisplayList.push(Object.assign(await this.getNotificationMessage(result), result)));
                    }
                }

                // Delay for setting to prevent outside click close notification menu
                await AsyncUtil.wait(100);
                this.isNotificationMenuFinishDisplay = true;

            } else {
                this.closeNotification();
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            if (this.notification_spinner) { this.notification_spinner.hide(); }
        }
    }

    async getUserTotalAlertNotification() {
        try {
            const notificationTotalAlertCount: any = await this.notificationService.getUserTotalAlertNotification();
            if (notificationTotalAlertCount) {
                this.notificationRecentAlertCount = notificationTotalAlertCount.body.totalRecord;
                if (notificationTotalAlertCount.body.sessionStatus === 'LOGOUT') { await this.authService.logout(); }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        }
    }

    navigateToSettingPage() {
        this.closeNotification();
        this.router.navigateByUrl('/my-account/notification-settings');
    }

    navigateToPage(notificationType, urlPath) {

        // Check Url Path (Send Message to Vehicle Panel for Toggle Component)
        // if (urlPath.includes("map")) {
        //     if (urlPath.includes("vehicleStatus")) {
        //         this.msgbus.sendMessage(new BusMessage(
        //             "notification",
        //             "vehicleStatus",
        //             null
        //         ));
        //     } else {
        //         this.msgbus.sendMessage(new BusMessage(
        //             "notification",
        //             "overview",
        //             null
        //         ));
        //     }
        // }

        if (this.isClickEnable(notificationType)) {
            this.closeNotification();
            this.routerProxyService.navigateByUrl(urlPath);
        }
    }

    isClickEnable(notificationType) {
        if (this.roleId === USER_ROLE.USER_ROLE.DRIVER.roleId && notificationType === 'LICENSE_EXPIRY') {
            return false;
        } else {
            return true;
        }
    }

    async onNotificationScroll(event) {
        if (this.isViewMore && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            await this.loadMore();
        }
    }

    async getNotificationMessage(notificationList) {
        const notification = notificationList;
        const notificationType = notificationList.notificationType;
        let data: any = {};
        let urlPath = null;
        if (notificationType === this.imageConstant.constants.ALLOW_DRIVING_HOURS.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.DAILY_MILEAGE.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.DRIVING_SUMMARY.value) {
            // this.imageConstant.getNotificationMessage(notificationType, notification.vehicleName);
            //do nothing

        } else if (notificationType === this.imageConstant.constants.GEOFENCE.value) {
            const geofenceStatus = notification.notificationDescription.toLowerCase().includes("exit") ? "exited" : "entered";
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName, geofenceStatus, notification.geofenceName, moment(notification.notificationValue).format('YYYY-MM-DD hh:mm A'));
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.HARSH_ACCELERATION.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.HARSH_BRAKING.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.HARSH_TURNING.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.IDLING.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.POST_TRIP.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.SPEEDING.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.TRIP_START.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.LICENSE_EXPIRY.value) {
            const licenseExpiryDate = notification.notificationValue;
            notification.licenseType = notification.licenseType !== 'ROAD_TAX' && notification.licenseType !== 'PUSPAKOM' ? 'DRIVING_LICENSE' : notification.licenseType;
            data = await this.imageConstant.getNotificationMessageAndImagePath("LICENSE_EXPIRY", notification.vehicleName, "License", licenseExpiryDate, notification.licenseType);
            urlPath = await this.imageConstant.getNotificationUrlPath("LICENSE_EXPIRY", notification.driverId, notification.licenseType);

        } else if (notificationType === this.imageConstant.constants.VEHICLE_MAINTENANCE.value) {
            const maintenanceName = notification.notificationDescription;
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName, maintenanceName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.DEVICE_DISCONNECTED.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === "VEHICLE_HEALTH_ALERTS") {
            const notificationName = notification.notificationDescription.toLowerCase();

            if (notificationName.includes("battery")) {
                data = await this.imageConstant.getNotificationMessageAndImagePath("BATTERY", notification.vehicleName);
                urlPath = await this.imageConstant.getNotificationUrlPath("BATTERY", notification.vehicleImeiNo);

            } else if (notificationName.includes("temperature")) {
                data = await this.imageConstant.getNotificationMessageAndImagePath("TEMPERATURE", notification.vehicleName);
                urlPath = await this.imageConstant.getNotificationUrlPath("TEMPERATURE", notification.vehicleImeiNo);

                // } else if (notificationName.includes("license")) {
                //     let licenseExpiryDate = notification.notificationValue;
                //     data = await this.imageConstant.getNotificationMessageAndImagePath("LICENSE_EXPIRY", notification.vehicleName, "License", licenseExpiryDate);
                //     urlPath = await this.imageConstant.getNotificationUrlPath("LICENSE_EXPIRY", notification.vehicleId);

            } else {
                data = await this.imageConstant.getNotificationMessageAndImagePath("ENGINE", notification.vehicleName);
                urlPath = await this.imageConstant.getNotificationUrlPath("ENGINE", notification.vehicleImeiNo);
            }

        } else if (notificationType === this.imageConstant.constants.UNEXPECTED_FUEL_DROP.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.vehicleName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.DRIVER_TAG.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.driverName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else if (notificationType === this.imageConstant.constants.PANIC_BUTTON.value) {
            data = await this.imageConstant.getNotificationMessageAndImagePath(notificationType, notification.driverName);
            urlPath = await this.imageConstant.getNotificationUrlPath(notificationType, notification.vehicleImeiNo);

        } else {
            data = { message: "NO MESSAGE", imagePath: "NO IMAGE PATH" };
        }
        if (urlPath) {
            data = Object.assign(data, { urlPath: urlPath, isClickEnable: this.isClickEnable(notificationType) });
        }
        return data;
    }

    async getUserSurvey() {
        const result: any = await this.userService.getUserSurvey();
        if (result) {
            this.userSurveyStatus = result.body;
        }
    }

    async checkSurveyPopup() {
        await this.getUserSurvey();
        if (this.userSurveyStatus.length) {
            const firstSurvey = this.userSurveyStatus[0];
            const currTime = moment();
            if (firstSurvey.defaultFrequency != null) {
                //Have Fixed datetime for the survey Eg: every 24 hours display once
                const defFreqInMillisSecond = firstSurvey.defaultFrequency * 60 * 60 * 1000;
                if (firstSurvey.lastNotifyDate) {
                    const lastNotifyDate = moment(firstSurvey.lastNotifyDate, "YYYY-MM-DD HH:mm:ss");
                    if (currTime.diff(lastNotifyDate) >= defFreqInMillisSecond) {
                        this.popSurvey(firstSurvey);
                    }
                } else {
                    this.popSurvey(firstSurvey);
                }
            } else if (firstSurvey.customFrequency != null) {
                //Having Random datetime for the survey Eg: 2019-07-20, 2019-07-25, 2019-08-01
                let notifyTimeList: any = firstSurvey.customFrequency.split(",");

                //remove expired survey date for random datetime survey
                notifyTimeList = notifyTimeList.filter(eachTime => {
                    const verifyDate = moment(eachTime, "YYYY-MM-DD");
                    const currDate = moment(currTime.format("YYYY-MM-DD"), "YYYY-MM-DD");
                    if (verifyDate.diff(currDate) >= 0) {
                        return eachTime;
                    }
                });
                let lastNotifyDate: any = "";
                if (firstSurvey.lastNotifyDate) {
                    lastNotifyDate = moment(firstSurvey.lastNotifyDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
                    for (let i = 0; i < notifyTimeList.length; i++) {
                        const eachNotifyTime = moment(notifyTimeList[i], "YYYY-MM-DD");
                        if (eachNotifyTime.diff(lastNotifyDate) >= 0) {
                            this.popSurvey(firstSurvey);
                            break;
                        }
                    }
                } else {
                    this.popSurvey(firstSurvey);
                }
            }
        }
    }

    // Prepare survey content & show popUp
    popSurvey(surveyObj: any) {
        if (surveyObj.surveyStatus == 'COMPLETE') {
            return;
        }
        this.survey = {
            surveyTitle: surveyObj.surveyTitle,
            surveyContent: surveyObj.surveyContent,
            surveyUrl: surveyObj.url,
            surveyId: surveyObj.surveyId
        };
        this.surveyPopup.show('survey');
        this.isSurveyDisplayed = true;
    }

    // Mark complete survey when user click start survey
    startSurvey(url, surveyId) {
        this.surveyPopup.hide();
        const currDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
        this.updateSurveyStatus('COMPLETE', surveyId, currDateTime);
        window.open(url);
    }

    updateSurveyStatus(surveyStatus, surveyId, currDateTime) {
        this.userService.updateUserSurvey(surveyStatus, surveyId, currDateTime);
    }

    // Update Last Notify Date when user close survey
    closeSurvey(surveyId) {
        const currDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
        this.updateSurveyStatus('NOT_COMPLETE', surveyId, currDateTime);
    }

    isSuperSuperAdminManagingAccount() {
        let managing = false;
        const currentUser = this.authService.getCurrentUserDetails();
        const administratorUserExist = this.tokenService.isAdministratorUserTokenExist();
        if (administratorUserExist && currentUser && currentUser.role === USER_ROLE.USER_ROLE.SUPER_SUPER_ADMIN.value) {
            managing = true;
        }
        return managing;
    }
}
