import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { VehicleService } from '../../../../_services/vehicle/vehicle.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';

import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import * as moment from 'moment';
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as DomUtil from '../../../../util/domUtil';

@Component({
    selector: 'app-set-limit',
    templateUrl: './set-limit.component.html',
    styleUrls: ['./set-limit.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class SetLimitComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("SpeedLimit",{static:false}) SpeedLimit;

    color = 'primary';
    checked = false;
    disabled = false;

    // Schedule Form
    @ViewChild('vehicleAssign',{static:false}) vehicleAssign: VehicleSelectorComponent;

    timeSelector: {
        hour: string[],
        fullHour: string[],
        minute: string[],
        second: string[]
    } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private snackBar: SnackBarService,
        private vehicleService: VehicleService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    //Driving Limit Type
    speedLimit: string;
    allowedTimeFrom: string;
    allowedTimeTo: string;
    dailyMileageLimit: string;
    selectedVehiclesList = [];

    // Slide Bar Switch
    isSpeedLimitOff: boolean = false;
    isAllowedTimeOff: boolean = false;
    isDailyMileageLimitOff: boolean = false;

    //Maybe need to change
    allowedTimeFromHour = '';
    allowedTimeFromMinute = '';
    allowedTimeFromAMPM = 'AM';
    allowedTimeToHour = '';
    allowedTimeToMinute = '';
    allowedTimeToAMPM = 'AM';

    message = Message;

    async ngOnInit() {
        DomUtil.autoFocusForm(this.el);

        await this.checkUnviewedCampaign();

    }

    onChangeSlideBar(status, slideBarName) {
        this[slideBarName] = !status;
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async setDrivingLimit() {
        this.pageSpinner.show();
        try {
            this.speedLimit = this.isSpeedLimitOff ? String(this.speedLimit) : null;
            let allowedTimeFromT = `${this.allowedTimeFromHour}:${this.allowedTimeFromMinute} ${this.allowedTimeFromAMPM}`;
            let allowedTimeToT = `${this.allowedTimeToHour}:${this.allowedTimeToMinute} ${this.allowedTimeToAMPM}`;
            if (this.allowedTimeFromHour == "HH") {
                allowedTimeFromT = "";
            } else if (this.allowedTimeFromMinute == "MM") {
                allowedTimeFromT = "";
            }
            if (this.allowedTimeToHour == "HH") {
                allowedTimeToT = "";
            } else if (this.allowedTimeToMinute == "MM") {
                allowedTimeToT = "";
            }
            this.allowedTimeFrom = this.isAllowedTimeOff ? (allowedTimeFromT.length != 0 ? moment(allowedTimeFromT, "HH:mm A").format("HH:mm") : "") : null;
            this.allowedTimeTo = this.isAllowedTimeOff ? (allowedTimeToT.length != 0 ? moment(allowedTimeToT, "HH:mm A").format("HH:mm") : "") : null;
            this.dailyMileageLimit = this.isDailyMileageLimitOff ? String(this.dailyMileageLimit) : null;

            this.selectedVehiclesList = this.vehicleAssign.getSelectedVehicleIds();

            const result = await this.vehicleService.updateVehicleDrivingLimit(this.speedLimit, this.allowedTimeFrom, this.allowedTimeTo, this.dailyMileageLimit, this.selectedVehiclesList);
            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Vehicle', "Driving Limit");
            if (result) {
                if (result.statusCode == ResponseStatusCode.PARTIAL.code) {
                    msg = Message.getMessage(Message.MESSAGE.CREATE_PARTIAL.value, 'Vehicle', "Driving Limit");
                } else if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Vehicle', "Driving Limit");
                }
                this.navigateSuccessToDrivingLimitView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.pageSpinner.hide();
        }

    }

    navigateSuccessToDrivingLimitView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/driving-limits/view'], null, 'general');
    }

    navigateToDrivingLimitView() {
        this.routerProxyService.navigate(['/create-manage/driving-limits/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

}
