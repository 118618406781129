import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import 'hammerjs';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

/* Services */
import { AuthService, AuthGuardService } from './_services/auth';
import { JwtInterceptor } from './_services/interceptor/jwt.interceptor';
import { AlertTypeDataService } from './_services/alert-type/alert-type-data.service';
import { DriverService } from './_services/driver/driver.service';
import { DriverDataService } from './_services/driver/driver-data.service';
import { DriverTagService } from './_services/driver-tag/driver-tag.service';
import { FileExportService } from './_services/file-export/file-export.service';
import { MaintenanceService } from './_services/maintenance/maintenance.service';
import { PagerService } from './_services/pager/pager.service';
import { TripsService } from './_services/trips/trips.service';
import { GeofenceService } from './_services/geofence/geofence.service';
import { StatsService } from './_services/stats/stats.service';
import { RouterProxyService } from './_services/router-proxy/router-proxy.service';
import { SnackBarService } from './_services/snackBar/snack-bar.service';
// import { IconSnackBarComponent } from './_services/snackBar/icon-snack-bar.component';

/* Pipes */
import { DurationPipe } from './pipes/duration/duration.pipe';
import { FilterPipe } from './pipes/filter-pipe/filter.pipe';
import { FilterStatusPipe } from './pipes/filter-status/filter-status.pipe';
import { FilterVehicleGroupPipe } from './pipes/filter-vehicle-group/filter-vehicle-group.pipe';

/* Directives */
import { ToggleDirective } from './directives/toggle/toggle.directive';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { ClickOutsideManualDirective } from './directives/click-outside/click-outside-manual.directive';
import { DOMStatesDirective } from './directives/dom-states/dom-states.directive';
import { OverrideCssVarDirective } from './directives/override-css-variable/override-css-variable.directive';
import { OnlyNumberDirective } from './directives/only-number/only-number.directive';
import { RoleAccessDirective } from './directives/role-access/role-access.directive';
import { LineClampDirective } from './directives/line-clamp/line-clamp.directive';

/* Components */
//Site
import { EmptyComponent } from './components/site/empty/empty.component';
import { ErrorComponent } from './components/site/error/error.component';
import { LoginComponent } from './components/site/login/login.component';
import { SsoLoginComponent } from './components/site/sso-login/sso-login.component';
import { LogoutComponent } from './components/site/logout/logout.component';
import { SiteHeaderComponent } from './components/site/site-header/site-header.component';
import { SiteInfoComponent } from './components/site/site-info/site-info.component';
import { HomePageComponent } from './components/site/home-page/home-page.component';
import { RedirectAppDownloadComponent } from './components/site/redirect-app-download/redirect-app-download.component';
import { AppForceUpdateComponent } from './components/site/app-force-update/app-force-update.component';

//Common
import { DashboardComponent } from './components/modules/dashboard/dashboard.component';
import { SpinnerComponent } from './components/common/spinner/spinner.component';
import { PopupModule } from './components/common/popup/popup.module';
import { BreadcrumbComponent } from './components/common/breadcrumb/breadcrumb.component';
import { PopupCampaignComponent } from './components/common/campaignPopup/popup-campaign/popup-campaign.component';
import { SimpleMapPopupComponent } from "./components/common/simple-map-popup/simple-map-popup.component";
import { SlickCarouselModule } from 'ngx-slick-carousel';
//Map
import { MapComponent } from './components/modules/map/map.component';
import { MapListComponent } from './components/modules/map/map-list/map-list.component';
import { MapItemComponent } from './components/modules/map/map-item/map-item.component';

//Vehicle Panel
import { VehiclePanelComponent } from './components/modules/vehicle-panel/vehicle-panel.component';
import { TripslistComponent } from './components/modules/vehicle-panel/trip/tripslist/tripslist.component';
import { TripdayitemComponent } from './components/modules/vehicle-panel/trip/tripdayitem/tripdayitem.component';
import { VehicleHealthStatusComponent } from './components/modules/vehicle-panel/health-status/health-status.component';
import { VehicleDrivingLimitsComponent } from './components/modules/vehicle-panel/driving-limits/driving-limits.component';

//Report
import { TripSummaryComponent } from './components/modules/reports/trip-summary/trip-summary.component';
import { MaintenanceReportComponent } from './components/modules/reports/maintenance/maintenance-report.component';
import { ScoringReportComponent } from './components/modules/reports/scoring/scoring-report.component';
import { UtilizationReportComponent } from './components/modules/reports/utilization/utilization-report.component';
import { ViolationsReportComponent } from './components/modules/reports/violations/violations-reports.component';
import { GeofenceReportComponent } from './components/modules/reports/geofence-report/geofence-report.component';
import { RefuelLogsReportComponent } from './components/modules/reports/refuel-logs-report/refuel-logs-report.component';
import { FuelUsageReportComponent } from './components/modules/reports/fuel-usage-report/fuel-usage-report.component';
import { FuelReportComponent } from './components/modules/reports/fuel-report/fuel-report.component';
import { VehicleStatusReportComponent } from './components/modules/reports/vehicle-status-report/vehicle-status-report.component';
import { VehicleActivityReportComponent } from './components/modules/reports/vehicle-activity-report/vehicle-activity-report.component';
import { SpeedingReportComponent } from './components/modules/reports/speeding-report/speeding-report.component';
import { IdlingReportComponent } from './components/modules/reports/idling-report/idling-report.component';
import { VehicleRankingComponent } from './components/modules/reports/vehicle-ranking/vehicle-ranking.component';
import { AlertHistoryReportComponent } from './components/modules/reports/alert-history-report/alert-history-report.component';
import { DriverRankingComponent } from './components/modules/reports/driver-ranking/driver-ranking.component';
import { GeofenceMonitoringReportComponent } from './components/modules/reports/geofence-monitoring-report/geofence-monitoring-report.component';
import { VehicleTemperatureReportComponent } from './components/modules/reports/vehicle-temperature-report/vehicle-temperature-report.component';
import { IncidentAnalysisComponent } from './components/modules/reports/incident-analysis/incident-analysis.component';
import { CreateIncidentAnalysisComponent } from './components/modules/reports/incident-analysis/create-incident-analysis/create-incident-analysis.component';
import { ViewIncidentAnalysisComponent } from './components/modules/reports/incident-analysis/view-incident-analysis/view-incident-analysis.component';
import { RouteCompletionReportComponent } from './components/modules/reports/route-completion-report/route-completion-report.component';

//Customized Report
import { CustomizedUtilizationReportComponent } from './components/modules/reports/customized-utilization-report/customized-utilization-report.component'
import { CustomizedPanicButtonReportComponent } from './components/modules/reports/customized-panic-button-report/customized-panic-button-report.component';

//Groups
import { ViewGroupsComponent } from './components/modules/groups/view-groups/view-groups.component';
import { CreateGroupsComponent } from './components/modules/groups/create-groups/create-groups.component';
import { EditGroupComponent } from './components/modules/groups/edit-group/edit-group.component';

//Admin
import { ViewAdminsComponent } from './components/modules/admins/view-admins/view-admins.component';
import { CreateAdminComponent } from './components/modules/admins/create-admin/create-admin.component';
import { EditAdminComponent } from './components/modules/admins/edit-admin/edit-admin.component';
import { CreateRoleComponent } from './components/modules/admins/create-role/create-role.component';
import { EditRoleComponent } from './components/modules/admins/edit-role/edit-role.component';

//Driver
import { ViewDriversComponent } from './components/modules/drivers/view-drivers/view-drivers.component';
import { CreateDriverComponent } from './components/modules/drivers/create-driver/create-driver.component';
import { EditDriverComponent } from './components/modules/drivers/edit-driver/edit-driver.component';
import { DriverSelectorComponent } from './components/modules/drivers/driver-selector/driver-selector.component';
import { DriverTagSelectorComponent } from './components/modules/driver-tag-selector/driver-tag-selector.component';

//Driver Tag
import { ViewDriverTagsComponent } from './components/modules/driver-tag/view-driver-tags/view-driver-tags.component';
import { CreateDriverTagComponent } from './components/modules/driver-tag/create-driver-tag/create-driver-tag.component';

//Vehicle
import { ViewVehiclesComponent } from './components/modules/vehicles/view-vehicles/view-vehicles.component';
import { EditVehicleComponent } from './components/modules/vehicles/edit-vehicle/edit-vehicle.component';
import { AssignDriverComponent } from './components/modules/vehicles/assign-driver/assign-driver.component';
import { VehicleSelectorComponent } from './components/modules/vehicles/vehicle-selector/vehicle-selector.component';

//Maintenance
import { ViewMaintenanceComponent } from './components/modules/maintenances/view-maintenance/view-maintenance.component';
import { CreateMaintenanceComponent } from './components/modules/maintenances/create-maintenance/create-maintenance.component';
import { EditMaintenanceComponent } from './components/modules/maintenances/edit-maintenance/edit-maintenance.component';

//Trip Tags
import { ViewTripTagsComponent } from './components/modules/trip-tags/view-trip-tags/view-trip-tags.component';
import { CreateTripTagsComponent } from './components/modules/trip-tags/create-trip-tags/create-trip-tags.component';
import { EditTripTagsComponent } from './components/modules/trip-tags/edit-trip-tags/edit-trip-tags.component';

//Driving Limits
import { ViewDrivingLimitsComponent } from './components/modules/driving-limits/view-driving-limits/view-driving-limits.component';
import { SetLimitComponent } from './components/modules/driving-limits/set-limit/set-limit.component';
import { CreateGeofenceComponent } from './components/modules/driving-limits/create-geofence/create-geofence.component';
import { EditGeofenceComponent } from './components/modules/driving-limits/edit-geofence/edit-geofence.component';
import { CreateDestinationComponent } from './components/modules/driving-limits/create-destination/create-destination.component';
import { EditDestinationComponent } from './components/modules/driving-limits/edit-destination/edit-destination.component';

// Checkpoints
import { ViewRoutesComponent } from './components/modules/routes/view-routes/view-routes.component';
import { CreateRouteComponent } from './components/modules/routes/create-route/create-route.component';
import { EditRouteComponent } from './components/modules/routes/edit-route/edit-route.component';
import { CreateCheckpointComponent } from './components/modules/routes/create-checkpoint/create-checkpoint.component';
import { EditCheckpointComponent } from './components/modules/routes/edit-checkpoint/edit-checkpoint.component';

//Maxis Support Panel
import { MaxisSupportPanelComponent } from './components/modules/maxis-support-panel/maxis-support-panel.component';
import { CustomerViewComponent } from './components/modules/maxis-support-panel/customer-view/customer-view.component';
import { ResetPasswordComponent } from './components/modules/maxis-support-panel/reset-password/reset-password.component';
import { IdManagementComponent } from './components/modules/maxis-support-panel/id-management/id-management.component';
import { CreateSupportComponent } from './components/modules/maxis-support-panel/id-management/create-support/create-support.component';
import { EditSupportComponent } from './components/modules/maxis-support-panel/id-management/edit-support/edit-support.component';
import { ManageCampaignsComponent } from './components/modules/maxis-support-panel/manage-campaigns/manage-campaigns.component';
import { CreateCampaignsComponent } from './components/modules/maxis-support-panel/manage-campaigns/create-campaigns/create-campaigns.component';
import { EditCampaignsComponent } from './components/modules/maxis-support-panel/manage-campaigns/edit-campaigns/edit-campaigns.component';
import { ManageDeviceComponent } from './components/modules/maxis-support-panel/manage-device/manage-device.component';
import { CompanyDetailsComponent } from './components/modules/maxis-support-panel/manage-device/add-device/company-details/company-details.component';
import { VehicleDetailsComponent } from './components/modules/maxis-support-panel/manage-device/add-device/vehicle-details/vehicle-details.component';
import { EditCompanyDetailsComponent } from './components/modules/maxis-support-panel/manage-device/edit-multiple/company-details/edit-company-details.component';
import { EditVehicleDetailsComponent } from './components/modules/maxis-support-panel/manage-device/edit-multiple/vehicle-details/edit-vehicle-details.component';
import { UploadComponent } from './components/modules/maxis-support-panel/manage-device/add-device/upload/upload.component';
import { SaleDashboardViewComponent } from './components/modules/maxis-support-panel/sale-dashboard/sale-dashboard-view/sale-dashboard-view.component';
import { VehicleTerminationComponent } from './components/modules/maxis-support-panel/vehicle-termination/vehicle-termination.component';

//My Account
import { EditInformationComponent } from './components/modules/my-account/edit-information/edit-information.component';
import { ChangePasswordComponent } from './components/modules/my-account/change-password/change-password.component';
import { ActivateResetPasswordComponent } from './components/modules/user/activate-change-password/activate-change-password.component';
import { NotificationSettingsComponent } from './components/modules/my-account/notification-settings/notification-settings.component';

// Support User Guide
import { UserGuideComponent } from './components/modules/support/user-guide/user-guide.component';

// Fuel
import { ViewFuelComponent } from './components/modules/fuel/view-fuel/view-fuel.component';
import { SetFuelLimitsComponent } from './components/modules/fuel/set-fuel-limits/set-fuel-limits.component';
import { CreateRefuelEntryComponent } from './components/modules/fuel/create-refuel-entry/create-refuel-entry.component';

//Document Upload
import { ViewDocumentUploadComponent } from './components/modules/upload-documents/view-document-upload/view-document-upload.component';

/* Other Modules */
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { RequestInterceptor } from './_services/interceptor/request-interceptor';
import { HttpXSRFInterceptor } from "./_services/interceptor/http-xsrf.interceptor";
import { ErrorHandlerModule } from './_services/error-handler';
import { TestImageComponent } from './components/modules/test-image/test-image.component';
import { GlobalErrorHandlerModule } from './_services/global-error-handler';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './app-routing-custom-reuse-strategy';

/* Firebase */
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

/* Ionic */
import { FcmService } from './shared/service/fcm.service';
import { ToastService } from './shared/service/toast.service';
import { Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { DragulaModule } from 'ng2-dragula';
import { OverviewComponent } from './components/modules/reports/overview/overview.component';
import { RefuelLogsSensorComponent } from './components/modules/reports/refuel-logs-sensor/refuel-logs-sensor.component';
import { PerformanceComponent } from './components/modules/drivers/edit-driver/performance/performance.component';
import { SwitchProviderComponent } from './components/modules/maxis-support-panel/manage-device/switch-provider/switch-provider.component';
import { CustomizedDriverAnalysisReportComponent } from './components/modules/reports/customized-driver-analysis-report/customized-driver-analysis-report.component';


// Azure AD login
import { MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { PublicClientApplication } from "@azure/msal-browser";
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
    declarations: [
        /* Directives */
        ClickOutsideDirective,
        ClickOutsideManualDirective,
        DOMStatesDirective,
        ToggleDirective,
        OverrideCssVarDirective,
        OnlyNumberDirective,
        RoleAccessDirective,
        LineClampDirective,
        /* Pipes */
        DurationPipe,
        FilterPipe,
        FilterStatusPipe,
        FilterVehicleGroupPipe,
        /* Components */
        AppComponent,
        SiteHeaderComponent,
        SiteInfoComponent,
        LoginComponent,
        SsoLoginComponent,
        LogoutComponent,
        BreadcrumbComponent,
        EmptyComponent,
        HomePageComponent,
        SpinnerComponent,
        ErrorComponent,
        DashboardComponent,
        RedirectAppDownloadComponent,
        AppForceUpdateComponent,
        //map
        SimpleMapPopupComponent,
        MapComponent,
        MapListComponent,
        MapItemComponent,
        VehiclePanelComponent,
        TripslistComponent,
        TripdayitemComponent,
        VehicleHealthStatusComponent,
        VehicleDrivingLimitsComponent,
        //reports
        TripSummaryComponent,
        MaintenanceReportComponent,
        ScoringReportComponent,
        ViolationsReportComponent,
        UtilizationReportComponent,
        GeofenceReportComponent,
        RefuelLogsReportComponent,
        FuelUsageReportComponent,
        FuelReportComponent,
        VehicleStatusReportComponent,
        VehicleActivityReportComponent,
        SpeedingReportComponent,
        IdlingReportComponent,
        VehicleRankingComponent,
        AlertHistoryReportComponent,
        DriverRankingComponent,
        GeofenceMonitoringReportComponent,
        VehicleTemperatureReportComponent,
        IncidentAnalysisComponent,
        CreateIncidentAnalysisComponent,
        ViewIncidentAnalysisComponent,
        RouteCompletionReportComponent,
        CustomizedUtilizationReportComponent,
        CustomizedDriverAnalysisReportComponent,
        CustomizedPanicButtonReportComponent,
        //general
        TestImageComponent,
        //admins
        ViewAdminsComponent,
        CreateAdminComponent,
        EditAdminComponent,
        CreateRoleComponent,
        EditRoleComponent,
        //driver
        ViewDriversComponent,
        CreateDriverComponent,
        EditDriverComponent,
        DriverSelectorComponent,
        DriverTagSelectorComponent,
        //driver tag
        ViewDriverTagsComponent,
        CreateDriverTagComponent,
        //vehicle
        ViewVehiclesComponent,
        EditVehicleComponent,
        AssignDriverComponent,
        VehicleSelectorComponent,
        //group
        ViewGroupsComponent,
        CreateGroupsComponent,
        EditGroupComponent,
        //maintenance
        ViewMaintenanceComponent,
        CreateMaintenanceComponent,
        EditMaintenanceComponent,
        //trip
        ViewTripTagsComponent,
        CreateTripTagsComponent,
        EditTripTagsComponent,
        //driving limit
        ViewDrivingLimitsComponent,
        SetLimitComponent,
        CreateGeofenceComponent,
        EditGeofenceComponent,
        CreateDestinationComponent,
        EditDestinationComponent,
        // routes
        ViewRoutesComponent,
        CreateRouteComponent,
        EditRouteComponent,
        CreateCheckpointComponent,
        EditCheckpointComponent,
        //maxis support
        MaxisSupportPanelComponent,
        CustomerViewComponent,
        ResetPasswordComponent,
        ManageCampaignsComponent,
        CreateCampaignsComponent,
        EditCampaignsComponent,
        IdManagementComponent,
        CreateSupportComponent,
        EditSupportComponent,
        ActivateResetPasswordComponent,
        //my account
        EditInformationComponent,
        ChangePasswordComponent,
        NotificationSettingsComponent,
        UserGuideComponent,
        //fuel
        ViewFuelComponent,
        SetFuelLimitsComponent,
        CreateRefuelEntryComponent,
        PopupCampaignComponent,
        ManageDeviceComponent,
        CompanyDetailsComponent,
        VehicleDetailsComponent,
        EditCompanyDetailsComponent,
        EditVehicleDetailsComponent,
        OverviewComponent,
        RefuelLogsSensorComponent,
        PerformanceComponent,
        UploadComponent,
        SaleDashboardViewComponent,
        SwitchProviderComponent,
        VehicleTerminationComponent,

        //Document Upload
        ViewDocumentUploadComponent,

        //others
        // IconSnackBarComponent
    ],
    imports: [
        ImageCropperModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatIconModule,
        PopupModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        NgCircleProgressModule.forRoot({
            radius: 90,
            space: -15,
            outerStrokeWidth: 15,
            outerStrokeColor: "#aeaeae",
            innerStrokeWidth: 15,
            innerStrokeColor: "#f5f5f5",
            titleFontSize: "45",
            unitsFontSize: "45",
            titleColor: "#aeaeae",
            unitsColor: "#aeaeae",
            animateTitle: false,
            animationDuration: 1000,
            showTitle: true,
            showSubtitle: false,
            showUnits: false,
            showBackground: false,
            responsive: true,
            outerStrokeLinecap: "butt"
        }),
        MatPasswordStrengthModule.forRoot(),
        // NgxMaterialTimepickerModule
        DragulaModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        AngularFirestoreModule,
        SlickCarouselModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: environment.azureAD.clientId,
                    authority: environment.azureAD.authority,
                    redirectUri: "/login/sso",
                    postLogoutRedirectUri: '/'
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
                },
            }),
            null,
            null
        ),
    ],
    providers: [
        /* Base */
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy
        },
        /* Services */
        AuthGuardService,
        AuthService,
        RouterProxyService,
        FileExportService,
        PagerService,
        AlertTypeDataService,
        StatsService,
        DriverService,
        DriverDataService,
        DriverTagService,
        MaintenanceService,
        GeofenceService,
        TripsService,
        SnackBarService,
        /* Mobile */
        Keyboard,
        Platform,
        SplashScreen,
        StatusBar,
        FcmService,
        ToastService,
        Device,
        AppVersion,
        File,
        FileOpener,
        FileTransfer,
        LaunchNavigator,
        ToastController,
        AlertController,
        InAppBrowser,
        /* Handlers */
        GlobalErrorHandlerModule,
        ErrorHandlerModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpXSRFInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerModule
        },
        { provide: LOCALE_ID, useValue: 'en-GB' }
    ],
    // entryComponents: [
    //     IconSnackBarComponent,
    // ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }
