import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Platform } from '@ionic/angular';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import * as moment from 'moment';
import { environment } from './../../../../../environments/environment';

import { UploadComponent } from './../../maxis-support-panel/manage-device/add-device/upload/upload.component';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';

import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { DocumentService } from './../../../../_services/upload-documents/upload-documents.service';

import * as DateTimeUtil from './../../../../util/dateTimeUtil';
import * as DomUtil from './../../../../util/domUtil';

import { modules } from './../../../../constants/module-access.constant';
import * as Message from './../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { ERROR_MESSAGE as ErrorMessage } from './../../../../constants/errorMessage';

@Component({
    selector: 'app-view-document-upload',
    templateUrl: './view-document-upload.component.html',
    styleUrls: ['./view-document-upload.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ViewDocumentUploadComponent implements OnInit {

    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    documentList: any = [];
    sortField = 'FileName';
    sortAscending = true;
    filterKey = 'FileName';
    filterValue = '';
    currentFilterValue = '';

    pager: any = {};
    pageRecordSize = environment.appConfig.createManage.pageRecordSize;

    creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    message = Message;
    moment = moment;

    /* ----- Documents ----- */
    // Document Selected
    selectedDocument: any = {};
    @ViewChild('newFileUpload',{static:false}) newFileUpload: UploadComponent;
    @ViewChild('existFileUpload',{static:false}) existFileUpload: UploadComponent;
    selectedDocumentGroups: any = {};

    /* ----- Create Document ----- */
    isCreate: string = "CREATE";

    /* ----- Edit Document ----- */
    customFileType: Array<string> = ["xlsx", "xls", "csv", "pdf", "jpg", "jpeg", "png", "bmp", "docx", "txt", "pptx", "gz", "rar", "7z"];
    isEdit: string = "EDIT";
    isDocChanged: Boolean = false;

    /* ----- Delete Document ----- */
    isDeletingDocument: boolean;

    /* ----- Download Document ----- */
    @ViewChild('DocumentDownloadedPopup') documentDownloadedPopup;
    documentDownloadedMsg: string;
    /* ----- Temp variable ----- */
    directory: string;
    saveRes: any;


    constructor(
        private popupService: PopupService,
        private pagerService: PagerService,
        private documentService: DocumentService,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService,
        private snackBar: SnackBarService,
        private mpService: ModulePermissionService,
        private platform: Platform,
        private file: File,
        private fileOpener: FileOpener
    ) { }

    async ngOnInit() {
        await this.checkUnviewedCampaign();
        const permissions = await this.mpService.hasPermission(modules.CRUD_DRIVING_LIMIT.value);
        this.creatable = permissions[modules.CRUD_DRIVING_LIMIT.value].cAccess;
        this.editable = permissions[modules.CRUD_DRIVING_LIMIT.value].eAccess;
        this.deletable = permissions[modules.CRUD_DRIVING_LIMIT.value].dAccess;

        await this.generalDocuments();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async searchDocumentName() {
        this.currentFilterValue = this.filterValue;
        await this.getDocuments();
    }

    async getDocuments(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            const documentListResult = await this.documentService.getDocumentList(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            this.documentList = documentListResult.body.result;
            this.pager = this.pagerService.getPager(documentListResult.body.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.documentList.forEach(document => {
                document.dateCreated = `${this.formateDateLabel(document.dateCreated)}`;
                document.dateUpdated = `${this.formateDateLabel(document.dateUpdated)}`;
                document.creatorFullName = (document.creatorFirstName || document.creatorLastName) ?
                    `${document.creatorFirstName ? document.creatorFirstName : ''} ${document.creatorLastName ? document.creatorLastName : ''}` : null;
                document.editorFullName = (document.editorFirstName || document.editorLastName) ?
                    `${document.editorFirstName ? document.editorFirstName : ''} ${document.editorLastName ? document.editorLastName : ''}` : null;
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async generalDocuments(filterValue = '') {
        this.sortField = 'FileName';
        this.sortAscending = true;
        this.filterValue = filterValue || ''; // Empty Text Box
        this.currentFilterValue = filterValue || '';
        await this.getDocuments();
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }

        await this.getDocuments();
    }

    getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    countSelectedDocuments() {
        return Object.keys(this.selectedDocument).filter(result => this.selectedDocument[result] === true).length;
    }

    async searchDocument() {
        this.currentFilterValue = this.filterValue;
        await this.getDocuments();
    }

    onDocumentAllSelectChange() {
        const allIsSelected = this.selectedDocumentGroups.all;
        for (let i = 0; i < this.documentList.length; i++) {
            const documentModel = this.documentList[i];
            this.selectedDocument[documentModel.documentId] = allIsSelected;
        }
    }

    onDocumentSelectChange(drivingLimitSelected: boolean) {
        if (drivingLimitSelected) {
            // this.selectedDrivingLimit[drivingLimitModel.vehicleId] = true;
        } else {
            this.selectedDocumentGroups['all'] = false;
        }
    }

    cancelResetDelete() {
        this.isDeletingDocument = false;
        this.selectedDocument = {};
        this.selectedDocumentGroups['all'] = false;
    }

    async deleteDocument() {
        this.pageSpinner.show();
        try {
            const selectedDocumentList = [];
            for (const key in this.selectedDocument) {
                if (this.selectedDocument[key] === true) {
                    selectedDocumentList.push(parseInt(key));
                }
            }

            const result = await this.documentService.deleteDocument(selectedDocumentList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Document');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Document');

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getDocuments();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateToSetLimit() {
        this.routerProxyService.navigate(['/create-manage/upload-documents/create']);
    }

    onDocumentClick(documentId) {
        this.routerProxyService.navigate([`/create-manage/upload-documents/edit/${documentId}`]);
    }

    // Save document to device by native(hybrid) App/mobile browser/desktop browser from Ionic Framework
    async downloadDocument(documentId) {
        const result = await this.documentService.downloadDocument(documentId);
        //Step 1: Convert base64 file to buffer
        const base64String = result.body.buffer;
        const fileBuffer = Buffer.from(result.body.buffer, 'base64');
        let filename = result.body.fileName;
        //Step 2: Convert buffer to blob
        let fileBlob = new Blob([fileBuffer]);
        try {
            //Step 3: Create download for Android and IOS if belong to mobile platform
            if (DomUtil.isMobileApp(this.platform)) {
                console.debug("Mobile Platform detected...");
                // Wait for Ionic platform to ready before using it
                this.platform.ready().then(() => {
                    let fileExist = false;
                    let fileNameIndex = 0;
                    //Step 3-1: Get IOS/Android file directory part
                    let directory = (DomUtil.isMobileApp(this.platform, 'ios')) ? this.file.documentsDirectory : this.file.externalDataDirectory;
                    do {
                        if (fileNameIndex != 0) {
                            filename = filename.substring(0, filename.lastIndexOf(".")) + `(${fileNameIndex})` + filename.substring(filename.lastIndexOf("."));
                        }
                        //Step 3-2: Try Save the file to the directory
                        this.file.writeFile(directory, filename, fileBlob)
                            .then((success) => {
                                console.debug(`Download Success: [IOS:${DomUtil.isMobileApp(this.platform, 'ios')},Android:${DomUtil.isMobileApp(this.platform, 'android')}] Location: [${directory + filename}]`);
                                this.documentDownloadedMsg = Message.MESSAGE.DOCUMENT_DOWNLOAD_SUCCESS.label;
                                this.showPopup(this.documentDownloadedPopup);
                                fileExist = false;// disabled do while loop if success
                            })
                            .catch((error) => {
                                console.debug(`Download Failed: [IOS:${DomUtil.isMobileApp(this.platform, 'ios')},Android:${DomUtil.isMobileApp(this.platform, 'android')}] Location: [${directory + filename}]`);
                                console.debug(error);
                                //Step 3-3: Retry for existing file error
                                if (error && error.code == 12 && error.message == "PATH_EXISTS_ERR") {
                                    fileNameIndex++;
                                    console.debug(`File exist...Shallow error, Retry to create new file with "(${filename})"...`)
                                    fileExist = true;
                                } else {
                                    const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.FILE_DOWNLOAD_FAILED);
                                    this.snackBar.openGenericSnackBar(msg);
                                }
                            });
                    } while (fileExist != false);
                });
            } else {
                console.debug("Desktop/Mobile Browser Platform detected...");
                //Step 4: Desktop/Mobile-Browser Create download url
                const url = window.URL.createObjectURL(fileBlob);
                //Step 4-1: Inject a new download dom element
                const downloadLink = document.createElement('a');
                if (downloadLink.download !== undefined) {
                    downloadLink.setAttribute('href', url);
                    downloadLink.setAttribute('download', filename);
                    downloadLink.style.visibility = 'hidden';
                    document.body.appendChild(downloadLink);
                    //Step 4-2: Auto click the dom to open download dialog
                    downloadLink.click();
                    //Step 4-3: Remove dom element after download completed
                    document.body.removeChild(downloadLink);
                }
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    formateDateLabel(dateTime) {
        const currFormat = "YYYY-MM-DD hh:mm:ss";
        const desireFormat = "DD MMM YYYY hh:mm A";
        if (dateTime == null) {
            return '';
        } else {
            return DateTimeUtil.getDateLabel(dateTime, currFormat, desireFormat);
        }
    }

    async uploadDocument() {
        this.pageSpinner.show();
        try {
            const fileInBytes = [];
            const fileReader = new FileReader();
            for (let i = 0; i < this.newFileUpload.fileUploadList.length; i++) {
                const file = this.newFileUpload.fileUploadList[i];
                const reader = new Promise((resolve, reject) => {
                    fileReader.readAsDataURL(file);
                    fileReader.onload = async (e) => {
                        resolve(fileReader.result);
                    };
                });
                const base64 = await reader;
                const formattedFile = {
                    fileByte: base64,
                    fileName: file.name.replace(/\s/g, "-")
                };
                fileInBytes.push(formattedFile);
            }
            const result = await this.documentService.uploadDocument(fileInBytes);

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Document');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Document');
                this.closeFileUploadPopup();
                this.getDocuments();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);

        } finally {
            this.pageSpinner.hide();
        }
    }

    refreshList() {
        // Force page refresh
        this.closeFileUploadPopup();
    }

    /*Edit Document Functions */
    async editDocument(documentId) {
        const selectedDoc = this.documentList.find(eachDoc => { return eachDoc.documentId == documentId; });
        this.selectedDocument = selectedDoc;
        const uploadDocumentObj = {
            name: selectedDoc.fileName
        }
        this.existFileUpload.fileUploadList = [uploadDocumentObj];
    }

    async isDocChange(value) {
        this.isDocChanged = value;
    }

    async getUploadedDocument(id) {
        // get the file from s3 if needed
    }

    async closeFileUploadPopup() {
        //clear uploaded file
        if (this.isCreate)
            this.newFileUpload.fileUploadList = [];
        if (this.isEdit)
            this.existFileUpload.fileUploadList = [];
        // this.fileUpload.fileUploadList = [];
        this.isDocChanged = false;
        this.selectedDocument = {};
        this.hidePopup();
    }

    async editUploadedDocument() {
        this.pageSpinner.show();
        // Read and process uplaoded document
        try {
            const fileInBytes = [];
            const fileReader = new FileReader();
            for (let i = 0; i < this.existFileUpload.fileUploadList.length; i++) {
                const file = this.existFileUpload.fileUploadList[i];
                const reader = new Promise((resolve, reject) => {
                    fileReader.readAsDataURL(file);
                    fileReader.onload = async (e) => {
                        resolve(fileReader.result);
                    };
                });
                const base64 = await reader;
                const formattedFile = {
                    fileByte: base64,
                    fileName: file.name
                };
                fileInBytes.push(formattedFile);
            }

            // From API Request Body
            const documentObj = {
                id: this.selectedDocument.documentId,
                fileName: this.selectedDocument.fileName,
                urlPath: this.selectedDocument.urlPath,
                fileInBytes: fileInBytes
            }
            const uploadDocumentRes = await this.documentService.editUploadedDocument(documentObj);
            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Document');
            if (uploadDocumentRes && uploadDocumentRes.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Document');

                this.closeFileUploadPopup();
                await this.getDocuments();
            }
            this.snackBar.openGenericSnackBar(msg);
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.pageSpinner.hide();
        }
    }
    /*End of Edit Document Functions*/

    async submitDocument(fromFilePopup) {
        if (fromFilePopup == this.isCreate) {
            await this.uploadDocument();
        }
        if (fromFilePopup == this.isEdit) {
            await this.editUploadedDocument();
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

}