import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, ElementRef, HostListener } from '@angular/core';
import { PopupService } from './../../../../components/common/popup/popup.service';
import * as DomUtil from './../../../../util/domUtil';
import { Platform } from '@ionic/angular';
import { CampaignServiceService } from './../../../../_services/campaign/campaign-service.service';

declare var $: any;

@Component({
    selector: 'popup-campaign',
    templateUrl: './popup-campaign.component.html',
    styleUrls: ['./popup-campaign.component.scss']
})
export class PopupCampaignComponent implements OnInit {

    @Input() unviewedCampaigns: Array<any> = [];
    @Input() test: string = "";
    @ViewChild('slickModal', { static: false }) slickModal;
    @ViewChild('supportPopups', { static: false }) supportPopups;

    allUnviewedCampaigns: Array<any> = [];
    // slides = [
    //     {
    //         image: "/assets/images/scss-components/gfx/gfx-slider.jpg",
    //         title: "Testing 123",
    //         description: "This is testing campaigns",
    //         currentIndex: 0
    //     }, {
    //         image: "/assets/images/scss-components/gfx/gfx-slider.jpg",
    //         title: "Testing 456",
    //         description: "What will we have in the testing",
    //         currentIndex: 1
    //     }, {
    //         image: "/assets/images/scss-components/gfx/gfx-slider.jpg",
    //         title: "Testing 789",
    //         description: "Lorem Ipsum is simply dumm text of the printing and typesetting industry. Lorem Ipsum has been the industry��s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    //         currentIndex: 2
    //     }, {
    //         image: "/assets/images/scss-components/gfx/gfx-slider.jpg",
    //         title: "Testing 321",
    //         description: "Lorem Ipsum is simply dumm text of the printing and typesetting industry. Lorem Ipsum has been the industry��s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    //         currentIndex: 3
    //     }
    // ];

    slideConfig = {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "dots": true,
        "arrows": true,
        "infinite": false
    };
    nextOrCloseBtn: string = "Next";
    isCampaignFirstItem: boolean = true;
    isOnlySingleImage: boolean = false;
    onClickClosed: string = "";
    campaignIdList: Array<Number> = [];

    constructor(
        private popupService: PopupService,
        private changeDetectorRef: ChangeDetectorRef,
        private platform: Platform,
        private el: ElementRef,
        private campaignServiceService: CampaignServiceService
    ) {

    }

    async ngOnInit() {
        let previousCampaignId: Number = null;
        this.allUnviewedCampaigns = this.unviewedCampaigns.map((eachCampaign, index) => {
            if (previousCampaignId !== eachCampaign.campaignId) {
                this.campaignIdList.push(eachCampaign.campaignId);
            }
            previousCampaignId = eachCampaign.campaignId;
            return {
                // image: "/assets/images/map_overlay_image/discoverypark_map.png",
                image: (DomUtil.isMobile(this.platform)) ? eachCampaign.mobileImage : eachCampaign.desktopImage,
                title: eachCampaign.title,
                description: eachCampaign.description,
                currentIndex: index
            };
        });
        if (this.campaignIdList.length > 0) {
            await this.insertViewedCampaigns();
        }

        if (this.allUnviewedCampaigns.length == 1) {
            this.changeButton(true, "Close");
        } else {
            // hide it first when campaign popup is loaded
            this.el.nativeElement.getElementsByClassName("slick-prev")[0].hidden = true;
        }
    }
    async ngAfterViewInit() {
        this.showPopup(this.supportPopups);
    }
    async insertViewedCampaigns() {
        const _this = this;
        await setTimeout(function () {
            _this.campaignServiceService.insertViewedCampaigns(_this.campaignIdList);
        }, 5 * 1000);
        // this.campaignServiceService.insertViewedCampaigns(this.campaignIdList);
    }

    // to detect user click, only when the campaign popup is show. to manipulate the next/prev button display
    @HostListener('click')
    async onMouseEnter() {
        const currCampSequenceResult = await this.checkCurrentCampaignSequence();
        await this.hideOrUnhideNextPrevButton(currCampSequenceResult.btnList);
        await this.getNextPrevBtnHidden();
    }

    hideOrUnhideNextPrevButton(hideOrUnhideBtnList) {
        if (this.allUnviewedCampaigns.length > 1) {
            hideOrUnhideBtnList.map(eachBtn => {
                this.el.nativeElement.getElementsByClassName(eachBtn.name)[0].hidden = eachBtn.hide;
            });
        }
    }

    checkCurrentCampaignSequence() {
        // get current viewing items
        const currActiveSlick = this.el.nativeElement.getElementsByClassName("slick-current");
        const hideOrUnhide = [{ name: "slick-next", hide: false }, { name: "slick-prev", hide: false }];
        let currActSlickSequence = 0; // 0 = first, 1 = middle, 2 = last
        for (let i = 0; i < currActiveSlick.length; i++) {
            // locate currActiveSlickIndex label
            if (currActiveSlick[i].getElementsByTagName('label').hiddenActiveIndex) {
                // get index number
                const currActiveSlickIndex = parseInt(currActiveSlick[i].getElementsByTagName('label').hiddenActiveIndex.innerHTML);
                if (currActiveSlickIndex == this.allUnviewedCampaigns.length - 1) {
                    // hide next button if reach last campaign's last item
                    hideOrUnhide[0].hide = true;
                    currActSlickSequence = 2;

                } else if (currActiveSlickIndex == 0) {
                    // hide previous button if reach 1st campaign's 1st item
                    hideOrUnhide[1].hide = true;
                    currActSlickSequence = 0;

                } else {
                    // dont hide any button between 1st campaign's 1st item and last campaign's last item
                    currActSlickSequence = 1;

                }
            }
        }
        return { btnList: hideOrUnhide, currActiveSlickSequence: currActSlickSequence };
    }

    changeButton(isHideRequired, nextBtnText) {
        if (this.allUnviewedCampaigns.length === 1) {
            this.isCampaignFirstItem = true;
            this.nextOrCloseBtn = "Close";
        } else {
            this.isCampaignFirstItem = isHideRequired;
            this.nextOrCloseBtn = nextBtnText;
        }
    }

    getNextPrevBtnHidden() {
        if (this.allUnviewedCampaigns.length > 1) {
            const isNextBtnHide = this.el.nativeElement.getElementsByClassName('slick-next')[0].hidden;
            const isPrevBtnHide = this.el.nativeElement.getElementsByClassName('slick-prev')[0].hidden;
            if (!isNextBtnHide && isPrevBtnHide) {
                this.changeButton(true, "Next");
            } else if (!isNextBtnHide && !isPrevBtnHide) {
                this.changeButton(false, "Next");
            } else if (isNextBtnHide && !isPrevBtnHide) {
                this.changeButton(false, "Close");
            }
        }
    }

    async next() {
        const currCampSequenceResult = await this.checkCurrentCampaignSequence();
        if (currCampSequenceResult.currActiveSlickSequence !== 2) {
            this.slickModal.slickNext();
        } else if (currCampSequenceResult.currActiveSlickSequence === 2) {
            this.hidePopup();
        }
        this.getNextPrevBtnHidden();
        return;
    }

    async prev() {
        const currCampSequenceResult = await this.checkCurrentCampaignSequence();
        if (currCampSequenceResult.currActiveSlickSequence != 0) {
            this.slickModal.slickPrev();
        }
        this.getNextPrevBtnHidden();
        return;
    }

    showPopup(popup) {
        this.popupService.show(popup);
        this.changeDetectorRef.detectChanges(); //to tell angular to update the latest changes
        $(this.slickModal.el.nativeElement).slick('setPosition');
    }

    hidePopup() {
        this.popupService.hide();
    }

}
