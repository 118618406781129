import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
// import { environment } from './../../../../../environments/environment';

import { PopupService } from '../../../common/popup/popup.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';
import { TagService } from '../../../../_services/tag/tag.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';

import * as moment from 'moment';
import * as ObjectUtil from './../../../../util/objectUtil';
import * as DomUtil from './../../../../util/domUtil';
import { ToggleDirective } from './../../../../directives/toggle/toggle.directive';
import * as StringUtil from './../../../../util/stringUtil';

import * as Message from '../../../../constants/message';
import { COLOR_PICKER } from './../../../../constants/color-picker.constant';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

@Component({
    selector: 'app-create-trip-tags',
    templateUrl: './create-trip-tags.component.html',
    styleUrls: ['./create-trip-tags.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CreateTripTagsComponent implements OnInit {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    newTagDetails = {
        tagName: '',
        tagColor: { no: 1, code: COLOR_PICKER.ONE },
    };

    minDate: any = moment().format();
    message = Message;
    COLOR_PICKER: any = COLOR_PICKER;

    colorPickerToggle: ToggleDirective = new ToggleDirective(null);
    array20: number[] = ObjectUtil.fillRange(20);

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private popupService: PopupService,
        private tagService: TagService,
        private el: ElementRef,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService

    ) { }

    ngOnInit() {
        DomUtil.autoFocusForm(this.el);
        this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    toggleColor(color: number) {
        this.newTagDetails.tagColor.no = color;
        if (color === 1) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.ONE;
        } else if (color === 2) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.TWO;
        } else if (color === 3) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.THREE;
        } else if (color === 4) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.FOUR;
        } else if (color === 5) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.FIVE;
        } else if (color === 6) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.SIX;
        } else if (color === 7) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.SEVEN;
        } else if (color === 8) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.EIGHT;
        } else if (color === 9) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.NINE;
        } else if (color === 10) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.TEN;
        } else if (color === 11) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.ELEVEN;
        } else if (color === 12) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.TWELVE;
        } else if (color === 13) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.THIRTEEN;
        } else if (color === 14) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.FOURTEEN;
        } else if (color === 15) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.FIFTEEN;
        } else if (color === 16) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.SIXTEEN;
        } else if (color === 17) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.SEVENTEEN;
        } else if (color === 18) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.EIGHTEEN;
        } else if (color === 19) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.NINETEEN;
        } else if (color === 20) {
            this.newTagDetails.tagColor.code = COLOR_PICKER.TWENTY;
        }
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    navigateSuccessToTripTagView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/trip-tags/view']);
    }

    navigateToTripTagView() {
        this.routerProxyService.navigate(['/create-manage/trip-tags/view']);
    }

    async createTag() {
        this.pageSpinner.show();
        try {
            for (const objKey in this.newTagDetails) {
                if (this.newTagDetails[objKey] == '' || this.newTagDetails[objKey] == null || this.newTagDetails[objKey] == undefined) {
                    delete this.newTagDetails[objKey];
                }
            }

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.newTagDetails.tagName)) {
                this.newTagDetails.tagName = this.newTagDetails.tagName.trim();
            }

            const result = await this.tagService.createTag(this.newTagDetails.tagName, this.newTagDetails.tagColor.code);

            // scroll back to the top for error (if any)
            // window.scroll(0, 0);

            const tagName = this.newTagDetails.tagName;

            // let msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Tag', this.newTagDetails.tagName);
            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Tag', tagName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Tag', tagName);
                // redirect to view
                this.navigateSuccessToTripTagView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    closeColorPicker(clickEvent?: Event): void {
        // let floaterContentTagId = "#colorpicker-floater-wrapper"; //tag with '--active' class
        // let excludedHtmlIds = [
        //     "colorpicker-floater-toggler",
        //     "colorpicker-floater-content"
        // ];

        //Avoid floater auto-closed when clicked on toggler
        // if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
        //     // console.debug('blocked event by: ' + (<Element>clickEvent.target).id);
        //     return;
        // }

        // let colorPickerDom = this.el.nativeElement.querySelector(floaterContentTagId);
        // if (Boolean(colorPickerDom) && colorPickerDom.className.indexOf("--active") >= 0) {
        //     // console.debug('EditGroupComponent: Closing color picker');
        //     this.colorPickerToggle.setInactive();
        // }
        this.colorPickerToggle.setInactive();
    }
}
