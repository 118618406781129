<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>
            <h1 class="reports__heading   heading --level-1">
                Scoring Report
            </h1>

            <div class="reports__filters" style="position: relative; z-index: 3;">
                <div class="grid --gutter   reports__filters-grid">
                    <div class="grid__block   reports__filters-grid-block --type-2">
                        <div class="field-group --unstack@xs   reports__type-field-group">

                            <div class="field-group__item   reports__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                <label class="select --grey">
                                    <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                        <option value="vehicles">Vehicles</option>
                                        <option value="drivers">Drivers</option>
                                        <option value="driverTag">Driver Tag</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__item   reports__type-field-group-input" [class.--active]="vehiclesSearchDOMStates.isFocused" *ngIf="searchBy === 'vehicles'" click-outside (clickOutside)="onOutsideClickVehicleReaction($event)">
                                <div class="field" #vehiclesField>
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                            </div>
                                        </div>

                                        <label class="field__holder">
                                            <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                {{ vehiclesSelectedLabel }}
                                            </div>

                                            <input id="vehicleSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #vehiclesSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterVehiclesSelection($event.target.value)">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>

                                    <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                        <ul class="inline-input-list">
                                            <li *ngIf="!vehiclesModel.vehicles.length" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    No vehicles found
                                                </label>
                                            </li>
                                            <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups['all']" (ngModelChange)="onVehiclesAllSelectChange()">

                                                    <span class="inline-input__icon"></span>

                                                    All vehicles
                                                </label>
                                            </li>

                                            <ng-container *ngFor="let group of vehiclesModel.vehicles; let groupIndex = index;">
                                                <ng-container *ngIf="group.type === 'INDIVIDUAL'">
                                                    <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                        <label class="inline-input">
                                                            <input type="checkbox" [name]="item.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[item.vehicleId]" (ngModelChange)="onVehiclesSelectChange()">

                                                            <span class="inline-input__icon"></span>

                                                            {{ item.vehicleName }}
                                                        </label>
                                                    </li>
                                                </ng-container>

                                                <ng-container *ngIf="group.type === 'GROUP'">
                                                    <li class="inline-input-list__item">
                                                        <label class="inline-input">
                                                            <input type="checkbox" [name]="group.name" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups[groupIndex]" (ngModelChange)="onVehiclesGroupSelectChange(groupIndex);">

                                                            <span class="inline-input__icon"></span>

                                                            {{ group.name }}
                                                        </label>

                                                        <button class="item-group-expand" (click)="expandGroupVehicle[groupIndex] = !expandGroupVehicle[groupIndex]">

                                                            <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupVehicle[groupIndex]">

                                                        </button>

                                                        <ng-container *ngIf="expandGroupVehicle[groupIndex]">
                                                            <ul class="inline-input-list">
                                                                <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                                    <label class="inline-input">
                                                                        <input type="checkbox" [name]="item.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[item.vehicleId]" (ngModelChange)="onVehiclesSelectChange(groupIndex, $event)">

                                                                        <span class="inline-input__icon"></span>

                                                                        {{ item.vehicleName }}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </ng-container>
                                                    </li>
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="field-group__item   reports__type-field-group-input" [class.--active]="driversSearchDOMStates.isFocused" *ngIf="searchBy === 'drivers'" click-outside (clickOutside)="onOutsideClickDriverReaction($event)">
                                <div class="field" #driversField>
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <img src="assets/images/scss-components/forms/icon-steering-wheel.svg" width="26" height="20" alt="" class="field__icon-image">
                                            </div>
                                        </div>

                                        <label class="field__holder">
                                            <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                {{ driversSelectedLabel }}
                                            </div>

                                            <input id="driverSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #driversSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterDriversSelection($event.target.value)">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>

                                    <!-- START DRIVER -->

                                    <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                        <ul class="inline-input-list">
                                            <li *ngIf="!driversModel.drivers.length" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    No drivers found
                                                </label>
                                            </li>
                                            <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverGroups['all']" (ngModelChange)="onDriversAllSelectChange()">
                                                    <span class="inline-input__icon"></span>

                                                    All Drivers
                                                </label>
                                            </li>

                                            <ng-container *ngFor="let group of driversModel.drivers; let groupIndex = index;">
                                                <ng-container *ngIf="group.type === 'INDIVIDUAL'">
                                                    <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                        <label class="inline-input">
                                                            <input type="checkbox" [name]="item.driverId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivers[item.driverId]" (ngModelChange)="onDriversSelectChange()">
                                                            <span class="inline-input__icon"></span>
                                                            {{ item.driverName }}
                                                        </label>
                                                    </li>
                                                </ng-container>

                                                <ng-container *ngIf="group.type === 'GROUP'">
                                                    <li class="inline-input-list__item">
                                                        <label class="inline-input">
                                                            <input type="checkbox" [name]="group.name" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverGroups[groupIndex]" (ngModelChange)="onDriversGroupSelectChange(groupIndex);">
                                                            <span class="inline-input__icon"></span>

                                                            {{ group.name }}
                                                        </label>

                                                        <button class="item-group-expand" (click)="expandGroupDriver[groupIndex] = !expandGroupDriver[groupIndex]">

                                                            <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupDriver[groupIndex]">

                                                        </button>

                                                        <ng-container *ngIf="expandGroupDriver[groupIndex]">
                                                            <ul class="inline-input-list">
                                                                <li class="inline-input-list__item truncate-word" *ngFor="let item of group.idList">
                                                                    <label class="inline-input">
                                                                        <input type="checkbox" [name]="item.driverId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivers[item.driverId]" (ngModelChange)="onDriversSelectChange(groupIndex, $event)">
                                                                        <span class="inline-input__icon"></span>

                                                                        {{ item.driverName }}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </ng-container>
                                                    </li>
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                    </div>

                                    <!-- END DRIVER -->

                                </div>
                            </div>

                            <!-- START DRIVER TAG-->
                            <div class="field-group__item   reports__type-field-group-input" [class.--active]="driverTagSearchDOMStates.isFocused" *ngIf="searchBy === 'driverTag'" click-outside (clickOutside)="onOutsideClickDriverTagReaction($event)">
                                <div class="field" #driverTagField>
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <img src="assets/images/ng-components/vehicle-info/icon-id.svg" width="26" height="20" alt="" class="field__icon-image">
                                            </div>
                                        </div>

                                        <label class="field__holder">
                                            <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                {{ driverTagSelectedLabel }}
                                            </div>

                                            <input id="driverTagSearchBox" type="text" autocomplete="off" class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown" dom-states #driverTagSearchDOMStates="DOMStates" (focus)="toggleSubjectSelectionFloater(true)" (keyup)="filterDriverTagSelection($event.target.value)">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>

                                    <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                        <ul class="inline-input-list">
                                            <li *ngIf="!driverTagModel.driverTags.length" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    No driver tag found
                                                </label>
                                            </li>
                                            <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverTagGroups['all']" (ngModelChange)="onDriverTagAllSelectChange()">
                                                    <span class="inline-input__icon"></span>

                                                    All Driver Tag
                                                </label>
                                            </li>

                                            <li class="inline-input-list__item" *ngFor="let item of driverTagModel.driverTags; let groupIndex = index;">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="item.driverTagId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverTag[item.driverTagId]" (ngModelChange)="onDriverTagSelectChange()">
                                                    <span class="inline-input__icon"></span>
                                                    {{ item.driverTagNo }} (Current Driver : {{item.driverName ? item.driverName : '-'}} )
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- END DRIVER TAG-->
                        </div>
                    </div>

                    <div id="divSearchType" class="grid__block   reports__filters-grid-block --period">
                        <div class="field-group --unstack@xs   reports__period-field-group">

                            <div class="field-group__item   reports__period-field-group-select" [class.--active]="searchTypeDOMStates.isFocused">
                                <label class="select --grey">
                                    <select class="select__input" [(ngModel)]="searchType" dom-states #searchTypeDOMStates="DOMStates" (change)="changeSearchType()">
                                        <option value="weekly">Weekly</option>
                                        <option value="lifetime">Lifetime</option>
                                    </select>
                                </label>
                            </div>

                            <div *ngIf="searchType === 'weekly'" class="field-group__item   reports__period-field-group-input" [class.--active]="fromDateDOMStates.isFocused">
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <mat-datepicker-toggle [for]="FromDatepicker">
                                                    <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                </mat-datepicker-toggle>
                                            </div>
                                        </div>

                                        <input [matDatepicker]="FromDatepicker" type="text" [(ngModel)]="fromDate" [max]="toDate" readonly (focus)="FromDatepicker.open()" (click)="FromDatepicker.open()" placeholder="From Date" class="field__input" dom-states #fromDateDOMStates="DOMStates">

                                        <div class="field__ui"></div>
                                    </div>

                                    <mat-datepicker #FromDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                                </div>
                            </div>

                            <div *ngIf="searchType === 'weekly'" class="field-group__item   reports__period-field-group-input" [class.--active]="toDateDOMStates.isFocused">
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <mat-datepicker-toggle [for]="ToDatepicker">
                                                    <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                </mat-datepicker-toggle>
                                            </div>
                                        </div>

                                        <input [matDatepicker]="ToDatepicker" type="text" [(ngModel)]="toDate" [min]="fromDate" [max]="moment().startOf('day').format()" readonly (focus)="ToDatepicker.open()" (click)="ToDatepicker.open()" placeholder="To Date" class="field__input" dom-states #toDateDOMStates="DOMStates">

                                        <div class="field__ui"></div>
                                    </div>

                                    <mat-datepicker #ToDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid__block   reports__filters-grid-block --cta">
                        <button type="button" class="button" (click)="generateReport()">
                            Generate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid grid-table --gutter">
                    <div class="grid__block   w-3of12@xs">
                        <h2 class="reports__heading   heading --level-2 --partial-underline">
                            Results:
                        </h2>
                    </div>

                    <div class="grid__block inline-table-button  w-9of12@xs   ta-r@xs" *ngIf="isReportShown && !showGraph">
                        <button type="button" class="button download-button" (click)="showDownloadPopup(downloadsPopup)">
                            <div class="download-button__icon">
                                <img src="assets/images/scss-components/buttons/icon-download.svg" width="24" height="18" alt="">
                            </div>
                            
                            <div class="download-button__desc">     
                                Download
                            </div>
                        </button>

                        <button type="button" class="button --outline download-button" (click)="triggerShowGraph()">
                            <div class="download-button__icon">
                                <img src="assets/images/scss-components/buttons/icon-graph-green.svg" width="24" height="20" alt="">
                            </div>
                            
                            <div class="download-button__desc">     
                                Show Graph
                            </div>
                        </button>
                    </div>

                    <div class="grid__block inline-table-button  w-9of12@xs   ta-r@xs" *ngIf="isReportShown && showGraph">
                        <button type="button" class="button --outline download-button" (click)="triggerShowGraph()">
                            <div class="download-button__icon">
                                <img src="assets/images/scss-components/buttons/icon-table-green.svg" width="24" height="20" alt="">
                            </div>
                            
                            <div class="download-button__desc">     
                                Show Table
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div class="report-box" [hidden]="showGraph">
                <app-spinner #reportResult_spinner></app-spinner>
                <div class="block">
                    <div class="table">
                        <table class="table__elem">
                            <!-- Weekly Scoring -->
                            <ng-container *ngIf="currentSearchType === 'weekly'">
                                <thead>
                                    <tr>
                                        <th scope="col" width="20%" *ngIf="resultType == 'vehicle'">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('VehicleName')" (click)="doSorting('VehicleName')">Vehicle
                                            </button>
                                        </th>
                                        <th scope="col" width="20%" *ngIf="resultType == 'driver'">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DriverName')" (click)="doSorting('DriverName')">Driver
                                            </button>
                                        </th>
                                        <th scope="col" width="13%" *ngIf="resultType == 'driver'">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DriverTagNo')" (click)="doSorting('DriverTagNo')">ID Tag No.</button>
                                        </th>
                                        <th scope="col" width="15%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DateTime')" (click)="doSorting('DateTime')">Week
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Score')" (click)="doSorting('Score')">
                                                <span *ngIf="resultType == 'driver'">
                                                    Driver
                                                </span>
                                                <span *ngIf="resultType == 'vehicle'">
                                                    Vehicle
                                                </span>
                                                <br>Score (%)
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ImpactOfHA')" (click)="doSorting('ImpactOfHA')">Impact of<br>Harsh Accel.<br>(%)
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ImpactOfHB')" (click)="doSorting('ImpactOfHB')">Impact
                                                of<br>Harsh Brake.<br>(%)
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ImpactOfHC')" (click)="doSorting('ImpactOfHC')">Impact
                                                of<br>Harsh Turn.<br>(%)
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ImpactOfSpeedingDistance')" (click)="doSorting('ImpactOfSpeedingDistance')">Impact
                                                of<br>Speeding<br>(%)
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let record of resultList">
                                        <td *ngIf="resultType == 'vehicle'" class="td-break-word td-truncate" [title]="record.VehicleName || '-'">{{record.VehicleName || '-'}}</td>
                                        <td *ngIf="resultType == 'driver'" class="td-break-word td-truncate" [title]="record.DriverName || '-'">{{record.DriverName || '-'}}</td>
                                        <td *ngIf="resultType == 'driver'">{{record.DriverTagNo || '-'}}</td>
                                        <td>{{record.DateRange || '-'}}</td>
                                        <td>{{record.ScoreLabel}}</td>
                                        <td>{{record.ImpactOfHALabel}}</td>
                                        <td>{{record.ImpactOfHBLabel}}</td>
                                        <td>{{record.ImpactOfHCLabel}}</td>
                                        <td>{{record.ImpactOfSpeedingLabel}}</td>
                                    </tr>
                                </tbody>
                            </ng-container>
                            <!-- Lifetime Scoring -->
                            <ng-container *ngIf="currentSearchType === 'lifetime'">
                                <thead>
                                    <tr>
                                        <th scope="col" width="20%" *ngIf="resultType == 'vehicle'">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('VehicleName')" (click)="doSorting('VehicleName')">Vehicle</button>
                                        </th>
                                        <th scope="col" width="20%" *ngIf="resultType == 'driver'">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DriverName')" (click)="doSorting('DriverName')">Driver</button>
                                        </th>
                                        <th scope="col" width="15%" *ngIf="resultType == 'driver'">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DriverTagNo')" (click)="doSorting('DriverTagNo')">ID Tag No.</button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Score')" (click)="doSorting('Score')">
                                                <span *ngIf="resultType == 'driver'">
                                                    Driver
                                                </span>
                                                <span *ngIf="resultType == 'vehicle'">
                                                    Vehicle
                                                </span>
                                                <br>Score (%)
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ImpactOfHA')" (click)="doSorting('ImpactOfHA')">Impact
                                                of <br>Harsh Accel. (%)
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ImpactOfHB')" (click)="doSorting('ImpactOfHB')">Impact
                                                of<br>Harsh Brake. (%)
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ImpactOfHC')" (click)="doSorting('ImpactOfHC')">Impact
                                                of<br>Harsh Turn. (%)
                                            </button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ImpactOfSpeedingDistance')" (click)="doSorting('ImpactOfSpeedingDistance')">Impact
                                                of<br>Speeding (%)
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let record of resultList">
                                        <td *ngIf="resultType == 'vehicle'" class="td-break-word" [title]="'Vehicle ID: ' + record.VehicleId">{{record.VehicleName || '-'}}</td>
                                        <td *ngIf="resultType == 'driver'" class="td-break-word" [title]="'Driver ID: ' + record.DriverId">{{record.DriverName || '-'}}</td>
                                        <td *ngIf="resultType == 'driver'">{{record.DriverTagNo || '-'}}</td>
                                        <td>{{record.ScoreLabel}}</td>
                                        <td>{{record.ImpactOfHALabel}}</td>
                                        <td>{{record.ImpactOfHBLabel}}</td>
                                        <td>{{record.ImpactOfHCLabel}}</td>
                                        <td>{{record.ImpactOfSpeedingLabel}}</td>
                                    </tr>
                                </tbody>
                            </ng-container>
                        </table>
                    </div>
                    <h2 class="reports__record-not-found --level-1" *ngIf="!isReportShown">{{ isReportSearched ? message.getMessage(message.MESSAGE.REPORT_NO_RECORD_FOUND.value) : message.getMessage(message.MESSAGE.REPORT_PLEASE_GENERATE.value) }}</h2>
                </div>

                <div class="block" *ngIf="isReportShown">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>

                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination">
                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="fetchPage(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="fetchPage(page)">{{ page }}</button>

                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="fetchPage(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="report-box" [hidden]="!showGraph || (showGraph && showLifeTimeGraph)">
                <div style="margin-bottom: 30px;">
                    <app-spinner #scoreGraph_spinner></app-spinner>
                    <div *ngIf="!scoreCharts.ScoreGraph.isEmpty" class="graph-chart">
                        <div class="graph-title">
                            {{ this.currentSearchBy == "vehicles" ? 'Vehicle Score Over Week' : 'Driver Score Over Week' }}
                        </div>
                        <div class="graph-chart__panel" style="height: 450px;">
                            <!-- Insert chart here -->
                            <div style="height: 450px">
                                <canvas id="ScoreGraph"></canvas>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <app-spinner #scoreViolationGraph_spinner></app-spinner>
                    <div *ngIf="!scoreCharts.ScoreViolationGraph.isEmpty" class="graph-chart">
                        <div class="graph-title">
                            Score Impact by Violations
                        </div>
                        <div style="width: 150px; margin-left: 15px;">
                            <label class="select --grey">
                                <select class="select__input" [(ngModel)]="scoreViolationGraphType" (change)="getScoreViolationsGraph()">
                                    <option value="Count">Count</option>
                                    <option value="Percentage">Percentage</option>]
                                </select>
                            </label>
                        </div>
                        <div class="graph-chart__panel" style="height: 450px;">
                            <!-- Insert chart here -->
                            <div style="height: 450px">
                                <canvas id="ScoreViolationGraph"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="report-box" [hidden]="!showGraph || (showGraph && showWeeklyGraph)">
                <div style="margin-bottom: 30px;">
                    <app-spinner #lifeTimeScoreGraph_spinner></app-spinner>
                    <div *ngIf="!scoreCharts.LifeTimeScoreGraph.isEmpty" class="graph-chart">
                        <div class="graph-title">
                            {{ this.currentSearchBy == "vehicles" ? 'Vehicle Score' : 'Driver Score' }}
                        </div>
                        <div class="graph-chart__panel" style="height: 450px;">
                            <!-- Insert chart here -->
                            <div style="height: 450px">
                                <canvas id="LifeTimeScoreGraph"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-bottom: 30px;">
                    <app-spinner #lifeTimeScoreViolationGraph_spinner></app-spinner>
                    <div *ngIf="!scoreCharts.LifeTimeScoreViolationGraph.isEmpty" class="graph-chart">
                        <div class="graph-title">
                            Score Impact by Violations
                        </div>
                        <div style="width: 150px; margin-left: 15px;">
                            <label class="select --grey">
                                <select class="select__input" [(ngModel)]="lifeTimeScoreViolationGraphType" (change)="getLifeTimeScoreViolationsGraph()">
                                    <option value="Count">Count</option>
                                    <option value="Percentage">Percentage</option>]
                                </select>
                            </label>
                        </div>
                        <div class="graph-chart__panel" style="height: 300px;">
                            <!-- Insert chart here -->
                            <div style="height: 300px">
                                <canvas id="LifeTimeScoreViolationGraph"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <app-spinner #lifeTimeScoreImpactGraph_spinner></app-spinner>
                    <div *ngIf="!scoreCharts.LifeTimeScoreImpactGraph.isEmpty" class="graph-chart">
                        <div class="graph-title">
                            Score Impact by {{this.currentSearchBy == "vehicles" ? 'Vehicle' : 'Driver'}}
                        </div>
                        <div class="graph-chart__panel" style="height: 450px;">
                            <!-- Insert chart here -->
                            <div style="height: 450px">
                                <canvas id="LifeTimeScoreImpactGraph"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #downloadsPopup="popup">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="btnExcel()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnCsv()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnPdf()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35" class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
