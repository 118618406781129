import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { TripsService } from '../../../../_services/trips/trips.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as NumberUtil from './../../../../util/numberUtil';

// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';
import { ActivatedRoute } from '@angular/router';

@Component({
    providers: [GeneralReport],
    templateUrl: './utilization-report.component.html',
    styleUrls: ['./utilization-report.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class UtilizationReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

    expandGroupVehicle: any = [];
    expandGroupDriver: any = [];
    breakdownID = null;
    breakdownName = '';
    currentPage: number = null;
    isBack: boolean = false; //to check if user click back from Vehicle Breakdown View
    useStored: boolean = false; // to check whether to use data stored before entering Vehicle Breakdown View


    // Set Export File Name from environments
    exportPdfUtilizationPageLayou = environment.appConfig.reporting.utilization.layout;
    exportFileNameUtilization = environment.appConfig.reporting.utilization.fileName;
    exportFileNameUtilizationPdfTitle = environment.appConfig.reporting.utilization.label;
    pageLayout = this.exportPdfUtilizationPageLayou;
    moment = moment;

    @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private tripsService: TripsService,
        private popupService_parent: PopupService,
        private route: ActivatedRoute,
        private popupCampaignService: PopupCampaignService
    ) {

        //pass services to parent
        super(http_parent, popupService_parent, snackBar);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingSearchBy: true,
            usingDateRangePicker: true,
            usingDriverSelector: true,
            usingVehicleSelector: true
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {

        //Default search by
        this.searchBy = "vehicles";

        //Default search range
        // this.fromDate = moment().subtract(1, 'weeks').format("YYYY-MM-DD");
        // this.toDate = moment().format("YYYY-MM-DD");

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        //when redirect from advisory
        if (this.route.snapshot.queryParams.autoSearch == 'true') {
            const filterIdVehicle = this.route.snapshot.queryParams.vehicleIds || '';
            if (filterIdVehicle != '') {
                //clear default selected all vehicles
                this.selectedVehicles = {};
                const idArray = filterIdVehicle.split(',');
                idArray.forEach(vehicleId => {
                    this.selectedVehicles[vehicleId] = true;
                });
                const current = moment();
                const pastWeek = current.subtract(1, 'weeks');
                const pastWeekStart = pastWeek.startOf('isoWeek').format('YYYY-MM-DD');
                const pastWeekEnd = pastWeek.endOf('isoWeek').format('YYYY-MM-DD');
                this.fromDate = pastWeekStart;
                this.toDate = pastWeekEnd;
                //update ui changes
                this.onRedirectUpdateVehiclesSelectChange();
                this.currentSortField = this.route.snapshot.queryParams.autoSortColumn;
                if (this.route.snapshot.queryParams.autoSortColumn == 'TotalDrivingTime') {
                    this.currentSortAscending = true;
                } else {
                    this.currentSortAscending = false;
                }
                await this.generateReport();
            }
        }

        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {
            if(this.isBack) {
                page = this.currentPage;
            }
            // let idListObj = this.currentSearchBy == "vehicles" ? { VehicleId: '-', VehicleName: 'Total' } : { DriverId: '-', DriverName: 'Total' };
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //call api to get report page
            const apiControllerResponse: any = await this.getApiControllerResponse(startRecord, null, this.isBreakdown);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;

                //update sorting classes
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = this.apiResponse.utilizationReport;
                if (this.resultList.length) {

                    // parse some values to reduce function data bindings
                    this.resultList.forEach(record => {
                        record.TotalDrivingTimeLabel = NumberUtil.formatHourMinute(record.TotalDrivingTime, '-');
                        record.AvgTimePerTripLabel = NumberUtil.formatHourMinute(record.AvgTimePerTrip, '-');
                        record.TotalIdlingTimeLabel = NumberUtil.formatHourMinute(record.TotalIdlingTime, '-');
                        record.IdlingPercentageLabel = NumberUtil.formatFloat(record.IdlingPercentage, 1, '0');
                        record.TotalDistanceLabel = NumberUtil.formatFloat(record.TotalDistance, 1, '0');
                        record.AvgDistancePerTripLabel = NumberUtil.formatFloat(record.AvgDistancePerTrip, 1, '0');
                    });

                    //update isReportShown boolean
                    this.isReportShown = true;

                    //get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }
    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }
    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false, breakdown: boolean = false) {

        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        apiResponse = await this.getUtilizationRecords(startRecord, isDownload, breakdown);
        if (apiResponse != null) {
            apiResultList = apiResponse.utilizationReport;
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }
    // For download summary report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 9 }, { wch: 21 }, { wch: 23 }, { wch: 22 }, { wch: 18 }, { wch: 17 }, { wch: 23 }];

        if (this.currentSearchBy == "vehicles") {
            filename = this.exportFileNameUtilization + "_VEHICLES";
            label = this.exportFileNameUtilizationPdfTitle + " (Vehicle)";
            headerName = this.isBreakdown ? 
            [
                "Vehicle", "Date", "Total Trip", "Total Driving Time (hh:mm)", "Avg. Time Per Trip (minutes)", "Total Idling Time (minutes)",
                "Idling Percentage (%)", "Total Distance (km)", "Avg. Distance Per Trip (km)"
            ] : [
                "Vehicle", "Total Trip", "Total Driving Time (hours)", "Avg. Time Per Trip (minutes)", "Total Idling Time (minutes)",
                "Idling Percentage (%)", "Total Distance (km)", "Avg. Distance Per Trip (km)"
            ];

            for (let i = 0; i < recordList.length; i++) {
                const tempRow = this.isBreakdown ? [
                    recordList[i].VehicleName,
                    recordList[i].TripDate,
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalTrip, 0, '')),
                    NumberUtil.formatHourMinute(recordList[i].TotalDrivingTime, '-'),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].AvgTimePerTrip * 60, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalIdlingTime * 60, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].IdlingPercentage, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalDistance, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].AvgDistancePerTrip, 1, ''))
                ] : [
                    recordList[i].VehicleName,                    
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalTrip, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalDrivingTime, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].AvgTimePerTrip * 60, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalIdlingTime * 60, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].IdlingPercentage, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalDistance, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].AvgDistancePerTrip, 1, ''))
                ];
                data.push(tempRow);
            }
        } else if (this.currentSearchBy == "drivers") {
            filename = this.exportFileNameUtilization + "_DRIVERS";
            label = this.exportFileNameUtilizationPdfTitle + " (Driver)";
            headerName = this.isBreakdown ? [
                "Driver", "Date", "Total Trip", "Total Driving Time (hh:mm)", "Avg. Time Per Trip (minutes)", "Total Idling Time (minutes)",
                "Idling Percentage (%)", "Total Distance (km)", "Avg. Distance Per Trip (km)"
            ] : [
                "Driver", "Total Trip", "Total Driving Time (hours)", "Avg. Time Per Trip (minutes)", "Total Idling Time (minutes)",
                "Idling Percentage (%)", "Total Distance (km)", "Avg. Distance Per Trip (km)"
            ];

            for (let i = 0; i < recordList.length; i++) {
                const tempRow = this.isBreakdown ? [
                    recordList[i].DriverName,
                    recordList[i].TripDate,
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalTrip, 0, '')),
                    NumberUtil.formatHourMinute(recordList[i].TotalDrivingTime, '-'),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].AvgTimePerTrip * 60, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalIdlingTime * 60, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].IdlingPercentage, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalDistance, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].AvgDistancePerTrip, 1, ''))
                ] : [
                    recordList[i].DriverName,
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalTrip, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalDrivingTime, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].AvgTimePerTrip * 60, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalIdlingTime * 60, 0, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].IdlingPercentage, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].TotalDistance, 1, '')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].AvgDistancePerTrip, 1, ''))
                ];
                data.push(tempRow);
            }            
        }

        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    /**
     * Report Specific Methods
     */

    // 1. Call API Utilization Report
    async getUtilizationRecords(startRecord: number = 1, isDownload: boolean = false, breakdown: boolean = false) {
        let result: any = null;
        let idList = [];
        
        if (this.isBreakdown) {            
            idList.push(this.breakdownID);

        } else {
            idList = this.currentSearchBy == 'vehicles' ? this.selectedVehiclesList : this.selectedDriversList;
        }

        let searchBy = this.useStored ? this.storedSearchBy : this.currentSearchBy;
        let fromDate = this.useStored ? this.storedFromDate : this.currentFromDate;
        let toDate = this.useStored ? this.storedToDate : this.currentToDate;
        let sortField = this.isBack ? this.storedSortField : this.currentSortField;
        let sortAscending = this.isBack ? this.storedSortAscending : this.currentSortAscending;

        if (!isDownload) {
            // result = await this.tripsService.getUtilizationReport(this.currentSearchBy, idList, this.currentFromDate, this.currentToDate, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending, this.isBreakdown);
            result = await this.tripsService.getUtilizationReport(searchBy, idList, fromDate, toDate, this.pageRecordSize, startRecord, sortField, sortAscending, this.isBreakdown);
        } else {            
            // result = await this.tripsService.getUtilizationReport(this.currentSearchBy, idList, this.currentFromDate, this.currentToDate, null, null, this.currentSortField, this.currentSortAscending, this.isBreakdown);
            result = await this.tripsService.getUtilizationReport(searchBy, idList, fromDate, toDate, null, null, sortField, sortAscending, this.isBreakdown);
        }

        this.isBack = false;
        return result || null;
    }

    tripBreakdown(ID, name, currentPage: number){
        if(!this.isBreakdown){
            this.breakdownID = ID;
            this.isBreakdown = true;
            this.breakdownName = name;
            this.currentPage = currentPage;
            this.storedSortField = this.currentSortField;
            this.storedSortAscending = this.currentSortAscending;

            this.fetchPage();
        }        
    }

    preGenerateReport(){
        this.isBreakdown = false;
        this.isBack = false;        
        this.useStored = false;

        this.generateReport(true);
        this.useStored = true;
    }

    async reportGoBack() {        
        this.isBack = true;
        this.isBreakdown = false;

        await this.fetchPage(this.currentPage);
        this.isBack = false;

    }
}
