export const constants = {
    POOR_GPS: {
        value: "POOR_GPS",
        label: "GPS poor"
    },
    UNPLUG_DEVICE: {
        value: "UNPLUG_DEVICE",
        label: "Device unplugged"
    },
    PLUG_DEVICE: {
        value: "PLUG_DEVICE",
        label: "Device plugged"
    },
    INSTALL_DEVICE: {
        value: "INSTALL_DEVICE",
        label: "Device installed"
    },
    DISCONNECTED: {
        value: "DISCONNECTED",
        label: "Disconnected"
    },
    UNPLUGGED: { //nid to remove once old stg is closed
        value: "UNPLUGGED",
        label: "Device unplugged"
    },
    INSTALLED: { //nid to remove once old stg is closed
        value: "INSTALLED",
        label: "Device installed"
    }
};

export function getDeviceStatusFromValue(value: string) {
    let array = Object.getOwnPropertyNames(constants);
    for (status of array) {
        if (constants[status].value == value) {
            return constants[status].label;
        }
    }
    return null;
}

