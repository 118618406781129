import { Component, OnInit, Input, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as moment from 'moment';
import * as ObjectUtil from './../../../../../util/objectUtil';
import * as StringUtil from './../../../../../util/stringUtil';
import * as DomUtil from './../../../../../util/domUtil';

import { TripsService } from "../../../../../_services/trips/trips.service";
import { VehicleService } from "../../../../../_services/vehicle/vehicle.service";
import { MessageBusService, BusMessage } from "../../../../../_services/messagebus.service";
import { SnackBarService } from '../../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../../common/spinner/spinner.component';

import * as Message from '../../../../../constants/message';
import { ERROR_MESSAGE as ErrorMessage } from './../../../../../constants/errorMessage';

@Component({
    selector: 'vehicle-tripslist',
    templateUrl: './tripslist.component.html',
    styleUrls: ['./tripslist.component.scss']
})
export class TripslistComponent implements OnInit, OnDestroy {

    @ViewChild("panel_spinner",{static:true}) panelSpinner: SpinnerComponent;

    @ViewChild('tripslist',{static:false}) tripslist;
    private _imei: string = '';
    private _init: boolean = false;
    private _hasFuelSensor: boolean = false;

    moment = moment;
    startdate: any = '';
    enddate: any = '';
    currtrips: Array<any> = [];
    tagsList: any = [];
    imeiObj: any = {};

    lastSearchStartDate: string = '';
    lastSearchEndDate: string = '';

    //For scrolling lazy load
    hasMoreRecord: boolean = false;
    nextScrollStartDate: string = '';
    nextScrollEndDate: string = '';

    //For auto load highlighted post trip
    tripDayItemOnInitLoadHighlightedTrip: boolean = !DomUtil.isMobileOrTabletView(); // false for mobile view
    tripDayItemOnInitLoadViolatedTrip: boolean = false;
    // isLoadedMostRecent: boolean = false;
    dateWithHighlightedTrip: any = null;
    highlightedTripIndex: number = -1;

    // DatepickerTouchUi
    lastResizeTime: any = new Date(0);
    resizeTimeout: any;
    datepickerTouchUi: boolean = false;

    tripDayItemSingleViewMode: boolean = false;

    selectedViolation = "";

    @Input()
    set init(init: boolean) {
        if (!init) {
            this._init = false;
            return;
        }
        if (!this._init) {
            this._init = true;
            this.triggerInit();
        }
    }

    get init(): boolean {
        return this._init;
    }

    @Input()
    set imei(imei: string) {
        if (imei == null) { return; }
        this._imei = imei;
        // console.warn("Imei Changed: " + this._imei);
        // this.getTripInfo();
    }

    get imei(): string {
        return this._imei;
    }

    @Input()
    set hasFuelSensor(hasFuelSensor: boolean) {
        if (hasFuelSensor == null) { return; }
        this._hasFuelSensor = hasFuelSensor;
    }

    get hasFuelSensor(): boolean {
        return this._hasFuelSensor;
    }

    msgBusSubscription: Subscription;
    constructor(private tripsvc: TripsService,
        public el: ElementRef,
        private vehicleService: VehicleService,
        private snackBar: SnackBarService,
        private msgbus: MessageBusService) {

        const busesPort = ["vehiclePanel", "tripDayItem"];
        this.msgBusSubscription = this.msgbus.messagebus$.pipe(
            filter(message => busesPort.indexOf(message.source) != -1)
        ).subscribe(this.messageBusGetMessage.bind(this));
    }

    /* Message Controller*/
    async messageBusGetMessage(message: BusMessage) {
        switch (message.source) {
            case "vehiclePanel":
                await this.handleVehiclePanelMessage(message);
                break;
            case "tripDayItem":
                await this.handleTripDayItemMessage(message);
                break;
            // case "tripRoute":
            //     await this.handleTripRouteMessage(message);
            //     break;
        }
    }

    /* Message Handler: Trip Route */
    // async handleTripRouteMessage(message: BusMessage) {
    //     switch (message.messagetype) {
    //         case "highlightItem":
    //             // console.debug('selected date = ' + message.data.selectedDate);
    //             // console.debug('selected index = ' + message.data.selectedIndex);
    //             this.msgbus.sendMessage(new BusMessage(
    //                 "triplist",
    //                 "highlightItem",
    //                 {
    //                     selectedDate: message.data.selectedDate,
    //                     selectedIndex: message.data.selectedIndex
    //                 }
    //             ));
    //             break;
    //     }
    // }

    async handleTripDayItemMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "singleViewModeToggle": {
                this.tripDayItemSingleViewMode = message.data.active;
                this.tripDayItemOnInitLoadViolatedTrip = false; //restore default value after trip loaded
                break;
            }
        }
    }

    async handleVehiclePanelMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "singleViewModeToggle": {
                this.tripDayItemSingleViewMode = message.data.active;
                break;
            }
            case "triplisttoggle": {
                // console.debug('(MsgBus)TripsListComponent: triplist -> triplisttoggle (VehiclePanelComponent)');

                if (message.data.isActive && !message.data.skipTripList) {

                    // load most recent trip (depreciated)
                    // if (!this.isLoadedMostRecent) {
                    //     if (message.data.isActive) {
                    //         this.loadMostRecentTripRoute();
                    //     }
                    // }

                    // load highlighted trip
                    if (message.data.loadDefaultTrip === true) {
                        if (this.init && this.highlightedTripIndex != -1) {
                            // console.debug('loadHighlightedTrip after toggle triplist');
                            this.loadHighlightedTripRoute();
                        }
                    }
                    await this.onTripslistScroll(this.tripslist);
                }
                break;
            }
        }
    }

    async ngOnInit() {
        //move to triggerInit()
    }

    async triggerInit() {
        const _this = this;

        this.getImeis()
            .then(function () {
                return _this.getTripInfo();
            })
            .then(function () {
                return _this.getTripTags();
            })
            .then(function () {
                _this.updateDatepickerTouchUi();
            })
            .then(function () {
                return _this.onTripslistScroll(_this.tripslist); // trigger scroll first time to fill up blank spaces
            })
            .catch((err) => {
                throw err;
            });
    }

    async ngOnDestroy() {
        if (this.msgBusSubscription) {
            this.msgBusSubscription.unsubscribe();
        }
    }

    async getImeis() {
        try {
            // get historic imeis
            const imeiResult: any = await this.vehicleService.getVehicleImeisFromImei(this.imei);
            if (!imeiResult) {
                return;
            }
            this.imeiObj = imeiResult.body;
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        }
    }

    async getTripInfo(tripId: number = null) {
        if (!this.imei || !this.imeiObj || ObjectUtil.isEmpty(this.imeiObj)) {
            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.INFO_LOAD_FAILED);
            this.snackBar.openGenericSnackBar(msg);
            return;
        }
        this.panelSpinner.show();
        try {

            let tempStart = null;
            let tempEnd = null;

            if (this.startdate != '' && this.startdate != null) {
                tempStart = moment(this.startdate);
            }
            if (this.enddate != '' && this.enddate != null) {
                tempEnd = moment(this.enddate);
            }

            if (tempStart != null && tempEnd != null) {
                if (tempStart > tempEnd) {
                    const msg = Message.getMessage(Message.MESSAGE.START_DATE_LARGER.value);
                    this.snackBar.openGenericSnackBar(msg);
                    return;
                }
            }

            //able to perform search, do cache
            if (tempStart != null) {
                this.lastSearchStartDate = tempStart.format("YYYY-MM-DD");
            } else {
                this.lastSearchStartDate = '';
            }

            if (tempEnd == null) {
                if (tempStart != null) {
                    //default search, do not update date picker input value
                    this.enddate = new Date;
                    tempEnd = moment(this.enddate);
                } else {
                    //manual search, auto-correct date picker input value
                    tempEnd = moment();
                }
            }
            this.lastSearchEndDate = tempEnd.format("YYYY-MM-DD");

            //searching
            const pageSize = 10;
            let postSumTripsResult: any;
            if (tempStart == null) {
                // console.debug("search most recent trips");
                postSumTripsResult = await this.tripsvc.getTrips(this.imeiObj.imeis, this.imeiObj.vehicleId, null, this.lastSearchEndDate, pageSize, [this.selectedViolation]);
            } else {
                // console.debug("search base on date");
                postSumTripsResult = await this.tripsvc.getTrips(this.imeiObj.imeis, this.imeiObj.vehicleId, this.lastSearchStartDate,this.lastSearchEndDate, pageSize, [this.selectedViolation]);
            }

            // return new Promise((resolve, reject) => {
            if (postSumTripsResult) {
                let result = postSumTripsResult.body;
                // hide 1970 post trip records
                // result = await this.removeIncorrectTrip(result);
                result = this.processScrollable(result, pageSize);
                result = this.identifyMostRecentTrip(result, tripId);

                //replace list with this result
                this.currtrips = result;
                const wrapper = document.getElementById('divVehicleTripHost');
                if (wrapper != null && String(wrapper.className).indexOf("--active") >= 0) {
                    if (this.highlightedTripIndex != -1) {
                        // console.debug('loadHighlightedTrip after fetch result');
                        // this.loadHighlightedTripRoute();
                        if (tripId && DomUtil.isMobileOrTabletView()) {
                            // in mobile view, show violated trips immediately
                            this.tripDayItemOnInitLoadViolatedTrip = true;
                        }
                    } else {
                        this.showVehicleMarker();
                    }
                }
                // resolve();
            }

            await this.setStartDateToDeviceActivationDate();
            
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.panelSpinner.hide();
        }
    }


    /**
     * @author Sam Liew 2024-04-15
     */
    private async setStartDateToDeviceActivationDate() {

        try {

        if (!this.startdate) {
            
            const activationDate = await this.getDeviceActivationDate();

            this.startdate = moment(activationDate).format('YYYY-MM-DD');
        }
        } catch (error) {
            // unnecessary to do anything on error.
        }
    }

    /**
     * returns `vehicle.ActivationDate` by `vehicleId`.  
     * returns `undefined` on any error.  
     * @author Sam Liew 2024-04-09
     */
    private async getDeviceActivationDate() {

        try {

            const vehicleId = this.imeiObj.vehicleId;
            const res = await this.vehicleService.getVehicleInfoDetailsByVid(vehicleId);
            const {body} = res;
            const {vehicleDetails} = body;
            const {activationDate} = vehicleDetails;
            return activationDate;

        } catch (error: any) {
            return undefined;    
        }

    }

    clearTripsData() {
        this.currtrips = [];
        // this.imeiObj = {};
    }

    async getMoreTripInfo() {
        if (!this.imeiObj || ObjectUtil.isEmpty(this.imeiObj)) {
            return;
        }

        console.debug('TripListComponent: Lazy load: getMoreTripInfo()');

        const pageSize = 10;
        let postSumTripsResult: any;

        postSumTripsResult = await this.tripsvc.getTrips(this.imeiObj.imeis, this.imeiObj.vehicleId, this.nextScrollStartDate, this.nextScrollEndDate, pageSize);

        return new Promise(async (resolve, reject) => {
            const tripList: any = postSumTripsResult;
            let result = tripList.body;
            // result = await this.removeIncorrectTrip(result);
            result = this.processScrollable(result, pageSize);

            //append result to list
            const oriList = this.currtrips;
            this.currtrips = oriList.concat(result);
        });
    }

    async removeIncorrectTrip(postTripList) {
        const result = postTripList.filter(eachRes => {
            const currDate = moment(eachRes.date, "YYYY-MM-DD HH:mm:ss.SSS");
            if (currDate.year() != 1970) {
                return eachRes;
            }
        });
        return result;
    }

    async showSelectedViolationHistoryTrip(startDate, endDate, tripId) {
        this.startdate = moment(startDate).format("YYYY-MM-DD");
        this.enddate = moment(endDate).format("YYYY-MM-DD");
        await this.getTripInfo(tripId);
        // let tripDiv = this.tripslist.nativeElement.querySelector("#tripId_" + tripId);
        // if (tripDiv) {
        //     tripDiv.click();
        // }
    }

    identifyMostRecentTrip(tripList: Array<any>, tripId: number = null) {

        const trips = tripList;
        let foundTrip: boolean = false;
        for (const day of trips) {

            for (let i = 0; i < day.inf.length; i++) {
                const tempTrip = day.inf[i].tripDet;
                if (tempTrip !== undefined) {
                    // if tripId is given, find index of the trip
                    // else, choose first in list
                    if (!tripId || tripId === tempTrip.tId) {
                        this.highlightedTripIndex = i;
                        foundTrip = true;
                        break;
                    }
                }
            }

            if (foundTrip) {
                this.dateWithHighlightedTrip = day;
                day.highlighted = true;
                day.highlightedIndex = this.highlightedTripIndex;
                break;
            }
        }

        if (!foundTrip) {
            this.dateWithHighlightedTrip = null;
            this.highlightedTripIndex = -1;
        }

        return trips;
    }

    // DOM ACCESS METHOD : DEPRECIATED
    // collapseMostRecentDate():void {
    //   let wrapper = document.getElementById('divVehicleTripHost');
    //   if (wrapper != null && this.mostRecentTrip != null) {
    //     if (String(wrapper.className).indexOf("--active") != -1) {
    //       let target = "tripDate_" + this.mostRecentTrip.date;
    //       console.debug(target);
    //       var toCollapse = document.getElementById(target);
    //       console.debug(toCollapse);
    //       if(String(toCollapse.className).indexOf("--active") == -1) {
    //         // toCollapse.classList.add('--active');
    //         toCollapse.click();
    //       }
    //     }
    //   }
    // }

    // loadMostRecentTripRoute():void {
    //   let wrapper = document.getElementById('divVehicleTripHost');
    //   if(wrapper != null && this.mostRecentTrip != null) {
    //     console.debug(wrapper.className);
    //     if (String(wrapper.className).indexOf("--active") != -1) {
    //       let target = "tripId_" + this.mostRecentTrip.tripId;
    //       // console.debug(target);
    //       var toLoad = document.getElementById(target);
    //       // console.debug(toLoad);
    //       debugger;
    //       if(toLoad != null && String(toLoad.className).indexOf("--active") == -1) {
    //         toLoad.click();
    //       }
    //       this.isLoadedMostRecent = true;
    //     }
    //   }
    // }

    // loadMostRecentTripRoute(): void {
    //     let tripIndex = this.highlightedTripIndex;
    //     this.msgbus.sendMessage(new BusMessage(
    //         "triplist", "loadMostRecentTrip", {
    //             index: tripIndex
    //         }
    //     ));
    //     this.isLoadedMostRecent = true;
    // }

    loadHighlightedTripRoute(): void {
        this.msgbus.sendMessage(new BusMessage("triplist", "loadHighlightedTrip", null));
    }

    showVehicleMarker() {
        this.msgbus.sendMessage(new BusMessage(
            "triplist", "missingTripRoute", null
        ));
    }

    processScrollable(tripList, pageSize) {

        let result = [];
        result = result.concat(tripList);

        if (result.length > pageSize) {
            this.hasMoreRecord = true;
            this.nextScrollStartDate = this.lastSearchStartDate;
            this.nextScrollEndDate = result[result.length - 1].date;
            result.pop(); //remove last
        } else {
            this.hasMoreRecord = false;
            this.nextScrollStartDate = '';
            this.nextScrollEndDate = '';
        }

        return result;
    }

    async onTripslistScroll(container: HTMLElement) {
        const clientHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;

        // console.debug('TripListComponent: onTripslistScroll()');

        // Not scrollable, so don't do anything
        if (clientHeight === scrollHeight) {
            console.debug('no scrollbar, checking if got more records to show');
            if (this.hasMoreRecord) {
                await this.getMoreTripInfo();
            }
            return;
        }

        const scrollableHeight = scrollHeight - clientHeight;
        const scrollTop = container.scrollTop;

        if (scrollTop >= scrollableHeight) {
            console.debug('reached bottom - do lazy load here');
            if (this.hasMoreRecord) {
                await this.getMoreTripInfo();
            }
        }
    }

    updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }

    async getTripTags() {
        try {
            const tagsResult: any = await this.tripsvc.getTripTags();
            if (tagsResult) {
                this.tagsList = tagsResult.body.result;
                this.tagsList = this.tagsList.map(eachTag => {
                    eachTag.tagName = StringUtil.toTitleCase(eachTag.tagName);
                    return eachTag;
                });
            } else {
                this.tagsList = [];
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        }
    }

    /**
     * @author Sam 2024-01-24
     * fired when manually toggling the dropdown
     */
    async onChangeViolation(event:any){
        this.setViolation(event.target.value);
    }

    /**
     * @author Sam 2024-01-24
     * mutator when fired from other components
     */
    setViolation(violation:string){
        this.selectedViolation = violation
    }



}
