<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Create New Maintenance
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #maintenanceForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="compulsory-notes">
                    *Compulsory fields
                </div>

                <div class="grid --gutter">
                    <div class="grid__block">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Step 1: Maintenance Info
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Maintenance Name: <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" [(ngModel)]="newMaintenanceDetails.name" name="name" #name="ngModel" required maxlength="250">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="name.errors && (name.dirty || name.touched)">
                                            <div class="field-error" *ngIf="name.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Maintenance
                                                Name')}}
                                            </div>
                                            <div class="field-error" *ngIf="name.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Maintenance
                                                Name', '250')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Recurring Maintenance Type: <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <input type="hidden" [(ngModel)]="newMaintenanceDetails.selectedToggleCount" name="selectedToggleCount" #selectedToggleCount="ngModel" required>
                                        <div class="field-group__groups">
                                            <div class="field-group__groups-col">
                                                <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="newMaintenanceDetails.isToggleMileage" name="isToggleMileage" #isToggleMileage="ngModel" (change)="toggle()">

                                                    Distance Driven (km)
                                                </mat-slide-toggle>
                                            </div>

                                            <div class="field-group__groups-col">
                                                <div class="field-group__groups-inner">
                                                    <span class="field-title">
                                                        Every
                                                    </span>

                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" placeholder="km" [(ngModel)]="newMaintenanceDetails.mileage" name="mileage" #mileage="ngModel" [required]="newMaintenanceDetails.isToggleMileage ? '' : null" [disabled]="!newMaintenanceDetails.isToggleMileage" [class.--disabled]="!newMaintenanceDetails.isToggleMileage" maxlength="6" OnlyNumber OnlyInteger>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups-inner">
                                                    <span class="field-title">
                                                        Alert when
                                                    </span>

                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" placeholder="km" [(ngModel)]="newMaintenanceDetails.thresholdMileage" name="thresholdMileage" #thresholdMileage="ngModel" [required]="newMaintenanceDetails.isToggleMileage ? '' : null" [disabled]="!newMaintenanceDetails.isToggleMileage" [class.--disabled]="!newMaintenanceDetails.isToggleMileage" maxlength="6" OnlyNumber OnlyInteger>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>

                                                    <span class="field-remaining">
                                                        (km) remaining
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__groups">
                                            <div class="field-group__groups-col">
                                                <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="newMaintenanceDetails.isToggleDurationMonth" name="isToggleDurationMonth" #isToggleDurationMonth="ngModel" (change)="toggle()">

                                                    Months
                                                </mat-slide-toggle>
                                            </div>

                                            <div class="field-group__groups-col">
                                                <div class="field-group__groups-inner">
                                                    <span class="field-title">
                                                        Every
                                                    </span>

                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" placeholder="mm" [(ngModel)]="newMaintenanceDetails.durationMonth" name="durationMonth" #durationMonth="ngModel" [required]="newMaintenanceDetails.isToggleDurationMonth ? '' : null" [disabled]="!newMaintenanceDetails.isToggleDurationMonth" [class.--disabled]="!newMaintenanceDetails.isToggleDurationMonth" maxlength="4" OnlyNumber OnlyInteger>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups-inner">
                                                    <span class="field-title">
                                                        Alert when
                                                    </span>

                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" placeholder="dd" [(ngModel)]="newMaintenanceDetails.thresholdDurationDays" name="thresholdDurationDays" #thresholdDurationDays="ngModel" [required]="newMaintenanceDetails.isToggleDurationMonth ? '' : null" [disabled]="!newMaintenanceDetails.isToggleDurationMonth" [class.--disabled]="!newMaintenanceDetails.isToggleDurationMonth" maxlength="5" OnlyNumber OnlyInteger>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
 
                                                    <span class="field-remaining">
                                                        (days) remaining
                                                    </span>
                                                </div>
                                                <div class="field-group__groups-inner-last">
                                                    <div class="field-group__groups-inner">
                                                        <span class="field-title">
                                                            Reminder Frequency
                                                        </span> 
    
                                                        <div class="field">
                                                            <div class="field__elems">
                                                                <label class="field__holder">
                                                                    <input type="text" class="field__input" placeholder="dd" [(ngModel)]="newMaintenanceDetails.thresholdDurationFrequencyDays" name="thresholdDurationFrequencyDays" #thresholdDurationFrequencyDays="ngModel" [required]="newMaintenanceDetails.isToggleDurationMonth ? '' : null" [disabled]="!newMaintenanceDetails.isToggleDurationMonth" [class.--disabled]="!newMaintenanceDetails.isToggleDurationMonth" maxlength="5" OnlyNumber OnlyInteger>
                                                                </label>
    
                                                                <div class="field__ui"></div>
                                                            </div>
                                                        </div>
    
                                                        <span class="field-remaining">
                                                            (days)
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>

                                        <div class="field-group__groups">
                                            <div class="field-group__groups-col">
                                                <mat-slide-toggle [color]="color" [disabled]="disabled" [(ngModel)]="newMaintenanceDetails.isToggleEngineHours" name="isToggleEngineHours" #isToggleEngineHours="ngModel" (change)="toggle()">

                                                    Engine hours (hours)
                                                </mat-slide-toggle>
                                            </div>

                                            <div class="field-group__groups-col">
                                                <div class="field-group__groups-inner">
                                                    <span class="field-title">
                                                        Every
                                                    </span>

                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" placeholder="hh" [(ngModel)]="newMaintenanceDetails.engineHours" name="engineHours" #engineHours="ngModel" [required]="newMaintenanceDetails.isToggleEngineHours ? '' : null" [disabled]="!newMaintenanceDetails.isToggleEngineHours" [class.--disabled]="!newMaintenanceDetails.isToggleEngineHours" maxlength="6" OnlyNumber OnlyInteger>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups-inner">
                                                    <span class="field-title">
                                                        Alert when
                                                    </span>

                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" placeholder="hh" [(ngModel)]="newMaintenanceDetails.thresholdEngineHours" name="thresholdEngineHours" #thresholdEngineHours="ngModel" [required]="newMaintenanceDetails.isToggleEngineHours ? '' : null" [disabled]="!newMaintenanceDetails.isToggleEngineHours" [class.--disabled]="!newMaintenanceDetails.isToggleEngineHours" maxlength="6" OnlyNumber OnlyInteger>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>

                                                    <span class="field-remaining">
                                                        (hours) remaining
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="selectedToggleCount.errors">
                                            <div class="field-error">
                                                Please choose a recurring type
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-vehicle-selector #vehicleAssign [title]="'Step 2: Assign Vehicles'"></app-vehicle-selector>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="maintenanceForm.invalid || vehicleAssign.getSelectedCount() == 0" (click)="showPopup(createMaintenancePopup)">
                                CREATE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToMaintenanceView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #createMaintenancePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to create new maintenance and schedule
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="createMaintenanceAndSchedule();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
