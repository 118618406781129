import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { TripsService } from '../../../../_services/trips/trips.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as DateTimeUtil from './../../../../util/dateTimeUtil';
import * as NumberUtil from './../../../../util/numberUtil';

// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';

@Component({
    providers: [GeneralReport],
    templateUrl: './geofence-report.component.html',
    styleUrls: ['./geofence-report.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class GeofenceReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

    expandGroupVehicle: any = [];
    expandGroupDriver: any = [];

    // Set Export File Name from environments
    exportPdfGeofencePageLayout = environment.appConfig.reporting.geofence.layout;
    exportFileNameGeofence = environment.appConfig.reporting.geofence.fileName;
    exportFileNameGeofencePdfTitle = environment.appConfig.reporting.geofence.label;
    pageLayout = this.exportPdfGeofencePageLayout;
    moment = moment;

    @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private pagerService: PagerService,
        private tripsService: TripsService,
        private snackBar: SnackBarService,
        private popupService_parent: PopupService,
        private popupCampaignService: PopupCampaignService
    ) {

        //pass services to parent
        super(http_parent, popupService_parent, snackBar);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingSearchBy: true,
            usingDateRangePicker: true,
            usingDriverSelector: true,
            usingVehicleSelector: true,
            usingGeofenceSelector: true
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {

        //Default search by
        this.searchBy = "vehicles";

        //Default search range
        // this.fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
        // this.toDate = moment().format("YYYY-MM-DD");

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //call api to get report page
            const apiControllerResponse: any = await this.getApiControllerResponse(startRecord);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;

                //update sorting classes
                this.updateSortingState(this.apiResponse.sort);
                this.resultList = apiControllerResponse.resultList;
                if (this.resultList.length) {

                    // parse some values to reduce function data bindings
                    this.resultList.forEach(record => {
                        if (record.GeoFenceEnterTime) {
                            record.GeoFenceEnterTime = moment(record.GeoFenceEnterTime, 'DD/MM/YYYY hh:mm:ss A').format('DD/MM/YYYY hh:mm A');
                        }
                        if (record.GeoFenceExitTime) {
                            record.GeoFenceExitTime = moment(record.GeoFenceExitTime, 'DD/MM/YYYY hh:mm:ss A').format('DD/MM/YYYY hh:mm A');
                        }
                        record.DurationLabel = DateTimeUtil.genTimeDuration(record.Duration, 'hour', 'semiColon', true);
                    });

                    //update isReportShown boolean
                    this.isReportShown = true;

                    //get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }
    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }
    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        apiResponse = await this.getGeofenceRecords(startRecord, isDownload);
        if (apiResponse != null) {
            apiResultList = apiResponse.geofenceReport;
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }
    // For download report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 16 }, { wch: 16 }, { wch: 25 }];

        filename = this.exportFileNameGeofence;
        label = this.exportFileNameGeofencePdfTitle;
        headerName = [
            "Vehicle", "Driver Name", "Geofence Name", "Date/Time In", "Date/Time Out",
            "Duration in Geofence (minutes)"
        ];

        for (let i = 0; i < recordList.length; i++) {
            const tempRow = [
                recordList[i].VehicleName,
                recordList[i].DriverName,
                recordList[i].GeoFenceName,
                moment(recordList[i].GeoFenceEnterTime, 'DD/MM/YYYY hh:mm:ss A').format('YYYY/MM/DD HH:mm'),
                moment(recordList[i].GeoFenceExitTime, 'DD/MM/YYYY hh:mm:ss A').format('YYYY/MM/DD HH:mm'),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Duration, 0, '0'))
            ];
            data.push(tempRow);
        }

        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    /**
     * Report Specific Methods
     */

    // 1. Call API Geofence Report
    async getGeofenceRecords(startRecord: number = 1, isDownload: boolean = false) {

        let result: any = null;

        const idList = this.currentSearchBy === 'vehicles' ? this.selectedVehiclesList : (this.currentSearchBy === 'drivers' ? this.selectedDriversList : this.selectedGeofencesList);

        if (!isDownload) {
            result = await this.tripsService.getGeofenceReport(this.currentSearchBy, idList, this.currentFromDate, this.currentToDate, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);

        } else {
            result = await this.tripsService.getGeofenceReport(this.currentSearchBy, idList, this.currentFromDate, this.currentToDate, null, null, this.currentSortField, this.currentSortAscending);
        }

        return result || null;
    }
}
