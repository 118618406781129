// import { environment } from './../../environments/environment';
import { ErrorHandler, Injectable } from '@angular/core';
import * as ErrorUtil from './../util/errorUtil';
import { ErrorHandlerModule } from './error-handler';

@Injectable()
export class GlobalErrorHandlerModule implements ErrorHandler {
    constructor(
        private errorHandler: ErrorHandlerModule,
    ) { }

    handleError(error: Error) {

        try {

            // swallow HTTPErrorAlreadyHandled error
            if (ErrorUtil.isHttpErrorAlreadyHandled(error)) {
                return;
            }
            this.errorHandler.sendErrorEmail(error);

        } catch (ex) {
            console.debug(ex);
        }

        // IMPORTANT: Rethrow the error otherwise it gets swallowed
        throw error;
        // window.location.href = '/error';
    }

}
