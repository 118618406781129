// import { fuelType } from './fuelType.constant';
export const fuelType: any = {
    RON95: {
        value: "RON95",
        batchValue: "95",
        label: "RON95"
    },
    RON97: {
        value: "RON97",
        batchValue: "97",
        label: "RON97"
    },
    RON100: {
        value: "RON100",
        batchValue: "100",
        label: "RON100"
    },
    DIESEL: {
        value: "DIESEL",
        batchValue: "diesel",
        label: "Diesel"
    },
    // BATTERY: {
    //     value: "BATTERY",
    //     label: "Battery"
    // },
    // WATER: {
    //     value: "WATER",
    //     label: "Water"
    // },
    // HYDROGEN: {
    //     value: "HYDROGEN",
    //     label: "Hydrogen"
    // }
};

export function getFuelTypeList() {
    return Object.keys(fuelType).map(result => fuelType[result].label);
}

export function isValidFuelType(value: string) {
    const labelList: string[] = Object.keys(fuelType).map(result => fuelType[result].label);
    return labelList.includes(value);
}

export function getFuelTypeByBatchValue(batchValue) {
    for (const eachType in fuelType) {
        if (fuelType[eachType].batchValue == batchValue) {
            return fuelType[eachType].value;
        }
    }
}
