<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md --space-top">
                    <h1 class="heading --level-1">
                        Upload Documents
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="showPopup(CreateDocumentPopup); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Upload Document
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Upload Document
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingDocument = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Document
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Document
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="tabs__content">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-document.svg" width="26" height="20" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input id="documentSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Documents" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchDocumentName()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchDocumentName()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table table__upload-document-general">
                            <table class="table__elem" style="width: 100%; table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <ng-container *ngIf="isDeletingDocument == true">
                                            <th scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDocumentGroups['all']" (ngModelChange)="onDocumentAllSelectChange()">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container>

                                        <th scope="col">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('FileName')" (click)="sort('FileName')">Document / Image
                                                <br>Name</button>
                                        </th>
                                        <th scope="col" width="10%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('creatorFirstName')" (click)="sort('creatorFirstName')">Upload By</button>
                                        </th>
                                        <th scope="col" width="10%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DateCreated')" (click)="sort('editorFirstName')">Date
                                                <br>Uploaded</button>
                                        </th>
                                        <th scope="col" width="10%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('editorFirstName')" (click)="sort('DateCreated')">Edited By</button>
                                        </th>
                                        <th scope="col" width="10%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DateUpdated')" (click)="sort('DateUpdated')">Date
                                                <br>Edited</button>
                                        </th>
                                        <th scope="col" width="6%">
                                            Action
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let document of documentList">
                                        <ng-container *ngIf="isDeletingDocument">
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="document.documentId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDocument[document.documentId]" (ngModelChange)="onDocumentSelectChange($event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>
                                        </ng-container>
                                        <td class="td-truncate" *ngIf="editable">
                                            <a class="link --primary" [title]="document.documentName || '-'" (click)="editDocument(document.documentId);showPopup(EditDocumentPopup);">{{document.fileName}}</a>
                                        </td>
                                        <td class="td-truncate" *ngIf="!editable">
                                            {{document.fileName}}
                                        </td>
                                        <td>
                                            {{document.creatorFullName || '-'}}
                                        </td>
                                        <td>
                                            {{document.dateCreated || '-'}}
                                        </td>
                                        <td>
                                            {{document.editorFullName || '-'}}
                                        </td>
                                        <td>
                                            {{document.dateUpdated || '-'}}
                                        </td>
                                        <td>
                                            <button (click)="downloadDocument(document.documentId)">
                                                <img src="assets/images/ng-components/reports/icon-download.svg" width="22" height="16" alt="">
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="record-not-found" *ngIf="documentList.length === 0">No Record Found</h2>
                        </div>
                    </div>

                    <div class="block" *ngIf="documentList.length > 0">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination">
                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDocuments(pager.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDocuments(page)">
                                        {{ page }}
                                    </button>

                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDocuments(pager.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingDocument">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedDocuments()}} Document(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteDocumentPopup)" [disabled]="countSelectedDocuments() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>

<app-popup #deleteDocumentPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedDocuments()}}</span> Document(s)
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteDocument();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #CreateDocumentPopup="popup" classes="--wide-more" [disableClickOutside]="true" (onClickClosed)="closeFileUploadPopup()">
    <app-upload #newFileUpload [isCreateOrEdit]="isCreate" [customFileType]="customFileType" [importErrorPopup]="DocumentErrorPopup" (isDocChange)="isDocChange($event)"></app-upload>
    <div class="block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" *ngIf="newFileUpload.fileUploadList?.length > 0" (click)="hidePopup();showPopup(DocumentConfirmPopup);">
                Submit
            </button>

            <button class=" button inline-button__inputs --dark-grey" type="button" (click)="closeFileUploadPopup()">
                Cancel
            </button>
        </div>
    </div>
</app-popup>

<app-popup #EditDocumentPopup="popup" classes="--wide-more" [disableClickOutside]="true" (onClickClosed)="closeFileUploadPopup()">
    <app-upload #existFileUpload [isCreateOrEdit]="isEdit" [customFileType]="customFileType" [importErrorPopup]="DocumentErrorPopup" (isDocChange)="isDocChange($event)"></app-upload>
    <div class="block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" [class.--dark-grey]="isDocChanged == false" type="button" *ngIf="existFileUpload.fileUploadList?.length > 0 && existFileUpload.fileUploadList?.length < 2" (click)="hidePopup();showPopup(DocumentConfirmPopup);" [disabled]="isDocChanged == false">
                Submit
            </button>

            <button class=" button inline-button__inputs --dark-grey" type="button" (click)="closeFileUploadPopup()">
                Cancel
            </button>
        </div>
    </div>
</app-popup>

<app-popup #DocumentErrorPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert" [innerHTML]="newFileUpload.batchErrorMessage? newFileUpload.batchErrorMessage : existFileUpload.batchErrorMessage"></span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(newFileUpload.batchErrorMessage? CreateDocumentPopup : EditDocumentPopup);">
                OK
            </button>
        </div>
    </div>
    <!-- <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div> -->
</app-popup>

<app-popup #DocumentConfirmPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm {{ newFileUpload.fileUploadList.length > 0 ? 'Create' : 'Edit' }} This Document?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="submitDocument(newFileUpload.fileUploadList.length > 0 ? 'CREATE' : 'EDIT');hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #DocumentDownloadedPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Download Document
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p [innerHtml]="documentDownloadedMsg"></p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                Ok
            </button>
        </div>
    </div>
</app-popup>
