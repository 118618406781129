<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Admin Role & Permissions
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #roleForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Step 1: Create Role
                    </h2>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-9of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Role Name: <span class="indicator-green">*</span>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="text" class="field__input" name="roleName" [(ngModel)]="role.name" required>
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Step 2: Customise Permissions
                    </h2>
                </div>

                <div class="block">
                    <div class="table__wrapper">
                        <table class="table__elem">
                            <thead>
                                <tr>
                                    <th scope="col" class="thead__col">
                                        <label class="inline-input" [class.--transparent]="!isShowPermissionSelectAllOption">
                                            <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedPermissionGroups['all']" (ngModelChange)="onPermissionsAllSelectChange()">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </th>

                                    <th scope="col" class="thead__col">
                                        Module
                                    </th>

                                    <th scope="col" class="thead__col">
                                        View
                                    </th>

                                    <th scope="col" class="thead__col">
                                        Edit
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let permissions of pageModuleList; let groupIndex = index;">
                                    <ng-container *ngIf="permissions.moduleType === 'MODULE' && permissions.subModuleList?.length > 0 && permissions.viewable">
                                        <tr class="table-row-group">
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="permissions.moduleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedPermissionGroups[groupIndex]" (ngModelChange)="onPermissionsGroupSelectChange(groupIndex, $event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>

                                            <td scope="col">
                                                {{permissions.moduleName}}
                                            </td>

                                            <td scope="col">
                                            </td>

                                            <td scope="col">

                                            </td>

                                            <button class="table-group-expand" type="button" (click)="expandGroupPermission[groupIndex] = !expandGroupPermission[groupIndex]">
                                                <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupPermission[groupIndex]">
                                            </button>
                                        </tr>

                                        <ng-container *ngIf="expandGroupPermission[groupIndex]">
                                            <tr class="tr__elem-sub" *ngFor="let permissionsChild of permissions.subModuleList">
                                                <td scope="col">

                                                </td>

                                                <td scope="col">
                                                    <label class="inline-input">
                                                        <input type="checkbox" [name]="permissionsChild.moduleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedPermissions[permissionsChild.moduleId]" (ngModelChange)="onPermissionsSelectChange(groupIndex, permissionsChild.moduleId, $event)">

                                                        <span class="inline-input__icon"></span>

                                                        <span class="inline-input__text">{{permissionsChild.moduleName}}</span>
                                                    </label>
                                                </td>

                                                <td scope="col">
                                                    <label class="inline-input" *ngIf="permissionsChild.viewable === 1">
                                                        <input type="checkbox" [name]="'view' + permissionsChild.moduleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedPermissionsView[permissionsChild.moduleId]" (ngModelChange)="onPermissionsViewSelectChange(groupIndex, permissionsChild.moduleId, $event)">

                                                        <span class="inline-input__icon"></span>
                                                    </label>
                                                </td>

                                                <td scope="col">
                                                    <label class="inline-input" *ngIf="permissionsChild.editable === 1">
                                                        <input type="checkbox" [name]="'edit' + permissionsChild.moduleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedPermissionsEdit[permissionsChild.moduleId]" (ngModelChange)="onPermissionsEditSelectChange(groupIndex, permissionsChild.moduleId, $event)">

                                                        <span class="inline-input__icon"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="permissions.moduleType === 'MODULE' && permissions.subModuleList?.length === 0 && permissions.viewable">
                                        <tr>
                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="permissions.moduleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedPermissions[permissions.moduleId]" (ngModelChange)="onPermissionsSelectChange(groupIndex, permissions.moduleId, $event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>

                                            <td scope="col">
                                                {{permissions.moduleName}}
                                            </td>

                                            <td scope="col">
                                                <label class="inline-input" *ngIf="permissions.viewable === 1">
                                                    <input type="checkbox" [name]="'view' + permissions.moduleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedPermissionsView[permissions.moduleId]" (ngModelChange)="onPermissionsViewSelectChange(groupIndex, permissions.moduleId, $event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>

                                            <td scope="col">
                                                <label class="inline-input" *ngIf="permissions.editable === 1">
                                                    <input type="checkbox" [name]="'edit' + permissions.moduleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedPermissionsEdit[permissions.moduleId]" (ngModelChange)="onPermissionsEditSelectChange(groupIndex, permissions.moduleId, $event)">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-9of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Set up the home page: <span class="indicator-green">*</span>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="select">
                                                        <select class="select__input" name="homePage" [(ngModel)]="role.homePageId" required>
                                                            <option *ngFor="let item of homePageList;" [value]="item.id" [selected]="item.id === role.homePageId">{{item.name}}</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Step 3: Assign Admins
                    </h2>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-10of12 w-6of12@md search__group">
                            <div class="field-group --unstack@xs search__select-field">
                                <div class="field-group__item search-group__item">
                                    <div class="field">
                                        <div class="field__elems">
                                            <div class="field__icon">
                                                <div class="field__icon-inner">
                                                    <img src="assets/images/scss-components/forms/icon-search-admin.svg" width="23" height="22" alt="" class="field__icon-image">
                                                </div>
                                            </div>

                                            <label class="field__holder">
                                                <input type="text" class="field__input" placeholder="Search Admin" maxlength="200" [(ngModel)]="filterValue" name="filter" (keyup.enter)="getAdminList()">
                                            </label>

                                            <div class="field__ui"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="button__holder">
                                <button type="button" class="button" (click)="getAdminList()">SEARCH</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="table__wrapper">
                        <table class="table__elem">
                            <thead>
                                <tr>
                                    <th scope="col" class="thead__col">
                                        <label class="inline-input">
                                            <input type="checkbox" name="all" class="inline-input__input" [value]="true" [(ngModel)]="selectedAdminGroups['all']" (ngModelChange)="onAdminAllSelectChange()">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </th>

                                    <th scope="col" class="thead__col">
                                        Admin Name
                                    </th>

                                    <th scope="col" class="thead__col">
                                        Email
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let admin of adminList">
                                    <td scope="col">
                                        <label class="inline-input">
                                            <input type="checkbox" [name]="'admin' + admin.id" [value]="true" class="inline-input__input" [(ngModel)]="selectedAdmins[admin.id]" (ngModelChange)="onAdminSelectChange($event)">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </td>

                                    <td scope="col">
                                        {{admin.firstName}}
                                    </td>

                                    <td scope="col">
                                        {{admin.email}}
                                    </td>
                                </tr>
                                <h2 class=" record-not-found" *ngIf="adminList.length === 0" style="margin-top: 0px;">No Records Found</h2>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="roleForm.invalid" (click)="showPopup(createRolePopup)">
                                SAVE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToRoleView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #createRolePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to create new driver
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="createRole();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
