import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as StringUtil from '../../util/stringUtil';

@Injectable({
    providedIn: 'root'
})
export class TestService {

    constructor(private http: HttpClient) { }

    async simulateGetLiveStreamingVideo() {
        await this.simulateWaitingTime(5000);
        return "assets/videos/testVideo.mp4";
    }

    async simulateWaitingTime(seconds) {
        await new Promise(resolve => setTimeout(resolve, seconds));
    }
}
