import { Injectable } from '@angular/core';
// import { RequestOptions, Headers } from '@angular/http';
import { HttpClient } from '@angular/common/http';
// import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import * as StringUtil from './../../util/stringUtil';
import * as NumberUtil from './../../util/numberUtil';





@Injectable({
    providedIn: 'root'
})

export class MaintenanceService {

    constructor(private http: HttpClient) { }

    // Get Past Maintenance Report
    async getPastMaintenance(vehicleIds, startDate, endDate, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/maintenance/past";
        const body = {
            "vehicleIds": vehicleIds, //[1,2,3],
            "startDate": startDate, //"2018-01-01",
            "endDate": endDate //"2018-12-31"
        };

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    // Get Past Maintenance Report
    async getUpcomingMaintenance(vehicleIds, pageSize, startRecord, sortField, sortAscending) {
        const path = environment.mtntApiEndpoint + "/report/maintenance/upcoming";
        const body = {
            "vehicleIds": vehicleIds //[1,2,3]
        };

        if (Boolean(pageSize)) {
            body['pageSize'] = pageSize;
        }

        if (Boolean(startRecord)) {
            body['startRecord'] = startRecord;
        }

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body['sortField'] = sortField.trim();
        }

        if (typeof (sortAscending) == typeof (true)) {
            body['sortAscending'] = sortAscending;
        }

        const result = await this.http.post(path, body).toPromise();
        return result;
    }

    async getMaintenanceProfileDetails(maintenanceId) {
        const path = environment.mtntApiEndpoint + "/maintenance/" + maintenanceId;
        const result = await this.http.get(path).toPromise();
        return result;
    }

    // Create Maintenance Profile & Schedules
    async createMaintenanceAndSchedule(params) {
        const path = environment.mtntApiEndpoint + "/maintenance";

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(params.name)) {
            params.name = params.name.trim();
        }

        const result = await this.http.post(path, params).toPromise();
        return result;
    }

    // Update Maintenance Profile & Schedules
    async updateMaintenanceAndSchedule(params) {
        const path = environment.mtntApiEndpoint + "/maintenance";

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(params.name)) {
            params.name = params.name.trim();
        }

        const result = await this.http.put(path, params).toPromise();
        return result;
    }

    // Delete Maintenance Profile & Schedules
    async deleteMaintenanceAndSchedule(maintenanceId) {
        const path = environment.mtntApiEndpoint + "/maintenance";
        const result = await this.http.delete(path, {
            params: {
                id: maintenanceId
            }
        }).toPromise();
        return result;
    }

    // Mark Maintenance Complete
    async markMaintenanceComplete(params) {
        const path = environment.mtntApiEndpoint + "/maintenance-schedules/" + params.maintenanceScheduleId;
        const body = {
            serviceDate: params.serviceDate,
            odometer: params.odometer,
            engineHours: NumberUtil.numberOrUndefined(params.engineHours),
            notes: params.notes
        };
        const result = await this.http.put(path, body).toPromise();
        return result;
    }

    // Get Vehicle Maintenance By Schedule
    async getVehicleMaintenanceBySchedule(pageRecordSize: number = 0, startRecord: number = 0, sortField: string = '', sortAscending: boolean = null, filterValue: string = '') {
        const path = environment.mtntApiEndpoint + `/maintenance`;
        const params: any = {};
        params.pageSize = pageRecordSize;
        params.startRecord = startRecord;
        if (sortField) {
            params.sortField = sortField;
        }
        if (StringUtil.isNotEmptyOrNull(sortAscending)) {
            params.sortAscending = sortAscending;
        }
        if (filterValue) {
            params.filterValue = filterValue;
        }
        const result: any = await this.http.get(path, {
            params: params
        }).toPromise();
        return result;
    }

    // Get Vehicle Maintenance By Vehicle
    async getVehicleMaintenanceByVehicle(pageRecordSize: number = 0, startRecord: number = 0, sortField: string = '', sortAscending: boolean = null, filterValue: string = '', vehicleId: number = null) {
        const path = environment.mtntApiEndpoint + `/maintenance-schedules`;
        const params: any = {};
        params.pageSize = pageRecordSize;
        params.startRecord = startRecord;
        if (sortField) {
            params.sortField = sortField;
        }
        if (StringUtil.isNotEmptyOrNull(sortAscending)) {
            params.sortAscending = sortAscending;
        }
        if (filterValue) {
            params.filterValue = filterValue;
        }
        if (vehicleId) {
            params.vehicleId = vehicleId;
        }
        const result: any = await this.http.get(path, {
            params: params
        }).toPromise();
        return result;
    }

    // Get Upcoming Vehicle Maintenance (No pagination)
    async getUpcomingVehicleMaintenanceNoPaging(filterValue: string = '', groupId = null) {
        const path = environment.mtntApiEndpoint + `/maintenance-schedules`;
        const result: any = await this.http.get(path, {
            params: {
                filterValue: filterValue,
                filterOnlyUpcoming: 'true',
                groupId: groupId
            }
        }).toPromise();
        return result;
    }

    // Get Upcoming Vehicle Maintenance (With Pagination)
    async getUpcomingVehicleMaintenance(pageRecordSize: number = 0, startRecord: number = 0, sortField: string = '', sortAscending: boolean = null, filterValue: string = '', vehicleId: number = null) {
        const path = environment.mtntApiEndpoint + `/maintenance-schedules`;
        const params: any = {};
        params.pageSize = pageRecordSize;
        params.startRecord = startRecord;
        if (sortField) {
            params.sortField = sortField;
        }
        if (StringUtil.isNotEmptyOrNull(sortAscending)) {
            params.sortAscending = sortAscending;
        }
        if (filterValue) {
            params.filterValue = filterValue;
        }
        if (vehicleId) {
            params.vehicleId = vehicleId;
        }
        params.filterOnlyUpcoming = true;
        const result: any = await this.http.get(path, {
            params: params
        }).toPromise();
        return result;
    }

}
