import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AccessControlService } from './../access-control/access-control.service';
import { RouterProxyService } from './../router-proxy/router-proxy.service';
// import { SnackBarService } from '../snackBar/snack-bar.service';
import { TokenService } from '../token/token.service';
import { environment } from './../../../environments/environment';

const LEFTBAR_REGEX = /\(leftbar:.*\)/g;

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private authService: AuthService,
        private tokenService: TokenService,
        private accessControlService: AccessControlService,
        private routerProxyService: RouterProxyService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // throw new Error('test stacktrace');

        // console.warn("Guard called");
        // console.debug('-----route', route);
        // console.debug('-----state', state);
        // console.debug('canActivate: Visited URL = ' + state.url);

        // Login page handler
        if (state.url.endsWith("/login")
            || state.url.includes("/login/sso")) {
            if (this.authService.isAuthenticated()) {
                this.routerProxyService.navigateToHomePage();
                return false;
            }
            return true;
        }

        /* Logic to handle leftbar outlet */
        if (!state.url.startsWith('/' + environment.appConfig.page.mapUrlPath)) {
            // Remove leftbar outlet for non-map pages
            if (state.url.match(LEFTBAR_REGEX)) {
                this.routerProxyService.navigateByUrl(this.removeLeftbarOutlet(state.url));
                return false;
            }
        } else if (!state.url.match(LEFTBAR_REGEX)) {
            // Route to map-list page if missing leftbar
            this.routerProxyService.navigateToMapPage();
            return false;
        }

        // Check Page Authentication
        if (this.authService.isAuthenticated()) {

            // Stay signed in
            const stayLoggedInResp: any = await this.authService.refreshTokenAsync();
            if (stayLoggedInResp && stayLoggedInResp.abortAuthGuard == true) {
                return false; //abort auth guard
            }

            // Check Token Expiry
            // if (this.authService.isLoginTokenPastExpiryTime()) { //client side method
            //     // if (await this.authService.isTokenExpired()) { //server side method
            //     await this.authService.sessionExpiredRedirectLogin();
            //     return false;
            // } else if (this.tokenService.isAdministratorUserTokenExist()) {
            //     if (this.authService.isRefTokenPastExpiryTime()) { //client side method
            //         // if (await this.authService.isAdministratorTokenExpired()) { //server side method
            //         await this.authService.supportSessionExpiredRedirectSupportPanel();
            //         return false;
            //     }
            // }

            // Strict page access authentication
            const exactPageUrl = this.getPageUrlWithoutParamsAndOutlets(route.routeConfig.path, route.params, route.queryParams, state.url);
            // console.log('exactPageUrl: ', exactPageUrl);

            const hasRequiredPermission: boolean = await this.accessControlService.hasPermission(exactPageUrl);
            // console.debug(`canActivate: hasPermission(${exactPageUrl}):` + hasRequiredPermission);

            if (hasRequiredPermission) {
                const supportId = this.tokenService.getUserSupportId();
                //If is Maxis Support User
                if (supportId) {
                    //If having ref token
                    if (this.tokenService.isAdministratorUserTokenExist()) {
                        return true;
                    } else {
                        //Check if page requires ref token
                        const isRequiredRefToken: boolean = await this.accessControlService.isRequiredRefToken(exactPageUrl);
                        // console.debug(`canActivate: isRequiredRefToken(${exactPageUrl}):` + isRequiredRefToken);
                        if (isRequiredRefToken) {
                            return this.tokenService.navigateToSupportPage(supportId, route); // will return true or false
                        }
                    }
                }
                return true;
            }

            // Skip Page Access Authentication if ActivatedRoute is not Last Segment of URL
            // let isLastSegment = this.checkIfActivatedRouteIsLastSegmentOfRouter(route, state);
            // if (!isLastSegment) {
            //     return true;
            // }

            //show warning if not Last Segment of URL
            this.checkIfActivatedRouteIsLastSegmentOfRouter(route, state);

            //route to access error page
            this.routerProxyService.navigate(['/error'], {
                queryParams: {
                    returnUrl: state.url
                }
            });

            return false;

        } else {

            //Set return URL for redirection upon login
            this.routerProxyService.navigate(['/login'], {
                queryParams: {
                    returnUrl: state.url
                }
            });

            return false;
        }
    }

    private removeLeftbarOutlet(url: string): string {
        let newUrl = url;
        // let queryParams = '';
        // if (url.indexOf(")") !== url.length - 1) {
        //     queryParams = url.substring(url.indexOf(")") + 1, url.length);
        // }
        if (url.match(LEFTBAR_REGEX)) {
            for (const leftbar of url.match(LEFTBAR_REGEX)) {
                newUrl = url.replace(leftbar, '');
            }
            // newUrl = url.substring(0, url.indexOf(LEFTBAR_OUTLET_KEYWORD));
            // if (queryParams.length > 0) {
            //     newUrl += queryParams;
            // }
        }
        return newUrl;
    }

    private getPageUrlWithoutParamsAndOutlets(routeConfigPath: string, params: any, queryParams: any, stateUrl: string): string {

        let pageUrl = stateUrl.slice(0);

        //remove params at URL
        const template = routeConfigPath.slice(0);
        for (const paramName in params) {
            if (params.hasOwnProperty(paramName)) {
                const paramTemplate = `/:${paramName}`;
                if (template.includes(paramTemplate)) {
                    const paramValue = `/${params[paramName]}`;
                    pageUrl = pageUrl.replace(paramValue, '');
                } else {
                    const paramValue = `;${encodeURI(paramName)}=${encodeURI(params[paramName])}`;
                    pageUrl = pageUrl.replace(paramValue, '');
                }
            }
        }

        //remove outlets: leftbar
        pageUrl = this.removeLeftbarOutlet(pageUrl);

        //remove queryParams at URL
        let qpIndex = 0;
        for (const qparamName in queryParams) {
            if (queryParams.hasOwnProperty(qparamName)) {
                let paramValue = qpIndex == 0 ? '?' : '&';
                paramValue += `${encodeURI(qparamName)}=${encodeURI(queryParams[qparamName])}`;
                pageUrl = pageUrl.replace(paramValue, '');
            }
            qpIndex++;
        }

        // console.debug('canActivate: Actual URL = ' + pageUrl);
        return pageUrl;
    }

    private checkIfActivatedRouteIsLastSegmentOfRouter(route: ActivatedRouteSnapshot, router: RouterStateSnapshot) {
        const activatedPath = route.routeConfig.path;
        const lastSegmentPath = this.getLastSegmentOfRouter(router.root).routeConfig.path;
        // console.debug('canActivate: activatedPath = ' + activatedPath);
        // console.debug('canActivate: lastSegmentPath = ' + lastSegmentPath);
        if (lastSegmentPath == activatedPath) {
            // console.debug('canActivate: ActivatedRoute is the last segment in router. Proceed with page access authentication.');
            return true;
        }
        console.warn('canActivate: This segment of URL(' + activatedPath + ') is not the last child(' + lastSegmentPath + ') of router, therefore strict page access authentication will fail. Consider removing authGuard for this segment path.');
        // console.warn('canActivate: This segment of URL(' + activatedPath + ') is not the last child(' + lastSegmentPath + ') of router. Skipping [canActivate] process in this segment and proceed to child routes in the Router Tree. Consider removing authGuard for this segment path.');
        return false;
    }

    private getLastSegmentOfRouter(router: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
        if (router.children.length) {
            return this.getLastSegmentOfRouter(router.firstChild);
        } else {
            return router;
        }
    }

}
