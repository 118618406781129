import {tap,  timeout, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';

import { Platform } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';

import { Observable ,  of, throwError, from } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth';
import { SnackBarService } from '../snackBar/snack-bar.service';
// import { ErrorHandlerModule } from './../error-handler';

import * as LocalStorageUtil from '../../util/localStorageUtil';
import * as StringUtil from '../../util/stringUtil';
import * as DomUtil from '../../util/domUtil';
import * as ObjectUtil from '../../util/objectUtil';

const HTTP_TIMEOUT_IN_SECONDS = 45;
const HTTP_TIMEOUT_IN_SECONDS_TRIP_DETAIL = 60;
const HTTP_TIMEOUT_IN_SECONDS_REPORT = 120;
const HTTP_TIMEOUT_IN_SECONDS_CAMPAIGN = 60;

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private snackBar: SnackBarService,
        // private errorHandler: ErrorHandlerModule,
        private authService: AuthService,
        private platform: Platform,
        private appVersion: AppVersion
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Remove forceSwallowError from API request body
        const forceSwallowError: boolean = request.body && request.body.forceSwallowError;
        if (forceSwallowError) {
            delete request.body.forceSwallowError;
        }

        // Initiate time out duration
        let apiTimeOutInMs = HTTP_TIMEOUT_IN_SECONDS * 1000;
        if (request.url.includes('/report/trip/details')) {
            console.debug('RequestInterceptor: Time Out extended to 1min for trip-detail report download');
            apiTimeOutInMs = HTTP_TIMEOUT_IN_SECONDS_TRIP_DETAIL * 1000;
        } else if (request.url.includes('/report/')) {
            console.debug('RequestInterceptor: Time Out extended to 1min for report UI and downloads');
            apiTimeOutInMs = HTTP_TIMEOUT_IN_SECONDS_REPORT * 1000;
        } else if (request.url.includes('/campaign')) {
            console.debug('RequestInterceptor: Time Out extended to 1min for campaign');
            apiTimeOutInMs = HTTP_TIMEOUT_IN_SECONDS_CAMPAIGN * 1000;
        } else if (request.url.includes('/stat')) {
            console.debug('RequestInterceptor: Time Out extended to 5min for campaign');
            apiTimeOutInMs = 5 * 1000 * 60;
        }

        return from(this.appendExtraHTTPHeaders(request))
            .pipe(mergeMap((req: HttpRequest<any>) => {
                return next.handle(req)
                    .pipe(
                        timeout(apiTimeOutInMs),
                        catchError(
                            (error: any, caught: Observable<HttpEvent<any>>) => {

                                // Attach HTTP request into error object
                                if (request) {
                                    error.request = request;
                                    error.timeoutLimit = apiTimeOutInMs;
                                }

                                console.debug('RequestInterceptor: Error Status Code (' + error.status + ') caught');
                                console.debug('RequestInterceptor: Full HTTPErrorResponse -> ' + JSON.stringify(error, null, 2));

                                /* Ignore Certain APIs */
                                const ignoreURLs: string[] = ['email/sendEmailPostfix', 'email/sendPlainEmailSES'];
                                if (request.url && ignoreURLs.filter(item => request.url.includes(item)).length) {
                                    return throwError(error);
                                }

                                if (error.error) {

                                    // Flag the error.hasDisplayableError = true
                                    if (error.error.errorCode) {
                                        error.hasDisplayableError = true;
                                    }

                                    const handlingExcludeList: string[] = [
                                        'user-login',
                                        'token/verify',
                                        'token/refresh',
                                        'authorization/page-access'
                                    ];
                                    if (error.error.errorCode == 500203 || error.error.errorCode == 500204 || error.error.errorCode == 500210) {
                                        // Control: the invalid token to be auto logout from client side
                                        this.authService.noAuthorizationRedirectLogin(true); // logout
                                        return throwError(error);
                                    } else if (request.url && handlingExcludeList.filter(item => request.url.includes(item)).length) {
                                        console.debug('RequestInterceptor: URL is in exclude-list, error not handled, rethrow to downstreams');
                                        return throwError(error);

                                    } else if (error.status === 401) {
                                        // Note: Rarely will hit this case unless always-logged-in is OFF
                                        this.authService.noAuthorizationRedirectLogin(); // logout
                                        return of(error); // swallow error

                                    } else if (error.hasDisplayableError) {
                                        // Backend API returned ErrorCode & ErrorMessage
                                        if (!forceSwallowError) {
                                            // error will be thrown downstream
                                            console.debug('RequestInterceptor: Backend API Error rethrow to downstreams (HTTP: ' + error.status + ')');
                                            return throwError(error);
                                        } else {
                                            // error will be handled by downstream
                                            console.debug('RequestInterceptor: Backend Error swallowed due to "forceSwallowError" parameter in HTTP Request body. Error will be manually handled by downstreams');
                                            return of(error); // swallow error
                                        }
                                    }
                                }

                                /* Non-Backend Error (HTTP, or unknown) */
                                if (!error.message) {
                                    // Almost impossible to happen
                                    // Should be handled by downstreams to sendEmail in ErrorHandlerModule.handleStandardizedError()
                                    console.warn('RequestInterceptor: Unknown HttpErrorResponse. Error is not handled and thrown to downstreams to send email.');
                                    return throwError(error);
                                }

                                return this.handleNonBackendError(error);
                            }
                        )
                    )
                    .pipe(
                    tap((event: HttpEvent<any>) => {
                        // console.debug('Event intercepted by request interceptor:');

                        if (event instanceof HttpResponse) {
                            const newToken = event.headers.get('newToken');
                            if (newToken) {
                                const currentUser = JSON.parse(new Buffer(newToken, 'base64').toString("ascii"));
                                currentUser.tokenExpiryDate = this.authService.estimateExpiryDate(currentUser.expiresIn);
                                LocalStorageUtil.localStorageSet('currentUser', currentUser);
                            }
                            if (event.url.includes('administrator-login')) {
                                // Set Ref Token only if response has it
                                const result: any = event.body;
                                if (result.body.refToken) {
                                    result.body.tokenExpiryDate = this.authService.estimateExpiryDate(result.body.expiresIn);
                                    LocalStorageUtil.localStorageSet('administratorUser', result.body);
                                }
                            } else if (event.url.includes('my-account/change-password')) {
                                // update refresh token in user session
                                const result: any = event.body.body;
                                if (result.userToken) {
                                    const newToken = result.userToken;
                                    newToken.tokenExpiryDate = this.authService.estimateExpiryDate(newToken.expiresIn);
                                    LocalStorageUtil.localStorageSet('currentUser', newToken);
                                }
                            }
                        }

                    }));
            }));
    }

    async appendExtraHTTPHeaders(request) {

        // Add some client information into request body
        const clientInfo = {
            devicePlatform: DomUtil.getDevicePlatforms(this.platform),
            mobileVersion: '',
            webVersion: environment.systemInfo.version
        };
        if (DomUtil.isMobileApp(this.platform)) {
            await DomUtil.getMobileAppInfo(this.platform, this.appVersion)
                .then((appInfo) => {
                    clientInfo.mobileVersion = appInfo.versionCode;
                });
        }
        request = this.addCustomHTTPHeader(request, 'X-Client-Device-Platform', clientInfo.devicePlatform);
        request = this.addCustomHTTPHeader(request, 'X-Client-Web-Version', clientInfo.webVersion);
        request = this.addCustomHTTPHeader(request, 'X-Client-MobApp-Version', clientInfo.mobileVersion);

        return request;
    }

    handleNonBackendError(error: HttpErrorResponse) {
        console.debug('RequestInterceptor: Handling non back-end error');
        let isHandled: boolean = false;

        // Error will not have .hasDisplayableError property at this point
        isHandled = this.snackBar.openStandardizedErrorSnackBar(error);

        if (isHandled) {
            // Do not swallow, throw a dummy error that will be ignored by all error handlers
            return throwError('HTTPErrorAlreadyHandled: Potential missing catch clause when making HTTP call.\n    Request: ' + error['request'].url + '\n    Details: This error is purposely thrown by RequestInterceptor to abort downstream code execution during HTTP call failure, And should be caught and swallowed by downstream at the API caller function.');
        } else {
            return throwError(error);
        }
    }

    private addCustomHTTPHeader(req: HttpRequest<any>, headerName: string, headerVal: string): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                [headerName]: headerVal
            }
        });
    }

}
