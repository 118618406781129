import { Subscription } from "rxjs";
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as AsyncUtil from './../util/asyncUtil';

/**
 * Make same page navigation works.
 * Invocation cycle:
 *  1. componentPreInitCallback(), if any
 *  2. If preInit returns true, continue with 3,4,5:
 *  3. Unsubscribe previous componentSubscription
 *  4. componentDestroyCallback(), if any
 *  5. componentInitCallback(), if any
 * @param options (object) options required
 * @return (promise)
 */
export function makeSamePageNavigationWorks(options: {
    pageUrlConstant: string,
    componentSubscription: Subscription,
    /**
     * Executes before calling init callback, returns a boolean
     * @return (boolean) indicates whether to continue invocation cycle
    */
    componentPreInitCallback: () => boolean | Promise<boolean>,
    componentInitCallback: () => void,
    componentDestroyCallback: () => void,
    componentRouter: Router
}): void {

    killRxjsSubscription(options.componentSubscription);

    options.componentSubscription = options.componentRouter.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged()
    ).subscribe(event => {
        // console.debug('ComponentUtil: router event for same page navigations');
        try {
            const preIniter = async function () {
                // invoke pre-init callback
                if (options.componentPreInitCallback) {
                    return await options.componentPreInitCallback();
                }
                return true;
            };
            const initer = async function () {
                // invoke init callback
                if (options.componentInitCallback) {
                    await options.componentInitCallback();
                }
            };
            const destroyer = async function () {
                killRxjsSubscription(options.componentSubscription);
                // invoke destroy callback
                if (options.componentDestroyCallback) {
                    await options.componentDestroyCallback();
                    await AsyncUtil.wait(0);
                }
            };

            if (event instanceof RouterEvent
                && event.url.includes(options.pageUrlConstant)
                && window.location.href.includes(options.pageUrlConstant)) {
                return AsyncUtil.asAsyncPromise(async function () {
                    if (await preIniter() == true) {
                        await destroyer();
                        await initer();
                    }
                });
            } else {
                // self-destroy to avoid leaking
                return AsyncUtil.asAsyncPromise(async function () {
                    await destroyer();
                });
            }
        } catch (err) {
            throw err;
        }
    });
}

export function killRxjsSubscription(subscription: Subscription) {
    try {
        if (subscription) {
            subscription.unsubscribe();
        }
    } catch (err) {
        // do nothing
    }
}