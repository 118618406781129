<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md --space-top">
                    <h1 class="heading --level-1">
                        Maintenance
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top" [hidden]="currentTab !== 1">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToCreateMaintenance(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Maintenance
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Maintenance
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingMaintenance = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Maintenance
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Maintenance
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">

                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">

                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section   bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="bySchedule()">By
                    Schedule</button>
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="byVehicles()">By
                    Vehicles</button>
            </div>
        </div>
    </div>

    <div class="tabs__content" [hidden]="currentTab !== 1">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-maintenance.svg" width="21" height="21" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input id="maintenanceSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Maintenance" [(ngModel)]="filterValueSchedule" name="filter" (keyup.enter)="searchMaintenanceSchedule()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchMaintenanceSchedule()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table table__maintenance-by-schedule">
                            <table class="table__elem" style="width: 100%; table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <!--ng-container *ngIf="isDeletingMaintenance">
                                            <th scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input"
                                                        [(ngModel)]="selectedMaintenanceGroups['all']" (ngModelChange)="onMaintenanceAllSelectChange()">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container-->
                                        <th scope="col">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Name')" (click)="sort('Name')">Maintenance Name</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('Mileage')" (click)="sort('Mileage')">Distance (km)</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('ThresholdDurationDays')" (click)="sort('ThresholdDurationDays')">Months</button>
                                        </th>
                                        <th scope="col" width="18%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('EngineHours')" (click)="sort('EngineHours')">Engine Hours (hh)</button>
                                        </th>
                                        <th scope="col" width="18%">No. of Vehicles</th>
                                        <ng-container *ngIf="isDeletingMaintenance">
                                            <th scope="col" width="10%">
                                            </th>
                                        </ng-container>
                                    </tr>
                                </thead>

                                <tbody>
                                    <ng-container>
                                        <tr *ngFor="let maintenance of vehicleMaintenanceByScheduleList">
                                            <!--ng-container *ngIf="isDeletingMaintenance">
                                                <td scope="col">
                                                    <label class="inline-input">
                                                        <input type="checkbox" [name]="maintenance.id" [value]="true"
                                                            class="inline-input__input" [(ngModel)]="selectedMaintenance[maintenance.id]"
                                                            (ngModelChange)="onMaintenanceSelectChange($event)">

                                                        <span class="inline-input__icon"></span>
                                                    </label>
                                                </td>
                                            </ng-container-->
                                            <td class="td-truncate" *ngIf="editable">
                                                <a class="link --primary" (click)="navigateToEditMaintenance(maintenance.id)">{{maintenance.name
                                                    || '-'}}</a>
                                            </td>
                                            <td class="td-truncate" *ngIf="!editable">
                                                {{maintenance.name || '-'}}
                                            </td>
                                            <td>
                                                {{maintenance.mileage || '-'}}
                                            </td>
                                            <td>
                                                {{maintenance.durationMonth || '-'}}
                                            </td>
                                            <td>
                                                {{maintenance.engineHours || '-'}}
                                            </td>
                                            <td>
                                                {{maintenance.vehicleCount || '-'}}
                                            </td>
                                            <td scope="col" *ngIf="isDeletingMaintenance">
                                                <button (click)="deleteMaintenance(maintenance);showPopup(deleteMaintenancePopup);" class="link --primary">Delete</button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <h2 class="record-not-found" *ngIf="vehicleMaintenanceByScheduleList.length === 0">No Record Found</h2>
                        </div>
                    </div>

                    <div class="block" *ngIf="vehicleMaintenanceByScheduleList.length > 0">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination">
                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getVehicleMaintenanceBySchedule(pager.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>

                                    <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getVehicleMaintenanceBySchedule(page)">{{
                                        page }}</button>

                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getVehicleMaintenanceBySchedule(pager.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="tabs__content" [hidden]="currentTab !== 2">
        <div class="section">
            <div class="wrapper">
                <div class="driver__filters" style="position: relative; z-index: 3;">
                    <div class="block">
                        <div class="grid --gutter">
                            <div class="grid__block w-6of12@sm">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item search-group__item">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="22" alt="" class="field__icon-image">
                                                    </div>
                                                </div>

                                                <label class="field__holder">
                                                    <input id="vehicleSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Vehicle" [(ngModel)]="filterValueVehicle" name="filter" (keyup.enter)="searchMaintenanceVehicle()">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                        <div class="button__holder">
                                            <button type="button" class="button" (click)="searchMaintenanceVehicle()">SEARCH</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="hide-when-md-dn" [ngStyle]="{ 'text-align': 'right', 'padding-bottom': '10px' }">
                            <span class="--warning">*Upcoming Maintenance</span>
                            <span class="--red" [ngStyle]="{'margin-left':'10px'}">*Overdue Maintenance</span>
                        </div>
                        <div class="table table__maintenance-by-vehicle">
                            <table class="table__elem" style="width: 100%; table-layout: fixed;">
                                <thead>
                                    <tr>
                                        <th scope="col" width="16%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('VehicleName')" (click)="sort('VehicleName')">Vehicle</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('VehiclePlateNo')" (click)="sort('VehiclePlateNo')">Number<br>Plate</button>
                                        </th>
                                        <th scope="col" width="13%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('MaintenanceName')" (click)="sort('MaintenanceName')">Maintenance<br>Name</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('PrevServiceDate')" (click)="sort('PrevServiceDate')">Previous<br>Service Date</button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DistanceUntil')" (click)="sort('DistanceUntil')">Distance<br><span class="text-small">(km to Next Service)</span></button>
                                        </th>
                                        <th scope="col" width="12%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('DaysUntil')" (click)="sort('DaysUntil')">Days<br><span class="text-small">(to Next Service)</span></button>
                                        </th>
                                        <th scope="col" width="14%">
                                            <button type="button" class="table__sortable" [ngClass]="getSortingState('EngineHourUntil')" (click)="sort('EngineHourUntil')">Engine Hours<br><span class="text-small">(hh:mm to Next Service)</span></button>
                                        </th>
                                        <th scope="col" width="9%">
                                            Action
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let schedule of vehicleMaintenanceByVehicleList">
                                        <td class="td-break-word td-truncate">
                                            {{schedule.vehicleName || '-'}}
                                        </td>
                                        <td>
                                            {{schedule.vehiclePlateNo || '-'}}
                                        </td>
                                        <td class="td-break-word">
                                            {{schedule.maintenanceName || '-'}}
                                        </td>
                                        <td>
                                            {{schedule.prevServiceDateLabel || '-'}}
                                        </td>
                                        <td [class.--red]="schedule.distanceUntil < 0" [class.--warning]="schedule.distanceUntil > 0 && schedule.distanceUntil < schedule.thresholdMileage">
                                            <span *ngIf="schedule.distanceUntilLabel != '-'" [title]="'(Scheduled at: ' + schedule.nextOdometerLabel + ' km)'">
                                                {{schedule.distanceUntilLabel}}
                                            </span>
                                            <span *ngIf="schedule.distanceUntilLabel == '-'">
                                                -
                                            </span>
                                        </td>
                                        <td [class.--red]="schedule.daysUntil < 0" [class.--warning]="schedule.daysUntil > 0 && schedule.daysUntil < schedule.thresholdDurationDays">
                                            <span *ngIf="schedule.daysUntilLabel != '-'" [title]="'(Scheduled at: ' + schedule.nextServiceDateLabel + ')'">
                                                {{schedule.daysUntilLabel}}
                                            </span>
                                            <span *ngIf="schedule.daysUntilLabel == '-'">
                                                -
                                            </span>
                                        </td>
                                        <td [class.--red]="schedule.engineHourUntil < 0" [class.--warning]="schedule.daysUntil > 0 && schedule.daysUntil < schedule.thresholdEngineHours">
                                            <span *ngIf="schedule.engineHourUntilLabel != '-'" [title]="'(Scheduled at: ' + schedule.nextEngineHoursLabel + ')'">
                                                {{schedule.engineHourUntilLabel}}
                                            </span>
                                            <span *ngIf="schedule.engineHourUntilLabel == '-'">
                                                -
                                            </span>
                                        </td>
                                        <td class="td-break-word" *ngIf="editable">
                                            <button (click)="markMaintenanceComplete(schedule);showPopup(markComplete);" class="link --primary">Mark as<br>completed</button>
                                        </td>
                                        <td class="td-break-word" *ngIf="!editable">
                                            -
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="record-not-found" *ngIf="vehicleMaintenanceByVehicleList.length === 0">No Record Found
                            </h2>
                        </div>
                    </div>

                    <div class="block" *ngIf="vehicleMaintenanceByVehicleList.length > 0">
                        <div class="grid --gutter">
                            <div class="grid__block   w-4of12@sm   as-c@sm">
                                <div class="notes">
                                    Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                                </div>
                            </div>
                            <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                                <div class="pagination">
                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getVehicleMaintenanceByVehicle(pager.currentPage - 1)">
                                        <span class="angle-arrow --left">
                                        </span>
                                        <span class="ha">Previous Page</span>
                                    </button>
                                    <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getVehicleMaintenanceByVehicle(page)">{{
                                        page }}</button>
                                    <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getVehicleMaintenanceByVehicle(pager.currentPage + 1)">
                                        <span class="angle-arrow --right">
                                        </span>
                                        <span class="ha">Next Page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingMaintenance">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <!--<div class="delete-dialog__total">
                You have selected {{countSelectedMaintenance()}} maintenance(s)
            </div>-->

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <!--<button class="button inline-button__inputs" type="button" (click)="showPopup(deleteMaintenancePopup)">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23"
                            height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>-->

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL DELETE
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #searchSuccess="popup" class="search-popups --success">
    <div class="popup__block">
        <img src="assets/images/ng-components/create-manage/icon-snackbar-success.svg" alt="" width="19" height="13" class="search-popups__icon">

        <div class="search-popups__text">
            Process Successful!
        </div>
    </div>
</app-popup>

<app-popup #searchFail="popup" class="search-popups --fail">
    <div class="popup__block">
        <img src="assets/images/ng-components/create-manage/icon-snackbar-fail.svg" alt="" width="4" height="17" class="search-popups__icon">

        <div class="search-popups__text">
            Please key in required fields
        </div>
    </div>
</app-popup>

<app-popup #deleteMaintenancePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are about to update maintenance and renew service schedules
        </p>

        <p class="notes">
            <b>{{selectedToDelete.vehicleCount}}</b>&nbsp;vehicle(s) will be deleted from the service schedule
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="confirmDeleteMaintenance();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #markMaintenancePopup="popup" class="cm-popups" (onClickClosed)="showPopup(markComplete)">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Mark Complete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            Your next maintenance will be scheduled as below:
        </p>
        <table width="100%">
            <tbody align="center">
                <tr>
                    <td scope="col" width="45%">
                        Next Service Date
                    </td>
                    <td scope="col" width="5%">
                        :
                    </td>
                    <td scope="col" width="45%">
                        {{simulatedNextSchedule.serviceDate}}
                    </td>
                </tr>
                <tr>
                    <td scope="col" width="45%">
                        Next Odometer
                    </td>
                    <td scope="col" width="5%">
                        :
                    </td>
                    <td scope="col" width="45%">
                        {{simulatedNextSchedule.odometer}}
                    </td>
                </tr>
                <tr>
                    <td scope="col" width="45%">
                        Next Engine Hours
                    </td>
                    <td scope="col" width="5%">
                        :
                    </td>
                    <td scope="col" width="45%">
                        {{simulatedNextSchedule.engineHours}}
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="confirmMarkMaintenanceComplete();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(markComplete);">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #markComplete="popup" class="maintenance-info-popups" classes="--wide-more">
    <div class="popup__block">
        <div class="grid --gutter --flex-bottom">
            <div class="grid__block w-8of12@sm form__flex">
                <h1 class="heading indicator-green">
                    {{selectedToMarkComplete.vehicleName}}
                </h1>
            </div>

            <div class="grid__block w-4of12@sm form__flex">
                <div class="compulsory-notes --no-margin --aligned-right">
                    *Compulsory fields
                </div>
            </div>
        </div>
    </div>

    <div class="popup__block">
        <h2 class="heading --level-2">
            {{selectedToMarkComplete.maintenanceName}}
        </h2>
    </div>

    <form class="form-field" #markCompleteForm="ngForm">
        <div class="popup__block">
            <div class="field-group --unstack@xs --space-top">
                <div class="field-group__item">
                    <div class="field-group__holder">
                        <div class="field-group__label">
                            Service Date:
                            <span class="indicator-green">*</span>
                        </div>

                        <div class="field-group__input">
                            <div class="field">
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <mat-datepicker-toggle [for]="ServiceDatepicker">
                                                <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                            </mat-datepicker-toggle>
                                        </div>
                                    </div>

                                    <input [matDatepicker]="ServiceDatepicker" (focus)="ServiceDatepicker.open()" (click)="ServiceDatepicker.open()" type="text" class="field__input" [(ngModel)]="markCompleteDetails.serviceDate" [max]="moment().startOf('day').format()" placeholder="Choose a date" name="markServiceDate" #markServiceDate="ngModel" readonly dom-states #ExpiryDateDOMStates="DOMStates" required>

                                    <div class="field__ui"></div>
                                </div>

                                <mat-datepicker #ServiceDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                            </div>

                            <div *ngIf="markServiceDate.errors && (markServiceDate.dirty || markServiceDate.touched)">
                                <div class="field-error" *ngIf="markServiceDate.errors?.required">
                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Service Date')}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field-group__holder">
                        <div class="field-group__label">
                            Current Odometer (km):
                        </div>

                        <div class="field-group__input">
                            <div class="field">
                                <div class="field__elems">
                                    <label class="field__holder">
                                        <input type="text" class="field__input" [value]="selectedToMarkComplete.currentOdometerLabel" name="currentOdometer" [class.--disabled]="true" readonly>
                                    </label>

                                    <div class="field__ui"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field-group__holder">
                        <div class="field-group__label">
                            Service Odometer (km):
                            <span class="indicator-green">*</span>
                        </div>

                        <div class="field-group__input">
                            <div class="field">
                                <div class="field__elems">
                                    <label class="field__holder">
                                        <input type="text" class="field__input" [(ngModel)]="markCompleteDetails.odometer" name="markOdometer" #markOdometer="ngModel" required maxlength="11" OnlyNumber>
                                    </label>
                                    <div class="field__ui"></div>
                                </div>
                            </div>
                            <div *ngIf="markOdometer.errors && (markOdometer.dirty || markOdometer.touched)">
                                <div class="field-error" *ngIf="markOdometer.errors?.required">
                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Odometer')}}
                                </div>
                                <div class="field-error" *ngIf="markOdometer.errors?.maxlength">
                                    {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Odometer','11')}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field-group__holder">
                        <div class="field-group__label">
                        </div>

                        <div class="field-group__input">
                            <!-- CHECK BUTTTON TICK -->
                            <div class="field">
                                <div class="field__elems">
                                    <label class="inline-input">
                                        <input type="checkbox" [value]="true" [(ngModel)]="checkOdometer" name="checkOdometer" class="inline-input__input">
                                        <span class="inline-input__icon"></span>
                                        <span>Update Current Odometer</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedToMarkComplete.recurringEngineHours" class="field-group__holder">
                        <div class="field-group__label">
                            Current Engine Hours (hh:mm):
                        </div>

                        <div class="field-group__input">
                            <div class="field split-field">
                                <div class="field__elems">
                                    <label class="field__holder">
                                        <input type="text" class="field__input" [value]="selectedToMarkComplete.currentEngineHoursLabel" name="currentEngineHours" [class.--disabled]="true" readonly>
                                    </label>
                                    <div class="field__ui"></div>
                                </div>
                            </div>
                            <div class="split-separator">
                                :
                            </div>
                            <div class="field split-field">
                                <div class="field__elems">
                                    <label class="field__holder">
                                        <input type="text" class="field__input" [value]="selectedToMarkComplete.currentEngineMinutesLabel" name="currentEngineMinutes" [class.--disabled]="true" readonly>
                                    </label>
                                    <div class="field__ui"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedToMarkComplete.recurringEngineHours" class="field-group__holder">
                        <div class="field-group__label">
                            Service Engine Hours (hh:mm):
                            <span class="indicator-green">*</span>
                        </div>

                        <div class="field-group__input">
                            <div class="field split-field">
                                <div class="field__elems">
                                    <label class="field__holder">
                                        <input type="text" class="field__input" [(ngModel)]="markCompleteDetails.engineHours" name="markEngineHours" #markEngineHours="ngModel" OnlyNumber OnlyInteger required MinNumber="0" maxLength="6" placeholder="hh">
                                    </label>

                                    <div class="field__ui"></div>
                                </div>
                            </div>
                            <div class="split-separator">
                                :
                            </div>
                            <div class="field split-field">
                                <div class="field__elems">
                                    <label class="field__holder">
                                        <input type="text" class="field__input" [(ngModel)]="markCompleteDetails.engineMinutes" name="markEngineMinutes" #markEngineMinutes="ngModel" OnlyNumber OnlyInteger required MinNumber="0" MaxNumber="59" maxLength="2" placeholder="mm">
                                    </label>
                                    <div class="field__ui"></div>
                                </div>
                            </div>


                            <div *ngIf="(markEngineHours.errors || markEngineMinutes.errors) && (markEngineHours.dirty || markEngineMinutes.dirty || markEngineHours.touched || markEngineMinutes.touched)">
                                <div class="field-error" *ngIf="markEngineHours.errors?.required || markEngineMinutes.errors?.required">
                                    {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Engine Hours')}}
                                </div>
                                <div class="field-error" *ngIf="markEngineHours.errors?.maxlength">
                                    {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Hours')}}
                                </div>
                                <div class="field-error" *ngIf="markEngineMinutes.errors?.maxlength">
                                    {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Minutes')}}
                                </div>
                            </div>

                            <!-- CHECK BUTTTON TICK -->
                            <div>
                                <label class="inline-input" style="margin-top: 10px">
                                    <input type="checkbox" [value]="true" [(ngModel)]="checkEngineHour" name="checkEngineHour" class="inline-input__input">
                                    <span class="inline-input__icon"></span>
                                    <span>Update Engine Hours</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="field-group__holder">
                        <div class="field-group__label">
                            Maintenance Cost (RM):
                        </div>

                        <div class="field-group__input">
                            <div class="field">
                                <div class="field__elems">
                                    <label class="field__holder">
                                        <input type="text" class="field__input" [(ngModel)]="markCompleteDetails.cost" name="markCost" #markCost="ngModel" maxlength="11" OnlyNumber>
                                    </label>
                                    <div class="field__ui"></div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="field-group__holder">
                        <div class="field-group__label">
                            Notes:
                        </div>

                        <div class="field-group__input">
                            <div class="field">
                                <div class="field__elems">
                                    <label class="field__holder">
                                        <textarea class="field__input" rows="5" [(ngModel)]="markCompleteDetails.notes" name="markNotes" #markNotes="ngModel" maxLength="4000"></textarea>
                                    </label>

                                    <div class="field__ui"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup__block">
            <div class="inline-button__field">
                <button class="button inline-button__inputs" type="button" [disabled]="markCompleteForm.invalid" (click)="simulateSchedule();showPopup(markMaintenancePopup);">
                    SAVE
                </button>

                <button class="button inline-button__inputs --grey" type="button" (click)="resetMarkCompleteForm();hidePopup();">
                    CANCEL
                </button>
            </div>
        </div>
    </form>

</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
