<div [ngClass]="sectionClass">
    <div [ngClass]="wrapperClass">
        <div [ngClass]="blockClass">
            <h2 class="heading indicator-green --level-2 --partial-underline">
                {{ title }}
            </h2>
        </div>

        <div [ngClass]="blockClass">
            <div class="grid --gutter">
                <div class="grid__block w-6of12@sm">
                    <div class="field-group --unstack@xs">
                        <div class="field-group__item search-group__item">
                            <div class="field" #vehiclesField>
                                <div class="field__elems">
                                    <div class="field__icon">
                                        <div class="field__icon-inner">
                                            <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image">
                                        </div>
                                    </div>

                                    <label class="field__holder">
                                        <input id="vehicleSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Vehicles" dom-states #vehiclesSearchDOMStates="DOMStates" (keyup)="filterVehiclesSelection($event.target.value)" maxlength="45">
                                    </label>

                                    <div class="field__ui"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass]="blockClass">
            <div class="table__wrapper">
                <table class="table__elem">
                    <thead>
                        <tr>
                            <th scope="col" class="thead__col">
                                <label class="inline-input" [class.--transparent]="!isShowVehicleSelectAllOption">
                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups['all']" (ngModelChange)="onVehiclesAllSelectChange()">

                                    <span class="inline-input__icon"></span>
                                </label>
                            </th>

                            <th scope="col" class="thead__col">
                                <button type="button" class="table__sortable" [ngClass]="getSortingState('VehicleName')" (click)="sort('VehicleName')">Vehicle Name</button>
                            </th>

                            <th scope="col" class="thead__col">
                                <button type="button" class="table__sortable" [ngClass]="getSortingState('PlateNo')" (click)="sort('PlateNo')">Number Plate</button>
                            </th>

                            <th scope="col" class="thead__col">
                                <button type="button" class="table__sortable" [ngClass]="getSortingState('MakeModel')" (click)="sort('MakeModel')">Vehicle Make / Model</button>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <div *ngIf="vehicleResult.vehicles && !vehicleResult.vehicles.length" class="no-record">
                            <label>
                                No vehicle found
                            </label>
                        </div>
                        <ng-container *ngIf="!withGroup">
                            <tr *ngFor="let vehicle of vehicleUniqueList">
                                <td scope="col">
                                    <label class="inline-input">
                                        <input type="checkbox" [name]="vehicle.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[vehicle.vehicleId]" (ngModelChange)="onVehiclesSelectChange()">

                                        <span class="inline-input__icon"></span>
                                    </label>
                                </td>

                                <td scope="col">
                                    {{vehicle.vehicleName}}
                                </td>

                                <td scope="col">
                                    {{vehicle.plateNo}}
                                </td>

                                <td scope="col">
                                    {{vehicle.vehicleMake}} {{vehicle.vehicleModel}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="withGroup">
                            <ng-container *ngFor="let vehicleGroup of vehicleResult.vehicles; let groupIndex = index;">
                                <ng-container *ngIf="vehicleGroup.type === 'GROUP'">
                                    <tr class="table-row-group">
                                        <td scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" [name]="vehicleGroup.name" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicleGroups[groupIndex]" (ngModelChange)="onVehiclesGroupSelectChange(groupIndex);">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>

                                        <td scope="col">
                                            {{vehicleGroup.name}}
                                        </td>

                                        <td scope="col"></td>

                                        <td scope="col"></td>

                                        <button class="table-group-expand" (click)="expandGroupVehicle[groupIndex] = !expandGroupVehicle[groupIndex]">

                                            <img src="assets/images/ng-components/past-trip/icon-arrow.svg" width="30" height="8" [class.expand-open]="!expandGroupVehicle[groupIndex]">

                                        </button>
                                    </tr>

                                    <ng-container *ngIf="expandGroupVehicle[groupIndex]">
                                        <tr class="tr__elem-sub" *ngFor="let vehicle of vehicleGroup.idList">
                                            <td scope="col">

                                            </td>

                                            <td scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" [name]="vehicle.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[vehicle.vehicleId]" (ngModelChange)="onVehiclesSelectChange()">

                                                    <span class="inline-input__icon"></span>

                                                    <span class="inline-input__text">{{vehicle.vehicleName}}</span>
                                                </label>
                                            </td>

                                            <td scope="col">
                                                {{vehicle.plateNo}}
                                            </td>

                                            <td scope="col">
                                                {{vehicle.vehicleMake}} {{vehicle.vehicleModel}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="vehicleGroup.type === 'INDIVIDUAL'">
                                    <tr *ngFor="let vehicle of vehicleGroup.idList">
                                        <td scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" [name]="vehicle.vehicleId" [value]="true" class="inline-input__input" [(ngModel)]="selectedVehicles[vehicle.vehicleId]" (ngModelChange)="onVehiclesSelectChange()">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>

                                        <td scope="col">
                                            {{vehicle.vehicleName}}
                                        </td>

                                        <td scope="col">
                                            {{vehicle.plateNo}}
                                        </td>

                                        <td scope="col">
                                            {{vehicle.vehicleMake}} {{vehicle.vehicleModel}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <app-spinner #page_spinner [disableScroll]="true"></app-spinner>
                    </tbody>
                </table>
            </div>
        </div>

        <div [ngClass]="blockClass">
            <div class="grid --gutter">
                <div class="grid__block   w-4of12@sm   as-c@sm">
                    <div class="notes">
                        {{getSelectedDisplayText()}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
