import * as moment from 'moment';

/**
 * Validate date range
 * @param fromDate (moment object) start date
 * @param toDate (moment object) end date
 * @param maxRange (number) 0 = unchecked
 * @return (boolean) true = pass validation
 */
export function checkDateRange(fromDate: moment.Moment = null, toDate: moment.Moment = null, maxRange: number = 0) {

    let pass: boolean = false;
    const momFromDate: moment.Moment = moment(fromDate);
    const momToDate: moment.Moment = moment(toDate);
    let msg = '';
    // let test = new SnackBarService(this.config);

    //todo: use popup UI from designer
    if (!momFromDate.isValid() || !momToDate.isValid()) {
        msg = "The selected Date is Invalid";
        // alert("Invalid Date!!!");
    } else if (momFromDate.isAfter(momToDate)) {
        msg = "Start Date must be the date before End Date";
        // alert("Start Date must be the Date before End Date");
    } else if (maxRange > 0 && Math.abs(momFromDate.diff(momToDate)) > maxRange) {
        msg = "Date range exceeds maximum of " + maxRange + " days!";
        // alert("Date range exceeds maximum of " + maxRange + " days!");
    } else {
        pass = true;
    }

    return { pass: pass, message: msg };
}

/**
 * Get the 'week of year' base on input date
 * @param date (moment object) date to get week number
 * @return (object) year and week number of the date
 */
export function fromDateToYearAndWeekNo(date: moment.Moment = null): any {

    let weekNo: number = 0;
    let year: number = 0;
    const momDate: moment.Moment = moment(date);

    if (!momDate.isValid()) {
        throw new Error("Invalid date input");
    }
    weekNo = momDate.isoWeek();
    year = momDate.isoWeekYear();

    return {
        year: year,
        weekNo: weekNo
    };
}

/**
 * Get the start/end date range of a particular 'week of year'
 * @param weekNo (number) week number (start)
 * @param year (number) year (start)
 * @param weekNo (number) optional: end week number
 * @param year (number) optional: end year
 * @return (object) consists of start and end date of week (moment object)
 */
export function fromWeekYearToDateRange(weekNo: number = 0, year: number = 0, weekNo2: number = 0, year2: number = 0) {

    if (weekNo == 0 || year == 0) {
        throw new Error("Invalid week/year input");
    }

    const momDate = moment().isoWeekYear(year).isoWeek(weekNo);
    const startDate = moment(momDate).startOf('isoWeek');
    let endDate = moment(momDate).endOf('isoWeek');

    if (weekNo2 > 0 && year2 > 0) {
        const momDate2 = moment().isoWeekYear(year2).isoWeek(weekNo2);
        endDate = moment(momDate2).endOf('isoWeek');
    }

    return {
        startDate: startDate,
        endDate: endDate
    };
}

// Generate Hour, Minute and Second
export function generateHourMinuteSecond() {
    const times = 60; // For Minute and Second
    const minute: string[] = [];
    const second: string[] = [];
    const hour: string[] = [];
    const fullHour: string[] = [];

    for (let i = 0; i < times; i++) {
        let value: string = String(i);
        // Add 0 in front single value
        if (value.length < 2) {
            value = '0' + value;
        }
        if (i != 0 && i <= 12) {
            hour.push(value);
        }
        if (i <= 23) {
            fullHour.push(value);
        }
        minute.push(value);
        second.push(value);
    }
    return {
        hour: hour,
        fullHour: fullHour,
        minute: minute,
        second: second
    };
}

export function genTimeDuration(floatValue, floatValueType, formatType = 'hms', withoutSecond = false) {
    if (floatValue == null || floatValue == undefined) {
        return '-';
    }

    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let strh = "";
    let strm = "";
    let strs = "";
    switch (formatType) {
        case "hms":
            strh = 'h ';
            strm = 'm ';
            strs = 's ';
            break;
        case "semiColon":
            strh = ":";
            strm = ":";
            strs = ":";
            break;
    }

    let timePrecedence = 0;
    switch (floatValueType) {
        case "hour":
            timePrecedence = 3;
            break;
        case "minute":
            timePrecedence = 2;
            break;
        case "second":
            timePrecedence = 1;
            break;
    }

    let time = '';

    //floatValue always pass in as second either in string format or number
    if (timePrecedence >= 3) {
        hours = parseInt(floatValue); //try parse string to number
        hours = hours / 60 / 60;
        hours = parseInt(hours.toString());
        const strHours = hours.toString().length < 2 ? `0${hours}` : hours;
        floatValue = floatValue - (hours * 60 * 60);
        time += strHours + strh;
    }
    if (timePrecedence >= 2) {
        minutes = parseInt(floatValue);
        minutes = minutes / 60;
        minutes = parseInt(minutes.toString());
        const strMinutes = minutes.toString().length < 2 ? `0${minutes}` : minutes;
        floatValue = floatValue - (minutes * 60);
        time += strMinutes + strm;
    }
    if (withoutSecond) {
        if (time.lastIndexOf(':') != -1) {
            time = time.substr(0, time.length - 1);
        }
        return time;
    }
    if (timePrecedence >= 1) {
        seconds = parseInt(floatValue);
        const strSeconds = seconds.toString().length < 2 ? `0${seconds}` : seconds;
        floatValue = (floatValue - seconds);
        time += strSeconds + strs;
    }

    if (time.lastIndexOf(':') != -1) {
        time = time.substr(0, time.length - 1);
    }
    return time;
}

export function sortAscending(obj1, obj2, key, format = 'YYYY-MM-DD') {

    if (format) {
        const date1 = moment(obj1[key], format);
        const date2 = moment(obj2[key], format);
        if (date1.isBefore(date2)) {
            return -1;
        }
        if (date1.isAfter(date2)) {
            return 1;
        }
    }
    return 0;
}

export function sortDescending(obj1, obj2, key) {
    return sortAscending(obj1, obj2, key) * -1;
}

export function getDateLabel(date, currFormat, desireFormat) {
    const checkDate = moment(date, currFormat).isValid();
    if (!checkDate) {
        return '';
    }
    return moment(date, currFormat).format(desireFormat);
}

export function isLeapYear(year): boolean {
    if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) {
        return true;
    }
    return false;
}

export function isValidDateTime(dateTime, regexExpression, format): boolean {
    const regex = new RegExp(regexExpression);
    // validate dateTime format using the given regex Expression
    if (regex.test(dateTime)) {
        const dateTimeMoment = moment(dateTime, format);
        const year = parseInt(dateTimeMoment.format("YYYY"));
        if (isLeapYear(year)) {
            // only leap year return 29-Feb isValid() = true;
            if (!dateTimeMoment.isValid()) {
                return false;
            }
        }
        return true;
    }
    return false;
}
