import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';

import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { PopupService } from './../../../common/popup/popup.service';
import { MaintenanceService } from './../../../../_services/maintenance/maintenance.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import * as DomUtil from '../../../../util/domUtil';
import * as StringUtil from './../../../../util/stringUtil';
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

@Component({
    templateUrl: './create-maintenance.component.html',
    styleUrls: ['./create-maintenance.component.scss']
})
export class CreateMaintenanceComponent implements OnInit {

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private popupService: PopupService,
        private maintenanceService: MaintenanceService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    //HTML use
    message = Message;
    @ViewChild("page_spinner",{static:false}) pageSpinner: SpinnerComponent;

    color = 'primary';
    disabled = false;

    // Maintenance Form
    @ViewChild('maintenanceForm',{static:false}) maintenanceForm: NgForm;
    newMaintenanceDetails: any = {
        name: '',
        isRecurring: true,
        isToggleMileage: false, //mileage
        mileage: null,
        thresholdMileage: null,
        isToggleEngineHours: false, //engineHours
        engineHours: null,
        thresholdEngineHours: null,
        isToggleDurationMonth: false, //durationMonth
        durationMonth: null,
        thresholdDurationDays: null,
        thresholdDurationFrequencyDays: null,
        selectedToggleCount: ''
    };

    // Schedule Form
    @ViewChild('vehicleAssign',{static:true}) vehicleAssign: VehicleSelectorComponent;

    markedDirty: boolean = false;

    ngOnInit() {
        DomUtil.autoFocusForm(this.el);
        this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async createMaintenanceAndSchedule() {
        this.pageSpinner.show();
        try {
            //Remove empty keys
            const cloneForm = JSON.parse(JSON.stringify(this.newMaintenanceDetails));

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(cloneForm.name)) {
                cloneForm.name = cloneForm.name.trim();
            }

            const integerFields = ['mileage',
                'thresholdMileage',
                'engineHours',
                'thresholdEngineHours',
                'durationMonth',
                'thresholdDurationDays',
                'thresholdDurationFrequencyDays'
            ];
            for (const objKey in cloneForm) {
                if (cloneForm.hasOwnProperty(objKey)) {
                    const value = cloneForm[objKey];
                    if (value === null || value === undefined) {
                        delete cloneForm[objKey];
                    } else if (integerFields.indexOf(objKey) != -1) {
                        cloneForm[objKey] = parseInt(cloneForm[objKey]);
                    }
                }
            }
            let selectedRecurring: number = 3;
            if (!cloneForm.isToggleMileage) {
                delete cloneForm.mileage;
                delete cloneForm.thresholdMileage;
                selectedRecurring--;
            }
            if (!cloneForm.isToggleEngineHours) {
                delete cloneForm.engineHours;
                delete cloneForm.thresholdEngineHours;
                selectedRecurring--;
            }
            if (!cloneForm.isToggleDurationMonth) {
                delete cloneForm.durationMonth;
                delete cloneForm.thresholdDurationDays;
                delete cloneForm.thresholdDurationFrequencyDays;
                selectedRecurring--;
            }

            if (!selectedRecurring) {
                this.pageSpinner.hide();
                this.snackBar.openGenericSnackBar('Please choose at least ONE(1) recurring type.');
                return;
            }

            delete cloneForm.isToggleMileage;
            delete cloneForm.isToggleDurationMonth;
            delete cloneForm.isToggleEngineHours;
            delete cloneForm.selectedToggleCount;
            cloneForm.vehicleIds = this.vehicleAssign.getSelectedVehicleIds();

            let result = null;
            result = await this.maintenanceService.createMaintenanceAndSchedule(cloneForm);

            //Show Snackbar
            const mName = cloneForm.name;
            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Maintenance', mName);
            if (result) {
                if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Maintenance', mName);
                } else if (result.statusCode == ResponseStatusCode.PARTIAL.code) {
                    msg = Message.getMessage(Message.MESSAGE.CREATE_PARTIAL.value, 'Maintenance', mName);
                }
                this.navigateSuccessToMaintenanceView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToMaintenanceView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/maintenance/view'], null, 'schedule');
    }

    navigateToMaintenanceView() {
        this.routerProxyService.navigate(['/create-manage/maintenance/view']);
    }

    markNumericFieldsDirty() {
        try {
            if (!this.markedDirty) {
                //Make some fields dirty on first load
                this.maintenanceForm.form.get('mileage').markAsDirty();
                this.maintenanceForm.form.get('thresholdMileage').markAsDirty();
                this.maintenanceForm.form.get('engineHours').markAsDirty();
                this.maintenanceForm.form.get('thresholdEngineHours').markAsDirty();
                this.maintenanceForm.form.get('durationMonth').markAsDirty();
                this.maintenanceForm.form.get('thresholdDurationDays').markAsDirty();
                this.maintenanceForm.form.get('thresholdDurationFrequencyDays').markAsDirty();
                this.markedDirty = true;
            }
        } catch (err) {
            //do nothing
        }
    }

    toggle() {
        this.markNumericFieldsDirty();
        if (this.newMaintenanceDetails.isToggleMileage
            || this.newMaintenanceDetails.isToggleEngineHours
            || this.newMaintenanceDetails.isToggleDurationMonth) {
            this.newMaintenanceDetails.selectedToggleCount = 'selected';
        } else {
            this.newMaintenanceDetails.selectedToggleCount = '';
        }
    }

    /* ---- popup table ---- */
    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

}
