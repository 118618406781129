import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import * as NumberUtil from './../../../../util/numberUtil';
import * as StringUtil from './../../../../util/stringUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';
import * as ObjectUtil from '../../../../util/objectUtil';
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { ScoringService } from './../../../../_services/scoring/scoring.service';
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import * as Message from '../../../../constants/message';

import { Chart } from 'chart.js';
import { DashboardColors as Colors } from './../../dashboard/DashboardColors';
import { ScoringCharts, ScoringChartChildObj } from './scoringCharts';
const CHART_TICK_LABEL_MAX_LENGTH: number = 5; // chartJS truncate: axis tick label
const CHART_TOOLTIP_MAX_LENGTH: number = 22; // chartJS truncate: tooltip title

@Component({
    providers: [GeneralReport],
    templateUrl: './scoring-report.component.html',
    styleUrls: ['./scoring-report.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s ease-out', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ScoringReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

    expandGroupVehicle: any = [];
    expandGroupDriver: any = [];

    // View
    resultType: any = 'vehicle';

    // Set Export File Name from environments
    exportPdfScoringPageLayout = environment.appConfig.reporting.scoring.layout;
    exportFileNameWeekly: string = environment.appConfig.reporting.scoring.weekly.filename;
    exportFileNameLifetime: string = environment.appConfig.reporting.scoring.lifetime.filename;
    exportFileNameWeeklyPdfTitle: string = environment.appConfig.reporting.scoring.weekly.label;
    exportFileNameLifetimePdfTitle: string = environment.appConfig.reporting.scoring.lifetime.label;
    pageLayout = this.exportPdfScoringPageLayout;
    moment = moment;

    @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    showGraph: boolean = false;
    showLifeTimeGraph: boolean = false;
    showWeeklyGraph: boolean = false;

    // CHARTS states
    chartJsTooltipTruncateCallbackTitleFn: any;
    scoreCharts: ScoringCharts; //chart main class
    padding: number = 0;
    stepSize: number = 20;
    // genColorfulHorizontalBarChart
    colourList: string[];
    violationPieColourList: string[];
    chartJsBarHoverInteraction: any;
    chartJsTooltipStyles: any;
    chartJsHoverProps: any;
    chartJsTicksFontStyles: any;
    chartJsTicksStringEllipsisCallbackFn: any;
    chartJsTicksNumberTruncateCallbackFn: any;
    chartJsAnimation: any;
    //overrideChartJsOptions
    chartJsScaleLabelFontStyles: any;

    scoreViolationGraphType: string = 'Percentage';
    lifeTimeScoreViolationGraphType: string = 'Percentage';

    @ViewChild("scoreGraph_spinner",{static:false}) scoreGraph_spinner: SpinnerComponent;
    @ViewChild("scoreViolationGraph_spinner",{static:false}) scoreViolationGraph_spinner: SpinnerComponent;
    @ViewChild("lifeTimeScoreGraph_spinner",{static:false}) lifeTimeScoreGraph_spinner: SpinnerComponent;
    @ViewChild("lifeTimeScoreViolationGraph_spinner",{static:false}) lifeTimeScoreViolationGraph_spinner: SpinnerComponent;
    @ViewChild("lifeTimeScoreImpactGraph_spinner",{static:false}) lifeTimeScoreImpactGraph_spinner: SpinnerComponent;

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private scoringService: ScoringService,
        private el: ElementRef,
        private popupService_parent: PopupService,
        private popupCampaignService: PopupCampaignService,
        private ref: ChangeDetectorRef
    ) {

        //pass services to parent
        super(http_parent, popupService_parent, snackBar);
        this.scoreCharts = new ScoringCharts(this.ref);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingSearchBy: true,
            usingSearchType: true,
            usingDateRangePicker: true,
            usingDriverSelector: true,
            usingVehicleSelector: true,
            usingDriverTagSelector: true
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {

        //Default search by
        this.searchBy = "vehicles";

        //Default search type
        this.searchType = "weekly";
        this.currentSearchType = "weekly";

        //Default search range
        // this.fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
        // this.toDate = moment().format("YYYY-MM-DD");

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();

        this.initChartJsDefaults();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        this.showGraph = false;
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //call api to get report page
            const apiControllerResponse: any = await this.getApiControllerResponse(startRecord);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;

                //update sorting classes
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = apiControllerResponse.resultList;
                if (this.resultList.length) {
                    this.resultType = this.resultList[0].VehicleName ? 'vehicle' : 'driver';

                    // parse some values to reduce function data bindings
                    this.resultList.forEach(record => {
                        record.ScoreLabel = NumberUtil.formatFloat(record.Score, 2);
                        record.ImpactOfHALabel = NumberUtil.formatFloat(record.ImpactOfHA, 2);
                        record.ImpactOfHBLabel = NumberUtil.formatFloat(record.ImpactOfHB, 2);
                        record.ImpactOfHCLabel = NumberUtil.formatFloat(record.ImpactOfHC, 2);
                        if (this.currentSearchType == "weekly") {
                            record.ImpactOfSpeedingLabel = NumberUtil.formatFloat(record.ImpactOfSpeeding, 2);
                        } else {
                            record.ImpactOfSpeedingLabel = NumberUtil.formatFloat(record.ImpactOfSpeedingDistance, 2);
                        }
                    });

                    //update isReportShown boolean
                    this.isReportShown = true;

                    //get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);

                } else {
                    this.resultType = this.currentSearchBy == 'vehicles' ? 'vehicle' : 'driver';
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }
    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }
    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        if (this.currentSearchBy == "vehicles") {
            apiResponse = await this.getVehicleScoring(startRecord, isDownload);
            if (apiResponse != null) {
                apiResultList = apiResponse.vehicle_scoring_list;
            }
        } else if (this.currentSearchBy == "drivers" || this.currentSearchBy == "driverTag") {
            apiResponse = await this.getDriverScoring(startRecord, isDownload);
            if (apiResponse != null) {
                apiResultList = apiResponse.driver_scoring_list;
            }
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }
    // For download report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        let excelWidthConfig = [{ wch: 15 }, { wch: 22 }, { wch: 14 }, { wch: 22 }, { wch: 21 }, { wch: 20.5 }, { wch: 19 }];

        if (this.currentSearchBy == "vehicles") {
            headerName = [
                "Vehicle", "Week", "Vehicle Score(%)", "Impact of Harsh Accel.(%)",
                "Impact of Harsh Brake(%)", "Impact of Harsh Turn(%)", "Impact of Speeding(%)"
            ];
        } else if (this.currentSearchBy == "drivers" || this.currentSearchBy == "driverTag") {
            headerName = [
                "Driver", "ID Tag No.", "Week", "Driver Score(%)", "Impact of Harsh Accel.(%)",
                "Impact of Harsh Brake(%)", "Impact of Harsh Turn(%)", "Impact of Speeding(%)"
            ];
        }

        if (this.currentSearchType == "weekly") {
            filename = this.currentSearchBy == "vehicles" ? this.exportFileNameWeekly + "_VEHICLE (Weekly)" : this.exportFileNameWeekly + "_DRIVER (Weekly)";
            label = this.currentSearchBy == "vehicles" ? this.exportFileNameWeeklyPdfTitle + " (Vehicle)" : this.exportFileNameWeeklyPdfTitle + " (Driver)";
            for (let i = 0; i < recordList.length; i++) {
                let tempDateRange = recordList[i].DateRange.split(' - ');
                tempDateRange = tempDateRange.map(eachDate => {
                    return moment(eachDate, "DD/MM/YYYY").format("YYYY/MM/DD");
                });
                recordList[i].DateRange = tempDateRange.join(' - ');
                let tempRow = [];
                if (this.currentSearchBy == "vehicles") {
                    tempRow = [
                        recordList[i].VehicleName ? recordList[i].VehicleName : recordList[i].DriverName || '',
                        recordList[i].DateRange || '',
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Score, 2)),
                        // recordList[i].TotalHA || 0,
                        // recordList[i].TotalHB || 0,
                        // recordList[i].TotalHC || 0,
                        // NumberUtil.formatFloat(recordList[i].TotalSpeedingDistance, 1),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHA, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHB, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHC, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfSpeeding, 2))
                    ];
                } else {
                    tempRow = [
                        recordList[i].VehicleName ? recordList[i].VehicleName : recordList[i].DriverName || '',
                        recordList[i].DriverTagNo || '',
                        recordList[i].DateRange || '',
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Score, 2)),
                        // recordList[i].TotalHA || 0,
                        // recordList[i].TotalHB || 0,
                        // recordList[i].TotalHC || 0,
                        // NumberUtil.formatFloat(recordList[i].TotalSpeedingDistance, 1),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHA, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHB, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHC, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfSpeeding, 2))
                    ];
                }
                data.push(tempRow);
            }
        } else if (this.currentSearchType == "lifetime") {
            excelWidthConfig = [{ wch: 15 }, { wch: 14 }, { wch: 22 }, { wch: 21 }, { wch: 20.5 }, { wch: 19 }];
            filename = this.currentSearchBy == "vehicles" ? this.exportFileNameLifetime + "_VEHICLE (Lifetime)" : this.exportFileNameLifetime + "_DRIVER (Lifetime)";
            label = this.currentSearchBy == "vehicles" ? this.exportFileNameLifetimePdfTitle + " (Vehicle)" : this.exportFileNameLifetimePdfTitle + " (Driver)";

            if (this.currentSearchBy == "vehicles") {
                headerName = headerName.splice(0, 1).concat(headerName.splice(1));
            } else {
                headerName = headerName.splice(0, 2).concat(headerName.splice(1));
            }

            // console.debug(headers);
            for (let i = 0; i < recordList.length; i++) {
                let tempRow = [];
                if (this.currentSearchBy == "vehicles") {
                    tempRow = [
                        recordList[i].VehicleName ? recordList[i].VehicleName : recordList[i].DriverName || '',
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Score, 2)),
                        // recordList[i].TotalHA || 0,
                        // recordList[i].TotalHB || 0,
                        // recordList[i].TotalHC || 0,
                        // NumberUtil.formatFloat(recordList[i].TotalSpeedingDistance, 1),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHA, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHB, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHC, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfSpeedingDistance, 2))
                    ];
                } else {
                    tempRow = [
                        recordList[i].VehicleName ? recordList[i].VehicleName : recordList[i].DriverName || '',
                        recordList[i].DriverTagNo || '',
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Score, 2)),
                        // recordList[i].TotalHA || 0,
                        // recordList[i].TotalHB || 0,
                        // recordList[i].TotalHC || 0,
                        // NumberUtil.formatFloat(recordList[i].TotalSpeedingDistance, 1),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHA, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHB, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfHC, 2)),
                        NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ImpactOfSpeedingDistance, 2))
                    ];
                }

                data.push(tempRow);
            }
        }
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    /**
     * Report Specific Methods
     */

    // 1. Call API Weekly Report
    async getVehicleScoring(startRecord: number = 1, isDownload: boolean = false) {

        let result: any = null;
        const freqType = this.currentSearchType;
        //Default value = null is for lifetime
        let startWeekNo = null;
        let endWeekNo = null;
        let startYear = null;
        let endYear = null;

        if (freqType == "weekly") {

            //get weekNo and year of from date
            const yearAndWeekNoFromDate = this.dateTimeUtil.fromDateToYearAndWeekNo(this.momFromDate);
            startYear = yearAndWeekNoFromDate.year;
            startWeekNo = yearAndWeekNoFromDate.weekNo;

            //get weekNo and year of to date
            const yearAndWeekNoToDate = this.dateTimeUtil.fromDateToYearAndWeekNo(this.momToDate);
            endYear = yearAndWeekNoToDate.year;
            endWeekNo = yearAndWeekNoToDate.weekNo;
        }

        if (!isDownload) {
            result = await this.scoringService.getVehicleScoring(this.selectedVehiclesList, freqType, startWeekNo, endWeekNo, startYear, endYear, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
        } else {
            result = await this.scoringService.getVehicleScoring(this.selectedVehiclesList, freqType, startWeekNo, endWeekNo, startYear, endYear, null, null, this.currentSortField, this.currentSortAscending);
        }

        return result || null;
    }

    // 2. Call API Lifetime Report
    async getDriverScoring(startRecord: number = 1, isDownload: boolean = false) {

        let result: any = null;
        const freqType = this.currentSearchType;
        //Default value = null is for lifetime
        let startWeekNo = null;
        let endWeekNo = null;
        let startYear = null;
        let endYear = null;

        if (freqType == "weekly") {

            //get weekNo and year of from date
            const yearAndWeekNoFromDate = this.dateTimeUtil.fromDateToYearAndWeekNo(this.momFromDate);
            startYear = yearAndWeekNoFromDate.year;
            startWeekNo = yearAndWeekNoFromDate.weekNo;

            //get weekNo and year of to date
            const yearAndWeekNoToDate = this.dateTimeUtil.fromDateToYearAndWeekNo(this.momToDate);
            endYear = yearAndWeekNoToDate.year;
            endWeekNo = yearAndWeekNoToDate.weekNo;

        }
        if (!isDownload) {
            result = await this.scoringService.getDriverScoring(this.selectedDriversList, freqType, startWeekNo, endWeekNo, startYear, endYear, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending, this.currentSearchBy, this.selectedDriverTagList);
        } else {
            result = await this.scoringService.getDriverScoring(this.selectedDriversList, freqType, startWeekNo, endWeekNo, startYear, endYear, null, null, this.currentSortField, this.currentSortAscending, this.currentSearchBy, this.selectedDriverTagList);
        }

        return result || null;
    }

    // 3. Upon Switching Search Type (Weekly/Lifetime)
    changeSearchType(): void {

        //reset sorting memory
        // this.currentSortField = "";
        // this.currentSortAscending = true;

        //clear table results
        // this.resultList = [];

        //fix position of GENERATE button
        const div = this.el.nativeElement.querySelector("#divSearchType");
        div.classList.remove("--period");
        if (this.searchType == "weekly") {
            div.classList.add("--period");
        }

        // this.generateReport();
    }

    async triggerShowGraph() {
        this.showGraph = !this.showGraph;
        if (this.showGraph) {
            if (this.searchType == "weekly") {
                this.showWeeklyGraph = true;
                this.showLifeTimeGraph = false;
                await Promise.all([
                    this.getScoreGraph(),
                    this.getScoreViolationsGraph()
                ]);
            } else {
                this.showLifeTimeGraph = true;
                this.showWeeklyGraph = false;
                await Promise.all([
                    this.getLifeTimeScoreGraph(),
                    this.getLifeTimeScoreViolationsGraph(),
                    this.getLifeTimeScoreImpactByEntityGraph()
                ]);
            }
        }
    }

    /* ---- ChartJS ---- // ---- Circle Progress Bar is done within HTML ---- */

    initChartJsDefaults() {
        Chart.defaults.global.legend.display = true;
        // this.randomColourGenerator();
        this.colourList = [
            Colors.RGBA_PRI1_SHARP_GREEN,
            Colors.RGBA_PRI3_DARK_GREEN,
            Colors.RGBA_PRI4_GRASS_GREEN,
            Colors.RGBA_HL3_SKY_BLUE,
            Colors.RGBA_HL2_ROYALE_BLUE,
            Colors.RGBA_VIOLET,
            Colors.RGBA_ORANGE,
            Colors.RGBA_BRIGHT_RED,
            Colors.RGBA_GRAY,
            Colors.RGBA_DARK_GREEN
        ];
        this.violationPieColourList = [
            Colors.RGBA_PRI4_GRASS_GREEN,
            Colors.RGBA_HL3_SKY_BLUE,
            Colors.RGBA_HL2_ROYALE_BLUE,
            Colors.RGBA_PRI1_SHARP_GREEN,
            Colors.RGBA_PRI3_DARK_GREEN,
            Colors.RGBA_CTA6_PURPLE
        ];
        this.chartJsHoverProps = {
            animationDuration: 300,
            onHover: function (e: MouseEvent) {
                const point = this.getElementAtEvent(e);
                if (point.length) {
                    (<HTMLElement>e.target).style.cursor = 'pointer';
                } else {
                    (<HTMLElement>e.target).style.cursor = 'default';
                }
            }
        };
        this.chartJsAnimation = {
            easing: "easeInOutCubic"
        };
        this.chartJsTooltipStyles = {
            titleAlign: 'center',
            bodyAlign: 'center',
            yPadding: 8,
            xPadding: 10
        };
        this.chartJsBarHoverInteraction = {
            hoverBorderColor: Colors.RGBA_WHITE_SHADE,
            hoverBorderWidth: 2
        };
        this.chartJsScaleLabelFontStyles = {
            fontStyle: "bold",
            fontColor: Colors.RGBA_BLACK,
            fontSize: 13
        };
        this.chartJsTicksFontStyles = {
            fontColor: Colors.RGBA_BLACK,
            fontSize: 12
        };
        this.chartJsTicksNumberTruncateCallbackFn = function (value) {
            if (value >= 1000000) {
                return NumberUtil.roundOff(value / 1000000, 1) + 'mil';
            } else if (value >= 10000) {
                return NumberUtil.roundOff(value / 1000, 1) + 'k';
            }
            return value;
        };
        this.chartJsTicksStringEllipsisCallbackFn = function (value, index, values) {
            // truncate name of ticks in axis
            return StringUtil.truncateText(value, CHART_TICK_LABEL_MAX_LENGTH, 'N/A');
        };
        this.chartJsTooltipTruncateCallbackTitleFn = function (tooltipItem, cdata) {
            // console.log('title:', tooltipItem);
            // console.log('title:', cdata);
            // truncate for tooltip
            const label = cdata.labels[tooltipItem[0].index];
            return StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
        };
    }

    async getScoreGraph() {
        try {
            this.scoreGraph_spinner.show();
            await AsyncUtil.wait(100);

            //clone tmp list, so sorting wont affect original list
            const tmpResultList = this.resultList.slice(0);
            //sort the list with datetime ascending for display in graph
            tmpResultList.sort((a, b) => {
                let field = '';
                field = this.currentSearchBy == "vehicles" ? 'VehicleName' : 'DriverName';
                return a[field].localeCompare(b[field]) || a.DateTime - b.DateTime;
            });
            //format the list structure to graph required structure
            const outcome = this.formatListForGraph(tmpResultList);
            const formattedList = outcome[0];
            const xLabelList = outcome[1];
            if (formattedList && formattedList.length > 0) {
                await this.generateScoreLineChart(formattedList, xLabelList);
            } else {
                // Remove old chart
                await this.generateScoreLineChart(null, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.scoreGraph_spinner.hide();
        }
    }

    async generateScoreLineChart(dataList, xLabelList) {
        // Clear Previous Chart Data
        this.scoreCharts.ScoreGraph.destroy();

        if (!dataList || !dataList.length) {
            return;
        }

        // Check if all data are zeroes
        // if (ObjectUtil.isArrayAllEmpty(tickValues)) {
        //     return;
        // }

        const tickLabels = xLabelList;
        const tickValues = [];

        const hsls = this.getColors(dataList.length);
        const datasets = [];
        dataList.map((tmp, index) => {
            const dataObj = {
                data: tmp.values,
                fill: false,
                backgroundColor: 'hsl(' + hsls[index] + ', 100%, 75%)',
                lineTension: 0,
                borderWidth: 1.2,
                pointBorderWidth: 1.2,
                borderColor: 'hsl(' + hsls[index] + ', 100%, 50%)',
                // backgroundColor: linearGradient,
                pointBackgroundColor: 'hsl(' + hsls[index] + ', 100%, 50%)',
                // pointHoverBackgroundColor: Colors.RGBA_WHITE,
                pointRadius: 4,
                pointHoverRadius: 6,
                pointHitRadius: 5,
                label: tmp.legendLabel
            };
            datasets.push(dataObj);
        });

        // Construct Chart
        const options = {
            priColor: Colors.RGBA_PRI3_DARK_GREEN,
            secColor: Colors.RGBA_WHITE,
            areaColor: Colors.RGBA_PRI4_GRASS_GREEN,
            xLabel: this.currentSearchBy == "vehicles" ? 'Vehicle' : 'Driver', //X-Axis Title
            yLabel: 'Score (%)', //Y-Axis Title
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values,
            tooltipCb: { //Tooltip Custom
                // title: function (tooltipItem, cdata) {
                //         // console.log('title:', tooltipItem);
                //         // console.log('title:', cdata);
                //         // // truncate for tooltip
                //         // const label = cdata.datasets[tooltipItem[0].datasetIndex].label;
                //         // return StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
                // },
                label: function (tooltipItem, cdata) {
                    // Add suffix
                    // console.log('title:', tooltipItem);
                    // console.log('title:', cdata);
                    let label = cdata.datasets[tooltipItem.datasetIndex].label;
                    label = StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
                    return label + ': ' + tooltipItem.value + '%';
                }
            },
            custom: function (cOptions) {
                if (cOptions.options.layout.padding) {
                    cOptions.options.layout.padding['bottom'] = 0; //because x-axis ticks not rotated, so no need -5 padding
                }
                if (cOptions.options.scales.yAxes[0].ticks) {
                    cOptions.options.scales.yAxes[0].ticks['min'] = 0;
                    cOptions.options.scales.yAxes[0].ticks['stepSize'] = 20;
                    cOptions.options.scales.yAxes[0].ticks['max'] = 100;
                }
                if (cOptions.options.scales.xAxes[0].ticks) {
                    cOptions.options.scales.xAxes[0].ticks['fontSize'] = 11;
                    cOptions.options.scales.xAxes[0].ticks['autoSkip'] = true;
                    cOptions.options.scales.xAxes[0].ticks['minRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxTicksLimit'] = 25;
                    // cOptions.options.scales.xAxes[0].ticks['callback'] = function (value) {
                    //     // Show hour in HH format
                    //     return value.slice(0, 2);
                    // };
                }
                if (cOptions.options.scales.xAxes[0].scaleLabel) {
                    cOptions.options.scales.xAxes[0].scaleLabel['padding'] = 4;
                }
                if (cOptions.options.scales.xAxes[0].gridLines) {
                    const lineWidth24hr = [3];
                    const color24hr = [Colors.RGBA_LIGHT_GRAY];
                    for (let i = 0; i < 24; i++) {
                        lineWidth24hr.push(1);
                        color24hr.push(Colors.RGBA_LIGHT_GRAY_SHADE);
                    }
                    cOptions.options.scales.xAxes[0].gridLines['lineWidth'] = lineWidth24hr;
                    cOptions.options.scales.xAxes[0].gridLines['color'] = color24hr;
                }

                cOptions.options.tooltips.bodyAlign = 'left';

                return cOptions;
            }
        };

        this.scoreCharts.ScoreGraph.chart = this.genGradientLineChartSmallerDots(this.scoreCharts.ScoreGraph, options, datasets);
    }

    async getScoreViolationsGraph() {
        try {
            this.scoreViolationGraph_spinner.show();
            await AsyncUtil.wait(100);

            // mockup data
            // const test = [{"VehicleId": 100048, "VehicleName": "7a", "Imei": null, "DateRange": "06/07/2020 - 12/07/2020", "DateTime": 202028, "ImpactOfHA": null, "ImpactOfHB": null, "ImpactOfHC": null, "ImpactOfSpeeding": null, "Score": 10, "AverageScore": 87.58}, {"VehicleId": 100048, "VehicleName": "7a", "Imei": null, "DateRange": "29/06/2020 - 05/07/2020", "DateTime": 202027, "ImpactOfHA": null, "ImpactOfHB": null, "ImpactOfHC": null, "ImpactOfSpeeding": null, "Score": 50, "AverageScore": 87.58}, {"VehicleId": 100586, "VehicleName": "Able-S", "Imei": null, "DateRange": "06/07/2020 - 12/07/2020", "DateTime": 202028, "ImpactOfHA": null, "ImpactOfHB": null, "ImpactOfHC": null, "ImpactOfSpeeding": null, "Score": 70, "AverageScore": 87.58}, {"VehicleId": 100586, "VehicleName": "Able-S", "Imei": null, "DateRange": "29/06/2020 - 05/07/2020", "DateTime": 202027, "ImpactOfHA": null, "ImpactOfHB": null, "ImpactOfHC": null, "ImpactOfSpeeding": null, "Score": 90, "AverageScore": 87.58}];
            const formattedList = [
                {
                    legendLabel : 'HA',
                    values: []
                },
                {
                    legendLabel : 'HB',
                    values: []
                },
                {
                    legendLabel : 'HT',
                    values: []
                }
            ];

            if (this.scoreViolationGraphType === "Percentage") {
                formattedList.push({
                        legendLabel : 'Speeding',
                        values: []
                    });
            }
            const hashMap = {};
            this.resultList.map(result => {
                //find is the datetime exist in map, if yes just total it up
                if (hashMap[result.DateTime]) {
                    hashMap[result.DateTime].count++;
                    if (this.scoreViolationGraphType === "Percentage") {
                        hashMap[result.DateTime].totalHAImpact += result.ImpactOfHA ? Math.abs(result.ImpactOfHA) : 0;
                        hashMap[result.DateTime].totalHBImpact += result.ImpactOfHB ? Math.abs(result.ImpactOfHB) : 0;
                        hashMap[result.DateTime].totalHTImpact += result.ImpactOfHC ? Math.abs(result.ImpactOfHC) : 0;
                        hashMap[result.DateTime].totalSpeedingImpact += result.ImpactOfSpeeding ? Math.abs(result.ImpactOfSpeeding) : 0;
                    } else if (this.scoreViolationGraphType === "Count") {
                        hashMap[result.DateTime].totalHAImpact += result.TotalHA ? Math.abs(result.TotalHA) : 0;
                        hashMap[result.DateTime].totalHBImpact += result.TotalHB ? Math.abs(result.TotalHB) : 0;
                        hashMap[result.DateTime].totalHTImpact += result.TotalHC ? Math.abs(result.TotalHC) : 0;
                        // hashMap[result.DateTime].totalSpeedingImpact += result.TotalSpeedingDistance ? Math.abs(result.TotalSpeedingDistance) : 0;
                    }
                } else {
                    //the datetime not exist in map, add new
                    if (this.scoreViolationGraphType === "Percentage") {
                        hashMap[result.DateTime] = {
                            count: 1,
                            totalHAImpact: result.ImpactOfHA ? Math.abs(result.ImpactOfHA) : 0,
                            totalHBImpact: result.ImpactOfHB ? Math.abs(result.ImpactOfHB) : 0,
                            totalHTImpact: result.ImpactOfHC ? Math.abs(result.ImpactOfHC) : 0,
                            totalSpeedingImpact: result.ImpactOfSpeeding ? Math.abs(result.ImpactOfSpeeding) : 0
                        };
                    } else if (this.scoreViolationGraphType === "Count") {
                        hashMap[result.DateTime] = {
                            count: 1,
                            totalHAImpact: result.TotalHA ? Math.abs(result.TotalHA) : 0,
                            totalHBImpact: result.TotalHB ? Math.abs(result.TotalHB) : 0,
                            totalHTImpact: result.TotalHC ? Math.abs(result.TotalHC) : 0,
                            // totalSpeedingImpact: result.TotalSpeedingDistance ? Math.abs(result.TotalSpeedingDistance) : 0
                        };
                    }
                }
            });

            //push each datetime fields to form final obj for graph use
            Object.values(hashMap).forEach((obj: any) => {
                if (this.scoreViolationGraphType === "Percentage") {
                    //recalculate percentage else it would more than 100%
                    formattedList[0].values.push((obj.totalHAImpact / (obj.count * 100)) * 100);
                    formattedList[1].values.push((obj.totalHBImpact / (obj.count * 100)) * 100);
                    formattedList[2].values.push((obj.totalHTImpact / (obj.count * 100)) * 100);
                    formattedList[3].values.push((obj.totalSpeedingImpact / (obj.count * 100)) * 100);
                } else if (this.scoreViolationGraphType === "Count") {
                    formattedList[0].values.push(obj.totalHAImpact);
                    formattedList[1].values.push(obj.totalHBImpact);
                    formattedList[2].values.push(obj.totalHTImpact);
                    // formattedList[3].values.push(obj.totalSpeedingImpact);
                }
            });
            //get x-axis label
            const xLabelList = Object.keys(hashMap);
            if (formattedList && formattedList.length > 0) {
                await this.generateScoreViolationsLineChart(formattedList, xLabelList);
            } else {
                // Remove old chart
                await this.generateScoreViolationsLineChart(null, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.scoreViolationGraph_spinner.hide();
        }
    }

    async generateScoreViolationsLineChart(data, xLabelList) {
        // Clear Previous Chart Data
        this.scoreCharts.ScoreViolationGraph.destroy();

        if (!data || !data.length) {
            return;
        }

        // Check if all data are zeroes
        // if (ObjectUtil.isArrayAllEmpty(tickValues)) {
        //     return;
        // }

        const tickLabels = xLabelList;
        const tickValues = [];

        const hsls = this.getColors(data.length);
        const datasets = [];
        data.map((tmp, index) => {
            const dataObj = {
                data: tmp.values,
                // fill: '-1',
                backgroundColor: 'hsl(' + hsls[index] + ', 100%, 75%)',
                lineTension: 0,
                borderWidth: 1.2,
                pointBorderWidth: 1.2,
                borderColor: 'hsl(' + hsls[index] + ', 100%, 50%)',
                // backgroundColor: linearGradient,
                pointBackgroundColor: 'hsl(' + hsls[index] + ', 100%, 50%)',
                // pointHoverBackgroundColor: Colors.RGBA_WHITE,
                pointRadius: 4,
                pointHoverRadius: 6,
                pointHitRadius: 5,
                label: tmp.legendLabel
            };
            datasets.push(dataObj);
        });

        const _this = this;
        // Construct Chart
        const options = {
            priColor: Colors.RGBA_PRI3_DARK_GREEN,
            secColor: Colors.RGBA_WHITE,
            areaColor: Colors.RGBA_PRI4_GRASS_GREEN,
            xLabel: this.currentSearchBy == "vehicles" ? 'Vehicle' : 'Driver', //X-Axis Title
            yLabel: this.scoreViolationGraphType === "Percentage" ? 'Score (%)' : 'Count', //Y-Axis Title
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values,
            tooltipCb: { //Tooltip Custom
                // title: function (tooltipItem, cdata) {
                //     console.log('title:', tooltipItem);
                //     console.log('title:', cdata);
                //     // // truncate for tooltip
                //     // const label = cdata.datasets[tooltipItem[0].datasetIndex].label;
                //     // return StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
                // },
                label: function (tooltipItem, cdata) {
                    // Add suffix
                    let label = cdata.datasets[tooltipItem.datasetIndex].label;
                    label = StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
                    return label + ': ' + tooltipItem.value + (_this.scoreViolationGraphType === "Percentage" ? '%' : '');
                }
            },
            custom: function (cOptions) {
                if (cOptions.options.layout.padding) {
                    cOptions.options.layout.padding['bottom'] = 0; //because x-axis ticks not rotated, so no need -5 padding
                }
                if (cOptions.options.scales.yAxes[0].ticks) {
                    cOptions.options.scales.yAxes[0].ticks['min'] = 0;
                    cOptions.options.scales.yAxes[0].ticks['stepSize'] = 20;
                    if (_this.scoreViolationGraphType === "Percentage") {
                        cOptions.options.scales.yAxes[0].ticks['max'] = 100;
                    }
                }
                if (cOptions.options.scales.xAxes[0].ticks) {
                    cOptions.options.scales.xAxes[0].ticks['fontSize'] = 11;
                    cOptions.options.scales.xAxes[0].ticks['autoSkip'] = true;
                    cOptions.options.scales.xAxes[0].ticks['minRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxTicksLimit'] = 25;
                    // cOptions.options.scales.xAxes[0].ticks['callback'] = function (value) {
                    //     // Show hour in HH format
                    //     return value.slice(0, 2);
                    // };
                }
                if (cOptions.options.scales.xAxes[0].scaleLabel) {
                    cOptions.options.scales.xAxes[0].scaleLabel['padding'] = 4;
                }
                if (cOptions.options.scales.xAxes[0].gridLines) {
                    const lineWidth24hr = [3];
                    const color24hr = [Colors.RGBA_LIGHT_GRAY];
                    for (let i = 0; i < 24; i++) {
                        lineWidth24hr.push(1);
                        color24hr.push(Colors.RGBA_LIGHT_GRAY_SHADE);
                    }
                    cOptions.options.scales.xAxes[0].gridLines['lineWidth'] = lineWidth24hr;
                    cOptions.options.scales.xAxes[0].gridLines['color'] = color24hr;
                }

                cOptions.options.tooltips.bodyAlign = 'left';

                return cOptions;
            }
        };
        this.scoreCharts.ScoreViolationGraph.chart = this.genGradientLineChartSmallerDots(this.scoreCharts.ScoreViolationGraph, options, datasets);
    }

    genGradientLineChartSmallerDots(chartObj: ScoringChartChildObj, options: any, dataset: any) {
        const {
            priColor = Colors.RGBA_PRI3_DARK_GREEN,
            secColor = Colors.RGBA_WHITE,
            areaColor = Colors.RGBA_PRI4_GRASS_GREEN,
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        // if (!dataset) {
        //     dataset = [{
        //         data: [10, 20, 30],
        //         fill: false,
        //         lineTension: 0,
        //         borderWidth: 1.2,
        //         pointBorderWidth: 1.2,
        //         borderColor: priColor,
        //         // backgroundColor: linearGradient,
        //         pointBackgroundColor: priColor,
        //         pointHoverBackgroundColor: secColor,
        //         pointRadius: 2,
        //         pointHoverRadius: 4,
        //         pointHitRadius: 5,
        //         label: 'Vehicle 1'
        //     },
        //     {
        //         data: [70, 10, 20],
        //         fill: false,
        //         lineTension: 0,
        //         borderWidth: 1.2,
        //         pointBorderWidth: 1.2,
        //         borderColor: priColor,
        //         // backgroundColor: linearGradient,
        //         pointBackgroundColor: priColor,
        //         pointHoverBackgroundColor: secColor,
        //         pointRadius: 2,
        //         pointHoverRadius: 4,
        //         pointHitRadius: 5,
        //         label: 'Vehicle 2'
        //     },
        //     {
        //         data: [90, 5, 50],
        //         fill: false,
        //         lineTension: 0,
        //         borderWidth: 1.2,
        //         pointBorderWidth: 1.2,
        //         borderColor: priColor,
        //         // backgroundColor: linearGradient,
        //         pointBackgroundColor: priColor,
        //         pointHoverBackgroundColor: secColor,
        //         pointRadius: 2,
        //         pointHoverRadius: 4,
        //         pointHitRadius: 5,
        //         label: 'Vehicle 3'
        //     }];
        // }

        // const customTooltips = function(tooltip) {
		// 	// Tooltip Element
		// 	let tooltipEl = document.getElementById('chartjs-tooltip');

		// 	if (!tooltipEl) {
		// 		tooltipEl = document.createElement('div');
		// 		tooltipEl.id = 'chartjs-tooltip';
		// 		tooltipEl.innerHTML = '<table></table>';
		// 		this._chart.canvas.parentNode.appendChild(tooltipEl);
		// 	}

		// 	// Hide if no tooltip
		// 	if (tooltip.opacity === 0) {
		// 		tooltipEl.style.opacity = '0';
		// 		return;
		// 	}

		// 	// Set caret Position
		// 	tooltipEl.classList.remove('above', 'below', 'no-transform');
		// 	if (tooltip.yAlign) {
		// 		tooltipEl.classList.add(tooltip.yAlign);
		// 	} else {
		// 		tooltipEl.classList.add('no-transform');
		// 	}

		// 	function getBody(bodyItem) {
		// 		return bodyItem.lines;
		// 	}

		// 	// Set Text
		// 	if (tooltip.body) {
		// 		const titleLines = tooltip.title || [];
		// 		const bodyLines = tooltip.body.map(getBody);

		// 		let innerHtml = '<thead>';

		// 		titleLines.forEach(function(title) {
		// 			innerHtml += '<tr><th>' + title + '</th></tr>';
		// 		});
		// 		innerHtml += '</thead><tbody>';

		// 		bodyLines.forEach(function(body, i) {
		// 			const colors = tooltip.labelColors[i];
		// 			let style = 'background:' + colors.backgroundColor;
		// 			style += '; border-color:' + colors.borderColor;
		// 			style += '; border-width: 2px';
		// 			const span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
		// 			innerHtml += '<tr><td>' + span + body + '</td></tr>';
		// 		});
		// 		innerHtml += '</tbody>';

		// 		const tableRoot = tooltipEl.querySelector('table');
		// 		tableRoot.innerHTML = innerHtml;
		// 	}

		// 	const positionY = this._chart.canvas.offsetTop;
        //     const positionX = this._chart.canvas.offsetLeft;
        //     const position = this._chart.canvas.getBoundingClientRect();

		// 	// Display, position, and set styles for font
        //     tooltipEl.style.opacity = '1';
        //     tooltipEl.style.left = positionX + tooltip.caretX + 'px';
		// 	// tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        //     // tooltipEl.style.left = position.left + 'px';
        //     tooltipEl.style.top = window.pageYOffset - position.top + 'px';
		// 	tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
		// 	tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
		// 	tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
		// 	tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
		// };

        // Make Gradient
        // const linearGradient = this.makeLinearCanvasGradient(chartObj.canvasId, areaColor);
        let cOptions = {
            type: 'line',
            data: {
                labels: tickLabels,
                datasets: dataset
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 10,
                        bottom: -5
                    }
                },
                tooltips: {
                    // displayColors: false,
                    ...this.chartJsTooltipStyles,
                    // enabled: false,
                    mode: 'nearest'
					// position: 'nearest',
                    // custom: customTooltips
                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxTicksLimit: 6,
                            autoSkip: false,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: 1,
                            zeroLineWidth: 0,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            fontSize: 11,
                            maxRotation: 20,
                            maxTicksLimit: 5,
                            autoSkip: false
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 1, 1, 1, 1],
                            zeroLineWidth: 3,
                            color: [Colors.RGBA_LIGHT_GRAY, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE],
                            zeroLineColor: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    async getLifeTimeScoreGraph() {
        try {
            //call api to get data
            this.lifeTimeScoreGraph_spinner.show();

            // let apiStats: any = {};
            await AsyncUtil.wait(1000);
            // apiStats = await this.driverService.getDriverScoring(this.driverId, weekNo, year, this.pastScoreNoOfWeeks);

            if (this.resultList) {
                // const stats = apiStats.body;

                // Chart
                await this.generateLifeTimeScoreBarChart(this.resultList);
            } else {
                // Remove old chart & stats
                await this.generateLifeTimeScoreBarChart(null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.lifeTimeScoreGraph_spinner.hide();
        }
    }

    async generateLifeTimeScoreBarChart(data) {
        // Clear Previous Chart Data
        this.scoreCharts.LifeTimeScoreGraph.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        // data = data.slice(0).reverse();
        const fieldName = this.currentSearchBy == "vehicles" ? 'VehicleName' : 'DriverName';
        const tickLabels = data.map(result => StringUtil.truncateText(result[fieldName], CHART_TOOLTIP_MAX_LENGTH, 'N/A'));
        const tickValues = data.map(result => result.Score || 0);

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        // Construct Bar Chart
        const options = {
            xLabel: this.currentSearchBy == "vehicles" ? 'Vehicle' : 'Driver', // X-Axis Title
            yLabel: 'Score (%)',
            tickLabels, //X-Axis values ......
            tickValues, //Y-Axis Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    return ' ' + tooltipItem.value + ' %';
                }
            },
            // onClickFn
            custom: function (cOptions) {
                if (cOptions.options.scales.xAxes[0].ticks) {
                    cOptions.options.scales.xAxes[0].ticks['fontSize'] = 11;
                    cOptions.options.scales.xAxes[0].ticks['autoSkip'] = false;
                    cOptions.options.scales.xAxes[0].ticks['minRotation'] = 20;
                    cOptions.options.scales.xAxes[0].ticks['maxRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxTicksLimit'] = 25;
                }
                return cOptions;
            }
        };
        this.padding = 0;
        this.stepSize = 20;
        const color = Colors.RGBA_PRI4_GRASS_GREEN;
        this.scoreCharts.LifeTimeScoreGraph.chart = this.genColorfulHorizontalBarChart(this.scoreCharts.LifeTimeScoreGraph, options, color);
    }

    async getLifeTimeScoreImpactByEntityGraph() {
        try {
            //call api to get data
            this.lifeTimeScoreImpactGraph_spinner.show();

            // let apiStats: any = {};
            await AsyncUtil.wait(1000);
            // apiStats = await this.driverService.getDriverScoring(this.driverId, weekNo, year, this.pastScoreNoOfWeeks);

            if (this.resultList) {
                // const stats = apiStats.body;
                const formattedList = [
                    {
                        legendLabel : 'Harsh Acceleration (HA)',
                        values: []
                    },
                    {
                        legendLabel : 'Harsh Brake (HB)',
                        values: []
                    },
                    {
                        legendLabel : 'Harsh Turn (HT)',
                        values: []
                    },
                    {
                        legendLabel : 'Speeding',
                        values: []
                    }
                ];

                const xLabelList = [];
                const fieldName = this.currentSearchBy == "vehicles" ? 'VehicleName' : 'DriverName';
                this.resultList.map(each => {
                    //get x-axis label
                    xLabelList.push(StringUtil.truncateText(each[fieldName], CHART_TOOLTIP_MAX_LENGTH, 'N/A'));
                    //HA
                    formattedList[0].values.push(Math.abs(each.ImpactOfHA) || 0);
                    //HB
                    formattedList[1].values.push(Math.abs(each.ImpactOfHB) || 0);
                    //HT
                    formattedList[2].values.push(Math.abs(each.ImpactOfHC) || 0);
                    //Speeing
                    formattedList[3].values.push(Math.abs(each.ImpactOfSpeedingDistance) || 0);
                });

                // Chart
                await this.generateLifeTimeScoreImpactBarChart(formattedList, xLabelList);
            } else {
                // Remove old chart & stats
                await this.generateLifeTimeScoreImpactBarChart(null, null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.lifeTimeScoreImpactGraph_spinner.hide();
        }
    }

    async generateLifeTimeScoreImpactBarChart(data, xLabelList) {
        // Clear Previous Chart Data
        this.scoreCharts.LifeTimeScoreImpactGraph.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        const tickLabels = xLabelList;
        const tickValues = [];

        const hsls = this.getColors(data.length);
        const datasets = [];
        data.map((tmp, index) => {
            const dataObj = {
                data: tmp.values,
                backgroundColor: 'hsl(' + hsls[index] + ', 100%, 75%)',
                ...this.chartJsBarHoverInteraction,
                label: tmp.legendLabel
            };
            datasets.push(dataObj);
        });

        // Check if all data are zeroes
        // if (ObjectUtil.isArrayAllEmpty(tickValues)) {
        //     return;
        // }

        // Construct Bar Chart
        const options = {
            xLabel: this.currentSearchBy == "vehicles" ? 'Vehicle' : 'Driver', // X-Axis Title
            yLabel: 'Score (%)',
            tickLabels, //X-Axis values ......
            tickValues, //Y-Axis Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    let label = cdata.datasets[tooltipItem.datasetIndex].label;
                    label = StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
                    return label + ': ' + tooltipItem.value + ' %';
                }
            },
            // onClickFn
            custom: function (cOptions) {
                if (cOptions.options.scales.xAxes[0].ticks) {
                    cOptions.options.scales.xAxes[0].ticks['fontSize'] = 11;
                    cOptions.options.scales.xAxes[0].ticks['autoSkip'] = false;
                    cOptions.options.scales.xAxes[0].ticks['minRotation'] = 20;
                    cOptions.options.scales.xAxes[0].ticks['maxRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxTicksLimit'] = 25;
                }

                cOptions.options.scales.xAxes[0].stacked = true;
                cOptions.options.scales.yAxes[0].stacked = true;
                cOptions.options.tooltips.bodyAlign = 'left';

                return cOptions;
            }
        };
        this.padding = 0;
        this.stepSize = 20;
        const color = Colors.RGBA_PRI4_GRASS_GREEN;
        this.scoreCharts.LifeTimeScoreImpactGraph.chart = this.genColorfulHorizontalBarChart(this.scoreCharts.LifeTimeScoreImpactGraph, options, color, datasets);
    }

    genColorfulHorizontalBarChart(chartObj: ScoringChartChildObj, options: any, color: any, dataset?: any) {
        const {
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        if (!dataset) {
            dataset = [{
                data: tickValues,
                backgroundColor: color,
                label: this.currentSearchBy == "vehicles" ? 'Vehicle Score' : 'Driver Score',
                ...this.chartJsBarHoverInteraction
            }];
        }
        let cOptions = {
            type: 'bar',
            data: {
                labels: tickLabels,
                datasets: dataset
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    ...this.chartJsTooltipStyles

                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        barThickness: "flex",
                        maxBarThickness: 15,
                        ticks: {
                            ...this.chartJsTicksFontStyles,
                            callback: this.chartJsTicksStringEllipsisCallbackFn,
                            suggestedMin: 0,
                            suggestedMax: 100,
                            padding: this.padding,
                            stepSize: this.stepSize
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 1, 1, 1, 1, 1, 1],
                            zeroLineWidth: 3,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxRotation: 20,
                            maxTicksLimit: 7,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: false,
                            lineWidth: 3,
                            zeroLineWidth: 3,
                            color: Colors.RGBA_LIGHT_GRAY
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    async getLifeTimeScoreViolationsGraph() {
        try {
            //call api to get data
            this.lifeTimeScoreViolationGraph_spinner.show();

            // let apiStats: any = {};
            await AsyncUtil.wait(1000);
            // apiStats = await this.driverService.getDriverScoring(this.driverId, weekNo, year, this.pastScoreNoOfWeeks);

            if (this.resultList) {
                // const stats = apiStats.body;
                const formattedObj = {
                    HA: 0,
                    HB: 0,
                    HT: 0
                };

                if (this.lifeTimeScoreViolationGraphType === "Percentage") {
                    formattedObj['Speeding'] = 0;
                }
                this.resultList.map(each => {
                    if (this.lifeTimeScoreViolationGraphType === "Percentage") {
                        formattedObj.HA += each.ImpactOfHA ? Math.abs(each.ImpactOfHA) : 0;
                        formattedObj.HB += each.ImpactOfHB ? Math.abs(each.ImpactOfHB) : 0;
                        formattedObj.HT += each.ImpactOfHC ? Math.abs(each.ImpactOfHC) : 0;
                        formattedObj['Speeding'] += each.ImpactOfSpeedingDistance ? Math.abs(each.ImpactOfSpeedingDistance) : 0;
                    } else if (this.lifeTimeScoreViolationGraphType === "Count") {
                        formattedObj.HA += each.TotalHA ? Math.abs(each.TotalHA) : 0;
                        formattedObj.HB += each.TotalHB ? Math.abs(each.TotalHB) : 0;
                        formattedObj.HT += each.TotalHC ? Math.abs(each.TotalHC) : 0;
                    }
                });

                if (this.lifeTimeScoreViolationGraphType === "Percentage") {
                    //recalculate percentage else would more than 100%
                    formattedObj.HA = (formattedObj.HA / (this.resultList.length * 100)) * 100;
                    formattedObj.HB = (formattedObj.HB / (this.resultList.length * 100)) * 100;
                    formattedObj.HT = (formattedObj.HT / (this.resultList.length * 100)) * 100;
                    formattedObj['Speeding'] = (formattedObj['Speeding'] / (this.resultList.length * 100)) * 100;
                }

                // Chart
                await this.generateLifeTimeScoreViolationsPieChart(formattedObj);
            } else {
                // Remove old chart & stats
                await this.generateLifeTimeScoreViolationsPieChart(null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.lifeTimeScoreViolationGraph_spinner.hide();
        }
    }

    async generateLifeTimeScoreViolationsPieChart(data) {
        // Clear Previous Chart Data
        this.scoreCharts.LifeTimeScoreViolationGraph.destroy();

        if (!ObjectUtil.isObject(data) || ObjectUtil.isEmpty(data)) {
            return;
        }

        // Prepare Chart Values
        // const tickLabels = ['Harsh Brake', 'Speeding', 'Harsh Acceleration', 'Drive Time', 'Harsh Turn', 'Mileage'];
        const tickLabels = [];
        const tickValues = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                tickLabels.push(key);
                tickValues.push(data[key]);
            }
        }

        const _this = this;
        // Construct Chart
        const options = {
            tickLabels, //Y-Axis Tick Labels
            tickValues, //Y-Axis Tick Values
            tooltipCb: { //Tooltip Custom
                // title: function (tooltipItem, cdata) {
                //     // console.log(tooltipItem, cdata);
                //     return ' ' + cdata.labels[tooltipItem[0].index];
                // },
                label: function (tooltipItem, cdata) {
                    // console.log(tooltipItem, cdata);
                    return ' ' + cdata.labels[tooltipItem.index] + ': ' + cdata.datasets[0].data[tooltipItem.index] + (_this.lifeTimeScoreViolationGraphType === "Percentage" ? ' %' : '');
                }
            },
        };
        this.scoreCharts.LifeTimeScoreViolationGraph.chart = this.genColorfulPieChart(this.scoreCharts.LifeTimeScoreViolationGraph, options);
    }

    genColorfulPieChart(chartObj: ScoringChartChildObj, options: any) {
        const {
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        let cOptions = {
            type: 'pie',
            data: {
                labels: tickLabels,
                datasets: [{
                    data: tickValues,
                    backgroundColor: this.violationPieColourList,
                    borderWidth: 4,
                    hoverBorderColor: Colors.RGBA_WHITE_SHADE,
                    hoverBorderWidth: 5
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 10
                    }
                },
                legend: {
                    display: true,
                    position: 'top',
                    align: 'center',
                    labels: {
                        fontSize: 14,
                        fontColor: Colors.RGBA_DARK_GRAY,
                        padding: 12,
                        usePointStyle: true
                        // generateLabels: (chart) => {
                        //     chart.legend.afterFit = function () {
                        //         const width = this.width;
                        //         this.lineWidths = this.lineWidths.map(() => this.width - width * 0.3);
                        //     };
                        //     const data = chart.data;
                        //     if (data.labels.length && data.datasets.length) {
                        //         return data.labels.map((label, i) => {
                        //             const meta = chart.getDatasetMeta(0);
                        //             const ds = data.datasets[0];
                        //             const arc = meta.data[i];
                        //             const custom = arc && arc.custom || {};
                        //             const getValueAtIndexOrDefault = this.getValueAtIndexOrDefault;
                        //             const arcOpts = chart.options.elements.arc;
                        //             const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                        //             const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                        //             const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                        //             return {
                        //                 text: label,
                        //                 fillStyle: fill,
                        //                 strokeStyle: stroke,
                        //                 lineWidth: bw,
                        //                 hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                        //                 index: i // for toggling the correct item
                        //             };
                        //         });
                        //     }
                        //     return [];
                        // }
                    }
                },
                tooltips: {
                    ...this.chartJsTooltipStyles
                },
                hover: this.chartJsHoverProps,
                cutoutPercentage: 0,
                animation: {
                    animateScale: true,
                    ...this.chartJsAnimation
                }
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    // ChartJS Helper: Template Generator Configuration Override
    overrideChartJsOptions(cOptions: any, options: any) {
        if (options.onClickFn) {
            cOptions.options['onClick'] = options.onClickFn;
        }
        if (options.tooltipCb) {
            cOptions.options.tooltips['callbacks'] = options.tooltipCb;
        }
        if (options.xLabel) {
            cOptions.options.scales.xAxes[0]['scaleLabel'] = {
                display: true,
                labelString: options.xLabel,
                ...this.chartJsScaleLabelFontStyles
            };
        }
        if (options.yLabel) {
            cOptions.options.scales.yAxes[0]['scaleLabel'] = {
                display: true,
                labelString: options.yLabel,
                ...this.chartJsScaleLabelFontStyles
            };
        }
        // Apply custom configurations
        if (options.custom) {
            cOptions = options.custom(cOptions);
        }
        return cOptions;
    }

    getColors(num) {
        const initialColor = Math.floor(Math.random() * 360);
        const increment = 360 / num;
        const hsls = [];
        for (let i = 0; i < num; i++) {
          hsls.push(Math.round((initialColor + (i * increment)) % 360));
        }
        return hsls;
      }

    formatListForGraph(oriList) {
        const formattedList = [];
            let previousId;
            let previousTagNo;
            let previousName;
            let valueList = [];
            let fieldName = '';
            let idName = '';
            if (this.currentSearchBy == "vehicles") {
                fieldName = 'VehicleName';
                idName = 'VehicleId';
            } else if (this.currentSearchBy == "drivers" || this.currentSearchBy == "driverTag") {
                fieldName = 'DriverName';
                idName = 'DriverId';
            }
            for (let i = 0; i < oriList.length; i++) {
                const item = oriList[i];
                if (!previousId) {
                    //1st loop
                    previousId = item[idName];
                    previousTagNo = item.DriverTagNo;
                    previousName = item[fieldName];
                    //insert datetime to know this score is belong to which week, for later format list use, will be overwrite for formatted pattern
                    valueList = [{ value: item.Score || 0, dateTime: item.DateTime }];
                } else if (item[idName] === previousId && item.DriverTagNo === previousTagNo) {
                    //same vehicle/driver, same driver tag
                    valueList.push({ value: item.Score || 0, dateTime: item.DateTime });
                } else if (item[idName] !== previousId || item.DriverTagNo !== previousTagNo) {
                    //different vehicle/driver or different driver tag, push previous vehicle list
                    formattedList.push({
                        legendLabel: previousName,
                        values: valueList
                    });
                    //new list
                    previousId = item[idName];
                    previousTagNo = item.DriverTagNo;
                    previousName = item[fieldName];
                    valueList = [{ value: item.Score || 0, dateTime: item.DateTime }];
                }

                if (i === oriList.length - 1) {
                    //hit last element, push the list
                    formattedList.push({
                        legendLabel: previousName,
                        values: valueList
                    });
                }
            }

            //get unique yearWeek number for x axis label
            const yearWeekSet = new Set();
            oriList.map(item => {
                yearWeekSet.add(item.DateTime);
            });

            //sort ascending
            const yearWeekArr = Array.from(yearWeekSet);
            yearWeekArr.sort((a: number, b: number) => {
                return a - b;
            });

            //loop to format the list to have 0 value for those data dont have value for that particular week
            for (let i = 0; i < formattedList.length; i++) {
                const item = formattedList[i];
                const values = [];

                for (let k = 0; k < yearWeekArr.length; k++) {
                    let found = false;
                    for (let j = 0; j < item.values.length; j++) {
                        if (yearWeekArr[k] === item.values[j].dateTime) {
                            //current record have data for the week
                            values.push(item.values[j].value);
                            found = true;
                            break;
                        }
                    }

                    if (!found) {
                        //current record dont have data for the week, give it 0 value for the week
                        values.push(0);
                    }
                }
                //overwrite the values array with latest formatted pattern
                item.values = values;
            }

            return [formattedList, yearWeekArr];
    }

}
