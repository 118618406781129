export class GeneralReportOptions {

    private usingSearchBy: boolean = false;           //search by dropdown
    private usingSearchType: boolean = false;         //search type dropdown
    private usingDatePicker: boolean = false;         //single date picker
    private usingDateRangePicker: boolean = false;    //date range picker
    private usingMonthYearPicker: boolean = false;
    private usingVehicleSelector: boolean = false;    //vehicle selection dropdown
    private usingDriverSelector: boolean = false;     //driver selection dropdown
    private usingGeofenceSelector: boolean = false;   //geofence selection dropdown
    private usingDriverTagSelector: boolean = false;   //driver tag selection dropdown
    private usingVehicleTypeSelector: boolean = false; //vehicle type selection dropdown for custom report

    constructor(options: any) {
        const newOptions = Object.assign({}, this, options);

        this.usingSearchBy = newOptions.usingSearchBy;
        this.usingSearchType = newOptions.usingSearchType;
        this.usingDatePicker = newOptions.usingDatePicker;
        this.usingMonthYearPicker = newOptions.usingMonthYearPicker;
        this.usingDateRangePicker = newOptions.usingDateRangePicker;
        this.usingVehicleSelector = newOptions.usingVehicleSelector;
        this.usingDriverSelector = newOptions.usingDriverSelector;
        this.usingGeofenceSelector = newOptions.usingGeofenceSelector;
        this.usingDriverTagSelector = newOptions.usingDriverTagSelector;
        this.usingVehicleTypeSelector = newOptions.usingVehicleTypeSelector
    }

    //Getter
    isUsingSearchBy() {
        return this.usingSearchBy;
    }
    isUsingSearchType() {
        return this.usingSearchType;
    }
    isUsingDatePicker() {
        return this.usingDatePicker;
    }
    isUsingDateRangePicker() {
        return this.usingDateRangePicker;
    }
    isUsingMonthYearPicker() {
        return this.usingMonthYearPicker;
    }
    isUsingVehicleSelector() {
        return this.usingVehicleSelector;
    }
    isUsingDriverSelector() {
        return this.usingDriverSelector;
    }
    isUsingGeofenceSelector() {
        return this.usingGeofenceSelector;
    }
    isUsingDriverTagSelector() {
        return this.usingDriverTagSelector;
    }
    isUsingVehicleTypeSelector() {
        return this.usingVehicleTypeSelector;
    }
}
