import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';

import { EMPTY } from 'rxjs';


// import * as moment from 'moment';
// import * as LocalStorageUtil from '../../util/localStorageUtil';
import { AuthService } from '../auth';
import { TokenService } from '../token/token.service';
import { USER_ROLE } from '../../constants/userRole';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    // currentUser: any = null;
    // administratorUser: any = null;

    constructor(
        private authService: AuthService,
        private tokenService: TokenService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {

        return this.authService.refreshToken(request).pipe(
            mergeMap((stayLoggedInResp: any) => {

                if (stayLoggedInResp && stayLoggedInResp.abortHTTP == true) {
                    return EMPTY; //abort HTTP call
                }

                // add authorization header with jwt token if available
                let token: string = null;
                let currentUser: any = null;
                let administratorUser: any = null;

                if (this.authService.isAuthenticated()) {
                    currentUser = this.authService.getCurrentUserDetails();
                    administratorUser = this.tokenService.getAdministratorUserDetails();

                    if (request.headers.get('_usereftoken') == 'true' && administratorUser && administratorUser.refToken) {
                        token = administratorUser.refToken;
                    } else if (request.url.includes('/authorization/page-access') && currentUser && currentUser.role !== USER_ROLE.SUPER_SUPER_ADMIN.value && currentUser.role !== USER_ROLE.MAXIS_SALES_DEMO.value) {
                        // For page access, always use login user token, but not super super admin, account managed by super super admin need to show menu of managed user
                        token = currentUser.token;
                    } else if (!request.url.includes('/token/refresh') && administratorUser && administratorUser.refToken) {
                        // Administrator User Token Found
                        token = administratorUser.refToken;
                    } else if (currentUser && currentUser.token) {
                        // Normal User Token Found
                        token = currentUser.token;
                        // If want to have other users view just generate token from back end and put here
                        //     token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mbyI6eyJ1c2VybmFtZSI6ImFybmlAc3RwLm5ldC5teSIsInVzZXJJZCI6MjAzLCJyb2xlSWQiOjIsInJvbGUiOiJBRE1JTiIsInN1cHBvcnRJZCI6bnVsbCwiaXNBZG1pbiI6dHJ1ZSwiZmlyc3ROYW1lIjoiQXJuaSIsImxhc3ROYW1lIjoiU1RQIiwiY29tcGFueUlkIjoxMDIyLCJlbWFpbCI6ImFybmlAc3RwLm5ldC5teSIsInJlZnJlc2hUb2tlbiI6Ikc0dXRUdWk3Uk9EZlYyaUliblFzelFqalBDQTJWaHhtNHp3Sm9jUHpZMmZoVlNkQ0JkcnZzSVhCT2dsVlpzVXJMNkJEcWtQbmQ1TkowUEg3U1pCV000ZU1TOWVxMkdiY2VzN2pudzFuTldpZTJxMUtsMDVwVElGbGFkNW1OZU9oWERLQW14ZTBxaDhnNWVwcjg5eHczd2hWczhkeHVtRzRzbkIyWW8wT1JtWTVhb21lWDd3b0tzZWVpWko3U09IVkRzNmN0emQ2U0JnQ2hzWnlLQ1hNRVVRU1ZDVWFKSExabDJIUm5Nck5LNnpVbjVIbUJOMVlCQTUzSUdkZDAyeHQifSwiaWF0IjoxNTc1MzQzMzY3LCJleHAiOjE1NzU0Mjk3Njd9.Zd1KQxO_K15rNswgtxmgU3xQNp3fTxMzFqpBG_0NIcoqy_ff3_h8hvHTwCu8kMvBhREcjnv1U7N_j9fpNezwVy_jD0tDYqyLsJhSlHX5w5j4WqWNSE68jt4ULHHbpoMiy3-koxacEYCqC4YWo0TV86LWF5Fh68DRnNo6yaeLjhI';
                    }

                    if (token) {
                        request = this.addAuthHeader(request, token);
                    }

                    request = this.deleteTempHeaders(request);
                }
                return next.handle(request);
            }));
    }

    private deleteTempHeaders(req: HttpRequest<any>) {
        let newHeaders = null;
        for (const h of req.headers.keys()) {
            newHeaders = h.startsWith('_') ? req.headers.delete(h) : newHeaders;
        }
        return req.clone({ headers: newHeaders });
    }

    private addAuthHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token,
            }
        });
    }

}
