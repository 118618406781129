import imageCompression from 'browser-image-compression';
import { Platform } from '@ionic/angular';
import * as DomUtil from './domUtil';

/**
 * @param value (string) base64 url
 * @return (file)
 */
export async function imageDataUrlToFile(base64) {
    const imageUrl = await imageCompression.getFilefromDataUrl(base64);
    return imageUrl;
}

/**
 * @param imageFile (file)
 * @return (string) base64 url
 */
export async function imageFileToDataUrl(imageFile) {
    const imageUrl = await imageCompression.getDataUrlFromFile(imageFile);
    return imageUrl;
}

/**
 * @param (file, number, number)
 * @return (file) compressed file
 */
export async function compressImageFile(imageFile, maxSize, maxWidthHeight) {
    const compressedFile = await imageCompression(imageFile, maxSize, maxWidthHeight);
    return compressedFile;
}

// Compress Image File Size
// export async function compressImageSize(imageUrl, maxSize, maxWidthHeight, platform: Platform) {
//     let imageFile = await imageDataUrlToFile(imageUrl);
//     let compressedFile = await compressImageFile(imageFile, maxSize, maxWidthHeight);
//     let compressedImageUrl = await imageFileToDataUrl(compressedFile);
//     return compressedImageUrl;
// }

export async function compressImageSize(imageUrl, maxSize, maxWidthHeight, platform: Platform) {

    try {
        // const platform = platform;
        let compressedImageUrl;

        if (DomUtil.isMobileApp(platform)) {
            compressedImageUrl = await compressMobileImageFile(imageUrl);
        } else {
            const imageFile = await imageDataUrlToFile(imageUrl);
            const compressedFile = await compressImageFile(imageFile, maxSize, maxWidthHeight);
            compressedImageUrl = await imageFileToDataUrl(compressedFile);
        }
        return compressedImageUrl;
    } catch (e) {
        console.warn('ImageUtil: Failed to compress image!');
        return null;
    }
}

export async function compressMobileImageFile(imageUrl) {

    const compressed_img = new Image();
    compressed_img.src = imageUrl;

    const canvas = document.createElement('canvas');
    canvas.setAttribute('type', 'hidden');
    document.body.appendChild(canvas);
    const ctx = canvas.getContext('2d');

    const canvasCopy = document.createElement('canvas');
    canvasCopy.width = compressed_img.width;
    canvasCopy.height = compressed_img.height;
    canvasCopy.setAttribute('type', 'hidden');
    document.body.appendChild(canvasCopy);
    const copyContext = canvasCopy.getContext('2d');

    const maxWidth = 200;
    const maxHeight = 180;
    const wRratio = maxWidth / compressed_img.width;
    const hRratio = maxHeight / compressed_img.height;
    const ratio = Math.min(wRratio, hRratio);

    canvas.width = compressed_img.width * ratio;
    canvas.height = compressed_img.height * ratio;

    await copyContext.drawImage(compressed_img, 0, 0);
    await ctx.drawImage(canvasCopy, 0, 0, compressed_img.width, compressed_img.height, 0, 0, canvas.width, canvas.height);

    const compressedImageUrl = await canvas.toDataURL();
    await document.body.removeChild(canvas);
    await document.body.removeChild(canvasCopy);

    return compressedImageUrl;
}
