<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Edit Route
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #routeForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="compulsory-notes">
                    *Compulsory fields
                </div>
                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex geofence-field">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Step 1: Route Info *
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Route Name: <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" [(ngModel)]="routeName" name="routeName"
                                                        class="field__input" required maxlength="255">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-vehicle-selector #vehicleAssign [title]="'Step 2: Assign Vehicles *'" *ngIf="selectedVehiclesList"
            [selectedIds]="selectedVehiclesList"></app-vehicle-selector>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Step 3: Checkpoint(s) <span class="indicator-green">*</span>
                    </h2>

                    <div class="grid__block w-8of12@md heading-buttons-field --space-top">
                        <div class="heading-buttons-container">
                            <div class="heading-buttons">
                                <a class="heading-buttons__button"
                                    (click)="navigateToCreatecheckpoint(); toggleMobileOpen = false">
                                    <div class="heading-buttons__desktop">
                                        <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt=""
                                            width="23" height="23" class="heading-buttons__icon">

                                        <div class="heading-buttons__text">
                                            Create Checkpoint
                                        </div>
                                    </div>

                                    <ng-container *ngIf="toggleMobileOpen">
                                        <div class="heading-buttons__text">
                                            Create Checkpoint
                                        </div>

                                        <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg"
                                            alt="" width="60" height="60">
                                    </ng-container>
                                </a>

                                <a class="heading-buttons__button"
                                    (click)="isDeletingCheckpoint = true; toggleMobileOpen = false">
                                    <div class="heading-buttons__desktop">
                                        <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg"
                                            alt="" width="23" height="23" class="heading-buttons__icon">

                                        <div class="heading-buttons__text">
                                            Delete Checkpoint
                                        </div>
                                    </div>

                                    <ng-container *ngIf="toggleMobileOpen">
                                        <div class="heading-buttons__text">
                                            Delete Checkpoint
                                        </div>

                                        <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg"
                                            alt="" width="60" height="60">
                                    </ng-container>
                                </a>

                                <button type="button" class="heading-buttons__toggle"
                                    (click)="toggleMobileOpen = !toggleMobileOpen">
                                    <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'"
                                        alt="" width="60" height="60">
                                </button>
                            </div>

                            <ng-container *ngIf="toggleMobileOpen">
                                <div @fadeToggle class="toggle-mobile-overlay"></div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="block">
                        <div class="table table__driving-limit-geofence" [class.--isDeleting]="isDeletingCheckpoint">
                            <table class="table__elem">
                                <thead>
                                    <tr>
                                        <ng-container *ngIf="isDeletingCheckpoint">
                                            <th scope="col">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true"
                                                        class="inline-input__input"
                                                        [(ngModel)]="selectedCheckpointGroups['all']"
                                                        (ngModelChange)="onCheckpointAllSelectChange()">

                                                    <span class="inline-input__icon"></span>
                                                </label>
                                            </th>
                                        </ng-container>

                                        <th scope="col">
                                            Checkpoint Name
                                        </th>
                                        <th scope="col" width="18%">
                                            Latitude
                                        </th>
                                        <th scope="col" width="18%">
                                            Longitude
                                        </th>
                                        <th scope="col" width="18%">
                                            Radius (m)
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <ng-container *ngIf="checkpointList != []">
                                        <tr *ngFor="let checkpoint of checkpointList">
                                            <ng-container *ngIf="isDeletingCheckpoint">
                                                <td scope="col">
                                                    <label class="inline-input">
                                                        <input type="checkbox" [name]="checkpoint.id" [value]="true"
                                                            class="inline-input__input"
                                                            [(ngModel)]="selectedCheckpoint[checkpoint.id]"
                                                            (ngModelChange)="onCheckpointSelectChange($event)">

                                                        <span class="inline-input__icon"></span>
                                                    </label>
                                                </td>
                                            </ng-container>
                                            <td>
                                                <a class="link --primary"
                                                    (click)="navigateToEditCheckpoint(checkpoint.id);">{{checkpoint.name}}</a>
                                            </td>
                                            <td>
                                                {{checkpoint.lat}}
                                            </td>
                                            <td>
                                                {{checkpoint.lng}}
                                            </td>
                                            <td>
                                                {{checkpoint.radius}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <h2 class="record-not-found" *ngIf="checkpointList.length === 0">No Record Found</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" *ngIf="selectedVehiclesList"
                                [disabled]="routeForm.invalid
                                || vehicleAssign.getSelectedVehicleIds().length == 0"
                                (click)="showPopup(updateRoutePopup)">
                                SAVE
                            </button>

                            <button class="button inline-button__inputs --grey" submit-button type="button"
                                (click)="navigateToRouteView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>

<app-popup #updateRoutePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43"
                class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateRouteInfo();hidePopup();">
                Confirm
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingCheckpoint">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedCheckpoint()}} checkpoint(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteRoutePopup)"
                        [disabled]="countSelectedCheckpoint() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23"
                            height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16"
                            height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteRoutePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43"
                class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedCheckpoint()}}</span> checkpoint(s)
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteCheckpoint();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>