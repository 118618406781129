import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { VehicleService } from '../../../../_services/vehicle/vehicle.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';

import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import * as moment from 'moment';
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as DomUtil from '../../../../util/domUtil';

@Component({
    selector: 'app-set-fuel-limits',
    templateUrl: './set-fuel-limits.component.html',
    styleUrls: ['./set-fuel-limits.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class SetFuelLimitsComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild('vehicleAssign',{static:false}) vehicleAssign: VehicleSelectorComponent;

    message = Message;

    fuelCostLimit: number;
    fuelUsedLimit: number;
    fuelEfficiencyLimit: number;
    fuelDropThreshold: number;
    fuelRefuelThreshold: number;
    selectedVehiclesList = [];

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private snackBar: SnackBarService,
        private vehicleService: VehicleService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    ngOnInit() {
        DomUtil.autoFocusForm(this.el);

        this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async createFuelLimit() {
        this.pageSpinner.show();
        try {
            this.fuelCostLimit = (this.fuelCostLimit == null) ? 0 : Number(this.fuelCostLimit);
            this.fuelUsedLimit = (this.fuelUsedLimit == null) ? 0 : Number(this.fuelUsedLimit);
            this.fuelEfficiencyLimit = (this.fuelEfficiencyLimit == null) ? 0 : Number(this.fuelEfficiencyLimit);
            this.fuelDropThreshold = (this.fuelDropThreshold == null) ? 0 : Number(this.fuelDropThreshold);
            this.fuelRefuelThreshold = (this.fuelRefuelThreshold == null) ? 0 : Number(this.fuelRefuelThreshold);

            this.selectedVehiclesList = this.vehicleAssign.getSelectedVehicleIds();

            const result = await this.vehicleService.updateVehiclesFuelLimit(this.fuelCostLimit, this.fuelUsedLimit, this.fuelEfficiencyLimit, this.fuelDropThreshold, this.fuelRefuelThreshold, this.selectedVehiclesList);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Vehicle', "KPI");
            if (result && result.statusCode == ResponseStatusCode.PARTIAL.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_PARTIAL.value, 'Vehicle', "KPI");
            } else if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Vehicle', "KPI");
            }

            this.snackBar.openGenericSnackBar(msg);
            await this.navigateSuccessToFuelView();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToFuelView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/fuel/view'], null, 'fuel-kpi');
    }

    navigateToFuelView() {
        this.routerProxyService.navigate(['/create-manage/fuel/view']);
        // this.routerProxyService.navigate(['/create-manage/fuel/view', { 'fromSetFuelLimit': true }]);
    }

    /* ---- popup table ---- */
    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

}
