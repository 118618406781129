import { environment } from './../../environments/environment';
const env: string = String(environment.environmentIdentifier).toUpperCase().trim();

export function isProduction(): boolean {
    return (env.startsWith('PRD') || env.startsWith('PROD'));
}

export function isStaging(): boolean {
    return (env.startsWith('STG') || env.startsWith('STAG'));
}

export function isDev(): boolean {
    return (env.startsWith('DEV') || env.startsWith('LOCAL'));
}

export function getEnv(): string {
    return env;
}
