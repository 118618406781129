import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';
import { DriverSelectorComponent } from './../../drivers/driver-selector/driver-selector.component';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { ToggleDirective } from './../../../../directives/toggle/toggle.directive';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import * as Message from './../../../../constants/message';
import { COLOR_PICKER } from './../../../../constants/color-picker.constant';

import { GroupService } from './../../../../_services/group/group.service';
import { PopupService } from './../../../common/popup/popup.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';

import * as StringUtil from './../../../../util/stringUtil';
import * as ObjectUtil from './../../../../util/objectUtil';
import * as DomUtil from './../../../../util/domUtil';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

@Component({
    selector: 'app-create-groups',
    templateUrl: './create-groups.component.html',
    styleUrls: ['./create-groups.component.scss']
})
export class CreateGroupsComponent implements OnInit {

    groupType = '';
    groupTypeText = '';

    groupName = '';
    colorChoose: any = {
        no: 1,
        code: COLOR_PICKER.ONE
    };
    adminId: 2;

    colorPickerToggle: ToggleDirective = new ToggleDirective(null);
    array20: number[] = ObjectUtil.fillRange(20);

    @ViewChild('vehicleAssign',{static:false}) vehicleAssign: VehicleSelectorComponent;
    @ViewChild('driverAssign',{static:false}) driverAssign: DriverSelectorComponent;
    @ViewChild('page_spinner',{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild('confirmCreatePopup',{static:false}) confirmCreatePopup;
    message = Message;
    COLOR_PICKER: any = COLOR_PICKER;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private popupService: PopupService,
        private groupService: GroupService,
        private snackBar: SnackBarService,
        private el: ElementRef,
        private route: ActivatedRoute,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService

    ) { }

    ngOnInit() {
        this.groupType = this.route.snapshot.params.type;
        this.groupTypeText = this.toUpperFirstChar(this.groupType);
        DomUtil.autoFocusForm(this.el);

        this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    toggleColor(color: number) {
        this.colorChoose.no = color;
        if (color === 1) {
            this.colorChoose.code = COLOR_PICKER.ONE;
        } else if (color === 2) {
            this.colorChoose.code = COLOR_PICKER.TWO;
        } else if (color === 3) {
            this.colorChoose.code = COLOR_PICKER.THREE;
        } else if (color === 4) {
            this.colorChoose.code = COLOR_PICKER.FOUR;
        } else if (color === 5) {
            this.colorChoose.code = COLOR_PICKER.FIVE;
        } else if (color === 6) {
            this.colorChoose.code = COLOR_PICKER.SIX;
        } else if (color === 7) {
            this.colorChoose.code = COLOR_PICKER.SEVEN;
        } else if (color === 8) {
            this.colorChoose.code = COLOR_PICKER.EIGHT;
        } else if (color === 9) {
            this.colorChoose.code = COLOR_PICKER.NINE;
        } else if (color === 10) {
            this.colorChoose.code = COLOR_PICKER.TEN;
        } else if (color === 11) {
            this.colorChoose.code = COLOR_PICKER.ELEVEN;
        } else if (color === 12) {
            this.colorChoose.code = COLOR_PICKER.TWELVE;
        } else if (color === 13) {
            this.colorChoose.code = COLOR_PICKER.THIRTEEN;
        } else if (color === 14) {
            this.colorChoose.code = COLOR_PICKER.FOURTEEN;
        } else if (color === 15) {
            this.colorChoose.code = COLOR_PICKER.FIFTEEN;
        } else if (color === 16) {
            this.colorChoose.code = COLOR_PICKER.SIXTEEN;
        } else if (color === 17) {
            this.colorChoose.code = COLOR_PICKER.SEVENTEEN;
        } else if (color === 18) {
            this.colorChoose.code = COLOR_PICKER.EIGHTEEN;
        } else if (color === 19) {
            this.colorChoose.code = COLOR_PICKER.NINETEEN;
        } else if (color === 20) {
            this.colorChoose.code = COLOR_PICKER.TWENTY;
        }
    }

    async submitCreateNewGroup() {
        this.pageSpinner.show();
        let ids: any;
        try {
            if (this.groupType === 'vehicle') {
                ids = this.vehicleAssign.getSelectedVehicleIds();
            } else {
                ids = this.driverAssign.getSelectedDriverIds();
            }
            this.showPopup(this.confirmCreatePopup);
            const body = {
                groupType: this.groupType.toUpperCase(),
                name: this.groupName,
                color: this.colorChoose.code,
                ids: ids
            };

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(body.name)) {
                body.name = body.name.trim();
            }

            const result = await this.groupService.createGroup(body);

            // window.scroll(0, 0); // Scroll to top
            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, this.toUpperFirstChar(this.groupType) + ' Group', this.groupName);

            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, this.toUpperFirstChar(this.groupType) + ' Group', this.groupName);
                this.navigateSuccessToGroupView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToGroupView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/groups/view'], null, this.groupType);
    }

    navigateToGroupView() {
        this.routerProxyService.navigate(['/create-manage/groups/view']);
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    toUpperFirstChar(value) {
        if (value !== '') {
            return value.substr(0, 1).toUpperCase() + value.substr(1);
        } else {
            return value;
        }
    }

    closeColorPicker(clickEvent?: Event): void {
        // let floaterContentTagId = "#colorpicker-floater-wrapper"; //tag with '--active' class
        // let excludedHtmlIds = [
        //     "colorpicker-floater-toggler",
        //     "colorpicker-floater-content"
        // ];

        //Avoid floater auto-closed when clicked on toggler
        // if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
        //     // console.debug('blocked event by: ' + (<Element>clickEvent.target).id);
        //     return;
        // }

        // let colorPickerDom = this.el.nativeElement.querySelector(floaterContentTagId);
        // if (Boolean(colorPickerDom) && colorPickerDom.className.indexOf("--active") >= 0) {
        //     // console.debug('EditGroupComponent: Closing color picker');
        //     this.colorPickerToggle.setInactive();
        // }
        this.colorPickerToggle.setInactive();
    }

}
