<!-- Spinner -->
<div #overlay class="overlay">

    <!-- 1. ROTATING PLANE -->
    <!-- <div #skSpinner class="sk-spinner sk-rotating-plane"></div> -->

    <!-- 2. DOUBLE BOUNCE -->
    <!-- <div #skSpinner class="sk-spinner sk-double-bounce">
    <div class="sk-child sk-double-bounce1"></div>
    <div class="sk-child sk-double-bounce2"></div>
  </div> -->

    <!-- 3. WAVE -->
    <!-- <div #skSpinner class="sk-spinner sk-wave">
    <div class="sk-rect sk-rect1"></div>
    <div class="sk-rect sk-rect2"></div>
    <div class="sk-rect sk-rect3"></div>
    <div class="sk-rect sk-rect4"></div>
    <div class="sk-rect sk-rect5"></div>
  </div> -->

    <!-- 4. WANDERING CUBES -->
    <!-- <div #skSpinner class="sk-spinner sk-wandering-cubes">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
  </div> -->

    <!-- 5. PULSE -->
    <!-- <div #skSpinner class="sk-spinner sk-spinner-pulse"></div> -->

    <!-- 6. CHASING DOTS -->
    <!-- <div #skSpinner class="sk-spinner sk-chasing-dots">
    <div class="sk-child sk-dot1"></div>
    <div class="sk-child sk-dot2"></div>
  </div> -->

    <!-- 7. THREE BOUNCE -->
    <!-- <div #skSpinner class="sk-spinner sk-three-bounce">
    <div class="sk-child sk-bounce1"></div>
    <div class="sk-child sk-bounce2"></div>
    <div class="sk-child sk-bounce3"></div>
  </div> -->

    <!-- 8. CIRCLE -->
    <!-- div class="sk-spinner sk-circle">
    <div class="sk-circle1 sk-child"></div>
    <div class="sk-circle2 sk-child"></div>
    <div class="sk-circle3 sk-child"></div>
    <div class="sk-circle4 sk-child"></div>
    <div class="sk-circle5 sk-child"></div>
    <div class="sk-circle6 sk-child"></div>
    <div class="sk-circle7 sk-child"></div>
    <div class="sk-circle8 sk-child"></div>
    <div class="sk-circle9 sk-child"></div>
    <div class="sk-circle10 sk-child"></div>
    <div class="sk-circle11 sk-child"></div>
    <div class="sk-circle12 sk-child"></div>
  </div> -->

    <!-- 9. CUBE GRID -->
    <!-- <div #skSpinner class="sk-spinner sk-cube-grid">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div> -->

    <!-- 10. FADING CIRCLE -->
    <div #skSpinner class="sk-spinner sk-fading-circle">
        <div class="sk-circle1 sk-circle"></div>
        <div class="sk-circle2 sk-circle"></div>
        <div class="sk-circle3 sk-circle"></div>
        <div class="sk-circle4 sk-circle"></div>
        <div class="sk-circle5 sk-circle"></div>
        <div class="sk-circle6 sk-circle"></div>
        <div class="sk-circle7 sk-circle"></div>
        <div class="sk-circle8 sk-circle"></div>
        <div class="sk-circle9 sk-circle"></div>
        <div class="sk-circle10 sk-circle"></div>
        <div class="sk-circle11 sk-circle"></div>
        <div class="sk-circle12 sk-circle"></div>
    </div>

    <!-- 11. FOLDING CUBE -->
    <!-- <div #skSpinner class="sk-spinner sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
  </div> -->

</div>
<!-- End of Spinner -->
