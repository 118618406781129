<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Edit Geofence
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #geofenceForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="compulsory-notes">
                    *Compulsory fields
                </div>
                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex geofence-field">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Step 1: Geofence Info *
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Geofence Name: <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" [(ngModel)]="geofenceName" name="geofenceName" class="field__input" required maxlength="255">
                                                </label>

                                                <div class="field__ui"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="field-group__holder geofence-alert">
                                    <div class="field-group__label">
                                        Alert Type: <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <label class="inline-input">
                                            <input type="checkbox" name="alertTypes[0].name" value="{{alertTypes[0].value}}" [(ngModel)]="alertTypes[0].checked" class="inline-input__input" />

                                            <span class="inline-input__icon"></span>
                                            {{alertTypes[0].name}}
                                        </label>

                                        <label class="inline-input">
                                            <input type="checkbox" name="alertTypes[1].name" value="{{alertTypes[1].value}}" [(ngModel)]="alertTypes[1].checked" class="inline-input__input" />

                                            <span class="inline-input__icon"></span>
                                            {{alertTypes[1].name}}
                                        </label>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Alert Day/Time : <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field-group__groups-col geofence-alert">
                                            <label class="inline-input">
                                                <input type="checkbox" name="alertDays[1]" value="{{alertDays[1].value}}" [(ngModel)]="alertDays[1].checked" class="inline-input__input" />

                                                <span class="inline-input__icon"></span>
                                                {{alertDays[1].name}}
                                            </label>

                                            <label class="inline-input">
                                                <input type="checkbox" name="alertDays[2]" value="{{alertDays[2].value}}" [(ngModel)]="alertDays[2].checked" class="inline-input__input" />

                                                <span class="inline-input__icon"></span>
                                                {{alertDays[2].name}}
                                            </label>

                                            <label class="inline-input">
                                                <input type="checkbox" name="alertDays[3]" value="{{alertDays[3].value}}" [(ngModel)]="alertDays[3].checked" class="inline-input__input" />

                                                <span class="inline-input__icon"></span>
                                                {{alertDays[3].name}}
                                            </label>

                                            <label class="inline-input">
                                                <input type="checkbox" name="alertDays[4]" value="{{alertDays[4].value}}" [(ngModel)]="alertDays[4].checked" class="inline-input__input" />

                                                <span class="inline-input__icon"></span>
                                                {{alertDays[4].name}}
                                            </label>

                                            <label class="inline-input">
                                                <input type="checkbox" name="alertDays[5]" value="{{alertDays[5].value}}" [(ngModel)]="alertDays[5].checked" class="inline-input__input" />

                                                <span class="inline-input__icon"></span>
                                                {{alertDays[5].name}}
                                            </label>

                                            <label class="inline-input">
                                                <input type="checkbox" name="alertDays[6]" value="{{alertDays[6].value}}" [(ngModel)]="alertDays[6].checked" class="inline-input__input" />

                                                <span class="inline-input__icon"></span>
                                                {{alertDays[6].name}}
                                            </label>

                                            <label class="inline-input">
                                                <input type="checkbox" name="alertDays[0]" value="{{alertDays[0].value}}" [(ngModel)]="alertDays[0].checked" class="inline-input__input" />

                                                <span class="inline-input__icon"></span>
                                                {{alertDays[0].name}}
                                            </label>

                                        </div>

                                        <div class="field-group__groups">
                                            <div class="field-group__groups-col">
                                                <div class="field-group__groups-inner field-group__time">
                                                    <div class="field-group__time-inner">
                                                        <label class="select select-time">
                                                            <select class="select__input" [(ngModel)]="allowedTimeFromHour" name="allowedTimeFromHour">
                                                                <option value="" selected disabled hidden>HH</option>
                                                                <option *ngFor="let hour of timeSelector.hour" [value]="hour">{{hour}}</option>
                                                            </select>
                                                        </label>
                                                    </div>

                                                    <div class="field-group__time-inner --no-padding">
                                                        :
                                                    </div>

                                                    <div class="field-group__time-inner">
                                                        <label class="select select-time">
                                                            <select class="select__input" [(ngModel)]="allowedTimeFromMinute" name="allowedTimeFromMinute">
                                                                <option value="" selected disabled hidden>MM</option>
                                                                <option *ngFor="let minute of timeSelector.minute" [value]="minute">{{minute}}</option>
                                                            </select>
                                                        </label>
                                                    </div>

                                                    <div class="field-group__time-inner">
                                                        <label class="select select-time">
                                                            <select class="select__input" [(ngModel)]="allowedTimeFromAMPM" name="allowedTimeFromAMPM">
                                                                <option value="AM">AM</option>
                                                                <option value="PM">PM</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="field-group__groups-inner">
                                                    -
                                                </div>

                                                <div class="field-group__groups-inner field-group__time">
                                                    <div class="field-group__time-inner">
                                                        <label class="select select-time">
                                                            <select class="select__input" [(ngModel)]="allowedTimeToHour" name="allowedTimeToHour">
                                                                <option value="" selected disabled hidden>HH</option>
                                                                <option *ngFor="let hour of timeSelector.hour" [value]="hour">{{hour}}</option>
                                                            </select>
                                                        </label>
                                                    </div>

                                                    <div class="field-group__time-inner --no-padding">
                                                        :
                                                    </div>

                                                    <div class="field-group__time-inner">
                                                        <label class="select select-time">
                                                            <select class="select__input" [(ngModel)]="allowedTimeToMinute" name="allowedTimeToMinute">
                                                                <option value="" disabled hidden>MM</option>
                                                                <option *ngFor="let minute of timeSelector.minute" [value]="minute">{{minute}}</option>
                                                            </select>
                                                        </label>
                                                    </div>

                                                    <div class="field-group__time-inner">
                                                        <label class="select select-time">
                                                            <select class="select__input" [(ngModel)]="allowedTimeToAMPM" name="allowedTimeToAMPM">
                                                                <option value="AM">AM</option>
                                                                <option value="PM">PM</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Step 2: Geofence Area <span class="indicator-green">*</span>
                    </h2>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-9of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Search Address:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input id="searchInput" type="text" placeholder="eg: KLCC" class="field__input">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field-group__holder" style="text-align: center">
                    <label class="inline-input" style="margin: 10px">
                        <input type="radio" name="role" class="inline-input__input" [checked]="selectedGeofenceType == polygon" (click)="chooseGeofenceType(polygon)">

                        <span class="inline-input__icon"></span>
                        Polygon
                    </label>

                    <label class="inline-input" style="margin: 10px">
                        <input type="radio" name="role" class="inline-input__input" [checked]="selectedGeofenceType == circle" (click)="chooseGeofenceType(circle)">

                        <span class="inline-input__icon"></span>
                        Circle
                    </label>
                </div>
                <div class="map__canvas" id="map">
                    map area
                </div>
            </div>
        </div>

        <app-vehicle-selector #vehicleAssign [title]="'Step 3: Assign Vehicles *'" *ngIf="selectedVehiclesList" [selectedIds]="selectedVehiclesList"></app-vehicle-selector>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" *ngIf="selectedVehiclesList" [disabled]="geofenceForm.invalid
                                || vehicleAssign.getSelectedVehicleIds().length == 0
                                || geofencePath === ''
                                || alertDaySelectedOptions().length == 0
                                || alertTypeSelectedOptions().length == 0
                                || allowedTimeFromHour == 'HH'
                                || allowedTimeFromMinute == 'MM'
                                || allowedTimeToHour == 'HH'
                                || allowedTimeToMinute == 'MM'" (click)="showPopup(updateGeofencePopup)">
                                SAVE
                            </button>

                            <button class="button inline-button__inputs --grey" submit-button type="button" (click)="navigateToDrivingLimitView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>

<app-popup #updateGeofencePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to save {{geofenceName}} geofence changes
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateGeofenceInfo();hidePopup();">
                Confirm
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
