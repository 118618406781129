import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TokenService } from './../../../_services/token/token.service';
import { RouterProxyService } from './../../../_services/router-proxy/router-proxy.service';
import { AccessControlService } from './../../../_services/access-control/access-control.service';
import { PopupCampaignService } from './../../../_services/campaign/popup-campaign.service';
import { SnackBarService } from './../../../_services/snackBar/snack-bar.service';
import { AuthService } from './../../../_services/auth/auth.service';

declare var $: any;

@Component({
    selector: 'app-maxis-support-panel',
    templateUrl: './maxis-support-panel.component.html',
    styleUrls: ['./maxis-support-panel.component.scss']
})
export class MaxisSupportPanelComponent implements OnInit {

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    allowAccessCampaign: boolean = false;
    allowAccessManageCompany: boolean = false;
    allowAccessResetPass: boolean = false;
    allowAccessIdManagement: boolean = false;
    allowAccessManageDevice: boolean = false;
    allowAccessSaleDashboard: boolean = false;
    allowAccessTerminateVehicle: boolean = false;

    constructor(
        private routerProxyService: RouterProxyService,
        private tokenService: TokenService,
        private accessControlService: AccessControlService,
        private popupCampaignService: PopupCampaignService,
        private snackBar: SnackBarService,
        private authService: AuthService
    ) {
        const token = this.tokenService.removeAdministratorUser(); // remove administrator token
        if (token) {
            this.authService.removeAdminitratorLogin(token);
        }
    }

    ngOnInit() {
        this.checkIsAllowAccess();
        this.checkUnviewedCampaign();
    }

    async checkIsAllowAccess() {
        this.allowAccessCampaign = await this.accessControlService.hasPermission('/support/manage/manage-campaigns');
        this.allowAccessManageCompany = await this.accessControlService.hasPermission('/support/manage/companies');
        this.allowAccessResetPass = await this.accessControlService.hasPermission('/support/manage/companies/reset-password');
        this.allowAccessIdManagement = await this.accessControlService.hasPermission('/support/manage/admins');
        this.allowAccessManageDevice = await this.accessControlService.hasPermission('/support/manage-device');
        this.allowAccessTerminateVehicle = await this.accessControlService.hasPermission('/support/terminate-vehicles');
        this.allowAccessSaleDashboard = await this.accessControlService.hasPermission('/support/sale-dashboard');
    }

    navigateToCustomerView() {
        this.routerProxyService.navigateByUrl('/support/manage/companies');
    }

    navigateToResetPassword() {
        this.routerProxyService.navigateByUrl('/support/manage/companies/reset-password');
    }

    navigateToIdManagement() {
        this.routerProxyService.navigateByUrl('/support/manage/admins');
    }

    navigateToManageCampaigns() {
        this.routerProxyService.navigateByUrl('/support/manage/manage-campaigns');
    }

    navigateToManageDevices() {
        this.routerProxyService.navigateByUrl('/support/manage-device');
    }

    navigateToSalesDashboard() {
        this.routerProxyService.navigateByUrl('/support/sale-dashboard');
    }

    navigateToVehicleTermination() {
        this.routerProxyService.navigateByUrl('/support/terminate-vehicles');
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }
}
