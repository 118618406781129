import * as USER_ROLE from './../../constants/userRole';
import { Injectable } from '@angular/core';

import * as LocalStorageUtil from '../../util/localStorageUtil';

import { SnackBarService } from './../snackBar/snack-bar.service';
import { RouterProxyService } from '../router-proxy/router-proxy.service';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor(
        private routerProxyService: RouterProxyService,
        private snackBar: SnackBarService
    ) { }

    removeAdministratorUser() {
        let removeToken = null;
        if (this.isAdministratorUserTokenExist()) {
            const adminToken = LocalStorageUtil.localStorageGet('administratorUser');
            if (adminToken) {
                removeToken = {
                    refToken: adminToken.refToken
                }
            }
            LocalStorageUtil.localStorageRemove('administratorUser');
            // this.msgbus.sendMessage(new BusMessage("support", "companyName", { companyName: null }));
        }
        return removeToken;
    }

    isAdministratorUserTokenExist() {
        if (LocalStorageUtil.localStorageGet('administratorUser')) {
            return true;
        }
        return false;
    }

    getAdministratorUserDetails() {
        if (this.isAdministratorUserTokenExist()) {
            return LocalStorageUtil.localStorageGet('administratorUser');
        }
        return null;
    }

    getUserSupportId() {
        const currentUser = LocalStorageUtil.localStorageGet('currentUser');
        if (currentUser && currentUser.supportId) {
            return currentUser.supportId;
        }
        return false;
    }

    navigateToSupportPage(supportId, activeRoute) {
        // console.debug('navigateToSupportPage');
        // Maxis Support Level B
        // if custom role support maxpert or CS here need to change
        if (supportId === USER_ROLE.USER_ROLE.MAXIS_SUPPORT_B.supportId || supportId === USER_ROLE.USER_ROLE.MAXIS_SALES_DEMO.supportId) {
            const pathList = ["support/panel", "support/manage/companies", "support/manage/companies/reset-password", "support/manage/manage-campaigns", "support/manage/manage-campaigns/create-campaigns", "support/manage/manage-maxis-users", "support/manage/manage-maxis-users/create-user", "support/sale-dashboard"];
            if (pathList.indexOf(activeRoute.routeConfig.path) > -1) {
                return true;
            } else {
                this.routerProxyService.navigateByUrl('/support/panel');
                this.snackBar.openGenericSnackBar("Please Select Company to Manage");
                return false;
            }
        } else if (supportId === USER_ROLE.USER_ROLE.MAXIS_SUPPORT_A.supportId) {
            const pathList = ["support/manage/companies/reset-password"];
            if (pathList.indexOf(activeRoute.routeConfig.path) > -1) {
                return true;
            } else {
                this.routerProxyService.navigateByUrl('/support/manage/companies/reset-password');
                this.snackBar.openGenericSnackBar("Your Role Only Allowed to Reset Password");
                return false;
            }
        } else if (supportId === USER_ROLE.USER_ROLE.MAXIS_SUPPORT_ID_MGMT.supportId) {
            const pathList = ["support/manage/admins", "support/manage/admins/create", "support/manage/admins/edit"];
            if (pathList.indexOf(activeRoute.routeConfig.path) > -1) {
                return true;
            } else {
                this.routerProxyService.navigateByUrl('/support/manage/admins');
                this.snackBar.openGenericSnackBar("Your Role Only Allowed to Manage Id");
                return false;
            }
        }
    }
}
