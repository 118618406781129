import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../_services/auth";
import { CustomReuseStrategy } from "../../../app-routing-custom-reuse-strategy";
import { BusMessage, MessageBusService } from "../../../_services/messagebus.service";
import { RouterProxyService } from "../../../_services/router-proxy/router-proxy.service";
import { ErrorHandlerModule } from "../../../_services/error-handler";
import { filter } from 'rxjs/operators';
import * as AsyncUtil from '../../../util/asyncUtil';

// azure ad
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { 
    AuthenticationResult, 
    EventMessage, 
    EventType 
} from '@azure/msal-browser';

@Component({
    selector: 'app-sso-login',
    templateUrl: './sso-login.component.html',
    styleUrls: ['./sso-login.component.scss'],
})
export class SsoLoginComponent implements OnInit {

    error: string = '';
    state: string = null;
    authCode: string = null;
    imageFolder: string = 'assets/images/ng-components/login/';
    countdownInSec: number = 10;

    constructor(
        private route: ActivatedRoute,
        private msgbus: MessageBusService,
        private authService: AuthService,
        private errorHandler: ErrorHandlerModule,
        private routerProxyService: RouterProxyService,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        this.state = route.snapshot.queryParams.state || null;
        this.authCode = route.snapshot.queryParams.code || null;
    }

    async ngOnInit() {
        const azureUserName = this.msalService.instance.getActiveAccount()?.username;
        const request = {
            scopes: ["User.Read"],
        };
        const azureToken = (await this.msalService.instance.acquireTokenSilent(request)).accessToken;

        try {
            if (!azureUserName || !azureToken) {
                throw new Error('Invalid Login !');
            }

            const result: any = await this.authService.loginViaSso(azureToken, azureUserName);
            if (result) {
                //Turn on reuse route path
                CustomReuseStrategy.turnOnReuseRoutePath();

                //Notify AppComponent to do something
                this.msgbus.sendMessage(new BusMessage("system", "login", null));

                //Stay logged in
                this.authService.setStayLoggedIn(true);

                //Init home page
                await this.routerProxyService.initHomePage();

                //Set Padding Top to come below notch/status bar (safe area)
                document.body.style.paddingTop = 'max(0vmin, env(safe-area-inset-top))';

                this.routerProxyService.navigateByUrl(this.routerProxyService.getUserHomePage());
            }
        } catch (error) {
            // console.log('error', error);
            this.error = this.errorHandler.handleStandardizedError(error, true);

            const intervalTimer = setInterval(() => {
                if (this.countdownInSec === 0) {
                    clearInterval(intervalTimer);
                    this.routerProxyService.navigate(['/login']);
                } else {
                    this.countdownInSec--;
                }
            }, 1000);
        }
    }

}
