import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../../../environments/environment';

import * as Message from '../../../../constants/message';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { DriverService } from './../../../../_services/driver/driver.service';
import { UserService } from './../../../../_services/user/user.service';
import { PagerService } from './../../../../_services/pager/pager.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { FileExportService } from '../../../../_services/file-export/file-export.service';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';
import { modules } from '../../../../constants/module-access.constant';
import { RESPONSE_STATUS_CODE as StatusCode } from '../../../../constants/responseStatusCode';

import * as moment from 'moment';
import * as StringUtil from '../../../../util/stringUtil';
import * as DomUtil from './../../../../util/domUtil';
import * as XLSX from 'xlsx';
import * as NumberUtil from './../../../../util/numberUtil';
import * as DateTimeUtil from './../../../../util/dateTimeUtil';

@Component({
    selector: 'app-view-drivers',
    templateUrl: './view-drivers.component.html',
    styleUrls: ['./view-drivers.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ViewDriversComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    isDeletingDrivers: boolean = false;
    selectedDeleteDriversList: any = [];
    selectedDeleteDrivers: any = {};

    driverViewOriList: any = [];
    driverViewInfoList: any = [];

    //Sort / Filter Driver Name
    sortField = 'UserName';
    sortAscending: boolean = true;
    filterKey = 'FirstName'; // Filter By Driver Name
    filterValue = null;
    currentFilterValue = null;
    moment = moment;
    message = Message;
    listView: boolean = false;
    viewText: string = "List View";

    //Pagination
    pager: any = {}; //Pager object
    pageRecordSize: number = environment.appConfig.createManage.pageRecordSize_tiles;

    @ViewChild('batchAlertPopup',{static:false}) batchAlertPopup;
    @ViewChild('batchCreateDriverPopup',{static:false}) batchCreateDriverPopup;
    file: File;
    arrayBuffer: any;
    batchCreateDriverFileSize = environment.appConfig.createManage.batchFileSize;
    batchDriverList = [];
    batchDriverCount: number = 0;
    batchErrorMessage = null;
    batchFileSize = 2;

    // Export Driver File
    exportDriverFileHeader = ["User Name", "Driver Tag No", "Email", "Telephone No", "Driving License No", "Driving License Expiry Date", "Vehicle Name", "Vehicle Plate No", "Vehicle Imei No", "Group List"];

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;

    constructor(
        private popupService: PopupService,
        private driverService: DriverService,
        private route: ActivatedRoute,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private routerProxyService: RouterProxyService,
        private fileExportService: FileExportService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService,
        private userService: UserService,

    ) { }

    async ngOnInit() {
        await this.getDisplayDriverList();
        // this.route.params.subscribe(params => {
        //     this.snackBar.openSnackBar(params);
        // });
        await this.checkUnviewedCampaign();

        // this component reuses module code 'CRUD_DRIVER' key to check for access right.
        const permissions = await this.mpService.hasPermission(modules.CRUD_DRIVER.value);
        this.creatable = permissions[modules.CRUD_DRIVER.value].cAccess;
        this.editable = permissions[modules.CRUD_DRIVER.value].eAccess;
        this.deletable = permissions[modules.CRUD_DRIVER.value].dAccess;

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async sort() {
        this.sortAscending = !this.sortAscending;
        await this.getDisplayDriverList();
    }

    changeView() {
        // change from grid view to list view and vice versa
        this.listView = !this.listView;
        this.viewText = this.listView ? 'Grid View' : 'List View';
    }

    async searchDriverName() {
        this.currentFilterValue = this.filterValue;
        await this.getDisplayDriverList();
    }

    async getDisplayDriverList(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            this.driverViewOriList = await this.driverService.getDrivers(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.currentFilterValue);
            this.driverViewInfoList = this.driverViewOriList.body.result;
            this.pager = this.pagerService.getPager(this.driverViewOriList.body.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.driverViewInfoList.forEach(driver => {
                driver.Username = StringUtil.toTitleCase(driver.Username);
                driver.shortName = StringUtil.getShortName(driver.Username);
                driver.hasUserNameBool = StringUtil.isNotEmptyOrNull(driver.Username);
                if ((driver.DrivingLicenseExpiryDate) !== null) {
                    driver.DrivingLicenseExpiryDate = moment(driver.DrivingLicenseExpiryDate).format('YYYY-MM-DD');
                }
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async deleteDriver() {
        this.pageSpinner.show();
        try {
            this.selectedDeleteDriversList = [];
            for (const objKey in this.selectedDeleteDrivers) {
                if (this.selectedDeleteDrivers[objKey] === true) {
                    this.selectedDeleteDriversList.push(parseInt(objKey));
                }
            }
            const result = await this.driverService.deleteDrivers(this.selectedDeleteDriversList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Driver');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Driver');

                this.cancelResetDelete();
                await this.getDisplayDriverList();
            }
            this.snackBar.openGenericSnackBar(msg);

            // if (result.statusCode == "FAILED") {
            //     let driverName = '';
            //     let userCount = 0;
            //     if (result.body.userFullName) {
            //         driverName = result.body.userFullName;
            //         userCount = 1;
            //     } else if (result.body.userIds.length) {
            //         userCount = result.body.userIds.length;
            //     }
            //     let params = {
            //         name: driverName,
            //         status: 'FAILED',
            //         action: 'DELETE',
            //         type: 'DRIVER',
            //         count: userCount
            //     }
            //     this.snackBar.openSnackBar(params);
            // } else {
            //     let driverName = '';
            //     let userCount = 0;
            //     if (result.body.userFullName) {
            //         driverName = result.body.userFullName;
            //         userCount = 1;
            //     } else if (result.body.userIds.length) {
            //         userCount = result.body.userIds.length;
            //     }
            //     let params = {
            //         name: driverName,
            //         status: 'SUCCESS',
            //         action: 'DELETE',
            //         type: 'DRIVER',
            //         count: userCount
            //     }
            //     this.snackBar.openSnackBar(params);
            // }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    countSelectedDrivers() {
        return Object.keys(this.selectedDeleteDrivers).filter(result => this.selectedDeleteDrivers[result] === true).length;
    }

    navigateToCreateDriver() {
        this.routerProxyService.navigate(['/create-manage/drivers/create']);
    }

    navigateToEditDriver(driverId, clickEvent?: Event) {

        if (!this.isDeletingDrivers) {
            const excludedHtmlIds = [
                "group-colors"
            ];

            //Avoid navigate when click on group info
            if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
                return;
            }

            this.routerProxyService.navigate(['/create-manage/drivers/edit', driverId]);
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    cancelResetDelete(closeDeletePanel: boolean = true): void {
        this.isDeletingDrivers = !closeDeletePanel;
        this.selectedDeleteDrivers = {};
        // this.selectedMaintenance = {};
        // this.selectedMaintenanceGroups['all'] = false;
    }

    showDownloadPopup(popup) {
        this.popupService.show(popup);
    }
    hideDownloadPopup() {
        this.popupService.hide();
    }

    async exportDriverFile(fileType) {
        const exportDriverViewResponse: any = await this.driverService.getDrivers(0, 0, "UserName", this.sortAscending);
        const exportDriverViewInfoList = exportDriverViewResponse.body.result;

        const data = [];
        for (let i = 0; i < exportDriverViewInfoList.length; i++) {
            const groupLength = exportDriverViewInfoList[i].GroupList.length;
            let groupName = '';

            for (let k = 0; k < groupLength; k++) {
                const nameGrp = exportDriverViewInfoList[i].GroupList[k].groupName;
                if (k == 0) {
                    groupName = nameGrp;
                } else {
                    groupName = groupName + "," + nameGrp;
                }
            }

            const tempRow = [
                exportDriverViewInfoList[i].Username || '',
                exportDriverViewInfoList[i].driverTagNo || '',
                exportDriverViewInfoList[i].Email || '',
                exportDriverViewInfoList[i].TelNo || '',
                exportDriverViewInfoList[i].DrivingLicenseNo || '',
                exportDriverViewInfoList[i].DrivingLicenseExpiryDate || '',
                exportDriverViewInfoList[i].VehicleName || '',
                exportDriverViewInfoList[i].VehiclePlateNo || '',
                exportDriverViewInfoList[i].VehicleImeiNo || '',
                groupName || ''];

            data.push(tempRow);
        }

        if (fileType === 'excel') {
            const excelData: any = this.fileExportService.mergeHeaderAndDataForExcel(data, this.exportDriverFileHeader);
            this.fileExportService.exportExcelFile(excelData, "Driver_Report_Excel");
        } else if (fileType === 'csv') {
            this.fileExportService.exportCsvFile(data, this.exportDriverFileHeader, "Driver_Report_Csv");
        } else if (fileType === 'pdf') {
            this.fileExportService.exportPdfFile(data, this.exportDriverFileHeader, "Driver_Report_Pdf", "Driver Report", null, "landscape");
        }

    }

    // onBatchFileUpload
    batchImportDriver(event) {
        const fileType = ["xlsx", "xls", "csv"];

        // Check File Type
        if (event.target.files.length > 0 && fileType.find(result => event.target.files[0].name.includes(result))) {
            const fileSize = event.target.files[0].size;
            if (fileSize > this.batchCreateDriverFileSize * 1024 * 1024) {
                this.batchErrorMessage = `${`File Size Cannot More Than ` + this.batchCreateDriverFileSize + 'MB'}`;
                this.showPopup(this.batchAlertPopup);
            } else {
                this.file = event.target.files[0];
                const fileInput: any = document.getElementById('myFileInput');
                fileInput.value = null; // Clear previous file input data
                this.convertExcelToJson();
            }
        } else {
            this.batchErrorMessage = 'File Type must be in (xlsx / xls / csv)';
            this.showPopup(this.batchAlertPopup);
        }
    }

    // Reading uploaded file
    async convertExcelToJson() {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = async (e) => {
            this.arrayBuffer = fileReader.result;
            const data = new Uint8Array(this.arrayBuffer);
            const arr = new Array();
            for (let i = 0; i != data.length; i++) { arr[i] = String.fromCharCode(data[i]); }
            const bstr = arr.join("");
            const workbook = XLSX.read(bstr, { type: "binary", cellDates: true, cellNF: false, cellText: false });
            const first_sheet_name = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[first_sheet_name];
            this.batchDriverList = XLSX.utils.sheet_to_json(worksheet, { dateNF: "DD/MM/YYYY", raw: false, defval: null });
            this.batchDriverCount = this.batchDriverList.length;

            // Check File Content
            const checkContentResult = await this.checkIsValidFileContent();
            if (!checkContentResult.status) {
                this.batchErrorMessage = checkContentResult.errorMessage;
                this.showPopup(this.batchAlertPopup);
            } else {
                this.showPopup(this.batchCreateDriverPopup);
            }
        };
    }

    // format checking
    async checkIsValidFileContent() {

        // Get current all user email list
        const emailResponse = await this.userService.getAllUserEmail();
        const userEmailList = emailResponse.body.result;

        const typeDef = {
            "FirstName": { type: "string", required: true },
            "LastName": { type: "string", required: true },
            "PhoneNumber": { type: "number", required: true },
            "Email": { type: "email", required: true, maxLength:50 },
            "IdTagNumber": { type: "string", required: false, maxLength:50 },
            "NotifyTagId (Leave empty will default 5 minutes)": { type: "number", required: false },
            "DrivingLicenseNo": { type: "string", required: false },
            "DrivingLicenseExpiryDate": { type: "date", format: "DD/MM/YYYY", required: false },
            "ExpiryReminderDay  (Leave empty will default 30 days)": { type: "number", required: false },
        }

        if (this.batchDriverList.length) {
            for (let i = 0; i < this.batchDriverList.length; i++) {
                const eachRecord = this.batchDriverList[i];
                const columnsStatus: any = {};

                // Loop each column of the row for validation
                for (const fieldName in typeDef) {                    
                    if (!Object.prototype.hasOwnProperty.call(typeDef, fieldName)) {
                        continue;
                    }
                    let fieldValue = eachRecord[fieldName];
                    const fieldRule = typeDef[fieldName];
    
                    // Normal validation
                    const response = this.validateFields(fieldValue, fieldRule, fieldName);
                    columnsStatus[fieldName + ' Valid'] = !response.hasViolation;
                    columnsStatus[fieldName + ' ErrMsg'] = response.errorMsg;

                    if (response.hasViolation) {
                        return { status: false, errorMessage: response.errorMsg };
                    }
                }

                // Customized the email rule, cannot taken by user
                if(eachRecord['Email']) {
                    const emailList = userEmailList.find(record =>{
                        return String(eachRecord['Email']).toLowerCase() === record.email.toLowerCase();
                    })
                    if(emailList) {
                        return { status: false, errorMessage: `${`Email inside excel is already taken (` + eachRecord['Email']+ `)`}` };
                    }
                }
            }

            return { status: true };
        }
        return { status: false, errorMessage: `${`Invalid File Contents`}` };
    }

    validateFields(fieldValue, fieldRule, fieldName) {
        const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        const response = {
            hasViolation: false,
            errorMsg: ''
        };
        // Check Required
        if (fieldRule.required) {
            if (!Array.isArray(fieldValue) && !StringUtil.isNotEmptyOrNull(fieldValue)) {
                response.hasViolation = true;
                response.errorMsg = Message.getMessage(Message.MESSAGE.IS_EMPTY.value, fieldName);
                return response;
            }
        }

        // Check Value Data Type and Format
        if (StringUtil.isNotEmptyOrNull(fieldValue)) {
            switch (fieldRule.type.toLowerCase()) {
                case "string": {
                    if(fieldRule.hasOwnProperty("maxLength") && fieldValue.length > fieldRule.maxLength){
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.MAXLENGTH.value, fieldName, fieldRule.maxLength);
                    }
                    break;
                }
                case "email": {
                    if(!emailPattern.test(String(fieldValue).toLowerCase())){
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.INVALID_EMAIL.value, fieldName)
                    }
                    break;
                }
                case "number": {
                    if (!NumberUtil.isNumeric(fieldValue)) {
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.NOT_NUMERIC.value, fieldName);
                    }
                    break;
                }
                case "date":
                case "datetime": {
                    const dateFormat = fieldRule.format || "";
                    if (!this.checkDateFormat(fieldValue, dateFormat)) {
                        response.hasViolation = true;
                        response.errorMsg = Message.getMessage(Message.MESSAGE.INVALID_DATE.value, fieldName, fieldRule.format);
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }

        return response;
    }

    checkDateFormat(dateStr = "", format = "YYYY-MM-DD") {
        if (dateStr !== null && typeof dateStr != 'string') {
            dateStr = moment(dateStr).format(format);
        } else if (dateStr == null || dateStr.trim().length == 0) {
            return false;
        }
        const momDate = moment(dateStr, format, true);

        return momDate.isValid();
    }

    // generate template for download
    generateExcelTemplate() {
        const header = [
            'FirstName',
            'LastName',
            'PhoneNumber',
            'Email',
            'IdTagNumber',
            'NotifyTagId (Leave empty will default 5 minutes)', // Leave empty will default 5 minutes
            'DrivingLicenseNo',
            'DrivingLicenseExpiryDate',
            'ExpiryReminderDay (Leave empty will default 30 days)' // Leave empty will default 30 days
        ];

        const data = [
            ['John', 'Lim Ka Fai', '0123456789', 'john@email.com', 'TAG001', '', 'KL9865893465411', '31/12/2023', ''],
            ['Kelly', 'Wong Mei Kuan', '0133456789', 'kelly@email.com', 'TAG002', '', '', '', '']
        ];
        const excel: any = this.fileExportService.mergeHeaderAndDataForExcel(data, header);
        this.fileExportService.exportExcelFile(excel, "Batch_Driver_Template");
    }

    // batch import process after validation
    async batchCreateDriver() {
        const formattedReqList = [];
        this.batchDriverList.map(eachEntry => {
            const driverObj ={
                firstName: eachEntry.FirstName,
                lastName: eachEntry.LastName,
                telNo: eachEntry.PhoneNumber,
                email: eachEntry.Email,
                idTagNumber: eachEntry.IdTagNumber,
                remindMinute: eachEntry['NotifyTagId (Leave empty will default 5 minutes)'],
                drivingLicenseNo: eachEntry.DrivingLicenseNo,
                drivingLicenseExpiryDate: DateTimeUtil.getDateLabel(eachEntry.DrivingLicenseExpiryDate, "DD/MM/YYYY", "YYYY-MM-DD"),
                licenseExpReminderDays: eachEntry['ExpiryReminderDay (Leave empty will default 30 days)']
            }
            formattedReqList.push(driverObj)
        });
        let errMessage = '';
        let allSuccess = true;
        const result = await this.driverService.createDriver(formattedReqList);
        if (result && result.statusCode !== StatusCode.SUCCESS.code) {
            allSuccess = false;

            errMessage += Message.getMessage(Message.MESSAGE.CREATE_FAILED.value) + ", ";
        }
        let msg = Message.getMessage(Message.MESSAGE.IMPORT_SUCCESS.value, 'Batch Data');
        if (!allSuccess) {
            errMessage = errMessage.substring(0, errMessage.lastIndexOf(", "));
            msg = errMessage;
        }
        await this.getDisplayDriverList();
        this.snackBar.openGenericSnackBar(msg);
    }
}
