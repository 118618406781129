import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../../../environments/environment';

import * as moment from 'moment';
// import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as NumberUtil from '../../../../util/numberUtil';
import * as StringUtil from '../../../../util/stringUtil';
import * as DomUtil from '../../../../util/domUtil';

import { PopupService } from './../../../../components/common/popup/popup.service';
import { MaintenanceService } from './../../../../_services/maintenance/maintenance.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { PagerService } from './../../../../_services/pager/pager.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';

import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { modules } from '../../../../constants/module-access.constant';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-view-maintenance',
    templateUrl: './view-maintenance.component.html',
    styleUrls: ['./view-maintenance.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ViewMaintenanceComponent implements OnInit {
    toggleMobileOpen: boolean = false;

    //HTML use
    message = Message;
    StringUtil = StringUtil;
    NumberUtil = NumberUtil;
    DomUtil = DomUtil;

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    // @ViewChild("tab1_spinner") tab1Spinner: SpinnerComponent;
    // @ViewChild("tab2_spinner") tab2Spinner: SpinnerComponent;
    @ViewChild("markCompleteForm",{static:false}) markCompleteForm: NgForm;
    @ViewChild("markComplete", {static:false, read: ElementRef }) markCompleteEL: ElementRef;

    //date picket touch ui
    datepickerTouchUi: boolean = false;
    lastResizeTime: any = new Date(0);
    resizeTimeout: any;

    moment: any = moment;

    //delete
    isDeletingMaintenance: boolean = false;
    selectedToDelete: any = {};
    selectedMaintenance: any = {};
    selectedMaintenanceGroups: any = {};

    //mark complete
    selectedToMarkComplete: any = {};
    markCompleteDetails = {
        serviceDate: moment().format(),
        odometer: null,
        engineHours: null,
        engineMinutes: null,
        cost: null,
        notes: null
    };
    simulatedNextSchedule = {
        prevServiceDate: null,
        serviceDate: null,
        prevOdometer: null,
        odometer: null,
        prevEngineHours: null,
        engineHours: null,
    };

    // check box
    checkOdometer: boolean = false;
    checkEngineHour: boolean = false;

    //result list
    currentTab: number = 1;
    vehicleMaintenanceByScheduleList: any = [];
    vehicleMaintenanceByVehicleList: any = [];
    sortField = 'Name';
    sortAscending = true;
    filterValueSchedule = '';
    currentFilterValueSchedule = '';
    filterValueVehicle = '';
    currentFilterValueVehicle = '';

    pager: any = {};
    pageRecordSize = environment.appConfig.createManage.pageRecordSize;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;

    constructor(
        private maintenanceService: MaintenanceService,
        private vehicleService: VehicleService,
        private pagerService: PagerService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private route: ActivatedRoute,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService

    ) { }

    async ngOnInit() {
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_MAINTENANCE.value);
        this.creatable = permissions[modules.CRUD_MAINTENANCE.value].cAccess;
        this.editable = permissions[modules.CRUD_MAINTENANCE.value].eAccess;
        this.deletable = permissions[modules.CRUD_MAINTENANCE.value].dAccess;

        if (this.route.snapshot.queryParams.autoSearch == 'true') {
            if (StringUtil.equalsIgnoreCase(this.route.snapshot.queryParams.tab, 'vehicles')) {
                const filterValueVehicle = this.route.snapshot.queryParams.vehicleName || '';
                await this.byVehicles(filterValueVehicle);
                return;
            } else if (StringUtil.equalsIgnoreCase(this.route.snapshot.queryParams.tab, 'schedules')) {
                await this.bySchedule();
                return;
            }
        } else {
            // Reopen tab after navigateSuccess() (not using route reuse strategy)
            const tab = this.routerProxyService.getViewTabFromQueryParam(this.route);
            switch (tab) {
                case 'schedule': await this.bySchedule(); break;
                case 'vehicle': await this.byVehicles(); break;
                default: await this.bySchedule(); // first tab
            }
        }
        this.updateDatepickerTouchUi();

        await this.checkUnviewedCampaign();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async bySchedule(filterValue = '') {
        this.currentTab = 1;
        this.sortField = 'Name'; // For Maintenance By Schedule
        this.sortAscending = true;
        this.filterValueSchedule = filterValue || ''; // Empty Text Box
        this.currentFilterValueSchedule = filterValue || '';
        await this.getVehicleMaintenanceBySchedule();
    }

    async byVehicles(filterValue = '') {
        this.currentTab = 2;
        this.isDeletingMaintenance = false; // Off delete bar
        this.sortField = 'VehicleName'; // For Maintenance By Vehicle
        this.sortAscending = true;
        this.filterValueVehicle = filterValue || ''; // Empty Text Box
        this.currentFilterValueVehicle = filterValue || '';
        await this.getVehicleMaintenanceByVehicle();
    }

    // Start Tab 1
    async getVehicleMaintenanceBySchedule(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Get Vehicle Maintenance By Schedule Page
            const res = await this.maintenanceService.getVehicleMaintenanceBySchedule(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.currentFilterValueSchedule);
            if (res) {
                this.vehicleMaintenanceByScheduleList = res.body.result;
                this.pager = this.pagerService.getPager(res.body.totalRecord, page, this.pageRecordSize);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async searchMaintenanceSchedule() {
        this.currentFilterValueSchedule = this.filterValueSchedule;
        await this.getVehicleMaintenanceBySchedule();
    }
    // End Tab 1

    // Start Tab 2
    async getVehicleMaintenanceByVehicle(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Get Vehicle Maintenance By Vehicle Page
            const res = await this.maintenanceService.getVehicleMaintenanceByVehicle(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.currentFilterValueVehicle);
            if (res) {
                this.vehicleMaintenanceByVehicleList = res.body.result;
                this.pager = this.pagerService.getPager(res.body.totalRecord, page, this.pageRecordSize);

                // parse some values to reduce function data bindings
                this.vehicleMaintenanceByVehicleList.forEach(schedule => {
                    schedule.prevServiceDateLabel = schedule.prevServiceDate !== null ?
                        moment(schedule.prevServiceDate).format('DD/MM/YYYY') : null;

                    schedule.distanceUntilLabel = NumberUtil.formatFloat(schedule.distanceUntil, 1);
                    schedule.daysUntilLabel = schedule.daysUntil || '-';
                    schedule.engineHourUntilLabel = NumberUtil.formatHourMinute(schedule.engineHourUntil, '-');

                    schedule.nextOdometerLabel = NumberUtil.formatFloat(schedule.nextOdometer, 1);
                    schedule.nextServiceDateLabel = schedule.nextServiceDate !== null ?
                        moment(schedule.nextServiceDate).format('DD/MM/YYYY') : null;
                    schedule.nextEngineHoursLabel = NumberUtil.formatHourMinute(schedule.nextEngineHours, '-');

                    const currentEngineHoursHhMm = NumberUtil.formatHourMinute(schedule.currentEngineHours, '');
                    if (currentEngineHoursHhMm.length) {
                        schedule.currentEngineHoursLabel = currentEngineHoursHhMm.split(':')[0];
                        schedule.currentEngineMinutesLabel = currentEngineHoursHhMm.split(':')[1];
                    } else {
                        schedule.currentEngineHoursLabel = '';
                        schedule.currentEngineMinutesLabel = '';
                    }

                    schedule.currentOdometerLabel = NumberUtil.formatFloat(schedule.currentOdometer, 1);

                });
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async searchMaintenanceVehicle() {
        this.currentFilterValueVehicle = this.filterValueVehicle;
        await this.getVehicleMaintenanceByVehicle();
    }
    // End Tab 2

    navigateToCreateMaintenance() {
        this.routerProxyService.navigate(['/create-manage/maintenance/create']);
    }

    navigateToEditMaintenance(maintenanceId) {
        if (!this.isDeletingMaintenance) {
            this.routerProxyService.navigate(['/create-manage/maintenance/edit', maintenanceId]);
        }
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }
        if (this.currentTab === 1) {
            await this.getVehicleMaintenanceBySchedule();
        } else {
            await this.getVehicleMaintenanceByVehicle();
        }
    }

    getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    // countSelectedMaintenance() {
    //     return Object.keys(this.selectedMaintenance).filter(result => this.selectedMaintenance[result] === true).length;
    // }

    cancelResetDelete(closeDeletePanel: boolean = true): void {
        this.isDeletingMaintenance = !closeDeletePanel;
        this.selectedToDelete = {};
        // this.selectedMaintenance = {};
        // this.selectedMaintenanceGroups['all'] = false;
    }

    async deleteMaintenance(maintenanceInfo: any) {

        // reset delete selection
        this.cancelResetDelete(false); //do not close delete panel

        this.selectedToDelete = {
            id: maintenanceInfo.id,
            name: maintenanceInfo.name,
            vehicleCount: maintenanceInfo.vehicleCount
        };
    }

    async confirmDeleteMaintenance() {
        this.pageSpinner.show();
        try {
            //delete
            let result = null;
            result = await this.maintenanceService.deleteMaintenanceAndSchedule(this.selectedToDelete.id);
            // console.debug(this.selectedToDelete);

            //Show Snackbar
            let msg = Message.getMessage(Message.MESSAGE.DELETE_ONE_FAILED.value, 'Maintenance', this.selectedToDelete.name);
            if (result) {
                if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.DELETE_ONE_SUCCESS.value, 'Maintenance', this.selectedToDelete.name);
                } else if (result.statusCode == ResponseStatusCode.PARTIAL.code) {
                    msg = Message.getMessage(Message.MESSAGE.DELETE_ONE_PARTIAL.value, 'Maintenance', this.selectedToDelete.name);
                }
            }
            this.snackBar.openGenericSnackBar(msg);

            // retrieve updated result
            await this.getVehicleMaintenanceBySchedule();

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async markMaintenanceComplete(scheduleItem) {

        // reset popup fields
        this.resetMarkCompleteForm();

        this.selectedToMarkComplete = scheduleItem;
        // console.debug(scheduleItem);
    }

    async simulateSchedule() {

        //service date
        const recurringDurationMonth = this.selectedToMarkComplete.recurringDurationMonth;
        this.simulatedNextSchedule.prevServiceDate = moment(this.markCompleteDetails.serviceDate);
        this.simulatedNextSchedule.serviceDate = recurringDurationMonth ? (moment(this.simulatedNextSchedule.prevServiceDate).add(parseInt(recurringDurationMonth), 'months').format('DD/MM/YYYY')) : '-';

        //odometer
        const recurringMileage = this.selectedToMarkComplete.recurringMileage;
        this.simulatedNextSchedule.prevOdometer = NumberUtil.roundOff(parseFloat(this.markCompleteDetails.odometer), 1);
        this.simulatedNextSchedule.odometer = recurringMileage ?
            NumberUtil.roundOff(parseFloat(this.simulatedNextSchedule.prevOdometer) +
                parseFloat(recurringMileage), 1) : '-';

        //engine hours
        const recurringEngineHours = this.selectedToMarkComplete.recurringEngineHours;
        if (recurringEngineHours) {
            //NumberUtil.isInteger(this.markCompleteDetails.engineHours) ||
            //NumberUtil.isInteger(this.markCompleteDetails.engineMinutes)
            const hh = this.markCompleteDetails.engineHours || 0;
            const mm = this.markCompleteDetails.engineMinutes || 0;
            this.simulatedNextSchedule.prevEngineHours = NumberUtil.roundOff(parseFloat(hh) + (parseFloat(mm) / 60), 4);
            this.simulatedNextSchedule.engineHours = recurringEngineHours ?
                NumberUtil.formatHourMinute(parseFloat(this.simulatedNextSchedule.prevEngineHours) +
                    parseFloat(recurringEngineHours), '-') : '-';
        } else {
            this.simulatedNextSchedule.prevEngineHours = null;
            this.simulatedNextSchedule.engineHours = '-';
        }

    }

    resetMarkCompleteForm(): void {
        this.markCompleteForm.reset();
        this.markCompleteDetails.serviceDate = moment().format();
        this.simulatedNextSchedule = {
            prevServiceDate: null,
            serviceDate: null,
            prevOdometer: null,
            odometer: null,
            prevEngineHours: null,
            engineHours: null,
        };
    }

    async confirmMarkMaintenanceComplete() {
        const updateVehicleObj = {};

        this.pageSpinner.show();
        try {
            //some validations
            if (this.selectedToMarkComplete.recurringEngineHours
                && !NumberUtil.isNumeric(this.simulatedNextSchedule.prevEngineHours)) {
                this.pageSpinner.hide();
                this.snackBar.openGenericSnackBar('Engine Hours is mandatory');
                return;
            }

            //mark
            const markParams = {
                maintenanceScheduleId: this.selectedToMarkComplete.id,
                serviceDate: this.simulatedNextSchedule.prevServiceDate.format('YYYY-MM-DD'),
                odometer: this.simulatedNextSchedule.prevOdometer,
                engineHours: this.simulatedNextSchedule.prevEngineHours,
                cost: this.markCompleteDetails.cost || '',
                notes: this.markCompleteDetails.notes || ''
            };

            if (this.checkOdometer) {
                Object.assign(updateVehicleObj, { currentOdometer: markParams.odometer });
            }
            if (this.checkEngineHour) {
                Object.assign(updateVehicleObj, { engineHour: markParams.engineHours });
            }
            let result = null;
            result = await this.maintenanceService.markMaintenanceComplete(markParams);

            //Update Vehicle Info
            if (Object.getOwnPropertyNames(updateVehicleObj).length > 0) {
                const tempVehicleObj = {
                    vehicleId: this.selectedToMarkComplete.vehicleId,
                    vehicleName: this.selectedToMarkComplete.vehicleName,
                    vehiclePlateNo: this.selectedToMarkComplete.vehiclePlateNo,
                    imeiId: this.selectedToMarkComplete.imeiId,
                    imeiNo: this.selectedToMarkComplete.imeiNo
                };
                Object.assign(updateVehicleObj, tempVehicleObj);
                await this.vehicleService.updateVehicleInfoDetails(this.selectedToMarkComplete.vehicleId, updateVehicleObj);
            }

            //Show Snackbar
            let msg = Message.getMessage(Message.MESSAGE.MARK_MAINTENANCE_FAILED.value, this.selectedToMarkComplete.maintenanceName, this.selectedToMarkComplete.vehicleName);
            if (result) {
                if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.MARK_MAINTENANCE_SUCCESS.value, this.selectedToMarkComplete.maintenanceName, this.selectedToMarkComplete.vehicleName);
                } else if (result.statusCode == ResponseStatusCode.PARTIAL.code) {
                    msg = Message.getMessage(Message.MESSAGE.MARK_MAINTENANCE_PARTIAL.value, this.selectedToMarkComplete.maintenanceName, this.selectedToMarkComplete.vehicleName);
                }
            }
            this.snackBar.openGenericSnackBar(msg);

            // retrieve updated result
            await this.getVehicleMaintenanceByVehicle();

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    // onMaintenanceAllSelectChange() {
    //     let allIsSelected = this.selectedMaintenanceGroups.all;
    //     for (let j = 0; j < this.vehicleMaintenanceByScheduleList.length; j++) {
    //         this.selectedMaintenance[this.vehicleMaintenanceByScheduleList[j].id] = allIsSelected;
    //     }
    // }

    // onMaintenanceSelectChange(maintenanceSelected: boolean) {
    //     if (maintenanceSelected) {
    //         this.checkAllMaintenanceAreSelected();
    //     } else {
    //         this.selectedMaintenanceGroups.all = false;
    //     }
    // }

    // checkAllMaintenanceAreSelected() {
    //     let allIsSelected = true;
    //     outerLoop:
    //     for (let k = 0; k < this.vehicleMaintenanceByScheduleList.length; k++) {
    //         if (!this.selectedMaintenance[this.vehicleMaintenanceByScheduleList[k].id]) {
    //             allIsSelected = false;
    //             break outerLoop;
    //         }
    //     }
    //     this.selectedMaintenanceGroups.all = allIsSelected;
    // }

    /* ---- popup table ---- */

    showPopup(popup, popupName = null) {
        this.popupService.show(popup);
        // console.log(popupName);
        // if(popupName == 'markComplete') {
        //     console.log(this.markCompleteEL);
        //     console.log(this.el);
        //     this.markCompleteEL.nativeElement.querySelector('input').focus();
        // }
    }
    hidePopup() {
        this.popupService.hide();
    }

    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }

    updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

}
