import { Component, Injectable, OnInit, ViewChild } from '@angular/core';

import * as AsyncUtil from '../../../util/asyncUtil';

import { PopupService } from '../../common/popup/popup.service';
import { SnackBarService } from './../../../_services/snackBar/snack-bar.service';
import * as DomUtil from '../../../util/domUtil';
import { Platform } from '@ionic/angular';
import { RouterProxyService } from '../../../_services/router-proxy/router-proxy.service';
import { environment } from '../../../../environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Browser } from '@capacitor/browser';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
    selector: 'app-force-update',
    templateUrl: './app-force-update.component.html',
    styleUrls: ['./app-force-update.component.scss'],
})
@Injectable({
    providedIn: 'root'
})
export class AppForceUpdateComponent implements OnInit {

    @ViewChild("updateConfirmationPopup", {static: false})
    private updateConfirmationPopup: any;

    constructor(
        private platform: Platform,
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private appVersion: AppVersion,
        private firestore: AngularFirestore
    ) { }

    async ngOnInit() {
        //do nothing
    }

    public show() {
        //show update popup
        this.showPopup(this.updateConfirmationPopup);
    }

    public async checkUpdate(): Promise<void> {

        const self = this;
        const documentId = 'force_update';
        const collection = 'version';
        
        
        let firebaseDoc = this.firestore.doc(`${collection}/${documentId}`).valueChanges();
        firebaseDoc.subscribe(async(firebaseResult: any)=>{
            console.debug("FIRESTORE :", JSON.stringify(firebaseResult));
            firebaseResult = JSON.parse(JSON.stringify(firebaseResult));
            let currentVersion;
            await self.appVersion.getVersionNumber().then((ver) => {
                    currentVersion = ver.replace('.', '');
            });
            const latestVersion = firebaseResult.latestVersion.replace('.', '');
            const minimumFunctionalVersion = firebaseResult.minimumFunctionalVersion.replace('.', '');

            if (firebaseResult.required) {
                if (Number(latestVersion) > Number(currentVersion)
                    || Number(minimumFunctionalVersion) > Number(currentVersion)) {
                    self.show();
                }
            }
        });

    }

    async confirmUpdate() {

        try {
            if (DomUtil.isMobile(this.platform, 'android')) {
                this.openInPlayStore();
            } else if (DomUtil.isMobile(this.platform, 'ios')) {
                this.openInAppleStore();
            } else {
                this.routerProxyService.redirectExternalUrl('https://mdrive.my');
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    openInPlayStore() {
        const actualLink = environment.appConfig.appDownload.android;
        const packageLink = actualLink.replace(/http(s)?:\/\/play\.google\.com\/store\/apps\//, ''); // details?id=my.com.maxis.mdrive

        this.routerProxyService.redirectExternalUrl(
            // 1. Use "market://"
            `market://${packageLink}`,

            // 2. Google Play App shorthand
            `https://play.app.goo.gl/?link=https://play.google.com/store/apps/${packageLink}`,

            // 3. Normal URL
            actualLink
        );
    }

    openInAppleStore() {
        const actualLink = environment.appConfig.appDownload.ios;
        Browser.open({url:actualLink});

        /*
        this.routerProxyService.redirectExternalUrl(
            // 1. Use "itms-appss://"
            actualLink.replace(/http(s)?:\/\//, 'itms-appss://'),

            // 2. Normal URL
            actualLink
        );
        */
    }

    /* ---- popup table ---- */
    showPopup(popup) {
        this.popupService.show(popup);
    }

}
