import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';

import { PopupService } from './../../../../components/common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { PagerService } from './../../../../_services/pager/pager.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { FuelService } from './../../../../_services/fuel/fuel.service';
import { FileExportService } from '../../../../_services/file-export/file-export.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';
import { EmailService } from './../../../../_services/email/email.service';

import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as StringUtil from '../../../../util/stringUtil';
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as NumberUtil from '../../../../util/numberUtil';
import * as ExcelUtil from '../../../../util/excelUtil';

import * as Message from '../../../../constants/message';
import * as FuelType from './../../../../constants/fuelType.constant';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { modules } from '../../../../constants/module-access.constant';
import * as FuelTempConst from './../../../../constants/fuelTemplateType.constant';

@Component({
    selector: 'app-view-fuel',
    templateUrl: './view-fuel.component.html',
    styleUrls: ['./view-fuel.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ViewFuelComponent implements OnInit {
    fuelSelections = [
        {
            "label": "Shell",
            "display": true
        }, {
            "label": "Template",
            "display": false
        }
    ];

    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    currentTab: number = 1;
    // currentTitle: Array<string> = ["Fuel", "Fuel"];

    vehiclesFuelLimitList: any = [];
    sortField = 'VehicleName';
    sortAscending = true;
    filterKey = 'VehicleName';
    filterValue = '';
    currentFilterValue = '';
    pager: any = {};
    pageRecordSize = environment.appConfig.createManage.pageRecordSize;

    /* ----- Fuel KPI ----- */
    // Fuel KPI Selected
    isDeletingFuelLimit: boolean;
    selectedFuelLimit: any = {};
    selectedFuelLimitGroups: any = {};

    // Fuel Update
    currentSelectedVehicleName: string;
    currentSelectedVehicleId: number;
    fuelCostLimit: number;
    fuelUsedLimit: number;
    fuelEfficiencyLimit: number;
    fuelDropThreshold: number;
    fuelRefuelThreshold: number;

    message = Message;
    moment = moment;

    /* ----- Refuel Entry ----- */
    isDeletingRefuelEntry: boolean;
    selectedRefuelEntry: any = {};
    selectedRefuelEntryGroups: any = {};

    // fuelType = FuelType;

    timeSelector: {
        hour: string[],
        fullHour: string[],
        minute: string[],
        second: string[]
    } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array
    fuelTypeList = [];
    fuelType = FuelType;

    vehiclesRefuelEntryList = [];
    fuelDateVar: string = '';
    fuelTimeHh: string = '';
    fuelTimeMm: string = '';
    fuelTimeA: string = '';
    currentVehicleRefuelEntryObj = {
        fuelId: null,
        vehicleId: null,
        vehicleName: null,
        vehiclePlateNo: null,
        fuelType: '',
        fuelQuantity: null,
        fuelCost: null,
        location: null,
        refuelDate: null,
        odometer: null
    };
    vehicleInfoList = [];
    selectedVehicleMessage: string;

    //date picket touch ui
    datepickerTouchUi: boolean = false;
    lastResizeTime: any = new Date(0);
    resizeTimeout: any;

    @ViewChild('batchRefuelErrorPopup',{static:false}) batchRefuelErrorPopup;
    @ViewChild('batchRefuelConfirmPopup',{static:false}) batchRefuelConfirmPopup;
    @ViewChild('batchImportPreviewPopup',{static:false}) batchImportPreviewPopup;
    // Batch Fuel Import
    fuelTempConst = FuelTempConst;
    fuelTemplateType: Array<any> = this.fuelTempConst.getFuelTypeList(); // type of template have in mDrive 2.0
    selectFuelTypeId: number = -1; // selected template's id
    enableUpload: boolean = false;
    browseBtnColor: string = 'grey';

    selectedFuelBrand: string = ''; // selected fuel brand (Shell,Cartex,BHP,Petronas...)
    selectedBrandLabel: string = '';
    selectedBrandHeaderList: Array<string> = [];
    dbHeaderList: Array<string> = [];
    maxHeaderRowCheck: number = 50;
    supportfileType: Array<string> = ["xlsx", "xls", "csv"];
    batchFileSize: number = environment.appConfig.createManage.batchFileSize;

    vehicleList: any = []; // all available vehicleList
    file: File; // uploaded file
    arrayBuffer: any; // uploaded file that coverted into array buffer
    oriWorkbook: any = null;
    oriRefRange: string = '';
    worksheet: any = [];

    oriHeaderRefRange: string = '';
    batchRefuelList: any = []; // Sheet to Json Result
    batchRefuelCount: number = 0; // Total Records provided in excel file
    batchErrorMessage = null; // Show any error while excel file validation is running
    fileName: String = ''; // Uploaded file name
    totalRefuelRecords: number = 0;
    isDownloadDone: boolean = false; // confirm client has download invalid file
    refuelRecordsHasError: boolean = false;
    validRefuelList: any = []; // All valid fuel entries
    invalidRefuelList: any = []; // All invalid fuel entries
    refuelErrorList: any = []; // All remark on each invalid fuel entries

    isCollectTemplateRequired: boolean = true;
    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;
    tmpTemplateEvent: any;

    constructor(
        private vehicleService: VehicleService,
        private fuelService: FuelService,
        private pagerService: PagerService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private route: ActivatedRoute,
        private routerProxyService: RouterProxyService,
        private fileExportService: FileExportService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService,
        private emailService: EmailService

    ) { }

    async ngOnInit() {
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_FUEL.value);
        this.creatable = permissions[modules.CRUD_FUEL.value].cAccess;
        this.editable = permissions[modules.CRUD_FUEL.value].eAccess;
        this.deletable = permissions[modules.CRUD_FUEL.value].dAccess;

        this.fuelTypeList = await FuelType.getFuelTypeList();
        if (this.route.snapshot.queryParams.autoSearch === 'true') {
            if (StringUtil.equalsIgnoreCase(this.route.snapshot.queryParams.tab, 'fuel')) {
                // await this.geofenceDrivingLimits(this.route.snapshot.queryParams.geofenceName);
                await this.generalFuelLimits(this.route.snapshot.queryParams.vehicleName);
                return;
            } else if (StringUtil.equalsIgnoreCase(this.route.snapshot.queryParams.tab, 'refuel')) {
                // await this.generalDrivingLimits(this.route.snapshot.queryParams.vehicleName);
                await this.generalRefuelEntry(this.route.snapshot.queryParams.vehicleName);
                return;
            }
        } else {
            // const isFromFuelLimitPage = this.route.snapshot.paramMap.get('fromSetFuelLimit');
            // if (isFromFuelLimitPage) {
            //     await this.generalFuelLimits();
            // } else {
            //     await this.generalRefuelEntry();
            // }
            // Reopen tab after navigateSuccess() (not using route reuse strategy)
            const tab = this.routerProxyService.getViewTabFromQueryParam(this.route);
            switch (tab) {
                case 'refuel-entry': await this.generalRefuelEntry(); break;
                case 'fuel-kpi': await this.generalFuelLimits(); break;
                default: await this.generalRefuelEntry(); // first tab
            }
        }
        await this.checkUnviewedCampaign();
        this.clearVehicleSelection();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /*--- Fuel KPI Methods ---*/
    // async fuel() {
    //     this.currentTab = 2;
    //     this.sortField = 'Name';
    //     this.filterKey = 'Name';
    //     await this.getVehiclesFuelLimit();
    // }

    async generalFuelLimits(filterValue = '') {
        this.currentTab = 2;
        this.sortField = 'Name';
        this.filterKey = 'Name';
        this.sortAscending = true;
        this.filterValue = filterValue || ''; // Empty Text Box
        this.currentFilterValue = filterValue || '';
        await this.getVehiclesFuelLimit();
    }

    navigateToSetFuelLimit() {
        this.routerProxyService.navigate(['/create-manage/fuel/set-vehicle-kpi']);
    }

    navigateToCreateRefuelEntry() {
        this.routerProxyService.navigate(['/create-manage/fuel/create-refuel-entry']);
    }

    async searchVehicleName() {
        this.currentFilterValue = this.filterValue;
        this.currentTab === 2 ? await this.getVehiclesFuelLimit() : await this.getVehiclesRefuelEntry();
    }

    async getVehiclesFuelLimit(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            const vehicleFuelLimitResult = await this.vehicleService.getVehiclesFuelLimit(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            if (vehicleFuelLimitResult) {
                this.vehiclesFuelLimitList = vehicleFuelLimitResult.body.result;
                this.pager = this.pagerService.getPager(vehicleFuelLimitResult.body.totalRecord, page, this.pageRecordSize);
                // parse some values to reduce function data bindings
                // this.vehiclesFuelLimitList.forEach(limit => {
                //     limit.fuelCostLimitLabel = NumberUtil.formatFloat(limit.fuelCostLimit, 1);
                //     limit.fuelUsedLimitLabel = NumberUtil.formatFloat(limit.fuelUsedLimit, 1);
                // });
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async updateFuelLimit() {
        this.pageSpinner.show();
        try {
            this.fuelCostLimit = (this.fuelCostLimit == null) ? 0 : Number(this.fuelCostLimit);
            this.fuelUsedLimit = (this.fuelUsedLimit == null) ? 0 : Number(this.fuelUsedLimit);
            this.fuelEfficiencyLimit = (this.fuelEfficiencyLimit == null) ? 0 : Number(this.fuelEfficiencyLimit);
            this.fuelRefuelThreshold = (this.fuelRefuelThreshold == null) ? 0 : Number(this.fuelRefuelThreshold);
            this.fuelDropThreshold = (this.fuelDropThreshold == null) ? 0 : Number(this.fuelDropThreshold);

            const result = await this.vehicleService.updateVehiclesFuelLimit(this.fuelCostLimit, this.fuelUsedLimit, this.fuelEfficiencyLimit, this.fuelDropThreshold, this.fuelRefuelThreshold, [this.currentSelectedVehicleId]);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'KPI', this.currentSelectedVehicleName);
            if (result && result.statusCode == ResponseStatusCode.PARTIAL.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_PARTIAL.value, 'KPI', this.currentSelectedVehicleName);
            } else if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'KPI', this.currentSelectedVehicleName);
            }

            this.snackBar.openGenericSnackBar(msg);
            this.clearVehicleSelection();
            await this.getVehiclesFuelLimit(1);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async deleteFuelLimit() {
        this.pageSpinner.show();
        try {
            const selectedFuelLimitList = [];
            for (const key in this.selectedFuelLimit) {
                if (this.selectedFuelLimit[key] === true) {
                    selectedFuelLimitList.push(parseInt(key));
                }
            }

            const result = await this.vehicleService.deleteVehicleFuelLimit(selectedFuelLimitList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'KPI');
            if (result && (result.statusCode == ResponseStatusCode.SUCCESS.code || result.statusCode == ResponseStatusCode.PARTIAL.code)) {
                if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'KPI');
                } else {
                    msg = Message.getMessage(Message.MESSAGE.UPDATE_PARTIAL.value, 'KPI');
                }

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getVehiclesFuelLimit();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    onFuelLimitAllSelectChange() {
        const allIsSelected = this.selectedFuelLimitGroups.all;
        for (let i = 0; i < this.vehiclesFuelLimitList.length; i++) {
            const fuelLimitModel = this.vehiclesFuelLimitList[i];
            this.selectedFuelLimit[fuelLimitModel.vehicleId] = allIsSelected;
        }
    }

    onFuelLimitSelectChange(fuelLimitSelected: boolean) {
        if (fuelLimitSelected) {
            // this.selectedFuelLimit[fuelLimitModel.vehicleId] = true;
        } else {
            this.selectedFuelLimitGroups['all'] = false;
        }
    }

    onVehicleClick(vehicleId, vehicleName) {
        // Set Value
        this.currentSelectedVehicleId = vehicleId;
        this.currentSelectedVehicleName = vehicleName;
        this.fuelCostLimit = this.vehiclesFuelLimitList.filter(result => result.vehicleId === vehicleId)[0].fuelCostLimit;
        this.fuelUsedLimit = this.vehiclesFuelLimitList.filter(result => result.vehicleId === vehicleId)[0].fuelUsedLimit;
        this.fuelEfficiencyLimit = this.vehiclesFuelLimitList.filter(result => result.vehicleId === vehicleId)[0].fuelEfficiencyLimit;
        this.fuelRefuelThreshold = this.vehiclesFuelLimitList.filter(result => result.vehicleId === vehicleId)[0].fuelSensorRefuelThreshold;
        this.fuelDropThreshold = this.vehiclesFuelLimitList.filter(result => result.vehicleId === vehicleId)[0].fuelSensorUnexpectDropThreshold;
    }

    clearVehicleSelection() {
        this.currentSelectedVehicleId = null;
        this.currentSelectedVehicleName = null;
        this.fuelCostLimit = null;
        this.fuelUsedLimit = null;
        this.fuelEfficiencyLimit = null;
    }

    countSelectedFuelLimit() {
        return Object.keys(this.selectedFuelLimit).filter(result => this.selectedFuelLimit[result] === true).length;
    }
    /*--- End of Fuel KPI Methods ---*/

    /*--- Refuel Entry --- */
    async generalRefuelEntry(filterValue = '') {
        this.currentTab = 1;
        this.sortField = 'VehicleName';
        this.filterKey = 'VehicleName';
        this.sortAscending = true;
        this.filterValue = filterValue || ''; // Empty Text Box
        this.currentFilterValue = filterValue || '';
        await this.getVehiclesRefuelEntry();
    }

    onRefuelEntryAllSelectChange() {
        const allIsSelected = this.selectedRefuelEntryGroups.all;
        for (let i = 0; i < this.vehiclesRefuelEntryList.length; i++) {
            const refuelEntryModel = this.vehiclesRefuelEntryList[i];
            this.selectedRefuelEntry[refuelEntryModel.FuelId] = allIsSelected;
        }
    }

    onRefuelEntrySelectChange(refuelEntrySelected: boolean) {
        if (refuelEntrySelected) {
        } else {
            this.selectedRefuelEntryGroups['all'] = false;
        }
    }

    onVehicleRefuelClick(fuelId) {
        // Set Object to Empty
        for (const item in this.currentVehicleRefuelEntryObj) {
            if (Object.prototype.hasOwnProperty.call(this.currentVehicleRefuelEntryObj, item)) {
                this.currentVehicleRefuelEntryObj[item] = null;
            }
        }
        // Set Value
        const currentVehicleRefuelEntry = this.vehiclesRefuelEntryList.find(result => result.FuelId === fuelId);
        this.currentVehicleRefuelEntryObj.fuelId = currentVehicleRefuelEntry.FuelId;
        this.currentVehicleRefuelEntryObj.vehicleId = currentVehicleRefuelEntry.VehicleId;
        this.currentVehicleRefuelEntryObj.vehiclePlateNo = currentVehicleRefuelEntry.VehiclePlateNo;
        this.currentVehicleRefuelEntryObj.vehicleName = currentVehicleRefuelEntry.VehicleName;
        const fuelTypeIsInDropdown: boolean = FuelType.isValidFuelType(currentVehicleRefuelEntry.FuelType);
        this.currentVehicleRefuelEntryObj.fuelType = fuelTypeIsInDropdown ? currentVehicleRefuelEntry.FuelType : 'Others';
        this.currentVehicleRefuelEntryObj.fuelQuantity = currentVehicleRefuelEntry.FuelQuantity;
        this.currentVehicleRefuelEntryObj.fuelCost = currentVehicleRefuelEntry.FuelCost;
        this.currentVehicleRefuelEntryObj.location = currentVehicleRefuelEntry.Location;
        this.currentVehicleRefuelEntryObj.refuelDate = currentVehicleRefuelEntry.RefuelDate;
        this.currentVehicleRefuelEntryObj.odometer = currentVehicleRefuelEntry.Odometer;
        this.fuelDateVar = moment(currentVehicleRefuelEntry.RefuelDate).format("YYYY-MM-DD");
        this.fuelTimeHh = moment(currentVehicleRefuelEntry.RefuelDate).format("hh");
        this.fuelTimeMm = moment(currentVehicleRefuelEntry.RefuelDate).format("mm");
        this.fuelTimeA = moment(currentVehicleRefuelEntry.RefuelDate).format("A");
    }

    countSelectedRefuelEntry() {
        return Object.keys(this.selectedRefuelEntry).filter(result => this.selectedRefuelEntry[result] === true).length;
    }

    async getVehiclesRefuelEntry(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            const vehicleRefuelEntryResult = await this.fuelService.getVehicleRefuelEntry(null, null, this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            if (vehicleRefuelEntryResult) {
                this.vehiclesRefuelEntryList = vehicleRefuelEntryResult.body.result;
                this.pager = this.pagerService.getPager(vehicleRefuelEntryResult.body.totalRecord, page, this.pageRecordSize);

                // parse some values to reduce function data bindings
                this.vehiclesRefuelEntryList.forEach(entry => {
                    entry.FuelDateLabel = entry.RefuelDate ?
                        moment(entry.RefuelDate).format('DD/MM/YYYY hh:mmA') : null;
                    entry.FuelQuantityLabel = NumberUtil.formatFloat(entry.FuelQuantity, 1, '0.0');
                    entry.FuelCostLabel = NumberUtil.formatFloat(entry.FuelCost, 2, '0.00');
                    entry.Odometer = NumberUtil.formatFloat(entry.Odometer, 1, '-');
                });
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async updateVehicleRefuelEntry() {
        this.pageSpinner.show();
        try {
            this.currentVehicleRefuelEntryObj.fuelCost = NumberUtil.numberOrNull(NumberUtil.formatFloat(this.currentVehicleRefuelEntryObj.fuelCost, 2, '0'));
            // parseFloat(parseFloat(this.currentVehicleRefuelEntryObj.fuelCost).toFixed(2));
            this.currentVehicleRefuelEntryObj.fuelQuantity = NumberUtil.numberOrNull(NumberUtil.formatFloat(this.currentVehicleRefuelEntryObj.fuelQuantity, 2, '0'));
            // parseFloat(parseFloat(this.currentVehicleRefuelEntryObj.fuelQuantity).toFixed(2));
            this.currentVehicleRefuelEntryObj.odometer = NumberUtil.numberOrNull(NumberUtil.formatFloat(this.currentVehicleRefuelEntryObj.odometer, 1, '0'));

            const fuelDateOnlyMom = moment(this.fuelDateVar);
            const fuelDate = `${fuelDateOnlyMom.format('YYYY-MM-DD')} ${this.fuelTimeHh}:${this.fuelTimeMm} ${this.fuelTimeA}`;
            this.currentVehicleRefuelEntryObj.refuelDate = fuelDate ? moment(fuelDate, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss") : null;

            const result = await this.fuelService.updateVehicleFuel(this.currentVehicleRefuelEntryObj.fuelId, this.currentVehicleRefuelEntryObj.fuelType, this.currentVehicleRefuelEntryObj.fuelCost, this.currentVehicleRefuelEntryObj.fuelQuantity, this.currentVehicleRefuelEntryObj.location, this.currentVehicleRefuelEntryObj.refuelDate, this.currentVehicleRefuelEntryObj.odometer);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Refuel Entry', this.currentVehicleRefuelEntryObj.vehicleName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Refuel Entry', this.currentVehicleRefuelEntryObj.vehicleName);
                // retrieve updated result
                await this.getVehiclesRefuelEntry();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async deleteRefuelEntry() {
        this.pageSpinner.show();
        try {
            const selectedRefuelEntryList = [];
            for (const key in this.selectedRefuelEntry) {
                if (this.selectedRefuelEntry[key] === true) {
                    selectedRefuelEntryList.push(parseInt(key));
                }
            }

            const result = await this.fuelService.deleteVehicleFuel(selectedRefuelEntryList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Refuel Entry');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Refuel Entry');

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getVehiclesRefuelEntry();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }

    updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

    // onBatchFileUpload
    async batchImportRefuelEntry(event) {
        this.fileName = event.target.files[0].name;
        const currFileType = this.fileName.substring(this.fileName.lastIndexOf('.') + 1);

        // Check File Type
        if (event.target.files.length > 0 && this.supportfileType.find(result => this.fileName.includes(result))) {
            let fileSize = event.target.files[0].size;
            // this.fileName = event.target.files[0].name;
            this.isDownloadDone = false;
            this.refuelRecordsHasError = false;
            this.validRefuelList = [];
            this.invalidRefuelList = [];
            fileSize = fileSize / 1024 / 1024;
            if (fileSize > this.batchFileSize) {
                // file size too large
                this.batchErrorMessage = Message.getMessage(Message.MESSAGE.FILE_SIZE_TOO_LARGE.value, this.batchFileSize, fileSize.toFixed(1));
                this.showPopup(this.batchRefuelErrorPopup);
            } else {
                this.file = event.target.files[0];
                const fileInput: any = document.getElementById('myFileInput');
                fileInput.value = null; // Clear previous file input data
                const vehiclesResponse = await this.vehicleService.getVehicleInfosByInfoRange("BASIC");
                // console.log(vehiclesResponse);
                if (vehiclesResponse) {
                    this.vehicleList = vehiclesResponse.body;
                }
                this.convertExcelToJson();
            }
        } else {
            const supportedFileType = this.supportfileType.join(',');
            this.batchErrorMessage = Message.getMessage(Message.MESSAGE.INCORRECT_FILE_FORMAT_TYPE.value, supportedFileType, currFileType);
            this.showPopup(this.batchRefuelErrorPopup);
        }
    }

    async tmpStoreUploadedTemplate(event) {
        this.tmpTemplateEvent = event; //tmp store event to set value at next function
    }

    async removeTmpUploadedTemplate() {
        const fileInput: any = this.tmpTemplateEvent.target;
        fileInput.value = null; // Clear previous file input data
        this.tmpTemplateEvent = null;
    }

    async uploadBatchTemplate() {
        const fileName = this.tmpTemplateEvent.target.files[0].name;
        const file = this.tmpTemplateEvent.target.files[0];
        const currFileType = this.fileName.substring(this.fileName.lastIndexOf('.') + 1);

        // Check File Type
        if (this.tmpTemplateEvent.target.files.length > 0 && this.supportfileType.find(result => fileName.includes(result))) {
            let fileSize = file.size;
            this.isDownloadDone = false;
            this.refuelRecordsHasError = false;
            this.validRefuelList = [];
            this.invalidRefuelList = [];
            fileSize = fileSize / 1024 / 1024;
            if (fileSize > this.batchFileSize) {
                // file size too large
                this.batchErrorMessage = Message.getMessage(Message.MESSAGE.FILE_SIZE_TOO_LARGE.value, this.batchFileSize, fileSize.toFixed(1));
                this.showPopup(this.batchRefuelErrorPopup);
            } else {
                const fileInput: any = this.tmpTemplateEvent.target;
                fileInput.value = null; // Clear previous file input data
                this.tmpTemplateEvent = null; //Clear previous file event target
                const reader = new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = async (e) => {
                        resolve(fileReader.result);
                    };
                });
                const base64 = await reader;
                this.emailService.sendEmailViaPostfixWithAttachment('Batch Import Template', 'User Uploaded Template. <br/>', fileName, base64);
            }
        } else {
            const supportedFileType = this.supportfileType.join(',');
            this.batchErrorMessage = Message.getMessage(Message.MESSAGE.INCORRECT_FILE_FORMAT_TYPE.value, supportedFileType, currFileType);
            this.showPopup(this.batchRefuelErrorPopup);
        }
    }

    /**
     * Used to convert File -> bstr format
     */
    async getBstrValue() {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(this.file);
            let bstr: any = null;
            fileReader.onload = async (e) => {
                this.arrayBuffer = fileReader.result;
                const data = new Uint8Array(this.arrayBuffer);
                const arr = new Array();
                for (let i = 0; i != data.length; i++) {
                    arr[i] = String.fromCharCode(data[i]);
                }
                bstr = arr.join("");
                resolve(bstr);
            };
        });
    }

    /**
     * Convert Excel File to Json Object
     */
    async convertExcelToJson() {
        // console.log("Begin to Convert Excel File to Json Format");
        if (!StringUtil.isNotEmptyOrNull(this.selectedFuelBrand)) {
            // console.log("Select Type is Empty Or Null");
            this.batchErrorMessage = Message.getMessage(Message.MESSAGE.INVALID_TEMPLATE_TYPE.value);
            this.showPopup(this.batchRefuelErrorPopup);
            return;
        }
        // convert excel file into bstr format
        const bstr = await this.getBstrValue();
        // Get Excel Content(Include Header);
        this.oriWorkbook = XLSX.read(bstr, { type: "binary", cellDates: true, cellNF: false, cellText: false });
        this.worksheet = this.oriWorkbook.Sheets[this.oriWorkbook.SheetNames[0]];
        this.oriRefRange = this.worksheet['!ref'];
        // let oriHeaderRefRange = '';
        // Get template file informations
        /**
         * toDo:
         * 1) support multiple version of same fuel brand
         */
        // const versionList = FuelTempConst.getFuelTemplateTypeVersions(this.selectedFuelBrand);
        this.selectedBrandLabel = FuelTempConst.getFuelTemplateTypeLabel(this.selectedFuelBrand);
        let headerRowNum = 0; //FuelTempConst.getFuelTemplateTypeExcludeRowNum(this.selectedFuelBrand) + 1;
        this.selectedBrandHeaderList = FuelTempConst.getFuelTemplateTypeColumns(this.selectedFuelBrand);
        this.dbHeaderList = FuelTempConst.getFuelTemplateTypeRepColumnList(this.selectedFuelBrand);

        // Find Header Row Number
        const wbHeaderChk = XLSX.read(bstr, { type: "binary", sheetRows: this.maxHeaderRowCheck });
        const wsHeaderChk = wbHeaderChk.Sheets[wbHeaderChk.SheetNames[0]];
        const headerResult = await ExcelUtil.findHeaderRowNum(wsHeaderChk, this.selectedBrandHeaderList[0], this.selectedBrandLabel);
        // console.log(headerResult);
        let actContentRefRange = null;
        if (StringUtil.equalsIgnoreCase(headerResult.status, 'SUCCESS')) {
            // console.log("Header Row Number Found: " + headerResult.rowNum);
            // Modified to Built only header work sheet(worksheetHeader)
            this.worksheet['!ref'] = headerResult.new_excla_ref;
            headerRowNum = headerResult.rowNum;
            // get initial ~ header range
            this.oriHeaderRefRange = ExcelUtil.delLastXRows(this.oriRefRange, headerRowNum);
            // get header ~ all data range
            actContentRefRange = ExcelUtil.delFirstXRows(this.oriRefRange, headerRowNum);
        } else {
            // console.log("Failed to Locate Header Row");
            this.batchErrorMessage = headerResult.errorMessage;
            this.showPopup(this.batchRefuelErrorPopup);
            return;
        }

        // Obtain Header for verify whether the header provided match our template header
        const workbookHeader = XLSX.read(bstr, { type: "binary", sheetRows: headerRowNum + 1 });
        const worksheetHeader = workbookHeader.Sheets[workbookHeader.SheetNames[0]];
        // Revert it back to original range so that can continue to verify each inputed row data
        const verifyTemplateResult = await ExcelUtil.verifyFuelBrandTemplate(worksheetHeader, this.worksheet, headerRowNum, this.selectedBrandHeaderList, this.dbHeaderList);
        let columnInfo = [];
        if (!verifyTemplateResult.status) {
            this.batchErrorMessage = "Something Wrong With the Template";
            this.showPopup(this.batchRefuelErrorPopup);
        } else {
            this.worksheet = verifyTemplateResult.worksheet;
            columnInfo = verifyTemplateResult.columnInfo;

        }

        // // Fully auto check, accuracy might be low(different brand have different meaning on column naming)
        // const requiredField = {
        //     vehiclePlateNo: true,
        //     fuelQuantity: true,
        //     fuelCost: true,
        //     fuelDate: true,
        //     location: false,
        //     fuelType: false,
        //     odometer: false
        // };
        // const verifyResult = await ExcelUtil.verifyExcelFuelEntryList(worksheetHeader, worksheet, requiredField, this.selectedFuelBrand);

        // if (verifyResult.status) {
        //     worksheet = verifyResult.worksheet;
        // } else {
        //     this.batchErrorMessage = verifyResult.errorMessage;
        //     this.showPopup(this.batchRefuelErrorPopup);
        //     return;
        // }
        // // End of Fully auto check

        this.worksheet['!ref'] = actContentRefRange;

        const contentRange = XLSX.utils.decode_range(this.worksheet['!ref']);
        const formatedWorksheet = await ExcelUtil.formatExcelSheetDateTime(this.worksheet, contentRange, columnInfo, headerRowNum);
        this.worksheet = formatedWorksheet;
        // let index = contentRange.s.r;
        // do {
        //     XLSX.utils.format_cell(this.worksheet[`H${index}`], null, { dateNF: "DD/MM/YYYY" });
        //     if (index != contentRange.s.r) {
        //         console.log(this.worksheet[`H${index - 1}`]);
        //         if (this.worksheet[`H${index - 1}`]) {
        //             this.worksheet[`H${index - 1}`].v = this.worksheet[`H${index - 1}`].w;
        //             delete this.worksheet[`H${index - 1}`].w;
        //         }
        //     }
        //     index++;
        // } while (index <= contentRange.e.r);
        // this.worksheet[`H${contentRange.e.r}`].v = this.worksheet[`H${contentRange.e.r}`].w;
        // delete this.worksheet[`H${contentRange.e.r}`].w;

        this.batchRefuelList = XLSX.utils.sheet_to_json(this.worksheet, { dateNF: "DD/MM/YYYY" }/* , { raw: true } */);
        this.batchRefuelCount = this.batchRefuelList.length;

        // Check File Content
        const checkContentResult = await this.checkIsValidFileContent();
        if (!checkContentResult.status) {
            this.batchErrorMessage = checkContentResult.errorMessage;
            this.showPopup(this.batchRefuelErrorPopup);
        } else {
            this.showPopup(this.batchImportPreviewPopup);
        }
    }

    // format checking
    checkIsValidFileContent() {
        if (this.batchRefuelList.length) {
            this.totalRefuelRecords = this.batchRefuelList.length;
            for (let i = 0; i < this.batchRefuelList.length; i++) {
                const eachRecord = this.batchRefuelList[i];
                const temp: any = {};
                const columnsStatus: any = {
                    plateNoValid: false,
                    plateNoErrMsg: null,
                    refuelAmountValid: false,
                    refuelAmountErrMsg: null,
                    refuelCostValid: false,
                    refuelCostErrMsg: null,
                    refuelDateValid: false,
                    refuelDateErrMsg: null,
                    odometerValid: false,
                    odometerErrMsg: null
                };
                if (eachRecord.plateNo && eachRecord.plateNo.includes("Note")) {
                    // Default Template Reach "Note" row mean the rest is note no more data after that
                    this.totalRefuelRecords = this.totalRefuelRecords - (this.batchRefuelList.length - i);
                    this.batchRefuelList = this.batchRefuelList.slice(0, this.totalRefuelRecords);
                    break; // skip unwanted row Eg: Note: * Required Fields
                }
                this.vehicleList.map(eachVehicle => {
                    // Required Fields
                    // Validate Vehicle's Plate Number
                    const currPlateNo = (eachRecord.plateNo) ? eachRecord.plateNo.replace(/\s/g, '') : '';
                    const storedPlateNo = eachVehicle.plateNo.replace(/\s/g, '');
                    if (StringUtil.equalsIgnoreCase(currPlateNo, storedPlateNo)) {
                        temp.vehicleId = eachVehicle.vehicleId;
                        columnsStatus.plateNoValid = true;
                    } else {
                        columnsStatus.plateNoErrMsg = Message.getMessage(Message.MESSAGE.PLATE_NO_NOT_FOUND.value);
                    }
                    temp.plateNo = eachRecord.plateNo;

                    // Validate refueled Quantity (Liters)
                    if (eachRecord.fuelQuantity && NumberUtil.isNumeric(eachRecord.fuelQuantity)) {
                        columnsStatus.refuelAmountValid = true;
                    } else {
                        columnsStatus.refuelAmountErrMsg = Message.getMessage(Message.MESSAGE.NOT_NUMERIC.value, 'Fuel Quantity');
                    }
                    temp.fuelQuantity = eachRecord.fuelQuantity;

                    // Validate refueled cost (RM)
                    if (eachRecord.fuelCost && NumberUtil.isNumeric(eachRecord.fuelCost)) {
                        columnsStatus.refuelCostValid = true;
                    } else {
                        columnsStatus.refuelCostErrMsg = Message.getMessage(Message.MESSAGE.NOT_NUMERIC.value, 'Cost');
                    }
                    temp.fuelCost = eachRecord.fuelCost;

                    // Validate refueled Date
                    const dateTimeRegex = "^([0-2][0-9]|3[0-1])\/(0[0-9]|1[0-2])\/[0-9]{4} ([0-1][0-9]|2[0-3])(:([0-4][0-9]|5[0-9])){2}$";
                    const strDateTime = (eachRecord.fuelTime) ? eachRecord.fuelDate + " " + eachRecord.fuelTime : eachRecord.fuelDate;
                    if (eachRecord.fuelDate) {
                        if (DateTimeUtil.isValidDateTime(strDateTime, dateTimeRegex, "DD/MM/YYYY HH:mm:ss")) {
                            columnsStatus.refuelDateValid = true;
                        } else {
                            columnsStatus.refuelDateErrMsg = Message.getMessage(Message.MESSAGE.INVALID_DATE.value, 'Date/Time', 'DD/MM/YYYY 24HH:mm:ss', 'Eg: 15/07/2020 18:00:12');
                        }
                    } else {
                        columnsStatus.refuelDateErrMsg = Message.getMessage(Message.MESSAGE.INVALID_DATE.value, 'Date/Time', 'DD/MM/YYYY 24HH:mm:ss');
                    }
                    temp.fuelDate = strDateTime;

                    // Non-required Fields
                    // Refueled Location (Fuel Stations)
                    temp.location = eachRecord.location;
                    // Refueled Fuel Type (RON95, 97, 100 or Diesel)
                    if (eachRecord.fuelType) {
                        const fuelType = ['95', '97', '100', 'diesel'];
                        const fuelTypeUsed = fuelType.find(eachType => {
                            return eachRecord.fuelType.toLowerCase().includes(eachType);
                        });
                        temp.fuelType = FuelType.getFuelTypeByBatchValue(fuelTypeUsed);
                        if (!temp.fuelType) {
                            temp.fuelType = eachRecord.fuelType;
                        }
                    }
                    // Refueled Vehicle's Odometer
                    if (eachRecord.odometer) {
                        if (NumberUtil.isNumeric(eachRecord.odometer)) {
                            columnsStatus.odometerValid = true;
                        } else {
                            columnsStatus.odometerErrMsg = Message.getMessage(Message.MESSAGE.INVALID_ODOMETER.value, 'Odometer');
                        }
                    }
                    temp.odometer = eachRecord.odometer;
                });
                this.refuelRecordValidation(columnsStatus, temp);
            }
            if (this.invalidRefuelList.length > 0) {
                this.refuelRecordsHasError = true;
            }
            return { status: true };
        }
        return { status: false, errorMessage: `${'Invalid File Contents'}` };
    }

    async refuelRecordValidation(columnsStatus = null, recordJSON = null) {
        if (!columnsStatus || !recordJSON) {
            return;
        }
        let isError: boolean = false;
        let errMsg: string = '';
        for (const eachStat in columnsStatus) {
            if (eachStat.includes('ErrMsg')) {
                continue;
            } else if (!columnsStatus[eachStat]) {
                const tempErrMsg = eachStat.replace('Valid', 'ErrMsg');
                isError = true;
                errMsg += columnsStatus[tempErrMsg] + ", ";
            }
        }
        errMsg = errMsg.substring(0, errMsg.lastIndexOf(", "));
        if (isError) {
            recordJSON.remark = errMsg;
            this.invalidRefuelList.push(recordJSON);
            this.refuelErrorList.push(columnsStatus);
            return;
        }
        this.validRefuelList.push(recordJSON);
        return;
    }

    /**
     * Generate Excel file
     * @param isTemplate boolean check is requst for template
     * toDo:
     * 1) to support diiferent type of brand & its multiple version
     */
    // generate template/invalid refuel entry records for download
    async generateExcelTemplate(isTemplate = true) {
        // console.log("Begin to process invalid data into excel file");
        // this.worksheet['!ref'] = this.oriHeaderRefRange;
        // const check = XLSX.utils.sheet_to_json(this.worksheet, { dateNF: "DD/MM/YYYY hh:mm:ss AM/PM", raw: true }/* , { raw: true } */);
        // const revertCheck = XLSX.utils.json_to_sheet(check);
        // this.worksheet['!ref'] = this.oriRefRange;
        // console.log(check);
        // console.log(revertCheck);
        const now = moment();
        const header = ['*Vehicle Plate Number', '*Fuel Quantity (L)', '*Cost (RM)', '*Date/Time', 'Location', 'Fuel Type', 'Odometer'];
        const headerType = [];
        const excelWidthConfig = [{ wch: 22.5 }, { wch: 16.5 }, { wch: 10 }, { wch: 22 }, { wch: 21 }, { wch: 8.5 }, { wch: 13.5 }];
        const noteMsg = [
            ['Note: ', '1.', 'If continue using this document for submission please select mDrive(Default Template).'],
            ['', '2.', '*Columns is Required Fields'],
            // ['', '3.', 'Please Convert Date/Time to string date by using =TEXT(<cellName>, "DD/MM/YYYY HH:mm:ss") in a new column.'],
            // ['', '', 'After that copy new column value to the existring Date/Time column using paste Value(V) method. Then Remove New Column.'],
            // ['', '', 'Paste Value(V) method: Right click cell > Paste Options > "Value(V)" or Icon come with 123.']
        ];
        let data = [];
        // template data
        if (isTemplate) {
            data = [
                ['www8888', 40.12, 82.15, now.format("01/MM/YYYY 02:34:13"), 'Shell Helix Wangsa Maju', 'RON 95', 12345.6],
                ['wxy1234', 55.017, 102.07, now.format("02/MM/YYYY 07:03:54"), 'Shell Helix Sentul', 'RON97', 34676.4],
                ['www8888', 40, 85.12, now.format("08/MM/YYYY 13:11:23"), 'Shell Helix Wangsa Maju', 'RON 100', 1001],
                ['wxy1234', 55.01, 102.07, now.format("08/MM/YYYY 13:00:00"), 'Shell Helix Sentul', 'DIESEL', 112345.2],
                ['wxy1234', 55.058, 102.07, now.format("16/MM/YYYY 23:00:23"), 'Shell Helix Sentul', 'BATTERY', 23341.2],
            ];
            data.push(noteMsg);
        } else {
            const actualData = this.invalidRefuelList.slice(0);
            // console.log(this.invalidRefuelList);
            // console.log(actualData);
            if (actualData && actualData.length >= 1) {
                data = [];
                //Add remark columns for invalid records
                header.push('Invalid Reason');
                excelWidthConfig.push({ wch: 90.5 });

                noteMsg.map(eachArray => {
                    // eachArray.push('');
                    actualData.push(eachArray);
                });
                // actualData.push(noteMsg);

                actualData.map(eachData => {
                    const temp: any = [];
                    for (const eachFieldValues in eachData) {
                        if (eachFieldValues != 'vehicleId') {
                            temp.push(eachData[eachFieldValues]);
                        }
                    }
                    data.push(temp);
                });
            }
        }
        const excel: any = this.fileExportService.mergeHeaderAndDataForExcel(data, header);
        this.fileExportService.exportExcelFile(excel, "Batch_Refuel_Entry_Template", headerType, excelWidthConfig);
    }

    async selectFuelType(fuelTypeId) {
        this.selectFuelTypeId = fuelTypeId;
        this.enableUpload = true;
        if (this.enableUpload) {
            this.browseBtnColor = 'green';
        }
        this.selectedFuelBrand = FuelTempConst.getFuelTemplateTypeValueById(fuelTypeId);
    }

    // batch import process after validation
    async batchCreateRefuelEntry() {
        const fuelList = [];
        this.validRefuelList.map(eachRefuel => {
            const temp = {
                vehicleId: eachRefuel.vehicleId,
                fuelType: eachRefuel.fuelType,
                fuelCost: eachRefuel.fuelCost,
                fuelQuantity: eachRefuel.fuelQuantity,
                location: eachRefuel.location,
                fuelDate: moment(eachRefuel.fuelDate, "DD/MM/YYYY hh:mm:ss A").format("YYYY-MM-DD HH:mm:ss"),
                odometer: eachRefuel.odometer
            };
            fuelList.push(temp);
        });
        const result = await this.fuelService.createVehicleFuel(fuelList);

        let msg = Message.getMessage(Message.MESSAGE.IMPORT_FAILED.value, 'Refuel Entry');
        if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
            msg = Message.getMessage(Message.MESSAGE.IMPORT_SUCCESS.value, 'Refuel Entry');
            this.generalRefuelEntry('');
        }
        this.cleanFuelImportData();
        this.snackBar.openGenericSnackBar(msg);
    }

    cleanFuelImportData() {
        this.selectFuelTypeId = -1; // selected template's id
        this.enableUpload = false;
        this.browseBtnColor = 'grey';

        this.selectedFuelBrand = ''; // selected fuel brand (Shell,Cartex,BHP,Petronas...)
        this.selectedBrandLabel = '';
        this.selectedBrandHeaderList = [];
        this.dbHeaderList = [];

        this.file = null; // uploaded file
        this.arrayBuffer = null; // uploaded file that coverted into array buffer
        this.oriWorkbook = null;
        this.oriRefRange = '';
        this.worksheet = [];

        this.oriHeaderRefRange = '';
        this.batchRefuelList = []; // Sheet to Json Result
        this.batchRefuelCount = 0; // Total Records provided in excel file
        this.batchErrorMessage = null; // Show any error while excel file validation is running
        this.fileName = ''; // Uploaded file name
        this.totalRefuelRecords = 0;
        this.isDownloadDone = false; // confirm client has download invalid file
        this.refuelRecordsHasError = false;
        this.validRefuelList = []; // All valid fuel entries
        this.invalidRefuelList = []; // All invalid fuel entries
        this.refuelErrorList = []; // All remark on each invalid fuel entries
    }

    /*--- General Use Methods ---*/
    cancelResetDelete() {
        // Set Fuel KPI
        this.isDeletingFuelLimit = false;
        this.selectedFuelLimit = {};
        this.selectedFuelLimitGroups['all'] = false;

        // Set Refuel Entry
        this.isDeletingRefuelEntry = false;
        this.selectedRefuelEntry = {};
        this.selectedRefuelEntryGroups['all'] = false;
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }

        if (this.currentTab === 2) {
            await this.getVehiclesFuelLimit();
        } else if (this.currentTab === 1) {
            await this.getVehiclesRefuelEntry();
        }
    }

    getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
    /*--- End of General Use Methods ---*/

}
