import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
// import { MessageBusService, BusMessage } from "./../../../_services/messagebus.service";
// import { Subscription } from 'rxjs';

// import * as LocalStorageUtil from './../../../util/localStorageUtil';

@Component({
    selector: 'site-info',
    templateUrl: './site-info.component.html',
    styleUrls: ['./site-info.component.scss']
})
export class SiteInfoComponent implements OnInit, OnDestroy {
    alertIsShown: boolean = false;
    // isLoggedIn: boolean = false;

    // msgBusSubscription: Subscription;
    constructor(
        private el: ElementRef,
        // private msgbus: MessageBusService
    ) {
        // const busesPort = ["system"];
        // this.msgBusSubscription = this.msgbus.messagebus$.pipe(
        //     filter(message => busesPort.indexOf(message.source) != -1)
        // ).subscribe(this.messageBusGetMessage.bind(this));
    }

    /* Message Controller*/
    //   messageBusGetMessage(message: BusMessage) {
    //     switch (message.source) {
    //       case "system":
    //         switch (message.messagetype) {
    //           case "login":
    //             // this.isLoggedIn = true;
    //             // this.hideAlert();
    //             break;
    //           // case "logout":
    //           //   this.isLoggedIn = false;
    //           //   this.hideAlert();
    //           //   break;
    //         }
    //     }
    //   }

    async ngOnInit() {
        // if (LocalStorageUtil.localStorageGet('currentUser')) {
        //     this.isLoggedIn = true;
        //     // this.showAlert(); //test
        // }
    }

    async ngOnDestroy() {
        // this.msgBusSubscription.unsubscribe();
    }

    showAlert(): void {
        this.alertIsShown = true;
        this.el.nativeElement.classList.add('site-info__alert');
        document.body.classList.add('site-info__alert');
    }

    hideAlert(): void {
        this.alertIsShown = false;
        this.el.nativeElement.classList.remove('site-info__alert');
        document.body.classList.remove('site-info__alert');
    }

}
