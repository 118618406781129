import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { trigger, style, animate, transition } from '@angular/animations';
// import { environment } from './../../../../../environments/environment';
// import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from './../../../../_services/pager/pager.service';
import { SupportService } from '../../../../_services/support/support.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

import * as USER_ROLE from '../../../../constants/userRole';
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';

@Component({
    selector: 'app-id-management',
    templateUrl: './id-management.component.html',
    styleUrls: ['./id-management.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class IdManagementComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("successNotification",{static:false}) successNotification;
    emailSentPopupMsg: string;

    userRole = USER_ROLE;

    isDeletingMaxisSupport: boolean;
    selectedMaxisSupport: any = {};
    selectedMaxisSupportGroups: any = {};

    searchBy = 'username';
    maxisSupportUserList: any = [];
    sortField = 'Email';
    sortAscending = true;
    filterKey = 'Email';
    filterValue = '';
    currentFilterValue = '';

    pager: any = {};
    pageRecordSize = 10;

    resetPasswordUserId: any = 0;
    resetPasswordUserEmail: any = '';

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private popupService: PopupService,
        private pagerService: PagerService,
        private supportService: SupportService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        await this.getMaxisSupportUsers();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async getMaxisSupportUsers(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Get Maxis Support User Page
            const maxisSupportUserResult: any = await this.supportService.getMaxisSupportUser(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            this.maxisSupportUserList = maxisSupportUserResult.body.result;
            this.pager = this.pagerService.getPager(maxisSupportUserResult.body.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.maxisSupportUserList.forEach(maxisUser => {
                //get role label by property name, if not found then search by value
                let label = USER_ROLE.USER_ROLE[maxisUser.userRole] ? USER_ROLE.USER_ROLE[maxisUser.userRole].label : undefined;
                if (!label) {
                    label = USER_ROLE.getUserRoleLabel(maxisUser.userRole);
                }
                maxisUser.roleLabel = label;
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async deleteMaxisSupportUsers() {
        this.pageSpinner.show();
        try {
            const selectedMaxisSupportList = [];
            for (const key in this.selectedMaxisSupport) {
                if (this.selectedMaxisSupport[key] === true) {
                    selectedMaxisSupportList.push(parseInt(key));
                }
            }

            const result = await this.supportService.deleteMaxisSupportUser(selectedMaxisSupportList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Maxis Support');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Maxis Support');

                this.cancelResetDelete();
                await this.getMaxisSupportUsers();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    setUserIdAndEmail(userId, userEmail) {
        this.resetPasswordUserId = userId;
        this.resetPasswordUserEmail = userEmail;
    }

    async resetPassword() {
        this.pageSpinner.show();
        try {
            const result = await this.supportService.resetPassword(this.resetPasswordUserId, this.resetPasswordUserEmail);
            if (result && result.statusCode === ResponseStatusCode.SUCCESS.code) {
                this.emailSentPopupMsg = Message.getMessage(Message.MESSAGE.EMAIL_SENT_PASSWORD_RESET_LINK.value, this.resetPasswordUserEmail);
                this.showPopup(this.successNotification);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    onMaxisSupportAllSelectChange() {
        const allIsSelected = this.selectedMaxisSupportGroups.all;
        // console.debug("allIsSelected:" + this.selectedMaxisSupportGroups.all);
        for (let i = 0; i < this.maxisSupportUserList.length; i++) {
            const maxisSupportModel = this.maxisSupportUserList[i];
            this.selectedMaxisSupport[maxisSupportModel.userId] = allIsSelected;
        }
    }

    onMaxisSupportSelectChange(maxisSupportSelected: boolean) {
        if (maxisSupportSelected) {
            // this.selectedDrivingLimit[drivingLimitModel.vehicleId] = true;
        } else {
            this.selectedMaxisSupportGroups['all'] = false;
        }
    }

    countSelectedMaxisSupport() {
        return Object.keys(this.selectedMaxisSupport).filter(result => this.selectedMaxisSupport[result] === true).length;
    }

    cancelResetDelete() {
        this.isDeletingMaxisSupport = false;
        this.selectedMaxisSupport = {};
        this.maxisSupportUserList['all'] = false;
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    navigateToCreateSupport() {
        this.routerProxyService.navigate(['/support/manage/admins/create']);
    }

    navigateToEditSupport(maxisSupportUser) {
        this.routerProxyService.navigate(['/support/manage/admins/edit', maxisSupportUser]);
    }

    async filterMaxisSupportUsers() {
        this.currentFilterValue = this.filterValue;
        switch (this.searchBy) {
            case 'username':
                this.filterKey = 'username';
                break;
            case 'name':
                this.filterKey = 'name';
                break;
        }
        await this.getMaxisSupportUsers();
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }
        await this.getMaxisSupportUsers();
    }

    getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }
}
