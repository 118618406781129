<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md --space-top">
                    <h1 class="heading --level-1">
                        Groups
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToCreateGroups(); toggleMobileOpen = false" *ngIf="creatable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create {{ groupTypeText }} Group
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create {{ groupTypeText }} Group
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingGroup = true; toggleMobileOpen = false" *ngIf="deletable">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Group
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Group
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section   bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="groupType === 'vehicle'" (click)="loadPage('vehicle')">Vehicle</button>
                <button type="button" class="tabs__link" [class.--is-active]="groupType === 'driver'" (click)="loadPage('driver')">Driver</button>
            </div>
        </div>
    </div>

    <div class="tabs__content">
        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-10of12 w-6of12@sm">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item search-group__item">
                                    <div class="field">
                                        <div class="field__elems">
                                            <div class="field__icon">
                                                <div class="field__icon-inner">
                                                    <img src="assets/images/scss-components/forms/icon-steering-wheel.svg" width="26" height="20" alt="" class="field__icon-image" *ngIf="groupType === 'driver'">
                                                    <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="26" height="20" alt="" class="field__icon-image" *ngIf="groupType === 'vehicle'">
                                                </div>
                                            </div>

                                            <label class="field__holder">
                                                <input id="driverSearchBox" type="text" autocomplete="off" class="field__input" placeholder="Search {{ groupTypeText }} Group" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchGroupName()">
                                            </label>

                                            <div class="field__ui"></div>
                                        </div>
                                    </div>

                                    <div class="button__holder">
                                        <button type="button" class="button" (click)="searchGroupName()">SEARCH</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid__block w-2of12 w-6of12@sm">
                            <div class="filter-button">
                                <button type="button" class="button --filter" (click)="sort()">
                                    <div class="filter-button__input">
                                        Group Name
                                    </div>

                                    <img [attr.src]="sortAscending ? 'assets/images/ng-components/create-manage/icon-filter-down.svg' : 'assets/images/ng-components/create-manage/icon-filter-up.svg'" alt="" width="25" height="12" class="filter-button__icon">

                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-6of12@sm w-4of12@lg" *ngFor="let group of groupList">

                            <a class="filter-result__box" [class.--cursor]="editable" *ngIf="!isDeletingGroup" (click)="editable && navigateToEditGroup(group.id)">
                                <ng-container *ngTemplateOutlet="result1"></ng-container>
                            </a>

                            <label class="result-checkbox" *ngIf="isDeletingGroup">
                                <div class="inline-input">
                                    <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDeleteGroups[group.id]">

                                    <span class="inline-input__icon"></span>

                                    <div class="filter-result__box --cursor">
                                        <ng-container *ngTemplateOutlet="result1"></ng-container>
                                    </div>
                                </div>
                            </label>

                            <ng-template #result1>
                                <div class="filter-result-group">
                                    <div class="filter-result__image">
                                        <div class="filter-result__image-cropped" [ngStyle]="{'background': group.colour}">
                                            {{group.shortName}}
                                        </div>
                                    </div>

                                    <div class="filter-result__info">
                                        <div class="filter-result__title">
                                            <span class="truncate-line">
                                                {{group.name}}
                                            </span>
                                        </div>

                                        <div class="filter-result__total-driver indicator-green">
                                            <span class="truncate-line">
                                                {{groupType === 'driver' ? "Drivers" : "Vehicles"}} ({{group.count}})
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>
                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination" *ngIf="groupList.length > 0">
                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getDisplayGroupList(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getDisplayGroupList(page)">{{
                                    page }}</button>

                                <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getDisplayGroupList(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingGroup">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedGroups()}} {{groupType}} group(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">

                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteGroupPopup)" [disabled]="countSelectedGroups()==0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteGroupPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete <span class="total-count">{{countSelectedGroups()}}</span> {{groupType}} group(s)
        </p>

        <!-- <p class="notes">
            *All assigned {{groupType}}s in the group will be removed
        </p> -->
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteGroups();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
