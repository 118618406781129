import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as StringUtil from './../../../util/stringUtil';
import { SnackBarService } from './../../../_services/snackBar/snack-bar.service';
import { DriverService } from './../../../_services/driver/driver.service';
import { SpinnerComponent } from './../../../components/common/spinner/spinner.component';

@Component({
    selector: 'app-driver-tag-selector',
    templateUrl: './driver-tag-selector.component.html',
    styleUrls: ['./driver-tag-selector.component.scss']
})
export class DriverTagSelectorComponent implements OnInit {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    @Input('title') title: string = "Assign Vehicles";
    // @Input('adminId') adminId: number;
    @Input('selectedIds') selectedIds: Array<number>;
    // @Input('withGroup') withGroup: boolean = true;
    @Input('inPopup') inPopup: boolean = false;
    // @Input('excludeDeactivated') excludeDeactivated: boolean = true;
    @Input('singleSelect') singleSelect: boolean = false;

    sectionClass: string;
    wrapperClass: string;
    blockClass: string;

    //Assign Resource Variables
    driverService: DriverService;
    driverTagResult: any = {};

    sortField: any = 'driverTagNo';
    sortAscending: boolean = true;
    // currVehicleSortField: any = 'vehicleName';
    // currVehicleSortAscending: boolean = true;
    filterValue: any = '';
    // currVehicleFilterValue: any = '';

    // vehicleUniqueList: Array<any> = [];
    // vehicleIdUniqueList: Array<number> = [];
    // vehicleIdUniqueCompleteList: Array<any> = [];
    unmodifiedSelectedDriverTagIdList: Array<number> = [];
    selectedDriverTagIdList: Array<number> = [];
    selectedDriverTag: any = {};
    selectedDriverTagGroups: any = {};

    // @ViewChild('driverTagField') driverTagField;
    driverTagList: any = [];
    // vehicleFilterSearchString: string = ""; //store filter search string
    isShowSelectAllOption: boolean = true;

    // ready: boolean = false;

    // expandGroupVehicle: any = [];

    constructor(private el: ElementRef,
        private snackBar: SnackBarService,
        private http: HttpClient) { }

    async ngOnInit() {
        this.sectionClass = (this.inPopup) ? '' : 'section --border-top';
        this.wrapperClass = (this.inPopup) ? '' : 'wrapper';
        this.blockClass = (this.inPopup) ? 'popup__block' : 'block';

        this.pageSpinner.show();
        try {

            //Get vehicle list
            // this.driverService = new DriverService(this.http);
            // this.driverTagResult = await this.driverService.getDriverTagListByCompanyId(this.sortField, this.sortAscending, this.filterValue);
            // // this.getAssignedVehicles();
            // this.driverTagList = this.driverTagResult.body.driverTags.slice(0);
            await this.getAllDriverTags();
            // this.getVehicleListWithoutGroup();
            // this.sort(this.currVehicleSortField, true);
            // this.ready = true;

            if (this.singleSelect) {
                //currently single select only for vehicle panel add id tag, which only show when driver dont have driver tag, so always show empty selected
                this.isShowSelectAllOption = false;
            } else {
                //multi select, need call api to get from db to assign back what was selected previously
            }

            //Check DriverTag
            if (this.selectedIds && this.selectedIds.length) {
                this.setSelectedDriverTagIds(this.selectedIds);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async getAllDriverTags() {
        this.pageSpinner.show();
        try {
            this.driverService = new DriverService(this.http);
            this.driverTagResult = await this.driverService.getDriverTagListByCompanyId(this.sortField, this.sortAscending, this.filterValue);
            this.driverTagList = this.driverTagResult.body.driverTags.slice(0);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    // warnIfNotReady(): boolean {
    //     if (!this.ready) {
    //         console.warn('Trying to select/deselect vehicles before selector component is ready. Instead, you should pass vehicle ids through component tag attribute.');
    //         return true;
    //     }
    //     return false;
    // }

    // public getSelectedCount(): number {
    //     // console.debug(this.selectedVehicleIdList);
    //     // console.debug(this.selectedVehicles);
    //     // console.debug(this.vehicleIdUniqueList);
    //     // console.debug(this.vehicleUniqueList);
    //     return this.selectedDriverTagIdList.length;
    // }

    getSelectedDisplayText(): string {

        const selectedCount = this.getSelectedIds().length;
        const totalCount = this.driverTagList.length;

        let text: string = '';
        text += `${selectedCount} / ${totalCount} total selected`;
        return text;
    }

    onDriverTagAllSelectChange() {
        // Note: Apply to ALL vehicles that are VISIBLE+HIDDEN
        const allIsSelected: boolean = this.selectedDriverTagGroups.all;
        if (!this.driverTagList) {
            return;
        }

        for (let i = 0; i < this.driverTagList.length; i++) {
            const driverTagModel = this.driverTagList[i];
            this.selectedDriverTag[driverTagModel.driverTagId] = allIsSelected;
        }
    }

    onDriverTagSelectChange(currentSelectedDriverTagId, selected: boolean) {
        if (this.singleSelect) {
            //only 1 selection at a time, delete the others except current selected
            for (const selectedId in this.selectedDriverTag) {
                if (Number(selectedId) !== currentSelectedDriverTagId) {
                    delete this.selectedDriverTag[selectedId];
                }
            }
        } else {
            //allow multiple select
            this.fixDriverTagsCheckBoxesStates(); // Tick correct checkboxes
            if (selected) {
                this.checkNeedTickSelectAllButton();
            } else {
                this.selectedDriverTagGroups['all'] = false;
            }
        }
    }

    checkNeedTickSelectAllButton() {
        let allGroupTicked = true;
        for (let i = 0; i < this.driverTagList.length; i++) {
            const dtModel = this.driverTagList[i];
            if (typeof (this.selectedDriverTag[dtModel.driverTagId]) === "undefined" || !this.selectedDriverTag[dtModel.driverTagId]) {
                allGroupTicked = false;
                break;
            }
        }

        if (allGroupTicked) {
            this.selectedDriverTagGroups['all'] = true;
        }
    }

    getAllIds() {
        return this.driverTagList.map((model) => {
            return model.driverTagId;
        });
    }

    getSelectedIds(additionalId?) {
        const selectedIds = [];

        if (additionalId !== undefined) {
            selectedIds.push(additionalId);
        }

        for (const id in this.selectedDriverTag) {
            if (this.selectedDriverTag[id]) {
                selectedIds.push(Number(id));
            }
        }

        return selectedIds;
    }

    async filterDriverTagSelection(searchString: string = "") {
        // this.filterValue = searchString;
        await this.getAllDriverTags();
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }
        await this.getAllDriverTags();
    }

    getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }
    public setSelectedDriverTagIds(ids: Array<number>): void {
        ids = ids.filter(item => item != null);
        // if (this.warnIfNotReady()) {
        //     return;
        // }
        this.selectedDriverTag = {};
        for (const id of ids) {
            // const ad = this.driverTagList.map(result => result.driverTagId).indexOf(id);s
            if (this.driverTagList.map(result => result.driverTagId).indexOf(id) != -1) {
                this.selectedDriverTag[id.toString()] = true;
            } else {
                delete this.selectedDriverTag[id.toString()];
            }
        }
        this.fixDriverTagsCheckBoxesStates(); // Tick correct checkboxes
        this.unmodifiedSelectedDriverTagIdList = this.selectedDriverTagIdList.slice(0);
    }

    private fixDriverTagsCheckBoxesStates() {

        // // Note: Apply ONLY to driverTags that are VISIBLE
        // if (!this.driverTagResult.body.driverTags) {
        //     return;
        // }
        // let allIsSelected = true;
        // for (let j = 0; j < this.driverTagResult.body.driverTags.length; j++) {
        //     const group = this.driverTagResult.body.driverTags[j];
        //     let groupIsSelected = true;
        //     for (let k = 0; k < group.idList.length; k++) {
        //         const driverTag = group.idList[k];
        //         if (!this.selectedDriverTag[driverTag.driverTagId.toString()]) {
        //             allIsSelected = false;
        //             groupIsSelected = false;
        //             break;
        //         }
        //     }
        //     this.selectedDriverTagGroups[j] = groupIsSelected;
        // }
        // this.selectedDriverTagGroups.all = allIsSelected;
        // if (this.driverTagResult.vehicles.length == 0) {
        //     this.selectedDriverTagGroups.all = false;
        // }
        this.syncSelectedDrivertagIdList();
    }

    private syncSelectedDrivertagIdList(): void {
        const assignedDriverTagId: Array<number> = [];
        for (const objKey in this.selectedDriverTag) {
            if (this.selectedDriverTag[objKey] === true) {
                assignedDriverTagId.push(parseInt(objKey));
            }
        }
        this.selectedDriverTagIdList = assignedDriverTagId;
    }

    public getSelectedDriverTagIds(): Array<number> {
        return this.selectedDriverTagIdList.slice(0); //clone copy
    }

}
