import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { RouterProxyService } from './../../../../../../_services/router-proxy/router-proxy.service';
import { ActivatedRoute } from '@angular/router';
import { CompanyRegistrationSharedService } from '../../../../../../shared/service/company-registration-shared.service';
import { CompanyRegistrationService } from './../../../../../../_services/company-registration/company-registration.service';
import { PopupService } from '../../../../../../components/common/popup/popup.service';
import { UploadComponent } from '../upload/upload.component';
import * as moment from 'moment';

@Component({
    selector: 'app-company-details',
    templateUrl: './company-details.component.html',
    styleUrls: ['./company-details.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CompanyDetailsComponent implements OnInit {

    showNew: boolean;
    showExisting: boolean;
    emptyCompanyObj: any = {
        "id": null,
        "name": null,
        "brn": null,
        "email": null,
        "rfDate": null,
        "address": {
            "address1": null,
            "address2": null,
            "city": null,
            "state": null,
            "zipCode": null,
            "countryCode": 'Malaysia'
        },
        "channel": {
            "channelName": null,
            "channelTypeId": null,
            "region": null
        },
        "type": "NEW",
        "cmssId": null
    }; 
    companyObj: any = {
        "id": null,
        "name": null,
        "brn": null,
        "email": null,
        "rfDate": null,
        "address": {
            "address1": null,
            "address2": null,
            "city": null,
            "state": null,
            "zipCode": null,
            "countryCode": 'Malaysia'
        },
        "channel": {
            "channelName": null,
            "channelTypeId": null,
            "region": null
        },
        "type": "NEW",
        "cmssId": null
    };
    regionList: any = ["Northern", "Southern", "Eastern", "Central", "Sabah", "Sarawak"];
    salesChannelList: any = [];
    companiesList: any = [];
    minDate = moment(new Date()).format('YYYY-MM-DD');
    newOrder: boolean =false;
    datepickerTouchUi: boolean = false;
    @ViewChild('fileUpload',{static:false}) fileUpload: UploadComponent;

    constructor(
        private routerProxyService: RouterProxyService,
        private companyRegistrationSharedService: CompanyRegistrationSharedService,
        private companyRegistrationService: CompanyRegistrationService,
        private route: ActivatedRoute,
        private popupService: PopupService
        
    ) { }

    goToVehicleDetails() {
        if (this.companyObj.type === "NEW") {
            this.companyObj.id = null;
        }
        this.companyObj.address.zipCode = this.companyObj.address.zipCode.toString();
        this.companyRegistrationSharedService.setCompanyObj(this.companyObj);
        this.routerProxyService.navigateByUrl('/support/manage-device/add-device/vehicle-details');
    }

    goToOverview() {
        this.routerProxyService.navigateByUrl('/support/manage-device');
    }

    ngOnInit() {

        //retrieve if have old data
        const object = JSON.parse(JSON.stringify(this.companyRegistrationSharedService.getCompanyObj()));
        if (object) {
            this.companyRegistrationSharedService.setCompanyObj(null);
            this.companyObj = object;
        }
        this.initCommonFunction();
    }
    ngAfterViewInit(){
        this.newOrder = this.route.snapshot.params.newOrder;
        if(this.newOrder === true){
            this.companyObj = this.emptyCompanyObj;
        }
    }
    async initCommonFunction() {
        const channelTypeResponse = await this.companyRegistrationService.getSalesChannelTypeList();
        this.salesChannelList = channelTypeResponse.body.result;
        const companiesResponse = await this.companyRegistrationService.getCompanyList();
        this.companiesList = companiesResponse.body.result.companyFormatResult;
    }

    async populateCompanyData() {
        const companyFound = this.companiesList.find(company => {
            return company.id === Number(this.companyObj.id);
        });
        Object.assign(this.companyObj, companyFound);
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    async closeFileUploadPopup() {
        //clear uploaded file
        this.fileUpload.fileUploadList = [];
        this.hidePopup();
    }
}
