<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main manage-device">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@lg --space-top">
                    <h1 class="heading --level-1">
                        Edit Info
                    </h1>
                </div>

                <div class="grid__block w-8of12@lg heading-buttons-field --space-top">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="showPopup(fileUploadPopup);">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/scss-components/key-value-icons/icon-upload.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Upload
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Upload
                                    </div>

                                    <img src="assets/images/scss-components/key-value-icons/icon-mobile-upload.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #vehicleForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <div class="add-device-tabs">
                        <div class="add-device-tabs__tab">
                            <div class="add-device-tabs__button --company-details">
                                Company Details
                            </div>
                        </div>

                        <div class="add-device-tabs__tab">
                            <div class="add-device-tabs__button --vehicle-details --active">
                                Vehicle Details
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="compulsory-notes">
                        *Compulsory fields
                    </div>
                </div>

                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Editing Vehicle Details
                    </h2>
                </div>

                <div class="block">
                    <div class="md-box-details-table table__edit-vehicle-details">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">
                                        Vehicles
                                    </th>

                                    <th scope="col">
                                        Installation
                                    </th>

                                    <th scope="col">
                                        Last Modified By
                                    </th>

                                    <th scope="col">
                                        Last Modified
                                    </th>

                                    <th scope="col">
                                        Progress
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>

                <div class="block block-mt-1" *ngFor="let vehicleObj of vehicleObjList; let position = index;">
                    <div class="md-box" [class.--active]="vehicleObj.isActive">
                        <div class="md-box__head" (click)="toggleVehicleAccordian(position)">
                            <div class="md-box-details-table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td scope="col">
                                                Vehicle {{ vehicleObj.vehicleId }}
                                            </td>

                                            <td scope="col">
                                                <!-- {{ vehicleObj.installationType === 'WIRED' ? 'Wired' : (vehicleObj.installationType === 'PLUG_PLAY' ? 'Plug & Play' : (vehicleObj.installationType === 'PLUG_PLAY_COURIER' ? 'Plug & Play Courier' : (vehicleObj.installationType === 'CUSTOM' ? 'Customised' : 'None'))) }} -->
                                                {{ vehicleObj.installationType === 'WIRED' ? 'Wired' : (vehicleObj.installationType === 'PLUG_PLAY' ? 'Plug & Play' : (vehicleObj.installationType === 'CUSTOM' ? 'Customised' : 'None')) }}
                                            </td>

                                            <td scope="col">
                                                {{ vehicleObj.updatedBy }}
                                            </td>

                                            <td scope="col">
                                                {{ vehicleObj.dateUpdated }}
                                            </td>

                                            <td scope="col">
                                                <div class="progress-status" [ngClass]="{
                                                '--in-progress': vehicleObj.status == 'SCHEDULED' || vehicleObj.status == 'PENDING',
                                                '--activated': vehicleObj.status == 'ACTIVATED',
                                                '--completed': vehicleObj.status == 'COMPLETED' || !vehicleObj.status || vehicleObj.status == 'DEACTIVATED',
                                                '--pending': vehicleObj.status=='RESCHEDULE' || vehicleObj.status == 'REQ_NEW_IMEI'
                                            }"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="md-box__contents-container" [hidden]="!vehicleObj.isActive" *ngIf="vehicleObj.installationType == 'WIRED'" @slideToggle>
                            <div class="md-box__contents">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Installation Type:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <strong>Wired</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="md-box__contents --bg-white">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item --2-col">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Device Provider:

                                                    <!-- <span class="indicator-green">*</span> -->
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <!-- <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.deviceProviderId" required name="{{'wiredDeviceProviderId'+position}}">
                                                                    <option *ngFor="let provider of deviceProviderList;" [value]="provider.id" [selected]="provider.id === selectedPlacement">{{provider.thirdPartyName}}</option>
                                                                </select>
                                                            </label> -->
                                                            {{vehicleObj.thirdPartyName}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Type:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleType" required name="{{'wiredVehicleType'+position}}">
                                                                    <option *ngFor="let vehicleType of vehicleTypeList;" [value]="vehicleType" [selected]="vehicleType === selectedPlacement">{{vehicleType}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Make:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleMakeId" required name="{{'wiredVehicleMakeId'+position}}" (change)="getVehicleModelList(vehicleObj.vehicleMakeId, position)">
                                                                    <option *ngFor="let vehicleMake of vehicleMakeList;" [value]="vehicleMake.id" [selected]="vehicleMake.id === selectedPlacement">{{vehicleMake.brandName}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Model:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleModelId" name="{{'wiredVehicleModelId'+position}}">
                                                                    <option *ngFor="let vehicleModel of vehicleModelList[position];" [value]="vehicleModel.id" [selected]="vehicleModel.id === selectedPlacement">{{vehicleModel.modelName}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Model Details:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'wiredVehicleModelDetails'+position}}" [(ngModel)]="vehicleObj.vehicleModelDetails">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Year:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleYear" name="{{'wiredVehicleYear'+position}}">
                                                                    <option *ngFor="let year of yearList;" [value]="year" [selected]="year === selectedPlacement">{{year}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Number Plate:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'wiredNumberPlate'+position}}" [(ngModel)]="vehicleObj.numberPlate" required>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    MES ID:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'mesId'+position}}" [(ngModel)]="vehicleObj.mesId" required>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Installation Remarks:
                                                </div>
            
                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" maxlength="150" class="field__input" name="{{'wiredInstallationRemarks'+position}}" [(ngModel)]="vehicleObj.installationRemarks">
                                                            </label>
            
                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    MOB:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'wiredMob'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.mob">
                                                                <span class="inline-input__icon"></span>
                                                                <span>Yes</span>
                                                            </label>

                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'wiredMob'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.mob">
                                                                <span class="inline-input__icon"></span>
                                                                <span>No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Roaming:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'wiredRoaming'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.roaming">
                                                                <span class="inline-input__icon"></span>
                                                                <span>Yes</span>
                                                            </label>

                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'wiredRoaming'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.roaming">
                                                                <span class="inline-input__icon"></span>
                                                                <span>No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. PIC Name:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'wiredPicName'+position}}" required [(ngModel)]="vehicleObj.installation.picName">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. Address:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'wiredAddress'+position}}" required [(ngModel)]="vehicleObj.installation.address">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Postcode:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="number" class="field__input" name="{{'wiredPostcode'+position}}" required [(ngModel)]="vehicleObj.installation.postcode">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    State:
                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.installation.state" name="{{'wiredState'+position}}" required>
                                                                    <option *ngFor="let state of stateList;" [value]="state" [selected]="state === selectedPlacement">{{state}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. PIC Contact No:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'wiredContactNo'+position}}" required [(ngModel)]="vehicleObj.installation.contactNo">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. PIC Email Address:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'wiredEmail'+position}}" required [(ngModel)]="vehicleObj.installation.email">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Proposal Instl. Date:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <div class="field__icon">
                                                                <div class="field__icon-inner">
                                                                    <mat-datepicker-toggle [for]="ProposalDatepicker">
                                                                        <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                                    </mat-datepicker-toggle>
                                                                </div>
                                                            </div>

                                                            <input [matDatepicker]="ProposalDatepicker" (focus)="ProposalDatepicker.open()" (click)="ProposalDatepicker.open()" type="text" name="{{'wiredProposedDate'+position}}" [min]="minDate" class="field__input" readonly dom-states required [(ngModel)]="vehicleObj.installation.proposedDate">

                                                            <div class="field__ui"></div>
                                                        </div>

                                                        <mat-datepicker #ProposalDatepicker [touchUi]="datepickerTouchUi">
                                                        </mat-datepicker>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Propose Instl.Time:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <div class="field__icon">
                                                                <div class="field__icon-inner">
                                                                    <img src="assets/images/scss-components/key-value-icons/icon-time-green.svg" width="24" height="24" alt="" class="field__icon-image">
                                                                </div>
                                                            </div>

                                                            <input type="time" class="field__input" name="{{'wiredProposedTime'+position}}" required [(ngModel)]="vehicleObj.installation.proposedTime">

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__item">
                                        <hr class="divider" />
                                    </div>

                                    <div class="field-group__item --2-col">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Contact Status:


                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.contactStatus || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Assigned installer:

                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.assignedInstaller || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. Date:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.installationDate || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. Status:

                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.installationStatus || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Remark:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.remark || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-box__contents-container" [hidden]="!vehicleObj.isActive" *ngIf="vehicleObj.installationType == 'PLUG_PLAY'" @slideToggle>
                            <div class="md-box__contents">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Installation Type:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <strong>Plug & Play</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="md-box__contents --bg-white">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item --2-col">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Device Provider:

                                                    <!-- <span class="indicator-green">*</span> -->
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <!-- <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.deviceProviderId" required name="{{'plugnplayDeviceProviderId'+position}}">
                                                                    <option *ngFor="let provider of deviceProviderList;" [value]="provider.id" [selected]="provider.id === selectedPlacement">{{provider.thirdPartyName}}</option>
                                                                </select>
                                                            </label> -->
                                                            {{vehicleObj.thirdPartyName}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Type:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" required [(ngModel)]="vehicleObj.vehicleType" name="{{'plugnplayVehicleType'+position}}">
                                                                    <option *ngFor="let vehicleType of vehicleTypeList;" [value]="vehicleType" [selected]="vehicleType === selectedPlacement">{{vehicleType}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Make:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleMakeId" required name="{{'plugnplayVehicleMakeId'+position}}" (change)="getVehicleModelList(vehicleObj.vehicleMakeId, position)">
                                                                    <option *ngFor="let vehicleMake of vehicleMakeList;" [value]="vehicleMake.id" [selected]="vehicleMake.id === selectedPlacement">{{vehicleMake.brandName}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Model:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleModelId" name="{{'plugnplayVehicleModelId'+position}}">
                                                                    <option *ngFor="let vehicleModel of vehicleModelList[position];" [value]="vehicleModel.id" [selected]="vehicleModel.id === selectedPlacement">{{vehicleModel.modelName}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Model Details:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayVehicleModelDetails'+position}}" [(ngModel)]="vehicleObj.vehicleModelDetails">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Year:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleYear" name="{{'plugnplayVehicleYear'+position}}">
                                                                    <option *ngFor="let year of yearList;" [value]="year" [selected]="year === selectedPlacement">{{year}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Number Plate:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayNumberPlate'+position}}" required [(ngModel)]="vehicleObj.numberPlate">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    MES ID:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'mesId'+position}}" [(ngModel)]="vehicleObj.mesId" required>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Installation Remarks:
                                                </div>
            
                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" maxlength="150" class="field__input" name="{{'plugnplayInstallationRemarks'+position}}" [(ngModel)]="vehicleObj.installationRemarks">
                                                            </label>
            
                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    MOB:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'plugnplayMob'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.mob">
                                                                <span class="inline-input__icon"></span>
                                                                <span>Yes</span>
                                                            </label>

                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'plugnplayMob'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.mob">
                                                                <span class="inline-input__icon"></span>
                                                                <span>No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Roaming:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'plugnplayRoaming'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.roaming">
                                                                <span class="inline-input__icon"></span>
                                                                <span>Yes</span>
                                                            </label>

                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'plugnplayRoaming'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.roaming">
                                                                <span class="inline-input__icon"></span>
                                                                <span>No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Delivery PIC Name:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayPicName'+position}}" required [(ngModel)]="vehicleObj.delivery.picName">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Delivery Address:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayAddress'+position}}" required [(ngModel)]="vehicleObj.delivery.address">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Postcode:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="number" class="field__input" name="{{'plugnplayPostcode'+position}}" required [(ngModel)]="vehicleObj.delivery.postcode">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    State:
                                                    <span class="indicator-green">*</span>
                                                </div>                                                
                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.delivery.state" name="{{'plugnplayState'+position}}" required>
                                                                    <option *ngFor="let state of stateList;" [value]="state" [selected]="state === selectedPlacement">{{state}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    PIC Contact No:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayContactNo'+position}}" required [(ngModel)]="vehicleObj.delivery.contactNo">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    PIC Email Address:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayEmail'+position}}" required [(ngModel)]="vehicleObj.delivery.email">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__item">
                                        <hr class="divider" />
                                    </div>

                                    <div class="field-group__item --2-col">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Device IMEI:

                                                    <!-- <span class="indicator-green">*</span> -->
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" minlength="15" maxlength="15" class="field__input" name="{{'plugnplayimei'+position}}" [(ngModel)]="vehicleObj.imei">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    ICCID:

                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" minlength="19" maxlength="19" class="field__input" name="{{'plugnplayiccid'+position}}" [(ngModel)]="vehicleObj.iccid">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    MSISDN:

                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplaymsisdn'+position}}" [(ngModel)]="vehicleObj.msisdn">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 
                        <div class="md-box__contents-container" [hidden]="!vehicleObj.isActive" *ngIf="vehicleObj.installationType == 'PLUG_PLAY_COURIER'" @slideToggle>
                            <div class="md-box__contents">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Installation Type:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <strong>Plug & Play Courier</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="md-box__contents --bg-white">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item --2-col">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Device Provider:

                                                    <!-- <span class="indicator-green">*</span> 
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <!-- <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.deviceProviderId" required name="{{'plugnplayDeviceProviderId'+position}}">
                                                                    <option *ngFor="let provider of deviceProviderList;" [value]="provider.id" [selected]="provider.id === selectedPlacement">{{provider.thirdPartyName}}</option>
                                                                </select>
                                                            </label> 
                                                            {{vehicleObj.thirdPartyName}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Type:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" required [(ngModel)]="vehicleObj.vehicleType" name="{{'plugnplayVehicleType'+position}}">
                                                                    <option *ngFor="let vehicleType of vehicleTypeList;" [value]="vehicleType" [selected]="vehicleType === selectedPlacement">{{vehicleType}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Make:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleMakeId" required name="{{'plugnplayVehicleMakeId'+position}}" (change)="getVehicleModelList(vehicleObj.vehicleMakeId, position)">
                                                                    <option *ngFor="let vehicleMake of vehicleMakeList;" [value]="vehicleMake.id" [selected]="vehicleMake.id === selectedPlacement">{{vehicleMake.brandName}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Model:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleModelId" name="{{'plugnplayVehicleModelId'+position}}">
                                                                    <option *ngFor="let vehicleModel of vehicleModelList[position];" [value]="vehicleModel.id" [selected]="vehicleModel.id === selectedPlacement">{{vehicleModel.modelName}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Model Details:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayVehicleModelDetails'+position}}" [(ngModel)]="vehicleObj.vehicleModelDetails">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Year:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleYear" name="{{'plugnplayVehicleYear'+position}}">
                                                                    <option *ngFor="let year of yearList;" [value]="year" [selected]="year === selectedPlacement">{{year}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Number Plate:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayNumberPlate'+position}}" required [(ngModel)]="vehicleObj.numberPlate">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Installation Remarks:
                                                </div>
            
                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" maxlength="150" class="field__input" name="{{'plugnplayInstallationRemarks'+position}}" [(ngModel)]="vehicleObj.installationRemarks">
                                                            </label>
            
                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    MOB:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'plugnplayMob'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.mob">
                                                                <span class="inline-input__icon"></span>
                                                                <span>Yes</span>
                                                            </label>

                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'plugnplayMob'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.mob">
                                                                <span class="inline-input__icon"></span>
                                                                <span>No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Roaming:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'plugnplayRoaming'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.roaming">
                                                                <span class="inline-input__icon"></span>
                                                                <span>Yes</span>
                                                            </label>

                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'plugnplayRoaming'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.roaming">
                                                                <span class="inline-input__icon"></span>
                                                                <span>No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Delivery PIC Name:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayPicName'+position}}" required [(ngModel)]="vehicleObj.delivery.picName">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Delivery Address:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayAddress'+position}}" required [(ngModel)]="vehicleObj.delivery.address">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Postcode:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="number" class="field__input" name="{{'plugnplayPostcode'+position}}" required [(ngModel)]="vehicleObj.delivery.postcode">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    State:
                                                    <span class="indicator-green">*</span>
                                                </div>                                                
                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.delivery.state" name="{{'plugnplayState'+position}}" required>
                                                                    <option *ngFor="let state of stateList;" [value]="state" [selected]="state === selectedPlacement">{{state}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    PIC Contact No:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayContactNo'+position}}" required [(ngModel)]="vehicleObj.delivery.contactNo">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    PIC Email Address:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplayEmail'+position}}" required [(ngModel)]="vehicleObj.delivery.email">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__item">
                                        <hr class="divider" />
                                    </div>

                                    <div class="field-group__item --2-col">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Device IMEI:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" minlength="15" maxlength="15" class="field__input" name="{{'plugnplayimei'+position}}" [(ngModel)]="vehicleObj.imei" required>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    ICCID:

                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" minlength="19" maxlength="19" class="field__input" name="{{'plugnplayiccid'+position}}" [(ngModel)]="vehicleObj.iccid">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    MSISDN:

                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'plugnplaymsisdn'+position}}" [(ngModel)]="vehicleObj.msisdn">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->     
                        <div class="md-box__contents-container" [hidden]="!vehicleObj.isActive" *ngIf="vehicleObj.installationType == 'CUSTOM'" @slideToggle>
                            <div class="md-box__contents">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Installation Type:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <strong>Customised</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="md-box__contents --bg-white">
                                <div class="field-group --unstack@xs">
                                    <div class="field-group__item --2-col">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Device Provider:

                                                    <!-- <span class="indicator-green">*</span> -->
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <!-- <label class="select">
                                                                <select class="select__input" required [(ngModel)]="vehicleObj.deviceProviderId" name="{{'customisedDeviceProviderId'+position}}">
                                                                    <option *ngFor="let provider of deviceProviderList;" [value]="provider.id" [selected]="provider.id === selectedPlacement">{{provider.thirdPartyName}}</option>
                                                                </select>
                                                            </label> -->
                                                            {{vehicleObj.thirdPartyName}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Type:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" required [(ngModel)]="vehicleObj.vehicleType" name="{{'customisedVehicleType'+position}}">
                                                                    <option *ngFor="let vehicleType of vehicleTypeList;" [value]="vehicleType" [selected]="vehicleType === selectedPlacement">{{vehicleType}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Make:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" required [(ngModel)]="vehicleObj.vehicleMakeId" name="{{'customisedVehicleMakeId'+position}}" (change)="getVehicleModelList(vehicleObj.vehicleMakeId, position)">
                                                                    <option *ngFor="let vehicleMake of vehicleMakeList;" [value]="vehicleMake.id" [selected]="vehicleMake.id === selectedPlacement">{{vehicleMake.brandName}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Model:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleModelId" name="{{'customisedVehicleModelId'+position}}">
                                                                    <option *ngFor="let vehicleModel of vehicleModelList[position];" [value]="vehicleModel.id" [selected]="vehicleModel.id === selectedPlacement">{{vehicleModel.modelName}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Model Details:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'customisedVehicleModelDetails'+position}}" [(ngModel)]="vehicleObj.vehicleModelDetails">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Vehicle Year:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.vehicleYear" name="{{'customisedVehicleYear'+position}}">
                                                                    <option *ngFor="let year of yearList;" [value]="year" [selected]="year === selectedPlacement">{{year}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Number Plate:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'customisedNumberPlate'+position}}" required [(ngModel)]="vehicleObj.numberPlate">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    MES ID:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'mesId'+position}}" [(ngModel)]="vehicleObj.mesId" required>
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Installation Remarks:
                                                </div>
            
                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" maxlength="150" class="field__input" name="{{'customisedInstallationRemarks'+position}}" [(ngModel)]="vehicleObj.installationRemarks">
                                                            </label>
            
                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Roaming:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'customisedRoaming'+position}}" class="inline-input__input" value="Y" [(ngModel)]="vehicleObj.roaming">
                                                                <span class="inline-input__icon"></span>
                                                                <span>Yes</span>
                                                            </label>

                                                            <label class="inline-input" style="margin: 6px 10px 7px;">
                                                                <input type="radio" name="{{'customisedRoaming'+position}}" class="inline-input__input" value="N" [(ngModel)]="vehicleObj.roaming">
                                                                <span class="inline-input__icon"></span>
                                                                <span>No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. PIC Name:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'customisedPicName'+position}}" required [(ngModel)]="vehicleObj.installation.picName">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. Address:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'customisedAddress'+position}}" required [(ngModel)]="vehicleObj.installation.address">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Postcode:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="number" class="field__input" name="{{'customisedPostcode'+position}}" required [(ngModel)]="vehicleObj.installation.postcode">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    State:
                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="select">
                                                                <select class="select__input" [(ngModel)]="vehicleObj.installation.state" name="{{'customisedState'+position}}" required>
                                                                    <option *ngFor="let state of stateList;" [value]="state" [selected]="state === selectedPlacement">{{state}}</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. Contact No:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'customisedContactNo'+position}}" required [(ngModel)]="vehicleObj.installation.contactNo">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. Email Address:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <label class="field__holder">
                                                                <input type="text" class="field__input" name="{{'customisedEmail'+position}}" required [(ngModel)]="vehicleObj.installation.email">
                                                            </label>

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Proposal Instl. Date:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <div class="field__icon">
                                                                <div class="field__icon-inner">
                                                                    <mat-datepicker-toggle [for]="ProposalDatepicker">
                                                                        <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                                    </mat-datepicker-toggle>
                                                                </div>
                                                            </div>

                                                            <input [matDatepicker]="ProposalDatepicker" (focus)="ProposalDatepicker.open()" (click)="ProposalDatepicker.open()" type="text" name="{{'customisedProposedDate'+position}}" [min]="minDate" class="field__input" readonly dom-states required [(ngModel)]="vehicleObj.installation.proposedDate">

                                                            <div class="field__ui"></div>
                                                        </div>

                                                        <mat-datepicker #ProposalDatepicker [touchUi]="datepickerTouchUi">
                                                        </mat-datepicker>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Propose Instl.Time:

                                                    <span class="indicator-green">*</span>
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            <div class="field__icon">
                                                                <div class="field__icon-inner">
                                                                    <img src="assets/images/scss-components/key-value-icons/icon-time-green.svg" width="24" height="24" alt="" class="field__icon-image">
                                                                </div>
                                                            </div>

                                                            <input type="time" class="field__input" name="{{'customisedProposedTime'+position}}" required [(ngModel)]="vehicleObj.installation.proposedTime">

                                                            <div class="field__ui"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__item">
                                        <hr class="divider" />
                                    </div>

                                    <div class="field-group__item --2-col">
                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Contact Status:


                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.contactStatus || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Assigned installer:

                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.assignedInstaller || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. Date:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.installationDate || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Instl. Status:

                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.installationStatus || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="field-group__groups">
                                                <div class="field-group__groups-col">
                                                    Remark:
                                                </div>

                                                <div class="field-group__groups-col">
                                                    <div class="field">
                                                        <div class="field__elems">
                                                            {{vehicleObj.remark || 'None'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block">
                    <div class="inline-button__field">
                        <button class=" button inline-button__inputs --dark-grey" type="button" (click)="navigateToAddDevice()">
                            Back
                        </button>

                        <button class="button inline-button__inputs" type="button" (click)="showPopup(updatePopup)" [disabled]="vehicleForm.invalid">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #updatePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Update?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateDevice();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #fileUploadPopup="popup" classes="--wide-more" [disableClickOutside]="true" (onClickClosed)="closeFileUploadPopup()">
    <app-upload #fileUpload [importErrorPopup]="batchImportErrorPopup"></app-upload>
    <div class="block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" *ngIf="fileUpload.fileUploadList?.length > 0" (click)="hidePopup();">
                Submit
            </button>

            <button class=" button inline-button__inputs --dark-grey" type="button" (click)="closeFileUploadPopup()">
                Cancel
            </button>
        </div>
    </div>
</app-popup>

<app-popup #batchImportErrorPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">
            <div class="cm-heading__text">
                Warning
            </div>
        </div>
    </div>
    <div class="popup__block">
        <span class="notes-input --alert" [innerHTML]="fileUpload.batchErrorMessage"></span>
    </div>
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(fileUploadPopup);">
                OK
            </button>
        </div>
    </div>
    <!-- <div class="popup__block">
        <p class="notes">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="26" height="26" class="notes__icon">
            <span class="notes__text">
                Batch Import template (.xls) can be downloaded
                <a class="link" (click)="generateExcelTemplate()">here</a>
            </span>
        </p>
    </div> -->
</app-popup>
