<app-spinner #page_spinner [fullscreen]="true"></app-spinner>

<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter">
                <div class="grid__block w-6of12@sm --space-top">
                    <h1 class="heading --level-1">
                        Notification Settings
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <!-- Temporary Hide -->
    <!-- <div class="section bgc-dark-white tabs__section">
        <div class="wrapper">
            <div class="tabs__button">
                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 1" (click)="byNotification()">
                    By Notification
                </button>

                <button type="button" class="tabs__link" [class.--is-active]="currentTab === 2" (click)="byVehicle()">
                    By Vehicle
                </button>
            </div>
        </div>
    </div> -->

    <!-- By Notification -->
    <ng-container *ngIf="currentTab === 1">
        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading --level-2 --partial-underline   indicator-green">
                        Set Email Updates
                    </h2>
                </div>

                <div class="block">
                    <div class="notification-toggles">
                        <div class="notification-toggle" *ngFor="let email of notification.email">
                            <div class="notification-toggle__main">
                                <mat-slide-toggle [color]="color" [(ngModel)]="email.isOn">
                                    {{ email.label }}
                                </mat-slide-toggle>
                            </div>

                            <div class="notification-toggle__side" *ngIf="!isDriverRole">
                                <button type="button" class="plus-button" (click)="setVehicleIdsList('email', email.name)"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section --border-top">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading --level-2 --partial-underline   indicator-green">
                        Set Push Notifications
                    </h2>
                </div>

                <div class="block">
                    <div class="notification-toggles">
                        <div class="notification-toggle" *ngFor="let push of notification.push">
                            <div class="notification-toggle__main" *ngIf="push.name !== 'DEVICE_DISCONNECTED'">
                                <mat-slide-toggle [color]="color" [(ngModel)]="push.isOn">
                                    {{ push.label }}
                                </mat-slide-toggle>
                            </div>

                            <div class="notification-toggle__side" *ngIf="!isDriverRole && push.name !== 'DEVICE_DISCONNECTED'">
                                <button type="button" class="plus-button" (click)="setVehicleIdsList('push', push.name)"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section ta-c">
            <div class="wrapper">
                <div class="inline-group">
                    <div class="inline-group__item">
                        <button type="button" class="button --min-width" (click)="showPopup(confirmationPopupForNotification)">
                            Save
                        </button>
                    </div>

                    <div class="inline-group__item">
                        <a routerLink="/" class="button --min-width --grey">
                            Cancel
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- By Vehicle -->
    <!--
    <div class="section" *ngIf="currentTab === 2">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block   w-7of12@sm">
                        <div class="field-group --unstack@xs">
                            <div class="field-group__item search-group__item">
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <img src="assets/images/scss-components/forms/icon-vehicle.svg" width="21" height="21" alt="" class="field__icon-image">
                                            </div>
                                        </div>

                                        <label class="field__holder">
                                            <input type="text" class="field__input" placeholder="Search Vehicles">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>
                                </div>

                                <div class="button__holder">
                                    <button type="button" class="button">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="table   vehicle-table">
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <button type="button" class="table__sortable">
                                        Vehicle
                                    </button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable --ascending">
                                        Number Plate
                                    </button>
                                </th>

                                <th scope="col">
                                    <button type="button" class="table__sortable --descending">
                                        Group
                                    </button>
                                </th>

                                <th scope="col">
                                    Action
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>
                                    BossPro 88
                                </td>

                                <td>
                                    WAS 5590
                                </td>

                                <td>
                                    Southeastern
                                </td>

                                <td>
                                    <a routerLink="edit/1" class="link --primary --u">
                                        Edit Notifications
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    WJJ 8800
                                </td>

                                <td>
                                    WJJ 8800
                                </td>

                                <td>
                                    KL AM Vehicle Group
                                </td>

                                <td>
                                    <a routerLink="edit/2" class="link --primary --u">
                                        Edit Notifications
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    BTS 1008
                                </td>

                                <td>
                                    BTS 1008
                                </td>

                                <td></td>

                                <td>
                                    <a routerLink="edit/3" class="link --primary --u">
                                        Edit Notifications
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    BTS 1008
                                </td>

                                <td>
                                    BTS 1008
                                </td>

                                <td></td>

                                <td>
                                    <a routerLink="edit/4" class="link --primary --u">
                                        Edit Notifications
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing 1 / 10 pages
                        </div>
                    </div>

                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination">
                            <button type="button" class="pagination__button" disabled>
                                <span class="angle-arrow --left"></span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" class="pagination__button --active">
                                1
                            </button>

                            <button type="button" class="pagination__button">
                                2
                            </button>

                            <button type="button" class="pagination__button">
                                3
                            </button>

                            <button type="button" class="pagination__button">
                                4
                            </button>

                            <button type="button" class="pagination__button">
                                5
                            </button>

                            <button type="button" class="pagination__button">
                                <span class="angle-arrow --right"></span>

                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<app-popup #confirmationPopupForNotification="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>
    <!-- <div class="popup__block">
        <p>
            You are going to update notification
        </p>
    </div> -->
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateUserNotification(); hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #notificationSettingsPopup="popup" classes="--wide-more">
    <app-vehicle-selector #vehicleNotification title="Show Notifications For" [inPopup]="true"></app-vehicle-selector>
    <div class="ta-c">
        <div class="wrapper">
            <div class="inline-group">
                <div class="inline-group__item">
                    <button type="button" class="button --min-width" (click)="showPopup(confirmationPopupForVehicle)" [disabled]="!vehicleNotification.hasModified()">
                        Save
                    </button>
                </div>

                <div class="inline-group__item">
                    <a class="button --min-width --grey" (click)="hidePopup();">
                        Cancel
                    </a>
                </div>
            </div>
        </div>
    </div>
</app-popup>

<app-popup #confirmationPopupForVehicle="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>
    <!-- <div class="popup__block">
        <p>
            You are going to update notification
        </p>
    </div> -->
    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateVehicleNotification(); hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(notificationSettingsPopup);">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>


<app-popup #driverTagPopup="popup" classes="--wide-more">
    <app-driver-tag-selector #driverTagSelector title="Add ID Tag" [inPopup]="true"></app-driver-tag-selector>
    <div class="ta-c">
        <div class="wrapper">
            <div class="inline-group">
                <div class="inline-group__item">
                    <button type="button" class="button --min-width" (click)="showPopup(confirmationPopupForDriverTag)" [disabled]="!driverTagSelector.getSelectedIds().length">
                        Save
                    </button>
                </div>

                <div class="inline-group__item">
                    <a class="button --min-width --grey" (click)="hidePopup();">
                        Cancel
                    </a>
                </div>
            </div>
        </div>
    </div>
</app-popup>

<app-popup #confirmationPopupForDriverTag="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p class="desc">
            Driver Would Be Overwrite If Selected Driver Tag Already Tied To Driver.
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="updateDriverTagNotification(); hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="showPopup(driverTagPopup);">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns"></popup-campaign>
