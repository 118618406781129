<p>
    test-image works!
</p>

<br><br><br><br>
<div class="image-upload">
    <!-- <img [src]="imageSrc" style="max-width:200px;max-height:200px" resize-max-height="80" resize-max-width="80" resize-quality="0.7" resize-type="image/jpg" ng-image-compress/> -->
    <input name="imageUrl" type="file" accept="*.png,*.jpeg,*.jpg" (change)="checkFileSize($event)" [ngModel]="image" data-max-size="1" ngf-max-size='1' />
    <button (click)="uploadImage()">Upload Image</button>
</div>

<div><button (click)="getImage()">Get Image</button>
    <label>{{token}}</label>
</div>

<div>
    <button class="button inline-button__inputs" (click)="loadLiveStream()">Live Stream Trip</button>
    <h1>Video player</h1>
    <h2>Configure: [HideVideo: {{hideVideo}}, autoPlay: {{autoPlayVideo}}]</h2>
    <div *ngIf="!hideVideo">
        <video controls [autoplay]=" autoPlayVideo" style="height: 300px;width: 500px;">
            <source #videoSource src="{{ videoUrls }}" type="video/mp4">
            <p>No Preview Available</p>
        </video>
        <video controls [autoplay]="autoPlayVideo" style="height: 300px;width: 500px;">
            <source src="assets/videos/Plants_watershot2.mp4" type="video/mp4">
            <p>No Preview Available</p>
        </video>
    </div>
</div>
<div>
    <!-- <img [src]="image" class="rounded mb-3" width="500" height="500"> -->
    <canvas id="canvas2" width="300" height="300"></canvas>
    <!-- <input type="file" multiple (change)="detectFiles($event)"> -->
    <!-- width="500" height="500" -->
</div>




<div>
    <!-- <img [src]="image" class="rounded mb-3" width="500" height="500"> -->
    <canvas id="canvas" #mycanvas></canvas>
    <!-- <input type="file" multiple (change)="detectFiles($event)"> -->
    <!-- width="500" height="500" -->
</div>

<div class="image">
    <img [src]="imageUrl" />
</div>


<div class="image">
    <button (click)="dropDownVehicle()">DropDown</button>
</div>
