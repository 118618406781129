import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as StringUtil from './../../../../util/stringUtil';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { DriverService } from './../../../../_services/driver/driver.service';
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

@Component({
    selector: 'app-driver-selector',
    templateUrl: './driver-selector.component.html',
    styleUrls: ['./driver-selector.component.scss']
})
export class DriverSelectorComponent implements OnInit {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    @Input('title') title: string = "Assign Drivers";
    @Input('adminId') adminId: number;
    @Input('selectedIds') selectedIds: Array<number>;
    @Input('withGroup') withGroup: boolean = true;
    @Input('inPopup') inPopup: boolean = false;
    @Input('excludeDeactivated') excludeDeactivated: boolean = true;

    sectionClass: string;
    wrapperClass: string;
    blockClass: string;

    //Assign Resource Variables
    driverService: DriverService;
    driverResult: any = {};

    driverSortField: any = 'firstName';
    driverSortAscending: boolean = true;
    currDriverSortField: any = 'firstName';
    currDriverSortAscending: boolean = true;
    driverFilterValue: any = '';
    currDriverFilterValue: any = '';

    driverUniqueList: Array<any> = [];
    driverIdUniqueList: Array<number> = [];
    driverIdUniqueCompleteList: Array<any> = [];
    unmodifiedSelectedDriverIdList: Array<number> = [];
    selectedDriverIdList: Array<number> = [];
    selectedDrivers: any = {};
    selectedDriverGroups: any = {};

    @ViewChild('driversField',{static:false}) driversField;
    driversModelCompleteDriversList: any = [];
    driverFilterSearchString: string = ""; //store filter search string
    isShowDriverSelectAllOption: boolean = true;

    ready: boolean = false;

    expandGroupDriver: any = [];

    constructor(private el: ElementRef,
        private snackBar: SnackBarService,
        private http: HttpClient) { }

    async ngOnInit() {
        this.sectionClass = (this.inPopup) ? '' : 'section --border-top';
        this.wrapperClass = (this.inPopup) ? '' : 'wrapper';
        this.blockClass = (this.inPopup) ? 'popup__block' : 'block';

        this.pageSpinner.show();
        try {

            //Get driver list
            this.driverService = new DriverService(this.http);
            if (this.adminId) {
                this.driverResult = await this.driverService.getGroupDriverModelByCompany(this.driverSortField, this.driverSortAscending, false, this.adminId, this.excludeDeactivated);
                this.getAssignedDrivers();
            } else {
                this.driverResult = await this.driverService.getGroupDriverModelByCompany(this.driverSortField, this.driverSortAscending, false, null, this.excludeDeactivated);
            }
            this.driversModelCompleteDriversList = this.driverResult.drivers.slice(0);
            this.getDriverListWithoutGroup();
            this.sort(this.currDriverSortField, true);
            this.ready = true;

            //Check drivers
            if (this.selectedIds && this.selectedIds.length) {
                this.setSelectedDriverIds(this.selectedIds);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    warnIfNotReady(): boolean {
        if (!this.ready) {
            console.warn('Trying to select/deselect drivers before selector component is ready. Instead, you should pass driver ids through component tag attribute.');
            return true;
        }
        return false;
    }

    private getDriverListWithoutGroup(): void {
        const list = this.driversModelCompleteDriversList;
        const uniqueDriverIds = [];
        const uniqueDriverList = [];
        for (const group of list) {
            if (Boolean(group.idList) && group.idList.length) {
                const tempDriverList = group.idList;
                for (const driver of tempDriverList) {
                    if (uniqueDriverIds.indexOf(driver.driverId) == -1) {
                        uniqueDriverIds.push(driver.driverId);
                        uniqueDriverList.push(driver);
                    }
                }
            }
        }
        this.driverUniqueList = uniqueDriverList;
        this.driverIdUniqueList = uniqueDriverIds;
        this.driverIdUniqueCompleteList = uniqueDriverIds;
    }

    private updateDriverListWithoutGroup(): void {
        const list = this.driverResult.drivers;
        let uniqueDriverIds = [];
        const uniqueDriverList = [];
        for (const group of list) {
            if (Boolean(group.idList) && group.idList.length) {
                const tempDriverList = group.idList;
                for (const driver of tempDriverList) {
                    if (uniqueDriverIds.indexOf(driver.driverId) == -1) {
                        uniqueDriverIds.push(driver.driverId);
                        uniqueDriverList.push(driver);
                    }
                }
            }
        }

        // Apply sorting
        let sortField = this.currDriverSortField;
        if (StringUtil.equalsIgnoreCase(sortField, 'firstName')) {
            sortField = 'driverName';
        }
        this.sortList(uniqueDriverList, sortField, this.currDriverSortAscending);
        uniqueDriverIds = [];
        uniqueDriverList.forEach(item => {
            uniqueDriverIds.push(item.driverId);
        });

        this.driverUniqueList = uniqueDriverList;
        this.driverIdUniqueList = uniqueDriverIds;
    }

    public hasModified(): boolean {
        return this.getAffectedCount().modified;
    }

    public getNewlySelectedDriverIds() {
        return this.getAffectedCount().insert;
    }

    public getUnmodifiedDriverIds() {
        return this.getAffectedCount().update;
    }

    public getRemovedDriverIds() {
        return this.getAffectedCount().delete;
    }

    public getAffectedCount() {
        const result = {
            insert: [],
            update: [],
            delete: [],
            modified: false
        };
        const oldIds = this.unmodifiedSelectedDriverIdList.slice(0);
        const newIds = this.selectedDriverIdList.slice(0);
        if (!oldIds) {
            return result;
        }
        for (const oldId of oldIds) {
            const f = newIds.indexOf(oldId);
            if (f == -1) {
                result.delete.push(oldId);
            } else {
                result.update.push(oldId);
                newIds.splice(f, 1);
            }
        }
        result.insert = [].concat(newIds);
        if (result.insert.length || result.delete.length) {
            result.modified = true;
        }
        return result;
    }

    public getSelectedCount(): number {
        // console.debug(this.selectedDriverIdList);
        // console.debug(this.selectedDrivers);
        // console.debug(this.driverIdUniqueList);
        // console.debug(this.driverUniqueList);
        return this.selectedDriverIdList.length;
    }

    public getSelectedVisibleCount(): number {
        let count = 0;
        for (const vId of this.selectedDriverIdList) {
            if (this.driverIdUniqueList.indexOf(vId) != -1) {
                count++;
            }
        }
        return count;
        // return this.selectedDriverIdList.length;
    }

    getSelectedDisplayText(): string {

        const selectedCount = this.getSelectedCount();
        const selectedVisibleCount = this.getSelectedVisibleCount();
        const totalCount = this.getTotalCount();
        const totalVisibleCount = this.getTotalVisibleCount();
        // let selectedHiddenCount = selectedCount - selectedVisibleCount;
        // let totalHiddenCount = totalCount - totalVisibleCount;

        let text: string = '';
        // text += `${selectedCount} / ${totalCount} driver${selectedCount > 1 ? 's' : ''} selected `;
        // if (totalCount > totalVisibleCount && totalVisibleCount > 0) {
        //     text += `(${selectedVisibleCount} / ${totalVisibleCount} visible) `;
        // }
        text += `${selectedVisibleCount} / ${totalVisibleCount} driver${selectedVisibleCount > 1 ? 's' : ''} selected `;
        if (totalCount > totalVisibleCount && totalVisibleCount > 0) {
            text += `(${selectedCount} / ${totalCount} total selected)`;
        }
        return text;
    }

    public getTotalCount(): number {
        return this.driverIdUniqueCompleteList.length;
    }

    public getTotalVisibleCount(): number {
        return this.driverIdUniqueList.length;
    }

    public getSelectedDriverIds(): Array<number> {
        return this.selectedDriverIdList.slice(0); //clone copy
    }

    public selectDriverIds(ids: Array<number>): void {
        ids = ids.filter(item => item != null);
        if (this.warnIfNotReady()) {
            return;
        }
        for (const id of ids) {
            if (this.driverIdUniqueCompleteList.indexOf(id) != -1) {
                this.selectedDrivers[id.toString()] = true;
            } else {
                delete this.selectedDrivers[id.toString()];
            }
        }
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
    }

    public setSelectedDriverIds(ids: Array<number>): void {
        ids = ids.filter(item => item != null);
        if (this.warnIfNotReady()) {
            return;
        }
        this.selectedDrivers = {};
        for (const id of ids) {
            if (this.driverIdUniqueCompleteList.indexOf(id) != -1) {
                this.selectedDrivers[id.toString()] = true;
            } else {
                delete this.selectedDrivers[id.toString()];
            }
        }
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
        this.unmodifiedSelectedDriverIdList = this.selectedDriverIdList.slice(0);
    }

    private syncSelectedDriverIdList(): void {
        const assignedDriverId: Array<number> = [];
        for (const objKey in this.selectedDrivers) {
            if (this.selectedDrivers[objKey] === true) {
                assignedDriverId.push(parseInt(objKey));
            }
        }
        this.selectedDriverIdList = assignedDriverId;
    }

    public selectAll() {
        if (this.warnIfNotReady()) {
            return;
        }
        this.selectedDriverGroups.all = true;
        this.onDriversAllSelectChange();
    }

    public deselectAll() {
        if (this.warnIfNotReady()) {
            return;
        }
        this.selectedDriverGroups.all = false;
        this.onDriversAllSelectChange();
    }

    onDriversAllSelectChange() {

        // Note: Apply to ALL drivers that are VISIBLE+HIDDEN
        const allIsSelected: boolean = this.selectedDriverGroups.all;
        if (!this.driverResult.drivers) {
            return;
        }
        //update array
        if (allIsSelected) {
            // this.selectedDriverIdList = this.driverIdUniqueCompleteList.slice(0);
            for (const vId of this.driverIdUniqueList) {
                if (this.selectedDriverIdList.indexOf(vId) == -1) {
                    this.selectedDriverIdList.push(vId);
                }
            }
        } else {
            // this.selectedDriverIdList = [];
            const newSelectedList = [];
            for (const vId of this.selectedDriverIdList) {
                if (this.driverIdUniqueList.indexOf(vId) == -1) {
                    newSelectedList.push(vId);
                }
            }
            this.selectedDriverIdList = newSelectedList;
        }
        //update object
        for (let i = 0; i < this.driverResult.drivers.length; i++) {
            const driverModel = this.driverResult.drivers[i];

            //check group
            if (driverModel.type === 'GROUP') {
                this.selectedDriverGroups[i] = allIsSelected;
            }

            //check all drivers in group
            for (let j = 0; j < driverModel.idList.length; j++) {
                this.selectedDrivers[driverModel.idList[j].driverId.toString()] = allIsSelected;
            }
        }
    }

    onDriversGroupSelectChange(groupIndex: number) {

        // Note: Apply ONLY to drivers that are VISIBLE
        const idList = this.driverResult.drivers[groupIndex].idList;
        const groupIsSelected = this.selectedDriverGroups[groupIndex];
        for (let i = 0; i < idList.length; i++) {
            this.selectedDrivers[idList[i].driverId.toString()] = groupIsSelected;
        }
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
    }

    onDriversSelectChange() {
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
    }

    private getAssignedDrivers() {
        if (!this.driverResult.drivers) {
            return;
        }
        for (let i = 0; i < this.driverResult.drivers.length; i++) {
            let allIsSelected = true;
            let groupIsSelected = true;
            const driverModel = this.driverResult.drivers[i];
            for (let j = 0; j < driverModel.idList.length; j++) {
                const eachIdList = driverModel.idList[j];
                if (eachIdList.assignedDriver === 1) {
                    this.selectedDrivers[eachIdList.driverId.toString()] = true;
                } else {
                    allIsSelected = false;
                    groupIsSelected = false;
                    this.selectedDrivers[eachIdList.driverId.toString()] = false;
                }
            }
            this.selectedDriverGroups[i] = groupIsSelected;
            this.selectedDriverGroups.all = allIsSelected;
        }
        this.fixDriversCheckBoxesStates();
    }

    filterDriversSelection(searchString: string = "") {

        this.driverFilterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.driverResult.drivers = this.driversModelCompleteDriversList;
            this.getDriverSearchBox().value = "";

            //Update withoutGroup listing
            this.updateDriverListWithoutGroup();
        } else {
            //clone deep copy
            const tempList = JSON.parse(JSON.stringify(this.driversModelCompleteDriversList));
            const newList = [];

            //do searching
            for (const group of tempList) {
                if (Boolean(group.idList) && group.idList.length) {
                    let tempDriverList = group.idList;
                    tempDriverList = tempDriverList.slice(0).filter(this.filterLogicDriverName.bind(this));
                    group.idList = tempDriverList;
                }
                if (Boolean(group.idList) && group.idList.length) {
                    newList.push(group);
                }
            }

            //Show/Hide Select all checkbox
            if (newList.length) {
                this.isShowDriverSelectAllOption = true;
            } else {
                this.isShowDriverSelectAllOption = false;
            }
            this.driverResult.drivers = newList;

            //Update withoutGroup listing
            this.updateDriverListWithoutGroup();
        }
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
    }

    private getDriverSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.driversField.nativeElement.querySelector("#driverSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }

    private filterLogicDriverName(driverItem: any): boolean {
        if (this.driverFilterSearchString) {
            const normalisedDriverName = driverItem.driverName.toLowerCase().replace(' ', '').trim();
            const normalisedFilterName = this.driverFilterSearchString.toLowerCase().replace(' ', '').trim();
            if (normalisedDriverName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }
        return true;
    }

    private fixDriversCheckBoxesStates() {

        // Note: Apply ONLY to drivers that are VISIBLE
        if (!this.driverResult.drivers) {
            return;
        }
        let allIsSelected = true;
        for (let j = 0; j < this.driverResult.drivers.length; j++) {
            const group = this.driverResult.drivers[j];
            let groupIsSelected = true;
            for (let k = 0; k < group.idList.length; k++) {
                const driver = group.idList[k];
                if (!this.selectedDrivers[driver.driverId.toString()]) {
                    allIsSelected = false;
                    groupIsSelected = false;
                    break;
                }
            }
            this.selectedDriverGroups[j] = groupIsSelected;
        }
        this.selectedDriverGroups.all = allIsSelected;
        if (this.driverResult.drivers.length == 0) {
            this.selectedDriverGroups.all = false;
        }
        this.syncSelectedDriverIdList();
    }

    async sort(sortField: string = "", skipStateChange: boolean = false) {
        sortField = sortField.trim();
        if (sortField == "") {
            return;
        }

        this.pageSpinner.show();
        try {
            if (!skipStateChange) {
                //toggle ascending/descending
                if (StringUtil.equalsIgnoreCase(sortField, this.currDriverSortField)) {
                    this.currDriverSortAscending = !this.currDriverSortAscending;
                } else {
                    this.currDriverSortAscending = true;
                }
                this.currDriverSortField = StringUtil.headToLowerCase(sortField);
            }

            // server side sorting
            // this.driverResult = await this.driverService.getGroupDriverModelByCompany(this.currDriverSortField, this.currDriverSortAscending);

            // client side sorting
            sortField = this.currDriverSortField;
            if (StringUtil.equalsIgnoreCase(sortField, 'firstName')) {
                sortField = 'driverName';
            }
            const groups = this.driverResult.drivers;
            if (groups && groups.length) {
                for (const group of groups) {
                    const driverList: Array<any> = group.idList;
                    this.sortList(driverList, sortField, this.currDriverSortAscending);
                    // if (this.currDriverSortAscending) {
                    //     driverList.sort(function (m1, m2) {
                    //         return StringUtil.sortAscending(m1, m2, sortField);
                    //     })
                    // } else {
                    //     driverList.sort(function (m1, m2) {
                    //         return StringUtil.sortDescending(m1, m2, sortField);
                    //     })
                    // }
                }
            }
            this.updateDriverListWithoutGroup();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    getSortingState(sortField: string = ""): string {
        let sortingClass = "";
        sortField = sortField.trim();
        if (sortField == "") {
            return;
        } else if (StringUtil.equalsIgnoreCase(sortField, this.currDriverSortField)) {
            sortingClass = this.currDriverSortAscending ? '--ascending' : '--descending';
        }
        return sortingClass;
    }

    sortList(list: any[], sortField: string, ascending: boolean) {
        if (ascending) {
            list.sort(function (m1, m2) {
                return StringUtil.sortAscending(m1, m2, sortField);
            });
        } else {
            list.sort(function (m1, m2) {
                return StringUtil.sortDescending(m1, m2, sortField);
            });
        }
    }

}
