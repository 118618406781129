import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';

import * as StringUtil from '../../../../util/stringUtil';
import * as Message from '../../../../constants/message';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { UserService } from './../../../../_services/user/user.service';
import { PagerService } from './../../../../_services/pager/pager.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { ActivatedRoute } from '@angular/router';
import { FileExportService } from '../../../../_services/file-export/file-export.service';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

// import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { RoleService } from '../../../../_services/role/role.service';
import { ModulePermissionService } from 'src/app/_services/access-control/module-permission.service';
import { modules } from 'src/app/constants/module-access.constant';

@Component({
    selector: 'app-view-admins',
    templateUrl: './view-admins.component.html',
    styleUrls: ['./view-admins.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ViewAdminsComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    isDeletingAdmins: boolean = false;
    selectedDeleteAdminsList: any = [];
    selectedDeleteAdmins: any = {};

    adminViewOriList: any = [];
    adminViewInfoList: any = [];

    roleOriList: any = [];
    roleInfoList: any = [];

    currentTab: number = 1;

    isDeletingRole: boolean = false;
    selectedRole: any = {};
    selectedRoleGroups: any = {};

    //Sort / Filter Driver Name
    sortField = 'UserName';
    sortAscending: boolean = true;
    filterValue = null;
    currentFilterValue = null;
    message = Message;

    //Pagination
    pager: any = {}; //Pager object
    rolePager: any = {}; //Pager object
    pageRecordSize: number = environment.appConfig.createManage.pageRecordSize_tiles;

    // Export Admin File
    exportAdminFileHeader = ["User Name", "Email", "Phone Number", "Vehicle Count", "Driver Count"];

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    //role
    //Sort / Filter Driver Name
    roleSortField = 'name';
    roleSortAscending: boolean = true;
    roleFilterValue = null;
    roleCurrentFilterValue = null;
    
    creatable = false;
    deletable = false;

    constructor(
        private popupService: PopupService,
        private userService: UserService,
        private route: ActivatedRoute,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private routerProxyService: RouterProxyService,
        private fileExportService: FileExportService,
        private popupCampaignService: PopupCampaignService,
        private roleService: RoleService,
        private mpService: ModulePermissionService,

    ) { }

    async ngOnInit() {
        if (this.route.snapshot.queryParams.roleTab == 'true') {
            //redirect from other page
            this.currentTab = 2;
            await this.listRole();
        } else {
            await this.getDisplayAdminList();
        }
        await this.checkUnviewedCampaign();

        const moduleCode = modules.CRUD_ADMIN.value;
        const permissions = await this.mpService.hasPermission(moduleCode);
        this.creatable = permissions[moduleCode].cAccess
        this.deletable = permissions[moduleCode].dAccess

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }
    async sort() {
        this.sortAscending = !this.sortAscending;
        await this.getDisplayAdminList();
    }

    showDownloadPopup(popup) {
        this.popupService.show(popup);
    }

    async searchAdminName() {
        this.currentFilterValue = this.filterValue;
        await this.getDisplayAdminList();
    }

    async getDisplayAdminList(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            //  alert(this.sortField +" ** "+ this.sortAscending+"** "+ this.currentFilterValue);
            this.adminViewOriList = await this.userService.getAdmins(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.currentFilterValue);
            this.adminViewInfoList = this.adminViewOriList.body.result;
            this.pager = this.pagerService.getPager(this.adminViewOriList.body.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.adminViewInfoList.forEach(admin => {
                admin.Username = StringUtil.toTitleCase(admin.Username);
                admin.shortName = StringUtil.getShortName(admin.Username);
                admin.hasUserNameBool = StringUtil.isNotEmptyOrNull(admin.Username);
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async deleteAdmin() {
        this.pageSpinner.show();
        try {
            this.selectedDeleteAdminsList = [];
            for (const objKey in this.selectedDeleteAdmins) {
                if (this.selectedDeleteAdmins[objKey] === true) {
                    this.selectedDeleteAdminsList.push(parseInt(objKey));
                }
            }
            const result = await this.userService.deleteAdmins(this.selectedDeleteAdminsList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Admin');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Admin');

                this.cancelResetDelete();
                await this.getDisplayAdminList();
            }
            this.snackBar.openGenericSnackBar(msg);

            // if (result.statusCode == "FAILED") {
            //     let adminName = '';
            //     let userCount = 0;
            //     if (result.body.userFullName) {
            //         adminName = result.body.userFullName;
            //         userCount = 1;
            //     } else if (result.body.userIds.length) {
            //         userCount = result.body.userIds.length;
            //     }
            //     let params = {
            //         name: adminName,
            //         status: 'FAILED',
            //         action: 'DELETE',
            //         type: 'ADMIN',
            //         count: userCount
            //     }
            //     this.snackBar.openSnackBar(params);
            // } else {
            //     let adminName = '';
            //     let userCount = 0;
            //     if (result.body.userFullName) {
            //         adminName = result.body.userFullName;
            //         userCount = 1;
            //     } else if (result.body.userIds.length) {
            //         userCount = result.body.userIds.length;
            //     }
            //     let params = {
            //         name: adminName,
            //         status: 'SUCCESS',
            //         action: 'DELETE',
            //         type: 'ADMIN',
            //         count: userCount
            //     }
            //     this.snackBar.openSnackBar(params);
            // }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    countSelectedAdmins() {
        return Object.keys(this.selectedDeleteAdmins).filter(result => this.selectedDeleteAdmins[result] === true).length;
    }

    navigateToCreateAdmin() {
        this.routerProxyService.navigate(['/create-manage/admins/create']);
    }

    navigateToEditAdmin(adminId) {
        if (!this.isDeletingAdmins) {
            this.routerProxyService.navigate(['/create-manage/admins/edit', adminId]);
        }
    }

    async deleteRole() {
        this.pageSpinner.show();
        try {
            const selectedDeleteRoleList = [];
            for (const objKey in this.selectedRole) {
                if (this.selectedRole[objKey] === true) {
                    for (let i = 0; i < this.roleInfoList.length; i++) {
                        const roleModel = this.roleInfoList[i];
                        if (roleModel.id === parseInt(objKey) && roleModel.defaultAdmin === 'N') {
                            //only not default role can delete
                            selectedDeleteRoleList.push(parseInt(objKey));
                            break;
                        }
                    }
                }
            }

            if (selectedDeleteRoleList.length > 0) {
                const result = await this.roleService.deleteRole(selectedDeleteRoleList);

                let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Role');
                if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Role');

                    this.cancelResetDelete();

                    // retrieve updated result
                    await this.listRole();
                }
                this.snackBar.openGenericSnackBar(msg);
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    countSelectedRole() {
        return Object.keys(this.selectedRole).filter(result => this.selectedRole[result] === true).length;
    }

    navigateToCreateRole() {
        this.routerProxyService.navigate(['/create-manage/admins/create-role']);
    }

    navigateToEditRole(roleId) {
        this.routerProxyService.navigate(['/create-manage/admins/edit-role', roleId]);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    cancelResetDelete(closeDeletePanel: boolean = true): void {
        this.isDeletingAdmins = !closeDeletePanel;
        this.selectedDeleteAdmins = {};
        this.isDeletingRole = !closeDeletePanel;
        this.selectedRole = {};
        this.selectedRoleGroups['all'] = false;
        // this.selectedMaintenance = {};
        // this.selectedMaintenanceGroups['all'] = false;
    }

    async exportAdminFile(fileType) {
        const exportAdminViewResponse: any = await this.userService.getAdmins(0, 0, "UserName", this.sortAscending, []);
        const exportAdminViewInfoList = exportAdminViewResponse.body.result;

        const data = [];
        for (let i = 0; i < exportAdminViewInfoList.length; i++) {
            const tempRow = [
                exportAdminViewInfoList[i].Username || '',
                exportAdminViewInfoList[i].Email || '',
                exportAdminViewInfoList[i].TelNo || '',
                exportAdminViewInfoList[i].VisibleVehicleCount || '',
                exportAdminViewInfoList[i].VisibleDriverCount || ''
            ];
            data.push(tempRow);
        }

        if (fileType === 'excel') {
            const excelData: any = this.fileExportService.mergeHeaderAndDataForExcel(data, this.exportAdminFileHeader);
            await this.fileExportService.exportExcelFile(excelData, "Admin_Report_Excel");
        } else if (fileType === 'csv') {
            this.fileExportService.exportCsvFile(data, this.exportAdminFileHeader, "Admin_Report_Csv");
        } else if (fileType === 'pdf') {
            this.fileExportService.exportPdfFile(data, this.exportAdminFileHeader, "Admin_Report_Pdf", "Admin Report", null, "landscape");
        }
    }

    async viewAdmin() {
        this.currentTab = 1;
        await this.getDisplayAdminList();
    }

    async viewRoles() {
        this.currentTab = 2;
        await this.listRole();
    }

    getSortingStatus(roleSortName) {
        return roleSortName === this.roleSortField ? (this.roleSortAscending ? '--ascending' : '--descending') : '';
    }

    async roleSort(roleSortName) {
        if (roleSortName === this.roleSortField) {
            this.roleSortAscending = !this.roleSortAscending;
        } else {
            this.roleSortField = roleSortName;
            this.roleSortAscending = true;
        }
        await this.listRole();
    }

    async listRole(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            //  alert(this.sortField +" ** "+ this.sortAscending+"** "+ this.currentFilterValue);
            this.roleOriList = await this.roleService.listRole(this.pageRecordSize, startRecord, this.roleSortField, this.roleSortAscending, this.roleCurrentFilterValue);
            this.roleInfoList = this.roleOriList.body.result;
            this.rolePager = this.pagerService.getPager(this.roleOriList.body.totalRecord, page, this.pageRecordSize);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    onRoleAllSelectChange() {
        const allIsSelected = this.selectedRoleGroups.all;
        for (let i = 0; i < this.roleInfoList.length; i++) {
            const roleModel = this.roleInfoList[i];
            if (roleModel.defaultAdmin === 'N') {
                //only not default role can delete
                this.selectedRole[roleModel.id] = allIsSelected;
            }
        }
    }

    onRoleSelectChange(roleSelected: boolean) {
        if (roleSelected) {
            // this.selectedDrivingLimit[drivingLimitModel.vehicleId] = true;
        } else {
            this.selectedRoleGroups['all'] = false;
        }
    }
}
