import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import * as StringUtil from '../../../../util/stringUtil';
import * as moment from 'moment';
import * as Message from '../../../../constants/message';
import { TagService } from './../../../../_services/tag/tag.service';
import { PagerService } from './../../../../_services/pager/pager.service';
// import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';

import { modules } from '../../../../constants/module-access.constant';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

@Component({
    selector: 'app-view-trip-tags',
    templateUrl: './view-trip-tags.component.html',
    styleUrls: ['./view-trip-tags.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ViewTripTagsComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    isDeletingTags: boolean = false;
    selectedDeleteTagsList: any = [];
    selectedDeleteTags: any = {};

    tagViewOriList: any = [];
    tagViewInfoList: any = [];

    //Sort / Filter Tag Name
    sortField = 'Name';
    sortAscending: boolean = true;
    filterValue = null;
    currentFilterValue = null;
    moment = moment;
    message = Message;

    //Pagination
    pager: any = {}; //Pager object
    pageRecordSize: number = environment.appConfig.createManage.pageRecordSize_tiles;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;

    constructor(
        private popupService: PopupService,
        private tagService: TagService,
        // private route: ActivatedRoute,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService

    ) { }

    async ngOnInit() {
        await this.getDisplayTagList();
        await this.checkUnviewedCampaign();
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_TRIP_TAG.value);
        this.creatable = permissions[modules.CRUD_TRIP_TAG.value].cAccess;
        this.editable = permissions[modules.CRUD_TRIP_TAG.value].eAccess;
        this.deletable = permissions[modules.CRUD_TRIP_TAG.value].dAccess;

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async sort() {
        this.sortAscending = !this.sortAscending;
        await this.getDisplayTagList();
    }

    async searchTagName() {
        this.currentFilterValue = this.filterValue;
        await this.getDisplayTagList();
    }

    async getDisplayTagList(page: number = 1) {
        this.pageSpinner.show();
        try {

            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            this.tagViewOriList = await this.tagService.getTags(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.currentFilterValue);
            this.tagViewInfoList = this.tagViewOriList.body.result;

            this.pager = this.pagerService.getPager(this.tagViewOriList.body.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.tagViewInfoList.forEach(tag => {
                tag.name = StringUtil.toTitleCase(tag.name);
                tag.shortName = StringUtil.getShortName(tag.name);
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    countSelectedTags() {
        return Object.keys(this.selectedDeleteTags).filter(result => this.selectedDeleteTags[result] === true).length;
    }

    async loadPage() {

        this.sortAscending = true;
        this.filterValue = '';
        this.cancelResetDelete();
        await this.getDisplayTagList();
    }

    navigateToCreateTripTags() {
        this.routerProxyService.navigate(['/create-manage/trip-tags/create']);
    }

    navigateToEditTag(tagId) {
        if (!this.isDeletingTags) {
            this.routerProxyService.navigate(['/create-manage/trip-tags/edit', tagId]);
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async deleteTags() {
        this.pageSpinner.show();
        try {
            this.selectedDeleteTagsList = [];
            for (const objKey in this.selectedDeleteTags) {
                if (this.selectedDeleteTags[objKey] === true) {
                    // tslint:disable-next-line:radix
                    this.selectedDeleteTagsList.push(parseInt(objKey));
                }
            }

            const result = await this.tagService.deleteTags(this.selectedDeleteTagsList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Tag');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Tag');

                this.cancelResetDelete();
                await this.getDisplayTagList();
            }
            this.snackBar.openGenericSnackBar(msg);

            // if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
            //     // msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Tag');
            //     let name = '';
            //     let count = 0;
            //     if (result.body.name) {
            //         name = result.body.name;
            //         count = 1;
            //     } else if (result.body.count) {
            //         count = result.body.count;
            //     }
            //     const params = {
            //         name: name,
            //         status: 'SUCCESS',
            //         action: 'DELETE',
            //         count: count,
            //         type: 'Tag'
            //     };
            //     this.snackBar.openSnackBar(params);
            //     this.selectedDeleteTags = {};
            //     await this.getDisplayTagList();
            // } else {
            //     let name = '';
            //     let count = 0;
            //     if (result.body.name) {
            //         name = result.body.name;

            //         count = 1;
            //     } else if (result.body.count) {
            //         count = result.body.count;
            //     }
            //     const params = {
            //         name: name,
            //         status: 'FAILED',
            //         action: 'DELETE',
            //         count: count,
            //         type: 'Tag'
            //     };
            //     this.snackBar.openSnackBar(params);
            // }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    cancelResetDelete(closeDeletePanel: boolean = true): void {
        this.isDeletingTags = !closeDeletePanel;
        this.selectedDeleteTags = {};
        // this.selectedMaintenance = {};
        // this.selectedMaintenanceGroups['all'] = false;
    }
}
