import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { RouterProxyService } from './../../../../../../_services/router-proxy/router-proxy.service';
import { CompanyRegistrationSharedService } from '../../../../../../shared/service/company-registration-shared.service';
import { CompanyRegistrationService } from './../../../../../../_services/company-registration/company-registration.service';
import * as moment from 'moment';
import * as Message from './../../../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../../../constants/responseStatusCode';
import { installationType } from '../../../../../../constants/installationType.constant';
import { CustomizeDeviceService } from 'src/app/_services/customize-device/customize-device.service';
import { PopupService } from '../../../../../../components/common/popup/popup.service';
import { SnackBarService } from './../../../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../../../../components/common/spinner/spinner.component';
import { UploadComponent } from '../upload/upload.component';

import { VehicleService } from './../../../../../../_services/vehicle/vehicle.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-vehicle-details',
    templateUrl: './vehicle-details.component.html',
    styleUrls: ['./vehicle-details.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ]),
        trigger('slideToggle', [
            transition(':enter', [
                style({ height: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ height: '*' }))
            ]),
            transition(':leave', [
                style({ height: '*' }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ height: 0 }))
            ])
        ])
    ]
})
export class VehicleDetailsComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    backupCompanyObj: any;
    companyObj: any = null;
    channelObj: any;
    region: string;
    rfDate: string;
    cmssId: string;
    vehicleObjList = [];
    isCreate: string = "CREATE";
    customFileType: Array<string> = ["xlsx", "xls", "csv", "pdf"];
    defaultProInstDate: any =null;
    vehicleObj: any = {
        "isActive": true,
        "deviceProviderId": null,
        "installationType": installationType.WIRED.code,
        "vehicleType": null,
        "vehicleModelId": null,
        "vehicleMakeId": null,
        "vehicleModelDetails": null,
        "vehicleYear": null,
        "numberPlate": null,
        "imei": null,
        "msisdn": null,
        "iccid": null,
        "mob": "Y",
        "mesId": null,
        "roaming": "Y",
        "isManualOdometer": "N",
        "region": null,
        "delivery": {
            "picName": null,
            "address": null,
            "postcode": null,
            "state": null,
            "contactNo": null,
            "email": null
        },
        "installation": {
            "picName": null,
            "address": null,
            "postcode": null,
            "state": null,
            "contactNo": null,
            "email": null,
            "proposedDate": "10:30",
            "proposedTime": this.defaultProInstDate
        },
        "installationRemarks": null
    };

    //list for dropdown
    yearList: any = [];
    deviceProviderList: any = [];
    vehicleTypeList: any = ['Car', 'Lorry', 'Truck', 'Motorbike', 'Buggy', 'Others'];
    vehicleMakeList: any = [];
    vehicleModelList: any = [[]];
    confirmDelVehiclePosition: number = null;
    stateList: any = ['Johor', 'Kedah', 'Kelantan', 'Kuala Lumpur', 'Labuan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Penang', 'Perak', 'Perlis', 'Putrajaya', 'Sabah', 'Sarawak', 'Selangor', 'Terengganu'];

    //list of device installation config
    vehicleService: VehicleService;
    excludeVehicleDeactivated: boolean = true; // filter inactive vehicle/imei
    subjectSelectionFloaterIsShown: boolean = false;
    filterSearchString: string = ""; //store filter search string
    isShowSelectAllOption: boolean = true;

    searchBy: string = "devices";
    @ViewChild('devicesField', { static: false }) devicesField;
    customizeDevicesModelComplateDeviceList: any = [];
    customizeDevicesModel: any = {};
    selectedDevices: any = {};
    selectedDeviceGroups: any = {};
    selectedDevicesList: any = [];
    deviceSelectedLabel: string = `All Devices`;
    expandGroupDevice: any = [];
    isBtmBorderEdge = true;
    minDate = moment(new Date()).format('YYYY-MM-DD');
    
    selectInstallationType: string = installationType.WIRED.code; // Default WIRED

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild('addDevicePopup',{static:false}) addDevicePopup;
    @ViewChild('reminderPopup',{static:false}) reminderPopup;
    @ViewChild('fileUpload',{static:false}) fileUpload: UploadComponent;

    constructor(
        private routerProxyService: RouterProxyService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private companyRegistrationSharedService: CompanyRegistrationSharedService,
        private companyRegistrationService: CompanyRegistrationService,
        private custDeviceSrv: CustomizeDeviceService,
        private http: HttpClient
    ) { }

    async addDevice() {
        try {
            this.pageSpinner.show();
            if (this.companyObj && this.vehicleObjList.length > 0) {
                //formed object structure that required by back end
                this.vehicleObjList.map(vehicle => {
                    vehicle.region = this.region;
                    if (vehicle.installationType !== installationType.PLUG_PLAY.code) { // && vehicle.installationType !== installationType.PLUG_PLAY_COURIER.code) {
                        if (moment(vehicle.installation.proposedDate).isValid()) {
                            vehicle.installation.proposedDate = moment(vehicle.installation.proposedDate).format("YYYY-MM-DD");
                        }
                        delete vehicle.delivery;
                        delete vehicle.imei;
                        delete vehicle.msisdn;
                        delete vehicle.iccid;
                        if (vehicle.installationType === installationType.CUSTOM.code) {
                            delete vehicle.mob;
                            vehicle.customDevicesList = this.selectedDevicesList;
                        }
                    } else {
                        delete vehicle.installation;
                    }
                    delete vehicle.isActive;
                    vehicle.deviceProviderId = Number(vehicle.deviceProviderId);
                    vehicle.vehicleModelId = Number(vehicle.vehicleModelId) !== -1 ? Number(vehicle.vehicleModelId) : null ;
                    vehicle.vehicleMakeId = Number(vehicle.vehicleMakeId);
                    vehicle.vehicleYear = vehicle.vehicleYear === 0 ? null : Number(vehicle.vehicleYear);
                });

                const fileInBytes = [];
                const fileReader = new FileReader();
                for (let i = 0; i < this.fileUpload.fileUploadList.length; i++) {
                    const file = this.fileUpload.fileUploadList[i];
                    const reader = new Promise((resolve, reject) => {
                        fileReader.readAsDataURL(file);
                        fileReader.onload = async (e) => {
                            resolve(fileReader.result);
                        };
                    });
                    const base64 = await reader;
                    const formattedFile = {
                        fileByte: base64,
                        fileName: file.name
                    };
                    fileInBytes.push(formattedFile);
                }

                const formattedReq = {
                    rfDate: this.rfDate,
                    cmssId: this.cmssId,
                    company: this.companyObj,
                    channel: this.channelObj,
                    vehicles: this.vehicleObjList,
                    files: fileInBytes
                };

                const result = await this.companyRegistrationService.addDevice(formattedReq);

                const companyName = this.companyObj.name;

                let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Company', companyName);
                if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Company', companyName);
                    this.navigateSuccessToCompanyView();
                }
                this.snackBar.openGenericSnackBar(msg);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
            for (let i = 0; i < this.vehicleObjList.length; i++) {
                const vehicle = this.vehicleObjList[i];
                vehicle.isActive = true;
                //to avoid undefined error if change type after hit error
                if (vehicle.installationType !== installationType.PLUG_PLAY.code) { // && vehicle.installationType !== installationType.PLUG_PLAY_COURIER.code) {
                    vehicle.delivery = {};
                    vehicle.delivery.picName = null;
                    vehicle.delivery.address = null;
                    vehicle.delivery.contactNo = null;
                    vehicle.delivery.email = null;
                } else {
                    vehicle.installation = {};
                    vehicle.installation.picName = null;
                    vehicle.installation.address = null;
                    vehicle.installation.contactNo = null;
                    vehicle.installation.email = null;
                    vehicle.installation.proposedDate = null;
                    vehicle.installation.proposedTime = null;
                }
            }
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToCompanyView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/support/manage-device']);
    }

    navigateToAddDevice() {
        if (this.backupCompanyObj) {
            //set back record for previous page use
            this.companyRegistrationSharedService.setCompanyObj(this.backupCompanyObj);
        }
        const newOrder = false;
        this.routerProxyService.navigate(['/support/manage-device/add-device/company-details',newOrder]);
    }

    ngOnInit() {
        this.initCommonFunction();
    }

    async initCommonFunction() {
        try {
            
            //default proposed installation date
            if(moment().day()===6){
                this.vehicleObj.installation.proposedDate = moment().add(2,'days').toDate();
                this.defaultProInstDate = this.vehicleObj.installation.proposedDate;
            }else if(moment().day()===0){
                this.vehicleObj.installation.proposedDate = moment().add(1,'days').toDate();
                this.defaultProInstDate = this.vehicleObj.installation.proposedDate;
            }else{
                this.vehicleObj.installation.proposedDate = moment().toDate();
                this.defaultProInstDate = this.vehicleObj.installation.proposedDate;
            }
            this.vehicleObj.installation.proposedTime = "10:30";
            //get company obj pass from company tab
            this.companyObj = JSON.parse(JSON.stringify(this.companyRegistrationSharedService.getCompanyObj()));
            this.backupCompanyObj = JSON.parse(JSON.stringify(this.companyObj));
            this.companyRegistrationSharedService.setCompanyObj(null);
            //dont have company obj then return back to company tab
            if (!this.companyObj) {
                this.navigateToAddDevice();
                return;
            }

            //separate fields from object for later use
            this.channelObj = this.companyObj.channel;
            this.channelObj.channelTypeId = Number(this.channelObj.channelTypeId);
            this.companyObj.id = Number(this.companyObj.id);
            if (moment(this.companyObj.rfDate).isValid()) {
                this.rfDate = moment(this.companyObj.rfDate).format("YYYY-MM-DD");
            }
            this.cmssId = this.companyObj.cmssId;
            delete this.companyObj.cmssId;
            delete this.companyObj.channel;
            delete this.companyObj.rfDate;
            delete this.companyObj.type;
            this.region = this.channelObj.region;
            delete this.channelObj.region;

            //initiate a new empty vehicle
            this.vehicleObjList.push(JSON.parse(JSON.stringify(this.vehicleObj)));

            this.getYearList(40); //default get 40 years
            //initialize drop down selection
            const deviceProviderResponse = await this.companyRegistrationService.getDeviceProviderList();
            this.deviceProviderList = deviceProviderResponse.body.result;
            this.deviceProviderList = this.deviceProviderList.filter((element, index) => element.id == 3) // temporarily show Maxis mDrive only
            this.deviceProviderList.map(deviceProvider=>{
                if(deviceProvider.defaultPartner){
                    this.vehicleObj.deviceProviderId = Number(deviceProvider.id);
                }
            });

            this.vehicleObjList.map(vehicleObj=>{
                vehicleObj.deviceProviderId = this.vehicleObj.deviceProviderId;
            })

            const vehicleMakeResponse = await this.companyRegistrationService.getVehicleMakeList();
            this.vehicleMakeList = vehicleMakeResponse.body.result;

            const sortField = "DeviceId";
            const sortAscending = true;
            let custDeviceRes = await this.custDeviceSrv.getAllCustomizeDevice(sortField, sortAscending, this.excludeVehicleDeactivated);
            this.customizeDevicesModel = custDeviceRes.body;
            this.customizeDevicesModelComplateDeviceList = this.customizeDevicesModel.devices.slice(0);
            this.selectedDeviceGroups.all = false;
            this.onDevicesAllSelectChange();
            
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    duplicateVehicle() {
        const newVehicle = JSON.parse(JSON.stringify(this.vehicleObjList[this.vehicleObjList.length - 1]));
        this.vehicleObjList.push(newVehicle);
        this.vehicleModelList.push(JSON.parse(JSON.stringify(this.vehicleModelList[this.vehicleModelList.length - 1])));
    }

    deleteVehicle() {
        const vehicleAfterRemovedList = this.vehicleObjList.filter((eachVehicle, index) => {
            return this.confirmDelVehiclePosition != index;
        });
        this.vehicleObjList = vehicleAfterRemovedList;
        this.confirmDelVehiclePosition = null;
    }

    confirmToDelVehicle(position) {
        this.confirmDelVehiclePosition = position;
    }

    clearConfirmToDelVehicle() {
        this.confirmDelVehiclePosition = null;
    }

    async getVehicleModelList(vehicleMakeId, position) {
        const vehicleModelResponse = await this.companyRegistrationService.getVehicleModelList(vehicleMakeId);
        this.vehicleModelList[position] = vehicleModelResponse.body.result;
        //vehicle make changed, empty previous selected model
        this.vehicleObjList[position].vehicleModelId = null;
    }

    installationTypeChange(event, type, position) {
        this.selectInstallationType = type;
        const target = event.target;
        const vehicleTriggered = this.vehicleObjList[position];
        if (target.checked) {
            if (type === 'wired') {
                vehicleTriggered.delivery = {};
                vehicleTriggered.delivery.picName = null;
                vehicleTriggered.delivery.address = null;
                vehicleTriggered.delivery.contactNo = null;
                vehicleTriggered.delivery.email = null;
                vehicleTriggered.imei = null;
                vehicleTriggered.msisdn = null;
                vehicleTriggered.iccid = null;
                vehicleTriggered.installation.proposedTime = "10:30";
                vehicleTriggered.installation.proposedDate = this.defaultProInstDate;
                vehicleTriggered.mob = 'Y'; //default value
            } else if (type === 'plugnplay') { // || type === 'plugnplaycourier') {
                vehicleTriggered.installation = {};
                vehicleTriggered.installation.picName = null;
                vehicleTriggered.installation.address = null;
                vehicleTriggered.installation.contactNo = null;
                vehicleTriggered.installation.email = null;
                vehicleTriggered.installation.proposedDate = null;
                vehicleTriggered.installation.proposedTime = null;
                vehicleTriggered.mob = 'Y'; //default value
            } else if (type === 'customised') {
                vehicleTriggered.delivery = {};
                vehicleTriggered.delivery.picName = null;
                vehicleTriggered.delivery.address = null;
                vehicleTriggered.delivery.contactNo = null;
                vehicleTriggered.delivery.email = null;
                vehicleTriggered.imei = null;
                vehicleTriggered.msisdn = null;
                vehicleTriggered.iccid = null;
                vehicleTriggered.mob = null;
                vehicleTriggered.installation.proposedTime = "10:30";
                vehicleTriggered.installation.proposedDate = this.defaultProInstDate;
            }
        }
    }

    getYearList(yearNumber: number) {
        let currentYear = parseInt(moment().format('YYYY'));
        for (let i = 0; i < yearNumber; i++) {
            this.yearList.push(currentYear);
            currentYear = currentYear - 1;
        }
    }

    submitFunction() {
        //to decide show reminder pop up or not
        const vehicleFound = this.vehicleObjList.find(vehicle => (vehicle.installationType === installationType.PLUG_PLAY.code)); // || vehicle.installationType === installationType.PLUG_PLAY_COURIER.code));
        if (vehicleFound) {
            //have at least 1 plug and play then show reminder pop up
            this.popupService.show(this.reminderPopup);
        } else {
            this.popupService.show(this.addDevicePopup);
        }
    }

    toggleVehicleAccordian(index) {
        if (this.vehicleObjList[index].isActive) {
            this.vehicleObjList[index].isActive = false;
        } else {
            this.vehicleObjList[index].isActive = true;
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    async closeFileUploadPopup() {
        //clear uploaded file
        this.fileUpload.fileUploadList = [];
        this.hidePopup();
    }

    /* ---- Device installtion method ---- */
    toggleSubjectSelectionFloater(bool: boolean) {
        this.subjectSelectionFloaterIsShown = bool;
    }

    /* ---- Device Selection Floater ---- */
    onDevicesAllSelectChange() {
        // Note: Apply to ALL devices that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedDeviceGroups.all;
        for (let i = 0; i < this.customizeDevicesModelComplateDeviceList.length; i++) {
            const deviceModel = this.customizeDevicesModelComplateDeviceList[i];
            for (let j = 0; j < deviceModel.idList.length; j ++) {
                this.selectedDevices[deviceModel.idList[j].modelId] = allIsSelected;
            }
        }
        this.updateDevicesSelectedLabel();
    }

    onDevicesSelectChange(modelId: number, deviceId: number) {
        this.fixDevicesCheckBoxesStates(modelId, deviceId); // Tick correct checkboxes
        this.updateDevicesSelectedLabel();
        this.focusDeviceSearchBox();
    }

    private updateDevicesSelectedLabel() {
        this.selectedDevicesList = [];
        for (let j = 0; j < this.customizeDevicesModelComplateDeviceList.length; j++) {
            const group = this.customizeDevicesModelComplateDeviceList[j];

            for (let k = 0; k < group.idList.length; k++) {
                let selectedModel: any = {};
                const device = group.idList[k];
                if (this.selectedDevices[device.modelId]) {
                    selectedModel.deviceId = group.deviceId;
                    selectedModel.modelId = device.modelId;
                    this.selectedDevicesList.push(selectedModel);
                }
            }
        }
        const totalDevices = this.selectedDevicesList.length;

        if (this.selectedDeviceGroups.all) {
            this.deviceSelectedLabel = `All devices selected (${totalDevices})`;
        } else {
            if (totalDevices === 0) {
                this.deviceSelectedLabel = 'Please select device';
            } else if (totalDevices === 1) {
                this.deviceSelectedLabel = '1 device selected';
            } else {
                this.deviceSelectedLabel = totalDevices + ' devices selected';
            }
        }
    }

    private getDeviceSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.devicesField.nativeElement.querySelector("#deviceSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }

    onOutsideClickDeviceReaction(clickEvent: Event): void {
        this.toggleSubjectSelectionFloater(false);
        this.filterDevicesSelection();
    }

    filterDevicesSelection(searchString: string = "") {
        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.isShowSelectAllOption = true;
            this.customizeDevicesModel.devices = this.customizeDevicesModelComplateDeviceList;
            this.getDeviceSearchBox().value = "";
        } else {
            this.isShowSelectAllOption = false;
            //clon deep copy
            const tempList = JSON.parse(JSON.stringify(this.customizeDevicesModelComplateDeviceList));
            const newList = [];
            //do searching
            for (const group of tempList) {
                
                // Filter by device name
                const normalisedDeviceName = group.name.toLowerCase().replace(' ', '').trim();
                const normalisedFilterName = this.filterSearchString.toLowerCase().replace(' ', '').trim();
                if (normalisedDeviceName.includes(normalisedFilterName) > 0) {
                    newList.push(group);
                } else {
                    // Filter by model name
                    if (Boolean(group.idList) && group.idList.length) {
                        let tempDeviceList = group.idList;
                        tempDeviceList = tempDeviceList.slice(0).filter(this.filterLogicModelName.bind(this));
                        if (tempDeviceList.length) {
                            group.idList = tempDeviceList;
                            newList.push(group);
                        }
                    }
                }
            }
            this.customizeDevicesModel.devices = newList;
        }
    }

    private filterLogicModelName(deviceItem: any): boolean {
        if (this.filterSearchString) {
            const normalisedModelName = deviceItem.modelName.toLowerCase().replace(' ', '').trim();
            const normalisedFilterName = this.filterSearchString.toLowerCase().replace(' ', '').trim();
            if (normalisedModelName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }
        return true;
    }

    private focusDeviceSearchBox() {
        const searchBox = this.getDeviceSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }

    private fixDevicesCheckBoxesStates(modelId: number, deviceId: number) {
        const devices = this.customizeDevicesModelComplateDeviceList.find(result => result.deviceId === deviceId);
        for (let i = 0; i < devices.idList.length; i++) {
            // Every device only can select one model, other model under same deviceId set to false
            if (devices.idList[i].modelId !== modelId) {
                this.selectedDevices[devices.idList[i].modelId] = false;
            }
        } 
    }
}
