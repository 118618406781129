<div class="login">
    <div class="login__layout">
        <div class="login__header">
            <button type="button" (click)="currentView = 'login'">
                <img [src]="imageFolder + 'logo-mDrive.svg'" alt="MDrive" class="login__logo">
            </button>
        </div>
        <app-spinner #page_spinner [fullscreen]="true"></app-spinner>
        <ng-container *ngIf="currentView === 'changePassword'">
            <div class="login__body reset-pw">
                <div class="login__body-top">
                    <h1 class="heading   login__heading">
                        {{ status }}
                    </h1>
                    <p>
                        {{ instruction }}
                    </p>
                </div>
                <p>Username:<br>{{ username }}</p>
                <br />
                <form class="form   login__form">

                    <div class="form__row field-holder">
                        <div class="field-label">
                            Password: <span class="indicator-green">*</span>
                        </div>

                        <div class="field-input">
                            <div class="field">
                                <div class="field__elems">
                                    <!--                                <input type="password" class="field__input" formControlName="password"
                                        [class.--error]="submitted && f.password.errors" #password="ngModel"
                                        [(ngModel)]="formModel.password" [ngModelOptions]="{standalone: true}"
                                        name="password">
    -->
                                    <label class="field__holder">
                                        <input type="password" class="field__input" #password="ngModel" [(ngModel)]="formModel.password" name="password" [ngModelOptions]="{standalone: true}" autocomplete="new-password" required tabindex="1">
                                    </label>

                                    <div class="field__ui"></div>
                                </div>
                            </div>

                            <div class="field-error" *ngIf="!!password.errors?.required && (!!password.dirty || !!password.touched)">
                                Password is required.
                            </div>

                            <div class="password-indicator">
                                <div class="password-strength">
                                    Password strength: <b>{{ formModel.passwordStrength }}</b>
                                </div>

                                <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)" [password]="password.value">
                                </mat-password-strength>
                            </div>
                        </div>

                        <div class="field__icon">
                            <div class="field__icon-inner">
                                <div class="toggle__item-groups" [class.--active]="toggle.active">
                                    <button class="toggle__item-groups-toggle" toggle #toggle="toggle">
                                        <img src="assets/images/scss-components/forms/icon-question.svg" width="26" height="26" alt="" class="field__icon-image">
                                    </button>

                                    <div class="toggle__item-groups-floater">
                                        <button class="toggle__item-groups-close" (click)="toggle.setInactive()"></button>

                                        <div class="toggle__item-groups-contents">
                                            Password must be a minimum of 8 characters and contain the following:<br><br>

                                            <ul>
                                                <li> At least 1 upper case letter (A, B, C...Z)</li>
                                                <li>At least 1 lower case letter (a, b, c...z)</li>
                                                <li>At least 1 number (1, 2, 3...9)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <br />

                    <div class="form__row">
                        <div class="field-label">
                            Confirm Password: <span class="indicator-green">*</span>
                        </div>

                        <div class="field">
                            <div class="field__elems">
                                <label class="field__holder">
                                    <!--                                <input type="password" class="field__input" formControlName="confirmPassword"
                                        [class.--error]="submitted && f.confirmPassword.errors">-->
                                    <input type="password" class="field__input" #confirmPassword="ngModel" [(ngModel)]="formModel.confirmPassword" name="confirmPassword" [ngModelOptions]="{standalone: true}" autocomplete="new-password" required [class.--error]="!confirmPassword.errors?.required && formModel.password !== formModel.confirmPassword && (!!confirmPassword.dirty || !!confirmPassword.touched)" tabindex="2">
                                </label>
                                <div class="field__ui"></div>
                            </div>
                        </div>
                        <div class="field-error" *ngIf="!!confirmPassword.errors?.required && (!!confirmPassword.dirty || !!confirmPassword.touched)">
                            Confirm Password is required.
                        </div>
                        <div class="field-error" *ngIf="!confirmPassword.errors?.required && formModel.password !== formModel.confirmPassword && (!!confirmPassword.dirty || !!confirmPassword.touched)">
                            Passwords do not match.
                        </div>
                    </div>

                    <div class="form__row --space-more">
                        <button type="button" [disabled]="loading" class="button --min-width" (click)="changePassword()" tabindex="3">
                            <mat-spinner *ngIf="loading" diameter="18" class="--light --center"></mat-spinner>
                            <span *ngIf="!loading">
                                {{ buttonName }}
                            </span>
                        </button>
                        <div class="field-error" *ngIf="error">
                            {{ error }}
                        </div>
                    </div>
                </form>
            </div>
        </ng-container>
        <ng-container *ngIf="currentView === 'invalid'">
            <div class="login__body">
                <div class="login__body-top">
                    <p>
                        Verifying the link...
                    </p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="currentView === 'redirect'">
            <div class="login__body">
                <div class="login__body-top">
                    <p>
                        Redirecting to login page...
                    </p>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<app-popup #passwordChangePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                {{status}}
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            {{message}}
        </p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();redirectToLoginPage()">
                Ok
            </button>
        </div>
    </div>
</app-popup>
<app-popup #passwordPolicyPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                {{status}}
            </div>
        </div>
    </div>

    <div class="popup__block">

        <p *ngIf="passwordValidation.isMatch">
            Password must be a minimum of 8 characters and contain the following:<br><br> * At least 1 upper case letter (A, B, C...Z)<br> * At least 1 lower case letter (a, b, c...z)<br> * At least 1 number (1, 2, 3...9)
        </p>

        <p *ngIf="!passwordValidation.isMatch">
            Password does not match the confirm password
        </p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                Ok
            </button>
        </div>
    </div>
</app-popup>
