import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { STATUS } from '../../../../constants/status.constant';
import { modules } from '../../../../constants/module-access.constant';
// import * as LiveSummaryTripStatusConstant from '../../../../constants/liveSummaryTripStatus.constant';

// import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import { VIOLATION_TYPE } from '../../../../constants/violationTypes.constant';
// import * as moment from 'moment';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';

@Component({
    selector: 'map-item',
    templateUrl: './map-item.component.html',
    styleUrls: ['./map-item.component.scss']
})
export class MapItemComponent implements OnInit {
    @Input() item: any; // vehicle
    @Output() pinEvent = new EventEmitter<any>();

    STATUS: any = STATUS;
    MODULES: any = modules;
    // STATUS:any = LiveSummaryTripStatusConstant.constants;

    VIOLATION_TYPE: any = VIOLATION_TYPE;

    activityStyle: any;
    imageStyle: any;
    showImageAlert: boolean = false;

    permissions: any = {};
    isPastTripViewable: boolean = true;

    constructor(
        private routerProxyService: RouterProxyService,
        private mpService: ModulePermissionService
    ) { }

    async ngOnInit() {
        this.activityStyle = this.getMapListItemActivityStyles();
        this.imageStyle = this.getMapListItemImageStyles();
        this.permissions = await this.mpService.hasPermission(modules.MAP_VEHICLE_HISTORY.value);
        this.isPastTripViewable = this.permissions[modules.MAP_VEHICLE_HISTORY.value].vAccess;
    }

    routeToVehicleMain(imei, vehicleName) {
        // // Alternative way to check special character and convert to a value that queryParam accepted
        // let regex = ["!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "`", "'", ">", "<", "?", "/", ":", ";", ",", "[", "]", "-", "+", "=", "_"];
        // let vehicleNameCharArr: Array<string> = Array.from(vehicleName);
        // let formattedVehicleName = vehicleNameCharArr.map(eachChar => {
        //     let check = regex.includes(eachChar);
        //     if (check) {
        //         return `%${eachChar.charCodeAt(0)}`;
        //     }
        //     return eachChar;
        // });

        // // Old method that are able to insert query Parameter before navigation take action
        // this.routerProxyService.navigate(['', { outlets: { leftbar: ['vehicle', imei] } }], {
        //     queryParams: {
        //         vehicleName: formattedVehicleName.join("")
        //     }
        // });
        this.routerProxyService.navigate(['', { outlets: { leftbar: ['vehicle', imei] } }]);
    }

    getMapListItemActivityStyles(): any {
        const styles = {
            'color': ''
        };

        if (this.item && this.item.status) {
            if (STATUS[this.item.status.toLowerCase()]) {
                styles['color'] = STATUS[this.item.status.toLowerCase()].COLOR;
            }
            if (this.item.status.toLowerCase() == STATUS.IDLING) {
                styles['color'] = '#d2aa00';
            }
        }
        return styles;
    }

    getMapListItemImageStyles(): any {
        const styles = {
            'color': '',
            'background-size': '35px 35px'
        };

        if (this.item) {
            if (this.item.status) {
                this.showImageAlert = (this.item.status.toLowerCase() == STATUS.PARKANDALERT);
                const statusConstant = STATUS[this.item.status.toLowerCase()];
                if (statusConstant) {
                    styles['color'] = statusConstant.COLOR;
                }
            }
            if (this.item.image) {
                // styles['background-image'] = `url(${ this.item.image })`;
                styles['background-image'] = `url(data:image/jpeg;base64,${this.item.image})`;
                // for Microsoft edge to avoid generate small image
                styles['background-size'] = 'cover';
            }
        }
        return styles;
    }

    // getViolationLabel(value) {
    //     let violation = getViolationLabelFromValue(value);
    //     return violation.label;
    // }

    // checkViolations() {
    //     let violations = this.item.violations;
    //     if (violations) {
    //         if (violations.type && violations.date && violations.type != VIOLATION_TYPE.IS_IDLING.value) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    pinToTop() {
        // Client side sorting will be faster
        this.item.isFavourite = true;
        this.item.sortSeqNo = '-1'; //pin above other favourites

        // Fire event to parent to call API
        this.pinEvent.emit({
            data: {
                pinToTop: true,
                vehicleId: this.item.vehicleId,
                vehicleName: this.item.vehicleName
            }
        });
    }

    unpinFromTop() {
        // Client side sorting will be faster
        this.item.isFavourite = false;
        const status = this.item.status.toLowerCase();
        //logic: follow trip/live API
        // console.log(this.item.vehicleId + ': status = ' + status);
        if (status === 'idling') {
            this.item.sortSeqNo = '1';
        } else if (status === 'moving') {
            this.item.sortSeqNo = '2';
        } else if (status === 'stopped' || status === 'parkandalert') {
            this.item.sortSeqNo = '3';
        } else if (status === 'disconnected') {
            this.item.sortSeqNo = '4';
        } else {
            this.item.sortSeqNo = '5';
        }

        // Fire event to parent to call API
        this.pinEvent.emit({
            data: {
                pinToTop: false,
                vehicleId: this.item.vehicleId,
                vehicleName: this.item.vehicleName
            }
        });
    }
}
