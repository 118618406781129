import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// import * as moment from 'moment';
import * as Message from '../../../constants/message';
import * as ObjectUtil from '../../../util/objectUtil';
import * as DateTimeUtil from '../../../util/dateTimeUtil';
import * as AsyncUtil from './../../../util/asyncUtil';
import * as ComponentUtil from './../../../util/componentUtil';
import * as DomUtil from './../../../util/domUtil';
import * as NumberUtil from './../../../util/numberUtil';
import * as StringUtil from './../../../util/stringUtil';

import { MessageBusService, BusMessage } from '../../../_services/messagebus.service';
import { SnackBarService } from '../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../_services/router-proxy/router-proxy.service';
import { VehicleService } from '../../../_services/vehicle/vehicle.service';
import { TripsService } from '../../../_services/trips/trips.service';
import { VehicleHealthStatusComponent } from '../vehicle-panel/health-status/health-status.component';
import { VehicleDrivingLimitsComponent } from '../vehicle-panel/driving-limits/driving-limits.component';
import { TripslistComponent } from './trip/tripslist/tripslist.component';
import { SpinnerComponent } from '../../common/spinner/spinner.component';
import { PopupCampaignService } from '../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../_services/access-control/module-permission.service';
import { PopupService } from '../../../components/common/popup/popup.service';
import { DriverService } from '../../../_services/driver/driver.service';

import { STATUS } from '../../../constants/status.constant';
import { VIOLATION_TYPE, getViolationLabelFromValue } from '../../../constants/violationTypes.constant';
import { DriverTagSelectorComponent } from '../driver-tag-selector/driver-tag-selector.component';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../constants/responseStatusCode';
import { modules } from '../../../constants/module-access.constant';

@Component({
    selector: 'app-vehicle-panel',
    templateUrl: './vehicle-panel.component.html',
    styleUrls: ['./vehicle-panel.component.scss']
})
export class VehiclePanelComponent implements OnInit, OnDestroy {

    @ViewChild("panel_spinner", { static: true }) panelSpinner: SpinnerComponent;

    @ViewChild('vehicleDrivingLimits', { static: false }) vehicleDrivingLimits: VehicleDrivingLimitsComponent;
    @ViewChild('vehicleHealthStatus', { static: false }) vehicleHealthStatus: VehicleHealthStatusComponent;
    @ViewChild('tripList', { static: false }) tripList: TripslistComponent;
    @ViewChild('driverTagSelector', { static: false }) driverTagSelector: DriverTagSelectorComponent;
    vehiclePanel: VehiclePanelComponent = this;
    triggerCount: number = 0;

    mapIsReady: boolean;
    //readyForChild: boolean;
    childTripListInit: boolean;
    childHealthStatusInit: boolean;
    childDrivingLimitInit: boolean;
    view: string;
    viewTripListLoadedOnce: boolean = false;

    vehicleName: string = '';
    visibleImei: string = '';
    vehicle: any = {
        companyId: null,
        imeiNo: null,
        userFirstName: null,
        userId: null,
        userImage: null,
        userLastName: null,
        vehicleBrand: "-",
        vehicleGroupList: [],
        vehicleId: null,
        vehicleImage: null,
        vehicleModel: "-",
        vehicleName: "-",
        vehiclePlateNo: "-",
        hasDriverTagDevice: 1,
        hasFuelSensor: 1,
        hasImmobilizer: 1,
        hasPanicButton: 1,
        fuelTankSize: null,
    };
    trip: any = {};
    hasViolation: any = false;
    STATUS: any = STATUS;
    VIOLATION_TYPE: any = VIOLATION_TYPE;

    activtyStyles: any;
    itemImageStyles: any;
    showImageAlert: boolean = false;

    // Date range
    startdate: any = '';
    enddate: any = '';

    tripDayItemSingleViewMode: boolean = false;

    msgBusSubscription: Subscription;
    routerSubscription: Subscription;

    showWalkToCar: boolean;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    viewable: boolean = false;

    expandInfoDetails: any;

    constructor(
        private vehicleService: VehicleService,
        private tripService: TripsService,
        private router: Router,
        private route: ActivatedRoute,
        private msgbus: MessageBusService,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private platform: Platform,
        private launchNavigator: LaunchNavigator,
        private popupService: PopupService,
        private driverService: DriverService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService

    ) {
        // walk to car
        this.showWalkToCar = DomUtil.isMobileApp(this.platform);
    }

    /* Message Controller*/
    async messageBusGetMessage(message: BusMessage) {

        // listen for map ready first
        if (!this.mapIsReady) {
            if (message.source != 'map' || message.messagetype != 'ready') {
                return;
            }
        }
        switch (message.source) {
            case "map":
                await this.handleMapComponentMessage(message);
                break;
            case "triplist":
                await this.handleTripListMessage(message);
                break;
            case "tripDayItem":
                await this.handleTripDayItemMessage(message);
                break;
            // case "tripRoute":
            //     await this.handleTripRouteMessage(message);
            //     break;
        }
    }

    /* Message Handler: Map */
    async handleMapComponentMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "refresh":
                console.debug('(MsgBus)VehiclePanelComponent: map -> refresh (MapComponent)');

                if (message.data.target == "VehiclePanelComponent") {
                    const imei = message.data.focusedMarker;
                    if (imei !== undefined && imei != null) {
                        this.visibleImei = imei;
                        await this.fetchVehicleDetails(true);
                    }
                }
                break;
            case "ready":
                // console.debug('(MsgBus)VehiclePanelComponent: map -> ready (MapComponent)');
                if (!this.mapIsReady && (message.data.target == "VehiclePanelComponent" || message.data.target == null)) {
                    await this.customDelayedInit();
                }
                break;
        }
    }

    /* Message Handler: Trip List */
    async handleTripListMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "missingTripRoute":
                // console.debug('(MsgBus)VehiclePanelComponent: triplist -> missingTripRoute (TripListComponent)');

                //show vehicle marker
                await this.fetchVehicleDetails();
                break;
        }
    }

    async handleTripDayItemMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "singleViewModeToggle":
                this.tripDayItemSingleViewMode = message.data.active;
                break;
        }
    }

    async ngOnInit() {
        // console.debug('VehiclePanelComponent: ngOnInit');

        this.panelSpinner.show(); //Show spinner on first time load vehicle panel
        this.visibleImei = this.route.snapshot.paramMap.get('imei') || '';
        // without queryParam insertion, another API call is required just to obtain the vehicle name
        const vehicleRes: any = await this.vehicleService.getVehicleDetailsInfo([this.visibleImei]);
        const vehicle = vehicleRes.body.vehicle_details_list.find(eachRes => { return this.visibleImei === eachRes.imeiNo });
        this.vehicleName = vehicle.vehicleName;


        const busesPort = ["map", "triplist", "tripDayItem"];
        this.msgBusSubscription = this.msgbus.messagebus$.pipe(
            filter(message => busesPort.indexOf(message.source) != -1)
        ).subscribe(this.messageBusGetMessage.bind(this));

        // Add event listener to route events to allow same page navigations
        const _this = this;
        ComponentUtil.makeSamePageNavigationWorks({
            pageUrlConstant: '(leftbar:vehicle/',
            componentSubscription: this.routerSubscription,
            componentPreInitCallback: async function () {
                // Do not re-init component upon url view param changes
                return new Promise<boolean>((resolve, reject) => {
                    (async function () {
                        const newImei: string = _this.route.snapshot.paramMap.get('imei') || '';
                        if (_this.visibleImei == newImei) {
                            await _this.checkUrlParamsToNavigate(); // correct view
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    })().catch(err => {
                        reject(err);
                    });
                });
            },
            componentInitCallback: this.ngOnInit.bind(this),
            componentDestroyCallback: this.ngOnDestroy.bind(this),
            componentRouter: this.router
        });

        // ask map to accept message bus from this component
        this.msgbus.sendMessage(new BusMessage("vehiclePanel", "unblock", {
            sender: "VehiclePanelComponent",
            bypassBlock: true
        }));

        //request info from map is ready
        this.msgbus.sendMessage(new BusMessage("map", "isReady", {
            sender: "VehiclePanelComponent"
        }));
        await this.checkUnviewedCampaign();
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_DRIVING_LIMIT.value);
        this.viewable = permissions[modules.CRUD_DRIVING_LIMIT.value].vAccess;

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /* MAP-DEPENDENT COMPONENT: DELAYED ON-INIT UNTIL MAP IS READY */
    async customDelayedInit() {
        // console.debug('VehiclePanelComponent: customDelayedInit');

        const _this = this;
        return (async function () {
            await _this.fetchVehicleDetails();

            // _this.readyForChild = true; //begin load child components
            await AsyncUtil.wait(0);    //delay for Angular to load child components
            _this.mapIsReady = true;

            // Navigate based on URL params
            await _this.checkUrlParamsToNavigate();

        })().catch(err => {
            throw err;
        });
    }

    async ngOnDestroy() {
        // console.debug('VehiclePanelComponent: ngOnDestroy');
        if (this.msgBusSubscription) {
            this.msgBusSubscription.unsubscribe();
        }
        this.resetComponentStates();
    }

    resetComponentStates() {
        this.mapIsReady = false;
        //this.readyForChild = false;
        this.childTripListInit = false;
        this.childHealthStatusInit = false;
        this.childDrivingLimitInit = false;
        this.view = 'overview';
    }

    async checkUrlParamsToNavigate() {
        // console.debug("checkUrlParamsToNavigate");
        const view = this.route.snapshot.queryParams.view;

        if (view) {
            // this.view = ''; // so that will force open & not toggling off
            if (view === "vehicleStatus") {
                await this.toggleVehicleStatus(true);
            } else if (view === "trips") {
                await this.toggleTriplist(null, null, null, true);
            } else if (view === "limits") {
                await this.toggleDrivingLimits(true);
            } else {
                await this.backToOverview(true);
            }
        } else if (this.view != 'overview') {
            await this.backToOverview(true);
        }
    }

    addCurrentViewToUrlParam(viewParam = '') {
        // console.debug('addCurrentViewToUrlParam -> ' + viewParam);
        if (viewParam) {
            this.routerProxyService.addParamsToCurrentUrl({
                view: viewParam
            });
        } else {
            // remove param from url
            this.routerProxyService.addParamsToCurrentUrl({});
        }
    }

    async fetchVehicleDetails(isRefresh: boolean = false) {
        // console.debug('VehiclePanelComponent: fetchVehicleDetails');

        await this.reloadVehicleDetails();
        const isTripListShown: boolean = (this.view === 'trips');
        await this.sendMsgToBusSetMapMarker(isRefresh, isTripListShown);
    }

    async reloadVehicleDetails() {
        // console.debug('VehiclePanelComponent: reloadVehicleDetails');

        try {
            const imeiNo = this.visibleImei;
            if (!StringUtil.isNotEmptyOrNull(imeiNo)) {
                this.routerProxyService.navigateToErrorPage();
                return false;
                // throw new Error('Invalid IMEI at page URL');
            }
            const vehicle: any = await this.vehicleService.getVehicleDetailsInfo([imeiNo]);
            // await AsyncUtil.wait(3000);
            if (!vehicle) {
                this.initEmptyVehicleData();
            } else if (vehicle.body.totalRecord == 0) {
                this.initEmptyVehicleData();
                const msg = Message.getMessage(Message.MESSAGE.NOT_FOUND.value, 'Vehicle');
                this.snackBar.openGenericSnackBar(msg);
            } else {
                this.vehicle = vehicle.body.vehicle_details_list[0];
                //format immobilised time
                this.vehicle.lastImmobilisedTime = DateTimeUtil.getDateLabel(this.vehicle.lastImmobilisedTime, "YYYY-MM-DD HH:mm:ss.SSS", "MMM DD, YYYY h.mm A");
                if (this.vehicle.userFirstName === null) {
                    this.vehicle.fullUserName = '';
                } else {
                    this.vehicle.fullUserName = this.vehicle.userFirstName + ' ';
                }
                if (this.vehicle.userLastName !== null) {
                    this.vehicle.fullUserName += this.vehicle.userLastName;
                }

                const trips: any = await this.tripService.getLiveSummaryTrips([imeiNo]);

                this.hasViolation = false;
                if (trips && trips.body) {
                    const trip = trips.body.live_summary_trip[0] || null;
                    if (trip) {
                        this.trip = {
                            ...trip,
                            fuelLevel: trip.fuelLevel || (this.trip ? this.trip.fuelLevel : null) // Show previous fuel level if current trip fuel level is empty.
                        };
                        this.hasViolation = this.checkViolations();
                        this.setVehicleData();
                    }
                }
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.panelSpinner.hide();
        }
    }

    checkViolations() {
        const violations = this.trip.violations;
        if (violations) {
            if (violations.type && violations.date && violations.type != VIOLATION_TYPE.IS_IDLING.value) {
                return true;
            }
        }
        return false;
    }

    initEmptyVehicleData() {
        this.vehicle = {
            companyId: null,
            imeiNo: null,
            userFirstName: null,
            userId: null,
            userImage: null,
            userLastName: null,
            vehicleBrand: "-",
            vehicleGroupList: [],
            vehicleId: null,
            vehicleImage: null,
            vehicleModel: "-",
            vehicleName: "-",
            vehiclePlateNo: "-"
        };
    }

    setVehicleData() {
        // console.debug("setVehicleData");
        delete this.trip.image;
        if (this.vehicle.vehicleImage) {
            this.trip.image = this.vehicle.vehicleImage;
        }

        this.trip.vehicleName = this.vehicle.vehicleName;

        this.trip.activityDurationStr = NumberUtil.formatHourMinute(Number(this.trip.activity.duration) / 60, '0 m', 'H h M m');
        // this.trip.activityDurationStr = this.trip.activity.duration.toString();

        let tripStatus = this.trip.status;
        if (tripStatus) {
            tripStatus = tripStatus.toLowerCase();
            this.trip.statusLoweredCase = tripStatus;
            if (this.STATUS[tripStatus]) {
                this.trip.statusLabel = this.STATUS[tripStatus].LABEL;
            }
            this.activtyStyles = this.getVehicleActivityStyles();
            this.itemImageStyles = this.getVehicleItemImageStyles();
        }
        if (tripStatus == STATUS.STOPPED ||
            tripStatus == STATUS.PARKANDALERT ||
            tripStatus == STATUS.DISCONNECTED ||
            tripStatus == STATUS.IDLING) {
            this.trip.lastStopDateLabel = DateTimeUtil.getDateLabel(this.trip.activity.lastStopDate, "YYYY-MM-DD HH:mm:ss.SSS", "MMM DD, YYYY h.mm A");
        }

        if (this.trip && !this.trip.activity.speed && !this.trip.activity.direction && !this.trip.activity.duration) {
            this.trip.activity.speed = "0";
            this.trip.activity.direction = "-";
            this.trip.activity.duration = "0";
        }

        if (this.hasViolation) {
            this.trip.violationLabel = this.getViolationLabel(this.trip.violations.type);
            this.trip.violationDateLabel = DateTimeUtil.getDateLabel(this.trip.violations.date, "YYYY-MM-DD HH:mm:ss.SSS", "MMM DD, YYYY h.mm A");
        }

        if (this.trip.fuelLevel) {
            this.trip.fuelLevel = NumberUtil.formatFloat(this.trip.fuelLevel, 1, '0');
        }
    }

    getVehicleItemImageStyles(): any {

        const styles = {};

        if (this.vehicle) {
            if (this.trip && this.trip.status) {
                this.showImageAlert = (this.trip.statusLoweredCase == STATUS.PARKANDALERT);
                const statusConstant = STATUS[this.trip.statusLoweredCase];
                if (statusConstant) {
                    styles['color'] = statusConstant.COLOR;
                }
            }
            if (this.vehicle.vehicleImage) {
                styles['background-image'] = `url(data:image/jpeg;base64,${this.vehicle.vehicleImage})`;
                styles['background-size'] = 'cover';
            } else {
                styles['background-size'] = '50px 50px';
            }
        }
        return styles;
    }

    getVehicleActivityStyles(): any {
        const styles = {
            'color': ''
        };
        if (this.trip && this.trip.status) {
            if (this.STATUS[this.trip.statusLoweredCase]) {
                styles['color'] = this.STATUS[this.trip.statusLoweredCase].COLOR;
            }
            if (this.trip.statusLoweredCase == STATUS.IDLING) {
                styles.color = '#d2aa00';
            }
        }
        return styles;
    }

    sendMsgToBusSetMapMarker(isRefresh: boolean = false, isPanelOpened: boolean = false) {
        // let tripInfo = this.trip;

        const currItem = [];
        if (!ObjectUtil.isEmpty(this.trip)) {
            currItem.push(this.trip);
        }

        this.msgbus.sendMessage(new BusMessage(
            "vehiclePanel",
            "markerlistchange",
            {
                currentItems: currItem,
                sender: "VehiclePanelComponent",
                focusedMarker: this.visibleImei,
                isRefresh: isRefresh,
                isPanelOpened: isPanelOpened
            }
        ));
    }

    async toggleTriplist(startDate = null, endDate = null, tripId = null, silent: boolean = false) {
        if (!silent) { this.addCurrentViewToUrlParam('trips'); }
        this.childTripListInit = true;
        if (this.tripDayItemSingleViewMode) {
            this.setInactiveTripDayItemSingleViewMode();
            // Fix mobile view toggle trip-list between map & listing
            if (DomUtil.isMobileOrTabletView()) {
                if (startDate != null && endDate != null && tripId != null) {
                    // show violated trip immediately
                } else {
                    return;
                }
            }
        }
        if (this.view !== 'trips') {
            this.view = 'trips';

            const blockToggleMessageBusForFirstTime: boolean = !this.viewTripListLoadedOnce;
            this.viewTripListLoadedOnce = true;
            console.debug('fixViewStateOfTripListComponent->blockToggleMessageBusForFirstTime = ' + blockToggleMessageBusForFirstTime);

            // this.tripList.el.nativeElement.querySelector('input,select,button,a').focus();
            if (startDate != null && endDate != null && tripId != null) {
                // this.tripList.isLoadedMostRecent = true;
                await this.tripList.showSelectedViolationHistoryTrip(startDate, endDate, tripId);
                this.fixViewStateOfTripListComponent(false); //do not load default trip in trip list
            } else {
                // do not load highlighted trip if in mobile
                this.fixViewStateOfTripListComponent(!DomUtil.isMobileOrTabletView(), blockToggleMessageBusForFirstTime);
            }
        } else if (!silent) {
            // this.childTripListInit = false; // temp to speed up page
            // this.tripList.clearTripsData(); // temp to speed up page
            await this.backToOverview();
        }
    }

    async toggleVehicleStatus(silent: boolean = false) {
        if (!silent) { this.addCurrentViewToUrlParam('vehicleStatus'); }
        this.childHealthStatusInit = true;

        if (this.view !== 'status') {
            this.view = 'status';

            this.vehicleHealthStatus.el.nativeElement.querySelector('input,select,button,a').focus();
            this.vehicleHealthStatus.vehicleId = this.vehicle.vehicleId;
            this.vehicleHealthStatus.vehicleName = this.vehicle.vehicleName;
            if (!this.vehicleHealthStatus.searchViolationHistory) {
                this.vehicleHealthStatus.getVehicleHealthStatus();
            }
        } else if (!silent) {
            await this.backToOverview();
        }
    }

    async toggleDrivingLimits(silent: boolean = false) {
        if (!silent) { this.addCurrentViewToUrlParam('limits'); }
        this.childDrivingLimitInit = true;

        if (this.view !== 'limits') {
            this.view = 'limits';

            this.vehicleDrivingLimits.el.nativeElement.querySelector('input,select,button,a').focus();
            this.vehicleDrivingLimits.vehicleId = this.vehicle.vehicleId;
            this.vehicleDrivingLimits.vehicleName = this.vehicle.vehicleName;
            await this.vehicleDrivingLimits.getVehicleDrivingLimits();
        } else if (!silent) {
            await this.backToOverview();
        }
    }

    async backToOverview(silent: boolean = false) {
        if (!silent) {
            this.addCurrentViewToUrlParam();
        }

        if (this.tripDayItemSingleViewMode) {
            this.setInactiveTripDayItemSingleViewMode();
        }

        this.view = 'overview';
        this.fixViewStateOfTripListComponent(false);

        await this.fetchVehicleDetails();
    }

    fixViewStateOfTripListComponent(
        loadDefaultTrip: boolean = true,
        blockToggleMessageBusForFirstTime: boolean = false
    ) {
        this.msgbus.sendMessage(new BusMessage(
            "vehiclePanel",
            "triplisttoggle",
            {
                isActive: (this.view === 'trips'),
                loadDefaultTrip: loadDefaultTrip,
                bypassBlock: true,
                skipTripList: blockToggleMessageBusForFirstTime
            }
        ));
    }

    walkToCar(): void {
        if (this.trip) {
            const destination: any = [this.trip.coordinates.lat, this.trip.coordinates.lng]; // dest lat,long
            const options: LaunchNavigatorOptions = {
                //app: this.launchNavigator.APP.GOOGLE_MAPS,
                app: this.launchNavigator.APP.USER_SELECT,
                transportMode: this.launchNavigator.TRANSPORT_MODE.WALKING,
                startName: 'Walk To Car'
            };

            this.launchNavigator.navigate(destination, options)
                .then(
                    success => console.debug('Launched navigator'),
                    error => console.debug('Error launching navigator', error)
                );
        }
    }

    setInactiveTripDayItemSingleViewMode() {
        this.tripDayItemSingleViewMode = false;

        this.msgbus.sendMessage(new BusMessage(
            "vehiclePanel",
            "singleViewModeToggle",
            {
                active: false,
                bypassBlock: true
            }
        ));
    }

    getViolationLabel(value) {
        const violation = getViolationLabelFromValue(value);
        return violation.label;
    }

    navigateToMapPage() {
        this.routerProxyService.navigateToMapPage();
    }

    showInfoDetails() {
        this.expandInfoDetails = !this.expandInfoDetails;
    }

    async tieDriverTagToDriver() {
        this.panelSpinner.show();
        try {
            // Get Selected Vehicle Ids
            const selectedDriverTagIdList = this.driverTagSelector.getSelectedIds();
            //only can select 1, so get the 1st one
            const selectedDriverTagId = selectedDriverTagIdList[0];

            const result = await this.driverService.tieDriverTagToDriver(this.vehicle.userId, selectedDriverTagId);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value);

                await this.fetchVehicleDetails(); // Get latest data notification
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.panelSpinner.hide();
        }
    }

    async immobiliseVehicle() {
        this.panelSpinner.show();
        try {
            const reqObj = {
                isImmobilise: true,
                vehicleId: this.vehicle.vehicleId,
                lat: this.trip.coordinates ? this.trip.coordinates.lat : 0,
                lng: this.trip.coordinates ? this.trip.coordinates.lng : 0,
                address: this.trip.activity ? this.trip.activity.lastStopAddress : ''
            };
            const result = await this.driverService.immobiliseOrStartVehicle(reqObj);

            let msg = Message.getMessage(Message.MESSAGE.GENERIC_FAILED.value, 'Immobilised vehicle');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.GENERIC_SUCCESS.value, 'Immobilised vehicle');

                await this.fetchVehicleDetails(); // Get latest data notification
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.panelSpinner.hide();
        }
    }

    async startVehicle() {
        this.panelSpinner.show();
        try {
            const reqObj = {
                isImmobilise: false,
                vehicleId: this.vehicle.vehicleId,
                lat: this.trip.coordinates ? this.trip.coordinates.lat : 0,
                lng: this.trip.coordinates ? this.trip.coordinates.lng : 0,
                address: this.trip.activity ? this.trip.activity.lastStopAddress : ''
            };
            const result = await this.driverService.immobiliseOrStartVehicle(reqObj);

            let msg = Message.getMessage(Message.MESSAGE.GENERIC_FAILED.value, 'Start vehicle');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.GENERIC_SUCCESS.value, 'Start vehicle');

                await this.fetchVehicleDetails(); // Get latest data notification
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.panelSpinner.hide();
        }
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    public async acknowledgeAlarm() {
        // button click -> send ack to backend to disable alarm in dynamoDB
        const result = await this.vehicleService.updateVehiclePanicButtonAlarm(this.vehicle.companyId, this.vehicle.imeiNo);

        if (result.statusCode == 'SUCCESS') {
            this.vehicle.isPanic = false;
        }
    }

    setViolation(violation:string){
        this.tripList.setViolation(violation);
    }

}
