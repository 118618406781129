import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Platform } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

// Component
import { VehicleSelectorComponent } from '../../vehicles/vehicle-selector/vehicle-selector.component';
import { SpinnerComponent } from '../../../common/spinner/spinner.component';

// Service
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { PopupService } from '../../../common/popup/popup.service';
import { RouterProxyService } from '../../../../_services/router-proxy/router-proxy.service';
import { UserService } from '../../../../_services/user/user.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';
import { RouteService } from '../../../../_services/route/route.service';

// Util
import * as DomUtil from '../../../../util/domUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';
import * as LocalStorageUtil from '../../../../util/localStorageUtil';
import * as MapUtil from '../../../../util/mapUtil';

// Constant
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from '../../../../constants/responseStatusCode';
import { ERROR_MESSAGE as ErrorMessage } from '../../../../constants/errorMessage';
import { overlayConstant } from '../../../../constants/overlay-image-map.constant';

declare var google: any;

@Component({
    selector: 'app-create-checkpoint',
    templateUrl: './create-checkpoint.component.html',
    styleUrls: ['./create-checkpoint.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class CreateCheckpointComponent implements OnInit, OnDestroy {

    constructor(
        private platform: Platform,
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private userService: UserService,
        private popupCampaignService: PopupCampaignService,
        private routeService: RouteService,
        private route: ActivatedRoute
    ) { }

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    // Checkpoint Form
    @ViewChild('vehicleAssign', { static: false }) vehicleAssign: VehicleSelectorComponent;
    @ViewChild("page_spinner", { static: true }) pageSpinner: SpinnerComponent;

    message = Message;

    MY_LOCATION_LAT: number = 3.158; //Menara Maxis
    MY_LOCATION_LNG: number = 101.713; //Menara Maxis
    mapIsReady = false;
    mapContent: any;
    searchInput: any;

    // Route Info
    routeId: number;

    // Checkpoint Info
    checkpointList = [];
    checkpointName: string;

    // Checkpoint  Area
    checkpointLatLng: string;
    checkpointRadius: number = 0;
    otherPathCoords = [];

    // Checkpoint Type
    circle: string = 'circle';
    selectedCheckpointType: string = this.circle;

    async ngOnInit() {
        this.routeId = this.route.snapshot.params.id;
        const res = await this.routeService.getCheckpoints(this.routeId)
        this.checkpointList = res.body

        this.mapContent = document.getElementById('map');
        this.searchInput = document.getElementById('searchInput');
        await this.loadScriptFirst();
        DomUtil.autoFocusForm(this.el);
        await this.checkUnviewedCampaign();
    }

    async ngOnDestroy() {

    }

    async createCheckpoint() {
        try {
            const result = await this.routeService.createCheckpoint(this.checkpointName, this.routeId, this.checkpointLatLng.split(',')[0], this.checkpointLatLng.split(',')[1], this.checkpointRadius, 0);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Checkpoint', this.checkpointName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Checkpoint', this.checkpointName);

                this.navigateToEditRoute();
            }
            this.snackBar.openGenericSnackBar(msg);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async loadScriptFirst() {
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        if (!DomUtil.hasGoogleMapDrawingScript(GOOGLE_MAP_API_KEY)) {
            DomUtil.addGoogleMapScript({
                libraries: ['drawing', 'places'],
                onload: this.finishScriptLoad.bind(this)
            }, GOOGLE_MAP_API_KEY);

        } else {
            if (DomUtil.isGoogleNotDefined()) {
                await AsyncUtil.wait(300); // wait for google object ready
            }
            this.finishScriptLoad();
        }
    }

    finishScriptLoad(): void {
        for (const otherCheckpoint of this.checkpointList) {
            this.otherPathCoords.push(new google.maps.LatLng(otherCheckpoint.lat, otherCheckpoint.lng))
        }
        this.initMap();
    }

    async initMap() {
        if (!DomUtil.isGoogleNotDefined()) {
            const self = this;
            let currentOverlay;
            const map = new google.maps.Map(this.mapContent, {
                center: { lat: this.MY_LOCATION_LAT, lng: this.MY_LOCATION_LNG },
                gestureHandling: 'greedy',
                zoom: 17
            });

            self.checkpointLatLng = '';

            const drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: self.selectedCheckpointType,
                drawingControl: false,
                [`${this.selectedCheckpointType}Options`]: {
                    fillColor: '#FFC0CB',
                    fillOpacity: 0.5,
                    strokeWeight: 3,
                    strokeColor: '#DC143C',
                    clickable: true,
                    draggable: true,
                    editable: true
                }
            });

            let otherAreaTypes = [];

            // Setting for Polygon / Circle
            const editableSetting = {
                clickable: true,
                editable: true,
                draggable: true,
                strokeColor: '#DC143C',
                strokeWeight: 3,
                fillColor: '#FFC0CB',
                fillOpacity: 0.5
            };

            const nonEditableSetting = {
                clickable: false,
                editable: false,
                draggable: false,
                strokeColor: '#DC143C',
                strokeWeight: 3,
                fillColor: '#FFC0CB',
                fillOpacity: 0.5
            };

            for (let i = 0; i < this.checkpointList.length; i++) {
                otherAreaTypes.push(new google.maps.Circle(Object.assign(nonEditableSetting, {
                    center: this.otherPathCoords[i],
                    radius: this.checkpointList[i].radius
                })))
            }

            if (otherAreaTypes) {
                for (const otherAreaType of otherAreaTypes) {
                    otherAreaType.setMap(map)
                }
            }

            drawingManager.setMap(map);

            google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {

                // Disable Drawing
                drawingManager.setDrawingMode(null);
                currentOverlay = event.overlay;
                self.checkpointLatLng = '';

                self.editCheckpointArea(currentOverlay, event.type);

                // remove the overlay
                currentOverlay.addListener('click', function (e) {
                    e.stop();
                    if (typeof event.path === 'undefined') { // ignore clicks on polygon paths
                        self.checkpointLatLng = '';
                        drawingManager.setDrawingMode(self.selectedCheckpointType);
                        currentOverlay.setMap(null);
                        google.maps.event.clearInstanceListeners(currentOverlay);
                        currentOverlay = null;
                    }
                });
            });

            // Create the search box and link it to the UI element.
            const searchBox = new google.maps.places.SearchBox(this.searchInput);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', function () {
                searchBox.setBounds(map.getBounds());
            });

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', function () {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();
                places.forEach(function (place) {
                    if (!place.geometry) {
                        console.debug("Returned place contains no geometry");
                        return;
                    }

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
                drawingManager.setMap(map);

                if (currentOverlay) {
                    currentOverlay.setMap(null);
                    currentOverlay = null;
                }
                self.checkpointLatLng = '';
                drawingManager.setDrawingMode(self.selectedCheckpointType);
            });

            const result = await this.userService.getCompanyIdByName('Gamuda Cove');
            if (result && result.body.length > 0) {
                const currentUserCompanyId = LocalStorageUtil.localStorageGet('currentUser').companyId;
                if (result.body[0].id === currentUserCompanyId) {
                    MapUtil.generateOverlayImg(overlayConstant.imageLoc, overlayConstant.imagePath, map);
                }
            }

        } else {
            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.MAP_LOAD_FAILED);
            this.snackBar.openGenericSnackBar(msg);
        }
    }

    editCheckpointArea(event, type) {
        const _this = this;
        // (Circle) For Edit / Drag Area
        google.maps.event.addListener(event, 'dragend', function () {
            _this.checkpointLatLng = event.center.lat() + ',' + event.center.lng();
        });
        google.maps.event.addListener(event, 'radius_changed', function () {
            _this.checkpointRadius = Math.round(event.radius);
        });
        // Run One Time After Finish Drawing
        _this.checkpointLatLng = event.center.lat() + ',' + event.center.lng();
        _this.checkpointRadius = Math.round(event.radius);
    }

    navigateToEditRoute() {
        this.routerProxyService.navigate(['/create-manage/routes/edit-route/', this.routeId]);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
