import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { TripsService } from './../../../../_services/trips/trips.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as NumberUtil from './../../../../util/numberUtil';

// Class
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';

// Constant
import * as Message from '../../../../constants/message';

@Component({
    templateUrl: './violations-reports.component.html',
    styleUrls: ['./violations-reports.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s ease-out', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ViolationsReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

    expandGroupVehicle: any = [];
    expandGroupDriver: any = [];

    grandTotal: any = {
        VehicleId: '-',
        VehicleName: '-',
        SpeedingDistance: 0,
        SpeedingDistanceLabel: '0',
        HarshAcceleration: 0,
        HarshBrake: 0,
        HarshTurn: 0,
        ExceedTimeOfDay: 0,
        Geofences: 0,
        ExceedMileage: 0
    };

    // Set Export File Name from environments
    exportPdfViolationPageLayout = environment.appConfig.reporting.violation.layout;
    exportFileNameViolationSummaryTrip = environment.appConfig.reporting.violation.fileName;
    exportFileNameViolationSummaryTripPdfTitle = environment.appConfig.reporting.violation.label;
    pageLayout = this.exportPdfViolationPageLayout;
    moment = moment;

    @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private tripsService: TripsService,
        private el: ElementRef,
        private popupService_parent: PopupService,
        private route: ActivatedRoute,
        private popupCampaignService: PopupCampaignService
    ) {

        super(http_parent, popupService_parent, snackBar);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingSearchBy: true,
            usingDateRangePicker: true,
            usingDriverSelector: true,
            usingVehicleSelector: true
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {

        //Default search by
        this.searchBy = "vehicles";

        //Default search range
        // this.fromDate = moment().subtract(1, 'weeks').format("YYYY-MM-DD");
        // this.toDate = moment().format("YYYY-MM-DD");

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        //when redirect from advisory
        if (this.route.snapshot.queryParams.autoSearch == 'true') {
            const filterIdVehicle = this.route.snapshot.queryParams.vehicleIds || '';
            if (filterIdVehicle != '') {
                //clear default selected all vehicles
                this.selectedVehicles = {};
                const idArray = filterIdVehicle.split(',');
                idArray.forEach(vehicleId => {
                    this.selectedVehicles[vehicleId] = true;
                });
                const current = moment();
                const pastWeek = current.subtract(1, 'weeks');
                const pastWeekStart = pastWeek.startOf('isoWeek').format('YYYY-MM-DD');
                const pastWeekEnd = pastWeek.endOf('isoWeek').format('YYYY-MM-DD');
                this.fromDate = pastWeekStart;
                this.toDate = pastWeekEnd;
                //update ui changes
                this.onRedirectUpdateVehiclesSelectChange();
                this.currentSortField = this.route.snapshot.queryParams.autoSortColumn;
                this.currentSortAscending = false;
                await this.generateReport();
            }
        }

        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {
            const idListObj = this.currentSearchBy == "vehicles" ? { VehicleId: '-', VehicleName: 'Total' } : { DriverId: '-', DriverName: 'Total' };
            this.grandTotal = {
                SpeedingDistance: 0,
                HarshAcceleration: 0,
                HarshBrake: 0,
                HarshTurn: 0,
                ExceedTimeOfDay: 0,
                Geofences: 0,
                ExceedMileage: 0
            };
            this.grandTotal = Object.assign(idListObj, this.grandTotal);

            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Call api to get report page
            const apiControllerResponse = await this.getApiControllerResponse(startRecord);

            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = apiControllerResponse.resultList;
                if (this.resultList.length) {

                    // parse some values to reduce function data bindings
                    this.resultList.forEach(record => {
                        record.SpeedingDistanceLabel = NumberUtil.formatFloat(record.SpeedingDistance, 1, '0');
                    });

                    this.isReportShown = true;
                    this.grandTotal = this.resultList[this.resultList.length - 1];
                    this.grandTotal.SpeedingDistanceLabel = NumberUtil.formatFloat(this.grandTotal.SpeedingDistance, 1, '0');
                    this.resultList.pop();

                    // Get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }

    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }

    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        if (this.currentSearchBy == "vehicles") {
            apiResponse = await this.getPostTripSummaryViolationRecords(startRecord, isDownload);
            if (apiResponse != null) {
                apiResultList = apiResponse.result;
            }
        } else if (this.currentSearchBy == "drivers") {
            apiResponse = await this.getPostTripSummaryViolationRecords(startRecord, isDownload);
            if (apiResponse != null) {
                apiResultList = apiResponse.result;
            }
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }

    // For download report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 19.5 }, { wch: 16 }, { wch: 12 }, { wch: 9 }, { wch: 10 }, { wch: 8 }, { wch: 7 }];

        //should use searchBy instead of searchType
        if (this.currentSearchBy == "vehicles") {
            filename = this.exportFileNameViolationSummaryTrip + "_VEHICLE";
            label = this.exportFileNameViolationSummaryTripPdfTitle + " (Vehicle)";
            headerName = [
                "VehicleName", "Speeding Distance (km)", "Harsh Acceleration", "Harsh Braking", "Harsh Turn",
                "Time of Day", "Geofence", "Mileage"
            ];
            /**
             * Change the header type sequence, when the header sequnce is changed
             * Change the header type, when the header content data type is changed
             */
            // // excel file cell data type & format(if applicable)
            // headerType = [
            //     { type: "string" }, { type: "number" }, { type: "number" }, { type: "number" }, { type: "number" },
            //     { type: "number" }, { type: "number" }, { type: "number" }
            // ];
            for (let i = 0; i < recordList.length; i++) {
                const tempRow = [
                    recordList[i].VehicleName,
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].SpeedingDistance, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].HarshAcceleration, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].HarshBrake, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].HarshTurn, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ExceedTimeOfDay, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Geofences, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ExceedMileage, 1, '0')),
                ];
                data.push(tempRow);
            }
        } else if (this.currentSearchBy == "drivers") {
            filename = this.exportFileNameViolationSummaryTrip + "_DRIVER";
            label = this.exportFileNameViolationSummaryTripPdfTitle + " (Driver)";
            headerName = [
                "DriverName", "Speeding Distance (km)", "Harsh Acceleration", "Harsh Braking", "Harsh Turn",
                "Time of Day", "Geofence", "Mileage"
            ];
            /**
             * Change the header type sequence, when the header sequnce is changed
             * Change the header type, when the header content data type is changed
             */
            // // excel file cell data type & format(if applicable)
            // headerType = [
            //     { type: "string" }, { type: "number" }, { type: "number" }, { type: "number" }, { type: "number" },
            //     { type: "number" }, { type: "number" }, { type: "number" }
            // ];
            for (let i = 0; i < recordList.length; i++) {
                const tempRow = [
                    recordList[i].DriverName,
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].SpeedingDistance, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].HarshAcceleration, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].HarshBrake, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].HarshTurn, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ExceedTimeOfDay, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Geofences, 1, '0')),
                    NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].ExceedMileage, 1, '0')),
                ];
                data.push(tempRow);
            }
        }
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    /**
     * Report Specific Methods
     */

    // 1. Call API Post Trip Summary Violation Report
    async getPostTripSummaryViolationRecords(startRecord, isDownload: boolean = false) {
        //Should use searchBy to detect vehicles|drivers options
        const idListObj = this.currentSearchBy === 'vehicles' ? { vehicleId: this.selectedVehiclesList } : { driverId: this.selectedDriversList };
        let result: any = null;
        //Get Data
        if (!isDownload) {
            result = await this.tripsService.getTripSummaryViolationReport(idListObj, this.currentFromDate, this.currentToDate, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
        } else {
            result = await this.tripsService.getTripSummaryViolationReport(idListObj, this.currentFromDate, this.currentToDate, null, null, this.currentSortField, this.currentSortAscending);
        }
        return result || null;
    }

}
