import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
// import imageCompression from 'browser-image-compression';
// import * as moment from 'moment';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';
import { DriverSelectorComponent } from './../../drivers/driver-selector/driver-selector.component';

// Service
import { UserService } from './../../../../_services/user/user.service';
import { PopupService } from './../../../common/popup/popup.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
// import { DriverService } from './../../../../_services/driver/driver.service';
// import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { RoleService } from '../../../../_services/role/role.service';

// Util
import * as passwordUtil from './../../../../util/passwordUtil';
import * as DomUtil from './../../../../util/domUtil';
import * as StringUtil from './../../../../util/stringUtil';
import * as imageUtil from '../../../../util/imageUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';

// Constant
import * as Message from './../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

@Component({
    selector: 'app-edit-admin',
    templateUrl: './edit-admin.component.html',
    styleUrls: ['./edit-admin.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class EditAdminComponent implements OnInit {
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    adminId: number;
    currentTab: number = 1;
    newPassword: any = {
        password: '',
        passwordStrength: '',
        confirmPassword: '',
    };
    actionMessage: string;
    adminDBDetails: any = {};
    adminUpdateDetails: any = {};
    message = Message;
    isValidEmail: boolean = true;

    passwordValidation: any = {
        hasError: false,
        isMatch: true,
        isEnoughLength: true,
        isOneUpperChar: true,
        isOneLowerChar: true,
        isOneNumber: true
    };

    passwordIsMatch = true;
    passwordNotEnoughLength = false;
    isEditFail: boolean = true;

    @ViewChild('imageAlertPopup',{static:false}) imageAlertPopup;
    hasImage: boolean = false;
    isImageChanged: boolean = false;
    maxSizeMB = environment.appConfig.createManage.maxSizeMB;
    maxWidthOrHeight = environment.appConfig.createManage.maxWidthOrHeight;
    maxSizeImageMB = environment.appConfig.createManage.maxSizeImageMB;
    imageAlertError = null;

    @ViewChild('vehicleAssign',{static:false}) vehicleAssign: VehicleSelectorComponent;
    @ViewChild('driverAssign',{static:false}) driverAssign: DriverSelectorComponent;

    @ViewChild('editCropImagePopup',{static:false}) editCropImagePopup;
    @ViewChild(ImageCropperComponent,{static:false}) imageCropper: ImageCropperComponent;
    @ViewChild("cropImage_spinner",{static:false}) cropImage_spinner: SpinnerComponent;
    imageChangedEvent: any = '';
    croppedImageData: any = '';

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    roleId: number;
    roleList = [];

    constructor(
        private platform: Platform,
        private route: ActivatedRoute,
        private popupService: PopupService,
        private userService: UserService,
        private snackBar: SnackBarService,
        private http: HttpClient,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService,
        private roleService: RoleService

    ) { }

    async ngOnInit() {
        this.adminId = this.route.snapshot.params.id;
        await this.onInitCommonFunction();
        DomUtil.autoFocusForm(this.el);
    }

    //Switch Tab Function
    generalInfo(): void {
        this.currentTab = 1;
    }

    assignResources(): void {
        this.currentTab = 2;
    }
    //End of Switch Tab Function

    //Edit Admin
    async onInitCommonFunction() {
        this.pageSpinner.show();
        try {
            await this.getAdminDetails(this.adminId);
            // this.setImage();

            await this.checkUnviewedCampaign();
            await this.getRoleList();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    // setImage() {
    //     if (this.adminUpdateDetails.photo) {
    //         this.adminUpdateDetails.photo = `data:image/jpeg;base64,${this.adminUpdateDetails.photo}`;
    //         this.hasImage = true;
    //     }
    // }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    onStrengthChanged($event) {
        if ($event === 30) {
            this.newPassword.passwordStrength = 'WEAK';
        } else if ($event === 60) {
            this.newPassword.passwordStrength = 'MODERATE';
        } else if ($event === 100) {
            this.newPassword.passwordStrength = 'STRONG';
        }
    }

    async getAdminDetails(userId) {
        const res: any = await this.userService.getAdminDetails(userId);
        this.adminUpdateDetails = res.body.result;
        if (this.adminUpdateDetails.photo) {
            this.adminUpdateDetails.photo = `data:image/jpeg;base64,${this.adminUpdateDetails.photo}`;
            this.hasImage = true;
        }
        this.adminDBDetails = Object.assign({}, this.adminUpdateDetails);
        this.roleId = this.adminDBDetails.roleId;
    }

    onKeyUp() {
        this.validateEmail();
    }

    async updateAdminInfo() {
        this.pageSpinner.show();
        try {

            const updatedFields = {};
            for (const objKey in this.adminUpdateDetails) {
                if (objKey == 'password') {
                    if (this.newPassword.password.length == 0) {
                        delete this.adminUpdateDetails[objKey];
                    } else {
                        Object.assign(updatedFields, { [objKey]: this.newPassword.password });
                        // this.adminUpdateDetails[objKey] = this.newPassword.password
                    }
                } else if (objKey == 'userId') {
                    Object.assign(updatedFields, { [objKey]: this.adminId });
                } else if (objKey !== 'userId') {

                    // trim leading & trailing whitespace for names
                    if (objKey == 'firstName' || objKey == 'lastName') {
                        if (StringUtil.isNotEmptyOrNull(this.adminUpdateDetails[objKey])) {
                            this.adminUpdateDetails[objKey] = this.adminUpdateDetails[objKey].trim();
                        }
                    }

                    if (objKey == 'photo') {

                        // Do not compress image before checking for unchanged flag
                        let newImage;
                        if (this.hasImage) {
                            const img: any = <HTMLElement>document.getElementById("imageId");
                            if (img) {
                                newImage = await imageUtil.compressImageSize(img.src, this.maxSizeMB, this.maxWidthOrHeight, this.platform); // maxSizeMB, maxWidthOrHeight are optional
                                await AsyncUtil.wait(2000); // Wait for image compress
                            } else {
                                newImage = null;
                            }
                            // Invalid image -> always update to blank
                            if (!newImage) {
                                this.adminUpdateDetails[objKey] = '';
                            } else if (this.isImageChanged) {
                                this.adminUpdateDetails[objKey] = newImage;
                            }
                        }
                        // console.log('old: ' + this.adminDBDetails.photo);
                        // console.log('new: ' + this.adminUpdateDetails.photo);
                    }

                    //Compare diff before and after
                    if (this.adminUpdateDetails[objKey] !== this.adminDBDetails[objKey]) {
                        Object.assign(updatedFields, { [objKey]: this.adminUpdateDetails[objKey] });
                    } else {
                        if (objKey == 'photo') {
                            console.debug('EditAdmin: Image unchanged');
                        }
                    }
                }
            }

            if (this.roleId) {
                Object.assign(updatedFields, { ['roleId']: this.roleId });
            }

            const result = await this.userService.updateAdminDetails(updatedFields);

            // scroll back to the top for error (if any)
            // window.scroll(0, 0);

            // redirect to view
            const adminName = this.adminUpdateDetails.firstName + ' ' + this.adminUpdateDetails.lastName;

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Admin', adminName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = this.message.getMessage(this.message.MESSAGE.UPDATE_SUCCESS.value, "Admin", adminName);
                this.navigateSuccessToAdminView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    validateEmail() {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.adminUpdateDetails.Email)) {
            return this.isValidEmail = true;
        }
        return this.isValidEmail = false;
    }

    validatePassword() {
        this.passwordValidation = passwordUtil.passwordValidator(this.newPassword, this.passwordValidation);
    }

    async chooseImage(item: any) {
        if (item.target.files.length > 0 && item.target.files[0].type.indexOf("image") > -1) {
            const imageFile = item.target.files[0];

            if ((imageFile.size / 1024 / 1024) < this.maxSizeImageMB) { //Check Image Size
                this.isImageChanged = true;
                this.hasImage = true;
                this.imageChangedEvent = item;
                this.showPopup(this.editCropImagePopup);
            } else {
                this.imageAlertError = 'sizeError';
                this.showPopup(this.imageAlertPopup);
            }
        } else {
            this.imageAlertError = 'fileError';
            this.showPopup(this.imageAlertPopup);
        }
    }

    navigateSuccessToAdminView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/admins/view']);
    }

    navigateToAdminView() {
        this.routerProxyService.navigate(['/create-manage/admins/view']);
    }

    //End of Edit Admin

    async submitAssignDriversAndVehiclesResources() {
        this.pageSpinner.show();
        try {
            let body = {
                adminId: +this.adminId
            };
            const assignedVehicleId = this.vehicleAssign.getSelectedVehicleIds();

            const assignedDriverId = this.driverAssign.getSelectedDriverIds();

            body = Object.assign(body, { vehicleIdList: assignedVehicleId, driverIdList: assignedDriverId });

            const result = await this.userService.assignVisibleDriversAndVehiclesResources(body);

            // window.scroll(0, 0); // Scroll to top
            const adminName = this.adminUpdateDetails.firstName + ' ' + this.adminUpdateDetails.lastName;

            let msg = Message.getMessage(Message.MESSAGE.ASSIGN_RESOURCE_FAILED.value);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.ASSIGN_RESOURCE_SUCCESS.value, adminName);
                this.navigateSuccessToAdminView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }
    //End of Assign Resources

    confirmCropImage() {
        const img: any = <HTMLElement>document.getElementById("imageId");
        img.src = this.croppedImageData;
    }

    imageDirection(direction) {
        try {
            this.cropImage_spinner.show();
            if (direction === 'left') {
                this.imageCropper.rotateLeft();
            } else if (direction === 'right') {
                this.imageCropper.rotateRight();
            }
        } catch (e) {
            // do nothing
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImageData = event.base64;
        //const img: any = <HTMLElement>document.getElementById("imageId");
        //img.src = this.croppedImageData;
    }

    cropperReady() {
        const fileInput: any = document.getElementById('myFileInput');
        if (fileInput && fileInput.value) { fileInput.value = null; } // Clean previous file
        this.cropImage_spinner.hide();
    }

    async getRoleList() {
        const response: any = await this.roleService.listRole();
        this.roleList = response.body.result;
    }
}
