import { NativeDateAdapter } from "@angular/material/core";
import { Injectable } from "@angular/core";
import * as moment from "moment";

const SLASH_DDMMYYYY = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};

const SLASH_MMYYYY = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY',
    },
};

const DASH_MMYYYY = {
    parse: {
        dateInput: 'MM-YYYY',
    },
    display: {
        dateInput: 'MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY',
    },
};

const SPACE_MMMYYYY = {
    parse: {
        dateInput: 'MMM YYYY',
    },
    display: {
        dateInput: 'MMM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY',
    },
};

const DASH_DDMMYYYY = {
    parse: {
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'DD MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY',
    },
};

export const FORMATS = {
    SLASH_MMYYYY: SLASH_MMYYYY,
    SLASH_DDMMYYYY: SLASH_DDMMYYYY,
    DASH_MMYYYY: DASH_MMYYYY,
    DASH_DDMMYYYY: DASH_DDMMYYYY,
    SPACE_MMMYYYY: SPACE_MMMYYYY
};

@Injectable()
export class DatePickerCustomFormatter extends NativeDateAdapter {

    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        } else if (displayFormat === 'MM/YYYY') {
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${month.toString().length > 1 ? month : '0' + month}/${year}`;
        }
        // mat-datepicker's header format will be affected by this
        const strDate = moment(date).format("MMM YYYY");
        return strDate;
        // return date.toDateString();
    }
}
