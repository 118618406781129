import { Component, OnInit, ViewChild } from '@angular/core';

import * as AsyncUtil from '../../../util/asyncUtil';

import { PopupService } from '../../common/popup/popup.service';
import { SnackBarService } from './../../../_services/snackBar/snack-bar.service';
import { AuthService } from './../../../_services/auth';
import { SpinnerComponent } from '../../common/spinner/spinner.component';
import { CustomReuseStrategy } from '../../../app-routing-custom-reuse-strategy';

// azure ad
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    @ViewChild("logoutConfirmationPopup",{static:false})
    private logoutConfirmationPopup: any;

    constructor(
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private authService: AuthService,
        private msalService: MsalService
    ) { }

    async ngOnInit() {
        //do nothing
    }

    // public setRedirectUrlAfterLogout(redirectUrl: string) {
    //     this.authService.setRedirectUrlAfterLogout(redirectUrl);
    // }

    public show() {
        //show logout popup
        this.showPopup(this.logoutConfirmationPopup);
    }

    public hide() {
        this.hidePopup();
    }

    async confirmLogout() {
        this.pageSpinner.show();
        try {
            this.hidePopup();

            const azureActiveAccount = this.msalService.instance.getActiveAccount();
            if (azureActiveAccount) {
                this.msalService.logoutPopup();
            }
            
            await this.authService.checkSuportOnBehalfSession();
            //perform logout
            await this.authService.logout();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    /* ---- popup table ---- */
    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
