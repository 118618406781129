export const dashboardModules = {
    RECOMMENDATION: {
        value: "Recommendation",
        label: "Recommendation"
    },
    TOP_5_MILEAGE: {
        value: 'Top5Mileage',
        label: 'Top 5 Mileage'
    },
    TOP_5_SPEEDING: {
        value: 'Top5Speeding',
        label: 'Top 5 Overspeeding Cases'
    },
    TOP_5_IDLING: {
        value: 'Top5Idling',
        label: 'Top 5 Idling'
    },
    FLEET_UTIL_WEEK: {
        value: 'FleetUtilWeek',
        label: 'Fleet Utilization'
    },
    FLEET_UTIL_HOUR: {
        value: 'FleetUtilHour',
        label: 'Fleet Utilization (Hour)'
    },
    TOP_5_FUEL_PERFORMANCE: {
        value: 'Top5FuelPerformance',
        label: 'Top 5 Fuel Performance'
    },
    FUEL_PERFORMANCE_MONTH: {
        value: 'FuelPerformanceMonth',
        label: 'Fuel Performance'
    },
    SCORING: {
        value: 'Scoring',
        label: 'Score'
    },
    VIOLATION_COUNT_WEEK: {
        value: 'ViolationCountWeek',
        label: 'Weekly Violation Count'
    }
};