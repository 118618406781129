import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    constructor(private http: HttpClient) { }

    async getPageModuleList() {
        const path = environment.mtntApiEndpoint + `/role/get-page-module`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getHomePageList() {
        const path = environment.mtntApiEndpoint + `/role/get-home-page-list`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getAdminList(filterValue: string) {
        const path = environment.mtntApiEndpoint + `/role/get-admin-list${filterValue !== '' ? `?` + `filterValue=${filterValue}` : ''}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async listRole(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/role${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getRoleDetails(roleId) {
        const path = environment.mtntApiEndpoint + `/role/${roleId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async createRole(roleObj: any) {
        const path = environment.mtntApiEndpoint + `/role`;
        const result: any = await this.http.post(path, roleObj).toPromise();
        return result;
    }

    async updateRole(roleObj: any) {
        const path = environment.mtntApiEndpoint + `/role/${roleObj.id}`;
        const result: any = await this.http.post(path, roleObj).toPromise();
        return result;
    }

    async deleteRole(roleIdList = []) {
        const body: any = { body: { roleIdList: roleIdList } };
        const path = environment.mtntApiEndpoint + `/role`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }
}
