<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Incident Details - {{ incidentDetails.plateNo}}
                    </h1>
                </div>
            </div>
        </div>
    </div>



        <div class="tabs__content">
            <div class="section">
                <div class="wrapper">

                    <div class="grid --gutter">
                        <div class="grid__block w-8of12@sm form__flex">
                            <h2 class="heading indicator-green --level-2 --partial-underline">
                                Vehicle Details
                            </h2>

                            <div class="field-group --unstack@xs --space-top">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Plate No:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.plateNo || '-' }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Imei No:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.imeiNo || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Vehicle Make:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.brandName || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Vehicle Model:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.modelName || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Vehicle Type:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.vehicleType || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Activation Date:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.activationDate || '-'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section --border-top">
                <div class="wrapper">
                    <div class="grid --gutter">
                        <div class="grid__block w-8of12@sm form__flex">
                            <h2 class="heading indicator-green --level-2 --partial-underline">
                                Incident Details
                            </h2>

                            <div class="field-group --unstack@xs --space-top">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Start Date/Time:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.incidentTimeStart || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            End Date/Time:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.incidentTimeEnd || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Created By:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.createdBy || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Updated By:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.updatedBy || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Description:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.description || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Remarks:
                                        </div>
                                        <div class="field-group__input" style="padding-top: 9px;">
                                            <div class="field">
                                                   {{ incidentDetails.remark || '-'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section --border-top">
                <div class="wrapper">
                    <div class="block">

                        <div class="field-group --unstack@xs --space-top">

                            <div class="manage-device field-group__item --2-col">
                                <div style="float: left; padding-right:30px; width: 50%;">
                                    <h2 class="heading indicator-green --level-2 --partial-underline">
                                        Start Trip
                                    </h2>
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Date/Time:
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                {{ startIncidentDetails.gpsTimeStamp || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Location:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                {{ startIncidentDetails.location || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Lat,Lon:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                {{ startIncidentDetails.latlng || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Altitude:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                {{ startIncidentDetails.altitude || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Direction:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                {{ startIncidentDetails.direction || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Speed:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                {{ startIncidentDetails.speed || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div style="width:50%;padding-left:30px; padding-right:0;padding-top:0; float:left">
                                    <h2 class="heading indicator-green --level-2 --partial-underline">
                                        End Trip
                                    </h2>
                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Date/Time:
                                        </div>

                                        <div class="field-group__groups-col">
                                            <div class="field">
                                                {{ endIncidentDetails.gpsTimeStamp || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Location:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                               {{ endIncidentDetails.location || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Lat,Lon:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                               {{ endIncidentDetails.latlng || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Altitude:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                               {{ endIncidentDetails.altitude || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Direction:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                               {{ endIncidentDetails.direction || '-'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__groups">
                                        <div class="field-group__groups-col">
                                            Speed:
                                        </div>
                                        <div class="field-group__groups-col">
                                            <div class="field">
                                               {{ endIncidentDetails.speed || '-'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section --border-top">
                <div class="wrapper">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        MAP
                    </h2>
                        <div class="map__canvas" style="height: 400px; top: 10px;" id="map">
                            <!-- map area <span class="indicator-green">*</span> -->
                        </div>

                </div>
            </div>
            <div class="section --border-top">
                <div class="wrapper">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Speed Chart
                    </h2>
                     <div class="report-box">
                        <!-- <app-spinner #reportResult_spinner></app-spinner> -->
                        <div style="margin-bottom: 30px;">
                          <!-- <app-spinner #fuelUsageGraph_spinner></app-spinner> -->
                          <div [hidden]="!speedingGraph" class="graph-chart">
                            <div class="graph-title">
                              Speeding
                            </div>
                            <div class="graph-chart__panel" style="height: 450px;">
                              <!-- Insert chart here -->
                              <div style="height: 450px">
                                <canvas id="speedingChart"></canvas>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                </div>
            </div>

            <div class="section">
                <div class="wrapper">
                    <div class="grid --gutter">
                        <div class="grid__block">
                            <div class="inline-button__field">
                                <button class="button inline-button__inputs --green" submit-button type="button" (click)="navigateToIncidentAnalysisList()">
                                    BACK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>
<!-- For Alert Marker -->
<div style="display: none; position: relative">
    <div class="map-alert-floater floater --active" id="alertMarkerTemplate">
        <button type="button" class="floater__closer" (click)="closePopUp()"></button>

        <div class="map-alert-floater-header">
            [[ title ]]
            <!--Harsh Braking-->
        </div>

        <div class="map-alert-floater-main">
            <div class="map-alert-floater-block">
                <div class="map-alert-floater-label">
                    Speed
                </div>

                <div class="map-alert-floater-value">
                    [[ speed ]]
                    <!--120km/h-->
                </div>
            </div>

            <div class="map-alert-floater-block">
                <div class="map-alert-floater-label">
                    Time
                </div>

                <div class="map-alert-floater-value">
                    [[ time ]]
                    <!--08:30PM-->
                </div>
            </div>
        </div>

        <div class="map-alert-floater-footer">
            [[ address ]]
            <!--18, Jalan Dutamas Raya, Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur 51200-->
        </div>
    </div>
</div>

<!-- For Map legend -->
<div style="display: none;" id="legend">
    <div class="legend-list">
        <div class="legend-item">
            <img class="legend-item-icon" src="assets/images/ng-components/map/speeding_new.png">
            <span class="legend-item-alt">Speeding</span>
        </div>
        <div class="legend-item">
            <img class="legend-item-icon" src="assets/images/ng-components/map/oriroute_new.png">
            <span class="legend-item-alt">Original Route</span>
        </div>
    </div>
</div>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
</div>
