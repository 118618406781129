import { GeofenceService } from './../../../_services/geofence/geofence.service';
import { ViewChild, HostListener, Injectable } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { environment } from './../../../../environments/environment';

import * as moment from 'moment';
import * as DateTimeUtil from './../../../util/dateTimeUtil';
import * as StringUtil from './../../../util/stringUtil';
import * as NumberUtil from './../../../util/numberUtil';
import { GeneralReportOptions } from './GeneralReportOptionsClass';

import { PopupService } from './../../../components/common/popup/popup.service';
import { DownloadableArrayItem } from './DownloadableArrayItemClass';
import { DriverService } from './../../../_services/driver/driver.service';
import { VehicleService } from './../../../_services/vehicle/vehicle.service';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from '../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from '../../common/spinner/spinner.component';

export interface GeneralReportInterface {
    apiResponse: any;           //Store api response when getting report results
    resultList: any;            //Store report results for render table at angular
    initGeneralReportClass();  //To work properly with GeneralReportClass
    fetchPage();               //Call API to get data with pagination
    fetchDataForDownload();    //Call API to get all data for download
    generateDownloadables(): DownloadableArrayItem; //For download report
}

@Injectable()
export class GeneralReport {

    // Report Options
    options: GeneralReportOptions;

    // Services
    driverService: DriverService;
    vehicleService: VehicleService;
    geofenceService: GeofenceService;
    dateTimeUtil: any = DateTimeUtil;
    numberUtil: any = NumberUtil;

    // Responsive Date Picker (HC)
    lastResizeTime: any = new Date(0);
    resizeTimeout: any;
    datepickerTouchUi: boolean = false;

    // UI View
    @ViewChild(MatDatepicker, { static: false }) datepickerFrom: MatDatepicker<Date>;
    @ViewChild(MatDatepicker, { static: false }) datepickerTo: MatDatepicker<Date>;
    searchBy: string;         // vehicles or drivers (or geofences)
    searchType: string;       // report search type (Eg: daily, weekly, yearly report)
    fromDate: any;            // value of date picker
    toDate: any;              // value of date picker
    // monthYear: any;   // value of date picker
    startMonthYear: any;
    endMonthYear: any;

    // Current Page Search Info
    currentSearchBy: string = "";
    currentSearchType: string = "";
    currentFromDate: string = "";
    currentToDate: string = "";
    storedSearchBy: string = "";
    storedFromDate: string = "";
    storedToDate: string = "";
    // currentMonth: string = "";
    // currentYear: string = "";
    startMonth: string = "";
    startYear: string = "";
    endMonth: string = "";
    endYear: string = "";
    momFromDate: moment.Moment;
    momToDate: moment.Moment;
    momSelectedMonthYear: moment.Moment;
    sSelectedMonthYear: moment.Moment;
    eSelectedMonthYear: moment.Moment;
    currentSortField: string = "";
    currentSortAscending: boolean = true;
    storedSortField: string = "";
    storedSortAscending: boolean = true;
    excludeVehicleDeactivated: boolean = true; // filter inactive vehicle/imei
    excludeDriverDeactivated: boolean = true; // filter inactive driver/user
    isBreakdown: boolean = false;
    isUsingVehicleType: boolean = false; // check if u
    companyId = null;

    // Current Page Result Info
    apiResponse: any = {};     //Store api response when getting report results
    resultList: any = [];      //Store report results for render table at angular
    isReportSearched: boolean = false; //If performed search
    isReportShown: boolean = false; //If got records in table
    pager: any = {};          //Pager object
    pageRecordSize: number = environment.appConfig.reporting.pageRecordSize;

    // General Selector Filter Selection when Typing
    subjectSelectionFloaterIsShown: boolean = false;
    filterSearchString: string = ""; //store filter search string
    isShowSelectAllOption: boolean = true;

    // Vehicles Selector/Floater
    @ViewChild('vehiclesField', { static: false }) vehiclesField;
    vehiclesModelCompleteVehiclesList: any = []; //complete vehicle list no filter
    vehiclesModel: any = {}; //store vehicles to display for selection
    vehicleTypesModel: any = {} //store vehicle types to display for selection
    selectedVehicles: any = {}; //selection state of all vehicles
    selectedVehicleGroups: any = {}; //selection state of all groups
    selectedVehiclesList: any = []; //selected vehicle list
    vehiclesSelectedLabel: string = 'All Vehicles';

    // Drivers Selector/Floater
    @ViewChild('driversField', { static: false }) driversField;
    driversModelCompleteDriversList: any = [];
    driversModel: any = {};
    selectedDrivers: any = {};
    selectedDriverGroups: any = {};
    selectedDriversList: any = [];
    driversSelectedLabel: string = 'All Drivers';

    // Drivers Tag Selector/Floater
    @ViewChild('driverTagField', { static: false }) driverTagField;
    driverTagModelCompleteDriverTagList: any = [];
    driverTagModel: any = {};
    selectedDriverTag: any = {};
    selectedDriverTagGroups: any = {};
    selectedDriverTagList: any = [];
    driverTagSelectedLabel: string = 'All Driver Tag';

    // Geofences Selector/Floater
    @ViewChild('geofencesField', { static: false }) geofencesField;
    geofencesModelCompleteGeofencesList: any = [];
    geofencesModel: any = {};
    selectedGeofences: any = {};
    selectedGeofenceGroups: any = {};
    selectedGeofencesList: any = [];
    geofencesSelectedLabel: string = 'All Geofences';

    // Downloads
    downloadableItem: DownloadableArrayItem;

    // Children Accessible Methods (GeneralReportInterface)
    childFetchPage: (page: number) => void;
    childfetchDataForDownload: () => any[];
    childGenDownloadables: (recordList: Array<any>) => DownloadableArrayItem;
    childPageSpinner: SpinnerComponent;

    //Vehicle select list
    vehicleInfoList = [];
    //Driver select list
    geofenceInfoList = [];
    /* all services should be passed from children component */
    constructor(private http: HttpClient, private popupService: PopupService, private generalSnackBar: SnackBarService) {

        /**
          * Methods for children to use in angular/html/component:
          *  - generateReport()
          *  - doSorting(sortField)
          *  - getSortingState(sortField)
          *  - updateSortingState(this.apiResponse.sort)
          *  - onVehiclesAllSelectChange()
          *  - onVehiclesGroupSelectChange(groupIndex)
          *  - onVehiclesSelectChange()
          *  - toggleSubjectSelectionFloater(boolean)
          *  - filterVehiclesSelection($event.target.value)
          *  - showDownloadPopup(downloadsPopup)
          *  - btnExcel()
          *  - btnPdf()
          *  - btnCsv()
          *  - onOutsideClick($event)
        */
    }

    handShakeImplementations(
        childFetchPage: (page: number) => void,
        childfetchDataForDownload: () => any[],
        childGenDownloadables: (recordList: Array<any>) => DownloadableArrayItem,
        childPageSpinner: SpinnerComponent): void {

        this.childFetchPage = childFetchPage;
        this.childfetchDataForDownload = childfetchDataForDownload;
        this.childGenDownloadables = childGenDownloadables;
        this.childPageSpinner = childPageSpinner;
    }

    async initialise(options: any) {
        // this.childPageSpinner.show();

        //Set Options
        this.options = new GeneralReportOptions(options);

        // Driver Selector (async)
        if (this.options.isUsingDriverSelector()) {
            this.driverService = new DriverService(this.http);
            const sortField = 'FirstName';
            const sortAscending = true;

            this.driverService.getGroupDriverModelByCompany(sortField, sortAscending, false, null, this.excludeDriverDeactivated).then((driversModel) => {
                this.driversModel = driversModel || {};
                this.driversModelCompleteDriversList = this.driversModel.drivers.slice(0);
                this.selectedDriverGroups.all = true; //default select all driver
                this.onDriversAllSelectChange();
            }).catch(err => {
                //do nothing
            });

            // this.driversModel = await this.driverService.getGroupDriverModelByCompany(sortField, sortAscending) || {};
            // this.driversModelCompleteDriversList = this.driversModel.drivers.slice(0);
            // this.selectedDriverGroups.all = true; //default select all driver
            // this.onDriversAllSelectChange();
        }

        // Geofence Selector (async)
        if (this.options.isUsingGeofenceSelector()) {
            this.geofenceService = new GeofenceService(this.http);
            this.geofenceService.getGeofenceModelByCompany().then((geofencesModel) => {
                this.geofencesModel = geofencesModel || {};
                this.geofencesModelCompleteGeofencesList = this.geofencesModel.geofences.slice(0);
                this.selectedGeofenceGroups.all = true; //default select all geofence
                this.onGeofencesAllSelectChange();
            }).catch(err => {
                //do nothing
            });

            // this.geofencesModel = await this.geofenceService.getGeofenceModelByCompany() || {};
            // this.geofencesModelCompleteGeofencesList = this.geofencesModel.geofences.slice(0);
            // this.selectedGeofenceGroups.all = true; //default select all geofence
            // this.onGeofencesAllSelectChange();
        }

        // Driver Tag Selector (async)
        if (this.options.isUsingDriverTagSelector()) {
            this.driverService = new DriverService(this.http);
            this.driverService.getDriverTagListByCompanyId().then((response) => {
                // console.log(response);
                this.driverTagModel = response.body || {};
                this.driverTagModelCompleteDriverTagList = this.driverTagModel.driverTags.slice(0);
                this.selectedDriverTagGroups.all = true; //default select all geofence
                this.onDriverTagAllSelectChange();
            }).catch(err => {
                //do nothing
            });
        }

        // Vehicle Selector (synchronous)
        if (this.options.isUsingVehicleSelector()) {
            this.vehicleService = new VehicleService(this.http);
            const sortField = "Name";
            const sortAscending = true;
            this.vehiclesModel = await this.vehicleService.getGroupVehicleModelByCompany(sortField, sortAscending, null, this.excludeVehicleDeactivated) || {};
            this.vehiclesModelCompleteVehiclesList = this.vehiclesModel.vehicles.slice(0);
            this.selectedVehicleGroups.all = true; //default select all vehicle
            this.onVehiclesAllSelectChange();
        }

        if(this.options.isUsingVehicleTypeSelector()) {                        
            this.isUsingVehicleType = true;
            this.vehicleService = new VehicleService(this.http);
            const sortField = "Utilization";
            const sortAscending = true;
            this.vehiclesModel = await this.vehicleService.getGroupVehicleTypeModelByCompany(sortField, sortAscending, null, this.excludeVehicleDeactivated, this.companyId);
            this.vehiclesModelCompleteVehiclesList = this.vehiclesModel.vehicles.slice(0);
            // this.vehiclesModelCompleteVehiclesList = this.vehiclesModel.vehicleTypes;
            this.selectedVehicleGroups.all = true; //default select all vehicle
            this.onVehicleTypesAllSelectChange();
        }

        this.updateDatepickerTouchUi(); //HC
    }

    /* ---- Generate Report ---- */
    // Generate Report button pressed
    async generateReport(storeParameter: boolean = false) {
        if (this.options.isUsingDateRangePicker()) {
            const momFromDate: moment.Moment = moment(this.fromDate);
            const momToDate: moment.Moment = moment(this.toDate);

            const dateValidation = DateTimeUtil.checkDateRange(momFromDate, momToDate);
            if (!dateValidation.pass) {
                this.generalSnackBar.openGenericSnackBar(dateValidation.message);
                return false;
            }
            this.momFromDate = moment(momFromDate);
            this.momToDate = moment(momToDate);
            this.currentFromDate = this.momFromDate.format("YYYY-MM-DD");
            this.currentToDate = this.momToDate.format("YYYY-MM-DD");
            if (storeParameter) {
                this.storedFromDate = this.currentFromDate;
                this.storedToDate = this.currentToDate;
            }
        } else if (this.options.isUsingMonthYearPicker()) {
            // const momSelectedMonthYear: moment.Moment = moment(this.monthYear);
            // this.momSelectedMonthYear = moment(momSelectedMonthYear);
            // this.currentMonth = this.momSelectedMonthYear.format("MM");
            // this.currentYear = this.momSelectedMonthYear.format("YYYY");
            const sSelectedMonthYear: moment.Moment = moment(this.startMonthYear);
            this.sSelectedMonthYear = moment(sSelectedMonthYear);
            this.startMonth = this.sSelectedMonthYear.format("MM");
            this.startYear = this.sSelectedMonthYear.format("YYYY");
            const eSelectedMonthYear: moment.Moment = moment(this.endMonthYear);
            this.eSelectedMonthYear = moment(eSelectedMonthYear);
            this.endMonth = this.eSelectedMonthYear.format("MM");
            this.endYear = this.eSelectedMonthYear.format("YYYY");
        }

        if (this.options.isUsingSearchBy()) {
            if (Boolean(this.currentSearchBy) && this.currentSearchBy != this.searchBy) {
                this.clearCurrentSearchCache();
            }
            this.currentSearchBy = this.searchBy;
            if (storeParameter) {
                this.storedSearchBy = this.currentSearchBy;
            }
        }
        if (this.options.isUsingSearchType()) {
            if (Boolean(this.currentSearchType) && this.currentSearchType != this.searchType) {
                this.clearCurrentSearchCache();
            }
            this.currentSearchType = this.searchType;
        }
        /*
        * This methods always ensure results is reset when generate button is clicked
        * So when back end delay|down|error it will stil show no result in front end
        * isReportShown = false already set once in child fetchPage(), so will be ignore
        */
        //clear table results
        this.apiResponse = {};
        this.resultList = [];
        this.pager = {};
        this.isReportShown = false;

        //call children fetchPage() implementation
        await this.childFetchPage(1);
    }
    clearCurrentSearchCache(): void {
        //reset sorting cache
        this.currentSortField = "";
        this.currentSortAscending = true;
    }

    /* ---- Sorting ---- */
    // Sorting Column Field
    async doSorting(sortField = "") {
        sortField = sortField.trim();
        if (sortField == "") {
            return;
        }

        //toggle ascending/descending
        if (StringUtil.equalsIgnoreCase(sortField, this.currentSortField)) {
            this.currentSortAscending = !this.currentSortAscending;
        } else {
            this.currentSortAscending = true;
        }
        this.currentSortField = StringUtil.headToLowerCase(sortField);

        await this.childFetchPage(1);
    }
    getSortingState(sortField: string = ""): string {
        let sortingClass = "";
        sortField = sortField.trim();
        if (sortField == "") {
            return;
        } else if (StringUtil.equalsIgnoreCase(sortField, this.currentSortField)) {
            sortingClass = this.currentSortAscending ? '--ascending' : '--descending';
        }
        return sortingClass;
    }
    updateSortingState(sortObject: any): void {
        if (Boolean(sortObject)) {
            this.currentSortField = StringUtil.headToLowerCase(sortObject.sortField);
            this.currentSortAscending = sortObject.sortAscending;
        } else {
            this.currentSortField = "";
            this.currentSortAscending = true;
        }
    }

    /* ---- Responsive Date Picker (HC) ---- */
    @HostListener('window:resize') onResize() {
        const now: any = new Date();
        const diff: any = now - this.lastResizeTime;

        clearTimeout(this.resizeTimeout);

        if (diff >= 100) {
            this.updateDatepickerTouchUi();
            this.lastResizeTime = now;
        } else {
            this.resizeTimeout = setTimeout(() => {
                this.updateDatepickerTouchUi();
                this.lastResizeTime = new Date();
            }, diff);
        }
    }
    private updateDatepickerTouchUi() {
        this.datepickerTouchUi = (window.innerWidth <= 480);
    }

    toggleSubjectSelectionFloater(bool: boolean) {
        this.subjectSelectionFloaterIsShown = bool;
    }

    /* ---- Vehicle Selection Floater ---- */
    onVehiclesAllSelectChange() {

        // Note: Apply to ALL vehicles that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedVehicleGroups.all;
        for (let i = 0; i < this.vehiclesModelCompleteVehiclesList.length; i++) {
            const vehicleModel = this.vehiclesModelCompleteVehiclesList[i];

            //check group
            if (vehicleModel.type === 'GROUP') {
                this.selectedVehicleGroups[i] = allIsSelected;
            }

            //check all vehicles in group
            for (let j = 0; j < vehicleModel.idList.length; j++) {
                this.selectedVehicles[vehicleModel.idList[j].vehicleId] = allIsSelected;
            }
        }
        this.updateVehiclesSelectedLabel();
    }
    onVehicleTypesAllSelectChange() {
        // Note: Apply to ALL vehicles that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedVehicleGroups.all;
        for (let i = 0; i < this.vehiclesModelCompleteVehiclesList.length; i++) {
            const vehicleModel = this.vehiclesModelCompleteVehiclesList[i];

            this.selectedVehicles[vehicleModel] = allIsSelected;
        }
        this.updateVehiclesSelectedLabel();
    }
    onVehiclesGroupSelectChange(groupIndex: number) {

        // Note: Apply ONLY to vehicles that are VISIBLE
        const vehicleList = this.vehiclesModel.vehicles[groupIndex].idList;
        const groupIsSelected = this.selectedVehicleGroups[groupIndex];
        for (let i = 0; i < vehicleList.length; i++) {
            this.selectedVehicles[vehicleList[i].vehicleId] = groupIsSelected;
        }

        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
        this.updateVehiclesSelectedLabel();
        this.focusVehicleSearchBox();
    }
    onVehiclesSelectChange() {
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
        this.updateVehiclesSelectedLabel();
        this.focusVehicleSearchBox();
    }

    onRedirectUpdateVehiclesSelectChange() {
        //use when redirect with selected values
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
        this.updateVehiclesSelectedLabel();
    }

    private updateVehiclesSelectedLabel() {

        this.selectedVehiclesList = [];
        for (const key in this.selectedVehicles) {
            if (this.selectedVehicles[key] === true) {
                if(this.isUsingVehicleType) {
                    this.selectedVehiclesList.push(key);
                } else {
                    this.selectedVehiclesList.push(parseInt(key));
                }                
            }
        }
        const sType = this.isUsingVehicleType ? "equipment" : "vehicle";
        const sTypes = this.isUsingVehicleType ? "equipment" : "vehicles";
        const totalVehicles = this.selectedVehiclesList.length;
        if (this.selectedVehicleGroups.all) {
            this.vehiclesSelectedLabel = 'All ' + sTypes +' selected (' + totalVehicles + ')';
        } else {
            if (totalVehicles === 0) {
                this.vehiclesSelectedLabel = 'Please select ' + sType;
            } else if (totalVehicles === 1) {
                this.vehiclesSelectedLabel = '1 ' + sType + ' selected';
            } else {
                this.vehiclesSelectedLabel = totalVehicles + ' ' + sTypes + ' selected';
            }
        }
    }
    private getVehicleSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.vehiclesField.nativeElement.querySelector("#vehicleSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }
    filterVehiclesSelection(searchString: string = "") {
        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.isShowSelectAllOption = true;
            this.vehiclesModel.vehicles = this.vehiclesModelCompleteVehiclesList;
            // this.getVehicleSearchBox().value = "";
        } else {
            this.isShowSelectAllOption = false;
            if(this.isUsingVehicleType) {
                const tempList = JSON.parse(JSON.stringify(this.vehiclesModelCompleteVehiclesList));
                const newList = tempList.slice(0).filter(this.filterLogicVehicleName.bind(this))
                this.vehiclesModel.vehicles = newList;
            } else {
                //clone deep copy
                const tempList = JSON.parse(JSON.stringify(this.vehiclesModelCompleteVehiclesList));
                const newList = [];

                //do searching
                for (const group of tempList) {
                    if (Boolean(group.idList) && group.idList.length) {
                        let tempVehicleList = group.idList;
                        tempVehicleList = tempVehicleList.slice(0).filter(this.filterLogicVehicleName.bind(this));
                        group.idList = tempVehicleList;
                    }
                    if (Boolean(group.idList) && group.idList.length) {
                        newList.push(group);
                    }
                }
                this.vehiclesModel.vehicles = newList;
            }            
        }
        this.fixVehiclesCheckBoxesStates(); // Tick correct checkboxes
    }
    private filterLogicVehicleName(vehicleItem: any): boolean {
        if (this.filterSearchString) {
            const normalisedVehicleName = this.isUsingVehicleType ? vehicleItem.toLowerCase().replace(' ', '').trim() :  vehicleItem.vehicleName.toLowerCase().replace(' ', '').trim();
            const normalisedFilterName = this.filterSearchString.toLowerCase().replace(' ', '').trim();
            if (normalisedVehicleName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }
        return true;
    }
    private focusVehicleSearchBox() {
        const searchBox = this.getVehicleSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private fixVehiclesCheckBoxesStates() {
        // Note: Apply ONLY to vehicles that are VISIBLE
        let allIsSelected = true;
        if(this.isUsingVehicleType) {
            for (let j = 0; j < this.vehiclesModel.vehicles.length; j++) {
                const vehicle = this.vehiclesModel.vehicles[j];
                if (!this.selectedVehicles[vehicle]) {

                    allIsSelected = false;
                    break;
                }
            }
            this.selectedVehicleGroups.all = allIsSelected;
            if (this.vehiclesModel.vehicles.length == 0) {
                this.selectedVehicleGroups.all = false;
            }

        }else {
            for (let j = 0; j < this.vehiclesModel.vehicles.length; j++) {
                const group = this.vehiclesModel.vehicles[j];
                let groupIsSelected = true;
                for (let k = 0; k < group.idList.length; k++) {
                    const vehicle = group.idList[k];
                    if (!this.selectedVehicles[vehicle.vehicleId]) {
                        allIsSelected = false;
                        groupIsSelected = false;
                        break;
                    }
                }
                this.selectedVehicleGroups[j] = groupIsSelected;
            }
            this.selectedVehicleGroups.all = allIsSelected;
            if (this.vehiclesModel.vehicles.length == 0) {
                this.selectedVehicleGroups.all = false;
            }
        }
        
    }

    /* ---- Driver Selection Floater ---- */
    onDriversAllSelectChange() {

        // Note: Apply to ALL drivers that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedDriverGroups.all;
        for (let i = 0; i < this.driversModelCompleteDriversList.length; i++) {
            const driverModel = this.driversModelCompleteDriversList[i];

            //check group
            if (driverModel.type === 'GROUP') {
                this.selectedDriverGroups[i] = allIsSelected;
            }

            //check all drivers in group
            for (let j = 0; j < driverModel.idList.length; j++) {
                this.selectedDrivers[driverModel.idList[j].driverId] = allIsSelected;
            }
        }
        this.updateDriversSelectedLabel();
    }
    onDriversGroupSelectChange(groupIndex: number) {

        // Note: Apply ONLY to drivers that are VISIBLE
        const driverList = this.driversModel.drivers[groupIndex].idList;
        const groupIsSelected = this.selectedDriverGroups[groupIndex];
        for (let i = 0; i < driverList.length; i++) {
            this.selectedDrivers[driverList[i].driverId] = groupIsSelected;
        }

        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
        this.updateDriversSelectedLabel();
        this.focusDriverSearchBox();
    }
    onDriversSelectChange() {
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
        this.updateDriversSelectedLabel();
        this.focusDriverSearchBox();
    }
    private updateDriversSelectedLabel() {

        this.selectedDriversList = [];
        for (const key in this.selectedDrivers) {
            if (this.selectedDrivers[key] === true) {
                this.selectedDriversList.push(parseInt(key));
            }
        }

        const totalDrivers = this.selectedDriversList.length;
        if (this.selectedDriverGroups.all) {
            this.driversSelectedLabel = 'All drivers selected (' + totalDrivers + ')';
        } else {
            if (totalDrivers === 0) {
                this.driversSelectedLabel = 'Please select driver';
            } else if (totalDrivers === 1) {
                this.driversSelectedLabel = '1 driver selected';
            } else {
                this.driversSelectedLabel = totalDrivers + ' drivers selected';
            }
        }
    }
    private getDriverSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.driversField.nativeElement.querySelector("#driverSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }
    filterDriversSelection(searchString: string = "") {

        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.isShowSelectAllOption = true;
            this.driversModel.drivers = this.driversModelCompleteDriversList;
            // this.getDriverSearchBox().value = "";
        } else {
            this.isShowSelectAllOption = false;
            //clone deep copy
            const tempList = JSON.parse(JSON.stringify(this.driversModelCompleteDriversList));
            const newList = [];

            //do searching
            for (const group of tempList) {
                if (Boolean(group.idList) && group.idList.length) {
                    let tempidList = group.idList;
                    tempidList = tempidList.slice(0).filter(this.filterLogicDriverName.bind(this));
                    group.idList = tempidList;
                }
                if (Boolean(group.idList) && group.idList.length) {
                    newList.push(group);
                }
            }
            this.driversModel.drivers = newList;
        }
        this.fixDriversCheckBoxesStates(); // Tick correct checkboxes
    }
    private filterLogicDriverName(driverItem: any): boolean {
        if (this.filterSearchString) {
            const normalisedDriverName = driverItem.driverName.toLowerCase().replace(' ', '').trim();
            const normalisedFilterName = this.filterSearchString.toLowerCase().replace(' ', '').trim();
            if (normalisedDriverName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }
        return true;
    }
    private focusDriverSearchBox() {
        const searchBox = this.getDriverSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private fixDriversCheckBoxesStates() {
        // Note: Apply ONLY to drivers that are VISIBLE
        let allIsSelected = true;
        for (let j = 0; j < this.driversModel.drivers.length; j++) {
            const group = this.driversModel.drivers[j];
            let groupIsSelected = true;
            for (let k = 0; k < group.idList.length; k++) {
                const driver = group.idList[k];
                if (!this.selectedDrivers[driver.driverId]) {
                    allIsSelected = false;
                    groupIsSelected = false;
                    break;
                }
            }
            this.selectedDriverGroups[j] = groupIsSelected;
        }
        this.selectedDriverGroups.all = allIsSelected;

        if (this.driversModel.drivers.length == 0) {
            this.selectedDriverGroups.all = false;
        }
    }

    /* ---- Driver Tag Selection Floater ---- */
    onDriverTagAllSelectChange() {

        // Note: Apply to ALL driver tag that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedDriverTagGroups.all;
        for (let i = 0; i < this.driverTagModelCompleteDriverTagList.length; i++) {
            this.selectedDriverTag[this.driverTagModelCompleteDriverTagList[i].driverTagId] = allIsSelected;
        }
        this.updateDriverTagSelectedLabel();
        this.focusDriverTagSearchBox();
    }
    onDriverTagSelectChange() {
        this.fixDriverTagCheckBoxesStates(); // Tick correct checkboxes
        this.updateDriverTagSelectedLabel();
        this.focusDriverTagSearchBox();
    }
    private focusDriverTagSearchBox() {
        const searchBox = this.getDriverTagSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private getDriverTagSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.driverTagField.nativeElement.querySelector("#driverTagSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }
    private fixDriverTagCheckBoxesStates() {
        let allIsSelected = true;

        for (let j = 0; j < this.driverTagModel.driverTags.length; j++) {
            if (!this.selectedDriverTag[this.driverTagModelCompleteDriverTagList[j].driverTagId]) {
                allIsSelected = false;
                break;
            }
        }
        this.selectedDriverTagGroups.all = allIsSelected;
    }
    private updateDriverTagSelectedLabel() {
        this.selectedDriverTagList = [];
        for (const key in this.selectedDriverTag) {
            if (this.selectedDriverTag[key] === true) {
                this.selectedDriverTagList.push(parseInt(key));
            }
        }

        const totalDriverTag = this.selectedDriverTagList.length;
        if (this.selectedDriverTagGroups.all) {
            this.driverTagSelectedLabel = 'All driver tag selected (' + totalDriverTag + ')';
        } else {
            if (totalDriverTag === 0) {
                this.driverTagSelectedLabel = 'Please select driver tag';
            } else if (totalDriverTag === 1) {
                this.driverTagSelectedLabel = '1 driver tag selected';
            } else {
                this.driverTagSelectedLabel = totalDriverTag + ' driver tag selected';
            }
        }
    }
    filterDriverTagSelection(searchString: string = "") {
        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.isShowSelectAllOption = true;
            this.driverTagModel.driverTags = this.driverTagModelCompleteDriverTagList;
            // this.getDriverTagSearchBox().value = "";
        } else {
            this.isShowSelectAllOption = false;
            this.driverTagModel.driverTags = this.driverTagModelCompleteDriverTagList.filter(result => result.driverTagNo.toLowerCase().trim().indexOf(searchString) > -1);
        }
    }

    /* ---- Geofence Selection Floater ---- */
    onGeofencesAllSelectChange() {

        // Note: Apply to ALL geofences that are VISIBLE+HIDDEN
        const allIsSelected = this.selectedGeofenceGroups.all;
        for (let i = 0; i < this.geofencesModelCompleteGeofencesList.length; i++) {
            this.selectedGeofences[this.geofencesModelCompleteGeofencesList[i].GeoFenceName] = allIsSelected;
        }
        this.updateGeofencesSelectedLabel();
        this.focusGeofenceSearchBox();
    }
    onGeofencesSelectChange() {
        this.fixGeofencesCheckBoxesStates(); // Tick correct checkboxes
        this.updateGeofencesSelectedLabel();
        this.focusGeofenceSearchBox();
    }
    private focusGeofenceSearchBox() {
        const searchBox = this.getGeofenceSearchBox();
        if (Boolean(searchBox)) {
            searchBox.focus();
        }
    }
    private getGeofenceSearchBox(): any {
        let searchBox: any = null;
        try {
            searchBox = this.geofencesField.nativeElement.querySelector("#geofenceSearchBox");
        } catch (err) {
            //do nothing
        }
        return searchBox;
    }
    private fixGeofencesCheckBoxesStates() {
        let allIsSelected = true;

        for (let j = 0; j < this.geofencesModel.geofences.length; j++) {
            if (!this.selectedGeofences[this.geofencesModelCompleteGeofencesList[j].GeoFenceName]) {
                allIsSelected = false;
                break;
            }
        }
        this.selectedGeofenceGroups.all = allIsSelected;
    }
    private updateGeofencesSelectedLabel() {
        this.selectedGeofencesList = [];
        for (const key in this.selectedGeofences) {
            if (this.selectedGeofences[key] === true) {
                this.selectedGeofencesList.push(key);
            }
        }

        const totalGeofences = this.selectedGeofencesList.length;
        if (this.selectedGeofenceGroups.all) {
            this.geofencesSelectedLabel = 'All geofences selected (' + totalGeofences + ')';
        } else {
            if (totalGeofences === 0) {
                this.geofencesSelectedLabel = 'Please select geofence';
            } else if (totalGeofences === 1) {
                this.geofencesSelectedLabel = '1 geofence selected';
            } else {
                this.geofencesSelectedLabel = totalGeofences + ' geofences selected';
            }
        }
    }
    filterGeofencesSelection(searchString: string = "") {
        this.filterSearchString = searchString;
        if (searchString.trim().length == 0) {
            this.isShowSelectAllOption = true;
            this.geofencesModel.geofences = this.geofencesModelCompleteGeofencesList;
            // this.getGeofenceSearchBox().value = "";
        } else {
            this.isShowSelectAllOption = false;
            this.geofencesModel.geofences = this.geofencesModelCompleteGeofencesList.filter(result => result.GeoFenceName.toLowerCase().trim().indexOf(searchString) > -1);
        }
    }

    /* ---- PopUp Download File Type Selection ---- */
    showDownloadPopup(popup) {
        this.popupService.show(popup);
    }
    hideDownloadPopup() {
        this.popupService.hide();
    }

    /* ---- Download File ---- */
    private async initDownload() {
        this.hideDownloadPopup();
        if (!this.childPageSpinner.isShowing()) {
            this.childPageSpinner.show();
        }
        try {
            const downloadedRecords: Array<any> = await this.childfetchDataForDownload();
            this.downloadableItem = this.childGenDownloadables(downloadedRecords);
        } catch (err) {
            this.generalSnackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.childPageSpinner.hide();
        }
    }
    async btnExcel() {
        await this.initDownload();
        await this.downloadableItem.downloadExcelFile();
    }
    async btnPdf(startDate = null, endDate = null) {
        let dateString = '';
        if (startDate == null && endDate == null){
            dateString = `${moment(this.currentFromDate).format("DD/MM/YYYY")} - ${moment(this.currentToDate).format("DD/MM/YYYY")}`;
        } else {
            dateString = `${moment(startDate).format("MM/YYYY")} - ${moment(endDate).format("MM/YYYY")}`;
        }
        const dateRange = dateString;
        await this.initDownload();
        await this.downloadableItem.downloadPdfFile(dateRange);
    }
    async btnCsv() {
        await this.initDownload();
        await this.downloadableItem.downloadCsvFile();
    }

    /* ---- Outside Click Event ---- */
    // onOutsideClick(clickEvent?: Event) {
    //     if (!clickEvent || !clickEvent.target) {
    //         return;
    //     }
    //     if (this.options.isUsingVehicleSelector()) {
    //         this.onOutsideClickVehicleReaction(clickEvent);
    //     }
    //     if (this.options.isUsingDriverSelector()) {
    //         this.onOutsideClickDriverReaction(clickEvent);
    //     }
    //     if (this.options.isUsingGeofenceSelector()) {
    //         this.onOutsideClickGeofenceReaction(clickEvent);
    //     }
    // }
    onOutsideClickVehicleReaction(clickEvent: Event): void {
        // if (Boolean(this.vehiclesField) && !this.vehiclesField.nativeElement.contains(clickEvent.target)) {
        // var el = clickEvent.target || clickEvent.srcElement;
        // if (!(el instanceof HTMLImageElement)) {
        this.toggleSubjectSelectionFloater(false);
        this.filterVehiclesSelection();
        // }
        // }
    }
    onOutsideClickDriverReaction(clickEvent: Event): void {
        // if (Boolean(this.driversField) && !this.driversField.nativeElement.contains(clickEvent.target)) {
        // var el = clickEvent.target || clickEvent.srcElement;
        // if (!(el instanceof HTMLImageElement)) {
        this.toggleSubjectSelectionFloater(false);
        this.filterDriversSelection();
        // }
        // }
    }
    onOutsideClickDriverTagReaction(clickEvent: Event): void {
        // if (Boolean(this.driversField) && !this.driversField.nativeElement.contains(clickEvent.target)) {
        // var el = clickEvent.target || clickEvent.srcElement;
        // if (!(el instanceof HTMLImageElement)) {
        this.toggleSubjectSelectionFloater(false);
        this.filterDriverTagSelection();
        // }
        // }
    }
    onOutsideClickGeofenceReaction(clickEvent: Event): void {
        // if (Boolean(this.geofencesField) && !this.geofencesField.nativeElement.contains(clickEvent.target)) {
        // var el = clickEvent.target || clickEvent.srcElement;
        // if (!(el instanceof HTMLImageElement)) {
        this.toggleSubjectSelectionFloater(false);
        this.filterGeofencesSelection();
        // }
        // }
    }
    /* ---- DropDown For Only one Vehicle Select ---- */
    async getVehiclesInfo() {
        // Pull for dropdown box
        this.vehicleService = new VehicleService(this.http);
        const vehicleList = await this.vehicleService.getAllVehicleInfoDetails();
        if (vehicleList) {
            this.vehicleInfoList = vehicleList.body.vehicle_details_list;
            this.vehicleInfoList = this.vehicleInfoList.filter(result => StringUtil.isNotEmptyOrNull(result.vehiclePlateNo));
        }
    }
    /* ---- DropDown For Only one Geofence Select ---- */
    async getGeofenceInfo() {
        // Pull for dropdown box
        this.geofenceService = new GeofenceService(this.http);
        const geofenceList = await this.geofenceService.getGeofenceModelByCompany();
        if (geofenceList) {
            this.geofenceInfoList = geofenceList.geofences;
            // this.geofenceInfoList = this.geofenceInfoList.filter(result => StringUtil.isNotEmptyOrNull(result.vehiclePlateNo));
        }
    }
}
