<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <!-- <app-breadcrumb></app-breadcrumb> -->

            <div class="grid --gutter">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Support
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <h2 class="heading indicator-green --level-2 --partial-underline">
                    Download User Guide
                </h2>
            </div>

            <div class="block">
                <div class="download-container">
                    <div class="download-row">
                        File
                    </div>

                    <div class="download-row">
                        <div class="download-row-col">
                            <img src="assets/images/scss-components/key-value-icons/icon-pdf.svg" alt="" class="download-pdf__icon">

                            <div class="download-pdf__desc">
                                {{environment.appConfig.support.userGuidePdfFileName}}
                            </div>
                        </div>

                        <div class="download-row-col">
                            <button class="button download-button" (click)="downloadUserGuideMenu()">
                                <img src="assets/images/scss-components/key-value-icons/icon-download.svg" alt="" class="download-button__icon">

                                <div class="download-button__desc">
                                    Download
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section --border-top">
        <div class="wrapper">
            <div class="block">
                <h2 class="heading indicator-green --level-2 --partial-underline">
                    Get In Touch With Us
                </h2>
            </div>

            <div class="block">
                <div class="get-in-touch-container">
                    <div class="get-in-touch-row">
                        Please call or email us if you have any questions, issues or concerns.
                    </div>

                    <div class="get-in-touch-row">
                        <img src="assets/images/scss-components/key-value-icons/icon-contact.svg" alt="" class="get-in-touch__icon">

                        <div class="get-in-touch__desc">
                            {{environment.appConfig.support.supportHotline}}
                        </div>
                    </div>

                    <div class="get-in-touch-row">
                        <img src="assets/images/scss-components/key-value-icons/icon-email.svg" alt="" class="get-in-touch__icon">

                        <div class="get-in-touch__desc">
                            {{environment.appConfig.support.supportEmail}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="systemInfo-container">
                    <div class="systemInfo">
                        Version: mDrive {{ systemInfo.version }} <img src="/assets/images/ng-components/site-info/copyright.svg" alt=""> {{ systemInfo.copyrightYear }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
<!-- <div *ngIf="isLoaded" style="text-align: center;">
    <button (click)="prevPage()" [disabled]="page === 1">Prev</button>
    <span>{{ page }} / {{ totalPages }}</span>
    <button (click)="nextPage()" [disabled]="page === totalPages">Next</button>
</div>

<pdf-viewer [src]="pdfUrl" [show-all]="false" [page]="page" (after-load-complete)="afterLoadComplete($event)"></pdf-viewer> -->
