<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>
            <h1 class="reports__heading   heading --level-1">
                Route Completion Report
            </h1>

            <div class="reports__filters" style="position: relative; z-index: 3;">
                <div class="grid --gutter   reports__filters-grid">
                    <div class="grid__block   reports__filters-grid-block --type">
                        <div class="field-group --unstack@xs   reports__type-field-group">

                            <div class="field-group__item   reports__type-field-group-select"
                                [class.--active]="typeDOMStates.isFocused">
                                <label class="select --grey">
                                    <select class="select__input" [(ngModel)]="searchBy" dom-states
                                        #typeDOMStates="DOMStates">
                                        <option value="vehicles">Vehicles</option>
                                        <option value="drivers">Drivers</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__item   reports__type-field-group-input"
                                [class.--active]="vehiclesSearchDOMStates.isFocused" *ngIf="searchBy === 'vehicles'"
                                click-outside (clickOutside)="onOutsideClickVehicleReaction($event)">
                                <div class="field" #vehiclesField>
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <img src="assets/images/scss-components/forms/icon-vehicle.svg"
                                                    width="26" height="20" alt="" class="field__icon-image">
                                            </div>
                                        </div>

                                        <label class="field__holder">
                                            <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                {{ vehiclesSelectedLabel }}
                                            </div>

                                            <input id="vehicleSearchBox" type="text" autocomplete="off"
                                                class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown"
                                                dom-states #vehiclesSearchDOMStates="DOMStates"
                                                (focus)="toggleSubjectSelectionFloater(true)"
                                                (keyup)="filterVehiclesSelection($event.target.value)">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>

                                    <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                        <ul class="inline-input-list">
                                            <li *ngIf="!vehiclesModel.vehicles.length" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    No vehicles found
                                                </label>
                                            </li>
                                            <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true"
                                                        class="inline-input__input"
                                                        [(ngModel)]="selectedVehicleGroups['all']"
                                                        (ngModelChange)="onVehiclesAllSelectChange()">

                                                    <span class="inline-input__icon"></span>

                                                    All vehicles
                                                </label>
                                            </li>

                                            <ng-container
                                                *ngFor="let group of vehiclesModel.vehicles; let groupIndex = index;">
                                                <ng-container *ngIf="group.type === 'INDIVIDUAL'">
                                                    <li class="inline-input-list__item truncate-word"
                                                        *ngFor="let item of group.idList">
                                                        <label class="inline-input">
                                                            <input type="checkbox" [name]="item.vehicleId"
                                                                [value]="true" class="inline-input__input"
                                                                [(ngModel)]="selectedVehicles[item.vehicleId]"
                                                                (ngModelChange)="onVehiclesSelectChange()">

                                                            <span class="inline-input__icon"></span>

                                                            {{ item.vehicleName }}
                                                        </label>
                                                    </li>
                                                </ng-container>

                                                <ng-container *ngIf="group.type === 'GROUP'">
                                                    <li class="inline-input-list__item">
                                                        <label class="inline-input">
                                                            <input type="checkbox" [name]="group.name" [value]="true"
                                                                class="inline-input__input"
                                                                [(ngModel)]="selectedVehicleGroups[groupIndex]"
                                                                (ngModelChange)="onVehiclesGroupSelectChange(groupIndex);">

                                                            <span class="inline-input__icon"></span>

                                                            {{ group.name }}
                                                        </label>

                                                        <button class="item-group-expand"
                                                            (click)="expandGroupVehicle[groupIndex] = !expandGroupVehicle[groupIndex]">

                                                            <img src="assets/images/ng-components/past-trip/icon-arrow.svg"
                                                                width="30" height="8"
                                                                [class.expand-open]="!expandGroupVehicle[groupIndex]">

                                                        </button>

                                                        <ng-container *ngIf="expandGroupVehicle[groupIndex]">
                                                            <ul class="inline-input-list">
                                                                <li class="inline-input-list__item truncate-word"
                                                                    *ngFor="let item of group.idList">
                                                                    <label class="inline-input">
                                                                        <input type="checkbox" [name]="item.vehicleId"
                                                                            [value]="true" class="inline-input__input"
                                                                            [(ngModel)]="selectedVehicles[item.vehicleId]"
                                                                            (ngModelChange)="onVehiclesSelectChange(groupIndex, $event)">

                                                                        <span class="inline-input__icon"></span>

                                                                        {{ item.vehicleName }}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </ng-container>
                                                    </li>
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="field-group__item   reports__type-field-group-input"
                                [class.--active]="driversSearchDOMStates.isFocused" *ngIf="searchBy === 'drivers'"
                                click-outside (clickOutside)="onOutsideClickDriverReaction($event)">
                                <div class="field" #driversField>
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <img src="assets/images/scss-components/forms/icon-steering-wheel.svg"
                                                    width="26" height="20" alt="" class="field__icon-image">
                                            </div>
                                        </div>

                                        <label class="field__holder">
                                            <div class="field__label" [class.o-0]="subjectSelectionFloaterIsShown">
                                                {{ driversSelectedLabel }}
                                            </div>

                                            <input id="driverSearchBox" type="text" autocomplete="off"
                                                class="field__input" [class.o-0]="!subjectSelectionFloaterIsShown"
                                                dom-states #driversSearchDOMStates="DOMStates"
                                                (focus)="toggleSubjectSelectionFloater(true)"
                                                (keyup)="filterDriversSelection($event.target.value)">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>

                                    <!-- START DRIVER -->

                                    <div class="field__floater" @fadeToggle *ngIf="subjectSelectionFloaterIsShown">
                                        <ul class="inline-input-list">
                                            <li *ngIf="!driversModel.drivers.length" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    No drivers found
                                                </label>
                                            </li>
                                            <li *ngIf="isShowSelectAllOption" class="inline-input-list__item">
                                                <label class="inline-input">
                                                    <input type="checkbox" name="all" [value]="true"
                                                        class="inline-input__input"
                                                        [(ngModel)]="selectedDriverGroups['all']"
                                                        (ngModelChange)="onDriversAllSelectChange()">
                                                    <span class="inline-input__icon"></span>

                                                    All Drivers
                                                </label>
                                            </li>

                                            <ng-container
                                                *ngFor="let group of driversModel.drivers; let groupIndex = index;">
                                                <ng-container *ngIf="group.type === 'INDIVIDUAL'">
                                                    <li class="inline-input-list__item truncate-word"
                                                        *ngFor="let item of group.idList">
                                                        <label class="inline-input">
                                                            <input type="checkbox" [name]="item.driverId" [value]="true"
                                                                class="inline-input__input"
                                                                [(ngModel)]="selectedDrivers[item.driverId]"
                                                                (ngModelChange)="onDriversSelectChange()">
                                                            <span class="inline-input__icon"></span>
                                                            {{ item.driverName }}
                                                        </label>
                                                    </li>
                                                </ng-container>

                                                <ng-container *ngIf="group.type === 'GROUP'">
                                                    <li class="inline-input-list__item">
                                                        <label class="inline-input">
                                                            <input type="checkbox" [name]="group.name" [value]="true"
                                                                class="inline-input__input"
                                                                [(ngModel)]="selectedDriverGroups[groupIndex]"
                                                                (ngModelChange)="onDriversGroupSelectChange(groupIndex);">
                                                            <span class="inline-input__icon"></span>

                                                            {{ group.name }}
                                                        </label>

                                                        <button class="item-group-expand"
                                                            (click)="expandGroupDriver[groupIndex] = !expandGroupDriver[groupIndex]">

                                                            <img src="assets/images/ng-components/past-trip/icon-arrow.svg"
                                                                width="30" height="8"
                                                                [class.expand-open]="!expandGroupDriver[groupIndex]">

                                                        </button>

                                                        <ng-container *ngIf="expandGroupDriver[groupIndex]">
                                                            <ul class="inline-input-list">
                                                                <li class="inline-input-list__item truncate-word"
                                                                    *ngFor="let item of group.idList">
                                                                    <label class="inline-input">
                                                                        <input type="checkbox" [name]="item.driverId"
                                                                            [value]="true" class="inline-input__input"
                                                                            [(ngModel)]="selectedDrivers[item.driverId]"
                                                                            (ngModelChange)="onDriversSelectChange(groupIndex, $event)">
                                                                        <span class="inline-input__icon"></span>

                                                                        {{ item.driverName }}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </ng-container>
                                                    </li>
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                    </div>

                                    <!-- END DRIVER -->

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid__block   reports__filters-grid-block --period">
                        <div class="field-group --unstack@xs   reports__period-field-group">
                            <div class="field-group__item   reports__period-field-group-input"
                                [class.--active]="fromDateDOMStates.isFocused">
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <mat-datepicker-toggle [for]="FromDatepicker">
                                                    <img matDatepickerToggleIcon
                                                        src="assets/images/scss-components/forms/icon-calendar.svg"
                                                        width="24" height="20" alt="" class="field__icon-image">
                                                </mat-datepicker-toggle>
                                            </div>
                                        </div>

                                        <input [matDatepicker]="FromDatepicker" (focus)="FromDatepicker.open()"
                                            (click)="FromDatepicker.open()" type="text" [(ngModel)]="fromDate"
                                            [max]="toDate" placeholder="From Date" class="field__input" readonly
                                            dom-states #fromDateDOMStates="DOMStates">

                                        <div class="field__ui"></div>
                                    </div>

                                    <mat-datepicker #FromDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                                </div>
                            </div>

                            <div class="field-group__item   reports__period-field-group-input"
                                [class.--active]="toDateDOMStates.isFocused">
                                <div class="field">
                                    <div class="field__elems">
                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <mat-datepicker-toggle [for]="ToDatepicker">
                                                    <img matDatepickerToggleIcon
                                                        src="assets/images/scss-components/forms/icon-calendar.svg"
                                                        width="24" height="20" alt="" class="field__icon-image">
                                                </mat-datepicker-toggle>
                                            </div>
                                        </div>
                                        <input [matDatepicker]="ToDatepicker" type="text" [(ngModel)]="toDate"
                                            [min]="fromDate" [max]="moment().startOf('day').format()" readonly
                                            (focus)="ToDatepicker.open()" (click)="ToDatepicker.open()"
                                            placeholder="To Date" class="field__input" dom-states
                                            #toDateDOMStates="DOMStates">

                                        <div class="field__ui"></div>
                                    </div>

                                    <mat-datepicker #ToDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid__block   reports__filters-grid-block --cta">
                        <button type="button" class="button" (click)="generateReport()">
                            Generate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block   w-3of12@xs">
                        <h2 class="reports__heading   heading --level-2 --partial-underline">
                            Results:
                        </h2>
                    </div>

                    <div class="grid__block   w-9of12@xs   ta-r@xs" *ngIf="isReportShown">
                        <button type="button" class="button" (click)="showDownloadPopup(downloadsPopup)">
                            <img src="assets/images/scss-components/buttons/icon-download.svg" width="24" height="18"
                                alt="" class="button__icon"> Download
                        </button>
                    </div>
                </div>
            </div>

            <div class="report-box">
                <app-spinner #reportResult_spinner></app-spinner>
                <div class="block">
                    <div class="table">
                        <table class="table__elem">
                            <thead>
                                <tr>
                                    <th scope="col" width="75px">
                                        <button type="button" class="table__sortable"
                                            [ngClass]="getSortingState('VehicleName')"
                                            (click)="doSorting('VehicleName')">Vehicle</button>
                                    </th>
                                    <th scope="col" width="70px">
                                        <button type="button" class="table__sortable"
                                            [ngClass]="getSortingState('DriverName')"
                                            (click)="doSorting('DriverName')">Route Name</button>
                                    </th>
                                    <th scope="col" width="70px">
                                        Location<br>(Start)
                                    </th>
                                    <th scope="col" width="70px">
                                        Location<br>(End)
                                    </th>
                                    <th scope="col" width="5%">
                                        <button type="button" class="table__sortable"
                                            [ngClass]="getSortingState('GpsTimeStampStart')"
                                            (click)="doSorting('GpsTimeStampStart')">Date/<br>Time<br>(Start)</button>
                                    </th>
                                    <th scope="col" width="5%">
                                        <button type="button" class="table__sortable"
                                            [ngClass]="getSortingState('GpsTimeStampEnd')"
                                            (click)="doSorting('GpsTimeStampEnd')">Date/<br>Time<br>(End)</button>
                                    </th>
                                    <th scope="col" width="4%">
                                        <button type="button" class="table__sortable"
                                            [ngClass]="getSortingState('Duration')"
                                            (click)="doSorting('Duration')">Duration<br>(hh:mm)</button>
                                    </th>
                                    <th scope="col" width="4%">
                                        Detail
                                    </th>
                                    <!-- <th scope="col" style="width:50px; min-width: 50px"></th> -->
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let record of resultList">
                                    <td class="td-truncate" [title]="record.vehicleName || '-'">{{record.vehicleName ||
                                        '-'}}</td>
                                    <td class="td-truncate" [title]="record.routeName || '-'">{{record.routeName ||
                                        '-'}}</td>
                                    <td class="td-truncate" [title]="record.StartAddress || '-'"
                                        [innerHTML]="truncateString(record.StartAddress)" style="white-space:inherit;">
                                    </td>
                                    <td class="td-truncate" [title]="record.EndAddress || '-'"
                                        [innerHTML]="truncateString(record.EndAddress)" style="white-space:inherit;">
                                    </td>
                                    <td>{{record.GpsTimeStampStart}}</td>
                                    <td>{{record.GpsTimeStampEnd}}</td>
                                    <td>{{record.TotalDurationLabel}}</td>
                                    <td>
                                        <button
                                            (click)="showPopup(detailPopup);getRouteCheckpointRecords(record.routeCompletedId, record.vehicleName, record.routeName);">
                                            <img src="assets/images/scss-components/key-value-icons/icon-button-action.svg"
                                                width="22" height="16" alt="">
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h2 class="reports__record-not-found --level-1" *ngIf="!isReportShown">{{ isReportSearched ?
                        message.getMessage(message.MESSAGE.REPORT_NO_RECORD_FOUND.value) :
                        message.getMessage(message.MESSAGE.REPORT_PLEASE_GENERATE.value) }}</h2>
                </div>

                <div class="block" *ngIf="isReportShown">
                    <div class="grid --gutter">
                        <div class="grid__block   w-4of12@sm   as-c@sm">
                            <div class="notes">
                                Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                            </div>
                        </div>

                        <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                            <div class="pagination">
                                <button type="button" class="pagination__button"
                                    [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1"
                                    (click)="fetchPage(pager.currentPage - 1)">
                                    <span class="angle-arrow --left">
                                    </span>
                                    <span class="ha">Previous Page</span>
                                </button>

                                <button type="button" *ngFor="let page of pager.pages" class="pagination__button"
                                    [class.--active]="page === pager.currentPage" (click)="fetchPage(page)">{{ page
                                    }}</button>

                                <button type="button" class="pagination__button"
                                    [class.--disabled]="pager.currentPage === pager.totalPages"
                                    [disabled]="pager.currentPage === pager.totalPages"
                                    (click)="fetchPage(pager.currentPage + 1)">
                                    <span class="angle-arrow --right">
                                    </span>
                                    <span class="ha">Next Page</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #downloadsPopup="popup">
    <h1 class="popup__heading">
        Downloads
    </h1>

    <ul class="download-list">
        <li class="download-list__item" (click)="btnExcel()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-excel.svg" alt="" width="40" height="43"
                        class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Microsoft Excel (.xls)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnCsv()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-csv.svg" alt="" width="40" height="43"
                        class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    CSV Format (.csv)
                </div>
            </button>
        </li>

        <li class="download-list__item" (click)="btnPdf()">
            <button class="download-list__item-layout">
                <div class="download-list__item-visual">
                    <img src="assets/images/scss-components/download-lists/icon-pdf.svg" alt="" width="35" height="35"
                        class="download-list__item-visual-image">
                </div>

                <div class="download-list__item-main">
                    Adobe PDF (.pdf)
                </div>
            </button>
        </li>
    </ul>
</app-popup>

<!-- Detail pop up-->
<app-popup #detailPopup="popup" class="cm-popups --batch-import">
    <div class="popup__block">
        <div class="popup__block-field">
            <div style="text-align:left">
                <div class="heading --level-2 ">
                    Vehicle: {{vehicleName}}
                </div>
                <div class="heading --level-2 ">
                    Route: {{routeName}}
                </div>
            </div>
        </div>
        <div class="popup__block-field">
            <div class="block">
                <div class="table">
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <th scope="col" width="10%">
                                    Seq.
                                </th>
                                <th scope="col" width="30%">
                                    Checkpoint
                                </th>
                                <th scope="col" width="30%">
                                    Entry Time
                                </th>
                                <th scope="col" width="30%">
                                    Exit Time
                                </th>
                                <!-- <th scope="col" style="width:50px; min-width: 50px"></th> -->
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let checkpoint of checkpointList">
                                <td>{{checkpoint.id}}</td>
                                <td class="td-truncate" [title]="checkpoint.name || '-'">{{checkpoint.name || '-'}}</td>
                                <td>{{checkpoint.entryTime}}</td>
                                <td>{{checkpoint.exitTime}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h2 class="reports__record-not-found --level-1" *ngIf="!isReportShown">{{ isReportSearched ?
                    message.getMessage(message.MESSAGE.REPORT_NO_RECORD_FOUND.value) :
                    message.getMessage(message.MESSAGE.REPORT_PLEASE_GENERATE.value) }}</h2>
            </div>
        </div>
    </div>
</app-popup>

<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>