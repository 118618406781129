import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { RouterProxyService } from '../../../../_services/router-proxy/router-proxy.service';

// Component
import { VehicleSelectorComponent } from '../../vehicles/vehicle-selector/vehicle-selector.component';
import { SpinnerComponent } from '../../../common/spinner/spinner.component';

// Service
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { RouteService } from '../../../../_services/route/route.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';
import { PopupService } from '../../../common/popup/popup.service';

// Util
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as DomUtil from '../../../../util/domUtil';

// Constant
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from '../../../../constants/responseStatusCode';

@Component({
    selector: 'app-create-route',
    templateUrl: './create-route.component.html',
    styleUrls: ['./create-route.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class CreateRouteComponent implements OnInit {
    @ViewChild("page_spinner", { static: true }) pageSpinner: SpinnerComponent;
    @ViewChild("SpeedLimit", { static: false }) SpeedLimit;

    color = 'primary';
    checked = false;
    disabled = false;

    sortField = 'Name';
    sortAscending = true;

    // Schedule Form
    @ViewChild('vehicleAssign', { static: false }) vehicleAssign: VehicleSelectorComponent;

    timeSelector: {
        hour: string[],
        fullHour: string[],
        minute: string[],
        second: string[]
    } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService,
        private routeService: RouteService
    ) { }

    // Route Info
    selectedVehiclesList = [];
    routeInfo = {}
    routeName: string;

    async ngOnInit() {
        DomUtil.autoFocusForm(this.el);
        await this.checkUnviewedCampaign();
    }

    onChangeSlideBar(status, slideBarName) {
        this[slideBarName] = !status;
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async createRoute() {
        this.pageSpinner.show();
        try {
            this.selectedVehiclesList = this.vehicleAssign.getSelectedVehicleIds();

            const result = await this.routeService.createRoute(this.routeName, this.selectedVehiclesList)

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, 'Route', result.body.name);
            if (result) {
                if (result.statusCode == ResponseStatusCode.PARTIAL.code) {
                    msg = Message.getMessage(Message.MESSAGE.CREATE_PARTIAL.value, 'Route', result.body.name);
                } else if (result.statusCode == ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, 'Route', result.body.name);
                }
                this.navigateSuccessToRouteView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.pageSpinner.hide();
        }

    }

    getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    navigateSuccessToRouteView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/routes/view'], null, 'general');
    }

    navigateToRouteView() {
        this.routerProxyService.navigate(['/create-manage/routes/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

}