import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from '../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { TripsService } from '../../../../_services/trips/trips.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
// import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
// Util

// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';
@Component({
    selector: 'app-geofence-monitoring-report',
    templateUrl: './geofence-monitoring-report.component.html',
    styleUrls: ['./geofence-monitoring-report.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class GeofenceMonitoringReportComponent extends GeneralReport implements GeneralReportInterface, OnInit, OnDestroy {
    private updateSubscription: Subscription;
    expandGroupVehicle: any = [];
    expandGroupDriver: any = [];

    // Set Export File Name from environments
    exportPdfGeofenceMonitoringPageLayout = environment.appConfig.reporting.geofenceMonitoring.layout;
    exportFileNameGeofenceMonitoring = environment.appConfig.reporting.geofenceMonitoring.fileName;
    exportFileNameGeofenceMonitoringPdfTitle = environment.appConfig.reporting.geofenceMonitoring.label;
    pageLayout = this.exportPdfGeofenceMonitoringPageLayout;
    moment = moment;

    @ViewChild("page_spinner") page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner") reportResult_spinner: SpinnerComponent;

    // Message Properties
    message = Message;

    //Vehicle select list
    vehicleInfoList = [];
    currentVehicleObj = {
        vehicleId: '',
        vehicleName: null,
        vehiclePlateNo: null,
    };

    //Geofence select list
    geofenceInfoList = [];
    currentGeofenceObj = {
        geofenceId: '',
        geofenceName: null,
        // vehiclePlateNo: null,
    };
    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    // Auto refresh
    refreshPageTimer: any;
    private refreshTimerIntervalInMilliSeconds: number = environment.appConfig.map.refreshInterval * 1000;

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private tripsService: TripsService,
        private popupService_parent: PopupService,
        private popupCampaignService: PopupCampaignService,
    ) {

        //pass services to parent
        super(http_parent, popupService_parent, snackBar);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
     async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingVehicleSelector: true,
            usingSearchBy: true,
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {
        this.searchBy = "geofence";
        // Init page components & dropdown options
        await this.getGeofenceInfo();
        await this.initGeneralReportClass();
        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    onVehicleChoose(vehicleId: number) {
        const foundVehicle = this.vehicleInfoList.find(result => result.vehicleId === parseInt(vehicleId.toString()));
        if (foundVehicle) {
            this.currentVehicleObj.vehicleName = foundVehicle.vehicleName;
        } else {
            this.currentVehicleObj.vehicleName = null;
        }
    }
    onGeofenceChoose(geofenceId: number) {
        const foundGeofence = this.geofenceInfoList.find(result => result.geofenceId === parseInt(geofenceId.toString()));

        if (foundGeofence) {
            this.currentGeofenceObj.geofenceName = foundGeofence.GeoFenceName;
        } else {
            this.currentGeofenceObj.geofenceName = null;
        }

    }
    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {
            // toggle
            this.isReportSearched = true;

            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //call api to get report page
            const apiControllerResponse: any = await this.getApiControllerResponse(startRecord);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;

                //update sorting classes
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = apiControllerResponse.resultList;
                if (this.resultList.length) {

                    // parse some values to reduce function data bindings
                    this.resultList.forEach(record => {
                        record.gpsTimeStamp = moment(record.gpsTimeStamp, 'DD/MM/YYYY hh:mm:ss A').format('DD/MM/YYYY hh:mm:ss A');
                        record.hdop = Math.ceil(record.hdop);
                    });

                    //update isReportShown boolean
                    this.isReportShown = true;

                    //get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
            this.initRefreshTimer();
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }
    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }
    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        apiResponse = await this.getGeofenceMonitoringRecords(startRecord, isDownload);
        if (apiResponse != null) {
            apiResultList = apiResponse.geofenceMonitoringReport;
        }
        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }
    // For download geofence monitoring report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 15 }, { wch: 17.5 }, { wch: 17.5 }, { wch: 10 }, { wch: 15.5 }, { wch: 16 }, { wch: 20 },];

        filename = this.exportFileNameGeofenceMonitoring;
        label = this.exportFileNameGeofenceMonitoringPdfTitle;

        headerName = [
            "Vehicle Type", "Plate No", "Last Ignition On", "Last Ignition Off",
            "Last Reported Date", "Location", "Speed", "Ignition Status", "Is In Geofence"
        ];

        /**
         * Change the header type sequence, when the header sequnce is changed
         * Change the header type, when the header content data type is changed
         */
        // // excel file cell data type & format(if applicable)
        // headerType = [
        //     { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" },
        //     { type: "string" }, { type: "number" }, { type: "number" }, { type: "number" },
        //     { type: "number" }, { type: "number" }, { type: "number" }, { type: "string" }, { type: "string" }
        // ];

        for (let i = 0; i < recordList.length; i++) {
            const tempRow = [
                recordList[i].vehicleType || '',
                recordList[i].plateNo || '',
                // When output excel xlsx.js library will add an addition 46 seconds into the date time
                moment(recordList[i].gpsTimeStampStart, 'DD/MM/YYYY hh:mm:ss A').format('YYYY/MM/DD HH:mm:ss'),
                moment(recordList[i].gpsTimeStampEnd, 'DD/MM/YYYY hh:mm:ss A').format('YYYY/MM/DD HH:mm:ss'),
                recordList[i].lastReportedDate || '',
                recordList[i].location || '',
                recordList[i].speed || '',
                recordList[i].ignition || '',
                recordList[i].isInGeofence || '',
            ];
            data.push(tempRow);
        }
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    /**
     * Report Specific Methods
     */

    // 1. Call API Geofence Monitoring Report
    async getGeofenceMonitoringRecords(startRecord: number = 1, isDownload: boolean = false) {

        let result: any = null;
        const idList = this.currentSearchBy == 'vehicles' ? this.selectedVehiclesList : this.currentGeofenceObj.geofenceId;

        if (!isDownload) {
            result = await this.tripsService.getGeofenceMonitoringReport(this.currentSearchBy, idList, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
        } else {
            result = await this.tripsService.getGeofenceMonitoringReport(this.currentSearchBy, idList, null, null, this.currentSortField, this.currentSortAscending);
        }

        return result || null;
    }
    //every 45 sec refresh one time
    initRefreshTimer(): void {
        //if subsc exist then unsubs and re-subsc to prevent redundant subsc
        if (this.updateSubscription != undefined) {
            this.updateSubscription.unsubscribe();
        }
        this.updateSubscription = interval(45000).subscribe(
            (val) => { this.generateReport(); });

    }
    ngOnDestroy() {
        if (this.updateSubscription != undefined) {
            this.updateSubscription.unsubscribe();
        }

    }

}
