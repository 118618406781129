<ng-container>
    <div class="site-header__main">
        <!--a routerLink="/">
                <img src="assets/images/ng-components/site-header/logo-maxis.png" alt="Maxis" class="site-header__logo">
            </a-->
        <a (click)="navigateToHomePage()">
            <img src="assets/images/ng-components/site-header/logo-mDrive.svg" alt="Maxis" class="site-header__logo">
        </a>
    </div>

    <div class="site-header__company company__desktop" *ngIf="!isAdminSupportLogin()">
        <span class="site-header__company-name">Welcome, {{username}}</span>
    </div>

    <div class="site-header__company company__desktop" *ngIf="isAdminSupportLogin()">
        <img src="assets/images/ng-components/maxis-support-panel/company-name.svg" alt="" width="28" height="24" class="site-header__company-logo">
        <span class="site-header__company-name">{{companyName}} {{isSuperSuperAdminManagingAccount() ? 'Super Super Admin' : ''}}</span>
    </div>

    <button class="site-header__alerts-toggle" [class.--active]="isShowNotification" (click)="isShowNotification = !isShowNotification; getUserAlertNotification();" *ngIf="!isSupportManagementRole || isSuperSuperAdminManagingAccount()">
        <span class=" badge-anchor ">
            <span class="badge" *ngIf="notificationRecentAlertCount> 0">{{notificationRecentAlertCount}}</span>
            <img src="assets/images/ng-components/site-header/icon-alerts.svg" alt="Alerts" class="site-header__toggle-icon">
        </span>
    </button>

    <div class="notification" @fadeToggle *ngIf="isShowNotification" click-outside (clickOutside)="closeNotificationMenu($event)" #notificationField>
        <div class="notification__head">
            <div class="notification__head-title">
                Notification
            </div>

            <a class="link --primary notification__head-link" (click)="navigateToSettingPage()">Settings</a>
        </div>

        <div class="notification__container" (scroll)="onNotificationScroll($event)">
            <app-spinner #notification_spinner [disableScroll]="true"></app-spinner>
            <div class="notification__desc" *ngFor="let notification of notificationDisplayList;" (click)="navigateToPage(notification.notificationType, notification.urlPath)" [class.--cursor]="notification.isClickEnable">
                <div class="notification__desc-icon">
                    <!-- <img [src]="imageConstant.getImagePath(notification.notificationType, notification.notificationDescription)"> -->
                    <img [src]="notification.imagePath">
                </div>

                <div class="notification__desc-info">
                    <div class="notification__desc-info-text" [innerHTML]="notification.message">
                        <!-- {{notification.message}} -->
                        <!-- [innerHTML]="testHTML" -->
                        <!-- <span class="notification__user">{{notification.vehicleName}}</span> has <span class="notification__keyword">{{notification.notificationDescription}}</span> violation in the last trip. -->
                    </div>

                    <div class="notification__desc-info-hour">
                        {{notification.intervalTime}}
                    </div>
                </div>
            </div>
            <div class="notification__desc" *ngIf="notificationDisplayList.length === 0">
                <div class="notification__desc-no-info-text">{{defaultMessage}}</div>
            </div>
            <!-- <button class="button notification__more-button" *ngIf="!isViewMore" disabled>View More</button> -->
            <!-- <button class="button notification__more-button" *ngIf="isViewMore" (click)="loadMore()">View More</button> -->
            <span class="button notification__more-button" style="cursor:default" *ngIf="notificationDisplayList.length && !isViewMore">End Of Record</span>
        </div>
    </div>

    <button class="site-header__nav-toggle" (click)="toggleNav()" id="menu">
        <span class="site-header__nav-toggle-label">
            Menu
        </span>

        <span class="site-header__nav-toggle-icon">
            <span class="site-header__nav-toggle-icon-bar"></span>
            <span class="site-header__nav-toggle-icon-bar"></span>
            <span class="site-header__nav-toggle-icon-bar"></span>
        </span>
    </button>

    <ul class="site-header__nav" click-outside [click-outside-excludedHtmlIds]="['menu']" (clickOutside)="closeMenu($event)" id="menu-sub">
        <ng-container *ngFor="let nav of navModel">
            <li *ngIf="isVisible(nav)" class="site-header__nav-item" [class.--disabled]="nav.disable" [class.--active]="currentSubnav === nav.id" [class.--has-subnav]="nav.subnav.length">
                <div class="site-header__nav-link" (click)="sideMenuClickFunction(nav.id, nav.disable, nav.subnav, nav.navId)">
                    <div class="site-header__nav-link-icon">
                        <img src="assets/images/ng-components/site-header/icon-{{ nav.navId }}.svg" class="site-header__nav-link-icon-image">
                    </div>
                    <div class="site-header__nav-link-label">
                        {{ nav.label }}
                    </div>
                </div>

                <ul class="site-header__subnav" *ngIf="nav.subnav && nav.subnav.length && currentSubnav === nav.id" @slideToggle>
                    <li class="site-header__subnav-item" *ngFor="let subnav of nav.subnav">
                        <a (click)="subNavMenuItemClickFunction(subnav.href)" class="site-header__subnav-link">
                            {{ subnav.label }}
                        </a>
                    </li>
                </ul>
            </li>
        </ng-container>
    </ul>

    <logout #logoutPopup class="logout-popup"></logout>

</ng-container>

<!-- For survey -->
<app-popup #surveyPopup="popup" class="survey-popup" style="color: black" classes="--inline@lg" scaleOut="scale(1)" scaleIn="scale(1)" (onClickClosed)="closeSurvey(survey.surveyId)">
    <div class="popup__block   ta-c">
        <img src="assets/images/ng-components/map/icon-survey.svg" alt="" width="32" style="margin-bottom: 5px;">

        <h1 class="heading --level-2" style="text-align: center">
            <!-- Tell Us How We Can Improve -->
            {{ survey.surveyTitle }}
        </h1>
    </div>

    <div class="popup__block   ta-c">
        <p>
            <!-- Your feedback will help us serve you better in the future. This survey will take around 3 minutes to complete and your answers will be confidential. -->
            {{ survey.surveyContent }}
        </p>
    </div>

    <div class="popup__block   ta-c">
        <a class="button" (click)="startSurvey(survey.surveyUrl, survey.surveyId)">
            Start Survey
        </a>
    </div>
</app-popup>
