<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        Create Refuel Entry
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <form class="form-field" #refuelEntryForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="compulsory-notes">
                    *Compulsory fields
                </div>

                <div class="grid --gutter">
                    <div class="grid__block w-9of12@md form__flex">
                        <h2 class="heading indicator-green --level-2 --partial-underline">
                            Refuel Info
                        </h2>

                        <div class="field-group --unstack@xs --space-top">
                            <div class="field-group__item">
                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Vehicle Plate Number:
                                        <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="select" style="width: 100%">
                                                    <select class="select__input" (change)="onVehicleChoose($event.target.value);" name="vehicleId" #vehicleId="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.vehicleId" required>
                                                        <option value="" selected disabled hidden>Please Select</option>
                                                        <option *ngFor="let item of vehicleInfoList" [value]="item.vehicleId">{{item.vehiclePlateNo}}</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>

                                        <div *ngIf="vehicleId.errors && (vehicleId.dirty || vehicleId.touched)">
                                            <div class="field-error" *ngIf="vehicleId.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Vehicle Plate Number')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Vehicle Name:
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" name="vehicleName" [(ngModel)]="currentVehicleRefuelEntryObj.vehicleName" disabled>
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Refuel Date:
                                        <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <div class="field__icon">
                                                    <div class="field__icon-inner">
                                                        <mat-datepicker-toggle [for]="FuelDatepicker">
                                                            <img matDatepickerToggleIcon src="assets/images/scss-components/forms/icon-calendar.svg" width="24" height="20" alt="" class="field__icon-image">
                                                        </mat-datepicker-toggle>
                                                    </div>
                                                </div>
                                                <input [matDatepicker]="FuelDatepicker" (focus)="FuelDatepicker.open()" (click)="FuelDatepicker.open()" type="text" #fuelDate="ngModel" [(ngModel)]="fuelDateVar" name="FuelDatepicker" [max]="datePickerMaxValue" class="field__input" readonly dom-states #FuelDateDOMStates="DOMStates" required>
                                                <div class="field__ui"></div>
                                            </div>
                                            <mat-datepicker #FuelDatepicker [touchUi]="datepickerTouchUi"></mat-datepicker>
                                        </div>
                                        <div *ngIf="fuelDate.errors && (fuelDate.dirty || fuelDate.touched)">
                                            <div class="field-error" *ngIf="fuelDate.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Refuel Date')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Refuel Time:
                                        <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field-group__time-inner">
                                                <label class="select select-time">
                                                    <select class="select__input" #fuelTimeHour="ngModel" [(ngModel)]="fuelTimeHh" name="fuelTimeHour" required>
                                                        <option value="" selected disabled hidden>HH</option>
                                                        <option *ngFor="let hour of timeSelector.hour" [value]="hour">{{hour}}</option>
                                                    </select>
                                                </label>
                                            </div>

                                            <div class="field-group__time-inner --no-padding">
                                                :
                                            </div>

                                            <div class="field-group__time-inner">
                                                <label class="select select-time">
                                                    <select class="select__input" #fuelTimeMin="ngModel" [(ngModel)]="fuelTimeMm" name="fuelTimeMin" required>
                                                        <option value="" selected disabled hidden>MM</option>
                                                        <option *ngFor="let minute of timeSelector.minute" [value]="minute">{{minute}}</option>
                                                    </select>
                                                </label>
                                            </div>

                                            <div class="field-group__time-inner">
                                                <label class="select select-time">
                                                    <select class="select__input" #fuelTimeAmPm="ngModel" [(ngModel)]="fuelTimeA" name="fuelTimeAmPm" required>
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>

                                        <div *ngIf="(fuelTimeHour.errors && (fuelTimeHour.dirty || fuelTimeHour.touched)) || (fuelTimeMin.errors && (fuelTimeMin.dirty || fuelTimeMin.touched)) || (fuelTimeAmPm.errors && (fuelTimeAmPm.dirty || fuelTimeAmPm.touched))">
                                            <div class="field-error">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Refuel Time')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Location:
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" name="location" #location="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.location" placeholder="eg: KLCC" maxlength="100">
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="location.errors && (location.dirty || location.touched)">
                                            <div class="field-error" *ngIf="location.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Location', '100')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Refuel Amount (L):
                                        <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" name="fuelQuantity" #fuelQuantity="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.fuelQuantity" placeholder="eg: 25" required maxlength="10" OnlyNumber>
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="fuelQuantity.errors && (fuelQuantity.dirty || fuelQuantity.touched)">
                                            <div class="field-error" *ngIf="fuelQuantity.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Fuel Amount')}}
                                            </div>
                                            <div class="field-error" *ngIf="fuelQuantity.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Fuel Amount', '10')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Fuel Type:
                                        <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="select" style="width: 100%">
                                                    <select class="select__input" name="fuelType" #fuelType="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.fuelType" required>
                                                        <option value="" selected disabled hidden>Please Select</option>
                                                        <option *ngFor="let item of fuelTypeList;" [value]="item" [selected]="item === currentVehicleRefuelEntryObj.fuelType">{{item}}</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div *ngIf="fuelType.errors && (fuelType.dirty || fuelType.touched)">
                                            <div class="field-error" *ngIf="fuelType.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Fuel Type')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Fuel Cost (RM):
                                        <span class="indicator-green">*</span>
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" name="fuelCost" #fuelCost="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.fuelCost" placeholder="eg: 28.50" required maxlength="10" OnlyNumber>
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="fuelCost.errors && (fuelCost.dirty || fuelCost.touched)">
                                            <div class="field-error" *ngIf="fuelCost.errors?.required">
                                                {{message.getMessage(message.MESSAGE.IS_EMPTY.value, 'Fuel Cost')}}
                                            </div>
                                            <div class="field-error" *ngIf="fuelCost.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Fuel Cost', '10')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                        Odometer (km):
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="field__holder">
                                                    <input type="text" class="field__input" name="odometer" #odometer="ngModel" [(ngModel)]="currentVehicleRefuelEntryObj.odometer" placeholder="34561.7" maxlength="14" OnlyNumber>
                                                </label>
                                                <div class="field__ui"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="odometer.errors && (odometer.dirty || odometer.touched)">
                                            <div class="field-error" *ngIf="odometer.errors?.maxlength">
                                                {{message.getMessage(message.MESSAGE.MAXLENGTH.value, 'Odometer', '14')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="field-group__holder">
                                    <div class="field-group__label">
                                    </div>

                                    <div class="field-group__input">
                                        <div class="field">
                                            <div class="field__elems">
                                                <label class="inline-input">
                                                    <input type="checkbox" [value]="true" [(ngModel)]="updateOdometer" name="updateOdometer" class="inline-input__input">
                                                    <span class="inline-input__icon"></span>
                                                    <span>Update Current Odometer</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" [disabled]="refuelEntryForm.invalid" (click)="showPopup(createConfirmPopup);">
                                CREATE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToFuelView()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #createConfirmPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Create?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="createVehicleRefuelEntry();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
