import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';

@Component({
    selector: 'app-fuel-report',
    templateUrl: './fuel-report.component.html',
    styleUrls: ['./fuel-report.component.scss']
})
export class FuelReportComponent implements OnInit {

    currentTab: number = 1;
    vehicleIds: string;
    autoSortColumn: string;
    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private route: ActivatedRoute,
        private popupCampaignService: PopupCampaignService,
        private snackBar: SnackBarService
    ) { }

    ngOnInit() {
        this.currentTab = 1;
        //when redirect from advisory
        if (this.route.snapshot.queryParams.autoSearch == 'true') {
            const filterIdVehicle = this.route.snapshot.queryParams.vehicleIds || '';
            this.vehicleIds = filterIdVehicle;
            this.autoSortColumn = this.route.snapshot.queryParams.autoSortColumn;

        }
        this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async overview() {
        this.currentTab = 1;
    }

    async fuelUsage() {
        this.currentTab = 2;
        this.vehicleIds = '';
        this.autoSortColumn = '';
    }

    async refuelEntry() {
        this.currentTab = 3;
    }

    async refuelSensor() {
        this.currentTab = 4;
    }

}
