import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from '../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { TripsService } from '../../../../_services/trips/trips.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
// import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
// Util
import * as NumberUtil from './../../../../util/numberUtil';
import * as StringUtil from './../../../../util/stringUtil';
// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';
import { ERROR_MESSAGE as ErrorMessage } from './../../../../constants/errorMessage';

@Component({
  selector: 'app-idling-report',
  templateUrl: './idling-report.component.html',
  styleUrls: ['./idling-report.component.scss'],
  animations: [
      trigger('fadeToggle', [
          transition(':enter', [
              style({ opacity: 0 }),
              animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
          ]),
          transition(':leave', [
              style({ opacity: 1 }),
              animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
          ])
      ])
  ]
})
export class IdlingReportComponent extends GeneralReport implements GeneralReportInterface, OnInit {

  expandGroupVehicle: any = [];
  expandGroupDriver: any = [];

  // Set Export File Name from environments
  exportPdfIdlingPageLayout = environment.appConfig.reporting.idling.layout;
  exportFileNameIdling = environment.appConfig.reporting.idling.fileName;
  exportFileNameIdlingPdfTitle = environment.appConfig.reporting.idling.label;
  pageLayout = this.exportPdfIdlingPageLayout;
  moment = moment;

  @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
  @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

  // Message Properties
  message = Message;

  //Vehicle select list
  vehicleInfoList = [];
  currentVehicleObj = {
      vehicleId: '',
      vehicleName: null,
      vehiclePlateNo: null,
  };
  hasUnviewedCamapaign: boolean = false;
  unviewedCampaigns: Array<any> = [];

  simpleMapMarkerCoordinates = {lat: 0, lng: 0};

  constructor(
      private platform: Platform,
      private http_parent: HttpClient,
      private snackBar: SnackBarService,
      private pagerService: PagerService,
      private tripsService: TripsService,
      private popupService_parent: PopupService,
      private popupCampaignService: PopupCampaignService,
      // private vehicleService: VehicleService,
  ) {

      //pass services to parent
      super(http_parent, popupService_parent, snackBar);
  }

  /**
   * To work properly with GeneralReportClass
   * Please call this method at first line of ngOnInit()
   */
  async initGeneralReportClass() {
      this.page_spinner.show();
      this.handShakeImplementations(
          this.fetchPage.bind(this),
          this.fetchDataForDownload.bind(this),
          this.generateDownloadables.bind(this),
          this.page_spinner
      );
      await this.initialise({
          usingDateRangePicker: true,
          usingVehicleSelector: true
      });
      this.page_spinner.hide();
  }

  async ngOnInit() {

      //Default search by
      this.searchBy = "vehicles";

      //Default search range
      // this.fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
      // this.toDate = moment().format("YYYY-MM-DD");
      await this.getVehiclesInfo();
      // Init page components & dropdown options
      await this.initGeneralReportClass();

      //Page load search report
      // await this.generateReport();
      await this.checkUnviewedCampaign();

  }

  async checkUnviewedCampaign() {
      try {
          const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
          this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
          this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
      } catch (e) {
          this.snackBar.openStandardizedErrorSnackBar(e);
      }
  }

  onVehicleChoose(vehicleId: number) {
      const foundVehicle = this.vehicleInfoList.find(result => result.vehicleId === parseInt(vehicleId.toString()));
      if (foundVehicle) {
          this.currentVehicleObj.vehicleName = foundVehicle.vehicleName;
      } else {
          this.currentVehicleObj.vehicleName = null;
      }
  }
  /**
   * @Implementing Methods of GeneralReportInterface
   */

  // Call API to get data with pagination
  async fetchPage(page: number = 1) {
      if (!this.page_spinner.isShowing()) {
          this.reportResult_spinner.show();
      }
      try {
          // toggle
          this.isReportSearched = true;

          const startRecord = ((page - 1) * this.pageRecordSize) + 1;

          //call api to get report page
          const apiControllerResponse: any = await this.getApiControllerResponse(startRecord);
          if (apiControllerResponse.response != null) {
              this.apiResponse = apiControllerResponse.response;

              //update sorting classes
              this.updateSortingState(this.apiResponse.sort);

              this.resultList = apiControllerResponse.resultList;
              if (this.resultList.length) {

                  // parse some values to reduce function data bindings
                  this.resultList.forEach(record => {
                      record.gpsTimeStamp = moment(record.gpsTimeStamp, 'DD/MM/YYYY hh:mm:ss A').format('DD/MM/YYYY hh:mm:ss A');
                      record.startTime = moment(record.startTime, "HH:mm:ss.ZZZ").format('HH:mm:ss');
                      record.endTime = moment(record.endTime, 'HH:mm:ss.ZZZ').format('HH:mm:ss');
                      record.hdop = Math.ceil(record.hdop);
                  });

                  //update isReportShown boolean
                  this.isReportShown = true;

                  //get Pager data from service
                  this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
              }
          }
      } catch (err) {
          this.snackBar.openStandardizedErrorSnackBar(err);
      } finally {
          this.reportResult_spinner.hide();
          this.page_spinner.hide();
      }
  }
  // Call API to get all data for download
  async fetchDataForDownload() {

      //call download api
      const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

      return apiControllerResponse.resultList;
  }
  // All API calls go through here, logics put inside here
  async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {

      let apiResponse: any = null;
      let apiResultList: Array<any> = [];

      apiResponse = await this.getIdlingRecords(startRecord, isDownload);
      if (apiResponse != null) {
          apiResultList = apiResponse.idlingReport;
      }

      return {
          response: apiResponse,
          resultList: apiResultList
      };
  }
  // For download idling report
  generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
      if (!recordList.length) {
          return null;
      }

      let headerName: any = [];
      const headerType: any = [];
      let filename: string = "";
      let label: string = "";
      const data: any = [];
      const excelWidthConfig = [{ wch: 15 }, { wch: 15 }, { wch: 17.5 }, { wch: 17.5 }, { wch: 12.5 }];

      filename = this.exportFileNameIdling;
      label = this.exportFileNameIdlingPdfTitle;

      headerName = [
          "Date/Time", "Start Time", "End Time", "Duration (minutes.seconds)",
        "Lat,Lon(Altitude)"
      ];

      /**
       * Change the header type sequence, when the header sequnce is changed
       * Change the header type, when the header content data type is changed
       */
      // // excel file cell data type & format(if applicable)
      // headerType = [
      //     { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" }, { type: "string" },
      //     { type: "string" }, { type: "number" }, { type: "number" }, { type: "number" },
      //     { type: "number" }, { type: "number" }, { type: "number" }, { type: "string" }, { type: "string" }
      // ];

      for (let i = 0; i < recordList.length; i++) {
          const tempRow = [
              // When output excel xlsx.js library will add an addition 46 seconds into the date time
              recordList[i].gpsTimeStampDate,
              // When output excel xlsx.js library will add an addition 46 seconds into the date time
              moment(recordList[i].startTime, 'hh:mm:ss A').format('HH:mm:ss'),
              moment(recordList[i].endTime, 'hh:mm:ss A').format('HH:mm:ss'),
              recordList[i].idlingDuration || 0,
              recordList[i].latlng || ''
          ];
          data.push(tempRow);
      }
      return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
  }

  /**
   * Report Specific Methods
   */

  // 1. Call API Idling Report
  async getIdlingRecords(startRecord: number = 1, isDownload: boolean = false) {

      let result: any = null;

      if (!isDownload) {
          result = await this.tripsService.getIdlingReport(this.currentVehicleObj.vehicleId, this.currentFromDate, this.currentToDate, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
      } else {
          result = await this.tripsService.getIdlingReport(this.currentVehicleObj.vehicleId, this.currentFromDate, this.currentToDate, null, null, this.currentSortField, this.currentSortAscending);
      }

      return result || null;
  }

    showSimpleMapPopup(popup: string, latlng) {
        //if blank then dont open popup
        if(latlng !== '-') {
            let coordsArr = latlng.split(/,\s*/);
            this.simpleMapMarkerCoordinates.lat = coordsArr[0];
            this.simpleMapMarkerCoordinates.lng = coordsArr[1];
            this.popupService_parent.show(popup);
        }
    }


}
