import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Platform } from '@ionic/angular';
import * as DomUtil from './../../util/domUtil';
import * as ExcelUtil from './../../util/excelUtil';
import * as ObjectUtil from './../../util/objectUtil';
import * as StringUtil from './../../util/stringUtil';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

//Excel
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';

//PDF
import {jsPDF} from 'jspdf';
import autoTable, {UserOptions} from "jspdf-autotable";

//CSV
import { Browser } from '@capacitor/browser';

@Injectable({
    providedIn: 'root'
})
export class FileExportService {

    constructor(
        private platform: Platform
    ) { }

    exportCsvFile(json: any[], headerName: string[], fileName: string): void {
        // Generate CSV File
        const currentDateTime = moment(new Date().getTime()).format("YYYYMMDDHHmmss");
        const csvFileName = `${fileName}_${currentDateTime}${CSV_EXTENSION}`;
        const delimiter = '|';

        const exportCsvStr = [headerName]
            .concat(json)
            .map(x => x.join(delimiter))
            .join('\n');

        if (DomUtil.isMobileApp(this.platform)) {
            this.platform.ready().then(() => {
                try {
                    Filesystem.writeFile({
                        path: `${fileName}.csv`,
                        data: exportCsvStr,
                        directory: Directory.Documents,
                        encoding: Encoding.UTF8
                    }).then(writeRes => {
                        Filesystem.getUri({
                            directory: Directory.Documents,
                            path: fileName
                        }).then((getUriResult) => {
                            const path = getUriResult.uri;
                            Browser.open({url: path});
                        }, (error) => {
                            console.log(error);
                        });
                    }).catch(err => {
                        console.error("Export CSV Error: ", JSON.stringify(err));
                    });
                } catch (e) {
                    console.error('Unable to write file:', e);
                }
            });
        } else {
            FileSaver.saveAs(
                new Blob([exportCsvStr], {type: 'application/csv'}),
                csvFileName);
        }
    }

    exportPdfFile(json: any[], headerName, fileName, title, dateRange: string = null, layout: string): void {
        const orientation = <'landscape' | 'portrait' | 'p' | 'l'> layout;
        const currentDateTime = moment(new Date().getTime()).format("YYYYMMDDHHmmss");
        const pdfFileName = fileName + "_" + currentDateTime;
        const columns = headerName;
        const rows = json;

        // For Trip Summary Report
        const columnStyles = {
            columnStyles: {
                0: { columnWidth: 50 },
                1: { columnWidth: 50 },
                2: { columnWidth: 65 },
                3: { columnWidth: 65 },
                4: { columnWidth: 90 },
                5: { columnWidth: 90 },
                6: { columnWidth: 35 },
                7: { columnWidth: 35 },
                8: { columnWidth: 40 },
                9: { columnWidth: 40 },
                10: { columnWidth: 65 },
                11: { columnWidth: 45 },
                12: { columnWidth: 40 },
                13: { columnWidth: 50 }
            }
        };

        let pdfOption: UserOptions = {
            columns,
            body: rows,
            startY: 60,
            tableWidth: 'auto',
            //   margin: {
            //     top: 5,
            //     bottom: 5
            // },
            // pageBreak: 'avoid',
            columnStyles: {
                0: {
                    halign: 'left'
                }
            },
            headStyles: {
                fillColor: '#128958', // maxis green
                textColor: '#FFFFFF',
                halign: 'center',
                valign: 'middle'
            },
            bodyStyles: {
                halign: 'center',
                valign: 'middle'
            },
            theme: 'striped',
            styles: {
                overflow: 'linebreak',
                fontSize: 7,
                cellPadding: 5,
                cellWidth: 'auto'
            },
            didDrawPage: data => {
                const footerStr = "Page " + doc.getNumberOfPages();
                // if (typeof doc.putTotalPages === 'function') {
                //   footerStr = footerStr + " of " + totalPagesExp;
                // }
                const pageNumberWidth = (pageWidth - 20) / 2;
                const pageNumberHeight = pageHeight - 15;
                doc.setFontSize(9);
                doc.text(footerStr, pageNumberWidth, pageNumberHeight);
            }
            // Add more Pages to test page number
            // ,drawRow: function (row) {
            //   if (row.index > 0) {
            //     doc.autoTableAddPage();
            //   }
            // }
        };

        // Resize Column Width only for Trip Summary Report
        if (fileName === environment.appConfig.reporting.tripSummary.fileName) {
            pdfOption = ObjectUtil.mergeDeep(pdfOption, columnStyles);
        }

        // Only pt supported (not mm or in)
        // '1'--landscape, 'p'--portrait
        const doc = new jsPDF(orientation, 'pt');
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        // var doc = new jsPDF("portrait", "px", "a4");
        doc.setFont("helvetica");
        // doc.setFontType("bold");
        // doc.setTextColtitle
        doc.setTextColor('#128958'); // maxis green

        // Set Title Font Size
        doc.setFontSize(15);
        doc.text(title, 45, 51); //(x-axis, y-axis, data)

        // Set Date Range Font Size
        doc.setFontSize(9);
        if (dateRange) { doc.text(`Date Range : ${dateRange}`, pageWidth - 200, 53); }

        // Table Option
        autoTable(doc, pdfOption);

        if (DomUtil.isMobileApp(this.platform)) {
            this.platform.ready().then(() => {
                const filename = `${pdfFileName}.pdf`;
                const pdfOutput = doc.output();
                // using ArrayBuffer will allow you to put image inside PDF
                const buffer = new ArrayBuffer(pdfOutput.length);
                const array = new Uint8Array(buffer);
                for (let i = 0; i < pdfOutput.length; i++) {
                    array[i] = pdfOutput.charCodeAt(i);
                }
                const fullPath = filename;
                //Writing File to Device for iOS
                Filesystem.writeFile({
                    path: filename,
                    data: pdfOutput,
                    directory: Directory.Documents
                }).then(writeRes => {
                    const path = writeRes.uri;
                    Browser.open({url: path});
                }).catch(err => {
                    console.error("Export PDF Error: ", JSON.stringify(err));
                });
            });
        } else {
            doc.save(`${pdfFileName}.pdf`);
        }

        // //Export PDF File
        // doc.save(`${pdfFileName}.pdf`);
    }

    async exportExcelFile(json: any[], fileName, headerType: any[] = [], excelWidthConfig: any = null) {
        const currentDateTime = moment(new Date().getTime()).format("YYYYMMDDHHmmss");
        const excelFileName = fileName + "_" + currentDateTime;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        if (excelWidthConfig && excelWidthConfig.length > 0) {
            // set column width size
            worksheet['!cols'] = excelWidthConfig;
        }
        // const checkResult = await this.checkExcelColumnsFormat(worksheet, headerType);
        // if (checkResult.status == 'SUCCESS') {
        //     worksheet = checkResult.worksheet;
        // }
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        this.generateExcelFile(fileName, excelFileName, excelBuffer);
    }

    /**
     * Code replicates from exportExcelFile(...), but to support multiple sheets scenario.
     * @param fileName File name
     * @param excelData Excel sheet data
     */
    async exportExcelFile2(fileName: string,
                           excelData: {[sheet: string]: {data: any[], widthConfig?: any[]}}) {
        const excelFileName = fileName + "_" + moment(new Date().getTime()).format("YYYYMMDDHHmmss");

        const workbook: XLSX.WorkBook = XLSX.utils.book_new();

        for (const [sheetName, sheetDetls] of Object.entries(excelData)) {
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetDetls.data);
            if (sheetDetls.widthConfig && sheetDetls.widthConfig.length > 0) {
                worksheet['!cols'] = sheetDetls.widthConfig; // set column width size
            }
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        }

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        this.generateExcelFile(fileName, excelFileName, excelBuffer);
    }

    /**
     * To Verify each Column using correct format for excel file
     * @param worksheet excel sheet that gen by XLSX.utils.json_to_sheet();
     * @param headerType each columns data type
     */
    async checkExcelColumnsFormat(worksheet: any, headerType: any) {
        const checkResult: any = {
            status: "FAILED",
            worksheet: worksheet
        };
        let currentColumnAlp: string = 'A';
        // Eg: !ref: "A1:N22"
        const dataContentRange: any = worksheet['!ref'].split(":");
        // const firstCol = dataContentRange[0];
        const lastCol: string = dataContentRange[1];
        const colLabelAndRowNum: any = await this.splitStringAndNumber(lastCol);
        let lastColumnRow: string = '0';
        if (colLabelAndRowNum.length) {
            lastColumnRow = colLabelAndRowNum[1];
        }

        for (let i = 0; i < headerType.length; i++) {
            if (StringUtil.equalsIgnoreCase(headerType[i].type, 'datetime') || StringUtil.equalsIgnoreCase(headerType[i].type, 'duration')) {
                for (const eachCell in worksheet) {
                    if (eachCell.includes(currentColumnAlp)) {
                        // Still in same column
                        if (worksheet[eachCell].z && eachCell.includes(currentColumnAlp)) {
                            delete worksheet[eachCell].w;
                            worksheet[eachCell].z = headerType[i].format;
                            worksheet[eachCell].w = XLSX.utils.format_cell(worksheet[eachCell]);
                        }
                    } else if (eachCell.includes(ExcelUtil.getNextExcelColumnLabelAlphabets(currentColumnAlp)) && eachCell.includes(lastColumnRow)) {
                        break;
                    } else {
                        // Found & skip some unrelated rows & columns Eg: '!cols', '!margin'
                        continue;
                    }
                }
            }
            // Reach last row of current column
            currentColumnAlp = ExcelUtil.getNextExcelColumnLabelAlphabets(currentColumnAlp); // process to next column
        }

        return checkResult;
    }

    splitStringAndNumber(inputText) {
        const output = inputText.replace(/\'/g, '').split(/(\d+)/).filter(Boolean);
        return output;
    }

    /**
     * Convert Array<Array> to Array<Object>, using provided headers as keys
     * Use: Convert CSV/PDF data array into Excel-ready object array
     * @author UF-Loo Zhu Hang (Jasper)
     * @param data Array of objects for report data
     * @param headers Array of strings for column headers
     * @return Array of objects ready for Excel export
     */
    mergeHeaderAndDataForExcel(data: Array<any>, headers: Array<string>): Array<any> {
        // if (data.length && data[0].length && headers.length) {
        //     if (data[0].length != headers.length) {
        //         throw new Error("Header length and Column length does not match!");
        //     }
        // }

        const newData = [];
        for (let i = 0; i < data.length; i++) {
            const tempRow = {};
            for (let j = 0; j < headers.length; j++) {
                tempRow[headers[j]] = data[i][j];
            }
            newData.push(tempRow);
        }
        return newData;
    }

    private generateExcelFile(fileName: string, excelFileName: string, excelBuffer: any) {
        //Generate Excel File
        if (DomUtil.isMobileApp(this.platform)) {
            this.platform.ready().then(() => {
                fileName = excelFileName + EXCEL_EXTENSION;

                if (DomUtil.isMobileApp(this.platform, 'ios') || DomUtil.isMobileApp(this.platform, 'android')) {
                    //Writing File to Device for iOS
                    Filesystem.writeFile({
                        path: fileName,
                        data: excelBuffer,
                        directory: Directory.Documents
                    }).then(writeRes => {
                        Filesystem.getUri({
                            directory: Directory.Documents,
                            path: fileName
                        }).then((getUriResult) => {
                            const path = getUriResult.uri;
                            Browser.open({url: path});
                        }, (error) => {
                            console.log(error);
                        });
                    }).catch(err => {
                        console.error("Export PDF Error: ", JSON.stringify(err));
                    });
                }

            });
        } else {
            const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
            FileSaver.saveAs(data, excelFileName + EXCEL_EXTENSION);
        }
    }

    // private saveAsExcelFile(buffer: any, fileName: string): void {
    //   //  const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    //    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    // }
}

//Pdf Style

/* {
      styles: {fillColor: [100, 255, 255]},
      columnStyles: {
      	id: {fillColor: 255}
      },{fillColor: [100, 255, 255]},
      margin: {top: 60},
      addPageContent: function(data) {
      	doc.text("Header", 40, 30);
      }
  } */

/* pdfStyle = {
  cellPadding: 5, // a number, array or object (see margin below)
  fontSize: 10,
  font: "helvetica", // helvetica, times, courier
  lineColor: 200,
  lineWidth: 0,
  fontStyle: 'normal', // normal, bold, italic, bolditalic
  overflow: 'ellipsize', // visible, hidden, ellipsize or linebreak
  fillColor: false, // false for transparent or a color as described below
  textColor: 20,
  halign: 'left', // left, center, right
  valign: 'middle', // top, middle, bottom
  columnWidth: 'auto' // 'auto', 'wrap' or a number
} */

/*
pdfOptions=
  {
    // Styling
    theme: 'striped', // 'striped', 'grid' or 'plain'
    styles: this.pdfStyle,
    headerStyles: {},
    bodyStyles: {},
    alternateRowStyles: {},
    columnStyles: {},

    // Properties
    startY: false, // false (indicates margin top value) or a number
    margin: 40, // a number, array or object
    pageBreak: 'auto', // 'auto', 'avoid' or 'always'
    tableWidth: 'auto', // 'auto', 'wrap' or a number,
    showHeader: 'everyPage', // 'everyPage', 'firstPage', 'never',
    tableLineColor: 200, // number, array (see color section below)
    tableLineWidth: 0,

    // Hooks
    createdHeaderCell: function (cell, data) {},
    createdCell: function (cell, data) {},
    drawHeaderRow: function (row, data) {},
    drawRow: function (row, data) {},
    drawHeaderCell: function (cell, data) {},
    drawCell: function (cell, data) {},
    addPageContent: function (data) {}
 };
 */

