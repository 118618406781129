<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="block --space-top">
                <h1 class="heading --level-1">
                    Change Password
                </h1>
            </div>
        </div>
    </div>

    <form class="form-field" #passwordForm="ngForm">
        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <h2 class="heading indicator-green --level-2 --partial-underline">
                        Account Security
                    </h2>
                </div>

                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-9of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Old Password:
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="password" class="field__input" #oldPassword="ngModel" [(ngModel)]="formModel.oldPassword" name="oldPassword" autocomplete="new-password" required tabindex="1">
                                                    </label>
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>

                                            <div class="field-error" *ngIf="!!oldPassword.errors?.required && (!!oldPassword.dirty || !!oldPassword.touched)">
                                                Old Password is required.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            New Password: <span class="indicator-green">*</span>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field" style="z-index: 5;">
                                                <div class="field__elems">
                                                    <label class="field__holder">
                                                        <input type="password" class="field__input" #password="ngModel" [(ngModel)]="formModel.password" name="password" autocomplete="new-password" required tabindex="2">
                                                    </label>

                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>

                                            <div class="field-error" *ngIf="!!password.errors?.required && (!!password.dirty || !!password.touched)">
                                                New Password is required.
                                            </div>

                                            <div class="password-indicator">
                                                <div class="password-strength">
                                                    Password strength: <b>{{ formModel.passwordStrength }}</b>
                                                </div>

                                                <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)" [password]="password.value">
                                                </mat-password-strength>
                                            </div>
                                        </div>

                                        <div class="field__icon">
                                            <div class="field__icon-inner">
                                                <div class="toggle__item-groups" [class.--active]="toggle.active">
                                                    <button class="toggle__item-groups-toggle" toggle #toggle="toggle">
                                                        <img src="assets/images/scss-components/forms/icon-question.svg" width="26" height="26" alt="" class="field__icon-image">
                                                    </button>

                                                    <div class="toggle__item-groups-floater">
                                                        <button class="toggle__item-groups-close" (click)="toggle.setInactive()"></button>

                                                        <div class="toggle__item-groups-contents">
                                                            Password must be a minimum of 8 characters and contain the following:<br><br>

                                                            <ul>
                                                                <li> At least 1 upper case letter (A, B, C...Z)
                                                                </li>
                                                                <li>At least 1 lower case letter (a, b, c...z)
                                                                </li>
                                                                <li>At least 1 number (1, 2, 3...9)</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            Confirm Password: <span class="indicator-green">*</span>
                                        </div>

                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <input type="password" class="field__input" #confirmPassword="ngModel" [(ngModel)]="formModel.confirmPassword" name="confirmPassword" autocomplete="new-password" required [class.--error]="!confirmPassword.errors?.required && formModel.password !== formModel.confirmPassword && (!!confirmPassword.dirty || !!confirmPassword.touched)" tabindex="3">
                                                    <div class="field__ui"></div>
                                                </div>
                                            </div>
                                            <div class="field-error" *ngIf="!!confirmPassword.errors?.required && (!!confirmPassword.dirty || !!confirmPassword.touched)">
                                                Confirm Password is required.
                                            </div>
                                            <div class="field-error" *ngIf="!confirmPassword.errors?.required && formModel.password !== formModel.confirmPassword && (!!confirmPassword.dirty || !!confirmPassword.touched)">
                                                Passwords do not match.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="grid --gutter">
                    <div class="grid__block">
                        <div class="inline-button__field">
                            <button class="button inline-button__inputs" type="button" (click)="changePassword()" *ngIf="!oldPassword.errors?.required &&
									!password.errors?.required &&
									formModel.password === formModel.confirmPassword">
                                SAVE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" *ngIf="!!oldPassword.errors?.required || !!password.errors?.required ||
									formModel.password !== formModel.confirmPassword">
                                SAVE
                            </button>

                            <button class="button inline-button__inputs --grey" type="button" (click)="navigateToHomePage()">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-popup #passwordPolicyPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Change Password
            </div>
        </div>
    </div>

    <div class="popup__block">

        <p>
            Password must be a minimum of 8 characters and contain the following:<br><br> * At least 1 upper case letter (A, B, C...Z)<br> * At least 1 lower case letter (a, b, c...z)<br> * At least 1 number (1, 2, 3...9)
        </p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                Ok
            </button>
        </div>
    </div>
</app-popup>
<app-popup #passwordChangePopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Change Password
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            {{message}}
        </p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();reload()">
                Ok
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
