import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';

// Service
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { FuelService } from './../../../../_services/fuel/fuel.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as NumberUtil from './../../../../util/numberUtil';

// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import * as Message from '../../../../constants/message';

@Component({
    selector: 'app-refuel-logs-sensor',
    templateUrl: './refuel-logs-sensor.component.html',
    styleUrls: ['./refuel-logs-sensor.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s ease-out', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class RefuelLogsSensorComponent extends GeneralReport implements GeneralReportInterface, OnInit {

    expandGroupVehicle: any = [];

    // Set Export File Name from environments
    exportPdfRefuelLogsPageLayout = environment.appConfig.reporting.refuelLogs.layout;
    exportFileNameRefuelLogs = environment.appConfig.reporting.refuelLogs.fileName;
    exportFileNameRefuelLogsPdfTitle = environment.appConfig.reporting.refuelLogs.label;
    pageLayout = this.exportPdfRefuelLogsPageLayout;
    moment = moment;

    @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private fuelService: FuelService,
        private el: ElementRef,
        private popupService_parent: PopupService,
        private popupCampaignService: PopupCampaignService
    ) {

        super(http_parent, popupService_parent, snackBar);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingDateRangePicker: true,
            usingVehicleSelector: true
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {

        //Default search by
        this.searchBy = "vehicles";

        //Default search range
        // this.fromDate = moment().subtract(1, 'weeks').format("YYYY-MM-DD");
        // this.toDate = moment().format("YYYY-MM-DD");

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
        this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
        this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {

            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Call api to get report page
            const apiControllerResponse = await this.getApiControllerResponse(startRecord);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = apiControllerResponse.resultList;
                if (this.resultList.length) {

                    // parse some values to reduce function data bindings
                    this.resultList.forEach(record => {
                        record.refuelDateLabel = record.refuelTimestamp ?
                            moment(record.refuelTimestamp).format('DD/MM/YYYY hh:mmA') : null;
                        record.refuelAmountLabel = NumberUtil.formatFloat(record.refuelAmount, 1, '0.0');
                        record.previousFuelLevel = NumberUtil.formatFloat(record.previousFuelLevel, 1, '0.0');
                        record.currentFuelLevel = NumberUtil.formatFloat(record.currentFuelLevel, 1, '0.0');
                    });

                    this.isReportShown = true;

                    // Get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }

    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }

    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {
        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        apiResponse = await this.getRefuelLogsBySensorRecords(startRecord, isDownload);
        if (apiResponse != null) {
            apiResultList = apiResponse.result;
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }

    // For download report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 15 }, { wch: 16 }, { wch: 20}, { wch: 17.5 }, { wch: 17.5 }, { wch: 17.5 }];

        filename = this.exportFileNameRefuelLogs + "BY_SENSOR_VEHICLE";
        label = this.exportFileNameRefuelLogsPdfTitle + " By Sensor (Vehicle)";
        headerName = [
            "Vehicle Name", "Plate Number", "Date/Time", "Location", "Before Refuel Level(L)", "After Refuel Level(L)", "Refuel Amount(L)"
        ];
        for (let i = 0; i < recordList.length; i++) {
            const tempRow = [
                recordList[i].vehicleName || '',
                recordList[i].plateNo || '',
                recordList[i].refuelTimestamp ? moment(recordList[i].refuelTimestamp).format('YYYY/MM/DD HH:mm') : '',
                recordList[i].location || '',
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].previousFuelLevel, 1, '0.0')),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].currentFuelLevel, 1, '0.0')),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].refuelAmount, 1, '0.0')),
            ];
            data.push(tempRow);
        }
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    async getRefuelLogsBySensorRecords(startRecord, isDownload: boolean = false) {
        let result: any = null;
        //Get Data
        if (!isDownload) {
            result = await this.fuelService.getRefuelLogsBySensorReport(this.selectedVehiclesList, this.currentFromDate, this.currentToDate, this.pageRecordSize, startRecord, this.currentSortField, this.currentSortAscending);
        } else {
            result = await this.fuelService.getRefuelLogsBySensorReport(this.selectedVehiclesList, this.currentFromDate, this.currentToDate, null, null, this.currentSortField, this.currentSortAscending);
        }
        return result || null;
    }

}
