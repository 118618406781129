import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { Chart } from 'chart.js';
import { Platform } from '@ionic/angular';

// Component
import { SpinnerComponent } from './../../../../components/common/spinner/spinner.component';
import { DatePickerCustomFormatter, FORMATS } from './../../../../shared/adapter/datepicker-custom-formatter';

// Service
import { PagerService } from './../../../../_services/pager/pager.service';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { FuelService } from './../../../../_services/fuel/fuel.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';

// Util
import * as NumberUtil from './../../../../util/numberUtil';
import * as DomUtil from './../../../../util/domUtil';
import * as StringUtil from './../../../../util/stringUtil';

// Class
import { GeneralReport, GeneralReportInterface } from './../GeneralReportClass';
import { DownloadableArrayItem } from './../DownloadableArrayItemClass';

// Constant
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import * as Message from '../../../../constants/message';
import { DashboardColors as Colors } from './../../dashboard/DashboardColors';
import { ContentObserver } from '@angular/cdk/observers';

const CHART_TICK_LABEL_MAX_LENGTH: number = 5; // chartJS truncate: axis tick label
const CHART_TOOLTIP_MAX_LENGTH: number = 22; // chartJS truncate: tooltip title

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s ease-out', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class OverviewComponent extends GeneralReport implements GeneralReportInterface, OnInit {
    expandGroupVehicle: any = [];

    // Set Export File Name from environments
    exportPdfFuelUsagePageLayout = environment.appConfig.reporting.fuelUsage.layout;
    exportFileNameFuelUsage = environment.appConfig.reporting.fuelUsage.fileName;
    exportFileNameFuelUsagePdfTitle = environment.appConfig.reporting.fuelUsage.label;
    pageLayout = this.exportPdfFuelUsagePageLayout;
    moment = moment;

    @ViewChild("page_spinner",{static:true}) page_spinner: SpinnerComponent;
    @ViewChild("reportResult_spinner",{static:false}) reportResult_spinner: SpinnerComponent;
    @ViewChild("fuelUsageGraph_spinner",{static:false}) fuelUsageGraph_spinner: SpinnerComponent;
    @ViewChild("fuelEfficiencyGraph_spinner",{static:false}) fuelEfficiencyGraph_spinner: SpinnerComponent;

    @Input()
    vehicleIds: string;

    @Input()
    autoSortColumn: string;

    // Message Properties
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    chartJsBarHoverInteraction: any;
    chartJsTooltipStyles: any;
    chartJsHoverProps: any;
    chartJsTicksFontStyles: any;
    chartJsTicksStringEllipsisCallbackFn: any;
    chartJsTicksNumberTruncateCallbackFn: any;
    chartJsTooltipTruncateCallbackTitleFn: any;
    chartJsAnimation: any;
    //overrideChartJsOptions
    chartJsScaleLabelFontStyles: any;
    fuelUsageGraph;
    fuelEfficiencyGraph;

    constructor(
        private platform: Platform,
        private http_parent: HttpClient,
        private snackBar: SnackBarService,
        private pagerService: PagerService,
        private fuelService: FuelService,
        private el: ElementRef,
        private popupService_parent: PopupService,
        private popupCampaignService: PopupCampaignService
    ) {

        super(http_parent, popupService_parent, snackBar);
    }

    /**
     * To work properly with GeneralReportClass
     * Please call this method at first line of ngOnInit()
     */
    async initGeneralReportClass() {
        this.page_spinner.show();
        this.handShakeImplementations(
            this.fetchPage.bind(this),
            this.fetchDataForDownload.bind(this),
            this.generateDownloadables.bind(this),
            this.page_spinner
        );
        await this.initialise({
            usingDateRangePicker: true,
            usingVehicleSelector: true
        });
        this.page_spinner.hide();
    }

    async ngOnInit() {

        //Default search by
        this.searchBy = "vehicles";

        //Default search range
        // this.monthYear = moment().format("YYYY-MM-DD");

        // Init page components & dropdown options
        await this.initGeneralReportClass();

        //when redirect from advisory
        if (this.vehicleIds && this.vehicleIds != '') {
            //clear default selected all vehicles
            this.selectedVehicles = {};
            const idArray = this.vehicleIds.split(',');
            idArray.forEach(vehicleId => {
                this.selectedVehicles[vehicleId] = true;
            });
            // const current = moment();
            // const pastMonth = current.subtract(1, 'months');
            // const pastMonthStart = pastMonth.startOf('month').format('YYYY-MM-DD');
            // const pastMonthEnd = pastMonth.endOf('month').format('YYYY-MM-DD');
            // this.monthYear = pastMonth.format("YYYY-MM-DD");
            const current = moment();
            const pastWeek = current.subtract(1, 'weeks');
            const pastWeekStart = pastWeek.startOf('isoWeek').format('YYYY-MM-DD');
            const pastWeekEnd = pastWeek.endOf('isoWeek').format('YYYY-MM-DD');
            this.fromDate = pastWeekStart;
            this.toDate = pastWeekEnd;
            //update ui changes
            this.onRedirectUpdateVehiclesSelectChange();
            this.currentSortField = this.autoSortColumn;
            this.currentSortAscending = false;
            await this.generateReport();
        }

        //Page load search report
        // await this.generateReport();
        await this.checkUnviewedCampaign();
        this.initChartJsDefaults();

    }

    async checkUnviewedCampaign() {
        const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
        this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
        this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
    }

    /**
     * @Implementing Methods of GeneralReportInterface
     */

    // Call API to get data with pagination
    async fetchPage(page: number = 1) {
        if (!this.page_spinner.isShowing()) {
            this.reportResult_spinner.show();
        }
        try {

            const startRecord = ((page - 1) * this.pageRecordSize) + 1;

            //Call api to get report page
            const apiControllerResponse = await this.getApiControllerResponse(startRecord);
            if (apiControllerResponse.response != null) {
                this.apiResponse = apiControllerResponse.response;
                this.updateSortingState(this.apiResponse.sort);

                this.resultList = apiControllerResponse.resultList;
                if (this.resultList.length) {

                    // parse some values to reduce function data bindings
                    // this.resultList.forEach(record => {
                    //     record.refuelAmountLimitColor = this.fontColorByTwoNumber(record.fuelUsed, record.fuelUsedLimit);
                    //     record.fuelCostLimitColor = this.fontColorByTwoNumber(record.fuelCost, record.fuelCostLimit);
                    //     //pass in param with reverse order, because fuel efficiency above limit need to be green and below be red
                    //     record.fuelEfficiencyColor = this.fontColorByTwoNumber(record.fuelEfficiencyLimit, record.fuelEfficiency);
                    //     record.numOfRefills = NumberUtil.formatFloat(record.numOfRefills, 0, '0');
                    //     record.fuelUsed = NumberUtil.formatFloat(record.fuelUsed, 0, '0');
                    //     record.fuelCost = NumberUtil.formatFloat(record.fuelCost, 2, '0.00');
                    //     record.fuelEfficiency = NumberUtil.formatFloat(record.fuelEfficiency, 1, '0.0');
                    // });

                    this.isReportShown = true;

                    // Get Pager data from service
                    this.pager = this.pagerService.getPager(this.apiResponse.totalRecord, page, this.pageRecordSize);
                }
            }
            // const datasets = [];
            // this.resultList.map((tmp, index) => {
            //     const dataObj = {
            //         data: tmp.fuelEfficiency,
            //         label: 'Fuel litre',
            //         backgroundColor: linearGradient,
            //         borderColor: Colors.RGBA_PRI1_SHARP_GREEN,
            //         data: [0, 10],
            //         lineTension: 0,
            //         borderWidth: 1.2,
            //         pointBorderWidth: 1.2
            //     };
            //     datasets.push(dataObj);
            // });
            await this.getFuelUsageGraph(this.resultList.fuelUsageResult);
            await this.getFuelEfficiencyGraph(this.resultList.effResult);
        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.reportResult_spinner.hide();
            this.page_spinner.hide();
        }
    }

    // Call API to get all data for download
    async fetchDataForDownload() {

        //call download api
        const apiControllerResponse: any = await this.getApiControllerResponse(null, true);

        return apiControllerResponse.resultList;
    }

    // All API calls go through here, logics put inside here
    async getApiControllerResponse(startRecord: number = 0, isDownload: boolean = false) {
        let apiResponse: any = null;
        let apiResultList: Array<any> = [];

        apiResponse = await this.getFuelUsageRecord(startRecord, isDownload);
        if (apiResponse != null) {
            apiResultList = apiResponse.result;
        }

        return {
            response: apiResponse,
            resultList: apiResultList
        };
    }

    // For download report
    generateDownloadables(recordList: Array<any> = []): DownloadableArrayItem {
        if (!recordList.length) {
            return null;
        }

        let headerName: any = [];
        const headerType: any = [];
        let filename: string = "";
        let label: string = "";
        const data: any = [];
        const excelWidthConfig = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 19 }, { wch: 17.5 }, { wch: 21 }];

        filename = this.exportFileNameFuelUsage + "_VEHICLE";
        label = this.exportFileNameFuelUsagePdfTitle + " (Vehicle)";
        headerName = [
            "Vehicle Name", "Plate Number", "Number of Refills", "Total Refuel Amount(L)", "Total Fuel Cost(RM)",
            "Fuel Efficiency(L/100km)"
        ];

        for (let i = 0; i < recordList.length; i++) {
            const tempRow = [
                recordList[i].vehicleName,
                recordList[i].plateNo,
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].numOfRefills, 0, '0')),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].fuelUsed, 0, '0')),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].fuelCost, 0, '0')),
                NumberUtil.numberOrNull(NumberUtil.formatFloat(recordList[i].Efficiency, 0, '0'))
            ];
            data.push(tempRow);
        }
        return new DownloadableArrayItem(filename, label, this.pageLayout, headerName, headerType, excelWidthConfig, data, this.platform);
    }

    /**
     * Report Specific Methods
     */

    // 1. Call API Post Trip Summary Violation Report
    async getFuelUsageRecord(startRecord, isDownload: boolean = false) {
        let result: any = null;
        //Get Data
        if (!isDownload) {
            result = await this.fuelService.getOverviewFuelUsageReport(this.selectedVehiclesList, this.currentFromDate, this.currentToDate);
        } else {
            result = await this.fuelService.getOverviewFuelUsageReport(this.selectedVehiclesList, this.currentFromDate, this.currentToDate);
        }
        return result || null;
    }

    fontColorByTwoNumber(exactNumber, compareNumber) {
        if (exactNumber == null || compareNumber == null || exactNumber == compareNumber) {
            return "--black";
        }
        if (exactNumber > compareNumber) {
            return "--red";
        }
        if (exactNumber < compareNumber) {
            return "--green";
        }
    }

    /**
     * Mat Datepicker Info:
     * startview="multi-year"(show select year tab)
     * startview="year"(show select month tab)
     * startview="month"(show select date tab)
     * each tab selection is done will emit an event that show a normalized date
     */

    // // Get Year from Mat Datepicker after year is selected
    // chosenYearHandler(normalizedYear) {
    //     this.chosenYear = '';
    //     const selectedYear = moment(normalizedYear).format("YYYY");
    //     // this.choosingDate = selectedYear;
    //     this.chosenYear = selectedYear;
    // }

    // // Get Month from Mat Datepicker after month is selected
    // chosenMonthHandler(normalizedMonth, datepicker: MatDatepicker<Date>) {
    //     const selectedYearMonth = moment(normalizedMonth).format("YYYY-MM-01");
    //     this.monthYear = selectedYearMonth;

    //     // Must have to prevent datepicker those date selection tab
    //     datepicker.close();
    // }

    initChartJsDefaults() {
        Chart.defaults.global.legend.display = false;
        // this.randomColourGenerator();
        // this.colourList = [
        //     Colors.RGBA_PRI1_SHARP_GREEN,
        //     Colors.RGBA_PRI3_DARK_GREEN,
        //     Colors.RGBA_PRI4_GRASS_GREEN,
        //     Colors.RGBA_HL3_SKY_BLUE,
        //     Colors.RGBA_HL2_ROYALE_BLUE,
        //     Colors.RGBA_VIOLET,
        //     Colors.RGBA_ORANGE,
        //     Colors.RGBA_BRIGHT_RED,
        //     Colors.RGBA_GRAY,
        //     Colors.RGBA_DARK_GREEN
        // ];
        // this.violationPieColourList = [
        //     Colors.RGBA_PRI4_GRASS_GREEN,
        //     Colors.RGBA_HL3_SKY_BLUE,
        //     Colors.RGBA_HL2_ROYALE_BLUE,
        //     Colors.RGBA_PRI1_SHARP_GREEN,
        //     Colors.RGBA_PRI3_DARK_GREEN,
        //     Colors.RGBA_CTA6_PURPLE
        // ];
        this.chartJsHoverProps = {
            animationDuration: 300,
            onHover: function (e: MouseEvent) {
                const point = this.getElementAtEvent(e);
                if (point.length) {
                    (<HTMLElement>e.target).style.cursor = 'pointer';
                } else {
                    (<HTMLElement>e.target).style.cursor = 'default';
                }
            }
        };
        this.chartJsAnimation = {
            easing: "easeInOutCubic"
        };
        this.chartJsTooltipStyles = {
            titleAlign: 'center',
            bodyAlign: 'center',
            yPadding: 8,
            xPadding: 10
        };
        this.chartJsBarHoverInteraction = {
            hoverBorderColor: Colors.RGBA_WHITE_SHADE,
            hoverBorderWidth: 2
        };
        this.chartJsScaleLabelFontStyles = {
            fontStyle: "bold",
            fontColor: Colors.RGBA_BLACK,
            fontSize: 13
        };
        this.chartJsTicksFontStyles = {
            fontColor: Colors.RGBA_BLACK,
            fontSize: 12
        };
        this.chartJsTicksNumberTruncateCallbackFn = function (value) {
            if (value >= 1000000) {
                return NumberUtil.roundOff(value / 1000000, 1) + 'mil';
            } else if (value >= 10000) {
                return NumberUtil.roundOff(value / 1000, 1) + 'k';
            }
            return value;
        };
        this.chartJsTicksStringEllipsisCallbackFn = function (value, index, values) {
            // truncate name of ticks in axis
            return StringUtil.truncateText(value, CHART_TICK_LABEL_MAX_LENGTH, 'N/A');
        };
        this.chartJsTooltipTruncateCallbackTitleFn = function (tooltipItem, cdata) {
            // console.log('title:', tooltipItem);
            // console.log('title:', cdata);
            // truncate for tooltip
            const label = cdata.labels[tooltipItem[0].index];
            return StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
        };
    }

    async getFuelUsageGraph(resultList) {

        const linearGradient = this.makeLinearCanvasGradient('fuelUsageChart', Colors.RGBA_PRI1_SHARP_GREEN);
        // const datasets = [];
        // this.resultList.map((tmp, index) => {
        //     const dataObj = {
        //         data: tmp.fuelEfficiency,
        //         label: 'Fuel litre',
        //         backgroundColor: linearGradient,
        //         borderColor: Colors.RGBA_PRI1_SHARP_GREEN,
        //         data: [0, 10],
        //         lineTension: 0,
        //         borderWidth: 1.2,
        //         pointBorderWidth: 1.2
        //     };
        //     datasets.push(dataObj);
        // });
        // const label = resultList.map(dateFormatter);
        // function dateFormatter(refuelDate) {
        //     console.log(isNaN(refuelDate))
        //     let temp = new Date(refuelDate).toISOString();
        //     console.log(temp)
        //     temp.toLocaleString('default', { month: 'long' })
        //     console.log(temp)
        //     return temp;
        // }
        const label = resultList.map(({ refuelDate }) => refuelDate);
        const data = resultList.map(({ fuelUsed }) => fuelUsed);

        const cOptions = {
            type: 'line',
            data: {
                labels: label,
                datasets: [{
                    label: 'Fuel litre',
                    backgroundColor: linearGradient,
                    borderColor: Colors.RGBA_PRI1_SHARP_GREEN,
                    data: data,
                    lineTension: 0,
                    borderWidth: 1.2,
                    pointBorderWidth: 1.2
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 10,
                        bottom: -5
                    }
                },
                tooltips: {
                    // displayColors: false,
                    ...this.chartJsTooltipStyles,
                    // enabled: false,
                    mode: 'nearest'
                    // position: 'nearest',
                    // custom: customTooltips
                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxTicksLimit: 6,
                            autoSkip: false,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: 1,
                            zeroLineWidth: 0,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Fuel (litre)',
                            ...this.chartJsScaleLabelFontStyles
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            fontSize: 11,
                            maxRotation: 20,
                            maxTicksLimit: 5,
                            autoSkip: false
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 1, 1, 1, 1],
                            zeroLineWidth: 3,
                            color: [Colors.RGBA_LIGHT_GRAY, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE, Colors.RGBA_LIGHT_GRAY_SHADE],
                            zeroLineColor: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Date',
                            ...this.chartJsScaleLabelFontStyles
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        const ctx = document.getElementById('fuelUsageChart');
        this.fuelUsageGraph = new Chart(ctx, cOptions);
        // const chart = new Chart(ctx, {
        //     // The type of chart we want to create
        //     type: 'line',

        //     // The data for our dataset
        //     data: {
        //         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        //         datasets: [{
        //             label: 'My First dataset',
        //             backgroundColor: linearGradient,
        //             borderColor: Colors.RGBA_PRI1_SHARP_GREEN,
        //             data: [0, 10, 5, 2, 20, 30, 45],
        //             lineTension: 0,
        //             borderWidth: 1.2,
        //             pointBorderWidth: 1.2
        //         }]
        //     },

        //     // Configuration options go here
        //     options: {}
        // });
    }

    async getFuelEfficiencyGraph(resultList) {
        try {
            //call api to get data
            this.fuelEfficiencyGraph_spinner.show();

            // let apiStats: any = {};
            // await AsyncUtil.wait(1000);
            // apiStats = await this.driverService.getDriverScoring(this.driverId, weekNo, year, this.pastScoreNoOfWeeks);

            if (resultList) {
                // const stats = apiStats.body;
                // Chart
                await this.generateLifeTimeScoreBarChart(resultList);
            } else {
                // Remove old chart & stats
                await this.generateLifeTimeScoreBarChart(null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.fuelEfficiencyGraph_spinner.hide();
        }
    }

    async generateLifeTimeScoreBarChart(data) {
        // Clear Previous Chart Data
        // this.scoreCharts.LifeTimeScoreGraph.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values

        // data = data.slice(0).reverse();
        // const fieldName = this.currentSearchBy == "vehicles" ? 'VehicleName' : 'DriverName';
        // const tickLabels = data.map(result => StringUtil.truncateText(result[fieldName], CHART_TOOLTIP_MAX_LENGTH, 'N/A'));
        // const tickValues = data.map(result => result.Score || 0);

        // // Check if all data are zeroes
        // if (ObjectUtil.isArrayAllEmpty(tickValues)) {
        //     return;
        // }

        // const tickLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
        // const tickLabels = [];
        // this.resultList.map((tmp, index) => {
        //     const dataObj = {
        //         data: tmp.fuelEfficiency,
        //         label: 'Fuel Efficiency',
        //         backgroundColor: color,
        //         borderColor: color,
        //         //data
        //         borderWidth: 1.2,
        //         pointBorderWidth: 1.2
        //     };
        //     tickLabels.push(dataObj);
        // });
        let tickLabels = data.map(temp => temp.vehicleName);
        // Construct Bar Chart
        const options = {
            xLabel: 'Vehicle', // X-Axis Title
            yLabel: 'Fuel Efficiency (km/L)',
            tickLabels, //X-Axis values ......
            // tickValues, //Y-Axis Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    return ' ' + tooltipItem.value + ' %';
                }
            },
            // onClickFn
            custom: function (cOptions) {
                if (cOptions.options.scales.xAxes[0].ticks) {
                    cOptions.options.scales.xAxes[0].ticks['fontSize'] = 11;
                    cOptions.options.scales.xAxes[0].ticks['autoSkip'] = false;
                    // cOptions.options.scales.xAxes[0].ticks['minRotation'] = 20;
                    // cOptions.options.scales.xAxes[0].ticks['maxRotation'] = 70;
                    cOptions.options.scales.xAxes[0].ticks['maxTicksLimit'] = 25;
                }
                return cOptions;
            }
        };
        // this.padding = 0;
        // this.stepSize = 20;
        const color = Colors.RGBA_PRI4_GRASS_GREEN;
        const fuelEffData = data.map(temp => temp.fuelEfficiency);
        const datasets = [{
            label: 'Fuel Efficiency',
            backgroundColor: color,
            borderColor: color,
            data: fuelEffData,
            borderWidth: 1.2,
            pointBorderWidth: 1.2
        }];


        this.fuelEfficiencyGraph = this.genColorfulHorizontalBarChart('fuelEfficiencyChart', options, color, datasets);
    }

    genColorfulHorizontalBarChart(canvasId, options: any, color: any, dataset?: any) {
        const {
            tickLabels, tickValues
        } = options;
        // chartObj.setIsEmpty(false);

        // if (!dataset) {
        //     dataset = [{
        //         data: tickValues,
        //         backgroundColor: color,
        //         label: this.currentSearchBy == "vehicles" ? 'Vehicle Score' : 'Driver Score',
        //         ...this.chartJsBarHoverInteraction
        //     }];
        // }
        let cOptions = {
            type: 'bar',
            data: {
                labels: tickLabels,
                datasets: dataset
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    ...this.chartJsTooltipStyles

                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        barThickness: "flex",
                        maxBarThickness: 15,
                        ticks: {
                            ...this.chartJsTicksFontStyles,
                            callback: this.chartJsTicksStringEllipsisCallbackFn,
                            // suggestedMin: 0,
                            // suggestedMax: 100
                            // padding: this.padding,
                            // stepSize: this.stepSize
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 1, 1, 1, 1, 1, 1],
                            zeroLineWidth: 3,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxRotation: 20,
                            maxTicksLimit: 7,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: false,
                            lineWidth: 3,
                            zeroLineWidth: 3,
                            color: Colors.RGBA_LIGHT_GRAY
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(canvasId, cOptions);
    }

    // ChartJS Helper: Template Generator Configuration Override
    overrideChartJsOptions(cOptions: any, options: any) {
        if (options.onClickFn) {
            cOptions.options['onClick'] = options.onClickFn;
        }
        if (options.tooltipCb) {
            cOptions.options.tooltips['callbacks'] = options.tooltipCb;
        }
        if (options.xLabel) {
            cOptions.options.scales.xAxes[0]['scaleLabel'] = {
                display: true,
                labelString: options.xLabel,
                ...this.chartJsScaleLabelFontStyles
            };
        }
        if (options.yLabel) {
            cOptions.options.scales.yAxes[0]['scaleLabel'] = {
                display: true,
                labelString: options.yLabel,
                ...this.chartJsScaleLabelFontStyles
            };
        }
        // Apply custom configurations
        if (options.custom) {
            cOptions = options.custom(cOptions);
        }
        return cOptions;
    }

    // ChartJS Helper: Create linear canvas gradient
    makeLinearCanvasGradient(canvasId: string, colorRgba: string, height: number = 200): CanvasGradient {
        // Render Gradient
        const ctx: CanvasRenderingContext2D = (<HTMLCanvasElement>this.el.nativeElement.querySelector(`#${canvasId}`)).getContext('2d');
        const cg: CanvasGradient = ctx.createLinearGradient(0, 0, 0, height);
        const rgb = DomUtil.getRgbaValues(colorRgba, false);
        cg.addColorStop(0, DomUtil.makeRgbaString(rgb.concat([0.9]))); //opacity 0.9
        cg.addColorStop(0.3, DomUtil.makeRgbaString(rgb.concat([0.9]))); //opacity 0.9
        cg.addColorStop(0.7, DomUtil.makeRgbaString(rgb.concat([0.5]))); //opacity 0.5
        cg.addColorStop(1, DomUtil.makeRgbaString(rgb.concat([0.2]))); //opacity 0.2
        return cg;
    }
}
