// import { environment } from '../../../../../environments/environment';
import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';

import { TripsService } from './../../../../_services/trips/trips.service';
import { MessageBusService, BusMessage } from "./../../../../_services/messagebus.service";
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { AuthService } from './../../../../_services/auth';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';

import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as NumberUtil from '../../../../util/numberUtil';

import { VIOLATION_TYPE, getViolationLabelFromValue } from '../../../../constants/violationTypes.constant';
import { STATUSES } from '../../../../constants/statuses.constant';
import { STATUS } from '../../../../constants/status.constant';
import * as Message from '../../../../constants/message';

@Component({
    selector: 'map-list',
    templateUrl: './map-list.component.html',
    styleUrls: ['./map-list.component.scss']
})
export class MapListComponent implements OnInit, OnDestroy {

    STATUSES: any = STATUSES;
    STATUS: any = STATUS;

    @ViewChild("map_list_spinner",{static:true}) mapListSpinner: SpinnerComponent;
    @ViewChild('mapItemList') mapItemList;
    mapIsReady: boolean = false;

    listIsShown: boolean = true;

    vehiclesList: any = [];

    filtersModel: any = {
        isShown: false,
        isAnimatingOut: false,
        timer: null,
        vehicleName: '',
        statuses: {},
        groups: []
    };

    lazyLoadLimit: number = 5;
    prevScrollTop: number = 0;
    sizePerView: number = 30;
    mapModel: any = {
        items: [],
        itemMarkers: [],
        currentItems: [],
        currentItem: null,
        currentItemIsActive: false,
        lazyLoadItems: [],
        endIndex: null,
        isPrevLastBatch: false,
        isNextLastBatch: false,
        isListRefresh: false
    };

    msgBusSubscription: Subscription;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private tripsService: TripsService,
        private snackBar: SnackBarService,
        private msgbus: MessageBusService,
        private vehicleService: VehicleService,
        private authService: AuthService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService

    ) {
        // console.debug('MapListComponent: constructor');
        for (const i of this.STATUSES) {
            this.filtersModel.statuses[i] = false;
        }
        // this.mapModel.currentItems = this.mapModel.items.slice(0);

        const busesPort = ["map"];
        this.msgBusSubscription = this.msgbus.messagebus$.pipe(
            filter(message => busesPort.indexOf(message.source) != -1)
        ).subscribe(this.messageBusGetMessage.bind(this));
    }

    /* Message Controller*/
    async messageBusGetMessage(message: BusMessage) {

        // listen for map ready first
        if (!this.mapIsReady) {
            if (message.source != 'map' || message.messagetype != 'ready') {
                return;
            }
        }
        switch (message.source) {
            case "map":
                await this.handleMapComponentMessage(message);
                break;
        }
    }

    /* Message Handler: Map */
    async handleMapComponentMessage(message: BusMessage) {
        switch (message.messagetype) {
            case "filterchange":
                // console.debug('(MsgBus)MapListComponent: map -> filterchange (MapComponent)');
                this.applyMapFilter(message.data.filters, {
                    isRefresh: false,
                    isFiltering: true
                });
                break;
            case "refresh":
                console.debug('(MsgBus)MapListComponent: map -> refresh (MapComponent)');
                if (message.data.target == "MapListComponent") {
                    await this.fetchMapList({
                        isRefresh: true
                    });
                }
                break;
            case "togglelist":
                // console.debug('(MsgBus)MapListComponent: map -> togglelist (MapComponent)');
                this.listIsShown = message.data.listIsShown;
                break;
            case "ready":
                // console.debug('(MsgBus)MapListComponent: map -> ready (MapComponent)');
                if (!this.mapIsReady && (message.data.target == "MapListComponent" || message.data.target == null)) {
                    this.mapIsReady = true;
                    if (message.data.cachedMapList.loaded) {
                        const cached = message.data.cachedMapList;
                        this.mapModel = cached.mapModel;
                        this.vehiclesList = cached.vehiclesList;
                        this.refreshVisibleMapList({
                            firstLoad: true
                        });
                        // trigger refresh immediately
                        await this.fetchMapList({
                            isRefresh: true
                        });
                    } else {
                        await this.customDelayedInit();
                    }
                }
                break;
        }
    }

    async ngOnInit() {
        // console.debug('MapListComponent: ngOnInit');

        //request info from map is ready
        this.msgbus.sendMessage(new BusMessage("map", "isReady", {
            sender: "MapListComponent"
        }));
    }

    /* MAP-DEPENDENT COMPONENT: DELAYED ON-INIT UNTIL MAP IS READY */
    async customDelayedInit() {
        await this.checkUnviewedCampaign();
        // console.debug('MapListComponent: customDelayedInit');
        this.sendMessagePreloadMapList();
        await this.fetchMapList({
            firstLoad: true
        });

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async ngOnDestroy() {
        // console.debug('MapListComponent: ngOnDestroy');
        if (this.msgBusSubscription) {
            this.msgBusSubscription.unsubscribe();
        }
    }

    async sendMessagePreloadMapList() {

        this.msgbus.sendMessage(new BusMessage(
            "mapList",
            "markerlistinit",
            {
                sender: "MapListComponent"
            }
        ));
    }

    async sendMessageForMapMarker(items, options: any) {

        const defaultOption: any = {
            isRefresh: false,
            isFiltering: false,
            firstLoad: false
        };
        options = Object.assign(defaultOption, options);

        this.msgbus.sendMessage(new BusMessage(
            "mapList",
            "markerlistchange",
            {
                currentItems: items,
                sender: "MapListComponent",
                focusedMarker: null,
                isPanelOpened: false,
                isRefresh: options.isRefresh,
                isFiltering: options.isFiltering,
                firstLoad: options.firstLoad,
                mapListMapModel: this.mapModel,
                mapListVehiclesList: this.vehiclesList
            }
        ));
    }

    async getVehicleDetailsList() {
        const result = await this.vehicleService.getAllVehicleInfoDetails();
        if (result && result.body.vehicle_details_list.length > 0) {
            return result.body.vehicle_details_list;
        }
        return [];
    }

    async getLiveSumTrips() {
        const liveSumTripsResult = await this.tripsService.getLiveSummaryTrips();
        if (liveSumTripsResult) {
            let result = liveSumTripsResult.body.live_summary_trip;
            const imeiNos = result.map(x => x.imei);
            const vehicles = (await this.vehicleService.getVehicleDetailsInfo(imeiNos))?.body?.vehicle_details_list || [];
            const vehiclesMap = vehicles.reduce((map, obj) => {
                map[obj.imeiNo] = obj;
                return map;
            }, {});
            result = result.map(x => {
                x.vehicleId = vehiclesMap[x.imei].vehicleId;
                x.vehicleName = vehiclesMap[x.imei].vehicleName;
                return x;
            });
            return result;
        }
        return [];
    }

    async fetchMapList(options: any = {}) {

        const defaultOption: any = {
            isRefresh: false,
            firstLoad: false
        };
        options = Object.assign(defaultOption, options);

        this.mapListSpinner.show();
        try {

            const liveSummaryTrips = await this.getLiveSumTrips();
            if (liveSummaryTrips) {
                this.mapModel.items = liveSummaryTrips;
                if (!this.mapModel.items.length) {
                    const msg = Message.getMessage(Message.MESSAGE.VEHICLE_NOT_FOUND.value);

                    if (this.authService.isAuthenticated()) {
                        this.snackBar.openGenericSnackBar(msg);
                    }

                    this.refreshVisibleMapList(options);
                    return;
                }

                //parse
                this.mapModel.items.forEach(item => {
                    if (item.status && STATUS[item.status.toLowerCase()]) {
                        item.statusLoweredCase = item.status.toLowerCase();
                        item.statusLabel = STATUS[item.statusLoweredCase].LABEL;
                        // item.statusClassLabel = item.statusLoweredCase;
                    }
                    item.activity.speedStr = item.activity.speed + ' km/h';
                    item.activity.durationStr = NumberUtil.formatHourMinute(Number(item.activity.duration) / 60, '0 m', 'H h M m');

                    item.activity.lastStopDateStr = DateTimeUtil.getDateLabel(item.activity.lastStopDate, "YYYY-MM-DD HH:mm:ss.SSS", "MMM DD, YYYY h.mm A");
                    item.hasViolation = this.checkViolations(item.violations);
                    if (item.hasViolation) {
                        item.violations.typeLabel = getViolationLabelFromValue(item.violations.type).label;
                        item.violations.dateLabel = DateTimeUtil.getDateLabel(item.violations.date, "YYYY-MM-DD HH:mm:ss.SSS", "MMM DD, YYYY h.mm A");
                    }
                });

            } else {
                return;
            }

            this.vehiclesList = await this.getVehicleDetailsList() || [];

            //Set Vehicle Group to the Trips
            this.mapModel.items.map(result => delete result.image); // Delete Image return from Live Trip
            for (let i = 0; i < this.mapModel.items.length; i++) {
                for (let j = 0; j < this.vehiclesList.length; j++) {
                    if (this.mapModel.items[i].vehicleId === this.vehiclesList[j].vehicleId) {
                        this.mapModel.items[i].vehicleName = this.vehiclesList[j].vehicleName;
                        this.mapModel.items[i].groups = this.vehiclesList[j].vehicleGroupList;
                        if (this.vehiclesList[j].vehicleImage) {
                            this.mapModel.items[i].image = this.vehiclesList[j].vehicleImage;
                        }
                        this.mapModel.items[i].hasDriverTagDevice = this.vehiclesList[j].hasDriverTagDevice;
                        this.mapModel.items[i].hasFuelSensor = this.vehiclesList[j].hasFuelSensor;
                        this.mapModel.items[i].hasImmobilizer = this.vehiclesList[j].hasImmobilizer;
                        this.mapModel.items[i].hasPanicButton = this.vehiclesList[j].hasPanicButton;
                        this.mapModel.items[i].isPanic = this.vehiclesList[j].isPanic;
                        break;
                    }
                }
            }

            // this.resortFullMapList(); *already sorted once at API*
            this.refreshVisibleMapList(options);
            this.generateLazyLoadList();
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.mapListSpinner.hide();
        }
    }

    checkViolations(violations) {

        if (violations) {
            if (violations.type && violations.date && violations.type != VIOLATION_TYPE.IS_IDLING.value) {
                return true;
            }
        }
        return false;
    }

    resortFullMapList() {
        // this.mapModel.items.sort(this.sortByName);
        this.mapModel.items.sort(this.sortBySeqNo);
    }

    refreshVisibleMapList(options = {}) {
        this.mapModel.currentItems = this.mapModel.items.slice(0);

        //apply filter
        this.applyMapFilter(this.filtersModel, options);
    }

    sortByName(a, b) {
        if (a.vehicleName === undefined) {
            a.vehicleName = "Unknown";
        } else if (b.vehicleName === undefined) {
            b.vehicleName = "Unknown";
        }
        const nameA = a.vehicleName.toLowerCase();
        const nameB = b.vehicleName.toLowerCase();

        let comparison = 0;
        if (nameA > nameB) {
            comparison = 1;
        } else if (nameA < nameB) {
            comparison = -1;
        }
        return comparison;
    }

    sortBySeqNo(a, b) {
        const sortSeqNoA = a.sortSeqNo;
        const sortSeqNoB = b.sortSeqNo;

        let comparison = 0;
        if (sortSeqNoA > sortSeqNoB) {
            comparison = 1;
        } else if (sortSeqNoA < sortSeqNoB) {
            comparison = -1;
        }
        return comparison;
    }

    filterHasStatusesOrGroups(): boolean {

        if (this.getActiveGroupFilters().length || this.getActiveStatusFilters().length) {
            return true;
        }
    }

    getActiveStatusFilters(): any {
        const activeStatusFilters = [];
        for (const i in this.filtersModel.statuses) {
            if (this.filtersModel.statuses[i]) {
                activeStatusFilters.push(i);
            }
        }
        return activeStatusFilters;
    }

    getActiveGroupFilters(): any {
        const activeGroupFilters = [];

        for (let i = 0; i < this.filtersModel.groups.length; i++) {
            if (this.filtersModel.groups[i].status) {
                activeGroupFilters.push(this.filtersModel.groups[i]);  // push the whole object
            }
        }
        return activeGroupFilters;
    }

    updateVehicleStatusAndGroupStatusToFalse(type: string, value: any) {
        if (type === 'status') {
            this.filtersModel.statuses[value] = false;
        } else if (type === 'group') {
            this.filtersModel.groups.filter(group =>
                group.groupId == value
            )[0].status = false;
        }
        this.applyMapFilter(this.filtersModel, {
            isRefresh: false,
            isFiltering: true
        });
    }

    //Filter function to see if a given map item
    //fulfils the filter
    filterMapItem(mapItem: any): boolean {

        /*
          * If there's a string in the name search and it doesn't match this
          * map item, filter out the map item.
          */
        if (this.filtersModel.vehicleName) {
            const normalisedMapItemName = mapItem.vehicleName.toLowerCase().trim();
            const normalisedFilterName = this.filtersModel.vehicleName.toLowerCase().trim();
            if (normalisedMapItemName.indexOf(normalisedFilterName) < 0) {
                return false;
            }
        }

        /*
          * If there are status filters, and the map item's status isn't part of
          * the filter, filter out the map item.
          */
        const filterStatuses = [];
        for (const i in this.filtersModel.statuses) {
            if (this.filtersModel.statuses[i]) {
                filterStatuses.push(i);
            }
        }
        if (filterStatuses.length) {
            if (filterStatuses.indexOf(mapItem.status.toLowerCase()) < 0) {
                return false;
            }
        }

        /*
          * If there are group filters, and NONE of the item's group is part of
          * the filter, filter out the map item.
          */
        const filterGroups = [];
        for (let i = 0; i < this.filtersModel.groups.length; i++) {
            if (this.filtersModel.groups[i].status) {
                filterGroups.push(this.filtersModel.groups[i].groupId);
            }
        }
        if (filterGroups.length) {
            let matchesGroup = false;

            if (mapItem.groups) {
                for (let i = 0; i < mapItem.groups.length; i++) {
                    if (filterGroups.indexOf(mapItem.groups[i].vehicleGroupId) > -1) {
                        matchesGroup = true;
                        break;
                    }
                }
            }
            if (!matchesGroup) {
                return false;
            }
        }

        /*
          * If the function reaches here, means it has passed all of the above
          * criteria, and should be included in the filter.
          */
        return true;
    }

    applyMapFilter(filters, options: any): void {

        const defaultOption: any = {
            isRefresh: false,
            isFiltering: false,
            firstLoad: false
        };
        options = Object.assign(defaultOption, options);

        this.filtersModel = filters;
        // console.debug('MapList: applyMapFilter()');
        // console.debug(this.mapModel.items);
        this.mapModel.currentItems = this.mapModel.items.slice(0).filter(this.filterMapItem.bind(this));
        this.generateLazyLoadList();
        this.sendMessageForMapMarker(this.mapModel.currentItems, options);
    }

    async pinVehicleToTop(vehicleId: number, vehicleName: string) {
        this.mapListSpinner.show();
        try {
            // Client side sorting will be faster
            // this.mapModel.items.forEach(item => {
            //     if (item.vehicleId == vehicleId) {
            //         item.isFavourite = true;
            //         item.sortSeqNo = '0';
            //     }
            // });
            this.resortFullMapList();
            this.refreshVisibleMapList();

            //Change sortSeqNo of most recently pinned vehicle to 0
            this.mapModel.items.forEach(item => {
                if (item.sortSeqNo == '-1') {
                    item.sortSeqNo = '0';
                }
            });

            const result = await this.vehicleService.pinVehicleToFavourites(vehicleId);

            if (result) {
                // await this.fetchMapList(); //reload list to reflect changes
            } else {
                //Show Error Snackbar
                const msg = Message.getMessage(Message.MESSAGE.VEHICLE_PIN_FAILED.value, vehicleName);
                this.snackBar.openGenericSnackBar(msg);
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.mapListSpinner.hide();
        }
    }

    async unpinVehicle(vehicleId: number, vehicleName: string) {
        this.mapListSpinner.show();
        try {
            // Client side sorting will be faster
            // this.mapModel.items.forEach(item => {
            //     if (item.vehicleId == vehicleId) {
            //         delete item.isFavourite;
            //         let status = item.status;
            //         //logic: follow trip/live API
            //         if (status === 'idling') {
            //             item.sortSeqNo = '1';
            //         } else if (status === 'moving') {
            //             item.sortSeqNo = '2';
            //         } else if (status === 'stopped') {
            //             item.sortSeqNo = '3';
            //         } else if (status === 'disconnected') {
            //             item.sortSeqNo = '4';
            //         } else {
            //             item.sortSeqNo = '5';
            //         }
            //     }
            // });
            this.resortFullMapList();
            this.refreshVisibleMapList();

            const result = await this.vehicleService.unpinVehicleFromFavourites(vehicleId);

            if (result) {
                // await this.fetchMapList(); //reload list to reflect changes
            } else {
                //Show Error Snackbar
                const msg = Message.getMessage(Message.MESSAGE.VEHICLE_UNPIN_FAILED.value, vehicleName);
                this.snackBar.openGenericSnackBar(msg);
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.mapListSpinner.hide();
        }
    }

    async receivePinEvent($event: any) {
        if (!$event.data) {
            return;
        }
        const data: any = $event.data;
        if (data.pinToTop) {
            await this.pinVehicleToTop(data.vehicleId, data.vehicleName);
        } else {
            await this.unpinVehicle(data.vehicleId, data.vehicleName);
        }
    }

    generateLazyLoadList(reset = false) {
        if (this.mapModel.currentItems.length > this.sizePerView) {
            if (!this.mapModel.endIndex || reset) {
                this.mapModel.endIndex = this.sizePerView - 1;
                this.mapModel.lazyLoadItems = this.mapModel.currentItems.slice(0, this.sizePerView);
                this.mapModel.isPrevLastBatch = true;
                this.mapModel.isNextLastBatch = false;
                this.mapModel.isListRefresh = false;
            } else {
                this.mapModel.lazyLoadItems = this.mapModel.currentItems.slice((this.mapModel.endIndex + 1) - this.sizePerView, this.mapModel.endIndex + 1);
                this.mapModel.isListRefresh = true;
            }

        } else {
            this.mapModel.endIndex = this.mapModel.currentItems.length;
            this.mapModel.lazyLoadItems = this.mapModel.currentItems.slice(0);
            this.mapModel.isPrevLastBatch = true;
            this.mapModel.isNextLastBatch = true;
            this.mapModel.isListRefresh = true;
        }
    }

    async onMaplistScroll(container: HTMLElement) {
        if (this.mapModel.isListRefresh) {
            container.scrollTop = this.prevScrollTop;
            this.mapModel.isListRefresh = false;
            return;
        }
        const clientHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;

        // Not scrollable, so don't do anything
        if (clientHeight === scrollHeight) {
            console.debug('no scrollbar, checking if got more records to show');
            if (this.mapModel.hasMoreVehicle) {
                await this.getNextVehicleFromList();
            }
            return;
        }

        const scrollTop = container.scrollTop;
        console.debug(`Client's View Height: [${scrollTop}, ${scrollTop / (scrollHeight - clientHeight) * 100}]`);
        if (scrollTop / (scrollHeight - clientHeight) * 100 >= 90) {
            console.debug(`next Lazy Load Kick In`);
            await this.getNextVehicleFromList();
        } else if (scrollTop / (scrollHeight - clientHeight) * 100 <= 10) {
            console.debug(`prev Lazy Load Kick In`);
            await this.getPrevVehicleFromList();
        }
        this.prevScrollTop = scrollTop;
    }

    async getPrevVehicleFromList() {
        const currList = this.mapModel;
        const prevRemaining = (currList.endIndex + 1) - this.sizePerView;
        if ((currList.endIndex + 1) - this.sizePerView != 0 && prevRemaining > this.lazyLoadLimit) {
            currList.lazyLoadItems.splice(currList.lazyLoadItems.length - this.lazyLoadLimit, currList.lazyLoadItems.length);
            const prevVehicles = this.mapModel.currentItems.slice((currList.endIndex + 1) - this.sizePerView - this.lazyLoadLimit, (currList.endIndex + 1) - this.sizePerView);
            currList.lazyLoadItems = prevVehicles.concat(currList.lazyLoadItems);
            currList.endIndex = currList.endIndex - this.lazyLoadLimit;
            currList.isPrevLastBatch = false;
            currList.isNextLastBatch = false;
        } else if ((currList.endIndex + 1) - this.sizePerView != 0 && prevRemaining <= this.lazyLoadLimit) {
            currList.lazyLoadItems.splice(currList.lazyLoadItems.length - prevRemaining, currList.lazyLoadItems.length);
            const prevVehicles = this.mapModel.currentItems.slice((currList.endIndex + 1) - this.sizePerView - prevRemaining, (currList.endIndex + 1) - this.sizePerView);
            currList.lazyLoadItems = prevVehicles.concat(currList.lazyLoadItems);
            currList.endIndex = currList.endIndex - prevRemaining;
            currList.isPrevLastBatch = true;
            currList.isNextLastBatch = false;
        } else {
            console.debug("Begining of List Reached");
        }
    }

    async getNextVehicleFromList() {
        const currList = this.mapModel;
        const remaining = this.mapModel.currentItems.length - (currList.endIndex + 1);
        if (currList.endIndex + 1 != this.mapModel.currentItems.length && remaining > this.lazyLoadLimit) {
            currList.lazyLoadItems.splice(0, this.lazyLoadLimit);
            const newVehicles = this.mapModel.currentItems.slice(currList.endIndex + 1, currList.endIndex + 1 + this.lazyLoadLimit);
            currList.lazyLoadItems = currList.lazyLoadItems.concat(newVehicles);
            currList.endIndex = currList.endIndex + this.lazyLoadLimit;
            currList.isNextLastBatch = false;
            currList.isPrevLastBatch = false;
        } else if (currList.endIndex + 1 != this.mapModel.currentItems.length && remaining <= this.lazyLoadLimit) {
            currList.lazyLoadItems.splice(0, remaining);
            const newVehicles = this.mapModel.currentItems.slice(currList.endIndex + 1, currList.endIndex + 1 + remaining);
            currList.lazyLoadItems = currList.lazyLoadItems.concat(newVehicles);
            currList.endIndex = currList.endIndex + remaining;
            currList.isNextLastBatch = true;
            currList.isPrevLastBatch = false;
        } else {
            console.debug("End of List Reached");
        }
    }
}
