import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToggleDirective } from './../../../../directives/toggle/toggle.directive';

import * as Message from './../../../../constants/message';
import { COLOR_PICKER } from './../../../../constants/color-picker.constant';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';

import { TagService } from './../../../../_services/tag/tag.service';
import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { PopupService } from './../../../common/popup/popup.service';
import { RouterProxyService } from '../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';

import * as StringUtil from './../../../../util/stringUtil';
import * as ObjectUtil from './../../../../util/objectUtil';
import * as DomUtil from './../../../../util/domUtil';

@Component({
    selector: 'app-edit-trip-tags',
    templateUrl: './edit-trip-tags.component.html',
    styleUrls: ['./edit-trip-tags.component.scss']
})
export class EditTripTagsComponent implements OnInit {

    tagId = '';
    tagName = '';
    tagColor = '';
    tagViewInfo: any = {};

    colorPickerToggle: ToggleDirective = new ToggleDirective(null);
    array20: number[] = ObjectUtil.fillRange(20);

    @ViewChild('page_spinner',{static:true}) pageSpinner: SpinnerComponent;

    tagDetails: any = {};
    colorChoose: any = {
        no: 0,
        code: COLOR_PICKER.ONE
    };
    message = Message;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private tagService: TagService,
        private popupService: PopupService,
        private el: ElementRef,
        private snackBar: SnackBarService,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService

    ) { }

    async ngOnInit() {
        this.tagId = this.route.snapshot.params.id;

        await this.onInitCommonFunction();
        DomUtil.autoFocusForm(this.el);
        await this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async onInitCommonFunction() {
        this.pageSpinner.show();
        try {

            const res = await this.tagService.getTagDetails(this.tagId);
            this.tagDetails = res.body[0];
            this.colorChoose.code = res.body[0].ColorCode;
            this.tagId = res.body[0].Id;
            this.tagName = res.body[0].Name;

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    toggleColor(color: number) {
        this.colorChoose.no = color;
        if (color === 1) {
            this.colorChoose.code = COLOR_PICKER.ONE;
        } else if (color === 2) {
            this.colorChoose.code = COLOR_PICKER.TWO;
        } else if (color === 3) {
            this.colorChoose.code = COLOR_PICKER.THREE;
        } else if (color === 4) {
            this.colorChoose.code = COLOR_PICKER.FOUR;
        } else if (color === 5) {
            this.colorChoose.code = COLOR_PICKER.FIVE;
        } else if (color === 6) {
            this.colorChoose.code = COLOR_PICKER.SIX;
        } else if (color === 7) {
            this.colorChoose.code = COLOR_PICKER.SEVEN;
        } else if (color === 8) {
            this.colorChoose.code = COLOR_PICKER.EIGHT;
        } else if (color === 9) {
            this.colorChoose.code = COLOR_PICKER.NINE;
        } else if (color === 10) {
            this.colorChoose.code = COLOR_PICKER.TEN;
        } else if (color === 11) {
            this.colorChoose.code = COLOR_PICKER.ELEVEN;
        } else if (color === 12) {
            this.colorChoose.code = COLOR_PICKER.TWELVE;
        } else if (color === 13) {
            this.colorChoose.code = COLOR_PICKER.THIRTEEN;
        } else if (color === 14) {
            this.colorChoose.code = COLOR_PICKER.FOURTEEN;
        } else if (color === 15) {
            this.colorChoose.code = COLOR_PICKER.FIFTEEN;
        } else if (color === 16) {
            this.colorChoose.code = COLOR_PICKER.SIXTEEN;
        } else if (color === 17) {
            this.colorChoose.code = COLOR_PICKER.SEVENTEEN;
        } else if (color === 18) {
            this.colorChoose.code = COLOR_PICKER.EIGHTEEN;
        } else if (color === 19) {
            this.colorChoose.code = COLOR_PICKER.NINETEEN;
        } else if (color === 20) {
            this.colorChoose.code = COLOR_PICKER.TWENTY;
        }
    }

    async updateTagDetails() {
        this.pageSpinner.show();

        try {
            const id = this.tagDetails.Id;
            let name = this.tagDetails.Name;
            const colour = this.colorChoose.code;

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(name)) {
                name = name.trim();
            }

            let result = null;
            result = await this.tagService.editTag(id, name, colour);

            const mName = name;

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Tag', mName);
            if (result) {
                if (result.statusCode === ResponseStatusCode.SUCCESS.code) {
                    msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Tag', mName);
                } else if (result.statusCode === ResponseStatusCode.PARTIAL.code) {
                    msg = Message.getMessage(Message.MESSAGE.UPDATE_PARTIAL.value, 'Tag', mName);
                }
                this.navigateSuccessToTripTagView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToTripTagView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/trip-tags/view']);
    }

    navigateToTripTagView() {
        this.routerProxyService.navigate(['/create-manage/trip-tags/view']);
    }

    toUpperFirstChar(value) {
        if (value !== '') {
            return value.substr(0, 1).toUpperCase() + value.substr(1);
        } else {
            return value;
        }
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    closeColorPicker(clickEvent?: Event): void {
        // let floaterContentTagId = "#colorpicker-floater-wrapper"; //tag with '--active' class
        // let excludedHtmlIds = [
        //     "colorpicker-floater-toggler",
        //     "colorpicker-floater-content"
        // ];

        //Avoid floater auto-closed when clicked on toggler
        // if (DomUtil.checkMouseClickOnMatchingElement(clickEvent, excludedHtmlIds)) {
        //     // console.debug('blocked event by: ' + (<Element>clickEvent.target).id);
        //     return;
        // }

        // let colorPickerDom = this.el.nativeElement.querySelector(floaterContentTagId);
        // if (Boolean(colorPickerDom) && colorPickerDom.className.indexOf("--active") >= 0) {
        //     // console.debug('EditTripTagsComponent: Closing color picker');
        //     this.colorPickerToggle.setInactive();
        // }
        this.colorPickerToggle.setInactive();
    }

}
