import { Injectable } from '@angular/core';
// import { RequestOptions, Headers } from '@angular/http';
import { HttpClient } from '@angular/common/http';
// import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as StringUtil from '../../util/stringUtil';
import * as moment from 'moment';





@Injectable({
    providedIn: 'root'
})
export class StatsService {

    constructor(private http: HttpClient) { }

    async getVehicleViolationStats(noOfWeek = 2, endDate = "", groupId = null) {
        const path = environment.mtntApiEndpoint + `/stats/violations/vehicle?noOfWeek=${noOfWeek}&endDate=${endDate}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getTopMileageStats(type = "", endDate = "", groupId = null) {
        const pageSize = 5;
        const path = environment.mtntApiEndpoint + `/stats/mileage?type=${type}&pageSize=${pageSize}&endDate=${endDate}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getTopSpeedingStats(type = "", endDate = "", groupId = null) {
        const pageSize = 5;
        const path = environment.mtntApiEndpoint + `/stats/speedingDistance?type=${type}&pageSize=${pageSize}&endDate=${endDate}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getTopIdlingStats(type = "", endDate = "", groupId = null) {
        const pageSize = 5;
        const path = environment.mtntApiEndpoint + `/stats/idlingTime2?type=${type}&pageSize=${pageSize}&endDate=${endDate}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getWeeklyFleetUtilisationStats(noOfWeek = 5, endDate = "", groupId = null) {
        const path = environment.mtntApiEndpoint + `/stats/fleet-utilisation?noOfWeek=${noOfWeek}&endDate=${endDate}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getHourlyFleetUtilisationStats(noOfWeek = 1, groupId = null) {
        const path = environment.mtntApiEndpoint + `/stats/hourly-fleet-utilisation?noOfWeek=${noOfWeek}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getAdvisory(groupId = null) {
        const path = environment.mtntApiEndpoint + `/advisory/vehicle?groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getTopFuelPerformance(groupId = null, type = 'FuelCost', month = parseInt(moment().format("MM")), year = parseInt(moment().format("YYYY")), pageSize = 5, startRecord = 1) {
        const path = environment.mtntApiEndpoint + `/stats/fuel-stats?type=${type}&month=${month}&year=${year}&pageSize=${pageSize}&startRecord=${startRecord}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getMonthlyFuelPerformance(groupId = null, type = 'FuelCost', noOfMonth = 5, todayDate = moment().format("YYYY-MM-DD")) {
        const path = environment.mtntApiEndpoint + `/stats/fuel-performance?type=${type}&todayDate=${todayDate}&noOfMonth=${noOfMonth}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getScoringStats(type = "vehicle", pageSize = 5, weekNo = 0, year = 0, sortAscending = true, groupId = null, noOfWeek = 2) {

        const weekNoStr = Boolean(weekNo) ? weekNo : "";
        const yearStr = Boolean(year) ? year : "";

        let path = environment.mtntApiEndpoint + `/stats/scoring?type=${type}`;
        path += `&pageSize=${pageSize}`;
        path += `&weekNo=${weekNoStr}`;
        path += `&year=${yearStr}`;
        path += `&sortAscending=${sortAscending}`;
        path += `&groupId=${groupId}`;
        path += `&noOfWeek=${noOfWeek}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getExpiringLicense(groupId = null, pageSize = null, startRecord = null, sortField = null, sortAscending = null) {
        const param: any = { type: 'all' };

        if (pageSize) {
            param.pageSize = pageSize;
        }
        if (startRecord) {
            param.startRecord = startRecord;
        }
        if (sortField) {
            param.sortField = sortField;
        }
        if (StringUtil.isNotEmptyOrNull(sortAscending)) {
            param.sortAscending = sortAscending;
        }
        if (groupId) {
            param.groupId = groupId;
        }

        const path = environment.mtntApiEndpoint + `/stats/expiringLicense`;
        const result: any = await this.http.get(path, {
            params: param
        }).toPromise();
        return result;
    }

    // Old:
    async getVehicleStats(noOfWeek = 2, endDate = "", groupId = null) {
        const path = environment.mtntApiEndpoint + `/stats/vehicle?noOfWeek=${noOfWeek}&endDate=${endDate}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    // Old:
    async getVehicleIdlingTime(type = "", endDate = "", groupId = null) {
        const pageSize = 5;
        const path = environment.mtntApiEndpoint + `/stats/idlingTime?type=${type}&pageSize=${pageSize}&endDate=${endDate}&groupId=${groupId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

}
