import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class MessageBusService {
    private messageSource = new Subject<BusMessage>();

    messagebus$ = this.messageSource.asObservable();

    constructor() { }

    sendMessage(msg: BusMessage) {
        this.messageSource.next(msg);
    }
}

export class BusMessage {
    source: string;
    messagetype: string;
    data: any;
    // timestamp: number;
    // _momTimestamp: moment.Moment;

    constructor(source: string, messagetype: string, data: any) {
        this.source = source;
        this.messagetype = messagetype;
        this.data = data;

        // attach timestamp for each message
        // this._momTimestamp = moment();
        // this.timestamp = this._momTimestamp.valueOf();
    }
}
