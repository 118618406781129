import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { PagerService } from './../../../../_services/pager/pager.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as StringUtil from '../../../../util/stringUtil';
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from './../../../../../environments/environment';
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import * as moment from 'moment';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { ActivatedRoute } from '@angular/router';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { ModulePermissionService } from './../../../../_services/access-control/module-permission.service';
import { modules } from '../../../../constants/module-access.constant';

@Component({
    selector: 'app-view-driving-limits',
    templateUrl: './view-driving-limits.component.html',
    styleUrls: ['./view-driving-limits.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ViewDrivingLimitsComponent implements OnInit {
    toggleMobileOpen: boolean = false;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    color = 'primary';
    // checked = false;
    disabled = false;

    currentTab: number = 1;
    deletingDrivers: boolean = false;

    vehicleDrivingLimitList: any = [];
    sortField = 'Name';
    sortAscending = true;
    filterKey = 'Name';
    filterValue = '';
    currentFilterValue = '';

    filterValueGeofence = '';
    currentFilterValueGeofence = '';

    pager: any = {};
    pageRecordSize = environment.appConfig.createManage.pageRecordSize;

    pagerGeofence: any = {};
    pageGeofenceRecordSize = environment.appConfig.createManage.pageRecordSize;

    /* ----- Driving Limit ----- */
    // Driving Limit Selected
    isDeletingDrivingLimit: boolean;
    selectedDrivingLimit: any = {};
    selectedDrivingLimitGroups: any = {};

    // Driving Limit Type
    speedLimit: string;
    allowedTimeFrom: string;
    allowedTimeTo: string;
    dailyMileageLimit: string;
    currentSelectedVehicleId: number;

    // Slide Bar Switch
    isSpeedLimitOff: boolean = false;
    isAllowedTimeOff: boolean = false;
    isDailyMileageLimitOff: boolean = false;
    /* ----- Driving Limit ----- */

    /* ----- Geofence ----- */
    vehicleGeofenceList: any = [];
    deletingGeofence: boolean = false;

    // Driving Limit Selected
    isDeletingGeofence: boolean;
    selectedGeofence: any = {};
    selectedGeofenceGroups: any = {};

    currentSelectedGeofenceId: number;
    /* ----- Geofence ----- */

    //Maybe Need to change
    allowedTimeFromHour = '';
    allowedTimeFromMinute = '';
    allowedTimeFromAMPM = 'AM';
    allowedTimeToHour = '';
    allowedTimeToMinute = '';
    allowedTimeToAMPM = 'AM';

    timeSelector: {
        hour: string[],
        fullHour: string[],
        minute: string[],
        second: string[]
    } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array

    message = Message;
    moment = moment;

    // Destination
    pagerDestination: any = {};
    pageDestinationRecordSize = environment.appConfig.createManage.pageRecordSize;
    isDeletingDestination: boolean;
    selectedDestination: any = {};
    selectedDestinationGroups: any = {};
    filterValueDestination = '';
    currentFilterValueDestination = '';
    vehicleDestinationList: any = [];
    deletingDestination: boolean = false;
    currentSelectedDestinationId: number;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];
    creatable: boolean = false;
    editable: boolean = false;
    deletable: boolean = false;

    constructor(
        private vehicleService: VehicleService,
        private pagerService: PagerService,
        private popupService: PopupService,
        private snackBar: SnackBarService,
        private route: ActivatedRoute,
        private routerProxyService: RouterProxyService,
        private popupCampaignService: PopupCampaignService,
        private mpService: ModulePermissionService

    ) { }

    async ngOnInit() {
        //initialize function user have permission to access
        const permissions = await this.mpService.hasPermission(modules.CRUD_DRIVING_LIMIT.value);
        this.creatable = permissions[modules.CRUD_DRIVING_LIMIT.value].cAccess;
        this.editable = permissions[modules.CRUD_DRIVING_LIMIT.value].eAccess;
        this.deletable = permissions[modules.CRUD_DRIVING_LIMIT.value].dAccess;

        if (this.route.snapshot.queryParams.autoSearch === 'true') {
            if (StringUtil.equalsIgnoreCase(this.route.snapshot.queryParams.tab, 'general')) {
                // await this.geofenceDrivingLimits(this.route.snapshot.queryParams.geofenceName);
                await this.generalDrivingLimits(this.route.snapshot.queryParams.vehicleName);
                return;
            } else if (StringUtil.equalsIgnoreCase(this.route.snapshot.queryParams.tab, 'geofence')) {
                // await this.generalDrivingLimits(this.route.snapshot.queryParams.vehicleName);
                await this.geofenceDrivingLimits(this.route.snapshot.queryParams.geofenceName);
                return;
            } else if (StringUtil.equalsIgnoreCase(this.route.snapshot.queryParams.tab, 'destination')) {
                await this.destinationDrivingLimits(this.route.snapshot.queryParams.destinationName);
                return;
            }
        } else {
            // const isFromGeofencePage = this.route.snapshot.paramMap.get('fromGeofence');
            // const isFromDestinationPage = this.route.snapshot.paramMap.get('fromDestination');
            // if (isFromGeofencePage) {
            //     await this.geofenceDrivingLimits(); // Go to Geofence Page
            // } else if (isFromDestinationPage) {
            //     await this.destinationDrivingLimits(); // Go to Destination Page
            // } else {
            //     await this.generalDrivingLimits();
            // }

            // Reopen tab after navigateSuccess() (not using route reuse strategy)
            const tab = this.routerProxyService.getViewTabFromQueryParam(this.route);
            switch (tab) {
                case 'general': await this.generalDrivingLimits(); break;
                case 'geofence': await this.geofenceDrivingLimits(); break;
                case 'destination': await this.destinationDrivingLimits(); break;
                default: await this.generalDrivingLimits(); // first tab
            }
        }

        await this.checkUnviewedCampaign();
    }

    async generalDrivingLimits(filterValue = '') {
        this.currentTab = 1;
        this.sortField = 'Name';
        this.sortAscending = true;
        this.filterValue = filterValue || ''; // Empty Text Box
        this.currentFilterValue = filterValue || '';
        await this.getVehicleDrivingLimit();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async geofenceDrivingLimits(filterValueGeofence = '') {
        this.currentTab = 2;
        this.sortField = 'Name';
        this.sortAscending = true;
        this.filterValueGeofence = filterValueGeofence || ''; // Empty Text Box
        this.currentFilterValueGeofence = filterValueGeofence || '';
        await this.getVehicleGeofence();
    }

    async destinationDrivingLimits(filterValueDestination = '') {
        this.currentTab = 3;
        this.sortField = 'Name';
        this.sortAscending = true;
        this.filterValueDestination = filterValueDestination || ''; // Empty Text Box
        this.currentFilterValueDestination = filterValueDestination || '';
        await this.getVehicleDestination();
    }

    // async goToGeofence() {
    //         this.sortField = 'Name';
    //         this.filterValueGeofence = ''; // Empty Text Box
    //         this.currentFilterValueGeofence = '';
    //         await this.getVehicleGeofence();
    //         this.currentTab = 2;
    // }

    // async goToDestination() {
    //         this.sortField = 'Name';
    //         this.filterValueDestination = ''; // Empty Text Box
    //         this.currentFilterValueDestination = '';
    //         await this.getVehicleDestination();
    //         this.currentTab = 3;
    // }

    // async general() {
    //     this.currentTab = 1;
    //     this.sortField = 'Name';
    //     await this.getVehicleDrivingLimit();
    // }

    // async geofence() {
    //     this.currentTab = 2;
    //     this.sortField = 'Name';
    //     await this.getVehicleGeofence();
    // }

    // async destination() {
    //     this.currentTab = 3;
    //     this.sortField = 'Name';
    //     await this.getVehicleDestination();
    // }

    navigateToSetLimit() {
        this.routerProxyService.navigate(['/create-manage/driving-limits/set-vehicle-limit']);
    }

    navigateToCreateGeofence() {
        this.routerProxyService.navigate(['/create-manage/driving-limits/create-geofence']);
    }

    navigateToEditGeofence(geofenceId) {
        this.routerProxyService.navigate(['/create-manage/driving-limits/edit-geofence/', geofenceId]);
    }

    async getVehicleDrivingLimit(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageRecordSize) + 1;
            //Get Vehicle Driving Limit Page
            const vehicleResult = await this.vehicleService.getVehicleDrivingLimit(this.pageRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValue);
            this.vehicleDrivingLimitList = vehicleResult.body.result;
            this.pager = this.pagerService.getPager(vehicleResult.body.totalRecord, page, this.pageRecordSize);

            // parse some values to reduce function data bindings
            this.vehicleDrivingLimitList.forEach(vehicle => {
                vehicle.allowedDriveTimeRange = `${this.formateDateLabel(vehicle.allowedTimeFrom)} - ${this.formateDateLabel(vehicle.allowedTimeTo)}`;
            });

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async getVehicleGeofence(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageGeofenceRecordSize) + 1;
            //Get Vehicle Geofence Page
            const geofenceResult = await this.vehicleService.getVehicleGeofence(this.pageGeofenceRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValueGeofence);
            this.vehicleGeofenceList = geofenceResult.body.result;
            this.pagerGeofence = this.pagerService.getPager(geofenceResult.body.totalRecord, page, this.pageGeofenceRecordSize);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async searchVehicleName() {
        this.currentFilterValue = this.filterValue;
        await this.getVehicleDrivingLimit();
    }

    async searchGeofenceName() {
        this.currentFilterValueGeofence = this.filterValueGeofence;
        await this.getVehicleGeofence();
    }

    async searchDestinationName() {
        this.currentFilterValueDestination = this.filterValueDestination;
        await this.getVehicleDestination();
    }

    async sort(sortName) {
        if (sortName === this.sortField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = sortName;
            this.sortAscending = true;
        }

        if (this.currentTab === 1) {
            await this.getVehicleDrivingLimit();
        } else if (this.currentTab === 2) {
            await this.getVehicleGeofence();
        } else if (this.currentTab === 3) {
            await this.getVehicleDestination();
        }
    }

    getSortingState(sortName) {
        return sortName === this.sortField ? (this.sortAscending ? '--ascending' : '--descending') : '';
    }

    onVehicleClick(vehicleId) {
        // Set Value
        this.currentSelectedVehicleId = vehicleId;
        this.speedLimit = this.vehicleDrivingLimitList.filter(result => result.vehicleId === vehicleId)[0].speedLimit;
        this.allowedTimeFrom = this.vehicleDrivingLimitList.filter(result => result.vehicleId === vehicleId)[0].allowedTimeFrom;
        this.allowedTimeTo = this.vehicleDrivingLimitList.filter(result => result.vehicleId === vehicleId)[0].allowedTimeTo;
        this.dailyMileageLimit = this.vehicleDrivingLimitList.filter(result => result.vehicleId === vehicleId)[0].dailyMileageLimit;
        this.allowedTimeFromHour = this.allowedTimeFrom !== null ? moment(this.allowedTimeFrom, "HH:mm:ss").format("hh") : '';
        this.allowedTimeFromMinute = this.allowedTimeFrom !== null ? moment(this.allowedTimeFrom, "HH:mm:ss").format("mm") : '';
        this.allowedTimeFromAMPM = this.allowedTimeFrom !== null ? moment(this.allowedTimeFrom, "HH:mm:ss").format("A") : 'AM';
        this.allowedTimeToHour = this.allowedTimeTo !== null ? moment(this.allowedTimeTo, "HH:mm:ss").format("hh") : '';
        this.allowedTimeToMinute = this.allowedTimeTo !== null ? moment(this.allowedTimeTo, "HH:mm:ss").format("mm") : '';
        this.allowedTimeToAMPM = this.allowedTimeTo !== null ? moment(this.allowedTimeTo, "HH:mm:ss").format("A") : 'AM';

        // Set Slide Bar On Off
        this.isSpeedLimitOff = this.speedLimit !== null ? true : false;
        this.isAllowedTimeOff = this.allowedTimeFrom !== null && this.allowedTimeTo !== null ? true : false;
        this.isDailyMileageLimitOff = this.dailyMileageLimit !== null ? true : false;
    }

    onChangeSlideBar(status, slideBarName) {
        this[slideBarName] = !status;
    }

    async updateDrivingLimit() {
        this.pageSpinner.show();
        try {
            this.speedLimit = this.isSpeedLimitOff ? String(this.speedLimit) : null;
            let allowedTimeFromT = `${this.allowedTimeFromHour}:${this.allowedTimeFromMinute} ${this.allowedTimeFromAMPM}`;
            let allowedTimeToT = `${this.allowedTimeToHour}:${this.allowedTimeToMinute} ${this.allowedTimeToAMPM}`;
            if (this.allowedTimeFromHour == "HH") {
                allowedTimeFromT = "";
            } else if (this.allowedTimeFromMinute == "MM") {
                allowedTimeFromT = "";
            }
            if (this.allowedTimeToHour == "HH") {
                allowedTimeToT = "";
            } else if (this.allowedTimeToMinute == "MM") {
                allowedTimeToT = "";
            }
            this.allowedTimeFrom = this.isAllowedTimeOff ? (allowedTimeFromT.length != 0 ? moment(allowedTimeFromT, "HH:mm A").format("HH:mm") : "") : null;
            this.allowedTimeTo = this.isAllowedTimeOff ? (allowedTimeToT.length != 0 ? moment(allowedTimeToT, "HH:mm A").format("HH:mm") : "") : null;
            this.dailyMileageLimit = this.isDailyMileageLimitOff ? String(this.dailyMileageLimit) : null;

            const result = await this.vehicleService.updateVehicleDrivingLimit(this.speedLimit, this.allowedTimeFrom, this.allowedTimeTo, this.dailyMileageLimit, [this.currentSelectedVehicleId]);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Vehicle', "Driving Limit");
            if (result && result.statusCode == ResponseStatusCode.PARTIAL.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_PARTIAL.value, 'Vehicle', "Driving Limit");
            } else if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Vehicle', "Driving Limit");
            }

            this.snackBar.openGenericSnackBar(msg);
            await this.getVehicleDrivingLimit(1);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    onDrivingLimitAllSelectChange() {
        const allIsSelected = this.selectedDrivingLimitGroups.all;
        for (let i = 0; i < this.vehicleDrivingLimitList.length; i++) {
            const drivingLimitModel = this.vehicleDrivingLimitList[i];
            this.selectedDrivingLimit[drivingLimitModel.vehicleId] = allIsSelected;
        }
    }

    onDrivingLimitSelectChange(drivingLimitSelected: boolean) {
        if (drivingLimitSelected) {
            // this.selectedDrivingLimit[drivingLimitModel.vehicleId] = true;
        } else {
            this.selectedDrivingLimitGroups['all'] = false;
        }
    }

    countSelectedDrivingLimit() {
        return Object.keys(this.selectedDrivingLimit).filter(result => this.selectedDrivingLimit[result] === true).length;
    }

    cancelResetDelete() {
        this.isDeletingDrivingLimit = false;
        this.selectedDrivingLimit = {};
        this.selectedDrivingLimitGroups['all'] = false;

        this.isDeletingGeofence = false;
        this.selectedGeofence = {};
        this.selectedGeofenceGroups['all'] = false;

        this.isDeletingDestination = false;
        this.selectedDestination = {};
        this.selectedDestinationGroups['all'] = false;
    }

    async deleteDrivingLimit() {
        // console.debug('deleteDrivingLimit');
        this.pageSpinner.show();
        try {
            const selectedDrivingLimitList = [];
            for (const key in this.selectedDrivingLimit) {
                if (this.selectedDrivingLimit[key] === true) {
                    selectedDrivingLimitList.push(parseInt(key));
                }
            }

            const result = await this.vehicleService.deleteVehicleDrivingLimit(selectedDrivingLimitList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Driving Limit');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Driving Limit');

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getVehicleDrivingLimit();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async deleteVehicleGeofence() {
        this.pageSpinner.show();
        try {
            const selectedGeofenceList = [];
            for (const key in this.selectedGeofence) {
                if (this.selectedGeofence[key] === true) {
                    selectedGeofenceList.push(parseInt(key));
                }
            }

            const result = await this.vehicleService.deleteGeofence(selectedGeofenceList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Geofence');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Geofence');

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getVehicleGeofence();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    formateDateLabel(dateTime) {
        const currFormat = "hh:mm:ss";
        const desireFormat = "hh:mm A";
        if (dateTime == null) {
            return '';
        } else {
            return DateTimeUtil.getDateLabel(dateTime, currFormat, desireFormat);
        }
    }

    onGeofenceAllSelectChange() {
        const allIsSelected = this.selectedGeofenceGroups.all;
        for (let i = 0; i < this.vehicleGeofenceList.length; i++) {
            const geofenceModel = this.vehicleGeofenceList[i];
            this.selectedGeofence[geofenceModel.Id] = allIsSelected;
        }
    }

    onGeofenceSelectChange(geofenceSelected: boolean) {
        if (geofenceSelected) {
            //this.selectedGeofence[geofenceModel.Id] = true;
        } else {
            this.selectedGeofence['all'] = geofenceSelected;
        }
    }

    countSelectedGeofence() {
        return Object.keys(this.selectedGeofence).filter(result => this.selectedGeofence[result] === true).length;
    }

    onGeofenceClick(geofenceId) {
        // Set Value
        this.currentSelectedGeofenceId = geofenceId;
    }

    // Destination
    async getVehicleDestination(page: number = 1) {
        this.pageSpinner.show();
        try {
            const startRecord = ((page - 1) * this.pageDestinationRecordSize) + 1;
            //Get Vehicle Destination Page
            const destinationResult = await this.vehicleService.getVehicleDestination(this.pageDestinationRecordSize, startRecord, this.sortField, this.sortAscending, this.filterKey, this.currentFilterValueDestination);
            this.vehicleDestinationList = destinationResult.body.result;
            this.pagerDestination = this.pagerService.getPager(destinationResult.body.totalRecord, page, this.pageDestinationRecordSize);
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    onDestinationAllSelectChange() {
        const allIsSelected = this.selectedDestinationGroups.all;
        for (let i = 0; i < this.vehicleDestinationList.length; i++) {
            const destinationModel = this.vehicleDestinationList[i];
            this.selectedDestination[destinationModel.Id] = allIsSelected;
        }
    }

    onDestinationSelectChange(destinationSelected: boolean) {
        if (destinationSelected) {
            //this.selectedGeofence[geofenceModel.Id] = true;
        } else {
            this.selectedDestination['all'] = destinationSelected;
        }
    }

    countSelectedDestination() {
        return Object.keys(this.selectedDestination).filter(result => this.selectedDestination[result] === true).length;
    }

    onDestinationClick(destinationId) {
        // Set Value
        this.currentSelectedDestinationId = destinationId;
    }

    navigateToCreateDestination() {
        this.routerProxyService.navigate(['/create-manage/driving-limits/create-destination']);
    }

    navigateToEditDestination(destinationId) {
        this.routerProxyService.navigate(['/create-manage/driving-limits/edit-destination/', destinationId]);
    }

    async deleteVehicleDestination() {
        this.pageSpinner.show();
        try {
            const selectedDestinationList = [];
            for (const key in this.selectedDestination) {
                if (this.selectedDestination[key] === true) {
                    selectedDestinationList.push(parseInt(key));
                }
            }

            const result = await this.vehicleService.deleteDestination(selectedDestinationList);

            let msg = Message.getMessage(Message.MESSAGE.DELETE_FAILED.value, 'Destination');
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.DELETE_SUCCESS.value, 'Destination');

                // reset delete selection
                this.cancelResetDelete();

                // retrieve updated result
                await this.getVehicleDestination();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

}
