import { Directive, Output, EventEmitter, ElementRef, Input } from '@angular/core';



import * as DomUtil from './../../util/domUtil';

@Directive({
    selector: '[click-outside-manual]'
})
export class ClickOutsideManualDirective {

    @Input('click-outside-excludedHtmlIds') excludedOutsideHtmlIds: string[] = [];
    @Output('clickOutside') clickOutside: EventEmitter<Object> = new EventEmitter();

    onClickBound: EventListener = null;

    constructor(private _elRef: ElementRef) {
    }

    public onClick($event: Event) {
        // console.debug('clicked');
        if ($event.target) {
            const clickedInside = DomUtil.isDescendantOrExcluded(this._elRef.nativeElement, $event.target, this.excludedOutsideHtmlIds);
            if (!clickedInside) {
                this.clickOutside.emit({
                    target: $event.target
                });
            }
        }
    }

    public init() {
        if (!this.onClickBound) {
            this.onClickBound = this.onClick.bind(this);
        }
        // console.debug('ClickOutsideDirective init()');
        document.addEventListener('click', this.onClickBound);
    }

    public destroy() {
        // console.debug('ClickOutsideDirective destroy()');
        document.removeEventListener('click', this.onClickBound);
        if (this.clickOutside) {
            this.clickOutside.unsubscribe();
        }
    }

}
