<div class="section">
    <app-spinner #general_spinner [fullscreen]="true"></app-spinner>
    <div class="wrapper">
        <div class="block">
            <div class="grid --gutter">
                <div class="grid__block   w-8of12@xs">
                    <h1 class="heading --level-2 --partial-underline   c-green">
                        General Limits
                    </h1>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="key-value-icons xs-up">
                <div class="key-value-icon">
                    <div class="key-value-icon__key">
                        Speed Limit
                    </div>

                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-speeding.svg" alt="" width="38" height="30">
                        </div>
                        <ng-container *ngIf="vehicleDrivingLimit">
                            <div class="key-value-icon__value c-grey">
                                <span *ngIf="vehicleDrivingLimit.speedLimit != null">{{vehicleDrivingLimit.speedLimit}}km/h</span>
                                <span *ngIf="vehicleDrivingLimit.speedLimit == null">-</span>
                            </div>
                        </ng-container>
                        <!-- <ng-container *ngIf="vehicleGeneralDrivingLimitList.length <= 0">
                            <div class="key-value-icon__value c-grey">
                                <span>-</span>
                            </div>
                        </ng-container> -->
                    </div>
                </div>

                <div class="key-value-icon allowDriveTime">
                    <div class="key-value-icon__key">
                        Allowed Driving Hours (hh:mm)
                    </div>

                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-time.svg" alt="" width="38" height="30">
                        </div>

                        <ng-container *ngIf="vehicleDrivingLimit">
                            <div class="key-value-icon__value c-grey">
                                <span *ngIf="vehicleDrivingLimit.allowedTimeFrom != null">{{allowedDriveTimeRange}}</span>
                                <span *ngIf="vehicleDrivingLimit.allowedTimeFrom == null">-</span>
                            </div>
                        </ng-container>
                        <!-- <ng-container *ngIf="vehicleGeneralDrivingLimitList.length <= 0">
                            <div class="key-value-icon__value c-grey">
                                <span>-</span>
                            </div>
                        </ng-container> -->
                    </div>
                </div>

                <div class="key-value-icon">
                    <div class="key-value-icon__key">
                        Daily Mileage
                    </div>

                    <div class="key-value-icon__main">
                        <div class="key-value-icon__icon">
                            <img src="assets/images/scss-components/key-value-icons/icon-mileage.svg" alt="" width="38" height="30">
                        </div>

                        <ng-container *ngIf="vehicleDrivingLimit">
                            <div class="key-value-icon__value c-grey">
                                <span *ngIf="vehicleDrivingLimit.dailyMileageLimit != null">{{vehicleDrivingLimit.dailyMileageLimit}}km</span>
                                <span *ngIf="vehicleDrivingLimit.dailyMileageLimit == null">-</span>
                            </div>
                        </ng-container>
                        <!-- <ng-container *ngIf="vehicleGeneralDrivingLimitList.length <= 0">
                            <div class="key-value-icon__value c-green">
                                <span>-</span>
                            </div>
                        </ng-container> -->
                    </div>
                </div>
                <div class="key-value-icon" [isVisibleElement]="createManageDrivingLimitsPath" *ngIf="editable">
                    <a class="button --min-width" (click)="navigateToViewGeneralDrivingLimitsByVehicleName()">
                        Manage
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Geofence-->
<div class="section --border-top">
    <app-spinner #geofence_spinner [fullscreen]="false"></app-spinner>
    <div class="wrapper">
        <div class="block">
            <div class="grid --gutter">
                <div class="grid__block   w-5of12@sm">
                    <h1 class="heading --level-2 --partial-underline   c-green">
                        Geofence
                    </h1>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="wrapper">
                <div class="block">
                    <div class="grid --gutter">
                        <div class="grid__block w-12of12@md form__flex">
                            <div class="field-group --unstack@xs">
                                <div class="field-group__item">
                                    <div class="field-group__holder">
                                        <div class="field-group__label">
                                            <label class="select">
                                                <select class="select__input" [(ngModel)]="vehicleGeofence" name="vehicleGeofence" *ngIf="vehicleGeofenceList" (change)="vehicleGeofenceChange()">
                                                    <option *ngFor="let geofence of vehicleGeofenceList" [selected]="geofenceId === geofence.Id" [value]="geofence.Id">{{geofence.Name}} | {{geofence.AlertType}} | {{geofence.AlertDay}} |
                                                        {{geofence.TimeFrom}} to {{geofence.TimeTo}}</option>
                                                </select>
                                            </label>
                                        </div>

                                        <div class="field-group__input" [isVisibleElement]="createManageDrivingLimitsPath" *ngIf="editable">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <a class="button --min-width" (click)="navigateToViewGeofenceDrivingLimitsByGeofenceName()">
                                                        Manage
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map__canvas" id="map_drivingLimit">
                    map area
                </div>
            </div>
        </div>

    </div>
</div>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
