// import { RequestOptions, Headers } from '@angular/http';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, } from '@angular/common/http';
// import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MessageBusService, BusMessage } from "../../_services/messagebus.service";
// import { JwtHelper } from '@auth0/angular-jwt';

import * as moment from 'moment';
import * as LocalStorageUtil from './../../util/localStorageUtil';
import * as AsyncUtil from './../../util/asyncUtil';
import * as DomUtil from './../../util/domUtil';
// import * as NumberUtil from './../../util/numberUtil';

import { Observable, from } from 'rxjs';




// import { EmptyObservable } from "rxjs/observable/EmptyObservable";

import { AccessControlService } from '../access-control/access-control.service';
import { RouterProxyService } from './../../_services/router-proxy/router-proxy.service';
import { Platform } from '@ionic/angular';
import { FcmService } from '../../shared/service/fcm.service';
import { SnackBarService } from '../snackBar/snack-bar.service';
import { TokenService } from '../token/token.service';
import { ModulePermissionService } from '../access-control/module-permission.service';
import { CustomReuseStrategy } from '../../app-routing-custom-reuse-strategy';
// import { SupportService } from '../support/support.service';

const MSG_UNAUTHORISED_CREDENTIALS = 'Session is not authorised. Please try re-login after 5 secs...';
const MSG_LOGIN_TOKEN_EXPIRED = 'User session expired. Redirecting to login page in 5 secs...';
const MSG_SUPPORT_TOKEN_EXPIRED = 'Support Session expired. Redirecting to support panel in 5 secs...';

const TOKEN_REFRESH_EXPIRY_THRESHOLD: number = 60; // 1 minute threshold to refresh token before jwt token really expired

@Injectable()
export class AuthService {

    // private redirectUrlAfterLogout: string;
    // private tokenExpiryCheckOn: boolean = environment.appConfig.auth.tokenExpiryCheck; // token expiry check

    isAbortRefreshToken: boolean = false;

    constructor(
        private http: HttpClient,
        private accessControlService: AccessControlService,
        private routerProxyService: RouterProxyService,
        private platform: Platform,
        private fcm: FcmService,
        private msgbus: MessageBusService,
        private tokenService: TokenService,
        // private supportService: SupportService,
        private snackBar: SnackBarService,
        private mpService: ModulePermissionService
    ) { }

    public isAuthenticated() {
        if (LocalStorageUtil.localStorageGet('currentUser')) {
            // this.hideBackgroundImage();
            // console.debug('Auth: session found, verifying token expiry...');
            return true;
        }
        // console.debug('Auth: session not found');
        return false;
    }

    getCurrentUserDetails() {
        if (this.isAuthenticated()) {
            return LocalStorageUtil.localStorageGet('currentUser');
        }
        return null;
    }

    //server side
    public async isTokenExpired() {
        // if (!this.tokenExpiryCheckOn) {
        //     return false;
        // }

        //validate token
        const token: string = LocalStorageUtil.localStorageGet('currentUser').token;
        try {
            const verifyResult: any = await this.verifyTokenAsync(token);
            if (verifyResult && verifyResult.token) {
                //token not expired
                console.debug('Auth: token is not expired');
                return false;
            }
            //token expired or invalid
            return true;

        } catch (err) {
            //do nothing
            return true;
        }
    }

    //server side
    public async isAdministratorTokenExpired() {
        // if (!this.tokenExpiryCheckOn) {
        //     return false;
        // }

        //validate ref token
        const refToken: string = LocalStorageUtil.localStorageGet('administratorUser').refToken;
        try {
            const verifyResult: any = await this.verifyTokenAsync(refToken);
            if (verifyResult && verifyResult.token) {
                //token not expired
                console.debug('Auth: Ref token is not expired');
                return false;
            }
            //ref token expired or invalid
            return true;

        } catch (err) {
            //do nothing
            return true;
        }
    }

    public async noAuthorizationRedirectLogin(isDeactivated = false): Promise<void> {
        console.debug('Auth: Invalid/unauthorised credentials used! redirecting to login...');
        // const msg = MSG_UNAUTHORISED_CREDENTIALS;
        // const _this = this;
        // return this.showSnackbarAndSiteSpinnerTimeout(msg, async function () {
        await this.logout(isDeactivated);
        // });
    }

    public async sessionExpiredRedirectLogin(): Promise<void> {
        console.debug('Auth: Login token expired! redirecting to login...');
        const msg = MSG_LOGIN_TOKEN_EXPIRED;
        const _this = this;
        return this.showSnackbarAndSiteSpinnerTimeout(msg, async function () {
            await _this.logout();
        });
    }

    public async supportSessionExpiredRedirectSupportPanel(): Promise<void> {
        console.debug('Auth: support ref token expired! redirecting to support panel...');
        const msg = MSG_SUPPORT_TOKEN_EXPIRED;
        const _this = this;
        return this.showSnackbarAndSiteSpinnerTimeout(msg, async function () {
            let token = _this.tokenService.removeAdministratorUser();
            if (token) {
                await _this.removeAdminitratorLogin(token);
            }
            _this.routerProxyService.navigateToSupportPanelPage();
        });
    }

    private async showSnackbarAndSiteSpinnerTimeout(msg: string = 'Redirecting to login page in 5 seconds...', callback: any, timeout: number = 5000): Promise<void> {

        // send message bus to app to show site spinner
        this.msgbus.sendMessage(new BusMessage("system", "spinner", { show: true }));

        // show snackbar
        if (msg) {
            this.snackBar.openGenericSnackBar(msg);
        }

        // delay N seconds
        await AsyncUtil.wait(timeout);

        // send message bus to app to hide site spinner
        this.msgbus.sendMessage(new BusMessage("system", "spinner", { show: false }));

        await callback();
    }

    // public showBackgroundImage(): void {
    //     document.body.classList.add('show-background');
    // }

    // public hideBackgroundImage(): void {
    //     document.body.classList.remove('show-background');
    // }

    public setStayLoggedIn(stayLoggedIn: boolean): void {
        LocalStorageUtil.setSessionDetails('stayLoggedIn', stayLoggedIn);
    }

    public isStayLoggedIn(): boolean {
        const sessionStayLoggedIn = LocalStorageUtil.getSessionDetails('stayLoggedIn');
        if (sessionStayLoggedIn) {
            if (typeof sessionStayLoggedIn == typeof true) {
                if (sessionStayLoggedIn) {
                    return true;
                }
            }
        }
        return false;
    }

    public async preLogin() {
        const path = environment.mtntApiEndpoint + '/user-pre-login';
        await this.http.get(path, { withCredentials: true }).toPromise();
        return null;
    }

    public async login(username: string, password: string) {
        const path = environment.mtntApiEndpoint + '/user-login';
        const result: any = await this.http.post(path, { username, password }, { withCredentials: true }).toPromise();
        if (result && result.token) {
            result.tokenExpiryDate = this.estimateExpiryDate(result.expiresIn);
            LocalStorageUtil.localStorageSet('currentUser', result);
            return result;
        }
        return null;
    }

    // public async loginViaSso(authCode?: string, state?: string) {
    //     const path = environment.mtntApiEndpoint + '/user-login/sso';
    //     const result: any = await this.http.post(path, { authCode, state }, { withCredentials: true }).toPromise();
    //     if (result && result.token) {
    //         result.tokenExpiryDate = this.estimateExpiryDate(result.expiresIn);
    //         LocalStorageUtil.localStorageSet('currentUser', result);
    //         return result;
    //     }
    //     return null;
    // }

    public async loginViaSso(token?: string, username?: string) {
        const path = environment.mtntApiEndpoint + '/user-login/sso';
        const result: any = await this.http.post(path, { token, username }, { withCredentials: true }).toPromise();
        if (result && result.token) {
            result.tokenExpiryDate = this.estimateExpiryDate(result.expiresIn);
            LocalStorageUtil.localStorageSet('currentUser', result);
            return result;
        }
        return null;
    }

    public async getSsoUrl(username: string) {
        const path = environment.mtntApiEndpoint + '/user-login/sso/url';
        const result: any = await this.http.post(path, { username }, { withCredentials: true }).toPromise();
        if (result) return result.ssoUrl;
        return null;
    }

    // public setRedirectUrlAfterLogout(redirectUrl: string) {
    //     this.redirectUrlAfterLogout = redirectUrl;
    // }

    public async forceClearSession(): Promise<void> {
        this.isAbortRefreshToken = true;
        await this.clearLoginSession();
    }

    public async clearLoginSession(isDeactivated = false): Promise<void> {
        if (!isDeactivated) {
            //remove user session token
            const path = environment.mtntApiEndpoint + '/user-logout';
            const result: any = await this.http.delete(path).toPromise();
        }

        //Remove page access permissions
        this.accessControlService.clearPermissions();

        //Remove module access(CRUD) permissions
        this.mpService.clearPermissions();

        //clear reuse route path to avoid cache issue
        CustomReuseStrategy.clearReuseRoutePath();

        //clear local storage
        LocalStorageUtil.localStorageRemove();
        if (DomUtil.isMobileApp(this.platform)) {
            await this.fcm.deleteToken();
        }

        //kill stay logged in timer
        // this.msgbus.sendMessage(new BusMessage("system", "userSession", { stayLoggedIn: false }));

        this.msgbus.sendMessage(new BusMessage("system", "logout", null));
    }

    public async logout(isDeactivated = false): Promise<void> {
        await this.clearLoginSession(isDeactivated);
        this.routerProxyService.navigateToLoginPage();
        // this.routerProxyService.navigateByUrl(this.redirectUrlAfterLogout);
    }

    public async forgotpassword(email: string) {
        const path = environment.mtntApiEndpoint + '/user/forgot-password';
        const result: any = await this.http.post(path, { email }).toPromise();
        return result;
        // if (result && result.body.result == 'SUCCESS') {
        //     return 'SUCCESS';
        // }
        // return 'FAILED';
    }

    // public verifyToken(token: string) {
    //     const path = environment.mtntApiEndpoint + '/token/verify';
    //     return this.http.post<any>(path, { token }).pipe(map(result => {
    //         if (result && result.email) {
    //             return result;
    //         }
    //     }));
    // }

    public async verifyTokenAsync(token: string) {
        const path = environment.mtntApiEndpoint + '/token/verify';
        const result = await this.http.post(path, { token }).toPromise();
        return result;
    }

    public estimateExpiryDate(millisec): moment.Moment {
        //Estimate Token Expiry Date
        const validityDurationInSec = parseInt(millisec);
        // validityDurationInSec = NumberUtil.roundOff(validityDurationInSec *= 0.9, 0); //10% threshold

        let estimatedDurationInSec = validityDurationInSec - TOKEN_REFRESH_EXPIRY_THRESHOLD;
        if (estimatedDurationInSec < TOKEN_REFRESH_EXPIRY_THRESHOLD) {
            estimatedDurationInSec = Math.min(TOKEN_REFRESH_EXPIRY_THRESHOLD, validityDurationInSec);
        }

        const expiryDate: moment.Moment = moment().utcOffset(8).add(estimatedDurationInSec, 'seconds');
        console.debug('Auth: Current token Expires at: ' + expiryDate.format('DD-MM-YYYY HH:mm:ss'));
        return expiryDate;
    }

    //client side
    public isLoginTokenPastExpiryTime(): boolean {
        const diff = this.getLoginTokenSecsUntilExpiry();
        return /*this.tokenExpiryCheckOn &&*/ this.isPastTokenExpiryTime(diff);
    }

    //client side
    public isRefTokenPastExpiryTime(): boolean {
        const diff = this.getRefTokenSecsUntilExpiry();
        return /*this.tokenExpiryCheckOn &&*/ this.isPastTokenExpiryTime(diff);
    }

    public isPastTokenExpiryTime(seconds: number = 0): boolean {
        if (seconds >= 15) { //15 secs gap for loading transition
            return false;
        }
        return true;
    }

    public getLoginTokenSecsUntilExpiry(): number {
        const tokenExpiryDate = this.getCurrentUserDetails().tokenExpiryDate;
        return moment(tokenExpiryDate).diff(moment(), 'seconds');
    }

    public getRefTokenSecsUntilExpiry(): number {
        const tokenExpiryDate = this.tokenService.getAdministratorUserDetails().tokenExpiryDate;
        return moment(tokenExpiryDate).diff(moment(), 'seconds');
    }

    public async renewLoginToken(): Promise<boolean> {
        if (this.isAbortRefreshToken) {
            return true; // skip refresh token & assume success
        }
        // console.debug('StayLoggedIn: Auto-renewing Login Token...');
        const path = environment.mtntApiEndpoint + '/token/refresh';
        try {
            const result: any = await this.http.get(path).toPromise();
            if (result && result.body) {
                const resultBody = result.body;
                resultBody.tokenExpiryDate = this.estimateExpiryDate(resultBody.expiresIn);

                //@ts-ignore
                if (!this.isAbortRefreshToken) {
                    LocalStorageUtil.localStorageSet('currentUser', resultBody);
                    console.debug('StayLoggedIn: Successfully auto-renewed Login Token.');
                }
                return true;
            }
        } catch (err) {
            console.debug("renewLoginToken throw error :: ", err);
            throw Error(err);
        }
        console.debug('StayLoggedIn: Failed to auto-renew Login Token.');
        return false;
    }

    public async refreshTokenAsync(request: HttpRequest<any> = null): Promise<any> {

        if (!request) {
            // console.debug('StayLoggedIn: Triggered by page navigation');
        } else {
            if (request.url.includes('/token/refresh')) {
                return;
            }
            // console.debug('StayLoggedIn: Triggered by HTTPIntercept on URL: ' + request.url);
        }

        const resp = {
            abortAuthGuard: false,
            abortHTTP: false
        };

        // Check Token Expiry
        if (this.isAuthenticated()) {
            // console.debug('StayLoggedIn: Checking Token Expiry...');
            if (this.isLoginTokenPastExpiryTime()) {
                if (this.isStayLoggedIn()) {
                    try {
                        const successRenewed: boolean = await this.renewLoginToken();
                        if (!successRenewed) {
                            resp.abortAuthGuard = true;
                            resp.abortHTTP = true;
                        }
                    } catch (err) {
                        resp.abortAuthGuard = true;
                        resp.abortHTTP = true;
                    }
                } else {
                    this.sessionExpiredRedirectLogin();
                    resp.abortAuthGuard = true;
                    resp.abortHTTP = true;
                }
            }
            if (this.tokenService.isAdministratorUserTokenExist()) {
                if (this.isRefTokenPastExpiryTime()) {
                    this.supportSessionExpiredRedirectSupportPanel();
                    resp.abortAuthGuard = true;
                    resp.abortHTTP = true;
                }
            }
        } else {
            resp.abortAuthGuard = true;

            // whitelist: APIs that doesnt require token
            const whitelist = [
                '/user-pre-login',
                '/user-login',
                '/user-login/sso',
                '/user-login/sso/url',
                '/token/verify',
                '/user/activation',
                '/user/forgot-password',
                '/user/change-password'];
            if (!request || (request && whitelist.filter(item => request.url.includes(item)).length == 0)) {
                resp.abortHTTP = true;
            }
        }
        return resp;
    }

    public refreshToken(request: HttpRequest<any>): Observable<any> {
        return from(this.refreshTokenAsync(request));
    }

    // public async rejectTokenForUsername(username: string) {
    //     let path = environment.mtntApiEndpoint + '/token/reject';
    //     let result: any = await this.http.post(path, { username: username }).toPromise();
    //     return result;
    // }

    // public async renewRefToken() {
    //     console.debug('StayLoggedIn: Renewing Ref Token...');
    //     let companyId: number = this.tokenService.getAdministratorUserDetails().companyId;
    //     await this.supportService.administatorLogin(companyId);
    // }

    public initLoginPage(): void {
        // this.showBackgroundImage();
        this.isAbortRefreshToken = false;
    }

    public async removeAdminitratorLogin(tokenObj) {
        const path = environment.mtntApiEndpoint + '/support/administrator-login';
        const body: any = { body: tokenObj };
        const result: any = await this.http.delete(path, body).toPromise();
        return null;
    }

    public async checkSuportOnBehalfSession() {
        const token = this.tokenService.removeAdministratorUser();
        if (token) {
            await this.removeAdminitratorLogin(token);
        }
    }
}
