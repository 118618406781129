import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import * as passwordUtil from './../../../../util/passwordUtil';
import * as AsyncUtil from './../../../../util/asyncUtil';
import { AuthService } from './../../../../_services/auth';
import { UserService } from './../../../../_services/user/user.service';
import { PopupService } from '../../../../components/common/popup/popup.service';
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { ErrorHandlerModule } from './../../../../_services/error-handler';

import { ERROR_MESSAGE as ErrorMessage } from '../../../../constants/errorMessage';

@Component({
    selector: 'app-login',
    templateUrl: './activate-change-password.component.html',
    styleUrls: ['./../../../site/login/login.component.scss']
})
export class ActivateResetPasswordComponent implements OnInit {
    imageFolder = 'assets/images/ng-components/login/';
    currentView = 'invalid';
    token: string;
    passwordForm: UntypedFormGroup;
    // email: string;
    id: number;
    username = '';
    status = 'Account Activation';
    instruction = '';
    buttonName = '';
    message = '';
    @ViewChild('passwordChangePopup',{static:false}) passwordChangePopup;
    @ViewChild('passwordPolicyPopup',{static:false}) passwordPolicyPopup;
    submitted = false;
    loading = false;
    returnUrl: string;
    error = '';
    formModel: any = {
        password: '',
        passwordStrength: '',
        confirmPassword: ''
    };
    passwordValidation: any = {
        hasError: false,
        isMatch: true,
        isEnoughLength: true,
        isOneUpperChar: true,
        isOneLowerChar: true,
        isOneNumber: true
    };

    @ViewChild('page_spinner',{static:true}) page_spinner: SpinnerComponent;
    constructor(
        private errorHandler: ErrorHandlerModule,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private authService: AuthService,
        private userService: UserService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private snackBar: SnackBarService) { }

    async ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });

        this.page_spinner.show();
        try {
            await this.authService.forceClearSession(); //force clear login session

            const verifyResult: any = await this.authService.verifyTokenAsync(this.token);
            if (verifyResult && verifyResult.email) {
                this.username = verifyResult.email;
                this.id = verifyResult.id;
                if (verifyResult.status === 'PENDING') {
                    this.buttonName = 'ACTIVATE';
                    this.status = 'Account Activation';
                    this.instruction = 'Please enter a password to activate your account';
                } else {
                    this.buttonName = 'RESET';
                    this.status = 'Reset Your Password';
                    this.instruction = 'Please enter a new password for your account';
                }
                this.currentView = 'changePassword';
            }
        } catch (error) {

            this.currentView = 'redirect';
            this.message = this.errorHandler.handleStandardizedError(error, true);
            this.showPopup(this.passwordChangePopup);
        } finally {
            this.page_spinner.hide();
        }
    }

    onStrengthChanged($event) {
        if ($event === 20) {
            this.formModel.passwordStrength = 'WEAK';
        } else if ($event === 40) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 60) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 80) {
            this.formModel.passwordStrength = 'MODERATE';
        } else if ($event === 100) {
            this.formModel.passwordStrength = 'STRONG';
        }
    }

    validatePassword() {
        this.passwordValidation = passwordUtil.passwordValidator(this.formModel, this.passwordValidation);
    }

    async changePassword() {
        this.submitted = true;
        this.loading = true;

        if (this.formModel.password.length !== 0 && this.formModel.confirmPassword.length !== 0) {
            this.validatePassword();
            if (this.passwordValidation.hasError) {
                this.loading = false;
                this.showPopup(this.passwordPolicyPopup);
            } else {
                try {
                    await this.userService.changePassword(this.id, this.formModel.password, this.token);
                    // console.debug('changepassword result = ' + data);
                    this.message = 'Password changed successfully. You will be redirected to login page.';
                    this.showPopup(this.passwordChangePopup); // upon click OK will redirect
                } catch (e) {
                    this.loading = false;
                    this.snackBar.openStandardizedErrorSnackBar(e);
                    // this.error = error.error.errorMessage;
                }
            }
        } else {
            this.loading = false;
        }
    }

    redirectToLoginPage() {
        this.page_spinner.hide();
        const _this = this;
        return AsyncUtil.asAsyncPromise(async function () {
            await _this.routerProxyService.navigateToLoginPage(true, 2000);
        });
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
