import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as StringUtil from '../../util/stringUtil';
// import * as LocalStorageUtil from './../../util/localStorageUtil';



// import * as Message from '../../constants/message';

@Injectable({
    providedIn: 'root'
})
export class TagService {

    constructor(private http: HttpClient) { }

    async createTag(tagName, tagColor) {
        const path = environment.mtntApiEndpoint + `/trip/tag/createTags`;

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(tagName)) {
            tagName = tagName.trim();
        }

        const body = {
            'tagName': tagName,
            'tagColor': tagColor
        };

        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async editTag(tagId, tagName, tagColor) {
        const path = environment.mtntApiEndpoint + `/trip/tag/editTag`;

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(tagName)) {
            tagName = tagName.trim();
        }

        const body = {
            'tagId': tagId,
            'tagName': tagName,
            'tagColor': tagColor
        };
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async getTags(pageRecordSize, startRecord, sortField, sortAscending, filterValue) {
        let param = `pageSize=${pageRecordSize}&startRecord=${startRecord}&sortField=${sortField}&sortAscending=${sortAscending}`;
        if (StringUtil.isNotEmptyOrNull(filterValue)) {
            param += `&filterValue=${filterValue}`;
        }

        const path = environment.mtntApiEndpoint + `/trip/tag/list?${param}`;

        const result: any = await this.http.get(path).toPromise();

        return result;
    }

    async getTagDetails(id) {
        const path = environment.mtntApiEndpoint + `/trip/tag/getTagDetails/${id}`;

        const result: any = await this.http.get(path).toPromise();
        return result;

    }

    async deleteTags(tagIdList) {
        const body: any = { body: { tagIds: tagIdList } };
        const path = environment.mtntApiEndpoint + `/trip/tag/deleteTags`;

        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

}
