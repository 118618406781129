import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as LocalStorageUtil from '../../util/localStorageUtil';
import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';
import {Device} from '@capacitor/device';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class FcmService {

    constructor(private firebase: AngularFirestore, private http: HttpClient) { }

    private firebaseToken: Token;

    async initNotification() {
        console.log('Initializing Push Notification');

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
        async (token: Token) => {
            this.firebaseToken = token;
            console.log(token);
            if (!token) { return; }

            const documentId = localStorage.getItem('fcmDocumentId');
            if (documentId) { return; }

                const deviceInfo = await Device.getInfo();
                //const currentUser = LocalStorageUtil.localStorageGet('currentUser');
                
                this.createFirebaseUserNotificationToken(this.firebaseToken.value,deviceInfo.platform)
                
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.error('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {});

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {});
    }

    async deleteToken() {
        const documentId = localStorage.getItem('fcmDocumentId');
        
        this.deleteFirebaseDocument(documentId);
    }

    async createFirebaseUserNotificationToken(token: String, platform: String) {

        const url = environment.mtntApiEndpoint + '/notification/firebase'
        const body = {
            firebaseToken: token,
            platform: platform
        };

        try {
            const result: any = this.http.post(url, body).toPromise();
            localStorage.setItem('fcmDocumentId',result.body.documentId);
            return result;
        } catch (err) {
            // do nothing
            return null;
        }

    }

    async deleteFirebaseDocument(documentId){
        const url = environment.mtntApiEndpoint + '/notification/firebase'
        const body = {
            documentId: documentId
        };

        try {
            const result: any = this.http.delete(url, {body:body}).toPromise();
            localStorage.removeItem('fcmDocumentId');
            return result;
        } catch (err) {
            // do nothing
            return null;
        }
    }
}
