import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as StringUtil from '../../util/stringUtil';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    getAdmins(pageRecordSize, startRecord, sortField, sortAscending, filterValue) {
        let param = `pageSize=${pageRecordSize}&startRecord=${startRecord}&sortField=${sortField}&sortAscending=${sortAscending}`;
        if (StringUtil.isNotEmptyOrNull(filterValue)) {
            param += `&filterValue=${filterValue}`;
        }
        const path = environment.mtntApiEndpoint + `/users/admins?${param}`;
        return this.http.get(path).toPromise();
    }

    async getAdminDetails(userId) {
        const path = environment.mtntApiEndpoint + `/users/admins/${userId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async deleteAdmins(adminIdList) {
        const body: any = { body: { userIds: adminIdList } };
        const path = environment.mtntApiEndpoint + `/users/admins`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async updateAdminDetails(admin) {
        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(admin.firstName)) {
            admin.firstName = admin.firstName.trim();
        }
        if (StringUtil.isNotEmptyOrNull(admin.lastName)) {
            admin.lastName = admin.lastName.trim();
        }
        const path = environment.mtntApiEndpoint + `/users/admins/${admin.userId}`;
        const result: any = await this.http.put(path, admin).toPromise();
        return result;
    }

    async createAdmin(admin) {
        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(admin.firstName)) {
            admin.firstName = admin.firstName.trim();
        }
        if (StringUtil.isNotEmptyOrNull(admin.lastName)) {
            admin.lastName = admin.lastName.trim();
        }
        const path = environment.mtntApiEndpoint + `/users/admins/create`;
        const result: any = await this.http.post(path, admin).toPromise();
        return result;
    }

    async assignVisibleDriversAndVehiclesResources(body) {
        const path = environment.mtntApiEndpoint + '/users/admins/resources/assign';
        //toDo: Get companyId from localStorage if login as UF_ADMIN||MAXIS_ADMIN
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async changePassword(id, password, token) {
        const path = environment.mtntApiEndpoint + '/user/change-password';
        const body = { id, password, token };
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    // changePassword(id, password) {
    //     const path = environment.mtntApiEndpoint + '/user/change-password';
    //     return this.http.post(path, { id, password }).pipe(map(data => {
    //         if (data) {
    //             return data;
    //         }
    //     }));
    // }

    async checkAndChangePassword(oldPassword, password) {
        const path = environment.mtntApiEndpoint + '/my-account/change-password';
        const body = { oldPassword, password };
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    // checkAndChangePassword(oldPassword, password) {
    //     const path = environment.mtntApiEndpoint + '/my-account/change-password';
    //     return this.http.post(path, { oldPassword, password }).pipe(map(data => {
    //         if (data) {
    //             return data;
    //         }
    //     }));
    // }

    async getAccountInfo() {
        const path = environment.mtntApiEndpoint + `/my-account/edit-info`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getUserSurvey() {
        const path = environment.mtntApiEndpoint + "/users/survey";
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async updateUserSurvey(surveyStatus, surveyId, currDateTime) {
        const body = {
            surveyStatus: surveyStatus,
            surveyId: surveyId,
            lastNotifyDate: currDateTime
        };
        const path = environment.mtntApiEndpoint + `/users/survey`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async getCompanyIdByName(name: string) {
        const path = environment.mtntApiEndpoint + `/users/getCompanyIdByName?companyName=${name}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getAllUserEmail() {
        const path = environment.mtntApiEndpoint + `/users/email`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }
    
}
