<app-popup #supportPopups="popup" class="cm-popups support-popups" id="supportPopups">
    <div class="popup__block">
        <!-- ngx-slick-carousel version 0.5.1 for angular 10, 0.6.0 might competible for angular 12-->
        <ngx-slick-carousel class="carousel support-carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let campaign of allUnviewedCampaigns" class="slide">
                <div class="slider-image" [ngStyle]="{'background-image':' url(' + campaign.image + ')'}" (mouseleave)="onMouseEnter()"></div>

                <div class="slider-text">
                    <label hidden id="hiddenActiveIndex">{{ campaign.currentIndex }}</label>
                    <div class="slider-text__title">
                        {{ campaign.title }}
                    </div>

                    <div class="slider-text__desc">
                        {{ campaign.description }}
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>

    <div class="popup__block" style="margin: 30px;">
        <div class="inline-button__field">
            <button [hidden]="isCampaignFirstItem" class="button inline-button__inputs --grey" type="button" (click)="prev()">
                Previous
            </button>

            <button class="button inline-button__inputs" type="button" (click)="next()">
                {{ nextOrCloseBtn }}
            </button>
        </div>
    </div>
</app-popup>
