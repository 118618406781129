import { Directive, HostListener, ChangeDetectorRef } from '@angular/core';

@Directive({
    selector: '[toggle]',
    exportAs: 'toggle'
})
export class ToggleDirective {
    active: boolean = false;
    data: any = null;

    constructor(private ref: ChangeDetectorRef) { }

    withData(data: any): ToggleDirective {
        this.data = data;
        return this;
    }

    setActive(): void {
        this.toggleActive(true);
    }

    setInactive(): void {
        this.toggleActive(false);
    }

    toggleActive(bool?: boolean): void {
        if (typeof bool === 'boolean') {
            this.active = bool;
        } else {
            this.active = !this.active;
        }

        if (Boolean(this.ref))
            this.ref.detectChanges();
    }

    @HostListener('click') onClick(): void {
        this.toggleActive();
    }
}
