
export const RESPONSE_STATUS_CODE = {
    SUCCESS: {
        code: "SUCCESS",
        desc: "Success"
    },
    PARTIAL: {
        code: "PARTIAL",
        desc: "Partially Success"
    },
    FAILED: {
        code: "FAILED",
        desc: "Failed"
    }
}
