<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md">
                    <h1 class="heading --level-1">
                        Manage Maxis Users
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToCreateSupport(); toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Maxis Support
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Maxis Support
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingMaxisSupport = true; toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Maxis Support
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Maxis Support
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">

                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">

                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block w-10of12 w-6of12@md search__group">
                        <div class="field-group --unstack@xs search__select-field">
                            <div class="field-group__item search__type-field-group-select" [class.--active]="typeDOMStates.isFocused">
                                <label class="select --grey">
                                    <select class="select__input" [(ngModel)]="searchBy" dom-states #typeDOMStates="DOMStates">
                                        <option value="username">Username</option>
                                        <option value="name">Name</option>
                                    </select>
                                </label>
                            </div>

                            <div class="field-group__item search__type-field-group-input">
                                <div class="field">
                                    <div class="field__elems">

                                        <label class="field__holder">
                                            <input id="searchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Username" [(ngModel)]="filterValue" name="filter" (keyup.enter)="filterMaxisSupportUsers()" *ngIf="searchBy === 'username'">
                                            <input id="searchBox" type="text" autocomplete="off" class="field__input" placeholder="Search Name" [(ngModel)]="filterValue" name="filter" (keyup.enter)="filterMaxisSupportUsers()" *ngIf="searchBy === 'name'">
                                        </label>

                                        <div class="field__ui"></div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="button__holder">
                            <button type="button" class="button" (click)="filterMaxisSupportUsers()">SEARCH</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="table table__support-id-mgmt" [class.--isDeleting]="isDeletingMaxisSupport">
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <ng-container *ngIf="isDeletingMaxisSupport">
                                    <th scope="col">
                                        <label class="inline-input">
                                            <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedMaxisSupportGroups['all']" (ngModelChange)="onMaxisSupportAllSelectChange()">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </th>
                                </ng-container>
                                <th scope="col">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('Email')" (click)="sort('Email')">Username</button>
                                </th>
                                <th scope="col" width="25%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('FirstName')" (click)="sort('FirstName')">Name</button>
                                </th>
                                <th scope="col" width="20%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingState('Role')" (click)="sort('Role')">Role</button>
                                </th>
                                <th scope="col" width="20%">
                                    Action
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-container>
                                <tr *ngFor="let maxisUser of maxisSupportUserList">
                                    <ng-container *ngIf="isDeletingMaxisSupport">
                                        <td scope="col">
                                            <label class="inline-input">
                                                <input type="checkbox" [name]="maxisUser.userId" [value]="true" class="inline-input__input" [(ngModel)]="selectedMaxisSupport[maxisUser.userId]" (ngModelChange)="onMaxisSupportSelectChange($event)">

                                                <span class="inline-input__icon"></span>
                                            </label>
                                        </td>
                                    </ng-container>
                                    <td scope="col">
                                        <a class="link --primary" (click)="navigateToEditSupport(maxisUser)">
                                            {{maxisUser.email}}
                                        </a>
                                    </td>

                                    <td scope="col">
                                        {{maxisUser.firstName}} {{maxisUser.lastName}}
                                    </td>

                                    <td scope="col">
                                        {{maxisUser.roleLabel}}
                                    </td>
                                    <td scope="col">
                                        <a class="link --primary" (click)="setUserIdAndEmail(maxisUser.userId, maxisUser.email); showPopup(resetPasswordPopup);">
                                            Reset Password
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                        </div>
                    </div>
                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination" *ngIf="maxisSupportUserList.length > 0">
                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="getMaxisSupportUsers(pager.currentPage - 1)">
                                <span class="angle-arrow --left">
                                </span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="getMaxisSupportUsers(page)">{{
                                page }}</button>

                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="getMaxisSupportUsers(pager.currentPage + 1)">
                                <span class="angle-arrow --right">
                                </span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingMaxisSupport">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedMaxisSupport()}} maxis support(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteSupportPopup)">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteSupportPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedMaxisSupport()}}</span> maxis support
        </p>

        <p class="notes">
            <!-- *All maxis supports' access will be revoked. -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteMaxisSupportUsers();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #resetPasswordPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Reset?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to reset current account password
        </p>

        <p class="notes">
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="resetPassword();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>

<app-popup #successNotification="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Notification
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p [innerHtml]="emailSentPopupMsg"></p>

        <p class="notes">
            <!--Additional notes here-->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="hidePopup();">
                OK
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
