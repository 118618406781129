import { environment } from './../../environments/environment';

export const constants = {
    ALLOW_DRIVING_HOURS: {
        value: "ALLOW_DRIVING_HOURS",
        label: "Allow Driving Hour",
        imagePath: "assets/images/ng-components/site-header/icon-timeoftheday.svg",
        message: "Alert! <b>{0}</b> is driving outside allowed hours",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    DAILY_MILEAGE: {
        value: "DAILY_MILEAGE",
        label: "Daily Mileage",
        imagePath: "assets/images/ng-components/site-header/icon-mileage.svg",
        message: "<b>{0}</b> has exceeded allowed mileage",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    DRIVING_SUMMARY: {
        value: "DRIVING_SUMMARY",
        label: "Driving Summary",
        imagePath: "assets/images/ng-components/site-header/icon-disconnected.svg", ///test
        message: "",
    },
    DEVICE_DISCONNECTED: {
        value: "DEVICE_DISCONNECTED",
        label: "Device Disconnected",
        imagePath: "assets/images/ng-components/site-header/icon-disconnected.svg",
        message: "<b>{0}</b> - Diconnect device event detected",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    GEOFENCE: {
        value: "GEOFENCE",
        label: "Geofence",
        imagePath: "assets/images/ng-components/site-header/icon-geofence.svg",
        message: "<b>{0}</b> has {1} {2} at {3}",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    HARSH_ACCELERATION: {
        value: "HARSH_ACCELERATION",
        label: "Harsh Acceleration",
        imagePath: "assets/images/ng-components/site-header/icon-harshacceleration.svg",
        message: "<b>{0}</b> - Harsh acceleration event detected",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    HARSH_BRAKING: {
        value: "HARSH_BRAKING",
        label: "Harsh Braking",
        imagePath: "assets/images/ng-components/site-header/icon-harshbraking.svg",
        message: "<b>{0}</b> - Harsh braking event detected",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    HARSH_TURNING: {
        value: "HARSH_TURNING",
        label: "Harsh Turning",
        imagePath: "assets/images/ng-components/site-header/icon-harshturn.svg",
        message: "<b>{0}</b> - Harsh turning event detected",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    IDLING: {
        value: "IDLING",
        label: "Idling",
        imagePath: "assets/images/ng-components/site-header/icon-idling.svg",
        message: "<b>{0}</b> is idling",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    POST_TRIP: {
        value: "POST_TRIP",
        label: "Post Trip",
        imagePath: "assets/images/ng-components/site-header/icon-parked.svg", ///test,
        message: "<b>{0}</b> has parked",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    SPEEDING: {
        value: "SPEEDING",
        label: "Speeding",
        imagePath: "assets/images/ng-components/site-header/icon-speeding.svg",
        message: "<b>{0}</b> - Speeding event detected",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    TRIP_START: {
        value: "TRIP_START",
        label: "Trip Start",
        imagePath: "assets/images/ng-components/site-header/icon-moving.svg",
        message: "<b>{0}</b> has started driving",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    DRIVER_TAG: {
        value: "DRIVER_TAG",
        label: "Driver Tag",
        imagePath: "assets/images/ng-components/site-header/icon_idtag.svg",
        message: "<b>{0}</b> has not yet tagged ID tag.",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
    // VEHICLE_HEALTH_ALERTS: {
    //   value: "VEHICLE_HEALTH_ALERTS",
    //   label: "Vehicle Health Alerts",
    //   imagePath: "assets/images/ng-components/site-header/icon-vehicle-health.svg"
    // },
    VEHICLE_MAINTENANCE: {
        value: "VEHICLE_MAINTENANCE",
        label: "Vehicle Maintenance",
        imagePath: "assets/images/ng-components/site-header/icon-maintenance.svg",
        message: "<b>{0}</b> due for <b>{1}</b> maintenance soon",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})?view=vehicleStatus'
    },
    BATTERY: {
        value: "BATTERY",
        label: "Battery",
        imagePath: "assets/images/ng-components/site-header/icon-battery.svg",
        message: `Abnormal status detected on <b>{0}</b> battery! Please check vehicle before driving`,
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})?view=vehicleStatus'
        // urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/1000000000000005)?view=vehicleStatus'
    },
    TEMPERATURE: {
        value: "TEMPERATURE",
        label: "Temperature",
        imagePath: "assets/images/ng-components/site-header/icon-temperature.svg",
        message: "Abnormal engine temperature detected on <b>{0}</b>! Please check vehicle before driving",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})?view=vehicleStatus'
    },
    ENGINE: {
        value: "ENGINE",
        label: "Engine",
        imagePath: "assets/images/ng-components/site-header/icon-engine.svg",
        message: "Abnormal status detected on <b>{0}</b> engine! Please check vehicle before driving",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})?view=vehicleStatus'
    },

    UNEXPECTED_FUEL_DROP: {
        value: "UNEXPECTED_FUEL_DROP",
        label: "Fuel Drop",
        imagePath: "assets/images/ng-components/site-header/icon-fueldrop.svg",
        message: "<b>{0}</b> fuel level has dropped to low level",
        urlPath: "/" + environment.appConfig.page.mapUrlPath + "(leftbar:vehicle/{imeiNoOrId})?view=vehicleStatus"
    },
    LICENSE_EXPIRY: {
        value: "LICENSE_EXPIRY",
        label: "License Expiry",
        imagePath: "assets/images/ng-components/site-header/icon-carlicense.svg",
        message: {
            ROAD_TAX: "{1}(Road Tax) for <b>{0}</b> due to expire on {2}",
            PUSPAKOM: "{1}(Puspakom) for <b>{0}</b> due to expire on {2}",
            DRIVING_LICENSE: "{1}(Driving License) for <b>{0}</b> due to expire on {2}"
        },
        urlPath: {
            ROAD_TAX: '/create-manage/vehicles/edit/{imeiNoOrId}',
            PUSPAKOM: '/create-manage/vehicles/edit/{imeiNoOrId}',
            DRIVING_LICENSE: '/create-manage/drivers/edit/{imeiNoOrId}'
        }
    },
    PANIC_BUTTON: {
        value: "PANIC_BUTTON",
        label: "Panic Button",
        imagePath: "assets/images/ng-components/site-header/icon-panicbutton.svg",
        message: "<b>{0}</b> has pressed panic button",
        urlPath: '/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/{imeiNoOrId})'
    },
};

export function getNotificationMessageAndImagePath(notificationType, ...param) {
    let message = notificationType === 'LICENSE_EXPIRY' ? constants[notificationType].message[param[3]] : constants[notificationType].message;
    const imagePath = constants[notificationType].imagePath;
    for (let i = 0; i < param.length; i++) {
        message = message.replace(`{${i}}`, param[i]);
    }
    return { message: message, imagePath: imagePath };
}

export function getNotificationUrlPath(notificationType, vehicleImeiOrId, licenseType = 'DRIVING_LICENSE') {
    let urlPath = notificationType === 'LICENSE_EXPIRY' ? constants[notificationType].urlPath[licenseType] : constants[notificationType].urlPath;
    urlPath = urlPath.replace(`{imeiNoOrId}`, vehicleImeiOrId);
    return urlPath;
}

