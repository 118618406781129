import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as LocalStorageUtil from './../../../util/localStorageUtil';
import { RouterProxyService } from './../../../_services/router-proxy/router-proxy.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, AfterViewInit {

    constructor(
        private routerProxyService: RouterProxyService
    ) { }

    ngOnInit() {
    }

    async ngAfterViewInit() {
        await this.falseNavigationOnLoginPage();
    }

    // Refresh page with will have few APIs call simultenously, when token is invalid will redirect to login.
    // However thoses simultenous API will return and navigate to error page
    async falseNavigationOnLoginPage() {
        const userToken = await LocalStorageUtil.localStorageGet('currentUser');
        if (!userToken) {
            this.routerProxyService.navigateToLoginPage();
        }
    }
}
