import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';

// Component
import { SpinnerComponent } from './../../../common/spinner/spinner.component';
import { PopupService } from './../../../../components/common/popup/popup.service';
import { VehicleSelectorComponent } from './../../vehicles/vehicle-selector/vehicle-selector.component';

// Service
import { SnackBarService } from './../../../../_services/snackBar/snack-bar.service';
import { VehicleService } from './../../../../_services/vehicle/vehicle.service';
import { RouterProxyService } from './../../../../_services/router-proxy/router-proxy.service';
import { PopupCampaignService } from './../../../../_services/campaign/popup-campaign.service';
import { UserService } from './../../../../_services/user/user.service';

// Util
import * as DateTimeUtil from '../../../../util/dateTimeUtil';
import * as DomUtil from '../../../../util/domUtil';
import * as AsyncUtil from '../../../../util/asyncUtil';
import * as StringUtil from './../../../../util/stringUtil';
import * as LocalStorageUtil from '../../../../util/localStorageUtil';
import * as MapUtil from '../../../../util/mapUtil';

// Constant
import * as Message from '../../../../constants/message';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../constants/responseStatusCode';
import { ERROR_MESSAGE as ErrorMessage } from './../../../../constants/errorMessage';
import { overlayConstant } from '../../../../constants/overlay-image-map.constant';

declare var google: any;

@Component({
    selector: 'app-edit-geofence',
    templateUrl: './edit-geofence.component.html',
    styleUrls: ['./edit-geofence.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class EditGeofenceComponent implements OnInit, OnDestroy {

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private vehicleService: VehicleService,
        private snackBar: SnackBarService,
        private popupService: PopupService,
        private route: ActivatedRoute,
        private routerProxyService: RouterProxyService,
        private el: ElementRef,
        private userService: UserService,
        private popupCampaignService: PopupCampaignService

    ) { }

    // Geofence Form
    @ViewChild('vehicleAssign',{static:false}) vehicleAssign: VehicleSelectorComponent;
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    // private map: any;
    // private drawingManager: any;
    // private loadAPI: Promise<any>;

    message = Message;

    MY_LOCATION_LAT: number = 3.158; //Menara Maxis
    MY_LOCATION_LNG: number = 101.713; //Menara Maxis
    // mapScriptLoaded = false;
    mapIsReady = false;
    mapContent: any;
    searchInput: any;
    geofenceId: number;
    geofenceDBDetails: any;

    //Geofence Info
    geofenceName: string;

    alertTimeFrom: string;
    alertTimeTo: string;
    allowedTimeFromHour = '';
    allowedTimeFromMinute = '';
    allowedTimeFromAMPM = 'AM';
    allowedTimeToHour = '';
    allowedTimeToMinute = '';
    allowedTimeToAMPM = 'AM';

    alertTypes = [
        { name: 'Entry', value: 'Entry', checked: false },
        { name: 'Exit', value: 'Exit', checked: false }
    ];

    alertDays = [
        { name: 'Sun', value: '0', checked: false },
        { name: 'Mon', value: '1', checked: false },
        { name: 'Tue', value: '2', checked: false },
        { name: 'Wed', value: '3', checked: false },
        { name: 'Thu', value: '4', checked: false },
        { name: 'Fri', value: '5', checked: false },
        { name: 'Sat', value: '6', checked: false }
    ];

    timeSelector: {
        hour: string[],
        fullHour: string[],
        minute: string[],
        second: string[]
    } = DateTimeUtil.generateHourMinuteSecond(); // time dropdown array

    //Geofence Area
    geofenceType: string;
    geofencePath: string;
    geofenceRadius: number;
    dbGeoFencePathList = [];
    pathCoords = [];

    //Vehicle List
    selectedVehiclesList: Array<number> = null;

    // Geofence Type
    polygon: string = 'polygon';
    circle: string = 'circle';
    selectedGeofenceType: string;

    alertTypeSelectedOptions() {
        return this.alertTypes
            .filter(opt => opt.checked)
            .map(opt => opt.value);
    }

    alertDaySelectedOptions() {
        return this.alertDays
            .filter(opt => opt.checked)
            .map(opt => opt.value);
    }

    async ngOnInit() {
        this.geofenceId = this.route.snapshot.params.id;
        this.mapContent = document.getElementById('map');
        this.searchInput = document.getElementById('searchInput');
        await this.loadScriptFirst();
        DomUtil.autoFocusForm(this.el);

        await this.checkUnviewedCampaign();

    }

    async ngOnDestroy() {
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    async getVehicleGeofenceDetail() {

        this.pageSpinner.show();
        try {
            const geofenceResult = await this.vehicleService.getVehicleGeofenceDetail(this.geofenceId);

            //assign values
            this.geofenceName = geofenceResult.body.result.Name;
            this.geofenceType = geofenceResult.body.result.Type === 'P' ? this.polygon : this.circle;
            this.selectedGeofenceType = this.geofenceType;
            this.geofenceRadius = geofenceResult.body.result.GeofenceRadius;
            this.alertTypes[0].checked = geofenceResult.body.result.AlertOnEntry;
            this.alertTypes[1].checked = geofenceResult.body.result.AlertOnExit;
            const daysOfWeek = geofenceResult.body.result.DaysOfWeek.split(',');

            const self = this;
            daysOfWeek.forEach(function (day) {

                switch (day) {
                    case '0': self.alertDays[0].checked = true; break;
                    case '1': self.alertDays[1].checked = true; break;
                    case '2': self.alertDays[2].checked = true; break;
                    case '3': self.alertDays[3].checked = true; break;
                    case '4': self.alertDays[4].checked = true; break;
                    case '5': self.alertDays[5].checked = true; break;
                    case '6': self.alertDays[6].checked = true; break;
                    default: break;
                }
            });

            this.allowedTimeFromHour = geofenceResult.body.result.TimeFrom !== null ? moment(geofenceResult.body.result.TimeFrom, "HH:mm:ss").format("hh") : 'HH';
            this.allowedTimeFromMinute = geofenceResult.body.result.TimeFrom !== null ? moment(geofenceResult.body.result.TimeFrom, "HH:mm:ss").format("mm") : 'MM';
            this.allowedTimeFromAMPM = geofenceResult.body.result.TimeFrom !== null ? moment(geofenceResult.body.result.TimeFrom, "HH:mm:ss").format("A") : 'AM';
            this.allowedTimeToHour = geofenceResult.body.result.TimeTo !== null ? moment(geofenceResult.body.result.TimeTo, "HH:mm:ss").format("hh") : 'HH';
            this.allowedTimeToMinute = geofenceResult.body.result.TimeTo !== null ? moment(geofenceResult.body.result.TimeTo, "HH:mm:ss").format("mm") : 'MM';
            this.allowedTimeToAMPM = geofenceResult.body.result.TimeTo !== null ? moment(geofenceResult.body.result.TimeTo, "HH:mm:ss").format("A") : 'AM';

            this.geofencePath = geofenceResult.body.result.Path;
            if (this.geofencePath) {
                if (this.geofenceType === this.polygon) {
                    this.dbGeoFencePathList = this.geofencePath.split(';');
                    this.dbGeoFencePathList.forEach(function (dbGeoFencePath) {
                        if (dbGeoFencePath) {
                            const latLng = dbGeoFencePath.split(',');
                            self.MY_LOCATION_LAT = latLng[0];
                            self.MY_LOCATION_LNG = latLng[1];
                            self.pathCoords.push(new google.maps.LatLng(latLng[0], latLng[1]));
                        }
                    });
                } else {
                    const latLng = this.geofencePath.split(',');
                    this.pathCoords.push(new google.maps.LatLng(latLng[0], latLng[1]));
                }
            }

            //this.vehicleAssign.setSelectedVehicleIds(geofenceResult.body.result.VehicleIds);
            this.selectedVehiclesList = geofenceResult.body.result.VehicleIds;

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    async loadScriptFirst() {
        const GOOGLE_MAP_API_KEY = await DomUtil.getGoogleAPIKeyByPlatform(this.platform);
        if (!DomUtil.hasGoogleMapDrawingScript(GOOGLE_MAP_API_KEY)) {

            DomUtil.addGoogleMapScript({
                libraries: ['drawing', 'places'],
                onload: this.finishScriptLoad.bind(this)
            }, GOOGLE_MAP_API_KEY);

        } else {
            console.debug('EditGeofenceComponent: (Geofence)Skip adding Google Map Script Tag ...');
            if (DomUtil.isGoogleNotDefined()) {
                await AsyncUtil.wait(300); // wait for google object ready
            }
            await this.finishScriptLoad();
        }
    }

    async finishScriptLoad() {
        await this.getVehicleGeofenceDetail();
        await this.initMap();
    }

    chooseGeofenceType(geofenceType) {
        if (this.selectedGeofenceType !== geofenceType) {
            this.selectedGeofenceType = geofenceType;
            this.initMap();
        }
    }

    async initMap() {

        if (!DomUtil.isGoogleNotDefined()) {
            this.geofencePath = '';
            const self = this;
            const mapZoom = 17;
            let currentOverlay;

            // Set Map Zoom Level
            google.maps.Polygon.prototype.getBounds = function () {
                const bounds = new google.maps.LatLngBounds();
                this.getPath().forEach(function (element, index) { bounds.extend(element); });
                return bounds;
            };

            const map = new google.maps.Map(this.mapContent, {
                center: { lat: + this.MY_LOCATION_LAT, lng: + this.MY_LOCATION_LNG },
                gestureHandling: 'greedy',
                zoom: mapZoom
            });

            const drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: this.selectedGeofenceType,
                drawingControl: false,
                [`${this.selectedGeofenceType}Options`]: {
                    fillColor: '#FFC0CB',
                    fillOpacity: 0.5,
                    strokeWeight: 3,
                    strokeColor: '#DC143C',
                    clickable: true,
                    editable: true,
                    draggable: true,
                    zIndex: 1
                }
            });

            let areaType;

            // Setting for Polygon / Circle
            const defaultSetting = {
                clickable: true,
                editable: true,
                draggable: true,
                strokeColor: '#DC143C',
                strokeWeight: 3,
                fillColor: '#FFC0CB',
                fillOpacity: 0.5
            };

            //Construct Polygon
            if (this.selectedGeofenceType === this.polygon) {
                if (this.geofenceType === this.polygon) {
                    areaType = new google.maps.Polygon(Object.assign(defaultSetting, { paths: this.pathCoords }));
                    /* Here Causing Geofence Edit dup twice for the path lat lng */
                    // this.pathCoords.forEach(function (latlng) {
                    //     if (self.geofencePath) {
                    //         self.geofencePath += ';';
                    //     }
                    //     self.geofencePath += latlng.lat() + ',' + latlng.lng();
                    // });
                }

                //Construct Circle
            } else {
                if (this.geofenceType === this.circle) {
                    areaType = new google.maps.Circle(Object.assign(defaultSetting, {
                        center: this.pathCoords[0],
                        radius: this.geofenceRadius
                    }));
                    self.geofencePath = this.pathCoords[0].lat() + ',' + this.pathCoords[0].lng();
                }
            }

            if (areaType) {
                areaType.setMap(map);
                if (areaType.getBounds()) {
                    const centerPoints = areaType.getBounds().getCenter();
                    map.setCenter(new google.maps.LatLng(centerPoints.lat(), centerPoints.lng()));
                    map.fitBounds(areaType.getBounds());
                }

                // Set Area Click Function
                google.maps.event.addListener(areaType, 'click', function () {
                    areaType.setMap(null);
                    drawingManager.setDrawingMode(self.selectedGeofenceType);
                });
                // add edit function after geofence loaded
                this.editGeofenceArea(areaType, this.geofenceType);

                // Disable drawing for first loaded geofence
                drawingManager.setDrawingMode(null);
            }
            drawingManager.setMap(map);

            google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
                self.geofencePath = '';
                drawingManager.setDrawingMode(null);
                currentOverlay = event.overlay;

                // Remove the Overlay
                currentOverlay.addListener('click', function (e) {
                    e.stop();
                    self.geofencePath = '';
                    if (typeof event.path === 'undefined') { // ignore clicks on polygon paths
                        drawingManager.setDrawingMode(self.selectedGeofenceType);
                        currentOverlay.setMap(null);
                        google.maps.event.clearInstanceListeners(currentOverlay);
                        currentOverlay = null;
                    }
                });
                // add edit function after geofence drawn
                self.editGeofenceArea(currentOverlay, event.type);
            });

            // Create the search box and link it to the UI element.
            const searchBox = new google.maps.places.SearchBox(this.searchInput);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', function () {
                searchBox.setBounds(map.getBounds());
            });

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', function () {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();
                places.forEach(function (place) {
                    if (!place.geometry) {
                        console.debug("Returned place contains no geometry");
                        return;
                    }

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
                drawingManager.setMap(map);

                if (currentOverlay) {
                    currentOverlay.setMap(null);
                    currentOverlay = null;
                }
                if (areaType) { areaType.setMap(null); }
                self.geofencePath = '';
                drawingManager.setDrawingMode(self.selectedGeofenceType);
            });

            const result = await this.userService.getCompanyIdByName('Gamuda Cove');
            if (result && result.body.length > 0) {
                const currentUserCompanyId = LocalStorageUtil.localStorageGet('currentUser').companyId;
                if (result.body[0].id === currentUserCompanyId) {
                    MapUtil.generateOverlayImg(overlayConstant.imageLoc, overlayConstant.imagePath, map);
                }
            }

        } else {
            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.MAP_LOAD_FAILED);
            this.snackBar.openGenericSnackBar(msg);
        }
    }

    editGeofenceArea(event, type) {
        const _this = this;
        if (type === this.polygon) {
            // (Polygon) For Edit / Drag Area
            google.maps.event.addListener(event.getPath(), 'insert_at', function () {
                _this.geofencePath = '';
                event.getPath().getArray().forEach(function (latlng) {
                    if (_this.geofencePath) {
                        _this.geofencePath += ';';
                    }
                    _this.geofencePath += latlng.lat() + ',' + latlng.lng();
                });
            });
            google.maps.event.addListener(event.getPath(), 'set_at', function () {
                _this.geofencePath = '';
                event.getPath().getArray().forEach(function (latlng) {
                    if (_this.geofencePath) {
                        _this.geofencePath += ';';
                    }
                    _this.geofencePath += latlng.lat() + ',' + latlng.lng();
                });
            });
            // Run One Time After Finish Drawing
            event.getPath().getArray().forEach(function (latlng) {
                if (_this.geofencePath) {
                    _this.geofencePath += ';';
                }
                _this.geofencePath += latlng.lat() + ',' + latlng.lng();
            });
        } else {
            // (Circle) For Edit / Drag Area
            google.maps.event.addListener(event, 'dragend', function () {
                _this.geofencePath = event.center.lat() + ',' + event.center.lng();
            });
            google.maps.event.addListener(event, 'radius_changed', function () {
                _this.geofenceRadius = Math.round(event.radius);
            });
            // Run One Time After Finish Drawing
            _this.geofencePath = event.center.lat() + ',' + event.center.lng();
            _this.geofenceRadius = Math.round(event.radius);
        }
    }

    async updateGeofenceInfo() {
        console.debug("Updating Geofence Info");
        this.pageSpinner.show();
        try {

            let alertDays = '';
            this.alertDaySelectedOptions().forEach(function (day) {

                switch (day) {
                    case '0': alertDays += '0,'; break;
                    case '1': alertDays += '1,'; break;
                    case '2': alertDays += '2,'; break;
                    case '3': alertDays += '3,'; break;
                    case '4': alertDays += '4,'; break;
                    case '5': alertDays += '5,'; break;
                    case '6': alertDays += '6,'; break;
                    default: break;
                }
            });
            alertDays = alertDays.replace(/.$/, "");

            let alertTimeFromT = `${this.allowedTimeFromHour}:${this.allowedTimeFromMinute} ${this.allowedTimeFromAMPM}`;
            let alertTimeToT = `${this.allowedTimeToHour}:${this.allowedTimeToMinute} ${this.allowedTimeToAMPM}`;
            if (this.allowedTimeFromHour == "HH") {
                alertTimeFromT = '';
            } else if (this.allowedTimeFromMinute == "MM") {
                alertTimeFromT = '';
            }
            if (this.allowedTimeToHour == "HH") {
                alertTimeToT = '';
            } else if (this.allowedTimeToMinute == "MM") {
                alertTimeToT = '';
            }

            this.alertTimeFrom = alertTimeFromT.length != 0 ? moment(alertTimeFromT, "HH:mm A").format("HH:mm") : "";
            this.alertTimeTo = alertTimeToT.length != 0 ? moment(alertTimeToT, "HH:mm A").format("HH:mm") : "";

            this.selectedVehiclesList = this.vehicleAssign.getSelectedVehicleIds()/* [] */;

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.geofenceName)) {
                this.geofenceName = this.geofenceName.trim();
            }

            const result = await this.vehicleService.editGeofence(this.geofenceId, this.geofenceName, this.alertTypes[0].checked, this.alertTypes[1].checked, alertDays, this.alertTimeFrom, this.alertTimeTo, this.geofencePath, this.selectedGeofenceType === this.polygon ? 'P' : 'C', this.selectedGeofenceType === this.polygon ? null : this.geofenceRadius, this.selectedVehiclesList);

            let msg = Message.getMessage(Message.MESSAGE.UPDATE_FAILED.value, 'Geofence', this.geofenceName);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.UPDATE_SUCCESS.value, 'Geofence', this.geofenceName);
                this.navigateSuccessToDrivingLimitView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    navigateSuccessToDrivingLimitView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/create-manage/driving-limits/view'], null, 'geofence');
    }

    navigateToDrivingLimitView() {
        this.routerProxyService.navigate(['/create-manage/driving-limits/view']);
    }

    /* ---- popup table ---- */

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }
}
