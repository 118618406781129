import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as USER_ROLE from '../../../../../constants/userRole';
import * as Message from '../../../../../constants/message';
import * as Domutil from '../../../../../util/domUtil';
import * as StringUtil from '../../../../../util/stringUtil';
import { ActivatedRoute } from '@angular/router';
import { SpinnerComponent } from '../../../../common/spinner/spinner.component';
import { SupportService } from '../../../../../_services/support/support.service';
import { PopupService } from '../../../../../components/common/popup/popup.service';
import { SnackBarService } from '../../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../../_services/router-proxy/router-proxy.service';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../../constants/responseStatusCode';
import { PopupCampaignService } from './../../../../../_services/campaign/popup-campaign.service';

@Component({
    selector: 'app-edit-support',
    templateUrl: './edit-support.component.html',
    styleUrls: ['./edit-support.component.scss']
})
export class EditSupportComponent implements OnInit {

    maxisSupportUser: any;
    userRole: any = USER_ROLE;
    userRoleALabel: string = USER_ROLE.getUserRoleLabel('MAXIS_ADMIN_LEVEL_A');
    userRoleBLabel: string = USER_ROLE.getUserRoleLabel('MAXIS_ADMIN_LEVEL_B');
    userRoleCLabel: string = USER_ROLE.getUserRoleLabel('MAXIS_ADMIN_LEVEL_C');
    userRoleSalesDemoLabel: string = USER_ROLE.getUserRoleLabel('MAXIS_SALES_DEMO');
    message: any = Message;

    maxisSupportUserUpdatedDetails: any = {
        userId: 0,
        name: '',
        email: '',
        userRole: ''
    };

    selectedRoleLabel: any = 'Maxis Support A';

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private route: ActivatedRoute,
        private supportService: SupportService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private snackBar: SnackBarService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    ngOnInit() {
        this.maxisSupportUser = {
            userId: this.route.snapshot.params.userId,
            name: this.route.snapshot.params.firstName + ' ' + this.route.snapshot.params.lastName,
            email: this.route.snapshot.params.email,
            userRole: this.route.snapshot.params.userRole
        };
        // this.selectedRoleLabel = this.userRole.getUserRoleLabel(this.maxisSupportUser.userRole);
        for (const objKey in this.maxisSupportUser) {
            if (objKey == 'userRole') {
                this.getSelectedRole(this.userRole.getUserRoleLabel(this.maxisSupportUser.userRole));
            } else {
                this.maxisSupportUserUpdatedDetails[objKey] = this.maxisSupportUser[objKey];
            }
        }
        Domutil.autoFocusForm(this.el);
        this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    getSelectedRole(role) {
        this.maxisSupportUserUpdatedDetails.userRole = this.userRole.getUserRoleFromLabel(role);
        this.selectedRoleLabel = role;
    }

    async updateMaxisSupport() {
        this.pageSpinner.show();

        try {
            const updateMaxisSupportUser = {};
            for (const objKey in this.maxisSupportUserUpdatedDetails) {
                if (objKey === "userRole") {
                    Object.assign(updateMaxisSupportUser, { userRole: this.maxisSupportUserUpdatedDetails[objKey] });
                } else if (objKey === "userId") {
                    Object.assign(updateMaxisSupportUser, { userId: this.maxisSupportUserUpdatedDetails[objKey] });
                } else {

                    // trim leading & trailing whitespace for names
                    if (objKey == 'name') {
                        if (StringUtil.isNotEmptyOrNull(this.maxisSupportUserUpdatedDetails[objKey])) {
                            this.maxisSupportUserUpdatedDetails[objKey] = this.maxisSupportUserUpdatedDetails[objKey].trim();
                        }
                    }

                    if (this.maxisSupportUserUpdatedDetails[objKey] !== this.maxisSupportUser[objKey]) {
                        Object.assign(updateMaxisSupportUser, { [objKey]: this.maxisSupportUserUpdatedDetails[objKey] });
                    }
                }
            }
            //ensure email is not undefined
            if (typeof updateMaxisSupportUser['email'] === "undefined") {
                Object.assign(updateMaxisSupportUser, {'email': this.maxisSupportUser.email});
            }

            const result = await this.supportService.updateMaxisSupportUser(updateMaxisSupportUser);

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, this.selectedRoleLabel, this.maxisSupportUserUpdatedDetails.name);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, this.selectedRoleLabel, this.maxisSupportUserUpdatedDetails.name);
                this.navigateSuccessToMaxisSupportView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (err) {
            this.snackBar.openStandardizedErrorSnackBar(err);
        } finally {
            this.pageSpinner.hide();
        }
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }

    hidePopup() {
        this.popupService.hide();
    }

    navigateSuccessToMaxisSupportView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/support/manage/admins']);
    }

    navigateToMaxisSupportView() {
        this.routerProxyService.navigate(['/support/manage/admins']);
    }
}
