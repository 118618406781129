<app-spinner #site_spinner [fullscreen]="true" [aboveSiteHeader]="true"></app-spinner>
<site-header *ngIf="isLoggedIn"></site-header>

<span *ngIf="showDebugBox" class="debugMsg">{{debugMessage}}
    <input type="button" [(ngModel)]="debugHideBtn" (click)="showDebugInfo()">
</span>

<div *ngIf="isLoggedIn">
    <router-outlet *ngIf="isShowLeftbar" name="leftbar" style="z-index:5"></router-outlet>
</div>
<div>
    <router-outlet></router-outlet>
</div>

<site-info *ngIf="isLoggedIn"></site-info>

<app-force-update #updatePopup class="logout-popup"></app-force-update>
