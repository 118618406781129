import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as StringUtil from '../../util/stringUtil';

@Injectable({
    providedIn: 'root'
})
export class CustomizeDeviceService {

    constructor(private http: HttpClient) { }
    
    async getAllCustomizeDevice(sortField = null, sortAscending = null, excludeDeactivated = true) {
        const path = environment.mtntApiEndpoint + "/vehicles/customize-device";
        const paramObj: any = {};
        if (StringUtil.isNotEmptyOrNull(sortField)) {
            paramObj.sortField = sortField;
        }
        if (typeof (sortAscending) == typeof (true)) {
            paramObj.sortAscending = sortAscending;
        }
        paramObj.excluDeact = excludeDeactivated;

        const result: any = await this.http.get(path, { params: paramObj }).toPromise();
        return result;
    }
}
