import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { Chart } from 'chart.js';
import * as html2canvas from 'html2canvas';
import { Platform } from '@ionic/angular';
import { jsPDF } from 'jspdf';
import { environment } from './../../../../../../environments/environment';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

//Utils
import * as ObjectUtil from '../../../../../util/objectUtil';
import * as moment from 'moment';
import * as DateTimeUtil from './../../../../../util/dateTimeUtil';
import * as NumberUtil from './../../../../../util/numberUtil';
import * as StringUtil from './../../../../../util/stringUtil';
import * as AsyncUtil from '../../../../../util/asyncUtil';
import * as DomUtil from './../../../../../util/domUtil';

// Services
import { SpinnerComponent } from './../../../../common/spinner/spinner.component';
import { SnackBarService } from './../../../../../_services/snackBar/snack-bar.service';
import { DriverService } from './../../../../../_services/driver/driver.service';
import { PerformanceCharts, PerformanceChartChildObj } from './PerformanceCharts';

//Constants
import { DashboardColors as Colors } from './../../../dashboard/DashboardColors';
const CHART_TICK_LABEL_MAX_LENGTH: number = 5; // chartJS truncate: axis tick label
const CHART_TOOLTIP_MAX_LENGTH: number = 22; // chartJS truncate: tooltip title
import { ERROR_MESSAGE as ErrorMessage } from '../../../../../constants/errorMessage';


const PDF_FILE_NAME_PREFIX: string = environment.appConfig.performance.pdfFileName;

@Component({
    selector: 'app-performance',
    templateUrl: './performance.component.html',
    styleUrls: ['./performance.component.scss'],
    animations: [
        trigger('fadeToggle', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.4s cubic-bezier(0.19, 1, 0.22, 1)', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class PerformanceComponent implements OnInit, AfterViewInit {

    constructor(
        private snackBar: SnackBarService,
        private driverService: DriverService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private platform: Platform,
        private file: File,
        private fileOpener: FileOpener
    ) {
        this.perfCharts = new PerformanceCharts(this.ref);
    }

    driverId: number;

    Moment = moment;
    //Page States
    todayDateMoment: moment.Moment = moment();
    todayDate: string = this.todayDateMoment.format('YYYY-MM-DD');
    isDownloadPdfClick: Boolean = false;    // true while downloading in progress

    // CHARTS states
    chartJsTooltipTruncateCallbackTitleFn: any;
    perfCharts: PerformanceCharts; //chart main class
    padding: number = 0;
    stepSize: number = 20;
    // genColorfulHorizontalBarChart
    colourList: string[];
    chartJsBarHoverInteraction: any;
    chartJsTooltipStyles: any;
    chartJsHoverProps: any;
    chartJsTicksFontStyles: any;
    chartJsTicksStringEllipsisCallbackFn: any;
    chartJsTicksNumberTruncateCallbackFn: any;
    chartJsAnimation: any;
    //overrideChartJsOptions
    chartJsScaleLabelFontStyles: any;

    // CHART: Scoring
    pastScoreNoOfWeeks: number = 6;
    scoringStatsNoOfWeeks: number = 2;
    scoringDriverLabelCssClass: string = '';
    scoringDriverLabelThisWeek: number = 0;
    scoringDriverLabelLastWeek: number = 0;
    scoringDriverLabelDiff: number = 0;
    scoringDriverLabelStars: { count: number, color: string, desc: string } = {
        count: 0,
        color: 'black',
        desc: null
    };

    // CHART: Working Hour
    driverPerformanceNoOfWeeks: number = 2;
    pastDriverPerformanceNoOfWeeks: number = 6;
    driverPerformanceCssClass: string = '';
    driverPerformanceThisWeek: number = 0;
    driverPerformanceLastWeek: number = 0;
    driverPerformanceDiff: number = 0;
    // formattedWeek: any;

    // CHART: Weekly Violation Stats
    violationTypes = ['HarshBrake', 'HarshAcceleration', 'HarshTurn', 'Speeding', 'RestrictedDrivingHour', 'Mileage'];
    weeklyViolationStatsNoOfWeeks: number = 2;
    classViolations: any = {};
    lastweekVioHBLabel: number = 0;
    lastweekVioHALabel: number = 0;
    lastweekVioHCLabel: number = 0;
    lastweekVioSpeedingLabel: number = 0;
    lastweekVioDriveTimeLabel: number = 0;
    lastweekVioMileageLabel: number = 0;
    weekVioHBLabel: number = 0;
    weekVioHALabel: number = 0;
    weekVioHCLabel: number = 0;
    weekVioSpeedingLabel: number = 0;
    weekVioDriveTimeLabel: number = 0;
    weekVioMileageLabel: number = 0;
    diffVioHBLabel: number = 0;
    diffVioHALabel: number = 0;
    diffVioHCLabel: number = 0;
    diffVioSpeedingLabel: number = 0;
    diffVioDriveTimeLabel: number = 0;
    diffVioMileageLabel: number = 0;

    dummyArrayThree: number[] = ObjectUtil.fillRange(3);

    //Angular ElementRefs
    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;
    @ViewChild("weeklyScoring_spinner",{static:false}) weeklyScoring_spinner: SpinnerComponent;
    @ViewChild("weeklyWorkingHour_spinner",{static:false}) weeklyWorkingHour_spinner: SpinnerComponent;
    @ViewChild("weeklyViolationCount_spinner",{static:false}) weeklyViolationCount_spinner: SpinnerComponent;
    @ViewChild("pastScore_spinner",{static:false}) pastScore_spinner: SpinnerComponent;
    @ViewChild("pastWorkingHour_spinner",{static:false}) pastWorkingHour_spinner: SpinnerComponent;

    ngOnInit() {
        this.driverId = this.route.snapshot.params.id;
        this.initChartJsDefaults();
    }

    async ngAfterViewInit() {
        await this.getPerformanceInfo();
    }

    async getPerformanceInfo() {

        await Promise.all([
            //top row - working hour and score
            this.getScoringStats(),
            this.getDriverWorkingHours(),
            //middle row - graph
            this.getPastScoreStats(),
            this.getPastWorkingHours(),
            // bottom row - violations
            this.getDriverViolations(),

        ]);

    }

    async getScoringStats() {

        await Promise.all([
            this.getDriverScoringStats(),

        ]);
    }
    private async getDriverScoringStats() {
        try {
            this.weeklyScoring_spinner.show();

            // this.todayDateMoment = moment(this.todayDate1);
            const lastWeekDate: moment.Moment = moment(this.todayDateMoment).subtract(1, "weeks");
            const yearAndWeekNo: any = DateTimeUtil.fromDateToYearAndWeekNo(lastWeekDate);
            const weekNo: number = yearAndWeekNo.weekNo;
            const year: number = yearAndWeekNo.year;

            let apiStats: any = {};
            apiStats = await this.driverService.getDriverScoring(this.driverId, weekNo, year, this.scoringStatsNoOfWeeks);
            if (apiStats && apiStats.body) {
                const stats = apiStats.body;

                // Weekly Average Score
                this.scoringDriverLabelThisWeek = NumberUtil.roundOff(stats.weeks[0].AverageScore, 1) || 0;
                this.scoringDriverLabelLastWeek = NumberUtil.roundOff(stats.weeks[1].AverageScore, 1) || 0;
                this.scoringDriverLabelDiff = NumberUtil.roundOff(Math.abs(this.scoringDriverLabelLastWeek - this.scoringDriverLabelThisWeek), 1);
                this.scoringDriverLabelCssClass = this.getCssClassUpDownIndicator(this.scoringDriverLabelLastWeek, this.scoringDriverLabelThisWeek, true);

                // stars rating
                this.scoringDriverLabelStars = {
                    ...this.countRatingScores(this.scoringDriverLabelThisWeek),
                    desc: null
                };
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.weeklyScoring_spinner.hide();
        }
    }

    private async getDriverWorkingHours() {
        try {
            this.weeklyWorkingHour_spinner.show();
            const lastWeekDate: moment.Moment = moment(this.todayDateMoment).subtract(1, "weeks");
            const formatDate: string = lastWeekDate.format('YYYY-MM-DD');
            let apiStats: any = {};
            apiStats = await this.driverService.getDriverPerformance(this.driverId, this.driverPerformanceNoOfWeeks, formatDate);
            if (apiStats && apiStats.body) {
                const stats = apiStats.body;

                // Weekly Average Score
                this.driverPerformanceThisWeek = NumberUtil.roundOff(stats.week[0].WorkingHour, 1) || 0;
                this.driverPerformanceLastWeek = NumberUtil.roundOff(stats.week[1].WorkingHour, 1) || 0;
                this.driverPerformanceDiff = NumberUtil.roundOff(Math.abs(this.driverPerformanceLastWeek - this.driverPerformanceThisWeek), 1);
                this.driverPerformanceCssClass = this.getCssClassUpDownIndicator(this.driverPerformanceLastWeek, this.driverPerformanceThisWeek, true);

            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.weeklyWorkingHour_spinner.hide();
        }
    }

    private async getDriverViolations() {
        try {

            this.weeklyViolationCount_spinner.show();

            let apiStats: any = {};
            apiStats = await this.driverService.getDriverPerformance(this.driverId, this.weeklyViolationStatsNoOfWeeks, this.todayDate);
            if (apiStats && apiStats.body) {
                const weekViolationStats = apiStats.body;

                // parse some values to reduce function data bindings
                this.weekVioHBLabel = weekViolationStats.week[0].HarshBrake || 0;
                this.weekVioHALabel = weekViolationStats.week[0].HarshAcceleration || 0;
                this.weekVioHCLabel = weekViolationStats.week[0].HarshTurn || 0;
                this.weekVioSpeedingLabel = weekViolationStats.week[0].Speeding || 0;
                this.weekVioDriveTimeLabel = weekViolationStats.week[0].RestrictedDrivingHour || 0;
                this.weekVioMileageLabel = weekViolationStats.week[0].Mileage || 0;

                this.lastweekVioHBLabel = weekViolationStats.week[1].HarshBrake || 0;
                this.lastweekVioHALabel = weekViolationStats.week[1].HarshAcceleration || 0;
                this.lastweekVioHCLabel = weekViolationStats.week[1].HarshTurn || 0;
                this.lastweekVioSpeedingLabel = weekViolationStats.week[1].Speeding || 0;
                this.lastweekVioDriveTimeLabel = weekViolationStats.week[1].RestrictedDrivingHour || 0;
                this.lastweekVioMileageLabel = weekViolationStats.week[1].Mileage || 0;

                // diff violation stats
                this.diffVioHBLabel = Math.abs(this.weekVioHBLabel - this.lastweekVioHBLabel);
                this.diffVioHALabel = Math.abs(this.weekVioHALabel - this.lastweekVioHALabel);
                this.diffVioHCLabel = Math.abs(this.weekVioHCLabel - this.lastweekVioHCLabel);
                this.diffVioSpeedingLabel = Math.abs(this.weekVioSpeedingLabel - this.lastweekVioSpeedingLabel);
                this.diffVioDriveTimeLabel = Math.abs(this.weekVioDriveTimeLabel - this.lastweekVioDriveTimeLabel);
                this.diffVioMileageLabel = Math.abs(this.weekVioMileageLabel - this.lastweekVioMileageLabel);

                this.violationTypes.forEach(violation => {
                    const thisWkVal = weekViolationStats.week[0][violation];
                    const lasyWkVal = weekViolationStats.week[1][violation];
                    this.classViolations[violation] = this.getCssClassUpDownIndicator(lasyWkVal, thisWkVal, true, false);
                });
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.weeklyViolationCount_spinner.hide();
        }
    }

    /* ---- Charts Stats ----  */

    //past driver score
    async getPastScoreStats() {
        try {
            //call api to get data
            this.pastScore_spinner.show();

            const lastWeekDate: moment.Moment = moment(this.todayDateMoment).subtract(1, "weeks");
            const yearAndWeekNo: any = DateTimeUtil.fromDateToYearAndWeekNo(lastWeekDate);
            const weekNo: number = yearAndWeekNo.weekNo;
            const year: number = yearAndWeekNo.year;

            let apiStats: any = {};
            // await AsyncUtil.wait(1000);
            apiStats = await this.driverService.getDriverScoring(this.driverId, weekNo, year, this.pastScoreNoOfWeeks);

            if (apiStats) {
                const stats = apiStats.body;

                // Chart
                await this.generatePastDriverScoreBarChart(stats.weeks);
            } else {
                // Remove old chart & stats
                await this.generatePastDriverScoreBarChart(null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pastScore_spinner.hide();
        }
    }

    //past driver working hours
    async getPastWorkingHours() {
        try {
            //call api to get data
            this.pastWorkingHour_spinner.show();
            const lastWeekDate: moment.Moment = moment(this.todayDateMoment).subtract(1, "weeks");
            const formatDate: string = lastWeekDate.format('YYYY-MM-DD');
            let apiStats: any = {};
            // await AsyncUtil.wait(1000);
            apiStats = await this.driverService.getDriverPerformance(this.driverId, this.pastDriverPerformanceNoOfWeeks, formatDate);

            if (apiStats) {
                const stats = apiStats.body;

                // Chart
                await this.generatePastDriverWorkingHourBarChart(stats.week);
            } else {
                // Remove old chart & stats
                await this.generatePastDriverWorkingHourBarChart(null);
            }
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pastWorkingHour_spinner.hide();
        }
    }

    /* ---- Charts Implementation ----  */
    //
    async generatePastDriverScoreBarChart(data) {
        // Clear Previous Chart Data
        this.perfCharts.PastScore.destroy();

        if (!data || !data.length) {
            return;
        }

        // Prepare Chart Values
        data = data.slice(0).reverse();
        const tickLabels = data.map(result => result.Week);
        const tickValues = data.map(result => result.AverageScore);

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        // const _this = this;
        // const onClickFn = function (e) {
        //     const element = this.getElementAtEvent(e);
        //     if (element.length) {
        //         const index = element[0]._index;
        //         const imeiNo = data[index].imeiNo;
        //         _this.routerProxyService.navigateByUrl('/' + environment.appConfig.page.mapUrlPath + '(leftbar:vehicle/' + imeiNo + ')?view=trips');
        //     }
        // };

        // Construct Bar Chart
        const options = {
            xLabel: 'Week', // X-Axis Title
            yLabel: 'Percentage (%)',
            tickLabels, //Y-Axis Labels ......
            tickValues, //Y-Axis Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    return ' ' + tooltipItem.value + ' %';
                }
            },
            // onClickFn
        };
        this.padding = 0;
        this.stepSize = 20;
        const color = Colors.RGBA_PRI3_DARK_GREEN;
        this.perfCharts.PastScore.chart = this.genColorfulHorizontalBarChart(this.perfCharts.PastScore, options, color);
    }
    async generatePastDriverWorkingHourBarChart(data) {
        // Clear Previous Chart Data
        this.perfCharts.PastWorkingHours.destroy();

        if (!data || !data.length) {
            return;
        }

        //set the format of y axis
        for (let i = 0; i < data.length; i++) {
            if (data[i].WorkingHour >= 100) {
                this.padding = 5;
                this.stepSize = 0;
            }
            // this.formattedWeek[i] = data[i].month + ' Week ' + data[i].weekInMonth;
        }
        // Prepare Chart Values
        data = data.slice(0).reverse();
        // const tickLabels = data.map(result => result.month + `
        // Week ` + result.weekInMonth);
        const tickLabels = data.map(result => result.Week);
        const tickValues = data.map(result => result.WorkingHour);

        // Check if all data are zeroes
        if (ObjectUtil.isArrayAllEmpty(tickValues)) {
            return;
        }

        // Construct Bar Chart
        const options = {
            xLabel: 'Week', // X-Axis Title
            yLabel: 'Hour (hh)',
            tickLabels, //Y-Axis Labels ......
            tickValues, //Y-Axis Values
            tooltipCb: { //Tooltip Custom
                title: this.chartJsTooltipTruncateCallbackTitleFn,
                label: function (tooltipItem, cdata) {
                    // Add prefix & suffix
                    return ' ' + tooltipItem.value + ' hr';
                }
            },

        };

        const color = Colors.RGBA_PRI1_SHARP_GREEN;
        this.perfCharts.PastWorkingHours.chart = this.genColorfulHorizontalBarChart(this.perfCharts.PastWorkingHours, options, color);
    }
    genColorfulHorizontalBarChart(chartObj: PerformanceChartChildObj, options: any, color: any) {
        // right here yaa .. for the axis
        const {
            tickLabels, tickValues
        } = options;
        chartObj.setIsEmpty(false);

        let cOptions = {
            type: 'bar',
            data: {
                labels: tickLabels,
                datasets: [{
                    data: tickValues,
                    backgroundColor: color,
                    ...this.chartJsBarHoverInteraction
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    ...this.chartJsTooltipStyles

                },
                hover: this.chartJsHoverProps,
                scales: {
                    yAxes: [{
                        barThickness: "flex",
                        maxBarThickness: 15,
                        ticks: {
                            ...this.chartJsTicksFontStyles,
                            callback: this.chartJsTicksStringEllipsisCallbackFn,
                            suggestedMin: 0,
                            suggestedMax: 100,
                            padding: this.padding,
                            stepSize: this.stepSize
                        },
                        gridLines: {
                            z: 1,
                            display: true,
                            lineWidth: [3, 1, 1, 1, 1, 1, 1],
                            zeroLineWidth: 3,
                            borderDash: [5, 4],
                            color: Colors.RGBA_LIGHT_GRAY,
                            tickMarkLength: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            ...this.chartJsTicksFontStyles,
                            maxRotation: 20,
                            maxTicksLimit: 7,
                            callback: this.chartJsTicksNumberTruncateCallbackFn
                        },
                        gridLines: {
                            z: 1,
                            display: false,
                            lineWidth: 3,
                            zeroLineWidth: 3,
                            color: Colors.RGBA_LIGHT_GRAY
                        }
                    }]
                },
                animation: this.chartJsAnimation
            }
        };

        // Apply configurations
        if (options) {
            cOptions = this.overrideChartJsOptions(cOptions, options);
        }
        return new Chart(chartObj.canvasId, cOptions);
    }

    // ChartJS Helper: Template Generator Configuration Override
    overrideChartJsOptions(cOptions: any, options: any) {
        // if (options.onClickFn) {
        //     cOptions.options['onClick'] = options.onClickFn;
        // }
        if (options.tooltipCb) {
            cOptions.options.tooltips['callbacks'] = options.tooltipCb;
        }
        if (options.xLabel) {
            cOptions.options.scales.xAxes[0]['scaleLabel'] = {
                display: true,
                labelString: options.xLabel,
                ...this.chartJsScaleLabelFontStyles
            };
        }
        if (options.yLabel) {
            cOptions.options.scales.yAxes[0]['scaleLabel'] = {
                display: true,
                labelString: options.yLabel,
                ...this.chartJsScaleLabelFontStyles
            };
        }
        // Apply custom configurations
        if (options.custom) {
            cOptions = options.custom(cOptions);
        }
        return cOptions;
    }

    /* ---- ChartJS ---- // ---- Circle Progress Bar is done within HTML ---- */

    initChartJsDefaults() {
        Chart.defaults.global.legend.display = false;
        // this.randomColourGenerator();
        // this.colourList = [
        //     Colors.RGBA_PRI1_SHARP_GREEN,
        //     Colors.RGBA_PRI3_DARK_GREEN,
        //     Colors.RGBA_PRI4_GRASS_GREEN,
        //     Colors.RGBA_HL3_SKY_BLUE,
        //     Colors.RGBA_HL2_ROYALE_BLUE,
        //     Colors.RGBA_VIOLET,
        //     Colors.RGBA_ORANGE,
        //     Colors.RGBA_BRIGHT_RED,
        //     Colors.RGBA_GRAY,
        //     Colors.RGBA_DARK_GREEN
        // ];
        this.chartJsHoverProps = {
            animationDuration: 300,
            onHover: function (e: MouseEvent) {
                const point = this.getElementAtEvent(e);
                if (point.length) {
                    (<HTMLElement>e.target).style.cursor = 'pointer';
                } else {
                    (<HTMLElement>e.target).style.cursor = 'default';
                }
            }
        };
        this.chartJsAnimation = {
            easing: "easeInOutCubic"
        };
        this.chartJsTooltipStyles = {
            titleAlign: 'center',
            bodyAlign: 'center',
            yPadding: 8,     //8
            xPadding: 10     //10
        };
        this.chartJsBarHoverInteraction = {
            hoverBorderColor: Colors.RGBA_WHITE_SHADE,
            hoverBorderWidth: 2
        };
        this.chartJsScaleLabelFontStyles = {
            fontStyle: "bold",
            fontColor: Colors.RGBA_BLACK,
            fontSize: 13
        };
        this.chartJsTicksFontStyles = {
            fontColor: Colors.RGBA_BLACK,
            fontSize: 12
        };
        this.chartJsTicksNumberTruncateCallbackFn = function (value) {
            if (value >= 1000000) {
                return NumberUtil.roundOff(value / 1000000, 1) + 'mil';
            } else if (value >= 10000) {
                return NumberUtil.roundOff(value / 1000, 1) + 'k';
            }
            return value;
        };
        this.chartJsTicksStringEllipsisCallbackFn = function (value, index, values) {
            // truncate name of ticks in axis
            return StringUtil.truncateText(value, CHART_TICK_LABEL_MAX_LENGTH, 'N/A');
        };
        this.chartJsTooltipTruncateCallbackTitleFn = function (tooltipItem, cdata) {
            // console.log('title:', tooltipItem);
            // console.log('title:', cdata);
            // truncate for tooltip
            const label = cdata.labels[tooltipItem[0].index];
            return StringUtil.truncateText(label, CHART_TOOLTIP_MAX_LENGTH, 'N/A');
        };
    }

    getCssClassUpDownIndicator(val1: number = 0, val2: number = 0, color: boolean = true, healthyNature: boolean = true, indicator: boolean = false): string {
        val1 = NumberUtil.isNumeric(val1) ? val1 : 0;
        val2 = NumberUtil.isNumeric(val2) ? val2 : 0;

        const classes: string[] = [];
        const CLASS_INDICATOR = '--indicator';
        const CLASS_DASH = '--dash';
        const CLASS_ARROW_UP = '--up';
        const CLASS_ARROW_DOWN = '--down';
        const CLASS_RED = '--alert-red';
        const CLASS_GREEN = '--alert-green';

        if (val1 == val2) {
            classes.push(CLASS_DASH);
        } else {
            if (val1 < val2) {
                classes.push(CLASS_ARROW_UP);
                if (color) {
                    classes.push(healthyNature ? CLASS_GREEN : CLASS_RED);
                }
            } else {
                classes.push(CLASS_ARROW_DOWN);
                if (color) {
                    classes.push(healthyNature ? CLASS_RED : CLASS_GREEN);
                }
            }
        }
        if (indicator) {
            classes.push(CLASS_INDICATOR);
        }
        return classes.join(' ');
    }

    // JS: Calculate number of stars for average scores
    private countRatingScores(averageScore: number) {
        let count = 0;
        let color = '';
        if (averageScore == 0) {
            count = 0;
            color = 'black';
        } else if (averageScore < 25) {
            count = 0;
            color = 'red';
        } else if (averageScore < 50) {
            count = 1;
            color = 'red';
        } else if (averageScore < 80) {
            count = 2;
            color = 'amber';
        } else {
            count = 3;
            color = 'green';
        }
        return {
            count,
            color
        };
    }

    /* ---- PDF Downloads ---- */

    // PDF: Main Download Engine
    async downloadPdf() {
        try {
            this.pageSpinner.show();
            this.isDownloadPdfClick = true;

            // 1. Prepare temporary changes for PDF rendering

            await AsyncUtil.wait(1000); // Wait for canvas rendering to complete
            this.convertDocumentSvgsToPngs(); // convert SVG to PNG
            this.perfCharts.resizeForPdfDownload(); // resize charts to support desktop & mobile

            // 2. Start Main Rendering
            const canvas = document.createElement("canvas");
            const content = document.getElementById("performance");
            const scale = DomUtil.isMobile(this.platform) ? 1 : 2; // Reduce load on mobile
            const pdfPaperWidth = 1000;
            canvas.width = pdfPaperWidth * scale;
            // const pdfPaperHeight = 2100; // no height = get all contents
            // canvas.height = pdfPaperHeight * scale;
            canvas.getContext("2d").scale(scale, scale); // make image clearer / HD

            // 3. Generate PDF
            const opts = {
                // allowTaint: true, //allow cross-origin images
                tainttest: true, // perform test before render image
                // foreignObjectRendering: true, // ForeignObject rendering
                // useCORS: true,
                scale: scale,
                canvas: canvas,
                logging: true,
                width: pdfPaperWidth,
                // height: pdfPaperHeight,
                windowWidth: pdfPaperWidth,
                // windowHeight: pdfPaperHeight,
            };
            const htmlCanvas = await html2canvas(content, opts); // Convert HTML to Canvas
            const pdfTimeStamp = moment(this.todayDate).format("YYYYMMDD") + '-' + moment().format('HHmmss');
            const pdf = new jsPDF('p', 'pt', [htmlCanvas.width, htmlCanvas.height]);
            const imgData = htmlCanvas.toDataURL("image/jpeg", 1);
            pdf.addImage(imgData, 0, 0, htmlCanvas.width, htmlCanvas.height);
            // pdf.addImage(imgData, 'PNG', 20, 30, 0, 130);

            // 4. Reverting temporary changes
            this.isDownloadPdfClick = false;
            this.perfCharts.restoreDefaultSizes(); // revert chart sizes
            this.convertDocumentPngsToSvgs(); // revert PNG to SVG

            // circleCanvasList.forEach(result => {
            //     // Set Canvas small size to hide
            //     result.height = 0;
            //     result.width = 0;
            //     if (StringUtil.equalsIgnoreCase(result.id, "circleCanvas1")) {
            //         const childNodes = document.getElementById("vehicleScore").childNodes;
            //         const description = childNodes[childNodes.length - 1].cloneNode(true);
            //         document.getElementById("vehicleScore").childNodes[2].remove();
            //         document.getElementById('vehicleScore').appendChild(result);
            //         document.getElementById('vehicleScore').appendChild(description);
            //     } else if (StringUtil.equalsIgnoreCase(result.id, "circleCanvas2")) {
            //         const childNodes = document.getElementById("driverScore").childNodes;
            //         const description = childNodes[childNodes.length - 1].cloneNode(true);
            //         document.getElementById("driverScore").childNodes[2].remove();
            //         document.getElementById('driverScore').appendChild(result);
            //         document.getElementById('driverScore').appendChild(description);
            //     }
            //     // NOTE****: do not remove circle canvas else it will failed to download on second time

            // });

            // 5. Download to user
            const fileName = PDF_FILE_NAME_PREFIX + "_" + pdfTimeStamp + ".pdf";
            if (DomUtil.isMobileApp(this.platform)) {
                const pdfOutput = pdf.output();
                // using ArrayBuffer will allow you to put image inside PDF
                const buffer = new ArrayBuffer(pdfOutput.length);
                const array = new Uint8Array(buffer);
                for (let i = 0; i < pdfOutput.length; i++) {
                    array[i] = pdfOutput.charCodeAt(i);
                }

                if (DomUtil.isMobileApp(this.platform, 'ios')) {
                    const directory = this.file.documentsDirectory;
                    const fullPath = directory + fileName;
                    //Writing File to Device for iOS
                    this.file.writeFile(directory, fileName, buffer)
                        .then((success) => this.fileOpener.open(fullPath,'application/pdf'))
                        .catch((error) => {
                            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.FILE_DOWNLOAD_FAILED);
                            this.snackBar.openGenericSnackBar(msg);
                        });
                } else if (DomUtil.isMobileApp(this.platform, 'android')) {

                    const directory = this.file.externalDataDirectory;
                    //Writing File to Device for android
                    this.file.writeFile(directory, fileName, buffer)
                        //.then((success)=> this.snackBar.openGenericSnackBar("File has been downloaded successfully to " + fullPath)                        )
                        .then((success) => this.fileOpener.open(directory + fileName + "",'application/pdf'))
                        //.catch((error)=> this.snackBar.openGenericSnackBar("Cannot Create File " +JSON.stringify(error)));
                        .catch((error) => {
                            const msg = ErrorMessage.getPromptErrorMessage(ErrorMessage.FILE_DOWNLOAD_FAILED);
                            this.snackBar.openGenericSnackBar(msg);
                        });
                }
            } else {
                pdf.save(fileName);
            }

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hideAll();
        }
    }

    convertDocumentSvgsToPngs() {
        // Convert SVG to PNG for download PDF
        const smileyFaceContainers = document.getElementsByClassName('no-idle');
        // console.log('smileyFaceContainers', smileyFaceContainers);
        for (let i = 0; i < smileyFaceContainers.length; i++) {
            const prevSrc = smileyFaceContainers[i].children[0]['src'];
            if (prevSrc) {
                smileyFaceContainers[i].children[0]['src'] = prevSrc.replace(".svg", ".png");
            }
        }

    }

    convertDocumentPngsToSvgs() {
        // Convert PNG back to SVG
        const smileyFaceContainers = document.getElementsByClassName('no-idle');
        for (let i = 0; i < smileyFaceContainers.length; i++) {
            const prevSrc = smileyFaceContainers[i].children[0]['src'];
            if (prevSrc) {
                smileyFaceContainers[i].children[0]['src'] = prevSrc.replace(".png", ".svg");
            }
        }

    }

}
