<div class="map__list" [class.--active]="listIsShown">

    <div class="map__list-inner">
        <app-spinner #map_list_spinner [transparent]="true"></app-spinner>
        <div class="map__list-header" *ngIf="filterHasStatusesOrGroups()">

            <div class="map__list-result-title">Found {{ mapModel.currentItems.length }} drivers under:</div>

            <ng-container *ngFor="let status of getActiveStatusFilters()">
                <div class="tag" [ngStyle]="{ 'background-color': STATUS[status].COLOR }">
                    {{ STATUS[status].LABEL }}
                    <button type="button" class="tag__close" (click)="updateVehicleStatusAndGroupStatusToFalse('status', status)"></button>
                </div>
            </ng-container>

            <ng-container *ngFor="let group of getActiveGroupFilters()">
                <div class="tag" [ngStyle]="{ 'background-color': group.colour }">
                    {{ group.name }}
                    <button type="button" class="tag__close" (click)="updateVehicleStatusAndGroupStatusToFalse('group', group.groupId)"></button>
                </div>
            </ng-container>
        </div>

        <div class="mapListContainer" (scroll)="onMaplistScroll(mapItemList)" #mapItemList>
            <ng-container *ngFor="let item of mapModel.lazyLoadItems">
                <map-item (pinEvent)="receivePinEvent($event)" [item]="item"></map-item>
            </ng-container>
        </div>

        <ng-container *ngIf="!mapModel.lazyLoadItems.length">
            <div class="map__list-empty">
                <!-- No matching vehicle found. -->
                <span *ngIf="currtrips === undefined || currtrips.length == 0">No vehicles found</span>
            </div>
        </ng-container>
    </div>
</div>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
