import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class PagerService {

    constructor() { }

    pageLimitNumber: number = environment.appConfig.reporting.pageLimitNo; //Set Page Number for Display


    getPager(totalItems: number = 0, currentPage: number = 1, pageSize: number = 5) {

        // calculate total pages
        const totalPages = Math.ceil(totalItems / pageSize);
        // default is 2

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        const pageCountFormula = Math.ceil(this.pageLimitNumber / 2);

        if (totalPages <= this.pageLimitNumber) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= pageCountFormula) {  //6 (if pageNum is 10) Math.ceil(pageNumber / 2)
                startPage = 1;
                endPage = this.pageLimitNumber;  //10 (if pageNum is 10)

            } else if (currentPage + (pageCountFormula - 1) >= totalPages) { //4 (if pageNum is 10) Math.ceil(pageNumber / 2) - 1
                startPage = totalPages - (this.pageLimitNumber - 1);   //9 (if pageNum is 10) pageNumber - 1
                endPage = totalPages;

            } else {
                startPage = currentPage - (this.pageLimitNumber % 2 === 0 ? pageCountFormula : pageCountFormula - 1);   //5 (if pageNum is 10) Math.ceil(pageNumber / 2)
                endPage = currentPage + (pageCountFormula - 1);  //4 (if pageNum is 10) Math.ceil(pageNumber / 2) - 1
            }
        }

        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}
