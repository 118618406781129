import { HttpErrorResponse } from "@angular/common/http";

/* Check if error already handled in RequestInterceptor.handleHTTPError() */
export function isHttpErrorAlreadyHandled(error: any): boolean {
    const skipKeyword = 'HTTPErrorAlreadyHandled';
    if ((typeof error == 'string' && error.includes(skipKeyword)) ||
        (error instanceof Error && error.message.includes(skipKeyword))) {
        return true;
    }
    return false;
}

export function isError(error: any): boolean {
    return (error instanceof Error || error instanceof HttpErrorResponse);
}
