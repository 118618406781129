<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">
            <app-breadcrumb></app-breadcrumb>

            <div class="grid --gutter">
                <div class="grid__block --space-top">
                    <h1 class="heading --level-1">
                        {{vehicleDetails.vehicleName}}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter-xspace">
                    <div class="grid__block-xspace w-6of12@sm">
                        <h2 class="heading indicator-green --level-2 --partial-underline --space-bottom">
                            Vehicle
                        </h2>
                        <div class="filter-result__box filter-result__manage-vehicle --highlight-grey">
                            <div class="filter-result-group">
                                <div class="filter-result__image" [class.--placeholder]="!vehicleDetails.vehicleImage">
                                    <div class="filter-result__image-cropped  --filter-result__assign" [class.--placeholder-green]="!vehicleDetails.vehicleImage">
                                        <img src="data:image/jpeg;base64,{{vehicleDetails.vehicleImage}}" alt="" width="142" height="142" *ngIf="vehicleDetails.vehicleImage">

                                        <div *ngIf="!vehicleDetails.vehicleImage && vehicleDetails.vehicleName">
                                            {{ vehicleDetails.vehicleShortName }}
                                        </div>
                                    </div>
                                </div>

                                <div class="filter-result__info">
                                    <div class="filter-result__table">
                                        <table cellspacing="0" cellpadding="0" style="width: 100%; table-layout: fixed;">
                                            <tr>
                                                <td>Current Driver:</td>
                                                <td class="truncate-word" [title]="vehicleDetails.userName"><b>{{vehicleDetails.userName || 'N/A'}}
                                                        <span [class]="'indicator-' + vehicleDetails.statusColourIndicator" *ngIf="vehicleDetails.status !== null">({{vehicleDetails.status || 'N/A'}})</span>
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Vehicle Make:</td>
                                                <td><b>{{vehicleDetails.vehicleMakeModel || 'N/A'}}</b></td>
                                            </tr>

                                            <tr>
                                                <td>Number Plate:</td>
                                                <td><b>{{vehicleDetails.vehiclePlateNo || 'N/A'}}</b></td>
                                            </tr>
                                            <ul class="update-log">
                                                <li class="module-driver-tag" *ngIf="vehicleDetails.hasDriverTagDevice"></li>
                                                <li class="module-immobilizer" *ngIf="vehicleDetails.hasImmobilizer"></li>
                                                <li class="module-fuel-sensor" *ngIf="vehicleDetails.hasFuelSensor"></li>
                                                <li class="module-panic-button" *ngIf="vehicleDetails.hasPanicButton"></li>
                                            </ul>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid__block-xspace w-6of12@sm">
                        <h2 class="heading indicator-green --level-2 --partial-underline --space-bottom">
                            Driver
                        </h2>

                        <div class="filter-result__box filter-result__manage-vehicle" *ngIf="!hasDriverTagDevice">
                            <div class="filter-result-group">
                                <div class="filter-result__image">
                                    <div class="profile__image-cropped --assign-driver" *ngIf="isShowSelectMessage">
                                        <img src="assets/images/ng-components/create-manage/icon-driver-placeholder.svg" alt="" width="94" height="102">
                                    </div>
                                    <div *ngIf="!isShowSelectMessage">
                                        <div class="filter-result__image-cropped --filter-result__assign" [class.--placeholder-grey]="!selectedDriverDetails.Photo">
                                            <img src="data:image/jpeg;base64,{{selectedDriverDetails.Photo}}" alt="" width="142" height="142" *ngIf="selectedDriverDetails.Photo">

                                            <div *ngIf="!selectedDriverDetails.Photo">
                                                {{ selectedDriverDetails.userShortName }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="filter-result__info">
                                    <div class="field-group__holder">
                                        <div class="field-group__input">
                                            <div class="field">
                                                <div class="field__elems">
                                                    <label class="select">
                                                        <select class="select__input" (change)="onSelectedDriver($event.target.value)">
                                                            <option *ngFor="let driver of driverDetailsList" [selected]="vehicleDetails.userId === driver.Id" [value]="driver.Id" [title]="driver.Username">{{driver.Username}}</option>
                                                            <option selected disabled hidden *ngIf="isShowSelectMessage">Please
                                                                Select Driver</option>
                                                            <option selected disabled hidden *ngIf="selectedDriverDetails.NonVisible">{{selectedDriverDetails.Username}}</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="filter-result__table">
                                        <div class="filter-result__email">
                                            <span class="truncate-line">
                                                {{ selectedDriverDetails.Email || 'N/A'}}
                                            </span>
                                        </div>

                                        <div class="filter-result__contact">
                                            <span class="truncate-line">
                                                {{ selectedDriverDetails.TelNo || 'N/A' }}
                                            </span>
                                        </div>

                                        <div class="filter-result__desc">
                                            <table cellspacing="0" cellpadding="0" style="width: 100%; table-layout: fixed;">
                                                <tr>
                                                    <td>ID Tag No :</td>
                                                    <td>{{selectedDriverDetails.driverTagNo || 'N/A'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Current Status:</td>
                                                    <td><b><span [class]="'indicator-' + selectedDriverDetails.StatusColourIndicator">{{selectedDriverDetails.Status || 'N/A'}}</span></b></td>
                                                </tr>
                                                <tr>
                                                    <td>Current Vehicle:</td>
                                                    <td class="truncate-word" [title]="selectedDriverDetails.VehicleName"><b>{{selectedDriverDetails.VehicleName || 'N/A'}}</b>
                                                        <span *ngIf="!isShowSelectMessage && selectedDriverDetails.VehicleName !== null">
                                                            <br>({{selectedDriverDetails.VehicleMakeModel}})
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="filter-result__box filter-result__manage-vehicle" *ngIf="hasDriverTagDevice">
                            <div class="filter-result-group">
                                <div class="filter-result__info">
                                    <div class="field-group__holder">
                                        <div class="sectionClass">
                                            <div class="wrapperClass">
                                                <div [ngClass]="blockClass">
                                                    <div class="table__wrapper">
                                                        <table class="table__elem">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="thead__col">
                                                                        <label class="inline-input">
                                                                            <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedDriverGroups['all']" (ngModelChange)="onDriversAllSelectChange()">
                                        
                                                                            <span class="inline-input__icon"></span>
                                                                        </label>
                                                                    </th>
                                                                    <th scope="col" class="thead__col">
                                                                        Driver Name
                                                                    </th>
                                                                    <th scope="col" class="thead__col">
                                                                        Driver Tag No
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let driver of ibuttonDriverList">
                                                                    <td scope="col">
                                                                        <label class="inline-input">
                                                                            <input type="checkbox" [name]="driver.driverId" [value]="true" class="inline-input__input" [(ngModel)]="selectedDrivers[driver.Id]" (ngModelChange)="onDriversSelectChange()">
                                    
                                                                            <span class="inline-input__icon"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td scope="col">
                                                                        {{driver.Username}}
                                                                    </td>
                                                                    <td scope="col">
                                                                        {{driver.driverTagNo || '-'}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="wrapper">
            <div class="grid --gutter">
                <div class="grid__block">
                    <div class="inline-button__field">
                        <button class="button inline-button__inputs" [disabled]="isSaveButtonOff" type="button" (click)="showPopup(confirmationPopup)">
                            SAVE
                        </button>
                        <button class="button inline-button__inputs --grey" type="button" (click)="navigateToVehicleView()">
                            CANCEL
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #confirmationPopup="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Changes?
            </div>
        </div>
    </div>

    <!-- <div class="popup__block">
        <p>
            You are going to save vehicle changes
        </p>

        <p class="notes">
        </p>
    </div> -->

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="assignVehicleToDriver();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" type="button" (click)="hidePopup();">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
<popup-campaign *ngIf="hasUnviewedCamapaign" [unviewedCampaigns]="unviewedCampaigns">
</popup-campaign>
