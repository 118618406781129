import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

import * as StringUtil from '../../util/stringUtil';
import * as NumberUtil from '../../util/numberUtil';
import * as LocalStorageUtil from './../../util/localStorageUtil';

@Injectable({
    providedIn: 'root'
})
export class VehicleService {

    constructor(private http: HttpClient) { }

    async getVehicleInfosByInfoRange(infoRange = "BASIC") {
        const path = environment.mtntApiEndpoint + '/vehicles';
        const paramsObj = {
            infoRange: infoRange
        };
        const result: any = await this.http.get(path, { params: paramsObj }).toPromise();
        return result;
    }
    async getVehicleDetailsInfo(imei) {
        const path = environment.mtntApiEndpoint + '/vehicles';
        const body = { "imeiNo": imei };
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Vehicle Model Dropdown List
    async getGroupVehicleModelByCompany(sortField = null, sortAscending = null, adminId = null, excludeDeactivated = true) {
        const url = "/vehicles/group/listing";
        const body: any = {};

        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body.sortField = sortField;
        }
        if (typeof (sortAscending) == typeof (true)) {
            body.sortAscending = sortAscending;
        }
        if (StringUtil.isNotEmptyOrNull(adminId)) {
            body.adminId = Number(adminId);
        }
        body.excluDeact = excludeDeactivated; //determine deactivated should exclude or not

        const path = environment.mtntApiEndpoint + url /* ?companyId=${companyId} */;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    //get Vehicle Type Model Dropdown List
    async getGroupVehicleTypeModelByCompany(sortField = null, sortAscending = null, adminId = null, excludeDeactivated = true, companyId) {
        const url = "/vehicles/type";        

        const body: any = {};
        if (StringUtil.isNotEmptyOrNull(sortField)) {
            body.sortField = sortField;
        }
        if (typeof (sortAscending) == typeof (true)) {
            body.sortAscending = sortAscending;
        }
        if (StringUtil.isNotEmptyOrNull(adminId)) {
            body.adminId = Number(adminId);
        }
        if (StringUtil.isNotEmptyOrNull(companyId)) {
            body.companyId = Number(companyId);
        }
        body.excluDeact = excludeDeactivated; //determine deactivated should exclude or not

        const path = environment.mtntApiEndpoint + url;
        const result: any = await this.http.post(path, body).toPromise();
        return result;

    }

    //Get All previous Imeis of this vehicle
    async getVehicleImeisFromImei(imei) {
        const path = environment.mtntApiEndpoint + `/vehicles/imei?imei=${imei}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    //Get Vehicle Make Model
    async getVehicleMakeModel() {
        const path = environment.mtntApiEndpoint + `/vehicles/make-model`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    //Get All Vehicle Info Details
    async getAllVehicleInfoDetails(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined, currentVehicleStatus = undefined, vehicleIdList = []) {
        const body = {
            pageSize: pageRecordSize,
            startRecord: startRecord,
            sortField: sortField,
            sortAscending: sortAscending,
            filterKey: filterKey,
            filterValue: filterValue,
            vehicleId: vehicleIdList,
            currentVehicleStatus: currentVehicleStatus
        };
        const path = environment.mtntApiEndpoint + `/vehicles`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Vehicle Info Details
    async getVehicleInfoDetailsByVid(vehicleId) {
        const path = environment.mtntApiEndpoint + `/vehicles/${vehicleId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    //Update Vehicle Info Details
    async updateVehicleInfoDetails(vehicleId, body) {
        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(body.vehicleName)) {
            body.vehicleName = body.vehicleName.trim();
        }
        const path = environment.mtntApiEndpoint + `/vehicles/${vehicleId}`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    //Update Assign Vehicle To Driver
    async updateAssignVehicleToDriver(vehicleId, driverId, vehiclePlateNo, imeiId, imeiNo) {
        const body = {
            vehicleId: vehicleId,
            driverId: driverId,
            vehiclePlateNo: vehiclePlateNo,
            imeiId: imeiId,
            imeiNo: imeiNo
        };

        const path = environment.mtntApiEndpoint + `/vehicles/assign`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async updateAssignVehicleToDriverIbutton (vehicleId, driverIdList) {
        const body = {
            vehicleId: vehicleId,
            driverIdList: driverIdList
        }

        const path = environment.mtntApiEndpoint + `/vehicles/assign/ibutton`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    //Get Vehicle Driving Limit
    async getVehicleDrivingLimit(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        // try {
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
        // } catch (err) {
        //     return { hasError: true, body: err };
        // }
    }

    async updateVehicleDrivingLimit(speedLimit, allowedTimeFrom, allowedTimeTo, dailyMileageLimit, vehicleIdList = []) {
        let body = {
            vehicleIdList: vehicleIdList
        };
        if (StringUtil.isNotEmptyOrNull(speedLimit)) {
            body = Object.assign({ speedLimit: speedLimit }, body);
        }

        if (allowedTimeFrom !== undefined && allowedTimeFrom !== null) {
            body = Object.assign({ allowedTimeFrom: allowedTimeFrom }, body);
        }

        if (allowedTimeTo !== undefined && allowedTimeTo !== null) {
            body = Object.assign({ allowedTimeTo: allowedTimeTo }, body);
        }

        if (dailyMileageLimit !== undefined && dailyMileageLimit !== null) {
            body = Object.assign({ dailyMileageLimit: dailyMileageLimit }, body);
        }

        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async deleteVehicleDrivingLimit(vehicleIdList = []) {
        const body: any = { body: { vehicleIdList: vehicleIdList } };
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    //Get Vehicle Driving Limit By Id
    async getVehicleDrivingLimitById(vehicleId) {
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/${vehicleId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async createGeofence(geofenceName, alertTypeEntry, alertTypeExit, alertDays, alertTimeFrom, alertTimeTo, geofencePath, geofenceType, geofenceRadius, vehicleIdList = []) {
        let body = {
            vehicleIdList: vehicleIdList
        };

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(geofenceName)) {
            geofenceName = geofenceName.trim();
        }

        if (StringUtil.isNotEmptyOrNull(geofencePath)) {
            body = Object.assign({ geofencePath: geofencePath }, body);
        }

        if (StringUtil.isNotEmptyOrNull(geofenceType)) {
            body = Object.assign({ geofenceType: geofenceType }, body);
        }

        if (StringUtil.isNotEmptyOrNull(geofenceRadius)) {
            body = Object.assign({ geofenceRadius: geofenceRadius }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertTimeTo)) {
            body = Object.assign({ alertTimeTo: alertTimeTo }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertTimeFrom)) {
            body = Object.assign({ alertTimeFrom: alertTimeFrom }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertDays)) {
            body = Object.assign({ alertDay: alertDays }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertTypeExit)) {
            body = Object.assign({ alertTypeExit: +alertTypeExit }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertTypeEntry)) {
            body = Object.assign({ alertTypeEntry: +alertTypeEntry }, body);
        }

        if (StringUtil.isNotEmptyOrNull(geofenceName)) {
            body = Object.assign({ geofenceName: geofenceName }, body);
        }

        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/geofence`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async createDestination(destinationName, destinationPath, destinationType, destinationRadius, vehicleIdList = []) {
        let body = {
            vehicleIdList: vehicleIdList
        };

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(destinationName)) {
            destinationName = destinationName.trim();
        }

        if (StringUtil.isNotEmptyOrNull(destinationPath)) {
            body = Object.assign({ destinationPath: destinationPath }, body);
        }

        if (StringUtil.isNotEmptyOrNull(destinationType)) {
            body = Object.assign({ destinationType: destinationType }, body);
        }

        if (StringUtil.isNotEmptyOrNull(destinationRadius)) {
            body = Object.assign({ destinationRadius: destinationRadius }, body);
        }

        if (StringUtil.isNotEmptyOrNull(destinationName)) {
            body = Object.assign({ destinationName: destinationName }, body);
        }

        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/destination`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async editGeofence(geofenceId, geofenceName, alertTypeEntry, alertTypeExit, alertDays, alertTimeFrom, alertTimeTo, geofencePath, geofenceType, geofenceRadius, vehicleIdList = []) {
        let body = {
            vehicleIdList: vehicleIdList
        };

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(geofenceName)) {
            geofenceName = geofenceName.trim();
        }

        if (StringUtil.isNotEmptyOrNull(geofencePath)) {
            body = Object.assign({ geofencePath: geofencePath }, body);
        }

        if (StringUtil.isNotEmptyOrNull(geofenceType)) {
            body = Object.assign({ geofenceType: geofenceType }, body);
        }

        if (StringUtil.isNotEmptyOrNull(geofenceRadius)) {
            body = Object.assign({ geofenceRadius: geofenceRadius }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertTimeTo)) {
            body = Object.assign({ alertTimeTo: alertTimeTo }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertTimeFrom)) {
            body = Object.assign({ alertTimeFrom: alertTimeFrom }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertDays)) {
            body = Object.assign({ alertDay: alertDays }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertTypeExit)) {
            body = Object.assign({ alertTypeExit: +alertTypeExit }, body);
        }

        if (StringUtil.isNotEmptyOrNull(alertTypeEntry)) {
            body = Object.assign({ alertTypeEntry: +alertTypeEntry }, body);
        }

        if (StringUtil.isNotEmptyOrNull(geofenceName)) {
            body = Object.assign({ geofenceName: geofenceName }, body);
        }

        if (StringUtil.isNotEmptyOrNull(geofenceId)) {
            body = Object.assign({ id: +geofenceId }, body);
        }

        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/geofence`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    async editDestination(destinationId, destinationName, destinationPath, destinationType, destinationRadius, vehicleIdList = []) {
        let body = {
            vehicleIdList: vehicleIdList
        };

        // trim leading & trailing whitespace for names
        if (StringUtil.isNotEmptyOrNull(destinationName)) {
            destinationName = destinationName.trim();
        }

        if (StringUtil.isNotEmptyOrNull(destinationPath)) {
            body = Object.assign({ destinationPath: destinationPath }, body);
        }

        if (StringUtil.isNotEmptyOrNull(destinationType)) {
            body = Object.assign({ destinationType: destinationType }, body);
        }

        if (StringUtil.isNotEmptyOrNull(destinationRadius)) {
            body = Object.assign({ destinationRadius: destinationRadius }, body);
        }

        if (StringUtil.isNotEmptyOrNull(destinationName)) {
            body = Object.assign({ destinationName: destinationName }, body);
        }

        if (StringUtil.isNotEmptyOrNull(destinationId)) {
            body = Object.assign({ id: + destinationId }, body);
        }
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/destination`;
        const result: any = await this.http.put(path, body).toPromise();
        return result;
    }

    //Get Vehicle Geofence By Vehicle ID
    async getVehicleGeofenceDetailsByVehicleId(vehicleId) {
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/geofence/vehicle/${vehicleId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    //Get Vehicle Geofence
    async getVehicleGeofenceDetail(geofenceId) {
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/geofence/${geofenceId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getVehicleGeofence(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        // try {
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/geofence${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
        // } catch (err) {
        //     return { hasError: true, body: err };
        // }
    }

    async deleteGeofence(geofenceIdList = []) {
        const body: any = { body: { geofenceIdList: geofenceIdList } };
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/geofence`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async deleteDestination(destinationIdList = []) {
        const body: any = { body: { destinationIdList: destinationIdList } };
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/destination`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    //Get Vehicle Destination
    async getVehicleDestinationDetail(destinationId) {
        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/destination/${destinationId}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async getVehicleDestination(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/vehicles/driving-limit/destination${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async pinVehicleToFavourites(vehicleId) {
        const currentUser = LocalStorageUtil.localStorageGet('currentUser');
        const path = environment.mtntApiEndpoint + "/users/favourite-vehicles";
        const result = await this.http.post(path, {
            userId: currentUser.userId,
            vehicleId: vehicleId
        }).toPromise();
        return result;
    }

    async unpinVehicleFromFavourites(vehicleId) {
        const currentUser = LocalStorageUtil.localStorageGet('currentUser');
        const path = environment.mtntApiEndpoint + "/users/favourite-vehicles";
        const result = await this.http.delete(path, {
            params: {
                userId: currentUser.userId,
                vehicleId: vehicleId
            }
        }).toPromise();
        return result;
    }

    async getVehiclesFuelLimit(pageRecordSize = undefined, startRecord = undefined, sortField = undefined, sortAscending = undefined, filterKey = undefined, filterValue = undefined) {
        let param = '';
        param += pageRecordSize ? `pageSize=${pageRecordSize}` : '';
        param += startRecord ? `&startRecord=${startRecord}` : '';
        param += sortField ? `&sortField=${sortField}` : '';
        param += sortAscending !== undefined ? `&sortAscending=${sortAscending}` : '';
        param += filterKey ? `&filterKey=${filterKey}` : '';
        param += filterValue ? `&filterValue=${filterValue}` : '';

        const path = environment.mtntApiEndpoint + `/vehicles/fuel-limit${param !== '' ? `?` + param : param}`;
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async updateVehiclesFuelLimit(fuelCostLimit: number, fuelUsedLimit: number, fuelEfficiencyLimit: number, fuelDropThreshold: number, fuelRefuelThreshold: number, vehicleIdList: any = []) {
        let body = {
            vehicleIdList: vehicleIdList
        };
        if (NumberUtil.isInteger(fuelCostLimit)) {
            body = Object.assign({ fuelCostLimit: fuelCostLimit }, body);
        }

        if (NumberUtil.isInteger(fuelUsedLimit)) {
            body = Object.assign({ fuelUsedLimit: fuelUsedLimit }, body);
        }

        if (NumberUtil.isNumeric(fuelEfficiencyLimit)) {
            body = Object.assign({ fuelEfficiencyLimit: fuelEfficiencyLimit }, body);
        }

        if (NumberUtil.isNumeric(fuelDropThreshold)) {
            body = Object.assign({ fuelSensorUnexpectDropThreshold: fuelDropThreshold }, body);
        }

        if (NumberUtil.isNumeric(fuelRefuelThreshold)) {
            body = Object.assign({ fuelSensorRefuelThreshold: fuelRefuelThreshold }, body);
        }

        const path = environment.mtntApiEndpoint + `/vehicles/fuel-limit`;
        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }

    async deleteVehicleFuelLimit(vehicleIdList = []) {
        const body: any = { body: { vehicleIdList: vehicleIdList } };
        const path = environment.mtntApiEndpoint + `/vehicles/fuel-limit`;
        const result: any = await this.http.delete(path, body).toPromise();
        return result;
    }

    async getVehicleLatestHealthStatus(vehicleId: Number) {
        let path = "";
        if (vehicleId != null) {
            path = environment.mtntApiEndpoint + `/vehicles/health-status/${vehicleId}`;
        } else {
            return {
                notificationId: null,
                notifyDate: null,
                vehicleId: vehicleId,
                vehicleName: null,
                isBatteryLow: null,
                batteryLevel: null,
                isTemperatureHigh: null,
                temperature: null,
                isDtcPoor: null
            };
        }
        const result: any = await this.http.get(path).toPromise();
        return result;
    }

    async updateVehiclePanicButtonAlarm(companyId: number, imeiNo: String) {
        let path = environment.mtntApiEndpoint + `/vehicles/acknowledgeAlarm`;

        // let path = "http://localhost:10010/mtnt/v1" + `/vehicles/acknowledgeAlarm`;
        
        const body: any = { 
            companyId: companyId,
            imeiNo: imeiNo 
        };

        const result: any = await this.http.post(path, body).toPromise();
        return result;
    }
}

