<app-spinner #page_spinner [fullscreen]="true"></app-spinner>
<div class="site-main">
    <div class="section   bgc-dark-white">
        <div class="wrapper">

            <div class="grid --gutter --flex-bottom">
                <div class="grid__block w-4of12@md">
                    <h1 class="heading --level-1">
                        Manage Campaigns
                    </h1>
                </div>

                <div class="grid__block w-8of12@md heading-buttons-field">
                    <div class="heading-buttons-container">
                        <div class="heading-buttons">
                            <a class="heading-buttons__button" (click)="navigateToCreateCampaigns(); toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-add-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Create Campaigns
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Create Campaigns
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-add-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <a class="heading-buttons__button" (click)="isDeletingCampaign = true; toggleMobileOpen = false">
                                <div class="heading-buttons__desktop">
                                    <img src="assets/images/ng-components/create-manage/icon-delete-driver.svg" alt="" width="23" height="23" class="heading-buttons__icon">

                                    <div class="heading-buttons__text">
                                        Delete Campaigns
                                    </div>
                                </div>

                                <ng-container *ngIf="toggleMobileOpen">
                                    <div class="heading-buttons__text">
                                        Delete Campaigns
                                    </div>

                                    <img src="assets/images/ng-components/create-manage/icon-mobile-delete-driver.svg" alt="" width="60" height="60">
                                </ng-container>
                            </a>

                            <button type="button" class="heading-buttons__toggle" (click)="toggleMobileOpen = !toggleMobileOpen">
                                <img [attr.src]="toggleMobileOpen ? 'assets/images/ng-components/create-manage/icon-mobile-close.svg' : 'assets/images/ng-components/create-manage/icon-mobile-open.svg'" alt="" width="60" height="60">
                            </button>
                        </div>

                        <ng-container *ngIf="toggleMobileOpen">
                            <div @fadeToggle class="toggle-mobile-overlay"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <div class="wrapper">
            <div class="block">
                <div class="grid --gutter">
                    <div class="grid__block w-10of12 w-6of12@md search__group">
                        <div class="field-group --unstack@xs search__select-field">
                            <div class="field-group__item search__type-field-group-input">
                                <div class="field">
                                    <div class="field__elems">
                                        <label class="field__holder">
                                            <input id="searchBox" type="text" autocomplete="off" class="field__input" [(ngModel)]="filterValue" name="filter" (keyup.enter)="searchCampaign()">
                                        </label>
                                        <div class="field__ui"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="button__holder">
                            <button type="button" class="button" (click)="searchCampaign()">SEARCH</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="table table__support-customer-view">
                    <table class="table__elem">
                        <thead>
                            <tr>
                                <ng-container *ngIf="isDeletingCampaign">
                                    <th scope="col">
                                        <label class="inline-input">
                                            <input type="checkbox" name="all" [value]="true" class="inline-input__input" [(ngModel)]="selectedCampaignGroups['all']" (ngModelChange)="onCampaignAllSelectChange()">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </th>
                                </ng-container>

                                <th scope="col" width="33%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('name')" (click)="sort('name')">Campaign Name</button>
                                </th>

                                <th scope="col" width="33%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('category')" (click)="sort('category')">Category</button>
                                </th>

                                <th scope="col" width="33%">
                                    <button type="button" class="table__sortable" [ngClass]="getSortingStatus('status')" (click)="sort('status')">Status</button>
                                </th>

                                <th scope="col" width="33%">
                                    Action
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let campaign of campaignList">
                                <ng-container *ngIf="isDeletingCampaign">
                                    <td scope="col">
                                        <label class="inline-input">
                                            <input type="checkbox" [name]="campaign.id" [value]="true" class="inline-input__input" [(ngModel)]="selectedCampaign[campaign.id]" (ngModelChange)="onCampaignSelectChange($event)">

                                            <span class="inline-input__icon"></span>
                                        </label>
                                    </td>
                                </ng-container>
                                <td>{{campaign.name}}</td>
                                <td>{{campaign.category}}</td>
                                <td>{{campaign.status}}</td>
                                <td><a class="link --primary" (click)="navigateToEditCampaign(campaign.id)">Edit</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h2 class=" record-not-found" *ngIf="campaignList.length === 0">No Records Found</h2>
            </div>

            <div class="block" *ngIf="campaignList.length > 0">
                <div class="grid --gutter">
                    <div class="grid__block   w-4of12@sm   as-c@sm">
                        <div class="notes">
                            Showing {{pager.currentPage}} / {{pager.totalPages}} Page
                        </div>
                    </div>

                    <div class="grid__block   w-8of12@sm   ta-r@sm   as-c@sm">
                        <div class="pagination">
                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === 1" [disabled]="pager.currentPage === 1" (click)="listCampaign(pager.currentPage - 1)">
                                <span class="angle-arrow --left"></span>
                                <span class="ha">Previous Page</span>
                            </button>

                            <button type="button" *ngFor="let page of pager.pages" class="pagination__button" [class.--active]="page === pager.currentPage" (click)="listCampaign(page)">{{
                                page
                                }}</button>

                            <button type="button" class="pagination__button" [class.--disabled]="pager.currentPage === pager.totalPages" [disabled]="pager.currentPage === pager.totalPages" (click)="listCampaign(pager.currentPage + 1)">
                                <span class="angle-arrow --right"></span>
                                <span class="ha">Next Page</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-dialog" @fadeToggle *ngIf="isDeletingCampaign;">
    <div class="wrapper">
        <div class="delete-dialog__field">
            <div class="delete-dialog__total">
                You have selected {{countSelectedCampaign()}} campaign(s)
            </div>

            <div class="delete-dialog__button">
                <div class="inline-button__field">
                    <button class="button inline-button__inputs" type="button" (click)="showPopup(deleteCampaign)" [disabled]="countSelectedCampaign() === 0">
                        <img src="assets/images/ng-components/create-manage/icon-delete-white.svg" alt="" width="23" height="23" class="inline-button__icon">

                        <div class="inline-button__input">
                            Delete
                        </div>
                    </button>

                    <button class="button inline-button__inputs --grey" type="button" (click)="cancelResetDelete()">
                        <img src="assets/images/ng-components/create-manage/icon-cancel-white.svg" alt="" width="16" height="16" class="inline-button__icon">

                        <div class="inline-button__input">
                            CANCEL
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup #deleteCampaign="popup" class="cm-popups">
    <div class="popup__block">
        <div class="cm-heading__main">
            <img src="assets/images/ng-components/create-manage/icon-cm-alert.svg" alt="" width="43" height="43" class="cm-heading__icon">

            <div class="cm-heading__text">
                Confirm Delete?
            </div>
        </div>
    </div>

    <div class="popup__block">
        <p>
            You are going to delete
            <span class="total-count">{{countSelectedCampaign()}}</span> campaign(s)
        </p>

        <p class="notes">
            <!-- if any -->
        </p>
    </div>

    <div class="popup__block">
        <div class="inline-button__field">
            <button class="button inline-button__inputs" type="button" (click)="deleteCampaigns();hidePopup();">
                CONFIRM
            </button>

            <button class="button inline-button__inputs --grey" (click)="hidePopup();" type="button">
                CANCEL
            </button>
        </div>
    </div>
</app-popup>
