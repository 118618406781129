import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { environment } from '../../../../../environments/environment';

import { SnackBarService } from '../../../../_services/snackBar/snack-bar.service';
import { PopupCampaignService } from '../../../../_services/campaign/popup-campaign.service';

import * as DomUtil from './../../../../util/domUtil';

import { ERROR_MESSAGE as ErrorMessage } from './../../../../constants/errorMessage';
import { Browser } from '@capacitor/browser';

@Component({
    selector: 'app-user-guide',
    templateUrl: './user-guide.component.html',
    styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent implements OnInit {

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private platform: Platform,
        private snackBar: SnackBarService,
        private transfer: FileTransfer,
        private file: File,
        private popupCampaignService: PopupCampaignService
    ) { }

    environment = environment;
    pdfUrl = environment.appConfig.support.userGuidePdfFileUrlPath;
    page: number = 1;
    totalPages: number;
    isLoaded: boolean = false;
    systemInfo = environment.systemInfo;

    ngOnInit() {
        this.checkUnviewedCampaign();
    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    nextPage() {
        this.page++;
    }

    prevPage() {
        this.page--;
    }

    afterLoadComplete(pdfData: any) {
        this.totalPages = pdfData.numPages;
        this.isLoaded = true;
    }

    downloadUserGuideMenu() {
        if (DomUtil.isMobileApp(this.platform)) {
            if (DomUtil.isMobileApp(this.platform, 'ios') || DomUtil.isMobileApp(this.platform, 'android')) {
                Browser.open({url:this.pdfUrl,windowName:'_self'});

            }
        } else {
            DomUtil.downloadInNewTab(this.pdfUrl);
            // window.open(this.pdfUrl);
            // this.downloadPdf();
        }
    }

    // async downloadPdf() {
    //   let result = await this.userService.userGuide();
    //   console.debug("THIS IS PDF");
    //   console.debug(result);

    //   //Generate Excel File
    //   const data = new Blob([result], { type: 'application/pdf' });
    //   FileSaver.saveAs(data, "testData.pdf");

    // }

    // download2() {
    //   this.userService.downloadfile2().subscribe(data => this.downloadFile(data)),//console.debug(data),
    //     error => console.debug('Error downloading the file.'),
    //     () => console.info('OK');
    // }

    // downloadFile(data: any) {
    //   const blob = new Blob([data], { type: 'application/pdf' });
    //   const url = window.URL.createObjectURL(blob);
    //   window.open(url);
    // }

}
