import * as StringUtil from './../util/stringUtil';
export const partialAppend = 'Kindly retry and contact admin if issue persists.';

export const MESSAGE = {
    MAXLENGTH: {
        value: 'MAXLENGTH',
        label: '{0} Exceed Maximum length. Max:[{1} Character] '
    },
    OUT_OF_RANGE: {
        value: 'OUT_OF_RANGE',
        label: '{0} must between {1} - {2}'
    },
    IS_DUPLICATE: {
        value: 'IS_DUPLICATE',
        label: '{0} is Duplicated'
    },
    IS_EMPTY: {
        value: 'IS_EMPTY',
        label: '{0} is Required'
    },
    NOT_FOUND: {
        value: "NOT_FOUND",
        label: '{0} is Not Found'
    },
    VEHICLE_NOT_FOUND: {
        value: 'VEHICLE_NOT_FOUND',
        label: 'Current user does not have any vehicle(s). Please contact your admin.'
    },
    START_DATE_LARGER: {
        value: 'START_DATE_LARGER',
        label: 'Please select a starting date that is before ending date.'
    },
    INVALID_EMAIL: {
        value: 'INVALID_EMAIL',
        label: 'Email is Invalid'
    },
    IS_MATCH: {
        value: 'NOT_MATCH',
        label: 'Password Mismatch'
    },
    NO_UPPERCASE: {
        value: 'NO_UPPERCASE',
        label: 'Password Required at least 1 Uppercase Letter'
    },
    NO_LOWERCASE: {
        value: 'NO_LOWERCASE',
        label: 'Password Required at least 1 Lowercase Letter'
    },
    NOT_ENOUGH_LENGTH: {
        value: 'NOT_ENOUGH_LENGTH',
        label: 'Password must be a minimum of 8 character'
    },
    NO_NUMBER: {
        value: 'NO_NUMBER',
        label: 'Password Required at least 1 Numeric Digit'
    },
    CREATE_SUCCESS: {
        value: 'CREATE_SUCCESS',
        label: '{0} ({1}) created successfully.'
    },
    CREATE_PARTIAL: {
        value: 'CREATE_PARTIAL',
        label: '{0} ({1}) created partially. ' + partialAppend
    },
    CREATE_FAILED: {
        value: 'CREATE_FAILED',
        label: '{0} ({1}) create failed.'
    },
    UPDATE_SUCCESS: {
        value: 'UPDATE_SUCCESS',
        label: '{0} ({1}) updated successfully.'
    },
    UPDATE_PARTIAL: {
        value: 'UPDATE_PARTIAL',
        label: '{0} ({1}) updated partially. ' + partialAppend
    },
    UPDATE_FAILED: {
        value: 'UPDATE_FAILED',
        label: '{0} ({1}) update failed.'// (ErrorCode: {2})'
    },
    DELETE_SUCCESS: {
        value: 'DELETE_SUCCESS',
        //Eg1: 2 Drivers has been successfully deleted
        //Eg2: Driver (xxx) has been successfuly delete
        label: '{0} deleted successfully.'
    },
    DELETE_PARTIAL: {
        value: 'DELETE_PARTIAL',
        label: '{0} deleted partially. ' + partialAppend
    },
    DELETE_FAILED: {
        value: 'DELETE_FAILED',
        //Eg1: 2 Drivers failed to delete
        //Eg2: Driver (xxx) failed to delete
        label: '{0} delete failed.'
    },
    DELETE_ONE_SUCCESS: {
        value: 'DELETE_ONE_SUCCESS',
        label: '{0} ({1}) deleted successfully.'
    },
    DELETE_ONE_PARTIAL: {
        value: 'DELETE_ONE_PARTIAL',
        label: '{0} ({1}) deleted partially. ' + partialAppend
    },
    DELETE_ONE_FAILED: {
        value: 'DELETE_ONE_FAILED',
        label: '{0} ({1}) delete failed.'
    },
    IMPORT_SUCCESS: {
        value: 'IMPORT_SUCCESS',
        label: 'All {0} import successfully'
    },
    IMPORT_FAILED: {
        value: 'IMPORT_FAILED',
        label: '{0} import failed'
    },
    // TRIP_SUMMARY_NO_DETAIL_TRIP: {
    //     value: 'TRIP_SUMMARY_NO_DETAIL_TRIP',
    //     label: 'There are no details for the selected trip.'
    // },
    ASSIGN_RESOURCE_SUCCESS: {
        value: "ASSIGN_RESOURCE_SUCCESS",
        label: "{0}'s assigned Drivers and Vehicles has been successfully updated"
    },
    ASSIGN_RESOURCE_FAILED: {
        value: "ASSIGN_RESOURCE_FAILED",
        label: "Failed to assign vehicles and/or drivers"
    },
    ASSIGN_DRIVER_SUCCESS: {
        value: "ASSIGN_DRIVER_SUCCESS",
        label: "Driver {0} has been successfully assigned to {1}"
    },
    ASSIGN_DRIVER_FAILED: {
        value: "ASSIGN_DRIVER_FAILED",
        label: "Driver {0} failed to assigned to {1}, please try again"
    },
    MARK_MAINTENANCE_SUCCESS: {
        value: "MARK_MAINTENANCE_SUCCESS",
        label: "Maintenance ({0}) for {1} marked complete successfully."
    },
    MARK_MAINTENANCE_PARTIAL: {
        value: "MARK_MAINTENANCE_PARTIAL",
        label: "Maintenance ({0}) for {1} marked complete partially. " + partialAppend
    },
    MARK_MAINTENANCE_FAILED: {
        value: "MARK_MAINTENANCE_FAILED",
        label: "Maintenance ({0}) for {1} mark complete failed."
    },
    EMAIL_SENT_PASSWORD_RESET_LINK: {
        value: "EMAIL_SENT_PASSWORD_RESET_LINK",
        label: "An email with the password reset link has been sent<br>to <b><u>{0}</u></b> if this email address is registered in the system."
    },
    EMAIL_SENT_ACCOUNT_REACTIVATE_LINK: {
        value: "EMAIL_SENT_ACCOUNT_REACTIVATE_LINK",
        label: "An email with the account re-activate link has been sent<br>to <b><u>{0}</u></b> if this email address is registered in the system."
    },
    DOCUMENT_DOWNLOAD_SUCCESS: {
        value: "DOCUMENT_DOWNLOAD_SUCCESS",
        label: "Document download successfully."
    },
    EMAIL_SENT_EMAIL_UPDATE_EMAIL_PASSWORD_LINK: {
        value: "EMAIL_SENT_EMAIL_UPDATE_EMAIL_PASSWORD_LINK",
        label: "An email with the password reset link has been sent<br>to <b><u>{0}</u></b>"
    },
    VEHICLE_TERMINATION_SUCCESS: {
        value: "VEHICLE_TERMINATION_SUCCESS",
        label: "Vehicle is terminated successfully."
    },
    // Note: To render HTML tags as string correctly in Angular, use attribute: [innerHTML]="someVariable"

    REPORT_NO_RECORD_FOUND: {
        value: 'REPORT_NO_RECORD_FOUND',
        label: 'No Records Found'
    },

    REPORT_PLEASE_GENERATE: {
        value: 'REPORT_PLEASE_GENERATE',
        label: 'Please click \'GENERATE\' to view report'
    },

    VEHICLE_PIN_FAILED: {
        value: 'VEHICLE_PIN_FAILED',
        label: 'Vehicle ({0}) pin to top failed.'
    },
    VEHICLE_UNPIN_FAILED: {
        value: 'VEHICLE_UNPIN_FAILED',
        label: 'Vehicle ({0}) unpin from top failed.'
    },

    VEHICLE_SWITCH_SUCCESS: {
        value: 'VEHICLE_SWITCH_SUCCESS',
        //Eg1: 2 devices provider switching successfully.
        //Eg2: device (500068) provider switching succesfully.
        label: '{0} provider switching successfully.'
    },
    VEHICLE_SWITCH_FAILED: {
        value: 'VEHICLE_SWITCH_FAILED',
        //Eg1: 2 devices provider switching successfully.
        //Eg2: device (500068) provider switching succesfully.
        label: '{0} provider switching failed.'
    },

    // Mistake Handling
    POTENTIAL_DEVELOPER_MISTAKE: {
        value: 'POTENTIAL_DEVELOPER_MISTAKE',
        label: 'Potential developer mistake. {0}'
    },

    // Excel Columns Validation
    INVALID_TEMPLATE_TYPE: {
        value: 'INVALID_TEMPLATE_TYPE',
        label: 'Please select a file type'
    },
    TEMPLATE_FILE_MISMATCH: {
        value: 'TEMPLATE_FILE_MISMATCH',
        label: 'Uploaded file not belong to {0}<br>Please check your file type selection'
    },
    PLATE_NO_NOT_FOUND: {
        value: 'PLATE_NO_NOT_FOUND',
        label: 'Vehicle plate number is either not found/empty, please update your vehicle(s) plate number in vehicle page'
    },
    NOT_NUMERIC: {
        value: 'NOT_NUMERIC',
        label: '{0} is either not numerical values/empty'
    },
    INVALID_DATE: {
        value: 'INVALID_DATE',
        label: '{0} is either invalid format/empty, please format your date format to: {1}'
    },
    INVALID_ODOMETER: {
        value: 'INVALID_ODOMETER',
        label: '{0} is not numerical values'
    },
    COLUMN_NOT_FOUND: {
        value: 'COLUMN_NOT_FOUND',
        label: 'Required Column {0} is missing<br>Please follow template format'
    },
    FILE_SIZE_TOO_LARGE: {
        value: 'FILE_SIZE_TOO_LARGE',
        label: 'File Size Cannot More Than {0}MB (Current: {1}MB)'
    },
    INCORRECT_FILE_FORMAT_TYPE: {
        value: 'INCORRECT_FILE_FORMAT_TYPE',
        label: '<span>Incorrect file type "{0}" is selected</span><br/><span>Available file type: {1}</span>'
    },
    RAISED_SUCCESS: {
        value: 'RAISED_SUCCESS',
        label: 'Issue raised successfully.'
    },
    RAISED_FAILED: {
        value: 'RAISED_FAILED',
        label: 'Issue raised failed.'
    },
    GENERIC_SUCCESS: {
        value: 'GENERIC_SUCCESS',
        label: '{0} successfully.'
    },
    GENERIC_FAILED: {
        value: 'GENERIC_FAILED',
        label: '{0} failed.'
    }
};

export function getMessage(msgCode: string, ...params: any[]) {
    if (!MESSAGE[msgCode]) {
        return null;
    }
    let message = MESSAGE[msgCode].label;
    message = StringUtil.replaceVarsInText(message, params);
    return message;
}
