import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as USER_ROLE from '../../../../../constants/userRole';
import * as Message from '../../../../../constants/message';
import * as DomUtil from '../../../../../util/domUtil';
import * as StringUtil from '../../../../../util/stringUtil';
import { SpinnerComponent } from '../../../../common/spinner/spinner.component';
import { SupportService } from '../../../../../_services/support/support.service';
import { PopupService } from '../../../../../components/common/popup/popup.service';
import { SnackBarService } from '../../../../../_services/snackBar/snack-bar.service';
import { RouterProxyService } from './../../../../../_services/router-proxy/router-proxy.service';
import { RESPONSE_STATUS_CODE as ResponseStatusCode } from './../../../../../constants/responseStatusCode';
import { PopupCampaignService } from './../../../../../_services/campaign/popup-campaign.service';

@Component({
    selector: 'app-create-support',
    templateUrl: './create-support.component.html',
    styleUrls: ['./create-support.component.scss']
})
export class CreateSupportComponent implements OnInit {

    userRole: any = USER_ROLE;
    userRoleALabel: string = USER_ROLE.getUserRoleLabel('MAXIS_ADMIN_LEVEL_A');
    userRoleBLabel: string = USER_ROLE.getUserRoleLabel('MAXIS_ADMIN_LEVEL_B');
    userRoleCLabel: string = USER_ROLE.getUserRoleLabel('MAXIS_ADMIN_LEVEL_C');
    userRoleSalesDemoLabel: string = USER_ROLE.getUserRoleLabel('MAXIS_SALES_DEMO');
    message: any = Message;

    newMaxisSupport = {
        selectedRole: "MAXIS_SUPPORT_A",
        name: '',
        email: ''
    };

    selectedRoleLabel: any = 'Maxis Support A';

    @ViewChild("page_spinner",{static:true}) pageSpinner: SpinnerComponent;

    hasUnviewedCamapaign: boolean = false;
    unviewedCampaigns: Array<any> = [];

    constructor(
        private supportService: SupportService,
        private popupService: PopupService,
        private routerProxyService: RouterProxyService,
        private snackBar: SnackBarService,
        private el: ElementRef,
        private popupCampaignService: PopupCampaignService

    ) { }

    ngOnInit() {
        DomUtil.autoFocusForm(this.el);
        this.checkUnviewedCampaign();

    }

    async checkUnviewedCampaign() {
        try {
            const unViewedCampaignsResult = await this.popupCampaignService.getUnviewedCampaigns();
            this.hasUnviewedCamapaign = unViewedCampaignsResult.hasUnviewedCamapaign;
            this.unviewedCampaigns = unViewedCampaignsResult.unviewedCampaigns;
        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        }
    }

    getSelectedRole(role) {
        this.newMaxisSupport.selectedRole = USER_ROLE.getUserRoleFromLabel(role);
        this.selectedRoleLabel = role;
    }

    async createMaxisSupport() {
        this.pageSpinner.show();

        try {

            // trim leading & trailing whitespace for names
            if (StringUtil.isNotEmptyOrNull(this.newMaxisSupport.name)) {
                this.newMaxisSupport.name = this.newMaxisSupport.name.trim();
            }

            const result = await this.supportService.createMaxisSupportUser(this.newMaxisSupport);

            let msg = Message.getMessage(Message.MESSAGE.CREATE_FAILED.value, this.selectedRoleLabel, this.newMaxisSupport.name);
            if (result && result.statusCode == ResponseStatusCode.SUCCESS.code) {
                msg = Message.getMessage(Message.MESSAGE.CREATE_SUCCESS.value, this.selectedRoleLabel, this.newMaxisSupport.name);
                this.navigateSuccessToMaxisSupportView();
            }
            this.snackBar.openGenericSnackBar(msg);

        } catch (e) {
            this.snackBar.openStandardizedErrorSnackBar(e);
        } finally {
            this.pageSpinner.hide();
        }
    }

    showPopup(popup) {
        this.popupService.show(popup);
    }
    hidePopup() {
        this.popupService.hide();
    }

    navigateSuccessToMaxisSupportView() {
        // Force page refresh
        this.routerProxyService.navigateSuccess(['/support/manage/admins']);
    }

    navigateToMaxisSupportView() {
        this.routerProxyService.navigate(['/support/manage/admins']);
    }
}
