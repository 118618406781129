import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as StringUtil from './../../util/stringUtil';
import { modules } from './../../constants/module-access.constant';

@Injectable({
    providedIn: 'root'
})
export class ModulePermissionService {
    permissions: Array<any> = [];

    constructor(private http: HttpClient) { }

    /**
     * Get each module accessibility
     * @param moduleCode module value from constant.
     * @returns An object of all specified permission
     */
    async hasPermission(...moduleCode: string[]) {
        const permissions: any = {};
        if (!this.permissions.length) {
            await this.initialPermissions();
        }

        if (this.permissions.length) {
            moduleCode.map(eachCode => {
                let requiredPermission = {};
                this.permissions.find(permission => {
                    if (StringUtil.equalsIgnoreCase(eachCode, permission.mCode)) {
                        requiredPermission = {
                            mName: permission.mName,
                            vAccess: permission.vAccess,
                            cAccess: permission.cAccess,
                            eAccess: permission.eAccess,
                            dAccess: permission.dAccess
                        };
                        return permission;
                    }
                });
                permissions[eachCode] = requiredPermission;
            });
        }

        if (permissions) {
            return permissions;
        } else {
            return;
        }
    }

    clearPermissions() {
        this.permissions = [];
    }

    async initialPermissions() {
        try {
            // console.debug('initializePermissions');
            const moduleAccess = await this.getModuleAccess();
            if (moduleAccess && moduleAccess.body) {
                this.permissions = [];

                for (let i = 0; i < moduleAccess.body.length; i++) {
                    const ma = moduleAccess.body[i];
                    if (StringUtil.equalsIgnoreCase(ma.mCode, modules.REPORTS.value) ||
                        StringUtil.equalsIgnoreCase(ma.mCode, modules.CREATE_MANGE.value)) {
                        continue;
                    }
                    this.permissions.push(ma);
                }
            }
            // console.debug('permission:'+JSON.stringify(this.permissions));
        } catch (err) {
            // do nothing
        }
    }

    async getModuleAccess() {
        const path = environment.mtntApiEndpoint + '/authorization/module-access';
        try {
            const result: any = await this.http.get(path).toPromise();
            return result;
        } catch (err) {
            // do nothing
            return null;
        }
    }
}
